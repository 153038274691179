import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { getMoment, showPeriod, showPeriodByDay } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import NwIcon from 'Components/Gui/NwIcon';
import AcknowledgementEvent from 'Components/EventButtons/AcknowledgementEvent'
import CancelEvent from 'Components/EventButtons/CancelEvent'
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { showDateNoTime, showTime, isAllDay } from 'Libs/NwMoment';
import { ListContext } from 'Contexts/ListContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { NwButton, NwButtonGroup } from 'Components/Gui/NwButton';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { AcknowledgmentDot } from 'Components/AcknowledgmentDot/AcknowledgmentDot';
import { isFullDay } from 'Libs/NwMoment';
import NwDivider from 'Components/Gui/NwDivider';
import { useAgendaContext } from './AgendaContext';
import { useAddModelToDate } from 'Hooks/Event/UseEvent';

const AgendaEventContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: ${props => props.theme.DefaultBorder};
  min-height: 3rem;
  ${props => props.cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}

  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }

  &:hover {
    background-color: ${props => props.theme.SelectedBackgroundColor};
  }

  .agenda-event-time {
    text-transform: uppercase;
    padding: .25em .5em .25em 0;
    white-space: nowrap;
    min-width: 8.5rem;
    text-align: right;
    opacity: ${props => props.isAvailable && 0.5};
    @media only screen and (max-width: 480px) {
      text-align: left;
      width: 100%; 
      padding: 0.25em 0.5em;
      border-bottom: ${props => props.theme.DefaultBorder};
    }
    .start-end-time {
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 480px) {
        flex-direction: row;
        .start-time {
          margin-right: 10px;
        }
      }
    }
    span {
      display: block;    
      font-size: 1rem;
      
      &.start-time {
        font-weight: bold;
      }
      &.all-day {
        font-weight: normal;
        font-size: ${props => props.theme.FontSizeSm};
      }
      &.reminder {
        font-weight: bold;
        color: ${props => props.theme.DangerColor};
        font-size: .6rem;
      }
    }
    small {
      display: block;
      font-weight: normal;
      font-size: ${props => props.theme.FontSizeSm};
    }
    div.end-date {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        margin-right: 1em;
      }

    }
    div.started-date {
      display: block;
    }
  }

  .agenda-event-content {
    border-left: ${props => props.theme.DefaultBorder};
    padding: .5em;
    min-height: 3rem;
    flex: 1 0 0;
    opacity: ${props => props.isAvailable && .5};

    @media only screen and (max-width: 480px) {
        width: 100%;
        border-left: none;
    }

    .agenda-event-name {

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: .15rem 0;

        .ant-tag {
            ${props => props.cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
        }
        .netwalk-event-tag {
            margin-top: .25rem;
            margin-bottom: .15rem;
        }

        .agenda-event-nametext {
            font-weight: bold;
            font-size: .8rem;
            line-height: ${props => props.theme.FontSizeSm};
            text-transform: uppercase;
            ${props => props.cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
        }
    }

    .agenda-event-description {
        padding-right: 2rem;
        font-size: ${props => props.theme.FontSizeSm};
        line-height: 1.1em;
    }

    .agenda-event-models-list {
        padding: .15rem 0;
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: capitalize;
        em {
            font-style: normal;
        }
    }
    .agenda-event-models-exceptions {
        padding: .15rem 0;
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: capitalize;
        strong {
            display: block;
            text-transform: uppercase;
        }
        em {
            font-style: normal;
        }
    }
  }
`

const AgendaEventToolbar = styled.div`
    padding-top: ${props => props.$mobileView ? '.75rem' : '1.25rem'};
    padding-right: .5rem;
    opacity: ${props => props.isAvailable && 0.5};
`;



const ButtonGroupWrapper = styled.div`
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-right: 1px solid #d9d9d9 !important;
        &.event-cancelled-button {
            border-right: 1px solid ${props => props.theme.DangerColor} !important;
        }
    }
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;

const TimeWithBreaks = ({ st, et, sb, eb }) => {
    return (
        <div className="double-time-wrapper">
            <div className="start-end-time">
                <span className="start-time">{showTime(st)}</span>
                <div className="end-date">
                    <span><NwIcon icon={light("arrow-to-right")} size="xs" /></span>
                    <span className="end-time">{showTime(sb)}</span>
                </div>
            </div>
            <div className="start-end-time">
                <span className="start-time">{showTime(eb)}</span>
                <div className="end-date">
                    <span><NwIcon icon={light("arrow-to-right")} size="xs" /></span>
                    <span className="end-time">{showTime(et)}</span>
                </div>
            </div>
        </div>
    )
}

const SimpleTime = ({ st, et }) => {
    return (
        <div className="start-end-time">
            <span className="start-time">{showTime(st)}</span>
            <div className="end-date">
                <span><NwIcon icon={light("arrow-to-right")} size="xs" /></span>
                <span className="end-time">{showTime(et)}</span>
            </div>
        </div>
    )
}

const OnlyStartTime = ({ st }) => {
    return (
        <span className="start-time">{showTime(st)}</span>
    )
}

const AllDayTime = () => {
    return (
        <span className="all-day">ALL DAY</span>
    )
}

const NoTimes = ({ st, et }) => {
    return (
        <>
            <div className="start-date">
                <span className="end-time">{showDateNoTime(st)}</span>
            </div>
            <div className="end-date">
                <span><NwIcon icon={light("arrow-to-right")} size="xs" /></span>
                <span className="end-time">{showDateNoTime(et)}</span>
            </div>
        </>
    )
}

const PeriodTime = ({ st, et }) => {
    return (
        <>
            <div className="started-date">
                <small><strong>STARTED ON</strong></small>
                {isFullDay(st, et)
                    ?
                    <small>{showDateNoTime(st)}</small>
                    :
                    <small>{showDateNoTime(st)} &bull; {showTime(st)}</small>
                }
            </div>
            <div className="started-date">
                <small><strong>WILL END ON</strong></small>
                {isFullDay(st, et)
                    ?
                    <small>{showDateNoTime(et)}</small>
                    :
                    <small>{showDateNoTime(et)} &bull; {showTime(et)}</small>
                }
            </div>
        </>
    )
}

const PeriodEnd = ({ st, et }) => {
    return (
        <div className="start-end-time">
            <span className="start-time"><NwIcon icon={light("arrow-to-right")} /> {showTime(et)}</span>
            <div className="started-date">
                <small>STARTED ON</small>
                <small>{showDateNoTime(st)} &bull; {showTime(st)}</small>
            </div>
        </div>
    )
}

const PeriodStart = ({ st, et }) => {
    return (
        <div className="start-end-time">
            <span className="start-time">{showTime(st)}</span>
            <div className="end-date">
                <small><NwIcon icon={light("arrow-to-right")} /></small>
                <small>{showDateNoTime(et)}<br />{showTime(et)}</small>
            </div>
        </div>
    )
}

const AgendaEvent = ({
    event
}) => {

    const {
        contactId,
        loadMonthEvs,
        onSelectEvent,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    } = useAgendaContext();

    const isAvailable = event.isAvailable
    const date = getMoment(scheduleRange.start)
    const { mutateAsync: onAddModelDate } = useAddModelToDate();

    const onReload = () => {
        loadMonthEvs();
    }

    const onAddModelEvent = async event => {
        try {
            await onAddModelDate({
                eventDateId: event.EventDateID,
                modelId: contactId
            })
            onReload()
        } catch (error) {
            console.log('ant : Add Model Event Error => ', error)
        }
    };

    const { cachedList } = useContext(ListContext);
    const [loading, setLoading] = useState(false);
    const breakpoints = useNwBreakPoints();

    let cls = ""
    if (event === selectedEvent) {
        cls = "agenda-event-selected"
    }
    const st_string = event.FromDate
    const et_string = event.ToDate
    const sb_string = event.StartBreak
    const eb_string = event.EndBreak
    const st = getMoment(st_string)
    const et = getMoment(et_string)
    const sb = sb_string ? getMoment(sb_string) : null
    const eb = eb_string ? getMoment(eb_string) : null
    const moddates = event.ModelsDates

    const eventtype = cachedList.eventTypes.items.find((et) => (et.code === event.TypeName))

    const showBookingEventTime = (st, et, sb, eb) => {
        if (sb && eb) {
            return (
                <TimeWithBreaks st={st} et={et} sb={sb} eb={eb} />
            )
        }
        if (st.hours() === 0 && st.minutes() === 0 && et.hours() === 23 && et.minutes() === 59) {
            return (
                <AllDayTime />
            )
        } else {
            if (st.hours() === et.hours() && st.minutes() === et.minutes()) {
                return (
                    <OnlyStartTime st={st} />
                )
            } else {
                return (
                    <SimpleTime st={st} et={et} />
                )
            }
        }
    }

    const writeTime = () => {
        switch (eventtype.code) {
            case "Accommodation":
                if (!st.isSame(et, 'day')) {
                    return (
                        <NoTimes st={st} et={et} />
                    )
                }
                return <AllDayTime />
            case "Casting":
            case "Job":
                let timetoshow = ""
                if (moddates && moddates.length) {
                    if (moddates.length > 1) {
                        return (
                            <span className="all-day">{moddates.length} time frames</span>
                        )
                    } else {
                        const stmd = getMoment(moddates[0].FromDate)
                        const etmd = getMoment(moddates[0].ToDate)
                        const sbmd = moddates[0].StartBreak ? getMoment(moddates[0].StartBreak) : null
                        const ebmd = moddates[0].EndBreak ? getMoment(moddates[0].EndBreak) : null
                        timetoshow = showBookingEventTime(stmd, etmd, sbmd, ebmd)
                    }
                } else {
                    timetoshow = showBookingEventTime(st, et, sb, eb)
                }
                return timetoshow
            default:
                if (!et || st.isSame(et, 'minute')) {
                    //single date (no endtime)
                    return (
                        <OnlyStartTime st={st} />
                    )
                }
                if (et && st.isSame(et, 'day')) {
                    if (st.hours() === 0 && st.minutes() === 0 && et.hours() === 23 && et.minutes() === 59) {
                        return (
                            <AllDayTime />
                        )
                    }
                    return (
                        <SimpleTime st={st} et={et} />
                    )
                } else {
                    //longer than one day
                    if (!st.isSame(date, "days")) {
                        //it's not start date
                        if (!et.isSame(date, "days")) {
                            //between start and end
                            return (
                                <PeriodTime st={st} et={et} />
                            )
                        } else {
                            //it's end date
                            return (
                                <PeriodEnd st={st} et={et} />
                            )
                        }
                    } else {
                        //it's start date
                        return (
                            <PeriodStart st={st} et={et} />
                        )
                    }
                }
        }
    }
    //TODO: different event type

    const hasAcknowledgement = () => {
        if (eventtype.code !== "ToDo" && eventtype.code !== "Meeting" && eventtype.code !== "ExtJob" && eventtype.code !== "Accommodation" && eventtype.code !== "Notice") {
            return true
        }
        return false
    }
    const hasCancel = () => {
        if (eventtype.code !== "ToDo" && eventtype.code !== "Meeting" && eventtype.code !== "Accommodation" && eventtype.code !== "Notice") {
            return true
        }
        return false
    }

    const handleAddModelEvent = async () => {
        setLoading(true);
        await onAddModelEvent(event);
        setLoading(false);
    };

    const renderModelsAndExceptions = () => {
        if (event.TypeName !== "Casting" && event.TypeName !== "Job") {
            return event.ModelID ? <div>{getContactName(event.ModelID)}</div> : '';
        } else {
            const exceptions = event.ModelsDates.filter(modelDate => modelDate.isException);
            if (exceptions.length === 0) {
                let modelList = [];
                for (const modelDate of event.ModelsDates) {
                    //if (!modelDate.isException) {
                    for (const modelofdate of modelDate.Models) {
                        if (!modelList.find(item => item.ModelID === modelofdate.ModelID)) {
                            modelList.push(modelofdate);
                        }
                    }
                    //}
                }
                return (
                    <>
                        <NwDivider lessMargin>models</NwDivider>
                        <div className="agenda-event-models-list">
                            {modelList.map((model, index) => (
                                <>
                                    <span key={model.ModelID} style={{ textDecoration: model.Cancel ? 'line-through' : null }}>
                                        <AcknowledgmentDot ackn={model.Acknowledgment} mg="0 .15rem 0 0" />
                                        {getContactName(model.ModelID)}
                                    </span>
                                    {(index < (modelList.length - 1)) &&
                                        <em>, </em>
                                    }
                                </>
                            ))}
                        </div>
                    </>
                )
            } else {
                return (
                    event.ModelsDates.map((exception, index) => (
                        <div key={index} className="agenda-event-models-exceptions">
                            <strong>
                                {isAllDay(exception.FromDate, exception.ToDate) ? 'all day' : `${showTime(exception.FromDate)} > ${showTime(exception.ToDate)}`}
                            </strong>
                            {exception.Models.map((model, index) => (
                                <>
                                    <span key={model.ModelID} style={{ textDecoration: model.Cancel ? 'line-through' : null }}>
                                        <AcknowledgmentDot ackn={model.Acknowledgment} mg="0 .15rem 0 0" />
                                        {getContactName(model.ModelID)}
                                    </span>
                                    {(index < (exception.Models.length - 1)) &&
                                        <em>, </em>
                                    }
                                </>
                            ))}
                        </div>
                    ))
                );
            }
        }
    };

    const renderAgendaToolbar = () => {
        if (type !== 'all' && event.TypeName !== "Accommodation") {
            return (<AgendaEventToolbar $mobileView={breakpoints === 1}>
                {isAvailable ?
                    <NwButtonGroup size="small">
                        <NwButton
                            icon={light('plus')}
                            loading={loading}
                            onClick={e => {
                                handleAddModelEvent();
                                e.stopPropagation();
                            }} />
                    </NwButtonGroup>
                    :
                    <>
                        {(hasAcknowledgement() || hasCancel()) &&
                            <ButtonGroupWrapper>
                                <NwButtonGroup size="small">
                                    {hasAcknowledgement() &&
                                        <AcknowledgementEvent
                                            button
                                            previewMode={previewMode}
                                            onStatusChanged={onReload}
                                            acknowledgmentValue={event.Acknowledgment}
                                            type={event.TypeName}
                                            eventId={event.ID}
                                            eventDateModelID={event.EventDateModelID} />}
                                    {hasCancel() &&
                                        <CancelEvent
                                            button
                                            eventData={event}
                                            previewMode={previewMode}
                                            onStatusChanged={onReload}
                                            cancelValue={event.Cancel}
                                            type={event.TypeName}
                                            eventId={event.ID}
                                            modelID={event.ModelID}
                                            eventDateModelID={event.EventDateModelID} />}
                                </NwButtonGroup>
                            </ButtonGroupWrapper>
                        }
                    </>
                }
            </AgendaEventToolbar>)
        } else {
            return (<></>)
        }
    }
    return (
        <AgendaEventContainer
            cancel={event.Cancel}
            isAvailable={isAvailable}
            className={cls}
            onClick={() => (!previewMode && onSelectEvent) && onSelectEvent(event)}
        >
            <div className="agenda-event-time" >
                {writeTime()}
            </div>
            <div className="agenda-event-content" >
                {event.Project &&
                    <ProjectLine small project={event.Project} ellipsis mg="0" />
                }
                <div className="agenda-event-name">
                    <EventTypeTag event={event} />
                    <div className="agenda-event-nametext">{event.Name}</div>
                </div>
                {eventtype.id !== 150 &&
                    <div className="agenda-event-description">
                        <NwEllipsis noFontSize text={event.Description} rows={2} />
                    </div>
                }
                {type === 'all' &&
                    <div className="agenda-event-models">
                        {renderModelsAndExceptions()}
                    </div>
                }
                {breakpoints === 1 && renderAgendaToolbar()}
            </div>
            {breakpoints !== 1 && renderAgendaToolbar()}
        </AgendaEventContainer>
    )

}

export default AgendaEvent
