import React, { useContext } from "react"
import { ListContext } from "Contexts/ListContext";
import { showPeriodByDay } from "Libs/NwMoment";
import { Tooltip, Typography } from "antd";
import { getContactName } from "Hooks/Contact/UseContactsList";
import NwDivider from "Components/Gui/NwDivider";
import { ProjectTitleCompactContainer, ProjectTitleContainer, ProjectTitlePopoverContainer } from "./ProjectTitleUI";




const ProjectTitle = ({ project, small, pointer, noProjectText, compact, popover, hidePeriod }) => {
    const { cachedList } = useContext(ListContext);
    const category = project.ProjectCategoryID ? cachedList.projectCategories.items.find(category => category.ID === project.ProjectCategoryID) : null;
    const { Text } = Typography;

    const getProjectCustomerName = () => {
        if (project.Contact) {
            return project.Contact.Name
        }
        if (project.CustomerName) {
            return project.CustomerName
        }
        if (project.CustomerID) {
            return getContactName(project.CustomerID)
        }
        return ""
    }

    const getProjectName = () => {
        if (project.Name) {
            return project.Name
        }
        if (project.ProjectName) {
            return project.ProjectName
        }
        return ""
    }



    const getPeriod = () => {
        if (project.FirstDate) {
            return showPeriodByDay(project.FirstDate, project.LastDate)
        }
        return ""
    }

    const ProductText = () => {
        if (!project.Product) {
            return null
        }
        if (project.Product.length > 60) {
            return (
                <Tooltip title={project.Product}>
                    <span>{project.Product.substring(0, 59)}(...)</span>
                </Tooltip>
            )
        } else {
            return (<span>{project.Product}</span>)
        }
    }

    const ProjectContent = () => {
        const pn = getProjectName();
        const pcn = getProjectCustomerName();
        if (pn && (pn.toLowerCase() !== pcn.toLowerCase())) {
            if (project.Product) {
                return (
                    <>
                        <h1>
                            <strong>{pn}</strong>
                            <em>/</em>
                            {project.Product}
                        </h1>
                        <p>client: <strong>{pcn}</strong>
                        {!hidePeriod && 
                            <>, period: <strong>{getPeriod()}</strong></>
                        }
                        </p>
                    </>
                )
            } else {
                return (
                    <>
                        <h1>
                            <strong>{pcn}</strong>
                            <em>/</em>
                            {pn}
                        </h1>
                        {!hidePeriod && <p>{getPeriod()}</p>}
                    </>
                )
            }
        }
        return (
            <>
                <h1>
                    <strong>{pn}</strong>
                    {project.Product &&
                        <>
                            <em>/</em>
                            <ProductText />
                        </>
                    }
                </h1>
                {!hidePeriod &&  <p>{getPeriod()}</p>}
            </>
        )
    }

    if (compact) {
        return (
            <ProjectTitleCompactContainer $pointer={pointer}>
                <ProjectContent />
            </ProjectTitleCompactContainer>
        )
    }
    if (popover) {
        return (<ProjectTitlePopoverContainer >
            <NwDivider>PROJECT {category && <> / {category.Name}</>}</NwDivider>
            <ProjectContent />
        </ProjectTitlePopoverContainer>)
    }
    return (
        <ProjectTitleContainer $small={small} $pointer={pointer} $noProjectText={noProjectText}>
            {noProjectText
                ?
                (category && <p className="first-line"><Text code>{category.Name}</Text></p>)
                :
                <p className="first-line">PROJECT {category && <Text code>{category.Name}</Text>}</p>
            }
            <ProjectContent />
        </ProjectTitleContainer>
    )
}

export default ProjectTitle