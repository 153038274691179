import React from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { showDateNoTime } from 'Libs/NwMoment';
import { PhoneLine } from 'Components/Gui/NwContactInfo';
import useCountryName from 'Hooks/UseCountryName';

export const EventFieldsTable = styled.table`
    width: 100%;
    tr {
        display: flex;
    }
    td {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: .8rem;

        .icon-container {
            padding-right: 1rem;
            svg {
                font-size: 2.4rem;
                margin: 0 0 8px;
            }
        }
        .address-container {
            cursor: pointer;
            margin-bottom: 1rem;
        }
        .arrow-dropdown {
            font-size: .95rem;
            margin: 4px 0;
        }
        .label {
            font-size: .65rem;
        }
        .date {
            font-size: .95rem;
            font-weight: bold;
        }

        &:first-child {
            padding-left: 0;
            flex: unset;
        }
        &:last-child {
            border-right: none;
        }
    }
`;

const ScoutingAccommodationDetails = ({ type, scoutingAccommodationData }) => {
    const countryName = useCountryName(scoutingAccommodationData && scoutingAccommodationData.Country);

    const ComposeAddressForMaps = () => {
        const addressString = [scoutingAccommodationData.Street, 
            scoutingAccommodationData.City, 
            scoutingAccommodationData.Zip, 
            scoutingAccommodationData.State, 
            countryName].filter(Boolean).join("+");
        return "https://www.google.com/maps/place/" + encodeURIComponent(addressString)
    }

    const showMap = event => {
        event.stopPropagation();
        window.open(ComposeAddressForMaps())
    }

    return (
        <FlexContainer pd='16px 30px 16px 40px'>
            <EventFieldsTable>
                <tbody>
                    <tr>
                        <td>
                            <div className='icon-container'>
                                <NwIcon icon={type === 'RoomLodging' ? light('house-user') : light('bed')} />
                            </div>
                        </td>
                        <td>
                            {scoutingAccommodationData.Title !== scoutingAccommodationData.Service.Name &&
                                <div>{scoutingAccommodationData.Service.Name}</div>
                            }
                            <div className='address-container' onClick={showMap}>
                                {scoutingAccommodationData.Street && <div>{scoutingAccommodationData.Street}</div>}
                                {scoutingAccommodationData.Zip && <div>{scoutingAccommodationData.Zip}</div>}
                                {scoutingAccommodationData.City} ({countryName})
                            </div>
                            {scoutingAccommodationData.PhoneNumber && 
                                <PhoneLine justify='flex-start' phoneType='other' phoneNumber={scoutingAccommodationData.PhoneNumber} noIcon />
                            }
                        </td>
                        <td>
                            <div className='label'>Check In</div>
                            <FlexContainer>
                                <FlexContainer column alignItems='center'>
                                    <div className='date'>{showDateNoTime(scoutingAccommodationData.FromDate)}</div>
                                    <NwIcon icon={light('arrow-down')} className='arrow-dropdown' />
                                </FlexContainer>
                            </FlexContainer>
                            <div className='label'>Check Out</div>
                            <div className='date'>{showDateNoTime(scoutingAccommodationData.ToDate)}</div>
                        </td>
                    </tr>
                </tbody>
            </EventFieldsTable>
        </FlexContainer>
    );
};

export default ScoutingAccommodationDetails;