import React from 'react'
import styled from 'styled-components';
import { Divider } from 'antd';

const StyledVerticalDivider = styled(Divider)`
    position: relative;

    &.ant-divider-vertical {
        border-left: ${props => props.$dark ? props.theme.DarkerBorder : props.theme.DefaultBorder} !important;
    }
`

const NwDividerVertical = ({ dark, ...other }) => {
    return (
        <StyledVerticalDivider type="vertical" $dark={dark} />
    )
}

export default NwDividerVertical