import React from 'react';
import { Result } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const ResultContainer = styled.div`
    .ant-result-icon {
        font-size: 5rem;
    }

    .ant-result-subtitle {
        color: ${props => props.theme.TextColor};
        font-size: 1rem;

        p {
            max-width: 20rem;
            margin: 0 auto;
        }
    }

    &.full-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 4rem);
        background-color: ${props => props.theme.BackgroundColorLight};

        .ant-result-icon {
            font-size: 10rem;
        }
    }
`


const NotAllowedFeature = ({ fullPage }) => {
    return (
        <ResultContainer className={fullPage ? 'full-page' : ''}>
            <Result
                icon={<NwIcon danger bounce icon={solid('ban')} />}
                title="Invalid Access"
                subTitle={<p>We're sorry, but your current Netwalk user policies do not permit this operation.<br/>Please contact your administrator if you believe this is an error or need further assistance.<br/><br/>Thank you for your understanding.</p>}
            />
        </ResultContainer>
    );
}

export default NotAllowedFeature;