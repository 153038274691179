import React from "react";
import ls from "local-storage";
import _ from 'lodash';
import Axios from 'axios';
import { thin, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { GetTimestampNumeric } from "Libs/NwMoment";
import { GetForeColor } from 'Libs/NwUtils';
import { FollowupColorsList } from 'Constants/SwatchColors';
import StyleVariables from "Components/Gui/StyleVariables";
import { eventsTypes } from "Configs/EVENT_TYPES";

//Set the default Status at application start
const LIST_DEFAULT_STATE = {
    cachedListLoaded: false,
    cachedList: {
        countries: {
            url: "/countries",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        generalconfigurations: {
            url: "/configurations/general",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        cities: {
            url: "/cities",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        privatetags: {
            url: "/tags/private",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        publictags: {
            url: "/tags/public",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        users: {
            url: "/users",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        accountingGroups: {
            url: "/accountinggroups",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        transactionFamilies: {
            url: "/transactionfamilies",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        enumerators: {
            url: "/enumerators",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        taxmethods: {
            url: "/taxmethods/active",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        modelAgencyLabels: {
            url: "/modelagencylabels",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        followUpLabels: {
            url: "/followuplabels",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        eventSubtypes: {
            url: "/eventsubtypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        callsheetTypes: {
            url: "/callsheettypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        templates: {
            url: "/configurations/templates",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        layoutConfigurations: {
            url: "/configurations/layoutmodel",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        connectionTypes: {
            url: "/connectiontypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        eventCategories: {
            url: "/eventcategories",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        projectCategories: {
            url: "/projectcategories",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        eventClientRoles: {
            url: "/eventclientroles",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        customFields: {
            url: '/customfields',
            isLoaded: false,
            fetchError: null,
            subKeys: ['Project', 'Event', 'ProjectModel', 'EventModel', 'Model', 'Client', 'Service', 'Agency', 'Invoice'],
            items: {}
        },
        usageTypes: {
            url: "/UsageTypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        noteTypes: {
            url: "/NoteTypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        bankAccounts: {
            url: "/BankAccounts",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        paymentTerms: {
            url: "/PaymentTerms",
            isLoaded: false,
            fetchError: null,
            items: []
        },
        legalTypes: {
            url: "/legaltypes",
            isLoaded: false,
            fetchError: null,
            items: []
        },
    }
};

const ListContext = React.createContext(LIST_DEFAULT_STATE);

const ListContextConsumer = ListContext.Consumer;

class ListContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...LIST_DEFAULT_STATE,
            LoadData: this.loadData,
            LoadUsers: this.loadUsers,
            UpdateCachedList: this.updateCachedList,
            UpdateCachedListwithStatic: this.updateCachedListwithStatic,
            GetCountry: this.getCountry,
            GetCity: this.getCity,
            GetUserName: this.getUserName,
            GetUserNameShort: this.getUserNameShort,
            GetCategoryName: this.getCategoryName,
            GetTagName: this.getTagName,
            GetEventClientRole: this.getEventClientRole,
            GetFamilyName: this.getFamilyName,
            GetTransactionLabelName: this.getTransactionLabelName
        };
    }

    loadData = async () => {

        return new Promise((resolve, reject) => {
            this.loadCachedLists()
                .then(newCachedLists => {


                    const ful = newCachedLists.find((l) => {
                        return l.key === "followUpLabels"
                    })
                    const boo = newCachedLists.find((l) => {
                        return l.key === "eventSubtypes"
                    })
                    const cas = boo.data.items.filter((s) => {
                        return s.TypeName === "Casting"
                    })
                    const job = boo.data.items.filter((s) => {
                        return s.TypeName === "Job"
                    })

                    const eventTypesItems = [...eventsTypes]
                    eventTypesItems.find(x => x.id === 10).subTypes = cas.map((i) => {
                        return _.pick(i, ['Code', 'Name', 'ForeColor', 'BackColor', 'Disabled', 'Default'])
                    })
                    eventTypesItems.find(x => x.id === 20).subTypes = job.map((i) => {
                        return _.pick(i, ['Code', 'Name', 'ForeColor', 'BackColor', 'OptionForeColor', 'OptionBackColor', 'Disabled', 'Default'])
                    })
                    eventTypesItems.find(x => x.id === 110).subTypes = ful.data.items.map((i) => (
                        {
                            ID: i.ID,
                            Code: i.Label,
                            Name: i.Label,
                            ForeColor: null,
                            BackColor: null
                        }
                    ))


                    newCachedLists.push({
                        key: "eventTypes",
                        data: {
                            isLoaded: true,
                            fetchError: null,
                            items: eventTypesItems
                        }
                    });

                    this.setState(() => ({
                        cachedListLoaded: true,
                        cachedList: Object.assign(
                            {},
                            ...newCachedLists.map(d => ({ [d.key]: d.data }))
                        )
                    }));
                    resolve();
                })
                .catch(error => {
                    //TODO: manage errors
                    reject(error);
                });
        });
    };

    //Methods to Load Initial Cached Lists;
    loadCachedLists = () => {
        //wait for all the lists to be fetched
        return Promise.all(
            Object.keys(this.state.cachedList).map(key =>
                //Iterate through cachedlists and return a promise for each of them
                this.loadSingleCachedList(key)
            )
        );
    };

    loadUsers = async () => {
        const users = await Axios.get('/users');
        this.setState({
            cachedList: {
                ...this.state.cachedList,
                users: {
                    ...this.state.cachedList.users,
                    items: [...users]
                }
            }
        });
    }

    //Load a single Cached List
    loadSingleCachedList = key =>
        new Promise(async (resolve, reject) => {
            const use_cl_local = true;
            const cl_local = ls.get(`nw_cachedlist_${key}`);
            
            if (use_cl_local && cl_local) { // && now_ts - cl_lastupdate_local > 1000
                let clist = { ...this.state.cachedList[key] };
                clist.items = cl_local;
                clist.isLoaded = true;
                clist.fetchError = null;
                resolve({ key, data: clist });
            } else {
                try {
                    let clist = { ...this.state.cachedList[key] };
                    if (key === 'customFields') {
                        for (const subKey of this.state.cachedList[key].subKeys) {
                            const subCustomFields = await Axios.get(`${this.state.cachedList[key].url}/${subKey}`);
                            clist.items[subKey] = subCustomFields;
                        }
                    } else {
                        const data = await Axios.get(this.state.cachedList[key].url);
                        if (key === 'followUpLabels') {
                            clist.items = data.map(followupLabel => {
                                return {
                                    ...followupLabel,
                                    BackColor: `#${followupLabel.color}`,
                                    ForeColor: GetForeColor(FollowupColorsList, `#${followupLabel.color}`)
                                }
                            })
                        } else {
                            clist.items = data;
                        }
                    }
                    clist.isLoaded = true;
                    clist.fetchError = null;

                    ls.set(`nw_cachedlist_${key}`, clist.items);
                    ls.set(`nw_cachedlist_${key}_lastupdate`, GetTimestampNumeric());

                    resolve({ key, data: clist });

                } catch (error) {
                    let clist = { ...this.state.cachedList[key] };
                    clist.items = [];
                    clist.isLoaded = false;
                    clist.fetchError = error;
                    reject({ key, data: clist });
                }
            }
        });

    updateCachedList = key => {
        ls.remove(`nw_cachedlist_${key}`)
        this.loadSingleCachedList(key)
            .then(result => {
                this.setState(() => ({
                    cachedList: { ...this.state.cachedList, [key]: result.data }
                }));
            })
            .catch(error => console.log("ERROR", error));
    };

    updateCachedListwithStatic = (key, data) => {
        this.setState({
            cachedList: {
                ...this.state.cachedList, [key]: {
                    ...this.state.cachedList[key],
                    items: data
                }
            }
        });
        ls.remove(`nw_cachedlist_${key}`)
        ls.set(`nw_cachedlist_${key}`, data);
        ls.set(`nw_cachedlist_${key}_lastupdate`, GetTimestampNumeric());
    }

    getCountry = code => {
        return this.state.cachedList.countries.items.find(country => country.Code === code);
    };

    getCity = cityName => {
        return this.state.cachedList.cities.items.find(city => city.Name === cityName);
    };

    getUserName = userID => {
        const user = this.state.cachedList.users.items.find(user => user.ID === userID);
        let un = ""
        if (user) {
            un += user.Name
            if (user.Surname) {
                un += " " + user.Surname;
            }
        }
        return un;
    };

    getUserNameShort = userID => {
        const user = this.state.cachedList.users.items.find(user => user.ID === userID);
        let un = ""
        if (user) {
            un += user.Name
            if (user.Surname && user.Surname.trim().length > 0) {
                un += " " + user.Surname.substring(0, 1) + ".";
            }
        }
        return un;
    };

    getCategoryName = categoryID => {
        return this.state.cachedList.eventCategories.items.find(category => category.ID === categoryID).Name
    };

    getTagName = tagId => {
        return [...this.state.cachedList.publictags.items, ...this.state.cachedList.privatetags.items].find(tag => tag.ID === tagId).Name
    }

    getEventClientRole = roleId => {
        const role = this.state.cachedList.eventClientRoles.items.find(role => role.ID === roleId)
        return role && role.Name
    }

    getFamilyName = (code) => {
        const family = this.state.cachedList.transactionFamilies.items.find(tf => tf.Code === code)
        if (family) {
            return family.Name
        }
        return ""
    }

    getTransactionLabelName = (transactionFamily, transactionLabelID) => {
        const family = this.state.cachedList.transactionFamilies.items.find(tf => tf.Code === transactionFamily)
        if (family && family.TransactionLabels) {
            const label = family.TransactionLabels.find(tl => tl.ID === transactionLabelID)
            if (label) {
                return label.Label
            }
        }
        return ""
    }

    render() {
        return (
            <ListContext.Provider value={this.state}>
                {this.props.children}
            </ListContext.Provider>
        );
    }
}

export { ListContext, ListContextProvider, ListContextConsumer };
