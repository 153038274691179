import React, { useState } from "react";
import styled from "styled-components";
import NwIcon from "./NwIcon";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwLink from "./NwLink";

const CopyTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .25rem 1rem;
    font-size: 90%;
    background: rgba(150, 150, 150, 0.06);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-bottom-width: 2px;
    border-radius: 3px;
    font-family: ${props => props.theme.FontFamilyMonospace};
    width: 100%;
    color: ${props => props.theme.TextColor};
    opacity: 1;

    .copy-text-container-text {
        p {
            display: block;
            font-family: ${props => props.theme.FontFamilyMonospace};
            margin-bottom: .25rem;
        }
        small {
            display: block;
        }
    }
`

const NwClipboardCopyText = ({ text, label, asLink }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(text)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 3500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        asLink
            ?
            <NwLink onClick={handleCopyClick}>
                <NwIcon icon={isCopied ? light('clipboard-check') : light('clipboard')} />
                {label ? label : text}
            </NwLink>
            :
            <CopyTextContainer className="copy-text-container">
                <div className="copy-text-container-text">
                    <p>{label ? label : text}</p>
                    {isCopied && <small><NwIcon icon={light('check')} /> Copied!</small>}
                </div>
                <NwIcon pointer size="2x" icon={isCopied ? light('clipboard-check') : light('clipboard')} onClick={handleCopyClick} />
            </CopyTextContainer>
    );
}

export default NwClipboardCopyText;