import React, { useContext, useMemo, useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import NwDrawer from 'Components/Gui/NwDrawer';
import { NWTextAreaCharCounter, NWEventDateTimePicker, NWInput, NWContactSelector, NWSelect } from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { validateDates } from 'Libs/NwUtils';
import { ListContext } from 'Contexts/ListContext';
import { useProject, useUpdateProject, useUpdateProjectCustomFields } from 'Hooks/Project/UseProject';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { getCustomFieldInitialValue, getEmbeddedCustomField } from 'Libs/NwCustomFields';
import { toISODate } from 'Libs/NwMoment';
import { Alert } from 'antd';

const getDefaultDate = (date, provisionalDate) => {
    if (date) {
        return getMoment(date);
    }
    if (provisionalDate) {
        return getMoment(provisionalDate);
    }
    return null;
};

const ProjectEditDrawer = ({ projectId, customFields, onClose }) => {
    useFormFieldFocus('Name');
    const { data: currentProject } = useProject(projectId);
    const { mutateAsync: onUpdateProject } = useUpdateProject();
    const { mutateAsync: onUpdateProjectCustomFields } = useUpdateProjectCustomFields();
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const [errorSaving, setErrorSaving] = useState(null);

    let initialValues = {
        Name: currentProject.Name,
        Description: currentProject.Description,
        Product: currentProject.Product,
        CustomerID: currentProject.CustomerID,
        ProjectCategoryID: currentProject.ProjectCategoryID,
        // Period: {
        //     startdate: null,
        //     enddate: null
        // },
        CustomFields: {}
    };

    const availableCustomFields = customFields ? customFields.filter(field => !field.Disabled) : [];

    availableCustomFields.map(customField => {
        if (!customField.Disabled) {
            initialValues = {
                ...initialValues,
                CustomFields: {
                    ...initialValues.CustomFields,
                    [customField.Name]: getCustomFieldInitialValue(customField.TypeName, currentProject.CustomFields[customField.Name])
                }
            };
        }

        return null;
    })

    const saveProject = async (values, setSubmitting) => {
        const isCustomFieldsTouched = JSON.stringify(initialValues.CustomFields) !== JSON.stringify(values.CustomFields);

        // const startDate = toISODate(getDefaultDate(currentProject.FirstDate, currentProject.PrevisionalFirstDate))
        // const endDate = toISODate(getDefaultDate(currentProject.LastDate, currentProject.PrevisionalLastDate))

        //if (JSON.stringify({ ...initialValues, CustomFields: {}, Period: {} }) !== JSON.stringify({ ...values, CustomFields: {}, Period: {} }) ||
        //    (startDate !== toISODate(values.Period.startdate) || endDate !== toISODate(values.Period.enddate))) {
        
        const somethingChanged = JSON.stringify({ ...initialValues, CustomFields: {} }) !== JSON.stringify({ ...values, CustomFields: {} });

        if (somethingChanged) {
            const projectData = [
                { name: 'Name', value: values.Name, changed: true, errormessage: '', updated: true },
                { name: 'Product', value: values.Product, changed: true, errormessage: '', updated: true },
                { name: 'Description', value: values.Description, changed: true, errormessage: '', updated: true },
                { name: 'CustomerID', value: values.CustomerID, changed: true, errormessage: '', updated: true },
                { name: 'ProjectCategoryID', value: values.ProjectCategoryID, changed: true, errormessage: '', updated: true },
                // { name: 'PrevisionalFirstDate', value: values.Period.startdate, changed: true, errormessage: '', updated: true },
                // { name: 'PrevisionalLastDate', value: values.Period.enddate, changed: true, errormessage: '', updated: true },
            ];

            // setSubmitting(true);
            try {
                await onUpdateProject({ projectId: projectId, data: projectData });
                if (!isCustomFieldsTouched) {
                    setTouchForm(false);
                    onClose();
                }
                // setSubmitting(false);
            } catch (error) {
                setErrorSaving("There was an error saving the project. Please try again.");
                setSubmitting(false);
            }
        }

        if (isCustomFieldsTouched) {
            let customFieldsData = {};
            Object.keys(values.CustomFields).map(key => {
                if (values.CustomFields[key] !== '' && JSON.stringify(values.CustomFields[key]) !== JSON.stringify([]) && values.CustomFields[key]) {
                    const customField = availableCustomFields.find(item => item.Name === key);
                    if (customField.TypeName === 'MultipleChoice') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key].join(',')
                        }
                    } else if (customField.TypeName === 'Date') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: toISODate(values.CustomFields[key])
                        }
                    } else {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key]
                        }
                    }
                }
                return null;
            })

            try {
                await onUpdateProjectCustomFields({ projectId: projectId, data: customFieldsData });
                setTouchForm(false);
                onClose();
            } catch (error) {
                setErrorSaving("There was an error saving the project. Please try again.");
            }
        }
        if (!somethingChanged && !isCustomFieldsTouched) {
            setErrorSaving("No changes to save.");
            setTouchForm(false);
            setSubmitting(false);
        }
        
    };

    const categories = useMemo(() => {
        let categories = [...cachedList.projectCategories.items];
        categories.sort((cat1, cat2) => cat1.Name.toLowerCase() > cat2.Name.toLowerCase() ? 1 : -1);
        return categories;
    }, [cachedList.projectCategories.items]);

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title={'Edit Project'}
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveProject(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Content is required"),
                    CustomerID: Yup.string().required("Content is required")
                })}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm
                        values={values}
                        //disabledFields={['Period']}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            required
                            component={NWContactSelector}
                            activeTypes={['cli', 'age', 'srv']}
                            activableTypes={[]}
                            label="Customer"
                            name="CustomerID"
                            value={values.CustomerID}
                        />
                        <Field
                            component={NWInput}
                            label="Title"
                            name="Name"
                            type="text"
                            id='Name'
                            value={values.Name}
                            required
                        />
                        <Field
                            component={NWInput}
                            label="Product"
                            name="Product"
                            type="text"
                            id='Product'
                            value={values.Product}
                        />
                        <Field
                            component={NWSelect}
                            label="Category"
                            name="ProjectCategoryID"
                            placeholder='Select a category'
                            value={values.ProjectCategoryID}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            options={
                                categories.map(category => {
                                    return { key: category.ID, label: category.Name, value: category.ID };
                                })}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Description"
                            name="Description"
                            type="text"
                            value={values.Description}
                            maxLength={2000}
                            autoSize={{ minRows: 5, maxRows: 10 }}
                        />
                        {/* <Field
                            component={NWEventDateTimePicker}
                            label="Period"
                            name="Period"
                            value={values.Period}
                            eventMode='period-notime'
                            defaultStartDate={getDefaultDate(currentProject.FirstDate, currentProject.PrevisionalFirstDate)}
                            defaultEndDate={getDefaultDate(currentProject.LastDate, currentProject.PrevisionalLastDate)}
                            validate={validateDates}
                            required
                        /> */}
                        {availableCustomFields.map((customField, index) => {
                            return <div key={`${customField.Name}-${index}`}>
                                {getEmbeddedCustomField(values, customField)}
                            </div>
                        })}
                        {errorSaving &&
                            <Alert style={{marginTop: "1rem"}} message="Error" description={errorSaving} type="error" showIcon />
                        }
                        <br/>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
};

export default ProjectEditDrawer;