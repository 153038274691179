import { useState } from "react";
import ls from 'local-storage';
import _ from "lodash";

const useLocalStorageState = (key, defVal) => {
    //This Hook expose methods to persist state in local storage
    //it should be used instead of useState whenever the state need to be persisted in the local storage 
    //It accept the key for the local storage and an optional default value
    //It returns the current value and the set function to change the value.

	//default prefix for local storage key
	const prefix = "nw_config_"
	
	//executed once and than on every key changes
	//read from the local storage the passed key and if exist save it into local storage
	const getFromLocalStorageOrSetDefault = () => {
		const st = ls.get(`${prefix}${key}`);
		if (!_.isUndefined(st) && !_.isNull(st)) {
			return st
		}
        return defVal
	}
	
	//the value is persisted in local state and initialized as default value or null
	const [value,setValue] = useState(() => getFromLocalStorageOrSetDefault())

	//This is the function returned to set the value with the initial S uppercase
	//not the default setValue used internally for the state
	const SetValue = (newVal) => {       
		ls.set(`${prefix}${key}`, newVal)
		setValue(newVal)
	}
	
	return [value, SetValue]
}

export default useLocalStorageState