import React from 'react';
import { Select,  Badge } from 'antd';
import styled from 'styled-components';

const ColorItem = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled.div`
    margin-left: 8px;
`;

const { Option } = Select;

const COLOR_TYPES = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime'
];

const ColorSelector = ({ value, onChange }) => {
    return (
        <Select
            autoComplete='off'
            showSearch={false}
            value={value}
            placeholder="Select a color"
            filterOption={false}
            onChange={onChange}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        >
            {COLOR_TYPES.map(item => {
                return (
                    <Option value={item} key={item}>
                        <ColorItem>
                            <Badge color={item} />
                            <Label>{item}</Label>
                        </ColorItem>
                    </Option>
                );
            })}
        </Select>
    );
};

export default ColorSelector;