import React, { useContext, useMemo } from "react";
import { Tabs } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import Axios from "axios";

import FeaturesGroup from "Components/Features/FeaturesGroup";
import NwDrawer from "Components/Gui/NwDrawer";
import DraggableTabs from 'Components/Gui/DraggableTabs';
import { useContact, forceReloadContact } from 'Hooks/Contact/UseContact';
import { useContactFeatures } from 'Hooks/Contact/UseContactFeatures';
import { forceReloadModels, forceReloadModelsWall, getModel } from 'Hooks/Contact/UseContactsList';
import { useGlobalFeatureSets } from 'Hooks/Features/UseFeatures';
import { ListContext } from 'Contexts/ListContext';
import { ConfigContext } from 'Contexts/ConfigContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { Loading } from "Components/Gui/Loading";
import { FeatureSetContainer } from "./FeaturesUI";


const { TabPane } = Tabs;

const FeatureSet = ({ featureSet, contactFeatures, readOnly, currentContact }) => {
    return featureSet.FeatureGroups.map((featureGroup, i) => {
        return (
            <FeaturesGroup
                key={featureGroup.ID}
                readOnly={readOnly}
                featuresGroup={featureGroup}
                contactFeatures={contactFeatures}
                contactID={currentContact.ID}
                contactType={currentContact.ContactType} />
        )
    });
};

export const Features = ({ contactID, readOnly }) => {
    const { data: currentContact } = useContact(contactID);
    const { data: contactFeatures } = useContactFeatures(contactID);
    const { data: globalFeatureSets, isFetching: fetchingGlobalFeatureSets } = useGlobalFeatureSets();
    const { cachedList } = useContext(ListContext);
    const { userGuiConfig, updateUserConfig } = useContext(ConfigContext);

    const departments = useMemo(() => {
        const departments = [];
        if (currentContact) {
            for (const accountingGroup of cachedList.accountingGroups.items) {
                for (const networkAgency of accountingGroup.NetworkAgencies) {
                    for (const department of networkAgency.Departments) {
                        if (currentContact.Departments.find(item => item.DepartmentID === department.ID)) {
                            departments.push(department);
                        }
                    }
                }
            }
        }
        return departments;
    }, [cachedList, currentContact]);

    const availableFeatures = useMemo(() => {
        if (!fetchingGlobalFeatureSets) {
            const featureSets = [];
            for (const department of departments) {
                for (const featureSetId of department.FeatureSets) {
                    if (!featureSets.find(featureSet => featureSet.ID === featureSetId)) {
                        const featureSet = globalFeatureSets && globalFeatureSets.find(featureSet => featureSet.ID === featureSetId);
                        featureSets.push(featureSet);
                    }
                }
            }
            return featureSets;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments, globalFeatureSets]);

    const handleSetFeatureOrders = orders => {
        updateUserConfig({
            ...userGuiConfig,
            featureTabsOrders: {
                ...userGuiConfig.featureTabsOrders,
                [contactID]: orders
            }
        });
    }

    const contactFeatureTabOrders = userGuiConfig && userGuiConfig.featureTabsOrders && userGuiConfig.featureTabsOrders[contactID] ?
        userGuiConfig.featureTabsOrders[contactID] : [];

    if (contactFeatures && !fetchingGlobalFeatureSets && availableFeatures) {
        if (availableFeatures.length > 1) {
            return (
                <DraggableTabs orders={contactFeatureTabOrders} onUpdateOrders={!readOnly && handleSetFeatureOrders}>
                    {availableFeatures.map(featureSet => (
                        <TabPane tab={featureSet.Name} key={featureSet.ID}>
                            <FeatureSetContainer>
                                <FeatureSet
                                    readOnly={readOnly}
                                    contactFeatures={contactFeatures}
                                    featureSet={featureSet}
                                    currentContact={currentContact} />
                            </FeatureSetContainer>
                        </TabPane>
                    ))}
                </DraggableTabs>
            )
        } else {
            const featureSet = availableFeatures[0]
            return (
                <FeatureSetContainer>
                    <FeatureSet
                        readOnly={readOnly}
                        contactFeatures={contactFeatures}
                        featureSet={featureSet}
                        currentContact={currentContact} />
                </FeatureSetContainer>
            )
        }
    } else {
        return (<Loading />)
    }
}

const FeaturesDrawer = ({ contactID, readOnly, onClose }) => {
    const breakpoints = useNwBreakPoints();
    //const queryClient = useQueryClient();

    const updateModel = async () => {
        forceReloadContact(contactID);
        const updatedSimpleContact = await Axios.get(`/models/simple/${contactID}`);
        const modelToUpdate = getModel(contactID);
        modelToUpdate.F = updatedSimpleContact.F;
    }

    return (
        <NwDrawer
            padding="0"
            whiteBg={breakpoints === 1}
            onClose={async() => {
                await updateModel();
                onClose();
            }}
            title="Contact features">
            <div className='drawer-content standard'>
                <Features readOnly={readOnly} contactID={contactID} />
            </div>
        </NwDrawer>
    );
};

export default FeaturesDrawer;
