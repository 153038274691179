import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ProjectSelector from 'Components/ProjectSelector/ProjectSelector';
import TransactionItemDetailForm from 'Components/Transactions/TransactionsDrawer/TransactionDetailForms/TransactionItemDetailForm';
import TransactionAmount from 'Components/Transactions/TransactionsDrawer/TransactionAmount';
import TransactionStatementDetails from 'Components/Transactions/TransactionsDrawer/TransactionStatementDetails';
import { Loading } from 'Components/Gui/Loading';
import NwIcon from 'Components/Gui/NwIcon';
import { useStateValue } from 'Contexts/StateProvider';
import { useProject, onGetOrderedEventList } from 'Hooks/Project/UseProject';
import { useTransaction } from 'Hooks/Transaction/UseTransaction';
import NwDivider from "Components/Gui/NwDivider";
import ServerError from "Components/Gui/ServerError";
import { isLocked } from "../TransactionsUtils";
import { Alert } from "antd";
import { orderBy } from "lodash";

const BackContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
`;

const BackIconStyle = {
    marginRight: 8
};


const TransactionDetails = ({ transaction, projectId, onUpdateTransaction, onBack, onTouchForm, onTransactionRemoved, hideProject }) => {
    const [{ areaContext }] = useStateValue()
    const { data: transactionData, isFetching: loadingTransaction, isError, error } = useTransaction(transaction.ID, transaction.Family)
    const [transactionLockStatus, setTransactionLockStatus] = useState(() => () => ({ locked: false, message: '' }));
    const { data: currentProject, isFetching: loadingProject } = useProject(projectId)
    
    useEffect(() => {
        if (currentProject) {
            onGetOrderedEventList(currentProject, areaContext)
        }
    }, [currentProject])

    useEffect(() => {
        if (transactionData) {
            setTransactionLockStatus(isLocked(transactionData));
        }
    }, [transactionData]);

    return (loadingProject || loadingTransaction ?
        <Loading textBlack />
        :
        (isError ?
            <ServerError error={error} />
            :
            <>
                {onBack && <BackContainer onClick={onBack}>
                    <NwIcon icon={light('arrow-left')} style={BackIconStyle} />Back
                </BackContainer>}
                {!hideProject && <ProjectSelector readOnly value={projectId} onChange />}
                {transactionLockStatus && transactionLockStatus.locked &&
                <Alert message={transactionLockStatus.message} type="warning" showIcon />
                }
                <TransactionItemDetailForm
                    transaction={transactionData}
                    onUpdateTransaction={onUpdateTransaction}
                    onTouchForm={onTouchForm}
                    onTransactionRemoved={onTransactionRemoved} 
                />
                <TransactionAmount
                    transaction={transactionData}
                    onUpdateTransaction={onUpdateTransaction}
                    onTouchForm={onTouchForm} />
                {transactionData.ClientStatements.length > 0 &&
                    <div className='transaction-statement-container'>
                        <NwDivider textSize="medium" icon={light("address-card")} dark>client</NwDivider>
                        <TransactionStatementDetails
                            transaction={transactionData}
                            transactionId={transactionData.ID}
                            type='client'
                            statements={transactionData.ClientStatements}
                            onUpdateTransaction={onUpdateTransaction}
                            onTouchForm={onTouchForm} />
                    </div>
                }
                {transactionData.ModelStatements.length > 0 &&
                    <div className='transaction-statement-container'>
                        <NwDivider textSize="medium" icon={light("address-card")} dark>model</NwDivider>
                        <TransactionStatementDetails
                            transaction={transactionData}
                            transactionId={transactionData.ID}
                            type='model'
                            statements={transactionData.ModelStatements}
                            onUpdateTransaction={onUpdateTransaction}
                            onTouchForm={onTouchForm} />
                    </div>
                }
                {transactionData.AgencyStatements.length > 0 &&
                    <div className='transaction-statement-container'>
                        <NwDivider textSize="medium" icon={light("globe")} dark>agency</NwDivider>
                        {orderBy(transactionData.AgencyStatements, 'amount').reverse().map((statement, index) => (
                            <TransactionStatementDetails
                                transaction={transactionData}
                                transactionId={transactionData.ID}
                                type='agency'
                                statements={[statement]}
                                onUpdateTransaction={onUpdateTransaction}
                                onTouchForm={onTouchForm} />
                        ))}
                    </div>
                }
            </>
        )
    );
};

export default TransactionDetails;
