import React from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { showDateNoTime } from 'Libs/NwMoment';
import useCountryName from 'Hooks/UseCountryName';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";

const AccommodationDetails = ({ type, accommodationData }) => {
    
    const countryName = useCountryName(accommodationData && accommodationData.Country);

    const ComposeAddressForMaps = () => {
        const addressString = [accommodationData.Street,
        accommodationData.City,
        accommodationData.Zip,
        accommodationData.State,
            countryName].filter(Boolean).join("+");
        return "https://www.google.com/maps/place/" + encodeURIComponent(addressString)
    }

    const showMap = event => {
        event.stopPropagation();
        window.open(ComposeAddressForMaps())
    }

    return (
        <EventDetailsWrapper>
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='is-icon'>
                                <NwIcon icon={type === 'RoomLodging' ? light('house-user') : light('bed')} />
                            </div>
                        ),
                        isBorder: true,
                        width: "30%"
                    },
                    {
                        content: (
                            <>
                                {accommodationData.Service && accommodationData.Service.Name &&
                                    <div className='is-content is-stronger big'>{accommodationData.Service.Name}</div>
                                }
                                <div className='address-container' onClick={showMap}>
                                    {accommodationData.Street && <div>{accommodationData.Street}</div>}
                                    <FlexContainer>
                                        {accommodationData.Zip && <div>{accommodationData.Zip},</div>}&nbsp;
                                        {accommodationData.State && <div>{accommodationData.State}</div>}
                                    </FlexContainer>
                                    {accommodationData.City} ({countryName})
                                </div>
                                <FlexContainer mg='8px 0 0'>
                                    <div>
                                        <div className='is-label'>CHECK IN</div>
                                        <div className='is-content date-time'>{showDateNoTime(accommodationData.FromDate)}</div>
                                    </div>
                                    <NwIcon className='arrow-icon' icon={light('arrow-right')} />
                                    <div>
                                        <div className='is-label'>Check Out</div>
                                        <div className='is-content date-time'>{showDateNoTime(accommodationData.ToDate)}</div>
                                    </div>
                                </FlexContainer>
                            </>
                        ),
                        isBorder: false
                    },
                ]}
            />
            {!accommodationData.GroupGuid &&
                <>
                    <ResponsiveFlexContainer
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Reservation Code</p>
                                ),
                                isLabel: true,
                                isBorder: true,
                                width: "30%"
                            },
                            {
                                content: (
                                    <p className="is-content">{accommodationData.ReservationCode}</p>
                                ),
                                isContent: true,
                                isBorder: false
                            },
                        ]}
                    />
                    <ResponsiveFlexContainer
                        columns={[
                            {
                                content: (
                                    <p className='is-label'>Note</p>
                                ),
                                isBorder: true,
                                width: "30%"
                            },
                            {
                                content: (
                                    <div className='is-content'>
                                        <NwEllipsis
                                            rows={3}
                                            text={accommodationData.Note} />
                                    </div>
                                ),
                                isBorder: false
                            },
                        ]}
                    />
                </>
            }
        </EventDetailsWrapper>
    );
};

export default AccommodationDetails;