import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { forceReloadProject } from 'Hooks/Project/UseProject';

const getProjectModels = projectId => (
    Axios.get(`/projectmodels/GetForProject/${projectId}`)
)

const useProjectModels = projectId => {
    return useQuery(["projectModels", projectId], () => getProjectModels(projectId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: projectId ? true : false
    });
};

const onAddProjectModel = ({ modelData }) => (
    Axios.post('/projectmodels/AddPrevisional', modelData)
)

const useAddProjectPrevisionalModel = () => {
    return useMutation(onAddProjectModel, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.modelData.ProjectID);
        }
    })
};

const onUpdateProjectModel = ({ modelData }) => (
    Axios.put('/projectmodels', modelData)
)

const useUpdateProjectModel = () => {
    return useMutation(onUpdateProjectModel, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.modelData.ProjectID);
        }
    })
};

const onDeleteProjectModel = ({ ID }) => (
    Axios.delete(`/projectmodels/${ID}`)
)

const useDeleteProjectModel = () => {
    return useMutation(onDeleteProjectModel, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
};

export {
    useProjectModels,
    useAddProjectPrevisionalModel,
    useUpdateProjectModel,
    useDeleteProjectModel
};