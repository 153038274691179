import React, { useState, useEffect } from 'react';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { Switch } from 'antd';

import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import TimePicker from 'Components/EventDateTimePicker/TimePicker';
import { getTime, timeStringToArray, timeArrayToString, getNow } from 'Libs/NwMoment';


const DateSelectorContainer = styled.div`
    display: flex;

    .date-selector-label {
        min-width: ${props => props.$labelMinWidth ? `${props.$labelMinWidth}px` : '4rem'};
        margin-right: 8px;
    }
`

const DateSelector = ({ value, onChange, text, timeOnly, dateOnly, disable, enabled, beforeTime, beforeDate, labelMinWidth, onDisable }) => {
    const [currentDate, setCurrentDate] = useState(value ? value : getNow().endOf('day'))
    const [currentTime, setCurrentTime] = useState(getTime(value ? value : getNow().endOf('day')));
    
    useEffect(() => {
        setCurrentDate(value ? value : getNow().endOf('day'));
        setCurrentTime(value ? getTime(value) : [23, 59]);
    }, [value]);

    const handleChangeDate = date => {
        setCurrentDate(date);
        onChange(getMoment(date).hour(currentTime[0]).minute(currentTime[1]))
    }
    const handleChangeTime = time => {
        setCurrentTime(timeStringToArray(time))
        onChange(
            getMoment(currentDate).hour(timeStringToArray(time)[0]).minute(timeStringToArray(time)[1])
        )
    }

    const disabledEndDate = date => {
        if (beforeDate) {
            const days = date.clone().startOf('day').diff(getMoment(beforeDate).startOf('day'), 'days');
            if (days >= 0 && days <= 100) {
                return false;
            }
            return true;
        }
        return false;
    }

    return (
        <DateSelectorContainer $labelMinWidth={labelMinWidth}>
            {disable && 
                <>
                    <Switch checked={enabled} onChange={onDisable} />
                    &nbsp;
                </>
            }
            <div className="date-selector-label">{text}</div>
            {!timeOnly && 
                <CustomDatePicker
                    label='date'
                    name='date'
                    value={currentDate}
                    disabledRange={disabledEndDate}
                    disabled={disable && !enabled}
                    defaultDate={currentDate}
                    onChange={handleChangeDate} />
            }
            {!dateOnly && 
                <TimePicker 
                    label='time' 
                    name='time' 
                    disabled={disable && !enabled}
                    beforeTime={beforeTime}
                    value={timeArrayToString(currentTime)} 
                    onChange={handleChangeTime} />
            }
        </DateSelectorContainer>
    );
};

export default DateSelector;