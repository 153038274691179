import React, { useState } from "react";

import AddressItem from "Components/Connection/AddressItem";
import AddressForm from "Components/Connection/AddressForm";
import EmailItem from "Components/Connection/EmailItem";
import EmailForm from "Components/Connection/EmailForm";
import PhoneItem from "Components/Connection/PhoneItem";
import PhoneForm from "Components/Connection/PhoneForm";
import NwDrawer from 'Components/Gui/NwDrawer';

import { useDeleteContactConnectionItem } from "Hooks/Contact/UseContactConnections";

const BaseItem = ({ item, type, readOnly, noMainIcon, connectionID, contactID, onAddToMailingList }) => {
    const [mode, setMode] = useState("SHOW");
    const [touchForm, setTouchForm] = useState(false);
    
    const { mutate: deleteItem } = useDeleteContactConnectionItem()
        
    const deleteElement = () => {
        deleteItem({itemType: type, itemID: item.ID, contactID: contactID})
    };

    const onEdit = () => {
        setMode("EDIT")
    }

    const onDelete = () => {
        deleteElement()
    }

    const renderForm = () => {
        switch (type) {
            case "address":
                return (
                    <AddressForm
                        address={item}
                        contactID={contactID}
                        connectionID={connectionID}
                        onCancel={() => setMode("SHOW")}
                        onSave={() => setMode("SHOW")}
                        onTouchForm={setTouchForm}
                    />
                );
            case "email":
                return (
                    <EmailForm
                        email={item}
                        contactID={contactID}
                        connectionID={connectionID}
                        onCancel={() => setMode("SHOW")}
                        onSave={() => setMode("SHOW")}
                        onTouchForm={setTouchForm}
                    />
                );
            case "phone":
                return (
                    <PhoneForm
                        phone={item}
                        contactID={contactID}
                        connectionID={connectionID}
                        onCancel={() => setMode("SHOW")}
                        onSave={() => setMode("SHOW")}
                        onTouchForm={setTouchForm}
                    />
                );
            default:
                return null;
        }
    };

    const renderItem = () => {
        switch (type) {
            case "address":
                return <AddressItem contactID={contactID} address={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} />;
            case "email":
                return <EmailItem contactID={contactID} email={item} readOnly={readOnly} noMainIcon={noMainIcon} onEdit={onEdit} onDelete={onDelete} onAddToMailingList={onAddToMailingList} />;
            case "phone":
                return <PhoneItem contactID={contactID} phone={item} readOnly={readOnly} onEdit={onEdit} onDelete={onDelete} />;
            default:
                return null;
        }
    };

    return (
        <>
            {renderItem()}
            {mode === "EDIT" && 
                <NwDrawer 
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title={'Edit'} 
                    onClose={() => setMode("SHOW")}>
                    {renderForm()}
                </NwDrawer>
            }
        </>
    );
};

export default BaseItem;
