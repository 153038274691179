export const PACKAGES_FILTERS_SET = {
    activeSet: "v1",
    customSets: [],
    filterType: ("SAVED_SEARCH"),
    staticSets: [
        {
            id: "v1",
            name: "Mine - Last 30 days",
            fixed: true,
            filters: [
                { name: "userID", value: "{me}"},
                { name: "DatePeriod", value: "{last30days}"},
            ]
        }
    ]
}