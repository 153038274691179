import React, { useState, useEffect } from 'react';
import { Input, Row } from 'antd';
import styled from 'styled-components';

import { NwAddButton } from 'Components/Gui/NwButton';

const StyledInput = styled(Input)`
  margin-bottom: 4px;
`;

const MultiChoices = ({ value, defaultChoices, onChange }) => {
  const [inputValues, setInputValues] = useState(new Array(3).fill(''));

  useEffect(() => {
    if (defaultChoices) {
      let newValues = new Array(defaultChoices.length > 3 ? defaultChoices.length : 3).fill('');
      defaultChoices.map((item, index) => {
        newValues[index] = item;
        return null;
      })
      setInputValues(newValues);
    }
  }, [defaultChoices]);

  const handleInputChange = index => event => {
    let newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
    onChange(newInputValues);
  };

  const handleAddChoiceInput = () => {
    setInputValues([...inputValues, '']);
    onChange([...inputValues, '']);
  };

  return (
    <>
      {inputValues.map((inputValue, index) => {
        return (
          <StyledInput 
            autoComplete='off'
            key={`multi-choice-value-${index}`} 
            value={inputValue} 
            placeholder='Value'
            onChange={handleInputChange(index)} />
        )
      })}
      <Row justify='end'>
        <NwAddButton label='Add More' onClick={handleAddChoiceInput} />
      </Row>
    </>
  )
};

export default MultiChoices;