import React, { useState } from 'react'
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButtonGroup } from 'Components/Gui/NwButton'
import { getMoment, getNow, showDate } from 'Libs/NwMoment';
import { Segmented } from 'antd';
import ContactAvatar from 'Components/ContactAvatarGroup/ContactAvatar';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import NwIcon from 'Components/Gui/NwIcon';
import { isCurrentPeriod } from '../ScheduleUtils';
import BirthdaysDrawer from 'Components/Birthdays/BirthdaysDrawer';
import { useAgendaContext } from './AgendaContext';


const BookingAgendaHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content:space-between;
    background: white;
    z-index: 4;
    width: 100%;
    border-bottom: ${props => props.theme.LighterBorder};
    gap: 1rem;

    @media (max-width: 720px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;
    }

    div.agenda-header-left {
        padding-left: 1rem;

        @media (max-width: 720px) {
            padding-left: 0;
        }

        &.agenda-header-left-model {
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .agenda-header-left-back {
                font-size: 2rem;
                border-right: ${props => props.theme.DefaultBorder};
                padding: 0 1rem;
                margin-right: 1rem;
            }

            .agenda-header-left-avatar {
                padding-right: 1rem;
            }
        }

        h2 {
            font-size: 1.25rem;
            margin: 0;
            padding: 0;
            font-weight: bold;
        }
        h3 {
            font-size: .75rem;
            text-transform: uppercase;
            font-weight: normal;
            margin: 0;
            padding: 0;
            line-height: 1.1em;
        }
    }

    div.agenda-header-middle {
        .agenda-header-birthdays {
            cursor: pointer;

            &.no-birthdays {
                color: ${props => props.theme.TextColorMuted};
                cursor: default;
            }
        }
    }
    
    div.agenda-header-right {
        display: flex;
        align-items: center;
        justify-content:flex-end;
        padding: 1rem 0;
        font-size: 1rem;
        
        div.calendar-type-selector-container {
            width: 15rem;
        }
    }
    div.date-navigator-container {
        width: 12rem;
        @media (max-width: 720px) {
            width: 100%;
        }
    }
`;


const ModelAgendaHeaderContainer = styled.div`
    height: auto;
    border-bottom: ${props => props.theme.LighterBorder};
    padding-left: 2rem;

    .agenda-header-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 1rem;
        width: 100%;
    }

    .agenda-header-bottom {
        width:  100%;
        padding-bottom: .5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 3.2rem;

        h1 {
            font-size: 1.1rem;
            margin: 0;
        }
    }
`

const AgendaHeader = ({
    isMobile,
    onChangeCalendarStyle,
}) => {

    const {
        bookingSection,
        contactId,
        dayBirthDays,
        onClearContactID,
        onSelectDate,
        previewMode,
        scheduleRange,
    } = useAgendaContext();

    const dateSelected = scheduleRange ? getMoment(scheduleRange.start) : getNow();
    
    const selectToday = () => {
        onSelectDate(getNow())
    }

    const selectDayAfter = () => {
        const newdate = dateSelected.clone();
        newdate.add(1, 'days');
        onSelectDate(newdate)
    }

    const selectDayBefore = () => {
        const newdate = dateSelected.clone();
        newdate.subtract(1, 'days');
        onSelectDate(newdate)
    }

    const [showBirthdays, setShowBirthdays] = useState(false)
    const onBirthdayIconClick = () => {
        setShowBirthdays(true)
    }
    const onCloseBirthdayDrawer = () => {
        setShowBirthdays(false)
    }

    if (bookingSection) {
        return (
            <>
                <BookingAgendaHeaderContainer className="booking-agenda-header-container">
                    {isMobile &&
                        <div className='date-navigator-container'>
                            <NwButtonGroup
                                capitalize
                                size="small"
                                right={isMobile}
                                buttons={[
                                    { action: selectDayBefore, icon: light("chevron-left") },
                                    { action: selectToday, label: "Today", disabled: isCurrentPeriod(dateSelected.toDate(), "agenda") },
                                    { action: selectDayAfter, icon: light("chevron-right") },
                                ]}
                            />
                        </div>
                    }
                    {(contactId && bookingSection)
                        ?
                        <div className="agenda-header-left agenda-header-left-model">
                            <div className="agenda-header-left-back">
                                <NwIcon pointer icon={light('arrow-left-to-line')} onClick={onClearContactID} />
                            </div>
                            <div className="agenda-header-left-avatar">
                                <ContactAvatar contactID={contactId} />
                            </div>
                            <div>
                                <h3>{getContactName(contactId)}</h3>
                                <h2>{showDate(dateSelected, "ext-notime")}</h2>
                            </div>
                        </div>
                        :
                        <div className="agenda-header-left">
                            {!contactId && <h3>agency calendar</h3>}
                            <h2>{showDate(dateSelected, "ext-notime")}</h2>
                        </div>
                    }
                    <div className="agenda-header-middle">
                        {contactId === null &&
                            (dayBirthDays.length > 0
                                ?
                            <div className="agenda-header-birthdays" onClick={onBirthdayIconClick}>
                                <NwIcon icon={light("birthday-cake")} />
                                &nbsp;&nbsp;
                                <span>{dayBirthDays.length} Birthday{dayBirthDays.length > 1 ? "s" : ""}</span>
                            </div>
                            :
                            <div className="agenda-header-birthdays no-birthdays">
                                <NwIcon icon={light("birthday-cake")} />
                                &nbsp;&nbsp;
                                <span>No birthdays</span>
                            </div>
                            )
                        }
                    </div>

                    {!isMobile &&
                        <div className="agenda-header-right">
                            <div className='date-navigator-container'>
                                <NwButtonGroup
                                    capitalize
                                    size="small"
                                    buttons={[
                                        { action: selectDayBefore, icon: light("chevron-left") },
                                        { action: selectToday, label: "Today", disabled: isCurrentPeriod(dateSelected.toDate(), "agenda") },
                                        { action: selectDayAfter, icon: light("chevron-right") },
                                    ]}
                                />
                            </div>
                            {!previewMode &&
                                <div className='calendar-type-selector-container'>
                                    <Segmented
                                        value="agenda"
                                        options={[
                                            {
                                                label: "Agenda",
                                                value: 'agenda'
                                            },
                                            {
                                                label: "Week",
                                                value: 'week'
                                            },
                                            {
                                                label: "Month",
                                                value: 'month'
                                            }
                                        ]}
                                        onChange={view => onChangeCalendarStyle(view)}
                                    />
                                </div>
                            }
                        </div>
                    }


                </BookingAgendaHeaderContainer>
                {showBirthdays &&
                    <BirthdaysDrawer
                        birthdays={dayBirthDays}
                        onClose={onCloseBirthdayDrawer}
                    />
                }
            </>
        )
    } else {
        return (
            <ModelAgendaHeaderContainer>
                <div className="agenda-header-bottom">
                    <h1>{showDate(dateSelected, "ext-notime")}</h1>
                    <NwButtonGroup
                        size="small"
                        buttons={[
                            { action: selectDayBefore, icon: light("chevron-left") },
                            { action: selectToday, label: "Today", disabled: isCurrentPeriod(dateSelected.toDate(), "agenda") },
                            { action: selectDayAfter, icon: light("chevron-right") },
                        ]}
                    />
                </div>
            </ModelAgendaHeaderContainer>
        )
    }
}

export default AgendaHeader
