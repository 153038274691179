import React, { useState } from "react";

import { NwAddButton } from "Components/Gui/NwButton";
import NwDrawer from "Components/Gui/NwDrawer";
import BankAccountForm from 'Components/BankAccounts/BankAccountForm';
import BankAccountItem from 'Components/BankAccounts/BankAccountItem';
import { useContactBankAccounts } from "Hooks/Contact/UseContactBankAccounts";
import { Loading } from "Components/Gui/Loading";
import EmptyState from "Components/Gui/EmptyState";

const BankAccountsDrawer = ({ contactId, type, readOnly, onClose }) => {
    //const { data: currentContact } = useContact(contactId)
    const { data: bankAccounts, isFetching } = useContactBankAccounts(contactId);
    const [viewMode, setViewMode] = useState("LISTING"); // LISTING, VIEWING, INSERTING
    const [bankAccountItem, setCurrentBankAccountItem] = useState(null);
    const [touchForm, setTouchForm] = useState(false);

    const handleCancelEdit = () => {
        setCurrentBankAccountItem(null);
        setViewMode("LISTING");
    };

    return (
        <NwDrawer
            onClose={onClose}
            title="Bank Accounts">
            {!readOnly &&
                <div className='drawer-toolbar-container'>
                    <NwAddButton primary onClick={() => { setViewMode("INSERTING") }} label="Add a new bank account" />
                </div>
            }
            <div className='drawer-content standard'>
                {!isFetching ?
                    ((bankAccounts.length > 0) ?
                        bankAccounts.map(item => (
                            <BankAccountItem
                                key={item.ID}
                                item={item}
                                readOnly={readOnly}
                                contactId={contactId}
                                onEditDoc={() => {
                                    setCurrentBankAccountItem(item);
                                    setViewMode("EDITING");
                                }} />
                        ))
                        :
                        <EmptyState message="no bank accounts" style={{marginTop: "5rem"}} />
                        )
                    :
                    <Loading />
                }
            </div>
            {viewMode === "INSERTING" && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add Bank Account'
                    onClose={handleCancelEdit}>
                    <BankAccountForm
                        type={type}
                        ContactID={contactId}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
            {viewMode === "EDITING" && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Bank Account'
                    onClose={handleCancelEdit}>
                    <BankAccountForm
                        type={type}
                        ContactID={contactId}
                        bankAccount={bankAccountItem}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
        </NwDrawer>
    );
};

export default BankAccountsDrawer;
