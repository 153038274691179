import React, { useEffect, useState } from "react";
import Axios from 'axios';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NWInput, NWTextAreaCharCounter } from "Components/Gui/NWForm/NwFormItems";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import EventSelector from 'Components/EventSelector';
import EventModelSelector from 'Components/EventModelSelector';
import { getEventByItem } from "Components/EventDrawer/BookingEvent/bookingEventUtils";

const Container = styled.div`
`;

const EditBox = styled.div`
  padding-bottom: 10px;
  .title {
    padding-bottom: 5px;
  }
`;

const AttachmentDetailsForm = ({ onClose, onSave, attachment, eventData, project, events, modelId, onTouchForm }) => {

    const [selectedEvent, setSelectedEvent] = useState(getEventByItem(attachment, events, eventData));

    const onEventSelect = (event) => {
        if (!event) {
            setSelectedEvent(null);
            return;
        }
        setSelectedEvent(event);
    }

    const initialValues = {
        Title: attachment.Title ? attachment.Title : '',
        Notes: attachment.Notes ? attachment.Notes : '',
        ContactID: attachment.ContactID,
        EventID: selectedEvent ? selectedEvent.uniqID : null
    };

    const getEventIdByType = (event, modelid) => {
        const modelevent = modelid ? event.Models.find(m => m.ModelID === modelid) : event.Models[0];
        let eventid = null;
        switch (event.TypeName) {
            case 'Travel':
                eventid = modelevent.TravelID;
                break;
            case 'FollowUp':
                eventid = modelevent.FollowUpID;
                break;
            case 'Accommodation':
                eventid = modelevent.AccommodationID;
                break;
        }
        return eventid;
    }

    const onSubmit = async (values) => {
        const event = events.find(e => e.uniqID === values.EventID);
        let data = {
            ID: attachment.ID,
            Title: values.Title,
            Notes: values.Notes,
            ContactID: values.ContactID
        }
        if (event) {
            switch (event.elType) {
                case 'Event':
                    data = {
                        ...data,
                        EventType: event ? event.TypeName || event.elType : null,
                        EventID: event ? event.ID : null
                    }
                    break;
                default:
                    if (!values.ContactID) {
                        if (event.GroupGuid) {
                            data = {
                                ...data,
                                EventGroupGuid: event.GroupGuid
                            }
                        } else {
                            const eventid = getEventIdByType(event)
                            data = {
                                ...data,
                                EventType: event.TypeName,
                                EventID: eventid
                            }
                        }
                    } else {
                        const eventid = getEventIdByType(event, values.ContactID)
                        data = {
                            ...data,
                            EventType: event.TypeName,
                            EventID: eventid
                        }
                    }
            }
        }
        await Axios.put(`/documents/UpdateFromProject`, data);
        onSave();
    }

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(form) => (
                        <NwForm
                            values={form.values}
                            onTouchForm={onTouchForm}
                            onFinish={form.handleSubmit} layout="vertical">
                            <EditBox>
                                <EventSelector
                                    label="EVENT"
                                    form={form}
                                    events={events}
                                    name="EventID"
                                    onChange={onEventSelect}
                                />
                            </EditBox>
                            {
                                (!selectedEvent || (selectedEvent && selectedEvent.elType !== 'Meeting')) && (
                                    <EditBox>
                                        <EventModelSelector
                                            form={form}
                                            event={selectedEvent}
                                            withZeroOption
                                            fixedIfSingle
                                            project={project}
                                            modelId={modelId}
                                            name="ContactID"
                                        />
                                    </EditBox>
                                )
                            }
                            <Field
                                component={NWInput}
                                label="Title"
                                name="Title"
                                type="text"
                                value={form.values.Title}
                            />
                            <Field
                                component={NWTextAreaCharCounter}
                                label="Notes"
                                name="Notes"
                                value={form.values.Notes}
                                maxLength={2000}
                                type="text"
                                autoSize={{ minRows: 2, maxRows: 5 }}
                            />
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={form.isSubmitting}
                                        onClick={() => {
                                            onTouchForm(false);
                                            onClose()
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={form.isSubmitting}
                                        loading={form.isSubmitting}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </Container>
        </>
    );
};

// AttachmentDetailsForm.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,
//   attachment: PropTypes.shape({
//     ID: PropTypes.number,
//     Title: PropTypes.string,
//     Notes: PropTypes.string,
//     ContactID: PropTypes.number,
//     EventID: PropTypes.number
//   }).isRequired,
//   eventData: PropTypes.shape({
//     EventDates: PropTypes.arrayOf(PropTypes.shape({
//       EventDatesModels: PropTypes.arrayOf(PropTypes.shape({
//         ID: PropTypes.number.isRequired,
//         ModelID: PropTypes.number.isRequired,
//       }))
//     }))
//   }).isRequired,
//   project: PropTypes.shape({
//     ID: PropTypes.number.isRequired,
//     Events: PropTypes.arrayOf(PropTypes.shape({
//       ID: PropTypes.number.isRequired
//     }))
//   }).isRequired
// }

export default AttachmentDetailsForm;
