import React from "react";
import styled from "styled-components";

import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const NWListStyle = styled.ul`
  padding: 0;
`;

const NWListItemStyle = styled.li`
  border-bottom: 1px solid #e8e8e8;
  padding: ${props => props.$noPadding ? 0 : '12px 8px'};
  list-style: none;
  justify-content: space-between;
  align-content: ${props => props.$verticalAlignment};
  display: ${props => !props.$mobileView && 'grid'};
  align-items: ${props => props.$verticalAlignment};
  grid-template-columns: 1fr auto;

  &:last-child {
    border-bottom: none;
  }

  .itemContent {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .itemActions {
    grid-column-start: 2;
    grid-column-end: 3;
    padding-left: ${props => props.$mobileView ? '48px' : '30px'};

    padding-top: ${props => props.$mobileView && '12px'};
  }

  &:hover {
    background-color: ${props => !props.$disableHover && props.theme.SelectedBackgroundColor};
  }
`;

const NWList = ({verticalAlignment = "center", children}) => (
   <NWListStyle $verticalAlignment={verticalAlignment}>{children}</NWListStyle>
);

const NWListItem = props => {
  const breakpoints = useNwBreakPoints();
  return (
    <NWListItemStyle $mobileView={breakpoints === 1} $noPadding={props.noPadding} $disableHover={props.disableHover}>
      {props.renderContent && (
        <div className="itemContent">{props.renderContent()}</div>
      )}
      {props.renderActions && (
        <div className="itemActions">{props.renderActions()}</div>
      )}
    </NWListItemStyle>
  );
};

export { NWList, NWListItem };
