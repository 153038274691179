import React, { useState } from 'react';
import styled from 'styled-components';
// import Axios from 'axios';
import StyleVariables from 'Components/Gui/StyleVariables.js';

import {Rate, message, Tag} from 'antd';
import RatingsViewDrawer from "Components/RatingManager/RatingsViewDrawer";
import useAreaAccentColor from 'Hooks/UseAreaAccentColor';
import { useRating, useUpdateRating } from 'Hooks/Rating/UseRating';

export const StyledRateContainer = styled.div`
    .ant-rate-star-full {
        color: ${props => props.ratingColor && props.ratingColor.mainColor}
    }
    .ant-rate-star-zero {
        .ant-rate-star-first, .ant-rate-star-second {
            color: ${props => props.ratingColor && props.ratingColor.lightColor} !important;
        }
    }
    .ant-rate {
        color: ${StyleVariables.PrimaryColor};

        .ant-rate-star:not(:last-child) {
            margin-right: 2px !important;
        }

        
    }
    p {
            margin-top: 4px;
            margin-bottom: 0;
            font-size: .7em;
            text-transform: uppercase;
            cursor: pointer;
            vertical-align: middle;

            .ant-tag {
                font-size: .75rem;
                padding: 0 3px;
                line-height: 12px;
                cursor: pointer;
            }
        }
       
`


const Rating = ({ objectId, objectType, onLoadMore, onRateSuccess, style }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ratingColor = useAreaAccentColor();
    const { data: ratingData } = useRating(objectId, objectType);
    const { mutateAsync: onUpdateRating } = useUpdateRating();
    
    const closeModal = () => {
        setIsModalOpen(false)
    };

    const getObjectIdNameByType = (type) => {
        switch (type) {
            case 'Model':
                return 'ContactID';
            case 'Customer':
                return 'ContactID';
            case 'Document':
                return 'DocumentID';
            case 'Playlist':
                return 'PlaylistID';
            default:
                return null;
        }
    };

    const handleMoreButtonClick = () => {
        if (onLoadMore) {
            getRatingForItem(false, onLoadMore)
        } else {
            getRatingForItem(true)
        }
    };

    const handleRate = async (rate) => {
        const data = {
            [getObjectIdNameByType(objectType)]: objectId,
            Rating: rate
        };

        await onUpdateRating({ data: data, type: objectType, ID: objectId });
        message.success('Rating added');
        if(onLoadMore){
            onLoadMore();
        }
        onRateSuccess && onRateSuccess()
    };

    const getRatingForItem = async (showingInDrawer, onLoadMore) => {
        if (onLoadMore) {
            onLoadMore()
        }
        if (showingInDrawer) {
            setIsModalOpen(true);
        } 
    };

    return (
        <>
            <StyledRateContainer ratingColor={ratingColor} onClick={e => e.stopPropagation()}>
                <Rate
                    style={{fontSize:"16px"}}
                    onChange={handleRate} 
                    value={ratingData ? ratingData.currentUserRating : 0}
                />
                {ratingData && ratingData.averageRating ? 
                    <p onClick={() => handleMoreButtonClick()}>
                        avg rating <strong>{ratingData ? ratingData.averageRating : 0}</strong> <Tag color={StyleVariables.PrimaryColor}>more</Tag>
                    </p>
                : 
                null}
            </StyledRateContainer>
            {isModalOpen &&
                <RatingsViewDrawer ratings={ratingData ? ratingData.ratings : []} onCancel={closeModal}/>
            }
        </>
    );
};

export default Rating;
