import styled from "styled-components";

export const AgendaEventContent = styled.div`
    max-width: 600px;
    small {
        display: block;
    }
    border-bottom: ${props => props.theme.DefaultBorder};
    padding: .25rem 0;
    margin: .25rem 0;
    text-decoration: ${props => props.$cancel ? "line-through" : "none"};
`