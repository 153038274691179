import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';
import Axios from 'axios';

const getContactInvoices = (ID) => {
    return Axios.get(`/contacts/invoices/${ID}`);
}

const useInvoices = (ID) => {
    // let searchParams = getModelPaymentsSearchParams(filters);
    return useQuery(['invoices-contact', ID], () => getContactInvoices(ID), {
        enabled: true,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

const forceReloadInvoices = (ID) => queryClient.invalidateQueries(['invoices-contact', ID]);

const getInvoice = (ID) => {
    return Axios.get(`/Invoices/${ID}`);
}

const useInvoice = (ID) => {
    return useQuery(["invoices", ID], () => getInvoice(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const forceReloadInvoice = (ID) => queryClient.invalidateQueries(["invoices", ID]);


const getInvoicesSearchParams = filters => {
    if (filters) {
        let searchParams = {...filters};
        if (filters.Period) {
            searchParams = {
                ...searchParams,
                FromDate: filters.Period[0],
                ToDate: filters.Period[1],
            }
            delete searchParams.Period;
        }
        return searchParams;
    }
    return null;
};

const onSearchInvoices = searchParams => {
    return Axios.post("/Invoices/Search", searchParams);
}

const useSearchInvoices = (filters) => {
    const searchParams = getInvoicesSearchParams(filters);
    return useQuery(['invoices-search'], () => onSearchInvoices(searchParams), {
        enabled: true,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

const refetchInvoicesSearch = async (filters) => {
    const searchParams = getInvoicesSearchParams(filters);
    try {
        await queryClient.fetchQuery(['invoices-search'], () => onSearchInvoices(searchParams));
    } catch (error) {
        console.log(error)
    }
};

const forceReloadSearchInvoices = (area) => queryClient.invalidateQueries(['invoices-search', area]);

const onAddNewInvoice = ({data}) => {
    return Axios.post(`/Invoices`, data);
}

const useAddNewInvoice = () => {
    return useMutation(onAddNewInvoice, {
        onSuccess: () => {
        }
    });
}

const onUpdateInvoice = ({data}) => {
    return Axios.put(`/Invoices`, data);
}

const useUpdateInvoice = () => {
    return useMutation(onUpdateInvoice, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.invoiceID);
        }
    });
}

const onSortRows = ({ID, data}) => {
    return Axios.put(`/Invoices/rows/SetOrder/${ID}`, data);
}

const useSortRows = () => {
    return useMutation(onSortRows, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.ID);
        }
    });
}

const onGroupRows = ({ID, data}) => {
    return Axios.put(`/Invoices/rows/Group/${ID}`, data);
}

const useGroupRows = () => {
    return useMutation(onGroupRows, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.ID);
        }
    });
}

const onUnGroupRows = ({ID, data}) => {
    return Axios.put(`/Invoices/rows/SplitGroup/${ID}`, data);
}

const useUnGroupRows = () => {
    return useMutation(onUnGroupRows, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.ID);
        }
    });
}

const onAddManual = ({data}) => {
    return Axios.post(`/Invoices/rows/AddManual`, data);
}

const useAddManual = () => {
    return useMutation(onAddManual, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onDeleteRow = ({data, type}) => {
    if (type === "Statement") {
        return Axios.delete("/Invoices/rows/statement", {data});
    }
    return Axios.delete("/Invoices/rows/text", {data});
}

const useDeleteRow = () => {
    return useMutation(onDeleteRow, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onDeleteInvoice = (ID) => {
    return Axios.delete(`/Invoices/${ID}`);
}

const useDeleteInvoice = () => {
    return useMutation(onDeleteInvoice, {
        onSuccess: () => {
        }
    });
}

const onEditText = ({data, type}) => {
    if (type === "Statement")
        return Axios.put("/Invoices/rows/SetStatementText", data);
    if (type === "Text")
        return Axios.put("/Invoices/rows/SetText", data);
    return Axios.put("/Invoices/rows/SetGroupText", data);
}

const useEditText = () => {
    return useMutation(onEditText, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onRemoveFromGroup = ({data}) => {
    return Axios.put("/Invoices/rows/RemoveFromGroup", data);
}

const useRemoveFromGroup = () => {
    return useMutation(onRemoveFromGroup, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onUpdatePaymentStatus = ({data}) => {
    return Axios.put("/Invoices/SetPaymentStatus", data);
}

const useUpdatePaymentStatus = () => {
    return useMutation(onUpdatePaymentStatus, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onInvoiceConfirm = ({data}) => {
    return Axios.put("/Invoices/Confirm", data);
}

const useInvoiceConfirm = () => {
    return useMutation(onInvoiceConfirm, {
        onSuccess: (_, variables) => {
            forceReloadInvoice(variables.data.InvoiceID);
        }
    });
}

const onClearInvoiceConfirm = (ID) => {
    return Axios.put(`/Invoices/Unconfirm/${ID}`);
}

const useClearInvoiceConfirm = () => {
    return useMutation(onClearInvoiceConfirm, {
        onSuccess: (_, ID) => {
            forceReloadInvoice(ID);
        }
    });
}

export {
    forceReloadInvoice,
    forceReloadInvoices,
    forceReloadSearchInvoices,
    refetchInvoicesSearch,
    useAddManual,
    useAddNewInvoice,
    useClearInvoiceConfirm,
    useDeleteInvoice,
    useDeleteRow,
    useEditText,
    useGroupRows,
    useInvoice,
    useInvoiceConfirm,
    useInvoices,
    useRemoveFromGroup,
    useSearchInvoices,
    useSortRows,
    useUnGroupRows,
    useUpdateInvoice,
    useUpdatePaymentStatus,
};