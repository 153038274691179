import React from 'react';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { isAllDay, /*showDate,*/ showDateNoTime } from 'Libs/NwMoment';
import { getContactName } from "Hooks/Contact/UseContactsList";
import useEventStyle from 'Hooks/UseEventStyle';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";
import AddressLine from 'Components/Addresses/AddressLine';
import VerticalPeriod from 'Components/Dates/VerticalPeriod';

const EventFieldsTable = styled.div`
    width: 100%;
    .icon-container {
        padding-right: 1rem;
        svg {
            font-size: 2.4rem;
            margin: 0 0 8px;
        }
    }
    .arrow-dropdown {
        font-size: .95rem;
        margin: 4px 0;
    }
    .label {
        font-size: .65rem;
    }
    .date {
        font-size: .8rem;
        font-weight: bold;
    }

    &:first-child {
        padding-left: 0;
        flex: unset;
    }
    &:last-child {
        border-right: none;
    }
`;

const MeetingDetails = ({ meetingsData }) => {
    const meetingStyle = useEventStyle({}, "Meeting");

    return (
        <FlexContainer pd='16px 30px 16px 40px'>
            <EventFieldsTable>
                <ResponsiveFlexContainer

                    columns= {[
                        {
                            content: (
                                <div className='is-icon'>
                                    <NwIcon icon={meetingStyle.icon} />
                                </div>
                            ),
                            isBorder: true,
                            width: "30%"
                        },
                        {
                            content:(
                                <>
                                    <div className='is-content is-stronger big'>
                                        w/ {getContactName(meetingsData.ContactID)}
                                    </div>
                                    <br/>
                                    <div className="is-content">
                                        <AddressLine address={meetingsData} twoLines noIcon />
                                    </div>
                                </>
                            ),
                            isBorder: true,
                            width: "40%"
                        },
                        {
                            content: (
                                <VerticalPeriod startDate={meetingsData.StartDate} endDate={meetingsData.EndDate} time />
                                // <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                //     <div>{showDateNoTime(meetingsData.StartDate)}</div>
                                //     {isAllDay(meetingsData.StartDate, meetingsData.EndDate) ? 
                                //         <div className='date'>All Day</div>
                                //         :
                                //         <>  
                                //             <FlexContainer column justifyContent='center' alignItems='center'>
                                //                 <div className='date'>{getMoment(meetingsData.StartDate).format('HH:mm')}</div>
                                //                 <NwIcon icon={light('arrow-down')} className='arrow-dropdown' />
                                //             </FlexContainer>
                                //             <div className='date'>{getMoment(meetingsData.EndDate).format('HH:mm')}</div>
                                //         </>
                                //     }
                                // </FlexContainer>
                            ),
                            isBorder: false,
                            width: "30%"
                        }
                    ]}
                />
            </EventFieldsTable>
        </FlexContainer>
    );
};

export default MeetingDetails;