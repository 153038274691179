import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import MediaCover from 'Components/Gui/MediaCover';
import styled from 'styled-components';

const MediaGalleryContent = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* background: #000; */
    padding-bottom: ${props => props.$isEditingBook ? "26rem" : "8rem" };

    margin: 0 -.4rem;

    .image-block {
        color: white;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        position: relative;
        margin: .5rem;
        cursor: pointer;
    }

    &.media-gallery-content-large {
        .image-block {
            margin: .75rem;
        }
    }
    &.media-gallery-content-xlarge {
        .image-block {
            margin: .75rem;
        }
    }

    .nw-book-model-picture {
        position: relative;

        span.plus-icon {
            position: absolute;
            top: 0;
            right: 0;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px;
        }

        &.nw-book-model-picture-disabled {
            opacity: .25;
        }
    }
`

const MediaGallery = (props) => {
    const {
        images,
        isMediaSelectable,
        isEditingBook,
        addMediaToBook,
        clickedMedia,
        mainBook,
        onClickThumbnail,
        size
    } = props;

    const getModelPictureStyle = (index, isDragging, combineTargetFor, draggableStyle) => {
        return ({
            width: images[index].thumbnailWidth,
            height: images[index].thumbnailHeight,
            ...draggableStyle
        });
    };

    const getSourceUrl = (item) => {
        if (size === 'large' || size === 'xlarge') {
            return item.src;
        }
        return item.thumbnail;
    }

    const handleClickThumbnail = (index, caption) => {
        if (caption !== 'ENCODING VIDEO') {
            if (onClickThumbnail) {
                onClickThumbnail(index)
            }
        }
    }

    const renderImageGallery = (item, index) => {
        return clickedMedia || !mainBook ? 
            <MediaCover
                type={item.typeName}
                selected={item.selected}
                selectable={isMediaSelectable}
                src={getSourceUrl(item)}
                encoding={item.caption === 'ENCODING VIDEO'}
                deleteError={item.deleteError}
                style={{
                    minWidth: item.thumbnailWidth + "px",
                    maxWidth: item.thumbnailWidth + 10 + "px",
                    width: item.thumbnailWidth + "px",
                    height: item.thumbnailHeight + "px",
                }}
            /> 
            : 
            <Draggable
                key={`ModelPicture-${item.ID}-${index}`}
                draggableId={`ModelPicture-${item.ID}-${index}`}
                index={index}
                isDragDisabled={item.disabled}
                >
                {(provided, snapshot) => (
                    <div 
                        //id={`ModelPicture-${item.ID}-${index}`}
                        className={`nw-book-model-picture ${item.disabled && "nw-book-model-picture-disabled"}`}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getModelPictureStyle(
                            index,
                            snapshot.isDragging,
                            snapshot.combineTargetFor,
                            provided.draggableProps.style
                        )}
                        onDoubleClick={() => {
                            if (!item.disabled)
                                addMediaToBook(index)}
                        }
                        >
                        <MediaCover
                            type={item.typeName}
                            selected={item.selected}                
                            selectable={isMediaSelectable}
                            src={getSourceUrl(item)}
                            style={{
                                minWidth: item.thumbnailWidth + "px",
                                maxWidth: item.thumbnailWidth + 10 + "px",
                                width: item.thumbnailWidth + "px",
                                height: item.thumbnailHeight + "px"
                            }}
                        />
                    </div>
                )}
            </Draggable>
    }

    return (
        <MediaGalleryContent $isEditingBook={isEditingBook} className={`media-gallery-content-${size}`}>
            {images.map((item, index) => {
                return (
                    <div
                        key={index}
                        className='image-block'
                        onClick={() => { handleClickThumbnail(index, item.caption) }}
                    >
                        {renderImageGallery(item, index)}
                    </div>                    
                )
            })}
        </MediaGalleryContent>
    )
}

export default MediaGallery;