import React from "react";
import styled from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables.js';
// import NwIcon from "Components/Gui/NwIcon";
import { Badge } from "antd";

const ObjectCounter = styled.div`
    display: inline-block;
    /* padding: .15em .5em .15em .15em;
    background-color: ${StyleVariables.LayoutBodyBackground};
    border: ${StyleVariables.DefaultBorder};
    border-radius: 12px; */
    
    .ant-badge-count {
        background-color: ${StyleVariables.LighterBorderColor};
        color: ${StyleVariables.TextColor};
        box-shadow: 0 0 0 1px ${StyleVariables.BorderColor} inset;
        font-size: .75rem;
        min-width: 16px;
        height: 16px;
        padding: 0 4px;
        line-height: 16px;
        border-radius: 8px;
    }
    
    cursor: ${props => props.onClick ? "pointer" : "standard"};
    font-size: .75rem;

    /* span {
        background-color: ${props => props.count > 0 ? StyleVariables.PrimaryColor : StyleVariables.DangerColor};
        color: #fff;
        border-radius: 25px;
        padding: 1px 5px;
        margin-right: 5px;
        vertical-align: middle;
        box-shadow: 0 1px 6px rgba(57,73,76,.35);
    }
     */

    em {
        padding-left: .5em;
        font-style: normal;
        text-transform: uppercase;
        vertical-align: middle;
    }
`;

const NwObjectCounter = ({ count, label, style, onClick }) => {
    const getStyle = () => (
        (count > 0) ? { backgroundColor: StyleVariables.PrimaryColor, color: "#fff", borderColor: StyleVariables.PrimaryColor } : null
    )
    
    return (
        <ObjectCounter style={style} className={count === 0 && "empty"} onClick={onClick}>
            <Badge count={count} showZero style={getStyle()} overflowCount={999999} />
            {label && <em>{label}</em>}
        </ObjectCounter>
    )
};

export default NwObjectCounter;
