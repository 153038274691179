import React from 'react';
import styled from 'styled-components';
import { List, Typography } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { 
    NWTextAreaCharCounter,
    NWRateTypeSelector, 
    NWAmountInput
} from 'Components/Gui/NWForm/NwFormItems';
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwAddButton } from 'Components/Gui/NwButton';
import { RATE_TYPES } from 'Constants/RateTypes';
import useConfigurations from 'Hooks/UseConfigurations';


const Container = styled.div`
    display: flex;
    
    .col {
        flex: 1;
        padding: 1em;
        border: 1px solid ${props => props.theme.LighterBorderColor};
        border-radius: 4px;
    }

    .add-form {
        margin-right: 8px;
    }
    .transaction-container {
        margin-left: 8px;
    }
`;

const AddButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const getTransactionTypeName = typeValue => {
    const rate = RATE_TYPES.find(type => type.TypeValue === typeValue);
    return rate.Type;
}

const validateCurrency = currency => {
    if (!currency[1]) {
        return 'Please input amount'
    };
    return null;
};

const ProjectPrevisionalFees = ({ transactions, onAddTransaction, onTouchForm }) => {
    
    const configurations = useConfigurations();
    
    const initialValues = {
        Type: '',
        Salary: [configurations.defaultCurrency, 0],
        Description: ''
    };

    const saveTransaction = async (values, setSubmitting, resetForm) => {
        let transactionData = {
            Type: values.Type,
            Amount: values.Salary[1],
            Currency: values.Salary[0],
            Description: values.Description
        }

        onAddTransaction(transactionData);
        resetForm(initialValues);
        setSubmitting(false);
    };

    return (
        <>
            <Typography>Project provisional fees</Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Type: Yup.string().required("Please input type"),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    saveTransaction(values, setSubmitting,  resetForm);
                }}
                render={(subformik) => (
                    <NwForm
                        values={subformik.values} 
                        onTouchForm={onTouchForm}
                        onFinish={subformik.handleSubmit} 
                        layout="vertical">
                        <Container>
                            <div className='col add-form'>
                                <Field
                                    component={NWRateTypeSelector}
                                    name="Type"
                                    value={subformik.values.Type}
                                />
                                <Field
                                    component={NWAmountInput}
                                    value={subformik.values.Salary}
                                    name="Salary"
                                    validate={validateCurrency}
                                />
                                <Field
                                    component={NWTextAreaCharCounter}
                                    name="Description"
                                    placeholder='Description'
                                    type="text"
                                    value={subformik.values.Description}
                                    maxLength={2000}
                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                /> 
                                <AddButtonContainer>
                                    <NwAddButton
                                        onClick={subformik.handleSubmit}
                                        primary={true}
                                        disabled={subformik.isSubmitting}
                                        loading={subformik.isSubmitting}
                                        label="Add"
                                        loadingLabel="Adding..."
                                    />
                                </AddButtonContainer>
                            </div>
                            <div className='col transaction-container'>
                                <List                
                                    size='small'
                                    itemLayout="horizontal"
                                    dataSource={transactions}
                                    renderItem={transaction => {
                                        return (
                                            <List.Item className="ant-list-item-top">
                                                <List.Item.Meta
                                                    title={`${getTransactionTypeName(transaction.Type)} - ${transaction.Amount} ${transaction.Currency}`}
                                                    description={transaction.Description}
                                                />
                                            </List.Item>
                                        )}
                                    }
                                />
                            </div>
                        </Container>
                    </NwForm>
                )}
            />
        </>
    )
};

export default ProjectPrevisionalFees;