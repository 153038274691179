import React, { useContext } from 'react';
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import Axios from 'axios';
import { NWInputNumber, NWSelect, NWSwitch } from "Components/Gui/NWForm/NwFormItems";
import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import FlexContainer from 'Components/Gui/FlexContainer';
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import { ListContext } from 'Contexts/ListContext';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';


const TransactionTaxForm = ({ statement, transaction, onTouchForm, onCancel, onUpdate }) => {
    const { cachedList } = useContext(ListContext);
    const isNegative = statement.TaxableBase < 0
    //TODO: verificare se Inverse e Deduction sono corretti
    //const impossibleTax = isNegative ? "Inverse" : "Deduction"
    // const taxOptions = cachedList.taxmethods.items.Taxes.filter((tax) => {
    //     return tax.TaxType !== impossibleTax && !tax.Disabled
    // });
    const taxOptions = cachedList.taxmethods.items.Taxes.filter((tax) => {
        return !tax.Disabled
    });

    const initialValues = {
        taxableBase: Math.abs(statement.TaxableBase),
        taxCode1: statement.TaxCode || undefined,
        taxCode2: statement.TaxCode2 || undefined,
        vatCode: statement.VatCode || undefined,
        taxCompound: statement.TaxCompound,
        transactionID: transaction.ID
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        const data = {
            ...values,
            taxableBase: isNegative ? -values.taxableBase : values.taxableBase,
            statementID: statement.ID
        };
        setSubmitting(true);
        try {
            await Axios.put(`/Transactions/${transaction.Family}/SetManualTaxes`, data);
            forceReloadTransaction(transaction.ID);
            if (transaction.ProjectID) {
                forceReloadProjectTransactions(transaction.ProjectID)
            }
            setSubmitting(false);
            onUpdate();
        } catch (error) {
            setSubmitting(false);
            console.log('ant : Update Tax Error => ', error);
        }
    };

    const validateTaxableBase = value => {
        if (value < 0) return 'It should be greater than 0';
        return null;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, {setStatus, setSubmitting}) => {
                setStatus();
                submitForm(values, setStatus, setSubmitting);
            }}
            render={({isSubmitting, values, handleSubmit}) => {
                return  (
                    <NwForm 
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Row gutter={16}>
                            <Col span={8}>
                                Taxable Base
                            </Col>
                            <Col span={12}>
                                <FlexContainer alignItems='flex-start'>
                                    <FlexContainer mg='0 8px 0 0'>{isNegative ? '-' : '+'}</FlexContainer>
                                    <Field
                                        component={NWInputNumber}
                                        // label="Amount"
                                        name="taxableBase"
                                        step={0.01}
                                        value={values.taxableBase}
                                        validate={validateTaxableBase}
                                    />
                                </FlexContainer>
                            </Col>
                        </Row>
                        {taxOptions.length > 0 && 
                            <Row gutter={16}>
                                <Col span={8}>
                                    Tax Code
                                </Col>
                                <Col span={12}>
                                    <Field
                                        component={NWSelect}
                                        name="taxCode1"
                                        placeholder='No Tax'
                                        value={values.taxCode1}
                                        options={taxOptions.map(tax => {
                                            return { key: tax.Code, value: tax.Code, label: tax.Name };
                                        })}
                                    />
                                </Col>
                            </Row>
                        }
                        {taxOptions.length > 0 && 
                            <Row gutter={16}>
                                <Col span={8}>
                                    Additional Tax Code
                                </Col>
                                <Col span={12}>
                                    <Field
                                        component={NWSelect}
                                        name="taxCode2"
                                        placeholder='No Tax'
                                        value={values.taxCode2}
                                        options={taxOptions.map(tax => {
                                            return { key: tax.Code, value: tax.Code, label: tax.Name };
                                        })}
                                    />
                                </Col>
                            </Row>
                        }
                        {taxOptions.length > 0 && 
                            <Row gutter={16}>
                                <Col span={8} />
                                <Col span={12}>
                                    <Field
                                        text='Compound Tax'
                                        component={NWSwitch}
                                        size='small'
                                        disabled={!values.taxCode2}
                                        checked={values.taxCompound}
                                        name='taxCompound'
                                        // onAfterChange={value => onChange('active', value)}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row gutter={16}>
                            <Col span={8}>
                                Vat Code
                            </Col>
                            <Col span={12}>
                                <Field
                                    component={NWSelect}
                                    name="vatCode"
                                    placeholder='No VAT'
                                    value={values.vatCode}
                                    options={cachedList.taxmethods.items.VatCodes.map(vat => {
                                        return { key: vat.Code, value: vat.Code, label: vat.Name };
                                    })}
                                />
                            </Col>
                        </Row>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )
            }}
        />
    )
}

export default TransactionTaxForm;