import { useMutation, useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';
import { forceReloadContact } from "Hooks/Contact/UseContact";

const getContactSocials = async (ID) => {
    const socials = await Axios.get(`/contacts/socials/${ID}`)
    return socials
}

const useContactSocials = (ID) => {
    return useQuery(["contactsocials", ID], 
        () => getContactSocials(ID),
        {
            enabled: ID ? true : false,
            staleTime: 10000,
            cacheTime: 10000,
            retry: false
        })
}

const forceReloadContactSocials = ID => {
    queryClient.invalidateQueries(['contactsocials', ID]);
};

const onUpdateSocials = (ID) => {
    forceReloadContactSocials(ID)
    forceReloadContact(ID)
}

const addContactSocial = ({data, ContactID}) => (
    Axios.post(`/socials`, data)
)

const useAddContactSocial = () => {
    return useMutation(addContactSocial, {
        onSuccess: (data, variables) => {
            onUpdateSocials(variables.ContactID)
        }
    })
}

const removeContactSocial = async ({ID, ContactID}) => (
    Axios.delete(`/socials/${ID}`)
)

const useRemoveContactSocial = () => {
    return useMutation(removeContactSocial, {
        onSuccess: (data, variables) => {
            onUpdateSocials(variables.ContactID)
        }
    })
}

const editContactSocial = async ({data, ContactID}) => (
    Axios.put(`/socials`, data)
)

const useEditContactSocial = () => {
    return useMutation(editContactSocial, { 
        onSuccess: (data, variables) => {
            onUpdateSocials(variables.ContactID)
        }
    })
}

export {
    useContactSocials,
    forceReloadContactSocials,
    useEditContactSocial,
    useRemoveContactSocial,
    useAddContactSocial
}