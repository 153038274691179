import React, { useState, useContext, useMemo } from "react";
import { getMoment } from "Libs/NwMoment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, Popover } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { datePickerBeforeOnChange, getNow, toISODate } from "Libs/NwMoment";

import {
    NWInput,
    NWSelect,
    NWContactSelector,
    NWTextAreaCharCounter,
    NWDatePicker,
    NWAddressForm
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import NwIcon from 'Components/Gui/NwIcon';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import GooglePlaces from 'Components/GooglePlaces/GooglePlaces';
import NwDivider from 'Components/Gui/NwDivider';
import StyleVariables from 'Components/Gui/StyleVariables';
import { PhoneLine } from "Components/Gui/NwContactInfo";
import AddressLine from 'Components/Addresses/AddressLine';
import {
    useUpdateScoutingAccommodation,
    useAddScoutingAccommodation
} from 'Hooks/Event/UseScoutingAccommodation';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { ListContext } from 'Contexts/ListContext';
import { UserContext } from 'Contexts/UserContext';
import { useTravelPlan } from 'Hooks/TravelPlan/UseTravelPlan';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useContactConnections } from 'Hooks/Contact/UseContactConnections';

const CheckDate = styled.div`
    margin: 2px 8px 0 0;
`;

const ConnectionItem = styled.div`
    cursor: pointer;
    border-bottom: ${StyleVariables.DefaultBorder};
    margin-bottom: 8px;
    padding-bottom: 4px;
`;

const ConnectionsContainer = styled.div`
    width: 260px;
    margin-right: 16px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 1rem;
`;

const Connection = ({ connection, onSetAddress }) => {
    const renderConnectionContent = () => {
        return (
            <>
                {connection.Addresses.length > 0 && 
                    <FlexContainer column alignItems='flex-start' mg='0 0 16px'>
                        <Label>Addresses</Label>
                        {connection.Addresses.map(address => {
                            return <AddressLine 
                                address={address} 
                                disableMap
                                onClick={handleSetAddress(address)}
                                key={`address-${address.ID}`} />
                        })}
                    </FlexContainer>
                }
                {connection.Phones.length > 0 && 
                    <FlexContainer column alignItems='flex-start'>
                        <Label>Phones</Label>
                        {connection.Phones.map(phone => {
                            return <PhoneLine 
                                phoneType={phone.Type} 
                                phoneNumber={phone.Number} 
                                disableMagnifier
                                onClick={handleSetPhone(phone)}
                                key={`phone-${phone.ID}`} />
                        })}
                    </FlexContainer>
                }
            </>
        )
    };

    const handleSetAddress = address => () => {
        onSetAddress(address);
    };

    const handleSetPhone = phone => () => {
        onSetAddress({
            Phone: phone.Number
        });
    }

    const handleSetAddressFromSimpleConnection = () => {
        let address = {};
        if (connection.Addresses.length === 1) {
            address = {
                ...address,
                ...connection.Addresses[0]
            };
        };
        if (connection.Phones.length === 1) {
            address = {
                ...address,
                Phone: connection.Phones[0].Number
            };
        }

        onSetAddress(address);
    };

    return (connection.Addresses.length > 1 || connection.Phones.length > 1 ?
        <Popover
            trigger='click'
            content={renderConnectionContent()}>
            <ConnectionItem>
                {connection.Name}
            </ConnectionItem>
        </Popover>
        : <ConnectionItem onClick={handleSetAddressFromSimpleConnection}>
            {connection.Name}
        </ConnectionItem>
    );
};

const ScoutingAccommodationForm = props => {
    const { 
        scoutingAccommodationData,
        viewMode, 
        travelPlanId,
        afterUpdate, 
        onCancel,
        onUpdateScoutingAccommodationData,
        onTouchForm
    } = props;
    const { data: currentTravelPlan } = useTravelPlan(travelPlanId);
    const { mutateAsync: onAddScoutingAccommodation } = useAddScoutingAccommodation();
    const { mutateAsync: onUpdateScoutingAccommodation } = useUpdateScoutingAccommodation();
    const [serviceId, setServiceId] = useState(scoutingAccommodationData && scoutingAccommodationData.ServiceID);
    const { data: contactConnections } = useContactConnections(serviceId);
    const [addAddressType, setAddAddressType] = useState('service');
    const { cachedList } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    const users = cachedList.users.items.filter(user => currentUser.isSuperAdmin || !user.isSuperAdmin);

    const bookers = useMemo(() => {
        let bookers = [];
        if (currentTravelPlan) {
            for (const tripBooker of currentTravelPlan.Bookers) {
                const booker = users.find(user => user.ID === tripBooker.BookerID);
                if (booker) {
                    bookers.push({
                        value: booker.ID,
                        key: booker.ID,
                        label: `${booker.Name} ${booker.Surname}`
                    });
                }
            }
        }
        return bookers;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTravelPlan]);

    const submitForm = async (values, setSubmitting) => {
        const newAccommodationData = {
            ...values,
            ...values.Address,
            PhoneNumber: values.Address.Phone,
            UserID: currentTravelPlan.Bookers.length === 1 ?  currentTravelPlan.Bookers[0].BookerID : values.UserID
        };

        delete newAccommodationData.Address;

        if (!scoutingAccommodationData) {
            try {
                const newAccommodation = await onAddScoutingAccommodation({ scoutingAccommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate){
                    afterUpdate({
                        ...newAccommodation,
                        TypeName: "Accommodation"
                    });
                }
                onUpdateScoutingAccommodationData(newAccommodation.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create Scouting Accommodation Error", error);
            }
            setSubmitting(false);
        } 
        else {
            newAccommodationData.ID = scoutingAccommodationData.ID;

            try {
                await onUpdateScoutingAccommodation({ scoutingAccommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateScoutingAccommodationData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update Scouting Accommodation Error", error);
            }
            setSubmitting(false);
        }
    };

    let initialValues = {
        TravelPlanID: travelPlanId,
        Title: scoutingAccommodationData ? scoutingAccommodationData.Title : '',
        ServiceID: scoutingAccommodationData ? scoutingAccommodationData.ServiceID : '',
        Note: scoutingAccommodationData ? scoutingAccommodationData.Note : '',
        FromDate: scoutingAccommodationData ? scoutingAccommodationData.FromDate : getNow(true),
        ToDate: scoutingAccommodationData ? scoutingAccommodationData.ToDate : getNow(true),
        UserID: scoutingAccommodationData ? scoutingAccommodationData.UserID : undefined,
        Address: {
            Country: scoutingAccommodationData ? scoutingAccommodationData.Country : '',
            City: scoutingAccommodationData ? scoutingAccommodationData.City : '',
            County: scoutingAccommodationData ? scoutingAccommodationData.County : '',
            State: scoutingAccommodationData ? scoutingAccommodationData.State : '',
            Zip: scoutingAccommodationData ? scoutingAccommodationData.Zip : '',
            Street: scoutingAccommodationData ? scoutingAccommodationData.Street : '',
            Access: scoutingAccommodationData ? scoutingAccommodationData.Access : '',
            Phone: scoutingAccommodationData ? scoutingAccommodationData.PhoneNumber : ''
        }
    };

    const disableCheckOutDate = beforeDate => date => {
        if (beforeDate && toISODate(beforeDate) > toISODate(date)){
            return true;
        }
        return false;
    }

    return (
        <div className='event-form'>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required"),
                    ServiceID: Yup.number().required('Content is required'),
                    UserID: bookers.length > 1 && Yup.string().required("Content is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                render={({ isSubmitting, values, handleSubmit, setFieldValue, validateField }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={onTouchForm}
                        small 
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWContactSelector}
                                    label={`Service`}
                                    name="ServiceID"
                                    activeTypes={['srv']}
                                    activableTypes={[]}
                                    value={values.ServiceID}
                                    onAfterChange={serviceId => {
                                        setFieldValue('Title', getContactName(serviceId));
                                        setServiceId(serviceId);
                                    }}
                                />
                            </Col>
                            {bookers.length > 1 && 
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <Field
                                        component={NWSelect}
                                        label="Booker"
                                        name="UserID"
                                        value={values.UserID}
                                        options={bookers}
                                    />
                                </Col>
                            }
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="Title"
                                    name="Title"
                                    type="text"
                                    value={values.Title}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <FlexContainer>
                                <FlexContainer alignItems='flex-start' mg='0 16px 0 0'>
                                    <CheckDate>Check In</CheckDate>
                                    <Field
                                        component={NWDatePicker}
                                        name="FromDate"
                                        format="MMM DD, YYYY"
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        style={{ width: "100%" }}
                                        value={values.FromDate ? getMoment(values.FromDate) : null}
                                        manipulateValue={datePickerBeforeOnChange}
                                        onAfterChange={date => setFieldValue('ToDate', date)}
                                    />
                                </FlexContainer>
                            
                                <FlexContainer alignItems='flex-start'>
                                    <CheckDate>Check Out</CheckDate>
                                    <Field
                                        component={NWDatePicker}
                                        // label="Check"
                                        name="ToDate"
                                        format="MMM DD, YYYY"
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        style={{ width: "100%" }}
                                        value={values.ToDate ? getMoment(values.ToDate) : null}
                                        manipulateValue={datePickerBeforeOnChange}
                                        disabledDate={disableCheckOutDate(values.FromDate)}
                                    />
                                </FlexContainer>
                            </FlexContainer>
                        </Row>
                        <NwDivider>Addresses</NwDivider>
                        <FlexContainer mg='0 0 16px'>
                            <FlexContainer fullWidth>
                                <RadioButtonGroup 
                                    value={addAddressType} 
                                    onChange={setAddAddressType} 
                                    options={[
                                        { label: 'SERVICE', value: 'service' },
                                        { label: 'NEW', value: 'create-new'}
                                    ]} />
                            </FlexContainer>
                            <FlexContainer fullWidth>
                                {/* {renderContactSelector()} */}
                                {addAddressType === 'create-new' && 
                                    <GooglePlaces 
                                        disableHeader
                                        onSetAddress={address => {
                                            setFieldValue('Address', {
                                                ...address,
                                                Country: address.CountryCode
                                            })
                                        }}
                                        suffix={
                                            <NwIcon icon={light('search')} />
                                    } />
                                }
                            </FlexContainer>
                        </FlexContainer>
                        <FlexContainer alignItems='flex-start'>
                            {addAddressType === 'service' && 
                                <ConnectionsContainer>
                                    {contactConnections && contactConnections.map(connection => {
                                        return (
                                            <Connection 
                                                connection={connection} 
                                                onSetAddress={address => {
                                                    setFieldValue('Address', {
                                                        ...values.Address,
                                                        ...address
                                                    });
                                                }}
                                                key={`connection-${connection.ID}`} />
                                        );
                                    })}
                                </ConnectionsContainer>
                            }
                            <Field
                                component={NWAddressForm}
                                name='Address'
                                phoneAddible
                                value={values.Address}
                            />
                        </FlexContainer>
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Note"
                            name="Note"
                            type="text"
                            value={values.Note}
                            maxLength={2000}
                            autoSize={{ minRows: 4, maxRows: 10 }}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (onTouchForm) {
                                            onTouchForm(false);
                                        }
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </div>
    );
};

export default React.memo(ScoutingAccommodationForm);
