import React from "react";
//import styled from 'styled-components';
import { Button } from "antd";
//import { presetPalettes } from '@ant-design/colors';
//import StyleVariables from './StyleVariables';

//const bgcolor = StyleVariables.LayoutBodyBackground

const btn_style = {
    fontSize: ".8em"
}

const btn_inactive_style = {
    ...btn_style
}

const InactiveButton = (props) => (
    <Button style={btn_inactive_style} type="default" onClick={props.onClick}>{props.children}</Button>
)


const ActiveButton = (props) => (
    <Button style={btn_style} type="primary" onClick={props.onClick}>{props.children}</Button>
)

const NwButtonOption = (props) => {
    //console.log(presetPalettes)
    const {active, children} = props;
    return (
        active ?
        <ActiveButton {...props}>{children}</ActiveButton>
        :
        <InactiveButton {...props}>{children}</InactiveButton>
    );
};

export default NwButtonOption;