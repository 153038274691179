import { getMoment } from "Libs/NwMoment";
import React from "react";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWTextAreaCharCounter,
    NWProjectSelector,
    NWVisibilitySelector,
    NWContactSelector,
    NWRadioButtonGroup,
    NWPeriodSelector,
    NWSwitch
} from "Components/Gui/NWForm/NwFormItems";
import {
    useUpdateTask,
    useAddTask,
    useUpdateGroupedIndividualTask,
    useUpdateGroupedCommonTask
} from 'Hooks/Event/UseTask';
import { validatePeriod } from 'Libs/NwUtils';
import { getNow } from "Libs/NwMoment";

const PrivacyOptions = [
    {
        label: 'PERSONAL',
        value: 'User'
    },
    {
        label: 'PUBLIC',
        value: 'Public'
    }
];

const TaskForm = ({
    afterUpdate,
    editModelEvent,
    modelId,
    onCancel,
    onTouchForm,
    onUpdateTaskData,
    projectId,
    taskData,
    viewMode,
}) => {
    const { mutateAsync: onAddToDo } = useAddTask();
    const { mutateAsync: onUpdateToDo } = useUpdateTask();
    const { mutateAsync: onUpdateGroupedIndividualToDo } = useUpdateGroupedIndividualTask();
    const { mutateAsync: onUpdateGroupedCommonToDo } = useUpdateGroupedCommonTask();

    let initialValues = {
        Title: taskData ? taskData.Title : '',
        Description: taskData ? taskData.Description : '',
        ContactID: taskData ? taskData.ContactID : modelId,
        ProjectID: taskData && taskData.Project ? taskData.Project.ID : projectId,
        Date: taskData && taskData.Date ? getMoment(taskData.Date) : null,
        RemindDate: taskData && taskData.RemindDate ? getMoment(taskData.RemindDate) : null,
        ChangeOnAllModels: true,
        Visibility: {
            Areas: taskData ? taskData.Visibility.Areas : [],
            isGlobal: taskData ? taskData.Visibility.isGlobal : true
        },
        PrivacyLevel: taskData && taskData.PrivacyLevelName ? taskData.PrivacyLevelName : 'Public',
        Period: {
            startDate: taskData && taskData.RemindDate ? getMoment(taskData.RemindDate) : getNow(true),
            endDate: taskData && taskData.Date ? getMoment(taskData.Date) : getNow(true),
            enableStart: taskData && !taskData.RemindDate ? false : true,
            enableEnd: taskData && !taskData.Date ? false : true
        },
    };

    const generalDataSource = [
        {
            component: NWPeriodSelector,
            label: 'Period',
            name: 'Period',
            Col: 24,
            validate: date => validatePeriod(date, true),
            enableDates: true,
            dateOnly: true,
            labels: [`Don't Show Before`, 'Due Date'],
            labelMinWidth: '160'
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            required: true,
            Col: 24
        },
    ];

    let modelDataSource = [
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        }
    ];

    let privacySource = [
        {
            component: NWVisibilitySelector,
            label: 'Visibility',
            name: 'Visibility',
            visibilityValidate: true
        },
        {
            component: NWRadioButtonGroup,
            name: "PrivacyLevel",
            label: 'Privacy',
            buttonStyle: "solid",
            options: PrivacyOptions.map(option => {
                return { key: option.value, value: option.value, label: option.label };
            })
        }
    ];

    let dataSource = editModelEvent ? [...modelDataSource] : taskData && taskData.GroupGuid ? [...generalDataSource, ...privacySource] : [...generalDataSource, ...modelDataSource, ...privacySource];

    if (!taskData) {
        dataSource.splice(0, 0, {
            component: NWProjectSelector,
            name: 'ProjectID',
            readOnly: projectId ? true : false,
            Col: 24
        });
    }

    if (!modelId && !taskData) {
        dataSource.splice(1, 0, {
            component: NWContactSelector,
            label: "Contact",
            activeTypes: ["mod"],
            activableTypes: ["srv", "cli"],
            name: "ContactID",
            projectId: projectId,
            disabled: taskData && true,
            Col: 24
        });
    }

    if (editModelEvent) {
        dataSource.push({
            component: NWSwitch,
            text: 'apply these changes on all models in this task',
            name: 'ChangeOnAllModels',
            Col: 24
        });
    }

    const submitForm = async (values, setStatus, setSubmitting) => {
        const todoData = {
            ...values,
            RemindDate: values.Period.enableStart ? values.Period.startDate : null,
            Date: values.Period.enableEnd ? values.Period.endDate : null,
            Status: 0,
            VisibilityAreas: values.Visibility.Areas
        };

        if (!taskData) {
            try {
                const response = await onAddToDo({ todoData: todoData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate({
                        ...response,
                        TypeName: "ToDo"
                    }, 'ToDo');
                }
                onUpdateTaskData(response.ID);
                onCancel();
            } catch (error) {
                console.log('ant : Add ToDo Error => ', error);
            }
            setSubmitting(false);
        } else {
            todoData.ID = taskData.ID;
            try {
                if (taskData.GroupGuid) {
                    if (editModelEvent) {
                        todoData.ChangeOnAllModels = values.ChangeOnAllModels;
                        await onUpdateGroupedIndividualToDo({ todoData: todoData });
                    } else {
                        todoData.GroupGuid = taskData.GroupGuid;
                        delete todoData.ID;
                        await onUpdateGroupedCommonToDo({ todoData: todoData });
                    }
                } else {
                    await onUpdateToDo({ todoData: todoData });
                }

                onTouchForm(false);
                onUpdateTaskData();
                // onUpdateToDoData();
                if (afterUpdate) {
                    afterUpdate();
                }
                if (!viewMode || viewMode === 'create') {
                    // setIsEdit(false);
                    onCancel();
                }
            } catch (error) {
                console.log('ant : Add ToDo Error => ', error);
            }
            setSubmitting(false);
        }
    };

    let validationSchema = Yup.object().shape({
        Title: Yup.string().required("Title is required"),
    });

    return (
        <EditEvent
            submitForm={submitForm}
            mg={taskData && '80px 0 0'}
            validationSchema={validationSchema}
            dataSource={dataSource}
            onCancel={onCancel}
            disableReinitialize
            onTouchForm={onTouchForm}
            initialValues={initialValues} />
    )
};

export default TaskForm;
