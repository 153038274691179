import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, /*Steps*/ } from 'antd';

// import { NwButton } from 'Components/Gui/NwButton';
// import GeneralProjectInfoPanel from 'Components/CreateProject/REMOVE_GeneralProjectInfoPanel';
// import ProjectTransactions from 'Components/CreateProject/REMOVE_ProjectTransactions';
// import ProjectModels from 'Components/CreateProject/REMOVE_ProjectModels';
import NwDrawer from 'Components/Gui/NwDrawer';
import ProjectAddSelectForm from 'Components/ProjectAddSelectForm/ProjectAddSelectForm';
import { useUserPermission } from 'Contexts/UserContext';
import NotAllowedFeature from 'Components/Utils/NotAllowedFeature';

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    height: 30px;
`;

const CreateProjectDrawer = ({ onCloseModal, searchMode, searchOnly, showSecondLevel, drawerPlacement, onChange }) => {
    const [touchForm, setTouchForm] = useState(false);
    const handleSelectProject = projectId => {
        onChange(projectId);
        onCloseModal();
        if (touchForm) {
            setTouchForm(false);
        }
    }

    const canCreateEvent = useUserPermission('CreateBookingEvent');

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            placement={drawerPlacement}
            onClose={onCloseModal}
            title={
                <ModalHeader>
                    {!searchMode ?
                        <>
                            New Project
                        </>
                        : <>
                            <Typography>Select Project</Typography>
                        </>
                    }
                </ModalHeader>
            }
        >
            {!canCreateEvent
                ?
                <NotAllowedFeature />
                :
                <ProjectAddSelectForm
                    drawerPlacement={drawerPlacement}
                    searchOnly={searchOnly}
                    showSecondLevel={showSecondLevel}
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onSelectProject={handleSelectProject}
                    searchMode={searchMode}
                    onCancel={onCloseModal}
                />
            }
        </NwDrawer>
    )
};

export default CreateProjectDrawer;