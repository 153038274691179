import React from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { withRouter } from "react-router";
import CountryLabel from 'Components/Gui/CountryLabel';
import { getAge } from "Libs/NwMoment";
import { showDateNoTime } from "Libs/NwMoment";
import NwIcon, { NwGenderIcon } from "Components/Gui/NwIcon";
import { getModel, modelFullName } from "Hooks/Contact/UseContactsList";
import ModelInTown from "Components/ModelInTown/ModelInTown";
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import ModelDetailsHeaderAgencies from "./CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import ContactUsers from "Components/ContactUsers/ContactUsers";
import ContactDepartments from "Components/ContactDepartments/ContactDepartments";
import styled from "styled-components";
import { ModelCover } from 'Components/ModelCover';
import { Tooltip } from "antd";


const HeaderWrapper = styled.div`
    padding-bottom: 1rem;
    padding-right: 3rem;
    border-bottom: ${props => props.theme.DefaultBorder};
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    position: relative;
    
    .model-trigger-fullview {
        position: absolute;
        top: 0;
        right: 0;
        font-size: ${props => props.theme.FontSizeXLg};
    }

    .model-cover-wrapper {
        padding-top: .5rem;
    }


    .model-details-wrapper {
        h1 {
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: .15rem;
        }
        p {
            margin: 0;
        }
        .header-line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;

            svg, span {margin-top: 0;}
        }
    }
`

const ModelHeader = ({ modelID, history }) => {
    const contact = getModel(modelID);
    const fullName = modelFullName(contact);
    const birthDate = contact.B
    const nationalityCode = contact.NAT
    const gender = contact.G

    const getBirthdayContent = () => (
        <>
            {getAge(birthDate)} y.o.&nbsp;&nbsp;<NwIcon icon={light("birthday-cake")} />&nbsp;&nbsp;{showDateNoTime(birthDate)}
        </>
    )

    const goToModelPage = () => {
        history.push(`/models/${modelID}`)
    }

    const renderContactDetailsHeaderAnagraphical = () => {
        return (contact.ContactType === 'Customer' ?
            <NwIcon icon={light("id-card")} />
            :
            <>
                <NwGenderIcon gender={`${gender}`} />
                &nbsp;&nbsp;
                {birthDate ? getBirthdayContent() : `birthday: n.d.`}
                &nbsp;&nbsp;&nbsp;
                {nationalityCode && <CountryLabel usecode code={nationalityCode} flag />}
            </>
        )
    }

    return (
        <HeaderWrapper>
            <div className="model-trigger-fullview">
                <Tooltip placement="left" title="Leave the search and go to model page">
                    <NwIcon pointer icon={light("expand")} onClick={goToModelPage} />
                </Tooltip>
            </div>
            <div className="model-cover-wrapper">
                <ModelCover maxWidth={70} model={contact} />
            </div>
            <div className="model-details-wrapper">
                <h1>{fullName}</h1>
                <div className="header-line">
                    <ModelInTown
                        modelID={contact.ID}
                        modelInTownSchedule={contact.ITS}
                        readOnly
                    />
                    <NwDividerVertical />
                    <ModelDetailsHeaderAgencies
                        model={contact}
                    />
                </div>
                <div className="header-line">
                    <div className="header-body-boxes header-body-boxes-hoverable">
                        <ContactUsers
                            users={contact.U}
                            contactID={contact.ID}
                        />
                    </div>
                    <NwDividerVertical />
                    <div className="header-body-boxes">
                        <ContactDepartments
                            departments={contact.DEPS}
                            contactID={contact.ID}
                        />
                    </div>
                </div>
                <div className="header-line">
                    {renderContactDetailsHeaderAnagraphical()}
                </div>
            </div>
        </HeaderWrapper>
    )
};

export default withRouter(ModelHeader);
