import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import { queryClient } from 'QueryClientProvider';

const getAgencyModels = ID => (
    Axios.get(`/contacts/agencymodels/${ID}`)
)

const useAgencyModels = (ID) => (
    useQuery(["agencyModels", ID], () => getAgencyModels(ID), {
        staleTime: 60000,
        cacheTime: 60000
    })
)

const forceReloadAgencyModels = (ID) => {
    queryClient.invalidateQueries(['agencyModels', ID])
}


export {
    useAgencyModels,
    forceReloadAgencyModels
}
