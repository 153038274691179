import React, { useState } from 'react';
import NwDrawer from 'Components/Gui/NwDrawer';
import BuildingFeature from "Components/Utils/BuildingFeature";
import { getNow, toISODate } from 'Libs/NwMoment';

const AgendaDrawer = ({ modelId, startDate, onClose }) => {
  const [dateSelected, setDateSelected] = useState(startDate || getNow());

  const [agendaParams, setAgendaParams] = useState({
    inclJobs: true,
    inclCastings: true,
    inclTodos: false,
    checkConflict: false,
    inclTravels: true,
    inclAccommodations: true,
    inclExternalJobs: true,
    inclFollowUps: true,
    inclMeetings: true,
    inclNotices: true,
    General: false,
    Warning: false,
    idmodels: [modelId]
  });

  const handleChangeDateSelected = selectedDate => {
    setDateSelected(selectedDate);
    setAgendaParams({
      ...agendaParams,
      FDate: toISODate(selectedDate.clone().startOf("month")),
      TDate: toISODate(selectedDate.clone().endOf("month"))
    })
  };

  return (
    <NwDrawer
      title="Agenda"
      placement="right"
      width="40rem"
      onClose={onClose}
    >
      {/* TODO: implementare agenda read-only */}

      <BuildingFeature />

      {/* <AgendaContainer
          mode={drawer}
          contactId={modelId}

          params={agendaParams}
          //ADD FILTERS IN AGENDA?
          //filters={filters}

          scheduleRange={scheduleRange}
          onScheduleRangeChange={onScheduleRangeChange}
          type={type}
          previewMode={previewMode}

          reload={reload}
          onReload={handleReload}
          afterReload={handleReloadComplete}

          onSelectEvent={selectEvent} //VERIFICARE

          selectedEvent={selectedEvent}
          isFetchingCastings={isFetchingCastings}
          displayedEvents={displayedEvents}
          onUpdateDateSelectedEvents={setDateSelectedEvents}
          bookingSection={bookingSection}
          onClearContactID={onClearContactID}
      /> */}
      
      {/* <ContactScheduleCard
        type='model'
        mode='drawer'
        contactId={modelId}
        dateSelected={dateSelected}
        onSetDateSelected={handleChangeDateSelected}
        onClose={onClose}
        params={agendaParams} /> */}
    </NwDrawer>
  )
};

export default AgendaDrawer;