import React from "react";
import { Formik, Field } from "formik";
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";

import {
  NWDatePicker,
  NWInput,
  NWTextAreaCharCounter,
  NWContactSelector,
  NWVisibilitySelector
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useStateValue } from 'Contexts/StateProvider';
import { validateVisibility } from "Libs/NwUtils";
import { onContactMediaUpdate } from 'Hooks/Contact/UseContactMedia';
import Axios from "axios";
import { datePickerBeforeOnChange } from "Libs/NwMoment";

const FieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 992px) {
    flex-direction: column;
}
`;

const MediaDetailForm = ({ document, contactID, onCancel, onTouchForm }) => {
    const [{ areaContext }] = useStateValue();
    const initialValues = {
        Title: '',
        ShootingDate: null,
        Location: '',
        Notes: '',
        Author: '',
        PhotographerID: null,
        PublicDescription: '',
        Visibility: {
            isGlobal: true,
            Areas: []
        }
    };

    if (document) {
        initialValues.Title = document.Title;
        initialValues.ShootingDate = document.ShootingDate;
        initialValues.Location = document.Location;
        initialValues.Notes = document.Notes ? document.Notes : '';
        initialValues.Author = document.Author;
        initialValues.PhotographerID = document.PhotographerID;
        initialValues.PublicDescription = document.PublicDescription?document.PublicDescription:'';
        if (document.Visibility) {
            initialValues.Visibility.isGlobal = document.Visibility.isGlobal;
            initialValues.Visibility.Areas = document.Visibility.Areas;
        }
    }

    const handleSaveMediaDetail = async (values, setSubmitting) => {
        setSubmitting(true);
        const updatedPictureInfo = {
            ...values,
            VisibilityAreas: values.Visibility.Areas,
            DocumentsToUpdate: [document.ID]
        }
        try {
            const media = await Axios.put(`/documents/updatemetadata`, updatedPictureInfo)
            onTouchForm(false)
            onContactMediaUpdate(contactID)
            setSubmitting(false)
            onCancel(media)
        } catch (error) {
            console.log("errore", error)
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                handleSaveMediaDetail(values, setSubmitting);
            }}
            render={({
                isSubmitting,
                values,
                handleSubmit
            }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <Field
                        component={NWVisibilitySelector}
                        label="Visibility"
                        name="Visibility"
                        value={values.Visibility}
                        validate={validateVisibility(values.Visibility, areaContext.area)}
                    />
                    <Field
                        component={NWInput}
                        label="Title"
                        name="Title"
                        type="text"
                        value={values.Title}
                    />
                    <FieldsContainer>
                        {document.TypeName !== 'Document' && 
                            <>
                                <Field
                                    component={NWInput}
                                    label="Location"
                                    name="Location"
                                    type="text"
                                    value={values.Location}
                                />
                                &nbsp;&nbsp;
                                <Field
                                    component={NWInput}
                                    label="Author"
                                    name="Author"
                                    type="text"
                                    value={values.Author}
                                />
                                &nbsp;&nbsp;
                                <Field
                                    component={NWDatePicker}
                                    label="Shooting date"
                                    name="ShootingDate"
                                    format="MMM DD, YYYY"
                                    getCalendarContainer={triggerNode => triggerNode.parentNode}
                                    style={{ width: "100%" }}
                                    value={values.ShootingDate ? getMoment(values.ShootingDate) : null}
                                    manipulateValue={datePickerBeforeOnChange}
                                />
                                &nbsp;&nbsp;
                                <Field
                                    required
                                    component={NWContactSelector}
                                    label={document.TypeName === 'Photo' ? "Photographer" : 'Director'}
                                    name="PhotographerID"
                                    activeTypes={["srv", 'cli']}
                                    // activableTypes={['cli', 'srv']}
                                    value={values.PhotographerID}
                                />
                                &nbsp;&nbsp;
                            </>
                        }
                    </FieldsContainer>
                    {document.TypeName !== 'Document' && 
                        <Field
                            component={NWInput}
                            label="Public Description"
                            name="PublicDescription"
                            type="text"
                            value={values.PublicDescription}
                        />
                    }
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Notes"
                        name="Notes"
                        value={values.Notes}
                        maxLength={2000}
                        type="text"
                        autoSize={{ minRows: 2, maxRows: 5 }}
                    />          
                    <br />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        />
    );
};

export default MediaDetailForm;
