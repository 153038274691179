import React from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import CheckListItem from './CheckListItem';
import { forceReloadProject } from 'Hooks/Project/UseProject';

const Wrapper = styled.div`
    height: 100%;
`;

const CheckList = ({ checkListItems, objectType, objectID }) => {

    const handleReload = () => {
        if (objectType === 'project') {
            forceReloadProject(objectID)
        } else {
            forceReloadContact(objectID)
        }
    };

    return (
        <Wrapper>
            {checkListItems && checkListItems.length > 0 &&
                <List
                    itemLayout="horizontal"
                    dataSource={checkListItems}
                    renderItem={item => (
                        <CheckListItem
                            key={item.CheckListItemID}
                            checkListItem={item}
                            objectID={objectID}
                            objectType={objectType}
                            onUpdate={handleReload}
                        />
                    )}
                />
            }
        </Wrapper>
    );
};

export default CheckList;