import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import EventModelActions from 'Components/EventDrawer/EventDrawerUi/EventModelActions';
import OptionNumber from 'Components/EventDrawer/EventDrawerUi/OptionNumber';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { getModelNames } from './eventDatesAndModelsUtils';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BookingEventSingleModelNameContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-arrow {
        margin-right: 0 !important;
    }
`;

const CollapseHandle = styled(FlexContainer)`
    cursor: pointer;
    margin-top: 8px;
`;

const CollapsibleModels = ({
  models,
  eventData,
  collapsed,
  eventDateInfo,
  currentModel,
  reloadGroupedModels,
  onExtendOptionNumberToAllDates,
  afterUpdate
}) => {
  const [isExpanded, setIsExpanded] = useState(collapsed || false);

  const renderSingleModel = (model, withMarginTop = false) => {
    return (
      <BookingEventSingleModelNameContainer key={model.ModelID} withMarginTop={withMarginTop}>
        <div className="model-name-container">
          <span className={`model-name${model.Cancel ? ' is-cancelled' : ''}`}>
            {getContactName(model.ModelID).toLowerCase()}
          </span>
          <div onClick={e => e.stopPropagation()}>
            <OptionNumber
                key={model.OptionNumber}
                number={model.OptionNumber}
                event={eventData}
                eventDateInfo={eventDateInfo}
                selectedModel={model}
                eventDatesModel={model}
                reloadGroupedModels={reloadGroupedModels}
                onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
            />
          </div>
        </div>
        <EventModelActions
          model={model}
          eventDateInfo={eventDateInfo}
          eventData={eventData}
          onReloadEventStatus={reloadGroupedModels}
          afterUpdate={afterUpdate}
        />
      </BookingEventSingleModelNameContainer>
    )
  }

  const handleCollapse = event => {
    event.stopPropagation();
    setIsExpanded(isExpanded ? null : '1')
  }

  return (
    <StyledCollapse
      activeKey={isExpanded ? '1' : null}
      onChange={() => setIsExpanded(isExpanded ? null : '1')}
      ghost
      showArrow={false}
      accordion={true}
      expandIcon={() => <div />}
      bordered={false}
      className="event-info-collapse"
    >
      <Panel header={isExpanded ? renderSingleModel(models[0], 0) : getModelNames(models)} key="1">
        {models.map((model, index) => {
            if (index === 0) {
              return null
            }
            return renderSingleModel(model, index, true)
          })
        }
        <CollapseHandle onClick={handleCollapse}>
            <NwIcon icon={light('chevron-up')} />&nbsp;COLLAPSE LIST
        </CollapseHandle>
      </Panel>
    </StyledCollapse>
  );
}

// CollapsibleModels.propTypes = {
//   models: PropTypes.arrayOf(PropTypes.shape({
//     ModelID: PropTypes.number.isRequired,
//     OptionNumber: PropTypes.number,
//     Cancel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   })).isRequired,
//   currentModel: PropTypes.shape({
//     ID: PropTypes.number.isRequired
//   }),
//   reloadGroupedModels: PropTypes.func.isRequired,
//   afterUpdate: PropTypes.func.isRequired,
//   onExtendOptionNumberToAllDates: PropTypes.func.isRequired,
//   eventData: PropTypes.shape({
//     ID: PropTypes.number.isRequired
//   }).isRequired,
//   eventDateInfo: PropTypes.shape({
//     ID: PropTypes.number.isRequired
//   }).isRequired
// }

export default CollapsibleModels;