import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

const SiderStyled = styled(Sider)`
    height: calc(100vh - 60px);
    background-color: #fff;
    z-index: ${props => props.$zindex ? props.$zindex : 1030};
    box-shadow: ${props => props.theme.SiderShadow};
    position: fixed;
    padding: ${props => props.$moreBottomPadding ? "1rem 1rem 2rem 1rem" : "1rem"};
    overflow: auto;
`

export default SiderStyled;