import React from "react"; // { useState, useRef, useEffect }
import PlayListView from 'Components/MediaDrawer/PlayListView';
import NwDrawer from "Components/Gui/NwDrawer";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import {  usePlaylistPreview } from "Hooks/Playlist/UsePlaylist"; //forceReloadPlaylist,

const PlayListPreviewDrawer = ({ playlist, contactID, onClose }) => {

  const { data: playlistData } = usePlaylistPreview(playlist.ID)

  return (
    <NwDrawer
      onClose={onClose}
      modalDrawer
      title={`${getContactName(playlist.ContactID || contactID)} / ${playlist.Name}`}
      >
      {playlistData &&
          <PlayListView playlistID={playlistData.ID} />
      }
    </NwDrawer>
  )
};

export default PlayListPreviewDrawer;
