import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isSameDate } from 'Libs/NwMoment';
import { getConfirmationStyle, getMixedStyle, getOptionStyle, getOptionStyleNoLabel } from 'Libs/EventStylesUtils';

const isConfirmed = (event) => {
    if (event.minimal) {
        if (event.Confirmed) {
            return "confirmed"
        }
        return "option"
    }
    if (event.Cancel) {
        if (event.ModelsDates) {
            const hasconfirmed = event.ModelsDates.some(md => md.Models.some(m => m.OptionNumber === 10));
            const hasoptioned = event.ModelsDates.some(md => md.Models.some(m => m.OptionNumber < 10));
            if (hasconfirmed) {
                if (hasoptioned) {
                    return "mixed"
                } else {
                    return "confirmed"
                }
            } else {
                return "option"
            }
        }
        if (event.EventDates) {
            const hasconfirmed = event.EventDates.some(ed => ed.EventDatesModels.some(m => m.OptionNumber === 10));
            const hasoptioned = event.EventDates.some(ed => ed.EventDatesModels.some(m => m.OptionNumber < 10));
            if (hasconfirmed) {
                if (hasoptioned) {
                    return "mixed"
                } else {
                    return "confirmed"
                }
            } else {
                return "option"
            }
        }
    } else {
        switch (event.Confirmed) {
            case 1:
                return "mixed"
            case 2:
                return "confirmed"
            default:
                return "option"
        }
    }
}

const isConfirmedForDate = (event, date) => {
    if (event.minimal) {
        if (event.Confirmed) {
            return "confirmed"
        }
        return "option"
    }
    if (event.ModelsDates) {
        const eventdate = event.ModelsDates.find(md => isSameDate(md.FromDate, date));
        if (eventdate) {
            const hasconfirmed = eventdate.Models.some(m => m.OptionNumber === 10);
            const hasoptioned = eventdate.Models.some(m => m.OptionNumber < 10);
            if (hasconfirmed) {
                if (hasoptioned) {
                    return "mixed"
                } else {
                    return "confirmed"
                }
            } else {
                return "option"
            }
        } else {
            return isConfirmed(event)
        }
    }
    if (event.EventDates) {
        const eventdate = event.EventDates.find(ed => isSameDate(ed.FromDate, date));
        if (eventdate) {
            const hasconfirmed = eventdate.EventDatesModels.some(m => m.OptionNumber === 10);
            const hasoptioned = eventdate.EventDatesModels.some(m => m.OptionNumber < 10);
            if (hasconfirmed) {
                if (hasoptioned) {
                    return "mixed"
                } else {
                    return "confirmed"
                }
            } else {
                return "option"
            }
        } else {
            return isConfirmed(event)
        }
    }
}

const isConfirmedForModel = (event, modelID) => {
    if (event.minimal) {
        if (event.Confirmed) {
            return "confirmed"
        }
        return "option"
    }
    if (event.ModelsDates) {
        const hasconfirmed = event.ModelsDates.some(md => md.Models.some(m => m.OptionNumber === 10 && m.ModelID === modelID));
        const hasoptioned = event.ModelsDates.some(md => md.Models.some(m => m.OptionNumber < 10 && m.ModelID === modelID));
        if (hasconfirmed) {
            if (hasoptioned) {
                return "mixed"
            } else {
                return "confirmed"
            }
        } else {
            return "option"
        }
    }
    if (event.EventDates) {
        const hasconfirmed = event.EventDates.some(md => md.EventDatesModels.some(m => m.OptionNumber === 10 && m.ModelID === modelID));
        const hasoptioned = event.EventDates.some(md => md.EventDatesModels.some(m => m.OptionNumber < 10 && m.ModelID === modelID));
        if (hasconfirmed) {
            if (hasoptioned) {
                return "mixed"
            } else {
                return "confirmed"
            }
        } else {
            return "option"
        }
    }

}

const isConfirmedForModelAndDate = (event, modelID, date) => {
    if (event.minimal) {
        if (event.Confirmed) {
            return "confirmed"
        }
        return "option"
    }
    if (event.ModelsDates) {
        const eventdate = event.ModelsDates.find(md => isSameDate(md.FromDate, date));
        if (eventdate) {
            const isconfirmed = eventdate.Models.some(m => m.OptionNumber === 10 && m.ModelID === modelID);
            if (isconfirmed) {
                return "confirmed"
            }
            return "option"
        } else {
            return isConfirmedForModel(event, modelID)
        }
    }
    if (event.EventDates) {
        const eventdate = event.EventDates.find(ed => isSameDate(ed.FromDate, date));
        if (eventdate) {
            const isconfirmed = eventdate.EventDatesModels.some(m => m.OptionNumber === 10 && m.ModelID === modelID);
            if (isconfirmed) {
                return "confirmed"
            }
            return "option"
        } else {
            return isConfirmedForModel(event, modelID)
        }
    }
}

//this method can receive an event in 3 different formats (full details, single models details or minimal - in transaction details)
const useEventStyle = (eventdata, evtype, forModel, forDate, isMinimal) => {
    const event = {...eventdata, minimal: isMinimal}
    
    const { cachedList } = useContext(ListContext);
    const typeName = event && (event.TypeName && event.TypeName.toLowerCase() === "accommodation" &&
        ((event.SubTypeCode === "1" && event.SubTypeCode) || event.RoomID)) ? "RoomLodging" : event.TypeName;
    let eventtype = null
    if (typeName) {
        eventtype = cachedList.eventTypes.items.find((et) => (et.code.toLowerCase() === typeName.toLowerCase()))
    } else {
        if (evtype) {
            eventtype = cachedList.eventTypes.items.find((et) => (et.code.toLowerCase() === evtype.toLowerCase()))
        }
    }
    if (!eventtype) {
        return null;
    }

    let bgcolor = eventtype.baseColor
    let color = eventtype.textColor
    let icon = eventtype.icon
    let name = eventtype.name

    switch (eventtype.code.toLowerCase()) {
        case "casting":
            if (event.SubTypeCode) {
                const st = eventtype.subTypes.find((st) => {
                    return st.Code.toLowerCase() === event.SubTypeCode.toLowerCase()
                })
                if (st) {
                    name = st.Name
                    bgcolor = `#${st.BackColor}`
                    color = `#${st.ForeColor}`
                }
            } else {
                if (eventtype.subTypes && eventtype.subTypes.length > 0) {
                    const st = eventtype.subTypes.find((st) => ( st.Default ))
                    if (st) {
                        name = st.Name
                        bgcolor = `#${st.BackColor}`
                        color = `#${st.ForeColor}`
                    }
                }
            }
            break
        case "job":
            let mixcolors = null;
            let confcolors = null;
            let optcolors = null;
            if (event.SubTypeCode) {
                const st = eventtype.subTypes.find((st) => {
                    return st.Code.toLowerCase() === event.SubTypeCode.toLowerCase()
                })
                if (st) {
                    mixcolors = getMixedStyle(st)
                    confcolors = getConfirmationStyle(st)
                    optcolors = getOptionStyle(st)
                }
            } else {
                if (eventtype.subTypes && eventtype.subTypes.length > 0) {
                    const st = eventtype.subTypes.find((st) => ( st.Default ))
                    if (st) {
                        mixcolors = getMixedStyle(st)
                        confcolors = getConfirmationStyle(st)
                        optcolors = getOptionStyle(st)
                    }
                } else {
                    confcolors = { bgcolor: bgcolor, name: name, color: color }
                    mixcolors = { ...confcolors }
                    optcolors = getOptionStyleNoLabel(eventtype)
                }
            }

            let confirmationStatus = ""
            if (event.OptionNumber) {
                if (event.OptionNumber === 10) {
                    confirmationStatus = "confirmed"
                } else {
                    confirmationStatus = "option"
                }
            } else {
                if (forDate) {
                    if (forModel) {
                        confirmationStatus = isConfirmedForModelAndDate(event, forModel, forDate)
                    } else {
                        confirmationStatus = isConfirmedForDate(event, forDate)
                    }
                } else {
                    if (forModel) {
                        confirmationStatus = isConfirmedForModel(event, forModel)
                    } else {
                        confirmationStatus = isConfirmed(event)
                    }
                }
            }
            switch (confirmationStatus) {
                case "confirmed":
                    bgcolor = confcolors.bgcolor
                    color = confcolors.color
                    name = confcolors.name
                    break
                case "mixed":
                    bgcolor = mixcolors.bgcolor
                    color = mixcolors.color
                    name = mixcolors.name
                    break
                case "option":
                    bgcolor = optcolors.bgcolor
                    color = optcolors.color
                    name = optcolors.name
                    break
                default:
                    break
            }
            break;
        case "todo":
            if (event.Completed && eventtype.secondaryColor) {
                bgcolor = eventtype.secondaryColor
            }
            if (event.Completed && eventtype.secondaryTextColor) {
                color = eventtype.secondaryTextColor
            }
            if (event.Completed && eventtype.secondaryIcon) {
                icon = eventtype.secondaryIcon
            }
            break
        case "followup":
            if (event.SubTypeCode) {
                const followupLabel = cachedList.followUpLabels.items.find(item => item.Code === event.SubTypeCode);
                if (followupLabel) {
                    bgcolor = followupLabel.BackColor;
                    color = followupLabel.ForeColor;
                    if (followupLabel.ModelNotAvailable) {
                        icon = solid('do-not-enter');
                    }
                    name = followupLabel.Label
                }
            }
            break
        case "extjob":
            if (event.Confirmed === 0 && eventtype.secondaryColor) {
                bgcolor = eventtype.secondaryColor
            }
            if (event.Confirmed === 0 && eventtype.secondaryTextColor) {
                color = eventtype.secondaryTextColor
            }
            if (event.Confirmed === 0 && eventtype.secondaryIcon) {
                icon = eventtype.secondaryIcon
            }
            break
        default:
            break
    }
    return { color: color, backgroundColor: bgcolor, icon: icon, name: name }
}

export default useEventStyle;