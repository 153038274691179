import React, { useState } from "react";  //useContext, 
import styled from 'styled-components';
import useConfigurations from "Hooks/UseConfigurations";
import { Row, Col, Alert } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
    NWSelectCountry,
    NWInput,
    NWCitySelector,
    NWRadioButtonGroup
} from "Components/Gui/NWForm/NwFormItems";
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useStateValue } from 'Contexts/StateProvider';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { forceReloadContacts, forceReloadCustomersWall, forceReloadCustomers } from 'Hooks/Contact/UseContactsList';
import { useUserPermission } from "Contexts/UserContext";
import NotAllowedFeature from "Components/Utils/NotAllowedFeature";
import { useMutation } from "@tanstack/react-query";

const CUSTOMER_TYPES = ['Client', 'Agency', 'Service'];

const DuplicateCustomersContainer = styled.div`
    
    div.duplicate-customer-item {
        padding: .5rem 2rem;
        border-top: ${props => props.theme.DefaultBorder};
        p {
            margin: .25rem 0;
        }
        .duplicate-customer-networkagencies {
            p {   
                font-size: ${props => props.theme.fontSizeSm};
                &.storage {
                    text-decoration: line-through;
                }
            }
        }
    }
`


const getDefaultCustomerType = area => {
    if (area === 'scouting') {
        return 'Agency';
    }
    return 'Client';
}

const CreateCustomerForm = ({ onCustomerCreated, type, onCancel, onTouchForm, customerName }) => {
    useFormFieldFocus('Name');

    const [{ areaContext }] = useStateValue();
    const checkDuplicates = true;
    const [duplicateCustomers, setDuplicateCustomers] = useState(null);

    const configurations = useConfigurations();

    const canCreateClients = useUserPermission('CreateClients');
    const canCreateAgencies = useUserPermission('CreateAgencies');
    const canCreateServices = useUserPermission('CreateServices');


    const mutation = useMutation({
        mutationFn: (values) => {
            return Axios.post('/customers', values)
        },
        onSuccess: (data) => {
            forceReloadCustomers()
            forceReloadCustomersWall()
            forceReloadContacts()
            onCustomerCreated({
                ...data
            });
        }
    })

    if (!canCreateClients && !canCreateAgencies && !canCreateServices) {
        return (<NotAllowedFeature />)
    }

    const getInitialCustomerType = () => {
        const typetoset = CUSTOMER_TYPES.find(item => item === type) ? type : getDefaultCustomerType(areaContext.area)
        if (typetoset === 'Service' && !canCreateServices) {
            return '';
        }
        if (typetoset === 'Agency' && !canCreateAgencies) {
            return '';
        }
        if (typetoset === 'Client' && !canCreateClients) {
            return '';
        }
        return typetoset;
    }

    const initialValues = {
        Name: customerName || '',
        Email: '',
        CustomerType: getInitialCustomerType(),
        NationalityCode: configurations.defaultCountry,
        City: "",
        LandPhone: '',
        MobilePhone: ''
    };

    const beforeSubmit = (values, setSubmitting, resetForm) => {
        if (checkDuplicates) {
            const dataCheck = {
                Name: values.Name,
                Email: values.Email,
                NationalityCode: values.NationalityCode || null
            }

            Axios.post(`/customers/CheckDuplicates`, dataCheck)
                .then(response => {
                    if (response.length > 0) {
                        setSubmitting(false)
                        setDuplicateCustomers({ duplicates: response })
                    } else {
                        submitForm(values, setSubmitting, resetForm)
                    }
                })
                .catch(error => {
                    setSubmitting(false)
                    console.log("errore", error)
                });
        } else {
            submitForm(values, setSubmitting, resetForm)
        }
    };

    

    const submitForm = async (values, setSubmitting, resetForm) => {
        try {
            //const response = await Axios.post(`/customers`, values);

            await mutation.mutateAsync(values)

            if (onTouchForm) {
                onTouchForm(false);
            }

            //await prefetchCustomers()

            
            setSubmitting(false)
            resetForm()
        } catch (error) {
            setSubmitting(false)
            console.log("errore", error)
        }
    };

    const checkDisabled = (customerType) => {
        switch (customerType.toLowerCase()) {
            case 'client':
                return !canCreateClients;
            case 'agency':
                return !canCreateAgencies;
            case 'service':
                return !canCreateServices;
            default:
                return true;
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Email: Yup.string().email("Invalid email format"),
                    Name: Yup.string().required("Content is required"),
                    CustomerType: Yup.string().required("No customer type selected"),
                    NationalityCode: Yup.string().required("Content is required")
                })}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    setStatus();
                    beforeSubmit(values, setSubmitting, resetForm);
                }}>
                {({ values, handleSubmit, resetForm, setSubmitting, isSubmitting, isValid }) => (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        {(duplicateCustomers && duplicateCustomers.duplicates && duplicateCustomers.duplicates.length > 0)
                            ?
                            <>
                                <DuplicateCustomersContainer>
                                    <Alert showIcon message={duplicateCustomers.duplicates.length > 1 ? `${duplicateCustomers.duplicates.length} clients/agencies/services with similar names. Please check the list below to make sure you're not creating a duplicated record.` : `A client/agency/service with similar name. Please make sure you're not creating a duplicated record.`} type="warning" />
                                    {duplicateCustomers.duplicates.map((m) => (
                                        <div className="duplicate-customer-item">
                                            <p className="duplicate-customer-name"><strong>{m.Name}</strong></p>
                                            <div className="duplicate-customer-networkagencies">
                                                {m.AccountingGroups.map((ag) => (
                                                    ag.NetworkAgencies.map((na) => (
                                                        <p key={ag.NetworkAgencyID} className={na.StorageBooking && "storage"}>
                                                            {na.StorageBooking
                                                                ?
                                                                <NwIcon icon={light("box-archive")} />
                                                                :
                                                                <NwIcon icon={light("wave-pulse")} />
                                                            }
                                                            &nbsp;
                                                            {na.NetworkAgencyName}
                                                        </p>
                                                    ))
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </DuplicateCustomersContainer >
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            onClick={() => {
                                                setSubmitting(false);
                                                setDuplicateCustomers(null);
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            label="Save anyway"
                                            onClick={() => {
                                                submitForm(values, setSubmitting, resetForm)
                                                setDuplicateCustomers(null);
                                            }}
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                            </>
                            :
                            <>
                                <Row gutter={10}>
                                    <Col span={24}>
                                        <Field
                                            component={NWInput}
                                            label="Name"
                                            name="Name"
                                            type="text"
                                            id="Name"
                                            value={values.Name}
                                        />
                                    </Col>
                                </Row>
                                <Field
                                    component={NWRadioButtonGroup}
                                    value={values.CustomerType}
                                    name="CustomerType"
                                    label='Customer Type'
                                    buttonStyle="solid"
                                    options={CUSTOMER_TYPES.map(customerType => {
                                        return {
                                            key: customerType,
                                            value: customerType,
                                            label: customerType,
                                            disabled: checkDisabled(customerType)
                                        };
                                    })}
                                />
                                <Row gutter={10}>
                                    <Col span={24}>
                                        <Field
                                            component={NWInput}
                                            label="Email"
                                            name="Email"
                                            type="text"
                                            value={values.Email}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={NWSelectCountry}
                                            label="Country"
                                            name="NationalityCode"
                                            value={values.NationalityCode}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={NWCitySelector}
                                            label="City"
                                            name="City"
                                            value={values.City}
                                        />
                                    </Col>
                                </Row>
                                <Field
                                    component={NWInput}
                                    label={
                                        <FlexContainer>
                                            <NwIcon icon={light('phone-office')} />&nbsp;
                                            <div>Land Phone Number</div>
                                        </FlexContainer>
                                    }
                                    name="LandPhone"
                                    type="text"
                                    value={values.LandPhone}
                                />
                                <Field
                                    component={NWInput}
                                    label={
                                        <FlexContainer>
                                            <NwIcon icon={solid('mobile-alt')} />&nbsp;
                                            <div>Mobile Phone Number</div>
                                        </FlexContainer>
                                    }
                                    name="MobilePhone"
                                    type="text"
                                    value={values.MobilePhone}
                                />
                                <br />
                                <br />
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                if (onTouchForm) {
                                                    onTouchForm(false);
                                                }
                                                onCancel();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            onClick={handleSubmit}
                                            disabled={!isValid}
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                            </>
                        }
                    </NwForm>
                )}
            </Formik>
        </>
    );
};

export default CreateCustomerForm;