import React, { useState, useEffect } from "react";
import { Loading } from 'Components/Gui/Loading';
import { EventContainer, } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
import { useTravel, forceReloadTravel } from 'Hooks/Event/UseTravel';
import NwDrawer from 'Components/Gui/NwDrawer';
import TravelForm from 'Components/EventDrawer/TravelsEvent/TravelForm';
import EventDrawerDetailsContainer from "Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer";
import TravelContent from "./TravelContent";
import Axios from "axios";
import { notification } from "antd";
import NwIcon from "Components/Gui/NwIcon";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const TravelWrapper = ({
    afterRemove,
    afterUpdate,
    allDay,
    dateSelected,
    filterModel,
    ID,
    modelId,
    onClose,
    onSelectEvent,
    onTouchForm,
    plainMode,
    projectId,
    viewMode,
}) => {
    const [travelID, setTravelID] = useState(ID);
    const [isEdit, setIsEdit] = useState(viewMode ? true : false);
    
    const { data: travelsData, isFetching, status } = useTravel(travelID);
    
    const [viewDetails, setViewDetails] = useState(false);
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setViewDetails(false);
    }, [filterModel]);

    useEffect(() => {
        if (ID) {
            setTravelID(ID)
        }
    }, [ID])

    const handleSelectProject = async project => {
        try {
            //await onSetTravelProject({ travelId: travelsData.ID, projectId: project });
            const err = await Axios.put('/travels/SetProject', {
                ObjectID: travelsData.ID,
                ProjectID: project
            })
            console.log("err in try", err)
            forceReloadTravel(travelsData.ID);
            await afterUpdate();
        } catch (error) {
            //console.log('ant : select project error => ', error)
            if (error.errorCode && error.errorCode === "GenericError") {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: error.message,
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
            }
        }
    };

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const getTravelFormProps = () => ({
        plainMode: plainMode,
        travelsData: editModelEvent ? editModelEvent : travelsData,
        editModelEvent: editModelEvent ? true : false,
        viewMode: viewMode,
        modelId: modelId,
        projectId: projectId,
        dateSelected: dateSelected,
        allDay: allDay,
        afterUpdate: afterUpdate,
        onTouchForm: touchForm => {
            if (travelsData) {
                setTouchForm(touchForm);
            } else {
                onTouchForm(touchForm);
            }
        },
        onCancel: () => {
            handleClearEditEvent();
            handleClearTouchForm();
            if (travelsData) {
                setIsEdit(false);
            } else {
                onClose();
            }
        },
        onUpdateTravelData: (id) => {
            if (id) {
                setTravelID(id)
            } else {
                forceReloadTravel(travelID)
            }
            handleClearTouchForm();
            handleClearEditEvent();
        }
    })

    if ((!travelsData && travelID) || status === 'Loading') {
        return (
            <Loading textBlack />
        )
    }
    if (!travelsData && isEdit) {
        return (
            <EventContainer>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">Add new travel</div>
                </div>
                <TravelForm {...getTravelFormProps()} />
            </EventContainer>
        )
    }
    return (
        <>
            {plainMode  //travel card in the project page
                ?
                <EventContainer hasProject={travelsData && travelsData.Project}>
                    <div className="event-content-wrapper">
                        {(!plainMode && travelsData.Project) &&
                            <ProjectEventsTimeline
                                event={{
                                    ...travelsData,
                                    TypeName: "Travel"
                                }}
                                projectID={travelsData.Project.ID}
                                onSelectEvent={onSelectEvent} />
                        }
                        <TravelContent
                            afterRemove={afterRemove}
                            afterUpdate={afterUpdate}
                            filterModel={filterModel}
                            handleSelectProject={handleSelectProject}
                            isEdit={isEdit}
                            onTouchForm={onTouchForm}
                            plainMode={plainMode}
                            projectId={projectId}
                            setEditModelEvent={setEditModelEvent}
                            setIsEdit={setIsEdit}
                            setViewDetails={setViewDetails}
                            travelID={travelID}
                            travelsData={travelsData}
                            viewDetails={viewDetails}
                        />
                    </div>
                </EventContainer>
                :
                <EventDrawerDetailsContainer
                    eventData={{ ...travelsData, elType: 'Travel', uniqID: `Travel-${travelsData.ID}` }}
                    filterModel={modelId}
                    isFetching={isFetching}
                    typeName="travel"
                    onSelectEvent={onSelectEvent}
                    onSelectProject={handleSelectProject}
                    firstTab={
                        <div className="event-content-wrapper">
                            {(!plainMode && travelsData.Project) &&
                                <ProjectEventsTimeline
                                    event={{
                                        ...travelsData,
                                        TypeName: "Travel"
                                    }}
                                    projectID={travelsData.Project.ID}
                                    onSelectEvent={onSelectEvent} />
                            }
                            <TravelContent
                                afterRemove={afterRemove}
                                afterUpdate={afterUpdate}
                                filterModel={filterModel}
                                handleSelectProject={handleSelectProject}
                                isEdit={isEdit}
                                onTouchForm={onTouchForm}
                                plainMode={plainMode}
                                projectId={projectId}
                                setEditModelEvent={setEditModelEvent}
                                setIsEdit={setIsEdit}
                                setViewDetails={setViewDetails}
                                travelID={travelID}
                                travelsData={travelsData}
                                viewDetails={viewDetails}
                            />
                        </div>
                    }
                />
            }
            {isEdit &&
                <NwDrawer
                    padding="0px"
                    width={740}
                    title='Edit Travel'
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        handleClearEditEvent();
                        setIsEdit(false);
                        handleClearTouchForm();
                    }}
                >
                    <EventContainer>
                        <TravelForm {...getTravelFormProps()} />
                    </EventContainer>
                </NwDrawer>
            }
        </>
    )
};

export default TravelWrapper;
