import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';

const saveTasksSmartList = async customs => {
  try {
      await Axios.post('/userclientconfigurations', {
      Name: 'todo-smart-list',
      JsonData: JSON.stringify({
          smartList: customs
      })
    });
  } catch (error) {
    console.log('ant : Save Todos Smart List Error => ', error);
  }
};

const TaskFiltersReducer = (state, action) => {
  switch (action.type) {
    case "TaskFiltersChangeActiveSet":
      ls.set("nw_config_taskwallfilter", {
          ...state,
          activeSet: action.newActiveSet,
          filterType: action.filterType
      });
      return {
        ...state,
        activeSet: action.newActiveSet,
        filterType: action.filterType
      };
    case "TaskFiltersAddCustomSet":
      const customSetCopy = Array.isArray(state.customSets)?[...state.customSets]:[];
      customSetCopy.push(action.newSet);
      const data = {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      }
      ls.set("nw_config_taskwallfilter", data);
      let smartList = [...data.customSets];
      saveTasksSmartList(smartList);
      return {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      };
    case "TaskFiltersEditCustomSet":
      let customSetCopy2 = state.customSets.filter(
        el => el.id !== action.newSet.id
      );
      action.newSet.id = Date.now();
      customSetCopy2.push(action.newSet);
      const editData = {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };
      ls.set("nw_config_taskwallfilter", editData);
      let editSmartList = editData.customSets
      saveTasksSmartList(editSmartList);
      return {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };
    case "TaskFiltersCloneSet":
      const copyOfActive = _.cloneDeep(action.activeSet);
      copyOfActive.id = Date.now();
      copyOfActive.fixed = false;
      copyOfActive.name = copyOfActive.name + "(copy)";
      const copyOfCustomSets = [...state.customSets];
      copyOfCustomSets.push(copyOfActive);
      const cloneData = {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };
      ls.set("nw_config_taskwallfilter", cloneData);
      let cloneSmartList = [...cloneData.customSets];
      saveTasksSmartList(cloneSmartList);
      return {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };
    case "TaskFiltersDeleteSet":
      const newCustomSets = [...state.customSets].filter(
        el => el.id !== action.activeSet
      );
      const deleteData = {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v0'
      };
      ls.set("nw_config_taskwallfilter", deleteData);
      let deleteSmartList = [...deleteData.customSets];
      saveTasksSmartList(deleteSmartList);
      return {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v0'
      };
    case "TaskSaveCustomSets":
        let newFilterData = { ...state, customSets: action.customSets };
        ls.set("nw_config_taskwallfilter", newFilterData);
        return newFilterData;
    default:
      return state;
  }
};

export default TaskFiltersReducer;
