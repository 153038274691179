import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Typography } from 'antd';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';

const DropdownContainer = styled.div`
    border: 1px solid ${props => props.theme.LighterBorderColor};
    border-radius: 5px;
    position: absolute;
    z-index: 3000;
    width: 100%;
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 8px;

    .ant-typography {
        white-space: nowrap;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const Loading = styled.div`
    z-index: 3000;
`;

const Option = styled.div`
    padding: 4px 8px;
`;


const GooglePlaces = ({ clearable, disableHeader, onSetAddress, suffix }) => {
    const [address, setAddress] = useState('');
    const [editing, setEditing] = useState(false);
    // const [detailAddress, setDetailAddress] = useState();

    const handleChange = address => {
        setAddress(address);
        if (editing) {
            setEditing(false);
        }
    };
    
    const handleSelect = address => {
        geocodeByAddress(address)
        .then(results => {
            setAddress(results[0].formatted_address);
            setEditing(true);
            handleSetAddress(results[0]);
        })
        .catch(error => console.error('Error', error));
    };

    const handleSetAddress = selectedAddress => {
        const componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            administrative_area_level_2: 'short_name',
            country: 'short_name',
            postal_code: 'short_name',
            postal_town: 'long_name'
        };
        let address = {};
        console.log("selected address", selectedAddress)
        for (let i = 0; i < selectedAddress.address_components.length; i++) {
            const addressType = selectedAddress.address_components[i].types[0];
            if (componentForm[addressType]) {
                address[addressType] = selectedAddress.address_components[i][componentForm[addressType]];
            }
        };
        
        onSetAddress({
            CountryCode: address.country ? address.country : '',
            City: (address.locality ? address.locality : '') || (address.postal_town ? address.postal_town : ''),
            State: address.administrative_area_level_1 ? address.administrative_area_level_1 : '',
            County: address.administrative_area_level_2 ? address.administrative_area_level_2 : '',
            Zip: address.postal_code ? address.postal_code : '',
            Street: `${address.route ? address.route : ''} ${address.street_number ? address.street_number : ''}`
        });
    };

    const handleClearAddress = () => {
        setAddress('');
        onSetAddress({
            CountryCode: '',
            City: '',
            County: '',
            State: '',
            Zip: '',
            Street: '',
        });
    };
    
    return (
        <>
            {!disableHeader && 
                <Header>
                    <Typography>Search Address</Typography>
                </Header>
            }
            <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <Container>
                    <Input
                        autoComplete='off'
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                        })}
                        suffix={suffix}
                    />
                    {suggestions.length > 0 && 
                        <DropdownContainer className="autocomplete-dropdown-container">
                            {loading ? <Loading>Loading...</Loading>
                            : suggestions.map((suggestion, index) => {
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <Option
                                        key={`google-place-${index}-${suggestion.placeId}-${suggestion.description}`}
                                        {...getSuggestionItemProps(suggestion, {
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </Option>
                                );
                            })}
                        </DropdownContainer>
                    }
                </Container>
                )}
            </PlacesAutocomplete>
            {clearable && 
                <FlexContainer justifyContent='flex-end' mg='8px 0'>
                    <NwButton primary={true} size='small' label='Clear' onClick={handleClearAddress} />
                </FlexContainer>
            }
        </>
    );
}

export default GooglePlaces