import React from "react";

import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const AreaSelector = props => {
    return (
        <RadioButtonGroup 
            disabled={props.disabled}
            value={props.value} 
            onChange={props.onChange} 
            defaultValue={'booking'}
            options={[
                { label: 'Booking', value: 'booking' },
                { label: 'Scouting', value: 'scouting'}
            ]} />
    );
};

export default AreaSelector;





