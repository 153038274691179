import React from "react";
import NwIcon from "Components/Gui/NwIcon";
import { solid, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { showDate } from "Libs/NwMoment";
import styled from "styled-components";

const CalendarIcon = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: .6em;
    border: ${props => props.calendarIconColor ? `1px solid ${props.calendarIconColor}` : `1px solid ${props.theme.DarkerBorderColor}`};
    position: relative;
    overflow: hidden;
    min-width: 4em;
    min-height: 3.8em;
    margin-top: 1em;

    .month-name {
        position: absolute;
        top: 0;
        width: 6.5em;
        font-size: .6rem;
        padding: 0;
        background-color: ${props => props.calendarIconColor ? props.calendarIconColor : props.theme.PrimaryColor};
        color: #fff;
        text-transform: uppercase;
    }
    .day-number {
        padding-top: .3rem;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1em;
    }
    .addible {
        display: none;
        font-size: 1.5rem;
        margin-top: .75rem;
        opacity: .75;
        color: ${props => props.theme.PrimaryColor};
    }
    .loading {
        font-size: 1.5rem;
        margin-top: .75rem;
    }
    .day-name {
        position: absolute;
        bottom: 0.15em;
        font-size: .6rem;
        text-transform: uppercase;
        color: ${props => props.theme.TextColorMuted};
    }

    &:hover {
        .addible {
            display: block;
        }
        .day-number {
            display: ${props => props.addible ? 'none' : 'block'};
        }
        .day-name {
            display: ${props => props.addible ? 'none' : 'block'};
        }
    }

    &.calendar-icon-stacked {
        box-shadow: 4px -3px 0 -1px #fff, 4px -3px ${props => props.theme.DarkerBorderColor},
        8px -6px 0 -1px #fff, 8px -6px ${props => props.theme.DarkerBorderColor}, 
        12px -9px 0 -1px #fff, 12px -9px ${props => props.theme.DarkerBorderColor}; 
    }
`

const CalendarIconDate = ({ date, color, stacked, addible, loading }) => {

    let className = "calendar-icon"
    if (stacked) {
        className += " calendar-icon-stacked"
    }
    if (loading) {
        return (
            <CalendarIcon className={className} calendarIconColor={color} addible={addible}>
                <div className="month-name">
                    &nbsp;
                </div>
                <div className="loading">
                    <NwIcon icon={light("spinner-third")} spin />
                </div>
            </CalendarIcon>
        )
    }
    if (!date) {
        return (
            <CalendarIcon className={className} calendarIconColor={color} addible={addible}>
                <div className="month-name">
                    &nbsp;
                </div>
                <div className="day-number"><NwIcon icon={solid("plus")} /></div>
                <div className="day-name">
                    ALL DATES
                </div>
            </CalendarIcon>
        )
    }
    return (
        <CalendarIcon className={className} calendarIconColor={color} addible={addible}>
            <div className="month-name">
                {showDate(date, 'onlyMonth')}
            </div>
            <div className="day-number">
                {showDate(date, 'onlyDay')}
            </div>
            {addible &&
                <div className="addible"><NwIcon icon={solid("plus")} /></div>
            }
            <div className="day-name">
                {showDate(date, 'weekDay')}
            </div>
        </CalendarIcon>
    )
}

export default CalendarIconDate