//import OpenPackagesReducer from 'Contexts/OpenPackagesReducer';
import OpenFoldersReducer from 'Contexts/OpenFoldersReducer';
import ModelFiltersReducer from 'Contexts/ModelFiltersReducer.js';
//import ModelFiltersDrawerReducer from 'Contexts/ModelFiltersDrawerReducer';
import BillingProjectsFiltersReducer from 'Contexts/BillingProjectsFiltersReducer';
import InvoiceFiltersReducer from 'Contexts/InvoiceFiltersReducer';
import CustomerFiltersReducer from 'Contexts/CustomerFiltersReducer';
import EventFiltersReducer from 'Contexts/EventFiltersReducer';
import AreaReducer from 'Contexts/AreaReducer';
import LoadingReducer from 'Contexts/LoadingReducer';
import TravelPlanFilterReducer from 'Contexts/TravelPlanFilterReducer';
import StatementFilterReducer from 'Contexts/StatementFilterReducer';
import TaskFiltersReducer from 'Contexts/TaskFiltersReducer';
import PackageFiltersReducer from 'Contexts/PackageFiltersReducer';

const NavigationReducer = ({ 
    folderContext, 
    modelFiltersContext, 
    //modelFiltersDrawerContext,
    customerFiltersContext,
    billingProjectsFiltersContext,
    invoicesFiltersContext,
    eventFiltersContext,
    areaContext ,
    loadingStatusContext,
    travelPlanFiltersContext,
    statementFiltersContext,
    taskFiltersContext,
    packageFiltersContext,
}, action) => ({
    folderContext: OpenFoldersReducer(folderContext, action),
    modelFiltersContext: ModelFiltersReducer(modelFiltersContext, action),
    //modelFiltersDrawerContext: ModelFiltersDrawerReducer(modelFiltersDrawerContext, action),
    customerFiltersContext: CustomerFiltersReducer(customerFiltersContext, action),
    billingProjectsFiltersContext: BillingProjectsFiltersReducer(billingProjectsFiltersContext, action),
    invoicesFiltersContext: InvoiceFiltersReducer(invoicesFiltersContext, action),
    eventFiltersContext: EventFiltersReducer(eventFiltersContext, action),
    areaContext: AreaReducer(areaContext, action),
    loadingStatusContext: LoadingReducer(loadingStatusContext, action),
    travelPlanFiltersContext: TravelPlanFilterReducer(travelPlanFiltersContext, action),
    statementFiltersContext: StatementFilterReducer(statementFiltersContext, action),
    taskFiltersContext: TaskFiltersReducer(taskFiltersContext, action),
    packageFiltersContext: PackageFiltersReducer(packageFiltersContext, action)
});

export default NavigationReducer