import React, { Component } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import StyleVariables from 'Components/Gui/StyleVariables.js';

const ComponentWithCharCounterContainer = styled.div`
  height: auto;
  width: auto;
  position: relative;

  textarea {
    margin-bottom: 0 !important;
  }
`

const WithCharCounter = BaseComponent => {
  return class WithCharCounter extends Component {
    
    static propTypes = {
      onChange: PropTypes.func.isRequired,
      maxLength: PropTypes.number,
      value: PropTypes.string,
    };

    state = {
      count: (this.props.value ? this.props.value.length : 0)
    };

    handleChange = e => {
      this.props.onChange(e);
      this.setState({ count: e.target.value.length });
    };

    render() {
      return (
        <ComponentWithCharCounterContainer>
          <BaseComponent
            {...this.props}
            onChange={e => {
              this.handleChange(e);
            }}
          />
          <span className="char-counter-container">
            {this.props.maxLength && `${this.state.count}/${this.props.maxLength}`}
          </span>
        </ComponentWithCharCounterContainer>
      );
    }
  };
};

export default WithCharCounter;
