import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";

const Option = Select.Option;

const CategorySelect = ({ onChange, value, disabled }) => {
  const { cachedList } = useContext(ListContext);
  const allCategories = cachedList.projectCategories.items;

  const handleChange = val => {
    onChange(val);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      disabled={disabled}
      style={{ width: "100%"}}
    >
      {allCategories.map(el => (
        <Option key={el.ID} value={el.ID}>
          {el.Name}
        </Option>
      ))}
    </Select>
  );
};

export default CategorySelect;
