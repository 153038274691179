import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import NwDrawer from 'Components/Gui/NwDrawer';
import {
    NWInput,
    NWPrivacyLevelSelector,
} from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useEditPlaylist } from "Hooks/Contact/UseContactPlaylists";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';

const BookEditDrawer = ({ book, playList, contactID, onClose, onUpdate }) => {
    useFormFieldFocus('Title');
    const { mutateAsync: editPlaylist } = useEditPlaylist();
    const [touchForm, setTouchForm] = useState(false);

    let initialValues = {
        Title: "",
        PrivacyLevel: 'Public'
    };

    if (book) {
        initialValues.Title = book.Name;
        initialValues.PrivacyLevel = book.PrivacyLevelName;
    };

    const handleSaveBook = async (values, setSubmitting) => {
        let updatedBook = {
            ...book,
            Type: "book",
            Name: values.Title,
            PrivacyLevel: values.PrivacyLevel,
            PrivacyLevelName: values.PrivacyLevel
        };

        await editPlaylist({ playlist: updatedBook, contactID: contactID });
        setTouchForm(false);
        onUpdate(updatedBook);
    };

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title='Edit Book'
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required")
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    handleSaveBook(values, setSubmitting);
                }}
            >
                {({ status, isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Title"
                            name="Title"
                            type="text"
                            id='Title'
                            value={values.Title}
                        />
                        <Field
                            component={NWPrivacyLevelSelector}
                            label="Privacy Level"
                            name="PrivacyLevel"
                            value={values.PrivacyLevel}
                        />
                        <div className="form-group">
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </div>
                        {status && (
                            <div className={"alert alert-danger"}>
                                <React.Fragment>{status.msg}</React.Fragment>
                            </div>
                        )}
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
};

export default BookEditDrawer;