import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router"
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ListContext } from "Contexts/ListContext";
import { useStateValue } from "Contexts/StateProvider";
import { removeLogs } from "Libs/NwLogs";
import ContactCreatorDrawer from "Components/ContactCreator/ContactCreatorDrawer";
import InvoiceDrawer from "Components/Invoices/InvoiceDrawer";
import { getNavLogs, redirectOnAreaChange } from "Components/_LayoutComponents/Header/NavBar/NwNavUtils";
import { HeaderContext } from "Components/_LayoutComponents/Header/NwHeader";
import NwNavBarBooking from "./NwNavBarBooking";
import NwNavBarAccounting from "./NwNavBarAccounting";
import NwNavBarManagement from "./NwNavBarManagement";
import NwNavBarScouting from "./NwNavBarScouting";
import { BeatLoader } from "react-spinners";
import StyleVariables from "Components/Gui/StyleVariables";
import { NwNavIcon } from "../NwHeaderUI";

const NoRecordLabel = styled.div`
    opacity: .75;
    font-size: .8rem;
    font-style: italic;
`;



const NwNavbarWrapper = ({ history, location, drawer }) => {

    const headerContextValues = useContext(HeaderContext);
    const { area, device, onCloseEventDrawer, closeNavDrawer } = headerContextValues;

    const [{ loadingStatusContext }] = useStateValue();
    const { cachedListLoaded } = useContext(ListContext)
    const [current, setCurrent] = useState("/dashboard");
    const [creatorType, setCreatorType] = useState("Model")
    const [creatorDrawer, setCreatorDrawer] = useState(false);
    const [invoiceDrawer, setInvoiceDrawer] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    
    const allLogs = getNavLogs(area);

    const isMobile = device === 1
    
    useEffect(() => {
        setIsLoaded(loadingStatusContext.loaded);
    }, [loadingStatusContext]);

    const pageurl = location.pathname

    useEffect(() => {
        const currentToSet = redirectOnAreaChange(allLogs, location, current, pageurl);
        if (currentToSet) {
            setCurrent(currentToSet)
        }
    }, [pageurl])

    const closeCreatorDrawer = () => {
        setCreatorType();
        setCreatorDrawer(false)
    }

    const onCustomerCreated = customer => {
        if (area === 'booking') {
            history.push(`/contacts/${customer.ID}`);
        } else {
            history.push(`/${area}-contacts/${customer.ID}`);
        }
        closeCreatorDrawer();
    }

    const onModelCreated = (ID) => {
        if (area === 'booking') {
            history.push(`/models/${ID}`);
        } else {
            history.push(`/${area}-models/${ID}`);
        }
        closeCreatorDrawer();
    }

    const onProjectCreated = (ID) => {
        if (area === 'booking') {
            console.log("entrato onProjectCreated")
            history.push(`/bookings/${ID}`);
        } else {
            history.push(`/${area}-bookings/${ID}`);
        }
        closeCreatorDrawer();
    }

    const onTravelPlanCreated = ID => {
        history.push(`/travel-plans/${ID}`);
        closeCreatorDrawer();
    };

    const onRemoveLogs = logType => {
        switch (logType) {
            case 'Model':
                removeLogs(`${area}_model`);
                break;
            case 'Customer':
                removeLogs(`${area}_customer`);
                break;
            case 'Project':
                removeLogs(`project`);
                break;
            case 'Trip':
                removeLogs(`travelPlan`);
                break;
            case 'Statement':
                removeLogs(`statement`);
                break;
            default:
                break;
        }
    };

    const handleClick = (e) => {
        if (!isLoaded) return;
        if (drawer) {
            closeNavDrawer();
        }
        if (e.key && !e.key.includes('menu') && !e.key.includes('no-key')) {
            if (e.key.includes('add')) {
                const creatortype = e.key.substr(4)
                setCreatorType(creatortype);
                setCreatorDrawer(true);
            } else if (e.key.includes('invoice-drawer')) {
                setInvoiceDrawer(Number(e.key.split('/')[2]));
            } else if (e.key.includes('remove')) {
                const removeLogType = e.key.substr(7)
                onRemoveLogs(removeLogType);
            } else {
                setCurrent(e.key);
                history.push(`${e.key}`)
            }
        }
    }

    const createNewItemMenu = key => {
        if (!isLoaded) return;
        const items = [];
        if (key !== "Statement") {
            items.push({
                key: `add-${key}`,
                label: (
                    <div className="nw-main-nav-subitems">
                        <NwNavIcon icon={solid("plus-circle")} />Create New
                    </div>
                ),
            });
        }
        if (!isMobile) {
            items.push({
                key: `remove-${key}`,
                label: (
                    <div className="nw-main-nav-subitems">
                        <NwNavIcon icon={solid("trash-alt")} />Clear History
                    </div>
                ),
            });
        }
        return ({
            type: "group",
            key: `/menu-actions-${key}`,
            label: (<span className="submenu-title-wrapper"><NwNavIcon icon={light("bolt")} />Actions</span>),
            children: items
        });
    };

    const lastSeenMenu = (logs, prefix, noLabel, property) => {
        if (!isLoaded) return (null);
        const items = [];
        if (logs) {
            logs.map((log) => {
                let logname = log.name;
                let logproperty = property ? log[property] : null;
                if (prefix === "bookings") {
                    if (logproperty.toLowerCase() === logname.toLowerCase()) {
                        if (log.product) {
                            logname = log.product;
                        }
                    }
                }
                items.push({
                    key: `/${prefix}/${log.id}`,
                    label: (
                        logproperty
                            ?
                            <div className="last-seen-item">
                                <div>{logproperty}</div>
                                <small>{logname}</small>
                            </div>
                            :
                            <>{logname}</>
                    )
                });
                return null;
            })
        }
        if (!logs || logs.length === 0) {
            items.push({
                key: `no-key-${prefix}`,
                label: (<NoRecordLabel>{noLabel}</NoRecordLabel>),
                disabled: true
            });
        }
        return ({
            type: "group",
            key: `menu-last-seen-${prefix}`,
            label: (<span className="submenu-title-wrapper"><NwNavIcon icon={light("history")} />Last Seen</span>),
            children: items
        });
    };

    const handleChangeOpenKeys = (keys, submenukeys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (submenukeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    }

    const isOpen = key => {
        return openKeys.find(openKey => openKey === key);
    };

    
    const areaNavBarsProps = {
        allLogs,
        createNewItemMenu,
        current,
        drawer,
        handleChangeOpenKeys,
        handleClick,
        isLoaded,
        isOpen,
        lastSeenMenu,
        openKeys,
    }

    return (
        <>
            {cachedListLoaded
            ?
                (() => {
                    switch (area) {
                        case 'accounting':
                            return (<NwNavBarAccounting {...areaNavBarsProps} />)
                        case 'management':
                            return (<NwNavBarManagement {...areaNavBarsProps} />)
                        case 'scouting':
                            return (<NwNavBarScouting {...areaNavBarsProps} />)
                        default:
                            return (<NwNavBarBooking {...areaNavBarsProps} />)
                    }
                })()
            :
                <div style={{height: 60, width: 200, textAlign: 'center'}}>
                    <BeatLoader color={StyleVariables.PrimaryColor} size={8} margin={4} />
                </div>
            }
            {creatorDrawer &&
                <ContactCreatorDrawer
                    type={creatorType}
                    onCancel={closeCreatorDrawer}
                    onModelCreated={onModelCreated}
                    onCustomerCreated={onCustomerCreated}
                    onTravelPlanCreated={onTravelPlanCreated}
                    onProjectCreated={onProjectCreated}
                    onCloseEventDrawer={onCloseEventDrawer}
                />
            }
            {invoiceDrawer &&
                <InvoiceDrawer
                    invoiceId={invoiceDrawer}
                    onClose={() => setInvoiceDrawer()}
                />
            }
        </>
    );
};


export default withRouter(NwNavbarWrapper);
