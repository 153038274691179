import {produce} from 'immer'
import ls from "local-storage";
import {OPEN_FOLDER_DEFAULT} from 'Contexts/NavigationContext'
import Axios from 'axios';

const persistsOpenFolders = async (value) => {
    ls.set("nw_config_openfolders", value)
    //console.log("persistsOpenFolders")
    await Axios.post('/userclientconfigurations', {
        Name: 'folders',
        JsonData: JSON.stringify({
            openFolders: value.openFolders
        })
    });
}

const OpenFoldersReducer =  (state, action) => produce(state, draft => {
    switch (action.type) {
        case 'updateFolders': 
            if (action.lastupdate) {
                draft.lastupdate = action.lastupdate;
            }
            if (action.openFolders.length > 0) {
                draft.openFolders = [...action.openFolders];
            }
            ls.set("nw_config_openfolders", draft)
            break;
        case 'createFolder':
            draft.openFolders.push(action.newFolder)
            persistsOpenFolders(draft)
            break
        case 'editFolder':
            const folder = draft.openFolders.find(p => p.ID === action.folder.folderID);
            folder.title = action.folder.folderName;
            folder.color = action.folder.color;
            folder.type = action.folder.type;
            persistsOpenFolders(draft)
            break;
        case 'removeFolder':
            draft.openFolders = draft.openFolders.filter(p => p.ID !== action.folderID)
            persistsOpenFolders(draft)
            break;
        case 'updateOrderFolder':
            draft.openFolders = action.folders
            persistsOpenFolders(draft)
            break;
        case 'updateOrderFolderItems':
            draft.openFolders.find(p => p.ID === action.folder.ID).items = action.folder.items
            persistsOpenFolders(draft)
            break;
        case 'addItemsToFolder':
            let newItemslist = [...draft.openFolders.find(p => p.ID === action.folderID).items]
            action.items.forEach(actionItemId => {
                if (newItemslist.findIndex(itemId => itemId === actionItemId) < 0) {
                    newItemslist.push(actionItemId);
                }
            });
            draft.openFolders.find(p => p.ID === action.folderID).items = newItemslist
            persistsOpenFolders(draft)
            break;
        case 'removeItemsFromFolder':
            const currentFolder = draft.openFolders.find(p => p.ID === action.folderID)
            const FilteredFolderItems = currentFolder.items.filter(itemId => !action.items.includes(itemId))
            currentFolder.items = FilteredFolderItems
            persistsOpenFolders(draft)
            break;
        case 'clearFolder':
            const indfoldertoremove = draft.openFolders.findIndex(p => p.ID === action.folderID)
            draft.openFolders.splice(indfoldertoremove,1)
            persistsOpenFolders(draft)
            break
        case 'clearFolders':
            ls.set("nw_config_openfolders", null)
            draft.lastupdate = null
            draft.openFolders = [ ...OPEN_FOLDER_DEFAULT.openFolders ]
            persistsOpenFolders(draft)
            break;
        default:
            //do nothing
    }
});

export default OpenFoldersReducer
