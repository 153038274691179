import React, { useState, useContext, useMemo, useEffect } from 'react'
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import { Tag, Popover } from 'antd';
import { ListContext } from 'Contexts/ListContext'
import { sortTags, mapTags } from 'Libs/NwUtils';
import MiniLoading from 'Components/Gui/MiniLoading';
import NwIcon from 'Components/Gui/NwIcon';

const TagsText = styled.a`
    display:flex;
    align-items:center;
    small {
        margin: .15em;
        font-size: .85rem;
        line-height: 1.4em;
        font-weight: normal;
        text-transform: lowercase;
        background-color: ${props => props.theme.PrimaryDarkLight};
        color: #fff;
        padding: .2em .75em .15em .75em;
        border-radius: 4px;
        white-space: nowrap;

        &:first-child {
            margin-left: .2em;
        }
    }
`;

const TagsContainer = styled.div`
    width: 20rem;
    min-height: 20rem;
    max-height: 40vh;
    overflow-y: hidden;
    
    .ant-tag {
        margin: 0 .35rem .5rem 0;
        cursor: pointer;
    }
`
const TagsLoadingContainer = styled.div`
    width: 20rem;
    min-height: 20rem;
`

const TagManager = ({ itemType, noPopover, tags, onRemoveTag, onAddTag, selectedList, addOnly, readOnly, size = "default", removeOnly, maxTagsShown = 3, outerLoading }) => {
    const { cachedList } = useContext(ListContext)
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allTags = useMemo(() => sortTags(mapTags(cachedtags, itemType)), [cachedtags])

    useEffect(() => {
        if (outerLoading) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [outerLoading])

    const doRemoveTag = async (tag) => {
        setIsLoading(true)
        await onRemoveTag(tag)
        setIsLoading(false)
    }

    const doAddTag = async (tag) => {
        setIsLoading(true)
        await onAddTag(tag)
        setIsLoading(false)
    }

    const openTagManager = () => {
        setOpen(true)
    }

    const handleVisibleChange = visible => {
        setOpen(visible);
    };

    const renderContent = () => {
        return (
            <>
                {isLoading ?
                    <TagsLoadingContainer>
                        <MiniLoading />
                    </TagsLoadingContainer>
                    :
                    <TagsContainer>
                        {removeOnly || addOnly ?
                            tags.map(tag => {
                                return (
                                    removeOnly
                                        ?
                                        <Tag
                                            key={tag.ID}
                                            closable
                                            color={StyleVariables.PrimaryDark}
                                            onClose={() => doRemoveTag(tag)}>
                                            {tag.Name}
                                        </Tag>
                                        :
                                        <Tag key={tag.ID} onClick={() => doAddTag(tag)}>
                                            + {tag.Name}
                                        </Tag>
                                )
                            })
                            :
                            allTags.map(tag => {
                                const isTag = tags.find(item => item.ID === tag.ID);
                                return (
                                    isTag
                                        ?
                                        <Tag
                                            key={tag.ID}
                                            closable
                                            color={StyleVariables.PrimaryDark}
                                            onClose={() => doRemoveTag(tag)}>
                                            {tag.Name}
                                        </Tag>
                                        :
                                        <Tag
                                            key={tag.ID}
                                            onClick={() => doAddTag(tag)}>
                                            + {tag.Name}
                                        </Tag>
                                )
                            })
                        }
                    </TagsContainer>}
            </>
        )
    }

    return (noPopover ? renderContent()
        : <>
            <Popover
                placement='bottom'
                title='Tags'
                trigger='click'
                open={open && !readOnly}
                overlayStyle={{ maxWidth: 300, minWidth: 250, position: 'fixed' }}
                onOpenChange={handleVisibleChange}
                //getPopupContainer={triggerNode => triggerNode.parentNode}
                content={renderContent()}>
                <TagsText className="ant-dropdown-link" onClick={readOnly ? null : openTagManager}>
                    {(tags.length === 0)
                        ?
                        <NwIcon size="lg" icon={light("tag")} />
                        : (tags.length <= maxTagsShown)
                            ?
                            (tags.map((tag, index) => (
                                <small key={`${tag.Name}-${index}`}>{tag.Name}</small>
                            )))
                            :
                            <small>{tags.length} tags</small>
                    }
                </TagsText>
            </Popover>
        </>
    );
}

export default TagManager
