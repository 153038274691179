import React, { useState } from 'react';
import {Col, Input, Row} from 'antd';
import NwIcon from "../NwIcon";
import {NwEditButton} from "../NwButton";
import {NwButton} from "Components/Gui/NwButton";
import { getModel } from 'Hooks/Contact/UseContactsList';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const EditFolder = ({folder, removeModelFromFolder, onDeleteFolder, onSave}) => {

    const [folderName, setFolderName] = useState(folder.title);
    const [isFolderEditing, setFolderEditing] = useState(false);
    
    const handleSave = () => {
        setFolderEditing(false);
        onSave(folder.ID, folderName);
    };
    const getModelNameAndSurname = (model) => {
        const currentModel = model.S ? model : getModel(model);
        return `${currentModel.N} ${currentModel.S}`;
    };

    return (
        <>
            <Row key={folder.ID} value={folder.ID}>
                <Col span={8}>
                    <div style={{display: "flex",  width: "auto"}}>
                        <NwIcon icon={light("folder")} size={"lg"}/>
                        {isFolderEditing ? <Input autoComplete='off' style={{marginLeft: 10}} value={folderName} onPressEnter={handleSave}
                                                  onChange={({target}) => setFolderName(target.value)}/> :
                            <h1 style={{marginLeft: 15}} onDoubleClick={() => setFolderEditing(true)}>{folder.title}</h1>}
                    </div>
                    <div>
                        {folder.models.map(model => (
                            <>
                                <p style={{
                                    marginLeft: 85,
                                    display: "flex",
                                    height: 15,
                                    justifyContent: 'space-between'
                                }}>
                                    {getModelNameAndSurname(model)}
                                    <span>
                                        <NwButton onClick={() => removeModelFromFolder(folder.ID, model)}
                                            size='small'
                                            label={"Delete Model"}
                                            icon={light("trash-alt")}/>
                                    </span>
                                </p>
                            </>
                        ))}
                    </div>
                </Col>
                {isFolderEditing ?
                    <NwButton size="small" onClick={handleSave} label={"Save"}/>
                    : <NwEditButton size="small" label={"Edit"} onClick={() => setFolderEditing(true)}/>}
                {isFolderEditing ?
                    <NwButton size="small" label={"Cancel"} onClick={() => setFolderEditing(false)}/>
                    : <NwButton onClick={() => onDeleteFolder(folder.ID)} size="small" danger icon={light("trash-alt")} label={"Delete"}/>}
            </Row>
        </>
    );
};
export default EditFolder
