import React from 'react';
import { Select, Tooltip, Input, Avatar, InputNumber } from "antd";
import styled from "styled-components";
import { CURRENCIES } from "Configs/CURRENCIES";
import StyleVariables from 'Components/Gui/StyleVariables';
import Money from '../Money';

const InputGroup = Input.Group;
const Option = Select.Option;

const StyledInputGroup = styled(InputGroup)`
  white-space: nowrap;
`

const StyledSelect = styled(Select)`
  .ant-select-item-option-content {
    .currency-selector-option {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div.avatar {
        padding: .25rem .5rem;
      }
      div.text {
        p {
          margin: 0;
          line-height: 1.1em;
          font-weight: 600;
        }
        small {
          margin: 0;
          line-height: 1.1em;
        }
      }
    }
    
  }
`

const AmountInput = ({ value, disabled, currencyReadOnly, fixedCurrency, onChange, onBlur, allowZero = false }) => {

    const handleSelectChange = selectVal => {
        onChange([selectVal, value[1]]);
    }

    const handleChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^\d*\.?\d{0,2}$/;   //this is without negative minus allowed and max 2 decimals
        if ((!Number.isNaN(inputValue) && reg.test(inputValue)) || inputValue === '') {
            if (inputValue.charAt(0) === '.' && inputValue.length === 1) {
                onChange([value[0], `0${inputValue}`]);
            } else {
                onChange([value[0], inputValue]);
            }
        }
    }

    const evaluateZero = (val) => {
        if (!allowZero) {
            if (Number(val) === 0) {
                return true
            }
        }
        return false
    }

    const handleBlur = () => {
        let currentValue = value[1] ? value[1].toString() : '';
        let valueTemp = currentValue;
        if (currentValue.charAt(currentValue.length - 1) === '.') {
            valueTemp = currentValue.slice(0, -1);
        }
        if (Number.isNaN(valueTemp) || evaluateZero(valueTemp)) {
            console.log("entrato")
            onChange([value[0], null]);
        } else {
            onChange([value[0], Number(valueTemp.replace(/0*(\d+)/, '$1'))]);
        }
        if (onBlur) {
            onBlur();
        }
    };

    const title = () => {
        if (value[1] && !Number.isNaN(value[1])) {
            return (<Money amount={value[1]} currency={value[0]} />)
        } else {
            return ('Input a number')
        }
    }


    return (
        <StyledInputGroup compact>
            <StyledSelect
                autoComplete='off'
                style={{ width: "5.25rem", textAlign: "center" }}
                dropdownStyle={{ width: "15rem", textAlign: "left" }}
                dropdownMatchSelectWidth={false}
                disabled={disabled || currencyReadOnly}
                value={fixedCurrency || value[0]}
                onChange={val => handleSelectChange(val)}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                optionLabelProp="label"
                onBlur={onBlur}
            >
                {fixedCurrency ?
                    <Option key={fixedCurrency} value={fixedCurrency} label={fixedCurrency}>
                        <div className='currency-selector-option'>
                            <div className='avatar'>
                                <Avatar
                                    size='16'
                                    style={{ backgroundColor: StyleVariables.PrimaryColor, color: '#fff' }}
                                >
                                    {CURRENCIES.find(el => el.code === fixedCurrency).symbol_native}
                                </Avatar>
                            </div>
                            <div className='text'>
                                <p>{fixedCurrency}</p>
                                <small>{CURRENCIES.find(el => el.code === fixedCurrency).name}</small>
                            </div>
                        </div>
                    </Option>
                    :
                    (CURRENCIES.map(el =>
                        <Option key={el.code} value={el.code} label={el.code}>
                            <div className='currency-selector-option'>
                                <div className='avatar'>
                                    <Avatar
                                        size='16'
                                        style={{ backgroundColor: StyleVariables.PrimaryColor, color: '#fff' }}
                                    >
                                        {el.symbol_native}
                                    </Avatar>
                                </div>
                                <div className='text'>
                                    <p>{el.code}</p>
                                    <small>{el.name}</small>
                                </div>
                            </div>
                        </Option>
                    ))
                }
            </StyledSelect>
            <Tooltip trigger={['focus']} title={title()} placement="topLeft" overlayClassName="numeric-input">
                <Input
                    style={{ width: "8rem", height: 28 }}
                    value={value[1]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="0.00"
                    maxLength={16}
                />
            </Tooltip>
        </StyledInputGroup>
    );
};

export default AmountInput;