import React from 'react'
import { Row } from 'antd';

import ProjectLine from 'Components/ProjectLine/ProjectLine';
import EventTransactionsList from 'Components/Transactions/EventTransactionsList';
import { EventWrapper } from 'Components/EventsBoxes/EventBoxesUI';
import { TableColBorder } from 'Components/Gui/StyledComponent';
import EventBoxesModels from './EventBoxesModels';
import { EventBoxDateAndTime } from './EventBoxesUtils';
import NonBookingEventDetails from './NonBookingEventDetails';

const NonBookingEvent = ({
    //contactId,
    event,
    isGrouped,
    onSelectEvent,
    page,
    showTransactions,
}) => {

    const handleEventClick = () => {
        if (onSelectEvent) {
            onSelectEvent(event);
        }
    };

    const getStartDate = () => {
        if (event.SearchData) {
            return event.SearchData.StartDate;
        }
        if (event.StartDate) {
            return event.StartDate;
        }
        if (event.StartDateTime) {
            return event.StartDateTime;
        }
        if (event.FromDate) {
            return event.FromDate;
        }
    }

    const getEndDate = () => {
        if (event.SearchData) {
            return event.SearchData.EndDate;
        }
        if (event.EndDate) {
            return event.EndDate;
        }
        if (event.ArrivalDateTime) {
            return event.ArrivalDateTime;
        }
        if (event.ToDate) {
            return event.ToDate;
        }
    }

    return (
        isGrouped
            ?
            <NonBookingEventDetails event={event} page={page} isGrouped />
            :
            <EventWrapper $cancel={event.Cancel} page={page} onClick={handleEventClick}>
                <Row gutter={8} wrap={false}>
                    <EventBoxDateAndTime firstDate={getStartDate()} lastDate={getEndDate()} />
                    <TableColBorder flex={(page === "project") ? "auto" : "2"}>
                        <div className="event-box-details">
                            {event.Project && (page !== "project") &&
                                <ProjectLine project={event.Project} />
                            }
                            <NonBookingEventDetails event={event} page={page} />
                        </div>
                    </TableColBorder>
                    {page !== "project" &&
                        <TableColBorder flex="3">
                            {showTransactions ?
                                <EventTransactionsList event={event} readOnly />
                                :
                                <EventBoxesModels page={page} event={event} />
                            }
                        </TableColBorder>
                    }
                </Row>
            </EventWrapper>
    )
}

export default NonBookingEvent
