import React from "react";
import NwDrawer from "Components/Gui/NwDrawer";
import ModelSelector from "Components/ModelSelector/ModelSelector";

const AddModelsToNonBookingEvent = ({ event, projectId, eventType, onClose, onUpdateEvent }) => {
    return (
        <NwDrawer
            id="add-models-drawer"
            padding="0px"
            width='80vw'
            title='Add Models To Non-Booking Event'
            onClose={onClose}>
            <ModelSelector
                currentEvent={{ ...event, TypeName: eventType }}
                defaultView="table"
                fixedView
                isDrawer={true}
                layouttype="fullpage"
                multipleSelection={false}
                onSelectModel={() => {}}
                projectId={projectId}
                scope="nonbooking-event"
            />
        </NwDrawer>
    );
}

export default AddModelsToNonBookingEvent;
