import React from 'react';
import { Table } from 'antd';

import { showDateNoTime } from 'Libs/NwMoment';
import Money from 'Components/Gui/Money';
import { getMoment } from "Libs/NwMoment";
import TransactionEvent from 'Components/Transactions/TransactionEvent';
import TransactionTitle from 'Components/Transactions/TransactionTitle';
import VerticalDate from 'Components/Dates/VerticalDate';

const ContractCreationDrawerTransactionItem = ({ 
    modelID, 
    onSelect,
    project, 
    transactions, 
}) => {

    const TransactionRefDate = ({ transaction }) => {
        if (transaction.RefDate && !transaction.WorkDate) {
            if (getMoment(transaction.RefDate).year() > 1900) {
                return (<p className='upper'>{showDateNoTime(transaction.RefDate)}</p>)
            }
        }
        return null
    }

    // {transaction.ConfirmationProperties &&
    //     <div>
    //         {(transaction.ConfirmationProperties.Confirmed !== null) &&
    //             <p>confirmed: {String(transaction.ConfirmationProperties.Confirmed)}</p>
    //         }
    //         {transaction.ConfirmationProperties.OptionNumber &&
    //             <p>option number: {transaction.ConfirmationProperties.OptionNumber}</p>
    //         }
    //         {(transaction.ConfirmationProperties.Cancel !== null) &&
    //             <p>cancel: {String(transaction.ConfirmationProperties.Cancel)}</p>
    //         }
    //     </div>
    // }

    const transactionColumns = [
        {
            title: 'Date',
            key: 'Date',
            render: record => {
                return (
                    <div className="transaction-date">
                        <VerticalDate date={record.WorkDate} />
                    </div>
                )
            }
        },
        {
            title: 'Title',
            key: 'Title',
            width: '100%',
            render: record => {
                return (
                    <div className="transaction-text">
                        <TransactionTitle transaction={record} />
                        <TransactionEvent transaction={record} project={project} />
                        {/* <TransactionRefDate transaction={record} /> */}
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'Amount',
            align: 'right',
            render: record => {
                return (
                    <div className="transaction-amount">
                        <div className="transaction-fee">
                            <div className='total-fee'><Money highlightCurrency amount={record.TotalFee} currency={record.Currency} /></div>
                        </div>
                    </div>
                )
            }
        },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        onSelect(newSelectedRowKeys, modelID);
    };

    const getActiveTransactionKeys = () => {
        return transactions.filter(transaction => transaction.active).map(transaction => transaction.key)
    }

    const rowSelection = {
        selectedRowKeys: getActiveTransactionKeys(),
        onChange: onSelectChange,
    };

    return (
        <Table
            rowSelection={rowSelection}
            showHeader={false}
            pagination={false}
            columns={transactionColumns}
            size='small'
            dataSource={transactions.map(transaction => (
                { 
                    ...transaction, 
                    key: transaction.ID, 
                    WorkDate: transaction.WorkDate, 
                    ModelID: transaction.ModelID 
                }
            ))}
        />
    )
}

export default ContractCreationDrawerTransactionItem;