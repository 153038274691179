import React, { useContext, useState } from "react";
// import { useHistory } from 'react-router-dom';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment, showDateNoTime } from "Libs/NwMoment";

//import useLocalStorageState from 'Hooks/UseLocalStorageState';
import NwDrawer from 'Components/Gui/NwDrawer';
import {
    NWInput,
    NWProjectSelector,
    NWSwitch
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { UserContext } from 'Contexts/UserContext';
import { getNow, showDate } from 'Libs/NwMoment';
import { useAddPackage, useAddPlaylistToPackage, forceReloadFullSearchPackages } from 'Hooks/Packages/UsePackages';
import useConfigurations from "Hooks/UseConfigurations";
import { Segmented, DatePicker } from "antd";
import styled from 'styled-components';
import NwIcon from "Components/Gui/NwIcon";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwFakeForm, NwFakeFormItem } from "Components/Gui/NWForm/NwFakeFormWrapper";

const StyledSegmented = styled(Segmented)`
    margin-bottom: 2rem;
    .expiration-period-item {
        padding-top: .75rem;
        padding-bottom: .25rem;
        h1 {
            font-size: ${props => props.theme.FontSizeSm};
            font-weight: bold;
            margin: 0;
            line-height: 1.1em;
        }
        div {
            padding-top: .25rem;
            line-height: 1.6em;
        }
        &.expiration-period-item-custom {
            div {
                text-decoration: underline;
            }
        }
        &.expiration-period-item-custom-empty {
            div {
                padding-top: 0;
                font-size: 1.25rem;
            }
        }
    }
`

const AddPackageDrawer = ({ packageName, bookId, projectId, onAddedPackage, onClose }) => {
    const { currentUser } = useContext(UserContext);
    const { mutateAsync: onAddPackage } = useAddPackage();
    const { mutateAsync: onAddPlaylistToPackage } = useAddPlaylistToPackage();
    const configurations = useConfigurations();
    const [touchForm, setTouchForm] = useState(false);
    
    const [daysToExpire, setDaysToExpire] = useState(configurations.packageExpirationDays ? configurations.packageExpirationDays : 30);
    const [expirationCustomDate, setExpirationCustomDate] = useState();
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const initialValues = {
        Title: packageName || `${currentUser.Name} ${currentUser.Surname} ${showDate(getNow())}`,
        IsInteractive: true,
        ProjectID: projectId ? projectId : '',
    };

    const savePackage = async (values, setSubmitting) => {
        
        const expdate = daysToExpire === 0 ? expirationCustomDate.format('YYYY-MM-DD') : getMoment().add(daysToExpire, 'days').format('YYYY-MM-DD');
        
        const data = {
            name: values.Title,
            isInteractive: values.IsInteractive,
            ProjectID: values.ProjectID,
            ExpirationDate: expdate
        }

        try {
            const pack = await onAddPackage({ data });
            setTouchForm(false);

            if (bookId) {
                const data = {
                    packageID: pack.ID,
                    playlistID: bookId
                }
                await onAddPlaylistToPackage({
                    data,
                    isActivePackage: true
                });
            }
            //forceReloadFullSearchPackages();

            // history.push(`/package/${pack.ID}`);
            if (onAddedPackage) {
                onAddedPackage(pack.ID);
            }
            setSubmitting(false);
            onClose();
        } catch (error) {
            console.log('ant : Add New Package Error => ', error);
            setSubmitting(false);
        }
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < getMoment().endOf('day');
    };

    const notValidExpDate = () => {
        return daysToExpire === 0 && !expirationCustomDate;
    }

    return (
        <NwDrawer
            title='New Package'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    savePackage(values, setSubmitting);
                }}>
                {({ values, handleSubmit, isSubmitting }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Package Title"
                            name="Title"
                            type="text"
                            value={values.Title} />
                        <Field
                            component={NWSwitch}
                            label="Is Interactive"
                            name="IsInteractive"
                            defaultChecked={values.IsInteractive ? true : false}
                            checked={Boolean(values.IsInteractive)} />
                        <NwFakeFormItem
                            label="Expiration Date"
                            control={
                                <div className="expiration-wrapper">
                                    <StyledSegmented
                                        options={[
                                            {
                                                label: (
                                                    <div className="expiration-period-item">
                                                        <h1>7 days</h1>
                                                        <div>{getMoment().add(7, 'days').format('MMM DD')}</div>
                                                    </div>
                                                ),
                                                value: 7,
                                            },
                                            {
                                                label: (
                                                    <div className="expiration-period-item">
                                                        <h1>30 days</h1>
                                                        <div>{getMoment().add(30, 'days').format('MMM DD')}</div>
                                                    </div>
                                                ),
                                                value: 30,
                                            },
                                            {
                                                label: (
                                                    <div className="expiration-period-item">
                                                        <h1>60 days</h1>
                                                        <div>{getMoment().add(60, 'days').format('MMM DD')}</div>
                                                    </div>
                                                ),
                                                value: 60,
                                            },
                                            {
                                                label: (
                                                    <div className="expiration-period-item">
                                                        <h1>90 days</h1>
                                                        <div>{getMoment().add(90, 'days').format('MMM DD')}</div>
                                                    </div>
                                                ),
                                                value: 90,
                                            },
                                            {
                                                label: (
                                                    expirationCustomDate
                                                    ?
                                                    <div className="expiration-period-item expiration-period-item-custom">
                                                        <h1>Custom</h1>
                                                        <div onClick={() => setDatePickerOpen(true)}>
                                                            {showDateNoTime(expirationCustomDate)}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="expiration-period-item expiration-period-item-custom-empty">
                                                        <h1>Custom</h1>
                                                        <div>
                                                            <NwIcon pointer icon={light("calendar")} onClick={() => setDatePickerOpen(true)} />
                                                        </div>
                                                    </div>
                                                ),
                                                value: 0,
                                            },
                                        ]}
                                        value={daysToExpire}
                                        onChange={(val) => {
                                            setDaysToExpire(val)
                                            if (val === 0 && !expirationCustomDate) {
                                                setDatePickerOpen(true);
                                            }
                                        }}
                                    />
                                    {daysToExpire === 0 && (
                                        <>
                                            <DatePicker
                                                value={expirationCustomDate}
                                                allowClear={false}
                                                style={{ visibility: "hidden", width: 0 }}
                                                open={datePickerOpen}
                                                onChange={(val) => {
                                                    setExpirationCustomDate(val)
                                                    setDatePickerOpen(false)
                                                }}
                                                disabledDate={disabledDate}
                                            />
                                        </>
                                    )}
                                </div>
                            }
                        />
                        {!projectId &&
                            <Field
                                component={NWProjectSelector}
                                name="ProjectID"
                                value={values.ProjectID} />
                        }
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    label="Create"
                                    htmlType="submit"
                                    disabled={isSubmitting || notValidExpDate()}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}


export default AddPackageDrawer;