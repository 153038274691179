import React from 'react';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

const IconContainer = styled.span`
	svg {
		--fa-primary-color: ${props => props.theme.PrimaryColor};
		--fa-secondary-color: ${props => props.theme.PrimaryDarkLight};
		--fa-primary-opacity: 1;
		--fa-secondary-opacity: 1;  
	}
`


const MiniLoading = ({size}) => {
	return (
		<IconContainer>
			<NwIcon icon={duotone("spinner-third")} spin={true} size={size} />
		</IconContainer>
	);
};

export default MiniLoading;