export const MODELS_FILTERS = {
    filters: [
        {
            name: 'WorkingOn',
            label: 'Working On',
            area: 'work',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'NotWorkingOn',
            label: 'NOT working On',
            area: 'work',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Booker',
            label: 'Booker',
            area: 'work',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'Scouter',
            label: 'Scouter',
            area: 'work',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'Tags',
            label: 'Tags',
            area: 'work',
            defaultval: [],
            commontype: 'tag',
            type: 'tag'
        },
        {
            name: 'AddedSince',
            label: 'Added Since',
            area: 'work',
            defaultval: '{amonthago}',
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'AddedUntil',
            label: 'Added Until',
            area: 'work',
            defaultval: '{today}',
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'AgeSince',
            label: 'Age - From',
            area: 'demographic',
            defaultval: 20,
            commontype: null,
            type: 'number'
        },
        {
            name: 'AgeUntil',
            label: 'Age - To (included)',
            area: 'demographic',
            defaultval: 21,
            commontype: null,
            type: 'number'
        },
        {
            name: 'InTown',
            label: 'In Town',
            area: 'work',
            defaultval: true,
            commontype: null,
            type: 'boolean'
        },
        {
            name: 'Countries',
            label: 'Country (address)',
            area: 'demographic',
            // defaultval: [
            //   '{localcountry}'
            // ],
            defaultval: [],
            commontype: 'array',
            type: 'countriesList'
        },
        {
            name: 'Cities',
            label: 'City (address)',
            area: 'demographic',
            // defaultval: [
            //   '{localcountry}'
            // ],
            defaultval: [],
            commontype: 'array',
            type: 'citiesList'
        },
        {
            name: 'Nationalities',
            label: 'Nationality',
            area: 'demographic',
            // defaultval: [
            //   '{localcountry}'
            // ],
            defaultval: [],
            commontype: 'array',
            type: 'countriesList'
        },
        {
            name: 'Citizenships',
            label: 'Citizenship',
            area: 'demographic',
            // defaultval: [
            //   '{localcountry}'
            // ],
            defaultval: [],
            commontype: 'array',
            type: 'countriesList'
        },
        {
            name: 'Features',
            label: 'Measurements and features',
            area: 'features',
            defaultval: [],
            commontype: 'array',
            type: 'featuresList'
        },
        {
            name: 'Gender',
            label: 'Gender',
            area: 'demographic',
            defaultval: 1,
            commontype: null,
            type: 'gender'
        },
        {
            name: 'Documents',
            label: 'Pictures and videos',
            area: 'work',
            defaultval: 'NoMedia',
            commontype: null,
            type: 'document-filter'
        },
        {
            name: 'Playlist',
            label: 'Playlists',
            area: 'work',
            defaultval: 'NoPlaylist',
            commontype: null,
            type: 'playlist-filter'
        },
        {
            name: 'Published',
            label: 'Published playlists',
            area: 'work',
            defaultval: 'NotPublished',
            commontype: null,
            type: 'published-filter'
        },
    ]
};