import React from 'react';
import NwDrawer from "Components/Gui/NwDrawer";
import SendProjectDrawerContent from './SendProjectDrawerContent';

const SendProjectDrawer = ({ currentProject, ModelID, EventID, EventType, onClose }) => {

    return (
        <NwDrawer
            title="Send Project Schedule"
            onClose={onClose}
            width="80vw"
            maxWidth="960px"
            id='send-project-drawer'>
            <SendProjectDrawerContent
                currentProject={currentProject}
                ModelID={ModelID}
                EventID={EventID}
                EventType={EventType}
                onClose={onClose}
            />
        </NwDrawer>
    );
};

export default SendProjectDrawer