import React, { useContext } from 'react';
import { Badge } from 'antd';

import Select from 'Components/Gui/NWForm/CustomSelect';
import { ListContext } from "../../../Contexts/ListContext";

const FollowUpLabel = ({ value, onChange, allowClear, placeholder = "Select a label", multiple }) => {
    const {cachedList} = useContext(ListContext);
    const followUpLabels = cachedList.followUpLabels.items.filter(item => !item.Disabled) || [];

    const options = allowClear && !multiple ? [{value: null, label: 'All'}] : [];
    if (followUpLabels) {
        for (const followupLabel of followUpLabels) {
            options.push({
                value: followupLabel.Code,
                //label: <><Badge color={`#${followupLabel.color}`} /> {followupLabel.Label}</>
                label: followupLabel.Label
            })
        }
    }
    
    return (
        <Select
            width='100%'
            autoComplete='off'
            showSearch={false}
            value={value}
            placeholder={placeholder ? placeholder : 'Select a label'} 
            allowClear={allowClear}
            filterOption={false}
            onChange={onChange}
            options={options}
            mode={multiple ? 'multiple' : null}
        />
    );

}
export default FollowUpLabel;
