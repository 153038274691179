import React from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from "Components/PermissionBlock";
import { useUserPermission } from 'Contexts/UserContext';

const ContactDetailsHeaderStatement = ({ contact, type, onOpenStatements, iconSize }) => {
    const viewModelStatement = useUserPermission('ViewModelStatement', contact.Users.map(user => user.UserID));
    const viewContactStatement = true; //useUserPermission('VIewContactStatement');
    
    const renderAction = permission => {
        return (
            <PermissionBlock
                permission={permission}
                content={
                    <NwIcon size={iconSize} icon={light("balance-scale")} onClick={permission && onOpenStatements} /> 
                }
            />
        )
    }

    return renderAction(type === 'model' ? viewModelStatement : viewContactStatement);
};

export default ContactDetailsHeaderStatement;
