import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
//import { UserContext } from 'Contexts/UserContext';

const useDepartmentName = (departmentId, networkAgencyId) => {
    const { cachedList } = useContext(ListContext);
    //const { currentUser } = useContext(UserContext);
    if (!departmentId) {
        return ""
    }
    for (const accGroup of cachedList.accountingGroups.items) {
        for (const nwAg of accGroup.NetworkAgencies) {
            if (networkAgencyId) {
                if (nwAg.ID === networkAgencyId) {
                    for (const department of nwAg.Departments) {
                        if (department.ID === departmentId) {
                            return department.Name
                        }
                    }
                }
            } else {
                for (const department of nwAg.Departments) {
                    if (department.ID === departmentId) {
                        return department.Name
                    }
                }
            }
        }
    }
    return ""
}

export default useDepartmentName