import React from 'react';
import styled from 'styled-components';
import {
  Menu, Dropdown
} from 'antd';
import NwIcon from 'Components/Gui/NwIcon';

const StyledMenu = styled(Menu)`
    .ant-dropdown-menu-item-group-title {

    }
    
    .ant-dropdown-menu-item-group-list {
        list-style-type: none;
        max-height: 7rem;
        overflow: auto;
        padding: 0;
    }

    .ant-dropdown-menu-item  {
        text-transform: uppercase;
        font-size: .8rem;
        vertical-align: middle;

        &:hover {
            background-color:  ${props => props.theme.PrimaryColor};
            color: #fff;
        }

        &.strong {
            font-weight: bold;
        }

        &.small {
            font-size: .75rem;
        }

        &.list {
            padding: 2px 12px;
        }

        svg  {
            margin: 0 12px 0 6px;
        }

        .badge {
            color: #fff;
            background-color: ${props => props.theme.DangerColor};
            display: inline-block;
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            margin-top: .2em;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
`

const renderAction = (action, index) => {
    if (action.divider) {
        return ({
            key: `divider-${index}`,
            type: 'divider'
        });
        // return (<Menu.Divider key={`divider-${index}`} />)
    }
    if (action.group) {
        return ({
            key: `group-${index}`,
            label: action.name,
            type: "group",
            children: action.subactions.map(renderAction)
        });
        // return (
        //     <Menu.ItemGroup key={`group-${index}`} title={action.name}>
        //         {action.subactions.map(renderAction)}
        //     </Menu.ItemGroup>
        // )
    }
    let classn = ""
    if (action.list) {
        classn += "list "
    }
    if (action.small) {
        classn += "small "
    }
    if (action.strong) {
        classn += "strong "
    }
    return ({
        key: action.key,
        className: classn,
        label: (
            action.folder ?
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {action.prefix}
                        <span>
                            {action.icon && <NwIcon icon={action.icon} />}
                            {action.name}
                        </span>
                    </div>
                    {action.badge && <span className="badge">{action.badge}</span>}
                </div>
                :
                <>
                    <span>
                        {action.icon && <NwIcon icon={action.icon} />}
                        {action.name}
                    </span>
                    {action.badge && <span className="badge">{action.badge}</span>}
                </>
        )
    });
    // return (
    //     <Menu.Item key={action.key} className={classn}>
    //         {action.folder ?
    //             <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
    //                 <div style={{display: 'flex', alignItems: 'center'}}>
    //                     {action.prefix}
    //                     <span>
    //                         {action.icon && <NwIcon icon={action.icon} />}
    //                         {action.name}
    //                     </span>
    //                 </div>
    //                 {action.badge && <span className="badge">{action.badge}</span>}
    //             </div>
                    
    //             :
    //             <>
    //                 <span>
    //                     {action.icon && <NwIcon icon={action.icon} />}
    //                     {action.name}
    //                 </span>
    //                 {action.badge && <span className="badge">{action.badge}</span>}
    //             </>
    //         }
    //     </Menu.Item>
    // )
}

const NwMenu = ({actions, handleClick, onOpen, placement, noParent, trigger, children}) => {
    const actionsMenu = () => (
        <StyledMenu
            onClick={handleClick}
            className="nw-small-context-menu"
            items={
                (actions && actions.length > 0) ? actions.map(renderAction) : []
            }
        >
        {/* {(actions && actions.length > 0) ?
             actions.map(renderAction)
         :
             <div className="nw-loading-menu"></div>
         } */}
        </StyledMenu>
    )

	return (
        <Dropdown 
            onOpenChange={onOpen} 
            overlay={actionsMenu()} trigger={[trigger || 'hover']} placement={placement || "bottomRight"} 
            getPopupContainer={triggerNode => !noParent ? triggerNode.parentNode : document.body}
        >
            {children}
        </Dropdown>
    );
};

export default NwMenu;
