import _ from 'lodash'
import { getMoment, getNow } from 'Libs/NwMoment'
import Axios from 'axios'


const getRangeFromDateAndStyle = (date, calendarStyle) => {
    switch (calendarStyle) {
        case "month":
            return getMonthRangeDates(date)
        case "week":
            return getWeekRangeDates(date)
        default:
           return {start: date, end: date}
    }
}

export const isCurrentPeriod = (date, calendarStyle) => {
    const range = getRangeFromDateAndStyle(date, calendarStyle)
    const start = getMoment(range.start).startOf('day')
    const end = getMoment(range.end).endOf('day')
    return getNow().isBetween(start, end)
}

export const getTodayAsRangeDates = (date) => {
    const basedate = date ? getMoment(date) : getNow()
    const start = basedate.startOf('day').toDate()
    const end = basedate.endOf('day').toDate()
    return { start, end }
}

export const getCurrentWeekRange = () => {
    const start = getNow().startOf('isoWeek').format('YYYY-MM-DD')
    const end = getNow().endOf('isoWeek').format('YYYY-MM-DD')
    return { start, end }
}

export const getCurrentMonthRangeDates = (date) => {
    const monthstart = getNow().startOf('month')
    const start = monthstart.startOf('isoWeek').format('YYYY-MM-DD')
    const monthend = getNow().endOf('month')
    const end = monthend.endOf('isoWeek').format('YYYY-MM-DD')
    return { start, end }
}

export const getWeekRangeDates = (date) => {
    const start = getMoment(date).startOf('isoWeek').toDate()
    const end = getMoment(date).endOf('isoWeek').toDate()
    return { start, end }
}

export const getMonthRangeDates = (date) => {
    const monthstart = getMoment(date).startOf('month')
    const start = monthstart.startOf('isoWeek').toDate()
    const monthend = getMoment(date).endOf('month')
    const end = monthend.endOf('isoWeek').toDate()
    return { start, end }
}

export const getSendAgendaRange = (range) => {
    const style = getCalendarStyle(range)
    if (style === 'month') {
        const defdate = getSendAgendaDefaultDate(range)
        return {start: defdate.startOf('month').format('YYYY-MM-DD'), end: defdate.endOf('month').format('YYYY-MM-DD')}
    }
    return {start: getMoment(range.start).format('YYYY-MM-DD'), end: getMoment(range.end).format('YYYY-MM-DD')}
}

export const getCalendarStyle = (range) => {
    if (range && range.start && range.end) {
        const startmoment = getMoment(range.start)
        const endmoment = getMoment(range.end)
        const numberOfDaysInRange = endmoment.diff(startmoment, 'days')
        if (numberOfDaysInRange > 7) {
            return "month"
        }
        if (numberOfDaysInRange <= 1) {
            return "agenda"
        }
    }
    return "week"
}

export const getCalendarDefaultDate = (range) => {
    if (range && range.start && range.end) {
        const startmoment = getMoment(range.start).startOf('day')
        const endmoment = getMoment(range.end).endOf('day')
        if (getNow().isBetween(startmoment, endmoment)){
            return getNow()
        }
        if  (getCalendarStyle(range) === "month") {
            return startmoment.add(15, 'days')
        }
        return startmoment
    }
    return getNow()
}

export const getSendAgendaDefaultDate = (range) => {
    if (range && range.start && range.end) {
        const startmoment = getMoment(range.start)
        if  (getCalendarStyle(range) === "month") {
            return startmoment.add(15, 'days')
        }
        return startmoment
    }
    return getNow()
}

export const getAgendaDefaultDate = (range, calendarStyle) => {
    if (range && range.start && range.end) {
        const startmoment = getMoment(range.start).startOf('day')
        const endmoment = getMoment(range.end).endOf('day')
        if (getNow().isBetween(startmoment, endmoment)){
            return getNow()
        }
        const cs = calendarStyle ? calendarStyle : getCalendarStyle(range)
        switch (cs) {
            case "month":
                const monthdefaultdate = startmoment.add(15, 'days')
                return monthdefaultdate.startOf('month')
            case "week":
                return startmoment
            default:
                return startmoment
        }
    }
    return getNow()
}

export const getScheduleEvents = async (params, range) => {
    const startmoment = getMoment(range.start)
    const endmoment = getMoment(range.end)
    const postParams = {
        ...params,
        FDate: startmoment.format('YYYY-MM-DD'),
        TDate: endmoment.format('YYYY-MM-DD'),
    }
    const events = await Axios.post("/calendar/getevents", postParams)
    let calendarEvents = [];
    if (events && events.Events) {
        for (const event of events.Events) {
            if (event.TypeName === "Accommodation") {
                calendarEvents.push({
                    ...event,
                    FromDate: getMoment(event.FromDate).startOf('day').format(),
                    FirstDate: getMoment(event.FirstDate).startOf('day').format(),
                    ToDate: getMoment(event.ToDate).endOf('day').format(),
                    LastDate: getMoment(event.LastDate).endOf('day').format(),
                })
            } else {
                calendarEvents.push(event)
            }
        }
    }
    return calendarEvents
}


export const areEventsFiltered = (params) => {
    if (!params.inclJobs) {
        return true
    }
    if (!params.inclCastings) {
        return true
    }
    if (!params.inclTravels) {
        return true
    }
    if (!params.inclExternalJobs) {
        return true
    }
    if (!params.inclFollowUps) {
        return true
    }
    if (!params.inclMeetings) {
        return true
    }
    if (!params.inclNotices) {
        return true
    }
    if (!params.inclAccommodations) {
        return true
    }
    if (!params.inclFollowUps) {
        return true
    }
    return false
}

export const isStandardParams = (params, modelsSelectorFilter) => {
    if (modelsSelectorFilter && modelsSelectorFilter.active) {
        return false
    }
    if (params.idmodels.length) {
        return false
    }
    if (params.idclient) {
        return false
    }
    if (params.idservice) {
        return false
    }
    if (params.idagency) {
        return false
    }
    if (areEventsFiltered(params)) {
        return false
    }
    return true
}