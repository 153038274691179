import constants from 'Constants';
import Axios from 'axios';
import ls from 'local-storage';

export const saveAlerts = async (updateArea, alerts) => {
    let globalAlerts = {};
    for (const area of constants.AREAS) {
        globalAlerts = {
        ...globalAlerts,
        [area]: area === updateArea ? [...alerts] : (ls.get(`nw_config_${area}-read-alerts`) || [])
      };
    }
    await Axios.post('/userclientconfigurations', {
        Name: 'alerts',
        JsonData: JSON.stringify(globalAlerts)
    });
};