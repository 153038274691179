import * as React from 'react'
import styled from 'styled-components';
import FlagIcon from 'Components/Gui/FlagIcon';

const StyledFlagIcon = styled(FlagIcon)`
    margin-right: .5em;
    width: 1.25em !important;
`

const NwFlagIcon = ({code}) => (
    <StyledFlagIcon code={code} /> 
)

export default NwFlagIcon