import React from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import PropTypes from 'prop-types'
import { showTime, showDate, getTime, showTimeFromArray, isSameTime, isArrayTimesAllDay } from 'Libs/NwMoment';
import { NwLinkButton } from 'Components/Gui/NwButton';

const Container = styled.div`
  margin: 1rem 0;
  
  small {
    text-transform: uppercase;
    font-size: .8rem;
    opacity: .5;
    padding: 0;
    margin: 0;
  }
  
  ul {

    list-style-type: none;
    padding: 0px;
    margin: 0px;
    
    > li {
      margin-top: .4rem;
    }
    > li::first-child {
      margin-top: 0px;
    }
  }
  
`;

const checkAndSetTime = (fromDate, toDate, startBreak, endBreak, times) => {
    if (!fromDate || !toDate) {
        return times;
    }
    const date = showDate(fromDate, 'notime');
    const start = getTime(fromDate);
    const end = getTime(toDate);
    const start_break = startBreak ? getTime(startBreak) : null;
    const end_break = endBreak ? getTime(endBreak) : null;
    if (!times.find(t => isSameTime(t.start,start) && isSameTime(t.end,end) && isSameTime(t.start_break,start_break) && isSameTime(t.end_break,end_break)) && !isArrayTimesAllDay(start,end)) {
        times.push({
            start,
            end,
            start_break,
            end_break,
            dates: [date]
        })
    } else {
        times.forEach(time => {
            if (isSameTime(time.start,start) && isSameTime(time.end,end) && isSameTime(time.start_break,start_break) && isSameTime(time.end_break,end_break) && !time.dates.find(d => d === date)) {
                time.dates = [...time.dates, date]
            }
        })
    }
    return times;
}

const getTimes = (eventData) => {
    let times = [];
    const sortedEventDates = [...eventData.EventDates];
    sortedEventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);
    sortedEventDates.forEach(eventDate => {
        times = checkAndSetTime(eventDate.FromDate, eventDate.ToDate, eventDate.StartBreak, eventDate.EndBreak, times);
        eventDate.EventDatesModels.forEach(eventDateModel => {
            times = checkAndSetTime(eventDateModel.FromDate, eventDateModel.ToDate, eventDateModel.StartBreak, eventDateModel.EndBreak, times);
        })
    })
    return times;
}

const getTimeLabel = (time) => {
    const firstdates = time.dates.length > 4 ? time.dates.slice(0, 3) : time.dates;
    let label = `${firstdates.join(', ')}`;
    if (time.dates.length > 4) {
        label += ' (...)';
    }
    label += ' • '
    if (time.start_break && time.end_break) {
        label += `${showTimeFromArray(time.start)} > ${showTimeFromArray(time.start_break)} - ${showTimeFromArray(time.end_break)} > ${showTimeFromArray(time.end)}`;
    } else {
        label += `${showTimeFromArray(time.start)} > ${showTimeFromArray(time.end)}`;
    }
    return label;
}

const SuggestedTimes = ({ eventData, onSetSuggestedTime }) => {
    const times = getTimes(eventData);
    return (
        <Container>
            <small>Copy work times from existing working days</small>
            <ul>
                {
                    times.map(time => (
                        <li key={`${time.start}-${time.end}`}>
                            <NwLinkButton 
                                icon={light("clone")}
                                onClick={() => onSetSuggestedTime(time)}
                                label={getTimeLabel(time)} 
                            />
                        </li>
                    ))
                }
            </ul>
        </Container>
    );
}

export default SuggestedTimes;