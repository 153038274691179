import { getModelsFromEventDates } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';
import { getContactName } from "Hooks/Contact/UseContactsList";

export const getModels = (event, currentProject) => {
  if (!currentProject) {
    return [];
  }
  if (!event || !event.EventDates) {
    return currentProject.ProjectModels.map(model => {
      return {
        ModelId: model.ModelID,
        Name: getContactName(model.ModelID)
      }
    });
  }

  if (event.elType === 'Travel' || event.elType === 'FollowUp') {
    return [{ ModelId: event.ModelID, Name: getContactName(event.ModelID) }];
  }

  return getModelsFromEventDates(event.EventDates);
}

export const groupCommentsByModel = (comments, filterModel) => {
  const groupedComments = {};
  comments.forEach(comment => {
    if (!comment.ContactID || comment.ContactID === filterModel) {
      return;
    }
    if (groupedComments[comment.ContactID]) {
      groupedComments[comment.ContactID].push(comment);
      return;
    }
    groupedComments[comment.ContactID] = [comment];
  })
  return groupedComments;
}