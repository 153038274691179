import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDrawer from 'Components/Gui/NwDrawer';
import { Loading } from 'Components/Gui/Loading';
import FlexContainer from 'Components/Gui/FlexContainer';
import TaskCardsGrid from 'Components/TaskList/TaskCardsGrid';
import ContactSocials from 'Components/Social/ContactSocials';
import { NwCardTabs } from 'Components/Gui/NwCard/NwCardTabs';
import ContactDetailsNotes from "Components/ContactDetailsNotes/ContactDetailsNotes";
import CustomerDetailsConnections from "Components/ContactDetails/CustomerDetails/CommonComponents/CustomerDetailsConnections";
import CustomerModelList from 'Components/ContactDetails/CustomerDetails/CommonComponents/CustomerModelList';
import NwIcon from 'Components/Gui/NwIcon';
import { getVisibilityNotes } from 'Libs/NwNotes';
import { useContact } from 'Hooks/Contact/UseContact';
import { addCustomerLog } from "Libs/NwLogs";
import { useStateValue } from 'Contexts/StateProvider';
import CustomerDetailsHeaderDrawer from "Components/ContactDetails/ContactDetailsHeader/CustomerDetailsHeaderDrawer";
import ContactDetailsActions from 'Components/ContactDetails/ContactDetailsHeader/ContactDetailsActions';
import { useContactConnections } from 'Hooks/Contact/UseContactConnections';
import Comments from 'Components/Comments/Comments';

const Actions = styled(FlexContainer)`
    svg {
        margin-left: 8px;
    }
`;

const CustomerDrawer = ({ customerID, onClose }) => {
    const { data: currentContact } = useContact(customerID);
    const { data: currentContactConnections, isFetching: isFetchingConnections } = useContactConnections(customerID);

    const [{ areaContext }] = useStateValue();
    const history = useHistory();
    const [textTab, setTextTab] = useState('comments');
    
    useEffect(() => {
        if (currentContact) {
            addCustomerLog(currentContact, areaContext.area);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact]);

    const handleMoveContactDetails = () => {
        if (areaContext.area === 'booking') {
            history.push(`/contacts/${customerID}`);
        } else {
            history.push(`/${areaContext.area}-contacts/${customerID}`);
        }
    };

    
    const getTextsTabs = () => {
        return [{
            name: 'Comments',
            key: 'comments',
            counter: currentContact.Comments.length
        }, {
            name: 'Notes',
            key: 'notes',
            counter: getVisibilityNotes(currentContact ? currentContact.Notes : [], areaContext.area).length
        }];
    };

    const renderTextsTabs = () => {
        if (textTab === "notes") {
            return (<ContactDetailsNotes isInCardTab drawerMode currentContact={currentContact} readOnly />)
        } else {
            return (
                <Comments
                    isInCardTab
                    api="ContactID"
                    contactId={customerID}
                    ID={customerID}
                    readOnly
                    drawerMode
                    comments={currentContact ? currentContact.Comments : []}
                />)
        }
    };

    return (
        <NwDrawer
            width={780}
            title={currentContact ?
                <FlexContainer justifyContent='space-between'>
                    <div>Contact Preview</div>
                    <Actions>
                        {areaContext.area !== 'management' &&
                        <>
                            <NwIcon icon={solid('expand-wide')} pointer onClick={handleMoveContactDetails} size='lg' />
                            &nbsp;&nbsp;
                            <ContactDetailsActions contact={currentContact} />
                            &nbsp;&nbsp;
                        </>
                        }
                    </Actions>
                </FlexContainer>
                : 'Contact Preview'
            }
            onClose={onClose}
            whiteBg={false}
        >
            <div className='drawer-content preview'>
                {!currentContact ? <Loading />
                    :
                    <>
                        <CustomerDetailsHeaderDrawer
                            browseTodos={''}
                            contactID={customerID}
                            onOpenStatements={() => { }}
                            onBrowseTodos={() => { }}
                            onViewComments={() => { }}
                            onViewFiles={() => { }} />

                        <div className='content-section'>
                            <ContactSocials previewMode currentContact={currentContact} />
                        </div>
                        <div className='content-section'>
                            <TaskCardsGrid drawerMode contactType='model' previewMode contactId={currentContact.ID} />
                        </div>
                        <div className='content-section'>
                            {isFetchingConnections
                                ?
                                <Loading />
                                :
                                <CustomerDetailsConnections
                                    currentContact={currentContact}
                                    currentContactConnections={currentContactConnections}
                                    readOnly
                                    fluid
                                    contactID={currentContact.ID} 
                                />
                            }
                        </div>
                        {currentContact.SubType === 2 &&
                            <div className='content-section'>
                                <CustomerModelList contactID={currentContact.ID} fluid />
                            </div>
                        }
                        <div className='content-section'>
                            <NwCardTabs
                                tabs={getTextsTabs()}
                                activeTab={textTab}
                                onTabChange={setTextTab}
                                fluid
                            >
                                {renderTextsTabs()}
                            </NwCardTabs>
                        </div>
                    </>
                }
            </div>
        </NwDrawer>
    );
};

export default CustomerDrawer;