import React, { useContext } from 'react';
import { Select } from 'antd';
import _ from 'lodash';

//import { ListContext } from 'Contexts/ListContext';
import { UserContext } from 'Contexts/UserContext';
import useUserDepartments from 'Hooks/UseUserDepartments';

const DepartmentSelector = ({ onChange, mode, allAgencies, allAvailable, value, emptyValue, ...rest }) => {
    //const {cachedList} = useContext(ListContext)
    const { currentUser } = useContext(UserContext)
    const departmentsObject = useUserDepartments(allAvailable, allAgencies);
    const renderOptions = () => {
        if (departmentsObject.isSingleAgency) {
            return (
                <>
                    {emptyValue && <Select.Option value={null} label='All Departments'>All Departments</Select.Option>}
                    {departmentsObject.departments.map(({ Name, ID }) => (
                        <Select.Option key={ID} value={ID} label={Name}>
                            {Name}
                        </Select.Option>
                    ))}
                </>
            )
        } else {
            const departmentsGrouped = _.groupBy(departmentsObject.departments, 'networkAgencyName')
            const departmentsOptions = Object.entries(departmentsGrouped).map(([agency, depts]) => ({
                agency,
                depts: depts.map(({ ID, Name }) => ({
                    Name: Name,
                    ID: ID
                }))
            }));
            return (
                <>
                    {emptyValue && <Select.Option value={null} label='All Departments'>All Departments</Select.Option>}
                    {departmentsOptions.map(({ agency, depts }) => (
                        <Select.OptGroup key={agency} label={agency}>
                            {depts.map(({ Name, ID }) => (
                                <Select.Option key={ID} value={ID} label={`${agency} / ${Name}`}>
                                    {Name}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    ))}
                </>
            )
        }
    }

    return (
        <Select
            autoComplete='off'
            mode={mode}
            value={value ? value : (emptyValue ? null : currentUser.ActiveContext.depId)}
            label={rest.label ? rest.label : 'Department'}
            style={{ width: '100%', minWidth: "200px" }}
            placeholder="Select Departments"
            onChange={onChange}
            allowClear={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            optionLabelProp='label'
        >
            {renderOptions()}
        </Select>
    );
};

export default DepartmentSelector;