import React, { useState } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import SocialForm from 'Components/Social/SocialForm';
import { NwAddButton } from 'Components/Gui/NwButton';
import NwDrawer from 'Components/Gui/NwDrawer';
import { useContactSocials, useRemoveContactSocial } from 'Hooks/Contact/UseContactSocials';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import ContactSocialLink from './ContactSocialLink';


const ListItem = styled(List.Item)`
    justify-content: space-between;
    .ant-list-item-action {
        margin-left: 0;
    }
`;

const SocialsDrawer = ({ contactID, onClose }) => {

    const { data: socials, isFetching } = useContactSocials(contactID);
    //const [showModal, setShowModal] = useState(false);
    const [editSocial, setEditSocial] = useState(null);
    const [newSocial, setNewSocial] = useState(null);
    const [selectedSocial, setSelectedSocial] = useState();

    const { mutateAsync: deleteSocial } = useRemoveContactSocial()
    

    const handleEditSocial = social => async () => {
        setSelectedSocial(social);
        setEditSocial(true);
    };

    const handleRemoveSocial = social => async () => {
        try {
            await deleteSocial({ ID: social.ID, ContactID: contactID })
        } catch (error) {
            console.log('ant : Remove Social Error => ', error);
        }
    };

    const actions = social => {
        return [
            <NwDropDownMenu
                loading={isFetching}
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: handleEditSocial(social) },
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: handleRemoveSocial(social) }
                ]}
            />
        ];
    };

    const handleCloseSocial = () => {
        setSelectedSocial()
        setEditSocial(false)
        setNewSocial(false)
        //setShowModal(false);
    };

    return (
        <NwDrawer
            onClose={onClose}
            title='Socials'
            width="40rem"
        >
            <div className='drawer-toolbar-container'>
                <NwAddButton primary onClick={() => setNewSocial(true)} label="Add a new social link" />
            </div>
            <br />
            <List
                itemLayout="horizontal"
                dataSource={socials}
                loading={isFetching}
                renderItem={social => (
                    <ListItem actions={actions(social)}>
                        <ContactSocialLink contactSocial={social} showUrl />
                    </ListItem>
                )
                }
            />
            {editSocial &&
                <NwDrawer title='Edit Social Link' onClose={handleCloseSocial}>
                    <SocialForm contactID={contactID} selectedSocial={selectedSocial} onClose={handleCloseSocial} />
                </NwDrawer>
            }
            {newSocial &&
                <NwDrawer width="60rem" title='New Social Link' onClose={handleCloseSocial}>
                    <SocialForm contactID={contactID} onClose={handleCloseSocial} />
                </NwDrawer>
            }
            
        </NwDrawer>
    );
};

export default SocialsDrawer;