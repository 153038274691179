import React, { useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { GetTimestampFromDate, showDate, showDateNoTime } from 'Libs/NwMoment';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import Money from 'Components/Gui/Money';
import { NwEditButton } from 'Components/Gui/NwButton';
import { getMoment } from "Libs/NwMoment";
import TransactionEvent from 'Components/Transactions/TransactionEvent';
import TransactionTitle from 'Components/Transactions/TransactionTitle';
import VerticalDate from 'Components/Dates/VerticalDate';
import TransactionDetailDrawer from 'Components/Transactions/TransactionsDrawer/TransactionDetailDrawer';



const Wrapper = styled.div`
    .ant-table {
        .ant-table-cell {
            vertical-align: top;
        }
    }
`

const EventTransactionsListTable = ({ transactions, isExpanded, readOnly, onTransactionDeletedOrUpdated }) => { //expandedItems, onItemExpansion,eventDrawer, onEditTransaction

    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const renderTransactionAgFee = record => {
        return '';
    };

    const closeTransactionDetails = () => {
        setSelectedTransaction(null);
    }

    const isItemExpanded = (itemID) => {
        return isExpanded;
    }

    const transactionColumns = [
        {
            title: 'Title',
            key: 'Title',
            width: '60%',
            render: record => {
                return (
                    <div className="transaction-left">
                        <TransactionTitle transaction={record} />
                        {isItemExpanded(record.ID) &&
                            <small>{record.Description || <em className="super-muted">no description</em>}</small>
                        }
                        {record.ClientBillingInfo &&
                            <div>{record.ClientBillingInfo.CompanyName.toUpperCase()}</div>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'Amount',
            width: '40%',
            align: 'right',
            render: record => {
                return (
                    <div className="transaction-right">
                        <div className="transaction-fee">
                            {(isItemExpanded(record.ID) && (record.Quantity !== 1 || !['Flat','Units'].includes(record.TypeQuantity))) &&
                                <><Money amount={record.SingleFee} currency={record.Currency} />&nbsp;{`x ${record.Quantity} ${record.TypeQuantity}`}</>
                            }
                            <div className='total-fee'><Money highlightCurrency amount={record.TotalFee} currency={record.Currency} /></div>
                            {(isItemExpanded(record.ID) && record.Family === 'JobFee') &&
                                <small>{renderTransactionAgFee(record)}</small>
                                // <br />{renderTransactionCommission(record)}
                            }
                        </div>
                    </div>
                )
            }
        },
    ];
    if (!readOnly) {
        transactionColumns.push(
            {
                title: 'Edit',
                key: 'Edit',
                width: '6rem',
                align: 'right',
                render: record => {
                    return (
                        <div className="edit-button">
                            <NwEditButton iconOnly ghost onClick={(e) => {
                                e.stopPropagation()
                                setSelectedTransaction(record)
                                //onEditTransaction(record)
                            }} />
                        </div>
                    )
                }
            }
        )
    }

    return (
        <Wrapper>
            <Table
                showHeader={false}
                pagination={false}
                columns={transactionColumns}
                size='small'
                dataSource={transactions}
            />
            {selectedTransaction &&
                <TransactionDetailDrawer
                    transaction={selectedTransaction}
                    projectId={selectedTransaction.ProjectID}
                    onClose={closeTransactionDetails}
                    onUpdateTransaction={() => {
                        onTransactionDeletedOrUpdated();
                    }}
                    onTransactionRemoved={() => {
                        onTransactionDeletedOrUpdated();
                        closeTransactionDetails();
                    }}
                />
            }
        </Wrapper>
    );
};

export default EventTransactionsListTable;