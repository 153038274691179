import React from 'react';
import { Select, Input, Button } from 'antd';

import { RATE_TYPES } from 'Constants/RateTypes';

const { Option } = Select;
const InputGroup = Input.Group;

const RateTypeSelector = ({ value, onChange }) => {
    return (
        <InputGroup compact>
            <Button>Type</Button>
            <Select
                autoComplete='off'
                showSearch={false}
                value={value}
                style={{ width: 150 }}
                placeholder="Select a rate"
                filterOption={false}
                onChange={onChange}
                getPopupContainer={triggerNode => triggerNode.parentNode}
            >
                {RATE_TYPES.map(data => {
                    return (
                        <Option value={data.TypeValue} key={data.TypeValue}>{data.Type}</Option>
                    );
                })}
            </Select>
        </InputGroup>
    );
};

export default RateTypeSelector;