import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import styled from 'styled-components';

import { Loading } from 'Components/Gui/Loading';
import ScoutingMeetingModels from 'Components/EventDrawer/ScoutingMeetingsEvent/ScoutingMeetingModels';
import TravelPlanCard from 'Components/TravelPlanCard/TravelPlanCard';
import { ObjectHistoryContainer, EventContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import ScoutingMeetingForm from 'Components/EventDrawer/ScoutingMeetingsEvent/ScoutingMeetingForm';
import ScoutingMeetingDetails from 'Components/EventDrawer/ScoutingMeetingsEvent/ScoutingMeetingDetails';
import NwDrawer from 'Components/Gui/NwDrawer';
import StyleVariables from 'Components/Gui/StyleVariables';
import FlexContainer from 'Components/Gui/FlexContainer';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import { ListContext } from "Contexts/ListContext";
import { getEventLocationIframeUrl, getEventDirectionUrl } from 'Libs/NwGoogleMap';
import {
    useScoutingMeeting,
    useDeleteScoutingMeeting,
    forceReloadScoutingMeeting
} from 'Hooks/Event/UseScoutingMeeting';
import { useTravelPlan } from 'Hooks/TravelPlan/UseTravelPlan';



const LocationUrl = styled.a`
    color: ${StyleVariables.PrimaryColor};
    text-decoration: underline;

    &:hover {
        color: ${StyleVariables.PrimaryColor};
        text-decoration: underline;
    }
`;

const MeetingModels = styled.div`
    padding: 0 2rem;
`;

const ScoutingMeetingsEvent = props => {
    const {
        ID,
        travelPlanId,
        viewMode,
        contactId,
        plainMode,
        allDay,
        dateSelected,
        afterUpdate,
        afterRemove,
        onClose,
        onTouchForm
    } = props;
    const { cachedList } = useContext(ListContext);
    const countriesList = [...cachedList.countries.items];
    countriesList.forEach(el => {
        el.Matches = `${el.Name} ${el.AlternateSpelling}`;
    });
    const [isEdit, setIsEdit] = useState(false);
    const [scoutingMeetingID, setScoutingMeetingID] = useState();
    const { data: scoutingMeetingsData } = useScoutingMeeting(scoutingMeetingID);
    const { data: travelPlan } = useTravelPlan(travelPlanId);

    const { mutateAsync: onDeleteScoutingMeeting } = useDeleteScoutingMeeting();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setScoutingMeetingID(ID);
    }, [ID]);

    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    if (!scoutingMeetingsData && scoutingMeetingID) {
        return (<Loading textBlack />);
    }

    const HeaderContent = () => (
        scoutingMeetingsData
            ?
            (plainMode
                ?
                <div />
                :
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <TravelPlanCard
                        travelPlan={scoutingMeetingsData.TravelPlan}
                        plainMode />
                </div>
            )
            :
            <div className="ant-drawer-header ant-drawer-header-sticky ">
                <div className="ant-drawer-title">Add New Scouting Meeting</div>
            </div>
    )

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteScoutingMeeting({ ID: eventId, scoutingMeetingId: scoutingMeetingsData.ID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const EventTitleContent = () => {
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(scoutingMeetingsData.ID)
                    afterRemove(true)
                })
            },
            eventProperties: {
                eventType: "Meeting",
                isSingle: true
            },
            event: scoutingMeetingsData
        }
        return <EventTitle {...props} />
    }

    const EventCommentsDocumentsContent = ({ extra, showOnlyContent, showOnlyActions }) => (
        <EventCommentsDocuments
            event={scoutingMeetingsData}
            api='ScoutingMeetingID'
            type='ScoutingMeeting'
            drawerMode={!plainMode}
            fetchEvent={() => {
                forceReloadScoutingMeeting(scoutingMeetingsData.ID)
            }}
            extra={extra}
            showOnlyContent={showOnlyContent}
            showOnlyActions={showOnlyActions}
            disableTransaction={true}
            onTransactionCreatedOrUpdated={() => { forceReloadScoutingMeeting(scoutingMeetingsData.ID) }}
            onTouchForm={onTouchForm}
        />
    )

    const EventDetails = () => (
        <>
            <EventTitleContent />
            <ScoutingMeetingDetails scoutingMeetingsData={scoutingMeetingsData} />
            <EventCommentsDocumentsContent showOnlyContent />
            <ObjectHistoryContainer>
                <ObjectHistory asLine object={scoutingMeetingsData} />
            </ObjectHistoryContainer>
            <EventCommentsDocumentsContent showOnlyActions />
        </>
    )

    const EditForm = () => (
        <ScoutingMeetingForm
            scoutingMeetingsData={scoutingMeetingsData}
            travelPlanId={travelPlanId}
            viewMode={viewMode}
            contactId={contactId}
            plainMode={plainMode}
            allDay={allDay}
            dateSelected={dateSelected}
            afterUpdate={afterUpdate}
            onTouchForm={touchForm => {
                if (scoutingMeetingsData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            }}
            onCancel={() => {
                handleClearTouchForm();
                if (scoutingMeetingsData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            }}
            onUpdateScoutingMeetingData={scoutingMeetingId => {
                handleClearTouchForm();
                if (scoutingMeetingId) {
                    setScoutingMeetingID(scoutingMeetingId)
                } else {
                    forceReloadScoutingMeeting(scoutingMeetingID);
                }
            }} />
    );

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const Content = () => (
        plainMode ?
            <>
                {scoutingMeetingsData &&
                    <div className="event-details">
                        <EventDetails />
                    </div>
                }
                {isEdit &&
                    <NwDrawer
                        padding="0px"
                        width={740}
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title='Edit Scouting Meeting'
                        onClose={() => {
                            setIsEdit(false);
                            handleClearTouchForm();
                        }}>
                        <EventContainer>
                            <EditForm />
                        </EventContainer>
                    </NwDrawer>
                }
            </>
            :
            <>
                {!isEdit && scoutingMeetingsData ?
                    <>
                        <div className="event-content-wrapper">
                            <div className="event-details">
                                <EventDetails />
                            </div>
                        </div>
                    </>
                    :
                    <div className="event-content-wrapper">
                        <EditForm />
                    </div>
                }
            </>
    );

    // TODO: model views should be from scouting meeting data.
    const meeting = travelPlan && travelPlan.Meetings.find(meeting => meeting.ID === scoutingMeetingID);
    // TODO

    return (plainMode ?
        <EventContainer>
            {scoutingMeetingsData &&
                <Row gutter={32}>
                    <Col span={14}>
                        <Content />
                        <div dangerouslySetInnerHTML={{ __html: getEventLocationIframeUrl(scoutingMeetingsData, cachedList.countries.items) }} />
                        <FlexContainer mg='8px 0 0'>
                            <LocationUrl href={getEventDirectionUrl(scoutingMeetingsData, cachedList.countries.items)} target='_blank'>
                                {getEventDirectionUrl(scoutingMeetingsData, cachedList.countries.items)}
                            </LocationUrl>
                        </FlexContainer>
                    </Col>
                    <Col span={10}>
                        <div className="event-content-wrapper">
                            <MeetingModels>
                                <ScoutingMeetingModels
                                    travelPlanId={travelPlanId}
                                    modelViews={meeting ? meeting.ModelViews : []}
                                    meetingId={meeting.ID}
                                />
                            </MeetingModels>
                        </div>
                    </Col>
                </Row>
            }
        </EventContainer>
        : <EventContainer>
            <HeaderContent />
            <Content />
        </EventContainer>

    );
};

export default ScoutingMeetingsEvent;
