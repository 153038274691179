import React, { useState } from "react";
import { ObjectHistoryContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { useDeleteAccommodation, forceReloadAccommodation, useDeleteAccommodationGroup, } from 'Hooks/Event/UseAccommodation';
import RoomLodgingModels from 'Components/EventDrawer/RoomLodgingEvent/RoomLodgingModels';
import AccommodationDetails from 'Components/EventDrawer/AccommodationEvent/AccommodationDetails';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import EventDocuments from "Components/EventDocuments/EventDocuments";
import FlexContainer from 'Components/Gui/FlexContainer';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import AddModelsToNonBookingEvent from 'Components/EventDrawer/BookingEvent/AddModels/AddModelsToNonBookingEvent';
import AccommodationActions from 'Components/EventDrawer/AccommodationEvent/AccommodationActions';
import AccommodationModelDetails from 'Components/EventDrawer/AccommodationEvent/AccommodationModelDetails';
import styled from "styled-components";
import Axios from "axios";

const Wrapper = styled.div`
    .accommodation-models-details-wrapper {
        padding-bottom: 3rem;
    }
`

const AccommodationContent = ({
    accommodationData,
    accommodationID,
    afterRemove,
    afterUpdate,
    handleSelectProject,
    isEdit,
    onTouchForm,
    plainMode,
    projectId,
    setEditModelEvent,
    setIsEdit,
}) => {

    const [showAddModelDrawer, setShowAddModelDrawer] = useState(false);
    const { mutateAsync: onDeleteAccommodation } = useDeleteAccommodation();
    const { mutateAsync: onDeleteAccommodationGroup } = useDeleteAccommodationGroup();

    const AccommodationRelatedEvents = accommodationData.RelatedEvents ? [accommodationData, ...accommodationData.RelatedEvents] : [accommodationData]

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteAccommodation({ ID: eventId, accommodationId: accommodationID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleRemoveEventGroup = async groupGuid => {
        try {
            await onDeleteAccommodationGroup({ GroupGUID: groupGuid });
        } catch (error) {
            console.log('ant : Remove Event Group Error => ', error);
        }
    };

    const handleUnlinkEvent = async eventId => {
        try {
            await Axios.put(`/accommodation/UnLink/${accommodationID}`)
            forceReloadAccommodation(accommodationID)
            if (accommodationData.RelatedEvents && accommodationData.RelatedEvents.length > 0) {
                accommodationData.RelatedEvents.forEach(e => forceReloadAccommodation(e.ID));
            };
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    }

    const getEventCommentsDocumentsProps = (extra, showOnlyContent, showOnlyActions) => {
        return {
            event: { ...accommodationData, elType: 'Accommodation', uniqID: `Accommodation-${accommodationData.ID}` },
            api: 'AccommodationID',
            type: 'Accommodation',
            contactId: !accommodationData.GroupGuid && accommodationData.ModelID,
            drawerMode: !plainMode,
            fetchEvent: () => { forceReloadAccommodation(accommodationData.ID) },
            extra: accommodationData.Room ? null : extra,
            showOnlyContent: showOnlyContent,
            showOnlyActions: showOnlyActions,
            onTouchForm: onTouchForm,
            disableComments: true,
        }
    }

    const getEventTitleContentProps = () => {
        const singleEvent = (!accommodationData.RelatedEvents || accommodationData.RelatedEvents.length === 0);
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    if (!singleEvent) {
                        setEditModelEvent(accommodationData)
                    }
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(accommodationData.ID)
                    if (accommodationData.ID === accommodationID) {
                        afterRemove(true)
                    } else {
                        afterUpdate()
                    }
                }),
                onDeleteGroup: (async () => {
                    await handleRemoveEventGroup(accommodationData.GroupGuid)
                    afterRemove(true);
                }),
                onUnlink: (() => handleUnlinkEvent(accommodationData.ID)),
                onProjectUnlink: (() => handleSelectProject()),
                onAcknowledgmentUpdate: (() => {
                    forceReloadAccommodation(accommodationData.ID)
                    afterUpdate()
                }),
                onCancelUpdate: (() => {
                    forceReloadAccommodation(accommodationData.ID)
                    afterUpdate()
                }),
            },
            eventProperties: {
                eventType: "Accommodation",
                isSingle: singleEvent,
                hasExport: true
            },
            event: accommodationData
        }
        return props;
    }

    return (
        <>
            <Wrapper>
                <EventTitle {...getEventTitleContentProps()} />
                <AccommodationDetails
                     type={accommodationData && accommodationData.Room ? 'RoomLodging' : 'Accommodation'}
                     accommodationData={accommodationData} 
                />
                {accommodationData.GroupGuid && accommodationData.GroupDocuments.length > 0 &&
                    <FlexContainer pd='0 20px' column alignItems='flex-start'>
                        <EventDocuments showDocumentsByDefault event={accommodationData} type={'eventGroup'} updateEvent={() => forceReloadAccommodation(accommodationData.ID)} />
                    </FlexContainer>
                }
                {!accommodationData.GroupGuid && <EventCommentsDocuments {...getEventCommentsDocumentsProps(null, true)} />}
                <ObjectHistoryContainer>
                    <ObjectHistory asLine object={accommodationData} />
                </ObjectHistoryContainer>
                {!accommodationData.GroupGuid ?
                    <EventCommentsDocuments {
                        ...getEventCommentsDocumentsProps(
                            <AccommodationActions
                                plainMode={plainMode}
                                accommodationData={accommodationData}
                                onAddModels={() => setShowAddModelDrawer(true)}
                            />,
                            false,
                            true
                        )}
                    />
                    :
                    <AccommodationActions
                        plainMode={plainMode}
                        accommodationData={accommodationData}
                        onAddModels={() => setShowAddModelDrawer(true)}
                    />
                }
                <div className="accommodation-models-details-wrapper">
                    {(AccommodationRelatedEvents.length > 1)
                        ?
                        AccommodationRelatedEvents.map(event =>
                            <AccommodationModelDetails
                                key={`event-${event.ID}`}
                                plainMode={plainMode}
                                event={event}
                                afterUpdate={afterUpdate}
                                afterRemove={afterRemove}
                                onEditModelEvent={() => {
                                    setEditModelEvent(event);
                                    setIsEdit(!isEdit);
                                }}
                                onUpdateAccommodations={() => {
                                    forceReloadAccommodation(accommodationData.ID);
                                    AccommodationRelatedEvents.forEach(e => forceReloadAccommodation(e.ID));
                                }}
                                openedEventID={accommodationData.ID}
                                onTouchForm={onTouchForm}
                            />
                        )
                        :
                        <></>
                    }
                    {accommodationData.RoomID &&
                    <RoomLodgingModels accommodationData={accommodationData} />
                }
                </div>
            </Wrapper>
            {showAddModelDrawer &&
                <AddModelsToNonBookingEvent
                    event={accommodationData}
                    projectId={projectId || (accommodationData.Project && accommodationData.Project.ID)}
                    onUpdateEvent={() => {
                        forceReloadAccommodation(accommodationData.ID)
                        afterUpdate()
                    }}
                    eventType='Accommodation'
                    onClose={() => setShowAddModelDrawer(false)} />
            }
        </>
    )
};

export default AccommodationContent;
