import styled from 'styled-components';

export const ReportCreationDrawerWrapper = styled.div`
    opacity: ${props => props.isSending && 0.4};

    .project-general-documents {
        .project-general-document {
            padding: .25rem .5rem;
            label.ant-checkbox-wrapper {
                font-size: ${props => props.theme.FontSizeSm};
                text-transform: uppercase;

                svg {
                    margin: 0 .5rem;
                }
            }
        }
    }

    .export-options {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
            padding: 0 1rem 0 0;
            margin: 0;
        }
    }

    .export-content {
        padding: 1rem 0;

        
        .models-transactions-container {
            margin-bottom: 1rem;

            .model-transactions-container {
                margin-top: .75rem;
                margin-bottom: 1rem;

                h5 {
                    text-transform: uppercase;
                    padding-left: 2rem;
                }
            }
        }

        .other-transactions-container  {
            margin: 1rem 0;

            h5 {
                text-transform: uppercase;
                padding-left: 2rem;
            }
        }

        .transactions-container {
            padding-left: 2rem;
    
            .ant-table {
                table {
                    border-collapse: collapse;
                    tr {
                        td {
                            vertical-align: top;
                            border-bottom: none;
                            padding: 0;
                            border-top: ${props => props.theme.LighterBorder};

                            &.ant-table-selection-column {
                                vertical-align: middle;
                                padding: 0 1rem 0 1rem;

                                .ant-checkbox {
                                    margin-right: 0;
                                    margin-bottom: 0;
                                }
                            }
                            
                            .transaction-date {
                                min-width: 5rem;
                                padding-top: .25rem;
                                //min-height: 5rem;
                                //border-right: ${props => props.theme.DefaultBorder};
                                border-left: ${props => props.theme.DefaultBorder};
                                border-right: ${props => props.theme.DefaultBorder};
                            }

                            .transaction-text {
                                padding: .5rem 1rem;
                                small {
                                    display: block;
                                    line-height: 1.5;
                                }
                            }

                            .transaction-amount {
                                text-align: right;
                                min-width: 10rem;
                                
                                .transaction-fee {
                                    padding: .5rem 1rem;
                                    white-space: nowrap;

                                    small {
                                        display: block;
                                    }

                                    .total-fee {
                                        font-size: 1rem;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }

                        &.ant-table-row-selected  {
                            td {
                                background: transparent;
                            }
                        }

                        /* :nth-of-type(1) {
                            td {
                                border-top: none;
                            }
                        } */
                    }
                }
            }
        }

        .events-container {
            margin-bottom: 3rem;

            .event-container {
                margin-bottom: 1rem;
                border-bottom: ${props => props.theme.LighterBorder};
                padding-bottom: .5rem;

                .working-days-container {
                    padding-left: 3rem;

                    .working-day-container {
                        margin: .5rem  0 .5rem 0;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .ant-checkbox-wrapper {
        .ant-checkbox {
            margin-right: 1rem;
            margin-bottom: .25rem;
        }
        h2 {
            margin: 0;
            font-size: 1rem;
        }
        p {
            margin: 0;
        }
    }
`;


