import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import styled from 'styled-components';
import { Modal, Slider } from 'antd';
import Axios from 'axios';

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import FlexContainer from 'Components/Gui/FlexContainer';
import getCroppedImg from 'Libs/NwCropImage';
import { forceReloadContact } from 'Hooks/Contact/UseContact';

const CropContainer = styled.div`
    position: relative;
    width: 100%;
    height: 55vh;
    background: #333;
`;

const StyledSlider = styled(Slider)`
    width: 360px;
    margin-left: 16px;
`;

const ModelCoverGenerator = ({ imageUrl, contactID, onClose }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const fetchAsBlob = url => fetch(url)
    .then(response => response.blob());

    const handleSave = async () => {   
        setLoading(true);     
        try {
            const croppedImage = await getCroppedImg(
                imageUrl,
                croppedAreaPixels
            );
            const blob = await fetchAsBlob(croppedImage);
            let data = new FormData();
            data.append('files', blob);

            await Axios.post(`/contacts/setcover/${contactID}`, data);
            await forceReloadContact(contactID);
            setLoading(false);
            onClose();
        } catch (error) {   
            console.log('ant: Set Model Cover Error => ', error);  
            setLoading(false); 
        }
    }

    return (
        <Modal
            width='80vw'
            bodyStyle={{
                height: '70vh'
            }}
            visible={true}
            title="Model Cover"
            onCancel={onClose}
            footer={[
                <NwCancelButton
                    key='cancel'
                    disabled={loading}
                    onClick={onClose}
                />,
                <NwSaveButton
                    key='save'
                    disabled={loading}
                    loading={loading}
                    onClick={handleSave} />
            ]}>
            <CropContainer>
                <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={3 / 4}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </CropContainer>
            <FlexContainer fullWidth mg='16px 0 8px' justifyContent='center'>
                Zoom
                <StyledSlider value={zoom} onChange={setZoom} max={3} min={1} step={0.1}  />
            </FlexContainer>
        </Modal>
        
    )
};

export default ModelCoverGenerator;

