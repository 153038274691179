import React from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { getMoment } from "Libs/NwMoment";
import { solid, thin, duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import ToDoCheck from 'Components/Gui/ToDoCheck';
import { getExpirationInDays, getNow } from 'Libs/NwMoment';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { NwTag } from 'Components/Gui/NwTag';
import TaskContactAvatars from './TaskContactAvatars';


const StyledIcon = styled(NwIcon)`
    cursor: pointer;
    font-size: 25px;
    opacity: .4;
`

const StyledIconOver = styled(NwIcon)`
    cursor: pointer;
    font-size: 15px;
    opacity: 1;
    position: absolute;
    top: -5px;
    right: 8px;
        
    .fa-primary {
        fill: #fff;
        opacity: 1;
    }
    .fa-secondary {
        fill: ${props => props.theme.SuccessColor};
        opacity: 1;
    }
`

const StyledListItem = styled(List.Item)`
    .ant-list-item-meta {
        align-items: center;
        .ant-list-item-meta-avatar {
            padding: .25rem 1rem 0 1.5rem;
        }
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                cursor: pointer;
                .title-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 1rem;
                    .title-icon-container {
                        font-size: .75em;
                        padding-right: .5rem;

                        &.title-today {
                            color: ${props => props.theme.WarningColor};
                        }
                        &.title-overdue {
                            color: ${props => props.theme.DangerColor};
                        }
                    }
                    em {
                        display: block;
                        flex: 1 1 0;
                        font-style: normal;
                    }
                }
                margin-bottom: .25rem;
            }
            .ant-list-item-meta-description {
                .description-container {
                    cursor: pointer;
                    font-size: ${props => props.theme.FontSizeSm};
                    padding-right: 2rem;
                    .ant-typography {
                        font-size: ${props => props.theme.FontSizeSm} !important;
                        color: ${props => props.theme.TextColorMuted};
                    }
                }
                .created-by {
                    text-transform: uppercase;
                    font-size: ${props => props.theme.FontSizeSm};
                    padding-top: .25rem;
                }
            }
        }
    }

    &.is-extended {
        .ant-list-item-meta {
            align-items: flex-start;
            .ant-list-item-meta-content {
                .ant-list-item-meta-title {
                    .title-container {
                        em {
                            font-size: ${props => props.theme.FontSizeLg};
                        }
                    }
                    margin-bottom: .5rem;
                }
                .ant-list-item-meta-description {
                    .description-container {
                        cursor: pointer;
                        font-size: ${props => props.theme.FontSizeBase};
                        padding-right: 2rem;
                        color: ${props => props.theme.TextColor};
                    }
                }
            }
        }
    }
`

const TaskListItemContact = ({ todoItem, contactID, onUpdate, onSelect, extended }) => {
    const previewMode = false;

    //uniform objects between models and clients
    const todoItemContactToDo  = todoItem.TodoContacts 
        ? 
        { ...todoItem.TodoContacts.find(tc => tc.ContactID === contactID) }
        : 
        { ID: todoItem.ID, Status: todoItem.Status, Description: todoItem.Description }

    const todo = {
        ...todoItem,
        ContactTodo: todoItemContactToDo,
        TypeName: "ToDo",
        Completed: todoItem.GlobalStatus === "Completed",
        HalfCompleted: todoItem.GlobalStatus === "PartiallyCompleted"
    };

    const handleSelectTask = () => {
        onSelect(todo.ContactTodo.ID)
    }

    const tagTimeStatus = () => {
        const todoDate = todoItem.Date;
        if (todoItem.GlobalStatus === "Completed" || !todoDate) return (0)
        if (getExpirationInDays(todoDate) === "today") {
            return 1
        }
        if (getMoment(todoDate) < getNow()) {
            return 2
        }
    }

    const TaskStatusTag = () => {
        const todoDate = todoItem.Date;
        const tagStatus = tagTimeStatus();
        switch (tagStatus) {
            case 1:
                return (<NwTag type="warning">Due Today</NwTag>)
            case 2:
                return (<NwTag type="danger">Overdue {getExpirationInDays(todoDate)}</NwTag>)
            default:
                return null
        }
    }

    const TaskToggle = () => {
        if (todo.GroupGuid) {
            if (todo.ContactTodo.Status) {
                return (
                    <span className="fa-stack" onClick={handleSelectTask}>
                        <StyledIcon icon={thin("ballot-check")} />
                        <StyledIconOver icon={duotone('circle-check')} />
                    </span>
                )
            }
            return (
                <StyledIcon icon={thin("ballot-check")} onClick={handleSelectTask} />
            )
        } else {
            return (
                <ToDoCheck
                    previewMode={previewMode}
                    onStatusChanged={() => onUpdate(todo.ContactTodo.ID)}
                    eventId={todo.TodoContacts ? todo.ContactTodo.ID : todo.ContactID}
                    completedValue={todo.Completed}
                    size={25}
                />
            )
        }
    }

    const TaskDescription = () => {
        if (extended) {
            return (
                <>
                    {todo.PrivacyLevelName !== "Public"
                        ?
                        <>
                            (todo.Project && <div className="task-projectline-container with-tag"><ProjectLine project={todo.Project} ellipsis /></div>)
                            <div className="task-personal-tag"><NwTag type="primary">Personal</NwTag></div>
                        </>
                        :
                        (todo.Project && <div className="task-projectline-container"><ProjectLine project={todo.Project} ellipsis /></div>)
                    }
                    <div className="description-container" onClick={handleSelectTask}>
                        {todo.ContactTodo.Description}
                    </div>
                    <CreatedBy author={todo.UserCreated} date={todo.DateCreated ? todo.DateCreated : todo.Date} />
                    {todo.TodoContacts && todo.TodoContacts.length > 1 &&
                        <TaskContactAvatars task={todo} />
                    }
                </>
            )
        }
        return (
            <div className="description-container" onClick={handleSelectTask}>
                <NwEllipsis text={todo.ContactTodo.Description} rows={1} />
            </div>
        )
    }

    const TaskTitleIcon = () => {
        const tagStatus = tagTimeStatus()
        switch (tagStatus) {
            case 1:
                return (
                    <span className="title-icon-container title-today">
                        <NwIcon icon={solid('alarm-clock')} />
                    </span>
                )
            case 2:
                return (
                    <span className="title-icon-container title-overdue">
                        <NwIcon icon={solid('alarm-exclamation')} />
                    </span>
                )
            default:
                return null
        }
    }

    const TaskTitle = () => (
        <span className="title-container" onClick={handleSelectTask}>
            <TaskTitleIcon />
            <em>{todo.Title}</em>
            <TaskStatusTag />
        </span>
    )

    return (
        <StyledListItem className={extended ? "is-extended" : ""}>
            <List.Item.Meta
                avatar={<TaskToggle />}
                title={<TaskTitle />}
                description={<TaskDescription />}
            />
        </StyledListItem>
    )
};

export default TaskListItemContact;

