import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';

//import useLocalStorageState from 'Hooks/UseLocalStorageState';
import NwDrawer from 'Components/Gui/NwDrawer';
import { forceReloadActivePackages, usePackage, forceReloadPackage } from "Hooks/Packages/UsePackages";
import PackageContent from "Components/PackageDrawer/PackageContent";
import PackageHeader from 'Components/PackageDrawer/PackageHeader/PackageHeader';
import { groupPackage } from 'Libs/NwUtils';
import { PackageDrawerContainer } from 'Components/PackageDrawer/PackageDrawerUI';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import PackageRecipientsSummary from './PackageRecipientsSummary';
import { forceReloadProjectPackages } from 'Hooks/Project/UseProjectPackages';
import Axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SendDrawer from 'Components/SendDrawer/SendDrawer';
import PackageShareLink from './PackageShareLink';
import { PackageContext } from './PackageContext';
import { ListContext } from 'Contexts/ListContext';

const PackageDrawer = ({ packageId, onClose, isSingleSend }) => {
    const breakpoints = useNwBreakPoints();
    const [currentPackageID, setCurrentPackageID] = useState(packageId);
    const [addMode, setAddMode] = useState(false);
    const { data: currentPackage } = usePackage(packageId);
    const [openAddRecipients, setOpenAddRecipients] = useState(false);
    const [drawerShareAnonymousLink, setDrawerShareAnonymousLink] = useState(false);
    
    const { templates } = useContext(ListContext).cachedList;
    const filteredTemplatesByType = templates.items.filter(template => template.area === "Package");
    
    //to improve when we will need to make the user choose between different templates
    //console.log('ant : filteredTemplatesByType => ', filteredTemplatesByType);

    const history = useHistory();

    const handleAddPackageRecipient = async email => {
        const recipientData = {
            PackageID: currentPackage.ID,
            ContactID: email.ContactID,
            ConnectionID: email.ConnectionID,
            Email: email.Email,
            CC: email.CC
        }
        try {
            await Axios.post('/PackageRecipients', recipientData);
            afterUpdatePackage()

        } catch (error) {
            console.log('ant : Add Recipients Error => ', error);
        }
    }

    const handleAddMultiplePackageRecipient = async emails => {
        await Axios.post(`/Packages/AddRecipients/${currentPackage.ID}`, emails)
        afterUpdatePackage()
    }

    const handleRemovePackageRecipient = async email => {
        const recipient = currentPackage.Recipients.find(item => item.Email === email);
        if (recipient) {
            try {
                await Axios.delete(`/PackageRecipients/${recipient.ID}`)
                afterUpdatePackage();
            } catch (error) {
                console.log('ant : Delete Recipient Error => ', error)
            }
        }
    };

    const handleSendPackage = async (subject, message, recipients, signatureID) => {
        if (currentPackage.Models.length > 0) {
            try {
                const packagesenddata = {
                    PackageID: currentPackage.ID,
                    //Template: "pack1",
                    Subject: subject,
                    Message: message,
                    SignatureID: signatureID,
                }
                await Axios.post("/Packages/Send", packagesenddata)
                history.push('/dashboard');
            } catch (error) {
                console.log('ant : Fetch Send Package Error => ', error);
            }
        }
    };

    const handleAfterSendPackage = () => {
        afterUpdatePackage()
        handleCloseAddRecipients()
    }

    const handleOnClose = () => {
        forceReloadActivePackages()
        if (currentPackage.Project && currentPackage.Project.ID) {
            forceReloadProjectPackages(currentPackage.Project.ID)
        }
        onClose();
    }

    const afterUpdatePackage = (skipactive, isDeleted = false) => {
        if (!isDeleted) {
            forceReloadPackage(currentPackageID)
        }
    }

    const onDeletePackage = () => {
        handleOnClose()
    }

    const onClonePackage = (ClonedPackageID) => {
        setCurrentPackageID(ClonedPackageID)
    }

    const handleSetAddMode = () => {
        setAddMode(prevAddMode => !prevAddMode);
    };

    const handleOpenAddRecipients = () => {
        setOpenAddRecipients(true);
    };

    const handleGetAnonymousLink = () => { //async
        setDrawerShareAnonymousLink(true)
    }

    const handleClosePackageShareLink = () => {
        forceReloadPackage(currentPackage.ID)
        setDrawerShareAnonymousLink(false);
    }

    const handleCloseAddRecipients = () => {
        setOpenAddRecipients(false);
        if (isSingleSend) {
            onDeletePackage();
        }
    };

    const [groupedPackage, setGroupedPackage] = useState();
    
    const hydratePackageWithGroups = (pack) => {
        const updatedGroupedPackage = groupPackage(pack);
        setGroupedPackage(updatedGroupedPackage);
    }

    useEffect(() => {
        if (currentPackage) {
            hydratePackageWithGroups(currentPackage);
        }
    }, [currentPackage]);

    const compact = false

    const onAfterUpdatePackage = useCallback(() => {
        afterUpdatePackage()
    }, [afterUpdatePackage]);
    
    const packageContextValue = useMemo(() => ({
        afterUpdatePackage: onAfterUpdatePackage,
        groupedPackage,
        compact,
    }), [
        onAfterUpdatePackage,
        groupedPackage,
        compact,
    ]);

    if (isSingleSend) {
        if (!currentPackage) {
            return null;
        }
        return (
            <NwDrawer padding="0px" title='Add Recipients' onClose={handleCloseAddRecipients}>
                <SendDrawer
                    afterSend={handleAfterSendPackage}
                    currentPackage={currentPackage}
                    existingEmails={currentPackage.Recipients}
                    hasMessage
                    hasSubject
                    isSingleSend={true}
                    messageDefault=""
                    onCancel={handleCloseAddRecipients}
                    onRemoveRecipients={handleRemovePackageRecipient}
                    onSelectMultipleRecipients={handleAddMultiplePackageRecipient}
                    onSelectRecipient={handleAddPackageRecipient}
                    onSend={handleSendPackage}
                    onUpdatePackage={afterUpdatePackage}
                    recipientsBox
                    searchBox
                    sendBox
                    subjectDefault={currentPackage.Name}
                    suggestions={[]} //add here e-mail addresses for this package
                    textsBox
                />
            </NwDrawer>
        );
    }

    const isWideAndShortWindow = window.innerHeight < 800 && window.innerWidth > 1000;

    return (
        <NwDrawer
            title='Package'
            width='90vw'
            maxWidth='1600px'
            whiteBg={false}
            onClose={handleOnClose}
            padding={breakpoints > 2 ? "1.6rem" : "0"}
        >
            {(groupedPackage && currentPackage) &&
                <PackageContext.Provider value={packageContextValue}>
                    <PackageDrawerContainer>
                        <PackageHeader
                            onDeletePackage={onDeletePackage}
                            onClonePackage={onClonePackage}
                        />
                        <PackageRecipientsSummary 
                            recipients={currentPackage.Recipients} 
                            isSent={currentPackage.LastSentDate ? true : false}
                            onGetAnonymousLink={handleGetAnonymousLink}
                            onOpenAddRecipients={handleOpenAddRecipients}
                        />
                        <PackageContent
                            addMode={addMode}
                            showOnlyGroupModels
                            onSetAddMode={handleSetAddMode}
                        />
                        {openAddRecipients &&
                            <NwDrawer
                                padding="0px"
                                title='Add Recipients'
                                onClose={handleCloseAddRecipients}
                                width={isWideAndShortWindow ? '80rem' : '40rem'}
                            >
                                <SendDrawer
                                    twoColumns={isWideAndShortWindow}
                                    afterSend={handleAfterSendPackage}
                                    currentPackage={currentPackage}
                                    existingEmails={currentPackage.Recipients}
                                    hasMessage
                                    hasSubject
                                    messageDefault=""
                                    onCancel={handleCloseAddRecipients}
                                    onRemoveRecipients={handleRemovePackageRecipient}
                                    onSelectMultipleRecipients={handleAddMultiplePackageRecipient}
                                    onSelectRecipient={handleAddPackageRecipient}
                                    onSend={handleSendPackage}
                                    onUpdatePackage={afterUpdatePackage}
                                    recipientsBox
                                    searchBox
                                    sendBox
                                    subjectDefault={currentPackage.Name}
                                    suggestions={[]} //add here e-mail addresses for this package
                                    textsBox
                                />
                            </NwDrawer>
                        }
                        {drawerShareAnonymousLink &&
                            <PackageShareLink
                                currentPackage={currentPackage}
                                onClose={handleClosePackageShareLink}
                                anonymous
                            />
                        }
                    </PackageDrawerContainer>
                </PackageContext.Provider>
            }
        </NwDrawer>
    );
};

export default PackageDrawer;