import React from 'react';
import styled from 'styled-components';
import { Tabs, Tooltip } from 'antd';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const TabsStyled = styled(Tabs)`
    .ant-tabs-content {
        padding-top: 0;
        margin-bottom: ${props => props.$mobileView && '60px'};
    }
    .ant-tabs-nav-list {
        justify-content: flex-end;
        width: 100%;
        .ant-tabs-tab {
            min-width: 40px;
            display: flex;
            justify-content: center;
            .ant-tabs-tab-btn svg {
                font-size: 1.5em;
            }
        }
    }
    &.ant-tabs {
        position: relative;
        overflow: visible;
        .ant-tabs-nav {
            position: fixed;
            padding-right: 4rem;
            z-index: 1035;
            top: ${props => props.$mobileView ? "128px" : "138px"};
            width: ${props => (props.$isdrawer && !props.$mobileView) ? "calc(90vw - 60px)" : "100%"};
            margin: 0;

            &::before {
                border-bottom: none;
            }
            .ant-tabs-nav-wrap {
                position: ${props => props.$mobileView && 'fixed'};
                bottom: ${props => props.$mobileView && 0};
                background: ${props => props.$mobileView && 'white'};
                width: 100%;
                padding-right: ${props => props.$mobileView && '8px'};
                border-top: ${props => props.$mobileView && '2px solid #e8e8e8'};
            }
        }
    }
    .ant-tabs-extra-content {
        margin-left: auto;
    }
`;

const ProjectDetailTabs = ({ isdrawer, tabs, layout, projectId, onSetLayout }) => {
    const breakpoints = useNwBreakPoints();
    const onTabChange = activeKey => {
        window.scrollTo(0, 0);
        onSetLayout(activeKey);
    }

    return (
        tabs &&
        <TabsStyled
            $isdrawer={isdrawer}
            $mobileView={breakpoints < 4}
            activeKey={layout}
            defaultActiveKey="1"
            size="middle"
            destroyInactiveTabPane
            onChange={onTabChange}
            items={[
                {
                    label: (
                        <Tooltip title="Project Overview">
                            <NwIcon icon={light('clipboard-list-check')} />
                        </Tooltip>
                    ),
                    key: 'overview',
                    children: tabs[0]
                },
                {
                    label: (
                        <Tooltip title="Timeline">
                            <NwIcon icon={light('calendar-alt')} />
                        </Tooltip>
                    ),
                    key: 'calendar',
                    children: tabs[1]
                },
                { 
                    label: (
                        <Tooltip title="Models">
                            <NwIcon icon={light('users')} />
                        </Tooltip>
                    ),
                    key: 'users',
                    children: tabs[2]
                },
                {
                    label: (
                        <Tooltip title="Fees & Expenses">
                            <NwIcon icon={light('message-dollar')} />
                        </Tooltip>
                    ),
                    key: 'invoice',
                    children: tabs[3]
                },
                {
                    label: (
                        <Tooltip title="Callsheet">
                            <NwIcon icon={light('address-book')} />
                        </Tooltip>
                    ),
                    key: 'address',
                    children: tabs[4]
                },
                {
                    label: (
                        <Tooltip title="Documents">
                            <NwIcon icon={light('paperclip')} />
                        </Tooltip>
                    ),
                    key: 'attachments',
                    children: tabs[5]
                },
                {
                    label: (
                        <Tooltip title="Comments">
                            <NwIcon icon={light('comment-alt-lines')} />
                        </Tooltip>
                    ),
                    key: 'comments',
                    children: tabs[6]
                },
                {
                    label: (
                        <Tooltip title="Tasks">
                            <NwIcon icon={light('circle-check')} />
                        </Tooltip>
                    ),
                    key: 'all-tasks',
                    children: tabs[7]
                }
            ]}
        />
    );
}

export default ProjectDetailTabs;