export const CUSTOMER_FILTER_SET = {
    activeSet: ("v0"),
    filterType: ("SAVED_SEARCH"),
    customSets: ([]),
    staticSets: [
        {
            id: "v0",
            name: "all customers",
            fixed: true,
            filters: []
        },
        {
            id: "v1",
            name: "customers working today",
            fixed: true,
            filters: [
                { name: "WorkingOn", value: "{today_today}"}
            ]
        }
    ]
};

export const CUSTOMERS_FILTERS_SET = {
    booking: CUSTOMER_FILTER_SET,
    scouting: CUSTOMER_FILTER_SET,
    accounting: CUSTOMER_FILTER_SET,
    art: CUSTOMER_FILTER_SET,
    management: CUSTOMER_FILTER_SET
};