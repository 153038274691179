import React, { useState } from 'react';
import { Switch } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';

import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import TimePicker from 'Components/EventDateTimePicker/TimePicker';
import FlexContainer from 'Components/Gui/FlexContainer';
import { timeArrayToString, timeStringToArray, getTime, isSameDate } from 'Libs/NwMoment';
import { getEndTime } from 'Libs/NwUtils';

const EndDateContainer = styled.div`
    .disabled-end-date {
        background: #d9d9d9;
        opacity: 0.7;
    }
`;

const CheckAllDay = styled.div`
    white-space: nowrap;
`;

const LongEventPeriodPicker = ({ value, singleDay, onChange }) => {
    const { startDate, endDate, startTime, endTime, allDay } = value;
    const [disableEndDate, setDisableEndDate] = useState(true);

    const handleUpdateStartDate = date => {
        onChange({
            ...value,
            startDate: date,
            endDate: date, //newEndDate
            dates: [{
                date: date,
                optionNumber: null
            }]
        });
        if (!disableEndDate) {
            setDisableEndDate(true);
        }
    };

    const handleChangeStartTime = time => {
        const endTimeVal = getEndTime(startTime, endTime, timeStringToArray(time));
        onChange({
            ...value,
            startTime: timeStringToArray(time),
            endTime: [parseInt(endTimeVal / 60), endTimeVal % 60]
        });
    }

    const handleChangeEndTime = time => {
        onChange({
            ...value,
            endTime: timeStringToArray(time)
        });
    }

    const handleClickEndDate = () => {
        if (disableEndDate) {
            setDisableEndDate(false);
        }
    }

    const handleUpdateEndDate = date => {
        let newValue = {
            ...value,
            endDate: date
        };
        if (isSameDate(startDate, date)) {
            let endTimeVal = startTime[0] * 60 + startTime[1] + 120; // endtime = starttime + 2 hours;
            endTimeVal = endTimeVal >= 1440 ? 1439 : endTimeVal;
            newValue.endTime = [parseInt(endTimeVal / 60), endTimeVal % 60];
        }
        onChange(newValue);
    }

    const handleSetAllDay = checked => {
        if (checked) {
            onChange({
                ...value,
                allDay: checked,
                startTime: [0, 0], //getTime(getNow().hour(0).minute(0)),
                endTime: [23,59] //getTime(getNow().hour(23).minute(59)),
            });     
        } else {
            onChange({
                ...value,
                allDay: checked,
                startTime: [9, 0], //getTime(getNow().hour(9).minute(0)),
                endTime: [11, 0] //getTime(getNow().hour(11).minute(0)),
            });
        }
    };

    const disabledEndDate = date => {
        const days = date.diff(startDate, 'days');
        if (days >= 0 && days <= 100) {
            return false;
        }
        return true;
    }

    return (
        <FlexContainer alignItems='center' mg='0 0 8px'>
            <div>
                <div>From</div>
                <FlexContainer>
                    <CustomDatePicker
                        label='Start Date'
                        name='startDate'
                        value={startDate}
                        onChange={handleUpdateStartDate} />
                    <Switch checked={allDay} onChange={handleSetAllDay} />
                    &nbsp;
                    <CheckAllDay>all day</CheckAllDay>
                    &nbsp;
                    {!allDay && 
                        <TimePicker 
                            label='Start Time' 
                            name='startTime'
                            disabled={allDay}
                            value={timeArrayToString(startTime)} 
                            onChange={handleChangeStartTime} />
                    }
                </FlexContainer>
            </div>
            &nbsp;
            <div>
                {(!allDay || !singleDay) &&
                    <div>To</div>
                }
                <FlexContainer>
                    {!allDay && 
                        <TimePicker 
                            label='End Time' 
                            name='endTime'
                            disabled={allDay}
                            beforeTime={isSameDate(startDate, endDate) && timeArrayToString(startTime)}
                            value={timeArrayToString(endTime)} 
                            onChange={handleChangeEndTime} />
                    }
                    {!singleDay && 
                        <EndDateContainer>
                            <div onClick={handleClickEndDate}>
                                <CustomDatePicker
                                    label='End Date'
                                    name='endDate'
                                    className={disableEndDate && 'disabled-end-date'}
                                    disabledRange={disabledEndDate}
                                    value={endDate}
                                    onChange={handleUpdateEndDate} />
                            </div>
                        </EndDateContainer>
                    }
                </FlexContainer>
            </div>
        </FlexContainer>
    )
};


export default LongEventPeriodPicker;