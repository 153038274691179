import React, { useContext, useState } from "react";
import NwDrawer from "Components/Gui/NwDrawer";
import { ListContext } from "Contexts/ListContext";
import TransactionCreateFormContainer from "./TransactionCreateFormContainer";

const TransactionCreateDrawer = ({ transactionFamily, onClose, onTransactionRemoved, ...rest }) => {
    const [touchForm, setTouchForm] = useState(false);
    const { GetFamilyName } = useContext(ListContext);

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={`New ${GetFamilyName(transactionFamily)}`}
        >
            <TransactionCreateFormContainer 
                transactionFamily={transactionFamily} 
                {...rest} 
                onCancel={onClose}
                onTouchForm={setTouchForm}
                onTransactionRemoved={onTransactionRemoved} />
        </NwDrawer>
    );
};

export default TransactionCreateDrawer;
