import React from 'react';
import { NwAddButton } from 'Components/Gui/NwButton';
import { EventActionsToolbar } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { forceReloadTask } from 'Hooks/Event/UseTask';
import NwUpload from "Components/Gui/NwUpload";

const TaskActions = ({ plainMode, taskData, onAddModels }) => {
    const getUploadEndpoint = () => {
        let endpoint = `documents/upload/1?eventGroupGuid=${taskData.GroupGuid}`;
        if (taskData.Project) {
            endpoint = endpoint + `&projectID=${taskData.Project.ID}`
        }
        return endpoint;
    };

    return (
        <EventActionsToolbar plainMode={plainMode}>
            {taskData.GroupGuid &&
                <NwUpload
                    size='small'
                    label='add document'
                    maxSize={20}
                    showList={false}
                    types={["all"]}
                    onUploadEnd={() => {
                        forceReloadTask(taskData.ID);
                    }}
                    endpoint={getUploadEndpoint()}
                />
            }
            <NwAddButton size="small" ghost onClick={onAddModels} label="add models" />
        </EventActionsToolbar>
    )
};

export default TaskActions;