import React, { useState } from "react";
import styled from 'styled-components';
import { Typography, Tooltip, Modal } from 'antd';

const StyledParagraph = styled(Typography.Paragraph)`
    font-size: ${props => props.$noFontSize ? "inherit" : '.95rem'};
    line-height: 1.3em;
`
const StyledTitle = styled(Typography.Title)`
`
const NwEllipsis = ({
    className,
    divStyle,
    expandable,
    noFontSize,
    popover = false,
    popovertitle = "Full Text",
    rows,
    style,
    suffix,
    symbol,
    text = "",
    title,
    ...rest
}) => {

    const [modal, setModal] = useState(false)

    const ell = { rows: rows }
    if (suffix) {
        ell.suffix = suffix
    }

    const Content = () => {

        if (title) {
            if (popover) {
                ell.tooltip = text
            }
            return (<StyledTitle style={style || {}} ellipsis={ell} {...rest}>{text}</StyledTitle>)
        }
        else {
            if (expandable) {
                ell.expandable = true
                ell.symbol = symbol
            } else {
                if (popover) {
                    ell.tooltip = <p className="nw-ellipsis-tooltip">{text}</p>
                }
            }
            return (
                <div {...rest} style={divStyle || {}}>
                    <StyledParagraph $noFontSize={noFontSize} className={className} style={style || {}} ellipsis={ell}>
                        {text}
                    </StyledParagraph>
                </div>
            )
        }
    }

    if (!text) {
        return null
    }

    if (popover && text && text.length > 1500) {
        return (
            <>
                <div style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
                    <Tooltip title="Click to see complete text">
                        <div {...rest} style={divStyle || {}}>
                            <StyledParagraph 
                                $noFontSize={noFontSize} 
                                className={className} 
                                style={style || {}} 
                                ellipsis={ell}
                            >
                                {text}
                            </StyledParagraph>
                        </div>
                    </Tooltip>
                </div>
                <Modal
                    title={popovertitle}
                    open={modal}
                    footer={null}
                    closable
                    onCancel={() => setModal(false)}
                    wrapClassName="nw-modal-ellipsis"
                >
                    <StyledParagraph $noFontSize={noFontSize} className={className}>{text}</StyledParagraph>
                </Modal>
            </>
        )
    } else {
        return Content()
    }
}

export default NwEllipsis;