import React, { useState, useContext, useMemo } from 'react';
import { Tag, Popover } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import MiniLoading from 'Components/Gui/MiniLoading';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwAddButton } from 'Components/Gui/NwButton';
import { ListContext } from 'Contexts/ListContext';
import { sortTags, mapTags } from 'Libs/NwUtils';
import StyleVariables from 'Components/Gui/StyleVariables';
import EmptyState from 'Components/Gui/EmptyState';

const StyledCard = styled(NwCard)`
    width: 100%;
`;

const ObjectTagsCard = ({ objectTags, onAddTag, onRemoveTag, objectType, vertical }) => {
    const [adding, setAdding] = useState(false);
    const [removing, setRemoving] = useState(false);
    const { cachedList } = useContext(ListContext);

    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    const allTags = useMemo(() => sortTags(mapTags(cachedtags, objectType)), [cachedtags])
    const tags = objectTags ? objectTags : [];

    const doRemoveTag = async (tag) => {
        setRemoving(true)
        await onRemoveTag(tag)
        setRemoving(false)
    }

    const doAddTag = async (tag) => {
        setAdding(true)
        await onAddTag(tag)
        setAdding(false)
    }

    const addedTags = allTags.filter(tag => tags.find(item => item.ID === tag.ID));
    const addibleTags = allTags.filter(tag => !tags.find(item => item.ID === tag.ID));

    const AddTagsButton = (props) => (
        <Popover
            title='Tags'
            trigger='click'
            overlayStyle={{ maxWidth: 300, minWidth: 250 }}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            content={adding ?
                <MiniLoading />
                : <>
                    {addibleTags.map(tag => (
                        <Tag key={tag.ID} style={{ cursor: "pointer" }} visible onClick={() => doAddTag(tag)}>+ {tag.Name}</Tag>
                    ))}
                </>
            }>
            <NwAddButton label='add' ghost />
        </Popover>
    )

    return (
        <StyledCard
            title="Tags"
            icon={light("tag")}
            extra={vertical ? <AddTagsButton /> : null}
            horizontal={!vertical}
        >
            {removing
                ?
                <MiniLoading />
                :
                (vertical
                    ?
                    (addedTags.length
                        ?
                        <div>
                            {addedTags.map(tag => (
                                <Tag key={tag.ID} closable color={StyleVariables.PrimaryDark} onClose={() => doRemoveTag(tag)}>{tag.Name}</Tag>
                            ))}
                        </div>
                        :
                        <EmptyState
                            message="No comments"
                        />
                    )
                    :
                    <FlexContainer justifyContent='space-between'>
                        <div>
                            {addedTags.map(tag => (
                                <Tag key={tag.ID} closable color={StyleVariables.PrimaryDark} onClose={() => doRemoveTag(tag)}>{tag.Name}</Tag>
                            ))}
                        </div>
                        <AddTagsButton />
                    </FlexContainer>
                )
            }
        </StyledCard>
    );
};

export default ObjectTagsCard;