import React from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwUpload from "Components/Gui/NwUpload";
import { onUploadLegal } from 'Hooks/Contact/UseContactLegals';
import { getUrlDocument, getFileTypeIcons } from "Libs/NwUtils";
import NwIcon from 'Components/Gui/NwIcon';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';

const LegalUploadContainer = styled.div`
    .upload-list {
        .upload-list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        
        }
    }
    .upload-button-container {
        text-align: left;
        margin: 1rem 0 0 0;
    }
`

const LegalUpload = ({ item }) => {

    const deleteDocument = docId => {
        Axios.delete(`/documents/?ids=${docId}`)
            .then(() => {
                onUploadLegal(item.ContactID)
            })
            .catch(error => {
                console.log("Errore", error)
            })
    }

    const renderFile = doc => {
        return (
            <a href={getUrlDocument(doc)} target="_blank" rel="noopener noreferrer">
                <NwIcon icon={getFileTypeIcons(doc.Extension)} style={{ marginRight: 8 }} />
                <span>{doc.Title}</span>
            </a>
        );
    };

    const downloadDocument = document => {
        window.open(getUrlDocument(document));
    };

    const renderDocumentActions = document => {
        return ([
            <div className="actions-container">
                <NwDropDownMenu
                    size='small'
                    actions={[
                        { name: "", key: "preview", pinned: true, icon: light('cloud-download'), action: () => downloadDocument(document) },
                        { name: "", key: "delete", pinned: true, icon: light("trash-alt"), action: () => deleteDocument(document.ID), type: "delete", confirmationPlacement: "left" }
                    ]}
                />
            </div>
        ])
    }

    return (
        <LegalUploadContainer>
            <div className='upload-list'>
                {item.Documents.length > 0 && (
                    item.Documents.map((documentUpload) => (
                        <div className="upload-list-item" key={documentUpload.ID}>
                            {renderFile(documentUpload)}
                            {renderDocumentActions(documentUpload)}
                        </div>
                    ))
                )}
            </div>
            <div className='upload-button-container'>
                <NwUpload
                    maxSize={20}
                    showList={false}
                    types={
                        [
                            "document",
                            "image",
                            "data-document",
                            "office-document",
                            "archive-document",
                            "text-document"
                        ]
                    }
                    onUploadStart={() => { }}
                    onUploadEnd={() => {
                        onUploadLegal(item.ContactID)
                    }}
                    endpoint={`documents/uploadsingle/1?contactID=${item.ContactID
                        }&legalID=${item.ID}`}
                />
            </div>
        </LegalUploadContainer>
    );
};

export default LegalUpload;