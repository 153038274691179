import React from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
    display: flex;
    align-items: ${props => props.align || 'center'};
    
    .checkbox {
        margin-right: 4px;
    }
`;

const NwCheckbox = ({ value, text, compact, type, onChange, align, ...other }) => {
    return (
        <CheckboxContainer align={align}>
            <Checkbox className='checkbox' checked={value} onChange={e => onChange(e.target.checked)} {...other} />{text}
        </CheckboxContainer>
    )
};

export default NwCheckbox;