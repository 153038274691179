import React, { useContext } from 'react';
import { Row, Col, Badge } from 'antd';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import {
    NWTextAreaCharCounter,
    NWInput,
    NWContactSelector,
    NWSelect
} from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useUpdateEvent } from "Hooks/Event/UseEvent";
import { useUpdateEventCustomFields } from 'Hooks/Event/UseEvent';
import { getCustomFieldInitialValue, getEmbeddedCustomField } from 'Libs/NwCustomFields';
import { toISODate } from 'Libs/NwMoment';

const validateEventRole = (eventRoleId, eventClientId) => {
    if (!eventRoleId && eventClientId) {
        return 'Please select client role!'
    }
    return null;
}

const EventInfoEditForm = ({ event, customFields, onCancel, onUpdate, onTouchForm }) => {
    const { cachedList } = useContext(ListContext);
    const { mutateAsync: onUpdateEvent } = useUpdateEvent();
    const { mutateAsync: onUpdateEventCustomFields } = useUpdateEventCustomFields();
    const subType = cachedList.eventSubtypes.items.find(item => item.Code === event.SubTypeCode);

    const availableCustomFields = customFields ? customFields.filter(field => !field.Disabled) : [];

    const getSubtypes = () => {
        const filteredsubtypes = cachedList.eventSubtypes.items.filter(item => item.Type === event.Type && !item.Disabled)
        return filteredsubtypes.map(type => {
            return {
                label: <div style={{ display: 'flex' }}>
                    <Badge color={`#${type.BackColor}`} style={{marginRight: ".5rem"}} />
                    {type.Name}
                </div>,
                value: type.Code
            };
        })
    }

    let initialValues = {
        Name: '',
        Description: '',
        InternalNote: '',
        EventClientID: null,
        EventClientRoleID: null,
        SubTypeCode: null,
        CustomFields: {}
    };

    availableCustomFields.map(customField => {
        if (!customField.Disabled) {
            initialValues = {
                ...initialValues,
                CustomFields: {
                    ...initialValues.CustomFields,
                    [customField.Name]: getCustomFieldInitialValue(customField.TypeName, event.CustomFields[customField.Name]) 
                }
            };
        }

        return null;
    })

    if (event) {
        initialValues.Name = event.Name;
        initialValues.Description = event.Description ? event.Description : '';
        initialValues.InternalNote = event.InternalNote ? event.InternalNote : '';
        initialValues.EventClientID = event.EventClientID;
        initialValues.EventClientRoleID = event.EventClientRoleID;
        initialValues.SubTypeCode = event.SubTypeCode;
    }

    const saveBookingEvent = async (values, setSubmitting) => {
        const isCustomFieldsTouched = JSON.stringify(initialValues.CustomFields) !== JSON.stringify(values.CustomFields);
        if (JSON.stringify({...initialValues, CustomFields: {}}) !== JSON.stringify({...values, CustomFields: {}})) {
            try {
                const data = [];
                for (const key of Object.keys(values)) {
                    if (key !== 'CustomFields') {
                        data.push({
                            name: key,
                            value: values[key],
                            changed: true,
                            errormessage: '',
                            updated: true
                        })
                    }
                }
                if (event.EventClientID && !values.EventClientID) {
                    data.push({
                        name: 'EventClientID',
                        value: null,
                        changed: true,
                        errormessage: '',
                        updated: true
                    })
                }
                await onUpdateEvent({ ID: event.ID, data: data });
                if (onTouchForm && !isCustomFieldsTouched) {
                    onTouchForm(false);
                }
                if (!isCustomFieldsTouched) {
                    onUpdate();
                }
            } catch (error) {
                console.log('ant : Save Booking Event Error => ', error);
            }
        }

        if (isCustomFieldsTouched) {
            let customFieldsData = {};
            Object.keys(values.CustomFields).map(key => {
                if (values.CustomFields[key] !== '' && JSON.stringify(values.CustomFields[key]) !== JSON.stringify([]) && values.CustomFields[key]) {
                    const customField = availableCustomFields.find(item => item.Name === key);
                    if (customField.TypeName === 'MultipleChoice') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key].join(',')
                        }
                    } else if (customField.TypeName === 'Date') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: toISODate(values.CustomFields[key])
                        }
                    } else {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key]
                        }
                    }
                }
                return null;
            })

            try {
                await onUpdateEventCustomFields({ eventId: event.ID, data: customFieldsData });
                if (onTouchForm) {
                    onTouchForm(false);
                }
                onUpdate();
            } catch (error) {
                console.log('ant : Save Event Custom Fields Error => ', error);
            }
        }
    };

    return (
        <FlexContainer mg='0 0 16px'>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Content is required")
                })}
                onSubmit={(values, { setSubmitting }) => {
                    saveBookingEvent(values, setSubmitting);
                }}
                render={({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                    <NwForm 
                        values={values}
                        noValidate
                        onTouchForm={onTouchForm} 
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWSelect}
                            label="Event Type"
                            name="SubTypeCode"
                            value={values.SubTypeCode}
                            options={getSubtypes(values.Type)}
                        />
                        <Row gutter={10}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWContactSelector}
                                    label={`${subType ? subType.Name : ''} Client`}
                                    name="EventClientID"
                                    activeTypes={['age', 'cli', 'srv']}
                                    activableTypes={[]}
                                    value={values.EventClientID}
                                    onAfterChange={customerId => {
                                        setFieldValue('Name', getContactName(customerId));
                                        if (!customerId) {
                                            setFieldValue('EventClientRoleID', null);
                                        }
                                    }}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWSelect}
                                    label={`${subType ? subType.Name : ''} Client Role`}
                                    name="EventClientRoleID"
                                    disabled={!values.EventClientID}
                                    value={values.EventClientRoleID}
                                    validate={value => validateEventRole(value, values.EventClientID)}
                                    options={
                                        cachedList.eventClientRoles.items.filter(item => !item.Disabled).map(eventClientRole => {
                                            return {
                                                key: eventClientRole.ID,
                                                label: eventClientRole.Name,
                                                value: eventClientRole.ID
                                            };
                                        })}
                                />
                            </Col>
                        </Row>
                        <Field
                            component={NWInput}
                            label="Title"
                            name="Name"
                            type="text"
                            value={values.Name}
                        />
                        <Row gutter={10}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Description"
                                    name="Description"
                                    type="text"
                                    value={values.Description}
                                    maxLength={2000}
                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Internal Notes"
                                    name="InternalNote"
                                    type="text"
                                    value={values.InternalNote}
                                    maxLength={2000}
                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                />
                            </Col>
                        </Row>
                        {availableCustomFields.map((customField, index) => {
                            return <div key={`${customField.Name}-${index}`}>
                                {getEmbeddedCustomField(values, customField)}
                            </div>
                        })}
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton label='Cancel' disabled={isSubmitting} onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }} />
                            }
                            right={
                                <NwSaveButton
                                    label="Save"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    loadingLabel="Updating..."
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </FlexContainer>
    )
};

export default EventInfoEditForm;