import React, { useState, useMemo, useEffect } from 'react';
import { Popover, Tag } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton, NwButtonGroup } from 'Components/Gui/NwButton';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwDrawer from 'Components/Gui/NwDrawer';

const StyledTag = styled(Tag)`
    margin-right: 0 !important;
`;

const TagsContainer = styled.div`
    .ant-tag {
        margin: .25rem;
        cursor: pointer;
    }
`

const FilterTagSelector = ({ tags, selectedOptions = [], onChange }) => {
    const [selectedTags, setTags] = useState([]);
    const [showTagsDrawer, setShowTagsDrawer] = useState(false);

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setTags(selectedOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTag = tag => () => {
        let updatedTags = [...selectedTags];
        if (selectedTags.find(item => item === tag)) {
            updatedTags = selectedTags.filter(item => item !== tag);
        } else {
            updatedTags = [...updatedTags, tag];
        }

        setTags(updatedTags);
        onChange('tagFilter', updatedTags);
    };

    const handleCloseTags = event => {
        event.stopPropagation();
        onChange('tagFilter', []);
        setTags([]);
    };

    const displayLabel = useMemo(() => {
        if (selectedTags.length === 0) return 'Filter by tag';
        else {
            return (
                <StyledTag
                    visible
                    color={StyleVariables.PrimaryColor}
                    onClose={handleCloseTags}>
                    {selectedTags.length === 1 ? selectedTags[0] : `${selectedTags.length} Tags`}
                </StyledTag>
            )
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTags]);

    return (
        <div>
            <NwButtonGroup>
                <NwButton icon={light('tag')} onClick={() => { setShowTagsDrawer(true) }} label={displayLabel} />
                <NwButton icon={light("times")} onClick={handleCloseTags} tooltip="Clear tag filter" />
            </NwButtonGroup>
            {showTagsDrawer &&
                <NwDrawer
                    title="Filter By Tags"
                    onClose={() => { setShowTagsDrawer(false) }}
                >
                    <TagsContainer>
                        {tags.length === 0 && 'No tags used'}
                        {tags.map(tag => {
                            if (selectedTags.find(item => item === tag)) {
                                return <Tag key={tag} closable color={StyleVariables.PrimaryColor} onClose={handleChangeTag(tag)}>{tag}</Tag>;
                            } else {
                                return <Tag key={tag} onClick={handleChangeTag(tag)}>+ {tag}</Tag>;
                            }
                        })}
                    </TagsContainer>
                </NwDrawer>
            }
        </div>
    );
};

export default FilterTagSelector;