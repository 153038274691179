import React, { useState } from 'react'
import { DatePicker, Segmented } from 'antd';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButtonGroup, NwButton } from "Components/Gui/NwButton";
import { isCurrentPeriod } from 'Components/Schedule/ScheduleUtils';
import moment from 'moment';

const CalendarHeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    background: white;
    z-index: 4;
    width: 100%;
    padding-left: 1rem;

    position: sticky;
    top: 7rem;
    background-color: white;
    z-index: 1001;

    div.calendar-header-left {
        min-height: 2rem;
        padding: .75rem 0 1rem 0;

        .calendar-header-left-dates {
            width: 19rem;
            h2 {
                margin: 0;
                font-size: 1.25rem;
                padding: 0;
                font-weight: bold;
                margin: 0;
                line-height: 1.15em;
            }
        }
    }
    
    div.calendar-header-right {
        display: flex;
        align-items: center;
        justify-content:flex-end;
        padding: 1rem 0;
        flex-wrap: wrap;
        font-size: 1rem;
        
        div.current-period-button-container {
            width:10rem;
        }

        div.date-navigator-container {
            width: 8rem;
            display: flex;
            align-items: center;
            
            .ant-calendar-picker-input.ant-input {
                display: none!important;
            }
            .anticon.anticon-calendar.ant-calendar-picker-icon{
                margin-top: -13px;
            }
            .anticon.anticon-close-circle.ant-calendar-picker-clear{
                display: none;
            }
        }

        div.calendar-type-selector-container {
            width: 15rem;
        }
    }
`;

const StyledDatePicker = styled(DatePicker)`
    width: 0;
    opacity: 0;
    border: none;
    box-shadow: none; 
    padding: 0;
    margin-left: -80px !important;
`;

const ModelCalendarHeader = ({
    calendarStyle,
    onSwitchToAgenda,
    previewMode,
    toolbar,
}) => {
    

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const goToCurrent = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('date', new Date());
    };

    const handlePrev = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('PREV');
    };

    const handleNext = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('NEXT');
    }

    const showDatePicker = () => {
        if (!isDatePickerOpen) {
            setIsDatePickerOpen(true)
        } else {
            setIsDatePickerOpen(false)
        }
    }

    const RangePickerChange = (event) => {
        if (event && moment.isMoment(event)) {
            toolbar.onNavigate('date', event.toDate());
        }
        setIsDatePickerOpen(false);
    };

    const CalendarHeaderDates = ({ }) => {
        return <h2>{toolbar.label}</h2>
    }

    return (
        <CalendarHeaderContainer>
            <div className="calendar-header-left">
                <div className="calendar-header-left-dates">
                    <CalendarHeaderDates />
                </div>
            </div>
            <div className="calendar-header-right">
                <div className="current-period-button-container">
                    {!isCurrentPeriod(toolbar.date, toolbar.view) ? <NwButton ghost icon={light('arrow-to-right')} size='small' label={`current ${toolbar.view}`} onClick={goToCurrent} /> : null}
                </div>
                <div className="date-navigator-container">
                    <NwButtonGroup
                        size="small"
                        buttons={[
                            { action: handlePrev, icon: light("chevron-left") },
                            { action: showDatePicker, icon: light("calendar-alt") },
                            { action: handleNext, icon: light("chevron-right") },
                        ]}
                    />
                    <StyledDatePicker
                        autoComplete='off'
                        defaultValue={getMoment(toolbar.date)}
                        open={isDatePickerOpen}
                        size='small'
                        picker={toolbar.view}
                        onChange={RangePickerChange} />
                </div>
                {previewMode ? <div />
                    :
                    <div className="calendar-type-selector-container">
                        <Segmented
                            value={toolbar.view}
                            options={[
                                {
                                    label: "Agenda",
                                    value: 'agenda'
                                },
                                {
                                    label: "Week",
                                    value: 'week'
                                },
                                {
                                    label: "Month",
                                    value: 'month'
                                }
                            ]}
                            onChange={view => {
                                if (view !== "agenda") {
                                    setIsDatePickerOpen(false)
                                    toolbar.onView(view)
                                } else {
                                    onSwitchToAgenda()
                                }
                            }}
                        />
                    </div>
                }
            </div>
        </CalendarHeaderContainer>
    );
};

export default ModelCalendarHeader
