import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components';
import { light, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Loading } from 'Components/Gui/Loading';
import Comments from "Components/Comments/Comments";
import MediaDetails from './MediaDetails';
import MediaPreview from 'Components/MediaDrawer/MediaPreview';
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import FlexContainer from 'Components/Gui/FlexContainer';
import ModelCoverGenerator from 'Components/ImageCroppers/ModelCoverGenerator';
import MediaRatings from 'Components/MediaDrawer/MediaRatings';
import ObjectTagsCard from 'Components/ObjectTagsCard/ObjectTagsCard';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { useDeleteMediaList, onContactMediaUpdate } from 'Hooks/Contact/UseContactMedia';
import { NwButton } from 'Components/Gui/NwButton';
//import EditImage from 'Components/MediaDrawer/EditImage';
import MediaBooks from 'Components/MediaDrawer/MediaBooks';
import Axios from 'axios';
import EditImageNew from './EditImageNew';

const CardContainer = styled.div`
    margin-top: 1rem;
`;

const Actions = styled(FlexContainer)`
    svg {
        margin-left: 8px;
    }
`;

const Link = styled.div`
    cursor: pointer;
`;

const RateableMedia = ["Photo","Video"]

const MediaDrawer = ({
    mediaID,
    contactID,
    isShowComment,
    isEditDetail,
    afterMediaUpdate,
    onSelectMediaBefore,
    onSelectMediaAfter,
    onCloseDrawer,
    afterNewMediaCreation,
    viewOnly = false
}) => {

    const [workingOn, setWorkingOn] = useState(null);
    const [workingMessage, setWorkingMessage] = useState(null);
    const [document, setDocument] = useState(null);
    const [moveComment, setMoveComment] = useState(false);
    const [moveDetail, setMoveDetail] = useState(false);
    const commentsRef = useRef();
    const detailRef = useRef();
    const ratingsRef = useRef();
    const tagsRef = useRef();
    const { mutateAsync: onDeleteMediaList } = useDeleteMediaList();
    const [touchForm, setTouchForm] = useState(false);
    const [showCropDocument, setShowCropDocument] = useState(false);
    const [editDocumentImage, setEditDocumentImage] = useState(false);


    const getMedia = async () => {
        const documentData = await Axios.get(`/documents/${mediaID}`)
        setDocument(documentData);
    }

    useEffect(() => {
        if (mediaID) {
            getMedia()
        }
    }, [mediaID])


    const handleImageCreated = (newMediaID) => {
        setEditDocumentImage(false)
        afterNewMediaCreation()
    }

    useEffect(() => {
        if (isShowComment) {
            setMoveComment(true)
        }
        if (isEditDetail) {
            setMoveDetail(true)
        }
    }, [])

    useEffect(() => {
        if (moveComment && commentsRef.current && document) {
            handleShowComments()
            setMoveComment(false)
        }
    }, [moveComment, commentsRef.current, document])

    useEffect(() => {
        if (moveDetail && detailRef.current && document) {
            handleShowInfo()
            setMoveDetail(false)
        }
    }, [moveDetail, detailRef.current, document])

    const confirmDelete = () => {
        deleteMedia()
    }

    const afterChanges = async () => {
        if (contactID) {
            onContactMediaUpdate(contactID)
        }
        if (afterMediaUpdate) {
            afterMediaUpdate()
        }
    }

    const deleteMedia = async () => {
        setWorkingOn(mediaID)
        setWorkingMessage("Deleting Media...")
        await onDeleteMediaList({ documentIDs: [mediaID], contactID: contactID })
        await afterChanges()
        onCloseDrawer()
    }

    const handleSaveChanges = () => {
        afterChanges()
    }

    const handleDocumentUpdate = async (updateType, item) => {
        let comments = [...document.Comments]
        if (updateType === 'delete') {
            if (item) {
                comments = document.Comments.filter(comment => comment.ID !== item)
            }
        } else if (updateType === 'save') {
            const commentIndex = document.Comments.findIndex(comment => comment.ID === item.ID)
            if (commentIndex > -1) {
                comments[commentIndex] = item
            } else {
                comments.push(item)
            }
        }
        getMedia()
        await afterChanges()
    }

    const handleAddTag = async tag => {
        try {
            await Axios.post(`/documents/addtagmulti?tagID=${parseInt(tag.ID)}&contactID=${contactID}`, [document.ID])
            getMedia()
            await afterChanges()
        } catch (error) {
            console.log("errore", error)
        }
    }

    const handleRemoveTag = async tag => {
        try {
            await Axios.post(`/documents/removetagmulti?tagID=${tag.ID}&contactID=${contactID}`, [document.ID])
            getMedia()
            await afterChanges()
        } catch (error) {
            console.log("errore", error)
        }
    }

    const handleMediaUpdate = async () => {
        getMedia()
        await afterChanges()
    }

    const handleShowComments = () => {
        commentsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }

    const handleShowInfo = () => {
        detailRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }

    const handleShowCropDocumentImage = () => {
        setShowCropDocument(true)
    }

    const handleCloseCropDocumentImage = () => {
        setShowCropDocument(false)
    }

    const handleEditDocumentImage = () => {
        setEditDocumentImage(true)
    }

    const handleCloseEditDocumentImage = () => {
        setEditDocumentImage(false)
    }

    const handleViewWebVideo = () => {
        if (document) {
            window.open(document.Standard.Url, '_blank')
        }
    };

    return (
        <NwDrawer
            width={window.innerWidth > 876 ? "70vw" : "100%"}
            title={
                <FlexContainer justifyContent='space-between'>
                    <div>Media Details</div>
                    <Actions>
                        {document && document.Type === 'Photo' &&
                            <NwButton ghost size="small" label="use as profile picture" icon={light("user-circle")} onClick={handleShowCropDocumentImage} />}
                        {document && document.Type === 'Photo' &&
                            <NwButton icon={light('pen')} ghost size="small" label="edit" onClick={handleEditDocumentImage} />}
                    </Actions>
                </FlexContainer>
            }
            onClose={() => onCloseDrawer(document)}
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            whiteBg={false}
        >
            {editDocumentImage ?
                <EditImageNew 
                    contactID={contactID}
                    image={document}
                    onImageCreated={handleImageCreated}
                    onCancel={handleCloseEditDocumentImage}
                />
            :
                <div className='drawer-content preview'>
                    {workingOn || !document ?
                        <div>
                            <Loading />
                            <p>{workingMessage}</p>
                        </div>
                        : document &&
                        <>
                                <div id="drawerContentContainer">
                                    <div>
                                        <MediaPreview
                                            document={document}
                                            onSelectMediaBefore={onSelectMediaBefore}
                                            onSelectMediaAfter={onSelectMediaAfter} />                                    
                                        {(document.Provider === 'Youtube') &&
                                            <NwCard title={document.Provider} icon={brands("youtube")} horizontal>
                                                <Link onClick={handleViewWebVideo}>{document.Standard.Url}</Link>
                                            </NwCard>
                                        }
                                        {(document.Provider === 'Vimeo') &&
                                            <NwCard title={document.Provider} icon={brands("vimeo")} horizontal>
                                                <Link onClick={handleViewWebVideo}>{document.Standard.Url}</Link>
                                            </NwCard>
                                        }
                                        {document && RateableMedia.includes(document.Type) &&
                                            <CardContainer fullWidth ref={ratingsRef}>
                                                <MediaRatings objectType={'Document'} objectId={document.ID} onMediaUpdate={handleMediaUpdate} />
                                            </CardContainer>
                                        }
                                        <CardContainer fullWidth ref={tagsRef}>
                                            <ObjectTagsCard objectType={'Document'} objectTags={document.Tags} onAddTag={handleAddTag} onRemoveTag={handleRemoveTag}  />
                                        </CardContainer>
                                        <div ref={detailRef}>
                                            <MediaDetails
                                                onSaveChanges={handleSaveChanges}
                                                document={document}
                                                viewOnly={viewOnly}
                                                contactID={contactID}
                                                isEditDetail={isEditDetail}
                                                onTouchForm={setTouchForm}
                                                confirmDelete={confirmDelete} />
                                        </div>
                                        {(document && (document.Type === "Video" || document.Type === "Photo")) &&
                                            <MediaBooks
                                                documentID={document.ID}
                                                contactID={contactID}
                                            />
                                        }
                                        {document &&
                                            <CardContainer ref={commentsRef}>
                                                <Comments
                                                    api='DocumentID'
                                                    comments={document.Comments}
                                                    ID={document.ID}
                                                    disableVisibility
                                                    drawerMode
                                                    onTouchForm={setTouchForm}
                                                    onUpdate={handleDocumentUpdate}
                                                    isPaginated={false}
                                                />
                                            </CardContainer>
                                        }
                                    </div>
                                </div>
                        </>
                    }
                    {showCropDocument && 
                        <ModelCoverGenerator 
                            contactID={contactID}
                            imageUrl={document.Standard.Url}
                            onClose={handleCloseCropDocumentImage} />
                    }
                    {/* {editDocumentImage &&
                        <NwDrawer
                            width={"80vw"}
                            title="Edit Image"
                            padding="0"
                            onClose={handleCloseEditDocumentImage}>
                            <EditImage
                                contactID={contactID}
                                image={document}
                                onImageCreated={handleImageCreated}/>
                        </NwDrawer>
                    } */}
                </div>
            }
        </NwDrawer>
    )
}

export default MediaDrawer;
