import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const Container = styled.div`
    margin: 0 .5em 0 0;
`;

const CustomDatePicker = ({ label, name, value, defaultDate, onChange, disabledDate, disabledRange, ...rest }) => {
    const handleChange = (date, dateString) => {
        onChange(date);
    }

    const disableDate = current => {
        // Can not select days before today and today
        if (disabledDate && current) {
            if (name === 'startDate' && disabledDate < current) {
                return true;
            }
            else if (name === 'endDate' && disabledDate > current) {
                return true;
            }
        }
        return false;
    }

    return (
        <Container>
            <DatePicker
                autoComplete='off'
                placeholder={label}
                value={value}
                defaultValue={value || defaultDate}
                onChange={handleChange}
                suffixIcon={<NwIcon icon={light("calendar")} />}
                disabledDate={disabledRange ? disabledRange : disableDate}
                allowClear={false}
                format="ddd D MMM YY"
                getPopupContainer={triggerNode => triggerNode.parentNode}
                getCalendarContainer={triggerNode => triggerNode.parentNode}
                {...rest}
            />
        </Container>
    )
};

export default CustomDatePicker;