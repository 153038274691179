import React, { useState, useEffect, useContext } from 'react';
import { useEvent } from 'Hooks/Event/UseEvent';
import EventDrawerDetailsContainer from 'Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer';
import { EventContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import AddBookingEventForm from 'Components/EventDrawer/BookingEvent/AddBookingEventForm';
import BookingEventDetails from 'Components/EventDrawer/BookingEvent/BookingEventDetails/BookingEventDetails';
import { Loading } from 'Components/Gui/Loading';
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';
import { NwButton } from 'Components/Gui/NwButton';

const viewModeTypes = {
    create: "create",
    edit: "edit",
}

const BookingEvent = ({
    afterRemove,
    afterUpdate,
    allDay,
    dateSelected,
    eventInfo,
    filterModel,
    ID,
    onClose,
    onSelectEvent,
    onTouchForm,
    plainMode,
    projectId,
    touchForm,
    viewMode,
}) => {
    const [eventID, setEventID] = useState(ID);
    const [viewType, setViewType] = useState(viewMode);
    const { data: eventData, isFetching } = useEvent(eventID);
    const { currentUser } = useContext(UserContext);
    const { cachedList } = useContext(ListContext);
    useEffect(() => {
        setEventID(ID);
    }, [ID]);

    if (viewType === viewModeTypes.create) {
        return (
            <EventContainer noProjectHeader>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">Add Booking Event</div>
                </div>
                <div className="event-content-wrapper">
                    <AddBookingEventForm
                        projectId={projectId}
                        eventInfo={eventInfo}
                        modelId={filterModel}
                        allDay={allDay}
                        touchForm={touchForm}
                        onTouchForm={onTouchForm}
                        dateSelected={dateSelected}
                        onCreateBookingEvent={eventId => {
                            setEventID(eventId);
                            setViewType(undefined)
                        }}
                        onCancel={() => {
                            setViewType(undefined)
                            onClose();
                        }}
                        afterUpdate={afterUpdate} />
                </div>
            </EventContainer>
        )
    }

    if (!eventData) {
        return <Loading textBlack />;
    }

    if (eventData.NetworkAgencyCreated !== currentUser.ActiveContext.nwAgId) {
        let networkAgencies = [];
        for (const accountingItem of cachedList.accountingGroups.items) {
            networkAgencies = [...networkAgencies, ...accountingItem.NetworkAgencies];
        }
        const networkAgency = networkAgencies.find(agency => agency.ID === eventData.NetworkAgencyCreated);
        return (
            <div style={{padding: '6rem 2rem', textAlign: 'center'}}>
                <h2>Please switch to the correct network agency ({networkAgency.Name}) to view this event</h2>
                <p><a href="/booking/">Go back to booking chart</a></p>
            </div>
        );
    }

    return (
        plainMode ?
            <BookingEventDetails
                plainMode
                eventData={eventData}
                filterModel={filterModel}
                dateSelected={dateSelected}
                afterUpdate={afterUpdate}
                projectId={projectId}
                afterRemove={afterRemove}
                onSelectEvent={onSelectEvent}
            />
            :
            <EventDrawerDetailsContainer
                eventData={{ ...eventData, elType: 'Event', uniqID: `Event-${eventData.ID}` }}
                filterModel={filterModel}
                isFetching={isFetching}
                typeName="event"
                firstTab={
                    <BookingEventDetails
                        eventData={eventData}
                        filterModel={filterModel}
                        dateSelected={dateSelected}
                        afterUpdate={afterUpdate}
                        projectId={projectId}
                        afterRemove={afterRemove}
                        onSelectEvent={onSelectEvent}
                    />
                }
            />
    )
}

export default BookingEvent;