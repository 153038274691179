import React from "react";
import PackagePlayListView from 'Components/PackageDrawer/PackagePlayListView';
import NwDrawer from "Components/Gui/NwDrawer";
import { usePackagePlaylist } from "Hooks/Packages/UsePackagePlaylist";

const PackagePlayListDrawer = ({ packageplaylistid, onClose }) => {
  const { data: playlistData } = usePackagePlaylist(packageplaylistid)
  return (
    <NwDrawer
      width={'90%'}
      modalDrawer
      onClose={onClose}
      title={playlistData ? playlistData.Name : "Loading playlist..."}
      >
      {playlistData &&
          <PackagePlayListView
            packagePlaylist={playlistData}
          />
      }
    </NwDrawer>
  )
};

export default PackagePlayListDrawer;
