import React, { useContext, useState } from 'react';
import { List, Dropdown, Modal } from 'antd';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';
import { CURRENCIES } from "Configs/CURRENCIES";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import Axios from 'axios';
import { ListContext } from 'Contexts/ListContext';
import TransactionDetailDrawer from 'Components/Transactions/TransactionsDrawer/TransactionDetailDrawer';
import EventTransactionsListTable from './EventTransactionsListTable';
import TransactionCreateDrawer from 'Components/Transactions/TransactionsDrawer/TransactionCreateDrawer';

const Title = styled.div`
    font-weight: bold;
`;

const Description = styled.div`
    font-size: .65rem;
`;

export const AddTransactionButton = ({ type, ghost, onCreateTransaction }) => {
    switch (type.toLowerCase()) {
        case 'travel':
        case 'followup':
        case 'accommodation':
            return (
                <Dropdown
                    menu={{
                        onClick: onCreateTransaction,
                        items: [
                            { key: "JobExpense", label: "Job Expense" },
                            { key: "JobRefund", label: "Job Refund" },
                        ]
                    }}
                >
                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <NwButton
                            icon={ghost ? solid('money-bill-alt') : light('money-bill-alt')}
                            ghost={ghost}
                            primary={!ghost}
                            size='small'
                            label="Add Transaction" />
                    </div>
                </Dropdown>
            );
        case 'event':
            return (
                <Dropdown
                    menu={{
                        onClick: onCreateTransaction,
                        items: [
                            { key: "JobExpense", label: "Job Expense" },
                            { key: "JobRefund", label: "Job Refund" },
                            { key: "JobFee", label: "Job Fee" },
                            { key: "Product", label: "Product" },
                        ]
                    }}
                >
                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <NwButton
                            icon={ghost ? solid('money-bill-alt') : light('money-bill-alt')}
                            ghost={ghost}
                            primary={!ghost}
                            size='small'
                            label="Add Transaction" />
                    </div>
                </Dropdown>
            );
        default:
            return <div />;
    }
}

const EventTransactionsList = ({ event, projectId, readOnly, onTransactionOpen, onTransactionCreatedOrUpdated }) => {
    const [transactionFamily, setTransactionFamily] = useState();
    const [showCreateTransactionDrawer, setShowCreateTransactionDrawer] = useState(false);
    const { GetFamilyName } = useContext(ListContext);
    
    const handleShowCreateTransactionDrawer = ({ key }) => {
        setTransactionFamily(key)
        setShowCreateTransactionDrawer(true)
    };

    const handleCloseCreateTransactionDrawer = () => {
        setTransactionFamily()
        setShowCreateTransactionDrawer(false)
    };
    
    let displayedTransactions = event.Transactions ? event.Transactions.map(transaction => (
        {
            ...transaction,
            key: transaction.ID
        }
    )) : [];

    return (
        (projectId
            ?
            <NwCard
                title='Event Transactions'
                extra={projectId && <AddTransactionButton type={event.elType} onCreateTransaction={handleShowCreateTransactionDrawer} />}>
                <List
                    itemLayout="horizontal"
                    dataSource={displayedTransactions}
                    renderItem={transaction => {
                        const currency = CURRENCIES.find(item => item.code === transaction.Currency);
                        return (
                            <List.Item className="ant-list-item-top" onClick={() => onTransactionOpen(transaction)}>
                                <FlexContainer column alignItems='flex-start' fullWidth>
                                    <div>{getContactName(transaction.ClientID)}</div>
                                    <Description>{`${GetFamilyName(transaction.Family)}/${transaction.Label}`}</Description>
                                    <FlexContainer justifyContent='space-between' fullWidth>
                                        <Title>{transaction.Title}</Title>
                                        <div>{`${currency ? currency.symbol : ''}${transaction.SingleFee} `}</div>
                                    </FlexContainer>
                                </FlexContainer>
                            </List.Item>
                        )
                    }
                    }
                />
                {showCreateTransactionDrawer &&
                    <TransactionCreateDrawer
                        event={event}
                        modelSelectionEnabled
                        projectId={projectId}
                        eventType={event.elType}
                        transactionFamily={transactionFamily}
                        onClose={handleCloseCreateTransactionDrawer}
                        onTransactionCreatedOrUpdated={onTransactionCreatedOrUpdated}
                    />
                }
            </NwCard>
            :
            (displayedTransactions && displayedTransactions.length !== 0 &&
                <>
                    <EventTransactionsListTable
                        transactions={displayedTransactions} 
                        isExpanded={true} 
                        onTransactionDeletedOrUpdated={onTransactionCreatedOrUpdated}
                    />
                </>
            )
        )
    )
};

export default EventTransactionsList;