import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const onGetTransactionByID = (ID, family) => (
    Axios.get(`/transactions/${family}/${ID}`)
)

const useTransaction = (ID, family) => {
    return useQuery(["transaction", ID], () => onGetTransactionByID(ID, family), {
        staleTime: 1000,
        cacheTime: 1000,
        retry: false,
        enabled: ID ? true : false
    });
};

const onAddTransaction = ({ data }) => (
    Axios.post(`/transactions`, data)
);

const useAddTransaction = () => {
    return useMutation(onAddTransaction, {
        onSuccess: (data, variables) => {
            forceReloadTransaction(data.ID);
        }
    })
};

const forceReloadTransaction = (ID) => {
    if (ID) {
        queryClient.invalidateQueries(['transaction', ID])
    } else {
        queryClient.invalidateQueries(['transaction'])
    }
}

export {
    useTransaction,
    useAddTransaction,
    forceReloadTransaction
};
