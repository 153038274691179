import React, { useState, useMemo, useEffect } from 'react';
import { Checkbox } from 'antd';
import { useProject } from 'Hooks/Project/UseProject';
import { Loading } from 'Components/Gui/Loading';
import Switch from 'Components/Gui/CustomSwitch';
import { showDateNoTime } from 'Libs/NwMoment';
import FlexContainer from 'Components/Gui/FlexContainer';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import { NwButton } from 'Components/Gui/NwButton';
import useConfigurations from 'Hooks/UseConfigurations';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import NwDrawer from 'Components/Gui/NwDrawer';
import CustomSelect from 'Components/Gui/NWForm/CustomSelect';
import { ContractCreationDrawerWrapper } from './ContractCreationDrawerUI';
import { getModelConfirmationProperties, getTransactionConfirmationProperties } from '../ProjectUtils';
import ErrorMessage from 'Components/Gui/ErrorMessage';
import ContractCreationDrawerTransactionItem from './ContractCreationDrawerTransactionItem';
import Axios from 'axios';
import PdfPrintDrawer from 'Components/PdfPrintDrawer/PdfPrintDrawer';
import NwDivider from 'Components/Gui/NwDivider';
import ButtonTemplateSelector from 'Components/TemplateSelector/ButtonTemplateSelector';

const ContractCreationDrawer = ({ projectId, onClose }) => {
    const { data: currentProjectAccountingDetails, isFetching: isFetchingProjectAccountingDetails } = useProjectAccountingDetails(projectId);
    const { data: currentProject, isFetching: isFetchingProject } = useProject(projectId);

    //const [template, setTemplate] = useState(null);

    const configuration = useConfigurations();

    const [usages, setUsages] = useState(currentProject.Usages.map(usage => ({
        ...usage,
        active: true
    })));
    const [billingInfoList, setBillingInfoList] = useState([]);
    const [otherFees, setOtherFees] = useState([]);
    const [modelsFees, setModelsFees] = useState([]);
    const [hasTransactions, setHasTransactions] = useState(false);
    const [selectedBillingInfoId, setSelectedBillingInfoId] = useState(null);
    const [docVersion, setDocVersion] = useState(null);



    useEffect(() => {
        let otherFeesList = [];
        let modelsFeesList = [];

        if (currentProject && currentProjectAccountingDetails && currentProjectAccountingDetails.Transactions) {
            // Check if there are transactions and set the flag
            setHasTransactions(currentProjectAccountingDetails.Transactions.length > 0)
            for (const transaction of currentProjectAccountingDetails.Transactions) {
                if (transaction.ClientBillingInfoID === selectedBillingInfoId) {
                    if (!transaction.ModelID) {
                        const hydratedTransaction = {
                            ...transaction,
                            key: transaction.ID,
                            active: true
                        }
                        otherFeesList.push(hydratedTransaction);
                    } else {
                        const modelFeesList = modelsFeesList.find(mf => mf.ModelID === transaction.ModelID)
                        const hydratedTransaction = {
                            ...transaction,
                            key: transaction.ID,
                            ConfirmationProperties: getTransactionConfirmationProperties(currentProject, transaction)
                        }
                        if (modelFeesList) {
                            modelFeesList.transactions.push({
                                ...hydratedTransaction,
                                active: true
                            })
                        } else {
                            modelsFeesList.push({
                                ModelID: transaction.ModelID,
                                ModelConfirmationProperties: getModelConfirmationProperties(currentProject, transaction.ModelID),
                                transactions: [{
                                    ...hydratedTransaction,
                                    active: true
                                }],
                                active: true
                            })
                        }
                    }
                }
            }
        }
        setModelsFees([...modelsFeesList]);
        setOtherFees([...otherFeesList]);
    }, [currentProjectAccountingDetails, currentProject, selectedBillingInfoId]);

    const jobEventList = useMemo(() => {
        return currentProject.Events.filter(event => event.TypeName === 'Job').map(event => ({
            ...event,
            active: true,
            EventDates: event.EventDates.map(eventDate => ({
                ...eventDate,
                active: true
            }))
        }));
    }, [currentProject]);

    const [jobEvents, setJobEvents] = useState(jobEventList);

    useEffect(() => {
        if (currentProjectAccountingDetails && currentProjectAccountingDetails.Transactions) {
            let _billingInfoList = [];
            if (currentProjectAccountingDetails && currentProjectAccountingDetails.Transactions) {
                for (const transaction of currentProjectAccountingDetails.Transactions) {
                    if (transaction.ClientBillingInfo) {
                        if (!_billingInfoList.find(item => item.ID === transaction.ClientBillingInfo.ID)) {
                            _billingInfoList.push(transaction.ClientBillingInfo);
                        }
                    }
                }
            }
            setBillingInfoList(_billingInfoList);
            if (_billingInfoList.length) {
                setSelectedBillingInfoId(_billingInfoList[0].ID);
            }
        }
    }, [currentProjectAccountingDetails]);

    useEffect(() => {
        console.log("models fees", modelsFees);
    }, [modelsFees]);

    const handleUpdateUsage = usageIndex => active => {
        const newUsages = [...usages];
        newUsages[usageIndex].active = active.target.checked;
        setUsages(newUsages);
    }

    const handleUpdateModelFees = (selectedFees, modelID) => {
        let newModelsFees = [...modelsFees];
        const modelIndex = newModelsFees.findIndex(modelFees => modelFees.ModelID === modelID);
        for (const t of newModelsFees[modelIndex].transactions) {
            if (selectedFees.includes(t.ID)) {
                t.active = true;
            } else {
                t.active = false;
            }
        }
        setModelsFees(newModelsFees);
    };

    const handleUpdateOtherFees = (selectedFees) => {
        let newOtherFees = [...otherFees];
        for (const t of newOtherFees) {
            if (selectedFees.includes(t.ID)) {
                t.active = true;
            } else {
                t.active = false;
            }
        }
        setOtherFees(newOtherFees);
    };

    const handleChangeModel = modelId => active => {
        let newModelsFees = [...modelsFees];
        const modelIndex = newModelsFees.findIndex(modelFees => modelFees.ModelID === modelId);
        newModelsFees[modelIndex].active = active.target.checked;
        setModelsFees(newModelsFees);
    };

    const handleChangeEvent = eventIndex => active => {
        const newJobEvents = [...jobEvents];
        newJobEvents[eventIndex].active = active.target.checked;
        setJobEvents(newJobEvents);
    };

    const handleChangeEventDate = (eventIndex, dateIndex) => active => {
        const newJobEvents = [...jobEvents];
        newJobEvents[eventIndex].EventDates[dateIndex].active = active.target.checked;
        setJobEvents(newJobEvents);
    };

    const handleGenerateContract = async (templateid) => {
        //console.log("templateid in contract creation drawer", templateid);
        const modelIds = modelsFees.filter(modelFees => modelFees.active).map(modelFees => modelFees.ModelID);
        const otherFeeIds = otherFees.filter(fee => fee.active && fee.ClientBillingInfoID === selectedBillingInfoId).map(fee => fee.ID);
        let modelFeeIds = []
        for (const modelFees of modelsFees) {
            if (modelFees.active) {
                modelFeeIds = [
                    ...modelFeeIds,
                    ...modelFees.transactions.filter(
                        (transaction) => transaction.active && transaction.ClientBillingInfoID === selectedBillingInfoId
                    )
                        .map(transaction => transaction.ID)]
            }
        }
        let eventDateIds = []
        for (const event of jobEvents) {
            if (event.active) {
                eventDateIds = [
                    ...eventDateIds,
                    ...event.EventDates.filter(
                        eventDate => {
                            if (eventDate.active && eventDate.EventDatesModels.find(model => model.OptionNumber === 10)) {
                                //removed this condition: only if there's a fee for the model of current date
                                //&& modelsFees.find(modelFee => modelFee.ModelID === model.ModelID && modelFee.active) {
                                return true
                            }
                            return false;
                        }
                    )
                        .map(eventDate => eventDate.ID)
                ]
            }
        }
        const data = {
            Template: templateid,
            Area: "ProjectContract",
            OutputFileName: "Contract",
            BillingClientInfoID: selectedBillingInfoId,
            ProjectID: projectId,
            ModelIDs: modelIds,
            TransactionIDs: [...modelFeeIds, ...otherFeeIds],
            EventDateIDs: eventDateIds,
            UsagesIDs: usages.filter(usage => usage.active).map(usage => usage.ID)
        }
        const contractResponse = await Axios.post('/projects/printcontract', data)
        setDocVersion(contractResponse)
    };

    const getUsageTypes = (usage) => {
        if (usage.UsageTypes && usage.UsageTypes.length > 0) {
            return usage.UsageTypes.join(', ');
        }
        return "General";
    }

    const getUsageAreas = (usage) => {
        if (usage.UsageTypes && usage.Areas.length > 0) {
            return usage.Areas.join(', ');
        }
        return "All Areas";
    }

    // const handleSelectedTemplate = (template) => {
    //     setTemplate(template.id)
    // }

    return (
        <>
            <NwDrawer
                width='70vw'
                title='Add New Contract'
                onClose={onClose}
            >
                {(isFetchingProject || isFetchingProjectAccountingDetails)
                    ?
                    <Loading textBlack />
                    :
                    (!hasTransactions
                        ?
                        <ErrorMessage text="you can't create a contract with no fees" />
                        :
                        <ContractCreationDrawerWrapper>
                            <div className="export-options">
                                <div className="export-options-selector">
                                    <div style={{ paddingRight: ".5rem" }}>Billing Client:</div>
                                    {billingInfoList.length > 1
                                        ?
                                        <CustomSelect
                                            defaultValue={selectedBillingInfoId}
                                            onChange={setSelectedBillingInfoId}
                                            options={billingInfoList.map(el => {
                                                return { key: el.ID, value: el.ID, label: `${el.CompanyName} / ${el.InvoiceType}` };
                                            })}
                                        />
                                        :
                                        <p><strong>{billingInfoList[0].CompanyName} / {billingInfoList[0].InvoiceType}</strong></p>
                                    }
                                </div>
                                <div className="export-options-tools">
                                    <>
                                        <p>By default, future confirmed (and not cancelled) dates will be exported</p>
                                        {/* <p><NwLinkButton label="click here to select also past events" onClick={selectAll} /></p> */}
                                    </>
                                </div>
                            </div>
                            <div className="export-content">
                                <div className="export-content-left">
                                    <NwDivider dark textSize="big">models</NwDivider>
                                    <div className="models-container">
                                        {modelsFees.map((modelFees, index) => {
                                            return (
                                                <div className="model-container" key={`model-fees-${modelFees.ModelID}-${index}`}>
                                                    <Checkbox
                                                        checked={modelFees.active}
                                                        onChange={handleChangeModel(modelFees.ModelID)}>
                                                        <h2>{getContactName(modelFees.ModelID)}</h2>
                                                        <p>{modelFees.ModelConfirmationProperties.ConfirmedWorkDays} confirmed work dates</p>
                                                    </Checkbox>
                                                    {modelFees.active &&
                                                        <div className='transactions-container'>
                                                            <ContractCreationDrawerTransactionItem modelID={modelFees.ModelID} transactions={modelFees.transactions} project={currentProject} onSelect={handleUpdateModelFees} />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <NwDivider dark textSize="big">other project fees</NwDivider>
                                    <div className='transactions-container'>
                                        <ContractCreationDrawerTransactionItem transactions={otherFees} project={currentProject} onSelect={handleUpdateOtherFees} />
                                    </div>
                                </div>
                                <div className="export-content-right">
                                    <NwDivider dark textSize="big">Work Days</NwDivider>
                                    <div className="events-container">
                                        {jobEvents.map((jobEvent, eventIndex) => (
                                            <div className="event-container" key={`event-${eventIndex}`}>
                                                <Checkbox
                                                    checked={jobEvent.active}
                                                    onChange={handleChangeEvent(eventIndex)}>
                                                    <FlexContainer>
                                                        <div className='event-tag'>
                                                            <EventTypeTag event={jobEvent} type={jobEvent.TypeName} />
                                                        </div>
                                                        <div className='event-title'>{jobEvent.Name}</div>
                                                    </FlexContainer>
                                                </Checkbox>
                                                {jobEvent.active &&
                                                    <div className='working-days-container'>
                                                        {jobEvent.EventDates.map((eventDate, index) => {
                                                            let disabled = false;
                                                            if (!eventDate.EventDatesModels.find(model => model.OptionNumber === 10 && modelsFees.find(modelFee => modelFee.ModelID === model.ModelID && modelFee.active))) {
                                                                disabled = true;
                                                            }
                                                            return (
                                                                <div key={`event-date-${eventDate.ID}`} className="working-day-container">
                                                                    <Checkbox
                                                                        checked={eventDate.active}
                                                                        onChange={handleChangeEventDate(eventIndex, index)}
                                                                        disabled={disabled}
                                                                    >
                                                                        <FlexContainer>
                                                                            <div className='event-title'>{showDateNoTime(eventDate.ToDate)} {(eventDate.EventDatesModels && eventDate.EventDatesModels.length > 0) && <span> [{eventDate.EventDatesModels.length} models]</span>}</div>
                                                                        </FlexContainer>
                                                                    </Checkbox>
                                                                </div>
                                                            )
                                                        })}

                                                        {/* <ContractCreationDrawerTransactionItem modelID={modelFees.ModelID} transactions={modelFees.transactions} project={currentProject} onSelect={handleUpdateModelFees} /> */}
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <NwDivider dark textSize="big">Usages</NwDivider>
                                    <div className='usages-container'>
                                        {usages.map((usage, index) => {
                                            return (
                                                <div key={`usage-${usage.ID}`} className='usage-container'>
                                                    <Checkbox
                                                        checked={usage.active}
                                                        onChange={handleUpdateUsage(index)}
                                                    >
                                                        <h2>{`${getUsageTypes(usage)} - ${getUsageAreas(usage)}`}</h2>
                                                        <p>{usage.Duration}</p>
                                                        <p>{usage.Description}</p>
                                                    </Checkbox>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{textAlign: "center", margin: "1rem"}}>
                                <ButtonTemplateSelector templateArea="ProjectContract" onClick={handleGenerateContract} />
                                {/* <SelectTemplateSelector type="ProjectContract" onTemplateChange={handleSelectedTemplate} />
                                &nbsp;&nbsp;
                                <NwButton primary label='Generate Contract' onClick={handleGenerateContract} /> */}
                            </div>
                        </ContractCreationDrawerWrapper>
                    )
                }
            </NwDrawer>
            {docVersion &&
                <NwDrawer
                    onClose={() => setDocVersion(null)}
                    padding="0px"
                    title="Edit Contract"
                    visible={true}
                    whiteBg={false}
                    width="80vw"
                    maxWidth={"1024px"}
                >
                    <PdfPrintDrawer docVersion={docVersion} saveButton />
                </NwDrawer>
            }
        </>
    )

};

export default ContractCreationDrawer;