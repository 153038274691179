import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { forceReloadProject } from 'Hooks/Project/UseProject';

const getProjectPrevisionalTransactions = projectId => (
    Axios.get(`/PrevisionalTransactions/GetForProject/${projectId}`)
);

const usePrevisionalTransactions = projectId => {
    return useQuery(["previsionalTransactions", projectId], () => getProjectPrevisionalTransactions(projectId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false
    });
};

const onDeletePrevisionalTransaction = ({ ID }) => (
    Axios.delete(`/PrevisionalTransactions/${ID}`)
)

const useDeletePrevisionalTransaction = () => {
    return useMutation(onDeletePrevisionalTransaction, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
}

const onUpdatePrevisionalTransaction = ({ transactionData }) => (
    Axios.put('/PrevisionalTransactions', transactionData)
);

const useUpdatePrevisionalTransaction = () => {
    return useMutation(onUpdatePrevisionalTransaction, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.transactionData.ProjectID);
        }
    })
}

const onAddPrevisionalTransaction = ({ transactionData }) => (
    Axios.post('/PrevisionalTransactions', transactionData)
);

const useAddPrevisionalTransaction = () => {
    return useMutation(onAddPrevisionalTransaction, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.transactionData.ProjectID);
        }
    })
}

export {
    usePrevisionalTransactions,
    useDeletePrevisionalTransaction,
    useUpdatePrevisionalTransaction,
    useAddPrevisionalTransaction
};