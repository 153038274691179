import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import FlexContainer from 'Components/Gui/FlexContainer';
import AddEventsButtons from 'Components/AddEventsButtons/AddEventsButtons';
import AgendaEvent from 'Components/Schedule/Agenda/AgendaEvent'
import { useStateValue } from 'Contexts/StateProvider';
import { useAddModelToDate } from 'Hooks/Event/UseEvent';

const ProjectModal = styled(Modal)`
    min-width: 700px;
`;

const AddNewEventModal = ({ contactId, defaultEventDate, calendarEvents, onCreateEvent, onCloseModal, onFetchEvents }) => {
    // const [isFetchingCastings, setIsFetchingCastings] = useState(false);
    const [dateEvents, setDateEvents] = useState([]);
    const [{ areaContext }] = useStateValue();
    const { mutateAsync: onAddModelDate } = useAddModelToDate();

    useEffect(() => {
        handleFetchCastings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarEvents]);

    const handleFetchCastings = async () => {
        try {
            const fom = getMoment(defaultEventDate).clone().startOf("day").format('YYYY-MM-DD')
            const lom = getMoment(defaultEventDate).clone().endOf("day").format('YYYY-MM-DD')
            const postParams = {
                FDate: fom, 
                TDate: lom,
                inclJobs: true, 
                inclCastings: true, 
                checkConflict: false, 
                inclTravels: false, 
                inclAccommodations: false,
                inclExternalJobs: false, 
                inclFollowUps: false, 
                inclMeetings: false, 
                inclNotices: false,
                General: true, 
                Warning: false
            };
            const events = await Axios.post("/calendar/getevents", postParams)
            const availableEvents = [];
            if (events.Events) {
                events.Events.map(event => {
                    if (!calendarEvents.find(selectedEvent => selectedEvent.ID === event.ID)) {
                        availableEvents.push({
                            ...event,
                            isAvailable: true
                        });
                    }
                    return null;
                });
            }
            setDateEvents(availableEvents);
        } catch (error) {
            console.log('ant : Get Fetch Castings Error => ', error);
        }
    };

    const handleAddModelEvent = async event => {
        try {
            await onAddModelDate({
                eventDateId: event.EventDateID,
                modelId: contactId
            });
            await onFetchEvents();
            onCloseModal();
        } catch (error) {
            console.log('ant : Add Model Event Error => ', error);
        }        
    };

    return (
        <ProjectModal
            title={'Add New Event'}
            bodyStyle={{height: 520}}
            visible={true}
            footer={null}
            onCancel={onCloseModal}>   
            <div>create a new event</div>
            <FlexContainer mg='1rem 2rem'>
                <AddEventsButtons onCreateEvent={onCreateEvent} />
            </FlexContainer>
            {dateEvents.length > 0 ? 
                <>
                    <div>add model to an existing event</div>
                    {dateEvents.map(ev => {
                        return (
                            <AgendaEvent
                                isAvailable={true}
                                onAddModelEvent={handleAddModelEvent}
                                key={`${ev.TypeName}-${ev.ID}`}
                                date={defaultEventDate}
                                event={ev}
                            />
                        )
                    })}
                </>
                : <div>no available events</div>
            }
        </ProjectModal>
    );
};

export default AddNewEventModal;