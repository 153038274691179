import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getEventsForDate, getSortedEvents, loadMonthBirthdays, loadMonthEvents } from './AgendaUtils';
import { getMoment, isBirthday, toISODate } from 'Libs/NwMoment';
import Axios from 'axios';
import { useModelsList } from 'Hooks/Contact/UseContactsList';


export const AgendaContext = createContext(null);

export function AgendaProvider({ values, children }) {

    const {
        afterReload,
        bookingSection,
        contactId,
        forceReload,
        isMobileDrawer,
        onClearContactID,
        onScheduleRangeChange,
        onSelectEvent,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    } = values

    const [month, setMonth] = useState(null)
    const [monthEvents, setMonthEvents] = useState([]);
    const [dayEvents, setDayEvents] = useState([])
    const [monthBirthdays, setMonthBirthdays] = useState([]);
    const [dayBirthDays, setDayBirthDays] = useState([]);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [isLoadingMonth, setIsLoadingMonth] = useState(false);
    const [isLoadingDay, setIsLoadingDay] = useState(false);
    const [isLoadingBirthdays, setIsLoadingBirthdays] = useState(false);
    const [isLoadingAvailableEvents, setIsLoadingAvailableEvents] = useState(false);
    const [availableEventsPopoverVisible, setAvailableEventsPopoverVisible] = useState(false);
    const [isShowingAvailableEvents, setIsShowingAvailableEvents] = useState(false);

    const { data: fullList } = useModelsList();

    useEffect(() => {
        setAvailableEvents([]);
        setIsShowingAvailableEvents(false);
    }, [values.scheduleRange]);

    const loadAvailableEvents = async () => {
        setIsLoadingAvailableEvents(true);
        try {
            const postParams = {
                FDate: toISODate(scheduleRange.start),
                TDate: toISODate(scheduleRange.end),
                inclJobs: true,
                inclCastings: true,
                checkConflict: false,
                inclTravels: false,
                inclAccommodations: false,
                inclExternalJobs: false,
                inclFollowUps: false,
                inclMeetings: false,
                inclNotices: false,
                General: true,
                Warning: false
            };
            const events = await Axios.post("/calendar/getevents", postParams)
            setAvailableEvents(events.Events);
            const availableEvents = [];
            if (events.Events) {
                events.Events.map(event => {
                    if (!dayEvents.find(ev => ev.ID === event.ID)) {
                        availableEvents.push({
                            ...event,
                            isAvailable: true
                        });
                    }
                    return null;
                });
            }
            if (availableEvents.length === 0) {
                setAvailableEventsPopoverVisible(true);
            }
            setIsLoadingAvailableEvents(false);
            setIsShowingAvailableEvents(true);
        } catch (error) {
            setAvailableEvents([]);
            setIsLoadingAvailableEvents(false);
            setIsShowingAvailableEvents(false);
        }
    };

    const availableEventsToRender = useMemo(() => {
        const events = [];
        if (availableEvents) {
            availableEvents.map(event => {
                if (!dayEvents.find(ev => ev.ID === event.ID)) {
                    events.push({
                        ...event,
                        isAvailable: true
                    });
                }
                return null;
            });
        }
        return events;
    }, [dayEvents, availableEvents]);

    // const displayedEvents = [...dayEvents, ...availableEventsToRender];
    // displayedEvents.sort((event1, event2) => (new Date(event1.FromDate)) - (new Date(event2.ToDate)));

    useEffect(() => {
        if (forceReload) {
            loadMonthEvs();
            afterReload();
        }
    }, [forceReload]);

    useEffect(() => {
        if (fullList && scheduleRange && scheduleRange.start && fullList.length && monthBirthdays && monthBirthdays.length) {
            const d = getMoment(scheduleRange.start)
            const db = monthBirthdays.filter((b) => {
                if (isBirthday(b.BirthDate, d)) {
                    if (b.ContactType === "Model") {
                        const model = fullList.find((m) => m.ID === b.ID);
                        if (model) {
                            return true
                        }
                    } else {
                        return true
                    }
                }
                return false
            })
            setDayBirthDays(db)
        } else {
            setDayBirthDays([])
        }
    }, [monthBirthdays, fullList, scheduleRange])

    const loadMonthEvs = async () => {
        setIsLoadingDay(true)
        setIsLoadingMonth(true)
        setIsLoadingBirthdays(true)
        const evs = await loadMonthEvents(scheduleRange, params)
        setMonthEvents(evs)
        setIsLoadingMonth(false)
        if (!values.contactId) {
            const bds = await loadMonthBirthdays(scheduleRange)
            setMonthBirthdays(bds)
            setIsLoadingBirthdays(false)
        }
        //loadDayEvs()
    }

    const loadDayEvs = () => {
        const seldate = scheduleRange.start
        const eventsForDates = getEventsForDate(seldate, monthEvents, scheduleRange)
        setDayEvents(eventsForDates)
        setIsLoadingDay(false)
    }

    useEffect(() => {
        const startdate = getMoment(scheduleRange.start)
        const newMonth = startdate.month()
        if (month !== newMonth) {
            setMonth(newMonth)
        }
    },[scheduleRange])

    useEffect(() => {
        if (monthEvents && monthEvents.length) {
            setIsLoadingDay(true)
            loadDayEvs()
        } else {
            setIsLoadingDay(false)
        }
    }, [scheduleRange, monthEvents])

    // useEffect(() => {
    //     onUpdateDateSelectedEvents(getEventsForDate(null, monthEvents, scheduleRange))
    // }, [monthEvents, scheduleRange])

    const eventsToDisplay = useMemo(() => {
        const evsToDisplay = [...dayEvents, ...availableEventsToRender]
        const sorted_evs = getSortedEvents(evsToDisplay, contactId)
        return sorted_evs
    }, [dayEvents, availableEventsToRender, contactId])

    useEffect(() => {
        loadMonthEvs()
    }, [month, params])

    const onSelectDate = useCallback((selecteddate) => {
        onScheduleRangeChange(selecteddate.toDate(), selecteddate.toDate())
    },[])

    const closeAvailableEventsPopover = useCallback(() => {
        setAvailableEventsPopoverVisible(false)
    },[])

    const hideAvailableEvents = useCallback(() => {
        setAvailableEvents([])
        setIsShowingAvailableEvents(false)
    },[])

    const contextData = {
        availableEvents,
        bookingSection,
        closeAvailableEventsPopover,
        contactId,
        dayBirthDays,
        dayEvents,
        eventsToDisplay,
        hideAvailableEvents,
        isLoadingAvailableEvents,
        isLoadingBirthdays,
        isLoadingDay,
        isLoadingMonth,
        isMobileDrawer,
        isShowingAvailableEvents,
        loadAvailableEvents,
        loadDayEvs,
        loadMonthEvs,
        month,
        monthBirthdays,
        monthEvents,
        onClearContactID,
        onScheduleRangeChange,
        onSelectDate,
        onSelectEvent,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        setMonth,
        type,
    }

    return (
        <AgendaContext.Provider value={contextData}>
            {children}
        </AgendaContext.Provider>
    );
}

export function useAgendaContext() {
    return useContext(AgendaContext);
}

