import React, { useState, useMemo } from "react";

import { NwAddButton } from "Components/Gui/NwButton";
import NwDrawer from "Components/Gui/NwDrawer";
import BillingInfoForm from 'Components/BillingInfos/BillingInfoForm';
import BillingInfoItem from 'Components/BillingInfos/BillingInfoItem';
//import { useContact } from "Hooks/Contact/UseContact";
import { useContactBillingInfos } from "Hooks/Contact/UseContactBillingInfos";
import { getContactSubTypeName } from "Libs/NwCustomerTypes";

const BillingInfoDrawer = ({ contactId, type, subtype, readOnly, onClose }) => {
    //const { data: currentContact } = useContact(contactId)
    const { data: billingInfos, isFetching } = useContactBillingInfos(contactId);
    const [viewMode, setViewMode] = useState("LISTING"); // LISTING, VIEWING, INSERTING
    const [billingItem, setCurrentBillingItem] = useState(null);
    const [touchForm, setTouchForm] = useState(false);

    const handleCancelEdit = () => {
        setCurrentBillingItem(null);
        setViewMode("LISTING");
    };

    //const defaultBillingInfoID = currentContact.AccountingGroups[0].DefaultBillingInfo && currentContact.AccountingGroups[0].DefaultBillingInfo.ID;
    const billingInfoList = useMemo(() => {
        if (!isFetching && billingInfos && billingInfos.length > 0) {
            let sortedBillingInfos = [...billingInfos];
            sortedBillingInfos.sort((item1, item2) => (!item1.Disabled || (item1.Disabled && item2.Disabled)) ? -1 : 1);
            sortedBillingInfos.sort((item1, item2) => item1.isDefault ? -1 : 1)
            return sortedBillingInfos;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingInfos]);

    const typeForCode = type.toLowerCase() === 'model' ? 'model' : getContactSubTypeName(subtype).toLowerCase();

    return (
        <NwDrawer
            onClose={onClose}
            title="Billing Profiles">
            {!readOnly &&
                <div className='drawer-toolbar-container'>
                    <NwAddButton
                        primary
                        onClick={() => {
                            setViewMode("INSERTING");
                        }}
                        label="Add a new billing profile"
                    />
                </div>
            }
            <div className='drawer-content standard'>
                {(!isFetching && billingInfoList && billingInfoList.length > 0) &&
                    billingInfoList.map(item => (
                        <BillingInfoItem
                            key={item.ID}
                            isDefault={item.isDefault}
                            item={item}
                            type={type}
                            readOnly={readOnly}
                            contactId={contactId}
                            onEditDoc={() => {
                                setCurrentBillingItem(item);
                                setViewMode("EDITING");
                            }} />
                    ))}
            </div>
            {viewMode === "INSERTING" && (
                <NwDrawer
                    className='billinginfo-form-drawer'
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add Billing Profile'
                    onClose={handleCancelEdit}
                >
                    <BillingInfoForm
                        type={typeForCode}
                        ContactID={contactId}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
            {viewMode === "EDITING" && (
                <NwDrawer
                    className='billinginfo-form-drawer'
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Billing Profile'
                    onClose={handleCancelEdit}
                >
                    <BillingInfoForm
                        type={typeForCode}
                        ContactID={contactId}
                        billingInfo={billingItem}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
        </NwDrawer>
    );
};

export default BillingInfoDrawer;
