import React from 'react';
import styled from 'styled-components';
import { Field } from "formik";
import { Row, Col } from 'antd';
import { NWAmountInput, NWQuantitySelector } from "Components/Gui/NWForm/NwFormItems";
import { validateAmount, validateAmountZeroAllowed } from 'Libs/NwUtils';
import Money from 'Components/Gui/Money';

export const TotalContainer = styled.div`
    text-align: right;
    padding: .25rem .15rem;
    flex: 1 1 0;

    h2 {
        margin-bottom: 0;
        text-transform: uppercase;
        line-height: 1.15em;
        font-size: .85rem;
        font-weight: 400;
        color: ${props => props.theme.TextColorMuted};
    }
    div {
        line-height: 1.15em;
        font-weight: bold;
        font-size: 1rem;
        padding-top: .75rem;
    }
`;

const AmountLine = ({ values, touched, onChangeAmount, amountLabel, gutter = 16, hideQuantity, allowZero = false }) => {  //onChangeForm
    
    if (hideQuantity) {
        return (
            <Row gutter={gutter}>
                <Col>
                    <Field
                        component={NWAmountInput}
                        allowZero={allowZero}
                        value={values.Amount}
                        label={amountLabel}
                        name="Amount"
                        validate={allowZero ? validateAmountZeroAllowed : validateAmount}
                        onAfterChange={onChangeAmount}
                    />
                </Col>
            </Row>
        )
    } else {
        return (<Row gutter={gutter}>
            <Col>
                <Field
                    component={NWAmountInput}
                    allowZero={allowZero}
                    value={values.Amount}
                    label={amountLabel}
                    name="Amount"
                    validate={allowZero ? validateAmountZeroAllowed : validateAmount}
                    onAfterChange={onChangeAmount}
                />
            </Col>
            <Col>
                <Field
                    component={NWQuantitySelector}
                    value={values.Quantity}
                    label="Quantity"
                    name="Quantity"
                />
            </Col>
            <Col flex="auto">
                <TotalContainer>
                    <h2>Total</h2>
                    <div><Money amount={values.Amount[1] * values.Quantity[0]} currency={values.Amount[0]} /></div>
                </TotalContainer>
            </Col>
        </Row>)
    }
}

export default AmountLine;