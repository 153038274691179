import React from "react";
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwEllipsis from "Components/Gui/NwEllipsis";
import CreatedBy from "Components/CreatedBy/CreatedBy";
import { Comment, Tooltip, /*Avatar, Row, Col*/ } from "antd";

import { getMoment } from "Libs/NwMoment";
import UserIcon from "Components/UserIcon/UserIcon";
import NwIcon from "Components/Gui/NwIcon";
import Visibilities from 'Components/Gui/Visibilities';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import { getEvent } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';
import { showLog, showLogFromNow } from "Libs/NwMoment";

const StyledComment = styled(Comment)`
  
  white-space: pre-line;
  .ant-comment-content-author {
    justify-content: space-between;

    .ant-comment-content-author-name {
        color: black;
        font-weight: bold;
        font-size: 1rem;
        color: ${props => props.theme.TextColor};
        font-weight: 500;
        
        & > * {
          color: ${props => props.theme.TextColor};
        }
    }
  }
  

  .ant-comment-inner {
    padding: 0;
  }
`

const EventTypeTagContainer = styled.span`
  margin-left: 10px;
`;

const CommentItem = ({ item, disableVisibility, isEvent, events, rows = 3 }) => {
  const { Text, Priority, Visibility, DateCreated, UserCreated } = item;

  const actions = [];

  const event = getEvent(events, item);

  return (
    (isEvent
      ?
      <>
        <NwEllipsis popover popovertitle={<CreatedBy object={item} short />} rows={rows} text={Text} />
        {!disableVisibility && <Visibilities visibilities={Visibility.Areas} />}
        <Tooltip title={showLog(DateCreated)}><span>{showLogFromNow(DateCreated)}</span></Tooltip>
        {Priority === 1 &&
          <Tooltip title="Marked as important">&nbsp;<NwIcon style={{ marginLeft: "1em" }} icon={solid('comment-exclamation')} danger /></Tooltip>
        }
      </>
      :
      <StyledComment
        actions={actions}
        //author={<UserName userId={UserCreated} />}
        author={<CreatedBy object={item} short textonly />}
        avatar={
          <UserIcon userId={UserCreated} />
        }
        content={
          <>
            <NwEllipsis popover popovertitle={<CreatedBy object={item} short />} rows={rows} text={Text} />
            {!disableVisibility && <Visibilities visibilities={Visibility.Areas} />}
          </>
        }
        datetime={
          <>
            {Priority === 1 && <Tooltip title="Marked as important">&nbsp;<NwIcon style={{ marginLeft: "1em" }} icon={solid('comment-exclamation')} danger /></Tooltip>}
            {event && <EventTypeTagContainer><EventTypeTag event={event} /></EventTypeTagContainer>}
          </>
        }
      />
    )
  );
};

export default CommentItem;
