import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { Loading } from 'Components/Gui/Loading';
import { EventContainer, } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
import NwDrawer from 'Components/Gui/NwDrawer';
import { useAccommodation, forceReloadAccommodation, } from 'Hooks/Event/UseAccommodation';
import RoomLodgingForm from 'Components/EventDrawer/RoomLodgingEvent/RoomLodgingForm';
import AccommodationForm from 'Components/EventDrawer/AccommodationEvent/AccommodationForm';
import { getRoomConnections } from "Hooks/Contact/UseContactConnections";
import EventDrawerDetailsContainer from "Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer";
import Axios from "axios";
import NwIcon from "Components/Gui/NwIcon";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AccommodationContent from "./AccommodationContent";
import EditRoomLodging from "../RoomLodgingEvent/EditRoomLodging";

const AccommodationWrapper = ({
    afterRemove,
    afterUpdate,
    ID,
    modelId,
    onClose,
    onSelectEvent,
    onTouchForm,
    plainMode,
    projectId,
    type,
    viewMode,
}) => {
    const [accommodationID, setAccommodationID] = useState(ID);
    const [isEdit, setIsEdit] = useState(viewMode ? true : false);
    const { data: accommodationData, isFetching, status } = useAccommodation(accommodationID);
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        if (ID) {
            setAccommodationID(ID)
        }
    }, [ID])

    const handleSelectProject = async project => {
        try {
            await Axios.put('/accommodation/SetProject', {
                ObjectID: accommodationData.ID,
                ProjectID: project
            })
            forceReloadAccommodation(accommodationData.ID);
            await afterUpdate();
        } catch (error) {
            if (error.errorCode && error.errorCode === "GenericError") {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: error.message,
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
            }
        }
    };

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const getAccommodationFormProps = () => ({
        accommodationData: editModelEvent ? editModelEvent : accommodationData,
        editModelEvent: editModelEvent ? true : false,
        viewMode: viewMode,
        modelId: modelId,
        projectId: projectId,
        afterUpdate: afterUpdate,
        onTouchForm: touchForm => {
            if (accommodationData) {
                setTouchForm(touchForm);
            } else {
                onTouchForm(touchForm);
            }
        },
        onCancel: () => {
            handleClearEditEvent();
            handleClearTouchForm();
            if (accommodationData) {
                setIsEdit(false);
            } else {
                onClose();
            }
        },
        onUpdateAccommodationData: newEventID => {
            if (newEventID) {
                setAccommodationID(newEventID)
            } else {
                forceReloadAccommodation(accommodationID);
            }
            handleClearTouchForm();
            handleClearEditEvent();
        }
    })

    const getRoomLodgingFormProps = () => {
        const connections = getRoomConnections();
        const connection = connections.find(c => c.ContactID === (accommodationData && accommodationData.ServiceID));
        return ({
            accommodationData: accommodationData,
            room: accommodationData ? accommodationData.Room : null,
            viewMode: viewMode,
            modelId: modelId,
            projectId: projectId,
            afterUpdate: afterUpdate,
            connection: connection,
            onTouchForm: touchForm => {
                if (accommodationData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            },
            onCancel: () => {
                handleClearEditEvent();
                handleClearTouchForm();
                if (accommodationData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            },
            onUpdateAccommodationData: (id) => {
                if (id) {
                    setAccommodationID(id)
                } else {
                    forceReloadAccommodation(accommodationID);
                }
                handleClearTouchForm();
                handleClearEditEvent();
            },
            noDelete: true
        })
    }

    if ((!accommodationData && accommodationID) || status === 'Loading') {
        return (
            <Loading textBlack />
        )
    }
    if (!accommodationData && isEdit) {
        return (
            <EventContainer>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">{type === 'RoomLodging' ? 'Add new room lodging' : 'Add new accommodation'}</div>
                </div>
                {type === 'RoomLodging' ?
                    <RoomLodgingForm {...getRoomLodgingFormProps()} />
                    :
                    <AccommodationForm {...getAccommodationFormProps()} />
                }
            </EventContainer>
        )
    }
    return (
        <>
            {plainMode  //accommodation card in the project page
                ?
                <EventContainer hasProject={accommodationData && accommodationData.Project}>
                    <div className="event-content-wrapper">
                        {(!plainMode && accommodationData.Project) &&
                            <ProjectEventsTimeline
                                event={{
                                    ...accommodationData,
                                    TypeName: "Accommodation"
                                }}
                                projectID={accommodationData.Project.ID}
                                onSelectEvent={onSelectEvent} />
                        }
                        <AccommodationContent
                            afterRemove={afterRemove}
                            afterUpdate={afterUpdate}
                            handleSelectProject={handleSelectProject}
                            isEdit={isEdit}
                            onTouchForm={onTouchForm}
                            plainMode={plainMode}
                            projectId={projectId}
                            setEditModelEvent={setEditModelEvent}
                            setIsEdit={setIsEdit}
                            accommodationID={accommodationID}
                            accommodationData={accommodationData}
                        />
                    </div>
                </EventContainer>
                :
                <EventDrawerDetailsContainer
                    eventData={{ ...accommodationData, elType: 'Accommodation', uniqID: `Accommodation-${accommodationData.ID}` }}
                    filterModel={modelId}
                    isFetching={isFetching}
                    typeName="accommodation"
                    onSelectEvent={onSelectEvent}
                    onSelectProject={handleSelectProject}
                    firstTab={
                        <div className="event-content-wrapper">
                            {(!plainMode && accommodationData.Project) &&
                                <ProjectEventsTimeline
                                    event={{
                                        ...accommodationData,
                                        TypeName: "Accommodation"
                                    }}
                                    projectID={accommodationData.Project.ID}
                                    onSelectEvent={onSelectEvent} />
                            }
                            <AccommodationContent
                                afterRemove={afterRemove}
                                afterUpdate={afterUpdate}
                                handleSelectProject={handleSelectProject}
                                isEdit={isEdit}
                                onTouchForm={onTouchForm}
                                plainMode={plainMode}
                                projectId={projectId}
                                setEditModelEvent={setEditModelEvent}
                                setIsEdit={setIsEdit}
                                accommodationID={accommodationID}
                                accommodationData={accommodationData}
                            />
                        </div>
                    }
                />
            }
            {isEdit &&
                <NwDrawer
                    padding="0px"
                    width={740}
                    title={accommodationData && accommodationData.Room ? 'Edit Room Lodging' : 'Edit Accommodation'}
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        handleClearEditEvent();
                        setIsEdit(false);
                        handleClearTouchForm();
                    }}
                >
                    <EventContainer>
                        {accommodationData.Room ?
                            <EditRoomLodging {...getRoomLodgingFormProps()} />
                            :
                            <AccommodationForm {...getAccommodationFormProps()} />
                        }
                    </EventContainer>
                </NwDrawer>
            }
        </>
    )

};

export default AccommodationWrapper;
