import styled from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables.js';


const StyledGroupButton = styled.span`
    display: flex;
    justify-content: ${props => props.right ? 'flex-end' : 'flex-start'};

    
    .ant-select {
        .ant-select-selector {
            
            font-size: ${props => props.size === 'small' ? '11px' : '14px'};
            //height:  ${props => props.size === 'small' ? '26px' : '28px'};
            
            /* height: 28px !important;
            padding: 2px 10px !important; */
            
            border-right: none;
            border-radius: 0;

            &:hover {
                border-color: ${StyleVariables.BorderColor} !important;
            }

            &:focus, &:active {
                border-color: ${StyleVariables.BorderColor} !important;
            }
        }

        &.ant-select-focused { 
            .ant-select-selector {
                border-color: ${StyleVariables.BorderColor} !important;
                border-right-width: 0;
                outline: 0;
                box-shadow: none;
            }
        }
    }
    
    .ant-btn {
        border-radius: 0;
        border-right: none;
        margin: 0;

        font-size: ${props => props.size === 'small' ? '11px' : '14px'};
        //height:  ${props => props.size === 'small' ? '26px' : '28px'};
        text-transform: ${props => props.theme.$capitalize ? 'capitalize' : 'uppercase'} !important;

        &:hover, &:focus, &:active {
            /* border-color: ${StyleVariables.PrimaryColor} !important;
            background-color: ${StyleVariables.PrimaryColor};
            color: #fff; */
            border-color: ${StyleVariables.BorderColor} !important;
            color: #000;
            opacity: 1;
        }

        &.dropdown-trigger {
            padding-right: 8px;
            padding-left: 8px;
            border-right: 1px solid ${StyleVariables.DarkerBorderColor};
            border-radius: 0 4px 4px 0;
        }

        &.ant-btn-primary {
            &:hover, &:focus, &:active {
                border-color: #4fcdf7 !important;
                color: #fff;
                opacity: 1;
            }
            &.dropdown-trigger {
                border-left: 1px solid #fff;
            }
        }
    }

    .ant-btn:first-child:not(:last-child), span:first-child:not(:last-child) > .ant-btn, a:first-child:not(:last-child) > .ant-btn {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .ant-btn:last-child:not(:first-child), span:last-child:not(:first-child) > .ant-btn, a:last-child:not(:first-child) > .ant-btn {
        border-right: ${StyleVariables.DefaultBorder};
    }
    .ant-btn:last-child:not(:first-child), span:last-child:not(:first-child) > .ant-btn, a:last-child:not(:first-child) > .ant-btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    > .ant-btn:only-child, span:only-child > .ant-btn, a:only-child > .ant-btn {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: ${StyleVariables.DefaultBorder};
    }

    .ant-btn.dropdown-single {
        border-radius: 4px;
        border: ${StyleVariables.DefaultBorder} !important;

        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        padding-right: 5px !important;

        svg {
            margin: 0 .5em;
        }
    }
`

export {StyledGroupButton}