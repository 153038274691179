import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwEditButton } from 'Components/Gui/NwButton';
import SocialsDrawer from 'Components/Social/SocialsDrawer';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import ContactSocialLink from './ContactSocialLink';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
`;

const SocialsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const LockContainer = styled(FlexContainer)`
    svg {
        font-size: 1.6rem;
    }
`;

const ContactSocials = ({ currentContact, previewMode, minheight }) => {
    const [isOpenAddEditSocials, setIsOpenAddEditSocials] = useState(false);
    const breakpoints = useNwBreakPoints();

    const viewClientSocials = useUserPermission('ViewClientSocials', currentContact && currentContact.Users.map(user => user.UserID));
    const viewAgencySocials = useUserPermission('ViewAgencySocials', currentContact && currentContact.Users.map(user => user.UserID));
    const viewServiceSocials = useUserPermission('ViewServiceSocials', currentContact && currentContact.Users.map(user => user.UserID));
    const viewModelSocials = useUserPermission('ViewModelSocials', currentContact && currentContact.Users.map(user => user.UserID));

    const viewSocials = useMemo(() => {
        if (currentContact) {
            if (currentContact.SubType === 1) return viewClientSocials;
            if (currentContact.SubType === 2) return viewAgencySocials;
            if (currentContact.SubType === 3) return viewServiceSocials;
            return viewModelSocials;
        }
        return false;
    }, [currentContact, viewClientSocials, viewAgencySocials, viewServiceSocials, viewModelSocials]);

    const handleOpenAddEditSocialsDrawer = () => {
        setIsOpenAddEditSocials(true);
    };

    const handleCloseAddEditSocialsDrawer = () => {
        setIsOpenAddEditSocials(false);
    };

    const renderSocials = () => {
       return (
            <SocialsContainer>
                {viewSocials ? 
                    <>
                        {currentContact.Socials.map((social, index) => (
                            <ContactSocialLink contactSocial={social} key={index} />
                        ))}
                    </>    
                :
                    <LockContainer justifyContent='center' alignItems='center'>
                        <NwIcon icon={light('lock-alt')} />
                    </LockContainer>
                }
            </SocialsContainer>
        )
    }
    
    return (
        breakpoints === 1 
        ? 
            renderSocials()
        :
            <NwCard title="Socials" icon={light("hashtag")} horizontal>
                <Container>
                    {renderSocials()}
                    {!previewMode && viewSocials &&
                        <NwEditButton 
                            disabled={!viewSocials} 
                            ghost 
                            size="small" 
                            onClick={handleOpenAddEditSocialsDrawer}/>
                    }
                </Container>
                {isOpenAddEditSocials &&
                    <SocialsDrawer contactID={currentContact.ID} onClose={handleCloseAddEditSocialsDrawer} />
                }
            </NwCard>
    );
};

export default ContactSocials;