import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import StyleVariables from "Components/Gui/StyleVariables";
import { Badge } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getModelAge, isBirthday } from "Libs/NwMoment";
import { ModelCover } from 'Components/ModelCover';
import NwIcon from "Components/Gui/NwIcon";
import ModelCalendarEvents from 'Components/ModelSelector/ModelsWall/ModelComponents/ModelCalendarEvents';
import MainFeatures from "Components/Features/MainFeatures";
import ModelDetailsHeaderAgencies from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import { NwTag } from "Components/Gui/NwTag";
import { NwButton } from 'Components/Gui/NwButton';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import { modelFullName } from "Hooks/Contact/UseContactsList";
import { useStateValue } from 'Contexts/StateProvider';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import FlexContainer from 'Components/Gui/FlexContainer';
import ContactAlertPopover from "Components/ContactAlert/ContactAlertPopover";
import { AgeCell, AgeCellBirthday, CalendarActions, FeaturesContainer, ModelCoverWrapper, NameCell, StyledTable, TagsContainer } from "./ModelsTableUI";
import { Loading } from "Components/Gui/Loading";
import ModelsTableColumnPackage from "./ModelsTableColumnPackage";
import ModelsTableColumnEvent from "./ModelsTableColumnEvent";
import Axios from "axios";
import ModelsTableColumnNonBookingEvent from "./ModelsTableColumnNonBookingEvent";
import { isModelBusy } from "Libs/ModelAvailability";
import { useUserPermission } from "Contexts/UserContext";


const ModelsTable = ({
    archivedMode,
    addModelsAs,
    currentEvent,
    currentPackage,
    isDrawer,
    isMobile,
    list,
    modelEventsInfo,
    multipleSelection,
    onModelClick,
    onSelect,
    onSelectModel,
    onUnarchiveModel,
    packageGroup,
    scope, //package, mailing-list, models-wall, booking, add-event, archive
    selectedList,
    showModelDrawerOnClick,
}) => {

    // Filter and order Main Features depending on User Metrics Config
    const [modelsAvailability, setModelsAvailability] = useState({});
    const [pagedList, setPagedList] = useState([]);
    const breakpoints = useNwBreakPoints();
    const [isOpenModelDrawer, setIsOpenModelDrawer] = useState(false);
    const [modelId, setModelId] = useState();
    const [{ areaContext }] = useStateValue();
    const [loadMore, setLoadMore] = useState(false);
    const [singleDayEventAvailability, setSingleDayEventAvailability] = useState({})
    const [isFetchingSingleDayEventAvailability, setIsFetchingSingleDayEventAvailability] = useState(false);
    const [lastAvailabilityRequestIDs, setLastAvailabilityRequestIDs] = useState([])
    const [newlyAddedToPackage, setNewlyAddedToPackage] = useState([]);
    const [cachedAvailabilityData, setCachedAvailabilityData] = useState({});

    const accessManagement = useUserPermission('AccessManagement');


    const handleAddBooksToPackage = (modelId, bookIDs) => {
        //find the model in existing array and update the playlistIDs
        const found = newlyAddedToPackage.find(item => item.modelID === modelId);
        if (found) {
            const newPlaylistIDs = [...found.playlistIDs, ...bookIDs];
            const newModels = newlyAddedToPackage.map(item => {
                if (item.modelID === modelId) {
                    return { ...item, playlistIDs: newPlaylistIDs };
                }
                return item;
            });
            setNewlyAddedToPackage(newModels);
            return;
        } else {
            setNewlyAddedToPackage([...newlyAddedToPackage, { modelID: modelId, playlistIDs: bookIDs }]);
        }
    }

    const handleRemoveBookFromPackage = (modelId, bookID) => {
        const found = newlyAddedToPackage.find(item => item.modelID === modelId);
        if (found) {
            const newPlaylistIDs = found.playlistIDs.filter(id => id !== bookID);
            if (newPlaylistIDs.length === 0) {
                const newModels = newlyAddedToPackage.filter(item => item.modelID !== modelId);
                setNewlyAddedToPackage(newModels);
            } else {
                const newModels = newlyAddedToPackage.map(item => {
                    if (item.modelID === modelId) {
                        return { ...item, playlistIDs: newPlaylistIDs };
                    }
                    return item;
                });
                setNewlyAddedToPackage(newModels);
            }
        }
    }

    //useEffect to show the newly added books to the package
    // useEffect(() => {
    //     console.log('newlyAddedToPackage', newlyAddedToPackage);
    // }, [newlyAddedToPackage]);

    const loader = useRef(null);
    //const [refreshSingleDayEventAvailability, setRefreshSingleDayEventAvailability] = useState(false)
    //const [responseData, setResponseData] = useState();
    
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setLoadMore(true);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "10px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    const pageCardCount = 50;

    useEffect(() => {
        setPagedList(list.slice(0, pageCardCount))
    }, [list]);

    useEffect(() => {
        if (loadMore) {
            if (list.length > pagedList.length) {
                const newShowedList = list.slice(pagedList.length, pagedList.length + pageCardCount);
                setPagedList([...pagedList, ...newShowedList]);
            }
            setLoadMore(false)
        }
    }, [loadMore]);

    const dataSource = useMemo(() => {
        const pds = pagedList.map(model => ({
            ...model,
            key: model.ID,
            name: modelFullName(model),
            agency: model.MAN,
            birthday: model.B,
            features: model,
            tags: model.T.map(el => el.Name),
            image: model,
            action: model.ID,
            ...model
        }))
        return pds;
    }, [pagedList]);

    const handleOpenModelDrawer = modelId => {
        setModelId(modelId);
        setIsOpenModelDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setIsOpenModelDrawer(false);
        setModelId();
    };

    const isModelInCurrentEvent = (modelId) => {
        if (currentEvent && currentEvent.EventDates && currentEvent.EventDates.length === 1) {
            return currentEvent.EventDates[0].EventDatesModels.some(model => model.ModelID === modelId);
        }
        return false;
    }

    const isModelInCurrentNonBookingEvent = (modelId) => {
        let modelProp = "ModelID";
        if (currentEvent.TypeName === "ToDo") {
            modelProp = "ContactID";
        }
        if (Number(currentEvent[modelProp]) === Number(modelId)) {
            return true;
        }
        if (currentEvent.RelatedEvents) {
            return currentEvent.RelatedEvents.some(event => Number(event[modelProp]) === Number(modelId));
        }
    };

    useEffect(() => {
        let newModelsAvailability = { ...modelsAvailability };
        for (const model of list) {
            let isBusy = false
            let isFetched = false
            if (modelEventsInfo && modelEventsInfo.ModelsInvolved.find(idModel => idModel === model.ID)) {
                isBusy = isModelBusy(modelEventsInfo.Events, model.ID)
                isFetched = true
            } else {
                if (modelEventsInfo) {
                    isFetched = true
                }
            }

            newModelsAvailability = {
                ...newModelsAvailability,
                [model.ID]: {
                    isBusy: isBusy,
                    isFetched: isFetched
                }
            };
        }
        setModelsAvailability(newModelsAvailability)
    }, [modelEventsInfo])


    const assignAvailabilityToModels = (availabilityData) => {
        let eventAvailabilities = {}
        if (availabilityData && availabilityData.Events) {
            for (const model of list) {
                if (isModelInCurrentEvent(model.ID)) {
                    eventAvailabilities = {
                        ...eventAvailabilities,
                        [model.ID]: {
                            inEvent: true,
                            available: false,
                            otherEvents: availabilityData.Events.filter(event => event.ID !== currentEvent.ID && event.ModelID === model.ID)
                        }
                    }
                } else {
                    if (availabilityData.ModelsInvolved.find(idModel => idModel === model.ID)) {
                        eventAvailabilities = {
                            ...eventAvailabilities,
                            [model.ID]: {
                                inEvent: false,
                                available: false,
                                otherEvents: availabilityData.Events.filter(event => event.ID !== currentEvent.ID && event.ModelID === model.ID)
                            }
                        }
                    } else {
                        eventAvailabilities = {
                            ...eventAvailabilities,
                            [model.ID]: {
                                inEvent: false,
                                available: true,
                                otherEvents: []
                            }
                        }
                    }
                }
            }
        }
        return eventAvailabilities
    }

    useEffect(() => {
        if (currentEvent) {
            //single-day event or non bookingevent so I immediately fetch availability
            if (list && list.length) {
                const modelIds = list.map(model => model.ID);
                setIsFetchingSingleDayEventAvailability(true)
                switch (currentEvent.TypeName) {
                    case "Job":
                    case "Casting":
                        if (currentEvent.EventDates && currentEvent.EventDates.length === 1) {
                            if (modelIds.length > 0) {
                                let modelListChanged = modelIds.some(x => !lastAvailabilityRequestIDs.includes(x));
                                
                                if (modelListChanged) {  //|| refreshSingleDayEventAvailability
                                    let newValues = {
                                        FDate: currentEvent.EventDates[0].FromDate,
                                        TDate: currentEvent.EventDates[0].ToDate,
                                        idmodels: modelIds,
                                        inclJobs: true,
                                        inclCastings: true,
                                        checkConflict: true,
                                        inclTravels: true,
                                        inclAccommodations: true,
                                        inclExternalJobs: true,
                                        inclFollowUps: true,
                                        inclMeetings: true,
                                        inclNotices: true,
                                        General: false,
                                        Warning: false
                                    };
                                    Axios.post("/calendar/getevents", newValues).then(response => {
                                        setLastAvailabilityRequestIDs([...modelIds])
                                        setCachedAvailabilityData(response)
                                        const evAv = assignAvailabilityToModels(response);
                                        setSingleDayEventAvailability(evAv);
                                    }).catch(error => {
                                        console.log("error", error);
                                        setCachedAvailabilityData(null)
                                        setSingleDayEventAvailability({});
                                    });
                                } else {
                                    //I can use cached data
                                    const evAvFromCache = assignAvailabilityToModels(cachedAvailabilityData);
                                    setSingleDayEventAvailability(evAvFromCache);
                                }
                            }
                        }
                        break;
                    case "Travel":
                    case "Accommodation":
                    case "FollowUp":
                    case "ToDo":
                        let eventPresence = {}
                        for (const model of list) {
                            if (isModelInCurrentNonBookingEvent(model.ID)) {
                                eventPresence = {
                                    ...eventPresence,
                                    [model.ID]: {
                                        inEvent: true
                                    }
                                }
                            } else {
                                eventPresence = {
                                    ...eventPresence,
                                    [model.ID]: {
                                        inEvent: false
                                    }
                                }
                            }
                        }
                        setSingleDayEventAvailability(eventPresence);
                        break;
                    default:
                        break;
                }
                setIsFetchingSingleDayEventAvailability(false)
            }
        }
    }, [currentEvent, list]);

    const handleModelClick = (key) => {
        if (isMobile) {
            handleOpenModelDrawer(key);
            return;
        }
        if (!onModelClick && showModelDrawerOnClick) {
            handleOpenModelDrawer(key);
        } else {
            if (onModelClick) {
                onModelClick(key)
            }
        }
    }

    const getPlaylistCount = (model) => {
        if (!isDrawer) return 0;
        if (currentPackage && currentPackage.Models) {
            let pc = 0;
            const found = currentPackage.Models.find(item => item.ModelID === model.ID);
            const foundInNewlyAdded = newlyAddedToPackage.find(item => item.modelID === model.ID)
            if (found && found.Playlists) {
                pc += found.Playlists.length;
            }
            if (foundInNewlyAdded) {
                pc += foundInNewlyAdded.playlistIDs.length;
            }
            return pc
        }
        return 0;
    }

    const thumbnailColumn = {
        title: "Image",
        dataIndex: "image",
        key: "image",
        width: isMobile ? '4rem' : '7rem',
        className: "cover-image",
        render: model => {
            const selected = selectedList.find(modelId => modelId === model.ID);
            const playlistCount = getPlaylistCount(model);
            return (
                <ModelCoverWrapper selected={multipleSelection && selected}>
                    <Badge count={playlistCount} color={StyleVariables.PrimaryColor}>
                        <ModelCover maxWidth={60} model={model} classN="model-cover-small" />
                    </Badge>
                </ModelCoverWrapper>
            )
        },
        onCell: (record, rowIndex) => {
            return {
                onClick: event => {
                    multipleSelection ? onSelectModel(record.key) : handleModelClick(record.key);
                }
            };
        }
    }
    const eventsColumns = {
        title: "Event",
        className: "no-pointer",
        width: "3rem",
        render: record => {
            return (
                <CalendarActions>
                    <ModelCalendarEvents
                        modelId={record.key}
                        modelEventsInfo={modelEventsInfo}
                        isFetched={modelsAvailability[record.key] && modelsAvailability[record.key].isFetched}
                        isBusy={modelsAvailability[record.key] && modelsAvailability[record.key].isBusy}
                    />

                </CalendarActions>
            )
        }
    }
    const nameColumn = {
        title: "Name",
        key: "name",
        width: isDrawer ? "12rem" : "16rem",
        render: (record) => {
            const isFetched = modelsAvailability[record.key] && modelsAvailability[record.key].isFetched;
            const isBusy = modelsAvailability[record.key] && modelsAvailability[record.key].isBusy;
            return (
                <NameCell
                    $mobileView={breakpoints === 1}
                    $status={isFetched ? isBusy ? 'busy' : 'no-busy' : ''}>
                    <FlexContainer className='name'>
                        <h1>{record.name.toLowerCase()}</h1>
                        {record.AL &&
                            <ContactAlertPopover contact={record} />
                        }
                    </FlexContainer>
                    {!archivedMode &&
                        <div className='detail'>
                            <ModelDetailsHeaderAgencies readOnly={multipleSelection} model={record} />
                        </div>
                    }
                    {isMobile &&
                        <>
                            {record.B &&
                                isBirthday(record.B)
                                ?
                                <AgeCellBirthday mark><NwIcon icon={light("birthday-cake")} />&nbsp;&nbsp;{getModelAge(record.B)}</AgeCellBirthday>
                                :
                                <AgeCell $smaller={isDrawer || isMobile}>{getModelAge(record.B)}</AgeCell>
                            }
                            <FeaturesContainer $smaller={isDrawer || isMobile}>
                                <MainFeatures contact={record} />
                            </FeaturesContainer>
                        </>
                    }
                </NameCell>
            )
        },
        onCell: (record) => {
            return {
                onClick: event => {
                    multipleSelection ? onSelectModel(record.key) : handleModelClick(record.key);
                }
            };
        }
    }
    const featuresColumn = {
        title: "Features",
        dataIndex: "features",
        key: "features",
        //width: "100%",
        width: "100%",
        render: (model) => {
            return (
                <>
                    {model.B &&
                        isBirthday(model.B)
                        ?
                        <AgeCellBirthday $smaller={isDrawer}><NwIcon icon={light("birthday-cake")} />&nbsp;&nbsp;{getModelAge(model.B)}</AgeCellBirthday>
                        :
                        <AgeCell $smaller={isDrawer}>{getModelAge(model.B)}</AgeCell>
                    }
                    <FeaturesContainer $smaller={isDrawer}>
                        <MainFeatures contact={model} />
                    </FeaturesContainer>
                </>
            )
        },
        onCell: (record) => {
            return {
                onClick: event => {
                    multipleSelection ? onSelectModel(record.key) : handleModelClick(record.key);
                }
            };
        }
    }
    const tagsColumn = {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        width: "8rem",
        className: "no-pointer",
        render: tags => (
            <TagsContainer>
                {(tags.length < 8)
                    ?
                    (tags.map((tag, index) => (
                        <NwTag type="dark" key={index}>{tag}</NwTag>
                    )))
                    :
                    <NwTag type="dark" pointer>{tags.length} tags</NwTag>
                }
            </TagsContainer>
        )
    }
    const packageColumn = {
        title: "PackageAction",
        width: "14rem",
        render: record => {
            return (
                <ModelsTableColumnPackage
                    currentPackage={currentPackage}
                    isDrawer={isDrawer}
                    model={record}
                    newlyAddedToPackage={newlyAddedToPackage}
                    onAddingBooksToPackage={handleAddBooksToPackage}
                    onRemovingBookFromPackage={handleRemoveBookFromPackage}
                    packageGroup={packageGroup}
                />
            )
        }
    }
    const eventColumn = {
        title: "EventAction",
        width: "100%",
        render: record => {
            return (
                <ModelsTableColumnEvent
                    availability={(singleDayEventAvailability && singleDayEventAvailability[record.ID]) ? singleDayEventAvailability[record.ID] : null}
                    isFetchingAvailability={isFetchingSingleDayEventAvailability}
                    currentEvent={currentEvent}
                    model={record}
                    scope={scope}
                    addModelsAs={addModelsAs}
                />
            )
        }
    }
    const nonBookingEventColumn = {
        title: "EventAction",
        width: "100%",
        render: record => {
            return (
                <ModelsTableColumnNonBookingEvent
                    availability={singleDayEventAvailability ? singleDayEventAvailability[record.ID] : null}
                    currentEvent={currentEvent}
                    model={record}
                    scope={scope}
                />
            )
        }
    }
    const archiveColumn = {
        title: "Action",
        width: "5rem",
        render: record => {
            if (accessManagement) {
                return (
                    <NwButton
                        style={{ fontSize: 24, height: 36 }}
                        ghost
                        icon={light('inbox-out')}
                        onClick={() => onUnarchiveModel(record.key)} />
                )
            } else {
                return(<p></p>)
            }
        }
    }
    const previewColumn = {
        title: 'Preview',
        align: 'center',
        width: "5rem",
        className: 'preview-trigger',
        render: record => {
            return (
                <NwIcon icon={light('sidebar-flip')} />
            )
        },
        onCell: (record, rowIndex) => {
            return {
                onClick: event => {
                    handleOpenModelDrawer(record.key);
                }
            };
        }
    }

    const columns = []

    //TODO: deal with responsive breakpoints
    switch (scope) {
        case "package":
            columns.push(thumbnailColumn);
            if (modelEventsInfo) {
                columns.push(eventsColumns);
            }
            columns.push(nameColumn);
            columns.push(featuresColumn);
            columns.push(packageColumn);
            break;
        case "archive":
            columns.push(thumbnailColumn);
            columns.push(nameColumn);
            columns.push(featuresColumn);
            columns.push(tagsColumn);
            columns.push(archiveColumn);
            break;
        case "event":
            nameColumn.width = "20rem";
            columns.push(thumbnailColumn);
            if (modelEventsInfo) {
                columns.push(eventsColumns);
            }
            columns.push(nameColumn);
            columns.push(eventColumn);
            break;
        case "nonbooking-event":
            nameColumn.width = "20rem";
            columns.push(thumbnailColumn);
            if (modelEventsInfo) {
                columns.push(eventsColumns);
            }
            columns.push(nameColumn);
            columns.push(nonBookingEventColumn);
            break;
        default:
            columns.push(thumbnailColumn);
            if (modelEventsInfo) {
                columns.push(eventsColumns);
            }
            columns.push(nameColumn);
            if (!isMobile) {
                columns.push(featuresColumn);
                columns.push(previewColumn);
            }
            break;
    }



    const rowSelection = {
        selectedRowKeys: selectedList,
        onChange: (selectedRowKeys, selectedRows) => {
            onSelect(selectedRowKeys)
        }
    };

    return (
        <>
            <StyledTable
                breakpoints={breakpoints}
                rowSelection={(multipleSelection && breakpoints > 3) ? rowSelection : null}
                dataSource={dataSource}
                columns={columns}
                size="middle"
                pagination={false}
                showHeader={false}
                tableLayout="fixed"
            />
            {isOpenModelDrawer &&
                <ModelDrawer
                    modelId={modelId}
                    hideCalendar={areaContext.area === 'scouting'}
                    showRating={areaContext.area === 'scouting'}
                    onClose={handleCloseModelDrawer}
                />
            }
            <div ref={loader}>
                {loadMore && <Loading textBlack small />}
            </div>
        </>
    )
};

export default ModelsTable;
