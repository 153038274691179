import React, { useContext } from "react";
import { Select } from 'antd';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';

const DocumentFilterSelector = ({ onChange, value, filterType, onBlur }) => {
    const { cachedList } = useContext(ListContext);

    const getOptions = () => {
        if ( filterType === "document" ) {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallDocumentsFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        } else if ( filterType === "playlist" ) {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallPlaylistsFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        } else {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallPublishFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        }
        return [];
    }

    return (
        <div>
            <FlexContainer mg='0 0 10px 0' column alignItems='flex-start'>
                <Select
                    autoComplete='off'
                    value={value}
                    style={{ display: 'block' }}
                    allowClear={false}
                    filterOption={false}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={getOptions()}
                />
            </FlexContainer>
        </div>
    );
};

export default DocumentFilterSelector;
