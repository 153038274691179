import React, { useContext, useState } from "react";
import { Alert } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useContactLegals } from 'Hooks/Contact/UseContactLegals';
import { NwAddButton } from "Components/Gui/NwButton";
import LegalForm from "Components/Legal/LegalForm";
import NwDrawer from "Components/Gui/NwDrawer";
import NwIcon from 'Components/Gui/NwIcon';
import LegalDocumentItem from 'Components/Legal/LegalItem';
import { getNow, showDateNoTime } from 'Libs/NwMoment';
//import { LEGAL_TYPES } from "Configs/LEGAL_TYPES";
import { ListContext } from 'Contexts/ListContext';
import ModelHeader from "Components/ContactDetails/ModelDetails/ModelHeader";

const AlertsContainer = styled.div`
    .ant-alert{
        margin-bottom: 8px;
    }
`;

const LegalDrawer = ({ selectedContact, readOnly, onClose, modelHeader }) => {
    const { status, data: legalDocuments } = useContactLegals(selectedContact)
    const [viewMode, setViewMode] = useState("LISTING"); // LISTING, VIEWING, INSERTING
    const [currentDoc, setCurrentDoc] = useState(null);
    const [touchForm, setTouchForm] = useState(false);
    const { cachedList } = useContext(ListContext);
    const legalTypes = cachedList.legalTypes.items;


    const handleCancelEdit = () => {
        setCurrentDoc(null);
        setViewMode("LISTING");
    };

    return (
        <NwDrawer
            onClose={onClose}
            title="Legal Documents">
            {modelHeader &&
                <ModelHeader modelID={selectedContact} />
            }
            {!readOnly && 
                <div className='drawer-toolbar-container'>
                    <NwAddButton
                        primary
                        onClick={() => {
                            setViewMode("INSERTING");
                        }}
                        label="Add new document"
                    />
                </div>
            }
            <AlertsContainer>
                {status !== "loading" && legalDocuments.map(document => {
                    const currentDate = getNow().hour(0).minute(0).second(0);
                    const diffDays = document.ExpirationDate && currentDate.diff(getMoment(document.ExpirationDate), 'days');
                    const documentName = legalTypes.find(el => el.Code.toLowerCase() === document.Type.toLowerCase()).Name;
                    return diffDays !== null &&
                        <div key={`alert-${document.ID}`}>
                            {diffDays >= 0 ? 
                                <Alert 
                                    showIcon
                                    icon={<NwIcon icon={light('alarm-clock')} />}
                                    message={`${documentName} expired on ${showDateNoTime(document.ExpirationDate)}`}
                                    type="error" />
                                : diffDays >= -30 &&
                                <Alert 
                                    showIcon
                                    icon={<NwIcon icon={light('exclamation-triangle')} />}
                                    message={`${documentName} expiring on ${showDateNoTime(document.ExpirationDate)}`}
                                    type="warning" />
                            }
                        </div>
                })}
            </AlertsContainer>
            <div className='drawer-content standard'>
                {status !== "loading" &&
                    legalDocuments.map(item => (
                        <LegalDocumentItem 
                            key={item.ID} 
                            document={item} 
                            readOnly={readOnly}
                            contactId={selectedContact}
                            onEditDoc={() => {
                                setCurrentDoc(item);
                                setViewMode("EDITING");
                            }} />
                    ))
                }
            </div>
            {viewMode === "INSERTING" && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add Legal Document'
                    onClose={handleCancelEdit}>
                    <LegalForm
                        ContactID={selectedContact}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
            {viewMode === "EDITING" && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Legal Document'
                    onClose={handleCancelEdit}>
                    <LegalForm
                        ContactID={selectedContact}
                        Doc={currentDoc}
                        onCancel={handleCancelEdit}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )}
        </NwDrawer>
    );
};

export default LegalDrawer;
