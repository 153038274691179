import React from 'react'

import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import EventBoxesModels from './EventBoxesModels';
import AddressLine from 'Components/Addresses/AddressLine';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import useCountryName from 'Hooks/UseCountryName';

const NonBookingEventDetails = ({
    event,
    isGrouped,
    page,
}) => {

    const serviceName = event.ServiceID ? getContactName(event.ServiceID) : '';
    const meetingContactName = event.ContactID ? getContactName(event.ContactID) : '';
    const countryName = useCountryName(event && event.Country);

    const EventDetails = () => {
        switch (event.type.toLowerCase()) {
            case "followup":
                return (
                    <>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-title`}>
                            <EventTypeTag event={event} type="FollowUp" />
                            <p>{event.Title}</p>
                        </div>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-description`}>
                            {/* <NwEllipsis noFontSize text={event.Description} rows={2} /> */}
                            <p>{event.Description}</p>
                            <p>{serviceName}</p>
                        </div>
                    </>
                )
            case "accommodation":
            case "roomlodging":
                return (
                    <>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-title`}>
                            <EventTypeTag event={event} type={event.RoomID ? "RoomLodging" : "Accommodation"} />
                            <p>{event.Title}</p>
                        </div>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-description`}>
                            <AddressLine address={event} disableMap />
                            {event.RoomID &&
                                <p>{event.OccupiedBeds ? `Occupied Beds : ${event.OccupiedBeds}, ` : ''}{event.Room ? `Price : ${event.Room.BedPrice}` : ''}</p>
                            }
                        </div>
                    </>
                )
            case "travel":
                return (
                    <>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-title`}>
                            <EventTypeTag event={event} type="Travel" />
                            <p>{event.Title}</p>
                        </div>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-description`}>
                            <p>{event.StartLocation} - {event.ArrivalLocation}</p>
                            <p><small>{event.Transport} {event.TicketID} {event.BookingReference}</small></p>
                        </div>
                    </>
                )
            case "meeting":
                return (
                    <>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-title`}>
                            <EventTypeTag event={event} type="Meeting" />
                            <p>{event.Title}</p>
                        </div>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-description`}>
                            <p>meeting with: {meetingContactName}</p>
                            <p className="address-line">
                                {event.Street && <span>{event.Street}</span>}
                                {event.Zip && <span>{event.Zip}</span>}
                                <span>{event.City}</span>
                                <span>{countryName}</span>
                            </p>
                        </div>
                    </>
                )
            case "extjob":
                return (
                    <>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-title`}>
                            <EventTypeTag event={event} type="ExtJob" />
                            <p>{`${event.ClientName} / ${event.ProductName}`}</p>
                        </div>
                        <div className={`event-${isGrouped ? 'in-project-' : ''}box-details-description`}>
                            <NwEllipsis noFontSize text={event.Description} rows={2} />
                            {event.Location && <p>Location: {event.Location}</p>}
                            {event.TimeSheet && <p>Timesheet: {event.TimeSheet}</p>}
                        </div>
                    </>
                )
            default:
                return <></>
        }
    }

    return (
        <>
            <EventDetails />
            {(page === "project" || isGrouped) &&
                <EventBoxesModels page={page} event={event} />
            }
        </>
    )
}

export default NonBookingEventDetails
