import React, { useState, useEffect } from 'react';

import MiniLoading from 'Components/Gui/MiniLoading';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { onGetOptionNumber } from 'Hooks/Event/UseEvent';

const OptionSelector = ({ eventId, modelId, dates, optionNumber, manual, onUpdateOptionNumber }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!optionNumber && !manual) {
            onCheckOptionNumber();
        }
    }, []);

    useEffect(() => {
        if (!eventId && !manual) {
            onCheckOptionNumber();
        }
    }, [JSON.stringify(dates)]);

    const onCheckOptionNumber = async () => {
        const data = {
            EventID: eventId,
            ModelID: modelId,
            Dates: dates
        }

        setLoading(true);
        try {
            const optionNumber = await onGetOptionNumber(data);
            onUpdateOptionNumber(optionNumber[0].SuggestedOptionNumber);
            setLoading(false);
        } catch (error) {
            console.log('ant : Update Option Number Error => ', error);
            setLoading(false);
        };
    };

    return (loading ? <MiniLoading />
        : 
        <RadioButtonGroup 
            value={optionNumber} 
            onChange={value => {
                if (value === 'auto') {
                    onCheckOptionNumber();
                } else {
                    onUpdateOptionNumber(value)
                }
            }} 
            size='small' 
            options={[
                { value: 10, label: 'job' },
                ...[...Array(5).keys()].map(key => (
                    { value: key + 1 , label: key + 1 }
                )),
                { value: 'auto', label: 'auto' }
            ]} />
    )
}

export default OptionSelector;