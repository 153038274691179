import React from 'react';
import styled from 'styled-components';

import Axios from 'axios';
import { EventModelNameLineNonBooking } from 'Components/EventDrawer/EventDrawerUi/EventModelNameLineNonBooking';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteTask, forceReloadTask, } from 'Hooks/Event/UseTask';
import { EventDetailsWrapper } from '../EventDrawerUi/EventUI';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';

const ModelInfo = styled.div`
`;

const TaskModelDetails = ({
    afterRemove,
    afterUpdate,
    event,
    onEditModelEvent,
    onUpdateTasks,
    onTouchForm,
    openedEventID,
    differentDescriptions,
}) => {

    const { mutateAsync: onDeleteToDo } = useDeleteTask();
    
    const handleRemoveEvent = async () => {
        try {
            await onDeleteToDo({ ID: event.ID, todoId: openedEventID.ID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleUnlinkEvent = async () => {
        try {
            await Axios.put(`/todos/UnLink/${event.ID}`)
            onUpdateTasks()
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    };

    const modelNameLineProps = {
        actions: {
            onEdit: onEditModelEvent,
            onDelete: (async () => {
                await handleRemoveEvent(event.ID)
                if (event.ID === openedEventID) {
                    afterRemove()
                } else {
                    onUpdateTasks()
                    afterUpdate()
                }
            }),
            onUnlink: (() => handleUnlinkEvent()),
            onStatusUpdate: (() => {
                onUpdateTasks()
                afterUpdate()
            })
        },
        eventProperties: {
            eventType: "ToDo",
            isSingle: false,
            hasExport: true
        },
        event: event
    }

    return (
        <>
            <ModelInfo>
                <EventModelNameLineNonBooking {...modelNameLineProps} />
            </ModelInfo>
            {differentDescriptions &&
                <EventDetailsWrapper>
                    <ResponsiveFlexContainer
                        leftMinWidth={140}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">description</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{event.Description}</p>
                                )
                            },
                        ]}
                    />
                </EventDetailsWrapper>
            }
            <EventCommentsDocuments
                event={event}
                api='ToDoID'
                type='ToDo'
                contactId={event.ModelID}
                drawerMode
                fetchEvent={onUpdateTasks}
                disableTransaction={!event.Project}
                onTransactionCreatedOrUpdated={onUpdateTasks}
                onTouchForm={onTouchForm} />
        </>
    );
};

export default TaskModelDetails;
