import React from "react";
import styled from "styled-components";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ContactAddressLine } from "Components/Gui/NwContactInfo";
import { useEditContactConnectionItemDefault, useIsContactConnectionItemDefault } from 'Hooks/Contact/UseContactConnections';

const DefaultItem = styled.div`
    font-weight: ${props => props.isDefault && 'bold'};
`;

const AddressItem = ({ address, readOnly, onEdit, onDelete, contactID }) => {
    const { mutate: onSetItemDefault } = useEditContactConnectionItemDefault()

    const setDefault = () => {
        onSetItemDefault({itemType: "address", idItem: address.ID, contactID: contactID})
    };

    const isDefault = useIsContactConnectionItemDefault("address", address.ID, contactID);

    let actions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: onEdit},
        { name: 'set as default', key: "default", icon: light("bookmark"), action: setDefault },
        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: onDelete}
    ];
    
    actions = actions.filter(action => !(action.key === 'default' && isDefault));
    
    return (
        <FlexContainer justifyContent='space-between'>
            <DefaultItem isDefault={isDefault}>
                <ContactAddressLine address={address} />
            </DefaultItem>
            {!readOnly && 
                <NwDropDownMenu
                    size='small'
                    actions={actions}
                />
            }
        </FlexContainer>
    );
};

export default AddressItem;
