import React from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { isAllDay, showDateNoTime, showDateNoTimeExtended, showTime } from 'Libs/NwMoment';
import { getTransportIcon } from 'Libs/NwUtils';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';


const TravelDetails = ({ travelsData }) => {

    const getTravelPeriod = () => {
        if (showDateNoTime(travelsData.StartDateTime) !== showDateNoTime(travelsData.ArrivalDateTime)) {
            return (
                <FlexContainer mg='8px 0 0'>
                    <div>
                        <div className='is-label'>DEPARTURE</div>
                        <div className='is-content date-time'>
                            {showDateNoTime(travelsData.StartDateTime)}
                        </div>
                        {!isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) &&
                            <div className='is-content date-time'>
                                {showTime(travelsData.StartDateTime)}
                            </div>
                        }
                    </div>
                    <NwIcon className='arrow-icon' icon={light('arrow-right')} />
                    <div>
                        <div className='is-label'>ARRIVAL</div>
                        <div className='is-content date-time'>
                            {showDateNoTime(travelsData.ArrivalDateTime)}
                        </div>
                        {!isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) &&
                            <div className='is-content date-time'>
                                {showTime(travelsData.ArrivalDateTime)}
                            </div>
                        }
                    </div>
                </FlexContainer>
                
            )
        }
        if (isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime)) {
            return (
                <>
                    <div className='date'>{showDateNoTimeExtended(travelsData.StartDateTime)}</div>
                    <div className='is-label'>ALL DAY</div>
                </>
            )
        }
        return (
            <>
                <div className='date'>{showDateNoTimeExtended(travelsData.StartDateTime)}</div>
                <FlexContainer mg='8px 0 0'>
                    <div>
                        <div className='is-label'>DEPARTURE</div>
                        <div className='is-content date-time'>{showTime(travelsData.StartDateTime)}</div>
                    </div>
                    <NwIcon className='arrow-icon' icon={light('arrow-right')} />
                    <div>
                        <div className='is-label'>ARRIVAL</div>
                        <div className='is-content date-time'>{showTime(travelsData.ArrivalDateTime)}</div>
                    </div>
                </FlexContainer>
            </>
        )
    }

    const isGloballyCancelled = () => {
        if (travelsData.Cancel) {
            if (travelsData.RelatedEvents && travelsData.RelatedEvents.length > 0) {
                if (travelsData.RelatedEvents.every(e => e.Cancel)) {
                    return true
                } else {
                    return false
                }
            }
            return true
        }
        return false
    }

    return (
        <EventDetailsWrapper className={isGloballyCancelled() ? 'is-cancelled' : null}>
            <ResponsiveFlexContainer
                leftMinWidth={180}
                columns={[
                    {
                        content: (
                            <div className='is-icon'>
                                <NwIcon icon={getTransportIcon(travelsData.Transport)} />
                                <p className="is-label">{travelsData.Transport}</p>
                            </div>
                        ),
                        isBorder: true
                    },
                    {
                        content: (
                            <>
                            {travelsData.Service &&
                                <>
                                    <p className="is-label">SERVICE</p>
                                    <p className='is-content'>{travelsData.Service.Name}</p>
                                    <br/>
                                </>
                            }
                            {getTravelPeriod()}
                            </>
                        ),
                        isBorder: false,
                    }
                ]}
            />
            {!travelsData.GroupGuid &&
                <>
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Ticket Number</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{travelsData.TicketID}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Booking Reference</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{travelsData.BookingReference}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Flight/Train Code</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{travelsData.TransportReference}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Description</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <div className="is-content small">
                                        <NwEllipsis rows={3} text={travelsData.Description} />
                                    </div>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                </>
            }
        </EventDetailsWrapper>

    )
};

export default TravelDetails;