import React from 'react';

import { EventModelNameLineNonBooking } from 'Components/EventDrawer/EventDrawerUi/EventModelNameLineNonBooking';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteAccommodation, useUnlinkAccommodation, forceReloadAccommodation } from 'Hooks/Event/UseAccommodation';
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';
import Axios from 'axios';

const AccommodationModelDetails = ({
    afterRemove,
    afterUpdate,
    event,
    onEditModelEvent,
    onTouchForm,
    onUpdateAccommodations,
    openedEventID,
    plainMode,
}) => {
    const { mutateAsync: onDeleteAccommodation } = useDeleteAccommodation();
    
    const handleRemoveEvent = async () => {
        try {
            await onDeleteAccommodation({ ID: event.ID, accommodationId: openedEventID })
            onUpdateAccommodations()
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleUnlinkEvent = async eventId => {
        try {
            await Axios.put(`/accommodation/UnLink/${event.ID}`)
            onUpdateAccommodations()
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    };

    const modelNameLineProps = {
        actions: {
            onEdit: onEditModelEvent,
            onDelete: (async () => {
                await handleRemoveEvent(event.ID)
                if (openedEventID === event.ID) {
                    afterRemove(true)
                } else {
                    afterUpdate()
                }
            }),
            onUnlink: (() => handleUnlinkEvent(event.ID)),
            onAcknowledgmentUpdate: (() => {
                onUpdateAccommodations()
                afterUpdate()
            }),
            onCancelUpdate: (() => {
                onUpdateAccommodations()
                afterUpdate()
            }),
        },
        eventProperties: {
            eventType: "Accommodation",
            isSingle: false,
            hasExport: true
        },
        event: event
    }

    return (
        <>
            <EventModelNameLineNonBooking {...modelNameLineProps} />
            <EventDetailsWrapper>
                <ResponsiveFlexContainer
                    columns={[
                        {
                            content: (
                                <p className="is-label">Reservation Code</p>
                            ),
                            isBorder: true,
                            width: "30%"
                        },
                        {
                            content: (
                                <p className="is-content">{event.ReservationCode}</p>
                            )
                        },
                    ]}
                />
                <ResponsiveFlexContainer
                    columns={[
                        {
                            content: (
                                <p className="is-label">Note</p>
                            ),
                            isBorder: true,
                            width: "30%"
                        },
                        {
                            content: (
                                <p className="is-content">{event.Note}</p>
                            )
                        },
                    ]}
                />
            </EventDetailsWrapper>
            <EventCommentsDocuments
                event={{
                    ...event,
                    Documents: event.Documents || []
                }}
                api='AccommodationID'
                type='Accommodation'
                contactId={event.ModelID}
                drawerMode={!plainMode}
                fetchEvent={onUpdateAccommodations}
                disableComments={true}
                disableTransaction={plainMode || !event.Project}
                onTransactionCreatedOrUpdated={onUpdateAccommodations}
                onTouchForm={onTouchForm}
            />
        </>
    );
};

export default AccommodationModelDetails;