import React, { useState } from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Comments from "Components/Comments/Comments";
import EventDocuments from "Components/EventDocuments/EventDocuments";
import NwUpload from "Components/Gui/NwUpload";
import { NwAddButton } from "Components/Gui/NwButton";
import EventTransactionsList from 'Components/Transactions/EventTransactionsList'; //,{ AddTransactionButton }
import NwDivider from 'Components/Gui/NwDivider';
//import AddNonBookingEventTransactionDrawer from 'Components/EventTransactions/AddNonBookingEventTransactionDrawer';

const EventDocumentsCommentsContainer = styled.div`
    width: 100%;
    border-bottom: ${props => props.theme.DefaultBorder};
    
    .event-buttons {
        height: 50px;        
        padding: 0 20px;
        border-top: ${props => props.theme.DefaultBorder};
        //border-bottom: ${props => props.theme.DefaultBorder};

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .content {
        padding: ${props => props.isContent ? '0 20px' : 0};
    }

    .ant-badge-count {
        margin-left: 8px;
        background-color: ${props => props.theme.PrimaryColor};
    }

    .ant-divider {
        cursor: pointer;
    }
`

export const EventCommentsDocuments = ({
    api,
    contactId,
    disableComments = false,
    disableDocuments = false,
    disableTransactions,
    drawerMode,
    event,
    extra,
    fetchEvent,
    onTouchForm,
    onTransactionCreatedOrUpdated,
    showOnlyActions,
    showOnlyContent,
    type,
}) => {
    const [createMode, setCreateMode] = useState(false);
    const [showTransactions, setShowTransactions] = useState(true);
    const [showComments, setShowComments] = useState(true);
    //const [transactionDrawer, setTransactionDrawer] = useState(false);

    const getUploadEndpoint = () => {
        let endpoint = '';
        switch (type) {
            case 'Event':
                endpoint = `documents/upload/1?eventID=${event.ID}`;
                break;
            case 'ExtJob':
                endpoint = `documents/upload/1?externalJobID=${event.ID}`;
                break;
            case 'FollowUp':
                endpoint = `documents/upload/1?followUpID=${event.ID}`;
                break;
            case 'ToDo':
                endpoint = `documents/upload/1?todoID=${event.ID}`;
                break;
            case 'Travel':
                endpoint = `documents/upload/1?travelID=${event.ID}`;
                break;
            case 'ScoutingTravel':
                endpoint = `documents/upload/1?scoutingTravelID=${event.ID}`;
                break;
            case 'Meeting':
                endpoint = `documents/upload/1?meetingID=${event.ID}`;
                break;
            case 'ScoutingMeeting':
                endpoint = `documents/upload/1?scoutingMeetingID=${event.ID}`;
                break;
            case 'Accommodation':
                endpoint = `documents/upload/1?accommodationID=${event.ID}`;
                break;
            case 'eventGroup':
                endpoint = `documents/upload/1?eventGroupGuid=${event.GroupGuid}`;
                break;
            default:
                break;
        }
        if (event.Project) {
            endpoint = endpoint + `&projectID=${event.Project.ID}`;
        }
        if (contactId) {
            endpoint = endpoint + `&contactID=${contactId}`;
        }
        return endpoint;
    };

    const onCreateComment = () => {
        fetchEvent(event.ID)
        setCreateMode(false)
    }

    const docsToRender = (type === 'eventGroup') ? event.GroupDocuments : event.Documents;
    const comments = event.Comments ? event.Comments : [];
    const transactions = event.Transactions ? event.Transactions : [];
    const isContent = (!disableDocuments && docsToRender.length)
        || (!disableComments && comments.length)
        || (!disableTransactions && transactions.length);

    const handleShowTransactions = () => {
        setShowTransactions(prevShow => !prevShow);
    };

    const handleShowComments = () => {
        setShowComments(prevShow => !prevShow);
    };

    return (
        <EventDocumentsCommentsContainer isContent={isContent}>
            {!showOnlyContent &&
                <div className="event-buttons">
                    {!disableDocuments &&
                        <NwUpload
                            ghost
                            label='add document'
                            maxSize={20}
                            showList={false}
                            types={["all"]}
                            onUploadEnd={fetchEvent}
                            endpoint={getUploadEndpoint()}
                        />}
                    {!disableComments &&
                        <NwAddButton
                            ghost
                            onClick={() => {
                                setCreateMode(true)
                            }}
                            label="add comment"
                            icon={solid("comment-plus")}
                        />
                    }
                    {extra}
                </div>
            }
            <div className='content'>
                {!disableTransactions && !showOnlyActions &&
                    <div className='event-detail-item'>
                        {transactions.length > 0 &&
                            <NwDivider dark onClick={handleShowTransactions} icon={showTransactions ? light('chevron-down') : light('chevron-right')}>Transactions<Badge count={transactions.length} /></NwDivider>
                        }
                        {showTransactions &&
                            <EventTransactionsList
                                event={event}
                                drawerMode={drawerMode}
                                onTransactionCreatedOrUpdated={onTransactionCreatedOrUpdated} />
                        }
                    </div>
                }
                {!disableDocuments && !showOnlyActions &&
                    <div className='event-detail-item'>
                        <EventDocuments showDocumentsByDefault isEvent event={event} type={type} updateEvent={fetchEvent} />
                    </div>
                }
                {!disableComments &&
                    <div className='event-detail-item'>
                        {comments.length > 0 && !showOnlyActions &&
                            <NwDivider dark onClick={handleShowComments} icon={showComments ? light('chevron-down') : light('chevron-right')}>
                                Comments<Badge count={comments.length} />
                            </NwDivider>
                        }
                        {(showComments || createMode) &&
                            <Comments
                                isLineCard
                                isPaginated={false}
                                api={api}
                                disableVisibility
                                drawerMode={drawerMode}
                                comments={(showComments && event.Comments) ? event.Comments : []}
                                onUpdate={onCreateComment}
                                afterCommentsCreation={() => setCreateMode(false)}
                                ID={event.ID}
                                isEvent
                                contactId={contactId}
                                hideComments={showOnlyActions}
                                createComments={createMode}
                                onTouchForm={onTouchForm}
                            />
                        }
                    </div>
                }
            </div>
            
        </EventDocumentsCommentsContainer>
    )
};
