import React, { useState, useEffect } from 'react';
import { Alert, message, Tooltip } from 'antd';
import { Formik } from "formik";
import styled from 'styled-components';
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDrawer from "Components/Gui/NwDrawer";
import SendAgendaDrawerForm from 'Components/SendAgendaDrawer/SendAgendaDrawerForm';
import Recipients from 'Components/SendAgendaDrawer/Recipients';
import SendAgendaMailPreview from 'Components/SendAgendaDrawer/SendAgendaMailPreview';
import { Loading } from 'Components/Gui/Loading';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { showDateNoTime } from 'Libs/NwMoment';
import { useContact, name } from "Hooks/Contact/UseContact";
import { getSendAgendaRange } from 'Components/Schedule/ScheduleUtils';
import StyleVariables from 'Components/Gui/StyleVariables';

const Wrapper = styled.div`
    .ant-legacy-form-item {
        margin-bottom: 8px;
    }
    .send-agenda-wrapper {
        display: ${props => props.$useTwoColumns ? 'flex' : 'block'};
        justify-content: space-between;
        .send-agenda-left {
            width: ${props => props.$useTwoColumns ? '40%' : '100%'};
            margin-right: ${props => props.$useTwoColumns ? '2rem' : '0'};
        }
        .send-agenda-right {
            width: ${props => props.$useTwoColumns ? '60%' : '100%'};
        }
    }
`;

const LoadingContainer = styled.div`
    position: absolute;
    position: absolute;
    right: 0;
    left: 0;
    top: 40%;
    z-index: 2000;
`;

const DrawerContent = styled.div`
    opacity: ${props => props.isSending && 0.4};
`;

const SendAgendaDrawer = ({ scheduleRange, contactID, warning, onClose }) => {
    
    const [docVersion, setDocVersion] = useState();
    const [docVersionID, setDocVersionID] = useState(0);
    // const [loading, setLoading] = useState(false);
    const loading = false;
    const [isSending, setIsSending] = useState(false);
    const [recipientsData, setRecipientsData] = useState([]);
    const { data: currentContact } = useContact(contactID)
    const [docversionHtml, setDocversionHtml] = useState('');
    const [eventDateMap, setEventDateMap] = useState({});
    const [acknowledged, setAcknowledged] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const [title, setTitle] = useState('');
    const [selectedSignature, setSelectedSignature] = useState(null);
    const [errorSending, setErrorSending] = useState(null);
    
    const sendAgendaRange = getSendAgendaRange(scheduleRange)

    const [filters, setFilters] = useState({
        ExcludeAcknowledged: true,
        ExcludeCancelled: true,
        ExcludedEvents: [], //{ "Type": "Casting", "ID": 0 }
        ExcludeOptions: true,
        FDate : sendAgendaRange.start,
        InclAccommodations: true,
        InclCastings: true,
        InclExternalJobs:true,
        InclFollowUps:true,
        InclJobs: true, 
        InclMeetings:false,
        InclNotices: true,
        InclTravels:true,
        IncludedEventSubTypes: [],
        ModelID: contactID,
        TDate: sendAgendaRange.end,
        //UpdateDocVersionID: 0 -- it's set outside filter to prevent useless calls to API
    });


    // useEffect(() => {
    //     if (scheduleRange) {
    //         const sendAgendaRange = getSendAgendaRange(scheduleRange)
    //         if (sendAgendaRange.start !== filters.FDate || sendAgendaRange.end !== filters.TDate) {
    //             console.log("use effect su scheduleRange")
    //             console.log("start", sendAgendaRange.start, filters.FDate)
    //             console.log("end", sendAgendaRange.end, filters.TDate)
    //             setFilters({
    //                 ...filters,
    //                 FDate: sendAgendaRange.start,
    //                 TDate: sendAgendaRange.end
    //             })
    //         }
    //     }
    // }, [scheduleRange]);

    useEffect(() => {
        if (filters.FDate && filters.TDate && currentContact) {
            let tp = `Schedule for ${name(currentContact)}, ${showDateNoTime(filters.FDate)}` 
            if (getMoment(filters.TDate).isAfter(getMoment(filters.FDate),"day")) {
                tp += ` - ${showDateNoTime(filters.TDate)}`
            }
            setTitle(tp)
            onCreateDocversion();
        }
    }, [filters, currentContact]);

    const onCreateDocversion = async () => {  
        try {
            const filtersToPost = { ...filters }
            if (docVersionID) {
                filtersToPost.UpdateDocVersionID = docVersionID;
            }
            const docversionresponse = await Axios.post(`/calendar/ModelDocVersion?area=Calendar&template=cha1`, filtersToPost);
            if (!docVersionID)
            {
                setDocVersionID(docversionresponse.ID);
            }
            setDocVersion(docversionresponse);
        } catch (error) {
            console.log('ant : Create Docversion Error => ', error);
        }
    };
    
    const initialValues = {
        Subject: title,
        Description: '',
        SignatureID: null,
    };

    const handleUpdateRecipientsData = (newRecipientsData) => {
        setRecipientsData(newRecipientsData);
    };

    const handleChangeAcknowledged = acknowledged => {
        setAcknowledged(acknowledged);
    };

    const onSendAgenda = async (values, setSubmitting) => {
        setErrorSending(null);
        if (docVersion) {
            let AcknowledgeOnSend = [];
            if (acknowledged) {
                let events = {};
                for(const eventDate of Object.keys(eventDateMap)) {
                    for (const event of eventDateMap[eventDate]) {
                        if (!events[event.eventType]) {
                            events[event.eventType] = (event.eventType === "Casting" || event.eventType === "Job") ? [event.eventdatemodelid] : [event.eventId];
                        } else {
                            events[event.eventType].push((event.eventType === "Casting" || event.eventType === "Job") ? event.eventdatemodelid : event.eventId); 
                        }
                    }
                }

                let eventsMap = {};
                for(const eventType of Object.keys(events)) {
                    if (eventType === "Casting" || eventType === "Job") {
                        eventsMap.CheckEvents = eventsMap.CheckEvents ? [...eventsMap.CheckEvents, ...events[eventType]] : [...events[eventType]];
                    } else if (eventType === "Travel") {
                        eventsMap.CheckTravels = [...events[eventType]];
                    } else if (eventType === "FollowUp") {
                        eventsMap.CheckFollowUps = [...events[eventType]];
                    }
                }
                
                AcknowledgeOnSend = Object.keys(eventsMap).map(eventType => {
                    return {
                        Action: eventType,
                        Data: {Events: eventsMap[eventType]}
                    };
                });
            }

            const data = [
                {
                    DocVersionGUID: docVersion.DocGuid,
                    SendMethod: "SendGrid",
                    Subject: values.Subject,
                    AsAttachment: false,
                    Message: values.Description,
                    SignatureID: values.SignatureID,
                    //ActionsAfter: AcknowledgeOnSend
                    ActionsBefore: AcknowledgeOnSend
                }
            ];

            data[0].Recipients = [...recipientsData];

            setSubmitting(true);
            setIsSending(true);
    
            try {
                const htmlContent = docVersion.Html
                
                let replacedhtml = htmlContent
                replacedhtml = replacedhtml.replace('{{Title}}',values.Subject)
                replacedhtml = replacedhtml.replace('{{Message}}',values.Description)
                
                await Axios.put('/docVersions/UpdateHtml', {
                    ID: docVersion.ID,
                    Html: replacedhtml
                });
                
                await Axios.post('/docVersions/sendmessage', data);
                setTouchForm(false);
                setSubmitting(false);
                setIsSending(false);
                onClose();
            } catch (error) {
                setSubmitting(false);
                setIsSending(false);
                setErrorSending("There was an error sending the message");
                console.log('ant : Send Agenda Error => ', error);
                //onClose();
            }
        }
    }

    // const options = (values) => {
    //     return ({
    //         replace: ({ attribs, children }) => {
    //             if (!attribs) return;
    //             if (attribs.class === 'message-placeholder') {
    //                 return (
    //                     <span className="message-placeholder">{values.Description.length === 0 ? '' : values.Description}</span>
    //                 )
    //             }
    //             if (attribs.class === 'title-placeholder') {
    //                 return (
    //                     <span className="title-placeholder">{values.Subject.length === 0 ? '' : values.Subject}</span>
    //                 )
    //             }
    //         }
    //     })
    // };

    
    const SendButton = ({ values, isSubmitting }) => {
        let invalidMessage = '';
        if (recipientsData.length === 0) {
            invalidMessage += 'Please select at least one recipient';
        }
        if (!values.Subject) {
            invalidMessage += invalidMessage.length > 0 ? ' and enter a subject line for the e-mail' : 'Please write a subject line for the e-mail';
        }
        if (invalidMessage.length > 0) {
            return (
                <Tooltip 
                    placement="left"
                    title={invalidMessage}
                    color={StyleVariables.DangerColor}
                    overlayInnerStyle={{fontSize: StyleVariables.FontSizeSm, maxWidth: '20rem'}}
                >
                    <NwSaveButton
                        htmlType="submit"
                        icon={light('paper-plane')}
                        label='Send' 
                        disabled
                    />
                </Tooltip>
            )
        }
        return (
            <NwSaveButton
                htmlType="submit"
                icon={light('paper-plane')}
                label='Send' 
                disabled={isSubmitting}
                loading={isSubmitting}
            />
        )
    }

    const useTwoColumns = window.innerWidth > 1300;

    return (
        <NwDrawer 
            touchForm={touchForm} 
            onTouchForm={setTouchForm}
            title="Send Agenda"
            onClose={onClose}
            width="94vw"
            maxWidth="1600px"
            id='send-agenda-drawer'>
            {!currentContact && loading ? <Loading /> :
                <>
                    {isSending &&
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    }
                    <DrawerContent isSending={isSending}>
                        <Wrapper $useTwoColumns={useTwoColumns}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    onSendAgenda(values, setSubmitting);
                                }}
                            >
                            {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                                <NwForm 
                                    values={values}
                                    onTouchForm={setTouchForm}
                                    onFinish={handleSubmit} 
                                    //layout="vertical"
                                    >
                                    <div className="send-agenda-wrapper">
                                        <div className="send-agenda-left">
                                            <SendAgendaDrawerForm
                                                twoColumns={useTwoColumns}
                                                modelId={contactID} 
                                                values={values}
                                                filters={filters}
                                                title={title}
                                                onUpdateFilters={setFilters}
                                                setFieldValue={setFieldValue}
                                                acknowledged={acknowledged}
                                                onChangeAcknowledged={handleChangeAcknowledged}
                                                onSelectSignature={(value) => setSelectedSignature(value)}
                                            />
                                            {useTwoColumns &&
                                            <Recipients 
                                                contactID={contactID}
                                                recipientsData={recipientsData}
                                                onUpdateRecipientsData={handleUpdateRecipientsData}
                                            />
                                            }
                                        </div>
                                        <div className="send-agenda-right">
                                            {docVersion
                                            ?
                                            <SendAgendaMailPreview
                                                twoColumns={useTwoColumns}
                                                docVersionID={docVersion.ID}
                                                htmlContent={docVersion.Html} 
                                                message={values.Description}
                                                signature={selectedSignature}
                                                title={values.Subject}
                                                eventDateMap={eventDateMap}
                                                filters={filters}
                                                onUpdateFilters={setFilters}
                                                onUpdateDocversion={setDocversionHtml}
                                                onUpdateEventDateMap={setEventDateMap} />
                                            :
                                            <Loading textBlack/>
                                            }
                                            {!useTwoColumns &&
                                                <Recipients 
                                                    contactID={contactID}
                                                    recipientsData={recipientsData}
                                                    onUpdateRecipientsData={handleUpdateRecipientsData}
                                                />
                                            }
                                        </div>
                                    </div>
                                    {errorSending &&
                                        <Alert style={{marginTop: "1rem"}} message="Error" description={errorSending} type="error" showIcon />
                                    }
                                    <NwFormButtonsBar
                                        marginTop="2rem"
                                        left={
                                            <NwCancelButton
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    setTouchForm(false);
                                                    onClose();
                                                }}
                                            />
                                        }
                                        right={<SendButton values={values} isSubmitting={isSubmitting} />}
                                    />
                                </NwForm>
                            )}
                            </Formik>
                            
                        </Wrapper>
                    </DrawerContent>
                </>
            }
        </NwDrawer>
    );
};

export default SendAgendaDrawer;