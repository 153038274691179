import React from "react";
import { Modal } from "antd";

const { confirm } = Modal;

const NwModal = ({
    touchForm,
    children,
    onCancel,
    ...props
}) => {
    const handleClose = () => {
        if (touchForm) {
            confirm({
                title: <div>You can lose your inputs<br/>Are you sure to close the modal?</div>,
                onOk() {
                    onCancel();
                },
                onCancel() {},
            });
        } else {
            onCancel();
        }
    }

    return (
        <Modal
            onCancel={handleClose}
            {...props}
        >
            {children}
        </Modal>
    )
};

export default NwModal;