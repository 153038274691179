import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { showDateNoTime } from 'Libs/NwMoment';
import FlexContainer from 'Components/Gui/FlexContainer';

const Container = styled.div`
    font-size: ${props => props.plainMode ? '1.2em' : '1em'};
    width: 100%;

    margin-left: 3rem;
    min-height: ${props => props.hasTravelPlan ? '7.5rem' : '2rem'};

    /* .icon {
        margin: 0 1rem;
        font-size: 1rem;
    } */

    .travel-plan-details {

        cursor: pointer;
        padding-right: 1rem;
        flex: 1 0 0;
        text-overflow: ellipsis;

        small {
            font-size: .75rem;
            line-height: 90%;
        }

        h1 {
            font-weight: bold;
            font-size: 1.2em;
            text-transform: uppercase;
            margin: 0;
            line-height: 1.1em;
        }
        p {
            margin: 0;
            font-size: .9rem;
            line-height: 1.1em;
        }
    }
`

const Left = styled.div`
    text-transform: uppercase;
`

const Right = styled.div`
    font-size: .9rem;
`

const TravelPlanCard = ({
    travelPlan,
    type,
    event,
    history,
    plainMode,
    editable,
}) => {
    const getPeriod = () => {
        let period = "";
        if (travelPlan.FirstDate) {
            period += showDateNoTime(travelPlan.FirstDate);
        }
        if (travelPlan.LastDatetravelPlan) {
            period += (period ? " > " : "") + showDateNoTime(travelPlan.LastDate);
        }
        return (period || "no period defined")
    }

    return (
        <>
            {plainMode ?
                <Container hasTravelPlan={false} plainMode>
                    {travelPlan ?
                        <>
                            <FlexContainer>
                                <div className="travel-plan-details">
                                    <p>{travelPlan.Name}</p>
                                    <p>{getPeriod()}</p>
                                </div>
                            </FlexContainer>
                        </>
                        : <FlexContainer>
                            <div>no travel plan</div>
                        </FlexContainer>
                    }
                </Container>
                :
                <Container>
                    <NwCard
                        size="small"
                        fullwidth
                        icon={light("clipboard-list-check")}
                        title={travelPlan.Name}>
                        <Container>
                            <Left>{travelPlan.Name}</Left>
                            <Right>{getPeriod()}</Right>
                        </Container>
                    </NwCard>
                </Container>
            }
        </>
    );
};

export default withRouter(TravelPlanCard);