import React, { useState, useEffect } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from "styled-components";
import { Card } from 'antd';

import { NwAddButton } from 'Components/Gui/NwButton';
import TaskCard from 'Components/TaskList/TaskCard';
import FlexContainer from 'Components/Gui/FlexContainer';
import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import { useStateValue } from 'Contexts/StateProvider';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { Loading } from 'Components/Gui/Loading';
import { NwCard, NwCardContent } from 'Components/Gui/NwCard/NwCard';
import EmptyState from 'Components/Gui/EmptyState';

const Container = styled.div`
    .ant-card-body-content {
        padding: 0 !important;
    }

    .ant-card-grid {
        float: left; //added because ant removed it to use flex- to update later
        height: 270px;
        padding: 0;
        width: 50%;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .nw-card-paginated-content {
        padding: 0 !important;
    }
    .ant-row-space-between {
        flex-direction: row-reverse;
    }
`;

const ProjectTodos = ({ projectId, currentProject  }) => { 
    
    const [addDrawer, setAddDrawer] = useState(false);
    const [{areaContext}] = useStateValue();
    
    //const { data: currentProject, isFetching } = useProject(projectId);
    const isFetching = false;

    const [projectTasks, setProjectTasks] = useState([])

    useEffect(() => {
        if (!isFetching && currentProject) {
            //const te = onGetOrderedEventList(currentProject, areaContext, false, false)
            //setProjectEvents(te)

            const tds = currentProject.ToDos.filter(todo => {
                if (todo.Visibility.Areas.length === 0 || todo.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            })
            setProjectTasks(tds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject, isFetching])

    const handleOpenAddTodoDialog = () => {
        setAddDrawer(true);
    };

    const handleCloseAddDrawer = () => {
        setAddDrawer(false);
    }

    const onReloadTodos = () => {
        forceReloadProject(projectId)
    }

    // const renderTodos = () => {
    //     if (!projectTasks || !projectTasks.length) return (null);
    //     let filteredTasks = projectTasks;
    //     filteredTasks.sort((a, b) => {
    //         if (a.GlobalStatus === b.GlobalStatus) return 0;
    //         if (a.GlobalStatus === "Completed") return 1;
    //         if (b.GlobalStatus === "Completed") return -1;
    //         if (a.GlobalStatus === "PartiallyCompleted") return 1;
    //         return -1;
    //     });
    //     return (
    //         <Row className="content" wrap={true}>
    //             {filteredTasks.length > 0 && filteredTasks.map((todo, index) => {
    //                 return (
    //                     <Col className='todo-item' key={index} lg={8} md={12} sm={24} xs={24}>
    //                         <TodoItem 
    //                             showAll={true}
    //                             todoItem={todo} 
    //                             todoType={'public'}
    //                             projectId={projectId} 
    //                             onUpdate={onReloadTodos} />
    //                     </Col>
    //                 );
    //             })}
    //         </Row>
    //     );
    // }
    
    return (
        <Container>
            {isFetching?<Loading/>:
                <NwCard
                    title="Tasks"
                    icon={light("tasks")}
                    extra={
                        <FlexContainer>
                            <NwAddButton ghost size="small" label='new task' onClick={handleOpenAddTodoDialog}/>
                        </FlexContainer>
                    }>
                    <NwCardContent
                        // pagination={{ total: todosList.length, current: activePage, pageSize: itemsPerPage, onChange: (page) => setActivePage(page), showSizeChanger: false }}
                    >
                        <div>
                            {projectTasks && projectTasks.sort((a, b) => {
                                if (a.GlobalStatus === b.GlobalStatus) return 0;
                                if (a.GlobalStatus === "Completed") return 1;
                                if (b.GlobalStatus === "Completed") return -1;
                                if (a.GlobalStatus === "PartiallyCompleted") return 1;
                                return -1;
                            }).map((todo, index) => {
                                return (
                                    <Card.Grid key={index}>
                                        <TaskCard 
                                            showAll={true}
                                            todoItem={todo} 
                                            todoType={'public'}
                                            projectId={projectId} 
                                            onUpdate={onReloadTodos}
                                        />
                                    </Card.Grid>
                                )
                            })}
                            {(!projectTasks || !projectTasks.length) && <EmptyState message="No data" />}
                        </div>
                    </NwCardContent>
                </NwCard>
            }
            {addDrawer &&
                <EventDrawer 
                    viewMode='create'
                    onClose={handleCloseAddDrawer}
                    afterUpdate={onReloadTodos}
                    eventType="todo"
                    projectId={projectId}
                />
            }
        </Container>
    );
};

export default ProjectTodos;