import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';

const useCountryName = (countryCode) => {
    const { cachedList } = useContext(ListContext);
    if (!countryCode) {
        return '';
    }
    const country = cachedList.countries.items.find(country => country.Code === countryCode);
    if (!country) {
        return countryCode;
    }
    return country.Name
};

export default useCountryName;