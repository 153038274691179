import React, { useState } from 'react';
import { List, Radio } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import ConversionModal from 'Components/ConversionModal';

const ConversionSelector = ({ value, onChange, conversions}) => {
  const [conversion, setConversion] = useState();
  const handleSetConversion = conversion => () => {
    onChange(conversion.ID);
  };

  const handleCloseModal = () => {
    setConversion();
  };

  return (
    <>
      <List
        bordered
        size='small'
        dataSource={conversions}
        renderItem={conversion => (
          <List.Item actions={[<NwButton size="small" iconOnly icon={light('eye')} onClick={() => setConversion(conversion)} />]}>
            <Radio checked={value === conversion.ID} onClick={handleSetConversion(conversion)}>{conversion.Name}</Radio>
          </List.Item>
        )}
      />
      {conversion && <ConversionModal conversion={conversion} onClose={handleCloseModal} />}
    </>
  );
};

export default ConversionSelector;