import React, { useState } from 'react';

import SearchProject from 'Components/ProjectAddSelectForm/SearchProject';
import ProjectsCustomers from 'Components/ProjectAddSelectForm/ProjectsCustomers';
import AddProjectForm from 'Components/ProjectAddSelectForm/AddProjectForm';
import CreateCustomerForm from "Components/ContactCreator/CreateCustomerForm";
import NwDrawer from 'Components/Gui/NwDrawer';

const ProjectAddSelectForm = ({ type, searchOnly, searchMode, showSecondLevel, drawerPlacement, touchForm, onSelectProject, onTouchForm }) => {
    const [projects, setProjects] = useState([]);
    const [filter, setFilter] = useState('');
    const [customer, setCustomer] = useState();
    const [addCustomer, setAddCustomer] = useState(false);
    const [customerName, setCustomerName] = useState("");

    const handleAddCustomer = (customername) => {
        setAddCustomer(true);
        if (customername) {
            setCustomerName(customername);
        }
    };

    const handleCustomerCreated = customer => {
        setCustomer(customer);
        setAddCustomer(false);
        setCustomerName("");
    };
    
    const handleCloseTouchForm = () => {
        if (touchForm && onTouchForm) {
            onTouchForm(false);
        }
    }

    const handleCloseCustomerCreationForm = () => {
        setAddCustomer(false);
        setCustomerName("");
        handleCloseTouchForm();
    }
    

    const renderAddProjectForm = () => {
        return (
            <AddProjectForm 
                customer={customer}
                type={type}
                onSelectProject={onSelectProject} 
                onTouchForm={onTouchForm}
                onCancel={() => {
                    setCustomer();
                    if (touchForm && onTouchForm) {
                        onTouchForm(false);
                    }
                }} />
        )
    }

    return (
        <>
            {(customer || addCustomer) && 
                <>
                    {showSecondLevel ? 
                        <NwDrawer
                            touchForm={touchForm}
                            onTouchForm={onTouchForm}
                            placement={drawerPlacement}
                            title={customer ? 'Add Project' : 'Add New Customer'}
                            onClose={() => {
                                if (customer) {
                                    setCustomer();
                                }
                                if (addCustomer) {
                                    setAddCustomer(false);
                                    setCustomerName("");
                                }
                                handleCloseTouchForm();
                            }}>
                            {customer ? renderAddProjectForm() : 
                                <CreateCustomerForm 
                                    onCustomerCreated={handleCustomerCreated} 
                                    onTouchForm={onTouchForm}
                                    onCancel={handleCloseCustomerCreationForm}
                                    customerName={customerName} 
                                />    
                            }
                        </NwDrawer>
                        : 
                        <>
                            {customer ? renderAddProjectForm() : 
                                <CreateCustomerForm 
                                    onCustomerCreated={handleCustomerCreated} 
                                    onCancel={handleCloseCustomerCreationForm}
                                    onTouchForm={onTouchForm} />    
                            }
                        </>
                    }
                </>
            }
            {(showSecondLevel || (!customer && !addCustomer)) &&
                <>
                    <SearchProject 
                        filter={filter} 
                        projects={projects}
                        searchOnly={searchOnly}
                        onSearchedProjects={setProjects} 
                        onChangeFilter={setFilter}
                        searchMode={searchMode} />        
                    <ProjectsCustomers 
                        searchOnly={searchOnly}
                        projects={projects}
                        term={filter}
                        onSelectCustomer={setCustomer}
                        onAddCustomer={handleAddCustomer}
                        onSelectProject={onSelectProject} 
                        searchMode={searchMode} /> 
                </>
            }
        </>
    );
};

export default ProjectAddSelectForm;