import React, { useContext } from "react";
import { ListContext } from "Contexts/ListContext";
import { showLogNoTime } from "Libs/NwMoment";
import useDepartmentName from "Hooks/UseDepartmentName";
import { getUserName } from "Libs/NwUsers";

const AuthorAndDepartment = ({ object, textBefore }) => {
    const { cachedList, cachedListLoaded } = useContext(ListContext)
    const deptID = object.DepartmentID || object.DepartmentCreated || null
    const netAgID = object.NetworkAgencyID || object.NetworkAgencyCreated || null
    const deptname =  useDepartmentName(deptID, netAgID)
    const textPrefix = textBefore ? textBefore : "by"
    

    const getCreationDate = () => {
        if (object.DateCreated) {
            return `, ${showLogNoTime(object.DateCreated)}`
        }
        return ""
    }

    return (
        cachedListLoaded 
        ?
            <>{textPrefix} {getUserName(object.UserCreated, cachedList.users.items)} @ <strong>{deptname}</strong>{getCreationDate()}</>
        :
            <></>
    )
};

export default AuthorAndDepartment;
