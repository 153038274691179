import { Calendar } from "antd";
import styled from "styled-components";

export const StyledCalendar = styled(Calendar)`
    margin-top: 2px !important;
    margin-bottom: ${(props) => props.$mobileView && "2rem"};
    .ant-picker-panel {
        .ant-picker-body {
            tr {
                border-bottom: ${(props) => props.theme.DefaultBorder};

                td.ant-picker-cell {
                    vertical-align: top;
                    padding: 0;
                    position: relative;
                    line-height: 1.25rem;

                    .ant-picker-cell-inner {
                    }

                    &.ant-picker-cell-in-view {
                        &.ant-picker-cell-today {
                            .ant-picker-cell-inner {
                                &::before {
                                    border: none;
                                }
                            }
                        }
                    }

                    .ant-picker-calendar-date {
                        border-radius: 0;
                        line-height: 1.3em;

                        .ant-picker-calendar-date-value {
                            min-height: 1.6rem;
                            margin-bottom: .4rem;
                        }


                        .ant-picker-calendar-date-content {
                            line-height: 1.1em;
                            height: ${(props) =>
                                props.$mobileView ? "0" : "2rem"};
                            overflow: hidden;
                            background-color: white;
                        }

                        &.ant-picker-calendar-date-today {
                            .ant-picker-calendar-date-value {
                                font-weight: bold;
                                box-shadow: none;
                                background-color: transparent;
                                border: none;
                            }
                        }
                    }

                    &.ant-picker-cell-selected {
                        .ant-picker-calendar-date-value {
                            font-weight: bold;
                            box-shadow: none;
                            background-color: ${(props) =>
                                props.theme.PrimaryColor};
                        }
                    }
                }
            }
        }
    }
`;

export const StyledMobileCalendar = styled(Calendar)`
    padding: 4px 2rem 4px 4rem;
    position: fixed;
    background-color: white;
    z-index: 1000;

    .guWhpY .ant-picker-panel .ant-picker-date-panel .ant-picker-body table.ant-picker-content tr {
    border-bottom: 1px solid #e8e8e8;
}

    .ant-picker-panel {
        .ant-picker-date-panel {
            .ant-picker-body {
                padding: 8px 0 0 0;
                
                table.ant-picker-content {
                    height: 200px;
                    tr {
                        border-bottom: ${(props) => props.theme.DefaultBorder};

                        &:last-child {
                            border-bottom: none;
                        }

                        td.ant-picker-cell {
                            vertical-align: top;
                            padding: 3px 0;
                            position: relative;
                            line-height: 1.1em;
                            
                            &:before {
                                display: none;
                            }

                            .ant-picker-cell-inner {
                                border-radius: 50%;
                                padding-top: 2px;
                            }

                            &.ant-picker-cell-in-view {
                                &.ant-picker-cell-today {
                                    .ant-picker-cell-inner {
                                        border: 1px solid ${(props) => props.theme.PrimaryColor};
                                        &::before {
                                            border: none;
                                        }
                                    }
                                }
                            }

                            .ant-picker-calendar-date {
                                line-height: 1.3em;
                                .ant-picker-calendar-date-content {
                                    display: none;
                                }

                                /* &.ant-picker-calendar-date-today {
                                    .ant-picker-calendar-date-value {
                                        box-shadow: none;
                                        background-color: transparent;
                                    }
                                } */
                            }

                            &.ant-picker-cell-selected {
                                .ant-picker-calendar-date-value {
                                    font-weight: bold;
                                    box-shadow: none;
                                    color: #fff !important;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const CalendarHeaderContainer = styled.div`
    padding: 10px;
    height: 46px;

    display: flex;
    .ant-btn-group {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-select-selection {
            border-radius: 0;
        }
    }
`

export const MobileCalendarHeaderContainer = styled.div`
    padding: 1.5rem .5rem 0 .5rem;
    height: auto;

    display: flex;
    justify-content: flex-end;
    .ant-btn-group {
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-select-selection {
            border-radius: 0;
        }
    }
`

export const BadgesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    line-height: 10px;
    gap: 3px;
    padding-top: 2px;
    //min-height: 2.1rem;
`;

export const StyledBadge = styled.div`
    height: auto;
    line-height: 0;
    .ant-badge.ant-badge-status {
        .ant-badge-status-dot {
            width: 8px;
            height: 8px;
            margin: 0;
        }
    }
`;