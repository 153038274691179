import React from 'react';
import styled from 'styled-components';
import { List, Tag } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import { showDateNoTime } from 'Libs/NwMoment';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import StyleVariables from 'Components/Gui/StyleVariables';


const StyledListItem = styled(List.Item)`
    .ant-list-item-meta {
        align-items: flex-start;
        .ant-list-item-meta-avatar {
            padding-right: .25rem 2rem 0 0;
            min-width: 10rem;
            .agency-type {
                text-align: center;
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeSm};
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .agency-type-line {
                    margin-bottom: .25rem;

                    .ant-tag {
                        font-size: ${props => props.theme.FontSizeXSm};
                        padding: 0 .25rem;
                        line-height: 1.5;
                        margin-right: 0;
                    }
                }
            }
        }
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                .agency-title-container {
                    text-transform: capitalize;
                    cursor: pointer;
                    
                    &.agency-dropped {
                        ${props => props.theme.RedLineThrough(1)};
                    }
                }
            }
            .ant-list-item-meta-description {
                color: ${props => props.theme.TextColor};
                font-size: ${props => props.theme.FontSizeSm};
                .agency-description-container {
                    font-size: ${props => props.theme.FontSizeSm};
                    padding-right: 2rem;
                    
                    .agency-booker {
                        margin-top: .25rem;
                        font-weight: bold;
                    }
                    .agency-dates, .agency-contract {
                        text-transform: uppercase;
                        color: ${props => props.theme.TextColorMuted};
                    }
                    .agency-notes {
                        margin-bottom: .25rem;
                    }
                    .agency-commission {
                        margin-top: .25rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }
`

const ModelAgenciesListItem = ({
    handleCommissions,
    handleEdit,
    handleRemove,
    modelAgency,
    onNameClick
}) => {

    const ModelAgencyIcon = ({ type }) => {
        switch (type.toLowerCase()) {
            case "mother":
                return <NwIcon size="xl" icon={light("medal")} />
            case "placement":
                return <NwIcon size="xl" icon={light("handshake")} />
            default:
                return <NwIcon size="xl" icon={light("globe")} />
        }
    }

    const ModelAgencyAvatar = () => (
        <div className="agency-type" style={{opacity: modelAgency.Dropped ? '.4' : '1'}}>
            {modelAgency.Dropped && 
                <span className="agency-type-line"><Tag color={`${StyleVariables.DangerColor}`}>DROPPED</Tag></span>
            }
            <span className="agency-type-line">{modelAgency.TypeName.toUpperCase()}</span>
            <span className="agency-type-line"><ModelAgencyIcon type={modelAgency.TypeName} /></span>
            {modelAgency.Label && 
                <span className="agency-type-line"><Tag color={`#${modelAgency.Label.Color}`}>{modelAgency.Label.Name}</Tag></span>
            }
        </div>
    )

    const drowDownMenuActions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => handleEdit(modelAgency)) },
        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => handleRemove(modelAgency)) },
        { name: "show commissions", key: "logs", icon: light("percent"), action: (() => handleCommissions(true)) },
    ]

    const ModelAgencyDescription = () => {
        return (
            <div className={`agency-description-container${modelAgency.Dropped ? ' agency-dropped' : ''}`}>
                {modelAgency.Notes &&
                    <div className="agency-notes">{modelAgency.Notes}</div>
                }
                {modelAgency.Booker &&
                    <div className="agency-booker">Booker: {modelAgency.Booker}</div>
                }
                {modelAgency.ContractDate &&
                    <div className="agency-contract">Contract signed on {showDateNoTime(modelAgency.ContractDate)}</div>
                }
                {!modelAgency.DateIn && modelAgency.DateOut &&
                    <div className='agency-dates'>left on {showDateNoTime(modelAgency.DateOut)}</div>
                }
                {modelAgency.DateIn && !modelAgency.DateOut &&
                    <div className='agency-dates'>since {showDateNoTime(modelAgency.DateIn)}</div>
                }
                {modelAgency.DateIn && modelAgency.DateOut &&
                    <div className='agency-dates'>{`${showDateNoTime(modelAgency.DateIn)} > ${showDateNoTime(modelAgency.DateOut)}`}</div>
                }
                {modelAgency.Percentage > 0 &&
                    <div className="agency-commission">{`${modelAgency.Percentage}% comm. ${modelAgency.OnGross ? ' on model gross' : ' on model net'}`}</div>
                }
            </div>
        )
    }

    const ModelAgencyTitle = () => (
        <span className={`agency-title-container${modelAgency.Dropped ? ' agency-dropped' : ''}`} onClick={onNameClick}>
            <strong>{modelAgency.AgencyName.toLowerCase()}</strong>
            {modelAgency.Towns ? ` - ${modelAgency.Towns.split(',').map(t => t.toLowerCase()).join(', ')}` : ''}
        </span>
    )

    return (
        <StyledListItem
            actions={[<NwDropDownMenu actions={drowDownMenuActions} />]}
        >
            <List.Item.Meta
                avatar={<ModelAgencyAvatar />}
                title={<ModelAgencyTitle />}
                description={<ModelAgencyDescription />}
            />
        </StyledListItem>
    )
};

export default ModelAgenciesListItem;

