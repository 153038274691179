import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Layout } from "antd";

import { removeStatementLog } from 'Libs/NwLogs';
import { forceReloadSearchInvoices, useInvoice } from "Hooks/Invoices/UseInvoices";
import { Loading } from "Components/Gui/Loading";
import { addStatementLog } from "Libs/NwLogs";
import NwResult from "Components/Gui/NwResult";
import InvoicePageHeader from "Components/Invoices/InvoicePage/InvoicePageHeader";
import EditInvoiceTextsDrawer from "Components/Invoices/InvoicePage/Drawers/EditInvoiceTextsDrawer";
import { forceReloadInvoice } from "Hooks/Invoices/UseInvoices";
import { forceReloadProject } from 'Hooks/Project/UseProject';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import InvoicePageSider from "./InvoicePageSider";
import InvoicePageTop from "./InvoicePageTop";
///import InvoicePageBottom from "./InvoicePageBottom";
import InvoicePageRows from "./InvoicePageRows";
import { NwLinkButton } from "Components/Gui/NwButton";
import NwDivider from "Components/Gui/NwDivider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InvoicePageTotals from "./InvoicePageTotals";
import InvoiceSentStatus from "./InvoiceSentStatus";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import useConfigurations from "Hooks/UseConfigurations";

const StyledLayout = styled(Layout)`
    min-height: ${props => props.inDrawer ? "0" : "calc(100vh - 220px)"};
    padding: ${props => props.inDrawer ? "0" : "8px 16px 16px"};
    background: white;
    margin: 0 1rem;
    display: flex;
    flex-direction: row;
`;

const InvoiceContent = styled.div`
    //width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 0;
`;

const InvoicePage = ({ invoiceId, inDrawer, onClose }) => {
    const history = useHistory();
    const { data: invoiceData, error } = useInvoice(invoiceId);
    const configurations = useConfigurations();

    const [showEditDrawer, setShowEditDrawer] = useState(false);
    const [isLocked, setIsLocked] = useState(false);

    const accounts = [];
    const schemas = [];
    if (configurations && configurations.COA) {
        const COAjs = JSON.parse(configurations.COA);
        if (COAjs.accounts && COAjs.accounts.length > 0) {
            accounts.push(...COAjs.accounts);
        }
        if (COAjs.schemas && COAjs.schemas.length > 0) {
            schemas.push(...COAjs.schemas);
        }
    }


    useEffect(() => {
        if (error) {
            removeStatementLog(invoiceId);
        }
    }, [error]);

    useEffect(() => {
        if (invoiceData) {
            addStatementLog(invoiceData);
            setIsLocked(invoiceData.Posted || invoiceData.ReadyToPost);
        }
    }, [invoiceData]);

    const handleTransactionCreatedOrUpdated = (ID) => {
        forceReloadInvoice(invoiceData.ID);
        forceReloadProjectAccountingDetails(ID)
        forceReloadProject(ID)
    }

    const handleDeleteInvoice = () => {
        if (inDrawer) {
            onClose()
        } else {
            forceReloadSearchInvoices();
            history.push(`/accounting-invoices`)
        }
    }

    const handleCloseTextsDrawer = () => {
        forceReloadInvoice(invoiceData.ID)
        setShowEditDrawer(false);
    }

    if (error) {
        return (
            <NwResult
                status="error"
                title="This invoice has been deleted"
            />
        )
    }

    return (invoiceData
        ?
        <div>
            <InvoicePageHeader
                invoiceData={invoiceData}
                inDrawer={inDrawer}
                handleShowEditDrawer={() => setShowEditDrawer(true)}
                onDelete={handleDeleteInvoice}
                locked={isLocked}
            />
            <StyledLayout $inDrawer={inDrawer}>
                <InvoicePageSider
                    invoiceData={invoiceData}
                    onShowEditDrawer={() => setShowEditDrawer(true)}
                    locked={isLocked}
                />
                <InvoiceContent>
                    {(invoiceData.ReadyToPost || invoiceData.Posted) &&
                        <InvoiceSentStatus invoiceData={invoiceData} />
                    }
                    <InvoicePageTop
                        invoiceData={invoiceData}
                        locked={isLocked}
                    />
                    <NwDivider>INVOICE DESCRIPTION</NwDivider>
                    <div>
                        <p>{invoiceData.HeaderNote}</p>
                        <NwLinkButton
                            disabled={isLocked}
                            onClick={() => setShowEditDrawer(true)}
                            icon={light("message-lines")}
                            label={`${invoiceData.HeaderNote ? "edit" : "add"} an invoice description`}
                        />
                    </div>
                    <NwDivider>INVOICE LINES</NwDivider>
                    <InvoicePageRows
                        invoiceData={invoiceData}
                        onTransactionsUpdated={handleTransactionCreatedOrUpdated}
                        locked={isLocked}
                    />
                    <InvoicePageTotals
                        invoiceData={invoiceData}
                    />
                    <NwDivider>INVOICE FOOTER</NwDivider>
                    <div>
                        <p>{invoiceData.FooterNote}</p>
                        <NwLinkButton
                            disabled={isLocked}
                            onClick={() => setShowEditDrawer(true)}
                            icon={light("message-lines")}
                            label={`${invoiceData.FooterNote ? "edit" : "add"} a terms additional text`}
                        />
                    </div>
                </InvoiceContent>
            </StyledLayout>
            {showEditDrawer &&
                <EditInvoiceTextsDrawer
                    onClose={handleCloseTextsDrawer}
                    invoiceData={invoiceData}
                />
            }
        </div>
        :
        <Loading />
    )
}

export default InvoicePage;