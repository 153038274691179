import styled from "styled-components"

export const EventTag = styled.div`
	box-sizing: border-box;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    display: inline-block;
    height: auto;
    margin-right: .5rem;
	padding: 0 7px;
    line-height: 18px;
    font-size: .65rem;
	white-space: nowrap;
    border-radius: 4px;
	vertical-align: middle;
    cursor: default;
    -webkit-transition: all .3s cubic-bezier(.78, .14, .15, .86);
    transition: all .3s cubic-bezier(.78, .14, .15, .86);
	
	text-transform: uppercase;

	svg {
		margin-right: .5em;
	}
`

export const EventTagBig = styled.div`
	text-transform: capitalize;
	margin: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-right: 1rem;
	flex: 1 0 0;
	
	.title {
		display: flex;
		flex: 2 0 0;
        justify-content: space-between;
        align-items: center;
		min-height: 34px;
		border-right: ${props => !props.titleOnly && props.theme.DefaultBorder};
		padding-right: .25em;
		margin-right: auto;
		
		
		h1 {
			margin: 0;
			font-size: 1.1rem;
			text-transform: uppercase;			
		}
	}
	
	.spec {
		/* flex: 1 0 0; */
		small {
			font-size: .7rem;
			text-transform: uppercase;
		}
		border-right: ${props => !props.titleOnly && props.theme.DefaultBorder};
		margin-right: 3rem;
		padding-right: 2rem;
	}
	
`

export const EventTagIcon = styled.div`
	margin: 0 .2em 0 0;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    font-size: .75rem;
	vertical-align: middle;
`