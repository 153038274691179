import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getNotice = noticeId => (
    Axios.get(`/notices/${noticeId}`)
);

const useNotice = noticeId => {
    return useQuery(["notice", noticeId], () => getNotice(noticeId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: noticeId ? true : false
    });
};

const forceReloadNotice = (ID) => {
    queryClient.invalidateQueries(['notice', ID])
}

const onDeleteNotice = ({ ID }) => (
    Axios.delete(`/notices/${ID}`)
)

const useDeleteNotice = () => {
    return useMutation(onDeleteNotice, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.noticeId) {
                forceReloadNotice(variables.noticeId);
            }
        }
    })
}

const onUpdateNotice = ({ noticeData }) => (
    Axios.put('/notices', noticeData)
);

const useUpdateNotice = () => {
    return useMutation(onUpdateNotice, {
        onSuccess: (data, variables) => {
            forceReloadNotice(variables.noticeData.ID);
        }
    })
}

const onAddNotice = ({ noticeData }) => (
    Axios.post('/notices', noticeData)
);

const useAddNotice = () => {
    return useMutation(onAddNotice, {
        onSuccess: (data, variables) => {
            forceReloadNotice(data.ID);
        }
    })
};

export {
    useNotice,
    useDeleteNotice,
    useUpdateNotice,
    useAddNotice,
    forceReloadNotice
};