import React, { useState } from "react";
import { Pagination, Row, Col } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import "react-perfect-scrollbar/dist/css/styles.css";
import { duotone, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import NwObjectCounter from "Components/Gui/NwObjectCounter";
import { NwStyledCard, GetNwCardContentStyle, GetNwCardStyle, FilterBadge } from 'Components/Gui/NwCard/NwCardUi';

const SelectionIconWrapper = styled.div`
     svg {
        color: ${props => props.$isSelected ? props.theme.PrimaryColor : props.theme.PrimaryDarkLight};
        font-size: 1.45rem;
        margin-left: 4px;
        margin-top: 4px;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.PrimaryColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;  
    }
`

const CardFooterContainer = styled.div`
    border-top: ${props => props.theme.DefaultBorder};
    min-height: 4rem;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
`;

const NwCardHeaderTitleTextContent = (title) => {
    if (React.isValidElement(title)) {
        return (title)
    } else {
        return (<h1>{title}</h1>)
    }
}

const NwCardHeader = ({ title, icon, counter, titleAction, selection }) => {
    return (
        <div className="nw-card-head-title">
            {selection &&
                <div className="nw-card-head-selection">
                    <SelectionIconWrapper $isSelected={selection.isSelected}>
                    {selection.isSelected
                        ? <NwIcon pointer icon={duotone("check-circle")} primary onClick={selection.onDeselect} />
                        : <NwIcon pointer icon={solid("check-circle")} onClick={selection.onSelect} />
                    }
                    </SelectionIconWrapper>
                </div>
            }
            {icon && (<div className="nw-card-head-icon-holder"><NwIcon className="nw-card-header-icon" icon={icon} /></div>)}
            <div className="nw-card-head-title-content">
                {NwCardHeaderTitleTextContent(title)}
            </div>
            {(counter !== null) ? <NwObjectCounter count={counter} style={{ marginLeft: ".5em" }} /> : null}
            {titleAction && <div className="nw-card-head-title-action">{titleAction}</div>}
        </div>
    )
};

const NwCardContent = (props) => {
    return (
        <>
            <div className={GetNwCardContentStyle(props)} style={props.style}>{props.children}</div>
            {((props.pagination && props.pagination.total > props.pagination.pageSize) || props.extra) &&
                <CardFooterContainer style={props.footerStyle}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            {(props.pagination && props.pagination.total > props.pagination.pageSize) &&
                                <Pagination
                                    size="small"
                                    className="nw-card-paginated-pagination"
                                    current={props.pagination.current}
                                    pageSize={props.pagination.pageSize}
                                    onChange={props.pagination.onChange}
                                    total={props.pagination.total}
                                    showSizeChanger={props.pagination.showSizeChanger}
                                />
                            }
                        </Col>
                        <Col>
                            {props.extra}
                        </Col>
                    </Row>
                </CardFooterContainer>
            }
        </>
    )
}

const NwCard = (props) => {
    const { ClassN, Style, BodyStyle, ScrollStyle } = GetNwCardStyle(props);
    const { filtersContent, activeFilters } = props;
    const [showFiltersContent, setShowFiltersContent] = useState(false);

    const handleShowFiltersContent = () => {
        setShowFiltersContent(prevShowFiltersContent => !prevShowFiltersContent);
    };

    return (
        <NwStyledCard
            $transparent={props.transparent}
            $filters={activeFilters || filtersContent}
            $filterscontent={showFiltersContent ? true : undefined}
            size={props.size || "default"}
            title={props.title && 
                <NwCardHeader 
                    title={props.title} 
                    subtitle={props.subtitle} 
                    icon={props.icon} 
                    counter={props.counter} 
                    titleAction={props.titleAction}
                    selection={props.selection}
                 />
            }
            className={ClassN}
            actions={props.actions}
            extra={props.extra}
            //hoverable
            style={Style}
            bodyStyle={BodyStyle}
            onClick={props.onClick}
        >
            {(activeFilters || filtersContent) &&
                <div className='active-filters'>
                    {activeFilters ? 
                        <div className='active-filters-label' onClick={handleShowFiltersContent}>
                            <div>{activeFilters.join(', ')}</div>
                            <FilterBadge 
                                count={activeFilters.length}>
                                <NwIcon icon={light('filter')}/>
                            </FilterBadge>
                        </div>
                        : filtersContent
                    }
                    {showFiltersContent && filtersContent &&
                        <div className='filters-content'>
                            {filtersContent}
                        </div>
                    }
                </div>
            }      
            <div className='ant-card-body-content'>
                {
                    (props.scrollable && props.children) ? (
                        <div style={ScrollStyle}>
                            <PerfectScrollbar options={{ suppressScrollX: true }}>
                            {props.children}
                            </PerfectScrollbar>
                        </div>
                    ) : (
                        <>
                            {props.children}
                        </>
                    )
                }
            </div>
            {(props.footer) &&
                <CardFooterContainer>
                    {props.footer}
                </CardFooterContainer>
            }
            {(props.footerButtons) &&
                <CardFooterContainer>
                    <Row type="flex" justify="center">
                        {props.footerButtons.map((item, index) => (
                            <Col key={index} style={{ paddingLeft: ".5em", paddingRight: ".5em" }}>{item}</Col>
                        ))}
                    </Row>
                    {props.footer}
                </CardFooterContainer>
            }
        </NwStyledCard>
    );
};


export { NwCard, NwCardContent };
