import React, { useContext, useState, useMemo } from "react";
import styled from 'styled-components';
import { Row, Col, Alert } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import useConfigurations from "Hooks/UseConfigurations";
import { UserContext } from 'Contexts/UserContext';
import { useStateValue } from 'Contexts/StateProvider';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import {
    NWSelectCountry,
    NWInput,
    NWGenderSelector,
    NWContactSelector,
    NWInTownSelector,
    NWAreaSelector,
    NWDepartmentSelector,
    NWDatePicker
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { forceReloadModels, forceReloadContacts, forceReloadModelsWall } from 'Hooks/Contact/UseContactsList';
import NwIcon from "Components/Gui/NwIcon";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getMoment, getToday } from "Libs/NwMoment";
import { validateDate } from "Libs/NwUtils";
import { useMutation } from "@tanstack/react-query";

const DuplicateCustomersContainer = styled.div`
    
    div.duplicate-customer-item {
        padding: .5rem 2rem;
        border-top: ${props => props.theme.DefaultBorder};
        p {
            margin: .25rem 0;
        }
        .duplicate-customer-networkagencies {
            p {   
                font-size: ${props => props.theme.fontSizeSm};
                &.storage {
                    text-decoration: line-through;
                }
            }
        }
    }
`

const validateDepartments = value => {
    if (!value.length) {
        return 'Please select at least one department!';
    }
    return null;
}

const CreateModelForm = ({ onModelCreated, defaultFormData, onCancel, onTouchForm }) => {
    useFormFieldFocus('FirstName');
    const [{ areaContext },] = useStateValue();
    const { currentUser } = useContext(UserContext);
    const checkDuplicates = true;
    //const { cachedList } = useContext(ListContext);
    const configurations = useConfigurations();
    const [duplicateModels, setDuplicateModels] = useState([]);

    const mutation = useMutation({
        mutationFn: (values) => {
            return Axios.post('/models', values)
        },
        onSuccess: (data) => {
            forceReloadModels()
            forceReloadModelsWall()
            forceReloadContacts()
            onModelCreated(data.ID)
        }
    })

    // const departments = useMemo(() => {
    //     const departments = [];
    //     for (const accountingGroup of cachedList.accountingGroups.items) {
    //         for (const networkAgency of accountingGroup.NetworkAgencies) {
    //             for (const department of networkAgency.Departments) {
    //                 if (currentUser.ActiveContext.activeDepartments.find(departmentId => departmentId === department.ID)) {
    //                     departments.push(department);
    //                 }
    //             }
    //         }
    //     }
    //     return departments;
    // }, []);

    let initialValues = {
        LegalName: '',
        LegalSurname: '',
        Gender: 'Female',
        Email: '',
        Phone: '',
        InTown: true,
        SubType: areaContext.area || 'booking',
        NationalityCode: configurations.defaultCountry,
        CitizenshipCode: configurations.defaultCountry,
        MotherAgencyID: null,
        Represented: true,
        Departments: [currentUser.ActiveContext.depId],
        InAgencyFrom: getToday()
    };

    if (defaultFormData) {
        initialValues = {
            ...initialValues,
            ...defaultFormData
        }
    }

    const beforeSubmit = (values, setSubmitting, resetForm) => {
        if (checkDuplicates) {
            const dataCheck = {
                Name: values.LegalName,
                Surname: values.LegalSurname,
                MotherAgencyID: values.MotherAgencyID || null,
                Email: values.Email,
                NationalityCode: values.NationalityCode || null
            }

            Axios.post(`/models/CheckDuplicates`, dataCheck)
                .then(response => {
                    if (response.length > 0) {
                        setSubmitting(false)
                        setDuplicateModels({ duplicates: response })
                    } else {
                        submitForm(values, setSubmitting, resetForm);
                    }
                })
                .catch(error => {
                    setSubmitting(false)
                    console.log("errore", error);
                });
        } else {
            submitForm(values, setSubmitting, resetForm);
        }
    };

    const submitForm = async (values, setSubmitting, resetForm) => {
        if (values.MotherAgencyID) {
            values.Represented = false;
        } else {
            values.Represented = true;
        }
        values.SubType = values.SubType === 'booking' ? 'Booking' : 'Scouting';

        try {
            //const response = await Axios.post('/models', values);
            //onModelCreated(response.ID);
            // forceReloadModels();
            // await prefetchModels();
            // forceReloadModelsWall();
            // forceReloadContacts();

            await mutation.mutateAsync(values)

            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false)
            resetForm()
            
        } catch (error) {
            setSubmitting(false)
            console.log("errore", error);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                Email: Yup.string().email("Invalid email format"),
                LegalName: Yup.string().required("Name is required"),
                LegalSurname: Yup.string().required("Surname is required"),
                NationalityCode: Yup.string().required("Nationality is required")
            })}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                setSubmitting(true);
                setStatus();
                beforeSubmit(values, setSubmitting, resetForm);
            }}>
            {({ values, handleSubmit, resetForm, setSubmitting, isSubmitting, isValid }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    {(duplicateModels && duplicateModels.duplicates && duplicateModels.duplicates.length > 0)
                        ?
                        <>
                            <DuplicateCustomersContainer>
                                <Alert showIcon message={duplicateModels.duplicates.length > 1 ? `${duplicateModels.duplicates.length} models with similar names. Please check the list below to make sure you're not creating a duplicated record.` : `A model with similar name. Please make sure you're not creating a duplicated record.`} type="warning" />
                                {duplicateModels.duplicates.map((m) => (
                                    <div className="duplicate-customer-item">
                                        <p className="duplicate-customer-name"><strong>{m.Name} {m.Surname}</strong></p>
                                        <div className="duplicate-customer-networkagencies">
                                            {m.AccountingGroups.map((ag) => (
                                                ag.NetworkAgencies.map((na) => (
                                                    <p key={ag.NetworkAgencyID} className={na.StorageBooking && "storage"}>
                                                        {na.StorageBooking
                                                            ?
                                                            <NwIcon icon={light("box-archive")} />
                                                            :
                                                            <NwIcon icon={light("wave-pulse")} />
                                                        }
                                                        &nbsp;
                                                        {na.NetworkAgencyName}
                                                    </p>
                                                ))
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </DuplicateCustomersContainer >
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setSubmitting(false);
                                            setDuplicateModels(null);
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        label="Save anyway"
                                        onClick={() => {
                                            submitForm(values, setSubmitting, resetForm)
                                            setDuplicateModels(null);
                                        }}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </>
                        :
                        <>
                            <Row gutter={5}>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* Name */}
                                    <Field
                                        component={NWInput}
                                        label="First Name"
                                        name="LegalName"
                                        type="text"
                                        id={'FirstName'}
                                        value={values.LegalName}
                                    />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* Surname */}
                                    <Field
                                        component={NWInput}
                                        label="Last Name"
                                        name="LegalSurname"
                                        type="text"
                                        value={values.LegalSurname}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={5}>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <Field
                                        component={NWAreaSelector}
                                        label="Area"
                                        name="SubType"
                                        value={values.SubType}
                                    />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* Email */}
                                    <Field
                                        component={NWInTownSelector}
                                        label="Locations"
                                        name="InTown"
                                        value={values.InTown}
                                    />
                                </Col>
                            </Row>
                            <Field
                                component={NWGenderSelector}
                                label="Gender"
                                name="Gender"
                                value={values.Gender}
                            />
                            <Row gutter={5}>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* Email */}
                                    <Field
                                        component={NWInput}
                                        label="Email"
                                        name="Email"
                                        type="text"
                                        value={values.Email}
                                    />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* Email */}
                                    <Field
                                        component={NWInput}
                                        label="Phone (mobile)"
                                        name="Phone"
                                        type="text"
                                        value={values.Phone}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={5}>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* NationalityCode */}
                                    <Field
                                        component={NWSelectCountry}
                                        label="Nationality"
                                        name="NationalityCode"
                                        value={values.NationalityCode}
                                    />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    {/* NationalityCode */}
                                    <Field
                                        component={NWSelectCountry}
                                        label="Citizenship"
                                        name="CitizenshipCode"
                                        value={values.CitizenshipCode}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={5}>
                                <Col span={24}>
                                    {/* MotherAgency */}
                                    <Field
                                        component={NWContactSelector}
                                        label="Mother Agency"
                                        name="MotherAgencyID"
                                        activeTypes={['age', 'cli', 'srv']}
                                        activableTypes={[]}
                                        value={values.MotherAgencyID}
                                    />
                                </Col>
                            </Row>
                            <Field
                                component={NWDepartmentSelector}
                                label={'Departments'}
                                name="Departments"
                                value={values.Departments}
                                placeholder="Select Departments"
                                mode='multiple'
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                // options={departments.map(department => {
                                //     return { key: department.ID, label: department.Name, value: department.ID };
                                // })}
                                validate={validateDepartments}
                                allAvailable
                                allAgencies
                            />
                            <Field
                                component={NWDatePicker}
                                label={'In Agency Since'}
                                name="InAgencyFrom"
                                format="MMM DD, YYYY"
                                value={values.InAgencyFrom ? getMoment(values.InAgencyFrom) : null}
                                validate={value => validateDate(value, true)}
                            />
                            <br />
                            <br />
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            if (onTouchForm) {
                                                onTouchForm(false);
                                            }
                                            onCancel();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        onClick={handleSubmit}
                                        disabled={!isValid}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </>
                    }
                </NwForm>
            )}
        </Formik>
    );
};

export default CreateModelForm;
