import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';
import { Result } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ModelDetailsBooks from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooks";
import ModelDetailsCalendar from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsCalendar";
import ModelDetailsMobileFooter from 'Components/ContactDetails/ModelDetails/ModelDetailsMobile/ModelDetailsMobileFooter';
import FlexContainer from 'Components/Gui/FlexContainer';
import ContactDetailsDocuments from 'Components/ContactDetailsDocuments/ContactDetailsDocuments';
import ContactDetailsNotes from 'Components/ContactDetailsNotes/ContactDetailsNotes';
import { useStateValue } from 'Contexts/StateProvider';
import { useContact } from 'Hooks/Contact/UseContact';
import { addModelLog } from "Libs/NwLogs";
import NwDivider from "Components/Gui/NwDivider";
import NwIcon from 'Components/Gui/NwIcon';
import Comments from "Components/Comments/Comments";
import ModelDetailsMobileHeader from "./ModelDetailsMobile/ModelDetailsMobileHeader";

const Wrapper = styled.div`
    height: calc(100vh - 116px);
    background: white;

    .container {
        position: relative;
        padding: 12px 16px;
        height: ${props => props.$isDrawer ? 'calc(100vh - 126px)' : 'calc(100vh - 176px)'};
        overflow: auto;
    }

    .back-models {
        font-size: .8rem;
        cursor: pointer;
    }
`;

const Label = styled.div`
    font-size: 1.6rem;
`;

const ModelDetailsMobile = ({ modelId, isDrawer, match }) => {
    const selectedModel = modelId ? modelId : Number(match.params.modelId);
    const [{areaContext}] = useStateValue();
    const [viewType, setViewType] = useState('profile');   
    const { data: currentContact } = useContact(selectedModel);
    const history = useHistory();

    const [contactCalendarStyle, setContactCalendarStyle] = useState('agenda');

    useEffect(() => {
        currentContact && currentContact.NetworkAgencies && !currentContact.NetworkAgencies[0].StorageBooking && addModelLog(currentContact, areaContext.area)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact]);

    const handleChangeView = viewType => () => {
        setViewType(viewType);
    };

    const renderModelDetails = () => {
        switch(viewType) {
            case 'profile':
                return <ModelDetailsMobileHeader isDrawer selectedModel={selectedModel} />
            case 'calendar':
                return (
                    <ModelDetailsCalendar 
                        isMobileDrawer={isDrawer}
                        idmodel={selectedModel} 
                        calendarStyle={contactCalendarStyle} 
                        onChangeCalendarStyle={setContactCalendarStyle} 
                    />
                )
            case 'books':
                return <ModelDetailsBooks isInCardTab contactID={selectedModel} modelPlaylists={currentContact.Playlists} />
            case 'files':
                return <ContactDetailsDocuments isInCardTab currentContact={currentContact} />
            case 'comments':
                return (
                    <>
                        <NwDivider>Comments</NwDivider>
                        <Comments
                            api="ContactID"
                            drawerMode
                            isInCardTab
                            contactId={selectedModel}
                            ID={selectedModel}
                            comments={currentContact.Comments}
                        />
                        <NwDivider>Notes</NwDivider>
                        <ContactDetailsNotes 
                            isInCardTab
                            currentContact={currentContact}
                            afterNotesCreation={() => {}} />
                    </>
                )
            case 'statement':
                return <div />
            default:
                return null;
        }
    }

    const handleBackToModels = () => {
        history.push('/models');
    }

    return (
        <Wrapper $isDrawer={isDrawer}>
            {currentContact && 
                <>
                    {currentContact.NetworkAgencies[0].StorageBooking ? 
                        <Result
                            title="This model has been archived"
                        />
                    : 
                        <div className='container'>
                            {(viewType !== 'profile' && !isDrawer) && 
                                <FlexContainer mg='16px 0 8px' column alignItems='center' justifyContent='center'>
                                    <FlexContainer className='back-models' alignItems='center' onClick={handleBackToModels}>
                                        <NwIcon icon={light('arrow-left')}></NwIcon>&nbsp;back to models list
                                    </FlexContainer>
                                    <Label>{`${currentContact.Name} ${currentContact.Surname}`}</Label>
                                </FlexContainer>
                            }
                            {renderModelDetails()}
                        </div>
                    }
                </>
            }
            <ModelDetailsMobileFooter 
                viewType={viewType}
                onViewModelProfile={handleChangeView('profile')}
                onViewCalendar={handleChangeView('calendar')}
                onViewModelBooks={handleChangeView('books')}
                onViewFiles={handleChangeView('files')}
                onViewComments={handleChangeView('comments')}
                onViewStatement={handleChangeView('statement')}    
            />
        </Wrapper>
    );
};

export default withRouter(ModelDetailsMobile);
