import React from 'react';
import { Input, InputNumber, Select, AutoComplete, TimePicker, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const FormInput = props => {
    return (
        <Input autoComplete='off' {...props} />
    );
};

const FormTextArea = props => {
    return (
        <TextArea autoComplete='off' {...props} rows={5}/>
    );
};


const FormInputNumber = props => {
    return (
        <InputNumber autoComplete='off' {...props} />
    );
};

const FormSelect = props => {
    return (
        <Select autoComplete='off' {...props} />
    );
};

const FormAutoComplete = props => {
    return (
        <AutoComplete  {...props} />
    );
};

const FormTimePicker = props => {
    return (
        <TimePicker autoComplete='off' {...props} />
    );
};

const FormRangePicker = props => {
    return (
        <RangePicker autoComplete='off' {...props} />
    );
};

export {
    FormInput,
    FormInputNumber,
    FormSelect,
    FormAutoComplete,
    FormTimePicker,
    FormRangePicker,
    FormTextArea
};