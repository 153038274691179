import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import Axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwRemoveButton } from 'Components/Gui/NwButton';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import GlobalSearch from 'Components/_LayoutComponents/Header/GlobalSearch/GlobalSearch';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import { getContactName, getSimpleContacts, getContact } from 'Hooks/Contact/UseContactsList';
import { useStateValue } from "Contexts/StateProvider";

const NameContainer = styled.div`
    display: flex;
    align-items: center;
    ${props => props.isInStorage ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
    opacity: ${props => props.isNotAccessible && 0.5};
`;

const Label = styled.div`
    margin-left: 8px;
`;

const SortableList = styled.div`
    opacity: ${props => props.isLoading ? ".25" : "1"};
    position: relative;
    cursor: pointer;
`;

const SortableListItem = styled.div`
    background: ${props => props.isDragging ? StyleVariables.PrimaryColor : "#fff"};
    color: ${props => props.isDragging ? "#fff" : StyleVariables.TextColor };
    margin-bottom: 4px;
    padding: 0.5rem;
    box-shadow: ${props => props.isDragging ? '0 1px 6px rgba(57,73,76,.35)' : null};
    border-bottom: 0.5px solid ${props => props.theme.LighterBorderColor};
    display: flex;
    align-items: center;
    width: 100%;

    .sortable-list-item-icon {
        margin: 0 2rem 0 0;
    }

    .sortable-list-item-text {
        h6, p {
            padding: 0;
            margin: 0;
        }
    }
`;

const AddModel = styled.div`
    margin-right: 16px;
    white-space: nowrap;
    font-weight: bold;
`;

const FolderModels = ({ models }) => {
    const [{ folderContext, modelFiltersContext, areaContext }, dispatch] = useStateValue();
    const { activeSet } = modelFiltersContext[areaContext.area];

    const onItemDelete = itemID => () => {
        dispatch({
            type: 'removeItemsFromFolder',
            folderID: activeSet,
            items: [itemID]
        })
    };

    const renderItemIcon = item => {
        switch (item.type) {
            case 'model': 
                return <NwIcon icon={light('walking')} />;
            case 'customer':
                return <NwIcon icon={light('id-card')} />;
            case 'project':
                return <NwIcon icon={light('briefcase')} />
            default:
                return <></>
        }
    };

    const items = models.map(item => {
        return {
            ...item,
            Name: getContactName(item.ID),
            key: item.ID
        }
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async result => {
        if (result.source.index !== result.destination.index) {
            const folderIndex = folderContext.openFolders.findIndex(p => p.ID === activeSet)
            const reorderedFolderItems = reorder([...folderContext.openFolders[folderIndex].items], result.source.index, result.destination.index)
            dispatch({
                type: 'updateOrderFolderItems',
                folder: {
                    ...folderContext.openFolders[folderIndex],
                    items: reorderedFolderItems
                }
            })
        }
    };

    const handleSearchModel = async model => {
        dispatch({
            type: 'addItemsToFolder',
            folderID: activeSet,
            items: [model.ID]
        })
    };

    const simpleContacts = getSimpleContacts();

    return (
        <>
            <FlexContainer alignItems='center'>
                <AddModel>Add a model</AddModel>
                <GlobalSearch
                    sources={["Models"]}
                    openMode
                    onSearchItem={handleSearchModel} />
            </FlexContainer>
            {models.length > 0 ? 
                <NwCard>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <SortableList
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {items.map((item, index) => {
                                        const simpleContact = simpleContacts.find(contact => contact.ID === item.ID);
                                        const contact = getContact(item.ID);
                                        const isInStorage = (!contact && simpleContact && simpleContact.SB) ? true : false;
                                        const isNotAccessible = (!contact && simpleContact && !simpleContact.SB) ? true : false;

                                        return (
                                            <Draggable key={`${item.ID}${item.type}`} draggableId={`${item.ID}${item.type}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <SortableListItem 
                                                        isDragging={snapshot.isDragging}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <FlexContainer
                                                            fullWidth 
                                                            justifyContent='space-between' 
                                                            className="sortable-list-item-text">
                                                            <FlexContainer>
                                                                <NwIcon icon={light('sort')}/>
                                                                <NameContainer isInStorage={isInStorage} isNotAccessible={isNotAccessible}>
                                                                    {renderItemIcon(item)}
                                                                    <Label>{item.Name}</Label>
                                                                </NameContainer>
                                                            </FlexContainer>
                                                            {isInStorage && <div>model in storage</div>}
                                                            {isNotAccessible && <div>not accessible</div>}
                                                            <NwRemoveButton 
                                                                style={{marginRight: "15px"}} 
                                                                danger 
                                                                ghost
                                                                iconOnly 
                                                                size="small" 
                                                                onClick={onItemDelete(item.ID)} 
                                                            />
                                                        </FlexContainer>
                                                    </SortableListItem>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </SortableList>
                            )}
                        </Droppable>
                    </DragDropContext>
                </NwCard>
                :
                <CenteredBlock>
                    <EmptyState message="no models fitting your search" />
                </CenteredBlock>
            }
        </>
    );
};

export default withRouter(FolderModels);