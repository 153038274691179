import React, { useState } from "react";
import { Button, Popover, Tooltip } from "antd";
import styled from 'styled-components';
import NwIcon from "Components/Gui/NwIcon";
import { StyledGroupButton } from "Components/Gui/StyledGroupButton";
import FlexContainer from 'Components/Gui/FlexContainer';
import { light, duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const StyledIcon = styled(NwIcon)`
  margin-right: .5em;
`

const StyledButtonLink = styled(Button)`
  color: ${props => props.$warning ? props.theme.WarningColor : props.theme.PrimaryColor};
  padding: 0;
  svg {
    margin-right: .5rem;
  }

  &:hover, &:focus, &:active {
    box-shadow: none !important;
    //color: ${props => props.$warning ? props.theme.WarningColor : props.theme.PrimaryColor} !important;
  }

  &:hover {
    span {
      text-decoration: underline;
      font-weight: bold;
    }
  }
`

const StyledButton = styled(Button)`
    text-transform: ${props => props.$lowercase ? 'lowercase' : 'uppercase'};

    svg {
        font-size: 1.1em;
    }

    &.ant-btn-lg {
        svg {
            font-size: .85em;
        }
    }

    &.ant-btn-success {
        color: #fff;
        background-color: ${props => props.theme.SuccessColor};
        border-color: ${props => props.theme.SuccessColor};

        &:disabled {
            background-color: ${props => props.theme.LighterBorderColor};
            color: ${props => props.theme.DisabledTextColor};
        }
    }

    &.danger-icon {
        color: ${props => props.theme.DangerColor};
    }

    &.ghost-red.ghost {
        color: ${props => props.theme.DangerColor};
    }

    &.success-icon {
        color: ${props => props.theme.SuccessColor};
    }

    &.ghost {
        border: none;
        background-color: transparent;
        color: ${props => props.type ? props.theme.PrimaryColor : "#000"};
        box-shadow: none;
        text-transform: lowercase;

        &:hover, &:active, &:focus {
            background-color: ${props => props.theme.PrimaryColor};
            color: #fff;
            box-shadow: none;
        }

        &[disabled] {
            border: none;
            box-shadow: none;
            background-color: transparent;
            color: ${props => props.theme.DisabledTextColor};
            &:hover, &:active, &:focus {
                background-color: transparent;
                color: ${props => props.theme.DisabledTextColor};
            }
        }
    }

    &.minimal {
        border: none;
        background-color: transparent;
        color: ${props => props.theme.TextColor};
        box-shadow: none;
        text-transform: lowercase;
        padding: 0;
        margin: 0;

        &:hover {
            color: ${props => props.theme.PrimaryColor};
        }
    }

    &.icon-only {
        &:hover {
            background-color: transparent;
            color: ${props => props.theme.PrimaryColor};
            box-shadow: none;
        }
    }

    &.link-button {
        color: ${props => props.theme.PrimaryColor};
        span {
            padding-bottom: 1px;
        }
        &:hover {
            span {
                border-bottom: 1px solid ${props => props.theme.PrimaryColor};
                padding-bottom: 0px;
            }
        }
        &:disabled {
            color: ${props => props.theme.DisabledTextColor};
                &:hover {
                span {
                    border-bottom: none;
                    padding-bottom: 1px;
                }
            }
        }
    }

    &.multi {
        width: 100%;
        border: none;
        padding-left: 0;
        box-shadow: none !important;
        opacity: ${props => props.active ? 1 : 0.4};
        font-weight: ${props => props.active ? 'bold' : 'normal'};

        &:hover, &:focus, &:active {
            color: ${props => props.theme.TextColor};
        }
        &:after {
            display: none;
        }

        .icon {
            width: 20px;
            height: 20px;
            margin: 4px;
            border: 1px solid ${props => props.active ? props.theme.PrimaryColor : props.theme.TextColor};
            border-radius: 100%
        }
    }

    &.event-cancelled-button {
        background-color: ${props => props.theme.DangerColor} !important;
        color: #fff !important;
        border-color: ${props => props.theme.DangerColor} !important;

        &:hover {
            border-color: ${props => props.theme.DangerColor} !important;
            opacity: .75;
        }
    }

    &.event-acknowledged-button {
        background-color: ${props => props.theme.SuccessColor} !important;
        color: #fff !important;
        border-color: ${props => props.theme.SuccessColor} !important;

        &:hover {
            border-color: ${props => props.theme.SuccessColor} !important;
            opacity: .75;
        }
    }
`


const PopoverMessage = styled.div`
  width: 420px;
  padding: 2em 1em 3em 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    font-size: 1.2em;
    max-width: 300px;
    margin: 0;
  }

  .danger-icon {
    .fa-secondary {
      fill: ${props => props.theme.DangerColor};
      opacity: 1;
    }
    .fa-primary {
      fill: #fff;
      opacity: 1;
    }
  }
`

const PopoverButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

const NwMapButton = (props) => {
    const { label = "map", loadingLabel = "Saving...", ...other } = props
    return (
        <NwButton icon={light("map-marker")} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwAddButton = (props) => {
    const { label = "Add new", loadingLabel = "Saving...", ...other } = props
    return (
        <NwButton icon={solid("plus-circle")} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwEditButton = (props) => {
    const { label = "Edit", loadingLabel = "Saving...", ...other } = props
    return (
        <NwButton icon={light("pen")} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwMoreButton = (props) => {
    const { loadingLabel = "Loading...", ...other } = props
    return (
        <NwButton ghost icon={light("ellipsis")} iconOnly loadingLabel={loadingLabel} {...other} />
    )
}

const NwCancelButton = (props) => {
    const { label = "Cancel", ...other } = props
    return (
        <NwButton icon={light("times")} label={label} {...other} />
    )
}

const NwSaveButton = (props) => {
    const { label = "Save", loadingLabel = "Saving...", htmlType = "submit", primary = true, ...other } = props
    return (
        <NwButton icon={solid("save")} label={label} loadingLabel={loadingLabel} htmlType={htmlType} primary={primary} {...other} />
    )
}

const NwMainButton = (props) => {
    const { label = "main", loadingLabel = "Saving...", isMain, ...other } = props

    //const mainIcon = props.main ? ["fas","bookmark"] : "bookmark" 
    const mainIcon = light("bookmark");

    return (
        <NwButton primary={isMain} icon={mainIcon} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwRemoveButton = (props) => {
    const { label = "Remove", loadingLabel = "Removing...", onClick, needConfirmation = true, confirmationLabel = "You're permanently removing the selected object(s). Please note that this can't be undone", parentNode, confirmationPlacement = "top", className, ...other } = props
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

    const handleClick = () => {
        setOpenConfirmDialog(false)
        onClick()
    }

    if (needConfirmation) {
        return (
            <Popover
                getPopupContainer={triggerNode => parentNode ? triggerNode.parentNode : document.body}
                placement={confirmationPlacement}
                arrowPointAtCenter
                title="PLEASE CONFIRM DELETION"
                zIndex={3000}
                content={
                    <>
                        <PopoverMessage>
                            <NwIcon className="danger-icon" icon={duotone("exclamation-triangle")} size="3x" />
                            <p>{confirmationLabel}</p>
                        </PopoverMessage>
                        <PopoverButtons>
                            <NwCancelButton onClick={() => setOpenConfirmDialog(false)} />
                            <NwButton danger icon={light("trash")} label="DELETE" onClick={handleClick} />
                        </PopoverButtons>
                    </>
                }
                trigger="click"
                open={openConfirmDialog}
            >
                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NwButton 
                        icon={solid("trash")} 
                        className={`${other.ghost ? 'ghost-red' : ''} ${className}`} 
                        danger 
                        onClick={() => setOpenConfirmDialog(true)} 
                        label={label} 
                        loadingLabel={loadingLabel} {...other} 
                    />
                </div>
            </Popover>
        )
    } else {
        return (
            <NwButton icon={light("trash")} className={`${other.ghost ? 'ghost-red' : ''} ${className}`} danger label={label} onClick={onClick} loadingLabel={loadingLabel} {...other} />
        )
    }
}

const NwUploadButton = (props) => {
    const { label = "Upload", loadingLabel = "Uploading...", ...other } = props
    return (
        <NwButton icon={solid("cloud-upload")} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwBackButton = (props) => {
    const { label = "Back", loadingLabel = "Going back...", ...other } = props
    return (
        <NwButton icon={solid('chevron-circle-left')} label={label} loadingLabel={loadingLabel} {...other} />
    )
}

const NwButton = (props) => {
    const {
        label = "",
        primary,
        danger,
        success,
        tooltip,
        solid,
        iconOnly,
        iconOpacity,
        loadingLabel = "please wait",
        icon,
        loading,
        disabled,
        size = "default",
        ghost,
        minimal,
        lowercase,
        className,
        ...other
    } = props;

    let myicon = icon

    let buttonClasses = ""
    if (ghost) {
        buttonClasses += "ghost"
    } else {
        if (minimal) {
            buttonClasses += "minimal"
        }
    }
    if (iconOnly) {
        buttonClasses += " icon-only"
    }
    if (size) {
        switch (size) {
            case "small":
                buttonClasses += " ant-btn-sm"
                break
            case "large":
                buttonClasses += " ant-btn-lg"
                break
            default:
        }
    }
    let dangertype = false
    if (danger) {
        if (iconOnly || !label) {
            buttonClasses += "danger-icon"
        } else {
            dangertype = true
        }
    }
    if (success) {
        if (iconOnly || !label) {
            buttonClasses += "success-icon"
        } else {
            buttonClasses += "ant-btn-success"
        }
    }
    if (className) {
        buttonClasses += buttonClasses ? (" " + className) : className
    }

    const buttontype = primary ? "primary" : null

    const buttoncontent = () => (
        <StyledButton 
            $lowercase={lowercase} 
            className={buttonClasses} 
            danger={dangertype} 
            type={buttontype} 
            disabled={loading || disabled}  
            {...other}
        >
            {(iconOnly || label === "" || minimal) ? (
                (loading && (
                    <>
                        <NwIcon opacity={iconOpacity} icon={light("spinner-third")} spin />
                    </>
                )) || (
                    <>
                        {myicon && <NwIcon opacity={iconOpacity} icon={myicon} />}
                    </>
                )
            ) :
                (loading && (
                    <>
                        <StyledIcon opacity={iconOpacity} icon={light("spinner-third")} spin />
                        {loadingLabel}
                    </>
                )) || (
                    <>
                        {myicon && <StyledIcon opacity={iconOpacity} icon={myicon} />}{label}
                    </>
                )
            }
        </StyledButton>
    )

    if (tooltip) {
        return (
            <Tooltip title={tooltip} mouseEnterDelay={.4} mouseLeaveDelay={.2}>
                {buttoncontent()}
            </Tooltip>
        )
    }
    return (
        buttoncontent()
    )
};

const NwButtonGroup = ({ size, buttons, children, capitalize, ...rest }) => {
    return (
        <StyledGroupButton $capitalize={capitalize} {...rest} >
            {buttons && buttons.map((button, index) => {
                return (<NwButton icon={button.icon} key={`${index}-${button.label}`} label={button.label} onClick={button.action} size={size} disabled={button.disabled} success={button.success} />)
            })}
            {children}
        </StyledGroupButton>
    )
}

const NwMultiButton = ({ options, onChange, size }) => {
    const handleUpdateOptions = index => () => {
        const option = options[index];
        const newOptions = [...options];
        newOptions[index] = {
            ...option,
            value: !option.value
        };
        onChange(newOptions);
    };

    const renderButton = (option, index) => {
        const { icon, iconLabel, value, content } = option;
        return (
            <NwButton
                size={size}
                key={index}
                className='multi'
                onClick={handleUpdateOptions(index)}
                active={value ? 1 : 0}
                label={
                    <FlexContainer>
                        {(icon || iconLabel) &&
                            <FlexContainer justifyContent='center' className='icon'>
                                {iconLabel && iconLabel}
                                {icon && <NwIcon icon={icon} />}
                            </FlexContainer>
                        }
                        {content}
                    </FlexContainer>
                } />
        );
    }

    return (
        <FlexContainer column alignItems='flex-start'>
            {options.map((option, index) => (
                renderButton(option, index)
            ))}
        </FlexContainer>
    );
};

const NwLinkButton = ({ icon, label, children, warning, ...other }) => (
    <StyledButtonLink type="link" $warning={warning} {...other}>{icon && <NwIcon icon={icon} />}{label}</StyledButtonLink>
)

const NwRemoveLinkButton = ({ label = "Remove", onClick, needConfirmation = true, confirmationLabel = "You're permanently removing the selected object(s). Please note that this can't be undone", parentNode, confirmationPlacement = "top", className, ...other }) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

    const handleClick = () => {
        setOpenConfirmDialog(false)
        onClick()
    }

    if (needConfirmation) {
        return (
            <Popover
                getPopupContainer={triggerNode => parentNode ? triggerNode.parentNode : document.body}
                placement={confirmationPlacement}
                arrowPointAtCenter
                title="PLEASE CONFIRM DELETION"
                zIndex={3000}
                content={
                    <>
                        <PopoverMessage>
                            <NwIcon className="danger-icon" icon={duotone("exclamation-triangle")} size="3x" />
                            <p>{confirmationLabel}</p>
                        </PopoverMessage>
                        <PopoverButtons>
                            <NwCancelButton onClick={() => setOpenConfirmDialog(false)} />
                            <NwButton danger icon={light("trash")} label="DELETE" onClick={handleClick} />
                        </PopoverButtons>
                    </>
                }
                trigger="click"
                open={openConfirmDialog}
            >
                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <StyledButtonLink onClick={() => setOpenConfirmDialog(true)}  type="link" danger {...other}>
                        <NwIcon icon={light("trash")} danger />{label}
                    </StyledButtonLink>
                </div>
            </Popover>
        )
    } else {
        return (
            <StyledButtonLink type="link" danger {...other} onClick={onClick}>
                <NwIcon icon={light("trash")} danger />{label}
            </StyledButtonLink>
        )
    }
}

export { NwButton, NwUploadButton, NwAddButton, NwEditButton, NwBackButton, NwRemoveButton, NwMapButton, NwMainButton, NwSaveButton, NwCancelButton, NwButtonGroup, NwMultiButton, NwLinkButton, NwRemoveLinkButton, NwMoreButton };
