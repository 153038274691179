import React from "react";
import {Select} from "antd";

const Option = Select.Option;

const ModelAgencyTypeSelector = ({value, onChange, disabled}) => {
  const options = [
    { value: 'Mother', label: 'MOTHER' },
    { value: 'Placement', label: 'PLACEMENT' },
    { value: 'Other', label: 'OTHER' }
  ];

  return (
    <Select
        autoComplete='off'
        value={value}
        placeholder="Please select"
        onChange={onChange}
        disabled={disabled}
        style={{ width: "100%" }}
    >
        {options.map((el, key) => <Option key={key} value={el.value}>{el.label}</Option>)}
    </Select>
  );
};

export default ModelAgencyTypeSelector;
