import React from 'react'
import { Layout } from 'antd';
import NavigationContext from 'Contexts/NavigationContext'
import NavigationReducer from 'Contexts/NavigationReducer'
import { StateProvider } from 'Contexts/StateProvider'

const { Content } = Layout;

const DocumentLayout = ({ children }) => {
    return (
        <StateProvider initialState={NavigationContext} reducer={NavigationReducer}>
            <Layout className="nw-page">
                <Content style={{ marginTop: 60 }}>
                    {children}
                </Content>
            </Layout>
        </StateProvider>
    )
};

export default DocumentLayout;