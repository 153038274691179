import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getTask = taskId => (
    Axios.get(`/todos/${taskId}`)
);

const useTask = taskId => {
    return useQuery(["ToDo", taskId], () => getTask(taskId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: taskId ? true : false
    });
};

const forceReloadTask = (ID) => {
    queryClient.invalidateQueries(['ToDo', ID])
}

const onDeleteTask = ({ ID }) => (
    Axios.delete(`/todos/${ID}`)
)

const useDeleteTask = () => {
    return useMutation(onDeleteTask, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.todoId) {
                forceReloadTask(variables.todoId);
            }
        }
    })
}

const onDeleteTaskGroup = ({ GroupGUID }) => (
    Axios.delete(`/todos/deletegroup/${GroupGUID}`)
);

const useDeleteTaskGroup = () => {
    return useMutation(onDeleteTaskGroup, {
        onSuccess: (data, variables) => {
        }
    })
}

const onUpdateTask = ({ todoData }) => (
    Axios.put('/todos', todoData)
);

const useUpdateTask = () => {
    return useMutation(onUpdateTask, {
        onSuccess: (data, variables) => {
            forceReloadTask(variables.todoData.ID);
        }
    })
}

const onAddTask = ({ todoData }) => (
    Axios.post('/todos', todoData)
);

const useAddTask = () => {
    return useMutation(onAddTask, {
        onSuccess: (data, variables) => {
            forceReloadTask(data.ID);
        }
    })
};

const onCloneModelsTask = ({ data }) => (
    Axios.post(`/todos/CloneForModels`, data)
);

const useCloneModelsTask = () => {
    return useMutation(onCloneModelsTask, {
        onSuccess: (data, variables) => {
            forceReloadTask(variables.data.EventID);
        }
    })
};

const onUpdateGroupedIndividualTask = ({ todoData }) => (
    Axios.put('/todos/GroupedIndividual', todoData)
);

const useUpdateGroupedIndividualTask = () => {
    return useMutation(onUpdateGroupedIndividualTask, {
        onSuccess: (data, variables) => {
            forceReloadTask(variables.todoData.ID);
        }
    })
};

const onUpdateGroupedCommonTask = ({ todoData }) => (
    Axios.put('/todos/GroupedCommon', todoData)
);

const useUpdateGroupedCommonTask = () => {
    return useMutation(onUpdateGroupedCommonTask, {
        onSuccess: (data, variables) => {
            forceReloadTask(variables.todoData.ID);
        }
    })
};

export {
    useTask,
    useDeleteTask,
    useDeleteTaskGroup,
    useUpdateTask,
    useAddTask,
    forceReloadTask,
    useCloneModelsTask,
    useUpdateGroupedIndividualTask,
    useUpdateGroupedCommonTask
};