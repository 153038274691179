import React from 'react';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import DateSelector from 'Components/Gui/NWForm/DateSelector';


const PeriodSelectorContainer = styled.div`
    div {
        margin-bottom: .25rem;
    }
`

const PeriodSelector = ({ value, labels, timeOnly, dateOnly, enableDates, onChange, labelMinWidth }) => {
    const handleStartDateChange = date => {
        if (timeOnly) {
            onChange({
                ...value,
                startDate: date
            })
        } else {
            if (value.startDate > value.endDate) {
                onChange({
                    ...value,
                    startDate: date,
                    endDate: date
                })
            } else {
                const days = value.endDate.diff(value.startDate, 'days');
                let endDate = date.clone();
                endDate = getMoment(endDate, "DD-MM-YYYY").add(days, 'days');

                onChange({
                    ...value,
                    startDate: date,
                    endDate: endDate
                })
            }
        }
    };

    const handleEndDateChange = date => {
        onChange({
            ...value,
            endDate: date
        })
    };

    const handleDisableStart = checked => {
        onChange({
            ...value,
            enableStart: checked
        })
    };

    const handleDisableEnd = checked => {
        onChange({
            ...value,
            enableEnd: checked
        })
    };

    return (
        <PeriodSelectorContainer>
            <DateSelector
                text={labels ? labels[0] : 'Start'}
                value={value.startDate}
                dateOnly={dateOnly}
                timeOnly={timeOnly}
                disable={enableDates && true}
                enabled={value.enableStart}
                onChange={handleStartDateChange}
                onDisable={handleDisableStart}
                labelMinWidth={labelMinWidth}
            />
            <DateSelector
                text={labels ? labels[1] : 'End'}
                value={value.endDate}
                dateOnly={dateOnly}
                timeOnly={timeOnly}
                disable={enableDates && true}
                enabled={value.enableEnd}
                onChange={handleEndDateChange}
                onDisable={handleDisableEnd}
                labelMinWidth={labelMinWidth}
            />
        </PeriodSelectorContainer>
    );
};

export default PeriodSelector;