import React, { useContext } from "react";
import { Field } from "formik";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";

import { UserContext } from "Contexts/UserContext";
import {
  NWFilterPeriodInput,
  NWEmptyDateInput,
  NWFilterDateInput,
  NWSwitch,
  NWBookerSelector,
  NWGenderSelector,
  NWInputNumber,
  NWInputTag,
  NWSelectCountryMulti,
  NWBooleanSelector,
  NWDepartmentSelector,
  NWFeaturesRangeSlider,
  NWNumericRangeSelector,
  NWFeaturesMultichoice,
  NWFeaturesChoice,
  NWFeaturesTrans,
  NWFeaturesBool,
  NWCitySelectorMulti,
  NWInput,
  NWRatingSelector,
  NWContactSelector,
  NWFilterTypeSelector,
  NWInputTransaction,
  NWCurrencyRange,
  NWCategorySelect,
  NWTransactionFilter,
  NWDatePicker,
  NWStatementContactSelector,
  NWTransactionStatusSelector,
  NWFamilySelector,
  NWLabelSelector,
  NWTaxSelector,
  NWVatSelector,
  NWStatusSelector,
  NWPackageDateTypeSelector,
  NWModelPlayListSelector,
  NWDocumentFilterSelector,
  NWFilterFollowUpsOnLabels,
  NWFilterEventsOnSubTypes,
  NWFilterTravelsOnTransportTypes,
  NWFilterEventsOnSubTypesAndOptionNumber,
  NWCustomerTypeSelector,
} from "Components/Gui/NWForm/NwFormItems";

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ant-form-item {
      margin-bottom: 16px;
  }

  margin-bottom: 16px;
`;
const FilterLabel = styled.div`
  min-width: 160px;
  text-align: left;
`;

const FilterSwitch = styled.div`
   margin: -2px 10px 0;
`;

const FilterController = styled.div`
  flex: 1 0 0;
  padding: 0 4rem 0 1rem;

  .ant-slider-track {
    background-color: ${props => props.disabled && 'rgba(0, 0, 0, 0.25) !important'};
  }
  
  .ant-slider-handle, .ant-slider-dot {
    border-color: ${props => props.disabled && 'rgba(0, 0, 0, 0.25) !important'};
  }
`;

const Filter = ({ label, type, name, values, isFeature, onChange }) => {
    
    const { currentUser } = useContext(UserContext);
    const { accGroupId, nwAgId } = currentUser.ActiveContext;
    
    let component;
    let props = {};
    let checked;
    let switchName;
    let filterName;
    let filterValue;
  
    if (isFeature) {
        checked = values.Features[`${name}`].active;
        switchName = `Features.${name}.active`;
        filterName = `Features.${name}.value`;
        filterValue = values.Features[`${name}`].value;
    } else {
        checked = values[`${name}`].active;
        switchName = `${name}.active`;
        filterName = `${name}.value`;
        filterValue = values[`${name}`].value;
    }
  
    let disabled = !checked;
    
    const NWEventNoSubTypes = () => {
        return (<div></div>)
    }

    switch (type) {
        // case 'date-departure':
        //     component = NWDatePicker;
        //     props.value = filterValue?getMoment(filterValue):null;
        //     props.format = 'MMM DD, YYYY';
        //     break;
        case 'contact-selector':
            component = NWStatementContactSelector;
            break;
        case 'period':
            component = NWFilterPeriodInput;
            break;
        case 'empty-dates':
            component = NWEmptyDateInput;
            break;
        case 'date':
            component = NWFilterDateInput;
            break;
        case 'bookerList':
            component = NWBookerSelector;
            break;
        case 'customerList':
            component = NWContactSelector;
            props.activeTypes = ['cli', 'age','srv']
            break;
        case 'contactList':
            component = NWContactSelector;
            props.activeTypes = ['mod', 'cli', 'age', 'srv']
            break;
        case 'serviceList':
            component = NWContactSelector;
            props.activeTypes = ['srv']
            break;
        case 'clientList':
            component = NWContactSelector;
            props.activeTypes = ['cli']
            break;
        case 'modelList':
            component = NWContactSelector;
            props.activeTypes = ['mod'];
            break;
        case 'agency-selector':
            component = NWContactSelector;
            props.activeTypes = ['age'];
            break;
        case 'model-selector':
            component = NWContactSelector;
            props.activeTypes = ['mod', 'sco'];
            break;
        case 'filter-type':
            component = NWFilterTypeSelector;
            break;
        case 'jobs-tag':
            component = NWInputTag;
            props.contactType = 'Event';
            break;
        case 'projects-tag':
            component = NWInputTag;
            props.contactType = 'Event';
            break;
        case 'customers-tag':
            component = NWInputTag;
            props.contactType = 'Customer';
            break;
        case 'customers-type':
            component = NWCustomerTypeSelector;
            break;
        case 'tag':
            component = NWInputTag;
            props.contactType = 'Model';
            break;
        case 'JobsTransactionStatus':
            component = NWInputTransaction;
            break;
        case 'currency-range':
            component = NWCurrencyRange;
            break;
        case 'currency-range-withtax':
            component = NWCurrencyRange;
            props.taxed = true;
            break;
        case 'boolean':
            component = NWBooleanSelector;
            break;
        case 'event-casting':
            component = NWFilterEventsOnSubTypes;
            props.eventType = 'Casting';
            break;
        case 'event-job':
            component = NWFilterEventsOnSubTypesAndOptionNumber;
            props.eventType = 'Job';
            break;
        case 'event-travel':
            component = NWFilterTravelsOnTransportTypes;
            props.eventType = 'Travel';
            break;
        case 'event-followup':
            component = NWFilterFollowUpsOnLabels;
            break;
        case 'event-nosub':
            component = NWEventNoSubTypes;
            props.eventType = 'NoSub';
            break;
        case 'countriesList':
            component = NWSelectCountryMulti;
            break;
        case 'citiesList':
            component = NWCitySelectorMulti;
            break;
        case 'departmentsList':
            component = NWDepartmentSelector;
            props.acctGroup = accGroupId;
            props.nwAge = nwAgId;
            props.mode = 'multiple';
            break;
        case 'gender':
            component = NWGenderSelector;
            break;
        case 'number':
            component = NWInputNumber;
            break;
        case 'Conversion':
            props.itemid = name;
            component = NWFeaturesRangeSlider;
            break;
        case 'Boolean':
            component = NWFeaturesBool;
            break;
        case 'Multichoice':
            props.itemid = name;
            component = NWFeaturesMultichoice;
            break;
        case 'Choice':
            props.itemid = name;
            component = NWFeaturesChoice;
            break;
        case 'Trans':
            props.itemid = name;
            component = NWFeaturesTrans;
            break;
        case 'Rating':
            props.itemid = name;
            props.simpleMode = true;
            component = NWRatingSelector;
            break;
        case 'Numeric':
            props.itemid = name;
            component = NWNumericRangeSelector;
            break;
        case 'category':
            component = NWCategorySelect;
            break;
        case 'department':
            component = NWDepartmentSelector;
            props.mode = 'single';
            props.activeContext = true;
            props.allAgencies = false;
            break;
        case 'transaction-filter':
            component = NWTransactionFilter;
            break;
        case 'transaction-selector':
            component = NWTransactionStatusSelector;
            break;
        case 'family-selector':
            component = NWFamilySelector;
            break;
        case 'label-selector':
            component = NWLabelSelector;
            break;
        case 'tax-selector':
            component = NWTaxSelector;
            break;
        case 'vat-selector':
            component = NWVatSelector;
            break;
        case 'status-selector':
            component = NWStatusSelector;
            break;
        case 'datetype-selector':
            component = NWPackageDateTypeSelector;
            break;
        case 'model-playlist-selector':
            component = NWModelPlayListSelector;
            break;
        case 'document-filter':
            component = NWDocumentFilterSelector;
            props.filterType = 'document';
            break;
        case 'playlist-filter':
            component = NWDocumentFilterSelector;
            props.filterType = 'playlist';
            break;
        case 'published-filter':
            component = NWDocumentFilterSelector;
            props.filterType = 'published';
            break;
        case '':
        default:
            component = NWInput;
            props.type='text';
            break;
    };

    const checkComponentVisibility = (type) => {
        if (type === 'transaction-filter') return false;
        if (type === 'event-casting') return false;
        if (type === 'event-job') return false;
        if (type === 'event-travel') return false;
        if (type === 'event-followup') return false;
        return true;
    }

    return (
        <FilterRow>
            <FilterSwitch>
                <Field
                    component={NWSwitch}
                    size='small'
                    checked={checked}
                    name={switchName}
                    onAfterChange={value => onChange('active', value)}
                />
            </FilterSwitch>
            <FilterLabel>{label}</FilterLabel>
            {(checkComponentVisibility(type) || checked) && 
                <FilterController disabled={disabled}>
                    <Field
                        component={component}
                        value={filterValue}
                        name={filterName}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        {...props}
                        onAfterChange={value => {
                            if (type === 'Rating') {
                                onChange('value', value, value ? true : false);
                            } else if (type === 'currency-range' || type === 'currency-range-withtax') {
                                onChange('value', value, filterValue.Currency === '' ? false : true);
                            }
                            else {
                                onChange('value', value, true);
                            }
                        }}
                    />
                </FilterController>
            }
        </FilterRow>
    );
};

export default Filter;