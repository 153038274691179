import React, { useState } from "react";
// import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Popover, Badge } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import MiniLoading from 'Components/Gui/MiniLoading';
import ModelBooksToPackage from 'Components/ActivePackages/ModelBooksToPackage';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useActivePackages } from 'Hooks/Packages/UsePackages';
import { getModel } from 'Hooks/Contact/UseContactsList';
import { useUserPermission } from "Contexts/UserContext";
import PermissionBlock from "Components/PermissionBlock";

const StyledBadge = styled.div`
    .ant-badge-count {
        background: ${props => props.theme.PrimaryColor};
    }
    .ant-badge-multiple-words {
        padding: 0 3px;
        margin-top: 2px;
    }
`;

const AddToPackageButton = ({ model, packageId, noBadge, activePackageOnly, children }) => {
    const [playlists, setPlaylists] = useState([]);
    const [isFetched, setIsFetched] = useState(false);
    const [visiblePlaylists, setVisiblePlaylists] = useState(false);
    // const history = useHistory();
    const { data: packages } = useActivePackages();
    //const model = getModel(modelID);
    const modelID = model.ID
    const viewModelBooks = useUserPermission('ViewModelBooks', model.U && model.U.map(user => user.UserID));
    //const viewModelBooks = useUserPermission('ViewModelBooks', [], true);

    const getPlaylists = async () => {
        setPlaylists(await Axios.get(`/models/getPlaylists/${modelID}`))
        setIsFetched(true);
    };

    const handleVisibleChange = visible => {
        if (visible && !isFetched) {
            getPlaylists();
        }
        if (!visiblePlaylists) {
            setVisiblePlaylists(visible);
        }
    };

    const renderPlaylists = () => {
        return (
            <ModelBooksToPackage 
                modelID={modelID} 
                packageId={packageId}
                activePackageOnly={activePackageOnly}
                playlists={playlists}
            />
        );
    };

    // const handleEditBook = book => {
    //     history.push({
    //       pathname: `/models/${modelID}/media`,
    //       state: { bookId: book.ID }
    //     });
    // };

    // const handleDeleteBook = async book => {
    //     try {
    //         await Axios.delete(`/playlists/${book.ID}`);
    //         getPlaylists();
    //     } catch (error) {
    //         console.log("error on deleting book", error)
    //     }
    // }

    const addedPackages = packages ? packages.filter(pack => pack.Models.find(model => model.ModelID === modelID)) : [];

    return (
        <>  
            {viewModelBooks ? 
                <Popover
                    open={visiblePlaylists}
                    overlayStyle={{ minWidth: 400, width: 800, zIndex: 1040 }}
                    title={
                        <FlexContainer fullWidth justifyContent='space-between'>
                            <div>{`${getContactName(modelID)}'s Books`}</div>
                            <NwIcon icon={light('times')} pointer onClick={() => setVisiblePlaylists(false)} />
                        </FlexContainer>
                    }
                    content={!isFetched ? <MiniLoading /> : renderPlaylists()}
                    onOpenChange={handleVisibleChange}
                    trigger='click'
                >
                    {noBadge ? children
                        :
                        <StyledBadge>
                            <Badge count={addedPackages.length} size='small'>
                                {children}
                            </Badge>
                        </StyledBadge>
                    }
                </Popover>
                : 
                <PermissionBlock
                    permission={viewModelBooks}
                    content={
                        noBadge ? children :
                        <StyledBadge>
                            <Badge count={addedPackages.length} size='small'>
                                {children}
                            </Badge>
                        </StyledBadge>
                    } />
            }
        </>
    )
    
};


export default AddToPackageButton