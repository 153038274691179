import React, { useState } from 'react';
import Axios from 'axios';
import { duotone, thin } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import styled from 'styled-components';
import MiniLoading from "Components/Gui/MiniLoading"

const StyledIcon = styled(NwIcon)`
    cursor: pointer;
    font-size: ${props => props.$pixelSize ? props.$pixelSize : 18}px;
    opacity: .4;
    .fa-primary {
        fill: ${props => props.theme.TextColor};
        opacity: 0;
    }
    .fa-secondary {
        fill: ${props => props.theme.TextColor};
        opacity: .5;
    }

    &.todo-icon-done {
        opacity: 1;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.SuccessColor};
            opacity: 1;
        }
    }

    &.todo-icon-half {
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.PrimaryDark};
            opacity: 1;
        }
        opacity: .5;
    }
`



const CheckListCheckBox = ({ checkListItem, objectID, objectType, onStatusChanged, size }) => {
    const [completed, setCompleted] = useState(checkListItem.Done);
    const [isLoading, setLoading] = useState(false);

    const onChangeFunction = (e) => {
        setLoading(true);
        e.stopPropagation();

        const data = {
            CheckListItemID: checkListItem.CheckListItemID,
            Done: !completed
        }

        let urlbase = "/contacts/markchecklistitem";
        if (objectType === "Project") {
            data.ProjectID =  objectID;
            urlbase = "/projects/markchecklistitem";
        } else {
            data.ContactID = objectID;
        }

        Axios.put(urlbase, data)
            .then((data) => {
                setCompleted(!completed)
                onStatusChanged();
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    }

    const getClassName = `todo-icon ${completed && " todo-icon-done"}`
    const iconType = completed ? duotone('circle-check') : thin('circle-check');
    return (
        isLoading
            ?
            <MiniLoading />
            :
            <StyledIcon
                className={getClassName}
                onClick={onChangeFunction}
                icon={iconType}
                $pixelSize={size}
            />
    );

}
export default CheckListCheckBox;
