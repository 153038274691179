import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';

const useTransactionLabels = (family) => {
    const { cachedList } = useContext(ListContext);
    const transactionFamilies = cachedList.transactionFamilies.items;
    const transactionProperties = transactionFamilies.find(fam => fam.Code === family);
    const defaultTransactionLabel = transactionProperties.TransactionLabels.find(label => label.Default);
    const initialTransactionLabel = defaultTransactionLabel ? defaultTransactionLabel : transactionProperties.TransactionLabels[0]
   
    return {
        labels: transactionProperties.TransactionLabels,
        startLabel: initialTransactionLabel
    };
}

export default useTransactionLabels