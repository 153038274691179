import styled from "styled-components";


export const MediaItem = styled.div`
    
    position: relative;
    cursor: pointer;
    height: 180px;
    
    &.video {
        //height: ${props => props.width || 216}px;
        //position: relative;
        //width: ${props => props.height || 324}px;
        //background: rgba(0, 0, 0, 0.65);
        //display: flex;
    }

    &.encoding-video {
        width: 240px;
        cursor: default;



        div.cover-container {
            background: rgba(0, 0, 0, 0.65);

            div.icons-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: #fff;

                h1 {
                    font-size: 1.25rem;
                    margin: 0;
                    font-weight: 500;
                    line-height: 1.5em;
                    color: #fff;
                }
                small {
                    font-size: ${props => props.theme.FontSizeSm};
                    text-transform: uppercase;
                    color: #fff;
                    margin-bottom: 1rem;
                }
            }

            /* .spinner-container {
                font-size: 5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1 1 0;
                width: 100%;
                opacity: .5;
                --fa-animation-duration: 15s;
            } */
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        background-color: rgba(0,0,0,.6);
        transition: opacity 0.4s ease-in-out;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        div.text-container {
            padding: 3rem 1rem 2rem 1rem;
            color: #fff;
            font-size: ${(props) => props.theme.FontSizeSm};
            p {
                margin: .15rem 0;
            }
        }
    }
    
    &:hover {
        .overlay {
            opacity: 1;
        }
    }

    div.cover-container {
        position: relative;
        height: 100%;
        width: 100%;
        width: auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: .25rem 1rem;
        color: #fff;

        div.icons-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            font-size: 1.75rem;
            width: 100%;
        }
        
        div.tags-container {
            padding: 4px;
            max-height: 4rem;
            overflow: hidden;
            width: 100%;
        }
    }
    
`;
