import { filter, find, last, map, take, without } from 'lodash';
import ls from 'local-storage';
import { GetTimestampNumeric } from 'Libs/NwMoment';
import { customerFullName, getContactName, modelFullName } from 'Hooks/Contact/UseContactsList';

const lsVarName = `nw_config_userlogs`

const getLsLogs = () => ls.get(lsVarName)

export const setLsLogs = async val => {
    ls.set(lsVarName, val)
    // await Axios.post('/userclientconfigurations', {
    //     Name: 'logs',
    //     JsonData: JSON.stringify({
    //         logs: val
    //     })
    // });
}

const clearLogs = async () => {
    ls.remove(lsVarName);
    // await Axios.post('/userclientconfigurations', {
    //     Name: 'logs',
    //     JsonData: JSON.stringify({
    //         logs: null
    //     })
    // });
}

export const readUserLogs = () => {
    var objconfig = getLsLogs();
    if (objconfig == null || !Array.isArray(objconfig)) {
        return [];
    } else {
        return objconfig;
    }
}

export const addLog = (objtype, objid, secondaryid, objname) => {
    var objconfig = readUserLogs();
    if (objconfig.length > 0) {
        var ishere = find(objconfig, { t: objtype, id: objid });
        if (ishere) {
            objconfig = without(objconfig, ishere);
        }
    }
    objconfig.push({ t: objtype, id: objid, sid: secondaryid, name: objname, d: GetTimestampNumeric() });
    if (objconfig.length > 50) {
        var lastlogs = last(objconfig, 50);
        objconfig = lastlogs;
    }
    setLsLogs(objconfig)
};

export const removeLogs = type => {
    const logs = readUserLogs();
    setLsLogs(logs.filter(log => log.t !== type));
};

export const addModelLog = (d, area) => {
    addLog(`${area}_model`, d.ID, null, modelFullName(d));
};

export const removeModelLog = (ID, area) => {
    let objconfig = readUserLogs();
    objconfig = objconfig && objconfig.filter(log => !(log.t === `${area}_model` && log.id === ID));
    setLsLogs(objconfig);
};

export const removeStatementLog = (ID) => {
    let objconfig = readUserLogs();
    objconfig = objconfig && objconfig.filter(log => !(log.t === `statement` && log.id === ID));
    setLsLogs(objconfig);
};

export const addCustomerLog = (d, area) => {
    addLog(`${area}_customer`, d.ID, null, customerFullName(d));
};

export const removeCustomerLog = (ID, area) => {
    let objconfig = readUserLogs();
    objconfig = objconfig && objconfig.filter(log => !(log.t === `${area}_customer` && log.id === ID));
    setLsLogs(objconfig);
};

export const addEventLog = (d) => {
    addLog("event", d.ID, d.ProjectID, d.Name);
}

export const addProjectLog = (d) => {
    const customerName = getContactName(d.CustomerID);
    addLog("project", d.ID, null, `${d.Name}|${customerName}|${d.Product}|${d.ProjectCategoryID}`);
}

export const removeProjectLog = (ID) => {
    let objconfig = readUserLogs();
    objconfig = objconfig && objconfig.filter(log => !(log.t === "project" && log.id === ID));
    setLsLogs(objconfig);
};

export const addStatementLog = (d) => {
    const customerName = getContactName(d.ContactID);
    addLog("statement", d.ID, null, `${d.Name} | ${customerName}`);
}

export const addTravelPlanLog = d => {
    addLog("travelPlan", d.ID, null, `${d.Name} | ${d.PrivacyLevelName}`);
}

export const getLastLog = (t) => {
    var logs = readUserLogs();
    var tlogs = filter(logs, { t: t });
    if (tlogs.length > 0) {
        var last = last(tlogs);
        return last.id;
    }
    return 0;
}

export const getLastLogs = (t, n) => {
    var logs = readUserLogs().reverse();
    var tlogs = filter(logs, { t: t });
    if (tlogs.length > 0) {
        var last = take(tlogs, n);
        return last;
    }
    return [];
}

export const getLastLogsArray = (t, n) => {
    var logs = readUserLogs().reverse();
    var tlogs = filter(logs, { t: t });
    if (tlogs.length > 0) {
        var last = take(tlogs, n);
        return map(last, "id");
    }
    return [];
}

export const deleteUserLogs = () => {
    clearLogs()
}
