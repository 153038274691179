import React, { useEffect, useRef } from "react";
import { Input, Switch } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useStateValue } from "Contexts/StateProvider";
import NwIcon from 'Components/Gui/NwIcon';
import NwDivider from "Components/Gui/NwDivider";
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { useHistory } from "react-router";
import { QuickFilterContainer } from "../FilterFormUI";

const QuickFilters = ({
    accounting,
    isMini,
    isMobile,
    onAfterChangeFilters,
    onSetQuickFilter,
    quickFilter,
    tags,
}) => {

    const [{ areaContext }, dispatch] = useStateValue();
    const history = useHistory();

    const nameRef = useRef();

    const sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Agency', value: 'MotherAgency' },
    ];

    const handleChangeFilter = (name, value) => {
        onSetQuickFilter({
            ...quickFilter,
            [name]: value
        })
        if (onAfterChangeFilters) {
            onAfterChangeFilters()
        }
    }

    // useEffect(() => {
    //     if (nameRef.current) {
    //         nameRef.current.focus()
    //     }
    // }, [nameRef.current])

    return (
        <QuickFilterContainer $isMini={isMini}>
            {!isMini &&
                <NwDivider>quick search</NwDivider>
            }
            <div className="quick-filter-line">
                <Input
                    ref={nameRef}
                    allowClear
                    autoComplete="off"
                    placeholder="Filter by name"
                    prefix={<NwIcon icon={light("search")} />}
                    onChange={(e) => {
                        handleChangeFilter('nameFilter', e.target.value)
                    }}
                    value={quickFilter.nameFilter}
                />
            </div>
            {!accounting &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <Switch
                            size="small"
                            checked={quickFilter.onlymine}
                            onChange={(value) => {
                                handleChangeFilter('onlymine', value)
                            }}
                        />
                        <span className="after">mine only</span>
                    </div>
                    {isMini &&
                        <div className="quick-filter-item">
                            <Switch
                                size="small"
                                checked={quickFilter.showQuickFilters}
                                disabled={!quickFilter.showQuickFilters}
                                onChange={(val) => {
                                    if (!val) {
                                        onSetQuickFilter({
                                            ...quickFilter,
                                            showQuickFilters: false,
                                            quickFilters: {}
                                        });
                                        if (onAfterChangeFilters) {
                                            onAfterChangeFilters()
                                        }
                                    }
                                }}
                            />
                            <span className={`after${quickFilter.showQuickFilters ? ' highlighted' : ' muted'}`}>features filtered</span>
                        </div>
                    }
                </div>
            }
            <div className="quick-filter-line">
                <div className="quick-filter-item">
                    <FilterTagSelector
                        tags={tags}
                        selectedOptions={quickFilter.tagFilter}
                        onChange={(_, _tagFilter) => {
                            handleChangeFilter('tagFilter', _tagFilter)
                        }}
                    />
                </div>
            </div>
            <div className="quick-filter-line">
                {!accounting &&
                    <div className="quick-filter-item">
                        <RadioButtonGroup
                            value={quickFilter.intown}
                            onChange={(value) => {
                                handleChangeFilter('intown', value)
                            }}
                            size='small'
                            options={[
                                { label: 'IN', value: 'in' },
                                { label: 'OUT', value: 'out' },
                                { label: 'ALL', value: 'all' },
                            ]}
                        />
                    </div>
                }
                <div className="quick-filter-item">
                    <RadioButtonGroup
                        value={quickFilter.gender}
                        size='small'
                        onChange={(value) => {
                            handleChangeFilter('gender', value)
                        }}
                        defaultValue='All'
                        options={[
                            { label: '', value: 'Female', icon: light('venus') },
                            { label: '', value: 'Male', icon: light('mars') },
                            { label: '', value: 'NonBinary', icon: light('mars-and-venus') },
                            { label: '', value: 'all', icon: light('asterisk') }
                        ]}
                    />
                </div>
            </div>
            {!accounting && !isMini &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <Switch
                            size="small"
                            checked={quickFilter.showQuickFilters}
                            onChange={(val) => {
                                onSetQuickFilter({
                                    ...quickFilter,
                                    showQuickFilters: val,
                                    quickFilters: {}
                                });
                                if (onAfterChangeFilters) {
                                    onAfterChangeFilters()
                                }
                                if (!val) {
                                    history.replace({
                                        pathname: areaContext.area === 'booking' ? '/models' : `/${areaContext.area}-models`,
                                        state: {
                                            contactId: null,
                                            type: null
                                        }
                                    })
                                }
                            }}
                        />
                        <span className="after">filter list on features</span>
                    </div>
                </div>
            }
            {(isMini && !isMobile) &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <span className="before">sort by</span>
                        <RadioButtonGroup
                            value={quickFilter.sort}
                            onChange={(value) => handleChangeFilter('sort', value)}
                            size='small'
                            options={sortOptions}
                        />
                    </div>
                </div>
            }
        </QuickFilterContainer>
    )
}

export default QuickFilters;
