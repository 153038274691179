import React, { useState, useMemo } from 'react';
import { Upload, Typography, Alert } from 'antd';
import styled from 'styled-components';

import NwUpload from "Components/Gui/NwUpload";
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';
import { useStateValue } from 'Contexts/StateProvider';
import { getThumbnailWidth } from "Libs/NwUtils";
import { useContact, forceReloadContact } from 'Hooks/Contact/UseContact';
import ModelMediaItem from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsMedia/ModelMediaItem';

const Label = styled(Typography)`
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 16px;
`;

const StyledAlert = styled(Alert)`
    margin-bottom: 8px;
`;

const thumbheight = 100;

const MeetingModelMedia = ({ modelId, meetingId, onClose }) => {
    const { data: currentContact } = useContact(modelId);
    const [isStartUploadPictures, setStartUploadPictures] = useState(false);
    const [isStartUploadVideos, setStartUploadVideos] = useState(false);
    const [uploadingPictures, setUploadingPictures] = useState([]);
    const [uploadingVideos, setUploadingVideos] = useState([]); 
    const [invalidUploadingPictures, setInvalidUploadingPictures] = useState([]);
    const [invalidUploadingVideos, setInvalidUploadingVideos] = useState([]);
    const [{areaContext}] = useStateValue();

    const afterUploadingPictures = () => { 
        setUploadingPictures([]); 
        setStartUploadPictures(false);
        setInvalidUploadingPictures([]);
    };

    const afterUploadingVideos = () => { 
        setUploadingVideos([]); 
        setStartUploadVideos(false);
        setInvalidUploadingVideos([]);
    };

    const convertObjects = pictures => {
        return pictures.map(function (pic) {
            return {
                id: pic.ID,
                src: pic.Standard.Url,
                thumbnail: pic.Thumbnail.Url,
                thumbnailWidth: getThumbnailWidth(pic, thumbheight),
                thumbnailHeight: thumbheight,
                ...pic
            };
        });
    };

    const contactImages = useMemo(() => {
        let filteredImages = [];
        if (currentContact) {
            filteredImages = currentContact.Images.filter(image => {
                if (image.Visibility.Areas.length === 0 || image.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            });
        }
        return filteredImages
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentContact]);

    const contactVideos = useMemo(() => {
        let filteredVideos = [];
        if (currentContact) {
            filteredVideos = currentContact.Videos.filter(video => {
                if (video.Visibility.Areas.length === 0 || video.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
                })
        }
        return filteredVideos;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact]);

    const picturesToRender = convertObjects(contactImages);

    return (
        <>
            <FlexContainer justifyContent='space-between' mg='0 0 8px'>
                <Label>Pictures</Label>
                <NwUpload
                    primary
                    label='Upload images'
                    maxSize={50}
                    showList={false}
                    disable={isStartUploadPictures}
                    types={["image"]}
                    endpoint={`documents/uploadsingle/2?contactID=${modelId}&scoutingMeetingID=${meetingId}`}
                    onUploadStart={(uploadInfo) => {
                        setStartUploadPictures(true);
                        if (uploadInfo.event) {
                            setUploadingPictures(uploadInfo.fileList)
                        }
                    }}
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingPictures(invalidFiles)}
                    onUploadEnd={() => {
                        forceReloadContact(modelId);
                        afterUploadingPictures();
                    }}
                />
            </FlexContainer>
            {uploadingPictures.length > 0 && 
                <>
                    {invalidUploadingPictures.map(file => {
                        return (
                            <div key={file.uid}>
                                <Alert message={`${file.name} ${file.reason}`} type="error" showIcon />
                            </div>
                        )
                    })}
                    <Upload
                        name="files"
                        showUploadList={{
                            showRemoveIcon: false
                          }}
                        listType="picture"
                        multiple={true}
                        fileList={uploadingPictures}
                    >
                    </Upload>    
                </>
            }
            {picturesToRender.map((picture, index) => {
                return (
                    <div key={`${picture.ID}-model-pictures-${index}`}>
                        <img src={picture.src} height={picture.thumbnailHeight} width={picture.thumbnailWidth} alt=""/>
                    </div>
                )
            })}
            <FlexContainer justifyContent='space-between' mg='16px 0 8px'>
                <Label>Videos</Label>
                <NwUpload
                    primary
                    showList={false}
                    label='Upload video'
                    maxSize={50}
                    disable={isStartUploadVideos}
                    types={["video"]}
                    endpoint={`documents/uploadsingle/3?contactID=${modelId}&scoutingMeetingID=${meetingId}`}
                    onUploadStart={(uploadInfo) => {
                        setStartUploadVideos(true);
                        if (uploadInfo.event) {
                            setUploadingVideos(uploadInfo.fileList)
                        }
                    }}
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingVideos(invalidFiles)}
                    onUploadEnd={() => {
                        forceReloadContact(modelId);
                        afterUploadingVideos();
                    }}
                />
            </FlexContainer>
            {uploadingVideos.length > 0 && 
                <>
                    {invalidUploadingVideos.map(file => {
                        return (
                            <StyledAlert key={file.uid} message={`${file.name} ${file.reason}`} type="error" showIcon />
                        )
                    })}
                    <Upload
                        name="files"
                        showUploadList={{
                            showRemoveIcon: false
                          }}
                        listType="picture"
                        multiple={true}
                        fileList={uploadingVideos}
                    >
                    </Upload>    
                </>
            }
            {contactVideos.map(video => {
                return (
                    <ModelMediaItem 
                        key={video.ID}
                        media={video}
                        type="video"
                    />
                )
            })}
            <FlexContainer mg='8px 0 0'>
                <NwButton label='Close' primary onClick={onClose} />
            </FlexContainer>
        </>
    );
};

export default MeetingModelMedia;