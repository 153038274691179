import React, { useMemo, useState, useEffect } from "react";
import { Card, Popover, Spin, Tag } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwTag } from "Components/Gui/NwTag.js"
import AddToFolderButton from 'Components/OpenFolders/AddToFolderButton'
import AddToPackageButton from 'Components/ActivePackages/AddToPackageButton'
import AvatarBg from 'Components/Gui/AvatarBg';
import NwIcon from "Components/Gui/NwIcon";
import ContactUsers from 'Components/ContactUsers/ContactUsers';
import ModelInTown from 'Components/ModelInTown/ModelInTown';
import { useStateValue } from 'Contexts/StateProvider';
import ModelCalendarEvents from 'Components/ModelSelector/ModelsWall/ModelComponents/ModelCalendarEvents';
import ModelDetailsHeaderAgencies from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import MainFeatures from "Components/Features/MainFeatures";
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import usePlaceholderImageFixed from "Hooks/UsePlaceholderImageFixed";
import { showDateNoTime } from 'Libs/NwMoment';
import { modelFullName } from "Hooks/Contact/UseContactsList";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import MiniLoading from 'Components/Gui/MiniLoading';
import { useAddModelToDate, forceReloadEvent } from 'Hooks/Event/UseEvent';
import Title from "antd/lib/typography/Title";
//import ModelToEventDates from 'Components/QuickAddModels/ModelToEventDates/ModelToEventDates';
import ContactAlertPopover from "Components/ContactAlert/ContactAlertPopover";
import { ContactContainer, Counter, InTownPanel, LoadingContainer, StyledCard } from "./ModelCardUI";
import { isModelBusy } from "Libs/ModelAvailability";


const selectionIconstyle = {
    position: 'absolute',
    left: '8px',
    top: '8px',
    fontSize: '24px',
    zIndex: 1
}

const ModelCard = ({
    addModelProps,
    connections,
    disabled,
    displayBookPackage,
    displayConnections,
    isDrawer,
    isSelected,
    model,
    modelEventsInfo,
    multipleSelection,
    onModelClick,
    onSelect,
    packageBooks,
    packageId,
    scope,
}) => {
    const [loading, setLoading] = useState(false);
    const { Meta } = Card;
    const [{ areaContext }] = useStateValue();
    const { CG, ID, T, ST, G } = model;
    const [urlbg, isPlaceholder] = usePlaceholderImageFixed(CG, ST)
    const modelBooks = displayBookPackage ? packageBooks.filter(pack => pack.ContactID === model.ID) : [];
    const [isBusy, setIsBusy] = useState(false);
    const [isFetched, setFetched] = useState(false);
    const breakpoints = useNwBreakPoints();
    const [isOpenModelDrawer, setIsOpenModelDrawer] = useState(false);
    const [visibleEventDates, setVisibleEventDates] = useState(false);
    const addible = addModelProps && addModelProps.addible;
    const addibleModelToEvent = addModelProps && addModelProps.addibleModelToEvent;
    const onAddModelToEvent = addModelProps && addModelProps.onAddModelToEvent;
    const eventData = addModelProps && addModelProps.eventData;
    const eventDates = eventData ? eventData.EventDates : [];
    const [isAdding, setIsAdding] = useState(false);

    const { mutateAsync: onAddModelToDate } = useAddModelToDate();

    useEffect(() => {
        if (modelEventsInfo && modelEventsInfo.ModelsInvolved.find(idModel => idModel === ID)) {
            setIsBusy(isModelBusy(modelEventsInfo.Events, ID))
            setFetched(true);
        } else {
            if (modelEventsInfo) {
                setFetched(true);
            }
            setIsBusy(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelEventsInfo]);

    const handleVisibleChange = async visible => {
        setVisibleEventDates(visible);
    };

    const handleOpenModelDrawer = () => {
        setIsOpenModelDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setIsOpenModelDrawer(false);
    };

    const handleAddModelToEvent = async () => {
        setIsAdding(true);
        try {
            await onAddModelToEvent(ID);
            setIsAdding(false);
        } catch (error) {
            console.log('ant : Add Model To Event Error => ', error);
            setIsAdding(false);
        }
    };

    const handleAddModelToEventDate = async () => {
        setIsAdding(true);
        try {
            await onAddModelToDate({ modelId: model.ID, eventDateId: eventDates[0].ID });
            setIsAdding(false);
            await forceReloadEvent(eventData.ID);
        } catch (error) {
            console.log('ant : Add Model To Event Date Error => ', error);
            setIsAdding(false);
        }
    };

    const { visibleDates, addedDates } = useMemo(() => {
        let dates = eventDates ? [...eventDates] : [];
        dates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);
        const visibleDates = dates.filter(eventDate => !eventDate.EventDatesModels.find(model => model.ModelID === ID));
        const addedDates = dates.filter(eventDate => eventDate.EventDatesModels.find(model => model.ModelID === ID));
        return { visibleDates, addedDates };
    }, [eventDates, ID]);

    const getCardActions = () => {
        const actions = []
        if (!isDrawer) {
            if (multipleSelection && breakpoints > 3) {
                actions.push(isSelected ? <NwIcon icon={solid('check-circle')} onClick={() => { onSelect(ID) }} primary /> : <NwIcon icon={light("check-circle")} onClick={() => { onSelect(ID) }} />)
            }
            actions.push(<AddToFolderButton ids={[ID]} type='model' showBadge />)
            actions.push(<AddToPackageButton model={model} activePackageOnly ><NwIcon icon={light("book-arrow-right")} /></AddToPackageButton>)
        }
        actions.push(<NwIcon icon={light('sidebar-flip')} onClick={handleOpenModelDrawer} />);
        // actions.push(<ModelAgenda modelId={model.ID} />);
        return actions
    };

    const count = useMemo(() => {
        return displayConnections ? connections.filter(item => item.Connection.ContactID === ID).length : 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayConnections, connections]);

    const ModelNameText = ({ arrayname }) => (
        <>
            {arrayname[0]}
            <br />
            {arrayname[1].trim() ? arrayname[1] : <br />}
        </>
    )

    const ModelNameCard = ({ model }) => {
        const arrayname = modelFullName(model, false, true)
        if (modelEventsInfo) {
            return (
                <div className="model-name-and-calendar">
                    <ModelCalendarEvents
                        modelId={model.ID}
                        modelEventsInfo={modelEventsInfo}
                        isFetched={isFetched}
                        isBusy={isBusy}
                    />
                    <Title><ModelNameText arrayname={arrayname} /></Title>
                </div>
            )
        }
        return (<Title><ModelNameText arrayname={arrayname} /></Title>)
    }

    const renderModelCover = () => {
        return (
            <div className='model-card-top'>
                {displayBookPackage && modelBooks.length > 0 && <Counter>{modelBooks.length}</Counter>}
                {count && count > 0 ?
                    <Counter>{count}</Counter>
                    : null}
                {(isSelected && multipleSelection) && <NwIcon icon={solid('check-circle')} style={selectionIconstyle} primary />}
                <div className="model-cover" onClick={() => { multipleSelection ? onSelect(ID) : onModelClick(ID, true, disabled) }}>
                    <AvatarBg
                        src={urlbg}
                        isPlaceholder={isPlaceholder} size={breakpoints < 4 ? '18rem' : '18rem'}
                        disabled={disabled} />
                    {!multipleSelection &&
                        <div className='hover-container'>
                            <MainFeatures contact={model} />
                        </div>
                    }
                    {eventDates.length > 0 &&
                        <div className='model-event-dates'>
                            {visibleDates.length === 0 ? <Tag color="blue">all dates</Tag>
                                : addedDates.length > 3 ? <Tag color="blue">{`${eventDates.length - visibleDates.length} dates`}</Tag>
                                    : addedDates.map(eventDate =>
                                        <Tag color="blue" key={eventDate.ID}>{showDateNoTime(eventDate.FromDate)}</Tag>
                                    )
                            }
                        </div>
                    }
                    {addibleModelToEvent && !disabled &&
                        <div className='model-hover-container' onClick={handleAddModelToEvent}>
                            {isAdding ?
                                <LoadingContainer>
                                    <MiniLoading />
                                </LoadingContainer>
                                : <NwIcon icon={light('plus')} size='lg' style={{ fontSize: 48 }} />
                            }
                        </div>
                    }

                    {/* TODO: remove with QUICK ADD MODELS FUNCTIONALITY. CHECK USELESS PROPS */}
                    {/* {addible && (!disabled || eventData) && ((eventDates && visibleDates.length > 0) || !eventDates) &&
                        <>
                            {(eventDates.length > 1 || (eventData && eventData.Type === 'Job')) ?
                                <Popover
                                    zIndex={2050}
                                    placement="topRight"
                                    open={visibleEventDates}
                                    content={
                                        <ModelToEventDates
                                            modelId={ID}
                                            eventDates={eventDates}
                                            eventData={eventData}
                                            onHidePopover={() => { }} />
                                    }
                                    trigger="click"
                                    onOpenChange={visible => handleVisibleChange(visible)}
                                >
                                    <div className='model-hover-container'>
                                        {isAdding ?
                                            <LoadingContainer>
                                                <MiniLoading />
                                            </LoadingContainer>
                                            : <NwIcon icon={light('plus')} size='lg' style={{ fontSize: 48 }} />
                                        }
                                    </div>
                                </Popover>
                                :
                                <div className='model-hover-container' onClick={handleAddModelToEventDate}>
                                    {isAdding ?
                                        <LoadingContainer>
                                            <MiniLoading />
                                        </LoadingContainer>
                                        : <NwIcon icon={light('plus')} size='lg' style={{ fontSize: 48 }} />
                                    }
                                </div>
                            }
                        </>
                    } */}
                </div>

                <div className="tag-container">
                    {T.map((tag, index) => {
                        return <NwTag type="dark" key={`${tag.ID}-${index}`}>{tag.Name}</NwTag>
                    })}
                </div>
            </div>
        )
    }

    const renderModelCard = () => {
        return (
            <StyledCard
                $status={isFetched ? (isBusy ? 'busy' : 'no-busy') : ''}
                hoverable
                className={(multipleSelection && isSelected) ? "selected" : null}
                cover={renderModelCover()}
                actions={!multipleSelection && getCardActions()}
            >
                <Meta
                    title={
                        <div className="model-name-container">
                            <ModelNameCard model={model} />
                            {model.AL &&
                                <ContactAlertPopover big contact={model} />
                            }
                        </div>
                    }
                    description={
                        <>
                            <ModelDetailsHeaderAgencies readOnly={multipleSelection || isDrawer} model={model} />
                            <ContactContainer>
                                <ContactUsers users={model.U} contactID={model.ID} readOnly={true} selected={isSelected} />
                            </ContactContainer>
                            <InTownPanel onClick={event => {
                                if (!multipleSelection && !isDrawer) {
                                    event.stopPropagation();
                                }
                            }}>
                                <ModelInTown plainMode={multipleSelection || isDrawer} modelID={model.ID} modelInTownSchedule={model.ITS} />
                            </InTownPanel>
                        </>
                    }
                    onClick={() => {
                        multipleSelection ? onSelect(ID) :
                            onModelClick(ID, true, disabled)
                    }}
                />
                {isOpenModelDrawer &&
                    <ModelDrawer
                        modelId={model.ID}
                        hideCalendar={areaContext.area === 'scouting'}
                        showRating={areaContext.area === 'scouting'}
                        onClose={handleCloseModelDrawer} />
                }
            </StyledCard>
        )
    };

    return (
        <Spin spinning={loading}>
            {displayBookPackage && !(isDrawer && packageId) ?
                <AddToPackageButton
                    noBadge
                    model={model}
                    packageId={packageId}
                >
                    {renderModelCard()}
                </AddToPackageButton>
                : renderModelCard()}
        </Spin>
    )
}

export default ModelCard;
