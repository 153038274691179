import React from 'react';
import styled from 'styled-components';
import NwIcon from './NwIcon';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const StyledDiv = styled.div`
    opacity: ${props => props.disabled ? 0.4 : 1};
    width: ${props => props.portrait ? props.width : props.side};
    height: ${props => props.portrait ? props.height : props.side};
    position: relative;
    img {
        object-fit: cover;
        object-position: top center;
        width: 100%;
        height: 100%;
        border-radius: ${props => (props.$shape === "round") ? "50%" : "0"};
        padding: 0;
        background-color: transparent;
        opacity: ${props => props.isPlaceholder ? ".6" : "1"};
    }
    .sup-icon {
        position: absolute;
        left: 0;
        top: -6px;
        font-size: 1.5rem;
        color: ${props => props.theme.PrimaryColor};

        --fa-primary-color: #fff;
        --fa-secondary-color: ${props => props.theme.PrimaryColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
    }
`
const AvatarBg = ({
    check,
    disabled,
    height,
    isPlaceholder,
    onClick,
    portrait,
    shape = "square",
    size,
    src,
    width,
}) => {

    let side = "75px"
    if (size) {
        switch (size) {
            case "sm":
                side = "40px"
                break;
            case "lg":
                side = "120px"
                break;
            default:
                if (typeof size === "number") {
                    side = size + "px";
                } else {
                    side = size;
                }
                break;
        }
    }

    return (
        <StyledDiv
            disabled={disabled}
            height={height}
            isPlaceholder={isPlaceholder}
            onClick={onClick}
            portrait={portrait}
            $shape={shape}
            side={side}
            width={width}
        >
            <img src={src} alt="" />
            {check && 
                <div className="sup-icon">
                    <NwIcon 
                        icon={duotone("circle-check")} 
                    />
                </div>
            }
        </StyledDiv>
    )
}
export default AvatarBg;