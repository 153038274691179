import React, { useContext } from "react";
import { Popover } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { faThumbsUp } from "@fortawesome/pro-light-svg-icons";
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ListContext } from 'Contexts/ListContext';
import { showDate } from "Libs/NwMoment";
import useEventStyle from 'Hooks/UseEventStyle';

const EventModelDateContainer = styled(Popover)`
  min-width: 60px;
  position: relative;
  border: ${props => props.theme.DefaultBorder};
  opacity: ${props => props.isInvolved ? '1' : '0.25'};
  margin-top: 8px;
  .from-date, .to-date {
    white-space: nowrap;
    font-weight: 700;
    text-align: center;
    ${props => props.isCanceled ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
    padding: 10px;
  }
  .project-event-info > div {
    white-space: nowrap;
  }
  .event-box {
    height: 15px;
    background: ${({ borderColor }) => borderColor};
  }
  .header {
    position: absolute;
    top: -18px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    left: 0px;
    padding: 0px 15px;
  }
`;

const iconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 8px;
  border-radius: 50%;
`;

const PopoverContent = styled.div`
  .type {
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const OptionNumberIconContainer = styled.div`
  ${iconStyle};
  right: -5px;
  background-color: ${props => props.isConfirmed ? props.baseColor : props.secondaryColor};
  color: ${props => props.isConfirmed ? 'white' : 'inherit'}; 
`;

const AcknowledgmentIconContainer = styled.div`
  ${iconStyle};
  left: -5px;
  background-color: ${props => props.theme.SuccessColor}; 
  color: white;
`;

const isOptionNumberExists = (model, event) => {
  if (event.TypeName === 'Casting') {
    return false;
  }
  if (model && model.OptionNumber && model.OptionNumber !== 0) {
    return true;
  }
  return false;
}

const ProjectEventModelDate = ({ eventDate, model }) => {
  const { cachedList } = useContext(ListContext);
  const eventStyle = useEventStyle(eventDate.event);
  const eventStyleForPointerNumber = cachedList.eventTypes.items.find((et) => (et.id === 20));
  return (
    <EventModelDateContainer
      borderColor={eventStyle.backgroundColor}
      isInvolved={!!model}
      isCanceled={model && model.Cancel}
      content={(
        <PopoverContent>
          <div className="type">
            {eventStyle.name}
          </div>
          <div className="title">
            {eventDate.event.Name}
          </div>
        </PopoverContent>
      )}
    > 
      <div className='event-box' />
      <div className="header">
        {
          model && model.AcknowledgmentName !== 'NotChecked' && (
            <AcknowledgmentIconContainer>
              {model.Acknowledgment === 'Checked' ?
                <NwIcon icon={light('check')} /> : model.AcknowledgmentValue
              }
            </AcknowledgmentIconContainer>
          )
        }
        {
          isOptionNumberExists(model, eventDate.event) && (
            <OptionNumberIconContainer isConfirmed={model.OptionNumber === 10} baseColor={eventStyleForPointerNumber.baseColor} secondaryColor={eventStyleForPointerNumber.secondaryColor}>
              {
                model.OptionNumber === 10 ? (
                  <NwIcon icon={light("thumbs-up")} />
                ) : model.OptionNumber
              }
            </OptionNumberIconContainer>
          )
        }
      </div>
      <div className="from-date">
        <div>{showDate(eventDate.FromDate, 'onlyDay')}</div>
        <div>{showDate(eventDate.FromDate, 'onlyMonth')}</div>
      </div>
    </EventModelDateContainer>
  );
};

// ProjectEventModelDate.propTypes = {
//   eventDate: PropTypes.shape({
//     ID: PropTypes.number.isRequired,
//     FromDate: PropTypes.string,
//     event: PropTypes.shape({
//       ID: PropTypes.number.isRequired
//     })
//   }),
//   model: PropTypes.shape({
//     ID: PropTypes.number.isRequired,
//   })
// }

export default ProjectEventModelDate;
