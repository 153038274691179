import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Axios from "axios";

import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import StyleVariables from 'Components/Gui/StyleVariables';
import { getBookCover } from 'Libs/NwUtils';
import AvatarBg from 'Components/Gui/AvatarBg';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from "Components/Gui/NwButton";
import PlayListInfoDrawer from 'Components/MediaDrawer/PlayListInfoDrawer';
import PlayListPreviewDrawer from 'Components/MediaDrawer/PlayListPreviewDrawer';
import { onUpdatePlaylists } from "Hooks/Contact/UseContactPlaylists"
import CreatedBy from 'Components/CreatedBy/CreatedBy';

const StyledList = styled(List)`
    padding: ${props => !props.$mobileView && '0.5rem 0.75rem'};
    .ant-list-item-meta {
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                text-transform: uppercase;
                line-height: 18px;
                margin-bottom: 0;
            }
        }
    }
    .ant-list-item {
        .ant-list-item-meta-avatar {
            img {
                border: 2px solid transparent;
            }
        }
        &:hover {
            .ant-list-item-meta-avatar {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .preview-button {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .info-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const BookTitle = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: auto;
    .badge {
        width: .5rem;
        height: .5rem;
        min-width: 0.5rem;
        min-height: 0.5rem;
        background: ${StyleVariables.DangerColor};
        margin: auto .5rem auto 0;
        border-radius: 100%;
    }
    .book-name {
        font-weight: 500;
        font-size: .85rem;
    }
`;

const ListItem = styled(List.Item)`
    &:hover {
        background-color: ${props => props.$isDrawer ? "none" : props.theme.SelectedBackgroundColor};
    }
    cursor: pointer;
`;

const BookAuthorText = styled.div`
    font-size: 0.6em;
    text-transform: uppercase;
    margin-top: .3em;
`;

const MediaBooks = ({ documentID, contactID }) => {
    const [bookPreview, setBookPreview] = useState(null);
    const [bookInfo, setBookInfo] = useState(null);
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [playlists, setPlaylists] = useState([]);
    const breakpoints = useNwBreakPoints();
    
    const getPlaylists = async () => {
        const playlistsdata = await Axios.get(`/documents/playlists/${documentID}`)
        setPlaylists(playlistsdata);
    }

    useEffect(() => {
        if (documentID) {
            getPlaylists()
        }
    }, [documentID])
    
    const showBookPreview = (book) => {
        setBookPreview(book);
        setIsBookDrawer(true);
    }

    const showBookInfo = (book) => {
        setBookInfo(book);
        setIsBookDrawer(true);
    }

    const onDeleteBook = async book => {
        try {
          await Axios.delete(`/playlists/${book.ID}`);
          onUpdatePlaylists(contactID);
          getPlaylists();
        } catch (error) {
          console.log("error on deleting book", error);
        }
    }

    return (
        <NwCard
            icon={light("books")}
            title="Books"
        >
            <StyledList
                itemLayout="horizontal"
                $mobileView={breakpoints === 1}
                dataSource={playlists}
                renderItem={book => {
                    const { Divisions } = book;
                    return (
                      <ListItem
                            actions={[
                                <FlexContainer style={{margin: "0 0 auto 1rem"}}>
                                    <NwButton
                                        icon={solid("play")}
                                        onClick={() => showBookPreview(book)}
                                        className="preview-button"
                                    />
                                    <NwButton
                                        icon={light("circle-info")}
                                        onClick={() => showBookInfo(book)}
                                        className="info-button"
                                    />
                                </FlexContainer>
                            ]}
                            className="ant-list-item-top"
                            style={{ padding: "8px 0" }}
                        >
                            <List.Item.Meta
                                onClick={() => {
                                    showBookPreview(book)
                                }}
                                style={{ minHeight: "85px", overflow: "hidden" }}
                                avatar={<AvatarBg src={getBookCover(book)} />}
                                title={
                                    <BookTitle>
                                        {Divisions.length ? <div className="badge"/> : (null)}
                                        <div className="book-name">{book.Name}</div>
                                    </BookTitle>
                                }
                                description={breakpoints !== 1 && 
                                    <FlexContainer column justifyContent='space-between' alignItems='flex-start'>
                                        <BookAuthorText>
                                            <CreatedBy object={book} short textonly />
                                        </BookAuthorText>
                                        {Divisions.length ?
                                            <small>
                                                published {Divisions.length} in website{Divisions.length > 1 ? "s" : ""}
                                            </small> : (null)
                                        }
                                    </FlexContainer>
                                }
                            />
                      </ListItem>
                    )
            }}
              />
            {/* {renderContent()} */}
            {isBookDrawer && bookPreview &&
                <PlayListPreviewDrawer
                    playlist={bookPreview}
                    contactID={contactID}
                    onClose={() => {
                        setIsBookDrawer(false);
                        setBookPreview();
                    }}
                />
            }
            {isBookDrawer && bookInfo &&
                <PlayListInfoDrawer
                    playlist={bookInfo}
                    contactID={contactID}
                    onEditPlaylist={() => {}}
                    onClose={() => {
                        setIsBookDrawer(false);
                        setBookInfo();
                    }}
                    onUpdatePlaylists={() => {
                        onUpdatePlaylists(contactID);
                        getPlaylists()
                    }}
                    onDeletePlaylists={onDeleteBook}
                    onPublishBook={() => {}}
                />
            }
        </NwCard>
    )
};

export default MediaBooks;