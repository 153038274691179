import React from 'react';
import styled from 'styled-components';
import useEventStyle from 'Hooks/UseEventStyle';
import { showDate } from "Libs/NwMoment";
import FlexContainer from 'Components/Gui/FlexContainer';
import VerticalDate from 'Components/Dates/VerticalDate';
import VerticalPeriod from 'Components/Dates/VerticalPeriod';

const EventHeader = styled.div`
    height: 10px;
    background: ${props => props.background};
`;

const ProjectEventContainer = styled.div`
    min-height: 90px;
    border: ${props => props.theme.DefaultBorder};
    cursor: pointer;
    opacity: ${props => props.isSelected ? '1' : '0.5'};
    
    .project-event-period {
        min-height: 80px;
        min-width: 80px;
        padding: 8px;
        border-right: ${props => props.theme.DefaultBorder};
    }
    
    .project-event-info {
        padding: 8px;
        min-width: 140px;
        min-height: 80px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .type-name {
            text-transform: uppercase;
            font-size: .8rem;
        }
        .event-name {
            font-size: 1rem;
            font-weight: 600;
            padding-right: 1em;
        }
        .model-count {
            text-transform: uppercase;
            font-size: .8rem;
        }
    }
    .project-event-info > div {
        white-space: nowrap;
    }
`;

const ProjectTimelineEvent = ({ event, onSelectEvent, isSelected }) => {
    const eventstyle = useEventStyle(event);
    
    const isLongEvent = (startDate, endDate) => {
        return endDate && showDate(startDate, 'notime') !== showDate(endDate, 'notime')
    };

    const renderEventPeriod = (startDate, endDate) => {
        return isLongEvent(startDate, endDate) ?
            <VerticalPeriod startDate={startDate} endDate={endDate} size='small' noPadding />
            :
            <VerticalDate date={startDate} showDay size="small" />
    }

    const handleSelectEvent = (event) => {
        if (!event.ID) {
            onSelectEvent(event.GroupedEventIDs[0], event.elType)
        } else {
            onSelectEvent(event.ID, event.elType)
        }
        
    }

    const renderEventCard = () => {
        switch (event.elType.toLowerCase()) {
            case "event":
                return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.FirstDate, event.LastDate)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Name}</div>
                            <div className="model-count">{event.NumModels} model{event.NumModels > 1 ? 's' : ''}</div>
                        </div>
                    </>
                );
            case "travel":
                const travelModels = event.Models ? event.Models.length : 1;
                return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.StartDateTime, event.ArrivalDateTime)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Title}</div>
                            <div className="model-count">{travelModels} model{travelModels > 1 ? 's' : ''}</div>
                        </div>
                    </>
                )
            case "accommodation":
                const accommodationModels = event.Models ? event.Models.length : 1;
                return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.FromDate, event.ToDate)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Title}</div>
                            <div className="model-count">{accommodationModels} model{accommodationModels > 1 ? 's' : ''}</div>
                        </div>
                    </>
                )
            case "followup":
                const followupModels = event.Models ? event.Models.length : 1;
               return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.StartDate, event.EndDate)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Title}</div>
                            <div className="model-count">{followupModels} model{followupModels > 1 ? 's' : ''}</div>
                        </div>
                    </>
                )
            case "meeting":
                return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.StartDate, event.EndDate)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Title}</div>
                            <div className="model-count">&nbsp;</div>
                        </div>
                    </>
                )
            case "package":
                return (
                    <>
                        <div className="project-event-period">
                            {renderEventPeriod(event.DateCreated)}
                        </div>
                        <div className="project-event-info">
                            <div className="type-name">{eventstyle.name}</div>
                            <div className="event-name">{event.Name}</div>
                            <div className="model-count">{event.Models.length} model{event.Models.length > 1 ? 's' : ''}</div>
                        </div>
                    </>
                )
            default:
                return null;
        }
    }
    return (
        <ProjectEventContainer key={event.ID} projectColor={eventstyle.backgroundColor} isSelected={isSelected} onClick={() => handleSelectEvent(event)}>
            <EventHeader background={eventstyle.backgroundColor} />
            <FlexContainer alignItems='flex-start'>
                {renderEventCard(event)}
            </FlexContainer>
        </ProjectEventContainer>
    )
}

export default ProjectTimelineEvent;