import React from 'react';
import { List } from 'antd';

import NwDrawer from 'Components/Gui/NwDrawer';
import EmptyState from 'Components/Gui/EmptyState';
import { showDateNoTime } from 'Libs/NwMoment';

const ExistingRoomLodging = ({ accommodations, onClose }) => {
    return (
        <NwDrawer
            title="Model's Room Lodgings"
            onClose={onClose}>
            {accommodations.length === 0 ?
                <EmptyState message='No Room Lodging' />
                : <List
                    itemLayout="horizontal"
                    dataSource={accommodations}
                    renderItem={accommodation => {
                        return (
                            <List.Item className="ant-list-item-top">
                                <List.Item.Meta
                                    title={`${accommodation.Room && accommodation.Room.Name}`}
                                    description={
                                        <>
                                            <div>{accommodation.Service ? accommodation.Service.Name : "no service"}</div>
                                            <div>{`${showDateNoTime(accommodation.FromDate)} / ${showDateNoTime(accommodation.ToDate)}`}</div>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    }
                />
            }
        </NwDrawer>
    )
};

export default ExistingRoomLodging;