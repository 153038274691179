import styled from 'styled-components';

export const EventContent = styled.div`
    padding: 1rem .5rem .25rem .5rem;
    min-height: 3rem;
    opacity: ${props => props.$isAvailable && 0.5};
    
    // ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}

    /* div {    
        ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
    } */
    .event-tag-title {
        padding-bottom: 1rem;
        
        .event-type-tag-container {
            display: inline;
            margin-right: 8px;

            .ant-tag {
                margin-right: 0;
            }
        }

        .event-title {
            display: inline;
            font-weight: bold;
            text-transform: uppercase;
            font-size: .95rem;
        }
    }

    small {
      display: block;
      font-weight: normal;
      font-size: .75rem;
    }

    .event-tag-title {
      
    }
    .event-dates {
        .event-date {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: ${props => props.theme.DefaultBorder};
            padding-top: .5rem;

            .event-date-left {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                div.event-time {
                    width: 20rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2rem;

                    p:first-child {
                        font-weight: bold;
                    }
                }
            }

            &.cancelled {
                div.event-time {
                    ${props => props.theme.RedLineThrough(1)}
                }
            }

            .event-date-right {

                .event-date-model {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: .5rem;
                    
                    .switch-container {
                        width: 4rem;
                    }

                    .event-date-model-name {
                        display: block;
                        text-transform: capitalize;
                        font-size: .85rem;
                    }

                    &.cancelled {
                        .event-date-model-name {
                            ${props => props.theme.RedLineThrough(1)}
                        }
                    }
                    &.option {
                        .event-date-model-name {
                            opacity: .6;
                        }
                    }
                }

                
            }
        }
    }
`;

export const SendProjectDrawerContainer = styled.div`
    opacity: ${props => props.isSending && 0.4};

    .email-options {
        small {
            text-transform: uppercase;
            display: block;
            padding-bottom: .15rem;
            font-size: ${props => props.theme.FontSizeSm};
        }
        .email-options-subject {
            padding: .5rem 0 1rem 2rem;
            max-width: 50rem;
        }
        .email-options-message {
            padding: .5rem 0 1rem 2rem;
            max-width: 50rem;
        }
        .email-options-signature {
            padding: .5rem 0 1rem 2rem;
            max-width: 50rem;
        }
        .email-options-acknowledgement {
            padding: .5rem 0 1rem 2rem;
        }
    }

    .project-general-documents {
        .project-general-document {
            padding: .25rem .5rem .25rem 2rem;
            label.ant-checkbox-wrapper {
                font-size: ${ props => props.theme.FontSizeSm };
                text-transform: uppercase;

                svg {
                    margin: 0 .5rem;
                }
            }
        }
    }

    .export-options {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .export-options-models-selector {
        }
        .export-options-tools {

        }
    }

    .buttons-bar {
        margin-top: 1rem;
    }
`;

export const EmailItem = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: ${props => props.theme.DefaultBorder};
    padding: 1rem 2rem;


    .model-cover {
        padding: 0 1rem;
    }
    .model-details {
        flex: 1 1 0;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem;
        h2 {
            font-size: ${props => props.theme.FontSizeLg};
            font-weight: 500;
            margin-bottom: .25rem;
            text-transform: capitalize;
        }

        small {
            display: block;
            font-style: italic;
        }
        p {
            margin: 0;
        }
        .mail-addresses-container {
            .mail-address {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin: .5rem 0 .75rem 0;
                padding-left: .8rem;

                &.warning {
                    color: ${props => props.theme.DangerColor};
                }

                .icon-container {
                    font-size: 1rem;
                    padding-right: .45rem;
                }
                p {
                    text-transform: lowercase;
                    padding: 0 2rem 0 1rem;
                }
                .ant-btn {
                    line-height: 2;
                }
                span.ccs {
                    font-size: ${props => props.theme.FontSizeSm};
                    display: block;
                    padding: 0;
                    margin: 0;
                    line-height: 1;
                }
            }
        }

        .model-documents-container {
            .model-document {
                padding: .25rem .5rem;
                label.ant-checkbox-wrapper {
                    font-size: ${ props => props.theme.FontSizeSm };
                    text-transform: uppercase;

                    svg {
                        margin: 0 .5rem;
                    }
                }
            }
        }
    }
    .model-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 2rem;

        .icon-container {
            font-size: 3rem;
        }
    }
`


