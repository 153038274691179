export const PACKAGES_FILTERS = {
    reducerPrefix: 'PackageFilters',
    sections: [
        {name: 'period and author'},
        {name: 'models and books'},
        {name: 'recipients'}
    ],
    filters: [
        {
            name: 'DatePeriod',
            label: 'Date Period',
            area: 'period and author',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'asSentDate',
            label: 'Package Date Type',
            area: 'period and author',
            defaultval: true,
            commontype: 'selector',
            type: 'datetype-selector'
        },
        {
            name: 'userID',
            label: 'Booker',
            area: 'period and author',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'ModelsAndBooks',
            label: 'Model',
            area: 'models and books',
            defaultval: {
                modelID: null,
                playlistID: null,
            },
            commontype: 'selector',
            type: 'model-playlist-selector'
        },
        {
            name: 'email',
            label: 'Email',
            area: 'recipients',
            defaultval: '',
            commontype: 'text',
            type: 'recipients'
        },
        {
            name: 'contactID',
            label: 'Contact',
            area: 'recipients',
            defaultval: null,
            commontype: 'selector',
            type: 'contactList'
        },
    ]
}