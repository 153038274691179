import React, { useState } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import AddPackageDrawer from 'Components/ActivePackages/AddPackageDrawer';
import PackageDrawer from 'Components/PackageDrawer/PackageDrawer';
import AddProjectEventDropdownContent from './AddProjectEventDropdownContent';
import { Popover } from 'antd';
import { getNow } from 'Libs/NwMoment';


const Wrapper = styled.div`
`


const AddProjectEventDropdown = ({ projectId }) => {
    const { data: currentProject } = useProject(projectId);
    const [isOpenDrawer, setOpenDrawer] = useState(false);
    const [eventType, setEventType] = useState();
    const [packageId, setNewPackageId] = useState();
    const [open, setOpen] = useState(false);

    const afterRemove = () => {
        fetchData();
        setOpenDrawer(false);
        setEventType();
    };

    const fetchData = async (event, type) => {
        await forceReloadProject(currentProject.ID);
        setOpenDrawer(false);
        setEventType();
    };

    const handleOpenPackage = packId => {
        setNewPackageId(packId);
    };

    const handleClosePackageDrawer = () => {
        setNewPackageId();
    }

    const handleOpenPackageDrawer = () => {
        setEventType({ type: 'Package' });
        setOpen(false);
        setOpenDrawer(true);
    }

    const handleCreateEvent = type => {
        setEventType(type);
        setOpen(false);
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const CreateForm = ({ eventType }) => {
        switch (eventType.type) {
            case 'Event':
            case 'ToDo':
            case 'Travel':
            case 'Accommodation':
            case 'FollowUp':
            case 'Meeting':
                return (
                    <EventDrawer
                        eventType={eventType.type}
                        show={true}
                        eventInfo={eventType.eventInfo}
                        projectId={currentProject.ID}
                        viewMode='create'
                        allDay={true}
                        dateSelected={getNow()}
                        onClose={handleCloseDrawer}
                        afterUpdate={fetchData}
                        afterRemove={afterRemove}
                    />
                );
            case 'Package':
                return (
                    <AddPackageDrawer
                        packageName={currentProject.Name}
                        onAddedPackage={handleOpenPackage}
                        projectId={currentProject.ID}
                        onClose={handleCloseDrawer}
                    />
                );
            default:
                return null;
        }
    }

    return currentProject
        ?
        <Wrapper>
            <Popover
                zIndex={9999}
                overlayClassName='folder-popover'
                placement='bottomRight'
                trigger='click'
                open={open}
                onOpenChange={handleOpenChange}
                content={
                    <AddProjectEventDropdownContent 
                        onCreateEvent={handleCreateEvent} 
                        onCreatePackage={handleOpenPackageDrawer} 
                    />}
            >
                {open
                    ?
                    <NwButton size='small' icon={light('times')} label='close' ghost />
                    :
                    <NwAddButton size='small' label='add' ghost />
                }
            </Popover>
            {(currentProject && isOpenDrawer)
                ?
                <CreateForm eventType={eventType} />
                :
                null
            }
            {packageId &&
                <PackageDrawer
                    packageId={packageId}
                    onClose={handleClosePackageDrawer} />
            }
        </Wrapper>
        :
        <div />
}

export default AddProjectEventDropdown 