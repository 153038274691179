import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment, isSecondTimeEqualOrLater, isSecondTimeLater } from "Libs/NwMoment";
import Axios from 'axios';

import {
    NWTextAreaCharCounter,
    NWSelect,
    NWInput,
    NWProjectSelector,
    NWContactSelector,
    NWEventDatesPicker,
    NWRadioButtonGroup,
    NWLocationSelector
} from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwLinkButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import NwDivider from 'Components/Gui/NwDivider';
import ProjectAddSelectForm from 'Components/ProjectAddSelectForm/ProjectAddSelectForm';
import { getNow, getTime } from 'Libs/NwMoment';
import { ListContext } from 'Contexts/ListContext';
import { usePreviewProject } from 'Hooks/Project/UseProject';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';

const ConfirmationStatusList = [
    {
        label: 'Option',
        value: 'Option'
    },
    {
        label: 'Job',
        value: 'Job'
    }
];

const AddBookingEventForm = ({
    afterUpdate,
    allDay,
    dateSelected,
    eventInfo,
    modelId,
    onCancel,
    onCreateBookingEvent,
    onTouchForm,
    plainMode,
    projectId,
    touchForm,
}) => {
    const history = useHistory();
    const { cachedList } = useContext(ListContext);
    const [selectedProject, setProject] = useState(projectId ? projectId : '');
    const { data: project } = usePreviewProject(selectedProject);
    const [showEventClient, setShowEventClient] = useState(false);
    const [timeErrors, setTimeErrors] = useState([]);

    const getDefaultSubType = type => {
        const defaultValue = cachedList.eventSubtypes.items.find(item => item.Type === type && !item.Disabled && item.Default);
        return defaultValue ? defaultValue : null;
    };

    const getSubType = subTypeCode => {
        const subType = cachedList.eventSubtypes.items.find(item => item.Code === subTypeCode);
        return subType;
    };

    const { type, subTypeCode } = eventInfo;

    const defaultSubType = subTypeCode ? getSubType(subTypeCode) : type === 'Casting' ? getDefaultSubType('Casting') : getDefaultSubType('Job');

    const initialValues = {
        //changed type to typename - to check
        Type: type ? type : 'Casting',
        Title: project ? getContactName(project.Contact.ID) : '',
        Description: '',
        InternalNote: '',
        ProjectID: selectedProject,
        SubTypeCode: subTypeCode ? subTypeCode : defaultSubType.Code,
        Model: null,
        Date: null,
        AddModels: false,
        EventClientID: null,
        EventClientRoleID: null,
        EventDates: [{
            startDate: dateSelected ? getMoment(dateSelected).startOf("day") : getNow(true),
            endDate: dateSelected ? getMoment(dateSelected).startOf("day") : getNow(true),
            startTime: getTime(dateSelected ? allDay ? getMoment(dateSelected).hour(0).minute(0)
                : dateSelected : getNow().hour(9).minute(0)),
            endTime: getTime(dateSelected ? allDay ? getMoment(dateSelected).hour(23).minute(59)
                : dateSelected.clone().add(2, 'hours') : getNow().hour(11).minute(0)),
            startBreak: [0,0],
            endBreak: [0,0],
            doubleTime: false,
            allDay: allDay || false,
            dates: [{
                date: dateSelected ? dateSelected : getNow(true),
                optionNumber: null
            }]
        }],
        EventAddress: {
            Country: '',
            City: '',
            County: '',
            State: '',
            Zip: '',
            Street: '',
            Access: ''
        },
        ConfirmationStatus: 'Option'
    };

    const hasBreak = (s, e) => {
        if (!s || !e) {
            return false;
        }
        if ((s[0] === 0 && s[1] === 0) || (e[0] === 0 && e[1] === 0)) {
            return false;
        }
        return true;
    }

    const validateDatesTimes = (values) => {
        let atLeastOneError = false;
        const timeErrorMessages = []
        for (const datevalue of values) {
            let timeError = false
            if (hasBreak(datevalue.startBreak, datevalue.endBreak)) {
                if (!isSecondTimeEqualOrLater(datevalue.startTime, datevalue.startBreak)) {
                    timeError = true
                }
                if (!isSecondTimeLater(datevalue.startBreak, datevalue.endBreak)) {
                    timeError = true
                }
                if (!isSecondTimeEqualOrLater(datevalue.endBreak, datevalue.endTime)) {
                    timeError = true
                }
            } else {
                if (!isSecondTimeEqualOrLater(datevalue.startTime, datevalue.endTime)) {
                    timeError = true
                }
            }
            if (timeError) {
                timeErrorMessages.push('Invalid time range')
                timeError = true;
                atLeastOneError = true;
            } else {
                timeErrorMessages.push('')
            }
        }
        setTimeErrors(timeErrorMessages)
        return atLeastOneError
    }

    const saveBookingEvent = async (values, setSubmitting) => {
        const timeError = validateDatesTimes(values.EventDates)
        if (!timeError) {
            let Dates = [];
            if (!modelId) {
                values.EventDates.map(eventDate => {
                    const dates = eventDate.dates.map(date => {
                        return {
                            Models: [],
                            Start: getMoment(date.date).hour(eventDate.startTime[0]).minute(eventDate.startTime[1]),
                            End: getMoment(date.date).hour(eventDate.endTime[0]).minute(eventDate.endTime[1]),
                            StartBreak: hasBreak(eventDate.startBreak, eventDate.endBreak) ? getMoment(date.date).hour(eventDate.startBreak[0]).minute(eventDate.startBreak[1]) : null,
                            EndBreak: hasBreak(eventDate.startBreak, eventDate.endBreak) ? getMoment(date.date).hour(eventDate.endBreak[0]).minute(eventDate.endBreak[1]) : null,
                        }
                    })
                    Dates = [...Dates, ...dates];
                    return null;
                })
            } else {
                values.EventDates.map(eventDate => {
                    const dates = eventDate.dates.map(date => {
                        return {
                            Models: [{
                                ID: modelId,
                                OptionNumber: values.ConfirmationStatus === 'Job' ? 10 : null
                            }],
                            Start: getMoment(date.date).hour(eventDate.startTime[0]).minute(eventDate.startTime[1]),
                            End: getMoment(date.date).hour(eventDate.endTime[0]).minute(eventDate.endTime[1]),
                            StartBreak: hasBreak(eventDate.startBreak, eventDate.endBreak) ? getMoment(date.date).hour(eventDate.startBreak[0]).minute(eventDate.startBreak[1]) : null,
                            EndBreak: hasBreak(eventDate.startBreak, eventDate.endBreak) ? getMoment(date.date).hour(eventDate.endBreak[0]).minute(eventDate.endBreak[1]) : null,
                        }
                    })
                    Dates = [...Dates, ...dates];
                    return null;
                })
            }

            const ProjectID = projectId ? projectId : values.ProjectID;

            let eventData = {
                Type: values.Type,
                Title: values.Title,
                ProjectID: ProjectID,
                Description: values.Description,
                InternalNote: values.InternalNote,
                SubTypeCode: values.SubTypeCode,
                Confirmed: values.ConfirmationStatus === 'Job' ? 2 : 0,
                Dates: Dates,
                EventClientID: values.EventClientID,
                EventClientRoleID: values.EventClientRoleID,
            };

            if (values.EventAddress) {
                eventData = {
                    ...eventData,
                    Address: {
                        ...values.EventAddress,
                        AddressID: values.EventAddress.ID
                    }
                }
            };

            setSubmitting(true);
            try {
                const response = await Axios.post('/events', eventData);
                onTouchForm(false);
                setSubmitting(false);
                onCreateBookingEvent(response.ID);
                if (afterUpdate) {
                    afterUpdate({
                        ...response,
                        TypeName: values.TypeName
                    });
                }
            } catch (error) {
                console.log('ant : Create new event error => ', error);
                setSubmitting(false);
            }
        } else {
            setSubmitting(false)
        }
    };

    const formatDates = (values, setFieldValue) => {
        const dates = values.EventDates.map(eventDate => {
            return {
                ...eventDate,
                optionNumber: null
            }
        })
        setFieldValue('EventDates', dates);
    };

    const validateEventRole = (eventRoleId, eventClientId) => {
        if (!eventRoleId && eventClientId) {
            return 'Please select client role!'
        }
        return null;
    }

    const getSubtypes = (currentType) => {
        const filteredsubtypes = cachedList.eventSubtypes.items.filter(item => item.Type === currentType && !item.Disabled)
        return filteredsubtypes.map(type => {
            return {
                label: <div style={{ display: 'flex' }}>
                    <Badge color={`#${type.BackColor}`} style={{ marginRight: ".5rem" }} />
                    {type.Name}
                </div>,
                value: type.Code
            };
        })
    }

    return (
        <div className='event-form'>
            {(!selectedProject || !project) ?
                <ProjectAddSelectForm
                    showSecondLevel
                    onSelectProject={projectId => {
                        setProject(projectId);
                        onTouchForm(true);
                    }}
                    searchMode={true}
                    touchForm={touchForm}
                    onTouchForm={onTouchForm}
                    type={type}
                    onCancel={onCancel}
                />
                :
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        Title: Yup.string().required("Title is required"),
                        SubTypeCode: Yup.string().required('Subtype is required'),
                        ProjectID: Yup.number().required("Project is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        saveBookingEvent(values, setSubmitting);
                    }}
                >
                    {(form) => {
                        const { isSubmitting, values, handleSubmit, setFieldValue } = form;
                        return (
                            <NwForm
                                values={values}
                                onTouchForm={onTouchForm}
                                small
                                onFinish={handleSubmit}
                                layout="vertical">
                                <Row gutter={10}>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Field
                                            component={NWProjectSelector}
                                            name="ProjectID"
                                            noUnlink
                                            value={projectId ? projectId : values.ProjectID}
                                            readOnly={projectId ? true : false}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={type !== 'Job' ? 24 : 12} md={type !== 'Job' ? 24 : 12} sm={type !== 'Job' ? 24 : 12} xs={24}>
                                        <Field
                                            component={NWSelect}
                                            label="Event Type"
                                            name="SubTypeCode"
                                            value={values.SubTypeCode}
                                            options={getSubtypes(values.Type)}
                                        />
                                    </Col>
                                    {type === 'Job' &&
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <Field
                                                component={NWRadioButtonGroup}
                                                name="ConfirmationStatus"
                                                label='Confirmation Status'
                                                buttonStyle="solid"
                                                options={ConfirmationStatusList.map(option => {
                                                    return { key: option.value, value: option.value, label: option.label };
                                                })
                                                }
                                                onChange={value => {
                                                    if (value === 'option') {
                                                        formatDates(values, setFieldValue);
                                                    }
                                                }}
                                            />
                                        </Col>
                                    }
                                </Row>
                                {showEventClient
                                    ?
                                    <>
                                        <Row gutter={10}>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <Field
                                                    component={NWContactSelector}
                                                    label={`${defaultSubType ? defaultSubType.Name : ''} Client`}
                                                    name="EventClientID"
                                                    activeTypes={['age', 'cli', 'srv']}
                                                    activableTypes={[]}
                                                    value={values.EventClientID}
                                                    onAfterChange={customerId => setFieldValue('Title', getContactName(customerId))}
                                                />
                                                <div style={{ marginBottom: '.5rem' }}>
                                                    <NwLinkButton onClick={() => setShowEventClient(false)} label={<><NwIcon icon={light("xmark")} />&nbsp;Use the project client</>} />
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={24}>
                                                <Field
                                                    component={NWSelect}
                                                    label={`${defaultSubType ? defaultSubType.Name : ''} Client Role`}
                                                    name="EventClientRoleID"
                                                    value={values.EventClientRoleID}
                                                    validate={value => validateEventRole(value, values.EventClientID)}
                                                    options={
                                                        cachedList.eventClientRoles.items.filter(item => !item.Disabled).map(eventClientRole => {
                                                            return {
                                                                key: eventClientRole.ID,
                                                                label: eventClientRole.Name,
                                                                value: eventClientRole.ID
                                                            };
                                                        })}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <Row gutter={10}>
                                        <Col span={24}>
                                            <div style={{ margin: '1rem 0' }}>
                                                <NwLinkButton onClick={() => setShowEventClient(true)} label="Add an alternative client for this event" />
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                <Field
                                    component={NWInput}
                                    label="Title"
                                    name="Title"
                                    type="text"
                                    value={values.Title}
                                />
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <Field
                                            component={NWTextAreaCharCounter}
                                            label="Description"
                                            name="Description"
                                            type="text"
                                            value={values.Description}
                                            maxLength={2000}
                                            autoSize={{ minRows: 4, maxRows: 10 }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Field
                                            component={NWTextAreaCharCounter}
                                            label="Internal Notes"
                                            name="InternalNote"
                                            type="text"
                                            value={values.InternalNote}
                                            maxLength={2000}
                                            autoSize={{ minRows: 4, maxRows: 10 }}
                                        />
                                    </Col>
                                </Row>
                                <NwDivider>Dates</NwDivider>
                                <Field
                                    component={NWEventDatesPicker}
                                    label=""
                                    name="EventDates"
                                    disableOptions={type !== 'Job'}
                                    confirmationStatus={values.ConfirmationStatus}
                                    modelId={modelId}
                                    value={values.EventDates}
                                    useDoubleTime
                                    timeErrors={timeErrors}
                                />
                                <NwDivider>Address</NwDivider>
                                <Field
                                    component={NWLocationSelector}
                                    projectCustomerId={project && project.Contact.ID}
                                    customerId={values.EventClientID}
                                    name="EventAddress"
                                    value={form.values.EventAddress}
                                />
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                onTouchForm(false);
                                                onCancel();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            htmlType="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        />
                                    } />
                            </NwForm>
                        )
                    }}
                </Formik>
            }
        </div>
    )
};

export default AddBookingEventForm;