import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Image } from 'antd';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useWindowSize } from 'react-use';
import NwIcon from 'Components/Gui/NwIcon';
import { getPages, renderSlide, StyledBackButton, StyledNextButton, StyledFirstButton } from 'Components/MediaDrawer/PlayListUtils';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import VideoPlayer from 'Components/Gui/VideoPlayer';

const DocWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SlideContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
`;

const Root = styled.div`
    margin-bottom: 2rem;
    .carousel-flex {
        display: flex;
    }
`;

const PageContainer = styled.div`
    background: white;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 24px;
    font-weight: bold;
`;

const PackagePlayListView = ({ packagePlaylist }) => {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();

    const { height, width } = useWindowSize();
    const [currentSlide, setCurrentSlide] = useState(0);
    const ratio = width / height;
    //const slideWidth = 100 
    //const slideHeight = (slideWidth / ratio)
    const slideWidth = 100
    const slideHeight = slideWidth / ratio * 0.95;
    // const startonright = false
    const breakpoints = useNwBreakPoints();

    const [pages, setPages] = useState([]);
    useEffect(() => {
        if (packagePlaylist) {
            setPages(getPages(packagePlaylist.DocumentPlaylists, breakpoints, packagePlaylist.Layout));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packagePlaylist]);

    const handleBack = () => {
        if (currentSlide > 0) {
            setCurrentSlide(prevCurrentSlide => prevCurrentSlide - 1);
        }
    };

    const handleNext = () => {
        if (currentSlide < pages.length - 1) {
            setCurrentSlide(prevCurrentSlide => prevCurrentSlide + 1);
        }
    }

    const handleFirst = () => {
        setCurrentSlide(0);
    }

    return pages.length > 0 &&
        (breakpoints>2?
            <Root>
                <CarouselProvider
                    naturalSlideWidth={slideWidth}
                    naturalSlideHeight={slideHeight}
                    totalSlides={pages.length}
                    visibleSlides={1}
                    step={1}
                >
                    <Container>
                        <Slider style={{ marginLeft: 40, marginRight: 40 }}>
                            {pages.map((doc, index) => (
                                <Slide key={index} index={index}>
                                    <DocWrapper height={height}>
                                        <SlideContainer>
                                            {renderSlide(doc, width, height, packagePlaylist.Layout)}
                                        </SlideContainer>
                                    </DocWrapper>
                                </Slide>
                            ))}
                        </Slider>
                        {(pages.length > 1) &&
                            <>
                                <StyledBackButton negative={true} onClick={handleBack} height={height}><NwIcon icon={light("chevron-left")} /></StyledBackButton>
                                {(currentSlide === pages.length - 1)
                                    ?
                                    <StyledFirstButton negative={true} onClick={handleFirst} height={height}><NwIcon icon={light("redo")} /></StyledFirstButton>
                                    :
                                    <StyledNextButton negative={true} onClick={handleNext} height={height}><NwIcon icon={light("chevron-right")} /></StyledNextButton>
                                }
                            </>
                        }
                    </Container>
                </CarouselProvider>
                <PageContainer>
                    {`${currentSlide + 1} / ${pages.length}`}
                </PageContainer>
            </Root>:
            <div ref={myRef}>
                {pages.map((doc, index) => {
                    if (doc.video || doc.left.Type === 'Video') {
                        return (
                            <div key={index} style={{paddingTop: index === 0 ? "1rem" :"0.5rem"}}>
                                <VideoPlayer document={doc.left} />
                            </div>
                        )
                    }
                    return (
                        <div key={index} style={{paddingTop: index === 0 ? "1rem" :"0.5rem"}}>
                            <Image src={doc.left.Standard.Url} preview={false}/>
                        </div>
                    )
                })}
                <PageContainer onClick={executeScroll}>
                    <NwIcon icon={light("arrow-up")}/>
                </PageContainer>
            </div>
        )
}

export default PackagePlayListView