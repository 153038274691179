import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { NwButton } from "Components/Gui/NwButton";
import { NWInput } from 'Components/Gui/NWForm/NwFormItems';
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import NwModal from 'Components/Gui/NwModal';
import { Alert } from 'antd';
import { PackageContext } from '../PackageContext';

const FormItem = styled.div`
    display: flex;
    align-items: flex-start;

    .field-container {
        width: 100%;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
`;


const NewGroupModal = ({ onClose }) => {
    const [touchForm, setTouchForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage } = packageContextValue;
    const groupNames = groupedPackage.GroupNames;

    let initialValues = {
        GroupName: "",
    };

    const onCreateNewGroup = async groupName => {
        //check if group already exists
        if (groupNames && groupNames.find(name => name === groupName)) {
            setErrorMessage('Group already exists');
            return;
        }
        onClose(groupName);
    };

    const submitForm = async (values, setSubmitting) => {
        setSubmitting(true);
        await onCreateNewGroup(values.GroupName);
        setSubmitting(false);
    };

    return (
        <NwModal
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title='New Package Group'
            open
            footer={null}
            onCancel={() => {
                onClose()
            }}>   
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    GroupName: Yup.string().required("Content is required")
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    submitForm(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => ( 
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <FormItem>
                            <Field
                                component={NWInput}
                                name="GroupName"
                                type="text"
                                value={values.GroupName}
                            />  
                            &nbsp;
                            <NwButton
                                htmlType="submit"
                                primary
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                label="Save"
                                loadingLabel="Updating..."
                            />
                        </FormItem>
                    </NwForm>
                )}
            </Formik>
            {errorMessage && <Alert type="error" message={errorMessage} />}
        </NwModal>
    )
};

export default NewGroupModal;