// import React from 'react';
import { Modal, Menu } from 'antd';
import styled from 'styled-components';

import FlexContainer from 'Components/Gui/FlexContainer';

const QuickFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    .advanced {
        @media only screen and (max-width: 1280px) {
            display: none;
        }
    }
    padding-bottom: 1rem;
    padding-top: ${props => props.$topPadded ? "1rem" : "0"};

    div.quick-filter-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: ${props => props.$isMini ? ".5rem" : "1.25rem"};
    }

    div.quick-filter-item {
        span {
            font-size: ${props => props.$isMini ? ".75rem" : ".9rem"};
            text-transform: uppercase;
            &.before {
                margin-right: .5rem;
            }
            &.after {
                margin-left: .5rem;
            }
            &.highlighted {
                font-weight: bold;
                color: ${props => props.theme.DangerColor};
            }
            &.muted {
                color: ${props => props.theme.TextColorMuted};
            }
        }
    }
`
const QuickFilterSpacer = styled.div`
    min-height: 2rem;
`

const FilterDrawerAction = styled(FlexContainer)`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -24px;
    border-top: 1px solid ${props => props.theme.LighterBorderColor};
    padding: 16px 0;
    background: white;
    z-index: 1000;
`;

const FiltersContainer = styled.div`
    margin: 16px 0;
`;

const FilterItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.LighterBorderColor};
    padding: 0 8px;
    margin: 1px 0;
    cursor: pointer;
    &:hover {
        box-shadow: 0 1px 3px rgba(57,73,76,.35);
    }

    &.no-borders {
        border: none;
        &:hover {
            box-shadow: none;
        }
    }
`;

const ObjectCounter = styled.div`
    background-color: ${props => props.theme.PrimaryColor};
    color: #fff;
    border-radius: 25px;
    padding: 0 8px;
    margin: 4px;
    vertical-align: middle;
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
`;

const FilterItemWrapper = styled.div`
    display: flex;
    article {
        margin-top: .3em;
        font-weight: ${props => props.count > 0 && 'bold'};
    }
`;

const FilterPanel = styled.div`
    padding: 0 1.5em;
`;

const FiltersMenu = styled(Menu)`
    min-width: 200px;
`;

const FilterActions = styled(FlexContainer)`
    button {
        font-size: 0.8em;
        font-weight: bold;
    }
`;

const CompactSelectedFiltersContainer = styled.div`
    max-width: 400px;
`;

const FiltersLabel = styled.div`
    cursor: pointer;
    padding-right: 8px;
    white-space: nowrap;
`

const FilterModal = styled(Modal)`
    .ant-modal-body {
        max-height: calc(100vh - 240px);
        overflow: auto;
    }
`;

export {
    FilterDrawerAction,
    FiltersContainer,
    FilterItem,
    ObjectCounter,
    FilterItemWrapper,
    FilterPanel,
    FiltersMenu,
    CompactSelectedFiltersContainer,
    FilterActions,
    FilterModal,
    FiltersLabel,
    QuickFilterContainer,
    QuickFilterSpacer
};