import React from 'react'
import styled from 'styled-components';
import { Divider } from 'antd';

const StyledDivider = styled(Divider)`
    margin: .25rem .15rem .25rem .25rem !important;
    position: relative;
    opacity: .75;
    &::before, &::after {
        border-color: ${props => props.$color ? props.$color : props.theme.TextColor} !important;
    }

    .ant-divider-inner-text {
        font-size: .6rem;
        font-weight: normal;
        padding: 0 0.5em !important;
        text-transform: uppercase;
        opacity: 1;
        color: ${props => props.$color ? props.$color : props.theme.TextColor} !important;
    }
`

const EventCardDivider = ({ orientation = "left", color, children, ...other }) => {
    return (
        <StyledDivider $color={color} orientation={orientation} {...other}>
            {children}
        </StyledDivider>
    )
}

export default EventCardDivider