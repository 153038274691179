import React from 'react';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ModelDetailsHeaderFeatures from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderFeatures";
import ModelDetailsHeaderActions from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderActions';
import NwIcon from 'Components/Gui/NwIcon';
import PermissionBlock from 'Components/PermissionBlock';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useFeatures } from 'Hooks/Contact/UseContactFeatures';
import { useStateValue } from 'Contexts/StateProvider';
import { useUserPermission } from 'Contexts/UserContext';

const ModelDetailsHeaderFooter = ({
    currentContact,
    disableStatement,
    //onOpenModelAgencies,
    onOpenStatements,
    hideActions
}) => {

    const history = useHistory();
    const [{ areaContext }] = useStateValue();
    const viewModelFeatures = useUserPermission('ViewModelFeatures', currentContact && currentContact.Users.map(user => user.UserID));

    const features = currentContact.FeatNwMain || currentContact.F
    const filteredFeatures = useFeatures(features ? JSON.parse(features) : null);

    const handleMoveSimilarModels = () => {
        const currentLocation = history.location;
        if (currentLocation.pathname === '/models') {
            history.replace({
                pathname: currentLocation.pathname,
                state: {
                    contactId: currentContact.ID,
                    type: 'find-similar-models'
                }
            })
        } else {
            history.push({
                pathname: areaContext.area === 'booking' ? '/models' : `/${areaContext.area}-models`,
                state: {
                    contactId: currentContact.ID,
                    type: 'find-similar-models'
                }
            })
        }
    };

    return (
        <div className="header-footer">
            <div className="features-container">
                <ModelDetailsHeaderFeatures
                    contact={currentContact}
                    hasFeatureFilter
                />&nbsp;&nbsp;
                {filteredFeatures.length > 0 &&
                    <div>
                        {viewModelFeatures ?
                            <Popover content={`Find models similar to ${getContactName(currentContact.ID)}`} onClick={handleMoveSimilarModels}>
                                <NwIcon icon={light('people-arrows')} pointer size='lg' />
                            </Popover>
                            :
                            <PermissionBlock
                                permission={viewModelFeatures}
                                content={<NwIcon icon={light('people-arrows')} pointer size='lg' />}
                            />
                        }
                    </div>
                }
            </div>
            {!hideActions &&
                <ModelDetailsHeaderActions
                    currentContact={currentContact}
                    disableStatement={disableStatement}
                    //onOpenModelAgencies={onOpenModelAgencies}
                    onOpenStatements={onOpenStatements} />
            }
        </div>
    );
};

export default ModelDetailsHeaderFooter;