import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const getProjectTimeline = projectId => (
    Axios.get(`/projects/timeline/${projectId}`)
)

const useProjectTimeline = projectId => {
    return useQuery(["projectTimeline", projectId], () => getProjectTimeline(projectId), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false
    });
};

const forceReloadProjectTimeline = (ID) => {
    return queryClient.invalidateQueries(['projectTimeline', ID])
}

export {
    useProjectTimeline,
    forceReloadProjectTimeline
};