import React from "react";
import { Select } from 'antd';
import constants from 'Constants';

const FilterTravelsOnTransportTypes = ({ onChange, value, eventType, onBlur }) => {

    const onSubTypeChange = (val) => {
        onChange({
            ...value,
            travelTypes: val
        });
    }

    const travelTypesOptions = constants.TRANSPORTS.map(item => ({
        value: item,
        label: item,
    }))

    return (
        <Select
            value={value.travelTypes || []}
            style={{ width: '100%' }}
            onChange={onSubTypeChange}
            options={travelTypesOptions}
            onBlur={onBlur}
            placeholder={`All travel types`}
            mode="multiple"
            allowClear
        />
    );
};

export default FilterTravelsOnTransportTypes;
