import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import NwIcon from 'Components/Gui/NwIcon';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import LastEditedBy from 'Components/CreatedBy/LastEditedBy';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Popover } from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  
  .icon {
    margin: 0 0.5rem;
  }

  .separator {
    padding: 0 1em;
  }
`;

const ObjectHistory = ({ object, onIconClick, asLine, iconOnly }) => {
    const breakpoints = useNwBreakPoints();
    return (
        iconOnly
            ?
            <Popover 
                title="History"
                content={
                    <div style={{fontSize: '.8rem'}}>
                        <CreatedBy breaklines object={object} noWrap extra='Created' />
                        {object.DateModified &&
                            <LastEditedBy breaklines object={object} noWrap extra='Last Edited By' />
                        }
                    </div>
                }
                placement="right"
                trigger="hover"
            >
                <NwIcon style={{ fontSize: 20 }} onClick={onIconClick} className='icon' icon={light('history')} />
            </Popover>
            :
            (breakpoints !== 1 && asLine)
                ?
                <Container className="history-details" onClick={onIconClick}>
                    <NwIcon onClick={onIconClick} className='icon' icon={light('history')} />
                    <CreatedBy object={object} noWrap extra='Created' />
                    {object.DateModified &&
                        <>
                            <span className="separator">&bull;</span>
                            <LastEditedBy object={object} noWrap extra='Last Edited By' />
                        </>
                    }
                </Container>
                :
                <Container onClick={onIconClick}>
                    <NwIcon className='icon' icon={light('history')} size='2x' />
                    <div className="history-details">
                        <CreatedBy object={object} noWrap extra="" />
                        {object.DateModified &&
                            <LastEditedBy object={object} noWrap extra='edited by' />
                        }
                    </div>
                </Container>
    )
};

// ObjectHistory.propTypes = {
//   object: PropTypes.shape({
//     ID: PropTypes.number.isRequired
//   }).isRequired,
//   onIconClick: PropTypes.func,
// }

// ObjectHistory.defaultProps = {
//   onIconClick: () => null
// }

export default ObjectHistory;