import React from "react";
import styled from "styled-components";
import { NwButton } from "./NwButton";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

const StyledMailto = styled.a`

`

const NwMailTo = ({ email, subject = '', body = '', cc = [], label = "Open in your e-mail app", icon, asButton }) => {
    let params = (subject || body || cc) ? '?' : '';

    const buttonIcon = icon ? icon : light('paper-plane');

    if (subject) {
        params += `subject=${encodeURIComponent(subject)}`;
    }
    if (body) {
        params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
    }
    if (cc && cc.length > 0) {
        params += `${subject || body ? '&' : ''}cc=${cc.join(',')}`;
    }

    if (asButton) {
        return (
            <NwButton primary label={label} icon={buttonIcon} href={`mailto:${email}${params}`} />
        )
    } else {
        return (
            <StyledMailto href={`mailto:${email}${params}`}>
                {email}
            </StyledMailto>
        )
    }
}

export default NwMailTo;