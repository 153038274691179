import React, { useEffect, useContext, useState } from 'react';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import useLocalStorageState from "Hooks/UseLocalStorageState";
import { useContact } from "Hooks/Contact/UseContact";
import { useStateValue } from "Contexts/StateProvider";
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';
import { getNow, showLogNoTime } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { saveAlerts } from 'Libs/NwAlerts';

const AlertWrapper = styled.div`
    margin-bottom: 1rem;
    padding: 1.5rem 1rem;
    line-height: 1.25;
    border-bottom: 4px solid ${props => props.WarningColor};
    .alert-message {

    }
`

const ContactAlertDrawer = ({ contactId, icon, readOnly, size, children }) => {
    const { GetUserName } = useContext(ListContext);
    const { data: currentContact } = useContact(contactId);
    const [{ areaContext }] = useStateValue();
    const [readAlerts, setReadAlerts] = useLocalStorageState(`${areaContext.area}-read-alerts`, []);
    const { currentUser } = useContext(UserContext);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (currentContact && !readOnly) {
            if (currentContact.Alert) {
                if (!readAlerts.find((alert) => {
                        if (
                            alert.Author === currentUser.UserID &&
                            alert.ContactID === currentContact.ID &&
                            (getMoment(alert.ExpirationDate) >= getNow() &&
                            getMoment(currentContact.AlertCreationDate) <= getMoment(alert.Date)) 
                        ) {
                            return true;
                        }
                        return false;
                    })
                ) {
                    if (!showAlert) {
                        setShowAlert(true);
                    }
                }
            }
        }
    }, [currentContact, readOnly]);

    const handleAlerts = alerts => {
        setReadAlerts(alerts);
        saveAlerts(areaContext.area, alerts);
    };

    const handleDismissNotification = (key, period) => {
        setShowAlert(false);
        const findIndex = readAlerts.findIndex((alert) => {
            if (
                alert.Author === currentUser.UserID &&
                alert.ContactID === currentContact.ID
            ) {
                return true;
            }
            return false;
        }); 
        if (findIndex < 0) {
            handleAlerts([
                ...readAlerts,
                {
                    Author: currentUser.UserID,
                    ContactID: currentContact.ID,
                    Date: getNow(),
                    ExpirationDate: period === 'day' ? getNow().endOf('day')
                        : getNow().add(1, period)
                },
            ]);
        } else {
            const updatedReadAlerts = [...readAlerts];
            updatedReadAlerts[findIndex] = {
                ...updatedReadAlerts[findIndex],
                Date: getNow(),
                ExpirationDate: period === 'day' ? getNow().endOf('day')
                    : getNow().add(1, period)
            };
            handleAlerts(updatedReadAlerts);
        }
    };

    const getActions = key => {
        const actions = [
            { name: "Mute for 7 days", key: "week", pinned: true, action: (() => {
                handleDismissNotification(key, 'week');
            }) },
            { name: "Mute for today", type: "day", key: "day", action: (() => {
                handleDismissNotification(key, 'day');
            })},
            { name: "Mute for 30 days", type: "month", key: "month", action: (() => {
                handleDismissNotification(key, 'month');
            })}
        ]

        return actions;
    };

    return (
        ((currentContact && currentContact.Alert)
        ? 
            <AlertWrapper>
                <NwIcon icon={solid("message-exclamation")} />
                <h1>{GetUserName(currentContact.AlertUser)} {showLogNoTime(currentContact.AlertCreationDate)}</h1>
                <div className="alert-message">
                    <p>{currentContact.AlertMessage}</p>
                    <small>
                        
                    </small>
                </div>
                <NwDropDownMenu actions={getActions(`open${Date.now()}`)} />
            </AlertWrapper>
        :
            <div></div>
        )
    )

}
        


//     return (!currentContact ? <div />
//         : 
//             currentContact.Alert ?
//                 <>
//                     {icon ? 
//                         <div onClick={handleDisplayNotification}>
//                             {icon}
//                         </div>
//                         :
//                         <BadgeRibbon
//                             size={size}
//                             color={StyleVariables.DangerColor}
//                             text={
//                                 <div className="ribbon-content" onClick={handleDisplayNotification}>
//                                     <NwIcon icon={solid('exclamation-circle')} /> Alert
//                                 </div>
//                             }
//                             placement="start"
//                         >
//                             {children}
//                         </BadgeRibbon>
//                     }
//                 </>
//             :
//             <>
//                 {children}
//             </>
//     );
// };

export default ContactAlertDrawer;