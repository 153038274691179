import React, { useContext } from "react";
import { Modal, Tag, Badge } from "antd";
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

// import NwDescriptionList from "Components/Gui/NwDescriptionList";
import { NwCard } from "Components/Gui/NwCard/NwCard";
// import NwEllipsis from "Components/Gui/NwEllipsis";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import CountryLabel from 'Components/Gui/CountryLabel';
//import { LEGAL_TYPES } from "Configs/LEGAL_TYPES";
import { ListContext } from "Contexts/ListContext";
import LegalUpload from "./LegalUpload";
import { useRemoveLegal } from 'Hooks/Contact/UseContactLegals';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { getNow, showDateNoTime } from "Libs/NwMoment";
import NwEllipsis from 'Components/Gui/NwEllipsis';
import NwDivider from "Components/Gui/NwDivider";

const confirm = Modal.confirm;

const CardTitle = styled.h1`
    margin-bottom: 0.5rem;
    position: absolute;
    left: ${props => props.invalid ? '84px' : '38px'};
`;

const DocumentValue = styled.div`
    font-weight: bold;
`;

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};
        }
    }
`;

const StyledTag = styled(Tag)`
    margin-right: 8px;
`;

const LegalItem = ({ document, contactId, readOnly, onEditDoc }) => {
    const { mutateAsync: removeLegal } = useRemoveLegal();
    const diffDays = document.ExpirationDate && getNow(true).diff(getMoment(document.ExpirationDate), 'days');
    const breakpoints = useNwBreakPoints();
    const { cachedList } = useContext(ListContext);
    const legalTypes = cachedList.legalTypes.items;
    const documentName = legalTypes.find(el => el.Code.toLowerCase() === document.Type.toLowerCase()).Name;
    
    const deleteDocument = id => {
        removeLegal({ LegalID: id, ContactID: contactId })
    }

    const renderActions = () => (
        <NwDropDownMenu
            size='small'
            actions={[
                { name: "", key: "edit", pinned: true, icon: light("pen"), action: onEditDoc},
                { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => deleteDocument(document.ID)}
            ]}
        />
    );

    const renderTypeIcon = () => {
        switch (document.Type) {
        case 'VISA':
            return light('plane');
        case 'ID':
            return light('id-card');
        case 'PASSPORT':
            return light('passport');
        case 'SSN':
            return light('id-badge');
        case 'DRIVINGLIC':
            return light('car');
        case 'CONTRACT':
            return light('file-contract');
        default:
            return light('file');
        }
    };

    const invalid = diffDays !== null && (diffDays >= 0 || (diffDays < 0 && diffDays >= -30));

    const LegalDocumentTitle = () => {
        return <>
            {diffDays !== null && 
                <>
                    {diffDays >= 0 && 'expired'}
                    {diffDays < 0 && diffDays >= -30 && 
                        'expiring'}
                </>
            }
            &nbsp;
            <NwIcon icon={renderTypeIcon()} />
        </>
    }

    const getCardRibbonColor = () => {
        if (diffDays !== null) {
            if (diffDays >= 0) return StyleVariables.DangerColor;
            else if (diffDays < 0 && diffDays >= -30) {
                return StyleVariables.GoldColor;
            }
        }
        return StyleVariables.PrimaryColor;
    };

    const renderLegalCard = () => {
        return (
            <NwCard
                title={
                    <FlexContainer>
                        {breakpoints === 1 && 
                            <StyledTag color={getCardRibbonColor()}>
                                {LegalDocumentTitle()}
                            </StyledTag>
                        }
                        <CardTitle invalid={invalid ? 1 : 0}>{documentName}{document.YearRef ? ` (${document.YearRef})` : ''}</CardTitle>
                    </FlexContainer>
                }
                extra={!readOnly && renderActions()}>
                <FlexContainer
                    column={breakpoints === 1} 
                    alignItems={breakpoints === 1 && 'flex-start'}>
                    <FlexContainer 
                        column={breakpoints === 1} 
                        alignItems={breakpoints === 1 && 'flex-start'} 
                        mg={breakpoints !== 1 && '0 16px 0 0'}>
                        Number:&nbsp;
                        <DocumentValue>{document.DocNumber}</DocumentValue>
                    </FlexContainer>
                    <FlexContainer
                        column={breakpoints === 1} 
                        alignItems={breakpoints === 1 && 'flex-start'} >
                        Country:&nbsp;
                        <DocumentValue>
                            <CountryLabel code={document.CountryCode} flag={true} />
                        </DocumentValue>
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer
                    column={breakpoints === 1} 
                    alignItems={breakpoints === 1 && 'flex-start'}>
                    {document.StartDate ? 
                        <FlexContainer 
                            column={breakpoints === 1} 
                            alignItems={breakpoints === 1 && 'flex-start'}
                            mg={breakpoints !== 1 && '0 8px 0 0'}>
                            Issued on&nbsp;
                            <DocumentValue>{showDateNoTime(document.StartDate)}</DocumentValue>
                        </FlexContainer> : ''}
                    {document.ExpirationDate ? 
                        <FlexContainer
                            column={breakpoints === 1} 
                            alignItems={breakpoints === 1 && 'flex-start'}>
                            will expired on&nbsp;
                            <DocumentValue>{showDateNoTime(document.ExpirationDate)}</DocumentValue>
                        </FlexContainer> : ''}
                </FlexContainer>
                {document.Details ? 
                    <FlexContainer 
                        column={breakpoints === 1} 
                        alignItems={breakpoints === 1 && 'flex-start'}
                        mg={breakpoints !== 1 && '0 8px 0 0'}>
                        <div style={{marginBottom: "auto", marginRight: "1rem"}}>Details:</div>
                        <NwEllipsis popover text={document.Details} rows={4} />
                    </FlexContainer> : ''}
                {!readOnly &&
                <>
                    <NwDivider>Scan</NwDivider>
                    <LegalUpload item={document} />
                </>
                }
            </NwCard>
        )
    }

    return (breakpoints === 1 ? 
        <Wrapper isMobile>
            {renderLegalCard()}
        </Wrapper>
        : 
        <Badge.Ribbon
            color={getCardRibbonColor()}
            placement="start"
            text={LegalDocumentTitle()}>
            {renderLegalCard()}
        </Badge.Ribbon>
    );
};

export default LegalItem;
