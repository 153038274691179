import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import AnagraphicalForm from "Components/ContactDetails/CommonComponents/Anagraphical/AnagraphicalForm";
import NwDrawer from "Components/Gui/NwDrawer";
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import CountryLabel from 'Components/Gui/CountryLabel';
import { useContact } from 'Hooks/Contact/UseContact';
import { NwEditButton } from "Components/Gui/NwButton";
import { showDateNoTime } from "Libs/NwMoment";
import constants from 'Constants';
import { ListContext } from 'Contexts/ListContext';
import NwDivider from "Components/Gui/NwDivider";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import FlexContainer from "Components/Gui/FlexContainer";
import { getCustomFieldValue } from "Libs/NwUtils";

const DetailItemContainer = styled(FlexContainer)`
    padding: 4px 0;
`;

const DetailItemLabel = styled.div`
    min-width: 120px;
    white-space: nowrap;
    margin-right: 8px;
    font-size: 0.9rem;
    text-transform: uppercase;
`;

const DetailItemValue = styled.div`
    font-size: 0.9rem;
    font-weight: bold;
`;

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};
        }
    }
`;

const AnagraphicalDrawer = ({ contactID, readOnly, onClose, type }) => {
    const { data: currentContact } = useContact(contactID);
    const [isEdit, setIsEdit] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [validCustomFieldsKeys, setValidCustomFieldsKeys] = useState([]);
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const breakpoints = useNwBreakPoints();

    const Country = currentContact.DefaultConnection
        ? currentContact.DefaultConnection.Country
        : "";
    const Email = currentContact.DefaultConnection
        ? currentContact.DefaultConnection.Email
        : "";

    const {
        Name,
        Surname,
        Code,
        LegalName,
        LegalSurname,
        BirthDate,
        BirthPlace,
        NationalityCode,
        CitizenshipCode,
        Citizenship2Code,
        GenderValue
    } = currentContact;

    const dataFields = {
        Name,
        Surname,
        'Legal Name': LegalName,
        'Legal Surname': LegalSurname,
        Code,
        Email,
        Country,
        'Birth Date': showDateNoTime(BirthDate),
        'Birth Place': BirthPlace,
        'Nationality': NationalityCode,
        'Citizenship': CitizenshipCode,
        'Citizenship2': Citizenship2Code,
        'Gender': GenderValue
    };

    useEffect(() => {
        if (currentContact) {
            const contactType = currentContact.ContactType;
            const category = contactType === 'Model' ? 'Model' : constants.CUSTOMER_CATEGORIES[currentContact.SubType - 1];
            const customFields = cachedList.customFields.items[category];
            setCustomFields(customFields);
            setValidCustomFieldsKeys(customFields.filter(field => !field.Disabled).map(field => field.Name))
        }
    }, [currentContact, cachedList]);

    const handleEditContactDetail = () => {
        setIsEdit(true);
    };

    const handleCloseContactDetail = () => {
        setIsEdit(false);
    };

    const ItemValue = ({ name, value }) => {
        return (
            <DetailItemValue>
                {(name === 'Nationality' || name === 'Citizenship' || name === 'Citizenship2') ?
                    (value ?
                        <CountryLabel usecode code={value} flag />
                        :
                        <>n.d.</>
                    )
                    :
                    <div>{value}</div>
                }
            </DetailItemValue>
        )
    }

    return (
        <NwDrawer
            onClose={onClose}
            title="Contact detail"
        >
            <div className='drawer-content standard'>
                <Wrapper isMobile={breakpoints === 1}>
                    <NwCard
                        title='Info'
                        extra={
                            <NwEditButton ghost icon={light('pen')} onClick={handleEditContactDetail} />
                        }>
                        {Object.keys(dataFields).map(fieldKey => {
                            return dataFields[fieldKey] && (
                                <DetailItemContainer
                                    column={breakpoints === 1}
                                    alignItems={breakpoints === 1 && 'flex-start'}
                                    key={`event-detail-${fieldKey}`}>
                                    <DetailItemLabel>{fieldKey}</DetailItemLabel>
                                    <ItemValue name={fieldKey} value={dataFields[fieldKey]} />
                                </DetailItemContainer>
                            )
                        })
                        }

                        {currentContact.CustomFields && currentContact.CustomFields.length > 0 &&
                            <>
                                <NwDivider>additional info</NwDivider>
                                {Object.keys(currentContact.CustomFields).map((key, index) => {
                                    if (validCustomFieldsKeys && validCustomFieldsKeys.includes(key)) {
                                        return (
                                            <DetailItemContainer
                                                column={breakpoints === 1}
                                                alignItems={breakpoints === 1 && 'flex-start'}
                                                key={`custom-field-${key}`}>
                                                <DetailItemLabel>{key.replace(/_/g, ' ')}</DetailItemLabel>
                                                <ItemValue name="" value={getCustomFieldValue(key, currentContact.CustomFields[key], customFields)} />
                                            </DetailItemContainer>
                                        )
                                    }
                                })}
                            </>
                        }
                    </NwCard>
                </Wrapper>
            </div>
            {isEdit &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Customer Details'
                    onClose={handleCloseContactDetail}>
                    <AnagraphicalForm
                        onTouchForm={setTouchForm}
                        touchForm={touchForm}
                        contactID={currentContact.ID}
                        customFields={customFields}
                        type={type}
                        onCancel={handleCloseContactDetail} />
                </NwDrawer>
            }
        </NwDrawer>
    );
};

export default AnagraphicalDrawer;
