import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import MediaDetailForm from 'Components/MediaDrawer/MediaDetailForm';
import Visibilities from 'Components/Gui/Visibilities';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwDrawer from 'Components/Gui/NwDrawer';
import { showDateNoTime } from 'Libs/NwMoment';
import { getCustomer } from 'Hooks/Contact/UseContactsList';
import { useUserPermission } from 'Contexts/UserContext';
import { NwCard } from 'Components/Gui/NwCard/NwCard';

const MediaDetailsCard = styled(NwCard)`
    margin-top: 16px !important;

    .ant-card-body {
        padding: 16px !important;
    }
`;

const DataFieldsTable = styled.table`
    list-style-type: none;
    padding-left: 0px;

    .title-cell {
        border-right: ${props => props.theme.DefaultBorder};
        padding-right: 40px;
        font-size: .8rem;
        text-transform: uppercase;
    }
    .content-cell {
        padding-left: 20px;
        font-size: .95rem;
    }
`

const MediaDetails = ({ document, isEditDetail, contactID, viewOnly, onTouchForm, confirmDelete }) => {
    const [editing, setEditing] = useState(false);
    const userPermission = useUserPermission('DeleteMedia', [document.UserCreated]);

    useEffect(() => {
        if (isEditDetail) {
            setEditing(true);
        }
    }, [isEditDetail]);

    const getMediaItems = item => {
        let photographer = '';
        if (item.PhotographerID) {
            const customer = getCustomer(item.PhotographerID);
            if (customer) {
                photographer = customer.N;
            }
        }
        const legalItems = [];
        legalItems.push({
            label: 'Title',
            value: item.Title
        });
        if (document.TypeName !== 'Document') {
            legalItems.push({
                label: 'Location',
                value: item.Location
            });
            legalItems.push({
                label: 'Author',
                value: item.Author
            });
            legalItems.push({
                label: 'Shooting Date',
                value: showDateNoTime(item.ShootingDate)
            });
        }
        legalItems.push({
            label: 'Notes',
            value: item.Notes
        });
        if (document.TypeName !== 'Document') {
            legalItems.push({
                label: document.TypeName === 'Photo' ? 'Photographer' : 'Director',
                value: photographer
            });
            legalItems.push({
                label: 'Public Description',
                value: item.PublicDescription?item.PublicDescription:""
            });
        }
        legalItems.push({
            label: 'Visibility',
            value: <Visibilities hideLabel visibilities={item.Visibility.Areas} />
        })
        return legalItems;
    };

    const handleDelete = () => {
        if (!document.DocumentPlaylists.length) {
            confirmDelete();
        }
    }

    const renderContent = () => {
        const pictureItems = document && getMediaItems(document);
        return (
            <FlexContainer alignItems='flex-start' justifyContent='space-between' fullWidth>
                <DataFieldsTable>
                    <tbody>
                        {pictureItems.map((item, index) => (
                            <tr key={`picture-item-${index}`}>
                                <td className="title-cell">{item.label}</td>
                                <td className="content-cell">{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </DataFieldsTable>
                {/* <NwDescriptionList items={pictureItems} /> */}
                {!viewOnly && 
                    <NwDropDownMenu
                        actions={[
                            { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => handleEditMode() },
                            { 
                                name: "delete", 
                                type: "delete", 
                                key: "delete", 
                                icon: light("trash-alt"), 
                                confirmationPlacement: "left", 
                                disabled: !userPermission || document.DocumentPlaylists.length > 0,
                                action: (() => handleDelete()) ,
                                description: document.DocumentPlaylists.length > 0 && 'Please remove the picture from books before deleting it'
                            }
                        ]}
                    />
                }
            </FlexContainer>
        )
    }

    const handleEditMode = () => {
        setEditing(true);
    }

    return (
        <MediaDetailsCard
            icon={light("info-square")}
            title="Info"
        >
            {renderContent()}
            {editing &&
                <NwDrawer
                    title='Edit Detail'
                    onClose={() => setEditing(false)}>
                    <MediaDetailForm 
                        onTouchForm={onTouchForm}
                        onCancel={() => setEditing(false)} 
                        document={document} 
                        contactID={contactID} 
                        isEditDetail={isEditDetail} />
                </NwDrawer>
            }
        </MediaDetailsCard>
    )
};

export default MediaDetails;