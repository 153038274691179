import React from "react";
import styled from "styled-components";
import NwIcon from "./NwIcon";


const ActionLink = styled.span`
    cursor: ${props => props.$disabled ? "not-allowed" : "pointer"};
    color: ${props => props.$disabled ? props.theme.TextColorMuted : props.theme.PrimaryColor};
    display: ${props => props.$block ? 'block' : 'inline-block'};
    font-size: ${props => props.$small ? props.theme.FontSizeSm : 'inherit'};

    em {
        font-style: normal;
        text-decoration: ${props => props.$disabled ? "none" : "underline"};
    }

    svg {
        padding-right: .5em;
    }
`;

const NwLink = ({ children, icon, onClick, block, style, small, disabled, ...props }) => {
    return (
        <ActionLink
            {...props}
            onClick={disabled ? null : onClick} 
            $block={block} 
            style={style}
            $small={small}
            $disabled={disabled}
        >
            {icon && <NwIcon icon={icon} />}
            <em>{children}</em>
        </ActionLink>
    );
}

export default NwLink;