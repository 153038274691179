import React, { useState, useEffect } from "react";
import { getMoment } from "Libs/NwMoment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { Alert } from 'antd';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
    NWInput,
    NWTextAreaCharCounter,
    NWRadioButtonGroup,
    NWRangePicker
} from "Components/Gui/NWForm/NwFormItems";
import NwIcon from 'Components/Gui/NwIcon';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwRemoveLinkButton, NwSaveButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import AddressLine from 'Components/Addresses/AddressLine';
import ExistingRoomLodging from 'Components/EventDrawer/RoomLodgingEvent/ExistingRoomLodging';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import {
    useUpdateAccommodation,
    useAddAccommodation,
    useDeleteAccommodation
} from 'Hooks/Event/UseAccommodation';
// import { ListContext } from 'Contexts/ListContext';
import useConfigurations from "Hooks/UseConfigurations";
import { getNow, toISODate } from "Libs/NwMoment";

const BEDS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FormLabel = styled.div`
    width: 40px;
`;

const RoomPreviewContainer = styled(FlexContainer)`
    .ant-card {
        width: 100%;
    }
`;

const AlertContainer = styled.div`
    width: 100%;
    .ant-alert-info {
        background-color: ${props => props.status === 'empty' ?
        props.theme.LayoutBodyBackground : props.status === 'error' ?
            props.theme.DangerColor : props.theme.SuccessColor};
        border: none;
    }

    .ant-alert-message {
        color: ${props => props.status === 'error' || props.status === 'success' ? 'white' : 'black'};
    }
`;

// const initialAddress = {
//   Country: undefined,
//   City: '',
//   County: '',
//   State: '',
//   Zip: '',
//   Street: '',
//   Access: ''
// };

const EditRoomLodging = ({
    accommodationData,
    afterUpdate,
    connection,
    modelId,
    noDelete,
    onCancel,
    onRemoveAccommodation,
    onTouchForm,
    onUpdateAccommodationData,
    room,
    viewMode,
}) => {
    const { mutateAsync: onAddAccommodation } = useAddAccommodation();
    const { mutateAsync: onUpdateAccommodation } = useUpdateAccommodation();
    const { mutateAsync: onDeleteAccommodation } = useDeleteAccommodation();
    const [selectedConnection, setSelectedConnection] = useState(connection);
    const [roomAccommodations, setRoomAccommodations] = useState([]);
    const [modelAccommodations, setModelAccommodations] = useState([]);
    const [isRoomAvailable, setIsRoomAvailable] = useState(true);
    const [isCheckRoomLodging, setIsCheckRoomLodging] = useState(false);
    const [entireRoom, setEntireRoom] = useState(false);
    const configurations = useConfigurations();

    useEffect(() => {
        const getRoomAccommodations = async () => {
            try {
                const accommodations = await Axios.post('/accommodation/availabilitysearch', {
                    RoomID: room.ID,
                    RoomsOnly: true
                });
                const filteredRoomAccommodations = accommodations.filter(a => a.ID !== accommodationData.ID)
                setRoomAccommodations(filteredRoomAccommodations);
            } catch (error) {
                console.log('ant : Fetch Room Accommodations Error => ', error);
            }
        };

        const getModelAccommodations = async () => {
            try {
                const accommodations = await Axios.post('/accommodation/availabilitysearch', {
                    ModelID: accommodationData ? accommodationData.ModelID : modelId,
                    RoomsOnly: true
                });
                setModelAccommodations(accommodations);
            } catch (error) {
                console.log('ant : Fetch Room Accommodations Error => ', error);
            }
        };

        const getConnection = async () => {
            const connection = await Axios.get(`/connections/${room.ConnectionID}`);
            setSelectedConnection(connection);
        }

        getRoomAccommodations();
        getModelAccommodations();
        getConnection();
    }, []);

    const submitForm = async (values, setSubmitting) => {
        const newAccommodationData = {
            ...values,
            ...values.Address,
            FromDate: values.Period[0],
            ToDate: values.Period[1]
        };

        delete newAccommodationData.Address;

        if (!accommodationData) {
            try {
                const newAccommodation = await onAddAccommodation({ accommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate({
                        ...newAccommodation,
                        TypeName: "Accommodation"
                    });
                }
                onUpdateAccommodationData(newAccommodation.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create Accommodation Error", error);
            }
            setSubmitting(false);
        }
        else {
            newAccommodationData.ID = accommodationData.ID;

            try {
                await onUpdateAccommodation({ accommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateAccommodationData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update Accommodation Error", error);
            }
            setSubmitting(false);
        }
    };

    const getDefaultPeriod = () => {
        return [getNow(true), getNow(true).add(1, 'month')];
    };

    let initialValues = {
        ModelID: accommodationData ? accommodationData.ModelID : modelId ? modelId : '',
        Title: accommodationData ? accommodationData.Title : '',
        ServiceID: accommodationData ? accommodationData.ServiceID : '',
        RoomID: accommodationData ? accommodationData.RoomID : '',
        Note: accommodationData ? accommodationData.Note : '',
        OccupiedBeds: accommodationData ? accommodationData.OccupiedBeds : '',
        Address: {
            Country: accommodationData ? accommodationData.Country : '',
            City: accommodationData ? accommodationData.City : '',
            County: accommodationData ? accommodationData.County : '',
            State: accommodationData ? accommodationData.State : '',
            Zip: accommodationData ? accommodationData.Zip : '',
            Street: accommodationData ? accommodationData.Street : '',
            Access: accommodationData ? accommodationData.Access : '',
        },
        Period: accommodationData ?
            [getMoment(accommodationData.FromDate), getMoment(accommodationData.ToDate)]
            : getDefaultPeriod(),
        PhoneNumber: '',
        ReservationCode: accommodationData ? accommodationData.ReservationCode || '' : ''
    };

    const getOccupiedAccommodations = (roomAccommodations, date) => {
        return roomAccommodations.filter(accommodation => {
            if (accommodation.ID !== accommodationData.ID && toISODate(date) >= toISODate(accommodation.FromDate) && toISODate(date) <= toISODate(accommodation.ToDate)) {
                return true;
            }
            return false;
        });
    }

    const validateOccupiedBeds = (period, requestedBeds) => {
        let validate = true;
        let date, index = 0;
        while ((date = getMoment(period[0]).add(index, 'day').format('YYYY-MM-DD')) <= toISODate(period[0])) {
            let occupiedBeds = 0;
            const occupiedAccommodations = getOccupiedAccommodations(roomAccommodations, date);
            for (const accommodation of occupiedAccommodations) {
                occupiedBeds = occupiedBeds + accommodation.OccupiedBeds;
            }
            if (room.NumBeds < (occupiedBeds + requestedBeds)) {
                validate = null;
            }
            index += 1;
        }
        if (!validate) {
            setIsRoomAvailable(false);
            return false
        } else {
            setIsRoomAvailable(true);
            return true
        }
    };

    const validateForm = values => {
        if (values.Period && values.ModelID) {
            if (!validateOccupiedBeds(values.Period, values.OccupiedBeds)) {
                return (
                    <AlertContainer status='error'>
                        <Alert
                            message={
                                <>
                                    <NwIcon icon={solid('do-not-enter')} />&nbsp;
                                    Not available for this period
                                </>
                            } />
                    </AlertContainer>
                );
            }
            return (
                <AlertContainer status='success'>
                    <Alert
                        message={
                            <>
                                <NwIcon icon={light('check')} />&nbsp;
                                Available
                            </>
                        } />
                </AlertContainer>
            );
        }

        return (
            <AlertContainer status='empty'>
                <Alert message={'Select model and period to check room availability'} />
            </AlertContainer>
        )
    };

    const getTypeIcon = type => {
        switch (type) {
            case 'Land':
                return light('phone-office');
            case 'Mobile':
                return solid('mobile-alt');
            case 'Fax':
                return light('fax');
            case 'Other':
                return light('phone');
            default:
                break;
        }
    };

    const disableDate = rangeDate => {
        const period = modelAccommodations.find(accommodation => {
            if (accommodation.ID === accommodationData.ID) {
                return false;
            }
            if (toISODate(rangeDate) >= toISODate(accommodation.FromDate) && toISODate(rangeDate) <= toISODate(accommodation.ToDate)) {
                return true;
            }
            return false;
        });

        if (period) {
            return true;
        }
    };

    const validateRange = range => {
        if (range) {
            const period = modelAccommodations.find(accommodation => {
                if (accommodation.ID === accommodationData.ID
                    || toISODate(range[0]) > toISODate(accommodation.ToDate)
                    || toISODate(range[1]) < toISODate(accommodation.FromDate)
                ) {
                    return false;
                }
                return true;
            });

            if (period) {
                return 'This period overlaps with other lodgings';
            }
            return null;
        }
        return 'Please select period';
    };

    const handleCheckRoomLodging = () => {
        setIsCheckRoomLodging(true);
    };

    const handleRemoveLodging = async () => {
        try {
            await onDeleteAccommodation({ ID: accommodationData.ID, accommodationId: accommodationData.ID })
            onCancel()
            if (onRemoveAccommodation) {
                onRemoveAccommodation();
            } else {
                onUpdateAccommodationData()
            }
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    }

    return (
        <div className='event-form'>
            <div className='drawer-content'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        Title: Yup.string().required("Content is required"),
                        ServiceID: Yup.number().required('Content is required'),
                        ModelID: Yup.number().required('Content is required'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        submitForm(values, setSubmitting);
                    }}
                >
                    {({ isSubmitting, values, handleSubmit, isValid }) => (
                        <>
                            <NwForm
                                values={values}
                                onTouchForm={onTouchForm}
                                small
                                onFinish={handleSubmit}
                                layout="vertical">
                                <FlexContainer alignItems='flex-start'>
                                    <Field
                                        component={NWRangePicker}
                                        label={
                                            <div>
                                                First Night
                                                <span style={{ width: "5.5rem", display: "inline-block" }}>&nbsp;</span>
                                                Last Night
                                            </div>
                                        }
                                        validate={validateRange}
                                        name='Period'
                                        format="MMM DD, YYYY"
                                        disabledDate={disableDate}
                                        value={values.Period}
                                    />
                                    <p
                                        style={{ margin: '24px 16px 0' }}
                                        className={`nw-link ${values.ModelID ? '' : 'disabled'}`}
                                        onClick={values.ModelID ? handleCheckRoomLodging : undefined}>
                                        Check model's lodgings
                                    </p>
                                </FlexContainer>
                                <FlexContainer alignItems='flex-start'>
                                    <FlexContainer alignItems='flex-start'>
                                        <FormLabel>Beds</FormLabel>
                                        <Field
                                            size='small'
                                            component={NWRadioButtonGroup}
                                            value={values.OccupiedBeds}
                                            name="OccupiedBeds"
                                            buttonStyle="solid"
                                            options={BEDS.map(bed => {
                                                return { key: bed, value: bed, label: bed };
                                            })}
                                        />
                                    </FlexContainer>
                                    <FlexContainer mg='0 16px'>
                                        <CustomSwitch
                                            text='Entire Room'
                                            value={entireRoom}
                                            onChange={setEntireRoom} />
                                    </FlexContainer>
                                </FlexContainer>


                                <>
                                    <RoomPreviewContainer mg='0 0 16px' fullWidth>
                                        <NwCard
                                            style={{ width: '100%' }}
                                            title={selectedConnection.Name}>
                                            <div>{room.Name}</div>
                                            <div>{`${room.NumBeds} beds / ${room.BedPrice} ${configurations.defaultCurrency} per night`}</div>
                                            <FlexContainer mg='8px 0 0'>
                                                {validateForm(values)}
                                            </FlexContainer>
                                        </NwCard>
                                    </RoomPreviewContainer>
                                    <Field
                                        component={NWInput}
                                        label="Title"
                                        name="Title"
                                        type="text"
                                        value={values.Title}
                                    />
                                    <NwDivider>Addresses</NwDivider>
                                    <FlexContainer alignItems='flex-start' justifyContent='space-between'>
                                        {selectedConnection.Addresses.length > 0 &&
                                            <FlexContainer mg='0 0 16px'>
                                                <AddressLine address={selectedConnection.Addresses[0]} disableMap />
                                            </FlexContainer>
                                        }
                                        {selectedConnection.Phones.filter(phone => phone.Number).length > 0 &&
                                            <Field
                                                component={NWRadioButtonGroup}
                                                name="PhoneNumber"
                                                value={values.PhoneNumber}
                                                buttonType='radio'
                                                options={selectedConnection.Phones.map(phone => {
                                                    return {
                                                        key: phone.ID,
                                                        value: phone.Number,
                                                        label: phone.Number,
                                                        icon: getTypeIcon(phone.Type)
                                                    };
                                                })}
                                            />
                                        }
                                    </FlexContainer>
                                    <NwDivider />
                                    <Field
                                        component={NWTextAreaCharCounter}
                                        label="Note"
                                        name="Note"
                                        type="text"
                                        value={values.Note}
                                        maxLength={2000}
                                        autoSize={{ minRows: 4, maxRows: 10 }}
                                    />
                                    <Field
                                        component={NWInput}
                                        label="Reservation Code"
                                        name="ReservationCode"
                                        type="text"
                                        value={values.ReservationCode}
                                    />
                                    <NwFormButtonsBar
                                        left={
                                            <NwCancelButton
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    if (onTouchForm) {
                                                        onTouchForm(false);
                                                    }
                                                    onCancel();
                                                }}
                                            />
                                        }
                                        right={
                                            <NwSaveButton
                                                htmlType="submit"
                                                disabled={isSubmitting || !isValid || !isRoomAvailable}
                                                loading={isSubmitting}
                                            />
                                        }
                                    />
                                </>
                            </NwForm>
                            <br />
                            {!noDelete && (
                                <NwRemoveLinkButton onClick={handleRemoveLodging} label="Delete room lodging" />
                            )}
                        </>
                    )}
                </Formik>
                {isCheckRoomLodging &&
                    <ExistingRoomLodging
                        accommodations={modelAccommodations}
                        onClose={() => setIsCheckRoomLodging(false)} />
                }
            </div>
        </div>
    );
};

export default React.memo(EditRoomLodging);
