import React from "react";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { showDateNoTime, showDate, showTime, isAllDay } from "Libs/NwMoment";

const HorizontalPeriodContainer = styled.div`
    padding: ${props => props.$noPadding ? '0' : '.75rem 0'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;


    .period-date {
        font-size: 1rem;
        text-transform: uppercase;
    }

    .period-arrow {
        font-size: 1rem;
        padding: 0 .25rem;
    }

    .no-period {
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
    }

    &.horizontal-period-container-small {
        padding: ${props => props.$noPadding ? '0' : '.25rem 0'};
        
        .period-date {
            font-size: ${props => props.theme.FontSizeSm};
        }
        .period-arrow {
            font-size: ${props => props.theme.FontSizeSm};
            padding: 0 .25rem;
        }
    }

    &.horizontal-period-container-big {
        .period-date {
            font-size: 1.25rem;
        }
        .period-arrow {
            font-size: 1.25rem;
        }
    }
`

const HorizontalPeriod = ({ startDate, endDate, size, emptyText, noPadding, time, hideDate }) => {

    let className = "horizontal-period-container"
    if (size) {
        className += ` horizontal-period-container-${size}`
    }

    if (startDate && endDate) {
        if (time) {
            const st_string = startDate;
            const et_string = endDate;
            const st = getMoment(st_string);
            const et = getMoment(et_string);
            if (et && st.isSame(et, 'day')) {
                //day on top (if not hidden), time with arrow (if not allday)
                if (isAllDay(st, et)) {
                    return (
                        <HorizontalPeriodContainer className={className} $noPadding={noPadding}>
                            {!hideDate && <div className='period-date'><strong>{showDateNoTime(startDate)}</strong></div>}
                            <div className='period-date'>ALL<br />DAY</div>
                        </HorizontalPeriodContainer>
                    )
                } else {
                    return (
                        <HorizontalPeriodContainer className={className} $noPadding={noPadding}>
                            {!hideDate && <div className='period-date'><strong>{showDateNoTime(startDate)}</strong></div>}
                            <div className='period-date'>{showTime(startDate)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-right')} /></div>
                            <div className='period-date'>{showTime(endDate)}</div>
                        </HorizontalPeriodContainer>
                    )
                }
            } else {
                //start day and time then arrow the end day and time
                if (isAllDay(st, et)) {
                    return (
                        <HorizontalPeriodContainer $noPadding={noPadding} className={className}>
                            <div className='period-date'>{showDateNoTime(startDate, false)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-right')} /></div>
                            <div className='period-date'>{showDateNoTime(endDate)}</div>
                        </HorizontalPeriodContainer>
                    )
                } else {
                    return (
                        <HorizontalPeriodContainer $noPadding={noPadding} className={className}>
                            <div className='period-date'>{showDate(startDate)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-right')} /></div>
                            <div className='period-date'>{showDate(endDate)}</div>
                        </HorizontalPeriodContainer>
                    )
                }

            }
        } else {
            return (
                <HorizontalPeriodContainer $noPadding={noPadding} className={className}>
                    <div className='period-date'><strong>{showDateNoTime(startDate, false)}</strong></div>
                    <div className='period-arrow'><NwIcon icon={light('arrow-right')} /></div>
                    <div className='period-date'><strong>{showDateNoTime(endDate)}</strong></div>
                </HorizontalPeriodContainer>
            )
        }
    } else {
        return (
            <HorizontalPeriodContainer $noPadding={noPadding} className="horizontal-period-container">
                <div className="no-period">
                    {emptyText
                        ?
                        <>{emptyText}</>
                        :
                        <>no<br />period</>
                    }
                </div>
            </HorizontalPeriodContainer>
        )
    }
}

export default HorizontalPeriod