import { produce } from 'immer'
import ls from "local-storage";

const persistsArea = (value) => {
    ls.set("nw_config_area", value);
}

const AreaReducer =  (state, action) => produce(state, draft => {
    switch (action.type) {
        case 'updateArea':
            draft.area = action.area; 
            break;
        case 'updateContactsLoading':
            draft.areaContactsLoading = action.areaContactsLoading
            break;
        default:
    }
    persistsArea(draft)
});

export default AreaReducer
