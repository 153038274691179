import React from 'react';
import { Badge, Tooltip } from 'antd';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import { light, solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { showDateNoTime } from 'Libs/NwMoment';
import styled from 'styled-components';
import { hasIn } from 'lodash';

const Wrapper = styled.span`
    font-size: ${props => props.$small ? '1.1rem' : '1.25rem'};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    color: ${props => props.$green ? props.theme.SuccessColor : props.theme.TextColor};
    
    .ant-badge {
        padding: 0 .15rem;
        .ant-badge-status-dot {
            width: ${props => props.$small ? '.6rem' : '.75rem'};
            height: ${props => props.$small ? '.6rem' : '.75rem'};
        }
    }

`

const StatementStatusIcon = ({ 
    additionalText,
    onInvoiceClick,
    statement, 
    small,
}) => {

    //console.log('statement', statement)

    const ColorMap = {
        Option: StyleVariables.WarningColor,
        Job: StyleVariables.DangerColor,
        Billable: StyleVariables.SuccessColor
    }
    const TextMap = {
        Option: "Option",
        Job: "Confirmed",
        Billable: "To Bill"
    }
    const addtxt = () => {
        if (additionalText) {
            return additionalText + ' - '
        }
        return ''
    }

    const hasInvoiceClick = hasIn(onInvoiceClick, 'call')

    const handleInvoiceClick = () => {
        onInvoiceClick(statement.InvoiceID)
    }

    const Content = () => {
        const { Status, InvoiceNumber, InvoiceDate, PaidStatus } = statement;
        if (PaidStatus === "Paid") {
            return (
                <Tooltip title={`${addtxt()}Invoice #${InvoiceNumber} - ${showDateNoTime(InvoiceDate)}`}>
                    <Wrapper $small={small} $green>
                        {hasInvoiceClick ?
                        <NwIcon icon={solid("check")} pointer onClick={handleInvoiceClick} />
                        :
                        <NwIcon icon={solid("check")} />
                        }
                    </Wrapper>
                </Tooltip>
            )
        }
        switch (Status.toLowerCase()) {
            case "invoiced":
                return (
                    <Tooltip title={`${addtxt()}Invoice #${InvoiceNumber} - ${showDateNoTime(InvoiceDate)}`}>
                        <Wrapper $small={small}>
                            {hasInvoiceClick ?
                            <NwIcon icon={thin("file-invoice")} pointer onClick={handleInvoiceClick} />
                            :
                            <NwIcon icon={thin("file-invoice")} />
                            }
                        </Wrapper>
                    </Tooltip>
                )
            case "locked":
                return (
                    <Tooltip title={`${addtxt()}Locked - In a invoice draft`}>
                        <Wrapper $small={small}>
                            {hasInvoiceClick ?
                            <NwIcon icon={thin("lock")} pointer onClick={handleInvoiceClick} />
                            :
                            <NwIcon icon={thin("lock")} />
                            }
                        </Wrapper>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip title={`${addtxt()}${TextMap[Status]}`}>
                        <Wrapper $small={small}>
                            <Badge color={ColorMap[Status]} />
                        </Wrapper>
                    </Tooltip>
                )
        }
    }
    return (
        <Content />
    )
}

export default StatementStatusIcon