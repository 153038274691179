import React, { useContext } from "react";
import { Select } from "antd";
import styled from "styled-components";
import { ListContext } from "Contexts/ListContext";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import FilterTypeSelector from "Components/Gui/NWForm/FilterTypeSelector";
import CurrencyRange from "Components/Gui/NWForm/CurrencyRange";
import { CURRENCIES } from "Configs/CURRENCIES";
const Option = Select.Option;

const TxFilterContent = styled.div`
  position: absolute;
  top: 35px;
  left: -170px;
  width: 520px;
  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

const TransactionFilter = ({ onChange, value, disabled, onBlur }) => {
  const { cachedList } = useContext(ListContext);
  const enumTransactions = cachedList.enumerators.items.find(item => item.Name === "JobTransactionStatusEnum");
  const allTx = [ {Value: "All", Name: "All Status"}, ...enumTransactions.Values ];
  const families = cachedList.transactionFamilies.items;
  const labels = [];
  
  families.map(item => {
    if (value.Families && value.Families.find(el => el === item.Name))
      labels.push(...item.TransactionLabels);
    return null;
  });  

  const onOperationChange = (val) => {
    onChange({
      ...value,
      Operation: val
    });
  };

  const onStatusChange = (val) => {
    onChange({
      ...value,
      Status: val
    });
  };

  const onInvoicedChange = (val) => {
    onChange({
      ...value,
      Invoiced: val
    });
  }

  const onPaidChange = (val) => {
    onChange({
      ...value,
      Paid: val
    });
  }

  const onFamilyChange = (val) => {
    onChange({
      ...value,
      Labels: null,
      Families: val.length ? val : null
    });
  }

  const onLabelsChange = (val) => {
    onChange({
      ...value,
      Labels: val.length ? val : null
    });
  }

  const onCurrencyRangeChange = (val) => {
    onChange({
      ...value,
      AmountRange: val
    });
  }

  const onCurrencyChange = (val) => {
    onChange({
      ...value,
      Currency: val
    });
  }

  return (
    <TxFilterContent>
      <div className="input-row">
        <RadioButtonGroup 
          value={value.Operation}
          disabled={disabled}
          defaultValue={true}
          onChange={(val) => onOperationChange(val)}
          onBlur={onBlur}
          options={[
            { label: "Any", value: "Any" },
            { label: "All", value: "All" }
          ]}
        />
        <div style={{margin: "auto auto auto 10px"}}>transactions with:</div>
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Status</div>
        <Select
          autoComplete='off'
          mode="single"
          value={value.Status}
          placeholder="Please select"
          onChange={(val) => onStatusChange(val)}
          onBlur={onBlur}
          disabled={disabled}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          style={{width: "277px"}}
        >
          {allTx.map(el => (
            <Option key={el.Value} value={el.Value === "All" ? null : el.Value}>
              {el.Name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Invoiced</div>
        <FilterTypeSelector
          value={value.Invoiced}
          onChange={(val) => onInvoicedChange(val)}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Paid</div>
        <FilterTypeSelector
          value={value.Paid}
          onChange={(val) => onPaidChange(val)}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Families</div>
        <Select
          autoComplete='off'
          mode="multiple"
          value={value.Families ? value.Families : []}
          placeholder="Please select"
          onChange={(val) => onFamilyChange(val)}
          onBlur={onBlur}
          disabled={disabled}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          style={{width: "277px"}}
        >
          {families.map(el => (
            <Option key={el.Code} value={el.Code}>
              {el.Name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Labels</div>
        <Select
          autoComplete='off'
          mode="multiple"
          value={value.Labels ? value.Labels : []}
          placeholder="Please select"
          onChange={(val) => onLabelsChange(val)}
          onBlur={onBlur}
          disabled={disabled}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          style={{width: "277px"}}
        >
          {labels.map(el => (
            <Option key={el.ID} value={el.ID}>
              {el.Label}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Currency</div>
        <Select
            autoComplete='off'
            value={value.Currency}
            placeholder="Please select"
            onChange={(val) => onCurrencyChange(val)}
            onBlur={onBlur}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            style={{width: "277px"}}
        >
          {CURRENCIES.map(el=> <Option key={el.code} value={el.code}>{el.code}</Option>)}
        </Select>
      </div>
      <div className="input-row">
        <div style={{margin: "auto 10px"}}>Amount between</div>
        <CurrencyRange
          value={value.AmountRange}
          disabled={disabled}
          onBlur={onBlur}
          onChange={(val) => onCurrencyRangeChange(val)}
        />
      </div>
    </TxFilterContent>
  );
};

export default TransactionFilter;
