import React from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Loading } from 'Components/Gui/Loading';
import FolderModels from 'Components/ModelSelector/ModelsWall/FolderModels/FolderModels';
import MiniLoading from 'Components/Gui/MiniLoading';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';
import useDimensions from "Hooks/UseDimensions";
import { useStateValue } from "Contexts/StateProvider";

const WallContainer = styled.div`
    padding: 1rem;
    margin-bottom: 24px;
`;

const Title = styled(FlexContainer)`
    font-size: 1.3rem;
    font-weight: bold;
`;

const TitleLoading = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
`;

const LoadingContainer = styled.div`
    margin-top: -6px;
`;

const HeaderPanel = styled.div`
    padding: 16px;
    background: white;
    border-radius: 6px;
`;


const FolderModelsWrapper = (props) => {
    const {
        models,
        isLoading,
        onSelectModel,
        onEditFolder
    } = props;

    const [ref] = useDimensions();
    const [{ folderContext, modelFiltersContext, areaContext }] = useStateValue();
    const { activeSet } = modelFiltersContext[areaContext.area]

    const folder = folderContext.openFolders.find(folder => folder.ID === activeSet);

    return (
        <>
            <WallContainer ref={ref}>
                <HeaderPanel>
                    {!props.isLoading ? 
                        <FlexContainer fullWidth justifyContent='space-between'>
                            <Title>
                                {`${folder.title} - ${models.length || 'no'} models`}
                            </Title>
                            <NwButton ghost icon={light('arrow-left')} label='back' onClick={() => onEditFolder(false)} />
                        </FlexContainer>
                        : 
                        <>
                            <LoadingContainer>
                                <MiniLoading />
                            </LoadingContainer>
                            <TitleLoading>Fetching Models...</TitleLoading>
                        </>
                    }
                </HeaderPanel>
                {isLoading ?
                    <Loading loadingType="centered" />
                    :
                    <FolderModels
                        models={models}
                        onSelectModel={onSelectModel} />
                        
                }
            </WallContainer>
        </>
    )
};

export default FolderModelsWrapper;
