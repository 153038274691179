import React, { useState } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import CountryLabel from 'Components/Gui/CountryLabel';
import AnagraphicalDrawer from "Components/ContactDetails/CommonComponents/Anagraphical/AnagraphicalDrawer";
import { getAge } from "Libs/NwMoment";
import { showDateNoTime } from "Libs/NwMoment";
import NwIcon, { NwGenderIcon } from "Components/Gui/NwIcon";
import PermissionBlock from "Components/PermissionBlock";
import { useUserPermission } from 'Contexts/UserContext';

const ContactDetailsHeaderAnagraphical = ({ contact, iconSize, readOnly, noDrawer }) => {
    const [drawer, setDrawer] = useState(false);

    const userids = (contact && contact.users) ? contact.Users.map(user => user.UserID) : [];

    const viewModelAnagraphical = useUserPermission('ViewModelAnagraphical', userids);
    const birthDate = contact.BirthDate || contact.B
    const nationalityCode = contact.NationalityCode || contact.NAT
    const gender = contact.GenderName || contact.G

    const getBirthdayContent = () => (
        <>
            {getAge(birthDate)} y.o.&nbsp;&nbsp;<NwIcon icon={light("birthday-cake")}/>&nbsp;&nbsp;{showDateNoTime(birthDate)}
        </>
    )

    const renderContactDetailsHeaderAnagraphical = () => {
        return (contact.ContactType === 'Customer' ?
            <NwIcon size={iconSize} icon={light("id-card")} />
            :
            <>  
                <NwGenderIcon gender={`${gender}`} />
                &nbsp;&nbsp;
                {birthDate ? getBirthdayContent() : `birthday: n.d.` }
                &nbsp;&nbsp;&nbsp;
                {nationalityCode && <CountryLabel usecode code={nationalityCode} flag />}
            </>
        )
    }
    
    return (
        <>
            <div className="header-body-boxes header-body-boxes-hoverable" onClick={() => viewModelAnagraphical && setDrawer(true)}>
                <PermissionBlock
                    permission={viewModelAnagraphical}
                    content={renderContactDetailsHeaderAnagraphical()} />
            </div>
            {drawer && !noDrawer && 
                <AnagraphicalDrawer contactID={contact.ID} readOnly={readOnly} type={contact.ContactType === 'Customer' ? 0 : 1} onClose={() => {
                    setDrawer(false)
                }} />
            }
        </>
    )
};

export default ContactDetailsHeaderAnagraphical;
