import React, { useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ls from 'local-storage';

import NwDrawer from "Components/Gui/NwDrawer";
import constants from 'Constants';
import RedImage from '_img/markers/dot-red.png';
import BlackImage from '_img/markers/dot-black.png';
import GreenImage from '_img/markers/dot-green.png';
import BlueImage from '_img/markers/dot-blue.png';

const ModelAgenciesMap = ({ google, agencies, onClose }) => {
    const [touchForm, setTouchForm] = useState(false);
    const mapRef = useRef(null);   
    const cities = ls.get('nw_cachedlist_cities') ? ls.get('nw_cachedlist_cities') : [];

    const getMotherAgenciesByCity = city => {
        const motherAgencies = agencies ? agencies.filter(agency => {
            const towns = agency.Towns ? agency.Towns.split(',') : [];
            if (towns.find(town => town === city.Name)) {
                return true;
            }
            return false;
        }) : [];
        return motherAgencies
    };

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={"Map"}
            width="80vw"
        >
            <Map 
                ref={mapRef}
                google={google}
                containerStyle={{
                    height: '100%',
                    minHeight: '450px',
                    width: '100%',
                    position: 'relative',
                }}
                initialCenter={{
                    lat: 29.48003424,
                    lng: -37.6242444
                }}
                zoom={2}
            >
                {cities.map((city, index) => {
                    let imageUrl = null;
                    if (city.PlacementRelevant) {
                        const motherAgencies = getMotherAgenciesByCity(city);
        
                        imageUrl = RedImage;
                        if (motherAgencies.length > 0) {
                            if (motherAgencies.find(agency => agency.TypeName === 'Mother')) {
                                imageUrl = BlackImage;
                            } else {
                                imageUrl = GreenImage;
                            }
                        }
                    } else {
                        const motherAgencies = getMotherAgenciesByCity(city);
                        if (motherAgencies.length > 0) {
                            imageUrl = BlueImage;
                        }
                    }
                    return (
                        imageUrl &&
                            <Marker
                                key={`${city.Name}_${index}`}
                                icon={{
                                    url: imageUrl
                                }}
                                position={{
                                    lat: city.Lat,
                                    lng: city.Lng
                                }}
                            /> 
                        )
                })}
            </Map>
        </NwDrawer>
    );
};

export default GoogleApiWrapper({
    apiKey: constants.google_map_api_key, // google maps key
    libraries: ['places'],
  })(ModelAgenciesMap);