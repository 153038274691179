import React from "react";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const CustomerTypeSelector = (props) => {
    return (
        <RadioButtonGroup 
            value={props.value} 
            disabled={props.disabled}
            onChange={props.onChange}
            defaultValue={false}
            options={[
                { label: 'All', value: null },
                { label: 'Clients', value: 'Client' },
                { label: 'Agencies', value: 'Agency' },
                { label: 'Services', value: 'Service' },
            ]} />
    );
};

export default CustomerTypeSelector;
