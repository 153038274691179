import React, { useContext, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Input } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { ListContext } from 'Contexts/ListContext';
import NwIcon from 'Components/Gui/NwIcon';
import NwSelect from 'Components/Gui/NWForm/CustomSelect';

const CitySelector = (props) => {
	const {cachedList} = useContext(ListContext)
	//This will execute just 1 time on first render
	const [addNewCity, setAddNewCity] = useState(cachedList.cities.items.length === 0)
	const [savedText, setSavedText] = useState("")
	const [hasFocus, setHasFocus] = useState(false)
	// const onAdd = () => {
	// 	setAddNewCity(true)
	// }

	const onAdd = () => {
		setHasFocus(false)
		setAddNewCity(true)
		props.onChange(savedText)
		//props.onChange()
	}

	const backToSearch = () => {
		setSavedText("")
		setHasFocus(true)
		setAddNewCity(false)
		//props.onChange()
	}

	const saveSearchText = (value) => {
		setSavedText(value)
	}

	return addNewCity ?
        <Input 
            name={props.name} 
            disabled 
            value={savedText} 
            onChange={(e) => {props.onChange(e.target.value)}} 
            onBlur={(e) => {props.onBlur(e.target.value)}} 
            suffix={<NwIcon icon={light("pen")} onClick={() => backToSearch()} />}  />
    :
    <NwSelect
        allowClear={true}
        showSearch={true}
        value={props.value}
        onSearch={saveSearchText}
        onChange={val => props.onChange(val)}
        onBlur={val => props.onBlur(val)}
        autoFocus={hasFocus}
        dropdownRender={menu => (
            <div>
                {menu}
               <NwDividerVertical style={{ margin: '4px 0' }} />
                <div style={{ padding: '8px', cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => onAdd()}
                >
                    <LegacyIcon type="plus" /> Add item
                </div>
            </div>
        )}
        options={cachedList.cities.items.map(c => ({
            value: c.Name,
            label: c.Name
        }))}
    />;
}

export default CitySelector;