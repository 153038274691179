import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { Switch } from 'antd';

import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import TimePicker from 'Components/EventDateTimePicker/TimePicker';
import AllDaySwitch from 'Components/EventDateTimePicker/AllDaySwitch';
import { isAllDay, timeArrayToString, timeStringToArray, getTime, getNow } from 'Libs/NwMoment';

const WrapContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: ${props => props.layout === 'vertical' ? 'flex-start' : 'center' };
    flex-direction: ${props => props.layout === 'vertical' && 'column'};
    margin: ${props => props.mg};
`;

const EventDateTimePicker = ({
    // value,
    eventMode,
    layout,
    defaultStartDate,
    defaultEndDate,
    startFullDay,
    onChange
}) => {

    const getDefaultStartDate = (eventMode) => {
        if (eventMode === "period-notime" || startFullDay) {
            return getNow(true)
        }
        return getNow().hour(9).minute(0)
    }
    
    const getDefaultEndDate = (eventMode) => {
        if (eventMode === "period-notime" || startFullDay) {
            return getNow().endOf('day')
        }
        return getNow().hour(18).minute(0)
    }

    //TODO: deal with selected date with time to set (ex: drag in calendar on a)
    
    const [allDay, setAllDay] = useState(isAllDay(defaultStartDate || getDefaultStartDate(eventMode), defaultEndDate || getDefaultEndDate(eventMode)))
    const [currentStartDate, setCurrentStartDate] = useState(defaultStartDate || getDefaultStartDate(eventMode))
    const [currentEndDate, setCurrentEndDate] = useState(defaultEndDate || getDefaultEndDate(eventMode))
    const [currentStartTime, setCurrentStartTime] = useState(getTime(defaultStartDate || getDefaultStartDate(eventMode)))
    const [currentEndTime, setCurrentEndTime] = useState(getTime(defaultEndDate || getDefaultEndDate(eventMode)))
    
    // const composeTime = useCallback(() => {
    // }, []);

    useEffect(() => {
        const startdate = getMoment(currentStartDate).hour(currentStartTime[0]).minute(currentStartTime[1]);

        let enddate;
        switch(eventMode) {
            case 'singledate':
                enddate = getMoment(currentStartDate).hour(currentEndTime[0]).minute(currentEndTime[1]);
                break;
            case 'moment':
                enddate = startdate;
                break;
            default:
                enddate = getMoment(currentEndDate).hour(currentEndTime[0]).minute(currentEndTime[1]);
                break;
        }
        onChange(
            {
                startdate: startdate,
                enddate: enddate
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventMode, currentStartDate, currentEndDate, currentStartTime, currentEndTime, allDay])

    // const handleChange = pickerValue => {
    //     switch (pickerValue.name) {
    //         case 'startDate':
    //             onChange({
    //                 ...value,
    //                 [startDateFieldName]: pickerValue.value
    //             });
    //             break;
    //         case 'endDate':
    //             onChange({
    //                 ...value,
    //                 [endDateFieldName]: pickerValue.value
    //             });
    //             break;
    //         case 'startTime':
    //             onChange({
    //                 ...value,
    //                 startTime: pickerValue.value
    //             });
    //             break;
    //         case 'endTime':
    //             onChange({
    //                 ...value,
    //                 endTime: pickerValue.value
    //             });
    //     }
    // };

    const handleChangeStartDay = value => {
        setCurrentStartDate(value)
    }
    const handleChangeEndDay = value => {
        setCurrentEndDate(value)
    }
    const handleChangeStartTime = value => {
        setCurrentStartTime(timeStringToArray(value))
    }
    const handleChangeEndTime = value => {
        setCurrentEndTime(timeStringToArray(value))
    }

    const onChangeAllDay = (checked, event) => {
        setCurrentStartTime([0,0])
        setCurrentEndTime([23,59])
        setAllDay(checked)
    }

    const enableDifferentEndDate = (checked) => {
        let newEndDate = getMoment(currentStartDate);
        if (checked) {            
            setCurrentEndDate(newEndDate.add(1,"d"));
        } else {
            setCurrentEndDate(newEndDate);
        }        
    }

    const isSingleDay = () => (currentEndDate.diff(currentStartDate,"days") === 0)

    const renderingEventDate = () => {
        switch (eventMode) {
            case 'singledate':
                return (
                    <WrapContainer layout={layout}>
                        <WrapContainer>
                            <CustomDatePicker
                                label='Start Date'
                                name='startDate'
                                defaultDate={currentStartDate}
                                onChange={handleChangeStartDay} />&nbsp;
                            <AllDaySwitch onChange={onChangeAllDay} checked={allDay} />
                        </WrapContainer>
                        {!allDay &&
                            <WrapContainer mg={layout === 'vertical' && '4px 0 0'}>
                                <TimePicker label='Start Time' name='startTime' value={timeArrayToString(currentStartTime)} onChange={handleChangeStartTime} />
                                <TimePicker label='End Time' name='endTime' value={timeArrayToString(currentEndTime)} onChange={handleChangeEndTime} />
                            </WrapContainer>
                        }
                    </WrapContainer>
                );
            case 'period':
                return (
                    <WrapContainer layout={layout}>
                        <WrapContainer>
                            <CustomDatePicker
                                label='Start Date'
                                name='startDate'
                                defaultDate={currentStartDate}
                                onChange={handleChangeStartDay} />
                            <TimePicker 
                                label='Start Time' 
                                name='startTime' 
                                value={timeArrayToString(currentStartTime)} 
                                onChange={handleChangeStartTime} />
                        </WrapContainer>
                        <WrapContainer mg={layout === 'vertical' && '4px 0 0'}>
                            <TimePicker 
                                label='End Time' 
                                name='endTime' 
                                value={timeArrayToString(currentEndTime)} 
                                onChange={handleChangeEndTime} />
                            <Switch checked={!isSingleDay()} onChange={enableDifferentEndDate} /><label>different date</label>
                            {!isSingleDay() &&
                            <CustomDatePicker
                                label='End Date'
                                name='endDate'
                                defaultDate={currentEndDate}
                                onChange={handleChangeEndDay} />
                            }
                        </WrapContainer>
                    </WrapContainer>
                );
            case 'period-notime':
                return (
                    <WrapContainer>
                        <WrapContainer>
                            <CustomDatePicker
                                label='Start Date'
                                name='startDate'
                                defaultDate={currentStartDate}
                                onChange={handleChangeStartDay} />
                        </WrapContainer>
                        <WrapContainer>
                            <CustomDatePicker
                                label='End Date'
                                name='endDate'
                                defaultDate={currentEndDate}
                                onChange={handleChangeEndDay} />
                        </WrapContainer>
                    </WrapContainer>
                );
            case 'moment':
                return (
                    <WrapContainer>
                        <CustomDatePicker
                            label='Start Date'
                            name='startDate'
                            defaultDate={currentStartDate}
                            onChange={handleChangeStartDay} />
                        &nbsp;
                        <TimePicker 
                            label='Start Time' 
                            name='startTime' 
                            value={timeArrayToString(currentStartTime)} 
                            onChange={handleChangeStartTime} />
                    </WrapContainer>
                )
            // case 'workdays':
            //     return (
            //         <WrapContainer>
            //             <WrapContainer>
            //                 <DatePicker
            //                     label='Start Date'
            //                     name='startDate'
            //                     value={value && value[startDateFieldName]}
            //                     defaultDate={defaultStartDate}
            //                     disabledDate={value && value[endDateFieldName]}
            //                     onChange={handleChangeStartDay} />
            //                 <DatePicker
            //                     label='End Date'
            //                     name='endDate'
            //                     value={value && value[endDateFieldName]}
            //                     defaultEndDate={defaultEndDate}
            //                     disabledDate={value && value[startDateFieldName]}
            //                     onChange={handleChangeEndDay} />
            //             </WrapContainer>
            //             <WrapContainer>
            //                 <TimePicker label='Start Time' name='startTime' value={value && value.startTime} defaultDate={defaultEndDate} onChange={handleChangeStartTime} />
            //                 <TimePicker label='End Time' name='endTime' value={value && value.endTime} onChange={handleChangeEndTime} />
            //             </WrapContainer>
            //         </WrapContainer>
            //     );
            // default: //single date
            //     return (
            //         <WrapContainer>
            //             <DatePicker
            //                 label='Start Date'
            //                 name='startDate'
            //                 value={value && value[startDateFieldName]}
            //                 defaultDate={defaultStartDate}
            //                 onChange={handleChangeStartDay} />
            //             <TimePicker label='Start Time' name='startTime' value={value && value.startTime} onChange={handleChangeStartTime} />
            //         </WrapContainer>
            //     );
            default:
                return null;
        }
    };

    return (
        renderingEventDate()
    )
};

export default EventDateTimePicker;