import React from 'react'
import styled from 'styled-components';
import { Loading } from 'Components/Gui/Loading';
import MobileAgendaDatePicker from './MobileAgendaDatePicker'
import AgendaHeader from './AgendaHeader'
import AgendaEvent from './AgendaEvent'
import EmptyState from 'Components/Gui/EmptyState';
import { useAgendaContext } from './AgendaContext';


const MobileAgendaWrapper = styled.div`
    .mobile-agenda-header-and-events {
        padding-top: ${props => props.$isMobileDrawer ? '1rem' : '20.5rem'};

        .mobile-agenda-events {
            padding-left: ${props => props.$isMobileDrawer ? '0' : '1rem'};
        }
        .empty-state-container {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .castings-loading-container {
        width: 100%;
        background: ${props => props.theme.PrimaryColor};
        padding: 8px;
        text-align: center;
        color: white;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading-label {
            margin-left: 8px;
        }
    }
`

const MobileAgendaContainer = () => {
    const {
        eventsToDisplay,
        isLoadingDay,
        isLoadingMonth,
        isMobileDrawer
    } = useAgendaContext();

    return isLoadingMonth
        ?
        <Loading textBlack small paddingTop="3rem" />
        :
        <MobileAgendaWrapper $isMobileDrawer={isMobileDrawer}>
            <MobileAgendaDatePicker />
            <div className="mobile-agenda-header-and-events">
                <AgendaHeader isMobile />
                {(isLoadingDay)
                    ?
                    <Loading textBlack small paddingTop="3rem" />
                    :
                    ((eventsToDisplay && eventsToDisplay.length)
                        ?
                        <div className="mobile-agenda-events">
                            {eventsToDisplay.map((ev, index) => (
                                <AgendaEvent
                                    key={`${ev.TypeName}-${ev.ID}-${index}`}
                                    event={ev}
                                />
                            ))}
                        </div>
                        :
                        <div className="empty-state-container">
                            <EmptyState message="no events" />
                        </div>
                    )
                }
            </div>
        </MobileAgendaWrapper>
}

export default MobileAgendaContainer;
