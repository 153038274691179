import React, { useContext, useMemo } from 'react'
import { getMoment } from "Libs/NwMoment";
import { Badge, Select, Tooltip } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from "Components/Gui/NwButton";
import { StyledGroupButton } from "Components/Gui/StyledGroupButton";
import { groupBy, keys } from 'lodash';
import { getEventColorsByType } from 'Libs/EventStylesUtils';
import { ListContext } from 'Contexts/ListContext';
import { StyledBadge, BadgesContainer, StyledCalendar, CalendarHeaderContainer } from './AgendaUI';
import { getEventsForDate } from './AgendaUtils';
import { Loading } from 'Components/Gui/Loading';
import { useAgendaContext } from './AgendaContext';


const AgendaDatePicker = ({ afterDateSelection }) => {

    const {
        contactId,
        scheduleRange,
        month,
        monthEvents,
        onSelectDate
    } = useAgendaContext();

    const handleSelectDate = (value) => {
        onSelectDate(value)
        if (afterDateSelection) {
            afterDateSelection()
        }
    }

    const dateSelected = getMoment(scheduleRange.start)
    
    const { cachedList } = useContext(ListContext)

    const evsByDates = useMemo(() => {
        const rangeDate = scheduleRange.start;
        const lastDayOfMonth = getMoment(rangeDate).endOf('month');
        const days = [];
        for (let i = 1; i <= lastDayOfMonth.date(); i++) {
            const dateForLoop = lastDayOfMonth.clone().date(i)
            const evs = getEventsForDate(dateForLoop, monthEvents, null)
            if (evs.length > 0) {
                days.push([...evs])
            } else {
                days.push([])
            }
        }
        return days
    }, [monthEvents])


    const renderDate = (calendardate) => {
        if (evsByDates && evsByDates.length > 0) {
            let evs = []
            let badges = [];
            if (calendardate.month() === month) {
                evs = evsByDates[calendardate.date() - 1]
            }
            if (evs && evs.length > 0) {
                const notCancelledEvent = evs.filter((ev) => (!ev.Cancel))
                const eventsGroupedByTypeAndSubTypeCode = groupBy(notCancelledEvent, (ev) => (ev.TypeName + "-" + ev.SubTypeCode));
                const groups = keys(eventsGroupedByTypeAndSubTypeCode);
                const followupgroupsnumber = groups.filter((gr) => (gr.indexOf("FollowUp-") === 0)).length
                for (const gr of groups) {
                    if (gr.indexOf("-") > 0) {
                        const evtype = gr.split("-")[0];
                        const subtypecode = gr.split("-")[1];
                        const eventsnumber = eventsGroupedByTypeAndSubTypeCode[gr].length
                        switch (evtype) {
                            case "Job":
                                let hasConfirmedJobs = false
                                if (!contactId) {
                                    hasConfirmedJobs = eventsGroupedByTypeAndSubTypeCode[gr].some(ev => ev.ModelsDates.some(md => md.Models.some(m => m.OptionNumber === 10 && !m.Cancel)));
                                } else {
                                    hasConfirmedJobs = eventsGroupedByTypeAndSubTypeCode[gr].some(ev => ev.OptionNumber === 10 && !ev.Cancel);
                                }
                                badges.push({
                                    type: evtype,
                                    subtypecode: subtypecode,
                                    color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, subtypecode, hasConfirmedJobs ? 10 : 1).backgroundColor,
                                    tooltipTitle: `${eventsnumber} ${subtypecode ? (subtypecode + ' (' + evtype + ')') : evtype}`
                                })
                                break;
                            case "FollowUp":
                                if (followupgroupsnumber > 3) {
                                    const followUpBadges = badges.find((b) => (b.type === "FollowUp"))
                                    if (!followUpBadges) {
                                        badges.push({
                                            type: evtype,
                                            subtypecode: null,
                                            color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, null, 1).backgroundColor,
                                            tooltipTitle: `${eventsnumber} ${subtypecode}`
                                        })
                                    } else {
                                        followUpBadges.tooltipTitle += `\n ${eventsnumber} ${subtypecode}`
                                    }
                                } else {
                                    badges.push({
                                        type: evtype,
                                        subtypecode: subtypecode,
                                        color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, subtypecode, 1).backgroundColor,
                                        number: eventsnumber,
                                        tooltipTitle: `${eventsnumber} ${subtypecode ? (subtypecode + ' (' + evtype + ')') : evtype}`
                                    })
                                }
                                break;
                            case "ExtJob":
                                const hasConfirmedExtJobs = eventsGroupedByTypeAndSubTypeCode[gr].filter((ev) => (ev.Confirmed)).length > 0;
                                badges.push({
                                    type: evtype,
                                    subtypecode: null,
                                    color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, subtypecode, hasConfirmedExtJobs ? 10 : 1).backgroundColor,
                                    number: eventsGroupedByTypeAndSubTypeCode[gr].length,
                                    tooltipTitle: `${eventsnumber} ${evtype}`
                                })
                                break;
                            case "Accommodation":
                                const accommodation_name = subtypecode === "1" ? "Room Lodging" : "Accommodation"
                                badges.push({
                                    type: accommodation_name,
                                    subtypecode: null,
                                    color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, subtypecode, 1).backgroundColor,
                                    number: eventsGroupedByTypeAndSubTypeCode[gr].length,
                                    tooltipTitle: `${eventsnumber} ${accommodation_name}`
                                })
                                break;
                            default:
                                badges.push({
                                    type: evtype,
                                    subtypecode: subtypecode,
                                    color: getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, evtype, subtypecode, 1).backgroundColor,
                                    number: eventsnumber,
                                    tooltipTitle: `${eventsnumber} ${subtypecode ? (subtypecode + ' (' + evtype + ')') : evtype}`
                                })
                                break;
                        }
                    }
                }
            }
            return (
                <div className="ant-picker-cell-inner ant-picker-calendar-date">
                    <div className="ant-picker-calendar-date-value">
                        <span>{calendardate.format("DD")}</span>
                    </div>
                    <div className="ant-picker-calendar-date-content">
                        <BadgesContainer>
                            {badges.map((b, index) => (
                                <Tooltip 
                                    key={index} 
                                    placement="topLeft" 
                                    title={<pre>{b.tooltipTitle}</pre>}
                                >
                                    <StyledBadge>
                                        <Badge color={b.color} />
                                    </StyledBadge>
                                </Tooltip>
                            ))}
                            <StyledBadge>
                                <Badge color="white" style={{ width: 0 }} />
                            </StyledBadge>
                        </BadgesContainer>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getCalendarHeader = (value, onChange) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                </Select.Option>,
            );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }
        return (
            <CalendarHeaderContainer>
                <StyledGroupButton>
                    <NwButton
                        icon={light("chevron-left")}
                        onClick={
                            () => {
                                const now = value.clone().subtract(1, 'months');
                                handleSelectDate(now);
                            }
                        }
                    />
                    <Select
                        autoComplete='off'
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={newYear => {
                            const now = value.clone().year(newYear);
                            handleSelectDate(now);
                        }}
                        value={String(year)}
                    >
                        {options}
                    </Select>
                    <Select
                        autoComplete='off'
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        onChange={selectedMonth => {
                            const newValue = value.clone();
                            newValue.month(parseInt(selectedMonth, 10));
                            handleSelectDate(newValue);
                        }}
                    >
                        {monthOptions}
                    </Select>
                    <NwButton
                        icon={light("chevron-right")}
                        onClick={
                            () => {
                                const now = value.clone().add(1, 'months');
                                handleSelectDate(now);
                            }
                        }
                    />
                </StyledGroupButton>
            </CalendarHeaderContainer>
        );
    }

    if (evsByDates && evsByDates.length > 0) {
        return (
            <StyledCalendar
                fullscreen={false}
                mode="month"
                dateFullCellRender={renderDate}
                onPanelChange={handleSelectDate}
                onSelect={handleSelectDate}
                value={dateSelected}
                bordered
                headerRender={({ value, onChange }) => getCalendarHeader(value, onChange)}
            />
        )
    } else {
        return <Loading />;
    }
}

export default AgendaDatePicker
