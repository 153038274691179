import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input, Checkbox } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import TagSelector from 'Components/TagSelector';
import FlexContainer from 'Components/Gui/FlexContainer';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { onSearchProjects } from 'Hooks/Project/UseProject';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';

const days = [
    { value: 15, label: '2w' },
    { value: 31, label: '1m' },
    { value: 185, label: '6m' },
    { value: 0, label: 'ever' },
]

const SearchBoxContainer = styled.div`
    padding: 1rem 2rem;

    .search-box-field {
        width: 450px;
    }
    .settings-icon {
        font-size: 1.3rem;
        margin: 0 16px;
    }
    .search-by-mine {
        margin: 0.5em 0 !important;
        white-space: nowrap;
    }

    .advanced-search-container {
        display: flex;
        padding: 0 0 4px;
        margin: 0 0 32px;
        flex-wrap: wrap;

        
        .today-title {
            margin: 0 0.5em;
            text-transform: uppercase;
        }
    }
`;

const DefaultFilterParams = {
    isSearchOnlyMine: false,
    tags: [],
    //startDays: 31,
    //endDays: 31
    startDays: 0,
    endDays: 0
}

const SearchProject = ({ filter, onChangeFilter, projects, searchOnly, searchMode, onSearchedProjects }) => {
    useFormFieldFocus('search-input')
    const [cache, setCache] = useState('')
    const [filterParams, setFilterParams] = useState(DefaultFilterParams)
    const searchRef = useRef()
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(searchOnly ? searchOnly : false)

    useEffect(() => {
        if (cache.length >= 3) {
            getProject(cache)
        }
    }, [filterParams])

    const handleSearch = event => {
        const value = event.target.value
        onChangeFilter(value)
        if (value.length < 2) {
            onSearchedProjects([])
        } else {
            if (value.length > 2 && value.startsWith(cache)) {
                const updatedProjects = projects.filter((project) => {
                    return project.Contact.Name.toLowerCase().includes(value.toLowerCase()) || project.Name.toLowerCase().includes(value.toLowerCase()) || (project.Description && project.Description.toLowerCase().includes(value.toLowerCase())) || (project.Product && project.Product.toLowerCase().includes(value.toLowerCase()))
                });
                onSearchedProjects(updatedProjects)
            } else {
                getProject(value, null)
            }
        }
        setCache(value)
    }

    const getProject = async value => {
        const { tags } = filterParams
        const convertedTags = tags.map(tag => parseInt(tag))
        const searchData = {
            terms: value,
            onlyMine: filterParams.isSearchOnlyMine,
            tags: convertedTags,
            startDays: filterParams.startDays ? filterParams.startDays : 0,
            endDays: filterParams.endDays ? filterParams.endDays : 0
        }

        let projects = await onSearchProjects(searchData)
        projects = projects.map(result => {
            return {
                ...result,
                key: result.ID
            }
        })
        onSearchedProjects(projects)
        setCache(value)
    }

    const handleShowAdvancedSearch = () => {
        setShowAdvancedSearch(prevValue => !prevValue)
        if (showAdvancedSearch) {
            setFilterParams(DefaultFilterParams)
        }
    }

    const handleChangeSearchByMe = event => {
        setFilterParams({
            ...filterParams,
            isSearchOnlyMine: event.target.checked
        })
    }

    const handleChangeTags = tags => {
        setFilterParams({
            ...filterParams,
            tags
        })
    };

    const revertedDays = [...days].reverse()

    const GetTextLine = () => {
        if (searchMode) {
            return (<p>Type a <strong>client name</strong> or a <strong>project title</strong></p>)
        } else {
            return (<p>Type a <strong>client name</strong></p>)
        }
    }

    return (
        <>
            <SearchBoxContainer>
                <GetTextLine />
                <FlexContainer mg={'0 0 16px'} ref={searchRef}>
                    <Input
                        className="search-box-field" 
                        size="large"
                        id='search-input'
                        value={filter}
                        autoComplete='off'
                        placeholder="client name / project title" 
                        onChange={handleSearch}
                         />
                    <NwIcon icon={light('gear')} className="settings-icon" onClick={handleShowAdvancedSearch} />
                    {showAdvancedSearch && 
                        <Checkbox
                            className="search-by-mine" 
                            checked={filterParams.isSearchOnlyMine} 
                            onChange={handleChangeSearchByMe}>
                            mine only
                        </Checkbox>
                    }
                </FlexContainer>
                {showAdvancedSearch && 
                    <div className="advanced-search-container">
                        <TagSelector itemType="Project" onChange={handleChangeTags} />
                        <FlexContainer fullWidth justifyContent='flex-end'>
                            <RadioButtonGroup 
                                value={filterParams.startDays} 
                                onChange={value => {
                                    setFilterParams({
                                        ...filterParams,
                                        startDays: value
                                    });
                                }}                     
                                buttonStyle='outline'
                                size='small' 
                                options={revertedDays.map(day => ({ label: day.label, value: day.value }))} />
                            <p className="today-title">Today</p>
                            <RadioButtonGroup 
                                value={filterParams.endDays} 
                                onChange={value => {
                                    setFilterParams({
                                        ...filterParams,
                                        endDays: value
                                    });
                                }} 
                                buttonStyle='outline'
                                size='small' 
                                options={days.map(day => ({ label: day.label, value: day.value }))} />
                        </FlexContainer>
                    </div>
                }
            </SearchBoxContainer>
        </>
    );
};

export default SearchProject;