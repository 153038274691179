import React, { useContext } from "react";
import styled from 'styled-components';

import UserIcon from "Components/UserIcon/UserIcon";
import { ListContext } from "Contexts/ListContext";
import { showLog } from "Libs/NwMoment";

const EditedByText = styled.div`
  white-space: ${props => props.noWrap && 'nowrap'};
`;

const LastEditedBy = ({ object, extra = "last edited by", hideAvatar = true, breaklines = false, textonly = false, Style, noWrap, emptyText = "" }) => {
    const { cachedList, cachedListLoaded } = useContext(ListContext)

    if (object.DateModified && object.UserModified) {
        const { DateModified, UserModified } = object
        let user = null
        let userName = ""
        let userCoverGuid = ""
        let letters = "";

        if (cachedListLoaded) {
            user = cachedList.users.items.find(usr => usr.ID === UserModified)
            if (user) {
                userName = user.Name + " " + user.Surname[0]
                userCoverGuid = user.CoverGuid
                letters = user.Name.toUpperCase()[0] + user.Surname.toUpperCase()[0]
            } else {
                userName = "Utente F"
                letters = "UF"
            }
        }
        if (textonly) {
            return (<EditedByText noWrap={noWrap}>{extra} {userName} on {showLog(DateModified)}</EditedByText>)
        } else {
            return (
                <EditedByText className="last-edited-by" noWrap={noWrap}>
                    {hideAvatar ? (
                        <>
                            {extra} <strong>{userName}</strong>{breaklines && <br />} on {showLog(DateModified)}
                        </>
                    ) : (
                            <>
                                <UserIcon userId={UserModified} coverGuid={userCoverGuid} letters={letters} size="medium" />
                                {" "}
                                {extra} <strong>{userName}</strong> on {showLog(DateModified)}
                            </>
                        )}
                </EditedByText>
            );
        }
    } else {
        if (textonly) {
            return (<>{emptyText}</>)
        } else {
            return (
                <div className="last-edited-by">{emptyText}</div>
            )
        }
    }
};

export default LastEditedBy;
