import React, { useState } from 'react';
import { Dropdown, Menu, Tabs } from 'antd';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import { NwAddButton } from 'Components/Gui/NwButton';
import ProjectGroupedTransactionsList from 'Components/Project/ProjectTransactions/Transactions/ProjectGroupedTransactionsList';
import AddProjectTransactionDrawer from 'Components/Project/ProjectTransactions/Transactions/AddProjectTransactionDrawer';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledTabs } from './ProjectTransactionsUI';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import ProjectGroupedStatements from './ProjectGroupedStatements';
import { GetTimestamp } from 'Libs/NwMoment';

const { TabPane } = Tabs;

const ProjectTransactionsCard = ({ projectId, modelId, readOnly }) => {
    //const { data: currentProject } = useProject(projectId);
    const [transactionGroup, setTransactionGroup] = useState();
    const [transactionDrawer, setTransactionDrawer] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tab, setTab] = useState('summary');
    const [lastUpdate, setLastUpdate] = useState(GetTimestamp());

    const handleShowTransactionDrawer = ({ key }) => {
        setTransactionGroup(key);
        setTransactionDrawer(true);
    };

    const renderAddTransaction = () => {
        return [
            <Dropdown
                key='addTransaction'
                menu={{
                    onClick: handleShowTransactionDrawer,
                    items: [
                        { key: "Fee", label: "Fee" },
                        { key: "Expense", label: "Expense" },
                        { key: "Credit", label: "Credit" }
                    ]
                }}
            >
                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NwAddButton ghost size='small' label="add a transaction" />
                </div>
            </Dropdown>
        ];
    };

    const ExpandSwitch = ({ }) => (
        <CustomSwitch value={isExpanded} text="expand details" onChange={setIsExpanded} />
    )

    const handleCloseTransactionDrawer = () => {
        setTransactionDrawer(false)
        setTransactionGroup()
        setLastUpdate(GetTimestamp())
    }

    return (//currentProject ?
        <NwCard
            title='Transactions'
            icon={light('message-dollar')}
            extra={!readOnly && renderAddTransaction()}>
            <StyledTabs activeKey={tab} onChange={setTab} tabBarExtraContent={<ExpandSwitch />}>
                <TabPane tab='Summary' key='summary'>
                    <ProjectGroupedTransactionsList
                        projectId={projectId}
                        modelId={modelId}
                        readOnly={readOnly}
                        isExpanded={isExpanded}
                        lastUpdate={lastUpdate}
                    />
                </TabPane>
                <TabPane tab='Clients' key='clients'>
                    <ProjectGroupedStatements readOnly projectId={projectId} statementType='Client' isExpanded={isExpanded} />
                </TabPane>
                <TabPane tab='Models' key='models'>
                    <ProjectGroupedStatements readOnly projectId={projectId} statementType='Model' isExpanded={isExpanded} />
                </TabPane>
                <TabPane tab='Services' key='services'>
                    <ProjectGroupedStatements readOnly projectId={projectId} statementType='Service' isExpanded={isExpanded} />
                </TabPane>
                <TabPane tab='Agencies' key='agencies'>
                    <ProjectGroupedStatements readOnly projectId={projectId} statementType='Agency' isExpanded={isExpanded} />
                </TabPane>
            </StyledTabs>
            {transactionDrawer &&
                <AddProjectTransactionDrawer
                    transactionGroup={transactionGroup}
                    projectId={projectId}
                    onClose={handleCloseTransactionDrawer}
                />
            }
        </NwCard>
        //: <div />
    );
};

export default ProjectTransactionsCard;