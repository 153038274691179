import React from 'react'
import { Popover } from 'antd';
import styled from 'styled-components';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import _ from 'lodash';
import { getModelsListForBoxes } from './EventBoxesUtils';

const ModelsWrapper = styled.div`
    padding: .15rem .25rem;
    span {
        &::after {
            content: ', '; 
        }
        &:last-child::after {
            content: '';
        }
    }
    
    em {
        display: block;
        padding-top: .15rem;
    }
`

const ModelNameContainer = styled.span`
    ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
`;

const EventBoxesModels = ({ event, page }) => {

    const sortedmodels = getModelsListForBoxes(event, page)

    if (sortedmodels.length === 0) return null;
    return (
        <>
            {sortedmodels.length > 10 ?
                <Popover
                    title='Event Models'
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    content={
                        <ModelsWrapper>
                            {sortedmodels.map((model, index) => (
                                <ModelNameContainer $cancel={model.isCancelled} key={`${event.ID}-${model.id}-${index}`}>
                                    {model.name}
                                </ModelNameContainer>
                            ))}
                        </ModelsWrapper>
                    }>
                    <ModelsWrapper>
                        {page === 'project' && <em>Models: </em>}
                        {sortedmodels.slice(0, 10).map((model, index) => (
                            <ModelNameContainer key={model.id} $cancel={model.isCancelled}>{model.name}</ModelNameContainer>
                        ))}
                        {sortedmodels.length > 10 && <em>{`and ${sortedmodels.length - 10} more`}</em>}
                    </ModelsWrapper>
                </Popover>
                :
                <ModelsWrapper>
                    {page === 'project' && <strong>Models: </strong>}
                    {sortedmodels.map((model, index) => (
                        <ModelNameContainer key={model.id} $cancel={model.isCancelled}>{model.name}</ModelNameContainer>
                    ))}
                </ModelsWrapper>
            }
        </>
    )
}

export default EventBoxesModels