import React from 'react';
import styled from 'styled-components';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';

const ButtonBarContainer = styled.div`
    position: fixed;
    width: ${props => props.$width ? props.$width : '60vw'} ;
    min-width: ${props => props.$minWidth ? props.$minWidth : '980px'} ;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,.8);
    padding: 1rem 3rem 2rem 3rem;
    border-top: ${props => props.theme.DefaultBorder};
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
`


const FixedSaveAndCancelBar = ({ isSubmitting, isValid, onCancel, width, minWidth }) => {
    return (
        <ButtonBarContainer $width={width} $minWidth={minWidth}>
            <NwCancelButton
                disabled={isSubmitting}
                onClick={onCancel}
            />
            <NwSaveButton
                htmlType="submit"
                //disabled={isSubmitting || !isValid}
                disabled={!isValid}
                loading={isSubmitting}
            />
        </ButtonBarContainer>
    )
}

export default FixedSaveAndCancelBar;

