import { useState, useCallback, useLayoutEffect } from "react";

const getDimensionObject = (node) => {
    const rect = node.getBoundingClientRect()
    return {
        width: rect.width,
        height: rect.height,
    }
}

const useDimensions = () => {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node))
                );
            measure();
            window.addEventListener("resize", measure);
            return () => {
                window.removeEventListener("resize", measure);
            };
        }
    }, [node]);
    return [ref, dimensions, node];
}

export default useDimensions;