import React, { useEffect, useState } from "react";
import { Switch } from 'antd';
import Axios from 'axios';

import FlexContainer from 'Components/Gui/FlexContainer';
import EventDateTimePicker from 'Components/EventDateTimePicker/EventDateTimePicker';
import { useStateValue } from 'Contexts/StateProvider';
import MiniLoading from "Components/Gui/MiniLoading";

const ModelsEvents = ({ models, onUpdateModelEvents, modelEventsInfo, switchSize = 'medium' }) => {
    const [{areaContext}] = useStateValue();
    const [visible, setVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [checkDate, setCheckDate] = useState(null)

    useEffect(() => {
        if (checkDate) {
            const modelIds = models.map(model => model.ID);
            if (modelIds.length > 0) {
                let newValues = {
                    FDate: checkDate.startdate,
                    TDate: checkDate.enddate,
                    idmodels: modelIds,
                    inclJobs: true, 
                    inclCastings: true,
                    checkConflict: true,
                    inclTravels: true,
                    inclAccommodations: true,
                    inclExternalJobs: true,
                    inclFollowUps: true,
                    inclMeetings: true,
                    inclNotices: true,
                    General: false,
                    Warning: false
                };
                setIsFetching(true);
                Axios.post("/calendar/getevents", newValues).then(response => {
                    onUpdateModelEvents({
                        ...response,
                        period: {
                            startdate: checkDate.startdate,
                            enddate: checkDate.enddate,
                        },
                        enabled: visible
                    });
                    
                    setIsFetching(false);
                    //setVisible(false);
                })
                .catch(error => {
                    setIsFetching(false);
                    console.log("error", error);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkDate]);


    const onChangeDate = (date) => {
        if (date !== checkDate) {
            setCheckDate(date)
        }
    }

    const handleVisiblePopoverChange = visible => {
        setVisible(visible);
        if (!visible) {
            onUpdateModelEvents(null);
        }
    };

    return (
        <>  
            <FlexContainer>
                <Switch
                    checked={visible}
                    onChange={handleVisiblePopoverChange}
                    size={switchSize}
                />
                <FlexContainer>
                    <span className="label on-right"> get availability</span>&nbsp;
                    {isFetching && <MiniLoading />}
                </FlexContainer>
            </FlexContainer>
            <div>
                {visible && 
                    <FlexContainer mg='8px 0 0'>
                        <EventDateTimePicker
                            startFullDay
                            eventMode='singledate' 
                            layout='vertical'
                            onChange={onChangeDate} />
                    </FlexContainer>
                }
            </div>
        </>
    );
}

export default ModelsEvents;

