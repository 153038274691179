import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Select, Spin, Button } from 'antd';

import { getContactName } from 'Hooks/Contact/UseContactsList'
import StyleVariables from 'Components/Gui/StyleVariables';
import NwButtonOption from "Components/Gui/NwButtonOption";
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import BookerSelector from 'Components/BookerSelector/BookerSelector';
import InputTag from 'Components/Gui/NWForm/InputTag';
import FilterDateInput from "Components/Filters/FilterDateInput";

const ButtonGroup = Button.Group;
const { Option } = Select;

const Label = styled(Typography)`
    margin-right: 8px;
    min-width: 50px;
`;

const HeaderContainer = styled.div`
    margin: 1rem;
    padding: 1em;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    font-size: .9rem;
    @media (max-width: 480px) {
        border-radius: 0;
    }
    @media only screen and (max-width: 960px) {
        flex-direction: column;
    }
    .info-block {
        display: flex;
        flex-direction: row;
        border-right: ${StyleVariables.DefaultBorder};
        padding-right: 20px;
        @media only screen and (max-width: 960px) {
            border-right: none;
        }
        .back-icon {
            font-size: 1.6rem;
            margin: auto 15px auto 0;
            cursor: pointer;
            user-select: none;
        }
        .model-info {
            .model-name {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
    .filter-block {
        @media only screen and (max-width: 960px) {
            margin: 10px 0;
            padding: 10px 0;
            border-top: ${StyleVariables.DefaultBorder};
            border-bottom: ${StyleVariables.DefaultBorder};
        }
    }
    .action-block {
        border-left: ${StyleVariables.DefaultBorder};
        padding: 0 20px;
        margin-left: auto;
        @media only screen and (max-width: 960px) {
            border-left: none;
            margin-left: 0;
        }
        .upload-icon {
            font-size: 1.3rem;
            cursor: pointer;
            margin-right: 30px;
        }   
        .book-icon {
            font-size: 1.3rem;
            cursor: pointer;
        }            
    }
`;

const FilterContainer = styled.div`
    margin: 1rem;
    padding: 1em;
    background: #fff;
    border-radius: 6px;
    .filter-row {
        display: flex;
        flex-direction: row;
        .individual-filter {
            padding-right: 30px;
            width: 400px;
        }
    }
`;

const AddMediaHeader = ({
    modelId,
    documents,
    loading,
    size,
    onChangeSize,
    sort,
    onChangeSort,
    uploader,
    onChangeUploader,
    tags,
    onUpdateTags,
    uploadedSince,
    onChangeUploadedSince,
    uploadedUntil,
    onChangeUploadedUntil,
    showMedia,
    onUpdateShowMedia,
}) => {
    const photoCount = documents.filter(document => document.Type === 'Photo').length;
    const videoCount = documents.filter(document => document.Type === 'Video').length;
    const [showFilters, setShowFilters] = useState(false);

    const handleUpdateShowMedia = (type, value) => {
        if (type==='picture') {
            onUpdateShowMedia({
                ...showMedia,
                isShowPictures: value
            });
        } else if (type==='video') {
            onUpdateShowMedia({
                ...showMedia,
                isShowVideos: value
            });
        } else {
            onUpdateShowMedia({
                ...showMedia,
                isShowDocuments: value
            });
        }
    };

    return (
        <>
            <HeaderContainer>
                <div className="info-block">
                    <div className="model-info">
                        <div className="model-name">{getContactName(modelId)}</div>
                        {loading?
                            <Spin spinning={true}/>:
                            <div>{`${photoCount} PICTURES, ${videoCount} VIDEOS`}</div>
                        }
                    </div>
                </div>
                <div className="filter-block">
                    <FlexContainer mg="0 0 10px 16px">
                        <Label>Size</Label>
                        <ButtonGroup size="small" style={{ zIndex: "0" }}>
                            <NwButtonOption
                                active={size === "small"}
                                onClick={onChangeSize("small")}
                            >
                                S
                            </NwButtonOption>
                            <NwButtonOption
                                active={size === "medium"}
                                onClick={onChangeSize("medium")}
                            >
                                M
                            </NwButtonOption>
                            <NwButtonOption
                                active={size === "large"}
                                onClick={onChangeSize("large")}
                            >
                                L
                            </NwButtonOption>
                        </ButtonGroup>
                    </FlexContainer>
                    <FlexContainer>
                        <FlexContainer mg='0 16px'>
                            <Label>Sort By</Label>
                            <Select autoComplete='off' style={{ width: 160 }} value={sort} onChange={onChangeSort}>
                                <Option value="time">time</Option>
                                <Option value="myrating">my rating</Option>
                                <Option value="globalrating">global rating</Option>
                            </Select>
                        </FlexContainer>
                        <CustomSwitch checked={showFilters} onChange={() => setShowFilters(!showFilters)} text='Filter' />
                    </FlexContainer>
                </div>
            </HeaderContainer>
            {showFilters && 
                <FilterContainer>
                    <FlexContainer mg="0 0 20px 0">
                        <CustomSwitch
                            checked={showMedia.isShowPictures}
                            onChange={val => handleUpdateShowMedia('picture', val)}
                            text='Pictures'
                        />
                        <CustomSwitch
                            checked={showMedia.isShowVideos}
                            onChange={val => handleUpdateShowMedia('video', val)}
                            text='Videos'
                            style={{marginLeft: "30px"}}
                        />
                    </FlexContainer>
                    <div className="filter-row" style={{marginBottom: "20px"}}>
                        <div className="individual-filter">
                            <div>Uploaded By</div>
                            <BookerSelector value={uploader} style={{width: '100%'}} onChange={onChangeUploader} />
                        </div>
                        <div className="individual-filter">
                            <div>Tags</div>
                            <InputTag value={tags} contactType='Document' onChange={onUpdateTags} />
                        </div>
                    </div>
                    <div className="filter-row">
                        <div className="individual-filter">
                            <div>Uploaded Since</div>
                            <FilterDateInput allowClear value={uploadedSince} style={{width: '100%'}} onChange={onChangeUploadedSince} />
                        </div>
                        <div className="individual-filter">
                            <div>Uploaded Until</div>
                            <FilterDateInput allowClear value={uploadedUntil} style={{width: '100%'}} onChange={onChangeUploadedUntil}/>
                        </div>
                    </div>
                </FilterContainer>
            }
        </>
    );
}

export default AddMediaHeader;