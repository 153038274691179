import React, { useState, useEffect } from 'react';
import { Input, Row } from 'antd';
import styled from 'styled-components';

import { NwAddButton } from 'Components/Gui/NwButton';
const StyledInput = styled(Input)`
  margin-bottom: 4px;
`;

const MultiInput = ({ value, defaultValues, minCount, addible, onChange }) => {
  const [inputValues, setInputValues] = useState(new Array(minCount ? minCount : 5).fill(''));

  useEffect(() => {
    if (defaultValues) {
      let newValues = new Array(minCount ? minCount : 5).fill('');
      defaultValues.map((item, index) => {
        newValues[index] = item;
        return null;
      })
      setInputValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = index => event => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
    onChange(newInputValues);
  };

  const handleAddInput = () => {
    setInputValues([...inputValues, '']);
    onChange([...inputValues, '']);
  };

  return (
    <>
      {inputValues.map((inputValue, index) => {
        return <StyledInput 
          autoComplete='off'
          key={`multi-input-${index}`} 
          value={inputValue} 
          disabled={defaultValues.find(item => item === inputValue)}
          onChange={handleInputChange(index)} />;
      })}
      {addible && 
        <Row justify='end'>
          <NwAddButton label='Add More' onClick={handleAddInput} />
        </Row>
      }
    </>  
  )
};

export default MultiInput;