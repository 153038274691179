import React from "react";
import styled from "styled-components";
import { Table } from "antd";
import Money from "Components/Gui/Money";


const Wrapper = styled.div`
    .totals-table {
        background-color: ${props => props.theme.SelectedBackgroundColor};
        .ant-table {
            .ant-table-container {
                .ant-table-content {
                    .ant-table-tbody {
                        .ant-table-cell {
                            vertical-align: top;
                            padding: 1rem;
                            background-color: ${props => props.theme.SelectedBackgroundColor};

                            .total-amounts-left {
                                padding-left: 50px;
                            }

                            .invoice-row-taxes {
                                .invoice-row-tax {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-end;
                                    padding-bottom: .25rem;
                                    
                                    small {
                                        display: block;
                                        font-size: ${props => props.theme.FontSizeXSm};
                                        color: ${props => props.theme.TextColorMuted};
                                        line-height: .8em;
                                    }
                                    div {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        align-items: flex-end;
                                    }
                                }
                                .invoice-row-total {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-end;
                                    font-weight: bold;
                                    padding-bottom: .25rem;

                                    small {
                                        display: block;
                                        font-size: ${props => props.theme.FontSizeXSm};
                                        line-height: .8em;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;



const InvoicePageTotals = ({ invoiceData }) => {
    
    const invertAmount = invoiceData.TypeName !== "Client";

    const renderTotals = () => {
        const columns = [
            {
                dataIndex: 'Description',
                key: 'Description',
                render: (_, record) => (
                    <div className="total-amounts-left">
                        <p><strong>TOTAL AMOUNTS</strong></p>
                    </div>
                )
            },
            {
                width: 50,
                dataIndex: 'Group',
                key: 'Group',
                render: (_, record) => (
                    <p></p>
                )
            },
            {
                dataIndex: 'Taxes',
                key: 'Taxes',
                width: 160,
                render: (_, record) => (
                    <>
                        <div className="invoice-row-taxes">
                            <div className="invoice-row-tax">
                                <small>TOTAL AMOUNT</small>
                                <div><Money amount={record.TotalAmount} currency={record.Currency} invert={invertAmount} /></div>
                            </div>
                            {record.TotalTaxes !== 0 &&
                            <div className="invoice-row-tax">
                                <small>TOTAL TAXES</small>
                                <div><Money amount={record.TotalTaxes} invert={invertAmount} currency={invoiceData.Currency} /></div>
                            </div>
                            }
                            {record.TotalVat !== 0 &&
                            <div className="invoice-row-tax">
                                <small>TOTAL VAT</small>
                                <div><Money amount={record.TotalVat} invert={invertAmount} currency={invoiceData.Currency} /></div>
                            </div>
                            }
                        </div>
                    </>
                )
            },
            {
                dataIndex: 'actions',
                key: 'actions',
                width: 150,
                align: 'right',
                render: (_, record) => (
                    <div className="invoice-row-taxes">
                        <div className="invoice-row-total">
                            <small>TOTAL TO INVOICE</small>
                            <div><Money amounts={[record.TotalAmount, record.TotalTaxes, record.TotalVat]} currency={record.Currency} invert={invertAmount} /></div>
                        </div>
                    </div>
                ),
            }
        ];

        return (
                <Table
                    //bordered
                    showHeader={false}
                    columns={columns}
                    dataSource={[invoiceData]}
                    pagination={false}
                />
        );
    }

    return (
        <Wrapper>
            <div className="totals-table">
                {renderTotals()}
            </div>
        </Wrapper>
    )
}

export default InvoicePageTotals;