export const RATE_TYPES = [
    {
        Type: 'Hourly',
        TypeValue: 10
    },
    {
        Type: 'Daily',
        TypeValue: 20
    },
    {
        Type: 'Royalty',
        TypeValue: 30
    },
    {
        Type: 'Expense',
        TypeValue: 40
    }
];