export const BILLING_PROJECTS_FILTERS_SETS = {
    activeSet: "v1",
    customSets: [],
    staticSets: [
        {
            id: "v1",
            name: "billable - last 30 days",
            fixed: true,
            filters: [
                { name: "transactionStatuses", value: [20]},
                { name: "WorkingOn", value: "{last30days}"}
            ]
        }
    ]
}