import React, { useContext } from 'react';
import { ListContext } from "Contexts/ListContext";
import { Tooltip } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import TemplateSelectorPopover from './TemplateSelectorPopover';


const IconTemplateSelector = ({
    icon,
    onClick,
    placement,
    template,
    trigger,
    templateArea,
}) => {
    const { templates } = useContext(ListContext).cachedList;
    const filteredTemplatesByType = templates.items.filter(template => template.area === templateArea);
    
    const handleSelectTemplate = async (templateID) => {
        return await onClick(templateID)
    }

    const getIcon = () => {
        if (icon) {
            return icon;
        }
        return light('file-pdf');
    }

    if (filteredTemplatesByType.length === 0) {
        return (
            <Tooltip title="No available print templates">
                <NwIcon disabled icon={getIcon()} />
            </Tooltip>
        )
    }
    if (filteredTemplatesByType.length === 1) {
        return (
            <NwIcon pointer icon={getIcon()} onClick={() => handleSelectTemplate(filteredTemplatesByType[0].id)} />
        )
    }
    if (filteredTemplatesByType.length > 1) {
        return (
            <TemplateSelectorPopover
                onSelect={handleSelectTemplate}
                placement={placement}
                templates={filteredTemplatesByType}
                trigger={trigger}
            >
                <NwIcon pointer icon={getIcon()} />
            </TemplateSelectorPopover>
        )
    }
};

export default IconTemplateSelector;