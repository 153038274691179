import React, { useEffect, useMemo } from "react";
import styled from 'styled-components';
import { Popover } from 'antd';
import { getMoment } from "Libs/NwMoment";
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { showTime, showPeriod, showShortDate, showPeriodByDay } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import NwDivider from "Components/Gui/NwDivider";
import useEventStyle from 'Hooks/UseEventStyle';
import { getContactName } from "Hooks/Contact/UseContactsList";
import NwEllipsis from "Components/Gui/NwEllipsis";
import ProjectTitle from "Components/Project/ProjectTitle";
import AuthorAndDepartment from "Components/CreatedBy/AuthorAndDepartment";
import { useState } from "react";
import { debounce } from "lodash";
import EventCardDivider from "./EventCardDivider";
import CreatedBy from "Components/CreatedBy/CreatedBy";

// const { Paragraph } = Typography;

const EventContentMultiDayContainer = styled.div`
    display: flex;
`

//background: ${props => props.backgroundColor && props.backgroundColor};


const EventContentInner = styled.div`
    color: ${props => props.color && props.color};
    height: 100%;
    
    &.event-lapse {
        width: ${props => `calc(100% / ${props.multiDayDuration})`};
    }

    .event-content {
        padding: .2rem .5rem .25rem .5rem;
        min-height: ${props => !props.singleLine && !props.agendaView && '4rem'};

        .event-content-top {
            padding: 0 .25rem 0 0;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;

            div {
                font-size: .7rem;
                line-height: 1.2em;
                padding-bottom: 2px;
                font-family: 'Chivo Mono', monospace;

                span {
                    display: block;

                }
            }
        }

        .event-title {
            line-height: 1.15em;
            font-size: .9rem;
            font-weight: 600;
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;

            
            /* @media (min-height: 1000px) {
                font-size: 1rem;
            } */

            svg {
                margin-right: .5em;
                font-size: .85rem;
            }
        }

        .event-subtitle {
            padding-top: .25rem;
            padding-bottom: .25rem;
            font-size: .75rem;
            line-height: 1.15em;
            overflow: hidden;
            text-overflow: ellipsis;
            
            em {
                font-style: normal;
                text-transform: uppercase;
            }
        }

        .event-card-author {
            font-size: .68rem;
            opacity: .85;
            text-transform: lowercase;
            //font-style: italic;
            padding: .15rem 0 .25rem 0;
            strong {
                text-transform: uppercase;
            }
        }
    }

    .event-invisible {
        opacity: 0 !important;
    }

    .event-models {
        margin-top: 4px;
    }

    .cancel {
        ${props => props.theme.LineThrough(1)}
    }
`;

const EventToolTipContent = styled.div`
    min-width: 260px;
    max-width: 480px;
    
    .event-card-popover-body {
        padding: 1rem 1.5rem;

        .project-container {
            padding-bottom: .5rem;
            margin-bottom: .75rem;
            border-bottom: ${(props) => props.theme.DefaultBorder};
        }

        .ant-divider-horizontal.ant-divider-with-text {
            margin: 1rem 0 .5rem 0;
        }

        h3.event-type {
            font-size: ${props => props.theme.FontSizeSm};
            margin: 0;
            line-height: 1em;
            font-weight: 300;
            text-transform: uppercase;
        }

        h1.event-title {
            line-height: 1.15em;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: ${props => props.theme.FontSizeBase};
        }

        .description {
            line-height: 1.1em;
            margin: .25rem 0;
            font-size: ${props => props.theme.FontSizeSm};
            font-weight: 300;
        }
        
        p {
            margin: .15rem 0;
            &.time {
                font-size: ${props => props.theme.FontSizeSm};
                font-weight: 400;

                span {
                    display: block;
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding-left: 2rem;
                font-size: ${props => props.theme.FontSizeSm};
                &.cancel {
                    ${props => props.theme.LineThrough(1)}
                }
                &.confirmed {
                    font-weight: 600;
                }

                small {
                    margin-left: 1rem;
                    font-size: ${props => props.theme.FontSizeXSm};
                    text-transform: uppercase;
                }
            }
        }
    }

    .event-card-popover-footer {
        margin-top: .25rem;
        padding: .5rem 1.5rem .75rem 1.5rem;
        border-top: ${(props) => props.theme.DefaultBorder};
        
        p.event-author-and-department {

            svg {
                margin-right: .5rem;
            }

            font-size: ${props => props.theme.FontSizeSm};
            font-weight: 300;
            text-transform: uppercase;
            margin: 0;
        }
        small {
            text-transform: uppercase;
            display: block;
            font-size: ${props => props.theme.FontSizeXSm};
            padding-left: 1.3rem;
            line-height: 1.2em;
        }
    }
`

const showEventTime = (sd, ed, sb, eb) => {
    const st = getMoment(sd)
    const et = getMoment(ed)
    const sbt = sb ? getMoment(sb) : null
    const ebt = eb ? getMoment(eb) : null

    if (sbt && ebt) {
        return (
            <>
                <span>{showTime(st)} - {showTime(sbt)}</span>
                <span>{showTime(ebt)} - {showTime(et)}</span>
            </>
        )
    }
    if (st.hours() === 0 && st.minutes() === 0 && et.hours() === 23 && et.minutes() === 59) {
        if (et.isAfter(st, 'day')) {
            return (showPeriodByDay(st, et))
        }
        return ("ALL DAY")
    } else {
        if (et.isAfter(st, 'day')) {
            return (showPeriod(st, et))
            //return (`${showShortDate(st)} ${showTime(st)} - ${showShortDate(et)} ${showTime(et)}`)
        }
        if (st.hours() === et.hours() && st.minutes() === et.minutes()) {
            return `${showTime(sd)}`;
        } else {
            return `${showTime(sd)} - ${showTime(ed)}`
        }
    }
}

const EventCard = ({ event, model, modelNoTimeGrid, agendaView }) => {
    const {
        Acknowledgment,
        Address,
        allDay,
        Cancel,
        Description,
        eventType,
        FromDate,
        grouped,
        internalDates,
        ModelsDates,
        modelsNumber,
        multiDayDuration,
        OptionNumber,
        Project,
        title,
        ToDate,
        TypeName,
    } = event;

    const eventstyle = useEventStyle(event, event.TypeName)
    const isBookingEvent = TypeName === "Casting" || TypeName === "Job"

    //const ProjectID = Project ? Project.ID : null
    //const [hoverEffect, setHoverEffect] = useState(false)


    // const handleMouseEnter = () => {
    //     const toggleEvents = []
    //     const eventsCards = document.querySelectorAll('.rbc-event');

    //     //to verify, I don't understand why this is here
    //     // if (calendar === 'models') {
    //     //     const eventCard = document.querySelector('.event-' + id + type)
    //     //     eventCard.style.minHeight = eventCard.style.height
    //     // }

    //     if (ProjectID) {
    //         eventsCards.forEach(event => {
    //             if (
    //                 event.classList.value.search('type-empty') < 0 &&
    //                 event.classList.value.search(`project-${ProjectID}`) < 0
    //             ) {
    //                 toggleEvents.push(event)
    //             }
    //         })
    //     }
    //     toggleEvents.forEach(event => {
    //         event.classList.add('event-fade')
    //     })
    // }

    // const debouncedHandleMouseEnter = useMemo(
    //     () => debounce(handleMouseEnter, 800)
    // , []);



    // const handleMouseLeave = () => {
    //     const eventsCards = document.querySelectorAll('.rbc-event');
    //     eventsCards.forEach(event => {
    //         event.classList.remove('event-fade')
    //     })
    // }

    //add debounced function on mouse leave
    // const debouncedHandleMouseLeave = useMemo(
    //     () => debounce(handleMouseLeave, 800)
    // , []);

    // Stop the invocation of the debounced function
    // after unmounting
    // useEffect(() => {
    //     return () => {
    //         debouncedHandleMouseEnter.cancel();
    //         debouncedHandleMouseLeave.cancel();
    //     }
    // }, []);




    const handleMouseDown = () => {
        document.body.classList.add('dragging')
    }

    const handleMouseUp = () => {
        document.body.classList.remove('dragging')
    }

    const getClassName = (first, last) => {
        const classes = []
        classes.push("event")
        classes.push("event-with-tooltip")
        if (first) {
            classes.push("event-first-day")
        }
        if (last) {
            classes.push("event-last-day")
        }
        return classes.join(" ")
    }

    const getModelOptionNumber = () => {
        if (model) {
            if (TypeName === "Job") {
                if (OptionNumber && OptionNumber > 0 && OptionNumber < 10) {
                    return ` [${OptionNumber}]`
                }
            }
        }
        return ''
    }

    const getEventContent = (details) => {
        const modnum = details ? details.modelsNumber : modelsNumber
        const evtitle = details ? details.Name : (title ? title : eventstyle.name)
        const cancel = details ? details.Cancel : Cancel
        // let start = details ? details.FromDate : FromDate
        // let end = details ? details.ToDate : ToDate


        let timetoshow = ""

        const evdetails = details || event
        if (evdetails.ModelsDates && evdetails.ModelsDates.length) {
            if (evdetails.ModelsDates.length > 1) {
                timetoshow = evdetails.ModelsDates.length + " time frames"
            } else {
                timetoshow = showEventTime(evdetails.ModelsDates[0].FromDate, evdetails.ModelsDates[0].ToDate, evdetails.ModelsDates[0].StartBreak, evdetails.ModelsDates[0].EndBreak)
            }
        } else {
            timetoshow = showEventTime(evdetails.FromDate, evdetails.ToDate, evdetails.StartBreak, evdetails.EndBreak)
            //timetoshow = showEventTime(start, end)
        }

        return (
            <Popover
                content={renderEventToolTip(details)}
                trigger="hover"
                mouseEnterDelay={.25}
                placement="rightTop"
                overlayClassName="event-card-popover"
            >
                <div className='event-content'>
                    {/* <FlexContainer fullWidth column alignItems='flex-start'> */}
                    {(!agendaView || (agendaView && !allDay)) &&
                        <div className={`event-content-top ${(agendaView && cancel) ? 'cancel' : ''}`}>
                            {TypeName !== "Accommodation" && // exclude accommodation 
                                <div>{timetoshow}</div>
                            }
                            {!agendaView && TypeName !== "Accommodation" && TypeName !== "Notice" &&
                                (modnum > 0
                                    ?
                                    <div>{`${modnum} model${(modnum > 1) ? "s" : ""}`}</div>
                                    :
                                    <div>no models</div>
                                )
                            }
                        </div>
                    }
                    <div className={`event-title ${cancel ? 'cancel' : ''}`}>
                        {TypeName === 'Job' && event.Locked &&
                            <NwIcon icon={solid('dollar-sign')} />
                        }
                        {Acknowledgment === 'Checked' &&
                            <NwIcon icon={solid('check')} />
                        }
                        {eventstyle.icon && <NwIcon icon={eventstyle.icon} />}
                        {TypeName === "Accommodation" && <>{`${getContactName(event.ModelID)} @ `}</>}
                        {evtitle}{getModelOptionNumber()}
                    </div>
                    {Project &&
                        <div className={`event-subtitle ${cancel ? 'cancel' : ''}`}>
                            {Project.Name.toLowerCase() !== evtitle.toLowerCase()
                                ?
                                <><em>{Project.Name}</em>{Project.Product && ` / ${Project.Product}`}</>
                                :
                                (Project.Product &&
                                    <>{Project.Product}</>
                                )
                            }
                        </div>
                    }
                    {(model && isBookingEvent && Address && (Address.Street || Address.City || Address.Country)) &&
                        <>
                            <EventCardDivider color={eventstyle.color}>Address</EventCardDivider>
                            <p>{showAddress(Address)}</p>
                        </>
                    }
                    {(model && event) && (
                        <div className="event-card-author">
                            <AuthorAndDepartment object={event} />
                        </div>
                    )}
                </div>
            </Popover>
        )
    }

    const renderEventToolTip = details => {

        const start = details ? details.FromDate : FromDate
        const end = details ? details.ToDate : ToDate
        const evtitle = details ? details.Name : (title ? title : eventstyle.name)
        const modelDates = (TypeName !== "Casting" & TypeName !== "Job") ? null : (details ? details.ModelsDates : ModelsDates);

        const getModelsClassNames = (m) => {
            let myclasses = []
            if (m.Cancel) {
                myclasses.push("cancel")
            }
            if (m.OptionNumber === 10) {
                myclasses.push("confirmed")
            }
            if (myclasses.length) {
                return myclasses.join(" ")
            }
            return ""
        }

        const ordinalOf = (i) => {
            const j = i % 10
            const k = i % 100
            if (j === 1 && k !== 11) {
                return i + "st"
            }
            if (j === 2 && k !== 12) {
                return i + "nd"
            }
            if (j === 3 && k !== 13) {
                return i + "rd"
            }
            return i + "th"
        }


        const GetModelsTexts = (m) => (
            <>
                {getContactName(m.ModelID)}
                {(m.OptionNumber === 10) && (TypeName === "Job") &&
                    <small>confirmed</small>
                }
                {(m.OptionNumber > 0 && m.OptionNumber < 10) && (TypeName === "Job") &&
                    <small className="option-number">{ordinalOf(m.OptionNumber)} option</small>
                }
            </>
        )

        return (
            <EventToolTipContent>
                <div className="event-card-popover-body">
                    <h3 className="event-type">{eventstyle.name}</h3>
                    <h1 className="event-title">{evtitle}</h1>
                    {Description &&
                        <NwEllipsis text={Description} className="description" rows={3} />
                    }
                    {Project && <ProjectTitle project={Project} popover />}
                    {model ?
                        <></>
                        :
                        <>
                            <NwDivider>Dates &amp; Models</NwDivider>
                            {(TypeName === "Casting" || TypeName === "Job") &&
                                <>
                                    {modelDates && modelDates.map((md, index) => (
                                        <div key={index}>
                                            <p className="time">{showEventTime(md.FromDate, md.ToDate, md.StartBreak, md.EndBreak)}</p>
                                            <ul>
                                                {md.Models.map((m, index) => (
                                                    <li className={getModelsClassNames(m)} key={index}>
                                                        {GetModelsTexts(m)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </>
                            }
                            {TypeName !== "Casting" && TypeName !== "Job" && TypeName !== "Accommodation" &&
                                <div>
                                    <p className="time">{showEventTime(start, end)}</p>
                                    {grouped
                                        ?
                                        <ul>
                                            {grouped.map((m, ind) => (
                                                <li key={ind}>
                                                    {getContactName(m.ModelID)}
                                                </li>
                                            ))}
                                        </ul>
                                        :
                                        <ul>
                                            <li>
                                                {getContactName(event.ModelID)}
                                            </li>
                                        </ul>
                                    }
                                </div>
                            }
                            {TypeName === "Accommodation" &&
                                <div>
                                    <p className="time">{showPeriod(event.FromDate, event.ToDate)}</p>
                                    <p>{getContactName(event.ModelID)}</p>
                                </div>
                            }
                        </>
                    }
                    {(Address && (Address.Street || Address.City || Address.Country)) && (
                        <>
                            <NwDivider>Address</NwDivider>
                            <p>{showAddress(Address)}</p>
                        </>
                    )}
                </div>
                <div className="event-card-popover-footer">
                    {event.Project
                        ?
                        <>
                            <p className="event-author-and-department">
                                <NwIcon icon={light("circle-user")} /><AuthorAndDepartment object={event.Project} />
                            </p>
                            {event.Project.UserCreated !== event.UserCreated &&
                            <small>
                                <CreatedBy breaklines textonly noDiv object={event} extra="Event created" />
                            </small>
                            }
                        </>
                        :
                        <p className="event-author-and-department">
                            <NwIcon icon={light("circle-user")} /><AuthorAndDepartment object={event} />
                        </p>
                    }
                </div>
            </EventToolTipContent>
        )
    };

    const showAddress = (Address) => {
        const { Street, City, Country } = Address;
        if (Street) {
            if (City && Country)
                return `${Street} - ${City}, ${Country}`;
            return `${Street} - ${City}${Country}`;

        } else {
            if (City && Country)
                return `${City}, ${Country}`;
            return `${City}${Country}`;
        }
    }

    const isSingleLine = () => (TypeName === "Accommodation")

    let colorformultidayjob = null

    const MultiDayEventContentInner = ({ date, index }) => {
        const dateevent = { ...date.details }
        dateevent.TypeName = event.TypeName
        dateevent.SubTypeCode = event.SubTypeCode
        const datestyle = useEventStyle(dateevent, event.TypeName)
        const getStyleForMultiDay = (empty) => {
            const colors = {}
            colors.color = datestyle && datestyle.color
            if (empty) {
                if (event.TypeName === "Job") {
                    colors.style = {
                        width: `${100 / multiDayDuration}%`,
                        background: `repeating-linear-gradient(
                            -55deg,
                            ${colorformultidayjob},
                            ${colorformultidayjob} 1px,
                            #fff 2px,
                            #fff 5px
                        )`
                    }
                } else {
                    colors.style = {
                        width: `${100 / multiDayDuration}%`,
                        background: `repeating-linear-gradient(
                            -55deg,
                            ${datestyle.backgroundColor},
                            ${datestyle.backgroundColor} 1px,
                            #fff 2px,
                            #fff 5px
                        )`
                    }
                }
            } else {
                colors.style = { width: `${100 / multiDayDuration}%`, background: datestyle.backgroundColor }
                if (event.TypeName === "Job" && date.first) {
                    colorformultidayjob = datestyle.backgroundColor
                }
            }
            return colors
        }

        if (date.empty) {
            return (
                <EventContentInner
                    key={index}
                    singleLine={isSingleLine()}
                    agendaView={agendaView}
                    {...getStyleForMultiDay(true)}
                    className="event event-lapse"
                    multiDayDuration={multiDayDuration}
                >
                    <div className="event-content">&nbsp;</div>
                </EventContentInner>
            )
        } else {
            return (
                <EventContentInner
                    key={index}
                    singleLine={isSingleLine()}
                    agendaView={agendaView}
                    {...getStyleForMultiDay(false)}
                    className={getClassName(date.first, date.last)}
                    // onMouseEnter={hoverEffect ? debouncedHandleMouseEnter : null}
                    // onMouseLeave={hoverEffect ? debouncedHandleMouseLeave : null}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {getEventContent(date.details)}
                </EventContentInner>
            )
        }
    }

    switch (eventType) {
        case 'empty':
            return (
                <EventContentInner
                    singleLine={isSingleLine()}
                    className="event event-invisible">
                    <div className="event-title"></div>
                </EventContentInner>
            )
        case 'no-job':
            return (
                <EventContentInner
                    singleLine={isSingleLine()}
                    className="event">
                    <div className="event-title"></div>
                </EventContentInner>
            )
        case 'multi-day':
            return (
                <EventContentMultiDayContainer className="multi-day-container">
                    {internalDates.map((date, index) => (
                        <MultiDayEventContentInner
                            date={date}
                            index={index}
                            key={index}
                        />
                    ))}
                </EventContentMultiDayContainer>
            )
        case 'long-day':
            return (
                <EventContentInner
                    singleLine={isSingleLine()}
                    agendaView={agendaView}
                    color={eventstyle && eventstyle.color}
                    //backgroundColor={eventstyle && eventstyle.backgroundColor}
                    style={{ background: eventstyle.backgroundColor }}
                    className={getClassName(true, true)}
                    // onMouseEnter={hoverEffect ? debouncedHandleMouseEnter : null}
                    // onMouseLeave={hoverEffect ? debouncedHandleMouseLeave : null}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {getEventContent()}
                </EventContentInner>
            )
        case 'single-day':
            return (
                <EventContentInner
                    singleLine={isSingleLine()}
                    agendaView={agendaView}
                    color={eventstyle && eventstyle.color}
                    //backgroundColor={eventstyle && eventstyle.backgroundColor}
                    className={getClassName(true, true)}
                    // onMouseEnter={hoverEffect ? debouncedHandleMouseEnter : null}
                    // onMouseLeave={hoverEffect ? debouncedHandleMouseLeave : null}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    style={{ background: eventstyle.backgroundColor }}
                >
                    {getEventContent()}
                </EventContentInner>
            )
        default:
            return (
                <EventContentInner
                    singleLine={isSingleLine()}
                    className="event"
                    agendaView={agendaView}
                >
                    {getEventContent()}
                </EventContentInner>
            )
    }
}

export default EventCard
