import React from 'react';
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';

import { validateVisibility } from 'Libs/NwUtils';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWVisibilitySelector, NWTextAreaCharCounter, NWPrioritySelector } from 'Components/Gui/NWForm/NwFormItems';
// import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useStateValue } from 'Contexts/StateProvider';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';

const CommentForm = ({ comments, api, ID, disableVisibility, noPadding, onSave, selectedItem, contactId, onTouchForm }) => {
  // const breakpoints = useNwBreakPoints();
  const [{ areaContext }] = useStateValue();
  let initialValues = {
    Text: "",
    Priority: 0,
    Visibility: {
      isGlobal: true,
      Areas: []
    }
  };

  if (selectedItem) {
    const comment = comments.find(item => item.ID === selectedItem);
    if (comment) {
      initialValues.Text = comment.Text;
      initialValues.Priority = comment.Priority;
      initialValues.Visibility = {
        isGlobal: comment.Visibility.isGlobal,
        Areas: comment.Visibility.Areas
      }
    }
  }


  const addComment = (values, setStatus, setSubmitting) => {
    const { Text, Priority, Visibility } = values;
    setSubmitting(true);
    const comment = comments.find(el => el.ID === selectedItem);
    if (comment) {
      const commentData = {
        ...comment,
        Type: 0,
        Text: Text,
        Priority: Priority,
        PrivacyLevel: "Public",
        VisibilityAreas: Visibility.Areas,
        [api]: ID,
        ContactID: contactId
      }
      delete commentData.Visibility;
      Axios.put(`/comments`, {
        ...commentData,
        ID: selectedItem
      })
        .then((response) => {
            if (onTouchForm) {
                onTouchForm(false);
            }
          onSave(true, response);
        })
        .catch(error => {
          console.log("error", error);
        });
    } else {
      const commentData = {
        Type: 0,
        Text: Text,
        Priority: Priority,
        PrivacyLevel: "Public",
        VisibilityAreas: disableVisibility ? [] : Visibility.Areas,
        [api]: ID,
        ContactID: contactId
      }
      Axios.post(`/comments`, commentData)
        .then((response) => {
            if (onTouchForm) {
                onTouchForm(false);
            }
          onSave(true, response);
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        Text: Yup.string().required("Content is required")
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus();
        addComment(values, setStatus, setSubmitting);
      }}
      render={({
        errors,
        status,
        touched,
        isSubmitting,
        values,
        setFieldValue
      }) => (
        <NwForm 
          values={values}
          onTouchForm={onTouchForm}
          padded={!noPadding} 
          small>
          <Row>
            {!disableVisibility &&
              <Col flex="auto">
                <Field
                  label="Visibility"
                  inlineLabel
                  component={NWVisibilitySelector}
                  name="Visibility"
                  value={values.Visibility}
                  validate={validateVisibility(values.Visibility, areaContext.area)}
                />
              </Col>
            }
            <Col flex="160px">
              <Field
                label="Priority"
                inlineLabel
                component={NWPrioritySelector}
                name="Priority"
                value={values.Priority}
              />
            </Col>
          </Row>
          <Field
            component={NWTextAreaCharCounter}
            label="Comment"
            name="Text"
            type="text"
            required
            autoFocus
            value={values.Text}
            maxLength={2000}
            style={{ width: "100%" }}
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
            <NwFormButtonsBar
              left={
                <NwCancelButton
                  disabled={isSubmitting}
                  onClick={() => {
                      if (onTouchForm) {
                        onTouchForm(false);
                      }
                      onSave(false)
                    }}
                />
              }
              right={
                <NwSaveButton
                  htmlType="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              }
            />
          </NwForm>
        )}
    />
  );
};

export default CommentForm;