import React, { useEffect, useState } from "react";
import Axios from 'axios';

import ConnectionCard from "Components/Connection/ConnectionCard";
import NwDrawer from "Components/Gui/NwDrawer";
import { Loading } from 'Components/Gui/Loading';

const ConnectionDrawer = ({ connectionId, onClose }) => {
    const [connection, setConnection] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getConnection = async () => {
            setLoading(true);
            const connection = await Axios.get(`/connections/${connectionId}`);
            setConnection(connection);
            setLoading(false);
        }

        getConnection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NwDrawer
            onClose={onClose}
            title="Connection">
            {loading ? <Loading />
                : connection && <ConnectionCard readOnly item={connection} />}
        </NwDrawer>
    );
};

export default ConnectionDrawer;
