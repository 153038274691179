import React, { useState } from "react";
import { List, Typography } from "antd";
import styled from 'styled-components';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDrawer from 'Components/Gui/NwDrawer';
import ModelInTownScheduleAddEdit from 'Components/ModelInTown/ModelInTownScheduleAddEdit';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { showDateNoTime } from "Libs/NwMoment";
import { forceReloadContact } from "Hooks/Contact/UseContact";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const ListItem = styled(List.Item)`
    border-bottom: 0.5px solid ${props => props.theme.LighterBorderColor};
`;

const DateContainer = styled(FlexContainer)`
    font-weight: ${props => props.fontWeight};
    font-size: 1rem;
`;

const ModelInTownScheduleItems = ({ date, onChange, readOnly, checkOverlapping }) => {
    const [isEditing, setEditing] = useState(false);
    const [touchForm, setTouchForm] = useState(false); 
    const breakpoints = useNwBreakPoints();

    const deleteInTownSchedules = (ID) => {
        Axios.delete(`/inTownSchedules/${ID}`)
        .then(() => {
            forceReloadContact(date.ModelID)
            onChange();
        })
        .catch(error => {
            console.log("Errore", error);
        });
    };

    const actions = () => {
        return !isEditing ? [
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => {setEditing(true)}},
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => deleteInTownSchedules(date.ID)}
                ]}
            />
        ] : [];
    };

    return (
        <ListItem actions={!readOnly && actions()} className="ant-list-item-top">
            <FlexContainer column={breakpoints === 1} alignItems='flex-start' style={{width: "100%"}}>
                <DateContainer fontWeight={date.IsInTown?"bold":"normal"} style={{flex: "1 0 0"}}>
                    {date.IsInTown?"IN TOWN":date.Location}
                </DateContainer>
                <div style={{flex: "1 0 0"}}>
                    <DateContainer fontWeight={date.IsInTown?"bold":"normal"}>
                        <NwIcon icon={light('plane-arrival')} />
                        <Typography style={{marginLeft: "0.5rem"}}>{showDateNoTime(date.StartDate)}</Typography> 
                    </DateContainer>
                    <DateContainer fontWeight={date.IsInTown?"bold":"normal"}>
                        <NwIcon icon={light('plane-departure')} />
                        <Typography style={{marginLeft: "0.5rem"}}>{showDateNoTime(date.EndDate)}</Typography>
                    </DateContainer>
                </div>
            </FlexContainer>
            {isEditing &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Location'
                    onClose={() => setEditing(false)}>
                    <ModelInTownScheduleAddEdit
                        schedule={date}
                        checkOverlapping={checkOverlapping}
                        onCancel={() => setEditing(false)}
                        onChange={() => {
                            setEditing(false);
                            onChange();
                        }}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            }
        </ListItem>
    );
};

export default ModelInTownScheduleItems;
