import React from 'react';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwEllipsis from "Components/Gui/NwEllipsis";
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import useEventStyle from 'Hooks/UseEventStyle';
import { showDateNoTime, showDate, isAllDay } from 'Libs/NwMoment';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";

const ExtJobDetails = ({ extJobData }) => {
    const externalJobStyle = useEventStyle({}, "ExtJob");

    return (
        <EventDetailsWrapper className={extJobData.Cancel ? 'is-cancelled' : null}>
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='col'>
                                <div className='is-icon'>
                                    <NwIcon icon={externalJobStyle.icon} />
                                </div>
                            </div>
                        ),
                        width: "30%",
                        isBorder: true,
                    },
                    {
                        content: (
                            <div className='col'>
                                {showDateNoTime(extJobData.StartDate) !== showDateNoTime(extJobData.EndDate) ?
                                    <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                        <FlexContainer column justifyContent='center' alignItems='center'>
                                            <div className='is-content big is-strong'>
                                                {isAllDay(extJobData.StartDate, extJobData.EndDate) ?
                                                    showDateNoTime(extJobData.StartDate) : showDate(extJobData.StartDate)}
                                            </div>
                                            <NwIcon icon={light('arrow-down')} />
                                        </FlexContainer>
                                        <div className='is-content big is-strong'>
                                            {isAllDay(extJobData.StartDate, extJobData.EndDate) ?
                                                showDateNoTime(extJobData.EndDate) : showDate(extJobData.EndDate)}
                                        </div>
                                    </FlexContainer>
                                    :
                                    <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                        <div className='is-content big is-strong'>{showDateNoTime(extJobData.StartDate)}</div>
                                        {isAllDay(extJobData.StartDate, extJobData.EndDate) ?
                                            <div className='is-content big is-strong'>All Day</div>
                                            :
                                            <>
                                                <FlexContainer column justifyContent='center' alignItems='center'>
                                                    <div className='is-content big is-strong '>{getMoment(extJobData.StartDate).format('HH:mm')}</div>
                                                    <NwIcon icon={light('arrow-down')} />
                                                </FlexContainer>
                                                <div className='is-content big is-strong'>{getMoment(extJobData.EndDate).format('HH:mm')}</div>
                                            </>
                                        }
                                    </FlexContainer>
                                }
                                {extJobData.Location &&
                                    <FlexContainer column alignItems='flex-start' mg='8px 0 0'>
                                        <div className='label'>Location</div>
                                        <div className='is-content'>{extJobData.Location}</div>
                                    </FlexContainer>
                                }
                            </div>
                        ),
                        isBorder: false,
                    }
                ]}
            />
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='col'>
                                <div className='is-label'>Client</div>
                            </div>
                        ),
                        width: "30%",
                        isBorder: true,
                    },
                    {
                        content: (
                            <div className='col'>
                                {extJobData.ClientName &&
                                    <div className='is-content big is-strong'>{extJobData.ClientName}</div>
                                }
                            </div>
                        ),
                        isBorder: false,
                    },
                ]}
            />
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='col'>
                                <div className='is-label'>Product</div>
                            </div>
                        ),
                        width: "30%",
                        isBorder: true,
                    },
                    {
                        content: (
                            <div className='col'>
                                {extJobData.ProductName &&
                                    <div className='is-content'>{extJobData.ProductName}</div>
                                }
                            </div>
                        ),
                        isBorder: false,
                    },
                ]}
            />
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='col'>
                                <div className='is-label'>Timesheet</div>
                            </div>
                        ),
                        width: "30%",
                        isBorder: true,
                    },
                    {
                        content: (
                            <div className='col'>
                                {extJobData.TimeSheet &&
                                    <div className='is-content'>{extJobData.TimeSheet}</div>
                                }
                            </div>
                        ),
                        isBorder: false,
                    },
                ]}
            />
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='col'>
                                <div className='is-label'>Description</div>
                            </div>
                        ),
                        width: "30%",
                        isBorder: true,
                    },
                    {
                        content: (
                            <div className='col'>
                                {extJobData.Description &&
                                    <div className='is-content'>
                                        <NwEllipsis
                                            rows={3}
                                            text={extJobData.Description} />
                                    </div>
                                }
                            </div>
                        ),
                        isBorder: false,
                    },
                ]}
            />
        </EventDetailsWrapper>
    );
};

export default ExtJobDetails;