import styled from "styled-components";

export const MobileDatePickerWrapper = styled.div`
    position: fixed;
    background-color: white;
    border-bottom: ${(props) => props.theme.DefaultBorder};
    width: calc(100% - 2.1rem);
    z-index: 1020;

    .datepicker-date-navigation {
        padding: 1.5rem .5rem 0 .5rem;
        height: auto;
        display: flex;
        justify-content: flex-end;
        .ant-btn-group {
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-select-selection {
                border-radius: 0;
            }
        }
    }
    .ant-picker-calendar {
        padding: 4px 0 4px 5rem;
        z-index: 1000;

        .ant-picker-panel {
            padding-top: 2px;
            margin-top: 8px;

            .ant-picker-date-panel {
                .ant-picker-body {
                    padding: 2px 0 6px 0;
                    table.ant-picker-content {
                        height: 200px;
                        tbody {
                            tr {
                                border-bottom: ${(props) => props.theme.DefaultBorder};
                                &:last-child {
                                    border-bottom: none;
                                }

                                td.ant-picker-cell {
                                    vertical-align: top;
                                    padding: 3px 0;
                                    position: relative;
                                    line-height: 1.1em;
                                    
                                    &:before {
                                        display: none;
                                    }

                                    .ant-picker-cell-inner {
                                        border-radius: 50%;
                                        padding-top: 2px;
                                    }

                                    &.ant-picker-cell-in-view {
                                        &.ant-picker-cell-today {
                                            .ant-picker-cell-inner {
                                                border: 1px solid ${(props) => props.theme.PrimaryColor};
                                                &::before {
                                                    border: none;
                                                }
                                            }
                                        }
                                    }

                                    .ant-picker-calendar-date {
                                        line-height: 1.3em;
                                        .ant-picker-calendar-date-content {
                                            display: none;
                                        }

                                        /* &.ant-picker-calendar-date-today {
                                            .ant-picker-calendar-date-value {
                                                box-shadow: none;
                                                background-color: transparent;
                                            }
                                        } */
                                    }

                                    &.ant-picker-cell-selected {
                                        .ant-picker-calendar-date-value {
                                            font-weight: bold;
                                            box-shadow: none;
                                            color: #fff !important;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
                        }
                        thead {
                            tr {
                                border-bottom: 1px solid ${(props) => props.theme.PrimaryColor};
                                th {
                                    padding: 4px 0;
                                    color: ${(props) => props.theme.PrimaryColor} !important;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //when it's in the model details drawer on mobile
    &.mobile-drawer {
        position: relative;
        background-color: transparent;
        border-bottom: ${(props) => props.theme.DefaultBorder};
        width: calc(100% - 2.1rem);
        z-index: auto;

        .datepicker-date-navigation {
            padding: 1.5rem .5rem 0 2em;
        }

        .ant-picker-calendar {
            padding: 4px 0 4px 2rem;
        }
    }
`;