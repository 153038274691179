import React, { useState, useContext } from 'react';
import { List } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ListContext } from 'Contexts/ListContext';
import EmptyState from 'Components/Gui/EmptyState';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwDrawer from 'Components/Gui/NwDrawer';
import ContactUserForm from 'Components/ContactUsersDrawer/ContactUserForm';
import { useDeleteContactUser } from "Hooks/Contact/UseContactUsers";

const ContactUsers = ({ users, readOnly, contactID, isModel }) => {
    const { GetUserName, cachedList } = useContext(ListContext);
    const { mutate: deleteUser } = useDeleteContactUser();
    const [selectedContactUser, setSelectedContactUser] = useState();
    const [touchForm, setTouchForm] = useState(false);

    const handleEditContactUser = user => {
        setSelectedContactUser(user);
    };

    const handleCloseUserForm = () => {
        setSelectedContactUser();
    }

    const handleDeleteUser = user => {
        deleteUser({ID: user.ID, contactID: contactID})
    };

    const GetAgencyDepartment = (NetworkAgencyID, DepartmentID) => {
        if (!NetworkAgencyID) {
            return null;
        }
        let agencies = [];
        cachedList.accountingGroups.items.map(data => {
            agencies = [...agencies, ...data.NetworkAgencies]
            return null;
        });
        const networkAgency = agencies.find(agency => agency.ID === NetworkAgencyID);
        if (!networkAgency) {
            return null;
        }
        const department = networkAgency.Departments.find(department => department.ID === DepartmentID);
        if (!department) {
            return {
                NetworkAgency: networkAgency.Name
            };
        } else  {
            return {
                NetworkAgency: networkAgency.Name,
                Department: department.Name
            }
        }
    };

    const renderActions = user => {
        return ([
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => handleEditContactUser(user)},
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => handleDeleteUser(user)}
                ]}
            />
        ]);
    };
    
    return (
        <>
            {users.length > 0 ? 
                <List
                    size='small'
                    itemLayout="horizontal"
                    dataSource={users}
                    renderItem={user => {
                        const agencyDepartment = GetAgencyDepartment(user.NetworkAgencyID, user.DepartmentID);
                        return (
                            <List.Item actions={!readOnly && renderActions(user)}>
                                <List.Item.Meta
                                    title={
                                        <div>
                                            <div>{GetUserName(user.UserID)}</div>
                                            {agencyDepartment ? 
                                                <div>{agencyDepartment.NetworkAgency} / {agencyDepartment.Department ? agencyDepartment.Department : 'All Department'}</div>
                                                : 'All Department'
                                            }
                                        </div>
                                    }
                                    description={user.Note} />
                            </List.Item>
                        )}
                    }
                />
                : <EmptyState message='no users' />}
            {selectedContactUser && 
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                     title='Edit Contact User'
                    onClose={handleCloseUserForm}>
                    <ContactUserForm 
                        contactUser={selectedContactUser}
                        contactID={contactID}
                        onCancel={handleCloseUserForm} 
                        onUpdated={handleCloseUserForm}
                        onTouchForm={setTouchForm} 
                        isModel
                    />
                </NwDrawer>
            }
        </>
    )
};

export default ContactUsers;