import React, { useState, useMemo, useContext, useRef } from "react";
// import { useHistory } from 'react-router-dom';
// import Axios from 'axios';
import { Row, Col, Pagination, Input, Spin, Badge } from 'antd';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AvatarBg from 'Components/Gui/AvatarBg';
import NwIcon from 'Components/Gui/NwIcon';
import { getBookCover, sliceData } from 'Libs/NwUtils';
import FlexContainer from 'Components/Gui/FlexContainer';
import AddBookToPackageButton from 'Components/ActivePackages/AddBookToPackageButton';
import { NwButton } from 'Components/Gui/NwButton';
import EmptyState from 'Components/Gui/EmptyState';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { ListContext } from 'Contexts/ListContext';
import { UserContext } from 'Contexts/UserContext';
import { showLogNoTime } from 'Libs/NwMoment';
import { useActivePackages, usePackage, useAddPlaylistToPackage, useDeletePlaylistFromActivePackage, forceReloadActivePackages } from 'Hooks/Packages/UsePackages';
import { useWindowSize } from "react-use";
import PlayListPreviewDrawer from "Components/MediaDrawer/PlayListPreviewDrawer";

const BookDetail = styled(FlexContainer)`
    width: calc(100% - 83px);
    height: 100%;

    .playlist-info {
        width: 100%;
    }
    .playlist-name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .playlist-author {
        font-size: .8rem;
        color: ${props => props.theme.PrimaryDarkLight};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const BooksContainer = styled(FlexContainer)`
    min-height: ${props => !props.$projectModelBooks && '250px'};
    width: 100%;
    position: relative;
    .placeholder {
        min-height: 250px;
        width: 100%;
    }
    .book-detail {
        height: 100%;
    }
    .ant-input-search {
        width: 200px;
    }
    .ant-row {
        padding: ${props => props.$pagination ? '0 32px' : 0};
        width: 100%;
        margin: 0 !important;
    }
    .ant-col {
        margin-bottom: 8px;
    }
    .added {
        position: absolute;
        left: 20px;
        top: 4px;
    }
    .ant-pagination-item {
        svg {
            color: ${props => props.theme.PrimaryDarkLight};
        }
    }
    .ant-pagination-item-active {
        border: none;
        background: transparent;
        svg {
            color: ${props => props.theme.PrimaryColor};
            margin-top: 4px;
        }
    }
    .ant-pagination-prev {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: -12px;
        margin-top: 8px;
        svg {
            font-size: 1.6rem;
        }
    }
    .ant-pagination-next {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: -12px;
        svg {
            font-size: 1.6rem;
        }
    }
    .nw-empty-state {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .ant-input-group-wrapper {
        max-width: 240px;
    }
    .ant-spin-nested-loading {
        width: 100%;
    }
`;

const StyledBadge = styled.div`
    .ant-badge-count {
        background-color: ${props => props.theme.PrimaryColor};
        right: unset;
        left: 0;
        transform: translate(-50%, -50%);
    }
`;

const ModelBooksToPackage = ({ modelID, packageId, playlists = [], activePackageOnly, plainMode, hideFilters, projectModelBooks }) => {
    const { data: packages } = useActivePackages();
    const [activePage, setActivePage] = useState(1);
    const [bookPreview, setBookPreview] = useState(null);
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [filterAuthorType, setFilterAuthorType] = useState('all-books');
    const [searchTerm, setSearchTerm] = useState('');
    // const history = useHistory();
    const { cachedList } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    const { width } = useWindowSize();
    const booksRef = useRef();
    const { data: currentPack } = usePackage(packageId);
    const { mutateAsync: onAddPlaylistToPackage } = useAddPlaylistToPackage();
    const { mutateAsync: onDeletePlaylistFromActivePackage } = useDeletePlaylistFromActivePackage();

    const bookContainerWidth = useMemo(() => {
        if (booksRef.current) {
            return booksRef.current.clientWidth;
        }
        return 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, booksRef.current]);

    const itemsPerPage = useMemo(() => {
        if (!plainMode) {
            return 6;
        } else if (bookContainerWidth < 576) {
            return 2;
        } else if (bookContainerWidth < 768) {
            return 4;
        } else if (bookContainerWidth < 992) {
            return 6;
        } else {
            return 8;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookContainerWidth]);

    const booksSpan = useMemo(() => {
        if (!plainMode) {
            return 8;
        } else if (bookContainerWidth < 576) {
            return 24;
        } else if (bookContainerWidth < 768) {
            return 12;
        } else if (bookContainerWidth < 992) {
            return 8;
        } else {
            return 6;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookContainerWidth]);

    const getIsAddedBook = bookId => {
        const pack = activePackageOnly ? packages.find(pack => pack.ID === packageId) : currentPack;
        if (pack && pack.Models) {
            const model = pack.Models.find(model => model.ModelID === modelID);
            return model && model.Playlists.find(playlist => playlist.PlaylistID === bookId);
        }
        return null;
    };

    const getCreateBy = playlist => {
        const user = cachedList.users.items.find(usr => usr.ID === playlist.UserCreated);
        return `${user.Name} ${user.Surname}, ${showLogNoTime(playlist.DateCreated)}`;
    };

    const handleChangePagination = (page, pageSize) => {
        setActivePage(page);
    };

    const showBookPreview = (book) => {
        setBookPreview(book)
        setIsBookDrawer(true);
    };

    const filteredPlaylists = useMemo(() => {
        let filteredPlaylists = [...playlists];
        if (filterAuthorType === 'mine-only') {
            filteredPlaylists = filteredPlaylists.filter(playlist => playlist.UserCreated === currentUser.UserID);
        }
        if (searchTerm) {
            filteredPlaylists = filteredPlaylists.filter(playlist => playlist.Name.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        return filteredPlaylists;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlists, searchTerm, filterAuthorType]);

    const playlistsToRender = sliceData(activePage, itemsPerPage, filteredPlaylists);

    const handleChangeSearchTerm = event => {
        setSearchTerm(event.target.value);
    };

    const getPackagesCount = playlistId => {
        const filteredPackages = packages.filter(pack => {
            const model = pack.Models.find(model => model.ModelID === modelID);
            if (model && model.Playlists.find(playlist => playlist.PlaylistID === playlistId)) {
                return true;
            }
            return false;
        });
        return filteredPackages.length;
    };

    const handleAddPlaylist = playlistId => async () => {
        const data = {
            packageID: packageId,
            playlistID: playlistId
        }
        try {
            await onAddPlaylistToPackage({ 
                data,
                isActivePackage: packages.find(pack => pack.ID === packageId)
            });
            forceReloadActivePackages();
        } catch (error) {
            console.log('ant : Add Playlist To Package Error => ', error);
        }
    };

    const handleRemovePlaylist = playlistId => async () => {
        try {
            await onDeletePlaylistFromActivePackage({ 
                packageID: packageId, 
                packagePlaylistId: getIsAddedBook(playlistId).ID, 
                isActivePackage: packages.find(pack => pack.ID === packageId) });
        } catch (error) {
            console.log('ant : Delete Playlist To Package Error => ', error);
        }
    };

    return (
        <>
            <BooksContainer 
                column
                alignItems='flex-start'
                justifyContent='space-between'
                ref={booksRef} 
                $projectModelBooks={projectModelBooks}
                id='model-books-package' 
                $pagination={filteredPlaylists.length > itemsPerPage}>
                {(bookContainerWidth > 0 || !plainMode)? 
                    <>
                        <FlexContainer fullWidth column alignItems='flex-start'>
                            {!hideFilters && 
                                <FlexContainer fullWidth justifyContent='space-between' alignItems='center' mg='0 0 16px' wrap='wrap'>
                                    <Input 
                                        autoComplete='off'
                                        value={searchTerm}
                                        onChange={handleChangeSearchTerm}
                                        addonAfter={<NwIcon icon={light('search')} />} /> &nbsp;
                                    <RadioButtonGroup 
                                            value={filterAuthorType} 
                                            onChange={setFilterAuthorType} 
                                            size='small' 
                                            options={[
                                                { label: 'All Books', value: 'all-books' },
                                                { label: 'Mine Only', value: 'mine-only'}
                                            ]} />
                                </FlexContainer>
                            }
                            <Row gutter={32}>
                                {playlistsToRender.map(playlist => {
                                    const playlistCount = getPackagesCount(playlist.ID);

                                    return (
                                        <Col span={booksSpan} key={`playlist-${playlist.ID}`}>
                                            <FlexContainer direction='row' alignItems='flex-start' className='book-detail'>
                                                <div>
                                                    {packageId ? 
                                                        <AvatarBg 
                                                            onClick={() => {showBookPreview(playlist)}} 
                                                            src={getBookCover(playlist)} />
                                                        : <StyledBadge>
                                                            <Badge count={playlistCount}>
                                                                <AvatarBg 
                                                                    onClick={() => {showBookPreview(playlist)}} 
                                                                    src={getBookCover(playlist)}
                                                                />
                                                            </Badge>
                                                        </StyledBadge>
                                                    }
                                                    {getIsAddedBook(playlist.ID) && 
                                                        <NwIcon className='added' icon={solid('check-circle')} primary />}
                                                </div>
                                                <BookDetail 
                                                    mg='0 0 0 8px' 
                                                    column
                                                    justifyContent='space-between'
                                                    alignItems='flex-end'>
                                                    <div className='playlist-info'>
                                                        <div className='playlist-name'>
                                                            {playlist.Name}
                                                        </div>
                                                        <div className='playlist-author'>{getCreateBy(playlist)}</div>
                                                    </div>
                                                    {packageId ?
                                                        <>
                                                            {getIsAddedBook(playlist.ID) ? 
                                                                <NwButton 
                                                                    size='small'
                                                                    ghost
                                                                    icon={solid('minus-circle')}
                                                                    label={`remove`}
                                                                    onClick={handleRemovePlaylist(playlist.ID)}
                                                                />
                                                                : <NwButton 
                                                                    size='small'
                                                                    ghost
                                                                    icon={solid('plus-circle')}
                                                                    label={`add`}
                                                                    onClick={handleAddPlaylist(playlist.ID)}
                                                                />
                                                            }
                                                        </>
                                                        : <AddBookToPackageButton 
                                                            packageId={packageId} 
                                                            activePackageOnly={activePackageOnly}
                                                            book={playlist}>
                                                            <NwButton 
                                                                size='small'
                                                                ghost
                                                                icon={solid('plus-circle')}
                                                                label={`add`}
                                                                onClick={() => {}}
                                                            />
                                                        </AddBookToPackageButton>
                                                    }
                                                </BookDetail>
                                            </FlexContainer>
                                        </Col>
                                    )
                                })}
                                {filteredPlaylists.length === 0 && 
                                    <EmptyState message='no playlists fitting your search' />
                                }
                            </Row>
                        </FlexContainer>
                        {filteredPlaylists.length > itemsPerPage && 
                            <FlexContainer fullWidth justifyContent='center' mg='8px 0 0'>
                                <Pagination 
                                    current={activePage}
                                    pageSize={itemsPerPage}
                                    size="small" 
                                    total={filteredPlaylists.length} 
                                    itemRender={(page, type, originalElement) => {
                                        return type === 'page' ? <NwIcon icon={solid('circle')} />
                                            : originalElement
                                    }}
                                    onChange={handleChangePagination} />
                            </FlexContainer>
                        }
                    </>
                    : 
                    <Spin spinning={true}>
                        <div className='placeholder' />
                    </Spin>
                }
            </BooksContainer>   
            {isBookDrawer && bookPreview &&
                <PlayListPreviewDrawer
                    playlist={bookPreview}
                    contactID={modelID}
                    onClose={() => {
                        setIsBookDrawer(false)
                        setBookPreview();
                    }}
                />
            }
        </>
    )
    
};


export default ModelBooksToPackage