const getUncheckedItemsCount = (items) => {
    const result = {done: 0, text: '', total: 0, complete: true};
    if (items && items.length) {
        result.total = items.length;
        result.done =  items.filter(item => item.Done).length;
        result.text = `${result.done}/${result.total}`;
        if (result.done !== result.total) {
            result.complete = false;
        }
        result.percent = Math.round(result.done / result.total * 100);
    }
    return result;
}

export {
    getUncheckedItemsCount
};