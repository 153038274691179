import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getScoutingAccommodation = scoutingAccommodationId => (
    Axios.get(`/scoutingAccommodation/${scoutingAccommodationId}`)
);

const useScoutingAccommodation = scoutingAccommodationId => {
    return useQuery(["scoutingAccommodation", scoutingAccommodationId], () => getScoutingAccommodation(scoutingAccommodationId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: scoutingAccommodationId ? true : false
    });
};

const forceReloadScoutingAccommodation = (ID) => {
    queryClient.invalidateQueries(['scoutingAccommodation', ID])
}

const onDeleteScoutingAccommodation = ({ ID }) => (
    Axios.delete(`/scoutingAccommodation/${ID}`)
)

const useDeleteScoutingAccommodation = () => {
    return useMutation(onDeleteScoutingAccommodation, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.scoutingAccommodationId) {
                forceReloadScoutingAccommodation(variables.scoutingAccommodationId);
            }
        }
    })
}

const onUpdateScoutingAccommodation = ({ scoutingAccommodationData }) => (
    Axios.put('/scoutingAccommodation', scoutingAccommodationData)
);

const useUpdateScoutingAccommodation = () => {
    return useMutation(onUpdateScoutingAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadScoutingAccommodation(variables.scoutingAccommodationData.ID);
        }
    })
}

const onAddScoutingAccommodation = ({ scoutingAccommodationData }) => (
    Axios.post('/scoutingAccommodation', scoutingAccommodationData)
);

const useAddScoutingAccommodation = () => {
    return useMutation(onAddScoutingAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadScoutingAccommodation(data.ID);
        }
    })
};

export {
    useScoutingAccommodation,
    useDeleteScoutingAccommodation,
    useUpdateScoutingAccommodation,
    useAddScoutingAccommodation,
    forceReloadScoutingAccommodation,
};