import React from 'react';
import useEventStyle from 'Hooks/UseEventStyle';
import NwIcon from '../Gui/NwIcon';
import ObjectHistory from 'Components/CreatedBy/ObjectHistory';
import { EventTag, EventTagBig, EventTagIcon } from './EventDetailsUI';




const EventTypeTag = ({ event, big = false, type, noBack, titleOnly, iconOnly, forModel, forDate }) => {
	
	const eventstyle = useEventStyle( event, type, forModel, forDate, true )
	
	const renderOptionNumber = () => {
		if (event.TypeName === "Job") {
			if (event.OptionNumber && event.OptionNumber < 10) {
				return event.OptionNumber;
			}
		}
		return '';
	};

	if (!eventstyle) {
	    return null;
	}

	const tagStyle = noBack ? { backgroundColor: '#fff', color: '#000' } : { background: eventstyle.backgroundColor, color: eventstyle.color };
	return (
		<>
			{big
				?
				<EventTagBig className="netwalk-event-tag netwalk-event-tag-big" titleOnly={titleOnly}>
					<EventTagIcon>
						{eventstyle.icon && <NwIcon icon={eventstyle.icon} />}
					</EventTagIcon>
					<div className="title">
						<h1>{eventstyle.name}{renderOptionNumber()}</h1>
					</div>
					{!titleOnly &&
						<div className="spec"><small><ObjectHistory object={event} breaklines /></small></div>
					}
				</EventTagBig>
				:
				<EventTag className="netwalk-event-tag" style={tagStyle}>
					{eventstyle.icon && <NwIcon icon={eventstyle.icon} />}
					{!iconOnly && `${eventstyle.name} ${renderOptionNumber()}`}
				</EventTag>
			}
		</>
	);
};

export default EventTypeTag;
