import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProjectCallSheet from 'Components/Project/ProjectContacts/ProjectCallSheet';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import { ListContext } from 'Contexts/ListContext';

const Wrapper = styled.div`
    min-height: 100%;
    .ant-card {
        min-height: calc(100vh - 252px);
    }
    .ant-card-body-content {
        padding: 0 !important;
    }
`;

const GridCard = styled(Card.Grid)`
    float: left; //added because ant removed it to use flex- to update later
    width: ${props => props.$isDrawer ? "100%" : "33.33%"};
    height: 200px;
    @media only screen and (max-width: 1200px) {
        width: ${props => props.$isDrawer ? "100%" : "50%"};
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const Container = styled.div`
    padding: 16px;
`;

const CallSheet = styled.div`
    padding: 16px;
    height: 180px;
    border-bottom: ${props => props.theme.DefaultBorder};
    &:last-child {
        border-bottom: none;
    }
`;

const ProjectContacts = ({ projectId, mode = 'project', isDrawer = false }) => {
    const [callsheets, setCallsheets] = useState([]);
    const { cachedList } = useContext(ListContext);
    const { callsheetTypes } = cachedList;

    useEffect(() => {
        getProjectCallSheets()
    }, []);

    const getProjectCallSheets = async () => {
        try {
            const callsheets = await Axios.get(`/projects/callsheets/${projectId}`);
            callsheets.sort((a, b) => a.CallSheetType.ID - b.CallSheetType.ID);
            setCallsheets(callsheets);
        } catch (error) {
            console.log('ant : Get Project Callsheets Error => ', error);
        }
    };

    const handleUpdateCallSheets = async () => {
        getProjectCallSheets()
    }

    const renderEmptyCards = () => {
        const emptyRoles = callsheetTypes.items.filter(item => !callsheets.find(callsheet => callsheet.CallSheetType.ID === item.ID));
        if (!emptyRoles || !emptyRoles.length) return (null);
        return emptyRoles.map((emptyRole, index) =>
            <GridCard key={`callsheet-${index}`} $isDrawer={isDrawer}>
                <ProjectCallSheet
                    callsheet={emptyRole}
                    isempty={true}
                    isDrawer={isDrawer}
                    projectId={projectId}
                    onUpdateCallSheet={handleUpdateCallSheets} />
            </GridCard>
        )
    }

    const renderProjectContacts = () => {
        return (
            <>
                {callsheets.length > 0 &&
                    <>
                        {mode === 'project' ?
                            <>
                                {callsheets.map(callsheet => {
                                    return (
                                        <GridCard key={`callsheet-${callsheet.ID}`}>
                                            <ProjectCallSheet
                                                callsheet={callsheet}
                                                projectId={projectId}
                                                onUpdateCallSheet={handleUpdateCallSheets} />
                                        </GridCard>
                                    )
                                })}
                            </>
                            :
                            <Container>
                                {callsheets.map(callsheet => {
                                    return (
                                        <CallSheet key={`callsheet-${callsheet.ID}`}>
                                            <ProjectCallSheet
                                                mode='eventCallSheet'
                                                callsheet={callsheet}
                                                projectId={projectId}
                                                onUpdateCallSheet={getProjectCallSheets} />
                                        </CallSheet>
                                    )
                                })}
                            </Container>
                        }
                    </>
                }
                {renderEmptyCards()}
            </>
        )
    };

    return (
        <Wrapper>
            {mode === 'project' ?
                <NwCard
                    padding='no-padding'
                    title='Contacts'
                    icon={light('id-card-alt')}>
                    <div>
                        {renderProjectContacts()}
                    </div>
                </NwCard>
                :
                <>
                    {renderProjectContacts()}
                </>
            }
        </Wrapper>
    );
};

export default ProjectContacts;