import React from 'react'
import styled from 'styled-components'

export const PublishedBookContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .25rem 0;

    .published-book-icon {
        padding: 0 1.5rem;
        font-size: 1.75rem;
    }
    .published-book-info {
        p {
            color: ${props => props.theme.TextColor};
            font-size: ${props => props.theme.FontSizeSm};
            margin: 0;
            padding: 0;
            line-height: 1.2em;
        }
    }
`

export const OtherBooksPublishedContainer = styled.div`
    color: ${props => props.theme.TextColor};
    font-size: ${props => props.theme.FontSizeSm};
    text-transform: uppercase;
`