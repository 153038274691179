const RedColor = '#f5222d'
const PrimaryBlue = '#26b3ea' //BLUE
const PrimaryHalfBlue = '#a8e1f7' //BLUE (60% lighten)
const PrimaryLightBlue = '#c8ebf9'  //BLUE (75% lighten)
const PrimaryBackgroundBlue = '#eff7ff' //BLUE (95% lighten)
const YellowColor = '#fadb14' //YELLOW (yellow-6 on ANT)

const StyleVariables = {
    PrimaryColor: PrimaryBlue, //BLUE
    PrimaryColorHalf: PrimaryHalfBlue, //BLUE (60% lighten)
    PrimaryColorLight: PrimaryLightBlue, //BLUE (75% lighten)
    PrimaryDark: '#595959', //GRAY-8
    PrimaryDarkLight: '#bfbfbf', //GRAY-6
    GreyedColor: '#808080', //GREY
    DangerColor: RedColor, //RED
    WarningColor: YellowColor, //YELLOW
    WarningColorLight: '#fffb8f', //LIGHT YELLOW (yellow-3 on ANT)
    WorkingColor: '#ffffb8', //LIGHT YELLOW (yellow-2 on ANT)
    SuccessColor: '#389e0d', //GREEN (green-7 on ANT)
    SuccessColorLight: '#b7eb8f', //LIGHT GREEN (green-3 on ANT)
    FontSizeBase: '1rem',
    FontSizeXLg: '1.5rem',
    FontSizeLg: '1.2rem',
    FontSizeSm: '.8rem',
    FontSizeXSm: '.6rem',

    FontFamilyMonospace: `'Chivo Mono', monospace;`,

    BackgroundColorLight: '#fff',
    LightBlueBackground: PrimaryBackgroundBlue,
    //LayoutBodyBackground: '#e6ecf0',
    LayoutBodyBackground: '#f0f0f0',
    LayoutBodyBackgroundTransparent: 'rgba(240, 240, 240, .9)',
    LayoutHeaderBackground: '#fff',
    LayoutFooterBackground: '#fff',
    LayoutHeaderHeight: '60px',
    LayoutHeaderPadding: '0',
    LayoutFooterPadding: '0',
    LayoutSiderBackground: '#fff',
    BtnFontSizeSm: '.8rem',
    BtnTextShadow: 'none',
    //BtnDefaultColor: "color(~`colorPalette('gray', 5) `)",
    BtnDefaultBg: "#fff",
    //BtnDefaultBorder: "color(~`colorPalette('gray', 2) `)",
    TextColor: 'rgba(0,0,0,0.75)',
    TextColorMuted: 'rgba(0,0,0,0.5)',
    DisabledTextColor: 'rgba(0,0,0,0.25)',
    BlackBorder: '1px solid rgba(0,0,0,0.75)',
    DarkerBorder: '1px solid #d9d9d9',
    DefaultBorder: '1px solid #e8e8e8',
    LighterBorder: '1px solid #f0f0f0',
    BorderColor: '#e8e8e8',
    DarkerBorderColor: '#d9d9d9',
    LighterBorderColor: '#f0f0f0',
    HoverablesBackground: '#f0fdff',
    SelectedBackgroundColor: '#fafafa',
    SelectedShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
    GoldColor: '#faad14',
    TableRowDark: '#fafafa',

    RedLineThrough: (size) => `
    text-decoration: line-through;
    text-decoration-color: ${RedColor};
    text-decoration-thickness: ${size}px;
    `,

    LineThrough: (size) => `
    text-decoration: line-through;
    text-decoration-thickness: ${size}px;
    `,
    
    SiderShadow: '0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1)'
}

export default StyleVariables
