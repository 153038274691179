import React from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const GenderSelector = props => {
    
    return (
        <RadioButtonGroup
            style={{whiteSpace: "nowrap"}} 
            value={props.value} 
            disabled={props.disabled}
            onChange={props.onChange}
            defaultValue='Female'
            options={[
                { label: 'Female', value: 'Female', icon: light('venus') },
                { label: 'Male', value: 'Male', icon: light('mars') },
                { label: 'Non Binary', value: 'NonBinary', icon: light('mars-and-venus') }
            ]} />
    );
};

export { GenderSelector };





