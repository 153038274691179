import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getFollowup = followupId => (
    Axios.get(`/followUps/${followupId}`)
);

const useFollowup = followupId => {
    return useQuery(["followup", followupId], () => getFollowup(followupId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: followupId ? true : false
    });
};

const forceReloadFollowup = (ID) => {
    queryClient.invalidateQueries(['followup', ID])
}

const onDeleteFollowup = ({ ID }) => (
    Axios.delete(`/followUps/${ID}`)
)

const onDeleteFollowupGroup = ({ GroupGUID }) => (
    Axios.delete(`/followUps/deletegroup/${GroupGUID}`)
)

const useDeleteFollowup = () => {
    return useMutation(onDeleteFollowup, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.followupId) {
                forceReloadFollowup(variables.followupId);
            }
        }
    })
}

const useDeleteFollowupGroup = () => {
    return useMutation(onDeleteFollowupGroup, {
        onSuccess: (data, variables) => {
            // if (variables.ID !== variables.travelId) {
            //     forceReloadTravel(variables.travelId);
            // }
        }
    })
}

const onUpdateFollowup = ({ followupData }) => (
    Axios.put('/followUps', followupData)
);

const useUpdateFollowup = () => {
    return useMutation(onUpdateFollowup, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupData.ID);
        }
    })
}

const onAddFollowup = ({ followupData }) => (
    Axios.post('/followUps', followupData)
);

const useAddFollowup = () => {
    return useMutation(onAddFollowup, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(data.ID);
        }
    })
};

const onMoveFollowup = ({ followupData }) => (
    Axios.put('/followUps/MoveEvent', followupData)
);

const useMoveFollowup = () => {
    return useMutation(onMoveFollowup, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupData.ID);
        }
    })
};

const onMoveGroupedFollowup = ({ followupData }) => (
    Axios.put('/followUps/MoveGroupedEvent', followupData)
);

const useMoveGroupedFollowup = () => {
    return useMutation(onMoveGroupedFollowup, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupData.ID);
        }
    })
};

const onUpdateAcknowledgment = ({ ID }) => (
    Axios.put(`/followUps/acknowledgment/${ID}`)
)

const useUpdateFollowupAcknowledgment = () => {
    return useMutation(onUpdateAcknowledgment, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupId);
        }
    })
};

const onCancelFollowups = ({ ID }) => (
    Axios.put(`/followUps/cancellation/${ID}`)
);

const useCancelFollowup = () => {
    return useMutation(onCancelFollowups, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.ID);
        }
    })
};

const onCloneModelsFollowup = ({ data }) => (
    Axios.post(`/followups/CloneForModels`, data)
);

const useCloneModelsFollowup = () => {
    return useMutation(onCloneModelsFollowup, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.data.EventID);
        }
    })
};

const onUpdateGroupedIndividualFollowUp = ({ followupData }) => (
    Axios.put('/FollowUps/GroupedIndividual', followupData)
);

const useUpdateGroupedIndividualFollowUp = () => {
    return useMutation(onUpdateGroupedIndividualFollowUp, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupData.ID);
        }
    })
}

const onUpdateGroupedCommonFollowUp = ({ followupData }) => (
    Axios.put('/FollowUps/GroupedCommon', followupData)
);

const useUpdateGroupedCommonFollowUp = () => {
    return useMutation(onUpdateGroupedCommonFollowUp, {
        onSuccess: (data, variables) => {
            forceReloadFollowup(variables.followupData.ID);
        }
    })
}

export {
    useFollowup,
    useDeleteFollowup,
    useDeleteFollowupGroup,
    useUpdateFollowup,
    useAddFollowup,
    forceReloadFollowup,
    useUpdateFollowupAcknowledgment,
    useCancelFollowup,
    getFollowup,
    useCloneModelsFollowup,
    useUpdateGroupedIndividualFollowUp,
    useUpdateGroupedCommonFollowUp,
    useMoveFollowup,
    useMoveGroupedFollowup
};