
import { getContactName } from "Hooks/Contact/UseContactsList";

export const matchItemToEvent = (item, events) => {
    if (item.EventGroupGuid) {
        return events.find(e => e.GroupGuid === item.EventGroupGuid)
    }
    if (item.EventID) {
        return events.find(e => e.uniqID === `Event-${item.EventID}`)
    }
    if (item.FollowUpID) {
        return events.find(e => e.uniqID === `FollowUp-${item.FollowUpID}`)
    }
    if (item.MeetingID) {
        return events.find(e => e.uniqID === `Meeting-${item.MeetingID}`)
    }
    if (item.TodoID) {
        return events.find(e => e.uniqID === `ToDo-${item.TodoID}`)
    }
    if (item.TravelID) {
        return events.find(e => e.uniqID === `Travel-${item.TravelID}`)
    }
    if (item.AccommodationID) {
        return events.find(e => e.uniqID === `Accommodation-${item.AccommodationID}`)
    }
}

export const getEventByItem = (item, events, selectedEvent) => {
    if (!item && selectedEvent && selectedEvent.elType === 'Accommodation') {
        return null;
    }
    if (!item) return selectedEvent;
    
    return getEvent(events, item)

    if (item) {
        return null;
    }
    return selectedEvent;
}

// export const getGroupedEvents = (orderedEventList) => {
//   let events = JSON.parse(JSON.stringify([...orderedEventList]));
//   events = events.filter(event => event.TypeName !== "ToDo"); // && event.TypeName !== "Package"
//   let displayedEvents = [];
//   for (const event of events) {
//     if (event.GroupGuid) {
//       const groupEventIndex = displayedEvents.findIndex(item => item.GroupGuid === event.GroupGuid);

//       if (groupEventIndex > -1) {
//         if (!displayedEvents[groupEventIndex].relatedEventList) {
//           displayedEvents[groupEventIndex].relatedEventList = [event];
//         } else {
//           displayedEvents[groupEventIndex].relatedEventList.push(event);
//         }
//       }
//       if (!displayedEvents.find(item => item.GroupGuid === event.GroupGuid)) {
//         displayedEvents.push(event);
//       }
//     } else {
//       displayedEvents.push(event);
//     }
//   }
//   displayedEvents.sort((event1, event2) => new Date(event1.sortField) > new Date(event2.sortField) ? 1 : -1);
//   return displayedEvents;
// }

const matchEvent = (data, event) => {
    if (data.EventGroupGuid && data.EventGroupGuid === event.GroupGuid) {
        return true;
    }
    switch (event.elType) {
        case "Event":
            return data.EventID && data.EventID === event.ID;
        case "Travel":
            if (event.ID) {
                return data.TravelID && data.TravelID === event.ID;
            }
            return data.TravelID &&  event.Models.some(model => model.TravelID === data.TravelID);
        case "FollowUp":
            if (event.ID) {
                return data.FollowUpID && data.FollowUpID === event.ID;
            }
            return data.FollowUpID && event.Models.some(model => model.FollowUpID === data.FollowUpID);
        case "Accommodation":
            if (event.ID) {
                return data.AccommodationID && data.AccommodationID === event.ID;
            }
            return data.AccommodationID && event.Models.some(model => model.AccommodationID === data.AccommodationID);
        case "ExternalJob":
            return data.ExternalJobID && data.ExternalJobID === event.ID;
        case "Meeting":
            return data.MeetingID && data.MeetingID === event.ID;
        default:
            return false;
    }
}

export const getEvent = (events, data) => {
    if (!events) {
        return null;
    }
    return events.find(e => matchEvent(data, e));
}

export const getModelsFromEventDates = (eventDates) => {
    const models = []

    eventDates.forEach(eventDate => {
        eventDate.EventDatesModels.forEach(eventDateModel => {
            if (!models.find(model => model.ModelId === eventDateModel.ModelID)) {
                models.push({
                    ModelId: eventDateModel.ModelID,
                    Name: getContactName(eventDateModel.ModelID)
                })
            }
        })
    })

    return models;
}