import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { enumerateDaysBetweenDates } from 'Libs/NwUtils';
import { showDate, toISODate } from 'Libs/NwMoment';

const DateModels = styled.div`
    padding: 16px 30px 16px 40px;
    flex: 1;
    font-size: .95rem;
    border-bottom: ${props => props.theme.DefaultBorder};

    .date {
        font-weight: 700;
        font-size: 1.3rem;
        text-transform: uppercase;
    }

    svg {
        font-size: 1rem;
        margin-right: 0.5rem;
    }
`;

const RoomLodgingModels = ({ accommodationData }) => {
    const [daysAccommodations, setDaysAccommodations] = useState({});

    useEffect(() => {
        const getAccommodations = async () => {
            const accommodations = await Axios.post('/accommodation/availabilitysearch', {
                FromDate: accommodationData.FromDate,
                ToDate: accommodationData.ToDate,
                RoomID: accommodationData.RoomID,
                RoomsOnly: true 
            });
            const days = enumerateDaysBetweenDates(accommodationData.FromDate, accommodationData.ToDate);
            let daysAccommodations = {};
            days.map(day => {
                const dayAccommodations = accommodations.filter(accommodation => toISODate(day) >= toISODate(accommodation.FromDate) && toISODate(day) <= toISODate(accommodation.ToDate));
                const dayModels = dayAccommodations.map(accommodation => getContactName(accommodation.ModelID)).join(', ');
                daysAccommodations = {
                    ...daysAccommodations,
                    [showDate(day, 'ext-short')] : dayModels
                }
                return null;
            })
            setDaysAccommodations(daysAccommodations);
        }
        
        if (accommodationData.RoomID) {
            getAccommodations();
        }
    }, [accommodationData])

    return (
        <>
            {Object.keys(daysAccommodations).map(day => {
                return (
                    <DateModels key={day}>
                        <div className='date'>{day}</div>
                        <FlexContainer>
                            <NwIcon icon={light('users')} />
                            {daysAccommodations[day]}
                        </FlexContainer>
                    </DateModels>
                )
            })}
        </>
    );
};

export default RoomLodgingModels;