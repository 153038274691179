import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWTextAreaCharCounter } from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { useAddManual, useEditText } from "Hooks/Invoices/UseInvoices";

const AddManualDrawer = (props) => {
    const { onClose, invoiceID, record } = props;
    const [touchForm, setTouchForm] = useState(false);
    const { mutateAsync: onAddManual } = useAddManual();
    const { mutateAsync: onEditText } = useEditText();
    const initialValues = {
        Text: record ? record.Text : "",
    }

    const saveAddManual = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            if (record) {
                // Edit text
                if (record.Type === "Text")
                    await onEditText({
                        data: {
                            InvoiceID: invoiceID,
                            RowID: record.index,
                            Text: values.Text
                        },
                        type: "Text"
                    });
                else if (record.RowIds.length === 1)
                    await onEditText({
                        data: {
                            InvoiceID: invoiceID,
                            RowID: record.index,
                            Text: values.Text
                        },
                        type: "Statement"
                    });
                else
                    await onEditText({
                        data: {
                            InvoiceID: invoiceID,
                            GroupGuid: record.Guid,
                            Text: values.Text
                        },
                        type: "Group"
                    });
            } else {
                // Add text
                await onAddManual({
                    data: {
                        InvoiceID: invoiceID,
                        Text: values.Text
                    }
                });
            }
            onClose();
        } catch (e) {
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={record ? "Change text" : "Add a text line"}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveAddManual(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    Text: Yup.string().required('Content is required'),
                })}
            >
                {({ isSubmitting, values, handleSubmit, }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                    >
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Description"
                            name="Text"
                            maxLength={200}
                            type="text"
                            value={values.Text}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default AddManualDrawer;