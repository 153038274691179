import React from 'react';
import styled from 'styled-components';
import { Tabs, Tooltip } from 'antd';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const TabsStyled = styled(Tabs)`
  .ant-tabs-content {
    padding-top: 0;
    margin-bottom: ${props => props.$mobileView && '60px'};
  }
  .ant-tabs-nav-list {
    padding-right: 2rem;
    justify-content: flex-end;
    width: 100%;
    .ant-tabs-tab {
      min-width: 40px;
      display: flex;
      justify-content: center;
      .ant-tabs-tab-btn svg {
        font-size: 1.5em;
      }
    }
  }
  &.ant-tabs {
    position: relative;
    overflow: visible;
    .ant-tabs-nav {
      position: sticky;
      right: 8px;
      z-index: 5;
      top: 80px;
      width: 100%;
      margin: 0;
      margin-top: -50px;

      .ant-tabs-nav-wrap {
        position: ${props => props.$mobileView && 'fixed'};
        bottom: ${props => props.$mobileView && 0};
        background: ${props => props.$mobileView && 'white'};
        width: 100%;
        padding-right: ${props => props.$mobileView && '8px'};
        border-top: ${props => props.$mobileView && '2px solid #e8e8e8'};
      }
    }
  }
`;

const EventDrawerTabs = ({ tabs }) => {
  const breakpoints = useNwBreakPoints();
  const onDrawerTabChange = () => {
    document.querySelector('.ant-drawer-body').scrollTop = 0;
  }

  return (
    tabs &&
    <TabsStyled 
        $mobileView={breakpoints === 1} 
        defaultActiveKey="1" 
        size="middle"
        destroyInactiveTabPane
        onChange={onDrawerTabChange}
        items={
            [
                {
                    label: <Tooltip title="Project Timeline"><NwIcon icon={light('calendar-alt')} /></Tooltip>,
                    key: 'calendar',
                    children: tabs[0]
                },
                {
                    label: <Tooltip title="Fees and Expenses"><NwIcon icon={light('message-dollar')} /></Tooltip>,
                    key: 'invoice',
                    children: tabs[2]
                },
                {
                    label: <Tooltip title="Callsheet"><NwIcon icon={light('address-book')} /></Tooltip>,
                    key: 'address',
                    children: tabs[3]
                },
                {
                    label: <Tooltip title="Documents"><NwIcon icon={light('paperclip')} /></Tooltip>,
                    key: 'attachments',
                    children: tabs[4]
                },
            ]
        }
    >
    </TabsStyled>
  );
}

export default EventDrawerTabs;