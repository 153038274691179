import React, {useState} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';


const VideoPlayer = ({ document, width, height, playing }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const videoWidth = width || '98%';
    const videoHeight = height || '94%';

    return (
        <ReactPlayer
            onReady={() => setIsLoaded(true)}
            url={document.Standard.Url}    
            width={videoWidth}
            height={videoHeight}
            playing={playing}
            controls
        />
    );
};

export default VideoPlayer;