import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';
import ls from 'local-storage';
import { queryClient } from 'QueryClientProvider';

const fetchActivePackages = async (_) => (
    await Axios.get('/packages/getactive')
)

const useActivePackages = () => {
    return (
        useQuery(["activepackages"],
        fetchActivePackages,
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}

const getActivePackage = (ID) => {
    const packages = queryClient.getQueryData(['activepackages']);
    if (packages) {
        const p = packages.find((p) => (p.ID === ID))
        if (p) {
            return p
        }
    }
    return null;
}

const getActivePackages = () => {
    const packages = queryClient.getQueryData(['activepackages']) ? queryClient.getQueryData(['activepackages']) : []
    return packages
}

const prefetchActivePackages = () => {
    queryClient.prefetchQuery(
        ['activepackages'],
        () => fetchActivePackages(),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
};

const forceReloadActivePackages = () => queryClient.invalidateQueries(['activepackages']);

const onAddPackage = ({ data }) => (
    Axios.post(`/packages/create`, data)
);

const useAddPackage = () => {
    return useMutation(onAddPackage, {
        onSuccess: (data, variables) => {
            ls.set('nw_config_latest_package', data.ID);
            //forceReloadActivePackages();
        }
    })
};

const onClonePackage = ({ data }) => (
    Axios.post(`/packages/clone`, data)
);

const useClonePackage = () => {
    return useMutation(onClonePackage, {
        onSuccess: (data, variables) => {
            ls.set('nw_config_latest_package', data);
            forceReloadActivePackages();
            return data;
        }
    })
};

const onUpdatePackage = ({ data }) => (
    Axios.put(`/packages`, data)
);

const useUpdatePackage = () => {
    return useMutation(onUpdatePackage, {
        onSuccess: (data, variables) => {
            ls.set('nw_config_latest_package', data.ID);
            //forceReloadActivePackages();
        }
    })
};


const onDeletePackage = ({ PackageID }) => (
    Axios.delete(`/packages/${PackageID}`)
);

const useDeletePackage = () => {
    return useMutation(onDeletePackage, {
        onSuccess: (data, variables) => {
            ls.set('nw_config_latest_package', null);
            //forceReloadActivePackages();
        }
    })
};

const onAddPlaylistToPackage = ({ data }) => (
    Axios.post('/PackagePlaylists', data)
);

const useAddPlaylistToPackage = () => {
    return useMutation(onAddPlaylistToPackage, {
        onSuccess: (data, variables) => {
            if (variables.isActivePackage) {
                ls.set('nw_config_latest_package', variables.data.packageID);
            }
            if (variables.data.packageID) {
                forceReloadPackage(variables.data.packageID);
            }
            //forceReloadActivePackages();
        }
    });
};

const onDeletePlaylistFromPackage = ({ packagePlaylistId }) => (
    Axios.delete(`/PackagePlaylists/${packagePlaylistId}`)
);

const useDeletePlaylistFromActivePackage = () => {
    return useMutation(onDeletePlaylistFromPackage, {
        onSuccess: (data, variables) => {
            if (variables.isActivePackage) {
                ls.set('nw_config_latest_package', variables.packageID);
            }
            if (variables.packageID) {
                forceReloadPackage(variables.packageID);
            }
            //forceReloadActivePackages();
        }
    });
};

const useDeletePlaylistFromPackage = () => {
    return useMutation(onDeletePlaylistFromPackage, {
        onSuccess: (data, variables) => {
            if (variables.isActivePackage) {
                ls.set('nw_config_latest_package', variables.packageID);
                //forceReloadActivePackages();
            }
            console.log("chiamo force reload package")
            forceReloadPackage(variables.packageID);
        }
    });
};

const onGetPackageByID = ID => (
    Axios.get(`/packages/${ID}`)
)

const usePackage = (ID) => {
    return useQuery(["package", ID], () => onGetPackageByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
};
 
const forceReloadPackage = (ID) => {
    queryClient.invalidateQueries(['package', ID])
    //forceReloadActivePackages()
};

const onFullSearchPackages = filters => (
    Axios.post('/packages/search', {...filters, sentOnly: true})
);

const useFullSearchPackages = filters => {
    return useQuery(['packages-FullSearch'], () => onFullSearchPackages(filters), {
        //enabled: filters ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

const forceReloadFullSearchPackages = () => {
    queryClient.invalidateQueries(['packages-FullSearch'])
};

const refetchFullSearchPackages = async (filters) => {
    try {
        await queryClient.fetchQuery(['packages-FullSearch'], () => onFullSearchPackages(filters));
    } catch (error) {
        console.log(error)
    }
};

export {
    useActivePackages,
    forceReloadActivePackages,
    getActivePackage,
    getActivePackages,
    prefetchActivePackages,
    useAddPackage,
    useClonePackage,
    useUpdatePackage,
    useDeletePackage,
    useAddPlaylistToPackage,
    useDeletePlaylistFromPackage,
    useDeletePlaylistFromActivePackage,
    refetchFullSearchPackages,
    usePackage,
    forceReloadPackage,
    useFullSearchPackages,
    forceReloadFullSearchPackages
}
