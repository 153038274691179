import React from 'react';
import styled from 'styled-components';
import { List, Divider } from 'antd';
import _ from "lodash";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import AvatarBg from 'Components/Gui/AvatarBg';
import { getBookCover, findPath } from 'Libs/NwUtils';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import AddBookToPackageButton from 'Components/ActivePackages/AddBookToPackageButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import StyleVariables from 'Components/Gui/StyleVariables.js';
import PrintPlaylistButton from 'Components/Playlists/PrintPlaylistButton';
import { useState } from 'react';
import MiniLoading from 'Components/Gui/MiniLoading';

const ListItem = styled(List.Item)`
  .ant-list-item-action {
    margin-left: 0 !important;
  }
  &:hover {
    background-color: ${props => props.$isDrawer ? "none" : props.theme.SelectedBackgroundColor};
  }
  cursor: pointer;
`;

const BookAuthorText = styled.div`
  font-size: 0.6rem;
  text-transform: uppercase;
  margin: .25rem 0;
  display: flex;
  align-items: center;
  color: ${props => props.$isPrivate ? props.theme.DangerColor : props.theme.TextColorMuted};

  svg {
    margin-right: .25rem;
  }
`;

const CheckBookIcon = styled(NwIcon)`
  font-size: 1.4rem;
`;

const BookTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  align-items: center;
  gap: .5rem;
  
  .badge {
    width: .5rem;
    height: .5rem;
    min-width: 0.5rem;
    min-height: 0.5rem;
    background: ${StyleVariables.DangerColor};
    border-radius: 100%;
  }
  .book-name {
    font-weight: ${props => props.$fastpackage ? "700" : "500"};
    font-size: .85rem;
    color: ${props => props.$exist ? props.theme.PrimaryColor : props.theme.TextColor};
  }
`;

const BookLastLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  small.fast-package {
    color: ${props => props.theme.TextColor};
  }
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 5rem;

    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .icon-wrapper {
            padding-left: 1rem;
        }
    }
`

const AvatarLoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80px;
    height: 80px;
    font-size: 2rem;
`

const BookItem = ({
    addedPlaylists,
    book,
    isDrawer,
    onAddBook,
    onChangeFastPackageStatus,
    onChangeCompCardStatus,
    onDeleteBook,
    onEditBook,
    onRemoveBook,
    packageEditView,
    readOnly,
    showBookInfo,
    showBookPreview,
    showCoverSelector,
    removeCover,
    showPublishBook,
    spinning,
    websiteData,
}) => {

    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const userPermission = useUserPermission('DeleteBooks', [book.UserCreated]);
    const breakpoints = useNwBreakPoints();

    let hasCover = false
    if (book.Covers && book.Covers.length > 0) {
        const standardcover = _.find(book.Covers, { CoverFormat: "Standard" });
        if (standardcover) {
            hasCover = true;
        }
    }


    const actions = (book) => {
        const actions = [
            { name: "", key: "edit", tooltip: "edit book", pinned: true, icon: light("pen"), action: (() => onEditBook(book)) },
            { name: "", key: "preview", pinned: true, tooltip: "view book", icon: light("images-user"), action: (() => showBookPreview(book)) },
            { name: "", key: "info", pinned: true, tooltip: "info", icon: light("circle-info"), action: (() => showBookInfo(book)) },
            { name: "", pinned: true, key: "publish", tooltip: "publish on website", icon: light("globe"), action: (() => showPublishBook(book)) },
            { name: "select cover", key: "addcover", disabled: hasCover, tooltip: "select and crop a cover image", icon: light("scissors"), action: (() => showCoverSelector(book)) },
            { name: "remove cover", key: "removecover", disabled: !hasCover, tooltip: "remove selected cover image", icon: light("xmark"), action: (() => removeCover(book)) },
            { name: "delete", type: "delete", key: "delete", tooltip: "delete book", icon: light("trash-alt"), confirmationPlacement: "left", disabled: !userPermission, action: (() => onDeleteBook(book)) }
        ]

        let fpaction = null;
        let compaction = null;
        if (book.FastPackage) {
            fpaction = { name: "remove as fast package book", key: "fast", tooltip: "fast package", icon: light("truck-fast"), action: (() => onChangeFastPackageStatus(book, false)) }
        } else {
            fpaction = { name: "set as fast package book", key: "fast", tooltip: "fast package", icon: light("truck-fast"), action: (() => onChangeFastPackageStatus(book, true)) }
        }
        if (book.CompCard) {
            compaction = { name: "set as not compcard", key: "comp", tooltip: "not compcard", icon: light("image-polaroid-user"), action: (() => onChangeCompCardStatus(book, false)) }
        } else {
            compaction = { name: "set as compcard", key: "comp", tooltip: "compcard", icon: light("image-polaroid-user"), action: (() => onChangeCompCardStatus(book, true)) }
        }
        actions.splice(2, 0, fpaction, compaction);

        return [
            <ActionsContainer>
                <NwDropDownMenu
                    actions={actions}
                />
                <div className="bottom">
                    <div className="icon-wrapper">
                        <PrintPlaylistButton
                            playlistID={book.ID}
                            placement="leftTop"
                        />
                    </div>
                    <div className="icon-wrapper">
                        <AddBookToPackageButton
                            activePackageOnly
                            book={book}
                            placement="leftTop"
                        />
                    </div>
                </div>
            </ActionsContainer>
        ]
    }

    const isInPackage = packageEditView && addedPlaylists.find(playlistId => playlistId === book.ID);

    const packageEditOnClick = async () => {
        //const exist = addedPlaylists.find(playlistId => playlistId === book.ID);
        if (!isInPackage) {
            setIsAdding(true)
            await onAddBook(book)
            setIsAdding(false)
        } else {
            setIsRemoving(true)
            await onRemoveBook(book)
            setIsRemoving(false)
        }
    }

    const actionsPackageEdit = () => {
        return [
            <CheckBookIcon
                icon={light('magnifying-glass-plus')}
                pointer
                onClick={() => {
                    showBookPreview(book)
                }}
            />,
        ]
    };

    const getLinkedWebsites = (book) => {
        const linkedSections = [];
        if (websiteData && websiteData.length && book.Divisions) {
            book.Divisions.map((item) => {
                linkedSections.push(findPath(websiteData, item.WebsiteCode, item.DivisionCode, ""));
                return null;
            })
        }
        return [...linkedSections];
    }

    const getDescription = (book) => {

        if (packageEditView && breakpoints === 1) {
            return null
        }
        if (isAdding) {
            return <small>adding book to package...</small>
        }
        if (isRemoving) {
            return <small>removing book from package...</small>
        }
        let linkedSections = [];
        linkedSections = getLinkedWebsites(book);
        return (
            <FlexContainer column justifyContent='space-between' alignItems='flex-start'>
                <BookAuthorText $isPrivate={book.PrivacyLevelName === "User"}>
                    {book.PrivacyLevelName === "User" && <NwIcon icon={light("lock")} />}
                    <CreatedBy object={book} short textonly />
                </BookAuthorText>
                {linkedSections.length
                    ?
                    <small><NwIcon icon={light("globe")} /> published {linkedSections.length} in website{linkedSections.length > 1 ? "s" : ""}</small>
                    :
                    <small><NwIcon icon={light("globe")} /> not published</small>
                }
                <BookLastLine>
                    {book.FastPackage &&
                        <>
                            <small className="fast-package"><NwIcon icon={light("truck-fast")} /> fast package</small>
                            <Divider type="vertical" />
                        </>
                    }
                    {(book.Layout && book.Layout === "Sequence")
                        ?
                        <small><NwIcon icon={light("file-user")} /> 1 pic x page</small>
                        :
                        <small><NwIcon icon={light("book-open")} /> 2 pics x page</small>
                    }
                </BookLastLine>
            </FlexContainer>
        )
    }

    const getTitle = (book) => {
        const linkedSections = getLinkedWebsites(book)
        const exist = addedPlaylists ? addedPlaylists.find(playlistId => playlistId === book.ID) : false;
        //if (!addedPlaylists) {
            return (
                <BookTitle $fastpackage={book.FastPackage} $exist={exist}>
                    {(linkedSections.length > 0) && <div className="badge" />}
                    {book.CompCard && <NwIcon icon={light("image-polaroid-user")} />}
                    <div className="book-name">{book.Name}</div>
                </BookTitle>
            )
        //}
        // return (
        //     <BookTitle >
        //         {exist && <div className="badge" />}
        //         {book.CompCard && <NwIcon icon={light("image-polaroid-user")} />}
        //         <div className="book-name">{book.Name}</div>
        //     </BookTitle>
        // )
    }

    const getBookAvatar = () => {
        if (isAdding || isRemoving) {
            return (<AvatarLoadingContainer><MiniLoading /></AvatarLoadingContainer>)
        }
        return (<AvatarBg size={80} src={getBookCover(book)} check={isInPackage} />)
    }

    return (
        <ListItem
            $isDrawer={isDrawer}
            actions={packageEditView ? actionsPackageEdit(book) : !readOnly && actions(book)}
            className="ant-list-item-top"
            style={{ padding: ".5rem 0" }}>
            <List.Item.Meta
                onClick={() => {
                    if (packageEditView) {
                        packageEditOnClick(book)
                    } else {
                        showBookPreview(book)
                    }
                }}
                style={{ minHeight: "5.5rem", overflow: "hidden" }}
                avatar={getBookAvatar()}
                title={getTitle(book)}
                description={getDescription(book)}
            />
        </ListItem>
    )
}

export default BookItem;
