import React, { useContext } from 'react';
import ls from 'local-storage';
import Axios from 'axios';

import { ListContext } from 'Contexts/ListContext';
import { queryClient } from 'QueryClientProvider';
import { readUserLogs, setLsLogs } from 'Libs/NwLogs';

//Set the default Status at application start
const USER_DEFAULT_STATE = {
	currentUser: null
};

const UserContext = React.createContext(USER_DEFAULT_STATE);

const useUserPermission = (policyName, userIds, isMine) => {
	const { currentUser } = useContext(UserContext);
	const userPolicies = currentUser.UserProfile.Policies;
	const policyObject = userPolicies && userPolicies.find(policy => policy.Name.toLowerCase() === policyName.toLowerCase());
    if (policyObject && policyObject.LevelName) {
        const policyLevel = policyObject.LevelName;
        if (policyLevel === "All") return true;
        if (policyLevel === "Personal") {
            if (isMine) {
                return true
            }
            if (userIds && userIds.length) {
                if (userIds.find(userId => userId === currentUser.UserID)) {
                    return true;
                }
            }
            return false
        }
        return false;
    }
}

//Create an exportable consumer that can be injected into components
const UserContextConsumer = UserContext.Consumer;


// Create the provider using a traditional React.Component class
class UserContextProvider extends React.Component {

	constructor(props) {
		super(props);

		const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
		this.state = {
			...USER_DEFAULT_STATE,
			currentUser,
			//Metodi
			Login: this.login,
			Logout: this.logout,
			ForceClientLogout: this.forceclientlogout,
			UpdateCurrentUser: this.updateCurrentUser,
			UpdateUserCover: this.updateUserCover,
			RemoveLocalStorage: this.removelocalstorage
		};

	}

	updateUserCover = () => {
		const { cachedList } = this.context;
		const user = cachedList.users.items.find(usr => usr.ID === this.state.currentUser.UserID);
		const newCurrentUserInfo = {
			...this.state.currentUser,
			CoverGuid: user.CoverGuid
		}
		this.setState({ currentUser: newCurrentUserInfo });
		sessionStorage.setItem('currentUser', JSON.stringify(newCurrentUserInfo));
	};

	updateCurrentUser = userInfo => {
		this.setState({ currentUser: userInfo });
		sessionStorage.setItem('currentUser', JSON.stringify(userInfo));
	};

	login = async (currentUser, from) => {
		//Metto il loading a true e carico il currentUser
		this.setState(() => ({ currentUser }), () => {  })

		try {
			const tenantConfigurations = await Axios.get('/tenantconfigurations');
			ls.set('nw_tenant_configurations', tenantConfigurations);
		} catch (error) {
			console.log('ant : Get Tenant Configurations Error => ', error);
		}
	}

	logout = () => {
		Axios.post(`/account/logoff/${this.state.currentUser.UserID}`)
			.then(response => {
				sessionStorage.clear()
				ls.clear()
				queryClient.clear()
				this.setState(() => ({ currentUser: null }), () => { console.log("logout", this.state) })
			})
			.catch(error => {
				console.log("error on logoff", error);
			})
	}

	removelocalstorage = () => {
		var logsInStorage = readUserLogs()
		ls.clear();
		if (logsInStorage && logsInStorage.length > 0) {
			setLsLogs(logsInStorage)
		}
	}

	forceclientlogout = () => {
		const { cachedList } = this.context;
		sessionStorage.removeItem('currentUser');
		sessionStorage.removeItem('model_tabs_state');
		sessionStorage.removeItem('customer_tabs_state');
		sessionStorage.removeItem('model_agencies');
		sessionStorage.removeItem('model_statements');
		sessionStorage.removeItem('customer_statements');
		sessionStorage.removeItem('model_job_stats');
		ls.remove('nw_cachedlist_cities');
		ls.remove('selectedEvent');
		Object.keys(cachedList).map(key => {
			ls.remove(`nw_cachedlist_${key}`);
			ls.remove(`nw_cachedlist_${key}_lastupdate`);
			return null;
		})
		ls.remove('nw_config_prefetch');
		queryClient.clear();
		this.setState(() => ({ currentUser: null }), () => { console.log("logout", this.state) })
	}

	render() {
		return (
			<UserContext.Provider value={this.state}>
				{this.props.children}
			</UserContext.Provider>
		)
	}
}

UserContextProvider.contextType = ListContext;
export { UserContext, UserContextProvider, UserContextConsumer, useUserPermission }

