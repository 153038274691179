import React from 'react';
import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

const MultiSelect = ({value, onChange, disabled, dataSource}) => {
    return (
        <TreeSelect
            showSearch
            style={{ width: 250 }}
            value={value}
            placeholder="Please select"
            allowClear
            multiple
            treeDefaultExpandAll
            onChange={onChange}
            filterTreeNode={(search, item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
        >
            {dataSource && dataSource.map(data => {
                return (
                    <TreeNode value={data.ID} title={`${data.N} ${data.S}`} key={data.ID} />
                );
            })}
        </TreeSelect>
    );

}
export default MultiSelect;
