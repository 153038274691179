import React from 'react'
import { Segmented } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomSwitch from 'Components/Gui/CustomSwitch';

import styled from 'styled-components';



const CalendarFooterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

    .calendar-footer-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .calendar-footer-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`


const CalendarFooter = ({
    fixedFooter,
    type,
    previewMode,
    viewCalendar,
    filters,
    onSetFilters,
    onOpenSendAgendaDrawer,
    onChangeParams
}) => {

    const handleToggleCancelled = (checked) => {
        onSetFilters({
            ...filters,
            hideCancelled: checked
        });
    }

    const handleBilling = (value) => {
        const hideBilling = value === 'to-bill' ? true : false;
        onSetFilters({
            ...filters,
            hideBilling
        });
    }

    const handleAcknowledgement = (value) => {
        onSetFilters({
            ...filters,
            filterAcknowledgement: value
        });
    }

    const handleMine = (value) => {
        onSetFilters({
            ...filters,
            filterMine: value
        });
    }

    const getCalendarFooterContent = () => {
        return (
            <CalendarFooterContainer>
                {(previewMode || !viewCalendar)
                    ?
                    null
                    :
                    <>
                        <div className="calendar-footer-left">
                            <FlexContainer>
                                <CustomSwitch
                                    value={filters.hideCancelled}
                                    size="small"
                                    text='hide cancelled'
                                    onChange={handleToggleCancelled}
                                />
                                <Segmented
                                    style={{ marginLeft: "2rem" }}
                                    value={filters.filterAcknowledgement}
                                    onChange={handleAcknowledgement}
                                    size='small'
                                    options={[
                                        { label: 'all', value: 'all' },
                                        { label: 'checked', value: 'checked' },
                                        { label: 'to check', value: 'tocheck' }
                                    ]}
                                />
                                <Segmented
                                    style={{ marginLeft: "2rem" }}
                                    value={filters.filterMine}
                                    onChange={handleMine}
                                    size='small'
                                    options={[
                                        { label: 'all', value: 'all' },
                                        { label: 'mine only', value: 'mine' }
                                    ]}
                                />
                                <Segmented
                                    style={{ marginLeft: "2rem" }}
                                    value={filters.hideBilling ? 'to-bill' : 'all'}
                                    onChange={handleBilling}
                                    size='small'
                                    options={[
                                        { label: 'all', value: 'all' },
                                        { label: 'jobs to bill', value: 'to-bill' }
                                    ]}
                                />
                            </FlexContainer>
                        </div>
                        <div className="calendar-footer-right">
                            {(type !== 'model') &&
                                <NwButton ghost icon={light("filter-list")} size="small" label="change filters" onClick={onChangeParams} />
                            }
                            {type === 'model' &&
                                <NwButton ghost icon={light("share")} size="small" label="send agenda" onClick={onOpenSendAgendaDrawer} />
                            }
                        </div>
                    </>
                }
            </CalendarFooterContainer>
        )
    }

    if (fixedFooter) {
        return (
            <div className="calendar-fixed-footer">
                {getCalendarFooterContent()}
            </div>
        )
    } else {
        return getCalendarFooterContent()
    }
}

export default CalendarFooter