import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { List, Card } from 'antd';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import EmptyState from 'Components/Gui/EmptyState';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import { Loading } from 'Components/Gui/Loading';
import { NwAddButton } from 'Components/Gui/NwButton';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import ContractCreationDrawer from 'Components/Project/ProjectContracts/ContractCreationDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import PdfPrintDrawer from 'Components/PdfPrintDrawer/PdfPrintDrawer';
import NwDrawer from 'Components/Gui/NwDrawer';

const DocumentItemThumbStyle = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    svg {
        width: 40px !important;
        height: auto;
    }
`;

const Wrapper = styled.div`
    margin-bottom: 16px;
    .ant-card-body-content {
        padding: 0 !important;
    }
`;

const GridCard = styled(Card.Grid)`
    float: left; //added because ant removed it to use flex- to update later
    width: 25%;
    height: 140px;
    @media only screen and (max-width: 1600px) {
        width: 33.33%;
    }
    @media only screen and (max-width: 1200px) {
        width: 50%;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
    width: ${props => props.fluid && '50%'};

    .ant-list-item-meta {
        width: 100%;
    }
`;

const ListItemMeta = styled(List.Item.Meta)`
    padding: 0;
    cursor: pointer;
    &:hover {
        background-color: ${props => !props.$disablehover && props.theme.SelectedBackgroundColor};
        .document-image {
            color: ${props => !props.$disablehover && props.theme.PrimaryColor};
        }
    }
`;

const ConfirmedLabel = styled.div`
    color: ${props => props.theme.DangerColor};
`;

const ProjectContracts = ({ projectId, fluid }) => {
    const [loading, setLoading] = useState(false);
    const [contracts, setContracts] = useState([]);
    const breakpoints = useNwBreakPoints();
    const [addContract, setAddContract] = useState(false);
    const [editContractDrawer, setEditContractDrawer] = useState(false);
    const [selectedContract, setSelectedContract] = useState();

    const fetchContracts = async () => {
        try {
            const contracts = await Axios.post('/docVersions/getFiltered', {
                ProjectID: projectId,
                Area: "ProjectContract"
            });
            setContracts(contracts);
            setLoading(false);
        } catch (error) {
            console.log('ant : fetch contracts error => ', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if (projectId) {
            fetchContracts();
        }
    }, [projectId]);

    const onDeleteContract = contract => {
        Axios.delete(`/docVersions/${contract.ID}`)
            .then(() => {
                fetchContracts();
            })
            .catch(error => {
                console.log("Errore", error);
            });
    };

    const getDescription = contract => {
        return (
            <div>
                <CreatedBy object={contract} short textonly />
                {contract.Confirmed &&
                    <ConfirmedLabel>Confirmed</ConfirmedLabel>
                }
            </div>
        )
    };

    const onOpenEditContractDrawer = contract => {
        setSelectedContract(contract)
        setEditContractDrawer(true)
    };

    const onCloseEditContractDrawer = (contract) => {
        setSelectedContract(null)
        setEditContractDrawer(false)
        setLoading(true)
        fetchContracts()
    };

    const handleAddContract = () => {
        setAddContract(true);
    };

    const handleCloseContractDrawer = () => {
        setAddContract(false);
    }

    const actions = (contract) => {
        return (
            <div>
                <NwDropDownMenu
                    actions={[
                        { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => onOpenEditContractDrawer(contract)) },
                        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => onDeleteContract(contract)) }
                    ]}
                />
            </div>
        );
    }

    return (
        <Wrapper>
            <NwCard
                title='Contracts'
                padding='no-padding'
                extra={<NwAddButton ghost onClick={handleAddContract} />}
            >
                {loading ? <Loading textBlack />
                    :
                    <>
                        {contracts.length > 0 ?
                            <div>
                                {contracts.map(contract => (
                                    <GridCard fluid={fluid} key={`contract-${contract.ID}`}>
                                        <ListItemMeta
                                            $disablehover
                                            avatar={
                                                <DocumentItemThumbStyle className="document-image">
                                                    <NwIcon icon={light('file-contract')} />
                                                </DocumentItemThumbStyle>
                                            }
                                            title={(
                                                <FlexContainer justifyContent='space-between'>
                                                    <div className='title'>{contract.Area}</div>
                                                    {actions && breakpoints !== 1 && actions(contract)}
                                                </FlexContainer>
                                            )}
                                            description={
                                                <div>
                                                    {getDescription(contract)}
                                                    {breakpoints === 1 &&
                                                        <FlexContainer mg='12px 0 0'>
                                                            {actions(contract)}
                                                        </FlexContainer>
                                                    }
                                                </div>
                                            }
                                        />
                                    </GridCard>
                                ))}
                            </div>
                            : <EmptyState message="No contracts" />
                        }
                    </>
                }
                {addContract &&
                    <ContractCreationDrawer
                        projectId={projectId}
                        onClose={handleCloseContractDrawer}
                    />
                }
                {editContractDrawer &&
                    <NwDrawer
                        onClose={onCloseEditContractDrawer}
                        padding="0px"
                        title="Edit Contract"
                        visible={true}
                        whiteBg={false}
                        width="80vw"
                        maxWidth={"1024px"}
                    >
                        <PdfPrintDrawer docVersion={selectedContract} saveButton onClose={onCloseEditContractDrawer} />
                    </NwDrawer>
                }
            </NwCard>
        </Wrapper>
    );
};

export default ProjectContracts;