import React from "react"
import { Progress } from "antd"
import StyleVariables from "Components/Gui/StyleVariables"
import styled from "styled-components"
import { NwTag } from "Components/Gui/NwTag"
import NwIcon from "Components/Gui/NwIcon"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const StyledProgress = styled(Progress)`
    .ant-progress-text {
        width: auto;
        font-size: ${props => props.theme.FontSizeSm};
        font-weight: normal;
        color: ${props => props.theme.TextColorMuted};
        margin-left: .25rem;

        span.steps-text {
            font-size: ${props => props.theme.FontSizeXSm};
        }
    }
`

export const CheckListProgress = ({ checkListStatus }) => (
    <StyledProgress 
        percent={checkListStatus.percent} 
        steps={checkListStatus.total} 
        size="small" 
        strokeColor={StyleVariables.SuccessColor} 
        trailColor={StyleVariables.DangerColor}
        format={() => {
            if (checkListStatus.complete) {
                return (
                    <NwIcon  icon={solid("check-circle")} color={StyleVariables.SuccessColor} />
                )
            } else {
                return (
                    <span className="steps-text">{checkListStatus.text}</span>
                )
            }
        }}
    />
)

export const CheckListTag = ({ checkListStatus }) => (
    <NwTag type={checkListStatus.complete ? 'success' : 'danger'} style={{ marginLeft: ".5em" }}>{checkListStatus.text}</NwTag>
)