import React, { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
import { getUserCover } from 'Libs/NwUtils';
import { Avatar, Tooltip } from 'antd';
import styled from 'styled-components';
// import StyleVariables from 'Components/Gui/StyleVariables.js';

const NwAvatar = styled(Avatar)`
	&.ant-avatar {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border: none;
		
		background: ${props => props.theme.PrimaryDark};

		.ant-avatar-string {
			line-height: 40px;
			font-weight: 600;
		}

		img {
			border-radius: 0;
			border: none;
			/* width: 40px;
			height: 40px; */
		}
	}
	
`

const UserIcon = ({
	userId, 
	coverGuid, 
	letters,
	size,
	shape,
	userObject
}) => {
	const { cachedList } = useContext(ListContext)
	
	
	const getUserFullName = () => {
		if (userObject) {
			let n = userObject.Name
			if (userObject.Surname) {
				n += ' ' + userObject.Surname
			}
			return n
		}
		return ''
	}
	
	const renderIconPicture = (cg) => (
		<NwAvatar src={getUserCover(cg)} size={size} shape={shape} />
	)
	
	const renderIconLetters = (l) => (
		<NwAvatar size={size} shape={shape}>{l}</NwAvatar>
	)

	const getInitials = (user) => {
		let l = user.Name.toUpperCase()[0]
		if (user.Surname && user.Surname.length) {
			l += user.Surname.toUpperCase()[0]
		}
		return l
	}
	
	
	if (coverGuid) {
		return renderIconPicture(coverGuid)
	}
	if (letters) {
		return renderIconLetters(letters)
	}
	if (userObject) {
		if (userObject.CoverGuid) {
			return renderIconPicture(userObject.CoverGuid)
		} else {
			return renderIconLetters(getInitials(userObject))
		}
	}
	if (userId) {
		const myuser = cachedList.users.items.find(usr => usr.ID === userId)
        if (myuser) {
            if (myuser.CoverGuid) {
                return renderIconPicture(myuser.CoverGuid)
            } else {
				return renderIconLetters(getInitials(myuser))
            }
        }
	}
	return renderIconLetters("...")
}
export default UserIcon;