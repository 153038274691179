import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import { List } from 'antd';
import { light, solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import { NwAddButton } from 'Components/Gui/NwButton';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import EmptyState from 'Components/Gui/EmptyState';
import AddEditUsageDrawer from 'Components/Project/ProjectTransactions/Usages/AddEditUsageDrawer';
import { forceReloadProject, useProject } from 'Hooks/Project/UseProject';
import { ListContext } from 'Contexts/ListContext';
import Axios from 'axios';
import { showPeriodByDay } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';

const NwStyledCard = styled(NwCard)`
    .ant-list {
        margin-right: 1rem;
    }
    .ant-list-item {
        align-items: flex-start;
    }
    
    .usages-block {
        width: 100%;
        padding-left: 1.5rem;
        .usages-type, .usages-area, .usages-period, .usages-duration {
            margin: .25rem 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon-wrapper {
                margin-right: .5rem;
                width: 2rem;
                text-align: center;
                font-size: 1.1rem;
            }
        }
        p {
            margin: .5rem;
            font-size: 1.1rem;
            font-weight: 400;
        }
    }
`;

const ProjectUsagesCard = ({readOnly, projectId}) => {
    const { data: currentProject } = useProject(projectId);
    const [showNewUsage, setShowNewUsage] = useState(false);
    const { cachedList, GetCountry } = useContext(ListContext);
    const [usage, setUsage] = useState(null);
    
    const getCountryName = code => {
        const country = GetCountry(code);
        return country ? country.Name : code;
    }

    const RenderAddButton = () => {
        return (
            <NwAddButton
                ghost
                size='small'
                label='create usage'
                onClick={() => {
                    setUsage(null);
                    setShowNewUsage(true)
                }}
            />
        );
    };

    const deleteUsage = async (usage) => {
        try {
            await Axios.delete(`/Usages/${usage.ID}`);
            await forceReloadProject(projectId);
        } catch (e) {

        }
    }

    const actions = (usage) => {
        return [
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => {
                        setUsage({
                            ...usage,
                            UsageTypes: cachedList.usageTypes.items.filter(item => usage.UsageTypes.find(el => item.Name === el))
                        });
                        setShowNewUsage(true);
                    }},
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => deleteUsage(usage))}
                ]}
            />
        ];
    }

    return (
        currentProject 
        ?
            <NwStyledCard 
                //Style={{marginTop: 8, width: "calc(50% - 5px)"}}
                title='Usages'
                extra={!readOnly && RenderAddButton()}
                icon={light("tv-retro")}
            >
                {currentProject.Usages.length > 0 ?
                    <List
                        itemLayout="horizontal"
                        dataSource={currentProject.Usages}
                        renderItem={usage => {
                            return (
                                <List.Item actions={!readOnly && actions(usage)} className="ant-list-item-top">
                                    <div className="usages-block">
                                        <div className="usages-type">
                                            <div className="icon-wrapper">
                                                <NwIcon icon={regular("broadcast-tower")} />
                                            </div>
                                            {usage.UsageTypes.map((type, index) =>
                                                <Tag key={index} color={StyleVariables.PrimaryColor}>{type}</Tag>
                                            )}
                                        </div>
                                        <div className="usages-area">
                                            <div className="icon-wrapper">
                                                <NwIcon icon={regular("globe")} />
                                            </div>
                                            {usage.Areas.map((area, index) =>
                                                <div key={index}><strong>{index ? `, ${getCountryName(area)}`: getCountryName(area) }</strong></div>
                                            )}
                                        </div>
                                        <div className="usages-duration">
                                            <div className="icon-wrapper">
                                                <NwIcon icon={regular("hourglass-start")} />
                                            </div>
                                            <div>{usage.Duration}</div>
                                        </div>
                                        <div className="usages-period">
                                            <div className="icon-wrapper">
                                                <NwIcon icon={regular("calendar")} />
                                            </div>
                                            <div>{showPeriodByDay(usage.StartDate, usage.ExpirationDate)}</div>
                                        </div>
                                        <p>{usage.Description}</p>
                                    </div>
                                </List.Item>
                            )}
                        }
                    />
                    : <EmptyState 
                        message="No usages" />
                }
                {showNewUsage && 
                    <AddEditUsageDrawer
                        projectId={projectId}
                        usageTypes={cachedList.usageTypes.items}
                        usage={usage}
                        onUpdate={async () => {
                            await forceReloadProject(projectId);
                        }}
                        onClose={() => setShowNewUsage(false)} />
                }
            </NwStyledCard>
        :
            <div></div>
    );
}

export default ProjectUsagesCard;