import React from 'react';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import useEventStyle from 'Hooks/UseEventStyle';
import { showDateNoTime, showDate, isAllDay } from 'Libs/NwMoment';

export const EventFieldsTable = styled.table`
    width: 100%;
    tr {
        display: flex;
    }
    td {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: .65rem;

        .icon-container {
            padding-right: 1rem;
            svg {
                font-size: 2.4rem;
                margin: 0 0 8px;
            }
        }
        
        svg {
            font-size: .95rem;
            margin: 4px 0;
        }

        .label {
            line-height: 1;
            margin-top: 8px;

            &:first-child {
                margin-top: 0;
            }
        }
        .description {
            font-size: .95rem;
        }
        .content {
            font-size: .95rem;
            font-weight: bold;
        }

        &:first-child {
            padding-left: 0;
            flex: unset;
        }
        &:last-child {
            border-right: none;
        }
    }
`;

const NoticeDetails = ({ noticeData }) => {
    const noticeStyle = useEventStyle({}, "Notice");

    return (
        <FlexContainer pd='16px 30px 16px 40px'>
            <EventFieldsTable>
                <tbody>
                    <tr>
                        <td>
                            <div className='icon-container'>
                                <NwIcon icon={noticeStyle.icon} />
                            </div>
                        </td>
                        <td>
                            <>
                                <div className='label'>Privacy Level</div>
                                <div className='content'>{noticeData.PrivacyLevelName}</div>
                            </>
                            <>
                                <div className='label'>Visible To Models</div>
                                <div className='content'>{noticeData.ModelVisibility ? 'Yes' : 'No'}</div>
                            </>
                            {noticeData.Description && !noticeData.GroupGuid &&
                                <>
                                    <div className='label'>Description</div>
                                    <div className='description'>{noticeData.Description}</div>
                                </>
                            }
                        </td>
                        <td>
                            {showDateNoTime(noticeData.StartDate) !== showDateNoTime(noticeData.EndDate) ?
                                <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                    <FlexContainer column justifyContent='center' alignItems='center'>
                                        <div className='content'>
                                            {isAllDay(noticeData.StartDate, noticeData.EndDate) ? 
                                                showDateNoTime(noticeData.StartDate) : showDate(noticeData.StartDate)}
                                        </div>
                                        <NwIcon icon={light('arrow-down')}  />
                                    </FlexContainer>
                                    <div className='content'>
                                        {isAllDay(noticeData.StartDate, noticeData.EndDate) ? 
                                            showDateNoTime(noticeData.EndDate) : showDate(noticeData.EndDate)}
                                    </div>
                                </FlexContainer>
                            : 
                                <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                    <div>{showDateNoTime(noticeData.StartDate)}</div>
                                    {isAllDay(noticeData.StartDate, noticeData.EndDate) ? 
                                        <div className='content'>All Day</div>
                                        :
                                        <>  
                                            <FlexContainer column justifyContent='center' alignItems='center'>
                                                <div className='content'>{getMoment(noticeData.StartDate).format('HH:mm')}</div>
                                                <NwIcon icon={light('arrow-down')} />
                                            </FlexContainer>
                                            <div className='content'>{getMoment(noticeData.EndDate).format('HH:mm')}</div>
                                        </>
                                    }
                                </FlexContainer>
                            }
                        </td>
                    </tr>
                </tbody>
            </EventFieldsTable>
        </FlexContainer>
    );
};

export default NoticeDetails;