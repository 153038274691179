import React, { useState, useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from 'antd';
import GooglePlaces from 'Components/GooglePlaces/GooglePlaces';
import NwIcon from 'Components/Gui/NwIcon';
import AddressForm from "Components/Gui/NWForm/AddressForm";
import AddressesByProject from './AddressesByProject'
import { ListContext } from 'Contexts/ListContext';
import { useContactConnections } from 'Hooks/Contact/UseContactConnections';
import AddressFromContacts from 'Components/Gui/NWForm/LocationSelector/AddressFromContacts';
import ContactSelector from 'Components/ContactSelector/ContactSelector';
import FlexContainer from 'Components/Gui/FlexContainer';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const { Option } = Select;

const SelectContainer = styled.div`
  padding: 20px 0px;
`;

const SelectTypeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const searchTypes = {
    event: 'event',
    contact: 'contact',
    google: 'google',
}

let searchOptions = [
    {
        value: searchTypes.event,
        label: 'on event'
    },
    {
        value: searchTypes.contact,
        label: 'on contact'
    },
    {
        value: searchTypes.google,
        label: 'on google'
    },
]

const LocationSelector = ({ eventType = 'event', value, eventData, projectCustomerId, customerId, onChange, onBlur }) => {
    const [selectedOption, setSelectedOption] = useState();
    const [contactId, setContactId] = useState(eventData ? (eventData.EventClientID || eventData.Project.CustomerID) : customerId || projectCustomerId);
    const { data: connections } = useContactConnections(contactId);
    const { cachedList } = useContext(ListContext);
    const countriesList = [...cachedList.countries.items];

    useEffect(() => {
        if (!eventData && customerId) {
            setContactId(customerId);
        }
    }, [eventData, customerId]);

    useEffect(() => {
        if (!eventData && !contactId && projectCustomerId) {
            setContactId(projectCustomerId);
        }
    }, [eventData, contactId, projectCustomerId]);

    useEffect(() => {
        if ((customerId || projectCustomerId) && !selectedOption) {
            setSelectedOption(searchTypes.contact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, projectCustomerId])

    const handleChangeOption = (value) => {
        setSelectedOption(value)
    }

    const handleSetAddress = (address) => {
        const country = countriesList.find(country => country.Code === address.CountryCode);
        if (country) {
            onChange({
                ...address,
                Country: country.Code
            })
        } else {
            onChange(address)
        }
    }

    const handleChangeContact = async contactId => {
        setContactId(contactId);
    };

    searchOptions = searchOptions.filter(option => (option.value !== searchTypes.event || eventType === 'event'));

    let addressCount = 0;
    if (connections) {
        for (const connection of connections) {
            addressCount += connection.Addresses.length;
        }
    }
    return (
        <div>
            <SelectTypeContainer>
                <Select
                    autoComplete='off'
                    placeholder="search addresses"
                    value={selectedOption}
                    style={{ width: 240 }}
                    onChange={handleChangeOption}
                    onBlur={onBlur}
                >
                    {searchOptions.map(searchOption => (
                        <Option value={searchOption.value} key={searchOption.value}>{searchOption.label}</Option>
                    ))}
                </Select>
            </SelectTypeContainer>
            <SelectContainer >
                {
                    selectedOption === searchTypes.google && (
                        <FlexContainer
                            column
                            alignItems='flex-start'
                            mg='0 0 8px'>
                            <GooglePlaces
                                onSetAddress={handleSetAddress}
                                suffix={
                                    <NwIcon icon={light('search')} />
                                } />
                        </FlexContainer>
                    )
                }
                {
                    selectedOption === searchTypes.event && (
                        <AddressesByProject eventData={eventData} onChange={handleSetAddress} />
                    )
                }
                {
                    selectedOption === searchTypes.contact && (
                        <>
                            <ContactSelector value={contactId} activeTypes={['cli', 'age', 'srv']} onChange={handleChangeContact} />
                            <div style={{ padding: !eventData && connections && addressCount > 3 ? '0 40px' : 0 }}>
                                <AddressFromContacts
                                    connections={connections ? connections : []}
                                    onChange={handleSetAddress}
                                />
                            </div>
                        </>
                    )
                }
                <div>
                    <AddressForm value={value} onChange={handleSetAddress} />
                </div>
            </SelectContainer>
        </div>
    )
}

export default LocationSelector;