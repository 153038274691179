import React, { useState } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { usePlaylistPackages, usePlaylistLastPackages } from "Hooks/Playlist/UsePlaylist";
import { Loading } from 'Components/Gui/Loading';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import PackageDrawer from 'Components/PackageDrawer/PackageDrawer';
import ContactAvatarGroup from 'Components/ContactAvatarGroup/ContactAvatarGroup';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { showLog } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';
import EmptyState from 'Components/Gui/EmptyState';
import { NwButton } from 'Components/Gui/NwButton';
import NwDrawer from 'Components/Gui/NwDrawer';

const Container = styled.div`
    .ant-card-body-content {
        padding: 0 !important;
    }

    .ant-card-grid {
        float: left; //added because ant removed it to use flex- to update later
        min-height: 14rem;
        padding: 0;
        width: 100%;
        position: relative;

        .package-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 1rem 1.5rem;
            cursor: pointer;
            

            .package-content {
                padding-top: .5rem;
                h2 {
                    font-size: 1rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin: 0;
                }
                p, div {
                    margin: 0;
                    font-size: .85rem;
                    line-height: 1.2em;
                }
                .package-projectline-container {

                }
            }
            .package-content-bottom {
                opacity: .75;
                font-size: .8rem;
                position: absolute;
                bottom: .5rem;
                right: .5rem;
                text-align: right;
                em {
                    font-style: normal !important;
                }
            }
        }
    }

    .nw-card-paginated-content {
        padding: 0 !important;
    }
    .ant-row-space-between {
        flex-direction: row-reverse;
    }
`;

const IconTextContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: .25rem 0 !important;
    svg {
        min-width: 2rem;
    }
`;

const MediaFullPackages = ({playlistID}) => {
    const { data: packages, isFetching } = usePlaylistPackages(playlistID);
    const [packageID, setPackageID] = useState();
    const [showPackageDrawer, setShowPackageDrawer] = useState(false);

    const getModelIDs = (pack) => {
        const models = pack.Models.map((m) => (m.ModelID))
        return models
    }

    const handleOpenLatestPackage = packageItem => () => {
        setPackageID(packageItem.ID)
        setShowPackageDrawer(true)
    }

    const handleClosePackageDrawer = () => {
        setShowPackageDrawer(false);
        setPackageID();
    }

    const renderPackages = (packages) => {
        return (
            <>
                {packages.map(pack => (
                    <Card.Grid key={`packcard-${pack.ID}`}>
                        <div className="package-container" onClick={handleOpenLatestPackage(pack)} /*key={`pack-${pack.ID}`}*/ >
                            <ContactAvatarGroup contactIDs={getModelIDs(pack)} />
                            <div className="package-content">
                                {pack.Project && <div className="package-projectline-container"><ProjectLine project={pack.Project} ellipsis /></div>}
                                <h2>{pack.Name}</h2>
                                <IconTextContainer><NwIcon icon={light("envelopes")} /><p>sent to: {pack.Recipients.length > 0 ? (pack.Recipients.length > 5 ? `${pack.Recipients.length} recipients` : pack.Recipients.map(recipient => recipient.Email).join(', ')) : 'no recipient'}</p></IconTextContainer>
                                {pack.LastSentDate
                                    ?
                                    <IconTextContainer><NwIcon icon={light("paper-plane")} /><p>sent on: {showLog(pack.LastSentDate)}</p></IconTextContainer>
                                    :
                                    <IconTextContainer><NwIcon icon={light("hourglass")} /><p>not sent</p></IconTextContainer>
                                }
                            </div>
                            <div className="package-content-bottom">
                                <CreatedBy object={pack}/>
                            </div>
                        </div>
                    </Card.Grid>
                ))}
            </>
        )
    }

    return (
        <Container>
            {isFetching?<Loading textBlack/>:renderPackages(packages)}
            {showPackageDrawer && <PackageDrawer
                packageId={packageID}
                onClose={handleClosePackageDrawer} />}
        </Container>
    )
}

const MediaPackages = ({playlistID}) => {
    const { data: packages, isFetching } = usePlaylistLastPackages(playlistID);
    const [showPackageDrawer, setShowPackageDrawer] = useState(false);
    const [packageID, setPackageID] = useState();
    const [showAll, setShowAll] = useState(false);

    const handleOpenLatestPackage = packageItem => () => {
        setPackageID(packageItem.ID)
        setShowPackageDrawer(true)
    }

    const handleClosePackageDrawer = () => {
        setShowPackageDrawer(false);
        setPackageID();
    }

    const getModelIDs = (pack) => {
        const models = pack.Models.map((m) => (m.ModelID))
        return models
    }

    const renderPackages = (packages) => {
        return (
            <>
                {packages.map(pack => (
                    <Card.Grid key={`packcard-${pack.ID}`}>
                        <div className="package-container" onClick={handleOpenLatestPackage(pack)} /*key={`pack-${pack.ID}`}*/ >
                            <ContactAvatarGroup contactIDs={getModelIDs(pack)} />
                            <div className="package-content">
                                {pack.Project && <div className="package-projectline-container"><ProjectLine project={pack.Project} ellipsis /></div>}
                                <h2>{pack.Name}</h2>
                                <IconTextContainer><NwIcon icon={light("envelopes")} /><p>sent to: {pack.Recipients.length > 0 ? (pack.Recipients.length > 5 ? `${pack.Recipients.length} recipients` : pack.Recipients.map(recipient => recipient.Email).join(', ')) : 'no recipient'}</p></IconTextContainer>
                                {pack.LastSentDate
                                    ?
                                    <IconTextContainer><NwIcon icon={light("paper-plane")} /><p>sent on: {showLog(pack.LastSentDate)}</p></IconTextContainer>
                                    :
                                    <IconTextContainer><NwIcon icon={light("hourglass")} /><p>not sent</p></IconTextContainer>
                                }
                            </div>
                            <div className="package-content-bottom">
                                <CreatedBy object={pack}/>
                            </div>
                        </div>
                    </Card.Grid>
                ))}
            </>
        )
    }

    return (
        <Container>
            <NwCard
                icon={light("book-arrow-right")}
                title={(packages && packages.length > 5)?"Last 5 packages":"Packages"}
                extra={(packages && packages.length > 5)?<NwButton size="small" primary ghost onClick={() => setShowAll(true)} label="Show All" />:(null)}
            >
                {isFetching?<Loading textBlack/>:
                    ((packages && packages.length) ?
                        renderPackages(packages.slice(0, 5))
                        :
                        <EmptyState
                            message="No packages"
                        />
                    )
                }
                {showPackageDrawer && <PackageDrawer
                    packageId={packageID}
                    onClose={handleClosePackageDrawer} />}
                {showAll &&
                    <NwDrawer title='Packages' onClose={() => setShowAll(false)}>
                        <MediaFullPackages playlistID={playlistID}/>
                    </NwDrawer>
                }

            </NwCard>
        </Container>
    )
};

export default MediaPackages;