import React from 'react';
import { Formik } from "formik";

import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { getCustomFieldInitialValue, getCustomField } from 'Libs/NwCustomFields';
import { toISODate } from 'Libs/NwMoment';

const CustomFieldsForm = ({ object, customFields = [], onSave, onClose, onTouchForm }) => {
    let initialValues = {};
    const availableCustomFields = customFields.filter(field => !field.Disabled);

    availableCustomFields.map(customField => {
        if (!customField.Disabled) {
            initialValues = {
                ...initialValues,
                [customField.Name]: getCustomFieldInitialValue(customField.TypeName, object.CustomFields[customField.Name]) 
            };
        }
        return null;
    })
    
    const saveCustomFields = async (values, setSubmitting) => {
        let customFieldsData = {};
        Object.keys(values).map(key => {
            if (values[key] !== '' && JSON.stringify(values[key]) !== JSON.stringify([]) && values[key]) {
                const customField = availableCustomFields.find(item => item.Name === key);
                if (customField.TypeName === 'MultipleChoice') {
                    customFieldsData = {
                        ...customFieldsData,
                        [key]: values[key].join(',')
                    }
                } else if (customField.TypeName === 'Date') {
                    customFieldsData = {
                        ...customFieldsData,
                        [key]: toISODate(values[key])
                    }
                } else {
                    customFieldsData = {
                        ...customFieldsData,
                        [key]: values[key]
                    }
                }
            }
            return null;
        })

        setSubmitting(true);
        try {
            await onSave(customFieldsData);
            onTouchForm(false);
            onClose();
            setSubmitting(false);
        } catch (error) {
            console.log('ant : Save Custom Fields Error => ', error);
            setSubmitting(false);
        }
    };
    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                saveCustomFields(values, setSubmitting);
            }}
        >
        {({ isSubmitting, values, handleSubmit }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    {availableCustomFields.map((customField, index) => {
                        return <div key={`${customField.Name}-${index}`}>
                            {getCustomField(values, customField)}
                        </div>
                    })}
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onClose();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default CustomFieldsForm;