import React, { useState } from "react";
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from "Components/Gui/NwIcon";
import { showDateNoTime } from "Libs/NwMoment";

import { useSortContactConnections } from "Hooks/Contact/UseContactConnections"

const SortableList = styled.div`
    opacity: ${props => props.isLoading ? ".25" : "1"};
    position: relative;
    padding: 2rem 1rem;
    cursor: pointer;
`

const SortableListItem = styled.div`
    background: ${props => props.isDragging ? props.theme.PrimaryColor : "#fff"};
    color: ${props => props.isDragging ? "#fff" : props.theme.TextColor };
    border-radius: 6px;
    margin-bottom: 1rem;
    padding: 1rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.5);
    display: flex;
    align-items: center;

    .sortable-list-item-icon {
        margin: 0 2rem 0 0;
    }

    .sortable-list-item-text {
        h6, p {
            padding: 0;
            margin: 0;
        }
    }

`

const ConnectionSorter = ({ connections, contactID }) => {

    const [isSaving, setIsSaving] = useState(false)

    const { mutate: onSortConnections } = useSortContactConnections()

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    
    
    //const grid = 8;

    // const getItemStyle = (isDragging, draggableStyle) => ({
    //     // some basic styles to make the items look a bit nicer
    //     userSelect: "none",
        
    //     // change background colour if dragging
    //     background: isDragging ? "lightgreen" : "white",

    //     // styles we need to apply on draggables
    //     ...draggableStyle
    // })

    const ConnectionCardTitleSecondLine = (item) => {
        let line = item.SubTypeName || ""
        if (item.Role) {
            if (line.length) {
                line += " - "
            }
            line += item.Role
        }
        return line;
    }

    const ConnectionCardTitle = (item) => (
        <>
            <p><strong>{item.Name} {item.Surname || ""}</strong></p>
            <p>{ConnectionCardTitleSecondLine(item)}</p>
            {item.Note && (
                <p>
                    {item.Note}
                </p>
            )}
            {item.ValidFrom && item.ValidTo && (
                <p>
                    <NwIcon icon={light("bed")} />&nbsp;{showDateNoTime(item.ValidFrom)}{" > "}{showDateNoTime(item.ValidTo)}
                </p>
            )}
        </>
    )

    const mapConnections = () => {
        return connections.map((item) => (
            { ID: item.ID, node: ConnectionCardTitle(item) }
        ))
    }

    const [items, setItems] = useState(mapConnections())

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        setIsSaving(true);

        const newitems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        
        const newitemsid = newitems.map((item) => item.ID)

        onSortConnections({newitemsid, contactID})
        setItems(newitems);
        setIsSaving(false);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <SortableList isLoading={isSaving}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items.map((item, index) => (
                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                {(provided, snapshot) => (
                                    <SortableListItem isDragging={snapshot.isDragging}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <NwIcon icon={light("sort")} size="2x" className="sortable-list-item-icon" />
                                        <div className="sortable-list-item-text">
                                            {item.node}
                                        </div>                                        
                                    </SortableListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </SortableList>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default ConnectionSorter;