const emptystatesdrawings = {
    confident: require('./confident.png'),
    martini: require('./martini.png'),
    blushing: require('./blushing.png'),
    shootingstar: require('./shootingstar.png'),
    desertisland: require('./desertisland.png'),
    lighthouse: require('./lighthouse.png'),
    paperplanet: require('./paperplanet.png'),
    weightlifter: require('./weightlifter.png'),
    emptybook: require('./empty-book.png')
}

export default emptystatesdrawings;