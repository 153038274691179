import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { forceReloadContact } from "Hooks/Contact/UseContact";

const editContactAlert = (params) => {
    //Axios.post(`/contacts/setAlert?ContactID=${contactID}&Message=${message}`)
    //params are: ContactID, Message
    const alertdata = {...params}
    Axios.post("/contacts/setAlert", alertdata)
}

const deleteContactAlert = ({contactID}) => (
    Axios.delete(`/contacts/removeAlert/${contactID}`)
)

const useEditContactAlert = () => {
    return useMutation(editContactAlert, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactID)
        }
    })
}

const useDeleteContactAlert = () => {
    return useMutation(deleteContactAlert, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactID)
        }
    })
}


export {
    useEditContactAlert,
    useDeleteContactAlert
}