import React from "react";
import styled from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";

import usePlaceholderImageFixed from "Hooks/UsePlaceholderImageFixed";

const StyledLazyLoadImage = styled(LazyLoadImage)`
    height: auto;
    width: auto;
    max-width: ${props => props.$maxwidth ? `${props.$maxwidth}px !important` : (props => props.$maxheight ? 'none' : '200px')};
    max-height: ${props => props.$maxheight ? `${props.$maxheight}px !important` : (props => props.$maxwidth ? 'none' : '200px')};
    &.model-cover-middle {
        max-width: 80px;
    }
    &.model-cover-small {
        max-width: 40px;
    }
    &.model-cover-xsmall {
        max-width: 30px;
    }
`
const StyledImage = styled.img`
    height: auto;
    width: auto;
    max-width: ${props => props.$maxwidth ? `${props.$maxwidth}px !important` : (props => props.$maxheight ? 'none' : '200px')};
    max-height: ${props => props.$maxheight ? `${props.$maxheight}px !important` : (props => props.$maxwidth ? 'none' : '200px')};
    &.model-cover-middle {
        max-width: 80px;
    }
    &.model-cover-small {
        max-width: 40px;
    }
    &.model-cover-xsmall {
        max-width: 30px;
    }
    &.is-placeholder {
        opacity: .6;
    }
`


const ModelCover = ({ model, classN, maxWidth, maxHeight, ...rest }) => {
    const modelCoverGuid = model ? model.CG : null;
    const modelSubType = model ? model.ST : null;
    const [urlbg, isPlaceholder] = usePlaceholderImageFixed(modelCoverGuid, modelSubType)
    
    if (!isPlaceholder) {
        return <StyledLazyLoadImage className={classN} $maxwidth={maxWidth} $maxheight={maxHeight} alt="Model Cover" src={urlbg} {...rest}  /> //placeholderSrc={}
    } else {
        return <StyledImage className={`${classN} is-placeholder`} $maxwidth={maxWidth} $maxheight={maxHeight} src={urlbg} alt="Model Cover Not Set" {...rest} />
    }
}

export { ModelCover };