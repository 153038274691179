import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import ProjectComment from 'Components/Comments/ProjectComment';
import { NwAddButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import { Loading } from 'Components/Gui/Loading';
import NwDrawer from 'Components/Gui/NwDrawer';
import BookingEventCommentForm from 'Components/EventDrawer/BookingEvent/BookingEventComments/BookingEventCommentForm';
import { groupCommentsByModel } from 'Components/EventDrawer/BookingEvent/BookingEventComments/BookingEventCommentsUtils';
import { useProject, forceReloadProject, onGetOrderedEventList } from 'Hooks/Project/UseProject';
import { useStateValue } from 'Contexts/StateProvider';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { forceReloadProjectComments, useProjectComments } from 'Hooks/Project/UseProjectComments';
import EmptyState from 'Components/Gui/EmptyState';
import Visibilities from 'Components/Gui/Visibilities';

const Wrapper = styled.div`
    min-height: 100%;
    .ant-card {
        min-height: calc(100vh - 252px);
    }
    .ant-card-body-content {
        padding: 0 !important;
    }
`;

const GridCard = styled(Card.Grid)`
    float: left; //added because ant removed it to use flex- to update later
    width: calc(100% / 3);
    height: 15rem;
    
    /* @media only screen and (max-width: 1600px) {
        width: 33.33%;
    } */
    
    @media only screen and (max-width: 1400px) {
        width: 50%;
    }
    
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
    width: ${props => props.fluid && '50%'};


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;

    .grid-card-body {
        flex: 1 1 0;
        padding: 1.5rem 1.5rem .25rem 1.5rem;
        width: 100%;
    }
    .grid-card-footer {
        height: 3rem;
        border-top: ${props => props.theme.DefaultBorder};
        padding: 0 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .ant-list-item-meta {
        width: 100%;
    }
`;

const DocumentBox = styled.div`
    width: 100%;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
    
    .header {
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 0px 10px 24px;
        margin-bottom: 0;
    }
`;

const ProjectComments = ({ projectId, currentProject, modelId }) => {
    const [{ areaContext }] = useStateValue();

    const [isAddComment, setAddComment] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const [groupedComments, setGroupedComments] = useState([]);
    const [generalComments, setGeneralComments] = useState([]);
    const [selectedModelComments, setSelectedModelComments] = useState([]);

    const [orderedEventList, setOrderedEventList] = useState([]);

    //const { data: currentProject } = useProject(projectId);
    const { data: projectComments, isFetching: loadingComments } = useProjectComments(projectId)

    useEffect(() => {
        if (!loadingComments && projectComments.length > 0) {
            setGroupedComments(groupCommentsByModel(projectComments, modelId))
            setGeneralComments(projectComments.filter(t => t.ContactID === null))
            setSelectedModelComments(projectComments.filter(t => t.ContactID === modelId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectComments])

    useEffect(() => {
        if (currentProject) {
            setOrderedEventList(onGetOrderedEventList(currentProject, areaContext))
        }
    }, [currentProject])



    const handleUpdateProjectComment = async () => {
        await forceReloadProjectComments(currentProject.ID)
        await forceReloadProject(currentProject.ID)
    };

    const handleAddComment = () => {
        setAddComment(true);
    };

    const handleCloseAddForm = () => {
        setAddComment(false);
    };

    return (
        <Wrapper>
            <NwCard
                extra={
                    <NwAddButton
                        size='small'
                        ghost
                        onClick={handleAddComment} />
                }
                title='Comments'
                padding='no-padding'
            >
                {loadingComments ? <Loading textBlack />
                    : projectComments ?
                        <FlexContainer
                            fullWidth
                            alignItems='flex-start'
                            column>
                            <DocumentBox>
                                <h3 className="header">General Comments</h3>
                                <div>
                                    {generalComments.map((comment, index) => {
                                        return (
                                            <GridCard key={`comment-${comment.ID}-${index}`}>
                                                <ProjectComment
                                                    comment={comment}
                                                    onUpdate={handleUpdateProjectComment}
                                                    project={currentProject}
                                                    events={orderedEventList}
                                                    modelId={modelId}
                                                    disableVisibility
                                                />

                                            </GridCard>
                                        )
                                    })}
                                </div>
                            </DocumentBox>
                            {Object.keys(groupedComments).map(modelId => (
                                <DocumentBox key={`model-${modelId}`}>
                                    <h3 className="header">{getContactName(+modelId)}</h3>
                                    <div>
                                        {groupedComments[modelId].map((comment, index) => {
                                            return (
                                                <GridCard key={`comment-${comment.ID}-${index}`}>
                                                    <ProjectComment
                                                        comment={comment}
                                                        onUpdate={handleUpdateProjectComment}
                                                        project={currentProject}
                                                        events={orderedEventList}
                                                        modelId={modelId}
                                                        disableVisibility
                                                    />
                                                </GridCard>
                                            )
                                        })}
                                    </div>
                                </DocumentBox>
                            ))
                            }
                        </FlexContainer>
                        : <EmptyState />
                }
            </NwCard>
            {isAddComment &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add comment'
                    onClose={handleCloseAddForm}>
                    <BookingEventCommentForm
                        events={orderedEventList}
                        modelId={modelId}
                        api="ProjectID"
                        project={currentProject}
                        noPadding
                        disableVisibility
                        onSave={save => {
                            if (save) {
                                handleUpdateProjectComment();
                            }
                            handleCloseAddForm();
                        }}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            }
        </Wrapper>
    );
};

export default ProjectComments;