import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { onGetOrderedEventList } from 'Hooks/Project/UseProject';
import EventCard from 'Components/Project/EventCards/EventCard';
import ProjectTimelineEvent from 'Components/Project/ProjectEventsTimeline/ProjectTimelineEvent';
import { useStateValue } from 'Contexts/StateProvider';
import { Loading } from 'Components/Gui/Loading';

const TimeLineContainer = styled.div`
    & > div {
        padding: 0 0 30px 0;
    }
`;

const ProjectEventsTimelineContainer = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  background: #f0f0f0;
  
  padding: 15px 30px 20px 40px;
  
  > div {
    margin: 0px 10px;
    display: inline-block;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    background: white;
  }
  > div:first-child {
    margin-left: 0px;
  }
  > div:last-child {
    margin-right: 0px;
  }
`;

//onMoveToEvent
const ProjectEvents = ({ modelId, projectId, currentProject, openOnEvent }) => {

    const [{ areaContext }] = useStateValue();
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [selectedEventObject, setSelectedEventObject] = useState(null)
    const [projectEvents, setProjectEvents] = useState([])
    const isFetching = false

    const getEventID = (event) => {
        return event.ID || event.GroupedEventIDs[0]
    }

    useEffect(() => {
        if (!isFetching && currentProject) {
            const te = onGetOrderedEventList(currentProject, areaContext, true, false)
            setProjectEvents(te)
        }
    }, [currentProject, isFetching])

    useEffect(() => {
        setSelectedEventObject(null)
    }, [projectId])

    useEffect(() => {
        if (openOnEvent) {
            setSelectedEvent(openOnEvent)
        } else {
            setSelectedEventObject(null)
        }
    }, [openOnEvent])

    useEffect(() => {
        if (projectEvents.length) {
            if (!selectedEvent) {
                const firstevent = projectEvents[0]
                const idfirstevent = getEventID(firstevent)
                setSelectedEvent({ ID: idfirstevent, TypeName: firstevent.elType })
            } else {
                setSelectedEventObject(getEventFromList(selectedEvent.ID, selectedEvent.TypeName))
            }
        }
    }, [projectEvents, selectedEvent])


    const getEventFromList = (eventId, eventType) => {
        const ev = projectEvents.find(item => {
            if (item.elType === eventType) {
                if (item.ID) {
                    return (item.ID === eventId)
                } else {
                    return (item.GroupedEventIDs.indexOf(eventId) >= 0)
                }
            } else {
                return false
            }
        })
        return ev
    }

    const handleMoveToEvent = (eventId, eventType) => {
        const newSelectedEvent = { ID: eventId, TypeName: eventType }
        setSelectedEvent(newSelectedEvent)
    };

    const timelineEventRef = useRef();

    useEffect(() => {
        if (timelineEventRef && timelineEventRef.current) {
            timelineEventRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'nearest'
            });
        }
    }, [timelineEventRef.current, selectedEventObject])

    const isEventSelected = (projectEvent) => {
        if (selectedEventObject) {
            if (projectEvent.TypeName === selectedEventObject.TypeName) {
                if (projectEvent.GroupGuid && selectedEventObject.GroupGuid) {
                    return (projectEvent.GroupGuid === selectedEventObject.GroupGuid)
                }
                if (projectEvent.GroupedEventIDs && selectedEventObject.GroupedEventIDs) {
                    return (_.isEqual(projectEvent.GroupedEventIDs, selectedEventObject.GroupedEventIDs))
                }
                if (projectEvent.ID && selectedEventObject.ID) {
                    return (projectEvent.ID === selectedEventObject.ID)
                }
            }
        }
        return false
    }

    const getEventKey = (event) => {
        return event.uniqID;
    }

    return (
        <>
            <TimeLineContainer>
                <ProjectEventsTimelineContainer>
                    {isFetching
                        ?
                        <Loading />
                        :
                        (projectEvents && projectEvents.length > 0) && projectEvents.map(projectEvent => (
                            <div ref={isEventSelected(projectEvent) ? timelineEventRef : null} key={getEventKey(projectEvent)} >
                                <ProjectTimelineEvent isSelected={isEventSelected(projectEvent)} event={projectEvent} onSelectEvent={handleMoveToEvent} />
                            </div>
                        ))
                    }
                </ProjectEventsTimelineContainer>
            </TimeLineContainer>
            {selectedEventObject &&
                <EventCard
                    projectId={projectId}
                    currentProject={currentProject}
                    event={selectedEventObject}
                    modelId={modelId}
                    onSetLayout={() => { }} />
            }
        </>
    )
};

export default ProjectEvents;