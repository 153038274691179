import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components';
//import { useHistory } from 'react-router-dom';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import NwIcon from 'Components/Gui/NwIcon';
import AgendaContainer from 'Components/Schedule/Agenda/AgendaContainer';
import { useContact } from 'Hooks/Contact/UseContact';
import { useUserPermission } from 'Contexts/UserContext';
//import { getCalendarDefaultDate } from 'Components/Schedule/ScheduleUtils';
import FlexContainer from 'Components/Gui/FlexContainer';
import { Loading } from 'Components/Gui/Loading';
import { getNow, toISODate } from 'Libs/NwMoment';
import useNwBreakPoints from 'Hooks/UseNwBreakPoints';
import { AgendaProvider } from './AgendaContext';
import MobileAgendaContainer from './MobileAgendaContainer';

const LockContainer = styled(FlexContainer)`
    min-height: 320px;
    padding: 16px 0;
    svg {
        font-size: ${props => props.$previewMode ? '80px' : '120px'};
    }
`;

const ScheduleMobileWrapper = styled.div`
    min-height: 100vh;
    padding: 2px 16px;
    background: white;
`;


const ReadOnlyAgenda = ({
    type,
    contactId,
    defaultDate = getNow()
}) => {

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1;
    
    let defaultSchedulePeriod = { start: toISODate(defaultDate), end: toISODate(defaultDate) }
    const [dateSelectedEvents, setDateSelectedEvents] = useState([])
    const [scheduleStartRange, setScheduleStartRange] = useState(defaultSchedulePeriod.start);
    const [scheduleEndRange, setScheduleEndRange] = useState(defaultSchedulePeriod.end);
    const [scheduleRange, setScheduleRange] = useState(null)



    useEffect(() => {
        if (scheduleStartRange && scheduleEndRange) {
            const dateObject = { start: getMoment(scheduleStartRange), end: getMoment(scheduleEndRange) }
            setScheduleRange(dateObject)
        }
    }, [scheduleStartRange, scheduleEndRange])

    const onScheduleRangeChange = useCallback((start, end) => {
        //start and end provided as js date objects
        const startmoment = getMoment(start)
        const endmoment = getMoment(end)
        setScheduleStartRange(toISODate(startmoment))
        setScheduleEndRange(toISODate(endmoment))
    },[])

    const [params, setParams] = useState({
        inclJobs: true,
        inclCastings: true,
        checkConflict: false,
        inclTravels: true,
        inclAccommodations: true,
        inclExternalJobs: true,
        inclFollowUps: true,
        inclMeetings: true,
        inclNotices: true,
        General: false,
        Warning: false,
        idmodels: [contactId]
    })

    const { data: currentContact } = useContact(contactId);
    const viewModelCalendar = useUserPermission('ViewModelCalendar', currentContact && currentContact.Users.map(user => user.UserID));
    const viewCalendar = type === 'model' ? viewModelCalendar : true;

    useEffect(() => {
        setParams({
            ...params,
            idmodels: [contactId]
        })
    }, [contactId])

    const afterReload = useCallback(() => {
        //do nothing
    }, [])
    const handleClearContactId = useCallback(() => {
        //do nothing
    }, [])
    const bookingSection = false
    const forceReload = false
    const onSelectEvent = useCallback(() => {
        //do nothing
    }, [])
    const previewMode = true
    const selectedEvent = null

    const AgendaContextValues = useMemo(() => ({
        afterReload,
        bookingSection,
        contactId,
        forceReload,
        onClearContactID: handleClearContactId,
        onScheduleRangeChange,
        onSelectEvent,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    }), [
        afterReload,
        bookingSection,
        contactId,
        forceReload,
        handleClearContactId,
        onSelectEvent,
        onScheduleRangeChange,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    ]);

    // const renderCardContent = () => {
    //     return viewCalendar ?
    //         (scheduleRange ?
    //             <AgendaContainer
    //                 contactId={contactId}
    //                 params={params}
    //                 scheduleRange={scheduleRange}
    //                 onScheduleRangeChange={onScheduleRangeChange}
    //                 type={type}
    //                 previewMode
    //                 onReload={doNothing}
    //                 afterReload={doNothing}
    //                 onSelectEvent={doNothing}
    //                 reload={reload}
    //                 displayedEvents={dateSelectedEvents}
    //                 onUpdateDateSelectedEvents={setDateSelectedEvents}
    //                 onClearContactID={doNothing}
    //             />
    //             :
    //             <Loading />
    //         )
    //         :
    //         <LockContainer $previewMode justifyContent='center' alignItems='center'>
    //             <NwIcon icon={light('lock-alt')} />
    //         </LockContainer>
    // };

    const getCardMinHeight = () => {
        if (type === 'model') {
            return 580
        }
        return 520
    }

    return (
        <NwCard
            title='Calendar'
            icon={light('calendar')}
            minheight={getCardMinHeight()}
            padding="reduced">
            {isMobile
                ?
                <ScheduleMobileWrapper>
                    {viewCalendar
                        ?
                        (scheduleRange
                            ?
                            <AgendaProvider values={AgendaContextValues}>
                                <MobileAgendaContainer />
                            </AgendaProvider>
                            :
                            <Loading textBlack small paddingTop="2rem" />
                        )
                        :
                        <LockContainer $previewMode justifyContent='center' alignItems='center'>
                            <NwIcon icon={light('lock-alt')} />
                        </LockContainer>
                    }
                </ScheduleMobileWrapper>
                :
                (viewCalendar
                    ?
                    (scheduleRange
                        ?
                        <AgendaProvider values={AgendaContextValues}>
                            <AgendaContainer />
                        </AgendaProvider>
                        :
                        <Loading />
                    )
                    :
                    <LockContainer $previewMode justifyContent='center' alignItems='center'>
                        <NwIcon icon={light('lock-alt')} />
                    </LockContainer>
                )
            }
        </NwCard>
    );
}

export default ReadOnlyAgenda;
