import React, { useState, useEffect } from "react";
import { getMoment, getTime, isSecondTimeEqualOrLater, isSecondTimeLater } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Alert, Tooltip } from "antd";
import { Formik, Field } from "formik";
import { NwCancelButton, NwSaveButton, NwLinkButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import EventDateTime from 'Components/EventDrawer/BookingEvent/AddEventDate/EventDateTime';
import FlexContainer from 'Components/Gui/FlexContainer';
import { useAddEventDate } from 'Hooks/Event/UseEvent';
import { getNow } from "Libs/NwMoment";
import { groupModels, isAddressEmpty } from "../EventDatesAndModels/eventDatesAndModelsUtils";
import { NWTextAreaCharCounter, NWLocationSelector } from "Components/Gui/NWForm/NwFormItems";
import { getEmptyAddress } from "Libs/NwUtils";

const Container = styled.div`
    .ant-legacy-form-item {
        margin-bottom: 16px;
        .ant-legacy-form-item-label {
            line-height: 1.2rem;
        }
    }
`;

const getLastDate = (dateItems, selectedPeriods) => {
    if (dateItems.length === 0) return getNow();
    let lastDate = getMoment(dateItems[0].ToDate);
    for (const dateItem of dateItems) {
        if (getMoment(lastDate) < getMoment(dateItem.ToDate)) {
            lastDate = getMoment(dateItem.ToDate);
        }
    }
    for (const period of selectedPeriods) {
        if (getMoment(lastDate) < getMoment(period[1])) {
            lastDate = getMoment(period[1]);
        }
    }
    lastDate.add(1, 'days');
    return lastDate;
};

const getLastEventModelDate = (event) => {
    const sortedEventDates = [...event.EventDates];
    sortedEventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);
    const lastEventDate = sortedEventDates[sortedEventDates.length - 1];
    const groupedModels = lastEventDate ? groupModels(
        lastEventDate.EventDatesModels,
        { FromDate: lastEventDate.FromDate, ToDate: lastEventDate.ToDate, StartBreak: lastEventDate.StartBreak, EndBreak: lastEventDate.EndBreak, EventAddressID: lastEventDate.EventAddressID }
    ) : []
    if (groupedModels.length > 0) {
        const lastGroupedModel = groupedModels[groupedModels.length - 1];
        return { start: lastGroupedModel.groupingData.FromDate, end: lastGroupedModel.groupingData.ToDate, startBreak: lastGroupedModel.groupingData.StartBreak, endBreak: lastGroupedModel.groupingData.EndBreak, address: (!lastGroupedModel.groupingData.Address || isAddressEmpty(lastGroupedModel.groupingData.Address)) ? {} : lastGroupedModel.groupingData.Address };
    } else {
        return null
    }
}

const ConfirmationStatusList = [
    {
        label: 'Option',
        value: 'Option'
    },
    {
        label: 'Job',
        value: 'Job'
    }
];

const AddEventDate = ({ event, onCancel, onSave, onTouchForm }) => {
    // const { cachedList } = useContext(ListContext);
    // const countriesList = [...cachedList.countries.items];
    const [existingDates, setExistingDates] = useState([]);
    const { mutateAsync: onAddEventDate } = useAddEventDate();
    const [datesList, setDatesList] = useState([{ dateModels: {}, dateAvailableModels: [] }]);
    const [models, setModels] = useState({});

    const lastEventDates = getLastEventModelDate(event);
    
    const [timeErrorMessages, setTimeErrorMessages] = useState([]);

    const [availableModels, setAvailableModels] = useState([]);
    const initialValues = {
        EventDate: '',
        EventTime: [{
            startTime: lastEventDates ? getTime(lastEventDates.start) : [0,0],
            startBreakTime: lastEventDates ? getTime(lastEventDates.startBreak): [0,0],
            endBreakTime: lastEventDates ? getTime(lastEventDates.endBreak) : [0,0],
            endTime: lastEventDates ? getTime(lastEventDates.end) : [23,59]
        }],
        Time: ['newTime'],
        EventAddress: lastEventDates ? lastEventDates.address : getEmptyAddress(),
        Notes: '',
        Periods: lastEventDates ? [[getLastDate(event.EventDates, []), getLastDate(event.EventDates, [])]] : [[getNow(), getNow()]]
        //Confirmed: event.Confirmed === 2 ? 2 : 0
    };

    useEffect(() => {
        let availableModels = [];
        let existingDates = [];
        let models = {};

        let eventDatesModels = [];
        for (const eventDate of event.EventDates) {
            eventDatesModels = [...eventDatesModels, ...eventDate.EventDatesModels];
            existingDates.push(eventDate.FromDate);
        }

        for (const eventDateModel of eventDatesModels) {
            if (!availableModels.find(modelId => modelId === eventDateModel.ModelID)) {
                availableModels.push(eventDateModel.ModelID);

                const sameModels = eventDatesModels.filter(model => model.ModelID === eventDateModel.ModelID);
                if (sameModels.find(model => model.OptionNumber !== 10)) {
                    models[eventDateModel.ModelID] = {
                        checked: false,
                        optionNumber: null,
                        defaultOptionNumber: null
                    }
                } else {
                    models[eventDateModel.ModelID] = {
                        checked: false,
                        optionNumber: 10,
                        defaultOptionNumber: 10
                    }
                }
            }
        }

        setAvailableModels(availableModels)
        setExistingDates(existingDates);
        setModels(models);
        setDatesList([{
            dateModels: models,
            dateAvailableModels: availableModels
        }]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasTime = (time) => {
        return time && Array.isArray(time) && time.length === 2 && (time[0] !== 0 || time[1] !== 0);
    }

    const getData = (date, values, i) => {
        const currentdate = getMoment(date).startOf('day');
        const fromDate = currentdate.clone().hour(values.EventTime[i].startTime[0]).minute(values.EventTime[i].startTime[1]);
        const toDate = currentdate.clone().hour(values.EventTime[i].endTime[0]).minute(values.EventTime[i].endTime[1]);
        let startBreak = null
        let endBreak = null
        if (hasTime(values.EventTime[i].startBreakTime) && hasTime(values.EventTime[i].endBreakTime)) {
            startBreak = currentdate.clone().hour(values.EventTime[i].startBreakTime[0]).minute(values.EventTime[i].startBreakTime[1]);
            endBreak = currentdate.clone().hour(values.EventTime[i].endBreakTime[0]).minute(values.EventTime[i].endBreakTime[1]);
        }

        const modelIds = [];
        for (const modelId of Object.keys(datesList[i].dateModels)) {
            if (datesList[i].dateModels[modelId].checked) {
                modelIds.push({
                    ModelID: parseInt(modelId),
                    OptionNumber: event.TypeName === 'Casting' ? null : datesList[i].dateModels[modelId].optionNumber
                });
            }
        }
        const data = {
            EventID: event.ID,
            ProjectID: event.Project && event.Project.ID,
            Models: modelIds,
            FromDate: fromDate,
            ToDate: toDate,
            StartBreak: startBreak,
            EndBreak: endBreak,
            EventAddress: isAddressEmpty(values.EventAddress) ? undefined : values.EventAddress,
            Notes: values.Notes,
            Confirmed: (event.TypeName === 'Job' && event.Confirmed === 2 && (modelIds.length === 0)) ? 2 : 0
        };
        return data;
    };

    const hasBreak = (values) => {
        if (!values.startBreakTime || !values.endBreakTime) {
            return false;
        }
        if ((values.startBreakTime[0] === 0 && values.startBreakTime[1] === 0) || (values.endBreakTime[0] === 0 && values.endBreakTime[1] === 0)) {
            return false;
        }
        return true;
    }

    const validateTimes = (values) => {
        const timeValidation = {hasErrors: false, errors: []}
        for (const period of values.EventTime) {
            const hasBreakValues = hasBreak(period);
            let timeError = false
            if (hasBreakValues) {
                if (!isSecondTimeEqualOrLater(period.startTime, period.startBreakTime)) {
                    timeError = true
                }
                if (!isSecondTimeLater(period.startBreakTime, period.endBreakTime)) {
                    timeError = true
                }
                if (!isSecondTimeEqualOrLater(period.endBreakTime, period.endTime)) {
                    timeError = true
                }
            } else {
                if (!isSecondTimeEqualOrLater(period.startTime, period.endTime)) {
                    timeError = true
                }
            }
            if (timeError) {
                timeValidation.hasErrors = true
                timeValidation.errors.push('Invalid time range')
            } else {
                timeValidation.errors.push('')
            }
        }
        return timeValidation;
    }

    const saveEventDate = async (values, setSubmitting) => {
        let eventDates = [];

        const timeValidation = validateTimes(values);
        if (timeValidation.hasErrors) {
            setSubmitting(false);
            setTimeErrorMessages([ ...timeValidation.errors ]);
            document.querySelector('.event-edit-drawers .ant-drawer-body').scrollTop = 0;
        } else {
            setTimeErrorMessages([]);
            for (let i = 0; i < datesList.length; i++) {
                const firstDate = getMoment(values.Periods[i][0]).startOf('day');
                const lastDate = getMoment(values.Periods[i][1]).startOf('day');
                //get the number of days between currDate and lastDate
                const days = lastDate.diff(firstDate, 'days');
                //create an array of moment object from currDate to lastDate
                if (days === 0) {
                    eventDates.push(getData(firstDate.clone(), values, i));
                } else {
                    for (let j = 0; j <= days; j++) {
                        eventDates.push(getData(firstDate.clone().add(j, 'days'), values, i));
                    }
                }
            }
            try {
                for (const eventDate of eventDates) {
                    await onAddEventDate({ data: eventDate });
                }
                if (onTouchForm) {
                    onTouchForm(false);
                }
                setSubmitting(false);
                onSave();
            } catch (error) {
                console.log('ant : Add Event Date Error => ', error);
                setSubmitting(false);
            }
        }
    };

    const handleUpdateEventDate = (eventDateId, eventDate) => {
        setDatesList(prevDatesList => {
            const newDatesList = [...prevDatesList];
            newDatesList[eventDateId].dateModels = { ...eventDate.dateModels };
            newDatesList[eventDateId].dateAvailableModels = [...eventDate.dateAvailableModels];
            return newDatesList;
        });
        if (onTouchForm) {
            onTouchForm(true);
        }
    };

    const handleAddEventDate = (setFieldValue, values) => {
        const newDatesList = [...datesList];
        setDatesList([...newDatesList, {
            dateModels: models,
            dateAvailableModels: availableModels
        }]);
        setFieldValue(`Periods`, [...values.Periods,
        [getLastDate(event.EventDates, values.Periods), getLastDate(event.EventDates, values.Periods)]
        ]);
        setFieldValue('EventTime', [...values.EventTime,
        {
            startTime: [0, 0],
            endTime: [23, 59]
        }
        ]);
        setFieldValue('Time', [...values.Time, 'newTime']);
    };

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        saveEventDate(values, setSubmitting);
                    }}
                >
                    {(form) => (
                        <NwForm
                            values={form.values}
                            onTouchForm={onTouchForm}
                            onFinish={form.handleSubmit}
                        >
                            {/* {event.Type === "Job" &&
                            <Field
                                component={NWRadioButtonGroup}
                                name="Confirmed"
                                label='Add as'
                                buttonStyle="solid"
                                options={
                                    ConfirmationStatusList.map(option => {
                                        return { key: option.value, value: option.value, label: option.label };
                                    })
                                }
                            />
                            } */}
                            {datesList.map((periodModels, index) => {
                                return (
                                    <EventDateTime
                                        key={index}
                                        event={event}
                                        periodCount={datesList.length}
                                        periodModels={periodModels}
                                        periodId={index}
                                        values={form.values}
                                        existingDates={existingDates}
                                        setFieldValue={form.setFieldValue}
                                        onUpdateEventDate={handleUpdateEventDate}
                                        errorMessages={timeErrorMessages}
                                    />
                                );
                            })}
                            <FlexContainer justifyContent='flex-end' mg='8px 0 0'>
                                <Tooltip title="Click here to add several dates or periods">
                                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <NwLinkButton icon={light("square-plus")} label='add another date/period' onClick={() => handleAddEventDate(form.setFieldValue, form.values)} />
                                    </div>
                                </Tooltip>
                            </FlexContainer>
                            <Field
                                component={NWLocationSelector}
                                eventData={event}
                                name="EventAddress"
                                value={form.values.EventAddress}
                            />
                            <br />
                            <Field
                                component={NWTextAreaCharCounter}
                                name="Notes"
                                type="text"
                                maxLength={999}
                                autoSize={{ minRows: 2, maxRows: 5 }}
                            />
                            {timeErrorMessages.length > 0 &&
                                (timeErrorMessages.length === 1
                                ?
                                    <Alert type="error" message="Time range is invalid. Please check" showIcon />
                                :
                                    <Alert type="error" message="Some time ranges are invalid. Please check" showIcon />
                                )
                            }
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={form.isSubmitting}
                                        onClick={() => {
                                            onTouchForm(false);
                                            onCancel();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        disabled={form.isSubmitting}
                                        loading={form.isSubmitting}
                                        label="Save"
                                        loadingLabel="Updating..."
                                        onClick={form.handleSubmit}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default AddEventDate;
