import React, { useMemo } from "react";
import { Slider } from "antd";
import styled from 'styled-components';

const NumericSlider = styled(Slider)`
    margin-top: 4px;
    
    &.ant-slider-with-marks {
        margin-bottom: 1rem;
    }
    
    .ant-slider-mark-text {
        font-size: .65rem;
        font-weight: bold;
    }

    .ant-slider-track {
        background-color: ${props => props.disabledSlider && 'rgba(0, 0, 0, 0.25) !important'};
    }
  
    .ant-slider-handle, .ant-slider-dot {
        border-color: ${props => props.disabledSlider && 'rgba(0, 0, 0, 0.25) !important'};
    }
`;

const NumericFilter = ({
    disabled,
    fullWidth,
    maxValue,
    minValue,
    onChange,
    value,
}) => {

    console.log(minValue, maxValue, value);

    const handleChange = val => {
        onChange(val);
    };

    // const filterValue = val => {
    //     const index1 = val ? existingValues.findIndex(el => el === val[0]) : 0;
    //     const index2 = val ? existingValues.findIndex(el => el === val[1]) : existingValues.length- 1;
    //     return [index1, index2];
    // };

    // let marks = {};
    // const interval = Math.round(existingValues.length / 10);
    // existingValues.map((existingValue, index) => {
    //     if ((index % interval === 0 && existingValues.length - index >= interval)
    //         || index === existingValues.length-1) {
    //         marks = {
    //             ...marks,
    //             [index]: existingValue
    //         }
    //     } 
    //     return null;
    // });
    // console.log('marks', marks);

    return (
        <NumericSlider
            min={minValue}
            max={maxValue}
            disabledSlider={disabled}
            range={true}
            step={.1}
            style={{ width: fullWidth?'calc(100% - 20px)':'calc(100% - 200px)' }}
            value={value}
            onChange={handleChange}
            tooltip={
                {
                    getPopupContainer: triggerNode => triggerNode.parentNode,
                    open: true
                }
            }
        />
    )
};

export default NumericFilter;
