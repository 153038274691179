import React, { useState, useMemo } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import { EventDaysHeaderContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ManageModelsAndDates from 'Components/EventDrawer/EventDrawerUi/ManageModelsAndDates';
import NwDrawer from 'Components/Gui/NwDrawer';
import AddModelsToEventDate from '../BookingEvent/AddModels/AddModelsToBookingEvent';
import { forceReloadProject } from 'Hooks/Project/UseProject';

const EventDaysHeader = ({ plainMode, eventData, onAddDays, onEventPlanner, afterUpdate }) => { //onAddModels
    const [isManageModels, setIsManageModels] = useState(false);
    const [showAddModelDrawer, setShowAddModelDrawer] = useState(false);

    const handleManageModels = () => {
        setIsManageModels(true);
    };

    const eventDatesModels = useMemo(() => {
        let eventDatesModels = [];
        for (const eventDate of eventData.EventDates) {
            eventDatesModels = [...eventDatesModels, ...eventDate.EventDatesModels]
        }
        return eventDatesModels;
    }, [eventData]);

    const handleCloseAdd = () => {
        if (eventData.Project && eventData.Project.ID) {
            forceReloadProject(eventData.Project.ID)
        }
        setShowAddModelDrawer(false);
    }

    return (
        <EventDaysHeaderContainer plainMode={plainMode}>
            <NwButton ghost size="small" icon={light('stream')} label='event planner' onClick={onEventPlanner} />
            {eventDatesModels.length > 1 && 
                <NwButton ghost size='small' icon={light('people-group')} label='Models & Dates' onClick={handleManageModels} />
            }
            <div className="buttons">
                <NwAddButton size="small" ghost onClick={onAddDays} label="add days" />
                {/* <NwAddButton size="small" ghost onClick={onAddModels} label="add models" /> */}
                <NwAddButton size="small" ghost onClick={() => setShowAddModelDrawer(true)} label="add models" />
            </div>
            {isManageModels && 
                <NwDrawer
                    padding="0px"
                    onClose={() => setIsManageModels(false)}
                    title='Manage models and dates'>
                    <ManageModelsAndDates eventDatesModels={eventDatesModels} eventData={eventData} afterUpdate={afterUpdate} />
                </NwDrawer>
            }
            {showAddModelDrawer && 
                <AddModelsToEventDate
                    event={eventData}
                    projectId={eventData.Project && eventData.Project.ID}
                    onClose={handleCloseAdd} />
            }
        </EventDaysHeaderContainer>
    )
}

export default EventDaysHeader;