import React, { useEffect, useState, useMemo, useContext } from "react";
import styled from 'styled-components';
import { Segmented } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import TagManager from "Components/TagManager/TagManager";
import AddToFolderButton from "Components/OpenFolders/AddToFolderButton";
import { NwButton } from "Components/Gui/NwButton";
import NwDrawer from 'Components/Gui/NwDrawer';
import ContactUserForm from "Components/ContactUsersDrawer/ContactUserForm";
import { getModel, forceReloadModels, getArea } from "Hooks/Contact/UseContactsList";
import ContactsExporter from 'Components/ContactsExporter/ContactsExporter';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { ListContext } from 'Contexts/ListContext';
import { useUserPermission } from 'Contexts/UserContext';
import { getAge, getModelAge, showDateNoTime, showShortDate } from 'Libs/NwMoment';
import { sortTags, mapTags } from 'Libs/NwUtils';
import { ConfigContext } from 'Contexts/ConfigContext';
import PermissionBlock from "Components/PermissionBlock";
import MiniLoading from "Components/Gui/MiniLoading";
import { queryClient } from "QueryClientProvider";

const FixedFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: ${props => props.isDrawer ? "" : (props.leftMargin ? props.leftMargin : props.size === 'small' ? "200px" : "300px")};
    background-color: #fff;
    padding: 16px 24px;
    box-sizing: border-box;
    z-index: 4;
    width: ${props => props.isDrawer ? "" : (props.leftMargin ? `calc(100vw - ${props.leftMargin}px)` : props.size === 'small' ? 'calc(100vw - 200px)' : 'calc(100vw - 300px)')};
    border: ${StyleVariables.DefaultBorder};
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    flex-wrap: wrap;
    text-transform: uppercase;
    align-items: center;
`;

const FooterItems = styled.div`
    display: flex;
    align-items: center;
`;

const SelectContainer = styled.div`
    margin-left: 16px;
`;

const FolderContainer = styled.div`
    font-weight: normal;
    text-transform: initial;
    margin-left: 8px;
`;

const TagsPopoverHeader = styled.div`
    text-align: center;

    .ant-segmented {
        margin: .5rem 0;
    }
`;

const getFeatures = (FeatNwMain) => {
    if (FeatNwMain) {
        const features = FeatNwMain ? FeatNwMain : [];
        // Filter and order Main Features depending on User Metrics Config
        let filteredFeatures = [];
        for (const item of features) {
            for (const feature of item.Features) {
                filteredFeatures.push(feature);
            }
        }
        return filteredFeatures;
    }
    return []
}

export const ModelsWallFooter = ({
    models,
    selectedList,
    leftMargin,
    multiSelection,
    onSelectAll,
    fixedView,
    addModelProps,
    onAddTag,
    onRemoveTag,
    onClearSelection,
    isDrawer,
    isLoading
}) => {
    const breakpoint = useNwBreakPoints();
    const { userGuiConfig } = useContext(ConfigContext);
    const [showContactUsers, setShowContactUsers] = useState(false);
    const [tagMode, setTagMode] = useState('Add');
    const eventData = addModelProps && addModelProps.eventData;
    const [isAdding] = useState(false);
    const [optionNumber, setOptionNumber] = useState(0);
    const [showDownloadDrawer, setShowDownloadDrawer] = useState(false);
    const [showTagsDrawer, setShowTagsDrawer] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const tagsOnContacts = useUserPermission('TagsOnContacts', []);

    const { cachedList } = useContext(ListContext)
    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    const allTags = useMemo(() => sortTags(mapTags(cachedtags, 'Model')), [cachedtags])

    useEffect(() => {
        if (eventData && eventData.Type === 'Job' && !optionNumber) {
            setOptionNumber(1);
        }
    }, [eventData]);

    const { removableTags, addibleTags } = useMemo(() => {
        //let addibleTags = [];
        //let removableTags = [];
        
        const models = queryClient.getQueryData(['models']);
        const selectedModels = models.filter(model => selectedList.includes(model.ID));
        
        const inAllModels = (tag) => {
            return selectedModels.every(model => model.T.some(modelTag => modelTag.Name.toLowerCase() === tag.Name.toLowerCase()));
        }
        
        const tagNames = selectedModels.map(model => model.T.map(tag => tag.Name.toLowerCase())).flat();
        const removableTags = allTags.filter(tag => tagNames.includes(tag.Name.toLowerCase()));
        const addibleTags = allTags.filter(tag => !inAllModels(tag));
        return { removableTags, addibleTags }
    }, [models, allTags, selectedList]);

    const handleAddUsers = () => {
        setShowContactUsers(true);
    };

    const handleCloseContacUsersDrawer = () => {
        setShowContactUsers(false)
    };

    const handleUpdateContactUsers = () => {
        forceReloadModels()
        setShowContactUsers(false)
    };

    const renderTagHeader = () => {
        return (
            <TagsPopoverHeader>
                <Segmented
                    value={tagMode}
                    onChange={setTagMode}
                    options={[
                        { label: 'Add Tags', value: 'Add' },
                        { label: 'Remove Tags', value: 'Remove' }
                    ]}

                />
            </TagsPopoverHeader>
        )
    };

    const handleOpenDownloadDrawer = () => {
        setShowDownloadDrawer(true);
    };

    const handleCloseDownloadDrawer = () => {
        setShowDownloadDrawer(false);
    };

    const getModelFeatures = features => {
        const filteredFeatures = getFeatures(features ? JSON.parse(features) : null);

        if (filteredFeatures.length) {
            return (
                filteredFeatures.map(({ DisplayName, DisplayValues, FeatureID, ConversionName }, index) => {
                    if (ConversionName) {
                        const conversion = ConversionName && userGuiConfig.metrics.find(metric => metric.name === ConversionName);
                        const displayMetric = conversion && conversion.metrics[0];
                        const displayValue = DisplayValues.find(displayValue => displayValue.metric === displayMetric);
                        return `${DisplayName} ${displayValue ? displayValue.value : DisplayValues[0].value}`;
                    }
                    return `${DisplayName} ${DisplayValues[0].value}`;
                })
            );
        }
        return [];
    };

    const selectedModelsData = useMemo(() => {
        const selectedModels = selectedList.map(modelId => {
            const model = models.find(model => model.ID === modelId);
            if (model) {
                return {
                    ...model,
                    id: model.ID,
                    email: model.E,
                    name: model.N.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                    surname: model.S.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                    age: getAge(model.B),
                    features: getModelFeatures(model.F).join(' • '),
                    tags: model.T.map(tag => tag.Name).join(', '),
                    land: model.P,
                    mobile: model.M,
                    motherAgency: model.MAN,
                    birthdate: model.B ? showShortDate(model.B) : null,
                    nationality: model.NAT
                }
            }
            return null;
        });
        return selectedModels;
    }, [models, selectedList]);

    return (
        <FixedFooter size={breakpoint < 4 ? 'small' : 'medium'} leftMargin={leftMargin} isDrawer={isDrawer}>
            <FooterItems>
                <div>{selectedList.length} Model{selectedList.length > 1 && "s"} selected</div>&nbsp;
                {multiSelection &&
                    <SelectContainer>
                        <NwButton ghost icon={light('check-double')} label='select all' onClick={onSelectAll} loading={isAdding} disabled={isAdding} />
                        &nbsp;
                        <NwButton ghost icon={light('times')} label='clear selection' onClick={onClearSelection} loading={isAdding} disabled={isAdding} />
                    </SelectContainer>
                }
            </FooterItems>
            <FooterItems>
                <>
                    <NwButton
                        disabled={selectedList.length === 0}
                        ghost
                        label='export'
                        icon={light('file-spreadsheet')}
                        onClick={handleOpenDownloadDrawer} />
                    &nbsp;
                    {selectedList.length === 0 ?
                        <NwButton ghost disabled={selectedList.length === 0} icon={light('tag')} label={'tags'} />
                        :
                        tagsOnContacts ?

                            <div onClick={() => setShowTagsDrawer(true)}>
                                <NwButton ghost icon={light('tag')} label={'tags'} />
                            </div>
                            :
                            <PermissionBlock
                                permission={false}
                                content={
                                    <NwButton ghost icon={light('tag')} label={'tags'} />
                                }
                            />
                    }
                    &nbsp;
                    <NwButton disabled={selectedList.length === 0} ghost icon={light('user-circle')} label={'Booker'} onClick={handleAddUsers} />
                    &nbsp;
                    <FolderContainer>
                        <AddToFolderButton ids={selectedList} type='model' placement='topLeft' text='folders' />
                    </FolderContainer>
                    {showTagsDrawer &&
                        <NwDrawer
                            title={renderTagHeader()}
                            onClose={() => { setShowTagsDrawer(false) }}
                        >
                            {isLoading ?
                                <MiniLoading />
                                :
                                tagMode === 'Add'
                                    ?
                                    <TagManager noPopover itemType="Model" addOnly onAddTag={onAddTag} tags={addibleTags} selectedList={selectedList} />
                                    :
                                    <TagManager noPopover itemType="Model" onRemoveTag={onRemoveTag} removeOnly tags={removableTags} selectedList={selectedList} />
                            }
                        </NwDrawer>
                    }
                    {showContactUsers &&
                        <NwDrawer
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            title={'Add Contact User'}
                            onClose={handleCloseContacUsersDrawer}>
                            <ContactUserForm
                                isModel
                                contactUserIds={selectedList}
                                onCancel={handleCloseContacUsersDrawer}
                                onUpdated={handleUpdateContactUsers}
                                onTouchForm={setTouchForm} />
                        </NwDrawer>
                    }
                    {showDownloadDrawer &&
                        <NwDrawer title={'Export Models'} onClose={handleCloseDownloadDrawer}>
                            <ContactsExporter
                                type='model'
                                headers={[
                                    { label: 'Id', key: 'id' },
                                    { label: "Name", key: "name" },
                                    { label: "Surname", key: "surname" },
                                    { label: "E-mail address", key: "email" },
                                    { label: 'Mother Agency', key: 'motherAgency' },
                                    { label: "Age", key: "age" },
                                    { label: "Birthdate", key: "birthdate" },
                                    { label: "Nationality", key: "nationality" },
                                    { label: "Features", key: "features" },
                                    { label: "Tags", key: 'tags' },
                                    { label: 'Land', key: 'land' },
                                    { label: 'Mobile', key: 'mobile' }
                                ]}
                                data={selectedModelsData}
                            />
                        </NwDrawer>
                    }
                </>
                {/* } */}
            </FooterItems>
        </FixedFooter>
    )
};
