import React from 'react';
import styled from 'styled-components';

import { EventModelNameLineNonBooking } from 'Components/EventDrawer/EventDrawerUi/EventModelNameLineNonBooking';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteTravel } from 'Hooks/Event/UseTravel';
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import Axios from 'axios';

const ModelInfo = styled.div`
`;

const TravelModelDetails = ({ 
    afterRemove,
    afterUpdate, 
    event,
    onEditModelEvent,
    onUpdateTravels,
    onTouchForm,
    openedEventID,
    plainMode,
}) => {
    const { mutateAsync: onDeleteTravel } = useDeleteTravel();
    
    const handleRemoveEvent = async () => {
        try {
            await onDeleteTravel({ ID: event.ID, travelId: openedEventID })
            onUpdateTravels()
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleUnlinkEvent = async () => {
        try {
            await Axios.put(`/travels/UnLink/${event.ID}`)
            onUpdateTravels()
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    };

    const modelNameLineProps = {
        actions:  {
            onEdit: onEditModelEvent,
            onDelete: (async () => {
                await handleRemoveEvent()
                if (event.ID === openedEventID) {
                    afterRemove()
                } else {
                    onUpdateTravels()
                    afterUpdate()
                }
            }),
            onUnlink: (() => handleUnlinkEvent()),
            onAcknowledgmentUpdate: (() => {
                onUpdateTravels()
                afterUpdate()
            }),
            onCancelUpdate: (() => {
                onUpdateTravels()
                afterUpdate()
            }),
        },
        eventProperties: {
            eventType: "Travel",
            isSingle: false,
            hasExport: true
        },
        event: event
    }
    
    return (
        <>
            <ModelInfo>
                <EventModelNameLineNonBooking {...modelNameLineProps} />
            </ModelInfo>
            <EventDetailsWrapper className={event.Cancel ? "is-cancelled" : null}>
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Ticket Number</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{event.TicketID}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Booking Reference</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{event.BookingReference}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Flight/Train Code</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <p className="is-content">{event.TransportReference}</p>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Description</p>
                                ),
                                isBorder: true
                            },
                            {
                                content: (
                                    <div className="is-content small">
                                        <NwEllipsis rows={3} text={event.Description} />
                                    </div>
                                ),
                                isBorder: false
                            }
                        ]}
                    />
            </EventDetailsWrapper>
            <EventCommentsDocuments
                event={event}
                api='TravelID'
                type='Travel'
                contactId={event.ModelID}
                drawerMode={!plainMode}
                fetchEvent={onUpdateTravels} 
                disableTransaction={plainMode || !event.Project}
                onTransactionCreatedOrUpdated={onUpdateTravels}
                onTouchForm={onTouchForm}
            />
        </>
    );
};

export default TravelModelDetails;