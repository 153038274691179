import React from "react";
import styled from 'styled-components';
import { Drawer, Modal } from "antd";
import NwIcon from 'Components/Gui/NwIcon';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const { confirm } = Modal;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-content-wrapper {
        min-width: ${props => props.$minWidth};
        max-width: ${props => props.$maxWidth};
        
        .ant-drawer-content {
            background: ${props => props.$customBgColor};

            .ant-drawer-wrapper-body {
                
                .ant-drawer-header {
                    background-color: ${props => props.$modalDrawer ? "#000" : "inherit"};
                    border-bottom: ${props => props.$modalDrawer ? "none" : "1px solid #f0f0f0"};
                    border-radius: 0;
                    
                    .ant-drawer-close {
                        display: none;
                    }
                    .ant-drawer-title {
                        padding-left: ${props => (props.$fixed || props.$left) ? "0" : "24px"};
                        color: ${props => props.$modalDrawer ? "#fff" : props.theme.TextColor};
                    }

                    &.ant-drawer-header-sticky {
                        position: sticky;
                        z-index: 5;
                        background: white;
                        top: 0;
                        overflow: hidden;
                    }
                    &.ant-drawer-header-noborder {
                        border-bottom: none;
                    }
                }

                .ant-drawer-body {
                    background-color:  ${props => props.$customBgColor ? props.$customBgColor : props.$whiteBg ? "#fff" : props.theme.LayoutBodyBackground};
                    padding: ${props => props.$padding};

                    .drawer-toolbar-container {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        padding: .5rem 0 1rem 0;
                    }

                    .drawer-content {
                        .ant-card {
                            margin: 1em 0;
                            .card-header {
                                margin: 0 16px !important;
                                .title {
                                    font-size: 1.2rem !important;
                                    font-weight: bold !important;
                                }
                                .label {
                                    font-size: 0.8rem !important;
                                }
                            }

                            .ant-card-head {
                                height: unset;
                                overflow: unset;
                                flex: unset;
                            }

                            .ant-card-body-content {
                                .base-item {
                                    ul {
                                        margin: 1em 0 0 0;
                                        padding: 0;
                                        list-style: none;
                                        
                                        li {
                                            margin: .25em 0;
                                        }
                                    }
                                }
                            }
                        }

                        .ant-ribbon {
                            top: 12px;
                            text-transform: uppercase;
                            font-size: .7em;
                            vertical-align: middle;
                            line-height: 2.6em;
                            
                            svg {
                                margin-left: .25em;
                                font-size: 1.5em;
                            }
                        }
                    }

                    &.standard {
                        .ant-card {
                            border-color: #d9d9d9;
                            .ant-card-head {
                                border-color: #d9d9d9;
                            }
                        }
                    }
                    &.preview {
                        .ant-card {
                            margin: 0;
                        }
                        .content-section {
                            margin-top: 0.75rem;
                        }
                        .drawer-header {
                            background: white;
                            display: flex;
                            border: ${props => props.theme.DefaultBorder};

                            .contact-detail {
                                padding: 8px 16px;
                                width: 100%;

                                p {
                                    line-height: 1.1em;
                                    font-size: .85rem;
                                    margin: 0;
                                    padding: 0;
                                }

                                .header-body-contacts-container {
                                    display: flex;
                                    
                                    .header-body-contact {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: flex-end;
                                        align-items: center;

                                        .header-body-contact-content {
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: flex-start;
                                            align-items: center;
                                            flex-wrap: nowrap;

                                            max-width: 20em;
                                            overflow: hidden;
                                            margin-right: 0;
                                            padding: 0 .25em;

                                            cursor: pointer;
                                            &:hover {
                                                background-color: ${props => props.theme.HoverablesBackground};
                                                color: #000;
                                            }

                                            span {
                                                min-width: 2em;
                                                text-align: center;
                                            }
                                            p {
                                                text-transform: uppercase;

                                                &.low {
                                                    text-transform: lowercase;
                                                }
                                            }
                                        }
                                    }
                                }       
                            }
                        }
                        .contact-features-container {
                            border: ${props => props.theme.DefaultBorder};
                            border-left: none;
                            padding: 0.5rem;
                            background: white;
                        }
                        .drawer-header.project {
                            border: none;
                            border-bottom: ${props => props.theme.DefaultBorder};
                            padding: .75em 1em;
                        }
                    }
                }
            }
        }
    }
    
    &.no-title {
        .ant-drawer-header-no-title {
            display: none;
        }
    }
    
    &.max-width-800 {
        .ant-drawer-content-wrapper {
            max-width: 800px;
        }
    }
`;

const DrawerCloseButton = styled.span`
    position: absolute;
    top: .4em;
    left: ${props => props.$placement !== 'left' ? '1em' : 'unset'};
    right: ${props => props.$placement === 'left' ? '1em' : 'unset'};
    z-index: 1040;
    font-size: 1.6em;
    cursor: pointer;
`

const NwDrawer = ({
    bgColor,
    children,
    fixed = false,
    fromLeft,
    fromTop,
    fullpage = false,
    icon,
    maxWidth = "100vw",
    minWidth = "0",
    modalDrawer,
    onClose,
    onTouchForm,
    padding = "1.6rem",
    placement = "right",
    title,
    touchForm,
    visible = true,
    whiteBg = true,
    width = "40rem",
    zIndex = 1040,
    ...props
}) => {
    const breakpoints = useNwBreakPoints();
    const drawerStyle = {}
    if (fromTop) {
        drawerStyle.marginTop = fromTop;
        drawerStyle.height = `calc(100vh - ${fromTop}px)`;
    }
    if (fromLeft) {
        drawerStyle.marginLeft = fromLeft
    }

    const handleClose = () => {
        if (touchForm) {
            confirm({
                title: <div>You can lose your inputs<br />Are you sure to close the drawer?</div>,
                onOk() {
                    if (onTouchForm) {
                        onTouchForm(false);
                    }
                    onClose();
                },
                onCancel() { },
            });
        } else {
            onClose();
        }
    }

    return (
        <StyledDrawer
            $customBgColor={modalDrawer ? "rgba(0, 0, 0, .65)" : bgColor}
            $minWidth={minWidth}
            $maxWidth={maxWidth}
            $padding={padding}
            $left={placement === 'left'}
            $fixed={fixed}
            $modalDrawer={modalDrawer}
            width={breakpoints === 1 ? '100vw' : (fullpage || modalDrawer) ? '90vw' : width}
            placement={placement}
            onClose={handleClose}
            destroyOnClose={true}
            open={visible}
            zIndex={zIndex}
            title={title}
            style={drawerStyle}
            $whiteBg={modalDrawer ? false : whiteBg}
            closable={!fixed} 
            {...props}
        >
            {!fixed &&
                <DrawerCloseButton $placement={placement}>
                    {
                        modalDrawer 
                        ?
                        <NwIcon icon={light("times")} color="#fff" onClick={onClose} />
                        :
                        (placement === 'left' 
                            ? 
                            <NwIcon icon={icon || light("chevron-left")} onClick={onClose} />
                            :
                            <NwIcon icon={icon || light("chevron-right")} onClick={onClose} />
                        )
                    }
                </DrawerCloseButton>
            }
            {children}
        </StyledDrawer>
    )
};

export default NwDrawer;