import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import MediaCover from 'Components/Gui/MediaCover';
import NwDrawer from "Components/Gui/NwDrawer";
import { usePackagePlaylist } from "Hooks/Packages/UsePackagePlaylist";
import { getThumbnailWidth, getVideoCover } from "Libs/NwUtils";

const ListContainer = styled.div`
    margin: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
`;

const MediaGalleryContent = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: "8rem";

    .image-block {
        color: white;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        position: relative;
        margin: .5rem;
        cursor: pointer;
    }

    &.media-gallery-content-large {
        .image-block {
            margin: .75rem;
        }
    }
    &.media-gallery-content-xlarge {
        .image-block {
            margin: .75rem;
        }
    }

    .nw-book-model-picture {
        position: relative;

        span.plus-icon {
            position: absolute;
            top: 0;
            right: 0;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px;
        }

        &.nw-book-model-picture-disabled {
            opacity: .25;
        }
    }
`

const getRowHeight = size => {
    switch(size) {
        case 'small':
            return 160;
        case 'large':
            return 300;
        case 'medium':
        default:
            return 230;
    }
};

const PackageMediaDrawer = ({ packageplaylistid, onClose }) => {
    const { data: playlistData } = usePackagePlaylist(packageplaylistid);
    const [documentList, setDocumentList] = useState([]);
    const size = "medium";

    useEffect(() => {
        if (playlistData && playlistData.DocumentPlaylists)
            setDocumentList(convertObjects(playlistData.DocumentPlaylists));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData]);

    const convertObjects = (documentList) => {
        return documentList.map(item => {
            return convertObject(item);
        });
    };

    const convertObject = item => {
        if (item.Type === 'Photo') {
            return {
                src: item.Standard.Url,
                thumbnail: item.Thumbnail.Url,
                thumbnailWidth: getThumbnailWidth(item, getRowHeight(size)),
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title,
                ID: item.ID,
                disabled: false,
                selected: item.isSelected ? true : false,
                type: item.Type,
                notes: '',
                removable: true
            };
        } else if (item.Type === 'Video') {
            return {
                src:  getVideoCover(item),
                thumbnail: getVideoCover(item),
                thumbnailWidth: getRowHeight(size) * 1.5,
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title,
                ID: item.ID,
                disabled: false,
                selected: item.isSelected ? true : false,
                type: item.Type,
                notes: '',
                removable: true
            };
        }
    };
    return (
        <NwDrawer
            modalDrawer
            onClose={onClose}
            title={playlistData ? playlistData.Name : "Loading playlist..."}
        >
            <ListContainer>
                <MediaGalleryContent>
                    {documentList.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className='image-block'
                            >
                                <MediaCover
                                    type={item.type}
                                    selected={item.selected}
                                    selectable={false}
                                    src={item.thumbnail}
                                    onClick={() => {}}
                                    style={{
                                        minWidth: item.thumbnailWidth + "px",
                                        maxWidth: item.thumbnailWidth + 10 + "px",
                                        width: item.thumbnailWidth + "px",
                                        height: item.thumbnailHeight + "px",
                                    }}
                                />
                            </div>                    
                        )
                    })}
                </MediaGalleryContent>
            </ListContainer>
        </NwDrawer>
    )
};

export default PackageMediaDrawer;
