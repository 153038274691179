import React, { useMemo } from 'react'; //useState
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
//import Axios from 'axios';

import NwIcon from 'Components/Gui/NwIcon';
import PackageCard from 'Containers/Booking/Packages/PackagesContent/PackageCard';
import { Loading } from 'Components/Gui/Loading';
//import { useEffect } from 'react';
import { forceReloadProjectPackages, useProjectPackages } from 'Hooks/Project/UseProjectPackages';

const ProjectPackagesOverview = ({ projectId, onMoveToEvent }) => {
    const { isLoading, error, data: projectPackages } = useProjectPackages(projectId);
    
    //const [projectPackages, setProjectPackages] = useState([])
    //const [fetchingPackages, setFetchingPackages] = useState(true)

    // const fetchPackages= async () => {
    //     const packageForProjectFilters = { projectID: projectId, sentOnly: false }
    //     const packages = await Axios.post('/packages/search', packageForProjectFilters)
    //     setProjectPackages(packages)
    //     setFetchingPackages(false)
    // }

    // useEffect(() => {
    //     fetchPackages()
    // }, [])

    const reloadPackages = () => {
        forceReloadProjectPackages(projectId)
        //setFetchingPackages(true)
        //fetchPackages()
    }

    const displayPackages = useMemo(() => {
        if (isLoading) { return [] }
        if (!projectPackages) { return [] }
        const sortedpackages = projectPackages.sort((p1, p2) => (new Date(p2.ExpirationDate)) - (new Date(p1.ExpirationDate)));
        return sortedpackages;
    }, [projectPackages, isLoading]);

    return (
        <div className="internal-card">
            <div className="header">
                <div className="title-container">
                    <NwIcon className="icon" icon={light("book-arrow-right")} />
                    <div className="title">Packages</div>
                </div>
            </div>
            <div className="content">
                {isLoading
                    ?
                    <Loading textBlack />
                    :
                    (displayPackages.map(pack => {
                        return (
                            <PackageCard
                                page="project"
                                small
                                key={pack.ID}
                                pack={pack}
                                multipleSelection={false}
                                onSelect={() => { }}
                                onCloseDrawer={() => reloadPackages()}
                            />
                        )
                    }))
                }
            </div>
        </div>
    );
};

export default ProjectPackagesOverview;