import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Loading } from 'Components/Gui/Loading';
import { EventContainer, } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import EventDrawerHeader from 'Components/EventDrawer/EventDrawerUi/EventDrawerHeader';
import { useTask, forceReloadTask, } from 'Hooks/Event/UseTask';
import TaskForm from 'Components/EventDrawer/TaskEvent/TaskForm';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwIcon from "Components/Gui/NwIcon";
import TaskContent from "./TaskContent";
import Axios from "axios";

const TaskWrapper = ({
    afterRemove,
    afterUpdate,
    filterModel,
    ID,
    modelId,
    onClose,
    onTouchForm,
    projectId,
    viewMode,
}) => {
    const [taskID, setTaskID] = useState(ID);
    const [isEdit, setIsEdit] = useState(viewMode ? true : false);
    const { data: taskData, isFetching, status } = useTask(taskID);
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        if (ID) {
            setTaskID(ID)
        }
    }, [ID])

    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    const handleSelectProject = async project => {
        try {
            const err = await Axios.put('/todos/SetProject', {
                ObjectID: taskData.ID,
                ProjectID: project
            })
            console.log("err in try", err)
            forceReloadTask(taskData.ID);
            await afterUpdate();
        } catch (error) {
            //console.log('ant : select project error => ', error)
            if (error.errorCode && error.errorCode === "GenericError") {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: error.message,
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
            }
        }
    };

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    }

    const getTaskFormProps = () => ({
        taskData: editModelEvent ? editModelEvent : taskData,
        editModelEvent: editModelEvent ? true : false,
        viewMode: viewMode,
        modelId: modelId,
        projectId: projectId,
        afterUpdate: afterUpdate,
        onTouchForm: touchForm => {
            if (taskData) {
                setTouchForm(touchForm);
            } else {
                onTouchForm(touchForm);
            }
        },
        onCancel: () => {
            handleClearEditEvent();
            handleClearTouchForm();
            if (taskData) {
                setIsEdit(false);
            } else {
                onClose();
            }
        },
        onUpdateTaskData: (id) => {
            handleClearTouchForm();
            if (id) {
                setTaskID(id)
            } else {
                forceReloadTask(taskID)
            }
            handleClearTouchForm()
            handleClearEditEvent();
        }
    })

    if (isFetching && !taskData) {
        return (<Loading textBlack />);
    }
    if ((!taskData && taskID) || status === 'Loading') {
        return (<Loading textBlack />);
    }
    if (!taskData && isEdit) {
        return (
            <EventContainer>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">Add new task</div>
                </div>
                <TaskForm {...getTaskFormProps()} />
            </EventContainer>
        )
    }
    return (
        <EventContainer hasProject={taskData && taskData.Project}>
            {taskData ?
                <>
                    <EventDrawerHeader
                        type={100}
                        typeName="todo"
                        data={taskData}
                        modelID={modelId}
                        onSelectProject={handleSelectProject}
                    />
                    <div className="event-content-wrapper">
                        <TaskContent 
                            afterRemove={afterRemove}
                            afterUpdate={afterUpdate}
                            filterModel={filterModel}
                            handleSelectProject={handleSelectProject}
                            isEdit={isEdit}
                            onTouchForm={onTouchForm}
                            projectId={projectId}
                            setEditModelEvent={setEditModelEvent}
                            setIsEdit={setIsEdit}
                            taskID={taskID}
                            taskData={taskData}
                        />
                        {isEdit &&
                            <NwDrawer
                                padding="0px"
                                width={740}
                                touchForm={touchForm}
                                onTouchForm={setTouchForm}
                                title='Edit Task'
                                onClose={() => {
                                    handleClearEditEvent();
                                    setIsEdit(false);
                                    handleClearTouchForm();
                                }}
                            >
                                <EventContainer>
                                    <TaskForm {...getTaskFormProps()} />
                                </EventContainer>
                            </NwDrawer>
                        }
                    </div>
                </>
                :
                <>
                    <div className="ant-drawer-header ant-drawer-header-sticky">
                        <div className="ant-drawer-title">Add New Task</div>
                    </div>
                    <TaskForm {...getTaskFormProps()} />
                </>

            }
        </EventContainer>
    );
};

export default TaskWrapper;
