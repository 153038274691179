
import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";

import { Loading } from 'Components/Gui/Loading';
// import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import {
    EventContainer,
    ObjectHistoryContainer
} from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
// import EventDrawerHeader from 'Components/EventDrawer/EventDrawerUi/EventDrawerHeader';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectLogHistories from "Components/ObjectLogHistories/ObjectLogHistories";
import {
    useMeeting,
    useDeleteMeeting,
    forceReloadMeeting,
    useSetMeetingProject
} from 'Hooks/Event/UseMeeting';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import EventDrawerDetailsContainer from "Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer";
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import MeetingDetails from 'Components/EventDrawer/MeetingEvent/MeetingDetails';
import MeetingEventForm from 'Components/EventDrawer/MeetingEvent/MeetingEventForm';

const MeetingEvent = props => {
    const { ID, viewMode, projectId, contactId, plainMode, allDay, dateSelected, afterUpdate, afterRemove, onSelectEvent, onClose, onTouchForm } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [meetingID, setMeetingID] = useState();
    const { data: meetingsData, isFetching } = useMeeting(meetingID);
    const { mutateAsync: onDeleteMeeting } = useDeleteMeeting();
    const { mutateAsync: onSetMeetingProject } = useSetMeetingProject();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setMeetingID(ID);
    }, [ID]);

    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    if (!meetingsData && meetingID) {
        return (<Loading textBlack />);
    }

    const handleSelectProject = async project => {
        try {
            await onSetMeetingProject({ meetingId: meetingsData.ID, projectId: project });
            await afterUpdate();
        } catch (error) {
            console.log('ant : select project error => ', error)
        }
    };

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteMeeting({ ID: eventId, meetingId: meetingsData.ID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const EventCommentsDocumentsContent = ({ extra, showOnlyContent, showOnlyActions }) => (
        <EventCommentsDocuments
            event={meetingsData}
            api='MeetingID'
            type='Meeting'
            drawerMode={!plainMode}
            fetchEvent={() => {
                forceReloadMeeting(meetingsData.ID)
            }}
            extra={extra}
            showOnlyContent={showOnlyContent}
            showOnlyActions={showOnlyActions}
            disableTransaction={true}
            onTransactionCreatedOrUpdated={() => { forceReloadMeeting(meetingsData.ID) }}
            onTouchForm={onTouchForm}
        />
    )

    const TitleContent = () => {
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(meetingsData.ID)
                    afterRemove(true)
                }),
                onProjectUnlink: (() => handleSelectProject())
            },
            eventProperties: {
                eventType: "Meeting",
                isSingle: true,
                hasExport: true
            },
            event: meetingsData
        }
        return <EventTitle {...props} />
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const MeetingDetailsContent = () => (
        <>
            <TitleContent />
            <MeetingDetails meetingsData={meetingsData} />
            <EventCommentsDocumentsContent showOnlyContent />
            <ObjectHistoryContainer>
                <ObjectHistory asLine object={meetingsData} />
            </ObjectHistoryContainer>
            <EventCommentsDocumentsContent showOnlyActions />
        </>
    )







    const MeetingFormContent = () => (
        <MeetingEventForm
            meetingsData={meetingsData}
            viewMode={viewMode}
            projectId={projectId}
            contactId={contactId}
            plainMode={plainMode}
            allDay={allDay}
            dateSelected={dateSelected}
            afterUpdate={afterUpdate}
            onTouchForm={touchForm => {
                if (meetingsData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            }}
            onCancel={() => {
                handleClearTouchForm();
                if (meetingsData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            }}
            onUpdateMeetingData={meetingId => {
                handleClearTouchForm();
                if (meetingId) {
                    setMeetingID(meetingId)
                } else {
                    forceReloadMeeting(meetingID);
                }
            }} />
    );

    const EventContent = () => (
        <>
            {meetingsData ?
                <>
                    <div className="event-content-wrapper">
                        {!plainMode &&
                            <ProjectEventsTimeline
                                event={{
                                    ...meetingsData,
                                    TypeName: "Meeting"
                                }}
                                projectID={meetingsData.Project.ID}
                                onSelectEvent={onSelectEvent} />
                        }
                        <MeetingDetailsContent />
                    </div>
                    {isEdit &&
                        <NwDrawer
                            padding="0px"
                            width={740}
                            title='Edit Meeting'
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            onClose={() => {
                                setIsEdit(false);
                                handleClearTouchForm();
                            }}>
                            <EventContainer>
                                <MeetingFormContent />
                            </EventContainer>
                        </NwDrawer>
                    }
                </>
                : isEdit && <MeetingFormContent />
            }
        </>
    )

    return plainMode ?
        //meeting card in the project page
        <EventContainer hasProject={meetingsData && meetingsData.Project}>
            {meetingsData &&
                <Row gutter={32}>
                    <Col span={14}>
                        <EventContent />
                    </Col>
                </Row>
            }
        </EventContainer>
        :
        meetingsData ?
            <>
                <EventDrawerDetailsContainer
                    eventData={{ ...meetingsData, elType: 'Meeting', uniqID: `Meeting-${meetingsData.ID}` }}
                    isFetching={isFetching}
                    typeName="meeting"
                    onSelectEvent={onSelectEvent}
                    onSelectProject={handleSelectProject}
                    firstTab={
                        <EventContent />
                    }
                />
            </>
            :
            <EventContainer>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">Add new meeting</div>
                </div>
                <EventContent />
            </EventContainer>

};

export default MeetingEvent;
