import React, { useEffect, useMemo, useState, useContext } from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import { Popover, Tag, Switch } from 'antd';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import StyleVariables from 'Components/Gui/StyleVariables';
import { Loading } from 'Components/Gui/Loading';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import CustomSwitch from 'Components/Gui/CustomSwitch';

const SendAgendaMailPreviewContainer = styled.div`
    .filters-line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: .5rem 0;

        .filters-line-left {

        }
        .filters-line-right {
            .event-type-filter-container {
                margin-bottom: .5rem;
                text-align: right;
                .ant-tag {
                    cursor: pointer;
                }

                .event-type-filter-trigger {
                    
                    font-size: 14px;
                    color: ${props => props.theme.PrimaryColor};
                    text-decoration: underline;
                    cursor: pointer;
                    user-select: none;
                    margin-right: 8px;
                }
            }

            .manually-excluded-events-container {
                text-align: right;
                .hidden-events-placeholder {
                    width: 10rem;
                }
            }
        }
    }
    .preview-container {
        background: white;
        border: ${props => props.theme.DefaultBorder};
        border-radius: 4px;
        min-height: 200px;
        height: ${props => props.$useTwoColumns ? `calc(100vh - 400px)` : `calc(100vh - 600px)`};
        overflow: auto;
        .ck-content {
            height: 360px;
        }

        .send-agenda-event-box {
            display: flex;
            justify-content: space-between;
            padding: .5rem;
            border: 2px dashed ${props => props.theme.BorderColor};
            margin-bottom: .5rem;
            font-size: 2rem;
            border-radius: 4px;
        }
    }
`

const EventContent = styled.div`
    ${props => props.styles}
`;

const PopoverContent = styled.div`
    .p-header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .status-text {
            margin-bottom: 20px;
        }
        .close-x {
            cursor: pointer;
            user-select: none;
        }
    }
    .event-row {
        display: flex;
        margin-bottom: 5px;
        .event-text {
            font-size: 14px;
            padding-left: 10px;
        }
    }
`

const SendAgendaMailPreview = ({
    docVersionID,
    htmlContent,
    message,
    signature,
    title,
    eventDateMap,
    filters,
    onUpdateFilters,
    onUpdateDocversion,
    onUpdateEventDateMap,
    twoColumns,
}) => {

    console.log("signature", signature);

    const [hiddenEvents, setHiddenEvents] = useState([]);
    const [showAllTypes, setShowAllTypes] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [showCancelled, setShowCancelled] = useState(false);
    const [showAcknowledged, setShowAcknowledged] = useState(false);
    const [eventTypeFilters, setEventTypeFilters] = useState([
        { content: 'Castings', value: filters.InclCastings },
        { content: 'Jobs', value: filters.InclJobs },
        { content: 'External Jobs', value: filters.InclExternalJobs },
        { content: 'Activities', value: filters.InclFollowUps },
        { content: 'Travels', value: filters.InclTravels },
        { content: 'Accommodations', value: filters.InclAccommodations },
        { content: 'Notices', value: filters.InclNotices },
        //{ content: 'Meetings', value: filters.InclMeetings },
    ]);

    useEffect(() => {
        clearHiddenEvents();
        //initEventMap();
    }, []);

    useEffect(() => {
        initEventMap();
    }, [htmlContent]);

    const initEventMap = () => {
        onUpdateEventDateMap({});
        if (htmlContent) {
            const htmlstring = ReactDOMServer.renderToString(parse(htmlContent, eventDateMapOptions));
        }
    }

    const clearHiddenEvents = () => {
        setHiddenEvents([]);
    }

    const eventDateMapOptions = {
        replace: ({ attribs/*, children*/ }) => {
            if (!attribs) return;

            if (attribs.class === 'event-block') {
                onUpdateEventDateMap(prevEventDateMap => {
                    let newEventDateMap = {...prevEventDateMap};
                    if (!newEventDateMap[attribs['event-date']]) {
                        newEventDateMap = {
                            ...newEventDateMap,
                            [attribs['event-date']]: [{ 
                                eventType: attribs['data-eventtype'],
                                eventId: attribs['data-eventid'], 
                                eventdateid: attribs['data-eventdateid'], 
                                eventdatemodelid: attribs['data-eventdatemodelid']
                            }]
                        }
                    } else {
                        newEventDateMap[attribs['event-date']].push({
                            eventType: attribs['data-eventtype'],
                            eventId: attribs['data-eventid'], 
                            eventdateid: attribs['data-eventdateid'], 
                            eventdatemodelid: attribs['data-eventdatemodelid']
                        })
                    }
                    return newEventDateMap
                })
            }
        }
    };

    const handleRemoveEventBlock = (eventDate, eventType, eventId, eventdateid, eventdatemodelid) => () => {
        const eventToExclude = { Type: eventType, ID: ((eventType === 'Casting' || eventType === 'Job') ? eventdatemodelid : eventId) };
        setHiddenEvents(prevHiddenEvents => [...prevHiddenEvents, eventToExclude]);
    };

    const options = isGenerate => {
        return ({
            replace: ({ attribs, children }) => {
                if (!attribs) return;

                if (attribs.type === 'text/css' && !isGenerate) {
                    return <div />;
                }
                if ((attribs.id === 'header' || attribs.id === 'footer') && !isGenerate) {
                    return <div />;
                }
                if (attribs.class === 'message-placeholder') {
                    return (<span className="message-placeholder">{message.length === 0 && !isGenerate ? '' : message}</span>)
                }
                if (attribs.class === 'signature-placeholder') {
                    if (!isGenerate && (!signature || !signature.Signature)) {
                        return <span className="signature-placeholder"></span>;
                    } else {
                        return (<span className="signature-placeholder" dangerouslySetInnerHTML={{__html: signature.Signature}}></span>)
                    }
                    //return (<span className="signature-placeholder">{signature && !isGenerate ? '' : signature}</span>)
                }
                if (attribs.class === 'title-placeholder') {
                    return (
                        <span className="title-placeholder">{title.length === 0 && !isGenerate ? '' : title}</span>
                    )
                }
                // if (attribs.class === 'event-date') {
                //     if (eventDateMap[attribs['data-date']] && eventDateMap[attribs['data-date']].length === 0) {
                //         return <div />;
                //     }
                //     return;
                // }
                if (attribs.class === 'event-block') {

                    // if (eventDateMap[attribs['event-date']] && !eventDateMap[attribs['event-date']].find(eventblock => 
                    //     (eventblock.eventId === attribs['data-eventid'] && 
                    //     eventblock.eventType === attribs['data-eventtype'] && 
                    //     eventblock.eventdateid === attribs['data-eventdateid'] && 
                    //     eventblock.eventdatemodelid === attribs['data-eventdatemodelid']))
                    // ) {
                    //     return <div />;
                    // }
                    if (!isGenerate) {
                        return (
                            <div className="send-agenda-event-box">
                                <EventContent
                                    className="event-block"
                                    event-date={attribs['event-date']}
                                    data-eventtype={attribs['data-eventtype']}
                                    data-eventid={attribs['data-eventid']}
                                    data-eventdateid={attribs['data-eventdateid']}
                                    data-eventdatemodelid={attribs['data-eventdatemodelid']}
                                    styles={attribs.style}
                                >
                                    {domToReact(children, options)}
                                </EventContent>
                                <NwIcon pointer icon={solid('xmark')} onClick={handleRemoveEventBlock(
                                    attribs['event-date'],
                                    attribs['data-eventtype'],
                                    attribs['data-eventid'],
                                    attribs['data-eventdateid'],
                                    attribs['data-eventdatemodelid'])} />
                            </div>
                        )
                    }
                    return
                }
            }
        })
    };

    const renderedHtml = useMemo(() => {
        const html = parse(htmlContent, options());
        const body = html.props.children.find(element => element.type === 'body');
        if (body) {
            const root = body.props.children.find(element => element.type === 'div');
            if (root) {
                return root;
            }
        }
        return '';
    }, [docVersionID, htmlContent, message, signature, title]);  //eventDateMap, 

    const handleDocversionFilters = (index) => {
        let _eventTypeFilters = [...eventTypeFilters];
        _eventTypeFilters[index].value = !_eventTypeFilters[index].value;

        const data = {
            ...filters,
            InclCastings: _eventTypeFilters[0].value,
            InclJobs: _eventTypeFilters[1].value,
            InclExternalJobs: _eventTypeFilters[2].value,
            InclFollowUps: _eventTypeFilters[3].value,
            InclTravels: _eventTypeFilters[4].value,
            InclAccommodations: _eventTypeFilters[5].value,
            InclNotices: _eventTypeFilters[6].value,
            //InclMeetings: _eventTypeFilters[7].value,
        }
        setEventTypeFilters(_eventTypeFilters);
        onUpdateFilters(data);
    };

    useEffect(() => {
        const excludeOptions = !showOptions;
        if (filters.ExcludeOptions !== excludeOptions) {
            const data = {
                ...filters,
                ExcludeOptions: !showOptions
            }
            onUpdateFilters(data);
        }
    }, [showOptions]);

    useEffect(() => {
        const excludeCancelled = !showCancelled;
        if (filters.ExcludeCancelled !== excludeCancelled) {
            const data = {
                ...filters,
                ExcludeCancelled: excludeCancelled
            }
            onUpdateFilters(data);
        }
    }, [showCancelled]);

    useEffect(() => {
        const excludeAcknowledged = !showAcknowledged;
        if (filters.ExcludeAcknowledged !== excludeAcknowledged) {
            const data = {
                ...filters,
                ExcludeAcknowledged: excludeAcknowledged
            }
            onUpdateFilters(data);
        }
    }, [showAcknowledged]);

    useEffect(() => {
        if (!(hiddenEvents.length === 0 & filters.ExcludedEvents.length === 0)) {
            const data = {
                ...filters,
                ExcludedEvents: [...hiddenEvents]
            }
            onUpdateFilters(data);
        }
    }, [hiddenEvents]);

    const shownEventTypesCount = eventTypeFilters.filter(eventTypeFilter => eventTypeFilter.value).length;

    return (htmlContent ?
        <SendAgendaMailPreviewContainer $useTwoColumns={twoColumns}>
            {!twoColumns && <NwDivider textSize='medium'>AGENDA</NwDivider>}
            <div className="filters-line">
                <div className="filters-line-left">
                    <CustomSwitch checked={showOptions} onChange={() => { setShowOptions(!showOptions) }} text="include options" />
                    <CustomSwitch checked={showCancelled} onChange={() => { setShowCancelled(!showCancelled) }} text="include cancelled events" />
                    <CustomSwitch checked={showAcknowledged} onChange={() => { setShowAcknowledged(!showAcknowledged) }} text="include notified events" />
                </div>
                <div className="filters-line-right">
                    <div className="event-type-filter-container">
                        <Popover
                            placement="left"
                            trigger="click"
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            open={showAllTypes}
                            onOpenChange={visible => {
                                setShowAllTypes(visible);
                            }}
                            content={
                                <PopoverContent>
                                    <div className="p-header">
                                        <div className="status-text">{`${shownEventTypesCount} / ${eventTypeFilters.length} TYPES SHOWN`}</div>
                                        <div className="close-x" onClick={() => { setShowAllTypes(false) }}>X</div>
                                    </div>
                                    {eventTypeFilters.map((event, index) => {
                                        return (
                                            <div key={index} className="event-row">
                                                <Switch checked={event.value} onChange={() => handleDocversionFilters(index)} />
                                                <div className="event-text">{event.content.toUpperCase()}</div>
                                            </div>
                                        )
                                    })}
                                </PopoverContent>
                            }>
                            {shownEventTypesCount < eventTypeFilters.length ?
                                <Tag color={StyleVariables.DangerColor}>
                                    {`${shownEventTypesCount} / ${eventTypeFilters.length} TYPES SELECTED`}
                                </Tag>
                                :
                                <div className="event-type-filter-trigger">filter on event type</div>
                            }
                        </Popover>
                    </div>
                    <div className="manually-excluded-events-container">
                    {hiddenEvents.length > 0 ?
                        <Tag color={StyleVariables.DangerColor}>
                            <FlexContainer>
                                {`${hiddenEvents.length} HIDDEN EVENTS`} &nbsp;
                                <NwIcon icon={solid('times-circle')} onClick={clearHiddenEvents} />
                            </FlexContainer>
                        </Tag>
                        :
                        <div className="hidden-events-placeholder" />
                    }
                    </div>
                </div>
            </div>
            <div className="preview-container">
                {renderedHtml}
            </div>
        </SendAgendaMailPreviewContainer>
        : <Loading />
    )
};

export default SendAgendaMailPreview;