import React, {useContext} from "react";
import {Select} from "antd";

import { ListContext } from "Contexts/ListContext";
const Option = Select.Option;

const TransactionInvoiceTypeSelector = ({value, onChange, disabled}) => {
  const {cachedList} = useContext(ListContext);
  const transactionInvoiceTypes = cachedList.taxmethods.items.TransactionInvoiceTypes || [];

  return (
    <Select
        autoComplete='off'
        value={value}
        placeholder="Please select"
        onChange={onChange}
        disabled={disabled}
        style={{ width: "100%" }}
    >
        {transactionInvoiceTypes.map((el, key) => <Option key={key} value={el}>{el}</Option>)}
    </Select>
  );
};

export default TransactionInvoiceTypeSelector;
