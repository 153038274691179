import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const onGetProjectTransactions = ID => (
    Axios.get(`/projects/TransactionsGroupedByModelAndDay/${ID}`)
)

const useProjectTransactions = (ID) => {
    return useQuery(["projecttransactions", ID], () => onGetProjectTransactions(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false
    });
}


const forceReloadProjectTransactions = (ID) => {
    return queryClient.invalidateQueries(['projecttransactions', ID])
}

export {
    useProjectTransactions,
    forceReloadProjectTransactions
};


