import React from "react";
import {Col, Row} from "antd";
import RatingsTable from "./RatingsTable";
import NwDrawer from "Components/Gui/NwDrawer";

const RatingsViewDrawer = ({ onCancel, ratings }) => {
    return (
        <NwDrawer
            onClose={onCancel}
            title="Ratings"
        >
            <Row>
            <Col span={24}>
                 <RatingsTable ratings={ratings}/>
            </Col>
            </Row>
        </NwDrawer>
    );
};

export default RatingsViewDrawer;
