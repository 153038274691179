const isSameDate = (date1string, date2string) => {
    if (date1string && date2string) {
        if (date1string.indexOf("T") > -1) {
            date1string = date1string.split("T")[0]
        }
        if (date2string.indexOf("T") > -1) {
            date2string = date2string.split("T")[0]
        }
        if (date1string === date2string) {
            return true
        }
    }
    return false
}

export const getTransactionConfirmationProperties = (project, transaction) => {
    const confprops = {
        Confirmed: null,
        OptionNumber: null,
        Cancel: null
    }
    if (transaction.EventID && transaction.ModelID) {
        const event = project.Events.find(event => event.ID === transaction.EventID)
        if (event) {
            if (transaction.WorkDate) {
                const eventDate = event.EventDates.find(ed => isSameDate(ed.FromDate, transaction.WorkDate))
                if (eventDate) {
                    const eventDateModel = eventDate.EventDatesModels.find(edm => edm.ModelID === transaction.ModelID)
                    if (eventDateModel) {
                        if (event.TypeName === "Job") {
                            confprops.OptionNumber = eventDateModel.OptionNumber
                            confprops.Confirmed = eventDateModel.OptionNumber === 10 && !eventDateModel.Cancel
                        } else {
                            confprops.OptionNumber = null
                            confprops.Confirmed = !eventDateModel.Cancel
                        }
                        confprops.Cancel = eventDateModel.Cancel
                    } else {
                        confprops.Confirmed = false
                        confprops.OptionNumber = null
                        confprops.Cancel = null
                    }
                }
            } else {
                const eventDateModel = event.EventDates.some((ed) => ed.EventDatesModels.find(edm => (edm.ModelID === transaction.ModelID) && !edm.Cancel && (event.TypeName !== "Job" || edm.OptionNumber === 10)))
                if (eventDateModel) {
                    confprops.Confirmed = true
                    confprops.OptionNumber = null
                    confprops.Cancel = false
                } else {
                    confprops.Confirmed = false
                    confprops.OptionNumber = null
                    confprops.Cancel = null
                }
            }
        }
    }
    return confprops
}

export const getModelConfirmationProperties = (project, modelID) => {
    const confprops = {
        ConfirmedWorkDays: 0
    }
    if (modelID) {
        const events = project.Events.filter(event => event.TypeName === 'Job' && event.EventModels.some(em => em.ModelID === modelID))
        if (events.length > 0) {
            for (const event of events) {
                const confirmedDates = event.EventDates.filter(
                    (ed) => (ed.EventDatesModels.find(
                        (edm) => ((edm.ModelID === modelID) && !edm.Cancel && edm.OptionNumber === 10)
                    ))
                )
                if (confirmedDates) {
                    confprops.ConfirmedWorkDays += confirmedDates.length
                }
            }
        }
    }
    return confprops
}