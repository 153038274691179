import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const onGetProjectStatements = (ID, statementType) => (
    Axios.get(`/projects/StatementsGroupedByContact/${statementType}/${ID}`)
)

const useProjectStatements = (ID, statementType) => {
    return useQuery(["projectstatements", ID, {statementType: statementType}], () => onGetProjectStatements(ID, statementType), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false
    });
}


const forceReloadProjectStatements = (ID) => {
    return queryClient.invalidateQueries(['projectstatements', ID])
}

export {
    useProjectStatements,
    forceReloadProjectStatements
};


