import React, { useContext } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { ListContext } from "Contexts/ListContext";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import {
    NWTextAreaCharCounter,
    NWBookerSelector,
    NWAgencyDepartmentSelector,
    NWRadioButtonGroup
} from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { UserContext } from 'Contexts/UserContext';
import { useAddContactUser, useEditContactUser } from "Hooks/Contact/UseContactUsers";
import { fetchModelsByArea } from 'Hooks/Contact/UseContactsList';
import { useStateValue } from 'Contexts/StateProvider';

const Roles = [
    {
        label: 'Booker',
        value: 'Booker'
    },
    {
        label: 'Scouter',
        value: 'Scouter'
    }
];

const ContactUserForm = ({ contactUser, contactID, contactUserIds, onCancel, onUpdated, onTouchForm, isModel }) => {
    const { cachedList } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    const { mutateAsync: addContactUser } = useAddContactUser(contactID)
    const { mutateAsync: editContactUser } = useEditContactUser(contactID)
    const [{ areaContext }] = useStateValue();

    const initialValues = {
        UserID: currentUser.UserID,
        AgencyDepartment: {
            NetworkAgencyID: null,
            DepartmentID: null
        },
        Note: '',
        Role: 'Booker',
    };

    if (contactUser) {
        initialValues.UserID = contactUser.UserID;
        initialValues.AgencyDepartment.DepartmentID = contactUser.DepartmentID;
        initialValues.Note = contactUser.Note;
        initialValues.Role = contactUser.RoleName;
        initialValues.AgencyDepartment.NetworkAgencyID = contactUser.NetworkAgencyID;
    }

    const saveContactUser = async (values, setSubmitting) => {
        const contactUserData = {
            ...values,
            ID: contactUser && contactUser.ID,
            NetworkAgencyID: values.AgencyDepartment.NetworkAgencyID,
            DepartmentID: values.AgencyDepartment.DepartmentID,
            PrivacyLevel: 'Public'
        };

        if (contactUser) {
            await editContactUser(contactUserData);
            fetchModelsByArea(areaContext.area);
            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false)
            onUpdated()
        } else {
            if (contactUserIds && contactUserIds.length) {
                contactUserData.ContactIDs = contactUserIds;
            } else {
                const singleid = []
                singleid.push(contactID)
                contactUserData.ContactIDs = singleid;
            }
            await addContactUser(contactUserData);
            fetchModelsByArea(areaContext.area);
            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false)
            onUpdated()
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveContactUser(values, setSubmitting);
            }}
            validationSchema={Yup.object().shape({
                UserID: Yup.string().required("Content is required"),
                Role: Yup.string().required("Content is required"),
            })}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onSubmit={handleSubmit}
                    layout="vertical">
                    {isModel &&
                        <Field
                            component={NWRadioButtonGroup}
                            name="Role"
                            value={values.Role}
                            buttonStyle="solid"
                            options={Roles.map(el => {
                                return { key: el.value, label: el.label, value: el.value };
                            })}
                        />
                    }
                    {!contactUser &&
                        <Field
                            component={NWBookerSelector}
                            label="User"
                            name="UserID"
                            required
                            disableClosable
                            value={values.UserID}
                        />
                    }
                    <Field
                        component={NWAgencyDepartmentSelector}
                        label="Agency / Department"
                        name="AgencyDepartment"
                        value={values.AgencyDepartment}
                        dataSource={cachedList.accountingGroups.items}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                    />
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Note"
                        name="Note"
                        type="text"
                        value={values.Note}
                        maxLength={500}
                        autoSize={{ minRows: 5, maxRows: 10 }}
                    />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    if (onTouchForm) {
                                        onTouchForm(false);
                                    }
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default ContactUserForm;