import React from 'react'
import styled from 'styled-components';
import { List } from 'antd';

import { getFileTypeIcons } from 'Libs/NwUtils';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import NwIcon from 'Components/Gui/NwIcon';
import { getEvent } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';
import FlexContainer from 'Components/Gui/FlexContainer';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const ListItemMeta = styled(List.Item.Meta)`
    padding: 0;
    cursor: pointer;
    &:hover {
        background-color: ${props => !props.$disablehover && props.theme.SelectedBackgroundColor};
        .document-image {
            color: ${props => !props.$disablehover && props.theme.PrimaryColor};
        }
    }
    .ant-card-grid {
        padding: 24px;
    }
`;

const DocumentItemThumbStyle = styled.div`
    svg {
        margin-left: 8px;
        width: 20px !important;
        height: auto;
    }
`;

const DocumentContent = styled.div`
    width: calc(100% - 120px);
    min-width: 80px;
    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const Document = ({
    actions,
    disablehover,
    document,
    events,
    getDescription,
    onEditDocument,
    onOpenDocument,
    showTag,
}) => {
    const event = getEvent(events, document);
    const breakpoints = useNwBreakPoints();
    return (
        <ListItemMeta
            $disablehover={disablehover}
            avatar={
                <DocumentItemThumbStyle
                    onClick={() => onEditDocument(document)}
                    className="document-image"
                >
                    <NwIcon icon={getFileTypeIcons(document.Extension)} />
                </DocumentItemThumbStyle>
            }
            title={(
                <FlexContainer justifyContent='space-between'>
                    <DocumentContent onClick={() => onEditDocument(document)}>
                        {
                            showTag && event && (
                                <EventTypeTag event={event} />
                            )
                        }
                        <div className='title'>{document.Title}</div>
                    </DocumentContent>
                    {actions && breakpoints !== 1 && actions(document)}
                </FlexContainer>
            )}
            description={
                <div>
                    {getDescription(document)}
                    {breakpoints === 1 && actions &&
                        <FlexContainer mg='12px 0 0'>
                            {actions(document)}
                        </FlexContainer>
                    }
                </div>
            }
        />
    )
}

// Document.propTypes = {
//     document: PropTypes.shape({
//         ID: PropTypes.number.isRequired
//     }),
//     handleShowDocumentDrawer: PropTypes.func,
//     getDescription: PropTypes.func.isRequired,
//     actions: PropTypes.func,
//     showTag: PropTypes.bool,
// }


export default Document
