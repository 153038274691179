import React, { useState, useEffect, useRef } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ConnectionCard from "Components/Connection/ConnectionCard";
import ConnectionDetailForm from "Components/Connection/ConnectionDetailForm";
import { NwButton, NwAddButton, NwBackButton } from "Components/Gui/NwButton";
import NwMenu from "Components/Gui/NwMenu";
import ConnectionSorter from "Components/Connection/ConnectionSorter";
import NwDrawer from "Components/Gui/NwDrawer";
import { Loading } from 'Components/Gui/Loading';
//import { useContact } from 'Hooks/Contact/UseContact';
import { useContactConnections, forceReloadContactConnections } from 'Hooks/Contact/UseContactConnections';
import AddToMailingListDrawer from "./AddToMailingListDrawer";

const ContactConnectionDrawer = ({ contactId, contactSubType, connectionId, readOnly, onClose }) => {
    const { data: Connections, isFetching: isFetchingConnections } = useContactConnections(contactId);
    const [viewMode, setViewMode] = useState("LISTING") // LISTING, VIEWING, INSERTING
    const [connectionType, setConnectionType] = useState("Place") // Default People
    const [isAddConnection, setIsAddConnection] = useState(false)
    const [mailingListDrawer, setMailingListDrawer] = useState(false)
    const [selectedEmail, setSelectedEmail] = useState(null)
    const [selectedConnection, setSelectedConnection] = useState(connectionId)
    const [touchForm, setTouchForm] = useState(false);
    const connectionRef = useRef();

    useEffect(() => {
        if (!isFetchingConnections && Connections && connectionRef.current) {
            connectionRef.current.scrollIntoView({
                block: 'start',
            });
        }
    }, [isFetchingConnections, Connections])

    useEffect(() => {
        if (!isFetchingConnections && Connections && selectedEmail) {
            let selectedEmailUpdated = null
            for (const c of Connections) {
                const email = c.Emails.find(e => e.ID === selectedEmail.ID)
                if (email) {
                    selectedEmailUpdated = email
                    break
                }
            }
            if (selectedEmailUpdated) {
                setSelectedEmail(selectedEmailUpdated)
            }
        }
    }, [Connections])

    const handleMenu = e => {
        switch (e.key) {
            case "place":
                setConnectionType("Place");
                setIsAddConnection(true);
                break;
            case "people":
                setConnectionType("People");
                setIsAddConnection(true);
                break;
            case 'room':
                setConnectionType("Room");
                setIsAddConnection(true);
                break;
            default:
                break;
        }
    };

    const handleAddToMailingList = (email) => {
        setSelectedEmail(email);
        setMailingListDrawer(true);
    }

    const handleCloseMailingListDrawer = () => {
        setSelectedEmail();
        setMailingListDrawer();
    }

    const handleUpdateMailingListItems = () => {
        forceReloadContactConnections(contactId)
    }

    const handleSelectConnection = (ConnectionID) => {
        setSelectedConnection(ConnectionID)
    }

    const menuActions = [
        { name: "people", key: "people", icon: light("user") },
        { name: "place", key: "place", icon: light("home-alt") },
    ]
    console.log("contactSubType", contactSubType)
    if (contactSubType === 3) {
        menuActions.push({ name: 'room', key: 'room', icon: light("bed") })
    }

    const renderMenu = () => (
        viewMode === "SORTING" ?
            <div>
                <NwBackButton primary label="Back to list" onClick={() => setViewMode("LISTING")} />
            </div>
            :
            <>
                <div className='drawer-toolbar-container'>
                    <NwMenu actions={menuActions} handleClick={handleMenu} placement="bottomRight">
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <NwAddButton primary label="Add a New Contact Card" />
                        </div>
                    </NwMenu>
                </div>
                <NwButton ghost icon={light("sort")} label="Sort Connections" onClick={() => setViewMode("SORTING")} />
            </>
    );

    return (
        <NwDrawer
            onClose={onClose}
            title="Connections">
            {!readOnly && renderMenu()}
            {isFetchingConnections ?
                <Loading textBlack />
                :
                <div className='drawer-content standard'>
                    {viewMode === "LISTING" && Connections &&
                        Connections.map(item => (
                            <div ref={(selectedConnection && selectedConnection === item.ID) ? connectionRef : null} key={item.ID}>
                                <ConnectionCard onSelect={handleSelectConnection} readOnly={readOnly} key={item.ID} item={item} contactID={contactId} onAddToMailingList={handleAddToMailingList} />
                            </div>
                        ))}
                    {viewMode === "SORTING" && Connections && (
                        <ConnectionSorter connections={Connections} contactID={contactId} />
                    )}
                </div>
            }
            {isAddConnection && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add Connection'
                    onClose={() => setIsAddConnection(false)}>
                    <ConnectionDetailForm
                        contactID={contactId}
                        connectionType={connectionType}
                        onTouchForm={setTouchForm}
                        onCancel={() => {
                            setIsAddConnection(false);
                        }}
                        onSave={id => {
                            setIsAddConnection(false);
                        }}
                    />
                </NwDrawer>
            )}
            {mailingListDrawer &&
                <AddToMailingListDrawer
                    selectedEmail={selectedEmail}
                    onClose={handleCloseMailingListDrawer}
                    onUpdateEmail={handleUpdateMailingListItems}
                />
            }
        </NwDrawer>
    );
};

export default ContactConnectionDrawer;
