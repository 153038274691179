import React, { useState } from "react";
import styled from 'styled-components';
import Axios from 'axios';
import PlaylistDetails from "Components/MediaDrawer/PlaylistDetails";
import NwDrawer from "Components/Gui/NwDrawer";
import Comments from "Components/Comments/Comments";
import MediaRatings from 'Components/MediaDrawer/MediaRatings';
import ObjectTagsCard from 'Components/ObjectTagsCard/ObjectTagsCard';
import MediaWebsites from "Components/MediaDrawer/MediaWebsites";
import MediaPackages from "Components/MediaDrawer/MediaPackages";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { forceReloadPlaylist, usePlaylistInfo } from "Hooks/Playlist/UsePlaylist";
import { Loading } from "Components/Gui/Loading";


const CardLine = styled.div`
  margin-top: 1rem;
`

const PlayListInfoDrawer = ({ onPublishBook, playlist, contactID, readOnly, onClose, isEdit, onDeletePlaylists, onUpdatePlaylists }) => {
  
  const [touchForm, setTouchForm] = useState(false);

  const { data: playlistData, isFetching } = usePlaylistInfo(playlist.ID)

  const handleDeletePlayList = () => {
    onDeletePlaylists(playlistData);
    onClose();
  };

  const handleAddTag = async tag => {
    const tagData = {
      idp: playlistData.ID,
      idt: tag.ID
    };

    try {
      await Axios.post(`/playlists/addtag/${playlistData.ID}/${tag.ID}`, tagData)
      onUpdatePlaylists();
      forceReloadPlaylist(playlistData.ID)
    } catch (error) {
      console.log("errore", error);
    }
  };

  const handleRemoveTag = async tag => {
    const tagData = {
      playlistID: playlist.ID,
      tagID: tag.ID
    };

    try {
      await Axios.post(`/playlists/removetag/${playlistData.ID}/${tag.ID}`, tagData)
      onUpdatePlaylists();
      forceReloadPlaylist(playlistData.ID)
    } catch (error) {
      //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
      console.log("errore", error);
    }
  };

  const handleMediaUpdate = () => {
    onUpdatePlaylists();
  }

  return (
    <NwDrawer
      touchForm={touchForm}
      onTouchForm={setTouchForm}
      onClose={onClose}
      icon={readOnly && 'times'}
      whiteBg={false}
      bgColor={readOnly ? `rgba(0, 0, 0, .5)` : null}
      title={`${getContactName(playlist.ContactID || contactID)} / ${playlist.Name}`}>
      {(playlistData && !isFetching) ?
        <>
          <CardLine>
            <PlaylistDetails
              isEdit={isEdit}
              playlist={playlistData}
              onTouchForm={setTouchForm}
              onDeletePlayList={handleDeletePlayList}
              onUpdatePlaylists={() => {
                forceReloadPlaylist(playlistData.ID)
                onUpdatePlaylists()
              }} />
          </CardLine>
          <CardLine>
            <MediaWebsites
              book={playlistData}
              onPublishBook={onPublishBook}
            />
          </CardLine>
          <CardLine>
            <MediaPackages
              playlistID={playlistData.ID}
            />
          </CardLine>
          <CardLine>
            <MediaRatings objectType={'Playlist'} vertical objectId={playlistData.ID} onMediaUpdate={handleMediaUpdate} />
          </CardLine>
          <CardLine>
            <ObjectTagsCard objectType={'Playlist'} vertical objectTags={playlistData.Tags} onAddTag={handleAddTag} onRemoveTag={handleRemoveTag} />
          </CardLine>
          <CardLine>
            <Comments
              api='PlaylistID'
              comments={playlistData.Comments}
              ID={playlistData.ID}
              disableVisibility
              drawerMode
              onTouchForm={setTouchForm}
              onUpdate={comment => {
                forceReloadPlaylist(playlistData.ID)
                onUpdatePlaylists()
              }}
              isPaginated={true}
            />
          </CardLine>
        </>
        :
        <Loading />
      }
    </NwDrawer>
  )
};

export default PlayListInfoDrawer;
