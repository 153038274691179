import React, { useMemo, useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { Row, Col } from 'antd';
import styled from 'styled-components';


import NwDrawer from 'Components/Gui/NwDrawer';
import { createFeatureItemForm } from "Libs/NWFeatures";
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import { useEditFeatures } from "Hooks/Contact/UseContactFeatures";
import { useGlobalFeatures, useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { ConfigContext } from 'Contexts/ConfigContext';
import { getContact } from "Hooks/Contact/UseContactsList";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const FormContainer = styled.div`
  padding: ${props => !props.isMobile && '1rem 2rem'};
`;

const Fieldset = styled.fieldset`
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 8px 0;  
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.2em;
  background-color: ${props => props.$isActive ? props.theme.WorkingColor : "none"};
  display: inline;
  width: auto;
`;

const AreaName = styled.div`
    font-size: 1.2em;
    text-transform: capitalize;
    margin-bottom: 2rem;
    font-weight: bold;
`;

const EditFeatureDrawer = ({ featuresGroup, contactFeatures, contactID, contactType, editFeatureName, updatedFieldIDs, setUpdatedFieldIDs, onClose }) => {
  const initialValues = {};
  const { userGuiConfig } = useContext(ConfigContext);
  const { data: globalFeatures } = useGlobalFeatures();
  const contact = getContact(contactID);
  const { data: globalConversions } = useGlobalConversions();
  const { mutateAsync: editFeatures } = useEditFeatures();
  const breakpoints = useNwBreakPoints();
  const [updatingFieldID, setUpdatingFieldID] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
        const element = document.getElementById('active-feature');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, 300);
    
    return () => clearTimeout(timer);
}, []);

  featuresGroup.GroupFeatures.forEach(feature => {
    const contactFeature = contactFeatures && contactFeatures.find(contactFeature => contactFeature.FeatureID === feature.FeatureID);
    initialValues[feature.ID] = contactFeature && contactFeature.Value;
  });

  const handleUpdatedFieldIDs = updatingFieldName => {
    if (!updatedFieldIDs.find(fieldName => fieldName === updatingFieldName)) {
      setUpdatedFieldIDs([...updatedFieldIDs, updatingFieldName]);
    }
  }

  const handleCurrentUpdatingFieldID = fieldID => {
    setUpdatingFieldID(fieldID);
  }

  const saveFeatures = async (values, setSubmitting) => {
    const Items = [];
    Object.entries(values).forEach(el => {
      if (updatingFieldID && updatingFieldID === el[0]) {
        const groupFeature = featuresGroup.GroupFeatures.find(groupFeature => groupFeature.ID === el[0]);
        Items.push({ FeatureID: groupFeature.FeatureID, Value: el[1] ? el[1] : '' });
      }
    });
    const data = {
      ContactID: contactID,
      ContactType: contactType.charAt(0),
      Features: [...Items]
    }
    editFeatures({ data, disableRefetchContact: true })
    setSubmitting(false);
  };

  const groupFeatures = useMemo(() => {
    let groupFeatures = featuresGroup.GroupFeatures.map(groupFeature => {
      let feature = globalFeatures && globalFeatures.find(feature => feature.ID === groupFeature.FeatureID);
      if (feature && feature.TypeName === 'Conversion') {
        feature = {
          ...feature,
          conversion: globalConversions && globalConversions.find(conversion => conversion.ID === feature.ConversionID)
        }
      }
      return {
        ...groupFeature,
        Feature: feature
      };
    });

    groupFeatures = groupFeatures.filter(feature => !((feature.Feature.GenderSpecificName === 'Male' && contact.G === 'Female') || (feature.Feature.GenderSpecificName === "Female" && contact.G === 'Male')));
    return groupFeatures;
  }, [featuresGroup, globalFeatures, globalConversions, contact]);

  return (
    <NwDrawer
        title='Edit Feature'
        onClose={onClose}>
        <FormContainer isMobile={breakpoints === 1}>
            <AreaName>{featuresGroup.Name}</AreaName>
            <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveFeatures(values, setSubmitting);
            }}>
            {({
                isSubmitting,
                values,
                setFieldValue,
                handleSubmit
            }) => (
                <NwForm onFinish={handleSubmit} style={{ paddingRight: "1rem" }}>
                <Row gutter={16}>
                    {groupFeatures.map(el => {
                    const isActive = updatedFieldIDs.find(fieldName => fieldName === el.ID);
                    return (
                        <React.Fragment key={el.ID}>
                        {!el.hidden && (
                            <Col span={(['Multichoice', 'BigText', 'Text', 'Conversion'].includes(el.Feature.TypeName) || breakpoints === 1) ? 24 : 12}>
                                <Fieldset>
                                    <div id={el.Feature.Name === editFeatureName ? 'active-feature' : ''}>
                                        <Label $isActive={isActive} htmlFor={el.itemid}>{el.Feature.Name}</Label>
                                    </div>
                                    {createFeatureItemForm(
                                        el,
                                        values[el.ID],
                                        globalConversions,
                                        setFieldValue,
                                        userGuiConfig,
                                        handleSubmit,
                                        isSubmitting,
                                        handleUpdatedFieldIDs,
                                        handleCurrentUpdatingFieldID,
                                        contact ? contact.G : null
                                    )}
                                </Fieldset>
                            </Col>
                        )}
                        </React.Fragment>
                    )
                    })}
                </Row>
                </NwForm>
            )}
            </Formik>
        </FormContainer>
    </NwDrawer>
  )
};

export default EditFeatureDrawer;
