import React, { useContext } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import NwEllipsis from "Components/Gui/NwEllipsis";
import { showDateNoTime, showDate, isAllDay, showTime, showDateNoTimeExtended } from 'Libs/NwMoment';
import { ListContext } from 'Contexts/ListContext';
import useEventStyle from 'Hooks/UseEventStyle';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";

const FollowUpDetails = ({ followUpData }) => {
    
    const followupStyle = useEventStyle({}, "FollowUp");
    const { cachedList } = useContext(ListContext);
    const followUpLabels = cachedList.followUpLabels.items || [];
    const followup = followUpLabels.find(item => item.Code === followUpData.FollowUpLabelCode);

    const isGloballyCancelled = () => {
        if (followUpData.Cancel) {
            if (followUpData.RelatedEvents && followUpData.RelatedEvents.length > 0) {
                if (followUpData.RelatedEvents.every(e => e.Cancel)) {
                    return true
                } else {
                    return false
                }
            }
            return true
        }
        return false
    }

    return (
        <EventDetailsWrapper className={isGloballyCancelled() ? 'is-cancelled' : null}>
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <div className='is-icon'>
                                <NwIcon icon={followupStyle.icon} />
                                <p className="is-label">{followup ? followup.Label: 'Follow-up Activity'}</p>
                            </div>
                        ),
                        isBorder: true,
                        width: "30%"
                    },
                    {
                        content: (
                            <>
                                {showDateNoTime(followUpData.StartDate) !== showDateNoTime(followUpData.EndDate) ?
                                    <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                        <FlexContainer column justifyContent='center' alignItems='center'>
                                            <div className='is-content big is-strong'>
                                                {(followUpData.GroupGuid || isAllDay(followUpData.StartDate, followUpData.EndDate)) ?
                                                    showDateNoTime(followUpData.StartDate) : showDate(followUpData.StartDate)}
                                            </div>
                                            <NwIcon icon={light('arrow-down')} />
                                        </FlexContainer>
                                        <div className='is-content big is-strong'>
                                            {(followUpData.GroupGuid || isAllDay(followUpData.StartDate, followUpData.EndDate)) ?
                                                showDateNoTime(followUpData.EndDate) : showDate(followUpData.EndDate)}
                                        </div>
                                    </FlexContainer>
                                    :
                                    <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                        <div className='is-content big is-strong'>{showDateNoTimeExtended(followUpData.StartDate)}</div>
                                        {(followUpData.GroupGuid || isAllDay(followUpData.StartDate, followUpData.EndDate)) ?
                                            <div className='is-content big is-strong is-uppercase'>All Day</div>
                                            :
                                            <>
                                                <FlexContainer column justifyContent='center' alignItems='center'>
                                                    <div className='is-content big is-strong'>{showTime(followUpData.StartDate)}</div>
                                                    <NwIcon icon={light('arrow-down')} />
                                                </FlexContainer>
                                                <div className='is-content big is-strong'>{showTime(followUpData.EndDate)}</div>
                                            </>
                                        }
                                    </FlexContainer>
                                }
                            </>
                        ),
                        isBorder: false,
                    }
                ]}
            />
            {followUpData.Service &&
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <p className="is-label">Service</p>
                        ),
                        isBorder: true,
                        width: "30%",
                    },
                    {
                        content: (
                            <p className="is-content is-stronger is-uppercase big">{followUpData.Service.Name}</p>
                        ),
                        isBorder: false,
                    }
                ]}
            />
            }
            {followUpData.Description && !followUpData.GroupGuid &&
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <p className="is-label">Description</p>
                        ),
                        isBorder: true,
                        width: "30%",
                    },
                    {
                        content: (
                            <p className="is-content small">
                                <NwEllipsis rows={3} text={followUpData.Description} />
                            </p>
                        ),
                        isBorder: false,
                    }
                ]}
            />
            }
            <ResponsiveFlexContainer
                columns={[
                    {
                        content: (
                            <p className="is-label">Set Model As</p>
                        ),
                        isBorder: true,
                        width: "30%",
                    },
                    {
                        content: (
                            <p className='is-content'>{followUpData.ModelNotAvailable ? 'Busy' : 'Available'}</p>
                        ),
                        isBorder: false,
                    }
                ]}
            />
        </EventDetailsWrapper>
    );
};

export default FollowUpDetails;