import { getMoment, isAllDay, isBirthday, isSameDate } from "Libs/NwMoment";
import Axios from "axios";
import { sortBy } from "lodash";
import { getScheduleEvents } from "../ScheduleUtils";
import { getSortOrder } from "Libs/Calendar";

export const loadMonthEvents = async (scheduleRange, params) => {
    const fom = getMoment(scheduleRange.start).clone().startOf("month").toDate()
    const lom = getMoment(scheduleRange.start).clone().endOf("month").toDate()
    const scheduleEvents = await getScheduleEvents(params, { start: fom, end: lom })
    return scheduleEvents
}

export const loadMonthBirthdays = async (scheduleRange) => {
    const fom = getMoment(scheduleRange.start).clone().startOf("month")
    const lom = getMoment(scheduleRange.start).clone().endOf("month")
    const bds = await Axios.post('/contacts/birthdaysinperiod', {StartDate: fom.format('YYYY-MM-DD'), EndDate: lom.format('YYYY-MM-DD') })
    return bds
}

export const getEventsForDate = (d, monthEvents, scheduleRange) => {
    const dateforevents = d ? getMoment(d) : (scheduleRange ? getMoment(scheduleRange.start) : getMoment())
    let datevents = []
    if (monthEvents && monthEvents.length) {
        datevents = monthEvents.filter((ev) => {
            const fd = getMoment(ev.FromDate)
            const td = getMoment(ev.ToDate)
            switch (ev.TypeName) {
                case "ToDo":
                    //date and reminder
                    return (fd.isSame(dateforevents, "day") || (ev.FirstDate && getMoment(ev.FirstDate).isSame(dateforevents, "day")))
                case "ExtJob":
                case "Travel":
                case "FollowUp":
                case "Accommodation":
                    //period
                    return getMoment(dateforevents).isBetween(fd, td, "day", '[]')
                default:
                    //single day - start and end time (meetings and booking events - multiple working days)
                    return fd.isSame(dateforevents, "day")
            }
        })
        datevents = sortBy(datevents, 'FirstDate')
    }
    return datevents
}

export const getBirthdaysForDate = (d, monthBirthdays, fullList) => {
    return monthBirthdays.filter((b) => {
        if (isBirthday(b.BirthDate, d)) {
            if (b.ContactType === "Model") {
                const model = fullList.find((m) => m.ID === b.ID);
                if (model) {
                    return true
                }
            } else {
                return true
            }
        }
        return false
    })
}

export const getSortedEvents = (displayedEvents, contactId) => {
    if (!displayedEvents || displayedEvents.length === 0) {
        return []
    }
    if (!contactId) {
        let sortedEvents = [...displayedEvents].map((ev) => (
            { ...ev, sortOrder: getSortOrder(ev)}
        ));
        return sortBy(sortedEvents, ['sortOrder', 'FromDate'])
    } else {
        let sortedEvents = [...displayedEvents];
        sortedEvents.sort((event1, event2) => {
            const event1FromDate = getMoment(event1.FromDate);
            const event1ToDate = getMoment(event1.ToDate);
            const event2FromDate = getMoment(event2.FromDate);
            const event2ToDate = getMoment(event2.ToDate);
            if (isSameDate(event1FromDate, event1ToDate) && isAllDay(event1FromDate, event1ToDate)) return -1;
            if (isSameDate(event2FromDate, event2ToDate) && isAllDay(event2FromDate, event2ToDate)) return 1;
            return event1FromDate > event2FromDate ? 1 : -1;
        })
        return sortedEvents
    }
}