import React, { useState, useEffect } from "react";
import ls from 'local-storage';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from 'styled-components';
import { Popover, Menu, Badge } from 'antd';
import _ from 'lodash';
import AddPackageDrawer from 'Components/ActivePackages/AddPackageDrawer';
import PackageDrawer from 'Components/PackageDrawer/PackageDrawer';
import NwIcon from 'Components/Gui/NwIcon';
import {
    useActivePackages,
    useAddPlaylistToPackage,
    useDeletePlaylistFromActivePackage,
    usePackage,
    useAddPackage,
    forceReloadActivePackages,
} from 'Hooks/Packages/UsePackages';
import { getContactName } from "Hooks/Contact/UseContactsList";

const Wrapper = styled.div`
    .ant-dropdown-menu-item-group-list {
        max-height: unset;
    }

    button {
        text-transform: lowercase;
        font-size: .8rem;
        margin-top: 4px;
    }
`;


const Content = styled.div`

    padding-bottom: 1rem;
    min-width: 20rem;

    .ant-menu-vertical {
        border-right: none;
    }

    .ant-menu {
        border-radius: 0 0 6px 6px;
    }
    .ant-menu-item {
        margin: 0 !important;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 2rem;
        line-height: 2rem;

        &:last-child {
            border-bottom: none;
        }

        text-transform: uppercase;
        font-size: .8rem;
        vertical-align: middle;

        svg  {
            margin: 0 6px;
        }

        .active {
            font-weight: bold;

            svg {
                color: ${props => props.theme.DangerColor};
            }
        }

        .badge {
            color: #fff;
            background-color: ${props => props.theme.DangerColor};
            display: inline-block;
            padding: .25em .4em;
            font-size: .75rem;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            margin-top: .2em;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
`;

const StyledBadge = styled.div`
    .ant-badge-count {
        background: ${props => props.theme.PrimaryColor};
        margin-top: 2px;
    }
    .ant-badge-multiple-words {
        padding: 0 3px;
    }
`;

const AddBookToPackageButton = ({
    book,
    packageId,
    activePackageOnly,
    children,
    placement = "top"
}) => {
    const { data: packages } = useActivePackages()
    const [menuActions, setMenuActions] = useState([])
    const latestPackageId = ls.get('nw_config_latest_package')
    const { mutateAsync: onAddPlaylistToPackage } = useAddPlaylistToPackage()
    const { mutateAsync: onDeletePlaylistFromActivePackage } = useDeletePlaylistFromActivePackage()
    const { mutateAsync: onAddPackage } = useAddPackage()
    const { data: currentPack } = usePackage(packageId)
    const [isAddNewPackage, setIsAddNewPackage] = useState(false)
    const [newPackageId, setNewPackageId] = useState()
    const [isInPackagesCount, setIsInPackagesCount] = useState(0);
    const [isSingleSend, setIsSingleSend] = useState(false);

    const getIsAddedBook = packId => {
        const pack = activePackageOnly ? packages.find(pack => pack.ID === packId) : currentPack;
        if (pack && pack.Models) {
            const model = pack.Models.find(model => model.Playlists.find(playlist => playlist.PlaylistID === book.ID));
            return model && model.Playlists.find(playlist => playlist.PlaylistID === book.ID);
        }
        return null;
    };

    useEffect(() => {
        if (packages) {
            let actions = []
            const packages_enriched = packages.map((p) => ({
                ...p,
                isAdded: getIsAddedBook(p.ID)
            }))
            const packagesWith = packages_enriched.filter((p) => { return p.isAdded })
            const packagesWithout = _.difference(packages_enriched, packagesWith)
            const newpackageaction = {
                name: "create a new package",
                key: "create-0",
                icon: light("book-arrow-right"),
                added: false
            }
            if (packagesWithout.length) {
                const sortedPackagesWithout = packagesWithout.sort(function (x, y) { return x.ID === latestPackageId ? -1 : y.ID === latestPackageId ? 1 : 0; });
                const subactions = sortedPackagesWithout.map((p) => (
                    {
                        name: `${p.Name}${(p.ID === latestPackageId) ? ' [last used]' : ''}`,
                        key: `add-${p.ID}`,
                        icon: light("plus-square"),
                        added: false
                    }
                ))
                actions.push({
                    group: true,
                    name: 'add to a package',
                    subactions: [newpackageaction, ...subactions]
                })
            } else {
                actions.push({
                    group: true,
                    name: 'add to a package',
                    subactions: [newpackageaction]
                })
            }
            if (packagesWith.length) {
                const removesubactions = packagesWith.map((p) => (
                    {
                        name: p.Name,
                        key: `remove-${p.ID}-${p.isAdded.ID}`,
                        icon: light("minus-square"),
                        added: true
                    }
                ))
                actions.push({
                    group: true,
                    name: 'remove from package',
                    subactions: [...removesubactions]
                })
            }
            setIsInPackagesCount(packagesWith.length)

            actions.push({
                group: true,
                name: 'quick send',
                subactions: [{
                    name: "send as a single book",
                    key: "send-0",
                    icon: light("book-circle-arrow-right"),
                    added: false
                }]
            });
            setMenuActions(actions)
        }
    }, [packages]);

    const onClick = (e) => {
        const action = e.key.split("-")[0]
        const packageId = Number(e.key.split("-")[1]);
        const packagePlaylistId = action === 'remove' && Number(e.key.split("-")[2]);

        switch (action) {
            case "create":
                handleAddNewPackage();
                break;
            case "add":
                addToPackage(packageId);
                break;
            case "remove":
                removeFromPackage(packageId, packagePlaylistId);
                break;
            case "send":
                addPackage();
                break;
            default:
                break;
        }
    }

    const addPackage = async () => {
        const data = {
            Name: getContactName(book.ContactID),
            Models: [{
                Playlists: [{
                    PlaylistID: book.ID,
                    Note: book.Name
                }],
                ModelID: book.ContactID
            }]
        }
        try {
            const res = await onAddPackage({ data });
            if (res) {
                setIsSingleSend(true);
                setNewPackageId(res.ID);
            }

        } catch (error) {
            console.log('ant : Add Package Error => ', error);
        }
    }

    const addToPackage = async packageId => {
        const data = {
            packageID: packageId,
            playlistID: book.ID
        }
        try {
            await onAddPlaylistToPackage({
                data,
                isActivePackage: packages.find(pack => pack.ID === packageId)
            });
            forceReloadActivePackages()
        } catch (error) {
            console.log('ant : Add Playlist To Package Error => ', error);
        }
    }

    const removeFromPackage = async (packageId, packagePlaylistId) => {
        try {
            await onDeletePlaylistFromActivePackage({
                packageID: packageId,
                packagePlaylistId: packagePlaylistId,
                isActivePackage: packages.find(pack => pack.ID === packageId)
            });
        } catch (error) {
            console.log('ant : Delete Playlist To Package Error => ', error);
        }
    };

    const renderAction = (action, index) => {
        if (action.group) {
            return ({
                type: "group",
                key:` group-${index}`,
                label: action.name,
                children: action.subactions.map(renderAction)
            });
        }
        return ({
            key: action.key,
            label: (
                <span>
                    <NwIcon icon={action.icon} />
                    {action.name}
                </span>
            ),
        });
    };

    const handleAddNewPackage = () => {
        setIsAddNewPackage(true);
    };

    const handleOpenPackage = packId => {
        setNewPackageId(packId);
    };

    const handleClosePackageDrawer = () => {
        setNewPackageId();
        setIsSingleSend(false);
    }
    
    return (
        <Wrapper>
            <Popover
                zIndex={9999}
                overlayClassName='folder-popover'
                placement={placement}
                trigger='click'
                content={
                    <Content>
                        <Menu
                            onClick={onClick}
                            className="nw-small-context-menu"
                            items={menuActions.map(renderAction)}
                        />
                    </Content>
                }
            >
                {children ? children
                    :
                    <StyledBadge>
                        <Badge count={isInPackagesCount} size='small'>
                            <NwIcon icon={light('book-arrow-right')} size='lg' />
                        </Badge>
                    </StyledBadge>
                }
                {/* {children} */}
            </Popover>
            {isAddNewPackage &&
                <AddPackageDrawer onAddedPackage={handleOpenPackage} bookId={book.ID} onClose={() => setIsAddNewPackage(false)} />
            }
            {newPackageId &&
                <PackageDrawer
                    packageId={newPackageId}
                    isSingleSend={isSingleSend}
                    onClose={handleClosePackageDrawer} />
            }
        </Wrapper>
    )
};


export default AddBookToPackageButton;