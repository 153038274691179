import React, { useState } from "react";
import { DatePicker } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from "styled-components";

import CustomSelect from 'Components/Gui/NWForm/CustomSelect';
import { getNow } from "Libs/NwMoment";
const { RangePicker } = DatePicker;

const FilterPeriodInputStyle = styled.div`
//   width: 300px;
`

const FilterPeriodInput = ({value, disabled, onChange }) => {

  const [showRangePicker, setShowRangePicker] = useState(typeof value !== "string");

  const filterValueRangePicker = () => {
    if (value && typeof value !== "string") {
      return [getMoment(value[0]), getMoment(value[1])];
    } else {
      return null;
    }
  };

  const filterValueSelect = () => {
    if (value && typeof value === "string") {
      const match = value.match(/\{(\w+)\}/);
      if (match) {
        return value;
      } else {
        return "{others}"; // others non può esserci
      }
    } else if (value) {
      return "{others}";
    } else {
      return null;
    }
  };

  const handleSelectChange = val => {
    if (val === "{others}") {
      onChange([getNow(), getNow()]);
      setShowRangePicker(true);
    } else {
      setShowRangePicker(false);
      onChange(val);
    }
  };

  const handleRangePickerChange = date => {
    if (date.length < 1) { // Reset
      setShowRangePicker(false);
    }
    onChange(date.length > 0 ? [getMoment(date[0].format()), getMoment(date[1].format())] : "{today}");
  };

  return (
    <FilterPeriodInputStyle>
      <CustomSelect
        width="100%"
        value={filterValueSelect(value)}
        onChange={val => handleSelectChange(val)}
        disabled={disabled}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        options={[
            { value: '{today_today}', label: 'Today' },
            { value: '{tomorrow_tomorrow}', label: 'Tomorrow' },
            { value: '{yesterday_yesterday}', label: 'Yesterday' },
            { value: '{currentweek}', label: 'Current Week' },
            { value: '{currentmonth}', label: 'Current Month' },
            { value: '{currentyear}', label: 'Current Year' },
            { value: '{lastweek}', label: 'Last Week' },
            { value: '{lastmonth}', label: 'Last Month' },
            { value: '{lastyear}', label: 'Last Year' },
            { value: '{last7days}', label: 'Last 7 days' },
            { value: '{last30days}', label: 'Last 30 days' },
            { value: '{last365days}', label: 'Last 365 days' },
            { value: '{next7days}', label: 'Next 7 days' },
            { value: '{next30days}', label: 'Next 30 days' },
            { value: '{next365days}', label: 'Next 365 days' },
            { value: '{others}', label: 'Select period' },
        ]}
        />
      {
        showRangePicker &&
        <RangePicker 
          autoComplete='off'
          style={{width: "100%"}}
          disabled={disabled}
          format="MMM DD, YYYY"
          value={filterValueRangePicker(value)}
          onChange={val => handleRangePickerChange(val)}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        />
      }
    </FilterPeriodInputStyle>
  );
};

export default FilterPeriodInput;
