import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";
const Option = Select.Option;

const VatSelector = ({ onChange, value, disabled, onBlur }) => {
    const { cachedList } = useContext(ListContext);
    const vatOptions = cachedList.taxmethods.items.VatCodes;

    return (
        <Select
            autoComplete='off'
            mode="single"
            value={value}
            placeholder="Please select"
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        >
            {vatOptions.map(el => (
                <Option key={el.Code} value={el.Code}>
                    {el.Name}
                </Option>
            ))}
        </Select>
    );
};

export default VatSelector;
