import React from 'react';
import styled from 'styled-components';

import { EventModelNameLineNonBooking } from '../EventDrawerUi/EventModelNameLineNonBooking';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteFollowup } from 'Hooks/Event/UseFollowup'
import { EventDetailsWrapper } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";
import FlexContainer from 'Components/Gui/FlexContainer';
import { isAllDay, showTime } from 'Libs/NwMoment';
import Axios from 'axios';

const ModelInfo = styled.div`
`;

const FollowUpModelDetails = ({ 
    afterRemove,
    afterUpdate, 
    event,
    onEditModelEvent,
    onTouchForm,
    onUpdateFollowUps,
    openedEventID,
    plainMode,
}) => {
    const { mutateAsync: onDeleteFollowup } = useDeleteFollowup();

    const handleRemoveEvent = async () => {
        try {
            await onDeleteFollowup({ ID: event.ID, followupId: openedEventID })
            onUpdateFollowUps()
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleUnlinkEvent = async () => {
        try {
            await Axios.put(`/followups/UnLink/${event.ID}`)
            onUpdateFollowUps()
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    };

    const modelNameLineProps = {
        actions:  {
            onEdit: onEditModelEvent,
            onDelete: (async () => {
                await handleRemoveEvent()
                if (openedEventID === event.ID) {
                    afterRemove()
                } else {
                    onUpdateFollowUps()
                    afterUpdate()
                }
            }),
            onUnlink: (() => handleUnlinkEvent()),
            onAcknowledgmentUpdate: (() => {
                onUpdateFollowUps()
                afterUpdate()
            }),
            onCancelUpdate: (() => {
                onUpdateFollowUps()
                afterUpdate()
            }),
        },
        eventProperties: {
            eventType: "FollowUp",
            isSingle: false,
            hasExport: true
        },
        event: event
    }

    return (
        <>
            <ModelInfo>
                <EventModelNameLineNonBooking {...modelNameLineProps} />
            </ModelInfo>
            <FlexContainer justifyContent="space-between" alignItems="flex-end" pd="16px 30px 16px 40px">
                <EventDetailsWrapper className={event.Cancel ? "is-cancelled" : null}>
                    <ResponsiveFlexContainer
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Description</p>
                                ),
                                width: "30%",
                                isBorder: true,
                            },
                            {
                                content: (
                                    <p className="is-content">{event.Description}</p>
                                ),
                                isBorder: false,
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Time</p>
                                ),
                                width: "30%",
                                isBorder: true,
                            },
                            {
                                content: (
                                    isAllDay(event.StartDate, event.EndDate) 
                                    ? 
                                        <p className="is-content">All Day</p>
                                    :
                                        <p className="is-content">{`${showTime(event.StartDate)} > ${showTime(event.EndDate)}`}</p>
                                ),
                                isBorder: false,
                            }
                        ]}
                    />
                </EventDetailsWrapper>
            </FlexContainer>
            <EventCommentsDocuments
                event={event}
                api='FollowUpID'
                type='FollowUp'
                contactId={event.ModelID}
                drawerMode={!plainMode}
                fetchEvent={onUpdateFollowUps}
                disableTransaction={plainMode || !event.Project}
                onTransactionCreatedOrUpdated={onUpdateFollowUps}
                onTouchForm={onTouchForm}
            />
        </>
    );
};

export default FollowUpModelDetails;