import React from 'react';
import { showDateNoTime } from 'Libs/NwMoment';
import { thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import BirthdayItem from './BirthdayItem';
import NwDrawer from 'Components/Gui/NwDrawer';

const BirthdaysDrawer = ({ birthdays, onClose }) => {

    return (
        <NwDrawer
            title='Birthdays'
            onClose={onClose}
        >
            <h2>
                <NwIcon icon={thin("birthday-cake")} />
                &nbsp;&nbsp;
                {(birthdays && birthdays.length > 0) ? showDateNoTime(birthdays[0].BirthDate, false) : ''}
            </h2>
            <br />
            {birthdays.map((birthday, index) => (
                <div style={{ paddingBottom: '1rem', marginBottom: '1rem', borderBottom: '1px solid #e8e8e8' }} key={`birthday-${birthday.ID}-${index}`}>
                    <BirthdayItem birthday={birthday} />
                </div>
            ))}
        </NwDrawer>
    )
}

export default BirthdaysDrawer;