import React from "react";
import { useProject } from "Hooks/Project/UseProject";
import { Loading } from "Components/Gui/Loading";
import NwDrawer from "Components/Gui/NwDrawer";
import SendProjectDrawerContent from "./SendProjectDrawerContent";

const SendProjectDrawerFromEvent = ({ event, onClose }) => {
    const { data: project, isFetching } = useProject(event.Project.ID);
    const EventType = event.TypeName;

    
    return (
        <NwDrawer
            title="Send Project Schedule"
            onClose={onClose}
            width="80vw"
            maxWidth="960px"
            id='send-project-drawer'>
            {(!project || isFetching) ?
                <Loading textBlack />
                :
                <SendProjectDrawerContent
                    currentProject={project}
                    //ModelID={ModelID}
                    EventID={event.ID}
                    EventType={EventType}
                    onClose={onClose}
                />
            }
        </NwDrawer>
    )
}

export default SendProjectDrawerFromEvent;