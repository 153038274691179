import React, { useContext } from 'react'
import { Row } from 'antd';

import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import { EventWrapper } from 'Components/EventsBoxes/EventBoxesUI';
import { TableColBorder } from 'Components/Gui/StyledComponent';
import EventBoxesModels from './EventBoxesModels';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { EventBoxDateAndTime } from './EventBoxesUtils';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { ListContext } from 'Contexts/ListContext';

const BookingEvent = ({
    event,
    onSelectEvent,
    contactId,
    page
}) => {

    const handleEventClick = () => {
        if (onSelectEvent) {
            onSelectEvent(event);
        }
    };

    const { cachedList } = useContext(ListContext);
    const getProjectCategory = (categoryID) => {
        const category = cachedList.projectCategories.items.find(category => category.ID ===categoryID)
        if (category) {
            return category.Name
        }
    }

    return (
        <EventWrapper $cancel={event.Cancel} page={page} onClick={handleEventClick}>
            <Row gutter={8} wrap={false}>
                <EventBoxDateAndTime isMultiDay firstDate={event.FirstDate} lastDate={event.LastDate} />
                <TableColBorder flex={(page === "project") ? "auto" : "2"}>
                    <div className="event-box-details">
                        {event.Project && (page !== "project") &&
                            <ProjectLine project={event.Project} />
                        }
                        <div className="event-box-details-title">
                            <EventTypeTag event={event} type={event.Type} />
                            <p>{event.Name}</p>
                        </div>
                        <div className="event-box-details-product">
                        {event.Project && (page !== "project") && event.Project.Product &&
                            <p>{event.Project.Product}</p>
                        }
                        {event.Project && (page !== "project") && event.Project.ProjectCategoryID &&
                            <p><em>{getProjectCategory(event.Project.ProjectCategoryID)}</em></p>
                        }
                        </div>
                        <div className="event-box-details-description">
                            <NwEllipsis style={{fontStyle: 'italic'}} noFontSize text={event.Description} rows={2} />
                        </div>
                        {page === "project" &&
                            <EventBoxesModels page={page} event={event} />
                        }
                    </div>
                </TableColBorder>
                {page !== "project" &&
                    <TableColBorder flex="3">
                        <EventBoxesModels page={page} event={event} />
                    </TableColBorder>
                }
            </Row>
        </EventWrapper>
    )
}

export default BookingEvent
