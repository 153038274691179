import React, { useState } from "react";
import styled from 'styled-components';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import CommentItem from 'Components/Comments/CommentItem';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import { NWListItem } from "Components/Gui/NWList";
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import BookingEventCommentForm from 'Components/EventDrawer/BookingEvent/BookingEventComments/BookingEventCommentForm';
import Visibilities from "Components/Gui/Visibilities";

const Wrapper = styled.div`
    .itemActions {
        padding-left: 8px;
    }
`;

const ProjectComment = ({
    comment,
    onUpdate,
    disableVisibility,
    project,
    events,
    modelId
}) => {
    const [loading, setLoading] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const [editingMode, setEditingMode] = useState(false);

    const deleteComment = async (commentId) => {
        setLoading(true)
        await Axios.delete(`/comments/${commentId}`)
            .then(() => {
                onUpdate();
            })
            .catch(error => {
                console.log("errore", error);
            });
        setLoading(false);
    };

    const handleCloseAddForm = () => {
        setEditingMode(false);
    }

    return (
        <>
            <div className="grid-card-body">
                <CommentItem item={comment} disableVisibility={true} events={events} rows={6} />
            </div>
            <div className="grid-card-footer">
                <NwDropDownMenu
                    loading={loading}
                    actions={[
                        { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => setEditingMode(true) },
                        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => { deleteComment(comment.ID) }) }
                    ]}
                />
                {!disableVisibility && <Visibilities visibilities={comment.Visibility.Areas} />}
            </div>
            {editingMode && (
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit comment'
                    onClose={handleCloseAddForm}>
                    <BookingEventCommentForm
                        events={events}
                        modelId={modelId}
                        api="ProjectID"
                        project={project}
                        noPadding
                        disableVisibility
                        onSave={save => {
                            if (save) {
                                onUpdate();
                            }
                            handleCloseAddForm();
                        }}
                        itemToEdit={comment}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            )
            }
        </>
    );
};

export default ProjectComment;
