import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';

const saveBillingProjectsSmartList = async customs => {
  try {
      await Axios.post('/userclientconfigurations', {
      Name: 'job-smart-list',
      JsonData: JSON.stringify({
          smartList: customs
      })
    });
  } catch (error) {
    console.log('ant : Save Jobs Smart List Error => ', error);
  }
};

const BillingProjectsFiltersReducer = (state, action) => {
  switch (action.type) {
    case "JobFiltersChangeActiveSet":
      ls.set("nw_config_jobwallfilter", {
          ...state,
          activeSet: action.newActiveSet,
          filterType: action.filterType
      });
      return {
        ...state,
        activeSet: action.newActiveSet,
        filterType: action.filterType
      };
    case "JobFiltersAddCustomSet":
      const customSetCopy = [...state.customSets];
      customSetCopy.push(action.newSet);
      const data = {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      }
      ls.set("nw_config_jobwallfilter", data);

      let smartList = [...data.customSets];

      saveBillingProjectsSmartList(smartList);
      return {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      };
    case "JobFiltersEditCustomSet":
      let customSetCopy2 = state.customSets.filter(
        el => el.id !== action.newSet.id
      );
      action.newSet.id = Date.now();
      customSetCopy2.push(action.newSet);
      const editData = {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };

      ls.set("nw_config_jobwallfilter", editData);

      let editSmartList = editData.customSets

      saveBillingProjectsSmartList(editSmartList);
      return {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };
    case "JobFiltersCloneSet":
      const copyOfActive = _.cloneDeep(action.activeSet);
      copyOfActive.id = Date.now();
      copyOfActive.fixed = false;
      copyOfActive.name = copyOfActive.name + "(copy)";
      const copyOfCustomSets = [...state.customSets];
      copyOfCustomSets.push(copyOfActive);

      const cloneData = {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };

      ls.set("nw_config_jobwallfilter", cloneData);
      
      let cloneSmartList = [...cloneData.customSets];

      saveBillingProjectsSmartList(cloneSmartList);
      return {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };
    case "JobFiltersDeleteSet":
      const newCustomSets = [...state.customSets].filter(
        el => el.id !== action.activeSet
      );
      const deleteData = {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v1'
      };

      ls.set("nw_config_jobwallfilter", deleteData);
      
      let deleteSmartList = [...deleteData.customSets];

      saveBillingProjectsSmartList(deleteSmartList);
      return {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v1'
      };
    case "JobSaveCustomSets":
        let newFilterData = { ...state, customSets: action.customSets };

        ls.set("nw_config_jobwallfilter", newFilterData);
        
        return newFilterData;
    default:
      //console.log("default del reducer")
      return state;
  }
};

export default BillingProjectsFiltersReducer;
