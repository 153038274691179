import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';

import { NwButton, NwLinkButton } from "Components/Gui/NwButton";
import { NWInput } from 'Components/Gui/NWForm/NwFormItems';
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import NwModal from 'Components/Gui/NwModal';
import { PackageContext } from '../PackageContext';
import { Input } from 'antd';

// const FormItem = styled.div`
//     display: flex;
//     align-items: flex-start;

//     .field-container {
//         width: 100%;
//     }
//     .ant-form-item {
//         margin-bottom: 0;
//     }
// `;

// const RadioWrapper = styled.div`
//     .ant-radio-wrapper {
//         margin-bottom: 10px;
//     }
// `;

const Wrapper = styled.div`
    .new-group-box {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: ${props => props.theme.DefaultBorder};
        p {
            margin: 0;
        }
        .field-and-button {
            margin-top: .5rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;
        }

    }
`

const GroupModal = ({ show, onClose, packageModelIDs, currentGroup, clearSelected }) => {
    
    const [newGroupName, setNewGroupName] = useState('');

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage } = packageContextValue;
    const { GroupNames, ModelIdsByGroup } = groupedPackage;
    
    const getNewOrder = (groupName) => {
        const newOrder = [];
        let foundGroup = false
        for (const g of ModelIdsByGroup) {
            const groupModels = [...g.modelIDs];
            const groupModelsWithoutSelected = groupModels.filter(modelID => !packageModelIDs.includes(modelID));
            if (g.group === groupName) {
                foundGroup = true;
                groupModelsWithoutSelected.push(...packageModelIDs);
            }
            newOrder.push(...groupModelsWithoutSelected)
        }
        if (!foundGroup) {
            newOrder.push(...packageModelIDs);
        }
        return newOrder;
    }

    const onSaveNewGroup = () => {
        onSaveGroup(newGroupName);
    }

    const onSaveGroup = async groupName => {
        try {
            if (groupName === 'No groups') {
                const dataClearGroup = {
                    PackageID: groupedPackage.ID,
                    ModelIDs: [...packageModelIDs],
                    ModelOrder: getNewOrder()
                }
                await Axios.put("/PackageModels/ClearGroup", dataClearGroup);
            } else {
                const dataGroupName = {
                    PackageID: groupedPackage.ID,
                    ModelIDs: [...packageModelIDs],
                    ModelOrder: getNewOrder(groupName),
                    GroupName: groupName
                }
                await Axios.put("/PackageModels/SetGroup", dataGroupName);
            }
            afterUpdatePackage()
            clearSelected()
            onClose();
        } catch (error) {
            console.log('ant : Update Group of PackageModel => ', error);
        }
    };

    return (
        <NwModal
            title='Change Group'
            open={show}
            footer={null}
            onCancel={() => {
                onClose()
            }}>
            <Wrapper>
                <p>
                    <NwLinkButton primary label="Clear Group" onClick={() => onSaveGroup('No groups')} />
                </p>
                {GroupNames && GroupNames.filter(name => name).length > 0 &&
                    GroupNames.map(groupName => (
                        <p>
                            <NwLinkButton
                                primary
                                disabled={groupName === currentGroup}
                                label={groupName}
                                onClick={() => onSaveGroup(groupName)}
                            />
                        </p>
                    ))
                }
                <div className="new-group-box">
                    <p>New Package Group</p>
                    <div className="field-and-button">
                        <Input
                            value={newGroupName}
                            onChange={e => setNewGroupName(e.target.value)}
                        />
                        <NwButton
                            primary
                            label="Save"
                            loadingLabel="Updating..."
                            onClick={() => onSaveNewGroup()}
                        />
                    </div>
                </div>
            </Wrapper>
        </NwModal>
    )

    // return (
    //     <NwModal
    //         touchForm={touchForm}
    //         onTouchForm={setTouchForm}
    //         title='Change Group'
    //         open={show}
    //         footer={null}
    //         onCancel={() => {
    //             onClose()
    //         }}>   
    //         <Formik
    //             initialValues={initialValues}
    //             validationSchema={Yup.object().shape({
    //                 GroupName: Yup.string().required("Content is required")
    //             })}
    //             onSubmit={(values, { setStatus, setSubmitting }) => {
    //                 setStatus();
    //                 submitForm(values, setSubmitting);
    //             }}
    //         >
    //             {({ isSubmitting, values, handleSubmit }) => ( 
    //                 <NwForm 
    //                     values={values}
    //                     onTouchForm={setTouchForm}
    //                     onFinish={handleSubmit} 
    //                     layout="vertical">
    //                     {GroupNames && GroupNames.filter(name => name).length > 0 && 
    //                         <RadioWrapper>
    //                             <RadioButtonGroup 
    //                                 value={selectedGroup} 
    //                                 onChange={value => {
    //                                     setSelectedGroup(value);
    //                                     onSaveGroup(value);
    //                                 }} 
    //                                 buttonType='radio'
    //                                 options={GroupNames.filter(name => name).map(groupName => (
    //                                     { label: groupName, value: groupName}
    //                                 ))} />
    //                         </RadioWrapper>
    //                     }
    //                     <div>New Package Group</div>
    //                     <FormItem>
    //                         <Field
    //                             component={NWInput}
    //                             // label="New Group"
    //                             name="GroupName"
    //                             type="text"
    //                             value={values.GroupName}
    //                         />  
    //                         &nbsp;
    //                         <NwButton
    //                             htmlType="submit"
    //                             disabled={isSubmitting}
    //                             loading={isSubmitting}
    //                             label="Save"
    //                             loadingLabel="Updating..."
    //                         />
    //                     </FormItem>
    //                 </NwForm>
    //             )}
    //         </Formik>
    //     </NwModal>
    // )
};

export default GroupModal;