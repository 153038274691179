import React from "react";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWTextAreaCharCounter,
    NWInput,
    NWSwitch,
    NWLongEventPeriodPicker,
    NWRadioButtonGroup
} from "Components/Gui/NWForm/NwFormItems";
// import { ListContext } from 'Contexts/ListContext';
import { useAddNotice, useUpdateNotice } from 'Hooks/Event/UseNotice';
import { getNow, getTime, isAllDay } from "Libs/NwMoment";

const NoticeForm = ({ 
    afterUpdate, 
    allDay, 
    dateSelected, 
    noticeData, 
    onCancel, 
    onTouchForm, 
    onUpdateNoticeData, 
    viewMode, 
}) => {
    const { mutateAsync: onAddNotice } = useAddNotice();
    const { mutateAsync: onUpdateNotice } = useUpdateNotice();

    let dataSource = [
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        },
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        },
        {
            component: NWLongEventPeriodPicker,
            label: 'Period',
            name: 'Period',
            Col: 24
        },
        {
            component: NWSwitch,
            text: 'Visible to models',
            name: 'ModelVisibility',
            required: true,
            Col: 24
        },
        {
            component: NWRadioButtonGroup,
            label: 'Privacy Level',
            name: 'PrivacyLevel',
            Col: 24,
            buttonStyle: "solid",
            options: [
                { key: 'NetworkAgency', value: 'NetworkAgency', label: 'Network Agency'},
                { key: 'Department', value: 'Department', label: 'Department' }
            ]
        }      
    ];

    let initialValues = {
        Description: noticeData ? noticeData.Description : '',
        Title: noticeData ? noticeData.Title : '',
        ModelVisibility: noticeData ? noticeData.ModelVisibility : true,
        PrivacyLevel: noticeData ? noticeData.PrivacyLevelName : 'NetworkAgency',
        StartDate: noticeData ? getMoment(noticeData.StartDate) : null,
        EndDate: noticeData ? getMoment(noticeData.EndDate) : null,
        Period: {
            startDate: noticeData ? getMoment(noticeData.StartDate) : 
                    dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            endDate: noticeData ? getMoment(noticeData.EndDate) : 
                    dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            startTime: getTime(noticeData ? getMoment(noticeData.StartDate) : 
                dateSelected ? allDay ? getMoment(dateSelected).startOf('day')
                    : dateSelected : getNow().hour(9).minute(0)),
            endTime: getTime(noticeData ? getMoment(noticeData.EndDate) : 
                dateSelected ? allDay ? getMoment(dateSelected).endOf('day')
                    : dateSelected.clone().add(2, 'hours') : getNow().hour(11).minute(0)),
            allDay: noticeData ? isAllDay(getMoment(noticeData.StartDate), getMoment(noticeData.EndDate)) : allDay || false,
            dates: []
        },
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        const newNoticeData = {
            ...initialValues,
            ...values,
            StartDate: getMoment(values.Period.startDate).hour(values.Period.startTime[0]).minute(values.Period.startTime[1]),
            EndDate: getMoment(values.Period.endDate).hour(values.Period.endTime[0]).minute(values.Period.endTime[1]),
        };
        if (!noticeData) {
            try {
                const response = await onAddNotice({ noticeData: newNoticeData });
                onTouchForm(false);
                if (afterUpdate){
                    afterUpdate({
                        ...response,
                        TypeName: "Notice"
                    });
                }
                onUpdateNoticeData(response.ID);
                onCancel();
            } catch (error) {
                console.log('ant : Add Notice Error => ', error);
            }
            setSubmitting(false);
        } else {
            newNoticeData.ID = noticeData.ID;
            try {
                await onUpdateNotice({ noticeData: newNoticeData });
                onTouchForm(false);
                if (afterUpdate){
                    afterUpdate();
                }
                onUpdateNoticeData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log('ant : Update Notice Error => ', error);
            }
            setSubmitting(false);
        }
    };

    let validationSchema = Yup.object().shape({
        Title: Yup.string().required("Title is required")
    });

    return (
        <EditEvent 
            submitForm={submitForm} 
            dataSource={dataSource} 
            mg={noticeData && '80px 0 0'}
            initialValues={initialValues}
            onCancel={onCancel}
            disableReinitialize
            onTouchForm={onTouchForm} 
            validationSchema={validationSchema} />
    )
};

export default NoticeForm;
