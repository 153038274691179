import React, { useState, useMemo } from 'react';
import Axios from 'axios';
import { List } from 'antd';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import NwDrawer from 'Components/Gui/NwDrawer'; 
import AddressLine from 'Components/Addresses/AddressLine';
import NwDivider from 'Components/Gui/NwDivider';
import { ModelCover } from 'Components/ModelCover';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { showDateNoTime, toISODate } from 'Libs/NwMoment';
import { getContactName, getModel } from 'Hooks/Contact/UseContactsList';
import useConfigurations from 'Hooks/UseConfigurations';
import { useStateValue } from 'Contexts/StateProvider';

const RoomCardContainer = styled(FlexContainer)`
    font-size: .95rem !important;
    font-weight: bold !important;
`;

const Link = styled.div`
    color: ${props => props.readOnly && props.theme.LighterBorderColor};
`;

const RoomPreview = ({
    room,
    modelId,
    readOnly,
    fromDate,
    toDate,
    calendarMode,
    onSelectRoom
}) => {
    const [isPreviewRoom, setIsPreviewRoom] = useState(false);
    const [accommodations, setAccommodations] = useState([]);
    const [connection, setConnection] = useState();
    const configurations = useConfigurations();
    const [{ areaContext }] = useStateValue();

    const handlePreviewRoom = async event => {
        event.stopPropagation();
        setIsPreviewRoom(true);
        try {
            const connection = await Axios.get(`/connections/${room.ConnectionID}`);
            setConnection(connection);

            const accommodations = await Axios.post('/accommodation/availabilitysearch', {
                RoomID: room.ID,
                ModelID: modelId,
                FromDate: fromDate && toISODate(fromDate),
                ToDate: toDate && toISODate(toDate),
            });
            setAccommodations(accommodations);
        } catch (error) {
            console.log('ant : Get Accommodations Error => ', error);
        }
    };

    const handleClosePreviewRoom = () => {
        setIsPreviewRoom(false);
    };

    const groupedAccommodations = useMemo(() => {
        let groupedAccommodations = [];
        for (const accommodation of accommodations) {
            const accommodationIndex = groupedAccommodations.findIndex(item => item.ModelID === accommodation.ModelID);
            if (accommodationIndex > -1) {
                groupedAccommodations[accommodationIndex].dates.push({
                    FromDate: accommodation.FromDate, 
                    ToDate: accommodation.ToDate
                });
            } else {
                groupedAccommodations.push({
                    ...accommodation,
                    dates: [{ FromDate: accommodation.FromDate, ToDate: accommodation.ToDate }]
                });
            }
        }

        return groupedAccommodations;
    }, [accommodations])

    return (
        <>
            <NwCard
                title={
                    <RoomCardContainer>
                        <NwIcon icon={light('check-circle')} onClick={!readOnly ? onSelectRoom : undefined} />&nbsp;
                        {room.ConnectionName}
                    </RoomCardContainer>
                }>
                <div>{room.Name}</div>
                <div>{`${room.NumBeds} beds / ${room.BedPrice} ${configurations.defaultCurrency} per night`}</div>
                <FlexContainer justifyContent='space-between' mg='8px 0 0'>
                    {calendarMode ? 
                        <Link 
                            readOnly={!fromDate && !toDate}
                            className='nw-link' onClick={(fromDate && toDate) ? handlePreviewRoom : undefined}>
                            Select model and period to check room availability</Link>
                        :
                        <>
                            <Link className='nw-link' readOnly={readOnly} onClick={!readOnly ? onSelectRoom : undefined}>Select Room</Link>
                            <div className='nw-link' onClick={handlePreviewRoom}>View More</div>
                        </>
                    }
                </FlexContainer>
            </NwCard>
            {isPreviewRoom && 
                <NwDrawer title={'Room Details'} onClose={handleClosePreviewRoom}>
                    <FlexContainer column alignItems='flex-start' mg='0 0 16px'>
                        <div>{connection && getContactName(room.ServiceID)}</div>
                        <div>{room.ConnectionName}</div>
                        {connection && connection.Addresses.length > 0 && 
                            <FlexContainer justifyContent='flex-start'>
                                <AddressLine address={connection.Addresses[0]} disableMap />
                            </FlexContainer>
                        }
                    </FlexContainer>
                    <div>{room.Name}</div>
                    <div>{`${room.NumBeds} beds / ${room.BedPrice} ${configurations.defaultCurrency} per night`}</div>
                    <NwDivider>Lodging</NwDivider>
                    <List
                        itemLayout="horizontal"
                        dataSource={groupedAccommodations}
                        renderItem={accommodation => {
                            const model = getModel(accommodation.ModelID);

                            let modelCoverData = {...model};
                            if (areaContext.area === 'management') {
                                const agency = model.NWAGS.find(nwAg => nwAg.CoverGuid);
                                modelCoverData = {
                                    ...modelCoverData,
                                    CG: agency && agency.CoverGuid
                                }
                            }
                            
                            return (
                                <List.Item className="ant-list-item-top">
                                    <List.Item.Meta
                                        avatar={<ModelCover model={modelCoverData} maxWidth={60} classN="model-cover-small" />}
                                        title={`${model.N} ${model.S}`}
                                        description={
                                            <>
                                                {accommodation.dates.map((date, index) => {
                                                    return (
                                                        <div key={`date-${index}`}>
                                                            {`${showDateNoTime(date.FromDate)} / ${showDateNoTime(date.ToDate)}`}
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        }
                    />
                </NwDrawer>
            }
        </>
    )
};

export default RoomPreview;