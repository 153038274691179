import React, { useState } from "react";
import styled from 'styled-components';

import NwDrawer from "Components/Gui/NwDrawer";
import ExtJobsEvent from 'Components/EventDrawer/ExtJobsEvent/ExtJobsEvent';
import BookingEvent from "Components/EventDrawer/BookingEvent/BookingEvent";
import FollowUpWrapper from "Components/EventDrawer/FollowUpEvent/FollowUpWrapper";
import TravelWrapper from "Components/EventDrawer/TravelsEvent/TravelWrapper";
import ScoutingTravelsEvent from 'Components/EventDrawer/ScoutingTravelsEvent/ScoutingTravelsEvent';
import ScoutingMeetingsEvent from 'Components/EventDrawer/ScoutingMeetingsEvent/ScoutingMeetingsEvent';
import ScoutingAccommodationEvent from 'Components/EventDrawer/ScoutingAccommodationEvent/ScoutingAccommodationEvent';
import AccommodationWrapper from 'Components/EventDrawer/AccommodationEvent/AccommodationWrapper';
import TaskWrapper from "Components/EventDrawer/TaskEvent/TaskWrapper";
import MeetingEvent from "Components/EventDrawer/MeetingEvent/MeetingEvent";
import NoticeEvent from "Components/EventDrawer/NoticeEvent/NoticeEvent";
import ProjectPackage from "Components/EventDrawer/ProjectPackage/ProjectPackage";
import { useEffect } from "react";

export const DrawerCloseButton = styled.span`
    position: absolute;
    top: .4em;
    left: 1em;
    z-index: 1040;
    font-size: 1.6em;
    cursor: pointer;
`

const EventDrawer = ({
    afterRemove,
    afterUpdate,
    allDay,
    contactId,
    dateSelected,
    drawerOrigin,
    eventID,
    eventInfo,
    eventType,
    modelId,
    onClose,
    onCloseNoReload,
    projectId,
    transactions,
    travelPlanId,
    viewMode,
}) => {

    const [selectedEventId, setSelectedEventId] = useState(eventID);
    const [selectedEventType, setSelectedEventType] = useState(eventType);
    const [touchForm, setTouchForm] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(740);
    const [eventUpdated, setEventUpdated] = useState(false);

    const handleSelectEvent = (eventId, eventType) => {
        setSelectedEventType(eventType);
        setSelectedEventId(eventId);
    };

    const handleClose = () => {
        if (onCloseNoReload) {
            if (eventUpdated) {
                onClose()
            } else {
                onCloseNoReload()
            }
        } else {
            onClose()
        }
    }

    const handleEventUpdate = (data) => {
        setEventUpdated(true)
        afterUpdate(data)
    }

    useEffect(() => {
        if (selectedEventType && selectedEventType.toLowerCase() === "package") {
            const width = window.innerWidth;
            let dwidth = 960;
            if (width < 900) {
                dwidth = "90vw"
            }
            setDrawerWidth(dwidth)
        }
    }, [selectedEventType])

    const renderEventDrawerContent = () => {
        const type = selectedEventType ? selectedEventType.toLowerCase() : '';
        switch (type) {
            case 'extjob':
                return (
                    <ExtJobsEvent
                        ID={eventID}
                        modelId={modelId}
                        viewMode={viewMode}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        onTouchForm={setTouchForm}
                        onClose={handleClose}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove} />
                );
            case 'travel':
                if (travelPlanId) {
                    return (
                        <ScoutingTravelsEvent
                            ID={selectedEventId}
                            filterModel={modelId}
                            travelPlanId={travelPlanId}
                            viewMode={viewMode}
                            dateSelected={dateSelected}
                            allDay={allDay}
                            transactions={transactions}
                            onTouchForm={setTouchForm}
                            onClose={handleClose}
                            afterUpdate={handleEventUpdate}
                            afterRemove={afterRemove} />
                    );
                }
                return (
                    <TravelWrapper
                        ID={selectedEventId}
                        modelId={modelId}
                        filterModel={modelId}
                        projectId={projectId}
                        viewMode={viewMode}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        onTouchForm={setTouchForm}
                        transactions={transactions}
                        onClose={handleClose}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent} />
                );
            case 'roomlodging':
            case 'accommodation':
                if (travelPlanId) {
                    return (
                        <ScoutingAccommodationEvent
                            ID={selectedEventId}
                            travelPlanId={travelPlanId}
                            viewMode={viewMode}
                            dateSelected={dateSelected}
                            allDay={allDay}
                            onTouchForm={setTouchForm}
                            onClose={handleClose}
                            afterUpdate={handleEventUpdate}
                            afterRemove={afterRemove} />
                    );
                }
                return (
                    <AccommodationWrapper
                        ID={selectedEventId}
                        projectId={projectId}
                        modelId={modelId}
                        viewMode={viewMode}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        type={selectedEventType}
                        onTouchForm={setTouchForm}
                        onClose={handleClose}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent} />
                );
            case 'meeting':
                if (travelPlanId) {
                    return (
                        <ScoutingMeetingsEvent
                            ID={selectedEventId}
                            viewMode={viewMode}
                            travelPlanId={travelPlanId}
                            contactId={contactId}
                            onTouchForm={setTouchForm}
                            onClose={handleClose}
                            allDay={allDay}
                            dateSelected={dateSelected}
                            afterUpdate={handleEventUpdate}
                            afterRemove={afterRemove} />
                    )
                }
                return (
                    <MeetingEvent
                        ID={selectedEventId}
                        viewMode={viewMode}
                        projectId={projectId}
                        travelPlanId={travelPlanId}
                        contactId={contactId}
                        onClose={handleClose}
                        onTouchForm={setTouchForm}
                        allDay={allDay}
                        dateSelected={dateSelected}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent} />
                );
            case 'todo':
                return (
                    <TaskWrapper
                        ID={eventID}
                        modelId={modelId}
                        projectId={projectId}
                        viewMode={viewMode}
                        onTouchForm={setTouchForm}
                        onClose={handleClose}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove} />
                );
            case 'followup':
                return (
                    <FollowUpWrapper
                        ID={selectedEventId}
                        modelId={modelId}
                        eventInfo={eventInfo}
                        filterModel={modelId}
                        viewMode={viewMode}
                        transactions={transactions}
                        projectId={projectId}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        onTouchForm={setTouchForm}
                        onClose={handleClose}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent} />
                );
            case 'notice':
                return (
                    <NoticeEvent
                        afterUpdate={handleEventUpdate}
                        viewMode={viewMode}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        onTouchForm={setTouchForm}
                        ID={eventID}
                        afterRemove={afterRemove}
                        onClose={handleClose}
                    />
                )
            case 'package':
                return (
                    <ProjectPackage
                        eventInfo={eventInfo}
                        ID={selectedEventId}
                        projectId={projectId}
                        viewMode={viewMode}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent}
                    />
                )
            default:
                return (
                    <BookingEvent
                        transactions={transactions}
                        filterModel={modelId}
                        eventInfo={eventInfo}
                        ID={selectedEventId}
                        projectId={projectId}
                        viewMode={viewMode}
                        dateSelected={dateSelected}
                        allDay={allDay}
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        afterUpdate={handleEventUpdate}
                        afterRemove={afterRemove}
                        onSelectEvent={handleSelectEvent}
                        onClose={handleClose}
                    />
                )
        }
    };

    return (
        <>
            <NwDrawer
                //title={`drawer from ${drawerOrigin || 'body'}`}
                padding="0px"
                width={drawerWidth}
                touchForm={touchForm}
                onTouchForm={setTouchForm}
                onClose={handleClose}
                closable={false}>
                {renderEventDrawerContent()}
            </NwDrawer>
        </>
    );
};

export default EventDrawer;
