import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import constants from 'Constants';

const getValidString = text => {
    if (!text) {
        return '';
    }
    return `${text} `;
}

const getAddressLatLng = async (address, countries) => {
    const country = countries.find(country => country.Code === address.Country);
    let addressKey = getValidString(country ? country.Name : '');
    addressKey += getValidString(address.City);
    addressKey += getValidString(address.State);
    addressKey += getValidString(address.County);
    addressKey += getValidString(address.Zip);
    addressKey += getValidString(address.Street);
    try {
        const addressOptions = await geocodeByAddress(addressKey);
        if (addressOptions.length > 0) {
            const { lat, lng } = await getLatLng(addressOptions[0]);
            return {
                event: address,
                address: addressOptions[0],
                lat,
                lng
            };
        }
    } catch (error) {
        console.log('ant : Get Google Address Error => ', error);
    }
    return null;
};

const getIsValidString = text => {
    if (!text) return false;
    if (text.split(' ').join('')) {
        return true;
    }
    return false;
};

const getEventDirection = (address, countries) => {
    const country = countries.find(country => country.Code === address.Country);
    let addressDetails = [];
    if (getIsValidString(country ? country.Name : '')) {
        addressDetails.push(country.Name);
    }
    if (getIsValidString(address.City)) addressDetails.push(address.City);
    if (getIsValidString(address.State)) addressDetails.push(address.State);
    if (getIsValidString(address.County)) addressDetails.push(address.County);
    if (getIsValidString(address.Zip)) addressDetails.push(address.Zip);
    if (getIsValidString(address.Street)) addressDetails.push(address.Street);
    return addressDetails.join('+');
    // return `https://www.google.com/maps/dir/?api=1&destination=${addressDetails.join('+')}`;
};

const getEventLocationIframeUrl = (event, countries) => {
    const link = `${constants.google_map_embed_link}${constants.google_map_api_key}&q=${getEventDirection(event, countries)}`;
    return `<iframe width="100%" height="450" frameborder="0" style="border:0" src="` + link + `" allowfullscreen></iframe>`;             
};

const getEventDirectionUrl = (event, countries) => {
    return `${constants.google_map_destination_link}${getEventDirection(event, countries)}`;
}

export {
    getAddressLatLng,
    getEventDirection,
    getEventLocationIframeUrl,
    getEventDirectionUrl
};