export const generateModelsDates = (project) => {
  const groupedItems = {
    models: [],
    eventDates: []
  };
  project.Events.forEach(event => {
    event.EventDates.forEach(eventDate => {
      eventDate.EventDatesModels.forEach(eventDatesModel => {
        if (!groupedItems.models.find(m => m.ModelID === eventDatesModel.ModelID)) {
          groupedItems.models = [...groupedItems.models, eventDatesModel];
        }
      })
      groupedItems.eventDates = [...groupedItems.eventDates, {
        ...eventDate,
        event
      }]
    })
  });
  groupedItems.eventDates = groupedItems.eventDates.sort((date1, date2) => new Date(date1.FromDate) - new Date(date2.FromDate));
  return groupedItems;
}