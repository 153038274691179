import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { forceReloadContact } from "Hooks/Contact/UseContact";

const editContactUser = (data) => (
    Axios.put('/contactusers', data)
)

const addContactUser = (data) => (
    Axios.post('/contactusers', data)
)

const deleteContactUser = ({ID, contactID}) => (
    Axios.delete(`/contactusers/${ID}`)
)

const useEditContactUser = () => {
    return useMutation(editContactUser, {
        onSuccess: (data, variables) => {
            forceReloadContact(data.ContactID)
        }
    })
}

const useAddContactUser = () => {
    return useMutation(addContactUser, {
        onSuccess: (data, variables) => {
            if (variables.ContactIDs.length === 1) {
                forceReloadContact(variables.ContactIDs[0])
            } else {
                //TODO: gestire aggiornamento lista modelle
            }
        }
    })
}

const useDeleteContactUser = () => {
    return useMutation(deleteContactUser, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactID)
        }
    })
}


export {
    useAddContactUser,
    useEditContactUser,
    useDeleteContactUser
}