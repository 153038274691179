import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import styled from 'styled-components';

import Filter from 'Components/Filters/FilterForm/Filter';
import CustomPropertyFilter from "Components/Filters/FilterForm/CustomPropertyFilter";
import { MODELS_FILTERS } from "Configs/Filters/MODELS_FILTERS";
import { NwButton } from 'Components/Gui/NwButton';
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import { useGlobalFeatureSets, useGlobalFeatures } from 'Hooks/Features/UseFeatures';
import { useStateValue } from 'Contexts/StateProvider';
import { FilterDrawerAction } from 'Components/Filters/FilterForm/FilterFormUI';
import NwDivider from 'Components/Gui/NwDivider';


const FormWrapper = styled.div`
    margin-bottom: 6rem;
`;

const FeatureGroup = styled.div`
    .title {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1.25rem;
    }
    .divider-wrapper {
        margin-bottom: 2rem;
    }
    margin-bottom: 2rem;
`;

const ModelFilterDrawer = ({ initialValues, selectedFilter, customFields, touchForm, onApplyFilters, onTouchForm }) => {
    const [filterValues, setValues] = useState(JSON.parse(JSON.stringify(initialValues)));
    const { data: globalFeatureSets } = useGlobalFeatureSets();
    const { data: globalFeatures } = useGlobalFeatures();
    const [{ areaContext }] = useStateValue();

    useEffect(() => {
        setValues(JSON.parse(JSON.stringify(initialValues)));
    }, [initialValues]);

    const handleFilterChange = (name, fieldType, value, values, isFeatures, fieldTypeName, active) => {
        //"fieldType" prop is "active" if a switch has been toggled and "value" if a value has been changed
        let filterValues = { ...values }
        if (isFeatures) {
            filterValues.Features[name][fieldType] = value
            if (fieldType === 'value') {
                filterValues.Features[name].active = active;
            }
        } else {
            filterValues[name][fieldType] = value;
            if (fieldType === 'value') {
                filterValues[name].active = active;
            }
        }
        if (fieldTypeName) {
            filterValues[name] = {
                ...filterValues[name],
                fieldTypeName: fieldTypeName
            }
        }
        setValues(filterValues);
        onTouchForm(true);
    }

    // useEffect(() => {
    //     console.log("current filtervalues", filterValues);
    // }, [filterValues]);
    
    const handleApplyFilters = () => {
        onApplyFilters(filterValues);
        onTouchForm(false);
    };

    const renderFilters = (values, selectedFilter) => {
        switch (selectedFilter) {
            case 'Work':
            case 'Bookers':
            case 'Location':
            case 'Media':
                return MODELS_FILTERS.filters
                    .filter(el => el.area === selectedFilter.toLowerCase()
                        && (areaContext.area === 'scouting' || el.name !== 'Scouter'))
                    .map(el => (
                        <Filter
                            key={el.name}
                            label={el.label}
                            name={el.name}
                            type={el.type}
                            values={values}
                            onChange={(fieldType, value, active) => handleFilterChange(
                                el.name,
                                fieldType,
                                value,
                                values,
                                null,
                                null,
                                active
                            )}
                        />
                    ));
            case 'Demographic':
                let filters = MODELS_FILTERS.filters
                    .filter(el => el.area === selectedFilter.toLowerCase())
                    .map(el => (
                        <Filter
                            key={el.name}
                            label={el.label}
                            name={el.name}
                            type={el.type}
                            values={values}
                            onChange={(fieldType, value, active) => handleFilterChange(
                                el.name,
                                fieldType,
                                value,
                                values,
                                null,
                                null,
                                active
                            )}
                        />
                    ));
                for (const customField of customFields) {
                    if (!customField.Disabled) {
                        filters.push(
                            <CustomPropertyFilter
                                key={customField.ID}
                                label={customField.Name.replace(/_/g, ' ')}
                                name={customField.Name}
                                type={customField.TypeName}
                                customField={customField}
                                values={values}
                                onChange={(fieldType, value, active) => {
                                    handleFilterChange(
                                        `custom_fields_${customField.Name}`,
                                        fieldType,
                                        value,
                                        values,
                                        false,
                                        customField.TypeName,
                                        active
                                    )
                                }}
                            />
                        );
                    }
                }
                return filters;
            default:
                const featureSet = globalFeatureSets && globalFeatureSets.find(featureSet => featureSet.Name === selectedFilter);
                return featureSet.FeatureGroups.map(featureGroup => (
                    <FeatureGroup key={`feature-group-${featureGroup.ID}`}>
                        {/* <div className='title'>{featureGroup.Name}</div> */}
                        <div className="divider-wrapper">
                            <NwDivider textSize="big" dark>{featureGroup.Name}</NwDivider>
                        </div>
                        {featureGroup.GroupFeatures.map(groupFeature => {
                            const feature = globalFeatures.find(feature => feature.ID === groupFeature.FeatureID);
                            return (
                                <Filter
                                    key={`group-feature-${groupFeature.ID}`}
                                    label={feature.Name}
                                    name={feature.ID}
                                    values={values}
                                    type={feature.TypeName}
                                    isFeature={true}
                                    onChange={(fieldType, value, active) => handleFilterChange(
                                        feature.ID,
                                        fieldType,
                                        value,
                                        values,
                                        true,
                                        null,
                                        active
                                    )}
                                />
                            )
                        })}
                    </FeatureGroup>
                )
                );
        }
    };

    return (
        <FormWrapper>
            <Formik
                id='subformik'
                initialValues={filterValues}
                enableReinitialize>
                {({ values }) => (
                    <NwForm
                        onTouchForm={onTouchForm}
                        values={values}
                        noValidate
                        layout="vertical"
                        id='subform'>
                        <>
                            {renderFilters(values, selectedFilter)}
                            <FilterDrawerAction fullWidth justifyContent='center'>
                                <NwButton
                                    disabled={!touchForm}
                                    primary
                                    label='Search'
                                    onClick={handleApplyFilters} />
                            </FilterDrawerAction>
                        </>
                    </NwForm>
                )}
            </Formik>
        </FormWrapper>
    )
};

export default ModelFilterDrawer;