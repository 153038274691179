import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import NwDrawer from "Components/Gui/NwDrawer";
import { Loading } from 'Components/Gui/Loading';
import Axios from 'axios';
import parse, { domToReact } from 'html-react-parser';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getContactName } from 'Hooks/Contact/UseContactsList';

const Wrapper = styled.div`
`;

const LoadingContainer = styled.div`
    position: absolute;
    position: absolute;
    right: 0;
    left: 0;
    top: 40%;
    z-index: 2000;
`;

const DrawerContent = styled.div`
    opacity: ${props => props.isSending && 0.4};
    
    .project-email-preview-header {
        margin-bottom: 2rem;
    }
`;

const DocContainer = styled.div`
    background: white;
    border: ${props => props.theme.DarkerBorder};
    border-radius: 4px;
    min-height: 200px;
    height: calc(100vh - 20rem);
    overflow: auto;
    padding: 1rem;
    .ck-content {
        height: 360px;
    }
`;


const ProjectEmailPreview = ({ doc, message, signature, onClose }) => {

    const [docVersion, setDocVersion] = useState();

    useEffect(() => {
        Axios.get(`/docversions/${doc.DocVersionID}`)
        .then(data => {
            setDocVersion(data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [doc]);

    const renderedHtml = useMemo(() => {
        console.log("signature", signature);
        if (docVersion && docVersion.Html) {
            let html = docVersion.Html;
            if (message) {
                html = html.replace('{{Message}}', message)
            }
            if (signature) {
                html = html.replace('{{Signature}}', signature.Signature)
            }
            const parsedHtml = parse(html);
            const body = parsedHtml.props.children.find(element => element.type === 'body');
            if (body) {
                const root = body.props.children.find(element => element.type === 'div');
                if (root) {
                    return root;
                }
            }
        }
        return '';
    }, [docVersion])

    return (
        <NwDrawer
            title="E-mail Preview"
            onClose={onClose}
            width="60vw"
            maxWidth="800px"
            id='mail-preview-drawer'>
            <DrawerContent>
                <div className='project-email-preview-header'>
                    <small><NwIcon icon={light("paper-plane")} /> project details for</small>
                    <h2>{getContactName(doc.ModelID)}</h2>
                </div>
                {docVersion
                    ?
                    <DocContainer>
                        {renderedHtml}
                    </DocContainer>
                    :
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                }
            </DrawerContent>
        </NwDrawer>
    );
};

export default ProjectEmailPreview