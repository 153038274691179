import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { UserContextProvider } from 'Contexts/UserContext';
import { ListContextProvider } from 'Contexts/ListContext';
import { ConfigContextProvider } from 'Contexts/ConfigContext';
import NavigationContext from 'Contexts/NavigationContext'
import NavigationReducer from 'Contexts/NavigationReducer'
import { StateProvider } from 'Contexts/StateProvider'

import App from 'App';

export const UpdateCalendarContext = createContext(null);

const GlobalContext = () => {
    const [updateCalendar, setUpdateCalendar] = useState(false);

    return (
        <BrowserRouter>
            <StateProvider initialState={NavigationContext} reducer={NavigationReducer}>
                <ListContextProvider>
                    <UserContextProvider>
                        <ConfigContextProvider>
                            <UpdateCalendarContext.Provider value={{ updateCalendar, setUpdateCalendar }}>
                                <App />
                            </UpdateCalendarContext.Provider>
                        </ConfigContextProvider>
                    </UserContextProvider>
                </ListContextProvider>
            </StateProvider>
        </BrowserRouter>
    )
}
const GlobalContextProvider = GlobalContext//process.env.NODE_ENV === "development" ? hot(GlobalContext) : GlobalContext
export default GlobalContextProvider
