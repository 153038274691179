import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const onGetProjectAcctDetailsByID = ID => (
    Axios.get(`/projects/getdetailsforaccounting/${ID}`)
)

const useProjectAccountingDetails = (ID) => {
    return useQuery(["projectaccountingdetails", ID], () => onGetProjectAcctDetailsByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false
    });
}


const forceReloadProjectAccountingDetails = (ID) => {
    return queryClient.invalidateQueries(['projectaccountingdetails', ID])
}

export {
    useProjectAccountingDetails,
    forceReloadProjectAccountingDetails
};


