import React, { useContext, memo } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from 'Contexts/UserContext';
// import { isEmpty } from 'Libs/NwUtils';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useContext(UserContext);

    return currentUser ? 
        <Route 
            {...rest}
            render={props => <Component {...props} />}
        />
        : 
        <Route 
            {...rest}
            render={props =>
                <Redirect 
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                }}/>
            }
        />

        // return (<Route {...rest} render={props => {
        //     const currentUser = this.context.currentUser;
        //     if (!currentUser) {
        //         // not logged in so redirect to login page with the return url
        //         return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        //     }

        //     // check if route is restricted by role
        //     if (roles && roles.indexOf(currentUser.role) === -1) {
        //         // role not authorised so redirect to home page
        //         return <Redirect to={{ pathname: '/'}} />
        //     }

        //     // authorised so return component
        //     return <Component {...props} />
        // }} />)

}

export default memo(PrivateRoute)
