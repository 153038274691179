import React, { useContext } from 'react'
import NwIcon from 'Components/Gui/NwIcon';
import { ListContext } from 'Contexts/ListContext';
import { showLogNoTime } from "Libs/NwMoment";
import { getVideoCover } from 'Libs/NwUtils';
import { NwTag } from 'Components/Gui/NwTag';
import { brands, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MediaItem } from './ModelDetailsMediaUI';




const ModelMediaItem = ({ media, type, onMediaClicked }) => {
    const { GetUserNameShort } = useContext(ListContext);
    const coverUrl = type == "video" ? getVideoCover(media) : (media.Thumbnail ? media.Thumbnail.Url : null);

    const MediaDescriptors = ({ media }) => {
        let line1 = GetUserNameShort(media.UserCreated) + ", " + showLogNoTime(media.DateCreated);
        let line2 = "";
        if (media.Notes) {
            line2 += media.Notes.substring(0, 80);
            if (media.Notes.length > 79) {
                line2 += "(...)";
            }
        }
        return (
            <>
                <p>{line1}</p>
                <p>{line2}</p>
            </>
        )
    }

    const getItemStyle = () => {
        const style = {}
        if (coverUrl) {
            style.backgroundImage = `url(${coverUrl})`;
        } else {
            if (type === "video") {
                style.backgroundColor = "black"
            }
        }
        return style
    }

    let typeForClass = (type === "video" && media.caption === "ENCODING VIDEO") ? "encoding-video" : type

    const handleClick = () => {
        if (media.caption !== "ENCODING VIDEO") {
            if (onMediaClicked) {
                onMediaClicked(media)
            }
        }
    }

    return (
        <MediaItem className={typeForClass} onClick={handleClick} style={{ width: media.thumbnailWidth, height: media.thumbnailHeight }}>
            {media.caption !== "ENCODING VIDEO" &&
                <div className='overlay'>
                    <div className="text-container">
                        <MediaDescriptors media={media} />
                    </div>
                </div>
            }
            <div className="cover-container" style={getItemStyle()}>
                {media.caption === "ENCODING VIDEO"
                    ?
                    <div className="icons-container">
                        <h1>Encoding video</h1>
                        <small>Please wait...</small>
                        <NwIcon icon={light("camera-movie")} beat />
                    </div>
                    :
                    <>
                        <div className="icons-container">
                            {type === "video" &&
                                <>
                                    <span><NwIcon icon={solid('play')} /></span>
                                    {media.Provider === 'Vimeo' && <span><NwIcon icon={brands('vimeo')} /></span>}
                                    {media.Provider === 'Youtube' && <span><NwIcon icon={brands('youtube')} /></span>}
                                </>
                            }
                        </div>
                        <div className="tags-container">
                            {media.Tags.map(tag => {
                                return (
                                    <NwTag type="dark" key={tag}>{tag}</NwTag>
                                )
                            })}
                        </div>
                    </>
                }
            </div>
        </MediaItem >
    )
};

export default ModelMediaItem;
