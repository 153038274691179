import styled from 'styled-components';
// import { Tag } from 'antd';
// import StyleVariables from 'Components/Gui/StyleVariables';

const TasksContainer = styled.div`
    
    .ant-card-head {
        display: none;
    }

    .ant-card {
        border-radius: 0 0 6px 6px;
        height: 100%;
    }
    .ant-card-body-content {
        padding: 0 !important;
    }
    .todo-item {
        border-right: ${props => props.theme.LighterBorder};
        border-bottom: ${props => props.theme.LighterBorder};
    }
    .ant-card-bordered {
        border-top: none;
    }

    opacity: ${props => (props.showDetails ? "1" : "0")};
    max-height: ${props => (props.showDetails ? "100%" : "0")};
    overflow: hidden;
    transition: all 0.3s;
`;


const TasksListContainer = styled.div`
    width: 100%;
    .ant-card-body-content {
        justify-content: center;
        font-size: 1rem;
    }

    .todos {
        cursor: pointer;
    }
`;

const TasksListHeader = styled.div`
    .ant-card-bordered {
        border: ${props => props.theme.DefaultBorder};
        height: 52px;
    }

    .ant-card {
        border-radius: ${props => props.isContent ? '6px 6px 0 0' : '6px'};
    }
`;

const TaskCheckDropdownItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
        margin-right: 16px;
    }
`;

const TaskContainer = styled.div`
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 3rem;
    
    .task-body {
        display: flex;
        flex-direction: row;
        padding: .8rem .8rem 0 .8rem;


        .task-toolbar {
            font-size: 1.4rem;
            padding: 0 .75rem 0 0;
        }

        .task-content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: ${props => props.showAll ? "100%" : "11rem"};
            width: 100%;

            .task-personal-tag {
                position: absolute;
                top: 0;
                right: 0;
            }

            .task-content-top {
                //width: 100%;

                .with-tag {
                    margin-right: 5rem !important;
                }
                .task-projectline-container {
                    margin-bottom: .5rem;
                    padding-top: .25rem;
                }

                .task-title {
                    text-transform: uppercase;
                    margin: .25rem 0 .5rem 0;
                    line-height: 1.25em;
                    font-size: 1rem;
                    font-weight: 600;
                }
                .task-description {
                    margin: 0 0 .5em 0;
                    line-height: 1.4em;
                    font-size: .85rem;
                }
            }
            
            .task-content-bottom {
                opacity: .75;
                font-size: .8rem;
                margin-top: .25em;
                width: 100%;
                text-align: right;

                em {
                    font-style: normal !important;
                }
            }
        }
    }

    .task-bottom {
        margin-top: auto;
        
        .task-contacts {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 0 .5rem 3rem;
        }
        
        .task-time {
            border-top: ${props => props.theme.LighterBorder};
            padding: .5em;
            min-height: 2.75rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            //width: 100%;

            .time-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 1.15rem;

                span {
                    font-size: .85rem;
                }

                svg {
                    margin: 0 .75rem 0 .5rem;
                }
            }
        }
    }
`;

export {
    TasksListContainer,
    TasksListHeader,
    TasksContainer,
    TaskCheckDropdownItem,
    TaskContainer
}