import React, { useState } from 'react';
import { Select, InputNumber, Input } from "antd";
import styled from "styled-components";

const InputGroup = Input.Group;
const Option = Select.Option;
const StyledQuantitySelector = styled.div`
`
const StyledInputGroup = styled(InputGroup)`
  white-space: nowrap;
`

export const QUANTITIES = [
  { value: "Units", label: "Unit(s)" },
  { value: "Days", label: "Day(s)" },
  { value: "Hours", label: "Hour(s)" },
]

const QuantitySelector = props => {

  const [localInputState, setLocalInputState] = useState(props.value[0]);

  const handleSelectChange = (val) => {
    props.onChange([props.value[0], val]);
  }

  const handleOnBlur = (e) => {
    props.onChange([localInputState, props.value[1]]);
  }

  return (
    <StyledQuantitySelector>
      <StyledInputGroup compact>
        <InputNumber
          autoComplete='off'
          style={{ width: "5.5rem", textAlign: "center" }}
          min={0.25}
          step={0.25}
          defaultValue={props.value[0]}
          onChange={val => setLocalInputState(val)}
          onBlur={e => handleOnBlur(e)}
        />
        <Select
          autoComplete='off'
          style={{ width: "6.5rem", textAlign: "center" }}
          disabled={props.disabled}
          value={props.value[1]}
          onChange={val => handleSelectChange(val)}
        >
          {QUANTITIES.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)}
        </Select>
      </StyledInputGroup>
    </StyledQuantitySelector>
  );
};

export default QuantitySelector;