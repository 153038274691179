import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import Axios from 'axios';
import { Popover } from 'antd';
import MiniLoading from 'Components/Gui/MiniLoading';
import { showLog } from 'Libs/NwMoment';

const EmailItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px 8px 12px;
    
    .email-item-left {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1 0;

        .email-address {
            font-size: .9rem;
            font-weight: bold;
            text-transform: lowercase;
            flex: 1 1 0;
            text-align: right;

            small {
                display: block;
                font-weight: normal;
            }
        }

        .email-contact {
            font-size: .8rem;
            small {
                text-transform: uppercase;
                font-size: .75em;
            }
            p {
                text-transform: uppercase;
                margin: 0;
                line-height: 1em;
            }
        }

        .email-buttons {
            padding: 0 1rem 0 2rem;
        }

        &.email-item-disabled {
            opacity: .4;
            cursor: not-allowed;
        }
    }
    
    .email-item-right {
        font-size: 1.5rem;
        cursor: pointer;
        padding: 0 1rem;
    }
`

const EmailInfoPopover = styled.div`
    min-width: 20rem;
    min-height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

// EMAIL OBJECT
// {
//     "ContactID":99,
//     "ContactName":"Andrea Riccadonna",
//     "ConnectionID":159,
//     "ConnectionName":"Company Address", [connection.name + (' ' + connection.surname)]
//     "ConnectionRole": "casting director",
//     "ConnectionNote": "note on this connection",
//     "ContactAlert": "Alert on contact"
//     "Email":"company@netwalkapp.com"
//      EmailID:
//      ConnectionTypeName
//      ConnectionTypeValue
//      ConnectionSubTypeID
//      ContactSubTypeName
//      ContactSubTypeValue
// }


const EmailSearchItem = ({
    email,
    selected,
    onSelect,
    clickable = true
    //buttons
}) => {

    const [emailInfo, setEmailInfo] = useState(null)

    const getCompanyNameAndRole = (item) => {
        if (!item.ContactName) {
            return ('added manually')
        }
        let name = item.ContactName
        if (item.ConnectionRole) {
            name += ' / ' + item.ConnectionRole
        }
        return name
    }

    const getConnectionName = (item) => {
        if (item.ConnectionName) {
            return item.ConnectionName
        }
        return ''
    }

    const onEmailClick = (item) => {
        if (clickable) {
            onSelect(item)
        }
    }

    const onEmailInfoPopover = (open, email) => {
        if (open) {
            Axios.get("/connections/emails/usagestats/" + email).then(res => {
                setEmailInfo({...res, email: email})
            })
        } else {
            setEmailInfo(null)
        }
    }

    const EmailInfo = useMemo(() => {
        if (emailInfo) {
            return (
                <EmailInfoPopover>
                    <p><strong>{emailInfo.email}</strong></p>
                    <p><strong>{emailInfo.SentCount}</strong> e-mails sent</p>
                    <p>last e-mail sent: <strong>{emailInfo.LastSentDate ? showLog(emailInfo.LastSentDate) : "never"}</strong></p>
                </EmailInfoPopover>
            )
        } else {
            return (<EmailInfoPopover><MiniLoading /></EmailInfoPopover>)
        }
    }, [emailInfo])

    return (
        <EmailItemContainer>
            <div className={`email-item-left${selected ? " email-item-disabled" : ""}`} onClick={() => !selected ? onEmailClick(email) : null}>
                <div className="email-contact">
                    <small>{getCompanyNameAndRole(email)}</small>
                    <p>{getConnectionName(email)}</p>
                </div>
                <div className='email-address'>
                    <NwIcon icon={light("check")} size="sm" />&nbsp;{email.Email}
                    {email.CC && email.CC.length > 0 &&
                        <small>CC: {email.CC.join(',')}</small>}
                </div>
                {/* {buttons && !selected &&
                    <div className='email-buttons'>{buttons}</div>
                    } */}
            </div>
            <div className="email-item-right">
                <Popover
                    placement="left"
                    mouseEnterDelay={.25}
                    content={EmailInfo}
                    title="E-mail address usage history"
                    onOpenChange={(open) => onEmailInfoPopover(open, email.Email)}
                >
                    <NwIcon icon={light("info-circle")} />
                </Popover>
            </div>
        </EmailItemContainer>
    )
};

export default EmailSearchItem;