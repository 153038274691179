import React from 'react';
import styled from 'styled-components';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';

const Wrapper = styled(FlexContainer)`
    border-top: 2px solid #e8e8e8;
    background: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 24px;

    svg {
        font-size: 1.6rem;
    }

    .active {
        color: ${props => props.theme.PrimaryColor};
    }
`;

const ModelDetailsMobileFooter = ({ 
    viewType,
    onViewModelProfile,
    onViewCalendar,
    onViewModelBooks,
    onViewFiles,
    onViewComments,
    onViewStatement
}) => {
    const getActiveClassName = view => {
        if (view === viewType) return 'active';
        return '';
    }

    const getActiveIcon = (view, iconName) => {
        if (view === viewType) {
            switch (iconName) {
                case 'id-card':
                    return solid('id-card');
                case 'calendar':
                    return solid('calendar');
                case 'images':
                    return solid('images');
                case 'file-pdf':
                    return solid('file-pdf');
                case 'comments':
                    return solid('comments');
                case 'balance-scale':
                    return solid('balance-scale');
                default: return "";
            }
        }
        switch (iconName) {
            case 'id-card':
                return light('id-card');
            case 'calendar':
                return light('calendar');
            case 'images':
                return light('images');
            case 'file-pdf':
                return light('file-pdf');
            case 'comments':
                return light('comments');
            case 'balance-scale':
                return light('balance-scale');
            default: return "";
        }
    }

    return (
        <Wrapper justifyContent='space-between'>
            <NwIcon icon={getActiveIcon('profile', 'id-card')} className={getActiveClassName('profile')} pointer onClick={onViewModelProfile} />
            <NwIcon icon={getActiveIcon('calendar', 'calendar')} className={getActiveClassName('calendar')} pointer onClick={onViewCalendar} />
            <NwIcon icon={getActiveIcon('books', 'images')} className={getActiveClassName('books')} pointer onClick={onViewModelBooks} />
            <NwIcon icon={getActiveIcon('files', 'file-pdf')} className={getActiveClassName('files')} pointer onClick={onViewFiles} />
            <NwIcon icon={getActiveIcon('comments', 'comments')} className={getActiveClassName('comments')} pointer onClick={onViewComments} />
            <NwIcon icon={getActiveIcon('statement', 'balance-scale')} className={getActiveClassName('statement')} pointer onClick={onViewStatement} />
        </Wrapper>
    )
};

export default ModelDetailsMobileFooter;