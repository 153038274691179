import { getContactName } from "Hooks/Contact/UseContactsList";
import { getModelsFromEventDates } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';

export const getModelsByEvent = (event, currentProject) => {
  if (!event) {
    return currentProject.ProjectModels.map(model => {
      return {
        ModelId: model.ModelID,
        Name: getContactName(model.ModelID)
      }
    });
  }

  if (event && (event.elType === 'Travel' || event.elType === 'FollowUp' || event.elType === 'Accommodation')) {
    return event.Models.map(modelEvent => {
      return {
        ModelId: modelEvent.ModelID,
        Name: getContactName(modelEvent.ModelID)
      }
    });
  }

  if (event.EventDates) {
    return getModelsFromEventDates(event.EventDates);
  }

  return [];
}