import React, { useEffect, useState } from "react";
import { Badge } from "antd";
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AddressForm from "Components/Connection/AddressForm";
import EmailForm from "Components/Connection/EmailForm";
import PhoneForm from "Components/Connection/PhoneForm";
import BaseItem from "Components/Connection/BaseItem";
import ConnectionDetailForm from "Components/Connection/ConnectionDetailForm";
import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwIcon from "Components/Gui/NwIcon";
import NwDrawer from "Components/Gui/NwDrawer";
import StyleVariables from 'Components/Gui/StyleVariables';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwDivider from 'Components/Gui/NwDivider';
import { showDateNoTime } from "Libs/NwMoment";
import { useDeleteContactConnection } from "Hooks/Contact/UseContactConnections";

const BaseItemWrapper = styled.div`
    padding-left: 24px;
`;

const ConnectionCard = ({ onSelect, item, readOnly, contactID, onAddToMailingList }) => {
    const [mode, setMode] = useState("SHOW");
    const [addingNewItem, setAddingNewItem] = useState(null); // ADDRESS - PHONE - EMAIL - CONNECTION
    const [touchForm, setTouchForm] = useState(false);
    const { mutate: deleteConnection } = useDeleteContactConnection()

    useEffect(() => {
        if (item && onSelect) {
            onSelect(item.ID)
        }
    }, [item]);

    const ConnectionCardTitleSecondLine = () => {
        let line = item.SubTypeName || ""
        if (item.Role) {
            if (line.length) {
                line += " - "
            }
            line += item.Role
        }
        return line;
    }

    const ConnectionCardTitle = () => (
        <div className='card-header'>
            <div className='title'>{item.Name} {item.Surname || ""}</div>
            {item.TypeName !== "Cc" && 
                <div className='label'>{ConnectionCardTitleSecondLine()}</div>
            }
        </div>
    )

    const renderTypeIcon = () => {
        switch (item.TypeName) {
            case "Personal":
                return light("home-heart");
            case "Place":
                return light("home-alt");
            case "People":
                return light("user");
            case "Cc":
                return light("user-plus");
            case "Room":
                return light('bed');
            default:
                return "";
        }
    };

    const deleteElement = () => {
        deleteConnection({connectionID: item.ID, contactID: contactID})
    };

    const renderMenuSubItem = () => (!readOnly ?
        <NwDropDownMenu
            size='small'
            actions={[
                { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => setMode("EDIT"))},
                { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => deleteElement())},
                { name: "new address", type: "address", key: "address", icon: solid("plus-circle"), action: (() => handleCreateContactInfo('Address'))},
                { name: "new email", type: "email", key: "email", icon: solid("plus-circle"), action: (() => handleCreateContactInfo('Email'))},
                { name: "new phone", type: "phone", key: "phone", icon: solid("plus-circle"), action: (() => handleCreateContactInfo('Phone'))}
            ]}
        />
        : <div />
    );

    const handleCreateContactInfo = key => {
        setAddingNewItem(key);
    };

    const renderCard = () => {
        return (
            <NwCard 
                title={ConnectionCardTitle()} 
                extra={renderMenuSubItem()}>
                {item.Note && (
                    <FlexContainer mg='0 24px'>
                        {item.Note}
                    </FlexContainer>
                )}
                {item.Birthday && (
                    <FlexContainer mg='0 24px'>
                        <NwIcon icon={light("birthday-cake")} />&nbsp;{showDateNoTime(item.Birthday)}
                    </FlexContainer>
                )}
                {(item.Addresses.length > 0) &&
                    <>
                        <NwDivider>Addresses</NwDivider>
                        <BaseItemWrapper className='base-item'>
                            <ul>
                                {item.Addresses.map(address => (
                                    <li key={address.ID}>
                                        <BaseItem item={address} readOnly={readOnly} contactID={contactID} connectionID={item.ID} type="address" />
                                    </li>
                                ))}
                            </ul>
                        </BaseItemWrapper>
                    </>
                }
                {(item.Emails.length > 0) && 
                    <>
                        <NwDivider>E-mail</NwDivider>
                        <BaseItemWrapper className='base-item'>
                            <ul>
                                {item.Emails.map(email => (
                                    <li key={email.ID}>
                                        <BaseItem item={email} readOnly={readOnly} contactID={contactID} noMainIcon={item.TypeName === "Cc"} connectionID={item.ID} type="email" onAddToMailingList={onAddToMailingList} />
                                    </li>
                                ))}
                            </ul>
                        </BaseItemWrapper>
                    </>
                }
                {item.Phones.length > 0 &&
                <>
                    <NwDivider>Phones</NwDivider>
                    <BaseItemWrapper className='base-item'>
                        <ul>
                            {item.Phones.map(phone => (
                                <li key={phone.ID}>
                                    <BaseItem item={phone} contactID={contactID} readOnly={readOnly} connectionID={item.ID} type="phone" />
                                </li>
                            ))}
                        </ul>
                    </BaseItemWrapper>
                </>
                }
                {mode === "EDIT" &&
                    <NwDrawer 
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title={`Edit Connection`} 
                        onClose={() => setMode("SHOW")}>
                        <ConnectionDetailForm
                            contactID={item.ContactID}
                            connection={item}
                            connectionType={item.TypeName}
                            onTouchForm={setTouchForm}
                            onCancel={() => setMode("SHOW")}
                            onSave={() => setMode("SHOW")}
                        />
                    </NwDrawer>
                }
                {addingNewItem && 
                    <NwDrawer                     
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title={`Add ${addingNewItem}`} 
                        onClose={() => setAddingNewItem(null)}>
                        {addingNewItem === "Address" && (
                            <AddressForm
                                connectionID={item.ID}
                                contactID={contactID}
                                onTouchForm={setTouchForm}
                                onCancel={() => setAddingNewItem(null)}
                                onSave={() => setAddingNewItem(null)}
                        />
                        )}
                        {addingNewItem === "Email" && (
                            <EmailForm
                                contactID={contactID}
                                connectionID={item.ID}
                                onTouchForm={setTouchForm}
                                onCancel={() => setAddingNewItem(null)}
                                onSave={() => setAddingNewItem(null)}
                            />
                        )}
                        {addingNewItem === "Phone" && (
                            <PhoneForm
                                contactID={contactID}
                                connectionID={item.ID}
                                onTouchForm={setTouchForm}
                                onCancel={() => setAddingNewItem(null)}
                                onSave={() => setAddingNewItem(null)}
                            />
                        )}
                    </NwDrawer>
                }
            </NwCard>
        )
    }

    return (
        <Badge.Ribbon
            color={item.TypeName === "Personal" ? StyleVariables.DangerColor : StyleVariables.PrimaryColor}
            text={
                <NwIcon icon={renderTypeIcon()} size='sm' />
            }
            placement="start"
        >
            {renderCard()}
        </Badge.Ribbon>
    );
};

export default ConnectionCard;
