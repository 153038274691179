import React, { useState } from "react";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { Formik } from "formik";

import { NwCancelButton, NwRemoveButton, NwSaveButton } from "Components/Gui/NwButton";
import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import SelectModelsIds from "../EditModelDatesForm/SelectModelsIds";
import { onMoveEvent, useDeleteEventDate } from 'Hooks/Event/UseEvent';
import { showDate, toISODate } from "Libs/NwMoment";

const Container = styled.div`
  padding: 16px;
  .ant-legacy-form-item {
    margin-bottom: 16px;
    .ant-legacy-form-item-label {
        line-height: 1.2rem;
    }
  }
  .date-title {
    padding-bottom: 10px;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const EditEventDayForm = ({ onClose, onSave, date, eventData, onTouchForm }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const { mutateAsync: onDeleteEventDate } = useDeleteEventDate();
    const initialValues = {
        modelIds: date.EventDatesModels.map(eventDateModel => eventDateModel.ModelID),
        newDate: getMoment(date.FromDate)
    };

    const onChangeDate = (form, date) => {
        form.setFieldValue('newDate', date)
    }

    const onSubmit = async (values) => {
        const moveEventData = {
            eventDateID: date.ID,
            newDate: showDate(values.newDate, 'short'),
            ...values
        }
        await onMoveEvent(moveEventData)
        onTouchForm(false);
        onSave()
    }

    const handleRemoveEventDates = async () => {
        try {
            await onDeleteEventDate({ ID: eventData.ID, eventDateId: date.ID });
            onSave();
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const disableDate = rangeDate => {
        const period = eventData.EventDates.find(eventDate => {
            if (toISODate(rangeDate) >= toISODate(eventDate.FromDate) && toISODate(rangeDate) <= toISODate(eventDate.ToDate) ) {
                return true;
            }
            return false;
        });

        if (period) {
            return true;
        }
    };

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(form) => (
                        <NwForm
                            values={form.values}
                            onTouchForm={onTouchForm}
                            onFinish={form.handleSubmit}
                            layout="vertical">
                            <div className="date-title">Move to date</div>
                            <DatePickerContainer>
                                <CustomDatePicker
                                    open={isDatePickerOpen}
                                    onOpenChange={setIsDatePickerOpen}
                                    label='Select day'
                                    name='newDate'
                                    value={form.values.newDate}
                                    onChange={(value) => onChangeDate(form, value)}
                                    disabledRange={disableDate}
                                />
                                <NwRemoveButton size='small' onClick={handleRemoveEventDates} label="Remove date" ghost />
                            </DatePickerContainer>
                            <SelectModelsIds form={form} eventDates={[date]} onlyOneDay modelProperty="ModelID" />
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={form.isSubmitting}
                                        onClick={() => {
                                            onTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        disabled={form.isSubmitting || showDate(getMoment(date.FromDate), 'short') === showDate(form.values.newDate, 'short')}
                                        loading={form.isSubmitting}
                                        label="Save"
                                        loadingLabel="Updating..."
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </Container>
        </>
    );
};

// EditEventDayForm.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,
//   date: PropTypes.shape({
//     FromDate: PropTypes.string,
//     ToDate: PropTypes.string,
//     EventAddressID: PropTypes.number,
//     models: PropTypes.arrayOf(PropTypes.number),
//   }).isRequired,
//   eventData: PropTypes.shape({
//     ID: PropTypes.number.isRequired
//   }).isRequired
// }

export default EditEventDayForm;
