import React, { useState } from 'react';
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';

import { getEventFieldName } from 'Libs/NwUtils';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import EventSelector from 'Components/EventSelector';
import EventModelSelector from 'Components/EventModelSelector';
import { NWTextAreaCharCounter, NWPrioritySelector } from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { getEventByItem } from '../bookingEventUtils';

const BookingEventCommentForm = ({
  api,
  modelId,
  noPadding,
  onSave,
  itemToEdit,
  events,
  project,
  eventData,
  onTouchForm
}) => {
  const [selectedEvent, setSelectedEvent] = useState(getEventByItem(itemToEdit, events, eventData));
  let initialValues = {
    Text: itemToEdit ? itemToEdit.Text : '',
    Priority: itemToEdit ? itemToEdit.Priority : 0,
    ModelID: itemToEdit ? itemToEdit.ContactID : undefined,
    EventID: selectedEvent ? selectedEvent.uniqID : null,
  };

  const handleEventChange = (event, setFieldValue) => {
    if (!event) {
      setSelectedEvent(null);
      setFieldValue('ModelID', undefined);
      return;
    }
    setSelectedEvent(event);
    setFieldValue('ModelID', undefined);
  }

  const addComment = (values, setStatus, setSubmitting) => {
    const { Text, Priority, ModelID } = values;
    setSubmitting(true);
    if (itemToEdit) {
      const commentData = {
        Text: Text,
        Priority: Priority,
        ContactID: ModelID,
        Visibility: {
          isGlobal: true
        },
        [api]: project.ID
      }
      if (selectedEvent) {
        const type = selectedEvent.elType || selectedEvent.Type;
        const eventFieldName = getEventFieldName(type.toLowerCase());
        commentData[eventFieldName] = selectedEvent.ID;
      }
      delete commentData.Visibility;
      Axios.put(`/comments`, {
        ...commentData,
        ID: itemToEdit.ID
      })
        .then((response) => {
          onTouchForm(false);
          onSave(true, response);
        })
        .catch(error => {
          //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
          console.log("error", error);
        });
    } else {
      const commentData = {
        Type: 0,
        Text: Text,
        Priority: Priority,
        Visibility: {
          isGlobal: true
        },
        ContactID: ModelID,
        [api]: project.ID
      }
      if (selectedEvent) {
        const type = selectedEvent.elType || selectedEvent.Type;
        const eventFieldName = getEventFieldName(type.toLowerCase());
        commentData[eventFieldName] = selectedEvent.ID;
      }
      Axios.post(`/comments`, commentData)
        .then((response) => {
          onTouchForm(false);
          onSave(true, response);
        })
        .catch(error => {
          //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
          console.log("error", error);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        Text: Yup.string().required("Content is required")
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus();
        addComment(values, setStatus, setSubmitting);
      }}
    >
      {(form) => (
        <NwForm values={form.values} onTouchForm={onTouchForm} padded={!noPadding} small>
          <Row gutter={16}>
            <Col span={24}>
              <EventSelector
                label="EVENT"
                form={form}
                events={events}
                typesToExclude={["Accommodation"]}
                name="EventID"
                onChange={(eventID, setFieldValue) => handleEventChange(eventID, setFieldValue, form.values)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <EventModelSelector
                form={form}
                event={selectedEvent}
                project={project}
                modelId={modelId}
                withZeroOption
                disabled={selectedEvent && selectedEvent.elType === 'Meeting'}
              />
            </Col>
          </Row>
          <Row>
            <Col flex="160px">
              <Field
                label="Priority"
                inlineLabel
                component={NWPrioritySelector}
                name="Priority"
                value={form.values.Priority}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={24} md={24}>
              <Field
                component={NWTextAreaCharCounter}
                label="Comment"
                name="Text"
                type="text"
                required
                autoFocus
                value={form.values.Text}
                maxLength={2000}
                style={{ width: "100%" }}
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Col>
          </Row>
          <NwFormButtonsBar
            left={
              <NwCancelButton
                disabled={form.isSubmitting}
                onClick={() => {
                  onTouchForm(false);
                  onSave(false)
                }}
              />
            }
            right={
              <NwSaveButton
                htmlType="submit"
                disabled={form.isSubmitting}
                loading={form.isSubmitting}
              />
            }
          />
        </NwForm>
      )}
    </Formik>
  );
};

export default BookingEventCommentForm;