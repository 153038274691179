const MODEL_AGENCY_TYPES = [{
    value: 'All',
    label: 'ALL'
},{
    value: 'Mother',
    label: 'MOTHER'
},{
    value: 'Placement',
    label: 'PLACEMENT'
},{
    value: 'Other',
    label: 'OTHER'
}];

const MODEL_AGENCY_TYPE_ORDERS = {
    'Mother': 0,
    'Placement': 1,
    'Other': 2
};

export {
    MODEL_AGENCY_TYPES,
    MODEL_AGENCY_TYPE_ORDERS
};