import React, { useRef, useState } from 'react';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { NwButton, NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import Axios from 'axios';
import { solid, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import styled from 'styled-components';
import { forceReloadContactDocuments } from 'Hooks/Contact/UseContactMedia';


const Wrapper = styled.div`
    .toolbar {
        margin: 2rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`


const EditImageNew = ({
    contactID,
    image,
    onImageCreated,
    onCancel
}) => {
    let imgObj = useRef(null);
    const [uploading, setUploading] = useState(false);

    const toolbar = [
        'Crop',
        'Straightening',
        'Transform',
        'Finetune',
        'Filter',
        'Frame',
        'ZoomIn',
        'ZoomOut',
        'Reset',
        'Undo',
        'Redo',
        'Reset'
    ];
    const imageEditorCreated = () => {
        imgObj.current.open(image.Standard.Url);
    };

    const handleUpload = async () => {
        // const data = {
        //     base64Image: imageEditor.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "")
        // };

        setUploading(true);
        let imageData = imgObj.current.getImageData();
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        const context = canvas.getContext('2d');
        context.putImageData(imageData, 0, 0);
        const data = {
            base64Image: canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "")
        };
        Axios.post(`/documents/uploadBase64Picture/${contactID}`, data).then((response) => {
            forceReloadContact(contactID);
            forceReloadContactDocuments(contactID);
            setUploading(false)
            onImageCreated(response)
        }).catch((error) => {
            console.log('ant : upload image error => ', error);
            setUploading(false);
        });
    };

    return (
        <Wrapper>
            <div className="e-img-editor-sample">
                <ImageEditorComponent
                    id="image-editor"
                    ref={imgObj}
                    created={imageEditorCreated}
                    height="calc(100vh - 200px)"
                    toolbar={toolbar}
                />
            </div>
            <div className="toolbar">
                <NwCancelButton label='Cancel' icon={light("times")} onClick={onCancel} />
                <NwSaveButton label='Save (as a new image)' onClick={handleUpload} loading={uploading} />
            </div>
        </Wrapper>
    );
}
export default EditImageNew;