export const EVENTS_FILTERS = {
    reducerPrefix: 'EventFilters',
    sections: [
        {name: 'period & people'},
        {name: 'event types'},
        {name: 'details'}
    ],
    filters: [
        {
            name: 'WorkingOn',
            label: 'Period',
            area: 'period & people',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Booker',
            label: 'Author',
            area: 'period & people',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'modelID',
            label: 'Model',
            area: 'period & people',
            defaultval: undefined,
            commontype: 'model',
            type: 'modelList'
        },
        {
            name: 'clientID',
            label: 'Client',
            area: 'period & people',
            defaultval: undefined,
            commontype: 'customer',
            type: 'customerList'
        },
        {
            name: 'serviceID',
            label: 'Service',
            area: 'period & people',
            defaultval: undefined,
            commontype: 'customer',
            type: 'serviceList'
        },
        {
            name: 'casting',
            label: 'Castings',
            area: 'event types',
            defaultval: {
                eventSubtypeCode: []
            },
            commontype: 'included',
            type: 'event-casting'
        },
        {
            name: 'job',
            label: 'Jobs',
            area: 'event types',
            defaultval: {
                eventSubtypeCode: [],
                optionNumber: null
            },
            commontype: 'included',
            type: 'event-job'
        },
        {
            name: 'travel',
            label: 'Travels',
            area: 'event types',
            defaultval: {
                travelTypes: []
            },
            commontype: 'included',
            type: 'event-travel'
        },
        {
            name: 'followup',
            label: 'Activities',
            area: 'event types',
            defaultval: {
                labels: []
            },
            commontype: 'included',
            type: 'event-followup'
        },
        {
            name: 'roomLodging',
            label: 'Room Lodgings',
            area: 'event types',
            defaultval: true,
            commontype: 'included',
            type: 'event-nosub'
        },
        {
            name: 'extjob',
            label: 'External Jobs',
            area: 'event types',
            defaultval: true,
            commontype: 'included',
            type: 'event-nosub'
        },
        {
            name: 'meeting',
            label: 'Meetings',
            area: 'event types',
            defaultval: true,
            commontype: 'included',
            type: 'event-nosub'
        },
        {
            name: 'accommodation',
            label: 'Accommodations',
            area: 'event types',
            defaultval: true,
            commontype: 'included',
            type: 'event-nosub'
        },
        {
            name: 'hasTransactions',
            label: 'Has transactions',
            area: 'details',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
    ]
};