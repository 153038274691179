export const INVOICES_FILTERS_SETS = {
    activeSet: "v1",
    customSets: [],
    staticSets: [
        {
            id: "v1",
            name: "last 30 days",
            fixed: true,
            filters: [
                { name: "Period", value: "{last30days}"},
                { name: "EmptyDates", value: "Include"}
            ]
        }
    ]
}