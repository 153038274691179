import React from "react";
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import NwIcon from "Components/Gui/NwIcon";
import Money from "Components/Gui/Money";

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
    border-radius: 6px;
    background: #fff;
    display: flex;
    flex-direction: row;
    cursor: ${props => props.$pointercursor ? 'pointer' : 'not-allowed'};
    user-select: none;
    border-bottom: ${props => props.theme.DefaultBorder};
    margin: .25rem .5rem;

    &:last-child {
        border-bottom: none;
    }

    .invoice-number {
        width: 4rem;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        padding-top: .75rem;
    }

    .invoice-detail {
        padding: .25rem .5rem;
        flex: 1 1 0;
        border-right: ${StyleVariables.DarkerBorder};

        .invoice-date {
            font-size: ${props => props.theme.FontSizeSm};
        }

        small {
            font-style: italic;
            opacity: .75;
            display: block;
        }
    }
    .invoice-totals {
        width: 10rem;
        padding: .25rem .5rem;
        text-align: right;

        span.title-span {
            text-transform: uppercase;
            font-size: ${props => props.theme.FontSizeSm};
            display: block;
        }
        span.money-span {
            font-weight: bold;
            display: block;
        }
    }
`;

const InvoiceItem = ({ invoice, onInvoiceClick, hasAccountingAccess }) => {

    const handleInvoiceClick = () => {
        onInvoiceClick(invoice.ID);
    }

    return (
        <Wrapper onClick={handleInvoiceClick} $pointercursor={hasAccountingAccess}>
            <div className="invoice-number">
                {invoice.Number ? invoice.Number : <NwIcon icon={light("empty-set")} />}
            </div>
            <div className="invoice-detail">
                <div className="contact-info">
                    {getContactName(invoice.ContactID).toUpperCase()} / {invoice.BillingInfo.CompanyName}
                </div>
                <small>{invoice.Name}</small>
                <div className="invoice-date">
                    {invoice.Date ? getMoment(invoice.Date).format("DD MMM YYYY") : "N.D"}
                </div>
            </div>
            <div className="invoice-totals">
                <span className="title-span">Total Amount</span>
                <Money amounts={[invoice.TotalAmount, invoice.TotalTaxes, invoice.TotalVat]} />
            </div>
        </Wrapper>
    )
}

export default InvoiceItem;