import React, { useContext, useState } from 'react';
import { Modal, Steps } from 'antd';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProjectSelector from 'Components/ProjectSelector/ProjectSelector';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwIcon from 'Components/Gui/NwIcon';
import PackageEditDrawer from 'Components/PackageDrawer/PackageHeader/PackageEditDrawer';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useDeletePackage } from 'Hooks/Packages/UsePackages';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import PackageCloneDrawer from './PackageCloneDrawer';
import { getTodayISO, showDate, showLog, toISODate } from 'Libs/NwMoment';
import PackageLinkToProjectDrawer from './PackageLinkToProjectDrawer';
import { PackageContext } from '../PackageContext';

const PackageHeader = ({
    compact,
    onClonePackage,
    onDeletePackage,
}) => {

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage } = packageContextValue;

    const [editPackage, setEditPackage] = useState(false);
    const [linkPackage, setLinkPackage] = useState(false);
    const [clonePackage, setClonePackage] = useState(false);
    const isExpired = getTodayISO() > toISODate(groupedPackage.ExpirationDate);
    const breakpoints = useNwBreakPoints();
    const { mutateAsync: deletePackage } = useDeletePackage();

    const handleDeletePackage = async () => {
        try {
            await deletePackage({ PackageID: groupedPackage.ID })
            onDeletePackage();
        } catch (e) {
            Modal.error({
                title: e.response.data.Message
            });
        }
    }

    const handleClonePackage = async () => {
        setClonePackage(true)
    }

    const handleClosePackageCloneDrawer = (clonedPackageID) => {
        setClonePackage(false)
        if (clonedPackageID) {
            onClonePackage(clonedPackageID)
        }
    }

    const handleUnlinkProject = async () => {
        await Axios.put(`/packages/unlinkfromproject/${groupedPackage.ID}`)
        afterUpdatePackage()
    }

    let currentStep = 0
    if (groupedPackage.LastSentDate) {
        currentStep = 1
    }
    if (groupedPackage.LastSentDate && isExpired) {
        currentStep = 2
    }

    const getDdMenuActions = () => {
        const menuActions = [{ name: "", key: "edit", pinned: true, icon: light("pen"), action: () => setEditPackage(true) }]
        if (groupedPackage.Project && groupedPackage.Project.ID) {
            var unlinkaction = {
                name: "",
                type: "delete",
                tooltip: `unlink this package from the project "${groupedPackage.Project.Name}"`,
                confirmationLabel: `This will unlink the current package from the project "${groupedPackage.Project.Name}". Please confirm your choice`,
                confirmationButtonLabel: "unlink",
                confirmationButtonIcon: light("link-slash"),
                confirmationPlacement: "bottomLeft",
                pinned: true,
                icon: light("folder-xmark"),
                key: "unlink",
                action: () => handleUnlinkProject()
            }
            menuActions.push(unlinkaction)

        } else {
            menuActions.push({
                name: "",
                key: "link-project",
                tooltip: "link this package to a project",
                pinned: true,
                icon: light("folder-plus"),
                action: () => setLinkPackage(true)
            })
        }
        if (!compact) {
            menuActions.push({
                name: "",
                key: "clone",
                tooltip: "clone this package",
                pinned: true,
                icon: light("clone"),
                action: () => handleClonePackage()
            })
        }
        menuActions.push({
            name: "",
            key: "delete",
            pinned: true,
            tooltip: "delete this package",
            icon: light("trash-alt"),
            action: () => handleDeletePackage(),
            type: "delete",
            confirmationPlacement: "left"
        })
        return menuActions
    }

    return (
        <div className="package-drawer-header-container">
            <div className="title-card-wrapper">
                <NwCard
                    title={groupedPackage.Name}
                    icon={light("book-arrow-right")}
                    extra={
                        <NwDropDownMenu
                            actions={getDdMenuActions()}
                        />
                    }
                >
                    <div className="package-header">
                        {groupedPackage.Project && groupedPackage.Project.ID &&
                            <div className="project-selector-wrapper">
                                <ProjectSelector small noBorder readOnly projectData={groupedPackage.Project} value={groupedPackage.Project.ID} />
                            </div>
                        }
                        <div className="steps-wrapper">
                            <Steps 
                                direction={breakpoints > 2 ? "horizontal" : "vertical"} 
                                current={currentStep} 
                                size="small"
                                items={[
                                    { 
                                        title:'Created',
                                        description: <CreatedBy object={groupedPackage} extra='' />,
                                        icon: <NwIcon icon={light('plus-circle')} />
                                    },
                                    {
                                        title: groupedPackage.LastSentDate ? `Sent to ${groupedPackage.Recipients.length} recipients`
                                            : `Not sent yet`,
                                        description: groupedPackage.LastSentDate ? `on ${showLog(groupedPackage.LastSentDate)}`
                                            : `${groupedPackage.Models.length} models, ${groupedPackage.Recipients.length} recipients`,
                                        icon: <NwIcon icon={light('paper-plane')} />
                                    },
                                    {
                                        title: isExpired ? 'Expired' : 'Will Expire',
                                        description: `on ${showDate(groupedPackage.ExpirationDate)}`,
                                        icon: <NwIcon icon={isExpired ? light('calendar-times') : light('calendar-exclamation')} />
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    {editPackage &&
                        <PackageEditDrawer onClose={() => setEditPackage(false)} />
                    }
                    {linkPackage &&
                        <PackageLinkToProjectDrawer onClose={() => setLinkPackage(false)} />
                    }
                    {clonePackage &&
                        <PackageCloneDrawer
                            packageName={groupedPackage.Name}
                            packageID={groupedPackage.ID}
                            onClose={handleClosePackageCloneDrawer}
                        />
                    }
                </NwCard>
            </div>
        </div>
    )
};

export default PackageHeader;