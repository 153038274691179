import React, { useContext, useState, useEffect } from 'react';
import { getMoment } from "Libs/NwMoment";
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ListContext } from 'Contexts/ListContext';
import { getContactName } from "Hooks/Contact/UseContactsList";
import EventDatesAndModels from 'Components/EventDrawer/BookingEvent/EventDatesAndModels';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import EventDaysHeader from 'Components/EventDrawer/EventDrawerUi/EventDaysHeader';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
//import QuickAddModelsToBookingEvent from 'Components/QuickAddModels/QuickAddModelsToBookingEvent';
import { EventAddModelQuestionBooking } from "Components/EventDrawer/EventDrawerUi/EventAddModelQuestion";
import { EventDetailsWrapper, ObjectHistoryContainer, EventTitleContainer, BookingEventTitleText } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import EventPlanner from 'Components/EventDrawer/BookingEvent/BookingEventDetails/EventPlanner';
import { Loading } from 'Components/Gui/Loading';
import NwDrawer from 'Components/Gui/NwDrawer';
import { getCustomFieldValue } from 'Libs/NwUtils';
import { forceReloadEvent, useRemoveEvent, useAddModelToDate } from 'Hooks/Event/UseEvent';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import { editDrawerTypes, extendOptionNumberToAllDates } from './bookingEventDetailsUtils';
import BookingEventDrawers from './BookingEventDrawers';
import ResponsiveFlexContainer from "Components/Gui/ResponsiveFlexContainer";
import SendProjectDrawer from 'Components/SendProjectDrawer/SendProjectDrawer';
import NwIcon from 'Components/Gui/NwIcon';
import SendProjectDrawerFromEvent from 'Components/SendProjectDrawer/SendProjectDrawerFromEvent';
import useDepartmentName from 'Hooks/UseDepartmentName';

const BookingEventDetails = ({
    eventData,
    filterModel,
    dateSelected,
    afterUpdate,
    projectId,
    afterRemove,
    plainMode,
    onSelectEvent
}) => {
    const { GetEventClientRole, cachedList } = useContext(ListContext);
    const { mutateAsync: onRemoveEvent } = useRemoveEvent();
    const history = useHistory();
    const [customFields, setCustomFields] = useState([]);
    const [validCustomFieldsKeys, setValidCustomFieldsKeys] = useState([]);
    const [selectedWorkingDate, setSelectedWorkingDate] = useState();
    const [selectedWorkingDay, setSelectedWorkingDay] = useState();
    const [editDrawerType, setEditDrawerType] = useState();
    const [eventDates, setEventDates] = useState([]);
    //const [showQuickAdd, setShowQuickAdd] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewEventPlanner, setViewEventPlanner] = useState(false);
    const { mutateAsync: onAddModelToDate } = useAddModelToDate();
    const [sendProjectDrawer, setSendProjectDrawer] = useState(false)

    useEffect(() => {
        setViewDetails(false);
    }, [filterModel]);

    useEffect(() => {
        let sortedEventDates = eventData && eventData.EventDates ? [...eventData.EventDates] : [];
        sortedEventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);
        setEventDates(sortedEventDates);
    }, [eventData]);

    useEffect(() => {
        const customfields = cachedList.customFields.items['Event'];
        setCustomFields(customfields);
        setValidCustomFieldsKeys(customfields.filter(field => !field.Disabled).map(field => field.Name))
    }, [cachedList]);

    // const handleAddModelsToDates = () => {
    //   history.push({
    //     pathname: `/add-models-eventdate/${eventData.ID}`,
    //     state: { projectId: projectId ? projectId : eventData.Project && eventData.Project.ID }
    //   });
    // };

    // const handleQuickAdd = () => {
    //   setShowQuickAdd(true);
    // };

    const DepartmentName = useDepartmentName(eventData.DepartmentCreated);

    const handleShareProject = () => {
        setSendProjectDrawer(true);
    }

    const handleRemoveEvent = async () => {
        try {
            await onRemoveEvent({ ID: eventData.ID });
            afterRemove();
        } catch (error) {
            console.log('ant : Remove Event Error => ', error);
            // setIsFetching(false);
        }
    };

    const onExtendOptionNumberToAllDates = (modelId, optionNumber) => {
        const newEventDates = extendOptionNumberToAllDates(eventDates, modelId, optionNumber);
        setEventDates(newEventDates);
    }

    const onOpenEditWorkingDateDrawer = (workingDateOptions) => {
        setSelectedWorkingDate(workingDateOptions);
        setEditDrawerType(editDrawerTypes.editDates);
    }

    const onOpenEditWorkingDayDrawer = (workingDayOptions) => {
        setSelectedWorkingDay(workingDayOptions);
        setEditDrawerType(editDrawerTypes.editWorkingDay);
    }

    const onCloseDrawers = () => {
        setEditDrawerType(undefined);
        setSelectedWorkingDate(undefined);
    }

    const getEventTitle = () => {
        if (eventData.EventClientID && (eventData.Name === getContactName(eventData.EventClientID))) {
            return (
                <BookingEventTitleText className={eventData.Cancel ? "is-cancelled" : null}>
                    <strong>{getContactName(eventData.EventClientID)}</strong> <em>/</em> {GetEventClientRole(eventData.EventClientRoleID)}
                </BookingEventTitleText>
            )
        }
        return (<BookingEventTitleText>{eventData.Name}</BookingEventTitleText>)
    }


    const isActiveBookingEvent = () => {
        let eventDateModels = [];
        if (eventData && eventData.EventDates) {
            for (const eventDate of eventData.EventDates) {
                eventDateModels = [...eventDateModels, ...eventDate.EventDatesModels];
            }
            if (eventDateModels.find(model => model.ModelID === filterModel)) {
                return true;
            }
        }
        return false;
    }

    const handleAddModelToEventDate = async eventDateId => {
        setLoading(true);
        if (eventDateId) {
            await onAddModelToDate({ modelId: filterModel, eventDateId: eventDateId });
        } else {
            for (const eventDate of eventData.EventDates) {
                await onAddModelToDate({ modelId: filterModel, eventDateId: eventDate.ID });
            }
        }
        forceReloadEvent(eventData.ID);
        setLoading(false);
        if (eventData.Project) {
            forceReloadProject(eventData.Project.ID);
        }
        setViewDetails(true);
    }

    const handleViewEventPlanner = () => {
        setViewEventPlanner(true);
    }

    const renderEventFields = (eventData) => {
        return (
            <EventDetailsWrapper className={eventData.Cancel ? "is-cancelled" : null}>
                <ResponsiveFlexContainer
                    leftMinWidth={180}
                    columns={[
                        {
                            content: <p className="is-label">Department</p>,
                            isBorder: true
                        },
                        {
                            content: <p className="is-content">{DepartmentName}</p>,
                            isBorder: false,
                        }
                    ]}
                />
                {(eventData.Name && eventData.Name !== getContactName(eventData.EventClientID)) &&
                    <ResponsiveFlexContainer
                        leftMinWidth={180}
                        columns={[
                            {
                                content: <p className="is-label">{GetEventClientRole(eventData.EventClientRoleID)}</p>,
                                isBorder: true
                            },
                            {
                                content: <p className="is-content">{getContactName(eventData.EventClientID)}</p>,
                                isBorder: false
                            }
                        ]}
                    />
                }
                <ResponsiveFlexContainer
                    leftMinWidth={180}
                    columns={[
                        {
                            content: <p className="is-label">Description</p>,
                            isBorder: true
                        },
                        {
                            content: <p className="is-content">{eventData.Description}</p>,
                            isBorder: false,
                        }
                    ]}
                />
                <ResponsiveFlexContainer
                    leftMinWidth={180}
                    columns={[
                        {
                            content: <p className="is-label">Internal Notes</p>,
                            isBorder: true
                        },
                        {
                            content: <p className="is-content">{eventData.InternalNote}</p>,
                            isBorder: false,
                        }
                    ]}
                />
                {Object.keys(eventData.CustomFields).map(key => {
                    if (validCustomFieldsKeys && validCustomFieldsKeys.includes(key)) {
                        return (<ResponsiveFlexContainer
                            leftMinWidth={180}
                            key={key}
                            columns={[
                                {
                                    content: <p className="is-label">{key.replace(/_/g, ' ')}</p>,
                                    isBorder: true
                                },
                                {
                                    content: <p className="is-content">{getCustomFieldValue(key, eventData.CustomFields[key], customFields)}</p>,
                                    isBorder: false,
                                }
                            ]}
                        />)
                    }
                })}
            </EventDetailsWrapper>
        )
    }

    return (loading ? <Loading textBlack />
        : <div>
            {editDrawerType && (
                <BookingEventDrawers
                    eventData={eventData}
                    selectedWorkingDate={selectedWorkingDate}
                    afterUpdate={afterUpdate}
                    editDrawerType={editDrawerType}
                    onCloseDrawers={onCloseDrawers}
                    customFields={customFields}
                    selectedWorkingDay={selectedWorkingDay}
                />
            )}
            {(!filterModel || isActiveBookingEvent() || viewDetails) ?
                <>
                    {!plainMode &&
                        <ProjectEventsTimeline 
                            event={eventData} 
                            projectID={eventData.Project.ID} 
                            onSelectEvent={onSelectEvent}
                            showPackage
                        />
                    }
                    <EventTitleContainer>
                        {getEventTitle(eventData)}
                        <div className="event-title-actions">
                            <div className="project-send-button">
                                <NwIcon key="share" icon={light("share")} pointer onClick={handleShareProject} />
                            </div>    
                            <NwDropDownMenu
                                right
                                actions={[
                                    { name: "", key: "edit", tooltip: "edit general details", pinned: true, icon: light("pen"), action: () => setEditDrawerType(editDrawerTypes.editGeneralDetails) },
                                    { name: "edit custom fields", key: "custom", tooltip: "edit custom fields", action: () => setEditDrawerType(editDrawerTypes.editCustomFields) },
                                    // { name: "add models", key: "add-model", tooltip: "add a new model to this event", icon: light("plus"), action: (() => handleAddModelsToDates()) },
                                    { name: "delete", type: "delete", key: "delete", tooltip: "delete event", icon: light("trash-alt"), confirmationPlacement: "left", action: () => handleRemoveEvent() }
                                ]}
                            />
                        </div>
                    </EventTitleContainer>
                    {renderEventFields(eventData)}
                    <ObjectHistoryContainer>
                        <ObjectHistory object={eventData} onIconClick={() => setEditDrawerType(editDrawerTypes.history)} asLine />
                    </ObjectHistoryContainer>
                    <EventDaysHeader
                        eventData={eventData}
                        plainMode={plainMode}
                        onAddDays={() => setEditDrawerType(editDrawerTypes.addDates)}
                        //onAddModels={handleQuickAdd}
                        onEventPlanner={handleViewEventPlanner}
                        afterUpdate={() => {
                            forceReloadEvent(eventData.ID);
                            afterUpdate();
                        }}
                    />
                    {/* {showQuickAdd &&
                      <QuickAddModelsToBookingEvent 
                          projectId={projectId}
                          eventData={eventData}
                          source={'models'} 
                          onCloseSearch={() => setShowQuickAdd(false)} />
                  } */}
                    {eventDates.length > 0 &&
                        <div>
                            {eventDates.map((eventDate) => {
                                const isActive = (!filterModel || eventDate.EventDatesModels.find(model => model.ModelID === filterModel)) ? true : false;
                                return (
                                    <div key={eventDate.ID}>
                                        <EventDatesAndModels
                                            filterModel={filterModel}
                                            eventData={eventData}
                                            selectedModel={isActive ? filterModel : null}
                                            dateSelected={dateSelected}
                                            loadEventData={() => {
                                                forceReloadEvent(eventData.ID);
                                            }}
                                            onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
                                            afterUpdate={afterUpdate}
                                            eventDateInfo={eventDate}
                                            onOpenEditWorkingDateDrawer={onOpenEditWorkingDateDrawer}
                                            onOpenEditWorkingDayDrawer={onOpenEditWorkingDayDrawer}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    }
                </>
                :
                <EventAddModelQuestionBooking
                    eventData={eventData}
                    model={filterModel}
                    onAddModelToEventDate={handleAddModelToEventDate}
                    onShowEvent={() => setViewDetails(true)} />
            }
            {viewEventPlanner &&
                <NwDrawer
                    padding="0px"
                    onClose={() => setViewEventPlanner(false)}
                    title='Event Planner'>
                    <EventPlanner eventData={eventData} afterUpdate={afterUpdate} />
                </NwDrawer>
            }
            {sendProjectDrawer &&
            <SendProjectDrawerFromEvent 
                event={eventData}
                onClose={() => setSendProjectDrawer(false)}
            />
        }
        </div>
    )
}

// BookingEventDetails.propTypes = {
//   eventData: PropTypes.shape({
//     ID: PropTypes.number.isRequired,
//     Description: PropTypes.string,
//     InternalNote: PropTypes.string,
//   }),
//   filterModel: PropTypes.number.isRequired,
//   dateSelected: PropTypes.instanceOf(moment).isRequired,
//   afterUpdate: PropTypes.func.isRequired,
//   afterRemove: PropTypes.func.isRequired,
//   onSelectEvent: PropTypes.func.isRequired,
//   projectId: PropTypes.number,
// }

// BookingEventDetails.defaultProps = {
//   eventData: null,
//   projectId: null,
// }

export default BookingEventDetails;