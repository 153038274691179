import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getAccommodation = accommodationId => (
    Axios.get(`/accommodation/${accommodationId}`)
);

const useAccommodation = accommodationId => {
    return useQuery(["accommodation", accommodationId], () => getAccommodation(accommodationId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: accommodationId ? true : false
    });
};

const forceReloadAccommodation = (ID) => {
    queryClient.invalidateQueries(['accommodation', ID])
}

const onDeleteAccommodation = ({ ID }) => (
    Axios.delete(`/accommodation/${ID}`)
)

const useDeleteAccommodation = () => {
    return useMutation(onDeleteAccommodation, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.accommodationId) {
                forceReloadAccommodation(variables.accommodationId);
            }
        }
    })
}

const onDeleteAccommodationGroup = ({ GroupGUID }) => (
    Axios.delete(`/accommodations/deletegroup/${GroupGUID}`)
);

const useDeleteAccommodationGroup = () => {
    return useMutation(onDeleteAccommodationGroup, {
        onSuccess: (data, variables) => {
            // if (variables.ID !== variables.travelId) {
            //     forceReloadTravel(variables.travelId);
            // }
        }
    })
}

const onUpdateAccommodation = ({ accommodationData }) => (
    Axios.put('/accommodation', accommodationData)
);

const useUpdateAccommodation = () => {
    return useMutation(onUpdateAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.accommodationData.ID);
        }
    })
}

const onAddAccommodation = ({ accommodationData }) => (
    Axios.post('/accommodation', accommodationData)
);

const useAddAccommodation = () => {
    return useMutation(onAddAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(data.ID);
        }
    })
};

const onUnlinkAccommodation = ({ ID }) => (
    Axios.put(`/accommodation/UnLink/${ID}`)
);

const useUnlinkAccommodation = () => {
    return useMutation(onUnlinkAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.accommodationId);
        }
    })
};


const onSetAccommodationProject = ({ accommodationId, projectId }) => (
    Axios.put('/accommodation/SetProject', {
        ObjectID: accommodationId,
        ProjectID: projectId
    })
);

const useSetAccommodationProject = () => {
    return useMutation(onSetAccommodationProject, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.accommodationId);
        }
    })
};

const onCloneModelsAccommodation = ({ data }) => (
    Axios.post(`/accommodation/CloneForModels`, data)
);

const useCloneModelsAccommodation = () => {
    return useMutation(onCloneModelsAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.data.EventID);
        }
    })
};

const onUpdateGroupedIndividualAccommodation = ({ accommodationData }) => (
    Axios.put('/accommodation/GroupedIndividual', accommodationData)
);

const useUpdateGroupedIndividualAccommodation = () => {
    return useMutation(onUpdateGroupedIndividualAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.accommodationData.ID);
        }
    })
};

const onUpdateGroupedCommonAccommodation = ({ accommodationData }) => (
    Axios.put('/accommodation/GroupedCommon', accommodationData)
);

const useUpdateGroupedCommonAccommodation = () => {
    return useMutation(onUpdateGroupedCommonAccommodation, {
        onSuccess: (data, variables) => {
            forceReloadAccommodation(variables.accommodationData.ID);
        }
    })
}

export {
    getAccommodation,
    useAccommodation,
    useDeleteAccommodation,
    useUpdateAccommodation,
    useAddAccommodation,
    forceReloadAccommodation,
    useSetAccommodationProject,
    useDeleteAccommodationGroup,
    useUnlinkAccommodation,
    useCloneModelsAccommodation,
    useUpdateGroupedIndividualAccommodation,
    useUpdateGroupedCommonAccommodation
};