import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import ProjectTimelineEvent from './ProjectTimelineEvent';
import { onGetOrderedEventList } from 'Hooks/Project/UseProject';
import { useProjectTimeline } from 'Hooks/Project/UseProjectTimeline';
import { useStateValue } from 'Contexts/StateProvider';
import { Loading } from 'Components/Gui/Loading';

const ProjectEventsTimelineContainer = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  background: #f0f0f0;
  
  
  padding: 15px 30px 20px 40px;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  > div {
    margin: 0px 10px;
    //display: inline-block;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    background: white;
  }
  > div:first-child {
    margin-left: 0px;
  }
  > div:last-child {
    margin-right: 30px;
  }
`;

const ProjectEventsTimeline = ({ event, projectID, showPackage, onSelectEvent }) => {
  
  const [{ areaContext }] = useStateValue();
  const { data: projectEvents, isFetching } = useProjectTimeline(projectID);
  const [timelineEvents, setTimelineEvents] = useState([])

  useEffect(() => {
    if (!isFetching && projectEvents) {
      const te = onGetOrderedEventList(projectEvents, areaContext, true, !showPackage)
      setTimelineEvents(te)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEvents, isFetching])


  const timelineEventRef = useRef();

  useEffect(() => {
    if (timelineEventRef && timelineEventRef.current) {
      timelineEventRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineEventRef.current, timelineEvents])

  const isEventSelected = (projectEvent) => {
    if (event) {
      if (projectEvent.TypeName === event.TypeName) {
        if (projectEvent.GroupedEventIDs) {
          return (projectEvent.GroupedEventIDs.includes(event.ID))
        }
        if (projectEvent.ID && event.ID) {
          return (projectEvent.ID === event.ID)
        }
      }
    }
    return false
  }

  return (
    <ProjectEventsTimelineContainer>
      {isFetching
        ?
        <Loading />
        :
        (timelineEvents && timelineEvents.length > 0) && timelineEvents.map((projectEvent, index) => (
          <div ref={(event && projectEvent.ID === event.ID && projectEvent.TypeName === event.TypeName) ? timelineEventRef : null} key={index} >
            <ProjectTimelineEvent isSelected={isEventSelected(projectEvent)} event={projectEvent} onSelectEvent={onSelectEvent} />
          </div>
        ))
      }
    </ProjectEventsTimelineContainer>
  )
}

export default ProjectEventsTimeline;