import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import MediaDrawer from 'Components/MediaDrawer/MediaDrawer';
import MultiMediaActions from 'Components/ModelMedia/MultiMediaActions/MultiMediaActions';
import MediaListContainer from 'Components/ModelMedia/ModelMediaPanel/ModelMediaList/MediaListContainer';
import { useStateValue } from 'Contexts/StateProvider';
import { useEffect } from 'react';
  
const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0 16px 16px;
`;

const ModelMediaList = ({ 
    isMediaSelectable, 
    sort, 
    size, 
    contactID, 
    selectedBook, 
    mediaList,
    onUpdateMediaList,
    onCancelBook, 
    onEditBook,
    onMediaUpdate,
    onUpdateSelectedBook,
    onOpenBookList,
    packageBook,
    onCloseBookEditor,
    isDrawer,
    onBookTouched,
}) => {
    const [clickedMedia, setClickedMedia] = useState(null);
    const [{areaContext}] = useStateValue();
    const [deleteErrors, setDeleteErrors] = useState([]); 

    useEffect(() => {
        if (deleteErrors.length > 0) {
            //clear errors after 10 seconds
            setTimeout(() => {
                setDeleteErrors([]);
            }, 10000);
        }
    }, [deleteErrors])

    const onClickThumbnail = (index, sortedMedia) => {
        const updatedMediaList = [...mediaList];
        if (isMediaSelectable) {
            const findIndex = mediaList.findIndex(media => media.ID === sortedMedia[index].ID);
            if (findIndex > -1) {
                updatedMediaList[findIndex].isSelected = mediaList[findIndex].isSelected ? false : true;
                onUpdateMediaList(updatedMediaList)
            }
        } else {
            setClickedMedia(sortedMedia[index]);
        }
        return true;
    };
    
    const onCloseDrawer = currentMedia => {
        let updatedMediaList = [...mediaList];
        if (currentMedia && clickedMedia.ID === currentMedia.ID) {
            const findIndex = mediaList.findIndex(media => media.ID === currentMedia.ID);
            updatedMediaList[findIndex] = {
                ...updatedMediaList[findIndex],
                ...currentMedia
            };
            onUpdateMediaList(updatedMediaList);
        } else {
            updatedMediaList = mediaList.filter(media => media.ID !== clickedMedia.ID);
            onUpdateMediaList(updatedMediaList);
        }
        setClickedMedia(null); 
    };

    const selectMediaBefore = sortedMediaList => () => {
        const mediaIndex = sortedMediaList.findIndex(media => media.ID === clickedMedia.ID);
        if (mediaIndex > 0) {
            setClickedMedia(sortedMediaList[mediaIndex - 1]);
        }
    };

    const selectMediaAfter = sortedMediaList => () => {
        const mediaIndex = sortedMediaList.findIndex(media => media.ID === clickedMedia.ID);
        if (mediaIndex > -1 && mediaIndex < sortedMediaList.length-1) {
            setClickedMedia(sortedMediaList[mediaIndex + 1]);
        }
    };

    const OnSelectCover = () => {};

    const handleUpdateSelectedMediaList = selectedMediaList => {
        let updatedMediaList = [...mediaList];
        selectedMediaList.map(media => {
            const findIndex = updatedMediaList.findIndex(item => item.ID === media.ID);
            if (findIndex > -1) {
                updatedMediaList[findIndex] = media;
            }
            return null;
        });
        onUpdateMediaList(updatedMediaList);
    }

    const handleDeleteErrors = errors => {
        setDeleteErrors([...errors]);
    }

    // const handleDeleteMedia = documentIDs => {
    //     const updatedMediaList = [];
    //     mediaList.map(media => {
    //         const findIndex = documentIDs.findIndex(mediaID => mediaID === media.ID);
    //         if (findIndex === -1) {
    //             updatedMediaList.push(media);
    //         }
    //         return null;
    //     });
    //     onUpdateMediaList(updatedMediaList);
    // };

    const handleSelectAllMedia = () => {
        const updatedMediaList = mediaList.map(media => ({
            ...media,
            isSelected: true
        }));
        onUpdateMediaList(updatedMediaList);
    };

    const handleClearSelection = () => {
        const updatedMediaList = mediaList.map(media => ({
            ...media,
            isSelected: false
        }));
        onUpdateMediaList(updatedMediaList);
    };

    const sortedMediaList = useMemo(() => {
        let sortedMediaList = [...mediaList];
        if (sort === 'time') {
            sortedMediaList.sort((item1, item2) => {
                return new Date(item2.DateCreated) > new Date(item1.DateCreated) ? 1 : -1;
            });
        } else if (sort === 'myrating') {
            sortedMediaList.sort((item1, item2) => {
                return item2.Rating.currentUserRating - item1.Rating.currentUserRating;
            });
        } else if (sort === 'globalrating') {
            sortedMediaList.sort((item1, item2) => {
                return item2.Rating.averageRating - item1.Rating.averageRating;
            });
        }

        sortedMediaList = sortedMediaList.filter(media => {
            if (media.Visibility.Areas.length === 0 || media.Visibility.Areas.find(area => area === areaContext.area)) {
                return true;
            }
            return false;
        });
        return sortedMediaList;
    }, [sort, mediaList]);

    const selectedMediaList = sortedMediaList.filter(media => media.isSelected);

    return (
        <ListContainer>
            <MediaListContainer 
                isMediaSelectable={isMediaSelectable}
                sortedMediaList={sortedMediaList}
                size={size}
                onClickThumbnail={onClickThumbnail}
                selectedBook={selectedBook}
                contactID={contactID}
                onCancelBook={onCancelBook}
                onEditBook={onEditBook}
                clickedMedia={clickedMedia}
                onUpdateSelectedBook={onUpdateSelectedBook}
                packageBook={packageBook}
                onCloseBookEditor={onCloseBookEditor}
                isDrawer={isDrawer}
                onOpenBookList={onOpenBookList}
                onBookTouched={onBookTouched}
                deleteErrors={deleteErrors}
            />
            {isMediaSelectable && 
                <MultiMediaActions 
                    mediaList={selectedMediaList} 
                    contactID={contactID} 
                    onUpdateSelectedMedia={handleUpdateSelectedMediaList}
                    //onDeleteMedia={handleDeleteMedia}
                    onDeleteErrors={handleDeleteErrors}
                    onOpenBookList={onOpenBookList}
                    onSelectAll={handleSelectAllMedia} 
                    onClearSelection={handleClearSelection}
                />
            }
            {clickedMedia && (
                <MediaDrawer 
                    mediaID={clickedMedia.ID}
                    contactID={contactID}
                    onCloseDrawer={onCloseDrawer}
                    onSelectMediaBefore={selectMediaBefore(sortedMediaList)}
                    onSelectMediaAfter={selectMediaAfter(sortedMediaList)}
                    onSelectCover={OnSelectCover} 
                    afterNewMediaCreation={onCloseDrawer}
                />
            )}
        </ListContainer>
    );
};

export default ModelMediaList;