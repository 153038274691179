import React, { useContext, useMemo } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { sortTags, mapTags } from 'Libs/NwUtils';
import { ListContext } from 'Contexts/ListContext'

const { Option } = Select;

const TagSelect = styled(Select)`
    margin: 0.5em 0 !important;
`

const TagSelector = ({ value, onChange, itemType, tagMode }) => {
    const {cachedList} = useContext(ListContext);
    
    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tags = useMemo(() => sortTags(mapTags(cachedtags, itemType)),[cachedtags])

    const handleChange = value => {
        onChange(value);
    };

    return (
        <TagSelect 
            autoComplete='off'
            mode={tagMode === 'item' ? 'default' : 'tags'}
            style={{ width: '100%' }} 
            value={value}
            placeholder={tagMode === 'item' ? 'Select Tag' : 'Select Tags'} 
            onChange={handleChange} 
            getPopupContainer={triggerNode => triggerNode.parentNode}>
            {tags.map(tag => (
                <Option key={tag.ID}>{tag.Name}</Option>
            ))}
        </TagSelect> 
    );
};

export default TagSelector;