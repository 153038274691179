import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables';
import { NwButton } from 'Components/Gui/NwButton';

const Container = styled.div`
    display: flex;

    .ant-btn:hover, .ant-btn:focus {
        border-color: rgba(0,0,0,0.25) !important;
    }

    .ant-btn:hover {
        color: ${StyleVariables.DangerColor} !important;
    }

    .ant-picker {
        border-radius: ${props => props.nullable ? '6px 0 0 6px !important' : '6px !important'};
    }
`;

const ClearButtonStyle = {
    padding: '0 8px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 4px 4px 0',  
    color: 'rgba(0,0,0,0.25)',
    borderLeft: 'none'
};

const CustomDatePicker = ({ nullable, onChange, disabledTime, ...rest }) => {
    return (
        <Container nullable={nullable}>
            <DatePicker
                autoComplete='off'
                onChange={onChange}
                {...rest} />
            {nullable && 
                <NwButton icon={light('times')} style={ClearButtonStyle} onClick={event => onChange(null)} />
            }
        </Container>
    );
};

export default CustomDatePicker;