import React, { useEffect, useState } from 'react';
import EventDrawerHeader from 'Components/EventDrawer/EventDrawerUi/EventDrawerHeader';
import EventDrawerTabs from 'Components/EventDrawer/EventDrawerUi/EventDrawerTabs';
import BookingEventComments from 'Components/EventDrawer/BookingEvent/BookingEventComments';
import ProjectEventModels from 'Components/Project/ProjectEventModels';
import ProjectTransactions from 'Components/Project/ProjectTransactions/ProjectTransactions';
import ProjectContacts from 'Components/Project/ProjectContacts/ProjectContacts';
import ProjectDocuments from "Components/Project/ProjectDocuments/ProjectDocuments";
import { Loading } from 'Components/Gui/Loading';
import { addProjectLog } from 'Libs/NwLogs';

const EventDrawerDetailsContainer = ({
  filterModel,
  firstTab,
  isFetching,
  eventData,
  typeName,
  onSelectProject
}) => {
  useEffect(() => {
    if (eventData.Project) {
      addProjectLog(eventData.Project);
    }
  }, []);
  const tabs = eventData.Project ? [
    firstTab,
    <ProjectEventModels
        projectId={eventData.Project.ID}
        filterModel={filterModel}
    />,
    <ProjectTransactions
        projectId={eventData.Project.ID}
        eventData={eventData}
        mode='event'
        modelId={filterModel}
    />,
    <ProjectContacts 
        projectId={eventData.Project.ID}
        mode='event'
        isDrawer={true}
    />,
    <ProjectDocuments
      projectId={eventData.Project.ID}
      modelId={filterModel}
      fluid={true}
    />,
    <BookingEventComments
      eventData={eventData}
      filterModel={filterModel}
    />,
  ] : [];

  const [projectErrorMessage, setProjectErrorMessage] = useState('');
  
  if (isFetching && !eventData) {
    return <Loading textBlack/>
  }

  return (
    <>
      <EventDrawerHeader
        typeName={typeName}
        data={eventData}
        modelID={filterModel}
        onSelectProject={onSelectProject}
        projectErrorMessageProps={{
          message: projectErrorMessage,
          onClose: () => setProjectErrorMessage('')
        }}
      />
      {eventData.Project ?
        <>
          <EventDrawerTabs tabs={tabs} />
        </>
        : firstTab
      }
    </>
  )
}

export default EventDrawerDetailsContainer;