import React, { useContext } from "react";
import { Select } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import _, { isArray } from "lodash";
import { ListContext } from "Contexts/ListContext";
import { UserContext } from 'Contexts/UserContext';
import { NwButton } from 'Components/Gui/NwButton';
import { checkUserInDepartments, checkUserInNetworkAgency, checkUserLicence } from "Libs/NwUsers";

const { Option } = Select;

const Container = styled.div`
    display: flex;
    /* width: 100%; */

    .ant-btn:hover, .ant-btn:focus {
        border-color: rgba(0,0,0,0.25) !important;
    }

    .ant-btn:hover {
        color: ${props => props.theme.DangerColor} !important;
    }
`;

const StyledSelect = styled(Select)`
    min-width: 200px;
    .ant-select-selection {
        border-radius: 4px 0 0 4px
    }

    .ant-select-selector {
        border-radius: ${props => props.$closable ? '6px !important' : '6px 0 0 6px !important'};
    }
`;

const ClearButtonStyle = {
    padding: '0 8px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 4px 4px 0',
    color: 'rgba(0,0,0,0.25)',
    borderLeft: 'none'
};

const BookerSelector = ({ 
    name,
    onBlur,
    onChange,
    value,
    disabled,
    style,
    mode,
    disableClosable,
    availableBookers,
    onlyActive, //boolean - only with an active licence
    onlyInDepartments, //array of allowed department IDs
    onlyInNetworkAgency, //NetworkAgencyID (single)
    placeholder,
    ...rest
}) => {
    const { cachedList } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    let users = cachedList.users.items.filter(user => currentUser.isSuperAdmin || !user.isSuperAdmin);

    if (availableBookers) {
        users = users.filter(user => availableBookers.find(bookerId => bookerId === user.ID));
    }
    if (onlyActive) {
        users = users.filter(user => checkUserLicence(user));
    }
    if (onlyInDepartments && isArray(onlyInDepartments)) {
        users = users.filter(user => checkUserInDepartments(user, onlyInDepartments));
    }
    if (onlyInNetworkAgency) {
        users = users.filter(user => checkUserInNetworkAgency(user, onlyInNetworkAgency));
    }
    if (users.map(user => user.ID).indexOf(value) === -1) {
        const currentUser = cachedList.users.items.find(user => user.ID === value);
        if (currentUser) {
            users = [...users, cachedList.users.items.find(user => user.ID === value)];
        }
    }
    users = _.sortBy(users, ['Name', 'Surname']);

    return (
        <Container>
            <StyledSelect
                $closable={disableClosable ? 1 : 0}
                name={name}
                showSearch
                value={value}
                defaultValue={value}
                disabled={disabled}
                style={style}
                mode={mode}
                placeholder={placeholder || 'Select a booker'}
                onChange={onChange}
                onBlur={onBlur}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                filterOption={(input, option) => {
                        return option.props.value &&
                        option.props.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }
                {...rest}
            >
                {_.sortBy(users, ['Name', 'Surname']).map(el => (
                    <Option key={el.ID} value={el.ID}>
                    {el.Name} {el.Surname}
                    </Option>
                ))}
            </StyledSelect>
            {!disableClosable && 
                <NwButton icon={light('times')} style={ClearButtonStyle} onClick={event => onChange(undefined)} />
            }
        </Container>
    );
};

export default BookerSelector;
