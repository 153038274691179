import React, { useState, useEffect } from "react";
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import styled from 'styled-components';
import { Dropdown, Popover } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import { NwButton, NwCancelButton } from "Components/Gui/NwButton";
import { StyledGroupButton } from "Components/Gui/StyledGroupButton";
import FlexContainer from 'Components/Gui/FlexContainer';

const PopoverMessage = styled.div`
  width: 420px;
  padding: 2em 1em 3em 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    font-size: 1.2em;
    max-width: 300px;
    margin: 0;
  }

  .danger-icon {
    .fa-secondary {
      fill: ${props => props.theme.DangerColor};
      opacity: 1;
    }
    .fa-primary {
      fill: #fff;
      opacity: 1;
    }
  }
`

const PopoverButtons = styled.div`
  display: flex;
  justify-content: space-around;
`




const NwDropDownMenuConfirm = ({ action, onClose, visibleKey, children }) => {

    const [visible, setVisible] = useState(visibleKey === action.key)

    const standardConfirmationLabel = "You're permanently removing the selected object. Please note that this can't be undone"
    const standardConfirmationButtonLabel = "DELETE"
    useEffect(() => {
        setVisible(visibleKey === action.key)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleKey])

    const handleVisibleChange = value => {
        setVisible(value)
    }

    const confirmClick = () => {
        setVisible(false)
        action.action()
        onClose()
    }

    const onCancelConfirm = () => {
        setVisible(false)
        onClose()
    }

    return (<Popover
        placement={action.confirmationPlacement || "top"}
        arrowPointAtCenter
        title="PLEASE CONFIRM"
        overlayClassName="confirm-on-dropdown" //defined in app.css to set z-index to 2000 (above dropdown menu)
        content={
            <>
                <PopoverMessage>
                    <NwIcon className="danger-icon" icon={duotone("exclamation-triangle")} size="3x" />
                    <p>{action.confirmationLabel || standardConfirmationLabel}</p>
                </PopoverMessage>
                <PopoverButtons>
                    <NwCancelButton onClick={onCancelConfirm} />
                    <NwButton danger icon={action.confirmationButtonIcon || light("trash-alt")} label={action.confirmationButtonLabel || standardConfirmationButtonLabel} onClick={confirmClick} />
                </PopoverButtons>
            </>
        }
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
    >
        {children}
    </Popover>)
}


const NwDropDownMenu = ({
    actions,
    label,
    loading,
    primary,
    right,
    size,
    tooltip,
    trigger = "click",
    ...rest
}) => {
    const [visible, setVisible] = useState(false)
    const [openConfirm, setOpenConfirm] = useState("")

    const [ddActions, setDdActions] = useState("")
    const [pinnedActions, setPinnedActions] = useState("")

    useEffect(() => {
        if (actions) {
            setDdActions(actions.filter((act) => !act.pinned))
            setPinnedActions(actions.filter((act) => act.pinned))
        }
    }, [actions])

    const renderAction = action => {
        return (
            <FlexContainer>
                {action.icon &&
                    <span className="menu-item-icon-container">
                        {action.icon && <NwIcon icon={action.icon} />}
                    </span>
                }
                {action.name}
            </FlexContainer>
        )
    };

    const renderMenu = (action, index) => {
        if (action.divider) {
            return ({
                key: `divider-${index}`,
                type: 'divider'
            });
        }
        if (action.group) {
            return ({
                key: `group-${index}`,
                label: action.name,
                type: "group",
                children: action.subactions.map(renderMenu)
            });
        }
        return ({
            key: action.key,
            disabled: action.disabled ? true : false,
            label: (
                (action.type === "delete" && !action.disabled) ?
                    <NwDropDownMenuConfirm action={action} onClose={onCloseConfirm} visibleKey={openConfirm}>
                        {renderAction(action)}
                    </NwDropDownMenuConfirm> :
                    action.description && action.disabled ?
                        <Popover content={action.description} overlayClassName="confirm-on-dropdown">
                            {renderAction(action)}
                        </Popover> : renderAction(action)
            )
        });
    }

    const onCloseConfirm = () => {
        setOpenConfirm("")
        setVisible(false)
    }

    const handleClick = (e) => {
        const actionobject = actions.find((act) => (act.key === e.key))
        if (actionobject.type === "delete") {
            setOpenConfirm(e.key)
        } else {
            setVisible(false)
            actionobject.action()
        }
    }

    const handleClickPinned = (action) => {
        if (action.type === "delete") {
            setOpenConfirm(action.key)
        } else {
            action.action()
        }
    }

    const getMenuActions = () => {
        if (ddActions && ddActions.length > 0) {
            const actionsdd = ddActions.map(renderMenu)
            return actionsdd
        } else {
            return []
        }
    }

    const handleVisibleChange = value => {
        setVisible(value)
    }

    return (
        <StyledGroupButton
            right={right}
            size={size}
        >
            {!loading &&
                <>
                    {(pinnedActions && pinnedActions.length > 0) &&
                        pinnedActions.map((action, index) => (
                            (action.type === "delete" && !action.disabled)
                                ?
                                <NwDropDownMenuConfirm
                                    key={`${action.name}-${index}`}
                                    action={action}
                                    onClose={onCloseConfirm}
                                    visibleKey={openConfirm}
                                >
                                    <NwButton
                                        primary={primary}
                                        tooltip={action.tooltip}
                                        onClick={() => handleClickPinned(action)}
                                        label={action.name}
                                        icon={action.icon}
                                        disabled={action.disabled}
                                    />
                                </NwDropDownMenuConfirm>
                                :
                                action.description && action.disabled ?
                                    <Popover
                                        key={`${action.name}-${index}`}
                                        content={action.description}
                                        overlayClassName="confirm-on-dropdown"
                                    >
                                        <NwButton
                                            primary={primary}
                                            tooltip={action.tooltip}
                                            onClick={() => handleClickPinned(action)}
                                            label={action.name}
                                            icon={action.icon}
                                            disabled={action.disabled}
                                        />
                                    </Popover>
                                    :
                                    <NwButton
                                        primary={primary}
                                        key={`${action.name}-${index}`}
                                        tooltip={action.tooltip}
                                        onClick={() => handleClickPinned(action)}
                                        label={action.name}
                                        icon={action.icon}
                                        disabled={action.disabled}
                                    />
                        )
                        )}
                    {(ddActions.length > 0) &&
                        <Dropdown
                            menu={{
                                onClick: handleClick,
                                items: getMenuActions()
                            }}
                            overlayClassName="nw-dropdown-menu"
                            open={visible}
                            trigger={trigger}
                            onOpenChange={handleVisibleChange}
                            placement="bottomRight"
                            {...rest}
                        >
                            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <NwButton
                                    primary={primary}
                                    className="dropdown-trigger"
                                    icon={regular("ellipsis-h")}
                                />
                            </div>
                        </Dropdown>
                    }
                </>
            }
        </StyledGroupButton>
    );
};

export default NwDropDownMenu;