import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { onGetOrderedEventList, forceReloadProject } from 'Hooks/Project/UseProject';
import EmptyState from "Components/Gui/EmptyState";
import Comments from "Components/Comments/Comments";
import { NwAddButton } from 'Components/Gui/NwButton';
import NwDrawer from 'Components/Gui/NwDrawer';
import { useStateValue } from 'Contexts/StateProvider';
import BookingEventCommentForm from './BookingEventCommentForm';
import { groupCommentsByModel } from './BookingEventCommentsUtils';
import { useProjectComments } from "Hooks/Project/UseProjectComments";
import { Loading } from "Components/Gui/Loading";

const AddButtonContainer = styled.div`
  padding: 0px 0px 20px 20px;
  border-bottom: ${props => props.theme.DefaultBorder};
`;

const CommentsBox = styled.div`
  .title {
    text-transform: uppercase;
    border-bottom: ${props => props.theme.DefaultBorder};
    padding: 10px 0px 10px 30px;
  }
  .comments-list {
    padding-left: 20px;
  }
`;

const BookingEventComments = ({ filterModel, project, eventData }) => {

  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [touchForm, setTouchForm] = useState(false);
  const [groupedComments, setGroupedComments] = useState([]);
  const [generalComments, setGeneralComments] = useState([]);
  const [selectedModelComments, setSelectedModelComments] = useState([]);

  const [{ areaContext }] = useStateValue();
  
  const events = onGetOrderedEventList(project, areaContext, true, true);
  
  const { data: projectComments, isFetching: loadingComments } = useProjectComments(project.ID)

  useEffect(() => {
    if (!loadingComments && projectComments.length > 0) {
      setGroupedComments(groupCommentsByModel(projectComments))
      setGeneralComments(projectComments.filter(t => t.ContactID === null))
      setSelectedModelComments(projectComments.filter(t => t.ContactID === filterModel))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectComments])

  const handleCloseAddForm = () => {
    setIsCommentDrawerOpen(false);
    setItemToEdit(null);
  }
  const onCommentCreatedOrUpdated = () => {
    forceReloadProject(project.ID);
    handleCloseAddForm();
    setItemToEdit(null);
  }

  const onEditComment = (comment) => {
    setItemToEdit(comment);
    setIsCommentDrawerOpen(true);
  }

  return (
    loadingComments
      ?
      <Loading />
      :
      <>
        <AddButtonContainer>
          <NwAddButton size='small' ghost label="add comment" onClick={() => setIsCommentDrawerOpen(true)} />
        </AddButtonContainer>
        {
          generalComments.length > 0 && (
            <CommentsBox>
              <h3 className="title">General comments</h3>
              <div className="comments-list">
                <Comments
                  events={events}
                  onEdit={onEditComment}
                  isInCardTab
                  api='ProjectID'
                  comments={generalComments}
                  ID={project.ID}
                  disableVisibility
                  onUpdate={onCommentCreatedOrUpdated}
                  isPaginated={false}
                  onTouchForm={setTouchForm}
                />
              </div>
            </CommentsBox>
          )
        }
        {
          selectedModelComments.length > 0 && (
            <CommentsBox>
              <h3 className="title">{getContactName(filterModel)}</h3>
              <div className="comments-list">
                <Comments
                  events={events}
                  onEdit={onEditComment}
                  isInCardTab
                  api='ProjectID'
                  comments={selectedModelComments}
                  ID={project.ID}
                  disableVisibility
                  onUpdate={onCommentCreatedOrUpdated}
                  isPaginated={false}
                  onTouchForm={setTouchForm}
                />
              </div>
            </CommentsBox>
          )
        }
        {
          Object.keys(groupedComments).map(modelId => (
            <CommentsBox>
              <h3 className="title">{getContactName(+modelId)}</h3>
              <div className="comments-list">
                <Comments
                  events={events}
                  onEdit={onEditComment}
                  isInCardTab
                  api='ProjectID'
                  comments={groupedComments[modelId]}
                  ID={project.ID}
                  disableVisibility
                  onUpdate={onCommentCreatedOrUpdated}
                  isPaginated={false}
                  onTouchForm={setTouchForm}
                />
              </div>
            </CommentsBox>
          ))
        }
        {
          generalComments.length === 0 && selectedModelComments.length === 0 && Object.keys(groupedComments).length === 0 && (
            <EmptyState message="No comments" />
          )
        }
        {
          isCommentDrawerOpen && (
            <NwDrawer
              touchForm={touchForm}
              onTouchForm={setTouchForm}
              title={itemToEdit ? 'Edit comment' : 'Add comment'}
              onClose={handleCloseAddForm}>
              <BookingEventCommentForm
                //events={orderedEventList}
                events={events}
                eventData={eventData}
                modelId={filterModel}
                comments={projectComments}
                api="ProjectID"
                project={project}
                noPadding
                disableVisibility
                onSave={onCommentCreatedOrUpdated}
                itemToEdit={itemToEdit}
                onTouchForm={setTouchForm}
              />
            </NwDrawer>
          )
        }
      </>
  )
}

export default BookingEventComments;
