import { Layout } from "antd";
import styled from 'styled-components';
import { Row, Col } from 'antd';

const { Content, Sider } = Layout;

const StyledLayout = styled(Layout)`
    margin-top: ${props =>  props.$navigation ? '50px' : '0'};
    min-height: calc(100vh - 60px);
`;

const StyledContent = styled(Content)`
    padding: 1rem;
    @media only screen and (max-width: 480px) {
        padding: 1rem 0;
    }
`;

const HeaderPanel = styled.div`
    padding: 1rem;
    background: white;
    margin: 1rem 1rem 0 1rem;

    @media only screen and (max-width: 480px) {
        margin: 1rem 0; 
    }
`;

const WallContainer = styled.div`
    padding: 1rem;
    margin-bottom: 24px;
    
    @media only screen and (max-width: 480px) {
        padding: 0;
    }
`;

const StyledCustomersLayout = styled(Layout)`
    margin-left: ${props => props.size === 'small' ? 0 : 
        (props.size === 'big'?"350px":'300px')};
`;

const DashboardContent = styled.div`
    margin: .75rem 1.5rem;
    @media only screen and (max-width: 480px) {
        margin: .75rem 0; 
    }
`;

const SiderStyled = styled(Sider)`
  height: calc(100vh - 60px);
  background-color: #fff;
  z-index: 1030;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
  position: fixed;
  padding: 1em;
  text-align: ${props => props.size === 'small' && 'center'};
`;

const ResponsiveRow = styled(Row)`
    width: 100%;
    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }
`;

const TableColBorder = styled(Col)`
    border-left: 1px solid rgb(232, 232, 232);

    &:first-of-type {
        border-left: none;
    }

    &.responsive-1600 {
        display: block;
        justify-content: flex-start !important;

        @media only screen and (max-width: 1600px) {
            display: none !important;
        }
    }
`;

export {
    HeaderPanel,
    StyledLayout,
    StyledContent,
    WallContainer,
    StyledCustomersLayout,
    DashboardContent,
    SiderStyled,
    ResponsiveRow,
    TableColBorder
}