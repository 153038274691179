export const CUSTOMERS_FILTERS = {
    reducerPrefix: 'CustomerFilters',
    sections: [
        {name: "work"},
        {name: "bookers"},
        {name: "demographic", showCustomFields: true, customFieldsObject: ['Client','Agency','Service']}
    ],
    filters: [
        {
            name: 'CustomerType',
            label: 'Type',
            area: 'work',
            defaultval: null,
            type: 'customers-type'
            // commontype: 'period',
            // type: 'period'
        },
        {
            name: 'WorkingOn',
            label: 'Working On',
            area: 'work',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'NotWorkingOn',
            label: 'NOT working On',
            area: 'work',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Booker',
            label: 'Booker',
            area: 'bookers',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'Tags',
            label: 'Tags',
            area: 'bookers',
            defaultval: [],
            commontype: 'tag',
            type: 'customers-tag'
        },
        {
            name: 'AddedSince',
            label: 'Added Since',
            area: 'bookers',
            defaultval: '{amonthago}',
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'AddedUntil',
            label: 'Added Until',
            area: 'bookers',
            defaultval: '{today}',
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'Countries',
            label: 'Nationality',
            area: 'demographic',
            defaultval: [],
            commontype: 'array',
            type: 'countriesList'
        },
        {
            name: 'Cities',
            label: 'City',
            area: 'demographic',
            defaultval: [],
            commontype: 'array',
            type: 'citiesList'
        },
    ]
};