import React from "react";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const InTownSelector = (props) => {
    return (
        <RadioButtonGroup 
            value={props.value} 
            disabled={props.disabled}
            onChange={props.onChange}
            defaultValue={false}
            options={[
                { label: 'In Town', value: true, icon: solid('location-smile') },
                { label: 'Out', value: false, icon: solid('island-tropical') }
            ]} />
    );
};

export default InTownSelector;
