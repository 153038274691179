import _ from 'lodash';
import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';
import { removeProjectLog } from 'Libs/NwLogs';

const onGetProjectByID = ID => (
    Axios.get(`/projects/${ID}`)
)

const useProject = (ID) => {
    return useQuery(["project", ID], () => onGetProjectByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: ID ? true : false
    });
}

// const useProjectForContacts = (ID) => {
//     return useQuery(["project-contact", ID], () => onGetProjectByID(ID), {
//         staleTime: 10000,
//         cacheTime: 10000,
//         retry: false,
//         enabled: ID ? true : false
//     });
// }

const getProjectPreviewByID = ID => {
    return Axios.get(`/projects/preview/${ID}`)
};

const usePreviewProject = (ID) => {
    return useQuery(["projectpreview", ID], () => getProjectPreviewByID(ID), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: ID ? true : false
    });
}

const forceReloadProject = (ID) => {
    return queryClient.invalidateQueries(['project', ID])
}

const getNonBookingEventID = (event, type) => {
    if (event.GroupGuid) {
        return event.GroupGuid
    }
    if (event.Models && event.Models.length) {
        const firstModel = event.Models[0];
        if (firstModel) {
            return firstModel[type + "ID"]
        }
    }
    if (event.ID) {
        return event.ID
    }
    return null;
}

const onGetOrderedEventList = (currentProject, areaContext, excludeTodos, excludePackages) => {
    let orderedEventList = [];
    if (currentProject) {
        const evs = currentProject.Events.map((item) => ({ ...item, sortField: item.FirstDate, elType: 'Event', uniqID: `Event-${item.ID}` }))
        let tds = []
        if (!excludeTodos) {
            tds = currentProject.ToDos.filter(todo => {
                if (todo.Visibility.Areas.length === 0 || todo.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            }).map((item) => ({ ...item, sortField: item.Date, elType: 'ToDo', TypeName: "ToDo", uniqID: `ToDo-${item.ID}` }))
        }
        let pkgs = []
        if (!excludePackages) {
            pkgs = currentProject.Packages.map((item) => ({ ...item, sortField: item.DateCreated, elType: 'Package', TypeName: "Package", uniqID: `Package-${item.ID}` }));
        }
        const travels = currentProject.Travels.map((item) => ({ ...item, sortField: item.StartDateTime, elType: 'Travel', TypeName: "Travel", uniqID: `Travel-${getNonBookingEventID(item, 'Travel')}` }));
        const accommodations = currentProject.Accommodations.map((item) => ({ ...item, sortField: item.FromDate, elType: 'Accommodation', TypeName: "Accommodation", uniqID: `Accommodation-${getNonBookingEventID(item, 'Accommodation')}` }));
        const followups = currentProject.FollowUps.map((item) => ({ ...item, sortField: item.StartDate, elType: 'FollowUp', TypeName: "FollowUp", uniqID: `FollowUp-${getNonBookingEventID(item, 'FollowUp')}` }));
        const meetings = currentProject.Meetings.map((item) => ({ ...item, sortField: item.StartDate, elType: 'Meeting', TypeName: "Meeting", uniqID: `Meeting-${item.ID}` }));
        let result = [].concat(
            evs,
            travels,
            accommodations,
            followups,
            meetings
        )
        if (!excludeTodos) {
            result.push(...tds)
        }
        if (!excludePackages) {
            result.push(...pkgs)
        }
        orderedEventList = _.sortBy(result, ['sortField']);
    }
    return orderedEventList
}

const onUpdateProject = ({ projectId, data }) => (
    Axios.put(`/projects/${projectId}`, data)
);

const useUpdateProject = () => {
    return useMutation(onUpdateProject, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
}

const onDeleteProject = ({ projectId }) => (
    Axios.delete(`/projects/delete/${projectId}`)
);

const useDeleteProject = () => {
    return useMutation(onDeleteProject, {
        onSuccess: (data, variables) => {
            removeProjectLog(variables.projectId)
            forceReloadBillingProjectsSearch()
        }
    })
};

const onAddProject = ({ data }) => (
    Axios.post("/projects", data)
);

const useAddProject = () => {
    return useMutation(onAddProject, {
        onSuccess: (data, variables) => {
        }
    })
};

const onAddTag = async ({ tag, projectId }) => {
    return await Axios.post(`/projects/addtag/${projectId}/${tag.ID}`);
}

const onRemoveTag = async ({ tag, projectId }) => {
    const response = await Axios.post(`/projects/removetag/${projectId}/${tag.ID}`);
    return response;
}

const useAddTag = () => {
    return useMutation(onAddTag, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
};

const useRemoveTag = () => {
    return useMutation(onRemoveTag, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
};

const onSearchProjects = searchParams => (
    Axios.post('/projects/search', searchParams)
);

const getProjectSearchParams = filters => {
    if (filters) {
        let searchParams = {...filters};
        if (filters.WorkingOn) {
            searchParams = {
                ...searchParams,
                startDate: filters.WorkingOn[0],
                endDate: filters.WorkingOn[1],
            }
            delete searchParams.WorkingOn;
        }
        return searchParams;
    }
    return null;
};

const useBillingProjectsSearch = (filters) => {
    let searchParams = getProjectSearchParams(filters);
    return useQuery(['billing-projects-search'], () => onFullSearchProjects(searchParams), {
        enabled: filters ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

const forceReloadBillingProjectsSearch = (ID) => {
    return queryClient.invalidateQueries(['billing-projects-search'])
}

const refetchBillingProjectsSearch = async (filters) => {
    let searchParams = getProjectSearchParams(filters);
    try {
        await queryClient.fetchQuery(['billing-projects-search'], () => onFullSearchProjects(searchParams));
    } catch (error) {
        console.log(error)
    }
};

const onFullSearchProjects = searchParams => (
    Axios.post('/projects/FullSearch', searchParams)
);

const onUpdateProjectCustomFields = ({ projectId, data }) => (
    Axios.put(`/projects/Customfields/${projectId}`, data)
);

const useUpdateProjectCustomFields = () => {
    return useMutation(onUpdateProjectCustomFields, {
        onSuccess: (data, variables) => {
            forceReloadProject(variables.projectId);
        }
    })
}

export {
    useProject,
    usePreviewProject,
    useUpdateProject,
    useAddProject,
    useDeleteProject,
    forceReloadProject,
    onGetOrderedEventList,
    useRemoveTag,
    useAddTag,
    //onGetTransActionType,
    //TRANSACTION_TYPES,
    onSearchProjects,
    onFullSearchProjects,
    onGetProjectByID,
    useUpdateProjectCustomFields,
    refetchBillingProjectsSearch,
    forceReloadBillingProjectsSearch,
    useBillingProjectsSearch
    //useProjectForContacts,
};


