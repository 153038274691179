import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import EmptyState from 'Components/Gui/EmptyState';
import NwIcon from 'Components/Gui/NwIcon';
import NwUpload from "Components/Gui/NwUpload";
import Document from "Components/ContactDetailsDocuments/Document";
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import NwDrawer from 'Components/Gui/NwDrawer';
import AttachmentDetailsForm from 'Components/Project/ProjectDocuments/AttachmentDetailsForm';
import { Loading } from 'Components/Gui/Loading';
import FlexContainer from 'Components/Gui/FlexContainer';
import { useStateValue } from 'Contexts/StateProvider';
import { getUrlDocument } from "Libs/NwUtils";
import { useProject, forceReloadProject, onGetOrderedEventList } from 'Hooks/Project/UseProject';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { getFileTypeIcons } from 'Libs/NwUtils';

const MiniContent = styled.div`
    font-size: 12px;
    padding-left: 10px;
    .model-name {
        font-weight: bold;
    }
    .document-row {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        user-select: none;
        .document-image {
            font-size: 20px;
            margin-left: 5px;
        }
        .document-title {
            margin: auto 0 auto 10px;
        }
    }
`

const Wrapper = styled.div`
    margin-bottom: 1rem;

    /* .ant-card {
        min-height: 400px;
    } */
    .ant-card-body-content {
        padding: 0 !important;
    }
`;

const GridCard = styled(Card.Grid)`
    float: left; //added because ant removed it to use flex- to update later
    width: 25%;
    height: 140px;
    @media only screen and (max-width: 1600px) {
        width: 33.33%;
    }
    @media only screen and (max-width: 1200px) {
        width: 50%;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
    width: ${props => props.fluid && '100% !important'};

    .ant-list-item-meta {
        width: 100%;
    }
`;

const DocumentBox = styled.div`
    width: 100%;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
    
    .header {
        text-transform: uppercase;
        font-weight: 500;
        font-size: ${props => props.theme.FontSizeBase};
        padding: 1rem 2rem;
        margin-bottom: 0;
    }
`;

const groupDocumentsByModel = (documents, filterModel) => {
    const groupedDocuments = {};
    documents.forEach(document => {
      if (!document.ContactID || document.ContactID === filterModel) {
        return;
      }
      if (groupedDocuments[document.ContactID]) {
        groupedDocuments[document.ContactID].push(document);
        return;
      }
      groupedDocuments[document.ContactID] = [document];
    })
    return groupedDocuments;
}

const ProjectDocuments = ({ projectId, modelId, readOnly, fluid, mini }) => {  //currentProject,
    
    const { data: currentProject } = useProject(projectId);
    const [{areaContext}] = useStateValue();
    const [documentId, setDocumentId] = useState(null);
    const [isDocumentDrawer, setIsDocumentDrawer] = useState(false);
    const [documents, setDocuments ] = useState([]);    
    const [documentsCount, setDocumentsCount] = useState(0);
    const [events, setEvents] = useState([]);
    
    const [touchForm, setTouchForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isNewDocumentUploaded, setIsNewDocumentUploaded] = useState(false);

    //let documentsCount = currentProject ? currentProject.Documents.length : 0;

    useEffect(() => {
        if (currentProject) {
            setDocuments(currentProject.Documents);
            const projectEvents = onGetOrderedEventList(currentProject, areaContext, false, false)
            setEvents([...projectEvents]);
        }
    }, [currentProject])

   useEffect(() => {
        setDocumentsCount(documents.length)
   }, [documents])


    useEffect(() => {
        if (isNewDocumentUploaded) {
            setDocumentId(documents[0].ID);
            setIsDocumentDrawer(true);
            setIsNewDocumentUploaded(false);
        }
    }, [documentsCount]);
 
    const RenderUploadButton = () => (
        <NwUpload
            maxSize={20}
            showList={false}
            types={
                [
                    "document",
                    "image",
                    "data-document",
                    "office-document",
                    "archive-document",
                    "text-document",
                    "video"
                ]
            }
            onUploadStart={() => {}}
            onUploadEnd={() => {
                forceReloadProject(projectId);
                setIsNewDocumentUploaded(true);
            }}
            endpoint={`documents/uploadsingle/1?projectID=${projectId}`}
        />
    );

    const handleShowDocumentDrawer = document => () => {
        setDocumentId(document.ID);
        setIsDocumentDrawer(true);
    };

    const onOpenDocument = document => {
        window.open(getUrlDocument(document), "_blank");
    };

    const onDeleteDocument = document => {
        Axios.delete(`/documents/?ids=${document.ID}`)
        .then(() => {
            forceReloadProject(projectId);
        })
        .catch(error => {
            console.log("Errore", error);
        });
    };

    const onCloseDrawer = () => {
        setTouchForm(false);
        setDocumentId();
        setIsDocumentDrawer(false);
    };

    //const documents = currentProject?[...currentProject.Documents]:[];
    const generalDocuments = documents.filter(d => d.ContactID === null)
    const selectedModelDocuments = documents.filter(d => d.ContactID === modelId)
    const groupedDocuments = groupDocumentsByModel(documents, modelId)

    const document = documents.find(item => item.ID === documentId);


    const getDescription = (document) => {
        return <CreatedBy object={document} short textonly />;
    };

    const onEditDocument = document => {
        setDocumentId(document.ID);
        setIsDocumentDrawer(true);
    };

    const onEditAttachment = async () => {
        onCloseDrawer();
        setLoading(true);
        await forceReloadProject(projectId);
        setLoading(false);
    };

    const actions = (document) => {
        return (
            <NwDropDownMenu
            actions={[
                { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => onEditDocument(document)) },
                { name: "", key: "download", pinned: true, icon: light("cloud-download"), action: (() => onOpenDocument(document)) },
                { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => onDeleteDocument(document)) }
            ]}
            />
        );
    }
    
    const renderMini = () => {
        return (
            <MiniContent>
                {generalDocuments.length ? <div className="model-name">General documents</div>:(null)}
                {generalDocuments.map((document, index) => {
                    return (
                        <div key={index}>
                            <div className="document-row" onClick={() => onOpenDocument(document)}>
                                <div className="document-image">
                                    <NwIcon icon={getFileTypeIcons(document.Extension)} />
                                </div>
                                <div className="document-title">{document.Title}</div>
                            </div>
                        </div>
                    )
                })}
                {Object.keys(groupedDocuments).map((modelId, index) => {
                    return (
                        <div key={index}>
                            <div className="model-name">{getContactName(+modelId)}</div>
                            {groupedDocuments[modelId].map((document, index) => {
                                return (
                                    <div key={index}>
                                        <div className="document-row" onClick={() => onOpenDocument(document)}>
                                            <div className="document-image">
                                                <NwIcon icon={getFileTypeIcons(document.Extension)} />
                                            </div>
                                            <div className="document-title">{document.Title}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </MiniContent>
        )
    }

    return (
            mini
            ?
                renderMini()
            :
                <Wrapper>
                    <NwCard 
                        extra={!readOnly && RenderUploadButton()}
                        title='Documents'
                        padding='no-padding'
                        // minheight={!fluid && 400} 
                    >
                        {loading ? <Loading textBlack />
                            :
                            <>
                                {documents.length > 0 ?
                                    // fluid ? renderDocuments(documents)
                                    // : 
                                    <FlexContainer
                                        fullWidth
                                        alignItems='flex-start'
                                        column>
                                        {modelId && selectedModelDocuments.length > 0 && (
                                            <DocumentBox>
                                                <h3 className="header">{getContactName(modelId)}</h3>
                                                <div>
                                                {
                                                    selectedModelDocuments.map(document => (
                                                        <GridCard key={`document-${document.ID}`} fluid={fluid}>
                                                            <Document
                                                                disablehover='true'
                                                                document={document}
                                                                handleShowDocumentDrawer={handleShowDocumentDrawer}
                                                                onEditDocument={onEditDocument}
                                                                onOpenDocument={onOpenDocument}
                                                                getDescription={getDescription}
                                                                showTag
                                                                actions={actions}
                                                                events={events}
                                                            />
                                                        </GridCard>
                                                    ))
                                                }
                                                </div>
                                            </DocumentBox>
                                            )
                                        }
                                        <DocumentBox>
                                            <h3 className="header">General documents</h3>
                                            <div>
                                                {generalDocuments.map(document => (
                                                    <GridCard key={`document-${document.ID}`} fluid={fluid}>
                                                        <Document
                                                            disablehover='true'
                                                            document={document}
                                                            handleShowDocumentDrawer={handleShowDocumentDrawer}
                                                            onEditDocument={onEditDocument}
                                                            onOpenDocument={onOpenDocument}
                                                            getDescription={getDescription}
                                                            showTag
                                                            actions={actions}
                                                            events={events}
                                                        />
                                                    </GridCard>
                                                    ))
                                                }
                                            </div>
                                        </DocumentBox>
                                        {!modelId && Object.keys(groupedDocuments).map((modelId, index) => (
                                            <DocumentBox key={index}>
                                                <h3 className="header">{getContactName(+modelId)}</h3>
                                                <div>
                                                    {groupedDocuments[modelId].map(document => (
                                                        <GridCard key={`document-${document.ID}`} fluid={fluid}>
                                                            <Document
                                                                disablehover='true'
                                                                document={document}
                                                                handleShowDocumentDrawer={handleShowDocumentDrawer}
                                                                onEditDocument={onEditDocument}
                                                                onOpenDocument={onOpenDocument}
                                                                getDescription={getDescription}
                                                                showTag
                                                                actions={actions}
                                                                events={events}
                                                            />
                                                        </GridCard>
                                                        ))
                                                    }
                                                </div>
                                            </DocumentBox>
                                            ))
                                        }
                                    </FlexContainer>
                                    : <EmptyState message="No documents" /> //extra={!readOnly && RenderUploadButton()}
                                }
                            </>
                        }
                    </NwCard>
                    {isDocumentDrawer && document && 
                        <NwDrawer
                            touchForm={touchForm}
                            title="Document Details"
                            onClose={onCloseDrawer}
                        >
                            <AttachmentDetailsForm
                                attachment={document}
                                project={currentProject}
                                events={events}
                                onSave={onEditAttachment}
                                onClose={onCloseDrawer}
                                onTouchForm={setTouchForm}
                            />
                    </NwDrawer>
                    }
                </Wrapper>
    );
};

export default ProjectDocuments;