import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { light, solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import { getCustomers } from 'Hooks/Contact/UseContactsList';
import { showDateNoTime } from 'Libs/NwMoment';
import { readUserLogs } from 'Libs/NwLogs';
// import { useStateValue } from 'Contexts/StateProvider';
import NwIcon from 'Components/Gui/NwIcon';
import { NwButton, NwLinkButton } from 'Components/Gui/NwButton';
// import Paragraph from 'antd/lib/typography/Paragraph';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import ProjectTitle from 'Components/Project/ProjectTitle';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    .projects-list {
        flex: ${props => props.searchOnly ? '100%' : '60%'};
        padding: 0 1em 1em;

        .ant-list-empty-text {
            padding: 0;
            
            .ant-empty {
                margin: 0;
            }
        }

        .ant-list-item {
            .project-list-item {
                cursor: pointer;
                width: 100%;
                display: flex;
                justifyContent: flex-start;
                alignItems: flex-start;

                .project-list-icon {
                    font-size: 2rem;
                    margin: 0 1rem;
                    line-height: 1em;
                }
                .project-list-details {
                    flex: 1 1 0;
                    padding-right: 1rem;
                    .project-clientname {
                        text-transform: uppercase;
                        font-size: .85rem;
                        line-height: 1em;
                    }
                    .project-name {
                        font-weight: bold;
                    }
                }
                .project-list-description {
                    width: 40%;
                    .project-description {
                        font-size: .75rem;
                        color: ${(props) => (props.theme.TextColorMuted)};
                        font-style: italic;
                    }
                    .project-period {
                        text-transform: uppercase;
                        font-size: .75rem;
                    }
                }
            }
        }
    }

    .clients-list {
        flex: ${props => props.searchOnly ? '0%' : '40%'};
        padding: 0 1em 1em;
        display: ${props => props.searchOnly && 'none'};

        .ant-list-item {
            cursor: pointer;
            .clients-list-icon {
                font-size: 2em;
                margin: 0 1em;
            }
            .client-name {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        
    }

    .new-client {
        flex: ${props => props.searchOnly ? '0%' : '40%'};
        padding: 0 1em 1em;
        display: ${props => props.searchOnly && 'none'};

        .ant-list-item {
            cursor: pointer;
            .new-client-icon {
                font-size: 2em;
                margin: 0 1em;
            }
            .new-client-text {
                font-weight: bold;
            }
        }
        
    }
`;

const ProjectsCustomers = ({ projects, term, searchOnly, searchMode, onSelectProject, onSelectCustomer, onAddCustomer }) => {
    const customers = getCustomers();
    // const [{ areaContext }] = useStateValue();
    const userLogs = readUserLogs();
    const [defaultProjects, setDefaultProjects] = useState([]);
    const [showAllProjects, setShowAllProjects] = useState(false);
    const [showAllCustomers, setShowAllCustomers] = useState(false);
    //const [defaultCustomers, setDefaultCustomers] = useState([]);

    useEffect(() => {
        if (showAllProjects) {
            setShowAllProjects(false);
        }
        if (showAllCustomers) {
            setShowAllCustomers(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (searchMode) {
            const projects = userLogs.filter(log => log.t === 'project').reverse().map(project => {
                const names = project.name.split('|');
                return {
                    ID: project.id,
                    Name: names[0],
                    Contact: { Name: names[1] },
                    Product: (names.length > 2) ? names[2] : "",
                    ProjectCategoryID: (names.length > 3) ? Number(names[3]) : null,
                }
            });
            setDefaultProjects(projects);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (defaultProjects.length > 0) {
            setDefaultProjects([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    const filteredCustomers = useMemo(() => {
        return term.length >= 3 ? customers.filter(customer => customer.N.toLowerCase().includes(term.toLowerCase())) : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    const getTextLine = (line) => {
        switch (line) {
            case "selectproject":
                if (searchMode) {
                    return ("select an existing project")
                } else {
                    return ("or select an existing project")
                }
            case "createproject":
                if (searchMode) {
                    return ("or create a new project for")
                } else {
                    return ("create a new project for")
                }
            default:
        }
    }

    const ProjectList = () => {
        if (defaultProjects.length > 0 || projects.length > 0) {
            return (
                <div className='projects-list'>
                    <NwDivider textSize="big" bold dark>
                        {getTextLine("selectproject")}
                    </NwDivider>
                    <List
                        itemLayout="horizontal"
                        dataSource={defaultProjects.length ? (showAllProjects ? defaultProjects : [...defaultProjects].splice(0, 4)) :
                            (showAllProjects ? projects : [...projects].splice(0, 4))}
                        renderItem={project => {
                            return (
                                <List.Item
                                    className='ant-list-item-top'
                                    onClick={() => onSelectProject(project.ID)}
                                >
                                    <ProjectTitle small project={project} pointer noProjectText />
                                </List.Item>
                            )
                        }
                        } />
                    {!showAllProjects && (defaultProjects.length > 4 || projects.length > 4) &&
                        <FlexContainer justifyContent='center'>
                            <NwLinkButton onClick={() => setShowAllProjects(true)} label='Load More' />
                        </FlexContainer>
                    }
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const ClientsList = () => {
        if (filteredCustomers.length > 0) {
            return (<div className='clients-list'>
                <NwDivider textSize="big" bold dark>{getTextLine("createproject")}</NwDivider>
                <List
                    itemLayout="horizontal"
                    //dataSource={defaultCustomers.length > 0 ? defaultCustomers : filteredCustomers}
                    dataSource={showAllCustomers ? filteredCustomers : [...filteredCustomers].splice(0, 4)}
                    renderItem={customer => {
                        return (
                            <List.Item className="ant-list-item-top" onClick={() => { onSelectCustomer(customer) }}>
                                <FlexContainer>
                                    <div className="clients-list-icon">
                                        <NwIcon icon={light("address-card")} />
                                    </div>
                                    <FlexContainer column alignItems='flex-start'>
                                        <div className='client-name'>{customer.N}</div>
                                    </FlexContainer>
                                </FlexContainer>
                            </List.Item>
                        )
                    }
                    } />
                {!showAllCustomers && filteredCustomers.length > 4 &&
                    <FlexContainer justifyContent='center'>
                        <NwButton onClick={() => setShowAllCustomers(true)} label='Load More' />
                    </FlexContainer>
                }
            </div>)
        } else {
            return (<></>)
        }
    }

    const NewClient = () => {
        if (term) {
            return (<div className='new-client'>
                <NwDivider textSize="big" bold dark>or</NwDivider>
                <List itemLayout="horizontal">
                    <List.Item className="ant-list-item-top" onClick={() => onAddCustomer(term)}>
                        <FlexContainer>
                            <div className="new-client-icon">
                                <NwIcon icon={solid('plus-circle')} />
                            </div>
                            <FlexContainer column alignItems='flex-start'>
                                <div className='new-client-text'>create a new "{term}" client</div>
                            </FlexContainer>
                        </FlexContainer>
                    </List.Item>
                </List>
            </div>)
        } else {
            return (<></>)
        }
    }

    return (
        <Container searchOnly={searchOnly}>
            {searchMode
                ?
                <>
                    <ProjectList />
                    <ClientsList />
                    <NewClient />
                </>
                :
                <>
                    <ClientsList />
                    <NewClient />
                    <ProjectList />
                </>
            }
        </Container>
    );
};

export default ProjectsCustomers;