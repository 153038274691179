import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { List, Switch } from 'antd';
import BookerSelector from 'Components/BookerSelector/BookerSelector';
import { UserContext } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import SelectTemplateSelector from 'Components/TemplateSelector/SelectTemplateSelector';
import {  showLog } from 'Libs/NwMoment';

const ListItem = styled(List.Item)`
    padding-left: 8px !important;
    cursor: pointer;
    &:hover {
        box-shadow: 0 1px 2px rgba(57,73,76,.35);
    }
`;

const DocumentsContainer = styled.div`
    max-height: calc( 100vh - 180px );
    margin-top: 16px;
    border: 0.5px solid ${props => props.theme.LighterBorderColor};
    border-radius: 5px;
    overflow: auto;
`;

const ConfirmedContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

const TemplateSelectorStyle = {
    width: '100%',
    marginBottom: 8
};

const DocsView = ({ documents, selectedTemplate, onSelectDocument, onTemplateChange }) => {
    const {currentUser} = useContext(UserContext);
    const { cachedList } = useContext(ListContext);
    const users = cachedList.users.items;
    const [creator, setCreator] = useState(currentUser.UserID);
    const [confirmed, setConfirmed] = useState(false);

    const handleChangeCreator = value => {
        setCreator(value);
    };

    const handleSelectDocumnet = document => () => {
        onSelectDocument(document);
    };

    const handleChangeConfirm = checked => {
        setConfirmed(checked);
    }

    const sortedDocuments = documents.sort((item1, item2) => {
        return new Date(item1.DateCreated) < new Date(item2.DateCreated) ? 1 : -1;
    });
    
    const documentsToRender = sortedDocuments.filter(document => {
        if (confirmed && !document.Confirmed) {
            return false;
        }
        return document.UserCreated === creator;
    });

    return (
        <>
            {selectedTemplate && 
                <SelectTemplateSelector style={TemplateSelectorStyle} type={selectedTemplate.area} template={selectedTemplate} onTemplateChange={onTemplateChange} />
            }
            <BookerSelector value={creator} style={{width: '100%'}} onChange={handleChangeCreator} />
            <ConfirmedContainer>
                Confirmed
                &nbsp;
                <Switch value={confirmed} onChange={handleChangeConfirm} />
            </ConfirmedContainer>
            <DocumentsContainer>
                <List
                    
                    itemLayout="horizontal"
                    dataSource={documentsToRender}
                    renderItem={document => {
                        const user = users.find(user => user.ID === document.UserCreated);
                        return (
                            <ListItem className="ant-list-item-top" onClick={handleSelectDocumnet(document)}>
                                <List.Item.Meta
                                    title={`${user.Name} ${user.Surname}`}
                                    description={showLog(document.DateCreated)}
                                />
                            </ListItem>
                        )}
                    }
                />
            </DocumentsContainer>
        </>
    );
};

export default DocsView;