import React, { useState } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FeaturesDrawer from "Components/Features/FeaturesDrawer";
import PermissionBlock from "Components/PermissionBlock";
import { useUserPermission } from 'Contexts/UserContext';
import { useContact } from 'Hooks/Contact/UseContact';

const FullFeatures = ({ contactID, iconSize }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { data: currentContact } = useContact(contactID);
  const viewModelFeatures = useUserPermission('ViewModelFeatures', currentContact && currentContact.Users.map(user => user.UserID));

  return (
    <React.Fragment>
      <PermissionBlock
        permission={viewModelFeatures}
        content={
            <NwIcon icon={light('weight-scale')} size={iconSize} tooltip="See and edit all measurements, sizes and skills" onClick={() => viewModelFeatures && setShowDrawer(true)} />
        } />
      {showDrawer && (
        <FeaturesDrawer contactID={contactID} onClose={() => setShowDrawer(false)} />
      )}
    </React.Fragment>
  );
};

export default FullFeatures;