import React, { useState, useMemo, useEffect, useContext } from 'react';
import { withRouter } from "react-router"
import { Badge, Select } from 'antd';
import ls from 'local-storage';
import _ from "lodash";
import { getMoment } from "Libs/NwMoment";
import { useQueryClient } from '@tanstack/react-query';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useStateValue } from 'Contexts/StateProvider';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { UserContext } from 'Contexts/UserContext';
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import ModelFilterForm from "Components/Filters/FilterForm/ModelFilterForm/ModelFilterForm";
import { NwButton, NwEditButton, NwRemoveButton, NwAddButton } from 'Components/Gui/NwButton';
import { formatModelsFilterValues } from 'Components/Filters/FilterForm/FilterUtils';
import NwDrawer from 'Components/Gui/NwDrawer';
import CreateEditForm from 'Components/FolderAddEditDrawer/CreateEditForm';
import FolderAddEditDrawer from 'Components/FolderAddEditDrawer/FolderAddEditDrawer';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { useProjectModels } from 'Hooks/Project/UseProjectModels';

import { refetchModelWallIds } from "Hooks/Contact/UseContactsList";
import FlexContainer from 'Components/Gui/FlexContainer';

import { StyledTabs, StyledSelect, FiltersWrapper } from "Components/Filters/FilterSidebarStyle";
import NwIcon from 'Components/Gui/NwIcon';

const { OptGroup, Option } = Select;

const ModelsFilter = ({
    filters,
    filtersType,
    history,
    inSideBar,
    isDrawer,
    layouttype,
    leftMargin,
    navigateUrl,
    onEditFolder,
    onListUpdated,
    onSetFilters,
    onSetLoading,
    onSetQuickFilter,
    onSetSearchType,
    projectId,
    projectModels,
    quickFilter,
    searchType,
    setFiltersType,
    startOnProjectModels,
    tags,
}) => {

    const breakpoints = useNwBreakPoints();
    const isCompact = breakpoints < 4;
    //const history = useHistory();

    const [{ modelFiltersContext, folderContext, areaContext }, dispatch] = useStateValue();

    const replacePlaceHolder = useDataPlaceholder();
    const [selectedTitle, setTitle] = useState();
    const { currentUser } = useContext(UserContext);
    const [isModifiedTitle, setIsModifiedTitle] = useState(false);

    const { activeSet, filterType } = modelFiltersContext[areaContext.area];
    const { customSets, staticSets } = modelFiltersContext[areaContext.area];

    const workingSet = formatModelsFilterValues([...customSets, ...staticSets], currentUser);
    const [isSetDefault, setIsDefault] = useState(false);

    //const { data: projectModels } = useProjectModels(projectId);

    const queryClient = useQueryClient();
    const [isRenameFolder, setIsRenameFolder] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const [addNewFolder, setAddNewFolder] = useState(false);

    // const filteredProjects = projectsModels.filter(projectModels => {
    //     return projectModels.VisitTime > (Date.now() - 3600000)
    // });

    // useEffect(() => {
    //     if (!projectId && filtersType === 'project') {
    //         setFiltersType('all-models');
    //         onSelectSet('v0');
    //     }
    // }, [projectId, filtersType]);

    // useEffect(() => {
    //     if (projectModels && !isSetDefault) {
    //         handleCurrentProject()
    //         setFiltersType('project')
    //         setIsDefault(true)
    //     }
    // }, [projectModels]);

    const filterParameters = useMemo(() => {
        //get the current active set filters
        const currentview = workingSet.find(item => item.id === activeSet);
        let currentviewfilters = [];
        if (currentview) {
            currentviewfilters = currentview.filters
        }
        if (currentviewfilters.length) {
            let configToSend = {};
            //Create the parameter object to send
            for (const filter of currentviewfilters) {
                if (filter.name.includes('custom_fields')) {
                    let fieldName = filter.name;
                    fieldName = fieldName.replace('custom_fields_', '');
                    let value = filter.value;
                    if (!(value instanceof Array)) {
                        //convert value to a single value array
                        value = [value];
                    }
                    configToSend = {
                        ...configToSend,
                        customFields: {
                            ...configToSend.customFields,
                            [fieldName]: value
                        }
                    }
                }
                else {
                    configToSend = {
                        ...configToSend,
                        [filter.name]: replacePlaceHolder(filter.value)
                    }
                }
            }
            return configToSend
        }
        return null;
    }, [activeSet, customSets, searchType]);

    const getFilteredIds = async () => {
        if (filterType === "FOLDER") {
            onSetFilters(null);
            onSetLoading(false);
            const currentFolder = folderContext.openFolders.find(folder => folder.ID === activeSet);
            manuallyUpdateWall(currentFolder ? currentFolder.items.map(item => item.ID) : null)
            return
        }
        // if (filterType === "PROJECT") {
        //     onSetFilters(null);
        //     manuallyUpdateWall(projectModels ? projectModels.map(model => model.ModelID) : null)
        //     onSetLoading(false);
        //     return
        // }
        let currentfilters = { ...filterParameters };
        if (currentfilters) {
            if (!_.isEqual(currentfilters, filters)) {
                onSetFilters(currentfilters);
                await refetchModelWallIds(currentfilters, currentUser.ActiveContext.activeDepartments)
                onListUpdated(true);
                onSetLoading(false);
            } else {
                onSetLoading(false);
                onListUpdated(false);
            }
        } else {
            onSetFilters(null);
            manuallyUpdateWall(null)
            //queryClient.setQueryData(getWallQueryDataName(), null);
            onListUpdated(true);
            onSetLoading(false);
        }
    };

    //     useEffect(() => {
    //         if (drawerMode) {
    //             if (projectId) {
    //                 handleCurrentProjectFilter();
    //             } else {
    //                 dispatch({
    //                     type: drawerMode ? 'ModelFiltersDrawerChangeActiveSet' : 'ModelFiltersChangeActiveSet',
    //                     area: areaContext.area,
    //                     newActiveSet: 'last-seen',
    //                     filterType: "SAVED_SEARCH"
    //                 });
    //             }
    //         }
    //    }, [drawerMode, projectId]);

    useEffect(() => {
        if (activeSet === "v0") {
            if (!searchType) {
                reloadAllModels();
                // return;
            }
        }
        // if (filterType === 'PROJECT') {
        //     if (!projectId) {
        //         dispatch({
        //             type: drawerMode ? 'ModelFiltersDrawerChangeActiveSet' : 'ModelFiltersChangeActiveSet',
        //             area: areaContext.area,
        //             newActiveSet: 'v0',
        //             filterType: "SAVED_SEARCH"
        //         });
        //     } else {
        //         onSetLoading(true);
        //         getFilteredIds();
        //     }
        // } else {
        onSetLoading(true);
        getFilteredIds();
        //}
    }, [activeSet, projectId, filterType]);

    const options = useMemo(() => {
        return workingSet.map(filter => {
            return { value: filter.id, label: filter.name };
        });
    }, [workingSet]);

    const active = workingSet.find(el => el.id === activeSet);

    const customOptions = useMemo(() => {
        return customSets.filter(filter => filter.id !== "advanced").map(filter => {
            return { value: filter.id, label: filter.name };
        });
    }, [customSets]);

    const staticOptions = useMemo(() => {
        if (layouttype === "left") {
            return staticSets.filter(staticSet => staticSet.id !== 'v4').map(filter => {
                return { value: filter.id, label: filter.name };
            });
        } else {
            return staticSets.map(filter => {
                return { value: filter.id, label: filter.name };
            });
        }
    }, [staticSets]);

    const manuallyUpdateWall = (list) => {
        queryClient.setQueryData([areaContext.area], list); //`${areaContext.area}${drawerMode ? '-drawer' : ''}`
    }

    const onSelectSet = value => {
        setIsModifiedTitle(false)
        ls.set(`nw_config_${areaContext.area}_modelswall_listScrollPosition`, 0);

        dispatch({
            type: 'ModelFiltersChangeActiveSet',
            area: areaContext.area,
            newActiveSet: value,
            filterType: "SAVED_SEARCH"
        });
        setTitle();
    };

    const selectedoption = useMemo(() => {
        if (filterType === "FOLDER") {
            const currentFolder = folderContext.openFolders.filter(folder => folder.ID === activeSet);
            return currentFolder.length ? currentFolder : [{ title: '' }]
        }
        // if (filterType === 'PROJECT') {
        //     return [{ title: "models in current project"}]
        // }
        return options.filter(option => {
            return option.value === activeSet;
        });
    }, [options, activeSet, filterType]);

    const selectModelsFolder = (models, value) => {
        onEditFolder(false);
        manuallyUpdateWall(models)
        dispatch({
            type: 'ModelFiltersChangeActiveSet',
            area: areaContext.area,
            newActiveSet: value,
            filterType: "FOLDER"
        });
    };

    // const handleCurrentProjectFilter = () => {
    //     manuallyUpdateWall(projectModels)
    //     dispatch({
    //         type: drawerMode ? 'ModelFiltersDrawerChangeActiveSet' : 'ModelFiltersChangeActiveSet',
    //         area: areaContext.area,
    //         newActiveSet: projectId,
    //         filterType: "PROJECT"
    //     });
    //     onListUpdated(true);
    // }

    // const handleCurrentProject = () => {
    //     manuallyUpdateWall(projectModels.map(model => model.ModelID))
    //     dispatch({
    //         type: drawerMode ? 'ModelFiltersDrawerChangeActiveSet' : 'ModelFiltersChangeActiveSet',
    //         area: areaContext.area,
    //         newActiveSet: projectId,
    //         filterType: "PROJECT"
    //     });
    //     onListUpdated(true);
    // };

    const handleChangeFilters = async filters => {
        setIsModifiedTitle(true)
        if (filters.length > 0) {
            let configToSend = {};
            for (const filter of filters) {
                if (filter.name === 'Features') {
                    const values = filter.value.filter(featureItem => {
                        if ((featureItem.FeatType === 'Text' || featureItem.FeatType === 'BigText')
                            && !featureItem.TextVal) {
                            return false;
                        }
                        if ((featureItem.FeatType === 'Choice' || featureItem.FeatType === 'Multichoice')
                            && featureItem.Choices.length === 0) {
                            return false;
                        }
                        if (featureItem.FeatType === 'Rating' && !featureItem.MinVal) {
                            return false;
                        }
                        return true;
                    });
                    if (values.length) {
                        configToSend = {
                            ...configToSend,
                            [filter.name]: replacePlaceHolder(values)
                        }
                    }
                } else if (filter.name.includes('custom_fields')) {
                    let fieldName = filter.name;
                    fieldName = fieldName.replace('custom_fields_', '');
                    let value = filter.value;
                    if (!(value instanceof Array)) {
                        value = [value];
                    }
                    configToSend = {
                        ...configToSend,
                        customFields: {
                            ...configToSend.customFields,
                            [fieldName]: value
                        }
                    }
                } else {
                    configToSend = {
                        ...configToSend,
                        [filter.name]: replacePlaceHolder(filter.value)
                    }
                }
            }
            onSetFilters(configToSend);
            onSetLoading(true);
            await refetchModelWallIds(configToSend, currentUser.ActiveContext.activeDepartments)
            onListUpdated(true);
            onSetLoading(false);
        } else {
            onSetFilters(filters);
            manuallyUpdateWall(null)
            onSetLoading(false);
        }
    };

    const onSaveFilter = (setID) => {
        onSelectSet(setID);
        setIsModifiedTitle(false)
    }

    const getSelectedTitle = () => {
        if (filtersType === 'smart-lists' || filtersType === 'folders') {
            if (activeSet === 'v0' || activeSet === 'last-seen') {
                return undefined;
            }
        }

        const title = selectedTitle || (selectedoption[0] && (selectedoption[0].label || selectedoption[0].title))
        if (isModifiedTitle)
            return (`[EDIT]  ${title}`);
        return title;
    };

    const getFolderValue = () => {
        if (activeSet === 'v0') {
            return undefined;
        }
        if (filtersType === 'folders' && filterType !== 'FOLDER') return undefined;
        return activeSet;
    };

    const reloadAllModels = async () => {
        onSetLoading(true);
        try {
            await refetchModelWallIds({}, currentUser.ActiveContext.activeDepartments);
        } catch (e) {

        } finally {
            onSetLoading(false);
        }
    }

    const handleChangeTab = async (tab) => {
        setFiltersType(tab);
        onEditFolder(false);

        if (tab === 'all-models') {
            onSelectSet('v0');
            if (!searchType) {
                reloadAllModels();
            }
        } else if (tab === 'smart-lists') {
            onSelectSet(staticOptions.filter(option => option.value !== 'v0')[0].value);
            // } else if (tab === 'project') {
            //     handleCurrentProject();
        } else {
            const modelFolders = folderContext.openFolders.filter(folder => folder.type === 'model');
            if (modelFolders.length) {
                manuallyUpdateWall(modelFolders[0].items)
                //queryClient.setQueryData(getWallQueryDataName(), modelFolders[0].items);
                dispatch({
                    //type: drawerMode ? 'ModelFiltersDrawerChangeActiveSet' : 'ModelFiltersChangeActiveSet',
                    type: 'ModelFiltersChangeActiveSet',
                    area: areaContext.area,
                    newActiveSet: modelFolders[0].ID,
                    filterType: "FOLDER"
                });
            } else {
                onSelectSet('v0');
            }
        }
    }
    let filterOptions = []
    if (startOnProjectModels && projectModels.length > 0) {
        filterOptions.push({ label: isCompact ? 'prj' : 'project', value: 'project' })
    }
    filterOptions.push({ label: isCompact ? 'all' : 'all models', value: 'all-models' })
    filterOptions.push({ label: isCompact ? 'lists' : 'search', value: 'smart-lists' })
    var folderstab = { label: 'folders', value: 'folders' }
    if (!folderContext.openFolders || folderContext.openFolders.length === 0) {
        if (layouttype !== 'fullpage') {
            folderstab.disabled = true
        }
    }
    filterOptions.push(folderstab)

    // if (projectId) {
    //     filterOptions.push({
    //         label: 'current project', value: 'project'
    //     });
    // }

    const handleRenameFolder = () => {
        setIsRenameFolder(true);
    };

    const handleCloseDrawer = () => {
        setIsRenameFolder(false);
    };

    const handleAddNewFolder = () => {
        setAddNewFolder(true);
    };

    const handleCloseNewFolder = () => {
        setAddNewFolder(false);
    };

    const handleRemoveFolder = async () => {
        dispatch({
            type: 'removeFolder',
            folderID: activeSet
        });

        onSelectSet('v0');
        setFiltersType('all-models');
    };

    const renderFilterForm = () => {
        return (
            <ModelFilterForm
                reloadAllModels={reloadAllModels}
                tags={tags}
                searchType={searchType}
                onSetSearchType={onSetSearchType}
                quickFilter={quickFilter}
                onSetQuickFilter={onSetQuickFilter}
                view={layouttype === 'fullpage-compact' ? 'compact' : 'full'}
                navigation={navigateUrl ? true : false}
                noActions={layouttype !== 'fullpage' && layouttype}
                itemSet={active}
                leftMargin={leftMargin}
                onSelectSet={onSelectSet}
                filtersType={filtersType}
                setFiltersType={setFiltersType}
                onChangeFilters={handleChangeFilters}
                onSave={(setID) => onSaveFilter(setID)}
                onCustomizingList={(isCustomized) => setIsModifiedTitle(isCustomized)}
                isDrawer={isDrawer}
            />
        )
    }

    const backToList = () => {
        history.push(`/models/`);
    }

    const folder = filtersType === 'folders' && folderContext.openFolders.find(folder => folder.ID === activeSet);

    return (
        <FiltersWrapper
            $breakpoints={breakpoints}
            $layouttype={layouttype}
            column={layouttype !== 'fullpage-compact'}
            alignItems={'flex-start'}>
            <StyledTabs
                size='small'
                activeKey={filtersType}
                onChange={handleChangeTab}
                items={
                    filterOptions.map(tab => (
                        {
                            label: tab.label,
                            key: tab.value,
                            disabled: tab.disabled,
                            children: (
                                <FlexContainer
                                    wrap='wrap'
                                    column={layouttype !== 'fullpage-compact'}
                                    alignItems={layouttype !== 'fullpage-compact' ? 'flex-start' : 'align-items'}>
                                    {filtersType === 'smart-lists' &&
                                        <FlexContainer fullWidth wrap='wrap' mg={isCompact && '0 0 8px'}>
                                            <StyledSelect
                                                autoComplete='off'
                                                $layouttype={layouttype}
                                                $breakpoints={breakpoints}
                                                showSearch={true}
                                                placeholder={'Please select a smart list'}
                                                dropdownStyle={{ minWidth: isCompact ? "250px" : '0' }}
                                                name="models_walls_views"
                                                value={getSelectedTitle()}
                                            >
                                                {/* {project &&
                                                <OptGroup label={"Current Project"}>
                                                    <Option key={`current-project-${projectId}`} value={`current-${projectId}`}>
                                                        <div onClick={() => handleCurrentProject(project)}>
                                                            {project.Name}
                                                        </div>
                                                    </Option>
                                                </OptGroup>
                                            } */}
                                                {customOptions.length > 0 &&
                                                    <OptGroup label={"Custom Smart Lists"}>
                                                        {customOptions.map(option => (
                                                            <Option key={`custom-search-${option.value}`} value={option.value}>
                                                                <div onClick={() => onSelectSet(option.value)}>
                                                                    {option.label}
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </OptGroup>
                                                }
                                                <OptGroup label={"Standard Smart Lists"}>
                                                    {staticOptions.filter(option => option.value !== 'v0').map(option => (
                                                        <Option key={`origin-search-${option.value}`} value={option.value}>
                                                            <div onClick={() => onSelectSet(option.value)}>
                                                                {option.label}
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </OptGroup>
                                                {/* {filteredProjects.length > 0 &&
                                                <OptGroup label={"Projects"}>
                                                    {filteredProjects.map(option => (
                                                        <Option key={`project-${option.ProjectID}`} value={option.ProjectID}>
                                                            <div onClick={() => handleProjectFilter(option)}>
                                                                {option.Name}
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </OptGroup>
                                            } */}
                                            </StyledSelect>
                                        </FlexContainer>
                                    }
                                    {filtersType === 'folders' &&
                                        (folderContext.openFolders.filter(folder => folder.type === 'model').length > 0
                                            ?
                                            <FlexContainer fullWidth wrap='wrap' mg={isCompact && '0 0 8px'}>
                                                <StyledSelect
                                                    popupClassName="ant-select-dropdown-areaselect"
                                                    autoComplete='off'
                                                    $layouttype={layouttype}
                                                    $breakpoints={breakpoints}
                                                    showSearch={true}
                                                    placeholder={'Please select a folder'}
                                                    dropdownStyle={{ minWidth: isCompact ? "250px" : '0' }}
                                                    value={getFolderValue()}
                                                >
                                                    {folderContext.openFolders.filter(folder => folder.type === 'model').map(fl => (
                                                        <Option key={`folder-${fl.ID}`} value={fl.ID}>
                                                            <FlexContainer onClick={() => selectModelsFolder(fl.items, fl.ID)}>
                                                                <Badge color={fl.color} style={{ marginRight: ".5rem" }} />{fl.title}
                                                            </FlexContainer>
                                                        </Option>
                                                    ))}
                                                </StyledSelect>
                                            </FlexContainer>
                                            :
                                            <p>no folders</p>
                                        )
                                    }
                                    {filtersType === 'folders' && layouttype === 'fullpage' &&
                                        <>
                                            {activeSet !== 'v0' &&
                                                <FlexContainer mg={layouttype === 'fullpage' ? '16px 0 0' : '0 0 0 16px'}>
                                                    <NwEditButton
                                                        size='small'
                                                        ghost
                                                        onClick={handleRenameFolder}
                                                        label="rename"
                                                    />
                                                    <NwDividerVertical />
                                                    <NwButton
                                                        size='small'
                                                        ghost
                                                        icon={light('sort-alt')}
                                                        label='edit list'
                                                        onClick={() => onEditFolder(true)}
                                                    />
                                                    <NwDividerVertical />
                                                    <NwRemoveButton
                                                        size='small'
                                                        ghost
                                                        iconOnly
                                                        onClick={handleRemoveFolder}
                                                    />
                                                </FlexContainer>
                                            }
                                            <br />
                                            <NwAddButton
                                                ghost
                                                size='small'
                                                label='create new folder'
                                                onClick={handleAddNewFolder} />
                                        </>
                                    }
                                    {(layouttype !== 'left' && !inSideBar && filtersType !== "project" && filtersType !== 'folders') &&
                                        renderFilterForm()
                                    }
                                    {filtersType === "project" &&
                                        <p>models in current project</p>
                                    }
                                </FlexContainer>
                            )
                        }
                    ))
                }
            />
            {(layouttype !== 'fullpage' && !isCompact) &&
                <span className="back-to-list">
                    <NwIcon icon={solid('grid')} onClick={backToList} />
                </span>
            }
            {isRenameFolder &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    width={640}
                    fromTop={navigateUrl ? 110 : 60}
                    fromLeft={(isCompact ? 200 : 300) + (leftMargin ? leftMargin : 0)}
                    placement="left"
                    onClose={handleCloseDrawer}
                    visible={true}
                    zIndex={5}
                    title={'Edit Folder'}
                >
                    <CreateEditForm type={'model'} folder={folder} onClose={handleCloseDrawer} onTouchForm={setTouchForm} />
                </NwDrawer>
            }
            {addNewFolder &&
                <FolderAddEditDrawer
                    type='model'
                    onClose={handleCloseNewFolder} />
            }
        </FiltersWrapper>
    );
};

export default withRouter(ModelsFilter);