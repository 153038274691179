import React from 'react';
import { Result } from 'antd';
import NwIcon from './NwIcon';
import styled from 'styled-components';
import { thin } from '@fortawesome/fontawesome-svg-core/import.macro';

const ResultContainer = styled.div`
    .ant-result-icon {
        font-size: 3rem;
    }
`


const ServerError = ({ error }) => {
    return (
        <ResultContainer>
            <Result
                icon={<NwIcon icon={thin('bug')} />}
                title="Server Error"
                subTitle={
                    (error && error.message ?
                        <>
                            <p>There was an error requesting data<br />Please send the following error message to our staff</p>
                            <p className="nw-code-block">{error.message}</p>
                        </>
                        :
                        <p>There was an error requesting data</p>
                    )}
            />
        </ResultContainer >
    );
}

export default ServerError;