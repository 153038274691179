import React, { useState } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { getMoment } from "Libs/NwMoment";
import CheckListCheckBox from './CheckListCheckBox';
import CheckListItemNoteDrawer from './CheckListItemNoteDrawer';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';



const StyledListItem = styled(List.Item)`
    padding: .5rem 0;
    .ant-list-item-meta {
        align-items: center;
        .ant-list-item-meta-avatar {
            padding: .15rem .5rem 0 1.5rem;
        }
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeSm};
                margin-bottom: 0;
            }
            .ant-list-item-meta-description {
                .description-container {
                    font-size: ${props => props.theme.FontSizeSm};
                    padding-right: 1rem;

                    p {
                        margin-bottom: .15rem;
                    }
                    .item-note {
                        font-size: ${props => props.theme.FontSizeSm};
                    }
                }
            }
        }
    }
    .ant-list-item-action {
        margin-left: 0;
        padding: 0;
        font-size: 0;
        list-style: none;

        > li {
            padding: 0 1.5rem 0 1rem;
            //defined in app.less
            //color: ${props => props.theme.TextColor};
            font-size: 1.25rem;
            cursor: pointer;
        }
    }
    
`

const CheckListItem = ({ checkListItem, objectID, objectType, onUpdate, extended }) => {

    const [editNoteDrawer, setEditNoteDrawer] = useState(false);

    const handleEditNote = () => {
        setEditNoteDrawer(true);
    }

    const handleUpdateNote = () => {
        onUpdate()
        setEditNoteDrawer(false)
    }

    const CheckListDescription = () => {
        return (
            <div className="description-container">
                <p>{checkListItem.CheckListItemDescription}</p>
                {checkListItem.Note &&<NwEllipsis className="item-note" popover popovertitle="Note" rows={2} text={`Note: ${checkListItem.Note}`} />}
            </div>
        )
    }

    return (
        <>
            <StyledListItem
                className={extended ? "is-extended" : ""}
                actions={[<NwIcon icon={light("message-pen")} key="edit-note" onClick={handleEditNote} />]}
            >
                <List.Item.Meta
                    avatar={<CheckListCheckBox
                        checkListItem={checkListItem}
                        objectID={objectID}
                        objectType={objectType}
                        onStatusChanged={onUpdate}
                        size={20}
                    />}
                    title={checkListItem.CheckListItemName}
                    description={<CheckListDescription />}
                />
            </StyledListItem>
            {editNoteDrawer && <CheckListItemNoteDrawer objectID={objectID} objectType={objectType} checkListItem={checkListItem} onUpdate={handleUpdateNote} onClose={() => setEditNoteDrawer(false)} />}
        </>
    )
};

export default CheckListItem;

