import React, { useState, useEffect, useMemo, useContext } from 'react';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { List, Row, Col, Typography, Popover } from 'antd';
import Axios from 'axios';

import { Loading } from 'Components/Gui/Loading';
import StyleVariables from 'Components/Gui/StyleVariables';
import AvatarBg from 'Components/Gui/AvatarBg';
import FullFeatures from 'Components/Features/FullFeatures';
import { getBookCover } from 'Libs/NwUtils';
import { getNow, showDateNoTime, showLogNoTime } from 'Libs/NwMoment';
import { ListContext } from 'Contexts/ListContext';
import { getContactName, getModel } from 'Hooks/Contact/UseContactsList';
import PlayListPreviewDrawer from 'Components/MediaDrawer/PlayListPreviewDrawer';

const ListItem = styled(List.Item)`
    padding: 16px 8px !important;
    cursor: pointer;
    width: 100%;
    &:hover {
        box-shadow: 0 1px 2px rgba(57,73,76,.35);
    }
`;

const GroupContainer = styled.div`
    margin-top: 8px;
    border: ${StyleVariables.DefaultBorder};
    border-radius: 4px;
`;

const GroupTitle = styled.div`
    padding: 8px;
    font-weight: bold;
`;

const ModelTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BookListContainer = styled.div`
    display: flex;
`;

const BookItem = styled.div`
    margin-right: 8px;
`;

const LeftContainer = styled(Col)`
    border-right: ${StyleVariables.DefaultBorder};
    border-right-width: 4px;
`;

const EmailsContainer = styled.div`
    margin: 8px 0 16px;
    border: ${StyleVariables.DefaultBorder};
    border-radius: 4px;
    padding: 0 16px;
`;


export const GetPackageGroupedModels = (pack) => {
    let groups = [];
    if (pack) {
        let models = [...pack.Models];
        models.sort((item1, item2) => {
            return item1.Order - item2.Order;
        });
        for (const packageModel of models) {
            groups.push({
                model: getModel(packageModel.ModelID),
                packageModel: packageModel,
                //playlists: pack.Playlists.filter(playlist => playlist.PackageModelID === packageModel.ID)
                playlists: packageModel.Playlists
            });
        }
    }
    let names = [];
    let modelGroupsByRole = [];
    for (const group of groups) {
        const findIndex = modelGroupsByRole.findIndex(modelGroup => modelGroup.groupName === group.packageModel.Group)
        if (findIndex > -1) {
            modelGroupsByRole[findIndex].modelGroups.push(group);
        } else {
            modelGroupsByRole.push({
                groupName: group.packageModel.Group,
                modelGroups: [group]
            });
            names.push(group.packageModel.Group);
        }
    };
    modelGroupsByRole.sort((group1, group2) => group1.groupName > group2.groupName ? 1 : -1);
    const emptyGroupIndex = modelGroupsByRole.findIndex(modelGroup => !modelGroup.groupName);
    if (emptyGroupIndex > -1) {
        const emptyGroup = modelGroupsByRole[emptyGroupIndex];
        modelGroupsByRole.splice(emptyGroupIndex, 1);
        modelGroupsByRole.push(emptyGroup);
    }
    return { groups: groups, groupNames: [...names, 'No groups'], modelGroupsByRole: modelGroupsByRole };
}

export const ModelPlaylistsItem = ({ model }) => {
    const { ID } = model.model;
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [selectedBook, setSelectedBook] = useState();
    
    const handleOpenBook = playlist => () => {
        setIsBookDrawer(true);
        setSelectedBook(playlist);
    };

    const modelBooks = [...model.playlists];
    modelBooks.shift();
    return (
        <ListItem className="ant-list-item-top">
            <List.Item.Meta
                avatar={
                    <Popover content={model.playlists[0].Name}>
                        <div onClick={e => e.preventDefault()}>
                            <AvatarBg src={getBookCover(model.playlists[0])} onClick={handleOpenBook(model.playlists[0])} />
                        </div>
                    </Popover>
                    }
                title={
                    <ModelTitle>
                        {getContactName(ID)}
                        <FullFeatures contactID={model.model.ID} empty={<p>no data</p>} />
                    </ModelTitle>
                }
                description={
                    <div>
                        {model.playlists.length > 1 ? 
                            <BookListContainer>
                                {modelBooks.map(playlist => {
                                    return (
                                        <BookItem key={playlist.ID}>
                                            <Popover content={playlist.Name}>
                                                <div onClick={e => e.preventDefault()}>
                                                    <AvatarBg 
                                                        size={50}
                                                        onClick={handleOpenBook(playlist)}
                                                        src={getBookCover(playlist)} />
                                                </div>
                                            </Popover>
                                        </BookItem>
                                    )
                                })}
                            </BookListContainer>
                            : model.playlists[0].Name
                        }
                    </div>
                } 
            />
            {isBookDrawer && selectedBook && 
                <PlayListPreviewDrawer
                    contactID={model.model.ID}
                    playlist={selectedBook}
                    onClose={() => {
                        setIsBookDrawer(false)
                    }} />
            }
        </ListItem>
    )
};

const PackageCard = ({ packageId }) => {
    const [loading, setLoading] = useState(false);
    const [pack, setPack] = useState();
    const { GetUserName } = useContext(ListContext);

    useEffect(() => {
        const fetchPackage = async () => {
            setLoading(true);
            const pack = await Axios.get(`/packages/${packageId}`);
            setPack(pack);
            setLoading(false);
        };

        fetchPackage();
    }, [packageId]);

    const { modelGroupsByRole } = useMemo(() => GetPackageGroupedModels(pack), [pack]);
    
    return (loading ? <Loading />
        :  <>
            {pack &&
                <>
                    <Row gutter={16}>
                        <LeftContainer span={8}>
                            {getNow() > getMoment(pack.ExpirationDate) ? 
                                `Expired on ${showDateNoTime(pack.ExpirationDate)}` : 
                                `Will expire ${showDateNoTime(pack.ExpirationDate)}`
                            }
                            <div>{`Created by ${GetUserName(pack.UserCreated)} on ${showLogNoTime(pack.DateCreated)}`}</div>
                        
                            {modelGroupsByRole.map((modelsGroup, index) => {
                                let playlists = [];
                                for (const modelGroup of modelsGroup.modelGroups) {
                                    playlists = [...playlists, ...modelGroup.playlists];
                                }

                                return (
                                    <GroupContainer key={`modelgroup-${modelsGroup.groupName}${index}`}>
                                        {(modelGroupsByRole.length !== 1 || modelGroupsByRole[0].groupName) &&
                                            <GroupTitle>
                                                {modelsGroup.groupName ? modelsGroup.groupName : 'No groups'}
                                            </GroupTitle>
                                        }
                                        <List
                                            
                                            itemLayout="horizontal"
                                            dataSource={modelsGroup.modelGroups}
                                            renderItem={model => {
                                                return (
                                                    <ModelPlaylistsItem model={model} />
                                                )
                                            }}
                                        />
                                    </GroupContainer>
                                )
                            })
                        }
                        </LeftContainer>
                        <Col span={16}>
                            {pack.Recipients.length > 0 && 
                                <>
                                    <Typography.Title>Recipients</Typography.Title>
                                    <EmailsContainer>
                                        <List
                                            
                                            itemLayout="horizontal"
                                            dataSource={pack.Recipients}
                                            renderItem={email => {
                                                return (
                                                    <List.Item
                                                        className="ant-list-item-top">
                                                        <List.Item.Meta
                                                            title={email.Email}
                                                        />
                                                    </List.Item>
                                                )}
                                            }
                                        />
                                    </EmailsContainer>
                                </>
                            }
                        </Col>
                    </Row>
                </>
            }    
        </>  
    )
};

export default PackageCard;