import React from 'react'
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { compareAsISODate, isAllDay, showDateNoTime, showTime } from 'Libs/NwMoment';
import VerticalDate from 'Components/Dates/VerticalDate';
import VerticalPeriod from 'Components/Dates/VerticalPeriod';
import { TableColBorder } from 'Components/Gui/StyledComponent';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import _ from 'lodash';

export const EventBoxDateAndTime = ({
    firstDate,
    forceAllDay,
    isGrouped,
    isMultiDay,
    lastDate
}) => {
    const allDay = forceAllDay ? true : isAllDay(firstDate, lastDate);
    const isSameDate = lastDate ? compareAsISODate(firstDate, lastDate) : true;
    const tableColFlex1 = isGrouped ? "8rem" : "6rem";
    
    if (isSameDate) {
        return (
            <>
                <TableColBorder flex={tableColFlex1}>
                    <div className={`event-box-date ${isGrouped && ' align-top'}`}>
                        <VerticalDate date={firstDate} size="small" />
                    </div>
                </TableColBorder>
                {!isGrouped &&
                <TableColBorder flex="8rem">
                    <div className="event-box-time">
                        {allDay
                            ?
                            <div className='time'>All Day</div>
                            :
                            <>
                                <div className='time'><strong>{showTime(firstDate)}</strong></div>
                                <NwIcon icon={light('arrow-down')} />
                                <div className='time'><strong>{showTime(lastDate)}</strong></div>
                            </>
                        }
                    </div>
                </TableColBorder>
                }
            </>
        );
    } else {
        if (allDay || isMultiDay) {
            return (
                <>
                    <TableColBorder flex={tableColFlex1}>
                        <div className={`event-box-date ${isGrouped && ' align-top'}`}>
                            <VerticalPeriod startDate={firstDate} endDate={lastDate} size="small" />
                        </div>
                    </TableColBorder>
                    {!isGrouped &&
                    <TableColBorder flex="8rem">
                        <div className="event-box-time">
                            {allDay
                                ?
                                <div className='time'>All Day</div>
                                :
                                <>
                                    <div className='time'><strong>{showTime(firstDate)}</strong></div>
                                    <NwIcon icon={light('arrow-down')} />
                                    <div className='time'><strong>{showTime(lastDate)}</strong></div>
                                </>
                            }
                        </div>
                    </TableColBorder>
                    }
                </>
            )
        } else {
            return (
                <>
                    <TableColBorder flex={tableColFlex1}>
                        <div className={`event-box-date ${isGrouped && ' align-top'}`}>
                            <VerticalPeriod startDate={firstDate} endDate={lastDate} size="small" />
                        </div>
                    </TableColBorder>
                    {!isGrouped &&
                    <TableColBorder flex="8rem">
                        <div className="event-box-time">
                            <div className='time'>{showDateNoTime(firstDate)} - {showTime(firstDate)}</div>
                            <NwIcon icon={light('arrow-down')} />
                            <div className='time'>{showDateNoTime(lastDate)} - {showTime(lastDate)}</div>
                        </div>
                    </TableColBorder>
                    }
                </>
            )
        }
    }
};

export const getModelsListForBoxes = (event, page) => {
    
    const isModelCancelled = (modelID) => {
        if (event.EventDates) {
            const hasActiveDates = event.EventDates.some((evdate) => evdate.EventDatesModels.some((evdatemodel) => evdatemodel.ModelID === modelID && !evdatemodel.Cancel));
            return !hasActiveDates;
        }
        return false;
    }
    
    const models = []
    
    if (event.EventModels) {
        if (page === 'project') {
            event.EventModels.forEach((model) => {
                const isCancelled = isModelCancelled(model.ModelID)
                models.push({ id: model.ModelID, name: getContactName(model.ModelID), isCancelled: isCancelled })
            })
        } else {
            event.EventModels.forEach((modelID) => {
                const isCancelled = isModelCancelled(modelID)
                models.push({ id: modelID, name: getContactName(modelID), isCancelled: isCancelled })
            })
        }
    }
    if (event.Models) {
        event.Models.forEach((model) => {
            models.push({ id: model.ModelID, name: getContactName(model.ModelID), isCancelled: model.Cancel })
        })
    }
    if (event.ModelIds) {
        event.ModelIds.forEach((modelID) => {
            const isCancelled = isModelCancelled(modelID)
            models.push({ id: modelID, name: getContactName(modelID), isCancelled: isCancelled })
        })
    }
    if (event.ModelID) {
        models.push({ id: event.ModelID, name: getContactName(event.ModelID), isCancelled: event.Cancel })
    }
    const sortedmodels = _.sortBy(models, ['name']);
    return sortedmodels
}