import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Select, Button, Menu, Dropdown } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import { UserContext } from 'Contexts/UserContext';
import { useCustomersList, useModelsList } from 'Hooks/Contact/UseContactsList';
//import { useProjectForContacts } from 'Hooks/Project/UseProject';
import { useProjectModels } from 'Hooks/Project/UseProjectModels';
import { useStateValue } from 'Contexts/StateProvider';
import Axios from 'axios';
// import NWSlider from "Components/Gui/NWSlider";
const { Option, OptGroup } = Select;

/*
Receives as props an array of Objects {ContacType, DisplayName} used to compose the options fo the select by merging corresponding cached lists.
agencies/services/clients works on the same cached list "customers" filtering it by subtype
models/scouting works on the same cached list "models" filtering it by subtype
items in exclusions prop (array of IDs), if present, are removed from the final list.
*/

const Container = styled.div`
    display: flex;

    .ant-btn:hover, .ant-btn:focus {
        border-color: rgba(0,0,0,0.25) !important;
    }

    .ant-btn:hover {
        color: ${StyleVariables.DangerColor} !important;
    }

    .ant-select-selection-item {
        text-transform: capitalize;
    }
`;

const ClearButtonStyle = {
    padding: '0 8px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 4px 4px 0',
    color: 'rgba(0,0,0,0.25)',
    borderLeft: 'none'
};

const OptionButtonStyle = {
    ...ClearButtonStyle,
    padding: '0 6px',
    borderRadius: 0,
};

const StyledSelect = styled(Select)`
    width: 100%;
    .ant-select-selection {
        border-radius: 4px 0 0 4px
    }

    .ant-select-selector {
        border-radius: 6px 0 0 6px !important;
    }

    .ant-select-item-option-content {
        text-transform: capitalize;
    }
`;

const contactTypes = [
    { code: "mod", name: "Models", enabled: false, active: false },
    { code: "sco", name: "Scouting Models", enabled: false, active: false },
    { code: "cli", name: "Clients", enabled: false, active: false },
    { code: "age", name: "Agencies", enabled: false, active: false },
    { code: "srv", name: "Services", enabled: false, active: false }
];

const ContactTypeContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${props => props.active && 'bold'};
    opacity: ${props => !props.active && '0.5'};
`;

const IconContainer = styled.div`
    width: 1rem;
`;

const ContactTypeButtons = ({ activeSources, onChange }) => {
    const enabledSources = activeSources.filter((as) => as.enabled);
    const activeOptions = enabledSources.filter(es => es.active);

    const handleChangeOption = (active, code) => {
        if (activeOptions.length > 1 || active) {
            onChange(active, code);
        }
    };

    return (
        <Dropdown 
            placement="bottomRight" 
            menu={{
                items: (enabledSources && enabledSources.length) 
                    ? 
                        enabledSources.map(es => {
                            return ({
                                    key: es.code,
                                    onClick: () => handleChangeOption(!es.active, es.code),
                                    label: (
                                        <ContactTypeContainer active={es.active}>
                                            <IconContainer>
                                                {es.active &&
                                                    <NwIcon icon={light('check')} />
                                                }
                                            </IconContainer>
                                            <div>{es.name}</div>
                                        </ContactTypeContainer>
                                    )
                            })
                        })
                    : 
                    []
            }}
            trigger={['click']}>
            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <NwButton icon={light('ellipsis')} style={OptionButtonStyle} />
            </div>
        </Dropdown>
    );
}

const ContactSelector = ({ 
    activableTypes,
    activeTypes,
    customPlaceholder,
    disabled,
    exclusions,
    hide,
    hideClearButton,
    isExtendedValue,
    name,
    onBlur,
    onChange,
    projectId,
    topListContacts,
    value,
    ...rest
}) => {

    const { currentUser } = useContext(UserContext);
    const { data: models } = useModelsList();
    const { data: customers } = useCustomersList();
    const { data: ProjectModels } = useProjectModels(projectId ? projectId : null);
    //const { data: ProjectCustomers } = useProjectModels(projectId ? projectId : null);
    const [{ areaContext }] = useStateValue();
    const [missingContact, setMissingContact] = useState();

    const allContactIDs = useMemo(() => {
        const allContacts = [];
        if (models) {
            allContacts.push(...models.map(model => model.ID));
        }
        if (customers) {
            allContacts.push(...customers.map(customer => customer.ID));
        }
        return allContacts;
    }, [models, customers]);

    useEffect(() => {
        const fetchMissingContact = async () => {
            const missingContactData = await Axios.get(`/contacts/simple/${isExtendedValue ? parseInt(value.split('-')[0]) : value}`);
            if (missingContactData) {
                setMissingContact(missingContactData);
            } else {
                setMissingContact();
            }
        }
        
        if (value && !allContactIDs.includes(isExtendedValue ? parseInt(value.split('-')[0]) : value)) {
            fetchMissingContact();
        }
    }, [value, allContactIDs]);


    const mapSources = () => {
        const newContactTypes = JSON.parse(JSON.stringify(contactTypes));
        activeTypes && activeTypes.map((t) => {
            const mycontIndex = contactTypes.findIndex((ct) => ct.code === t)
            if (mycontIndex > -1) {
                newContactTypes[mycontIndex].enabled = true
                newContactTypes[mycontIndex].active = true
            }
            return null;
        })
        activableTypes && activableTypes.map((t) => {
            const mycontIndex = contactTypes.findIndex((ct) => ct.code === t)
            if (mycontIndex > -1) {
                newContactTypes[mycontIndex].enabled = true
                newContactTypes[mycontIndex].active = false
            }
            return null;
        })
        return newContactTypes;
    }
    const [activeSources, setActiveSources] = useState(mapSources());
    const [options, setOptions] = useState([])
    const getCodeItems = code => {
        let dataList = [];
        let label = 'contacts'
        let excludedContacts = exclusions ? [...exclusions] : []
        if (topListContacts && topListContacts.length) {
            const topListContactsIds = topListContacts.map((contact) => contact.ID)
            excludedContacts.push(...topListContactsIds)
            excludedContacts = Array.from(new Set(excludedContacts))
        }
        const activeDepartments = currentUser.ActiveContext.activeDepartments;
        switch (code) {
            case "mod":
                let modelList = models ? [...models] : [];
                if (areaContext.area !== 'accounting' && areaContext.area !== 'management') {
                    modelList = modelList.filter(model => model.DEPS.some(departmentId => activeDepartments.includes(departmentId)));
                }
                dataList = modelList.filter((model) => (model.ST === 4) && !excludedContacts.includes(model.ID)).map((model) => ({ label: `${model.N.toLowerCase()} ${model.S ? model.S.toLowerCase() : ''}`, value: model.ID }))
                label = "models"
                break;
            case "sco":
                let scoutingModelList = models ? [...models] : []
                if (areaContext.area !== 'accounting' && areaContext.area !== 'management') {
                    scoutingModelList = scoutingModelList.filter(model => model.DEPS.some(departmentId => activeDepartments.includes(departmentId)));
                }
                dataList = scoutingModelList.filter((model) => (model.ST === 5) && !excludedContacts.includes(model.ID)).map((model) => ({ label: `${model.N.toLowerCase()} ${model.S ? model.S.toLowerCase() : ''}`, value: model.ID }))
                label = "scouting models"
                break;
            case "cli":
                dataList = customers ? customers.filter((customer) => (customer.ST === 1) && !excludedContacts.includes(customer.ID)).map((customer) => ({ label: customer.N.toLowerCase(), value: customer.ID })) : []
                label = "clients"
                break;
            case "age":
                dataList = customers ? customers.filter((customer) => (customer.ST === 2) && !excludedContacts.includes(customer.ID)).map((customer) => ({ label: customer.N.toLowerCase(), value: customer.ID })) : []
                label = "agencies"
                break;
            case "srv":
                dataList = customers ? customers.filter((customer) => (customer.ST === 3) && !excludedContacts.includes(customer.ID)).map((customer) => ({ label: customer.N.toLowerCase(), value: customer.ID })) : []
                label = "services"
                break;
            default:
                dataList = models ? models.filter((model) => (model.ST === 4)).map((customer) => ({ label: customer.N.toLowerCase(), value: customer.ID })) : []
                label = "models"
                break;
        }
        // if (projectId && currentProject) {
        //     const { ProjectModels } = currentProject;
        //     dataList = dataList.filter(item => ProjectModels.findIndex(model => model.ModelID === item.value) >= 0);
        // }
        if (projectId && ProjectModels) {
            dataList = dataList.filter(item => ProjectModels.findIndex(model => model.ModelID === item.value) >= 0);
        }
        dataList.sort((a, b) => a.label.localeCompare(b.label));
        return {
            label: label,
            options: [...dataList]
        }
    }

    const mapOptions = () => {
        return activeSources.filter((as) => as.active).map((source) => {
            return getCodeItems(source.code.toLowerCase());
        })
    }

    useEffect(() => {
        const topOptions = topListContacts ? {
            label: 'Project contacts',
            options: topListContacts.map((contact) => ({ label: contact.Name.toLowerCase(), value: contact.ID }))
            } : null
        const listOptions = mapOptions();
        const options = []
        if (topOptions) {
            options.push(topOptions)
        }
        options.push(...listOptions)
        //console.log("options", options)
        setOptions(options);
    }, [activeSources, exclusions, models, customers, ProjectModels, topListContacts])

    const updateSources = (contacttype, checked) => {
        const newActiveSources = _.clone(activeSources)
        const todisable = newActiveSources.find((s) => {
            return s.code === contacttype;
        })
        if (todisable) {
            todisable.active = checked
        }
        setActiveSources(newActiveSources)
    }

    const handleChangeContactButtons = (checked, contacttype) => {
        if (!checked && value) {
            const { options } = getCodeItems(contacttype);
            if (options.find(option => option.value === isExtendedValue ? parseInt(value.split('-')[0]) : value)) {
                onChange(undefined);
            }
        }
        updateSources(contacttype, checked)
    };

    const contactPlaceHolderName = contactType => {
        switch (contactType) {
            case "mod":
                return 'model'
            case "sco":
                return 'scouting'
            case "cli":
                return 'client'
            case "age":
                return 'agency'
            case "srv":
                return 'service';
            default:
                return null;
        }
    };

    const contactTypePlaceholder = useMemo(() => {
        let placeholder = null;
        if (activeTypes.length === 1 && (!activableTypes || activableTypes.length === 0)) {
            placeholder = `Select a ${contactPlaceHolderName(activeTypes[0])}`;
        }
        return placeholder;
    }, [activeTypes, activableTypes])

    return (
        <Container {...rest}>
            <StyledSelect
                autoComplete='off'
                name={name}
                showSearch
                value={value}
                defaultValue={value}
                disabled={disabled}
                placeholder={customPlaceholder ? customPlaceholder : (contactTypePlaceholder ? contactTypePlaceholder : "Select a contact")}
                onChange={value => onChange(value)}
                onBlur={onBlur}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                filterOption={(input, option) =>
                    option.value && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {missingContact && 
                    <Option key={missingContact.ID} value={missingContact.ID}>
                        {missingContact.FullName} {missingContact.StorageBooking ? ' (ARCHIVED)' : ''}
                    </Option>
                }
                {options.map((group) => (
                    <OptGroup key={group.label} label={group.label}>
                        {group.options.map((opt) => (
                            <Option key={opt.value} value={isExtendedValue ? `${opt.value}-${group.label}` : opt.value}>{opt.label}</Option>
                        ))}
                    </OptGroup>
                ))}
            </StyledSelect>
            <Button.Group style={{ display: 'flex', borderRadius: 0 }}>
                {!contactTypePlaceholder && !hide &&
                    <ContactTypeButtons activeSources={activeSources} onChange={handleChangeContactButtons} />
                }
                {!hideClearButton &&
                    <NwButton icon={light('times')} style={ClearButtonStyle} onClick={event => onChange(undefined)} />
                }
                
            </Button.Group>
        </Container>
    )
}

export default ContactSelector;
