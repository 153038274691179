import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getExternalJob = externalJobId => (
    Axios.get(`/ExternalJobs/${externalJobId}`)
);

const useExternalJob = externalJobId => {
    return useQuery(['externalJobs', externalJobId], () => getExternalJob(externalJobId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: externalJobId ? true : false
    });
};

const forceReloadExternalJob = (ID) => {
    queryClient.invalidateQueries(['externalJobs', ID])
}

const onDeleteExternalJob = ({ ID }) => (
    Axios.delete(`/ExternalJobs/${ID}`)
)

const useDeleteExternalJob = () => {
    return useMutation(onDeleteExternalJob, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.externalJobId) {
                forceReloadExternalJob(variables.externalJobId);
            }
        }
    })
}

const onUpdateExternalJob = ({ externalJobData }) => (
    Axios.put('/ExternalJobs', externalJobData)
);

const useUpdateExternalJob = () => {
    return useMutation(onUpdateExternalJob, {
        onSuccess: (data, variables) => {
            forceReloadExternalJob(variables.externalJobData.ID);
        }
    })
}

const onMoveExternalJob = ({ externalJobData }) => (
    Axios.put('/ExternalJobs/MoveEvent', externalJobData)
);

const useMoveExternalJob = () => {
    return useMutation(onMoveExternalJob, {
        onSuccess: (data, variables) => {
            forceReloadExternalJob(variables.externalJobData.ID);
        }
    })
};

const onAddExternalJob = ({ externalJobData }) => (
    Axios.post('/ExternalJobs', externalJobData)
);

const useAddExternalJob = () => {
    return useMutation(onAddExternalJob, {
        onSuccess: (data, variables) => {
            forceReloadExternalJob(data.ID);
        }
    })
};

const onCancelExternalJob = ({ ID }) => (
    Axios.put(`/ExternalJobs/cancellation/${ID}`)
);

const useCancelExternalJob = () => {
    return useMutation(onCancelExternalJob, {
        onSuccess: (data, variables) => {
            forceReloadExternalJob(variables.ID);
        }
    })
};


export {
    useExternalJob,
    useDeleteExternalJob,
    useUpdateExternalJob,
    useAddExternalJob,
    forceReloadExternalJob,
    useCancelExternalJob,
    useMoveExternalJob
};