import React, { useContext, useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { ListContext } from 'Contexts/ListContext';
import EventDateModelOptionNumbers from './EventDateModelOptionNumbers';

const OptionNumberStyled = styled.div`
  background-color: ${props => props.isConfirmed ? props.baseColor : props.secondaryColor};
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${props => props.isConfirmed ? 'white' : 'inherit'};
  margin-left: 10px;
  font-size: .95rem;
  cursor: pointer;

  small {
    font-size: .65rem;
  }
`;

const OptionNumber = ({ number, event, selectedModel, readOnly, reloadGroupedModels, eventDateInfo, eventDatesModel, onExtendOptionNumberToAllDates }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { cachedList } = useContext(ListContext);
  const eventStyle = cachedList.eventTypes.items.find((et) => (et.id === 20));
  if (!number && number !== 0) {
    return null;
  }

  const afterUpdate = (model) => {
    reloadGroupedModels(model);
    setIsVisible(false);
  }

  const handleExtendOptionNumberToAllDates = (modelId, optionNumber) => {
    onExtendOptionNumberToAllDates(modelId, optionNumber);
    setIsVisible(false);
  }

  if (event.TypeName === 'Casting') {
    return null;
  }

  return (
    <Popover
      placement='bottom'
      title='Change option number'
      trigger='click'
      getPopupContainer={triggerNode => triggerNode.parentNode}
      open={isVisible}
      onOpenChange={(visible) => setIsVisible(visible)}
      content={(
        <EventDateModelOptionNumbers
          eventID={event.ID}
          selectedModel={selectedModel}
          eventDates={event.EventDates}
          eventDateInfo={eventDateInfo}
          eventDatesModel={eventDatesModel}
          onExtendOptionNumberToAllDates={handleExtendOptionNumberToAllDates}
          afterUpdate={afterUpdate} />
      )}>
      <OptionNumberStyled isConfirmed={number === 10} baseColor={eventStyle.baseColor} secondaryColor={eventStyle.secondaryColor} onClick={() => !readOnly && setIsVisible(true)}>
        {
          number === 10 ? (
            <small>JOB</small>
          ) : number
        }
      </OptionNumberStyled>
    </Popover>

  )
}

export default OptionNumber;