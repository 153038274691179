import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getScoutingMeeting = scoutingMeetingId => (
    Axios.get(`/ScoutingMeetings/${scoutingMeetingId}`)
);

const useScoutingMeeting = scoutingMeetingId => {
    return useQuery(["scoutingMeetings", scoutingMeetingId], () => getScoutingMeeting(scoutingMeetingId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: scoutingMeetingId ? true : false
    });
};

const forceReloadScoutingMeeting = (ID) => {
    queryClient.invalidateQueries(['scoutingMeetings', ID])
}

const onDeleteScoutingMeeting = ({ ID }) => (
    Axios.delete(`/ScoutingMeetings/${ID}`)
)

const useDeleteScoutingMeeting = () => {
    return useMutation(onDeleteScoutingMeeting, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.scoutingMeetingId) {
                forceReloadScoutingMeeting(variables.scoutingMeetingId);
            }
        }
    })
}

const onUpdateScoutingMeeting = ({ scoutingMeetingData }) => (
    Axios.put('/ScoutingMeetings', scoutingMeetingData)
);

const useUpdateScoutingMeeting = () => {
    return useMutation(onUpdateScoutingMeeting, {
        onSuccess: (data, variables) => {
            forceReloadScoutingMeeting(variables.scoutingMeetingData.ID);
        }
    })
}

const onAddScoutingMeeting = ({ scoutingMeetingData }) => (
    Axios.post('/ScoutingMeetings', scoutingMeetingData)
);

const useAddScoutingMeeting = () => {
    return useMutation(onAddScoutingMeeting, {
        onSuccess: (data, variables) => {
            forceReloadScoutingMeeting(data.ID);
        }
    })
};

const onCancelScoutingMeeting = ({ ID }) => (
    Axios.put(`/ScoutingMeetings/cancellation/${ID}`)
);

const useCancelScoutingMeeting = () => {
    return useMutation(onCancelScoutingMeeting, {
        onSuccess: (data, variables) => {
            forceReloadScoutingMeeting(variables.ID);
        }
    })
};

export {
    useScoutingMeeting,
    useDeleteScoutingMeeting,
    useUpdateScoutingMeeting,
    useAddScoutingMeeting,
    forceReloadScoutingMeeting,
    useCancelScoutingMeeting
};