import React from 'react';
import styled from 'styled-components';

import { NwAddButton, NwButton } from "Components/Gui/NwButton";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { showDate, showDateNoTime } from 'Libs/NwMoment';
import { Row, Col } from 'antd';


const AddModelText = styled.div`
    font-size: 1.2rem;
`;

const AddModelRow = styled(Row)`
    margin-top: 16px;
`;

const AddModelContainer = styled.div`
    padding: 16px;
`;

export const EventAddModelQuestionBooking = ({
    eventData,
    model,
    onAddModelToEventDate,
    onShowEvent
}) => {
    const renderGoDetails = () => (
        <AddModelRow>
            <Col span={4}></Col>
            <Col span={8}><AddModelText>No, take me to event details</AddModelText>
            </Col>
            <Col span={8}><NwButton primary label='Go' onClick={onShowEvent} /></Col>
        </AddModelRow>
    );

    let eventDates = [...eventData.EventDates];
    eventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);

    return (
        <AddModelContainer>
            <AddModelText>
                {`Do you want to add ${getContactName(model)} to event - ${eventData.Name}?`}
            </AddModelText>
            {eventDates.length === 1 ?
                <>
                    <AddModelRow>
                        <Col span={4}></Col>
                        <Col span={8}>
                            <AddModelText>
                                {showDateNoTime(eventDates[0].FromDate)}
                            </AddModelText>
                        </Col>
                        <Col span={8}>
                            <NwAddButton primary label='Add' onClick={() => onAddModelToEventDate(eventDates[0].ID)} />
                        </Col>
                    </AddModelRow>
                    {renderGoDetails()}
                </>
                :
                <>
                    <AddModelRow>
                        <Col span={4}></Col>
                        <Col span={8}><AddModelText>Add model to all event dates</AddModelText></Col>
                        <Col span={8}><NwAddButton primary label='Add' onClick={() => onAddModelToEventDate()} /></Col>
                    </AddModelRow>
                    <AddModelRow>
                        <Col span={4}></Col>
                        <Col span={8}><AddModelText>Add model to a date</AddModelText></Col>
                    </AddModelRow>
                    {eventDates.map(eventDate => {
                        return (
                            <AddModelRow>
                                <Col span={8}></Col>
                                <Col span={4}><AddModelText>{showDateNoTime(eventDate.FromDate)}</AddModelText></Col>
                                <Col span={8}><NwAddButton primary label='Add' onClick={() => onAddModelToEventDate(eventDate.ID)} /></Col>
                            </AddModelRow>
                        )
                    })}
                    {renderGoDetails()}
                </>
            }
        </AddModelContainer>
    )
}

export const EventAddModelQuestion = ({
    eventData,
    model,
    onAddModel,
    onShowEvent
}) => {
    return (<AddModelContainer>
        <p>Here</p>
        <AddModelText>
            {`Do you want to add ${getContactName(model)} to: ${eventData.Title}?`}
        </AddModelText>
        <AddModelRow>
            <Col span={4}></Col>
            <Col span={8}>
                <AddModelText>
                    {`${showDate(eventData.StartDate)} - ${showDate(eventData.EndDate)}`}
                </AddModelText>
            </Col>
            <Col span={8}>
                <NwAddButton primary label='Add' onClick={() => onAddModel(model)} />
            </Col>
        </AddModelRow>
        <AddModelRow>
            <Col span={4}></Col>
            <Col span={8}>
                <AddModelText>
                    No, take me to event details
                </AddModelText>
            </Col>
            <Col span={8}>
                <NwButton primary label='Go' onClick={onShowEvent} />
            </Col>
        </AddModelRow>
    </AddModelContainer>)
}
