import React, { useContext } from "react";
import styled from 'styled-components';

import useEventStyle from 'Hooks/UseEventStyle';
import EventTypeTag from "Components/EventDetails/EventTypeTag";
import { ListContext } from 'Contexts/ListContext';
import EventTypeIcon from "Components/EventDetails/EventTypeIcon";

const EventContentInner = styled.div`
    color: ${props => props.color && props.color};
    padding: 0 0.5rem;
    //opacity: ${props => props.isActive ? 1 : 0.25};

    .event-container {
        display: flex;
        align-items: center;
    }

    .event-content {
        overflow: hidden;
    }

    .event-title {
        font-weight: bold;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: .8rem;
        padding: .15rem 0;
    }

    .event-invisible {
        opacity: 0 !important;
    }

    .tooltip-content {
        margin-top: 0 !important;
    }

    .event-models {
        margin-top: 4px;
    }

    .event-tooltip {
        height: 0;
        line-height: 1.5em;
        min-height: auto;
        overflow-y: hidden;
        white-space: normal;
        transition: all 300ms ease-out;
        transition-property: height;

        .tooltip-content {
            transition: all 300ms ease-out;
            transition-property: padding-top, margin-top;

            .separator {
                width: 100%;
                border-top: 1px dotted rgba(255, 255, 255, 0.2);
                margin-top: 2px;
                margin-bottom: 2px; 
            }
        }
    }

    .cancel {
        ${props => props.theme.LineThrough(1)}
    }
`;


const MonthEventCard = ({ event, model }) => {
    const {
        calendar,
        Cancel,
        ID,
        isActive = true,
        OptionNumber,
        Project,
        title,
        TypeName,
    } = event;
    
    const { cachedList } = useContext(ListContext);
    const followUpLabels = cachedList.followUpLabels.items || [];
    const ProjectID = Project ? Project.ID : null;
    const eventstyle = useEventStyle(event, event.TypeName)

    // const handleMouseEnter = (projectId, id, type, calendar) => {
    //     const toggleEvents = []
    //     const eventsCards = document.querySelectorAll('.rbc-event');
    //     if (calendar === 'models') {
    //         const eventCard = document.querySelector('.event-' + id + type)
    //         eventCard.style.minHeight = eventCard.style.height
    //     }
    //     if (projectId) {
    //         eventsCards.forEach(event => {
    //             if (
    //                 event.classList.value.search('type-empty') < 0 &&
    //                 event.classList.value.search(`project-${projectId}`) < 0
    //             ) {
    //                 toggleEvents.push(event)
    //             }
    //         })
    //     }
    //     toggleEvents.forEach(event => {
    //         event.classList.add('event-fade')
    //     })
    // }

    // const handleMouseLeave = () => {
    //     const eventsCards = document.querySelectorAll('.rbc-event');
    //     eventsCards.forEach(event => {
    //         event.classList.remove('event-fade')
    //     })
    // }

    const handleMouseDown = () => {
        // console.log('Holding')
        document.body.classList.add('dragging')
    }

    const handleMouseUp = () => {
        // console.log('Releasing')
        document.body.classList.remove('dragging')
    }

    const getFollowupLabel = () => {
        const followup = followUpLabels.find(item => item.Code === event.SubTypeCode);
        return followup ? followup.Label : 'followup';
    }

    const getModelOptionNumber = () => {
        if (model) {
            if (TypeName === "Job") {
                if (OptionNumber && OptionNumber > 0 && OptionNumber < 10) {
                    return ` [${OptionNumber}]`
                }
            }
        }
        return ''
    }

    if (event.emptyEvent) {
        return <div>{event.title}</div>
    }
    switch (TypeName) {
        case "Casting":
        case "Job":
            return (
                <EventContentInner
                    isActive={isActive}
                    color={eventstyle && eventstyle.color}
                    //backgroundColor={eventstyle && eventstyle.backgroundColor}
                    style={{background: eventstyle.backgroundColor}}
                    width='100px'
                    className="event event-with-tooltip"
                    //onMouseEnter={() => handleMouseEnter(ProjectID, ID, TypeName, calendar)}
                    //onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >  
                    <div className={`event-content${Cancel ? ' cancel' : ''}`}>
                        <div className="event-title">
                            {title}{getModelOptionNumber()}
                        </div>
                    </div>
                </EventContentInner>
            );
        case "Travel":
        case "FollowUp":
        case "Meeting":
        case "ExtJob":
        case "Notice":
        case "Accommodation":
        case "Package":
            return (
                <EventContentInner
                    isActive={isActive}
                    color={eventstyle && eventstyle.color}
                    //backgroundColor={eventstyle && eventstyle.backgroundColor}
                    style={{background: eventstyle.backgroundColor}}
                    className="event event-with-tooltip"
                    //onMouseEnter={() => handleMouseEnter(ProjectID, ID, TypeName, calendar)}
                    //onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    <div className='event-container'>
                        <EventTypeIcon event={event} type={TypeName} />
                        <div className={`event-content${Cancel ? ' cancel' : ''}`}>
                            <div className="event-title">
                                {TypeName === "FollowUp" ? getFollowupLabel() : title}
                            </div>
                        </div>
                    </div>
              </EventContentInner>
            );
        default:
            return null;
    }
}

export default MonthEventCard
