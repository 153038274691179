import React, { useContext, useEffect, useMemo, useState } from 'react';
import TransactionDetailDrawer from 'Components/Transactions/TransactionsDrawer/TransactionDetailDrawer';
import { Loading } from 'Components/Gui/Loading';
import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { NwTag } from 'Components/Gui/NwTag';
import { getMoment } from "Libs/NwMoment";
import ProjectGroupedTransactionsListItem from './ProjectGroupedTransactionsListItem';
import { ProjectTransactionListContainer } from './ProjectTransactionsUI';
import { ListContext } from 'Contexts/ListContext';
import { NwButton } from 'Components/Gui/NwButton';
import Axios from 'axios';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TransactionsStatusLegend from 'Components/Transactions/TransactionsStatusLegend';

const ProjectGroupedTransactionsList = ({ projectId, modelId, readOnly, isExpanded, event, lastUpdate }) => {
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const { data: project, isFetching: isFetchingProject } = useProject(projectId);
    //const { data: transactions, isFetching: isFetchingTransactions } = useProjectTransactions(projectId);
    const [transactions, setTransactions] = useState();
    const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
    const [selectedFamilies, setSelectedFamilies] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const { GetFamilyName } = useContext(ListContext);
    const [settingAsBillable, setSettingAsBillable] = useState(false);

    const fetchTransactions = async () => {
        setIsFetchingTransactions(true)
        const prjTransactions = await Axios.get(`/projects/TransactionsGroupedByModelAndDay/${projectId}`)
        setTransactions(prjTransactions)
        setIsFetchingTransactions(false)
    }

    useEffect(() => {
        fetchTransactions()
    }, [lastUpdate])
    
    const reloadTransactions = () => {
        fetchTransactions()
    }

    const dateToString = (date) => {
        if (date) {
            return getMoment(date).format('YYYYMMDD')
        } else {
            return '99999999'
        }
    }

    useEffect(() => {
        if (isExpanded) {
            setExpandedItems([])
        }
    }, [isExpanded])

    const usedFamilies = useMemo(() => {
        let families = []
        if (transactions && transactions.length) {
            for (const modeltransaction of transactions) {
                if (modeltransaction.Dates) {
                    for (const date of modeltransaction.Dates) {
                        if (date.Transactions) {
                            for (const transaction of date.Transactions) {
                                if (!families.includes(transaction.Family)) {
                                    families.push(transaction.Family);
                                }
                            }
                        }
                    }
                }
            }
        }
        return families;
    }, [transactions]);

    const sortedDates = useMemo(() => {
        const sortedArray = [];
        if (transactions && transactions.length) {
            for (const modeltransaction of transactions) {
                if (modeltransaction.Dates && modeltransaction.Dates.length) {
                    const sorteddates = modeltransaction.Dates.sort((a, b) => dateToString(a.Date) - dateToString(b.Date));
                    sortedArray.push({
                        ...modeltransaction,
                        Dates: sorteddates
                    })
                } else {
                    sortedArray.push(modeltransaction);
                }
            }
        }
        return sortedArray
    }, [transactions])

    const filteredTransactions = useMemo(() => {
        let filteredTransactions = [];
        if (sortedDates && sortedDates.length) {
            if (selectedFamilies.length) {
                for (const modeltransaction of sortedDates) {
                    if (modeltransaction.Dates && modeltransaction.Dates.length) {
                        const filtereddates = modeltransaction.Dates.map(date => {
                            if (date.Transactions && date.Transactions.length) {
                                const filteredtransactions = date.Transactions.filter(transaction => selectedFamilies.includes(transaction.Family));
                                return {
                                    ...date,
                                    Transactions: filteredtransactions
                                }
                            } else {
                                return date;
                            }
                        }).filter(date => date.Transactions && date.Transactions.length);
                        filteredTransactions.push({
                            ...modeltransaction,
                            Dates: filtereddates
                        })
                    } else {
                        filteredTransactions.push(modeltransaction);
                    }
                }
                //remove modeltransactions without dates
                filteredTransactions = filteredTransactions.filter(modeltransaction => modeltransaction.Dates && modeltransaction.Dates.length);
            } else {
                filteredTransactions = sortedDates;
            }
        }
        return filteredTransactions;
    }, [sortedDates, selectedFamilies]);


    const getFilteredTransactionsByEvent = (transactions) => {
        return transactions.filter(transaction => transaction[event.elType + "ID"] === event.ID)
    }

    const eventTransactions = useMemo(() => {
        let eventTransactions = [];
        if (sortedDates && sortedDates.length) {
            if (event) {
                for (const modeltransaction of sortedDates) {
                    if (modeltransaction.Dates && modeltransaction.Dates.length) {
                        const filtereddates = modeltransaction.Dates.map(date => {
                            if (date.Transactions && date.Transactions.length) {
                                const filteredtransactions = getFilteredTransactionsByEvent(date.Transactions);
                                return {
                                    ...date,
                                    Transactions: filteredtransactions
                                }
                            } else {
                                return date;
                            }
                        }).filter(date => date.Transactions && date.Transactions.length);
                        eventTransactions.push({
                            ...modeltransaction,
                            Dates: filtereddates
                        })
                    } else {
                        eventTransactions.push(modeltransaction);
                    }
                }
                //remove modeltransactions without dates
                eventTransactions = eventTransactions.filter(modeltransaction => modeltransaction.Dates && modeltransaction.Dates.length);
            }
        }
        return eventTransactions;
    }, [sortedDates]);

    const otherTransactions = useMemo(() => {
        let otherTransactions = [];
        if (sortedDates && sortedDates.length) {
            if (event) {
                for (const modeltransaction of sortedDates) {
                    if (modeltransaction.Dates && modeltransaction.Dates.length) {
                        const filtereddates = modeltransaction.Dates.map(date => {
                            if (date.Transactions && date.Transactions.length) {
                                const filteredtransactions = date.Transactions.filter(transaction => !transaction.EventID || transaction.EventID !== event.ID)
                                return {
                                    ...date,
                                    Transactions: filteredtransactions
                                }
                            } else {
                                return date;
                            }
                        }).filter(date => date.Transactions && date.Transactions.length);
                        otherTransactions.push({
                            ...modeltransaction,
                            Dates: filtereddates
                        })
                    } else {
                        otherTransactions.push(modeltransaction);
                    }
                }
                //remove modeltransactions without dates
                otherTransactions = otherTransactions.filter(modeltransaction => modeltransaction.Dates && modeltransaction.Dates.length);
            }
        }
        return otherTransactions;
    }, [sortedDates]);

    const handleEditTransaction = transaction => {
        setSelectedTransaction(transaction);
        setShowTransactionDetail(true);
    }

    const handleTransactionCreatedOrUpdated = () => {
        reloadTransactions()
        forceReloadProjectAccountingDetails(project.ID)
        forceReloadProject(project.ID)
    };

    const handleCloseTransactionDetail = () => {
        setSelectedTransaction();
        setShowTransactionDetail(false);
    };

    const handleSelectFamily = family => {
        if (selectedFamilies.includes(family)) {
            setSelectedFamilies(selectedFamilies.filter(selectedFamily => selectedFamily !== family))
        } else {
            setSelectedFamilies([...selectedFamilies, family])
        }
    }

    const handleSelectAllFamilies = () => {
        setSelectedFamilies([])
    }

    const isSelectedFamily = family => {
        return selectedFamilies.includes(family)
    }

    const setAllTransactionsToBillable = async () => {
        setSettingAsBillable(true)
        //const statusMoreThanBillable = ["Billable", "Locked", "Invoiced"]
        let rowsUpdatedCounter = 0;
        for (const m of filteredTransactions) {
            for (const d of m.Dates) {
                for (const t of d.Transactions) {
                    if (t.ClientStatements && t.ClientStatements.length) {
                        //if (!t.ClientStatements.some(statement => statusMoreThanBillable.includes(statement.Status))) {
                        //consider only confirmed (and not cancelled) transactions
                        if (t.ClientStatements.some(statement => statement.Status === "Job")) {
                            if (!t.WorkDateCancelled) {
                                await Axios.put(`/transactions/${t.Family}/setasbillable/${t.ID}`)
                                rowsUpdatedCounter++
                            }
                        }
                    }
                }
            }
        }
        if (rowsUpdatedCounter > 0) {
            reloadTransactions()
            forceReloadProjectAccountingDetails(projectId)
        }
        setSettingAsBillable(false)
    }

    if (isFetchingTransactions || isFetchingProject) {
        return <Loading paddingTop="4rem" />
    }


    return (
        (filteredTransactions && project)
            ?
            <ProjectTransactionListContainer>
                {!event &&
                    <div className="families-selector">
                        <NwTag pointer key="all-families" clickaction={handleSelectAllFamilies} type={selectedFamilies.length === 0 ? "primary" : null}>all transactions</NwTag>
                        {usedFamilies.map(family => (
                            <NwTag pointer key={family} clickaction={() => handleSelectFamily(family)} type={isSelectedFamily(family) ? "primary" : null}>{GetFamilyName(family)}</NwTag>
                        ))}
                    </div>
                }
                {event
                    ?
                    <div className="transactions-list-for-event-drawer">
                        <h1>Event transactions</h1>
                        {eventTransactions.map((model, index) => (
                            <ProjectGroupedTransactionsListItem
                                key={`model-${model.ModelID ? model.ModelID : '0'}`}
                                model={model}
                                project={project}
                                isExpanded={isExpanded}
                                onEditTransaction={handleEditTransaction}
                                readOnly={readOnly}
                                eventDrawer
                                expandedItems={expandedItems}
                                onItemExpansion={setExpandedItems}
                            />
                        ))}
                        <br />
                        <h1>Other transactions in project</h1>
                        {otherTransactions.map((model, index) => (
                            <ProjectGroupedTransactionsListItem
                                key={`model-${model.ModelID ? model.ModelID : '0'}`}
                                model={model}
                                project={project}
                                isExpanded={isExpanded}
                                onEditTransaction={handleEditTransaction}
                                readOnly={readOnly}
                                eventDrawer
                                expandedItems={expandedItems}
                                onItemExpansion={setExpandedItems}
                            />
                        ))}
                    </div>
                    :
                    <>
                        <div className="billable-trigger">
                            <NwButton primary icon={solid("bolt")} loading={settingAsBillable} label="set all confirmed transactions as billable" onClick={setAllTransactionsToBillable} />
                        </div>
                        {settingAsBillable
                            ?
                            <div className="loading-container">
                                <Loading textBlack text="applying changes..." />
                            </div>
                            :
                            <>
                                {filteredTransactions.map((model, index) => (
                                    <ProjectGroupedTransactionsListItem
                                        key={`model-${model.ModelID ? model.ModelID : '0'}`}
                                        model={model}
                                        project={project}
                                        isExpanded={isExpanded}
                                        onEditTransaction={handleEditTransaction}
                                        readOnly={readOnly}
                                        expandedItems={expandedItems}
                                        onItemExpansion={setExpandedItems}
                                    />
                                ))}
                            </>
                        }
                    </>
                }
                <div className="transactions-status-legend-container">
                    <TransactionsStatusLegend />
                </div>
                {showTransactionDetail &&
                    <TransactionDetailDrawer
                        hideProject
                        transaction={selectedTransaction}
                        projectId={project.ID}
                        // callsheets={project.Callsheets}
                        // clientID={project.CustomerID}
                        onClose={handleCloseTransactionDetail}
                        onUpdateTransaction={handleTransactionCreatedOrUpdated}
                        onTransactionRemoved={() => {
                            setShowTransactionDetail(false)
                            setSelectedTransaction()
                            reloadTransactions()
                            forceReloadProjectAccountingDetails(project.ID)
                            forceReloadProject(project.ID)
                        }}
                    />
                }
            </ProjectTransactionListContainer>
            :
            <Loading textBlack />
    );
};

export default ProjectGroupedTransactionsList;