import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";

const Option = Select.Option;

const InputTransaction = ({ onChange, value, disabled }) => {
  const { cachedList } = useContext(ListContext);
  const enumTransactions = cachedList.enumerators.items.find(item => item.Name === "JobTransactionStatusEnum");
  const allTx = enumTransactions.Values;
  const handleChange = val => {
    onChange(val);
  };
  return (
    <Select
      autoComplete='off'
      mode="multiple"
      value={value}
      placeholder="Please select"
      onChange={handleChange}
      disabled={disabled}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      style={{ width: "100%", zIndex: 1600 }}
    >
      {allTx.map(el => (
        <Option key={el.Value} value={el.Value}>
          {el.Name}
        </Option>
      ))}
    </Select>
  );
};

export default InputTransaction;
