import React from 'react';
import styled from 'styled-components';
import Switch from 'Components/Gui/CustomSwitch';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { NwLinkButton } from 'Components/Gui/NwButton';
import { ModelSelectorContainer } from '../../../Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { Divider } from 'antd';

const StyledSwitch = styled(Switch)`
    ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
`
const ModelSelectorNonBookingDates = ({ models, updateModels, selectedEvent }) => {
    
    const activateModel = (active, modelIndex) => {
        let newModels = [...models];
        newModels[modelIndex].active = active;
        updateModels(newModels);
    };

    const activateAll = () => {
        let newModels = models.map((m) => ({...m, active: true}));
        updateModels(newModels);
    }

    const deactivateAll = () => {
        let newModels = models.map((m) => ({...m, active: false}));
        updateModels(newModels);
    }

    return (
        <ModelSelectorContainer>
            <h1>{selectedEvent ? 'Models' : 'Models in current project'}</h1>
            <div className="button-bar">
                <NwLinkButton onClick={activateAll} label="select all" />
                <Divider type="vertical" />
                <NwLinkButton onClick={deactivateAll} label="deselect all" />
            </div>
            {models.map((model, index) => {
                return (
                    <div className='model-wrapper' key={model.ModelID}>
                        <StyledSwitch
                            $cancel={model.cancel}
                            text={getContactName(model.ModelID)}
                            checked={model.active}
                            onChange={value => activateModel(value, index)} />
                    </div>
                )
            })}
        </ModelSelectorContainer>
    )
};

export default ModelSelectorNonBookingDates;