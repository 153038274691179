import React from "react";
import styled from 'styled-components';

import DisplayFilterItem from "Components/Filters/DisplayFilterItem";
//import { useStateValue } from 'Contexts/StateProvider';

const Container = styled.div`
    width: 100%;
`;

const SelectedFilters = ({ filters, view, customFields, filterList }) => {
    
    // const [{modelFiltersContext, areaContext}] = useStateValue();
    // const { activeSet, customSets, staticSets } = modelFiltersContext[areaContext.area];
    // const workingSet = [...customSets, ...staticSets]
    // const active = workingSet.find(el => el.id === activeSet);
    // let displayFilters = filters ? filters : active && active.filters;

    // if (displayFilters && displayFilters.length > 0) {
    //     displayFilters = displayFilters.sort((filter1, filter2) => {
    //         if (filter1.name.includes('custom_fields')) {
    //             if (!filter2.name.includes('custom_fields')) return 1;
    //         }
    //         return -1;
    //     })
    // }

    const displayFilters = filters;

    return (
        <>
        {displayFilters && displayFilters.length > 0 && (
            <Container view={view}>
            {displayFilters.map(el => (
                <DisplayFilterItem key={el.name} filter={el} view={view} customFields={customFields} filterList={filterList} />
            ))}
            </Container>
        )}
        </>
    );
};

export default SelectedFilters;
