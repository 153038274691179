import React, { useMemo } from "react";
import { Slider } from "antd";
import styled from 'styled-components';

const ConversionSlider = styled(Slider)`
    margin-top: 4px;
    
    &.ant-slider-with-marks {
        margin-bottom: 1rem;
    }
    
    .ant-slider-mark-text {
        font-size: .65rem;
        font-weight: bold;
    }

    .ant-slider-track {
        background-color: ${props => props.disabledSlider && 'rgba(0, 0, 0, 0.25) !important'};
    }
  
    .ant-slider-handle, .ant-slider-dot {
        border-color: ${props => props.disabledSlider && 'rgba(0, 0, 0, 0.25) !important'};
    }
`;

const ConversionFilter = ({
    onChange,
    disabled,
    value,
    conversions,
    globalConversion,
    metric,
    fullWidth,
}) => {
    const conversionValues = useMemo(() => {
        const minValue = conversions[0].Key;
        const maxValue = conversions[conversions.length - 1].Key;
        const conversionValues = globalConversion.ConversionValues.filter(conversionValue => {
            if (conversionValue.Key >= minValue && conversionValue.Key <= maxValue && conversionValue.Metric === metric.metrics[0]) {
                return true;
            }
            return false;
        });
        return conversionValues;
    }, [globalConversion, conversions, metric]);


    const handleChange = index => {
        const val1 = conversionValues[index[0]].Key;
        const val2 = conversionValues[index[1]].Key;
        onChange([val1, val2]);
    };

    const filterValue = val => {
        const index1 = val ? conversionValues.findIndex(el => el.Key === val[0]) : 0;
        const index2 = val ? conversionValues.findIndex(el => el.Key === val[1]) : conversionValues.length- 1;
        return [index1, index2];
    };

    let marks = {};
    const interval = Math.round(conversionValues.length / 10);
    conversionValues.map((conversionValue, index) => {
        if ((index % interval === 0 && conversionValues.length - index >= interval)
            || index === conversionValues.length-1) {
            marks = {
                ...marks,
                [index]: conversionValue.Value
            }
        } 
        return null;
    });

    return conversions.length > 0 &&
        <ConversionSlider
            min={0}
            disabledSlider={disabled}
            marks={marks}
            range={true}
            tooltip={
                {
                    getPopupContainer: triggerNode => triggerNode.parentNode,
                    open: true,
                    formatter: value => `${conversionValues[value].Value} ${metric.metrics[0]}`
                }
            }
            max={conversionValues.length - 1}
            style={{ width: fullWidth?'calc(100% - 20px)':'calc(100% - 200px)' }}
            value={filterValue(value)}
            onChange={handleChange}
        />
};

export default ConversionFilter;
