import React, { useContext, useMemo, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Axios from 'axios';
import PackageModelCard from 'Components/PackageDrawer/PackageModelCard/PackageModelCard';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwLinkButton } from 'Components/Gui/NwButton';
import RenameGroupModal from './PackageGroupModals/RenameGroupModal';
import { PackageContext } from './PackageContext';
import { Dropdown } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';



const SortableItem = SortableElement(({
    compact,
    isSort,
    item,
    loading,
    onRemovePackagePlaylist,
    smallView,
    selectedModels,
    setSelectedModels
}) => {

    return (
        <div className="package-model-draggable">
            <div className="package-model-card-wrapper">
                <PackageModelCard
                    compact={compact}
                    isSort={isSort}
                    loading={loading}
                    smallView={smallView}
                    model={item}
                    onRemovePackagePlaylist={onRemovePackagePlaylist}
                    selectedModels={selectedModels}
                    setSelectedModels={setSelectedModels}
                />
            </div>
        </div>
    )
});

const SortableContainerWrapper = SortableContainer(({ children }) => (<div className="drag-container">{children}</div>));

const PackageGroup = ({
    compact,
    groupName,
    selectedModels,
    setSelectedModels
}) => {

    const [loading, setLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [renameModalOpen, setRenameModalOpen] = useState(false);

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage} = packageContextValue;
    const { ModelIdsByGroup } = groupedPackage;

    
    const currentGroup = useMemo(() => {
        return groupedPackage.GroupedModels.find(group => group.groupName === groupName);
    }, [groupName, groupedPackage]);
    
    const currentGroupIndex = ModelIdsByGroup.findIndex(group => group.group === groupName);
    const groupNumber = ModelIdsByGroup.length;
    const modelNumberInGroup = ModelIdsByGroup.find(group => group.group === groupName).modelIDs.length;


    const renameGroupModal = async (oldGroupName, newGroupName) => {
        const data = {
            PackageID: groupedPackage.ID,
            OldName: oldGroupName,
            NewName: newGroupName
        }
        try {
            await Axios.put(`/PackageModels/RenameGroup`, data);
            afterUpdatePackage()
        } catch (error) {
            console.log('ant : Rename Group Error => ', error);
        }
    }

    const handleCloseGroupModal = (newGroupName) => {
        setRenameModalOpen(false);
        if (newGroupName) {
            renameGroupModal(groupName, newGroupName);
        }
    }

    const onRemovePackagePlaylist = group => async () => {
        try {
            for (const playlist of group.playlists) {
                await Axios.delete(`/PackagePlaylists/${playlist.ID}`);
            }
            afterUpdatePackage()
        } catch (error) {
            console.log('ant : Remove PackagePlaylist Error => ', error);
        }
    };

    const onSortEnd = async ({ oldIndex, newIndex, collection }) => {
        if (oldIndex === newIndex) return;
        setLoading(true)
        const arrayToUpdate = [...ModelIdsByGroup.find(group => group.group === groupName).modelIDs];
        const updatedArray = arrayMove(arrayToUpdate, oldIndex, newIndex);
        const updatedModelIDs = []
        for (const groupArray of ModelIdsByGroup) {
            if (groupArray.group === groupName) {
                updatedModelIDs.push(...updatedArray)
            } else {
                updatedModelIDs.push(...groupArray.modelIDs)
            }
        }
        try {
            await Axios.put(`/PackageModels/SetOrder/${groupedPackage.ID}`, updatedModelIDs);
            afterUpdatePackage()
            setLoading(false)
        } catch (error) {
            console.log('ant : Update Package Model Order Error => ', error)
            setLoading(false)
        }
    };

    const onClickMoveGroup = async ({ key }) => {
        const arrayToUpdate = [...ModelIdsByGroup];
        let newIndex = 0
        if (key === "bottom") {
            newIndex = groupNumber - 1;
        }
        if (key === "up") {
            newIndex = currentGroupIndex > 0 ? currentGroupIndex - 1 : 0;
        }
        if (key === "down") {
            newIndex = currentGroupIndex < groupNumber - 1 ? currentGroupIndex + 1 : groupNumber - 1;
        }
        const updatedArray = arrayMove(arrayToUpdate, currentGroupIndex, newIndex);
        const updatedModelIDs = []
        for (const groupArray of updatedArray) {
            updatedModelIDs.push(...groupArray.modelIDs)
        }
        try {
            await Axios.put(`/PackageModels/SetOrder/${groupedPackage.ID}`, updatedModelIDs);
            afterUpdatePackage()
            setLoading(false)
        } catch (error) {
            console.log('ant : Update Package Model Order Error => ', error)
            setLoading(false)
        }
    };
      
    const moveGroupItems = []
    if (currentGroupIndex > 0) {
        moveGroupItems.push({
            label: <span>Move to <strong>top</strong></span>,
            icon: <NwIcon icon={light("arrow-up-to-line")} />,
            key: 'top',
        })
        moveGroupItems.push({
            label: <span>Move <strong>up</strong></span>,
            icon: <NwIcon icon={light("arrow-up")} />,
            key: 'up',
        })
    }
    if (currentGroupIndex < groupNumber - 1) {
        moveGroupItems.push({
            label: <span>Move <strong>down</strong></span>,
            icon: <NwIcon icon={light("arrow-down")} />,
            key: 'down',
        })
        moveGroupItems.push({
            label: <span>Move to <strong>bottom</strong></span>,
            icon: <NwIcon icon={light("arrow-down-to-line")} />,
            key: 'bottom',
        })
    }

    return (
        <div className="package-group">
            <div className="package-group-wrapper">
                <NwCard
                    icon={light("people-group")}
                    title={currentGroup.groupLabel}
                    padding="0"
                    transparent
                    extra={
                        <div className="actions-group-holder">
                            {isCollapsed
                                ?
                                <NwLinkButton primary lowercase icon={light('chevron-down')} label='expand group' onClick={() => setIsCollapsed(false)} />
                                :
                                <NwLinkButton primary lowercase icon={light('chevron-up')} label='collapse group' onClick={() => setIsCollapsed(true)} />
                            }
                            {groupNumber > 1 &&
                            <Dropdown menu={{ items: moveGroupItems, onClick: onClickMoveGroup }}>
                                <NwLinkButton  primary label="Move group" icon={light("sort")} onClick={e => e.preventDefault()} />
                            </Dropdown>
                            }
                            <NwLinkButton primary lowercase icon={light('input-text')} label='rename group' onClick={() => { setRenameModalOpen(true) }} />
                            {modelNumberInGroup > 1 &&
                                <NwLinkButton 
                                    primary 
                                    lowercase 
                                    icon={light("sort")} 
                                    label={isSort ? "end sorting" : "sort models"}
                                    onClick={() => setIsSort(!isSort)}
                                />
                            }
                        </div>
                    }
                >
                    {!isCollapsed &&
                        <div className="package-group-models">
                            <SortableContainerWrapper
                                onSortEnd={onSortEnd}
                                axis='y'
                                useDragHandle={isSort ? false : true}
                            >
                                {currentGroup.models.map((item, index) => (
                                    <SortableItem
                                        compact={compact}
                                        index={index}
                                        isSort={isSort}
                                        item={item}
                                        key={`item-${index}`}
                                        loading={loading}
                                        onRemovePackagePlaylist={((item) => onRemovePackagePlaylist(item))}
                                        smallView={(groupedPackage.Models && groupedPackage.Models.length > 15) ? true : false}
                                        selectedModels={selectedModels}
                                        setSelectedModels={setSelectedModels}
                                    />
                                ))}
                            </SortableContainerWrapper>
                        </div>
                    }
                </NwCard>
            </div>
            {renameModalOpen &&
                <RenameGroupModal 
                    currentGroupName={groupName}
                    onClose={handleCloseGroupModal}
                />
            }
        </div>
    )
};

export default PackageGroup;