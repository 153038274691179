import {getUrlCover} from "Libs/NwUtils";

//import pattern_waves from '_img/pattern2.png';
//import pattern_curly from '_img/pattern4.png';
import pattern_gray_tiles from '_img/pattern7.png';

const usePlaceholderImageFixed = (guid, contacttype) => {
    if ([4,5].includes(contacttype) && guid) {
        return [getUrlCover(guid), false];
    }
    switch (contacttype) {
        case 4: //booking model
            return [pattern_gray_tiles, true]
        case 5: //scouting model
            return [pattern_gray_tiles, true]
        default: //client, service, agency
            return [pattern_gray_tiles, true]
    }
}

export default usePlaceholderImageFixed