import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const StyledDiv = styled.div`

    width: 100%;

    &.padded {
        padding: 2em 2.5em;
    }

    .nw-form-item-container {
        .ant-form-item {
            margin-bottom: .5rem;

            .ant-form-item-label {
                padding: 0 0 .5em 0;
                text-transform: uppercase;
                font-size: .8rem;
                height: auto;

                label {
                    height: auto;
                    font-size: .85rem;
                    
                    font-weight: 400;
                    color: ${props => props.theme.TextColorMuted}; 

                    &.ant-form-item-required {
                        &::before {
                            content: '';
                        }

                        &::after {
                            display: inline-block;
                            content: '';
                            height: .4rem;
                            width: .4rem;
                            margin-left: .25em;
                            border-radius: .25em;
                            background-color: ${props => props.theme.DangerColor};
                        }
                    }
                }
                small {
                    display: block;
                    font-size: ${props => props.theme.FontSizeSm};
                    color: ${props => props.theme.TextColorMuted};
                    text-transform: lowercase;
                }
            }

            .char-counter-container {
                font-size: .75rem;
                text-align: right;
                padding: .25em 1em;
                position: absolute;
                right: 0;
                top: -20px;
            }

            .ant-form-item-explain {
                font-size: .8rem;
                text-transform: uppercase;
                padding: .25em 1em;
                opacity: .75;
                
            }

            &.ant-form-item-has-error {
                

                .ant-btn {
                    border-color: ${props => props.theme.DangerColor} !important;
                    color: ${props => props.theme.DangerColor} !important;

                    &:hover, &:focus {
                        border-color: ${props => props.theme.DangerColor} !important;
                        color: ${props => props.theme.DangerColor} !important;
                    }
                }

                .ant-select-arrow {
                    color: ${props => props.theme.DangerColor};
                }

                .ant-form-item-explain {
                    color: ${props => props.theme.DangerColor};
                    font-size: .75rem;
                    padding: 0;
                    opacity: 1;
                }
            }
        }

        &.inline {
            .ant-form-item {
                flex-direction: row !important;
                align-items: center;

                .ant-form-item-label {
                    padding: 0 1em 0 0;
                }
            }
        }
    }
`

const NwFakeFormButtonsBar = ({ left, right, marginTop = ".5em" }) => {
    return (
        <Row justify="space-between" style={{ margin: `${marginTop} 0 1em 0` }}>
            <Col>{left || <span></span>}</Col>
            <Col>{right || <span></span>}</Col>
        </Row>
    )
}

const NwFakeForm = ({ horizontal, inline, padded, small, children }) => {
    const getClassName = () => {
        let cn = "ant-form"
        if (horizontal) {
            cn += " ant-form-horizontal"
        } else {
            if (inline) {
                cn += " ant-form-inline"
            } else {
                cn += " ant-form-vertical"
            }
        }
        if (small) {
            cn += " ant-form-small"
        }
        if (padded) {
            cn += " padded"
        }
        return cn
    };

    return (
        <StyledDiv className={getClassName()}>
            {children}
        </StyledDiv>
    )
}

const NwFakeFormItem = ({label, additionalLabel, control, required}) => {
    return (
        <div className="nw-form-item-container">
            <div className="ant-row ant-form-item">
                { label && 
                <div className="ant-col ant-form-item-label">
                    <label className={`${required && "ant-form-item-required"} ant-form-item-no-colon`}>{label}</label>
                    { additionalLabel &&
                        <small className="additional-label">{additionalLabel}</small>
                    }
                </div>
                }
                
                <div className="ant-col ant-form-item-control">
                    {control}
                </div>
            </div>
        </div>
    )
}

export { NwFakeForm, NwFakeFormButtonsBar, NwFakeFormItem };