import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { List, Tag } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ModelCover } from 'Components/ModelCover';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import ModelAgenciesDrawer from 'Components/ModelAgencies/ModelAgenciesDrawer';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';
import { getModel, getContactName } from 'Hooks/Contact/UseContactsList';
import { useRemoveAgency } from 'Hooks/Contact/UseContactAgencies';
import { useStateValue } from 'Contexts/StateProvider';





const StyledList = styled(List)`
    .ant-list-item {
        flex-wrap: nowrap;
    }
    .ant-list-item-meta {
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                text-transform: uppercase;
                font-size: 1rem;
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
        max-width: 70%;
        border-right: ${props => props.theme.DefaultBorder};
        padding-right: 8px;
        margin-right: 16px;
    }
    .type-name {
        font-weight: bold;
    }
    .ant-tag {
        border-radius: 40px;
    }
`;

const AgencyModels = ({ models, modelType }) => {
    const [selectedModelId, setSelectedModelId] = useState();
    const [isModelDrawer, setIsModelDrawer] = useState(false);
    const [isEditModelAgency, setIsEditModelAgency] = useState(false);
    const [modelAgency, setModelAgency] = useState();
    const { mutate: onRemoveAgency } = useRemoveAgency();
    const [showCommissions, setShowCommissions] = useState(false);
    const { cachedList } = useContext(ListContext);
    const [{ areaContext }] = useStateValue();

    const { GetUserName } = useContext(ListContext);

    const modelAgencyLabels = cachedList.modelAgencyLabels.items;
    const departments = useMemo(() => {
        const departments = [];
        for (const accountingGroup of cachedList.accountingGroups.items) {
            for (const networkAgency of accountingGroup.NetworkAgencies) {
                for (const department of networkAgency.Departments) {
                    departments.push(department);
                }
            }
        }
        return departments;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const handleOpenModelDrawer = model => () => {
        setSelectedModelId(model.ModelID);
        setIsModelDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setSelectedModelId();
        setIsModelDrawer(false);
    };

    const handleEditModelAgency = modelAgency => {
        setModelAgency(modelAgency);
        setIsEditModelAgency(true);
    };

    const handleCloseEditModelAgency = () => {
        setIsEditModelAgency(false);
        setModelAgency();
    };

    const handleRemoveModelAgency = modelAgency => {
        onRemoveAgency({ ModelAgencyID: modelAgency.ID, AgencyID: modelAgency.AgencyID, ModelID: modelAgency.ModelID });
    };

    const handleCloseCommissionDrawer = () => {
        setShowCommissions(false);
    }

    const model = modelAgency && getModel(modelAgency.ModelID);
    let motherExist = false;
    if (model) {
        if (areaContext.area === 'management') {
            motherExist = model.ACCTGR.find(acc => acc.MotherAgencyID) ? true : false;
        } else {
            motherExist= model.MAID ? true : false;
        }
    }

    return (
        <>
            <StyledList
                itemLayout="horizontal"
                dataSource={models}
                renderItem={model => {
                    const modelObject = getModel(model.ModelID);
                    let modelCoverData = {...modelObject};
                    if (areaContext.area === 'management') {
                        const agency = modelObject.NWAGS.find(nwAg => nwAg.CoverGuid);
                        modelCoverData = {
                            ...modelCoverData,
                            CG: agency && agency.CoverGuid
                        }
                    }

                    
                    const department = departments.find(department => department.ID === model.DepartmentCreated);
                    const modelAgencyLabel = modelAgencyLabels.find(agencyLabel => agencyLabel.ID === model.ModelAgencyLabelID);
                    return (
                        <List.Item 
                        className="ant-list-item-top" 
                        actions={[
                            <NwDropDownMenu
                                actions={[
                                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => handleEditModelAgency(model)) },
                                        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => handleRemoveModelAgency(model))},
                                        { name: "show commissions", key: "logs", icon: light("percent"), action: (() => setShowCommissions(true)) },
                                    ]}
                                />
                            ]}>
                            <FlexContainer fullWidth alignItems='flex-start'>
                                <List.Item.Meta
                                    onClick={handleOpenModelDrawer(model)}
                                    avatar={<ModelCover model={modelCoverData} classN="model-cover-middle" />}
                                    title={getContactName(model.ModelID)}
                                    description={
                                        <div>
                                            <div>{`${modelType === 'Booking' ? 'BOOKERS': 'SCOUTERS'}: ${GetUserName(model.UserCreated)}`}</div>
                                            {department && 
                                                <div>{`DEPARTMENTS: ${department.Name}`}</div>
                                            }
                                        </div>
                                    }
                                />
                                <div>
                                    <div className='type-name'>{model.TypeName}</div>
                                    {model.Percentage > 0 && 
                                        <div>{`Commission: ${model.Percentage}%`}</div>
                                    }
                                    {modelAgencyLabel && 
                                        <Tag color={`#${modelAgencyLabel.Color}`}>{modelAgencyLabel.Name}</Tag>
                                    }
                                </div>
                            </FlexContainer>
                        </List.Item>
                    )}
                }
            />
            {isModelDrawer && 
                <ModelDrawer modelId={selectedModelId} onClose={handleCloseModelDrawer} />
            }
            {isEditModelAgency && 
                <ModelAgenciesDrawer 
                    viewMode={'EDITING'}
                    exclusions={[]}
                    contactID={modelAgency.ModelID}
                    currentModelAgency={modelAgency}
                    motherExist={motherExist}
                    onClose={handleCloseEditModelAgency}
                />
            }
            {showCommissions && 
                <NwDrawer title='Commissions' onClose={handleCloseCommissionDrawer} />
            }
        </>
    )
};

export default AgencyModels;