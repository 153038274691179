import React from 'react'
import styled from 'styled-components';

// import StyleVariables from 'Components/Gui/StyleVariables.js';

const StyledSpan = styled.span`
    padding: 0 .5em;
    vertical-align: baseline;
    font-size: 1.5em;
    line-height: .5em;
`

const NwBullet = (props) => {
    return (
        <StyledSpan>&bull;</StyledSpan>
    )
}

export default NwBullet