import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import Scrollbar from 'react-scrollbars-custom';
import { Switch, Spin } from "antd";
import Axios from 'axios';

import ModelsWallHeader from 'Components/ModelSelector/ModelsWall/ModelsWallHeader/ModelsWallHeader';
import ModelsWallMiniHeader from 'Components/ModelSelector/ModelsWall/ModelsWallHeader/ModelsWallMiniHeader';
import { ModelsWallFooter } from 'Components/ModelSelector/ModelsWall/ModelsWallFooter/ModelsWallFooter';
import ModelsTableWrapper from 'Components/ModelSelector/ModelsWall/ModelsTable/ModelsTableWrapper';
import ModelCardsWrapper from 'Components/ModelSelector/ModelsWall/ModelsCards/ModelCardsWrapper';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import useLocalStorageState from 'Hooks/UseLocalStorageState';
import { MODELS_WALL_DEFAULT_STYLE } from 'Configs/WALLS_CONFIG';
import { forceReloadModels, modelFullName } from "Hooks/Contact/UseContactsList";
import { useStateValue } from 'Contexts/StateProvider';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { WallLineItem } from 'Components/Gui/WallLineItem';
import FolderModelsWrapper from 'Components/ModelSelector/ModelsWall/FolderModels/FolderModelsWrapper';
import { WallContainer } from 'Components/Gui/StyledComponent';

//import { UserContext } from 'Contexts/UserContext';
//import { getModelInTown } from 'Components/ModelSelector/ModelsWall//ModelsWallUtils';

const ScrollbarContainer = styled.div`
    border: ${StyleVariables.DefaultBorder};
    border-radius: 4px;
    height: ${props => props.small ? 'calc(100vh - 25rem)' : 'calc(100vh - 20rem)'};
    position: relative;

    .empty-wall-line-item {
        height: 1.75rem;
        width: 100%;
    }

    .list-counter {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: ${props => props.theme.PrimaryColor}DC;
        color: #fff;
        font-size: ${props => props.theme.FontSizeSm};
        padding: .25rem 1rem;
        border-radius: 0 0 4px 4px;
        width: 100%;
        font-weight: bold;
    }
`

const UnderScrollbar = styled.div`
    margin-top: 10px;
    text-align: center;
`;

const ModelsWall = ({
    addModelProps,
    connections,
    currentEvent,
    currentPackage,
    defaultView,
    disabledmodels,
    displayConnections,
    editFolder,
    filtersType,
    fixedView,
    inSideBar,
    isDrawer,
    isLoading,
    leftMargin,
    list,
    listNotFeaturesFiltered,
    listUpdated,
    modelEventsInfo,
    multipleSelection,
    onChangeShowOnlyModelsWithBooks,
    onEditFolder,
    onSelectModel,
    onSetQuickFilter,
    onUpdateModelEvents,
    packageGroup,
    quickFilter,
    scope,
    searchType,
    selectedModel,
    showModelDrawerOnClick,
    showOnlyModelsWithBooks,
    tags
}) => {

    const [{ areaContext },] = useStateValue();
    //const location = useLocation();
    //const breakpoints = useNwBreakPoints();
    const breakpoints = useNwBreakPoints()
    const isMobile = breakpoints === 1

    const [scrollPosition, setScrollPosition] = useLocalStorageState(`${areaContext.area}_modelswall_listScrollPosition`, 0)
    const [motherAgencyInList, setMotherAgencyInList] = useLocalStorageState(`${areaContext.area}_modelswall_motherAgencyInList`, false)
    const [recalcScroll, setRecalcScroll] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [multiSelection, setMultiSelection] = useState(false);
    //const { currentUser } = useContext(UserContext);
    const onUpdateSelectedList = addModelProps && addModelProps.onUpdateSelectedList;
    const [loading, setLoading] = useState(false);

    const styleIfNotMobile = useLocalStorageState(`${areaContext.area}_modelswall_style`, breakpoints < 4 ? 'table' : areaContext.area === 'accounting' ? 'table' : MODELS_WALL_DEFAULT_STYLE)
    const [style, setStyle] = useState(isMobile ? 'table' : styleIfNotMobile)

    useEffect(() => {
        if (disabledmodels) {
            setSelectedList(selectedList.filter(modelId => !disabledmodels.find(item => item === modelId)));
        }
    }, [disabledmodels]);

    useEffect(() => {
        if (listUpdated) {
            setSelectedList([]);
            setScrollPosition(0)
        }
    }, [listUpdated])

    useEffect(() => {
        if (recalcScroll) {
            setRecalcScroll(false)
            setScrollPosition(itemScrollPosition() - 100)
        }
    }, [motherAgencyInList])

    // useEffect(() => {
    //     if (filteredList && filteredList.length && selectedModel && !listUpdated) {
    //         setScrollPosition(itemScrollPosition() - 100)
    //     }
    // }, [filteredList, selectedModel]);

    useEffect(() => {
        if (list && list.length && selectedModel && !listUpdated) {
            setScrollPosition(itemScrollPosition() - 100)
        }
    }, [list, selectedModel]);

    const changeListStyle = (newStyle) => {
        setStyle(newStyle)
    };

    const resetScroll = () => {
        setScrollPosition(0)
    }


    const changeListSort = key => {
        setScrollPosition(0)
        onSetQuickFilter({ ...quickFilter, sort: key })
    };

    const changeListSortFolder = key => {
        setScrollPosition(0)
        onSetQuickFilter({ ...quickFilter, sortFolder: key })
    };

    const handleScrollStop = (scrollValues) => {
        setScrollPosition(scrollValues.scrollTop)
    }

    const itemScrollPosition = () => {
        const listItemHeight = motherAgencyInList ? 42 : 34
        if (selectedModel) {
            const index = (list.findIndex((item) => {
                return item.ID === selectedModel
            }));
            if (index > 0) return listItemHeight * index;
        }
        return 0
    }

    const updateSelectedList = (newlist) => {
        setSelectedList(newlist);
        onUpdateSelectedList && onUpdateSelectedList(newlist);
    }

    const toggleModelAddToSelectedList = (ID) => {
        if (selectedList.includes(ID)) {
            let newSelectedList = selectedList.filter(item => item !== ID);
            setSelectedList(newSelectedList);
            onUpdateSelectedList && onUpdateSelectedList(newSelectedList);
        } else {
            let newSelectedList = [...selectedList, ID];
            setSelectedList(newSelectedList);
            onUpdateSelectedList && onUpdateSelectedList(newSelectedList);
        }
    }

    const handleClearSelection = () => {
        setSelectedList([]);
        onUpdateSelectedList && onUpdateSelectedList([]);
    }

    const handleSelectAll = () => {
        //let models = disabledmodels ? filteredList.filter(model => !disabledmodels.find(item => item === model.ID)) : filteredList
        let models = disabledmodels ? list.filter(model => !disabledmodels.find(item => item === model.ID)) : list
        setSelectedList(models.map(model => model.ID))
        onUpdateSelectedList && onUpdateSelectedList(models.map(model => model.ID))
    }

    const OnAddTagToSelectedModels = async (tagID) => {
        await Axios.post(`/contacts/addtagmulti?idt=${tagID.ID}`, selectedList)
        forceReloadModels()
    };

    const OnRemoveTagFromSelectedModels = async tagID => {
        await Axios.post(`/contacts/removetagmulti?idt=${tagID.ID}`, selectedList)
        forceReloadModels()
    }

    if (inSideBar) {
        return (
            <>
                <ModelsWallMiniHeader
                    quickFilter={quickFilter}
                    onSetQuickFilter={onSetQuickFilter}
                    onAfterChangeFilters={resetScroll}
                    filtersType={filtersType}
                    tags={tags}
                />
                {list.length ?
                    <>
                        <ScrollbarContainer motherAgencyInList={motherAgencyInList} small={filtersType === "all-models"}>
                            <Scrollbar onScrollStop={handleScrollStop} scrollTop={scrollPosition} style={{ width: '100%', height: '100%', minHeight: 100 }}>
                                {list.map(item =>
                                    <WallLineItem
                                        isSelected={item.ID === selectedModel}
                                        key={item.ID}
                                        onClick={() => onSelectModel(item.ID, false)}
                                        text={modelFullName(item)}
                                        prefix={(quickFilter.sort === 'ID') ? item.ID : null}
                                        smallText={item.MAN || (item.R ? "represented" : "no mother agency")}
                                        showSmallText={motherAgencyInList && !isMobile}
                                    />
                                )}
                                <div className="empty-wall-line-item"></div>
                            </Scrollbar>
                            <div className="list-counter">{list.length} models</div>
                        </ScrollbarContainer>
                        {!isMobile &&
                            <UnderScrollbar>
                                <small>
                                    <Switch
                                        checkedChildren="show"
                                        onChange={() => {
                                            setRecalcScroll(true)
                                            setMotherAgencyInList(!motherAgencyInList)
                                        }}
                                        checked={motherAgencyInList}
                                        unCheckedChildren="hide"
                                    />
                                    &nbsp;mother agencies
                                </small>
                            </UnderScrollbar>
                        }
                    </>
                    :
                    <CenteredBlock fullpage={true}>
                        <EmptyState message="no models fitting your search" />
                    </CenteredBlock>
                }
            </>
        )
    };

    const renderModelsWallHeader = () => {
        return (
            <ModelsWallHeader
                defaultView={fixedView ? defaultView : style}
                filteredList={list}
                filtersType={filtersType}
                fixedView={fixedView}
                isDrawer={isDrawer}
                isLoading={isLoading}
                modelEventsInfo={modelEventsInfo}
                modelList={listNotFeaturesFiltered}
                multipleSelection={multipleSelection}
                multiSelection={multiSelection}
                nItems={list.length}
                onChangeMultiSelection={setMultiSelection}
                onChangeShowOnlyModelsWithBooks={onChangeShowOnlyModelsWithBooks}
                onChangeSort={changeListSort}
                onChangeSortFolder={changeListSortFolder}
                onChangeStyle={changeListStyle}
                onSetQuickFilter={onSetQuickFilter}
                onUpdateModelEvents={onUpdateModelEvents}
                quickFilter={quickFilter}
                scope={scope}
                searchType={searchType}
                showOnlyModelsWithBooks={showOnlyModelsWithBooks}
            />
        )
    };

    const renderWallFooter = () => {
        return multiSelection &&
            <ModelsWallFooter
                isDrawer={isDrawer}
                selectedList={selectedList}
                onAddTag={OnAddTagToSelectedModels}
                onRemoveTag={OnRemoveTagFromSelectedModels}
                leftMargin={leftMargin}
                fixedView={fixedView}
                onClearSelection={handleClearSelection}
                onSelectAll={handleSelectAll}
                multiSelection={multiSelection}
                addModelProps={addModelProps}
                models={list}
                isLoading={isLoading}
            />
    };

    if (filtersType === 'folders' && editFolder) {
        return (
            <FolderModelsWrapper
                models={list}
                isLoading={isLoading}
                onSelectModel={onSelectModel}
                onEditFolder={onEditFolder}
            />
        );
    }

    const renderModels = () => {
        const viewStyle = fixedView ? defaultView : style;
        switch (viewStyle) {
            case "table":
                return (
                    <ModelsTableWrapper
                        currentEvent={currentEvent}
                        currentPackage={currentPackage}
                        footer={breakpoints > 3 && renderWallFooter()}
                        isDrawer={isDrawer}
                        isLoading={isLoading}
                        isMobile={isMobile}
                        modelEventsInfo={modelEventsInfo}
                        models={list}
                        multipleSelection={multiSelection}
                        onSelectModel={onSelectModel}
                        packageGroup={packageGroup}
                        scope={scope}
                        selectedList={selectedList}
                        showModelDrawerOnClick={showModelDrawerOnClick}
                        toggleModelAddToSelectedList={toggleModelAddToSelectedList}
                        updateSelectedList={updateSelectedList}
                    />
                )
            default:
                return (
                    <ModelCardsWrapper
                        addModelProps={addModelProps}
                        connections={connections}
                        disabledmodels={disabledmodels}
                        displayConnections={displayConnections}
                        drawerMode={false}
                        footer={breakpoints > 3 && renderWallFooter()}
                        isDrawer={isDrawer}
                        isLoading={isLoading}
                        modelEventsInfo={modelEventsInfo}
                        models={list}
                        multipleSelection={multiSelection}
                        onSelectModel={onSelectModel}
                        scope={scope}
                        selectedList={selectedList}
                        showModelDrawerOnClick={showModelDrawerOnClick}
                        toggleModelAddToSelectedList={toggleModelAddToSelectedList}
                        updateSelectedList={updateSelectedList}
                    />
                )
        }
    }

    return (
        <WallContainer>
            {renderModelsWallHeader()}
            {renderModels()}
        </WallContainer>
    )
}

export default ModelsWall;
