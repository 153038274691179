import React from "react";
import PropTypes from 'prop-types';
import Slider from "react-slick";
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const OpenIconStyle = {
  fontSize: 30,
  cursor: 'pointer',
  color: StyleVariables.TextColor
};

const NextArrow = ({ className, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <NwIcon icon={light("chevron-right")} style={OpenIconStyle} />
    </div>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <NwIcon icon={light("chevron-left")} style={OpenIconStyle} />
    </div>
  );
}

const NWSlider = ({ slidesToShow, slidesToScroll, responsive = undefined, children, ...rest }) => {

  const settings = {
    className: "slider variable-width",
    infinite: false,
    slidesToShow,
    slidesToScroll,
    variableWidth: true,
    centerPadding: 30,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive
  };

  return (
    <Slider {...settings} {...rest}>
      {children}
    </Slider>
  );
};

// NWSlider.propTypes = {
//   slidesToShow: PropTypes.number.isRequired,
//   slidesToScroll: PropTypes.number.isRequired,
//   responsive: PropTypes.arrayOf(PropTypes.shape({
//     breakpoint: PropTypes.number
//   }))
// }

export default NWSlider;

