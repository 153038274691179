import React, { useMemo } from 'react';
import { Modal, Table } from 'antd';

const ConversionModal = ({ conversion, onClose }) => {
  const columns = useMemo(() => {
    let columns = [];
    if (conversion) {
      columns.push({
        title: 'Key',
        dataIndex: 'Key',
        width: '20%',
        editable: true
      });
      conversion.AvailableMetrics.split(',').map(metricKey => {
        columns.push({
          title: metricKey,
          dataIndex: metricKey,
          // width: '20%',
          editable: true,
          render: (text, record) => {
            const metric = record.Metrics.find(metric => metric.Metric === metricKey);
            return metric ? metric.Value : '';
          }
        });
        return null;
      });
    }
    return columns;
  }, [conversion]);

  const groupedConversionValues = useMemo(() => {
    const groupedConversionValues = [];
    if (conversion) {
      for (const conversionValue of conversion.ConversionValues) {
        if (!groupedConversionValues.find(group => group.Key === conversionValue.Key)) {
          groupedConversionValues.push({
            Key: conversionValue.Key,
            key: conversionValue.Key,
            Metrics: conversion.ConversionValues.filter(item => item.Key === conversionValue.Key)
          });
        }
      }
    };
    return groupedConversionValues;
  }, [conversion]);

  return (
    <Modal
      zIndex={1050}
      title={conversion.Name}
      visible={true}
      footer={null}
      onCancel={onClose}
    >
      <Table
        bordered
        dataSource={groupedConversionValues}
        columns={columns}
        rowClassName="editable-row"
        size='small'
      />
    </Modal>
  );
};

export default ConversionModal;