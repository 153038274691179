import React from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
// import { getMoment } from "Libs/NwMoment";

const Container = styled.div`
    margin: 4px 1em 4px 0;
    display: flex;
    align-items: center;

    span {
        font-size: .75rem;
        margin-right: .5em;
    }
`;

const AllDaySwitch = ({ checked, onChange }) => {
    return (
        <Container>
            {/* <span>ALL DAY</span> */}
            <Switch checked={checked} onChange={onChange} checkedChildren="ALL DAY" unCheckedChildren="SET TIME"  />
        </Container>
    )
};

export default AllDaySwitch;