import { useContext } from "react"
import { getTodayISO, getTodayEndOfTheDayISO, getLastYearISO, getNextYearISO, getStartCurrentISO, getEndCurrentISO, getStartLastISO, getEndLastISO } from "Libs/NwMoment";
import { getLastLog } from "Libs/NwLogs";
import { UserContext } from "Contexts/UserContext";

const useDataPlaceholder = () => {
    //This hook manage the default placeholder replacement, it returns the funcion to use for replacement.

    const { currentUser } = useContext(UserContext)

    const replace = (value) => {
        var newval = null;
        switch (value) {
            case "{today_today}":
                newval = [getTodayISO(), getTodayISO()];
                break;
            case "{tomorrow_tomorrow}":
                newval = [getTodayISO(1), getTodayISO(1)];
                break;
            case "{yesterday_yesterday}":
                newval = [getTodayISO(-1), getTodayISO(-1)];
                break;
            case "{currentweek}":
                newval = [getStartCurrentISO('week'), getEndCurrentISO('week')];
                break;
            case "{currentmonth}":
                newval = [getStartCurrentISO('month'), getEndCurrentISO('month')];
                break;
            case "{currentyear}":
                newval = [getStartCurrentISO('year'), getEndCurrentISO('year')];
                break;
            case "{lastweek}":
                newval = [getStartLastISO('week'), getEndLastISO('week')];
                break;
            case "{lastmonth}":
                newval = [getStartLastISO('month'), getEndLastISO('month')];
                break;
            case "{lastyear}":
                newval = [getStartLastISO('year'), getEndLastISO('year')];
                break;
            case "{last7days}":
                newval = [getTodayISO(-7), getTodayISO()];
                break;
            case "{last30days}":
                newval = [getTodayISO(-30), getTodayISO()];
                break;
            case "{last365days}":
                newval = [getLastYearISO(), getTodayEndOfTheDayISO()];
                break;
            case "{next7days}":
                newval = [getTodayISO(), getTodayISO(7)];
                break;
            case "{next30days}":
                newval = [getTodayISO(), getTodayISO(30)];
                break;
            case "{next365days}":
                newval = [getTodayISO(), getNextYearISO()];
                break;
            case "{today}":
                newval = getTodayISO();
                break;
            case "{tomorrow}":
                newval = getTodayISO(1);
                break;
            case "{yesterday}":
                newval = getTodayISO(-1);
                break;
            case "{amonthago}":
                newval = getTodayISO(-30);
                break;
            case "{aweekago}":
                newval = getTodayISO(-7);
                break;
            case "{ayearago}":
                newval = getLastYearISO();
                break;
            case "{me}":
                newval = currentUser.ID;
                break;
            case "{lastmodel}":
                newval = getLastLog("model");
                break;
            case "{lastclient}":
                newval = getLastLog("client");
                break;
            case "[]":
                newval = [];
                break;
            default:
                newval = value;
        }
        return newval;
    }

    return replace
}

export default useDataPlaceholder