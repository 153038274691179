import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwLinkButton } from 'Components/Gui/NwButton';
import CreateProjectDrawer from 'Components/CreateProject/CreateProjectDrawer';
import { usePreviewProject } from 'Hooks/Project/UseProject';
import { Loading } from 'Components/Gui/Loading';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import ProjectTitle from 'Components/Project/ProjectTitle';


const ProjectSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${(props) => props.$small ? "25rem" : "none"};
    border-bottom: ${(props) => props.$noBorder ? "none" : props.theme.DefaultBorder};
    padding: .5rem 0 1rem 0;
    margin: .25rem 0;

    .project-line-actions {
        text-align: right;
        font-size: 1.2rem;
        margin-left: 1rem;
        svg {
            cursor: pointer;
        }
    }
`;

const ProjectSelector = ({ small, noBorder, value, projectData, onChange, readOnly, showSecondLevel, searchOnly, drawerPlacement, noUnlink }) => {
    const [isShowProjectModal, setIsShowProjectModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const { data: project, isFetching } = usePreviewProject(!projectData ? value : null);
    
    useEffect(() => {
        if (projectData) {
            setSelectedProject(projectData)
        } else {
            if (project) {
                setSelectedProject(project)
            }
        }
    }, [project, projectData]);

    const onSelectProject = (val) => {
        onChange(val)
    }

    const onClearProject = () => {
        setSelectedProject(null)
        onChange(null)
    }

    const handleOpenProjectModal = () => {
        setIsShowProjectModal(true);
    }

    const handleCancel = () => {
        setIsShowProjectModal(false);
    }

    const checkIsLoading = () => {
        if (!projectData) {
            if (value && !project) {
                return true
            }
        }
        return false
    }

    const dropDownActions = () => {
        let actions = [
            { name: "", key: "change", tooltip: "change project", pinned: true, icon: light("pen"), action: (() => handleOpenProjectModal()) }
        ]
        if (!noUnlink) {
            actions.push({ name: "", key: "unlink", tooltip: "unlink project", pinned: true, icon: light("trash-alt"), action: (() => onClearProject()) })
        }
        return actions
    }

    return (
        checkIsLoading()
            ?
            <Loading textBlack small />
            :
            <>
                {selectedProject ?
                    <ProjectSelectorContainer $small={small} $noBorder={noBorder} alignItems="flex-start">
                        <ProjectTitle small project={selectedProject} />
                        {!readOnly &&
                            <div className='project-line-actions'>
                                <NwDropDownMenu
                                    actions={dropDownActions()}
                                />
                            </div>
                        }
                    </ProjectSelectorContainer>
                    :
                    (!readOnly &&
                    <NwLinkButton icon={light("ballot-check")} label="Select a project" onClick={handleOpenProjectModal}>Select a project</NwLinkButton>
                    )
                }
                {isShowProjectModal &&
                    <CreateProjectDrawer
                        drawerPlacement={drawerPlacement}
                        onCloseModal={handleCancel}
                        onChange={onSelectProject}
                        showSecondLevel={showSecondLevel}
                        searchMode={true}
                        searchOnly={searchOnly} />
                }
            </>
    )
};

export default ProjectSelector;