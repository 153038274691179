import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { List, notification } from 'antd';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import EmptyState from 'Components/Gui/EmptyState';
import { useWebsites } from "Hooks/Websites/UseWebsites";
import { findPath } from 'Libs/NwUtils';
import { NwButton } from 'Components/Gui/NwButton';

const MediaWebsites = ({ book, onPublishBook }) => {
    const [linkedSections, setLinkedSections] = useState([]);
    const { data: websiteData } = useWebsites();
    const formatLinkedSections = (book) => {
        const _linkedSections = [];
        if (websiteData && websiteData.length) {
            book.Divisions.map((item) => {
                _linkedSections.push({
                    websiteName: item.WebsiteName ? item.WebsiteName.toUpperCase() : 'no website name',
                    path: findPath(websiteData, item.WebsiteCode, item.DivisionCode, "", true),
                    role: item.Role,
                    websiteCode: item.WebsiteCode,
                    divisionCode: item.DivisionCode,
                    playlistID: book.ID,
                });
                return null;
            });
        }
        return _linkedSections;
    }
    useEffect(() => {
        if (book && websiteData) {
            const _linkedSections = formatLinkedSections(book);
            setLinkedSections(_linkedSections);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [book, websiteData])

    const renderContent = () => {
        if (!book.Divisions || !book.Divisions.length)
            return (
                <EmptyState
                    message="No websites"
                />
            );
        return (
            <List
                itemLayout="horizontal"
                dataSource={linkedSections}
                renderItem={item => (
                    <List.Item
                    // actions={[renderActions(item)]}
                    >
                        <List.Item.Meta
                            description={
                                <>
                                    <div style={{ fontSize: "12px" }}>{item.websiteName}</div>
                                    <div style={{ fontSize: "12px" }}>{item.path}</div>
                                    <div style={{ fontSize: "12px" }}>as {item.role}</div>
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        )
    }

    const handlePublishBook = () => {
        if (websiteData && websiteData.length > 0) {
            onPublishBook(book)
        } else {
            notification.warning({
                message: 'No Websites Configured',
                description: 'There are no websites configured in the management section. Sorry, this book can\'t be published',
                style: {zIndex: 1050}
            });
        }
    }

    return (
        <NwCard
            icon={light("cloud-arrow-up")}
            title="Websites"
            extra={<NwButton icon={light('pen')} ghost size="small" label="edit" onClick={handlePublishBook} />}
        >
            {renderContent()}
        </NwCard>
    )
};

export default MediaWebsites;