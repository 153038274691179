import React, { useState } from "react";
import { ObjectHistoryContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteTask, useDeleteTaskGroup, forceReloadTask, } from 'Hooks/Event/UseTask';
import EventDocuments from "Components/EventDocuments/EventDocuments";
import TaskDetails from 'Components/EventDrawer/TaskEvent/TaskDetails';
import TaskModelDetails from 'Components/EventDrawer/TaskEvent/TaskModelDetails';
import FlexContainer from 'Components/Gui/FlexContainer';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import TaskActions from "Components/EventDrawer/TaskEvent/TaskActions";
import AddModelsToNonBookingEvent from "../BookingEvent/AddModels/AddModelsToNonBookingEvent";
import styled from "styled-components";
import Axios from "axios";
import { taskHasDifferentDescriptions } from "./TaskUtils";

const Wrapper = styled.div`
    .todo-models-details-wrapper {
        padding-bottom: 3rem;
    }
`

const TaskContent = ({
    afterRemove,
    afterUpdate,
    handleSelectProject,
    isEdit,
    onTouchForm,
    projectId,
    setEditModelEvent,
    setIsEdit,
    taskID,
    taskData,
}) => {

    const { mutateAsync: onDeleteToDo } = useDeleteTask();
    const { mutateAsync: onDeleteToDoGroup } = useDeleteTaskGroup();
    const [showAddModelDrawer, setShowAddModelDrawer] = useState(false);

    const tasksRelatedEvents = taskData.RelatedEvents ? [taskData, ...taskData.RelatedEvents] : [taskData]

    const hasDifferentDescriptions = taskHasDifferentDescriptions(taskData);

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteToDo({ ID: eventId, todoId: taskID });
            if (taskData.RelatedEvents) {
                forceReloadTask(taskID);
            }
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleRemoveEventGroup = async groupGuid => {
        try {
            await onDeleteToDoGroup({ GroupGUID: groupGuid });
        } catch (error) {
            console.log('ant : Remove Event Group Error => ', error);
        }
    };

    const handleUnlinkEvent = async eventId => {
        try {
            await Axios.put(`/todos/UnLink/${taskID}`)
            forceReloadTask(taskID)
            if (taskData.RelatedEvents && taskData.RelatedEvents.length > 0) {
                taskData.RelatedEvents.forEach(e => forceReloadTask(e.ID));
            };
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    }

    const getEventCommentsDocumentsProps = (extra, showOnlyContent, showOnlyActions) => {
        return {
            event: { ...taskData, elType: 'ToDo', uniqID: `ToDo-${taskData.ID}` },
            api: 'ToDoID',
            type: 'ToDo',
            contactId: !taskData.GroupGuid && taskData.ModelID,
            drawerMode: true,
            fetchEvent: () => { forceReloadTask(taskData.ID) },
            extra: extra,
            showOnlyContent: showOnlyContent,
            showOnlyActions: showOnlyActions,
            onTouchForm: onTouchForm
        }
    }

    const getEventTitleContentProps = () => {
        const singleEvent = (!taskData.RelatedEvents || taskData.RelatedEvents.length === 0);
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    if (!singleEvent) {
                        setEditModelEvent(taskData)
                    }
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(taskData.ID)
                    if (taskData.ID === taskID) {
                        afterRemove(true)
                    } else {
                        afterUpdate()
                    }
                }),
                onDeleteGroup: (async () => {
                    await handleRemoveEventGroup(taskData.GroupGuid)
                    afterRemove(true);
                }),
                onUnlink: (() => handleUnlinkEvent(taskData.ID)),
                onProjectUnlink: (() => handleSelectProject()),
                onAcknowledgmentUpdate: (() => { }),
                onCancelUpdate: (() => { }),
            },
            eventProperties: {
                eventType: "ToDo",
                isSingle: singleEvent,
                hasExport: true
            },
            event: taskData
        }
        return props;
    }

    return (
        <>
            <Wrapper>
                <EventTitle {...getEventTitleContentProps()} />
                <TaskDetails taskData={taskData} />
                {taskData.GroupGuid && taskData.GroupDocuments.length > 0 &&
                    <FlexContainer pd='0 20px' column alignItems='flex-start'>
                        <EventDocuments showDocumentsByDefault event={taskData} type={'eventGroup'} updateEvent={() => forceReloadTask(taskData.ID)} />
                    </FlexContainer>
                }
                {!taskData.GroupGuid && <EventCommentsDocuments {...getEventCommentsDocumentsProps(null, true)} />}
                <ObjectHistoryContainer>
                    <ObjectHistory asLine object={taskData} />
                </ObjectHistoryContainer>
                {!taskData.GroupGuid ?
                    <EventCommentsDocuments {
                        ...getEventCommentsDocumentsProps(
                            <TaskActions
                                taskData={taskData}
                                onAddModels={() => setShowAddModelDrawer(true)}
                            />,
                            false,
                            true
                        )}
                    />
                    :
                    <TaskActions
                        taskData={taskData}
                        onAddModels={() => setShowAddModelDrawer(true)}
                    />
                }
                <div className="travel-models-details-wrapper">
                    {(tasksRelatedEvents.length > 1)
                        ?
                        tasksRelatedEvents.map(event =>
                            <TaskModelDetails
                                key={`event-${event.ID}`}
                                event={event}
                                afterUpdate={afterUpdate}
                                afterRemove={afterRemove}
                                onEditModelEvent={() => {
                                    setEditModelEvent(event);
                                    setIsEdit(!isEdit);
                                }}
                                onUpdateTasks={() => {
                                    forceReloadTask(taskData.ID);
                                    tasksRelatedEvents.forEach(e => forceReloadTask(e.ID));
                                }}
                                openedEventID={taskData.ID}
                                onTouchForm={onTouchForm}
                                differentDescriptions={hasDifferentDescriptions}
                            />
                        )
                        :
                        <></>
                    }
                </div>
            </Wrapper>
            {showAddModelDrawer &&
                <AddModelsToNonBookingEvent
                    event={taskData}
                    projectId={projectId || (taskData.Project && taskData.Project.ID)}
                    onUpdateEvent={() => {
                        forceReloadTask(taskData.ID)
                        afterUpdate()
                    }}
                    eventType='ToDo'
                    onClose={() => setShowAddModelDrawer(false)}
                />
            }
        </>
    )


    // const EventTitleContent = () => {
    //     const singleEvent = (!toDosData.RelatedEvents || toDosData.RelatedEvents.length === 0);
    //     const props = {
    //         actions: {
    //             onEditGeneral: (() => {
    //                 setIsEdit(!isEdit)
    //             }),
    //             onEdit: (() => {
    //                 if (!singleEvent) {
    //                     setEditModelEvent(toDosData)
    //                 }
    //                 setIsEdit(!isEdit)
    //             }),
    //             onDelete: (async () => {
    //                 await handleRemoveEvent(toDosData.ID)
    //                 if (toDosData.ID === toDoID) {
    //                     afterRemove(true)
    //                 } else {
    //                     afterUpdate()
    //                 }
    //             }),
    //             onDeleteGroup: (async () => {
    //                 await handleRemoveEventGroup(toDosData.GroupGuid)
    //                 afterUpdate()
    //             }),
    //             onUnlink: (() => handleUnlinkEvent(toDosData.ID)),
    //             onProjectUnlink: (() => handleSelectProject()),
    //             onStatusUpdate: (() => {
    //                 forceReloadTask(toDosData.ID);
    //                 afterUpdate();
    //             })
    //         },
    //         eventProperties: {
    //             eventType: "ToDo",
    //             isSingle: singleEvent,
    //             hasExport: true
    //         },
    //         event: toDosData
    //     }
    //     return <EventTitle {...props} />
    // }

    // const RelatedEventsDetails = () => {
    //     return (
    //         <div>
    //             {toDosRelatedEvents.length > 1 && toDosRelatedEvents.map(event => {
    //                 return (
    //                     <ToDoModelDetails
    //                         key={`event-${event.ID}`}
    //                         isEdit={isEdit}
    //                         todosData={toDosData}
    //                         afterUpdate={afterUpdate}
    //                         afterRemove={afterRemove}
    //                         onEditModelEvent={() => {
    //                             setEditModelEvent(event);
    //                             setIsEdit(!isEdit);
    //                         }}
    //                         event={event}
    //                         onTouchForm={onTouchForm} />
    //                 )
    //             })}
    //         </div>
    //     )
    // };

    // const EventDetails = () => {
    //     const actions = [
    //         { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => setIsEdit(!isEdit)) },
    //         {
    //             name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (async () => {
    //                 await handleRemoveEvent(toDosData.ID);
    //                 afterRemove(true);
    //             })
    //         }
    //     ];
    //     if (toDosData.Project) {
    //         actions.push({ name: 'unlink from project', key: 'unlink-project', icon: light('trash-alt'), action: () => handleClearProject() });
    //     }
    //     return (
    //         <>
    //             <EventTitleContent />
    //             <ToDoDetails todosData={toDosData} />
    //             {toDosData.GroupGuid && toDosData.GroupDocuments.length > 0 &&
    //                 <FlexContainer pd='0 20px' column alignItems='flex-start'>
    //                     <EventDocuments
    //                         event={toDosData}
    //                         type={'eventGroup'}
    //                         updateEvent={() => forceReloadTask(toDosData.ID)} />
    //                 </FlexContainer>
    //             }
    //             {!toDosData.GroupGuid && <EventCommentsDocumentsContent showOnlyContent />}
    //             <ObjectHistoryContainer>
    //                 <ObjectHistory asLine object={toDosData} onIconClick={() => { }} />
    //             </ObjectHistoryContainer>
    //             {!toDosData.GroupGuid
    //                 ?
    //                 <EventCommentsDocumentsContent
    //                     extra={
    //                         <ToDoActions
    //                             todosData={toDosData}
    //                             onAddModels={() => setShowAddModelDrawer(true)}
    //                         />
    //                     }
    //                     showOnlyActions
    //                 />
    //                 :
    //                 <ToDoActions
    //                     todosData={toDosData}
    //                     onAddModels={() => setShowAddModelDrawer(true)}
    //                 />
    //             }
    //             {showAddModelDrawer &&
    //                 <AddModelsToNonBookingEvent
    //                     event={toDosData}
    //                     projectId={projectId || (toDosData.Project && toDosData.Project.ID)}
    //                     onUpdateEvent={() => {
    //                         forceReloadTask(toDosData.ID)
    //                         afterUpdate()
    //                     }}
    //                     eventType='ToDo'
    //                     onClose={() => setShowAddModelDrawer(false)} />
    //             }
    //             <RelatedEventsDetails />
    //         </>
    //     )
    // };

    // const EventCommentsDocumentsContent = ({ extra, showOnlyContent, showOnlyActions }) => (
    //     <EventCommentsDocuments
    //         event={toDosData}
    //         api='ToDoID'
    //         type='ToDo'
    //         contactId={!toDosData.GroupGuid && toDosData.ContactID}
    //         drawerMode
    //         fetchEvent={() => {
    //             forceReloadTask(toDosData.ID)
    //         }}
    //         extra={extra}
    //         showOnlyContent={showOnlyContent}
    //         showOnlyActions={showOnlyActions}
    //         disableTransaction
    //         onTouchForm={onTouchForm}
    //     />
    // )


};

export default TaskContent;
