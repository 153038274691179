
import React, { useContext, useState } from "react";
import styled from 'styled-components';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from "Components/Gui/NwIcon";
import { showDateNoTime, showLogNoTime } from 'Libs/NwMoment';
import { Popover } from "antd";
import { ListContext } from 'Contexts/ListContext';
import { Loading } from "Components/Gui/Loading";
import Axios from "axios";

const StyledIconContainer = styled.div`
    color: ${props => props.theme.DangerColor};
    margin: 0 .5rem;
    font-size: ${props => props.big ? '1.75rem' : '1rem'};
`

const AlertPopoverContentContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 30rem;

    .alert-icon-container {
        color: ${props => props.theme.DangerColor};
        font-size: 4rem;
        margin: 1rem 1.5rem;
    }
    .alert-text-container {
        margin: .5rem 1.5rem .5rem 0;

        .alert-message {
            font-size: 1rem;
            font-weight: 500;
            margin: 0 0 .5rem 0;
            line-height: 1.1em;
        }
        .alert-info {
            font-size: .9rem;
            margin: 0;
        }
    }
    
`
const ContactAlertPopover = ({ contact, big }) => {

    const { GetUserName } = useContext(ListContext);
    const [isFetching, setIsFetching] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertInfo, setAlertInfo] = useState('');

    const checkContent = (visible) => {
        if (visible) {
            if (!contact.ALM && !contact.alertMessage) {
                fetchAlertDetails()
            } else {
                setAlertMessage(contact.ALM || contact.alertMessage)
                setAlertInfo(`${GetUserName(contact.alertUser || contact.ALU)} ${showDateNoTime(contact.alertDate || contact.ALD)}`)
                setIsFetching(false)
            }
        }
    }

    const fetchAlertDetails = async () => {
        const idContact = contact.id || contact.ID
        if (idContact) {
            const alertData = await Axios.get(`/contacts/getalert/${idContact}`)
            if (alertData.AlertMessage) {
                setAlertMessage(alertData.AlertMessage)
                setAlertInfo(`${GetUserName(alertData.AlertUser)} ${showLogNoTime(alertData.AlertCreationDate)}`)
                setIsFetching(false)
            }
        } else {
            setAlertMessage("I can't load the alert details.")
            setAlertInfo("")
            setIsFetching(false)
        }
    }

    return (
        <Popover
            placement="bottomLeft"
            onOpenChange={checkContent}
            content={<AlertPopoverContentContainer>
                {isFetching
                    ?
                    <p>Loading alert details...</p>
                    :
                    <>
                        <div className="alert-icon-container">
                            <NwIcon icon={light('exclamation-circle')} />
                        </div>
                        <div className="alert-text-container">
                            <p className="alert-message">{alertMessage}</p>
                            <p className="alert-info">{alertInfo}</p>
                        </div>
                    </>
                }

            </AlertPopoverContentContainer>}
        >
            <StyledIconContainer big={big} className="ant-dropdown-link alert-icon-container" onClick={e => e.preventDefault()}>
                <NwIcon icon={regular('exclamation-circle')} />
            </StyledIconContainer>
        </Popover>
    )
}

export default ContactAlertPopover