import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ls from "local-storage";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import SendAgendaDrawer from 'Components/SendAgendaDrawer/SendAgendaDrawer';
import NwIcon from 'Components/Gui/NwIcon';
import AgendaContainer from 'Components/Schedule/Agenda/AgendaContainer';
import FlexContainer from 'Components/Gui/FlexContainer';
import AddEventsButtons from 'Components/AddEventsButtons/AddEventsButtons';
import { useContact } from 'Hooks/Contact/UseContact';
import { useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import CalendarFooter from 'Components/Schedule/Calendar/CalendarFooter';
import CalendarCardWrapper from 'Components/Schedule/Calendar/CalendarCardUI';
import { getCalendarDefaultDate, getWeekRangeDates } from 'Components/Schedule/ScheduleUtils';
import ModelCalendarNoTimeGrid from './Calendar/ModelCalendar/ModelCalendarNoTimeGrid';
import ModelCalendar from 'Components/Schedule/Calendar/ModelCalendar/ModelCalendar';
import { NwButton } from 'Components/Gui/NwButton';
import { getNow, toISODate } from 'Libs/NwMoment';
import { Loading } from 'Components/Gui/Loading';
import MobileAgendaContainer from './Agenda/MobileAgendaContainer';
import { AgendaProvider } from './Agenda/AgendaContext';
import AgendaFooter from './Agenda/AgendaFooter';

const LockContainer = styled(FlexContainer)`
    min-height: 320px;
    padding: 16px 0;
    svg {
        font-size: ${props => props.$previewMode ? '80px' : '120px'};
    }
`;

const ScheduleMobileWrapper = styled.div`
    min-height: 100vh;
    padding: 2px 16px;
    background: white;
`;

const ContactScheduleCard = ({
    bookingSection,
    calendarStyle,
    contactId,
    filters,
    hasSidebar,
    isMobileDrawer,
    onClearContactID,
    onScheduleRangeChange,
    previewMode,
    scheduleRange,
    setFilters,
    setTimeGrid,
    timeGrid,
    type,
}) => {


    const history = useHistory()
    const [sendAgenda, setSendAgenda] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [isEventDrawer, setIsEventDrawer] = useState(false)
    const [eventType, setEventType] = useState()

    //for contact schedule, we keep filters in state, not in local storage
    const [params, setParams] = useState({
        inclJobs: true,
        inclCastings: true,
        checkConflict: false,
        inclTravels: true,
        inclAccommodations: true,
        inclExternalJobs: true,
        inclFollowUps: true,
        inclMeetings: true,
        inclNotices: true,
        General: false,
        Warning: false,
        idmodels: [contactId]
    })

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1;

    const { data: currentContact } = useContact(contactId);
    const viewModelCalendar = useUserPermission('ViewModelCalendar', currentContact && currentContact.Users.map(user => user.UserID));
    const viewCalendar = type === 'model' ? viewModelCalendar : true;

    useEffect(() => {
        if (previewMode) {
            setFilters({
                filterMine: 'all',
                filterAcknowledgement: 'all',
                hideCancelled: false,
                hideBilling: false
            });
        }
    }, []);

    useEffect(() => {
        setParams({
            ...params,
            idmodels: [contactId]
        })
    }, [contactId])

    useEffect(() => {
        const setEvent = async () => {
            await setSelectedEvent(ls.get('selectedEvent'));
            ls.remove('selectedEvent');
        }
        if (ls.get('selectedEvent')) {
            setEvent();
        }
    }, []);

    const handleCreateEvent = type => {
        setEventType(type);
        setIsEventDrawer(true);
    }

    const onOpenSendAgendaDrawer = () => {
        setSendAgenda(true)
    }

    const onCloseSendAgendaDrawer = () => {
        setReload(true)
        setSendAgenda(false)
    }

    const handleReload = () => {
        setReload(true)
    }

    const handleReloadComplete = () => {
        setReload(false)
    }

    const handleCloseEventDrawer = () => {
        setSelectedEvent(null)
        setReload(true)
    }

    const handleCloseEventDrawerNoReload = () => {
        setSelectedEvent(null)
    }

    const goToModelSchedule = () => {
        if (scheduleRange && scheduleRange.start) {
            const startIsoNoTime = toISODate(scheduleRange.start)
            if (startIsoNoTime) {
                const weekRangeDates = getWeekRangeDates(scheduleRange.start)
                ls.set('nw_config_booking_schedule_start_range', toISODate(weekRangeDates.start));
                ls.set('nw_config_booking_schedule_end_range', toISODate(weekRangeDates.end));
                history.push(`/schedule/${currentContact.ID}`);
            }
        }
    };

    const getCardMinHeight = () => {
        if (bookingSection) {
            return 'calc( 100vh - 10rem)'
        } else {
            if (calendarStyle !== 'agenda') {
                return 'calc(100vh - 20rem)';
            } else {
                if (type === 'model') {
                    return 580
                }
                return 520
            }
        }
    }

    const handleSelectEvent = useCallback((eventdata) => {
        setSelectedEvent(eventdata)
    }, [])

    const [forceReload, setForceReload] = useState(false)

    const handleUpdateFromEventDrawer = () => {
        setForceReload(true)
    }

    const afterReload = useCallback(() => {
        setForceReload(false)
    }, [])

    const handleClearContactId = useCallback(() => {
        onClearContactID()
    }, [])

    const AgendaContextValues = useMemo(() => ({
        afterReload,
        bookingSection,
        contactId,
        forceReload,
        isMobileDrawer,
        onClearContactID: handleClearContactId,
        onScheduleRangeChange,
        onSelectEvent: handleSelectEvent,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    }), [
        afterReload,
        bookingSection,
        contactId,
        forceReload,
        handleClearContactId,
        handleSelectEvent,
        isMobileDrawer,
        onScheduleRangeChange,
        params,
        previewMode,
        scheduleRange,
        selectedEvent,
        type,
    ]);

    const calendarDefaultDate = scheduleRange ? getCalendarDefaultDate(scheduleRange) : getNow()
    const isAgenda = calendarStyle === 'agenda';

    return (
        <>
            <CalendarCardWrapper
                hasSidebar={hasSidebar}
                bookingSection={bookingSection}
                className={(calendarStyle !== "agenda") ? `chart-${calendarStyle}` : 'agenda'}
                viewCalendar={viewCalendar}
            >
                {isMobile
                    ?
                    <ScheduleMobileWrapper>
                        {scheduleRange
                            ?
                            <AgendaProvider values={AgendaContextValues}>
                                <MobileAgendaContainer />
                            </AgendaProvider>
                            :
                            <Loading textBlack small paddingTop="2rem" />
                        }
                    </ScheduleMobileWrapper>
                    :
                    <NwCard
                        title='Calendar'
                        icon={light('calendar')}
                        footer={
                            (!bookingSection && !isAgenda)
                                ?
                                <CalendarFooter
                                    fixedFooter={bookingSection}
                                    type={type}
                                    previewMode={previewMode}
                                    viewCalendar={viewCalendar}
                                    filters={filters}
                                    onSetFilters={setFilters}
                                    onOpenSendAgendaDrawer={onOpenSendAgendaDrawer}
                                />
                                :
                                null
                        }
                        extra={type === 'model' && !previewMode && viewCalendar &&
                            <FlexContainer>
                                <AddEventsButtons onCreateEvent={handleCreateEvent} />
                            </FlexContainer>
                        }
                        titleAction={(viewCalendar && !bookingSection) &&
                            <NwButton
                                minimal
                                icon={light('expand-wide')}
                                solid
                                size="small"
                                onClick={goToModelSchedule} />
                        }
                        minheight={getCardMinHeight()}
                        padding="reduced">
                        {viewCalendar
                            ?
                            <>
                                {!isAgenda
                                    ?
                                    (timeGrid
                                        ?
                                        <ModelCalendar
                                            afterReload={handleReloadComplete}
                                            bookingSection={bookingSection}
                                            calendarStyle={calendarStyle}
                                            contactId={contactId}
                                            filters={filters}
                                            onClearContactID={onClearContactID}
                                            onReload={handleReload}
                                            onScheduleRangeChange={onScheduleRangeChange}
                                            params={params}
                                            previewMode={previewMode}
                                            reload={reload}
                                            scheduleRange={scheduleRange}
                                            setTimeGrid={setTimeGrid}
                                        />
                                        :
                                        <ModelCalendarNoTimeGrid
                                            afterReload={handleReloadComplete}
                                            bookingSection={bookingSection}
                                            calendarStyle={calendarStyle}
                                            contactId={contactId}
                                            filters={filters}
                                            onClearContactID={onClearContactID}
                                            onReload={handleReload}
                                            onScheduleRangeChange={onScheduleRangeChange}
                                            params={params}
                                            previewMode={previewMode}
                                            reload={reload}
                                            scheduleRange={scheduleRange}
                                            setTimeGrid={setTimeGrid}
                                        />
                                    )
                                    :
                                    (scheduleRange
                                        ?
                                        <AgendaProvider values={AgendaContextValues}>
                                            <AgendaContainer />
                                            <AgendaFooter
                                                onOpenSendAgendaDrawer={onOpenSendAgendaDrawer}
                                            />
                                        </AgendaProvider>
                                        :
                                        <Loading />
                                    )
                                }
                            </>
                            :
                            <LockContainer $previewMode={previewMode} justifyContent='center' alignItems='center'>
                                <NwIcon icon={light('lock-alt')} />
                            </LockContainer>
                        }
                        {(bookingSection && !isAgenda) &&
                            <CalendarFooter
                                fixedFooter={bookingSection}
                                type={type}
                                previewMode={previewMode}
                                viewCalendar={viewCalendar}
                                filters={filters}
                                onSetFilters={setFilters}
                                onOpenSendAgendaDrawer={onOpenSendAgendaDrawer}
                            />
                        }
                    </NwCard>
                }
            </CalendarCardWrapper>
            {sendAgenda &&
                <SendAgendaDrawer
                    contactID={contactId}
                    scheduleRange={scheduleRange}
                    onClose={onCloseSendAgendaDrawer}
                />
            }
            {selectedEvent &&
                <EventDrawer
                    eventType={selectedEvent.TypeName}
                    eventID={selectedEvent.ID}
                    eventObject={selectedEvent}
                    dateSelected={calendarDefaultDate}
                    modelId={type === 'model' && contactId}
                    onClose={handleCloseEventDrawerNoReload}
                    afterUpdate={handleUpdateFromEventDrawer}
                    afterRemove={handleCloseEventDrawer}
                />
            }
            {isEventDrawer &&
                <EventDrawer
                    eventType={eventType.type}
                    show={true}
                    modelId={contactId}
                    eventInfo={eventType.eventInfo}
                    viewMode='create'
                    allDay={true}
                    dateSelected={getNow()}
                    onClose={() => {
                        setIsEventDrawer(false);
                        setEventType();
                    }}
                    afterUpdate={() => {
                        setReload(true)
                    }}
                    afterRemove={() => {
                        setIsEventDrawer(false);
                        setReload(true)
                    }} />
            }
        </>
    );
}

export default ContactScheduleCard;
