import React from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import NwDrawer from "Components/Gui/NwDrawer";
import { NWBookerSelector } from 'Components/Gui/NWForm/NwFormItems';
import Axios from 'axios';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import { forceReloadEvent } from 'Hooks/Event/UseEvent';

const ProjectChangeBookerDrawer = ({ eventID, project, onClose }) => {
    const initialValues = {
        BookerID: project ? project.UserCreated : "",
    }

    const saveChanges = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await Axios.put(`/projects/${project.ID}`, [{
                name: "UserCreated",
                value: values.BookerID.toString(),
                changed: true,
            }]);
            forceReloadProject(project.ID);
            if (eventID) {
                forceReloadEvent(eventID);
            }
        } catch (e) {
            console.log("Error in assigning booker", e);
        } finally {
            setSubmitting(false);
            onClose();
        }
    }
    return (
        <NwDrawer
            onClose={onClose}
            title="Change Booker"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveChanges(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    BookerID: Yup.number().required("Booker is required")
                })}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWBookerSelector}
                            label="Booker"
                            name="BookerID"
                            disableClosable
                            required
                            value={values.BookerID}
                            onlyActive //boolean - only with an active licence
                            onlyInDepartments={[project.DepartmentCreated]} //array of allowed department IDs
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default ProjectChangeBookerDrawer;