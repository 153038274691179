import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import styled from 'styled-components';

import { Loading } from 'Components/Gui/Loading';
import { ObjectHistoryContainer, EventContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import EventDrawerHeader from 'Components/EventDrawer/EventDrawerUi/EventDrawerHeader';
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import NwDrawer from 'Components/Gui/NwDrawer';
import {
    useScoutingAccommodation,
    useDeleteScoutingAccommodation,
    forceReloadScoutingAccommodation
} from 'Hooks/Event/UseScoutingAccommodation';
import ScoutingAccommodationForm from 'Components/EventDrawer/ScoutingAccommodationEvent/ScoutingAccommodationForm';
import ScoutingAccommodationDetails from 'Components/EventDrawer/ScoutingAccommodationEvent/ScoutingAccommodationDetails';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import FlexContainer from 'Components/Gui/FlexContainer';
import { getEventLocationIframeUrl, getEventDirectionUrl } from 'Libs/NwGoogleMap';
import { ListContext } from "Contexts/ListContext";

const LocationUrl = styled.a`
    color: ${props => props.theme.PrimaryColor};
    text-decoration: underline;

    &:hover {
        color: ${props => props.theme.PrimaryColor};
        text-decoration: underline;
    }
`;

const ScoutingAccommodationEvent = props => {
    const {
        ID,
        viewMode,
        travelPlanId,
        afterUpdate,
        afterRemove,
        plainMode,
        dateSelected,
        allDay,
        onClose,
        onTouchForm
    } = props;
    const [scoutingAccommodationID, setScoutingAccommodationID] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const { data: scoutingAccommodationData, status } = useScoutingAccommodation(scoutingAccommodationID);
    const { mutateAsync: onDeleteScoutingAccommodation } = useDeleteScoutingAccommodation();
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);
    const { cachedList } = useContext(ListContext);

    useEffect(() => {
        setScoutingAccommodationID(ID);
    }, [ID]);

    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteScoutingAccommodation({ ID: eventId, scoutingAccommodationId: scoutingAccommodationID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const HeaderContent = () => (
        scoutingAccommodationData
            ?
            plainMode
                ?
                <div />
                :
                <EventDrawerHeader
                    type={120}
                    typeName="scoutingAccommodation"
                    data={scoutingAccommodationData}
                />
            :
            <div className="ant-drawer-header ant-drawer-header-sticky ">
                <div className="ant-drawer-title">Add New Scouting Accommodation</div>
            </div>
    )

    const EventTitleContent = () => {
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(scoutingAccommodationData.ID)
                    if (scoutingAccommodationData.ID === scoutingAccommodationID) {
                        afterRemove(true)
                    } else {
                        afterUpdate()
                    }
                })
            },
            eventProperties: {
                eventType: "ScoutingAccommodation",
                isSingle: true
            },
            event: scoutingAccommodationData
        }
        return <EventTitle {...props} />
    };

    const EventDetailsContent = () => (
        <>
            <EventTitleContent />
            <ScoutingAccommodationDetails
                type={'ScoutingAccommodation'}
                scoutingAccommodationData={scoutingAccommodationData} />
            <ObjectHistoryContainer>
                <ObjectHistory asLine object={scoutingAccommodationData} />
            </ObjectHistoryContainer>
        </>
    )

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const ScoutingAccommodationFormContent = () => (
        <ScoutingAccommodationForm
            plainMode={plainMode}
            scoutingAccommodationData={editModelEvent ? editModelEvent : scoutingAccommodationData}
            editModelEvent={editModelEvent ? true : false}
            viewMode={viewMode}
            dateSelected={dateSelected}
            allDay={allDay}
            travelPlanId={travelPlanId}
            afterUpdate={afterUpdate}
            onTouchForm={touchForm => {
                if (scoutingAccommodationData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            }}
            onCancel={() => {
                handleClearEditEvent();
                handleClearTouchForm();
                if (scoutingAccommodationData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            }}
            onUpdateScoutingAccommodationData={scoutingAccommodationId => {
                if (scoutingAccommodationId) {
                    setScoutingAccommodationID(scoutingAccommodationId)
                } else {
                    forceReloadScoutingAccommodation(scoutingAccommodationID)
                }
                handleClearTouchForm();
                handleClearEditEvent();
            }} />
    );

    const Content = () => (
        plainMode
            ?
            <>
                {scoutingAccommodationData && <EventDetailsContent />}
                {isEdit &&
                    <NwDrawer
                        padding="0px"
                        width={740}
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title='Edit Scouting Accommodation'
                        onClose={() => {
                            handleClearEditEvent();
                            setIsEdit(false);
                            handleClearTouchForm();
                        }}
                    >
                        <EventContainer>
                            <ScoutingAccommodationFormContent />
                        </EventContainer>
                    </NwDrawer>
                }
            </>
            :
            <>
                {!isEdit && scoutingAccommodationData ?
                    <div className="event-content-wrapper">
                        <EventDetailsContent />
                    </div>
                    :
                    <div className="event-content-wrapper">
                        <ScoutingAccommodationFormContent />
                    </div>
                }
            </>
    )

    if ((!scoutingAccommodationData && scoutingAccommodationID) || status === 'Loading') {
        return <Loading textBlack />
    } else {
        return plainMode ?
            <EventContainer>
                {scoutingAccommodationData &&
                    <Row gutter={32}>
                        <Col span={14}>
                            <Content />
                            <div dangerouslySetInnerHTML={{ __html: getEventLocationIframeUrl(scoutingAccommodationData, cachedList.countries.items) }} />
                            <FlexContainer mg='8px 0 0'>
                                <LocationUrl href={getEventDirectionUrl(scoutingAccommodationData, cachedList.countries.items)} target='_blank'>
                                    {getEventDirectionUrl(scoutingAccommodationData, cachedList.countries.items)}
                                </LocationUrl>
                            </FlexContainer>
                        </Col>
                    </Row>
                }
            </EventContainer>
            : <EventContainer>
                <HeaderContent />
                <Content />
            </EventContainer>
    }
};

export default ScoutingAccommodationEvent;
