import React, { useState } from "react";
import { Formik, Field } from "formik";
// import * as Yup from "yup";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWDatePicker, NWPaymentStatusSelector, NWSwitch } from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { useUpdatePaymentStatus } from "Hooks/Invoices/UseInvoices";
import { getMoment, getNow } from "Libs/NwMoment";

const EditPaymentStatusDrawer = ({ onClose, invoiceData }) => {
    const [touchForm, setTouchForm] = useState(false);
    const [asPaid, setAsPaid] = useState(invoiceData && invoiceData.PaymentStatusName === "Paid" ? true : false);
    const { mutateAsync: onUpdatePaymentStatus } = useUpdatePaymentStatus();

    //disabled option for updating rows for the moment. if Model or Agency, rows will not be updated
    const typeForRowsUpdating = ["Model"]; //, "Agency"
    const showRowsUpdatingOption = invoiceData.TypeName && typeForRowsUpdating.includes(invoiceData.TypeName);
    console.log("invoiceData", invoiceData);
    console.log("showRowsUpdatingOption", showRowsUpdatingOption);

    const initialValues = {
        PaymentStatus: invoiceData.PaymentStatusName ? invoiceData.PaymentStatusName : "NotPaid",
        PaymentDate: invoiceData.PaymentDate ? getMoment(invoiceData.PaymentDate) : asPaid ? getNow() : null,
        UpdateRows: false
    }

    const savePaymentStatus = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await onUpdatePaymentStatus({
                data: {
                    InvoiceID: invoiceData.ID,
                    PaymentStatus: values.PaymentStatus,
                    PaymentDate: values.PaymentDate ? values.PaymentDate.format("YYYY-MM-DD") : null,
                    UpdateRows: showRowsUpdatingOption ? false : true
                    //UpdateRows: showRowsUpdatingOption ? Boolean(values.UpdateRows) : true
                }
            });
            onClose();
        } catch (e) {
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Change Payment Status"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    savePaymentStatus(values, setSubmitting);
                }}
            >
                {({ isSubmitting, setFieldValue, values, handleSubmit, }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                    >
                        <Field
                            component={NWPaymentStatusSelector}
                            label="Payment Status"
                            name="PaymentStatus"
                            value={values.PaymentStatus}
                            onAfterChange={(value) => {
                                setAsPaid(value === "Paid");
                                if (value === "Paid") {
                                    if (!values.PaymentDate) {
                                        setFieldValue("PaymentDate", getNow());
                                    }
                                } else {
                                    setFieldValue("PaymentDate", null);
                                }
                            }}
                        />
                        <Field
                            component={NWDatePicker}
                            format="MMM DD, YYYY"
                            label="Payment Date"
                            name="PaymentDate"
                            value={values.PaymentDate}
                            disabled={!asPaid}
                        />
                        <br/>
                        {/* {showRowsUpdatingOption &&
                            <Field
                                component={NWSwitch}
                                label="Update payment status on all statement lines in this invoice"
                                name="UpdateRows"
                                defaultChecked={initialValues.UpdateRows ? true : false}
                                checked={Boolean(values.UpdateRows)}
                            />
                        } */}
                        <br /><br />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default EditPaymentStatusDrawer;