export const PLACEHOLDER_LABELS = [
  { name: "{today}", value: "Today" },
  { name: "{tomorrow}", value: "Tomorrow" },
  { name: "{yesterday}", value: "Yesterday" },
  { name: "{aweekago}", value: "A week ago" },
  { name: "{amonthago}", value: "A month ago" },
  { name: "{ayearago}", value: "A year ago" },
  { name: "{today_today}", value: "Today" },
  { name: "{tomorrow_tomorrow}", value: "Tomorrow" },
  { name: "{yesterday_yesterday}", value: "Yesterday" },
  { name: "{currentweek}", value: "Current week" },
  { name: "{currentmonth}", value: "Current month" },
  { name: "{currentyear}", value: "Current Year" },
  { name: "{lastweek}", value: "Last Week" },
  { name: "{lastmonth}", value: "Last Month" },
  { name: "{lastyear}", value: "Last Year" },
  { name: "{last7days}", value: "Last 7 days" },
  { name: "{last30days}", value: "Last 30 days" },
  { name: "{last365days}", value: "Last 365 days" },
  { name: "{next7days}", value: "Next 7 days" },
  { name: "{next30days}", value: "Next 30 days" },
  { name: "{next365days}", value: "Next 365 days" }
];

export const placeholderToLabel = (placeholder) => {
  const item = PLACEHOLDER_LABELS.find(el => el.name === placeholder);
  return item ? item.value : "";
}