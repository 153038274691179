import React, { useContext, useState } from "react";
import { getMoment } from "Libs/NwMoment";
import { Modal, Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import styled from 'styled-components';

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import {
    NWSelectCountry,
    NWInput,
    NWGenderSelector
} from "Components/Gui/NWForm/NwFormItems";
import { ListContext } from "Contexts/ListContext";
import { NWBirthDatePicker } from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { validateDate } from 'Libs/NwUtils';
import {
    useContact,
    forceReloadContact,
    onUpdateContact,
    onUpdateContactCustomFields,
    onCheckDuplicates
} from 'Hooks/Contact/UseContact';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { getCustomFieldInitialValue, getEmbeddedCustomField } from 'Libs/NwCustomFields';
import { getNow, toISODate } from "Libs/NwMoment";

const FormItem = styled.div`
    .ant-form-item-control-input-content {
        width: 100%;
    }
`;
const AnagraphicalForm = ({ touchForm, type, contactID, customFields, onCancel, onTouchForm }) => {
    useFormFieldFocus('Name');
    const { data: currentContact } = useContact(contactID);
    const COUNTRIES = useContext(ListContext).cachedList.countries.items;
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [birthDay, setBirthDay] = useState((currentContact.BirthDate && getMoment(currentContact.BirthDate).isValid()) ? getMoment(currentContact.BirthDate).format("MM-DD-YYYY") : null);
    const birthDay = (currentContact.BirthDate && getMoment(currentContact.BirthDate).isValid()) ? getMoment(currentContact.BirthDate).format("MM-DD-YYYY") : null;

    const availableCustomFields = customFields ? customFields.filter(field => !field.Disabled) : [];

    // type 1: model
    // type 2: customer
    const isModel = type === 1;

    // Add country & email (only for checking customer existence)
    const Country = currentContact.DefaultConnection
        ? currentContact.DefaultConnection.Country
        : ""
    const Email = currentContact.DefaultConnection
        ? currentContact.DefaultConnection.Email
        : "";

    const {
        Name,
        Surname,
        LegalName,
        LegalSurname,
        Code,
        BirthPlace,
        NationalityCode,
        CitizenshipCode,
        Citizenship2Code,
        GenderName
    } = currentContact;

    const birthdate = (currentContact.BirthDate && getMoment(currentContact.BirthDate).isValid()) ? getMoment(currentContact.BirthDate).format("MM-DD-YYYY") : null;

    let initialValues = {
        Name,
        Surname,
        LegalName,
        LegalSurname,
        Code,
        birthDay,
        BirthDate: {
            year: null,
            month: undefined,
            day: null
        },
        BirthPlace,
        NationalityCode,
        CitizenshipCode,
        Citizenship2Code,
        Gender: GenderName,
        CustomFields: {}
    };

    if (birthdate) {
        console.log("birthdate", birthdate)
        initialValues.BirthDate = {
            year: getMoment(birthdate, "MM-DD-YYYY").year(),
            month: getMoment(birthdate, "MM-DD-YYYY").month(),
            day: getMoment(birthdate, "MM-DD-YYYY").date()
        };
    }

    availableCustomFields.map(customField => {
        if (!customField.Disabled) {
            initialValues = {
                ...initialValues,
                CustomFields: {
                    ...initialValues.CustomFields,
                    [customField.Name]: getCustomFieldInitialValue(customField.TypeName, currentContact.CustomFields[customField.Name])
                }
            };
        }

        return null;
    })

    // Called before submit, check duplicated entries on db
    const beforeSubmit = async (values, setStatus, setSubmitting) => {
        let data = {
            Name: values.Name, // Model & Client
            ID: currentContact.ID
        };
        if (isModel) {
            // Model
            data.Surname = values.Surname;
            data.NationalityCode = values.NationalityCode;
            data.Surname = values.Surname;
            data.MotherAgencyID = currentContact.MotherAgencyID;
        } else {
            // Customer
            data.Email = currentContact.DefaultConnection ? currentContact.DefaultConnection.Email : "";
            const country = COUNTRIES.find(el => {
                return el.Code === Country;
            });
            if (country) {
                data.NationalityID = country.ID;
            }
        }

        try {
            //call check duplicates only if name or surname are changed or ID is null
            let checkDuplicates = true;
            if (data.ID) {
                if (isModel) {
                    if (values.Name === Name && values.Surname === Surname) {
                        checkDuplicates = false;
                    }
                } else {
                    if (values.Name === Name) {
                        checkDuplicates = false;
                    }
                }
            }
            if (checkDuplicates) {
                const response = await onCheckDuplicates({ type: type, data });
                if (response.length > 0) {
                    const modal = Modal.confirm();
                    if (isModel) {
                        modal.update({
                            title: "Warning",
                            content: (
                                <>
                                    <p>
                                        Model{" "}
                                        <b>
                                            {values.Name} {values.Surname}
                                        </b>{" "}
                                        already exists.
                                        <br />
                                    </p>
                                    {response[0].HasSameNationality && (
                                        <p>
                                            Nationality:{" "}
                                            {
                                                COUNTRIES.find(el => el.Code === values.NationalityCode).Name
                                            }
                                        </p>
                                    )}
                                    {response[0].HasSameMotherAgency && (
                                        <p>MotherAgencyID: {currentContact.MotherAgencyID}</p>
                                    )}
                                </>
                            ),
                            zIndex: 2000,
                            confirmLoading: isSubmitting,
                            onCancel: () => {
                                setIsSubmitting(false);
                                setSubmitting(false);
                            },
                            onOk: () => submitForm(values, setStatus, setSubmitting)
                        });
                    } else {
                        modal.update({
                            title: "Warning",
                            content: (
                                <>
                                    <p>
                                        Contact <b>{values.Name}</b> already exists.
                                    </p>
                                    {response[0].HasSameNationality && (
                                        <p>
                                            Nationality:{" "}
                                            {COUNTRIES.find(el => el.Code === Country.Code).Name}
                                        </p>
                                    )}
                                    {response[0].HasSameEmail && <p>Email: {Email}</p>}
                                </>
                            ),
                            zIndex: 2000,
                            confirmLoading: isSubmitting,
                            onCancel: () => {
                                setIsSubmitting(false);
                                setSubmitting(false);
                            },
                            onOk: () => submitForm(values, setStatus, setSubmitting)
                        });
                    }
                } else {
                    submitForm(values, setStatus, setSubmitting);
                }
            } else {
                submitForm(values, setStatus, setSubmitting);
            }
        } catch (error) {
            console.log('ant : CheckUpdate Error => ', error);
        }
    };

    const GetDate = (year, month, day) => {
        if (year === null || month === undefined || day === null) {
            return null;
        }
        const birthDate = getNow().year(year).month(month).date(day);
        return birthDate.format("YYYY-MM-DD");
    }

    let validationSchema = isModel ? Yup.object().shape({
        Name: Yup.string().required("Content is required"),
        Surname: Yup.string().required("Content is required"),
        NationalityCode: Yup.string().required("Content is required"),
    }) : Yup.object().shape({
        Name: Yup.string().required("Content is required")
    });

    const submitForm = async (values, setStatus, setSubmitting) => {
        const isCustomFieldsTouched = JSON.stringify(initialValues.CustomFields) !== JSON.stringify(values.CustomFields);

        if (JSON.stringify({ ...initialValues, CustomFields: {} }) !== JSON.stringify({ ...values, CustomFields: {} })) {
            setIsSubmitting(true);
            setSubmitting(true); // Formik
            let data = [];
            if (isModel) {

                data.push({ name: "Name", value: values.Name, changed: true });
                data.push({ name: "Surname", value: values.Surname, changed: true });
                data.push({
                    name: "BirthPlace",
                    value: values.BirthPlace,
                    changed: true
                });
                data.push({
                    name: "BirthDate",
                    value: GetDate(values.BirthDate.year, values.BirthDate.month, values.BirthDate.day),
                    changed: true
                });
                data.push({
                    name: "NationalityCode",
                    value: values.NationalityCode,
                    changed: true
                });
                data.push({
                    name: "CitizenshipCode",
                    value: values.CitizenshipCode,
                    changed: true
                });
                data.push({
                    name: "Citizenship2Code",
                    value: values.Citizenship2Code,
                    changed: true
                });
                data.push({
                    name: "LegalName",
                    value: values.LegalName,
                    changed: true
                });
                data.push({
                    name: "LegalSurname",
                    value: values.LegalSurname,
                    changed: true
                });
                data.push({
                    name: "Code",
                    value: values.Code,
                    changed: true
                });
                data.push({ name: "Gender", value: values.Gender, changed: true });
                data.push({ name: "IsCompany", value: false, changed: true });
            } else {
                data.push({ name: "Name", value: values.Name, changed: true });
                data.push({ name: "Code", value: values.Code, changed: true });
            }
            try {
                await onUpdateContact({ type: type, ID: currentContact.ID, data });
                if (!isCustomFieldsTouched) {
                    forceReloadContact(contactID);
                    onTouchForm(false);
                }
                setIsSubmitting(false);
                setSubmitting(false);
                setShowModal(false);
                //   if (!isCustomFieldsTouched) {
                onCancel();
                //   }
            } catch (error) {
                console.log("errore", error);
            }
        }

        if (isCustomFieldsTouched) {
            let customFieldsData = {};
            Object.keys(values.CustomFields).map(key => {
                if (values.CustomFields[key] !== '' && JSON.stringify(values.CustomFields[key]) !== JSON.stringify([]) && values.CustomFields[key]) {
                    const customField = availableCustomFields.find(item => item.Name === key);
                    if (customField.TypeName === 'MultipleChoice') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key].join(',')
                        }
                    } else if (customField.TypeName === 'Date') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: toISODate(values.CustomFields[key])
                        }
                    } else {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key]
                        }
                    }
                }
                return null;
            })

            try {
                // await Axios.put(`/contacts/Customfields/${currentContact.ID}`, customFieldsData)
                await onUpdateContactCustomFields({ contactId: currentContact.ID, data: customFieldsData });
                forceReloadContact(contactID)
                if (onTouchForm) {
                    onTouchForm(false);
                }
                onCancel();
            } catch (error) {
                console.log('ant : Save Event Custom Fields Error => ', error);
            }
        } else {
            onCancel();
        }
    };

    return (
        <>
            <Modal
                title="Basic Modal"
                open={showModal}
                onOk={submitForm}
                zIndex={2000}
                confirmLoading={isSubmitting}
                onCancel={() => {
                    setIsSubmitting(false);
                    setShowModal(false);
                }}
            >
                <p>Contact already exist! Submit???</p>
            </Modal>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    beforeSubmit(values, setStatus, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        {isModel && (
                            <>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* Name */}
                                        <Field
                                            component={NWInput}
                                            label="Name"
                                            name="Name"
                                            type="text"
                                            value={values.Name}
                                            id='Name'
                                            required
                                        />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* Surname */}
                                        <Field
                                            component={NWInput}
                                            label="Surname"
                                            name="Surname"
                                            type="text"
                                            value={values.Surname}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* LegalName */}
                                        <Field
                                            component={NWInput}
                                            label="Legal Name"
                                            name="LegalName"
                                            type="text"
                                            value={values.LegalName}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* LegalSurname */}
                                        <Field
                                            component={NWInput}
                                            label="Legal Surname"
                                            name="LegalSurname"
                                            type="text"
                                            value={values.LegalSurname}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        {/* Model Code */}
                                        <Field
                                            component={NWInput}
                                            label="Model Code"
                                            name="Code"
                                            type="text"
                                            maxLength={10}
                                            value={values.Code}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        {/* Gender */}
                                        <Field
                                            component={NWGenderSelector}
                                            label="Gender"
                                            name="Gender"
                                            value={values.Gender}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        {/* BirthDate */}
                                        <FormItem>
                                            <Field
                                                component={NWBirthDatePicker}
                                                label="Birth Date"
                                                name="BirthDate"
                                                style={{ width: "100%" }}
                                                value={values.BirthDate}
                                                validate={value => validateDate(value, true)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* BirthPlace */}
                                        <Field
                                            component={NWInput}
                                            type="text"
                                            label="Birth Place"
                                            name="BirthPlace"
                                            value={values.BirthPlace}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* NationalityCode */}
                                        <Field
                                            required
                                            component={NWSelectCountry}
                                            label="Nationality"
                                            name="NationalityCode"
                                            value={values.NationalityCode}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* CitizenshipCode */}
                                        <Field
                                            component={NWSelectCountry}
                                            label="Citizenship"
                                            name="CitizenshipCode"
                                            value={values.CitizenshipCode}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        {/* Citizenship2Code */}
                                        <Field
                                            component={NWSelectCountry}
                                            label="Additional Citizenship"
                                            name="Citizenship2Code"
                                            value={values.Citizenship2Code}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!isModel && (
                            <>
                                <Row gutter={10}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={NWInput}
                                            label="Name"
                                            name="Name"
                                            type="text"
                                            value={values.Name}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        {/* Model Code */}
                                        <Field
                                            component={NWInput}
                                            label="Client Code"
                                            name="Code"
                                            type="text"
                                            maxLength={10}
                                            value={values.Code}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        {availableCustomFields.map((customField, index) => {
                            return <div key={`${customField.Name}-${index}`}>
                                {getEmbeddedCustomField(values, customField)}
                            </div>
                        })}
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting || !touchForm}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </>
    );
};

export default AnagraphicalForm;
