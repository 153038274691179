import React, { useMemo, useState, useEffect } from 'react';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { onGetOrderedEventList, useProject } from 'Hooks/Project/UseProject';
import { useStateValue } from 'Contexts/StateProvider';
import NonBookingEvent from 'Components/EventsBoxes/NonBookingEvent';
import BookingEvent from 'Components/EventsBoxes/BookingEvent';
import NwDivider from 'Components/Gui/NwDivider';
import NwIcon from 'Components/Gui/NwIcon';
import { getTodayISO, toISODate } from 'Libs/NwMoment';

const ProjectEventsOverview = ({ projectId, onMoveToEvent }) => {
    const [{ areaContext }] = useStateValue();

    const [projectEvents, setProjectEvents] = useState([])

    const { data: currentProject, isFetching } = useProject(projectId);

    useEffect(() => {
        if (!isFetching && currentProject) {
            const te = onGetOrderedEventList(currentProject, areaContext, true, true)
            setProjectEvents(te)
        }
    }, [currentProject, isFetching])
    

    const handleSelectEvent = (eventdata) => {
        let evid = 0
        if (eventdata.ID) {
            evid = eventdata.ID
        } else {
            evid = eventdata.GroupedEventIDs[0]
        }
        onMoveToEvent(evid, eventdata.elType);
    };

    const renderDisplayEvent = event => {
        switch (event.elType.toLowerCase()) {
            case 'event':
                return (
                    <BookingEvent 
                        event={event}  
                        page="project"
                        onSelectEvent={handleSelectEvent} 
                    />
                );
            default:
                return (<NonBookingEvent event={event} page='project' onSelectEvent={handleSelectEvent} />)
        }
    }

    const { pastEvents, nextEvents } = useMemo(() => {
        let pastEvents = []
        let nextEvents = []
        if (projectEvents) {
            pastEvents = projectEvents.filter(event => toISODate(event.sortField) < getTodayISO()).reverse()
            nextEvents = projectEvents.filter(event => toISODate(event.sortField) >= getTodayISO())
        }
        return { pastEvents, nextEvents }
    }, [projectEvents]);

    const renderEvents = events => {
        return (
            <>
                {events.map((event, index) => {
                    const eventWithType = { ...event, type: event.elType.toLowerCase() }
                    return (
                        <div style={{ width: '100%'}} key={index}>
                            {renderDisplayEvent(eventWithType)}
                        </div>
                    )
                })}
            </>
        )
    };

    return (
        <div className="internal-card">
            <div className="header">
                <div className="title-container">
                    <NwIcon className="icon" icon={light("calendar-alt")}/>
                    <div className="title">Events</div>
                </div>
            </div>
            {}
            <div className="content">
                {(nextEvents.length > 0) &&
                    <>
                        <NwDivider dark textSize="medium">Next and Current Events</NwDivider>
                        {renderEvents(nextEvents)}
                    </>
                }
                {(pastEvents.length > 0) &&
                    <>
                        <NwDivider dark textSize="medium">Past Events (newer to older)</NwDivider>
                        {renderEvents(pastEvents)}
                    </>
                }
            </div>
        </div>
    );
};

export default ProjectEventsOverview;