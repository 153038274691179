export const TRAVEL_PLANS_FILTERS_SETS = {
    activeSet: "v1",
    customSets: ([]),
    filterType: ("SAVED_SEARCH"),
    staticSets: [
        {
            id: "v1",
            name: "Ongoing travels",
            fixed: true,
            filters: [
                { name: "Ongoing", value: true, active: true },
            ]
        },
        {
            id: "v2",
            name: "My trips",
            fixed: true,
            filters: [
                { name: "BookerID", value: "{me}", active: true }
            ]
        }
    ]
}