import React, { useState } from "react";

import TransactionDetails from 'Components/Transactions/TransactionsDrawer/TransactionDetails';
import NwDrawer from "Components/Gui/NwDrawer";
import { Loading } from "Components/Gui/Loading";

const TransactionDetailDrawer = ({ transaction, projectId, onClose, onUpdateTransaction, onTransactionRemoved, hideProject }) => {
    const [touchForm, setTouchForm] = useState(false);
    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Transaction Details"
            width='60vw'
            maxWidth='60rem'
            minWidth='40rem'
        >
            {transaction 
            ?
            <TransactionDetails
                hideProject={hideProject}
                projectId={projectId} 
                transaction={transaction} 
                onUpdateTransaction={onUpdateTransaction}
                onTouchForm={setTouchForm}
                onTransactionRemoved={onTransactionRemoved} />
            :
            <Loading />
            }
            
        </NwDrawer>
    );
};

export default TransactionDetailDrawer;
