import React, { useState } from "react";
import { Formik, Field } from "formik";
import NwDrawer from "Components/Gui/NwDrawer";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import Axios from "axios";
import { NWAccountCodeSelector } from "Components/Gui/NWForm/NwFormItems";
import { forceReloadInvoice } from "Hooks/Invoices/UseInvoices";

const ChangeAccountDrawer = ({ onClose, invoiceID, records }) => {
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = {
        AccountCode: records[0].AccountCode ? records[0].AccountCode : null,
    }

    const saveAccountCode = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            const promises = records.map(record => {
                if (record.RowIds.length === 1) {
                    return Axios.put(
                        "/invoices/rows/setaccountcode",
                        {
                            InvoiceID: invoiceID,
                            RowID: record.index,
                            AccountCode: values.AccountCode
                        }
                    );
                } else {
                    return Axios.put(
                        "/invoices/rows/setgroupaccountcode",
                        {
                            InvoiceID: invoiceID,
                            GroupGuid: record.Guid,
                            AccountCode: values.AccountCode
                        }
                    );
                }
            });
            await Promise.all(promises);
            forceReloadInvoice(invoiceID);
            onClose();
        } catch (e) {
            console.log("error saving account code", e);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Change Account Code"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveAccountCode(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit, }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                    >
                        <Field
                            component={NWAccountCodeSelector}
                            label="Account #"
                            name="AccountCode"
                            value={values.AccountCode}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default ChangeAccountDrawer;