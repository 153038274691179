import React from "react";
import { Select } from 'antd';

const PackageDateTypeSelector = ({ onChange, value, disabled, onBlur }) => {
    const PackageDateTypes = [
        {
            label: 'As creation date',
            value: false
        },
        {
            label: 'As sent date',
            value: true
        }
    ];

    return (
        <Select
            autoComplete='off'
            disabled={disabled}
            value={value} 
            onChange={onChange}
            options={PackageDateTypes} 
            onBlur={onBlur}
        />
    );
};

export default PackageDateTypeSelector;
