import React from 'react';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { isAllDay, showDateNoTime } from 'Libs/NwMoment';
import { getContactName } from "Hooks/Contact/UseContactsList";
import useEventStyle from 'Hooks/UseEventStyle';
import useCountryName from 'Hooks/UseCountryName';

const EventFieldsTable = styled.table`
    width: 100%;
    tr {
        display: flex;
    }
    td {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem;
        text-transform: uppercase;
        font-size: .8rem;

        .icon-container {
            padding-right: 1rem;
            svg {
                font-size: 2.4rem;
                margin: 0 0 8px;
            }
        }
        .address-container {
            cursor: pointer;
            margin-bottom: 1rem;
        }
        .arrow-dropdown {
            font-size: .95rem;
            margin: 4px 0;
        }
        .label {
            font-size: .65rem;
        }
        .date {
            font-size: .8rem;
            font-weight: bold;
        }

        &:first-child {
            padding-left: 0;
            flex: unset;
        }
        &:last-child {
            border-right: none;
        }
    }
`;

const ScoutingMeetingDetails = ({ scoutingMeetingsData }) => {
    const meetingStyle = useEventStyle({}, "Meeting");
    const countryName = useCountryName(scoutingMeetingsData && scoutingMeetingsData.Country);

    const ComposeAddressForMaps = () => {
        const addressString = [scoutingMeetingsData.Street, 
            scoutingMeetingsData.City, 
            scoutingMeetingsData.Zip, 
            scoutingMeetingsData.State, 
            countryName].filter(Boolean).join("+");
        return "https://www.google.com/maps/place/" + encodeURIComponent(addressString)
    }

    const showMap = event => {
        event.stopPropagation();
        window.open(ComposeAddressForMaps())
    }

    return (
        <FlexContainer pd='16px 30px 16px 40px'>
            <EventFieldsTable>
                <tbody>
                    <tr>
                        <td>
                            <div className='icon-container'>
                                <NwIcon icon={meetingStyle.icon} />
                            </div>
                        </td>
                        <td>
                            <div className='address-container' onClick={showMap}>
                                {scoutingMeetingsData.Street && <div>{scoutingMeetingsData.Street}</div>}
                                {scoutingMeetingsData.Zip && <div>{scoutingMeetingsData.Zip}</div>}
                                {scoutingMeetingsData.City} ({countryName})
                            </div>
                            <div>{getContactName(scoutingMeetingsData.ContactID)}</div>
                        </td>
                        <td>
                            <FlexContainer column alignItems='flex-start' justifyContent='center'>
                                <div>{showDateNoTime(scoutingMeetingsData.StartDate)}</div>
                                {isAllDay(scoutingMeetingsData.StartDate, scoutingMeetingsData.EndDate) ? 
                                    <div className='date'>All Day</div>
                                    :
                                    <>  
                                        <FlexContainer column justifyContent='center' alignItems='center'>
                                            <div className='date'>{getMoment(scoutingMeetingsData.StartDate).format('HH:mm')}</div>
                                            <NwIcon icon={light('arrow-down')} className='arrow-dropdown' />
                                        </FlexContainer>
                                        <div className='date'>{getMoment(scoutingMeetingsData.EndDate).format('HH:mm')}</div>
                                    </>
                                }
                            </FlexContainer>
                        </td>
                    </tr>
                </tbody>
            </EventFieldsTable>
        </FlexContainer>
    );
};

export default ScoutingMeetingDetails;