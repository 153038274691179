const constants = {
    vsapiurl: "https://localhost:44364/api",
    tenanturl: "http://dev.netwalk.app/",
    notificationHub: "http://dev.netwalk.app/notificationhub",
    //tenanturl: "http://dev.netwalkapp.com/",
    //notificationHub: "http://dev.netwalkapp.com/notificationhub",
    
    baseapiurl: "/api",
    //baseapiurl: "https://dev.netwalk.app/api",
    imagePlaceholderDrawingsCount: 13,
    google_map_api_key: 'AIzaSyB6-Qef5zqoKOcUuSn-D6YwthXgZz7gGwk',
    google_map_embed_link: 'https://www.google.com/maps/embed/v1/place?key=',
    google_map_destination_link: 'https://www.google.com/maps/dir/?api=1&destination=',

    nw_defaulttimeformat12: true,

    nw_datetime_viewformat_long: "ddd D MMM",
    nw_datetime_viewformat_long_datepicker: "D d M",
    nw_datetime_viewformat_long_years: "ddd D MMM YY",
    nw_datetime_viewformat_long_years_datepicker: "D d M yy",
    nw_datetime_viewformat_short: "DD/MM/YYYY",
    nw_datetime_urlformat: "YYYY-MM-DD",
    nw_datetime_monthandyear: "MMMM YYYY",
    nw_datetime_yearonly: "YYYY",
    nw_datetime_timeformat: "HH:mm",
    nw_datetime_javascript: "ddd MMM DD YYYY hh:mm:ss [GMT]ZZ",
    nw_datetime_sql: "YYYY-MM-DD HH:mm:ss",
    nw_config_userguiconfig_calendar: {
        timeFormat: '24',
        weekFormat: 'monday'
    },
    nw_config_userguiconfig_numbers: {
        format: 'USD0.00',
        locale: 'en-US'
    },
    //this.nw_datetime_jsonformat = "YYYY-MM-DDTHH:mm";

    LAYOUT_TYPES: {
        DEFAULT: 'DEFAULT',
        HEADER_NAVIGATION: 'HEADER_NAVIGATION',
        ICON_SIDEBAR: 'ICON_SIDEBAR'
    },

    Roles: {
        Admin: 'Admin',
        User: 'User'
    },

    FileTypes: {
        video: ['m4v', 'mp4', 'mkv', 'mov'],
        image: ['jpeg', 'png', 'gif', 'jpg']
    },

    CUSTOMER_CATEGORIES: ['Client', 'Agency', 'Service'],

    TRANSPORTS: ['Plane','Train','Bus', 'Driver', 'Boat', 'Car', 'Taxi'],

    AREAS: ['booking', 'scouting', 'accounting', 'art', 'management']
}

export default constants;
