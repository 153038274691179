import React from "react";
import { Select } from "antd";

const Option = Select.Option;

const EmptyDateInput = ({ onChange, value, disabled }) => {
  const options = [
      {Value: "include", Name: "Include"},
      {Value: "exclude", Name: "Exclude"},
      {Value: "only", Name: "Only without dates"},
  ];
  const handleChange = val => {
    onChange(val);
  };
  return (
    <Select
      autoComplete='off'
      mode="single"
      value={value}
      placeholder="Please select"
      onChange={handleChange}
      disabled={disabled}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      style={{ width: "100%"}}
    >
      {options.map(el => (
        <Option key={el.Value} value={el.Value}>
          {el.Name}
        </Option>
      ))}
    </Select>
  );
};

export default EmptyDateInput;
