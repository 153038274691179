import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getContactStatements = async (ID, fromDate, toDate) => {
    const transactions = await Axios.get(`/contacts/statement/${ID}?fromDate=${fromDate}&toDate=${toDate}`);
    return transactions
};

const useContactStatements = (ID, fromDate, toDate) => {
    return useQuery(["contactStatements", ID, fromDate, toDate], () => getContactStatements(ID, fromDate, toDate), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false
    });
};

const forceReloadContactStatements = (ID, fromDate, toDate) => {
    if (fromDate && toDate) {
        queryClient.invalidateQueries(["contactStatements", ID, fromDate, toDate])
    } else {
        queryClient.invalidateQueries(["contactStatements", ID])
    }
}

export {
    useContactStatements,
    forceReloadContactStatements
};