import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Spin, Upload, message, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { forceReloadContact, useRemoveContactCover } from "Hooks/Contact/UseContact";
import { forceReloadModels } from "Hooks/Contact/UseContactsList";
import { UserContext, useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const ModelHeaderCoverDiv = styled.div`
    .ant-spin-nested-loading {
        height: ${props => props.$mobileView ? '7.5rem' : props.$collapseView ? '7rem' : '13rem'};
        width: ${props => props.$mobileView ? '6.5rem' : props.$collapseView ? '5rem' : '9.75rem'};
        margin: ${props => !props.$mobileView && props.$collapseView && '0.5rem 0 0 0.5rem'};
        position: absolute;
        top: 0;
        left: 0;
        padding: ${props => props.$mobileView && '4px'};
        box-shadow: ${props => props.$mobileView && props.theme.SelectedShadow};
    }
    .ant-spin-container {
        height: 100%;
    }
    .header-image-container {
        height: ${props => props.$collapseView ? '7rem' : '13rem'};
        width: ${props => props.$mobileView ? '6rem' : props.$collapseView ? '5rem' : '9.75rem'};
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        background-position: top center;
        background-size: ${(props) => (!props.$isPlaceholder ? "cover" : "auto")};
        background-repeat: ${(props) => (!props.$isPlaceholder ? "no-repeat" : "repeat")};
        background-image: url(${(props) => props.$urlBg});
        padding:0;
        background-color: transparent;
        opacity: ${(props) => (props.$isPlaceholder ? ".85" : "1")};

        .hover-container {
            height: 100%;
            width: 100%;
            position: absolute;
            overflow: hidden;
            left: 0;
            top: 0;
            opacity: 0;
            transition: all 0.4s ease-in-out;
            z-index: 4;
            color: #fff;
            line-height: 1.2;
            background-color: ${props => props.theme.GreyedColor + 'E6'};
    
            .action {
                cursor: pointer;
                .icon {
                    font-size: 1.3rem;
                    color: white;
                    width: 34px;
                }
                .label {
                    font-size: .8rem;
                    color: white;
                    line-height: 1.2;
                }
            }
        }
    
        &:hover {
            .hover-container {
                    opacity: 1;
                }
            }
        }

        small.model-id {
            background-color: ${props => props.theme.PrimaryDark};
            color: #fff;
            font-size: .65rem;
            font-weight: bold;
            padding: 0.5em;
        }

        small.scouting-label {
            background-color: ${props => props.theme.PrimaryColor};
            color: #fff;
            font-size: .65rem;
            font-weight: bold;
            padding: 0.5em;
            text-transform: uppercase;
        }

        small.archive-label {
            background-color: ${props => props.theme.DangerColor};
            color: #fff;
            font-size: .65rem;
            font-weight: bold;
            padding: 0.5em;
            text-transform: uppercase;
        }

        small.placeholder {
            color: transparent;
            height: 0;
        }
    }
`;




const ModelDetailsCover = ({ currentContact, urlBg, collapseView, isPlaceholder, readOnly }) => {
    const { currentUser } = useContext(UserContext);
    const authorization = { Authorization: "Bearer " + currentUser.Token };
    const [isUploadingCover, setIsUploadingCover] = useState(false);
    const { mutateAsync: onRemoveContactCover } = useRemoveContactCover();
    const viewModelMedia = useUserPermission('ViewModelMedia', currentContact && currentContact.Users.map(user => user.UserID));
    const breakpoints = useNwBreakPoints()

    const isArchived = currentContact.NetworkAgencies.every(agency => agency.StorageBooking);

    const handleModelCoverUpdated = async () => {
        await forceReloadContact(currentContact.ID);
        setIsUploadingCover(false);
    };

    const handleModelCoverUploadingChange = data => {
        if (data.file.status === 'uploading') {
            setIsUploadingCover(true);
        }
        if (data.file.status === 'done') {
            handleModelCoverUpdated();
            forceReloadModels();
        }
    };

    const handleRemoveCover = async () => {
        setIsUploadingCover(true);
        try {
            await onRemoveContactCover({ contactId: currentContact.ID });
            forceReloadModels();
            setIsUploadingCover(false);
        } catch (error) {
            console.log('ant : Remove Contact Cover Error => ', error);
            setIsUploadingCover(false);
        }
    };

    const beforeUpload = (file, types = []) => {
        const accept = []
        let acceptall = false
        types.forEach(el => {
            if (el === "image") {
                accept.push("image/jpeg");
                accept.push("image/png");
                accept.push("image/gif");
            }
        })
        const sizeOk = file.size / 1024 / 1024 < 5
        const typeOk = acceptall || accept.includes(file.type)
        if (!sizeOk) {
            notification.error({
                message: "File too big!",
                description: "Uploaded file must be smaller than 5MB.\nPlease use a smaller file",
                icon: <NwIcon icon={solid("circle-xmark")} />
            })
        }
        if (!typeOk) {
            notification.error({
                message: "File format not valid!",
                description: "This file format is not supported\nPlease use a JPEG, PNG or GIF file",
                icon: <NwIcon icon={solid("circle-xmark")} />
            })
        }
        return (sizeOk && typeOk)
    };

    return (
        <ModelHeaderCoverDiv
            $mobileView={breakpoints === 1}
            $collapseView={collapseView}
            $urlBg={urlBg}
            $isPlaceholder={isPlaceholder}>
            <Spin spinning={isUploadingCover}>
                <div className='header-image-container'>
                    {(viewModelMedia && !readOnly) &&
                        <FlexContainer
                            pd='24px'
                            column
                            alignItems='flex-start'
                            justifyContent='space-around'
                            className='hover-container'>
                            <ImgCrop
                                quality={0.8}
                                aspect={0.75}
                                beforeCrop={file => beforeUpload(file, ['image'])}>
                                <Upload
                                    name="files"
                                    showUploadList={false}
                                    listType="picture"
                                    multiple={false}
                                    onChange={handleModelCoverUploadingChange}
                                    action={`/api/contacts/setcover/${currentContact.ID}`}
                                    headers={authorization}
                                >
                                    <FlexContainer className='action'>
                                        <NwIcon className='icon' icon={solid('cloud-upload')} />
                                        <div className='label'>
                                            <div>UPLOAD</div>
                                            <div>NEW COVER</div>
                                        </div>
                                    </FlexContainer>
                                </Upload>
                            </ImgCrop>
                            <FlexContainer className='action' onClick={handleRemoveCover}>
                                <NwIcon className='icon' icon={solid('trash')} />
                                <div className='label'>
                                    <div>REMOVE</div>
                                    <div>COVER</div>
                                </div>
                            </FlexContainer>
                        </FlexContainer>
                    }
                    {isArchived
                        ?
                        <small className="archive-label">archived</small>
                        :
                        ((currentContact.SubType === 5)
                            ?
                            <small className="scouting-label">scouting</small>
                            :
                            <small className="placeholder"></small>
                        )
                    }
                    <small className="model-id">{currentContact.Code ? currentContact.Code : currentContact.ID}</small>
                </div>
            </Spin>
        </ModelHeaderCoverDiv>
    )
};

export default ModelDetailsCover;