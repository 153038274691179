import React, { useState, useEffect, useContext } from 'react';
import { Timeline, Collapse, List } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { showLog } from 'Libs/NwMoment';
import { ListContext } from 'Contexts/ListContext';
import NwBullet from 'Components/Gui/NwBullet';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import MiniLoading from 'Components/Gui/MiniLoading';
import { Loading } from 'Components/Gui/Loading';
import BuildingFeature from "Components/Utils/BuildingFeature";



const ComingSoonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
`;




// const { Panel } = Collapse;

// const Container = styled.div`
//     padding: 8px;
// `;

// const BackContainer = styled.div`
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     margin-bottom: 16px;
// `;

// const BackIconStyle = {
//     marginRight: 8
// };

// const LogHistory = ({ logHistory }) => {
//     const [logEntries, setLogEntries] = useState();
//     const [isFetching, setIsFetching] = useState(false);
//     const handleCollapseChange = collapse => {
//         if (collapse.length !== 0 && !logEntries) {
//             fetchLogEntries();
//         }
//     };

//     const fetchLogEntries = async () => {
//         setIsFetching(true);
//         try {
//             const logEntries = await Axios.get(`/LogHistories/GetLogEntries/${logHistory.ID}`);
//             setLogEntries(logEntries);
//         } catch (error) {
//             console.log('ant : Fetch Log Entries Error => ', error);
//         }
//         setIsFetching(false);
//     };

//     return (
//         <Collapse bordered={false} onChange={handleCollapseChange}>
//             <Panel header='more'>
//                 {isFetching ? <MiniLoading />
//                 : <div>
//                     {logEntries && 
//                         <List
//                             size='small'
//                             itemLayout="horizontal"
//                             dataSource={logEntries}
//                             renderItem={logEntry => {
//                                 return (
//                                     <List.Item className="ant-list-item-top">
//                                         <div>
//                                             <div>{`${logEntry.ObjectType} - ${logEntry.ActionName}`}</div>
//                                             {logEntry.LogEntryValues.map(logEntryValue => (
//                                                 <div key={logEntryValue.ID}>
//                                                     <div>{logEntryValue.OriginalValue}</div>
//                                                     changed to
//                                                     <div>{logEntryValue.ChangedValue}</div>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     </List.Item>
//                                 )}
//                             }
//                         />
//                         }
//                 </div>}
//             </Panel>
//         </Collapse>     
//     );
// };

const ObjectLogHistories = ({ objectId, type, onBack }) => {
    // const [logHistories, setLogHistories] = useState([]);
    // const { GetUserName } = useContext(ListContext);
    // const [isFetching, setIsFetching] = useState(false);

    // useEffect(() => {
    //     const fetchHistories = async () => {
    //         setIsFetching(true);
    //         try {
    //             const histories = await Axios.get(`/LogHistories/${type}/${objectId}`);
    //             setLogHistories(histories);
    //             setIsFetching(false);
    //         } catch (error) {
    //             console.log('ant : Get Lob Histories Error => ', error);
    //             setIsFetching(false);
    //         }
    //     }

    //     fetchHistories();
    // }, []);

    return (
        <ComingSoonContainer>
            <BuildingFeature />
        </ComingSoonContainer>
    )

    // return (isFetching ? <Loading textBlack />
    //     : <Container>
    //         {onBack && <BackContainer onClick={onBack}>
    //                 <NwIcon icon={light('arrow-left')} style={BackIconStyle} />Back
    //             </BackContainer>}
    //         <Timeline>
    //             {logHistories.map(logHistory => {
    //                 const isDeleted = logHistory.OperationName.toLowerCase().includes('deleted');
    //                 const isAdded = logHistory.OperationName.toLowerCase().includes('added');
    //                 return (
    //                     <Timeline.Item 
    //                         key={logHistory.ID} 
    //                         color={isDeleted ? 'red' : isAdded ? 'blue' : 'green'}
    //                         dot={isAdded && <NwIcon icon={solid('plus-circle')} />}>
    //                         <FlexContainer>{showLog(logHistory.DateModified)}<NwBullet />{GetUserName(logHistory.UserModified)}</FlexContainer>
    //                         <div>{logHistory.Description}</div>
    //                         {/* <LogHistory logHistory={logHistory} />            */}
    //                     </Timeline.Item>
    //                 )
    //             })}
    //         </Timeline>
    //     </Container>
    // );
};

export default ObjectLogHistories;