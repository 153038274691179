import React from "react";
import { InputNumber } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import Select from 'Components/Gui/NWForm/CustomSelect';

const BirthDatePicker = ({ value, onChange }) =>  {
    const onChangeMonth = (event) => {
        onChange({
            year: value.year,
            month: event,
            day: value.day
        });
    };
    const onChangeDay = (event) => {
        onChange({
            year: value.year,
            month: value.month,
            day: event
        });
    };
    const onChangeYear = (event) => {
        onChange({
            year: event,
            month: value.month,
            day: value.day
        });
    };

    const getDaysInMonth = (month, year) => {
        return 31; //(!year || !month) ? 31 : new Date(year, month+1, 0).getDate();
    };

   const CALENDAR_MONTHS = [
       {title: "Jan", key: 0},
       {title: "Feb", key: 1},
       {title: "Mar", key: 2},
       {title: "Apr", key: 3},
       {title: "May", key: 4},
       {title: "Jun", key: 5},
       {title: "Jul", key: 6},
       {title: "Aug", key: 7},
       {title: "Sep", key: 8},
       {title: "Oct", key: 9},
       {title: "Nov", key: 10},
       {title: "Dec", key: 11}
    ];

    const handleRemoveDate = () => {
        onChange({
            year: null, 
            month: undefined,
            day: null
        })
    }

    return(
        <div style={{display: "flex"}}>
            <Select
                autoComplete='off'
                showSearch={false}
                width='120px'
                value={value.month}
                allowClear={false}
                filterOption={false}
                placeholder='month'
                onChange={onChangeMonth}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                options={CALENDAR_MONTHS.map(data => ({ value: data.key, label: data.title }))}
            />
            &nbsp;
            <InputNumber  autoComplete='off' value={value.day} onChange={onChangeDay} min={1} max={getDaysInMonth(value.month, value.year)} placeholder='date' style={{width: '4em'}} />
            &nbsp;
            <InputNumber autoComplete='off' value={value.year} onChange={onChangeYear} min={1} placeholder='year' />
            &nbsp;
            <NwButton iconOnly icon={light('times')} onClick={handleRemoveDate} />
        </div>
    )
};

export default BirthDatePicker;
