import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import Axios from "axios";

import { getThumbnailWidth, getVideoCover } from "Libs/NwUtils";
import MediaCover from 'Components/Gui/MediaCover';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import { forceReloadPackage } from "Hooks/Packages/UsePackages";
  
const ListContainer = styled.div`
    margin: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 10vw;
    background-color: #fff;
    padding: 16px 24px;
    box-sizing: border-box;
    width: 90vw;
    font-weight: bold;
    text-transform: uppercase;
`;

const MediaGalleryContent = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: "8rem";

    .image-block {
        color: white;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        position: relative;
        margin: .5rem;
        cursor: pointer;
    }
    .count-badge {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: ${StyleVariables.PrimaryColor};
        position: absolute;
        top: -10px;
        display: flex;
        justify-content: center;
        margin: auto;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        .icon {
            display: none;
        }
        &:hover {
            background: ${StyleVariables.DangerColor};
            .number {
                display: none;
            }
            .icon {
                display: flex;
            }
        }
    }

    &.media-gallery-content-large {
        .image-block {
            margin: .75rem;
        }
    }
    &.media-gallery-content-xlarge {
        .image-block {
            margin: .75rem;
        }
    }

    .nw-book-model-picture {
        position: relative;

        span.plus-icon {
            position: absolute;
            top: 0;
            right: 0;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px;
        }

        &.nw-book-model-picture-disabled {
            opacity: .25;
        }
    }
`

const getRowHeight = size => {
    switch(size) {
        case 'small':
            return 160;
        case 'large':
            return 300;
        case 'medium':
        default:
            return 230;
    }
};

const SelectPictureList = ({
    sort,
    size,
    documents,
    onSelectPicture,
    modelID,
}) => {
    
    const [documentList, setDocumentList] = useState([]);
    
    const sortedMediaList = useMemo(() => {
        let sortedMediaList = [...documents];
        if (sort === 'time') {
            sortedMediaList.sort((item1, item2) => {
                return new Date(item2.DateCreated) > new Date(item1.DateCreated) ? 1 : -1;
            });
        } else if (sort === 'myrating') {
            sortedMediaList.sort((item1, item2) => {
                return item2.Rating.currentUserRating - item1.Rating.currentUserRating;
            });
        } else if (sort === 'globalrating') {
            sortedMediaList.sort((item1, item2) => {
                return item2.Rating.averageRating - item1.Rating.averageRating;
            });
        }
        return sortedMediaList;
    }, [sort, documents]);

    useEffect(() => {
        setDocumentList(convertObjects(sortedMediaList));
    }, [sortedMediaList, size])

    const convertObjects = documentList => {
        return documentList.map(item => {
            return convertObject(item);
        });
    };

    const handleSelectPicture = (item) => {
        onSelectPicture(item);
    }

    const convertObject = item => {
        if (item.Type === 'Photo') {
            return {
                src: item.Standard.Url,
                thumbnail: item.Thumbnail.Url,
                thumbnailWidth: getThumbnailWidth(item, getRowHeight(size)),
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title,
                ID: item.ID,
                disabled: false,
                selected: item.isSelected ? true : false,
                type: item.Type,
                notes: '',
                removable: true
            };
        } else if (item.Type === 'Video') {
            return {
                src: getVideoCover(item),
                thumbnail: getVideoCover(item),
                thumbnailWidth: getRowHeight(size) * 1.5,
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title,
                ID: item.ID,
                disabled: false,
                selected: item.isSelected ? true : false,
                type: item.Type,
                notes: '',
                removable: true
            };
        }
    };

    const getSourceUrl = (item) => {
        if (size === 'large')
            return item.src;
        return item.thumbnail;
    }

    // const onSavePictures = async () => {
    //     if (!selectedMediaList.length) return;
    //     setSaving(true);
    //     try {
    //         const data = {
    //             PackageID: packageId,
    //             ModelID: modelID,
    //             Media: selectedMediaList.map(media => media.ID)
    //         }
    //         await Axios.post("/packageplaylists/media", data);
    //         forceReloadPackage(packageId);
    //         onClose();
    //     } catch (e) {
            
    //     } finally {
    //         setSaving(false);
    //     }
    // }

    // const onClearSelection = () => {
    //     setSelectedMediaList([]);
    // }

    // const onSelectMedia = (media) => {
    //     const index = selectedMediaList.findIndex(item => item.ID === media.ID);
    //     if (index >= 0) return;
    //     setSelectedMediaList([
    //         ...selectedMediaList,
    //         media,
    //     ])
    // }

    // const onDeselectMedia = (media) => {
    //     const index = selectedMediaList.findIndex(item => item.ID === media.ID);
    //     const _selectedMediaList = [...selectedMediaList];
    //     _selectedMediaList.splice(index, 1);
    //     setSelectedMediaList([..._selectedMediaList]);
    // }

    // const renderBadge = (media) => {
    //     const index = selectedMediaList.findIndex(item => item.ID === media.ID);
    //     if (index === -1) return (null);
    //     return (
    //         <div className="count-badge" style={{left: `${media.thumbnailWidth - 5}px`}} onClick={() => onDeselectMedia(media)}>
    //             <div className="number">
    //                 {index + 1}
    //             </div>
    //             <div className="icon">
    //                 <NwIcon icon={light("times")}/>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <ListContainer>
            <MediaGalleryContent className={`media-gallery-content-${size}`}>
                {documentList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className='image-block'
                        >
                            <MediaCover
                                type={item.type}
                                selected={item.selected}
                                selectable={false}
                                src={getSourceUrl(item)}
                                onClick={() => handleSelectPicture(item)}
                                style={{
                                    minWidth: item.thumbnailWidth + "px",
                                    maxWidth: item.thumbnailWidth + 10 + "px",
                                    width: item.thumbnailWidth + "px",
                                    height: item.thumbnailHeight + "px",
                                }}
                            />
                        </div>                    
                    )
                })}
            </MediaGalleryContent>
        </ListContainer>
    )
};

export default SelectPictureList;