import React, { useState } from "react";
import TransactionCreateForm from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateForm';
import TransactionDetails from "Components/Transactions/TransactionsDrawer/TransactionDetails";

const TransactionCreateFormContainer = ({
    event,
    events,
    isEventEditable = true,
    modelId,
    modelSelectionEnabled,
    onCancel,
    onTouchForm,
    onTransactionCreatedOrUpdated,
    onTransactionRemoved,
    plainMode,
    projectId,
    transactionFamily,
    usageId,
}) => {

    const [transactionID, setTransactionID] = useState();

    const handleTransactionCreated = transaction => {
        onTransactionCreatedOrUpdated(transaction);
        setTransactionID(transaction.ID);
    }

    if (transactionID) {
        return (
            <TransactionDetails
                transactionID={transactionID}
                projectId={projectId}
                onUpdateTransaction={onTransactionCreatedOrUpdated}
                onTouchForm={onTouchForm}
                onTransactionRemoved={onTransactionRemoved} />
        );
    }

    return (
        <TransactionCreateForm
            family={transactionFamily}
            event={event}
            projectId={projectId}
            modelId={modelId}
            usageId={usageId}
            onTransactionCreated={handleTransactionCreated}
            onCancel={onCancel}
            modelSelectionEnabled={modelSelectionEnabled}
            events={events}
            plainMode={plainMode}
            isEventEditable={isEventEditable}
            onTouchForm={onTouchForm}
        />
    )
};

export default TransactionCreateFormContainer;
