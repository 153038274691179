import React from 'react';
import styled from 'styled-components';

// import StyleVariables from 'Components/Gui/StyleVariables.js';

const CenteredBlockDiv = styled.div`
    margin-top: 25%;
    
    &.nw-centeredblock-fullpage {
        margin-top: 0;
        height: calc(100vh - 60px);
    }

    display: flex;

    .nw-centeredblock-content {
        padding: 0 0.9375rem;
        display: flex;
        flex-flow: column;
        margin: auto;
        align-items: center;
        text-align: center;
    }
`


export default class CenteredBlock extends React.PureComponent {
    render() {
        const {children, fullpage = false} = this.props;
        
        return (
            <CenteredBlockDiv className={fullpage && "nw-centeredblock-fullpage"}>
                <div className="nw-centeredblock-content">
                    {children}
                </div>
            </CenteredBlockDiv>
        );
    }
}
