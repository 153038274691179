import React from 'react';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import ContactAvatarGroup from 'Components/ContactAvatarGroup/ContactAvatarGroup';



const TaskContactAvatars = ({ task }) => {
    const { TodoContacts } = task;
    const contactIDs = TodoContacts ? TodoContacts.map(c => c.ContactID) : [];
    if (contactIDs.length > 1) {
        return (
            <div className="task-contacts">
                <ContactAvatarGroup contactIDs={[...contactIDs]} />
                {contactIDs.length === 1 && <div style={{ marginLeft: "5px" }}>{getContactName(contactIDs[0])}</div>}
            </div>
        )
    }
    return <></>
}

export default TaskContactAvatars;

