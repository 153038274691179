import styled from "styled-components";
import { Select, Tabs } from 'antd';
import FlexContainer from "Components/Gui/FlexContainer";

export const StyledTabs = styled(Tabs)`
    overflow: unset;    
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            &::after {
                box-shadow: none !important;
            }
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    margin: 0 16px 0 0;

                    .ant-tabs-tab-btn {
                        text-transform: uppercase;
                        font-size: .75rem;
                    }
                }
            }
        }
    }
`;

export const StyledSelect = styled(Select)`
    width: ${props => (props.$breakpoints > 3 && props.$layouttype === 'fullpage-compact') ? '420px' : '100%' };
    margin-bottom: ${props => props.$layouttype === 'fullpage' && '.5rem' };
    .ant-select-selection-item {
        span.is-modified {
            font-size: .75rem;
            font-weight: bold;
            background-color: ${props => props.theme.DangerColor};
            color: #fff;
            border-radius: 4px;
            padding: 2px 4px;
            margin: 0 1em 0 0;
            vertical-align: middle;
        }
    }
`;

export const StyledSelectDrawer = styled(Select)`
    width: 400px;
    font-weight: bold;
`;

export const FiltersWrapper = styled(FlexContainer)`
    width: ${props => props.$breakpoints < 4 && '100%'};
    position: relative;

    .ant-tabs {
        width: ${props => (props.$breakpoints > 3 && props.$layouttype === 'fullpage-compact') ? '420px' : '100%'};
    }
    form {
        width: ${props => (props.$breakpoints > 3 && props.$layouttype === 'fullpage-compact') && 'unset'};
    }
    .back-to-list {
        position: absolute;
        top: .4rem;
        right: .2rem;
        cursor: pointer;
    }
`;
