export const TASKS_FILTERS_SETS = {
    activeSet: "v0",
    customSets: [],
    filterType: ("SAVED_SEARCH"),
    staticSets: [
        {
            id: "v0",
            name: "Mine - To Complete",
            fixed: true,
            filters: [
                { name: "Status", value: false},
                { name: "UserID", value: "{me}"},
            ]
        },
        {
            id: "v1",
            name: "To Complete",
            fixed: true,
            filters: [
                { name: "Status", value: false}
            ]
        },
        {
            id: "v2",
            name: "Due next 30 days",
            fixed: true,
            filters: [
                { name: "Status", value: false},
                { name: "DueDate", value: "{next30days}"}
            ]
        }
    ]
}