import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';
import { useStateValue } from 'Contexts/StateProvider';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const { Option } = Select;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    
    .title {
        margin-left: 8px;
        margin-right: 16px;
        text-transform: uppercase;
    }

    .ant-select {
        width: auto !important;
        min-width: 200px;
        margin-left: 1em;
    }
`;

const VisibilitySelector = ({ value, onChange }) => {
    const [{ areaContext }/*, dispatch*/] = useStateValue();

    const handleChangeVisibility = checked => {
        if (checked) {
            onChange({
                ...value,
                isGlobal: checked,
                Areas: []
            });
        } else {
            onChange({
                ...value,
                isGlobal: checked,
                Areas: value.Areas.length > 0 ? [...value.Areas] : [areaContext.area]
            });
        }
    };

    const handleClickViewType = viewType => {
        onChange({
            ...value,
            Areas: [...viewType]
        });
    };

    return (
        <>
            <SwitchContainer>
                <CustomSwitch checked={value ? value.isGlobal : true} compact type="visibilityarea" onChange={handleChangeVisibility} />
                {(value && !value.isGlobal) && 
                    <Select 
                        autoComplete='off'
                        mode="multiple" 
                        popupClassName="ant-select-dropdown-areaselect" 
                        value={value.Areas} 
                        onChange={handleClickViewType}
                        optionLabelProp="label">
                        <Option value="booking" label='BOOK'><NwIcon color={StyleVariables.PrimaryColor} icon={light("heart-rate")} style={{marginRight: ".6em"}} />BOOKING</Option>
                        <Option value="scouting" label='SCO'><NwIcon color={StyleVariables.PrimaryColor} icon={light("globe")} style={{marginRight: ".6em"}} />SCOUTING</Option>
                        <Option value="accounting" label='ACCT'><NwIcon color={StyleVariables.PrimaryColor} icon={light("comment-alt-dollar")} style={{marginRight: ".6em"}} />ACCOUNTING</Option>
                        <Option value="management" label='MGMT'><NwIcon color={StyleVariables.PrimaryColor} icon={light("chart-pie")} style={{marginRight: ".6em"}} />MANAGEMENT</Option>
                    </Select>
                }
            </SwitchContainer>
        </>
    );
};

export default VisibilitySelector;