import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Badge, Select, Spin } from 'antd';

import { NWList, NWListItem } from "Components/Gui/NWList";
import FlexContainer from 'Components/Gui/FlexContainer';
import EmptyState from 'Components/Gui/EmptyState';
import { NwAddButton } from 'Components/Gui/NwButton';
import EmailSearchItem from './EmailSearchItem';
import { useStateValue } from "Contexts/StateProvider";

const { Option } = Select;

const Folders = ({ fullHeight, onAddEmail, onAddAllEmails, selectedItems, addAllButton }) => {

    const [emails, setEmails] = useState([]);
    const [isFetchingFolder, setIsFetchingFolder] = useState(false)
    const [activeFolder, setActiveFolder] = useState(null)

    const [{ folderContext }] = useStateValue()

    
    useEffect(() => {
        if (activeFolder) {
            fetchFoldersEmail(activeFolder);
        }
    }, [activeFolder]);

    const fetchFoldersEmail = async folder => {
        setIsFetchingFolder(true);
        const folderEmails = await Axios.post("/contacts/connections/emails", {ContactIDs: folder.items});
        setEmails(folderEmails);
        setIsFetchingFolder(false);
    };

    const handleAddFolderEmails = () => {
        //removing duplicate e-mails
        const uniqueValuesSet = new Set();
        const emailsNoDuplicate = emails.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.Email);
            uniqueValuesSet.add(obj.Email);
            return !isPresentInSet;
        });
        if (!allSelected()) {
            onAddAllEmails(emailsNoDuplicate)
        }
    };

    const isSelected = (item) => {
        return selectedItems.find(selectedEmail => selectedEmail.Email === item.Email)
    }

    const allSelected = () => {
        var activeemails = selectedItems.map(item => item.Email)
        var emailsinlist = emails.map(item => item.Email)
        return emailsinlist.every(v => activeemails.includes(v));
    }

    

    return (
        <div>
            <div className="search-header">
            <Select
                showSearch={false}
                placeholder={'Please select a folder'}
            > 
                {folderContext.openFolders.map(fl => (
                    <Option key={`folder-${fl.ID}`} value={fl.ID}>
                        <FlexContainer onClick={() => setActiveFolder(fl)}>
                            <Badge color={fl.color} style={{marginRight: ".5rem"}} />{fl.title}
                        </FlexContainer>
                    </Option>
                ))}
            </Select>
            {(emails.length > 0  && !isFetchingFolder && addAllButton) &&
                <NwAddButton ghost label='add all e-mails' disabled={allSelected()} onClick={handleAddFolderEmails} />
            }
            </div>
            <Spin spinning={isFetchingFolder}>
                <div className={`search-results ${fullHeight && " full-height"}`}>
                    {(emails.length === 0 && !isFetchingFolder) ? <EmptyState message='No Results' />
                        :
                        <NWList>
                            {emails.map((item, index) => (
                                <NWListItem
                                    noPadding
                                    key={`email-${item.ID}`}
                                    renderContent={() => (
                                        <EmailSearchItem email={item} selected={isSelected(item)} onSelect={onAddEmail} />
                                    )}
                                />
                            ))}
                        </NWList>
                    }
                </div>
            </Spin>
        </div>
    );
};

export default Folders;