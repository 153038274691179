import React, { useState, useEffect, useRef } from 'react';
import { Alert, Input } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import { NWList, NWListItem } from "Components/Gui/NWList";
import EmptyState from 'Components/Gui/EmptyState';
import NwIcon from 'Components/Gui/NwIcon';
import EmailSearchItem from './EmailSearchItem';

const isInclude = (test, pattern) => {
    if (test && test.toLowerCase().includes(pattern.toLowerCase())) {
        return true;
    }
    return false;
};

const EmailSearch = ({
    addable,
    defaultEmail,
    fullHeight,
    onAddItem,
    onGetOptions,
    onSetOptions,
    options,
    selectedItems,
}) => {
    const [filter, setFilter] = useState('');
    const [cache, setCache] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const searchRef = useRef();

    
    useEffect(() => {
        setFilter(defaultEmail);
    }, []);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef.current])

    const handleSearch = event => {
        const value = event.target.value;
        setFilter(value);
        if (value.length < 3) {
            onSetOptions([]);
            setCache(value);
        } else {
            if (value.length > 3 && value.includes(cache)) {
                const updatedOptions = options.filter(option => {
                    return isInclude(option.Email, value) || isInclude(option.ContactName, value)
                        || isInclude(option.ConnectionName, value) || isInclude(option.ConnectionRole, value);
                });
                onSetOptions(updatedOptions);
                setCache(value);
            } else {
                onGetOptions(value);
            }
        }
        if (showAlert) {
            setShowAlert(false);
        }
    }

    const isAddable = email => {
        // eslint-disable-next-line
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(email)) {
            if (!options.find(opt => opt.Email === email)) {
                return true;
            }
        }
        return false;
    }

    const handleAdd = () => {
        if (isAddable(filter)) {
            onAddItem({ Email: filter });
        } else {
            setShowAlert(true);
        }
    }

    const onCloseAlert = () => {
        setShowAlert(false);
    };

    const isSelected = (option) => {
        return selectedItems.find(selectedEmail => selectedEmail.Email === option.Email)
    }

    return (
        <>
            {showAlert && (
                <Alert
                    message={'Please input valid email!'}
                    type="error"
                    closable
                    onClose={onCloseAlert}
                />
            )}
            <div className="search-header" disabledaction={(!isAddable(filter) ? "disable" : "")}>
                <Input
                    allowClear
                    className="search-textbox"
                    prefix={<NwIcon icon={light('search')} />}
                    autoComplete='off'
                    value={filter}
                    ref={searchRef}
                    placeholder="Search"
                    onChange={handleSearch} />
                &nbsp;
                {addable &&
                    <NwAddButton
                        ghost
                        label='add'
                        disabled={!isAddable(filter)}
                        onClick={handleAdd}
                    />
                }
                {defaultEmail &&
                    <NwButton
                        ghost
                        disabled={!isAddable(filter)}
                        label='update address'
                        onClick={handleAdd}
                    />
                }
            </div>
            <div className={`search-results ${fullHeight ? ' full-height' : ''}`}>
                {(!filter || filter.length < 3 || options.length === 0) ? <EmptyState message='No Results' />
                    :
                    <NWList>
                        {options.map((option, index) => (
                            <NWListItem
                                noPadding
                                key={`email-${index}`}
                                renderContent={() => (
                                    <EmailSearchItem email={option} selected={isSelected(option)} onSelect={onAddItem} />
                                )}
                            />
                        ))}
                    </NWList>
                }
            </div>
        </>
    )
};

export default EmailSearch;