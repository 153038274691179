import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import Axios from "axios"
import NwIcon from "Components/Gui/NwIcon"
import { forceReloadTransaction } from "Hooks/Transaction/UseTransaction"
import React from "react"
import styled from "styled-components"

const Breadcrumb = styled.ul`
    list-style: none;
    overflow: hidden; 
    margin: .5rem 0;
    padding: 0;
    text-transform: uppercase;
    font-size: ${props => props.theme.FontSizeSm};

    li { 
        float: left;
        a {
            text-decoration: none; 
            padding: .25rem 1rem .25rem 2rem;
            background: ${props => props.theme.LighterBorderColor};
            color: ${props => props.theme.TextColorMuted};
            position: relative; 
            display: block;
            float: left;

            pointer-events: none;
            cursor: default;

            &:before { 
                content: " "; 
                display: block; 
                width: 0; 
                height: 0;
                border-top: 2rem solid transparent;           /* Go big on the size, and let overflow hide */
                border-bottom: 2rem solid transparent;
                border-left: 1rem solid white;
                position: absolute;
                top: 50%;
                margin-top: -2rem; 
                margin-left: 2px;
                left: 100%;
                z-index: 1; 
            }

            &:after { 
                content: " "; 
                display: block; 
                width: 0; 
                height: 0;
                border-top: 2rem solid transparent;           /* Go big on the size, and let overflow hide */
                border-bottom: 2rem solid transparent;
                border-left: 1rem solid ${props => props.theme.LighterBorderColor};
                position: absolute;
                top: 50%;
                margin-top: -2rem; 
                left: 100%;
                z-index: 2; 
            }
        }
        &:first-child {
            a {
                padding-left: 1rem;
                border-radius: 5px 0 0 5px;
            }
        }
        &.active {
            a {
                color: white;
                background: ${props => props.theme.PrimaryColor}; 
            
                &:after {
                    border-left-color: ${props => props.theme.PrimaryColor};
                }
            }
        }
        &.clickable {
            a {
                pointer-events: auto;
                cursor: pointer;

                &:hover {
                    color: white;
                    background: ${props => props.theme.PrimaryColor}; 
                
                    &:after {
                        border-left-color: ${props => props.theme.PrimaryColor};
                    }
                }
            }
        }
    }
`

const TransactionStatusBreadcrumbs = ({ status, onStatusChange, transactionFamily, transactionID }) => {
    
    const setAsBillable = async () => {
        try {
            await Axios.put(`/transactions/${transactionFamily}/setasbillable/${transactionID}`)
            forceReloadTransaction(transactionID);
            onStatusChange()
        } catch (error) {
            console.log("error", error);
        }
    }
    
    return (
        <Breadcrumb>
            <li className={status.toLowerCase() === "option" ? "active" : ""}><a href="#">option</a></li>
            <li className={status.toLowerCase() === "job" ? "active" : ""}><a href="#">job</a></li>
            {status.toLowerCase() === "option" || status.toLowerCase() === "job" 
            ?
            <li className="clickable"><a href="#" onClick={setAsBillable}><NwIcon icon={light("check")} /> billable</a></li>
            :
            <li className={status.toLowerCase() === "billable" ? "active" : ""}><a href="#"><NwIcon icon={light("check")} /> billable</a></li>
            }
            
            <li className={status.toLowerCase() === "locked" ? "active" : ""}><a href="#"><NwIcon icon={light("lock")} /> locked</a></li>
            <li className={status.toLowerCase() === "invoiced" ? "active" : ""}><a href="#"><NwIcon icon={light("file-invoice")} /> invoiced</a></li>
            {/* TODO: stati per credit note */}
        </Breadcrumb>
    )
}

export default TransactionStatusBreadcrumbs