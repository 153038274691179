export const editDrawerTypes = {
  editGeneralDetails: 'editGeneralDetails',
  editCustomFields: 'editCustomFields',
  addDates: 'addDates',
  history: 'history',
  editDates: 'editDates',
  editWorkingDay: 'editWorkingDay',
}

export const getEditDrawerTitle = (type) => {
  switch (type) {
    case editDrawerTypes.editGeneralDetails:
      return 'Edit event';
    case editDrawerTypes.editCustomFields:
      return 'Edit custom fields';
    case editDrawerTypes.addDates:
      return 'Add event dates';
    case editDrawerTypes.history:
      return 'Event logs';
    case editDrawerTypes.editDates:
      return 'Edit working date';
    case editDrawerTypes.editWorkingDay:
      return 'Move event date';
    default:
      break;
  }
}

export const extendOptionNumberToAllDates = (eventDates, modelId, optionNumber) => eventDates.map(eventDate => {
  return {
    ...eventDate,
    EventDatesModels: eventDate.EventDatesModels.map(model => {
      if (model.ModelID === modelId) {
        return {
          ...model,
          OptionNumber: optionNumber
        }
      }
      return model;
    })
  }
});
