import styled from "styled-components";
import { Tabs } from "antd";

export const LoadingContainer = styled.div`
    height: calc(40vh + 60px);
`;

export const SendDrawerTabs = styled(Tabs)`
    .ant-tabs-nav {
        margin: 0;
        padding-left: 16px;
    }
`;


export const SendDrawerContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.$twoColumns ? 'row' : 'column'};
    justify-content: space-between;
    align-items: flex-start;
    
    height: calc(100vh - 12rem);

    .search-section {
        width: 100%;
        flex: 1 1 0;
        
        .search-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f9f9f9;
            padding: .5rem 1rem;
            border-bottom: ${props => props.theme.DefaultBorder};
            height: 60px;

            button {
                opacity: ${props => props.disabledaction ? 0.5 : 1};
            }

            .ant-select-selector {
                padding: 4px 12px !important;
                height: 36px;
            }

            .ant-tabs {
                width: 100%;
            }

            .search-textbox {
                //width: 300px !important;
                padding: 6px 12px;
                border-radius: 20px;

            }
        }

        .search-results {
            overflow: auto;
            height: ${props => props.$isSingleSend ? 'calc(100vh - 51rem)' : 'calc(100vh - 47rem)'};
            
            &.full-height {
                height: calc(100vh - 12rem);
            }

            ul {
                margin-bottom: 0; 
            }
        }

        .mailing-lists-add-all {
            border-top: ${props => props.theme.DefaultBorder};
            border-bottom: ${props => props.theme.DefaultBorder};
            width: 100%;
        }

    }
    .recipients-and-email-details-section {
        width: 100%;
        padding-top: ${props => props.$twoColumns ? '1.7rem' : '0'};
        flex: ${props => props.$twoColumns ? '1 1 0' : null};
    }
    .recipients-section {
        width: 100%;

        .recipients-list {
            height: ${props => props.$isSingleSend ? '3rem' : '6rem'};
            overflow: auto;
            padding: 0 1.25rem .75rem 1.25rem;
            overflow: auto;
        }
    }
    .email-details-section {
        height: ${props => props.$isSingleSend ? '36rem' : '27rem'};
        width: 100%;
        
        .ant-form {
            padding: 1rem 2rem 0 2rem;
        }
            
        .button-bar {
            margin-top: 2rem;
            width: 100%;
        }
    }
`;