import React, { useState, useContext, useMemo } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { PageHeader } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { useStateValue } from "Contexts/StateProvider";
import { UserContext, useUserPermission } from "Contexts/UserContext";
import { Loading } from "Components/Gui/Loading";
import NwIcon from "Components/Gui/NwIcon";
import AlertDrawer from "Components/AlertDrawer/AlertDrawer";
import ContactUsers from "Components/ContactUsers/ContactUsers";
import AnagraphicalDrawer from "Components/ContactDetails/CommonComponents/Anagraphical/AnagraphicalDrawer";
import AddToFolderButton from "Components/OpenFolders/AddToFolderButton";
import FlexContainer from "Components/Gui/FlexContainer";
import ObjectLogHistories from "Components/ObjectLogHistories/ObjectLogHistories";
import NwDrawer from "Components/Gui/NwDrawer";
import ContactAlert from 'Components/ContactAlert/ContactAlert';
import ContactDetailsBankAccounts from 'Components/ContactDetails/ContactDetailsHeader/ContactDetailsBankAccounts';
import PermissionBlock from "Components/PermissionBlock";
import useDimensions from "Hooks/UseDimensions";
import useLocalStorageState from "Hooks/UseLocalStorageState";
import { useContact } from "Hooks/Contact/UseContact";
import {
    useAddContactTag,
    useRemoveContactTag,
} from "Hooks/Contact/UseContactTags";
import { saveAlerts } from 'Libs/NwAlerts';
import StyleVariables from "Components/Gui/StyleVariables";
import ContactHeaderBillingInfos from "./ContactHeaderBillingInfos";
import TagManagerDrawer from "Components/TagManager/TagManagerDrawer";

const CustomerPageHeader = styled(PageHeader)`
    padding: 0 !important;
    .ant-page-header-heading {
        padding: 1rem 0 0.5rem 1rem;
        .ant-page-header-heading-title {
            width: 100%;
            display: flex;

            span.name-icon {
                margin-right: 12px;
                font-size: 1rem;
            }

            .name {
                font-size: 2rem;
                line-height: 1;
                padding-right: 1em;

                .name-with-alert {
                    margin-left: 24px;
                }
            }
            .header-history {
                line-height: 1.1em;
                font-size: .8rem;
                font-weight: initial !important;
                cursor: pointer;
                margin: 10px 0;
            }
        }
    }
    .header-body {
        padding: 0 0 1rem 1rem;
        .header-body-boxes-container {
            display: flex;
            flex-direction: row;
        }
    }
    .header-footer {
        border-top: ${StyleVariables.DefaultBorder};
    }
`;

const CustomerDetailsHeaderDrawer = ({ browseTodos, contactID, history, onOpenStatements, onBrowseTodos, onViewComments, onViewFiles }) => {
    const { data: currentContact } = useContact(contactID);
    const [{ areaContext }] = useStateValue();
    const [readAlerts, setReadAlerts] = useLocalStorageState(
        `${areaContext.area}-read-alerts`,
        []
    );
    const [showAlertDrawer, setShowAlertDrawer] = useState(false);
    const { currentUser } = useContext(UserContext);
    const { mutate: addTag } = useAddContactTag();
    const { mutate: removeTag } = useRemoveContactTag();
    const [ref] = useDimensions();
    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const [isAnagraphical, setIsAnagraphical] = useState(false);

    const tagsOnContacts = useUserPermission('TagsOnContacts', currentContact && currentContact.Users.map(user => user.UserID));
    const alertOnClients = useUserPermission('AlertOnClients', currentContact && currentContact.Users.map(user => user.UserID));
    const alertOnAgencies = useUserPermission('AlertOnAgencies', currentContact && currentContact.Users.map(user => user.UserID));
    const alertOnServices = useUserPermission('AlertOnServices', currentContact && currentContact.Users.map(user => user.UserID));
    
    const alertOn = useMemo(() => {
        if (currentContact) {
            if (currentContact.SubType === 1) return alertOnClients;
            if (currentContact.SubType === 2) return alertOnAgencies;
            if (currentContact.SubType === 3) return alertOnServices;
        }
        return false;
    }, [currentContact, alertOnClients, alertOnAgencies, alertOnServices]);

    const handleOpenAlertDrawer = () => {
        setShowAlertDrawer(true);
    };

    const handleCloseAlertDrawer = (updated) => {
        if (updated) {
            const alerts = readAlerts.filter(alert => alert.Author !== currentUser.UserID || alert.ContactID !== currentContact.ID);
            setReadAlerts(alerts);
            saveAlerts(alerts);
        }
        setShowAlertDrawer(false);
    };

    // const handleOpenHistory = () => {
    //     setIsOpenHistory(true);
    // };

    const handleCloseHistory = () => {
        setIsOpenHistory(false);
    };

    const handleOpenAnagraphical = () => {
        setIsAnagraphical(true);
    };

    const CustomerNameLine = () => (
        <FlexContainer fullWidth column alignItems="start">
            <FlexContainer wrap="wrap">
                <div className="name" onClick={handleOpenAnagraphical}>{currentContact.Name}</div>
                <span className="name-icon">
                    <AddToFolderButton ids={[currentContact.ID]} type="customer" size='lg' showBadge />
                </span>
                <span className="name-icon">
                    {alertOn ? 
                        <NwIcon
                            icon={light("comment-alt-exclamation")}
                            plus={!currentContact.Alert}
                            edit={currentContact.Alert}
                            pointer
                            onClick={handleOpenAlertDrawer}
                        />
                        : 
                        <ContactAlert
                            readOnly
                            contactId={currentContact.ID}
                            icon={
                                <NwIcon
                                    size='lg' 
                                    icon={light("comment-alt-exclamation")}
                                    pointer
                                />
                            }
                        />
                    }
                </span>
                <span className="name-icon">
                    <PermissionBlock
                        permission={tagsOnContacts}
                        content={
                            <TagManagerDrawer
                                readOnly={!tagsOnContacts}
                                itemType={currentContact.ContactType}
                                tags={currentContact.Tags}
                                onAddTag={tagsOnContacts && onAddTag}
                                onRemoveTag={tagsOnContacts && onRemoveTag}
                            />
                        }
                    />
                </span>
            </FlexContainer>
        </FlexContainer>
    );

    const onAddTag = (tag) => {
        addTag({ tag: tag, contactID: currentContact.ID });
    };

    const onRemoveTag = (tag) => {
        removeTag({ tag: tag, contactID: currentContact.ID });
    };

    const getCustomerPageHeader = () => (
        <CustomerPageHeader
            title={CustomerNameLine()}
            subTitle={false}
        >
            <div className="header-content">
                <div className="header-body">
                    <div className="header-body-boxes-container">
                        <div className="header-body-boxes header-body-boxes-hoverable">
                            <ContactUsers
                                contactID={currentContact.ID}
                                users={currentContact.Users}
                            />
                        </div>
                       <NwDividerVertical type ="vertical" style={{marginTop: "5px"}}/>
                        <div>
                            <ContactHeaderBillingInfos type="customer" currentContact={currentContact} />
                        </div>
                       <NwDividerVertical type ="vertical" style={{marginTop: "5px"}}/>
                        <ContactDetailsBankAccounts contactId={currentContact.ID} />
                    </div>
                </div>
            </div>
        </CustomerPageHeader>
    );

    return !currentContact ? (
        <Loading />
    ) : (
        <>
            <div ref={ref} className="details-header-container no-sidebar">
                <ContactAlert contactId={currentContact.ID}>
                    {getCustomerPageHeader()}
                </ContactAlert>
            </div>
            {showAlertDrawer && (
                <AlertDrawer
                    contactID={currentContact.ID}
                    onClose={handleCloseAlertDrawer}
                />
            )}
            {isOpenHistory && (
                <NwDrawer title="Customer Logs" onClose={handleCloseHistory}>
                    <ObjectLogHistories
                        objectId={currentContact.ID}
                        type="Customer"
                    />
                </NwDrawer>
            )}
            {isAnagraphical && 
                <AnagraphicalDrawer 
                    contactID={currentContact.ID}
                    type={currentContact.ContactType === 'Customer' ? 0 : 1} 
                    onClose={() => setIsAnagraphical(false)} />
            }
        </>
    );
};

export default withRouter(CustomerDetailsHeaderDrawer);
