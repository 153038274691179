import React, { useContext } from "react";
import Select from "Components/Gui/NWForm/CustomSelect";
import { Badge } from 'antd';
import { ListContext } from 'Contexts/ListContext';


const FilterEventsOnSubTypes = ({ onChange, value, eventType, onBlur }) => {
    const { cachedList } = useContext(ListContext);

    const eventSubtypesOptions =
        cachedList.eventSubtypes.items.filter(item => item.Type === eventType && !item.Disabled).map(type => {
            return {
                label:
                    <div style={{ display: 'flex' }}>
                        <Badge color={`#${type.BackColor}`} style={{ marginRight: ".5em" }} />
                        {type.Name}
                    </div>,
                value: type.Code
            };
        })


    const onSubTypeChange = (val) => {
        onChange({
            ...value,
            eventSubtypeCode: [...val]
        });
    }

    return (
        <Select
            value={value.eventSubtypeCode || []}
            style={{ width: '100%' }}
            onChange={onSubTypeChange}
            options={eventSubtypesOptions}
            onBlur={onBlur}
            placeholder={`All ${eventType} types`}
            mode="multiple"
            allowClear
        />
    );
};

export default FilterEventsOnSubTypes;
