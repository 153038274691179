import React from "react";
import styled from 'styled-components';

import useEventStyle from 'Hooks/UseEventStyle';
import { getContactName } from 'Hooks/Contact/UseContactsList';

const EventContentInner = styled.div`
    color: ${props => props.color && props.color};
    background: ${props => props.backgroundColor && props.backgroundColor};
    height: 100%;
    box-shadow: ${props => props.theme.SelectedShadow};
    padding: 4px 8px;
    border: 1px solid white;
    border-radius: 4px;
`;

const BookingEventCard = ({ event }) => {  
    const eventstyle = useEventStyle(event.eventData, event.eventData.TypeName)

    return (
        <EventContentInner  
            className='event'    
            color={eventstyle && eventstyle.color}
            backgroundColor={eventstyle && eventstyle.backgroundColor}
        >  
            {getContactName(event.ModelID)}
        </EventContentInner>
    )
}

export default BookingEventCard
