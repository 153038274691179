import React, { useState, useMemo, useContext } from 'react'
import styled from 'styled-components';
import { Tabs, List, Empty } from 'antd';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwAddButton, NwButton } from "Components/Gui/NwButton";
import ModelAgenciesDrawer from 'Components/ModelAgencies/ModelAgenciesDrawer';
import ModelAgenciesMap from 'Components/ModelAgencies/ModelAgenciesMap';
import { useContactAgencies, useRemoveAgency } from 'Hooks/Contact/UseContactAgencies';
import { MODEL_AGENCY_TYPES, MODEL_AGENCY_TYPE_ORDERS } from 'Constants/ModelAgencyTypes';
import { ListContext } from 'Contexts/ListContext';
import ModelAgenciesListItem from './ModelAgenciesListItem';
import NwDrawer from 'Components/Gui/NwDrawer';
import EmptyState from 'Components/Gui/EmptyState';
import CustomerDrawer from 'Components/ContactDetails/CustomerDetails/Drawer/CustomerDrawer';
import { forceReloadModels, getModel } from 'Hooks/Contact/UseContactsList';
import { UserContext } from 'Contexts/UserContext';
import Axios from 'axios';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import NwIcon from 'Components/Gui/NwIcon';

const StyledCard = styled(NwCard)`
    .ant-card-body {
        .ant-tabs {
            .ant-tabs-nav {
                margin: 0;
                padding: 0 2rem;
                
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        .ant-tabs-tab {
                            font-size: ${props => props.theme.FontSizeSm};
                            text-transform: uppercase;
                            padding: .5rem 0;
                        }
                    }
                }
            }
        }
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const ModelAgenciesCard = ({ contactID, represented,  drawerMode }) => {
    const { cachedList } = useContext(ListContext);
    const { data: currentContactAgencies } = useContactAgencies(contactID);
    const [viewMode, setViewMode] = useState("LISTING"); // LISTING, INSERTING, EDITING
    const [currentModelAgency, setCurrentModelAgency] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [selectedType, setSelectedType] = useState("All");
    const [showCommissions, setShowCommissions] = useState(false);
    const [previewAgency, setPreviewAgency] = useState(null);

    const { currentUser } = useContext(UserContext);
    const { nwAgId } = currentUser.ActiveContext;

    const { mutate: onRemoveAgency } = useRemoveAgency();

    let motherExist = false;
    let exclusions = [];
    if (currentContactAgencies) {
        currentContactAgencies.forEach(el => {
            if (el.TypeName === "Mother" && !el.Dropped) motherExist = true;
            exclusions.push(el.AgencyID);
        });
    }

    const handleCloseDrawer = () => {
        setCurrentModelAgency(null);
        setViewMode('LISTING');
    };

    const handleEditModelAgency = modelAgency => {
        setCurrentModelAgency(modelAgency);
        setViewMode('EDITING');
    };

    const handleRemoveModelAgency = modelAgency => {
        onRemoveAgency({ ModelAgencyID: modelAgency.ID, AgencyID: modelAgency.AgencyID, ModelID: contactID });
    };

    const handleCloseCommissionDrawer = () => {
        setShowCommissions(false);
    }

    const setAsRepresented = async () => {
        await Axios.post('/models/setRepresented', {ModelID: contactID, Represented: true, NetworkAgencyID: nwAgId})
        forceReloadModels()
        forceReloadContact(contactID)
    }

    const setAsNotRepresented = async () => {
        await Axios.post('/models/setRepresented', {ModelID: contactID, Represented: false, NetworkAgencyID: nwAgId})
        forceReloadModels()
        forceReloadContact(contactID)
    }

    const modelAgencies = useMemo(() => {
        let modelAgenciesList = [];
        if (currentContactAgencies) {
            modelAgenciesList = currentContactAgencies.filter(modelagency => {
                if (modelagency.TypeName === selectedType || selectedType === 'All') {
                    return true;
                }
                return false;
            });
            modelAgenciesList.sort((modelagency1, modelagency2) => modelagency1.AgencyName > modelagency2.AgencyName ? 1 : -1);
            modelAgenciesList.sort((modelagency1, modelagency2) => MODEL_AGENCY_TYPE_ORDERS[modelagency1.TypeName] >= MODEL_AGENCY_TYPE_ORDERS[modelagency2.TypeName] ? 1 : -1);
            modelAgenciesList.sort((modelagency1, modelagency2) => modelagency1.Dropped - modelagency2.Dropped);
            modelAgenciesList = modelAgenciesList.map((modelagency, index) => {
                return {
                    ...modelagency,
                    Label: cachedList.modelAgencyLabels.items.find(agencyLabel => agencyLabel.ID === modelagency.ModelAgencyLabelID)
                }
            })
        }
        return modelAgenciesList;
    }, [currentContactAgencies, selectedType]);

    const onChangeTab = (key) => {
        setSelectedType(key)
    }

    const handleShowMap = () => {
        setShowMap(true);
    }

    const handleHideMap = () => {
        setShowMap(false);
    }

    return (
        <>
            <StyledCard
                title="Model Agencies"
                icon={light('globe')}
                fullheight
                extra={
                    <ButtonsContainer>
                        {/* {motherExist
                            ?
                            <NwIcon style={{marginRight: ".5rem", cursor: "not-allowed"}} disabled icon={light('medal')} tooltip="There's a mother agency, you can't set this model as represented" />
                            : */}
                            {represented
                                ?
                                <NwButton ghost icon = { light('handshake-slash') } label = "set as not represented" tooltip = "Set this model as not represented" onClick = { setAsNotRepresented } />
                                :
                                <NwButton ghost icon = { light('medal') } label = "set as represented" tooltip = "Set this model as represented" onClick = { setAsRepresented } />
                            }
                        {/* } */}
                        <NwButton ghost icon={regular('map-location-dot')} label="map" onClick={handleShowMap} />
                        <NwAddButton
                            className='add-button'
                            ghost
                            onClick={() => {
                                setViewMode("INSERTING");
                            }}
                            label="Add"
                        />
                    </ButtonsContainer>
                }
                padding="no">
                <Tabs
                    defaultActiveKey={selectedType}
                    onChange={onChangeTab}
                    items={
                        MODEL_AGENCY_TYPES.map((modagtype, tabIndex) => {
                            return ({
                                key: modagtype.value,
                                label: modagtype.value
                            })
                        })
                    }
                />
                {modelAgencies.length === 0
                    ?
                    <EmptyState message="no agencies" style={{ marginTop: "2.8rem" }} />
                    :
                    <List
                        itemLayout="horizontal"
                        dataSource={modelAgencies}
                        renderItem={item => {
                            return (
                                <ModelAgenciesListItem
                                    modelAgency={item}
                                    handleEdit={handleEditModelAgency}
                                    handleRemove={handleRemoveModelAgency}
                                    handleCommissions={() => setShowCommissions(true)}
                                    onNameClick={() => setPreviewAgency(item.AgencyID)}
                                />
                            );
                        }}
                    />
                }

            </StyledCard>
            {showMap && <ModelAgenciesMap agencies={currentContactAgencies} onClose={handleHideMap} />}
            {(viewMode === 'INSERTING' || viewMode === 'EDITING') &&
                <ModelAgenciesDrawer
                    viewMode={viewMode}
                    exclusions={exclusions}
                    motherExist={motherExist}
                    contactID={contactID}
                    currentModelAgency={currentModelAgency}
                    onClose={handleCloseDrawer}
                    represented={represented}
                />
            }
            {previewAgency &&
                <CustomerDrawer customerID={previewAgency} onClose={() => setPreviewAgency(null)} />
            }
            {showCommissions &&
                <NwDrawer title='Commissions' onClose={handleCloseCommissionDrawer} />
            }
        </>
    )
}

export default ModelAgenciesCard
