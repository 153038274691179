import React from "react";
import styled from 'styled-components';
import { Row, Col, Switch } from "antd";
import { Formik, Field } from "formik";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useStateValue } from 'Contexts/StateProvider';
import { validateVisibility } from 'Libs/NwUtils';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const Container = styled.div`
    .meeting-period {
        .ant-form-item-control-input-content {
            display: flex;
            flex-wrap: wrap;
        }
    }
`;

const SwitchContainer = styled.div`
    display: flex;
    margin-right: 16px;
`;

const StyledSwitch = styled(Switch)`
    margin-right: 8px;
`;

const TodoContactsContainer = styled.div`
    display: flex;
    .ant-form-item-control {
        max-width: ${props => props.maxWidth && `${props.maxWidth}px`};
    }
`;

const FieldContainer = styled.div`
    .ant-form-item-control {
        max-width: ${props => props.maxWidth && `${props.maxWidth}px`};
    }
`;

const ContactSelectorContainer = styled.div`
    display: ${props => props.personal && 'none'};
    width: 100%;
`;

const EditEvent = ({ submitForm, initialValues, validationSchema, validate, dataSource, disableReinitialize, onCancel, renderOtherModels, mg, onTouchForm }) => {
    const [{ areaContext }] = useStateValue();
    const breakpoints = useNwBreakPoints();

    const beforeSubmit = async (values, setStatus, setSubmitting) => {
        await submitForm(values, setStatus, setSubmitting);
        if (onTouchForm) {
            onTouchForm(false);
        }
    };

    const validateContact = (contactID, personal) => () => {
        if (!contactID && !personal) {
            return 'Please select contact';
        }
        return null;
    };

    const handleCancel = () => {
        if (onTouchForm) {
            onTouchForm(false);
        }
        onCancel();
    };

    return (
        <Container className="event-form" mg={mg}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={disableReinitialize ? false : true}
                validate={validate}
                validationSchema={validationSchema}
                onSubmit={(values, {setStatus, setSubmitting}) => {
                    setStatus();
                    beforeSubmit(values, setStatus, setSubmitting);
                }}>
                {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <NwForm 
                        small 
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Row gutter={10}>
                            {dataSource && dataSource.map((fieldData, index) => {
                                let fieldProps = {};
                                fieldProps.component = fieldData.component;
                                fieldProps.label = fieldData.label;
                                fieldProps.name = fieldData.name;
                                fieldProps.type = fieldData.type;
                                fieldProps.value = values[fieldData.name];
                                fieldProps.required = fieldData.required;
                                fieldProps.validate = fieldData.validateDates;
                                fieldProps.disabled = fieldData.disabled;
                                fieldProps.defaultValue = fieldData.defaultValue;
                                fieldProps.text = fieldData.text;
                                if (fieldData.projectId) fieldProps.projectId = fieldData.projectId;
                                if (fieldData.defaultAddress) fieldProps.defaultAddress = fieldData.defaultAddress;
                                if (fieldData.validateAddress) fieldProps.validateAddress = fieldData.validateAddress;
                                if (fieldData.readOnly) fieldProps.readOnly = fieldData.readOnly;
                                if (fieldData.activeTypes) fieldProps.activeTypes = fieldData.activeTypes;
                                if (fieldData.activableTypes) fieldProps.activableTypes = fieldData.activableTypes;
                                if (fieldData.eventMode) {
                                    fieldProps.eventMode = fieldData.eventMode;
                                    if (values[fieldData.name]) {
                                        fieldProps.defaultStartDate = values[fieldData.name].defaultStartDate;
                                        fieldProps.defaultEndDate = values[fieldData.name].defaultEndDate;
                                    }
                                } 
                                if (fieldData.maxLength) fieldProps.maxLength = fieldData.maxLength;
                                if (fieldData.options) fieldProps.options = fieldData.options;
                                if (fieldData.buttonStyle) fieldProps.buttonStyle = fieldData.buttonStyle;
                                if (fieldData.displayLabel) fieldProps.displayLabel = fieldData.displayLabel;
                                if (fieldData.visibilityValidate) fieldProps.validate = validateVisibility(values.Visibility, areaContext.area);
                                if (fieldData.onAfterChange) fieldProps.onAfterChange = fieldData.onAfterChange;
                                if (fieldData.validate) fieldProps.validate = fieldData.validate;
                                if (fieldData.timeOnly) fieldProps.timeOnly = fieldData.timeOnly;
                                if (fieldData.dateOnly) fieldProps.dateOnly = fieldData.dateOnly;
                                if (fieldData.labels) fieldProps.labels = fieldData.labels;
                                if (fieldData.labelMinWidth) fieldProps.labelMinWidth = fieldData.labelMinWidth;
                                if (fieldData.modelId) fieldProps.modelId = fieldData.modelId;
                                if (fieldData.eventType) fieldProps.eventType = fieldData.eventType;
                                if (fieldData.enableDates) fieldProps.enableDates = fieldData.enableDates;
                                if (fieldData.allDaySelectable) fieldProps.allDaySelectable = fieldData.allDaySelectable;
                                if (fieldData.singleDay) fieldProps.singleDay = fieldData.singleDay;
                                if (fieldData.customerId) fieldProps.customerId = fieldData.customerId;
                                if (fieldData.autoSize) fieldProps.autoSize = fieldData.autoSize;
                                if (fieldData.projectCustomerId) fieldProps.projectCustomerId = fieldData.projectCustomerId;
                                if (fieldData.customerId === "ServiceID") fieldProps.customerId = values.ServiceID;
                                return (fieldData.todosEvent ? 
                                    <Col span={24} key={fieldData.name}>
                                        <TodoContactsContainer maxWidth={fieldData.maxWidth}>
                                            <SwitchContainer>
                                                <StyledSwitch 
                                                    disabled={fieldProps.disabled} 
                                                    checked={values.Personal} 
                                                    onChange={checked => {
                                                        setFieldValue('Personal', checked);
                                                        if (checked) {
                                                            setFieldValue('ContactID', null);
                                                        }
                                                    }} />
                                                Personal
                                            </SwitchContainer>
                                            <ContactSelectorContainer personal={values.Personal}>
                                                <Field {...fieldProps} validate={validateContact(values.ContactID, values.Personal)} />
                                            </ContactSelectorContainer>
                                        </TodoContactsContainer>
                                    </Col>
                                    :<Col span={breakpoints === 1 ? 24 : (fieldData.Col?fieldData.Col:12)}  key={fieldData.name}>
                                        <FieldContainer maxWidth={fieldData.maxWidth} className={fieldData.className ? fieldData.className : ''}>
                                            <Field {...fieldProps} 
                                                onAfterChange={value => fieldProps.onAfterChange && fieldProps.onAfterChange(values, setFieldValue, value)} />
                                        </FieldContainer>
                                    </Col>
                                )
                            })}
                        </Row>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={handleCancel}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            } />
                        {renderOtherModels && renderOtherModels}
                    </NwForm>
                )}
            </Formik>
        </Container>
    );
};

export default EditEvent;
