import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const getProjectPackages = projectId => (
    Axios.post('/packages/search', { projectID: projectId, sentOnly: false })
)

const useProjectPackages = projectId => {
    return useQuery(["projectPackages", projectId], () => getProjectPackages(projectId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: projectId ? true : false
    });
};

const forceReloadProjectPackages = projectId => {
    return queryClient.invalidateQueries(['projectPackages', projectId])
}

export {
    useProjectPackages,
    forceReloadProjectPackages
};