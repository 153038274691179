import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";

import {
  NWSwitch,
  NWInput,
  NWSelect,
  NWTextAreaCharCounter,
  NWBooleanSelector,
  NWNumericRangeSelector,
  NWRangePicker
} from "Components/Gui/NWForm/NwFormItems";
import { toISODate } from "Libs/NwMoment";

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ant-form-item {
      margin-bottom: 16px;
  }

  margin-bottom: 16px;
`;
const FilterLabel = styled.div`
  min-width: 160px;
  text-align: left;
`;

const FilterSwitch = styled.div`
  margin: -2px 10px 0;
`;
const FilterController = styled.div`
  //width: 350px;
  padding: 0 10px;
  flex: 1 0 0;
`;

const convertToMoment = (value) => {
    if (Array.isArray(value)) {
        const newarray = value.map(v => getMoment(v))
        return newarray
    }
    return value
}

const convertToDates = (value) => {
    if (Array.isArray(value)) {
        const newarray = value.map(v => toISODate(v))
        return newarray
    }
    return value
}


const CustomPropertyFilter = ({ label, type, name, values, customField, onChange }) => {
    let checked;
    let switchName;
    let filterName;
    let filterValue;
  
    checked = values[`custom_fields_${name}`].active;
    switchName = `custom_fields_${name}.active`;
    const existingValue = values[`custom_fields_${name}`].value;
    
    switch (type) {
        case 'Date':
            filterValue = existingValue ? existingValue : null;
            break;
        case 'Choice':
        case 'MultipleChoice':
            filterValue = existingValue ? (Array.isArray(existingValue) ? existingValue : [ existingValue ]) : [];
            break;
        case 'Numeric':
            filterValue = existingValue ? (Array.isArray(existingValue) ? existingValue : [ existingValue, existingValue ]) : [];
            break;
        default:
            filterValue = values[`custom_fields_${name}`].value;
    }
    filterName = `custom_fields_${name}.value`;

    const getFromArray = (array) => {
        if (Array.isArray(array)) {
            return array[0]
        }
        return array
    }

    const singleValueArray = (value) => {
        return [ value ]
    }

    
    //TODO: gestire con doppio controllo min/max
    // const rangeValueArray = (value) => {
    //     return [ value, value ]
    // }


    const renderComponent = () => {
        switch (type) {
            case 'String':
                return <Field
                    component={NWInput}
                    type='text'
                    value={getFromArray(filterValue)}
                    name={filterName}
                    onAfterChange={value => {
                        onChange('value', singleValueArray(value), true)
                    }}
                />
            case 'MultipleLineString':
                return <Field
                    component={NWTextAreaCharCounter}
                    value={getFromArray(filterValue)}
                    name={filterName}
                    type="text"
                    maxLength={2000}
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    onAfterChange={value => {
                        onChange('value', singleValueArray(value), true)
                    }}
                />
            case 'Choice':
                //let valuetoarray = (filterValue ? (Array.isArray(filterValue) ? filterValue.split('||') : [filterValue]) : [])
                // let valuetouse = [];
                // if (!Array.isArray(filterValue)) {
                //     console.log("non array")
                //     if (filterValue) {
                //         console.log("ha un valore")
                //         if (filterValue.replace(/\s/g, '').length) {
                //             console.log("non è vuoto")
                //             if (filterValue.indexOf('||') > -1) {
                //                 console.log("ha ||")
                //                 const splitvalue = filterValue.split('||')
                //                 valuetouse.push(...splitvalue)
                //             } else {
                //                 valuetouse.push(filterValue)
                //             }
                //         }
                //     }
                // }
                // console.log("valuetouse", valuetouse)
                // console.log("filterValue - choice", filterValue)
                return <Field
                    component={NWSelect}
                    mode='multiple'
                    value={filterValue}
                    name={filterName}
                    options={customField.Options.map(option => {
                        return { key: option, label: option, value: option };
                    })}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onAfterChange={value => {
                        // let arrayvalue = value
                        // if (arrayvalue.length > 0) {
                        //     arrayvalue = arrayvalue.filter(function (str) {
                        //         if (str.replace(/\s/g, '').length) {
                        //             return true
                        //         }
                        //         return false
                        //     })
                        // }
                        //onChange('value', arrayvalue.join('||'), true)
                        onChange('value', value, true)
                    }}
                />  
            case 'MultipleChoice':
                return <Field
                    component={NWSelect}
                    value={filterValue}
                    name={filterName}
                    mode='multiple'
                    options={customField.Options.map(option => {
                        return { key: option, label: option, value: option };
                    })}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onAfterChange={value => {
                        // let arrayvalue = value
                        // if (arrayvalue.length > 0) {
                        //     arrayvalue = arrayvalue.filter(function (str) {
                        //         if (str.replace(/\s/g, '').length) {
                        //             return true
                        //         }
                        //         return false
                        //     })
                        // }
                        // onChange('value', arrayvalue.join('||'), true)
                        onChange('value', value, true)
                    }}
                />  
            case 'Boolean':               
                return <Field
                    component={NWBooleanSelector}
                    value={getFromArray(filterValue)}
                    name={filterName}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onAfterChange={value => {
                        onChange('value', singleValueArray(value), true)
                    }}
                />
            case 'Numeric':
                return <Field
                    component={NWNumericRangeSelector}
                    value={filterValue}
                    name={filterName}
                    onAfterChange={value => {
                        onChange('value', value, true)
                    }}
                />
                // return <Field
                //     component={NWInputNumber}
                //     value={getFromArray(filterValue)}
                //     name={filterName}
                //     onAfterChange={value => {
                //         onChange('value', rangeValueArray(value), true)
                //     }}
                // />
            case 'Date':
                return <Field
                    component={NWRangePicker}
                    //value={getFromArray(filterValue)}
                    value={convertToMoment(filterValue)}
                    name={filterName}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onAfterChange={value => {
                        onChange('value', convertToDates(value), true)
                        //onChange('value', singleValueArray(value), true)
                    }}
                />
            default:
                return <div />
        }
    }
    return (
        <FilterRow>
            <FilterSwitch>
                <Field
                    component={NWSwitch}
                    size='small'
                    checked={checked}
                    name={switchName}
                    onAfterChange={value => onChange('active', value)}
                />
            </FilterSwitch>
            <FilterLabel>{label}</FilterLabel>
            <FilterController>
                {renderComponent()}
            </FilterController>
        </FilterRow>
    );
};

export default CustomPropertyFilter;