import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import NwDrawer from 'Components/Gui/NwDrawer';
import { NWTextAreaCharCounter, NWInput, NWPrivacyLevelSelector, NWRangePicker, NWBookerSelector } from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { useUpdateTravelPlan, useAddTravelPlan } from 'Hooks/TravelPlan/UseTravelPlan';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';

const TravelPlanAddEditDrawer = ({ travelPlan, onTravelPlanCreated, onClose }) => {
    useFormFieldFocus('Name');
    const { mutateAsync: onUpdateTravelPlan } = useUpdateTravelPlan();
    const { mutateAsync: onAddTravelPlan } = useAddTravelPlan();
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = {
        Name: travelPlan ? travelPlan.Name : '',
        Description: travelPlan ? travelPlan.Description : '',
        Period: travelPlan && [getMoment(travelPlan.FirstDate), getMoment(travelPlan.LastDate)],
        PrivacyLevel: travelPlan ? travelPlan.PrivacyLevelName : 'Public',
        Bookers: []
    };
    
    const saveTravelPlan = async (values, setSubmitting) => {
        setSubmitting(true);
        if (travelPlan) {
            const travelPlanData = [
                { name: 'Name', value: values.Name, changed: true, errormessage: '', updated: true },
                { name: 'Description', value: values.Description, changed: true, errormessage: '', updated: true },
                { name: 'PrivacyLevel', value: values.PrivacyLevel, changed: true, errormessage: '', updated: true },
                { name: 'FirstDate', value: values.Period[0], changed: true, errormessage: '', updated: true },
                { name: 'LastDate', value: values.Period[1], changed: true, errormessage: '', updated: true },
            ];
    
            try {
                await onUpdateTravelPlan({ travelPlanId: travelPlan.ID, data: travelPlanData });
                setTouchForm(false);
                onClose();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Save Travel Plan Error => ', error);
                setSubmitting(false);
            }
        } else {
            const travelPlanData = {
                ...values,
                FirstDate: values.Period[0],
                LastDate: values.Period[1]
            };
            try {
                const response = await onAddTravelPlan({data: travelPlanData });
                setTouchForm(false);
                onTravelPlanCreated(response.ID);
                onClose();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Save Travel Plan Error => ', error);
                setSubmitting(false);
            }
        }
    };

    return (
        <NwDrawer 
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title={travelPlan ? 'Edit Travel Plan' : 'Add Travel Plan'} 
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveTravelPlan(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Content is required"),
                    Period: Yup.string().required("Content is required"),
                })}
                render={({
                    isSubmitting,
                    values,
                    handleSubmit
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Name"
                            name="Name"
                            type="text"
                            id='Name'
                            value={values.Name}
                        />
                        {!travelPlan && 
                            <Field
                                component={NWBookerSelector}
                                label="Bookers"
                                name="Bookers"
                                mode='multiple'
                                disableClosable
                                value={values.Bookers}
                                validate={bookers => {
                                    if (bookers.length === 0) {
                                        return 'Please select at least one booker'
                                    }
                                }}
                            /> 
                        }
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Description"
                            name="Description"
                            type="text"
                            value={values.Description}
                            maxLength={2000}
                            autoSize={{ minRows: 5, maxRows: 10 }}
                        />
                        <Field
                            component={NWPrivacyLevelSelector}
                            label="Privacy Level"
                            name="PrivacyLevel"
                            value={values.PrivacyLevel}
                        />
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWRangePicker}
                                    label="Period"
                                    name='Period'
                                    format="MMM DD, YYYY"
                                    style={{ width: "100%" }}
                                    value={values.Period}
                                />
                            </Col>
                        </Row>
                        &nbsp;
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </NwDrawer>
    );
};

export default TravelPlanAddEditDrawer;