import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getTravel = travelId => (
    Axios.get(`/travels/${travelId}`)
);

const useTravel = travelId => {
    return useQuery(["travel", travelId], () => getTravel(travelId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: travelId ? true : false
    });
};

const forceReloadTravel = (ID) => {
    queryClient.invalidateQueries(['travel', ID])
}

const onDeleteTravel = ({ ID }) => (
    Axios.delete(`/travels/${ID}`)
)

const onDeleteTravelGroup = ({ GroupGUID }) => (
    Axios.delete(`/travels/deletegroup/${GroupGUID}`)
)

const useDeleteTravel = () => {
    return useMutation(onDeleteTravel, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.travelId) {
                forceReloadTravel(variables.travelId);
            }
        }
    })
}

const useDeleteTravelGroup = () => {
    return useMutation(onDeleteTravelGroup, {
        onSuccess: (data, variables) => {
            // if (variables.ID !== variables.travelId) {
            //     forceReloadTravel(variables.travelId);
            // }
        }
    })
}

const onUpdateTravel = ({ travelData }) => (
    Axios.put('/travels', travelData)
);

const useUpdateTravel = () => {
    return useMutation(onUpdateTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.travelData.ID);
        }
    })
}

const onMoveTravel = ({ travelData }) => (
    Axios.put('/travels/MoveEvent', travelData)
);

const useMoveTravel = () => {
    return useMutation(onMoveTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.travelData.ID);
        }
    })
};

const onMoveGroupedTravel = ({ travelData }) => (
    Axios.put('/travels/MoveGroupedEvent', travelData)
);

const useMoveGroupedTravel = () => {
    return useMutation(onMoveGroupedTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.travelData.ID);
        }
    })
};

const onAddTravel = ({ travelData }) => (
    Axios.post('/travels', travelData)
);

const useAddTravel = () => {
    return useMutation(onAddTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(data.ID);
        }
    })
};

const onCancelTravel = ({ ID }) => (
    Axios.put(`/travels/cancellation/${ID}`)
);

const useCancelTravel = () => {
    return useMutation(onCancelTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.ID);
        }
    })
};


const onUpdateAcknowledgment = ({ ID }) => (
    Axios.put(`/travels/acknowledgment/${ID}`)
)

const useUpdateTravelAcknowledgment = () => {
    return useMutation(onUpdateAcknowledgment, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.ID);
        }
    })
};

const onCloneModelsTravel = ({ data }) => (
    Axios.post(`/travels/CloneForModels`, data)
);

const useCloneModelsTravel = () => {
    return useMutation(onCloneModelsTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.data.EventID);
        }
    })
};

const onUpdateGroupedIndividualTravel = ({ travelData }) => (
    Axios.put('/travels/GroupedIndividual', travelData)
);

const useUpdateGroupedIndividualTravel = () => {
    return useMutation(onUpdateGroupedIndividualTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.travelData.ID);
        }
    })
};

const onUpdateGroupedCommonTravel = ({ travelData }) => (
    Axios.put('/travels/GroupedCommon', travelData)
);

const useUpdateGroupedCommonTravel = () => {
    return useMutation(onUpdateGroupedCommonTravel, {
        onSuccess: (data, variables) => {
            forceReloadTravel(variables.travelData.ID);
        }
    })
}

export {
    useTravel,
    useDeleteTravel,
    useDeleteTravelGroup,
    useUpdateTravel,
    useAddTravel,
    forceReloadTravel,
    useUpdateTravelAcknowledgment,
    useCancelTravel,
    getTravel,
    useCloneModelsTravel,
    useUpdateGroupedIndividualTravel,
    useUpdateGroupedCommonTravel,
    useMoveTravel,
    useMoveGroupedTravel
};