import React, { useState } from 'react'
import styled from 'styled-components';

import { getUrlDocument } from "Libs/NwUtils";
import constants from 'Constants';
import NwIcon from 'Components/Gui/NwIcon';
import VideoPlayer from 'Components/Gui/VideoPlayer';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .arrow-icon {
        font-size: 48px;
        &:hover {
            color: black;
        }
    }
`;

const MediaContainer = styled.div`
    text-align: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    &.media-container-image {
        height: calc(100vh - 250px);
    }

    &.media-container-video {
        height: calc(100vh - 250px);
        width: 100%;
    }

    img {
        opacity: ${props => props.noDisplay && 0};
        height: ${(props) => props.imgType === 'portrait' && `100%`};
        width: ${(props) => props.imgType === 'landscape' && `100%`};

        box-shadow: 0px 2px 8px rgb(0 0 0 / 40%);
    }    
`;

const DocumentItemThumbStyle = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  svg {
    width: 50% !important;
    height: auto;
  }
`;

const MediaPreview = ({
    document,
    onSelectMediaBefore,
    onSelectMediaAfter
}) => {
    const [dimensions, setDimensions] = useState(null);
  
    const onImgLoad = ({target:img}) => {
        setDimensions({height: img.offsetHeight, width: img.offsetWidth});
    };

    // const GetUrlMedia = (document, getPrivateUrl, getPublicUrl) => {
    //     if (document.TypeName === 'Document' || document.TypeName === 'Legal') {
    //         return getPrivateUrl(document);
    //     } else {
    //         return getPublicUrl(document);
    //     }
    // };

    const renderMedia = () => {
        if (document.Provider === 'Youtube' || document.Provider === 'Vimeo' || constants.FileTypes.video.find(fileType => document.Extension.includes(fileType))) {
            return (
                <MediaContainer className="media-container-video">
                    <VideoPlayer document={document} />
                </MediaContainer>
            );
        } else if (constants.FileTypes.image.find(fileType => document.Extension.includes(fileType))) {
            return (
                <MediaContainer className="media-container-image" 
                    noDisplay={!dimensions ? true : false}
                    imgType={dimensions && dimensions.height >= dimensions.width ? 'portrait' : 'landscape' } >
                    <img 
                        onLoad={onImgLoad}
                        src={document.Standard.Url}
                        //src={GetUrlMedia(document, GetUrlDocument, GetUrlPicture)} 
                        alt=""
                    />
                </MediaContainer>
            );
        } else {
            return (
                <MediaContainer className="media-container-file">
                    <DocumentItemThumbStyle>
                        <NwIcon icon={light("file-pdf")} />
                    </DocumentItemThumbStyle>
                     <div>
                        <a
                            href={getUrlDocument(document)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <NwIcon
                                icon={light("arrow-to-bottom")}
                                style={{ marginRight: 8 }}
                                />
                            <span>Download file</span>
                        </a>
                    </div>
                </MediaContainer>
            );    
        }
    }
    return (
        <Container>
            <NwIcon 
                pointer
                className='arrow-icon'
                icon={light('chevron-left')}
                style={{marginRight: 16}}
                onClick={onSelectMediaBefore}
            />
            {renderMedia()}
            <NwIcon 
                className='arrow-icon'
                icon={light('chevron-right')}
                pointer
                style={{marginLeft: 16}}
                onClick={onSelectMediaAfter}
            />
        </Container>
    )
};

export default MediaPreview;