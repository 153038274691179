import React, { useState, useEffect, } from "react";
import { SwatchesPicker } from 'react-color';
import reactCSS from 'reactcss';

import { GetForeColor } from 'Libs/NwUtils';

const SwatchPicker = ({ value, foreColor, defaultColor, displayedLabel, colorsList, onChange, disabled }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColors] = useState(value ? value : '#000000');

    useEffect(() => {
        setColors(defaultColor ? defaultColor : '#000000');
    }, [defaultColor, foreColor, disabled]);

   const handleClick = () => {
       if (!disabled) {
           setDisplayColorPicker(true);
       }
    };

   const handleClose = () => {
       setDisplayColorPicker(false)
    };

   const handleChange = (color) => {
       setColors(color.hex);
       onChange(color.hex);
    };

    const styles = reactCSS({
        'default': {
            swatch: {
                padding: '5px',
                background: `${color}`,
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                width: '120px',
                height: '32px',
            },
            label: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: `${GetForeColor(colorsList, color)}`
            },
            popover: {
                position: 'fixed',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        },
    });

    return (
        <div>
            <div style={ styles.swatch } onClick={ handleClick }>
                {displayedLabel &&
                    <div style={ styles.label }>{displayedLabel}</div>
                }
            </div>
            { displayColorPicker ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ handleClose }/>
                <SwatchesPicker colors={colorsList} color={ color } onChange={ handleChange } />
            </div> : null }

        </div>
    )

};

export default SwatchPicker;
