import React, { useState, useEffect } from "react";
import { Badge, Radio } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwIcon from "Components/Gui/NwIcon";
import { NwAddButton } from 'Components/Gui/NwButton';
import StyleVariables from 'Components/Gui/StyleVariables';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import EmailForm from 'Components/Connection/EmailForm';
import PhoneForm from 'Components/Connection/PhoneForm';
import { showDateNoTime } from "Libs/NwMoment";

const RadioGroup = styled(Radio.Group)`
    width: 100%;
    display: flex;
    margin-left: 11px;
    flex-direction: column;
    .ant-radio-wrapper {
        width: 100%;
        display: flex;
    }
`;

const ConnectionPreviewCard = ({ recipientMode, item, onAddConnection, onCancel }) => {
    const [connectionIds, setConnectionIds] = useState({
        PhoneID: null,
        EmailID: null 
    });
    const [isAddEmail, setIsAddEmail] = useState(false);
    const [isAddPhone, setIsAddPhone] = useState(false);
    const [emails, setEmails] = useState([...item.Emails]);
    const [phones, setPhones] = useState([...item.Phones]);

    useEffect(() => {
        let EmailID = null;
        let PhoneID = null;
        if (item.Emails.length > 0) {
            EmailID = item.Emails[0].ID;
        }
        if (item.Phones.length > 0) {
            PhoneID = item.Phones[0].ID;
        }
        setConnectionIds({
            EmailID, PhoneID
        });
    }, [item]);

    const handleAddNewEmail = () => {
        setIsAddEmail(true);
    };

    const handleSaveEmail = email => {
        setIsAddEmail(false);
        setEmails(prevEmails => {
            return [...prevEmails, email]
        });
        setConnectionIds(prevConnectionIds => {
            return {
                ...prevConnectionIds,
                EmailID: email.ID
            }
        });
    };

    const handleAddNewPhone = () => {
        setIsAddPhone(true);
    };

    const handleSavePhone = phone => {
        setIsAddPhone(false);
        setPhones(prevPhones => {
            return [...prevPhones, phone]
        });
        setConnectionIds(prevConnectionIds => {
            return {
                ...prevConnectionIds,
                PhoneID: phone.ID
            }
        });
    };
  
    const handleConnectionIdsChange = idType => event => {
        setConnectionIds(prevConnectionIds => ({
            ...prevConnectionIds,
            [idType]: event.target.value
        }));
    };
    
    const handleAddConnection = () => {
        onAddConnection({
            ...item,
            Emails: [...emails],
            Phones: [...phones]
        }, connectionIds);
        onCancel();
    };
    
    if (!item) return null;
    
    const ConnectionCardTitleSecondLine = () => {
        let line = item.SubTypeName || ""
        if (item.Role) {
            if (line.length) {
                line += " - "
            }
            line += item.Role
        }
        return line;
    }

    const ConnectionCardTitle = () => (
        <div className='card-header'>
            <div className='title'>{item.Name} {item.Surname || ""}</div>
            <div className='label'>{ConnectionCardTitleSecondLine()}</div>
        </div>
    )

    const renderTypeIcon = () => {
        switch (item.TypeName) {
            case "Personal":
                return light("home-heart");
            case "Place":
                return light("home-alt");
            case "People":
                return light("user");
            case "Cc":
                return light("user-plus");
            case "Room":
                return light('bed');
            default:
                return "";
        }
    };

    const renderCard = () => {
        return (
            <NwCard 
                title={ConnectionCardTitle()}>
                {item.Note && (
                    <FlexContainer mg='0 24px'>
                        {item.Note}
                    </FlexContainer>
                )}
                {item.Birthday && (
                    <FlexContainer mg='0 24px'>
                        <NwIcon icon={light("birthday-cake")} />&nbsp;{showDateNoTime(item.Birthday)}
                    </FlexContainer>
                )}
                <FlexContainer 
                    pd='8px' 
                    column 
                    alignItems='flex-start'>
                    {emails.length > 0 && 
                        <>
                            <NwDivider>E-mail</NwDivider>
                            <RadioGroup 
                                onChange={handleConnectionIdsChange('EmailID')} 
                                value={connectionIds.EmailID}>
                                {emails.map(email => {
                                    return (
                                        <Radio 
                                            key={`email-${email.ID}`} 
                                            value={email.ID}>
                                            {email.Address}
                                        </Radio>
                                    )
                                })}
                                <Radio 
                                    disabled={recipientMode}
                                    value={null}>
                                    no e-mail
                                </Radio>
                            </RadioGroup>
                            <FlexContainer mg='16px 0 0'>
                                {isAddEmail ? 
                                    <EmailForm 
                                        connectionID={item.ID}
                                        contactID={item.ContactID}
                                        onCancel={() => setIsAddEmail(false)}
                                        onSave={handleSaveEmail}
                                        onTouchForm={() => {}} />
                                    : 
                                    <NwAddButton label='add new' ghost onClick={handleAddNewEmail} />
                                }
                            </FlexContainer>
                        </>
                    }
                    {phones.length > 0 && 
                        <>
                            <NwDivider>Phone</NwDivider>
                            <RadioGroup 
                                onChange={handleConnectionIdsChange('PhoneID')} 
                                value={connectionIds.PhoneID}>
                                {phones.map(phone => {
                                    return (
                                        <Radio 
                                            key={`phone-${phone.ID}`} 
                                            value={phone.ID}>
                                            {phone.Number}
                                        </Radio>
                                    )
                                })}
                                <Radio 
                                    value={null}>
                                    no phone
                                </Radio>
                            </RadioGroup>
                            <FlexContainer mg='16px 0 0'>
                                {isAddPhone ? 
                                    <PhoneForm 
                                        connectionID={item.ID}
                                        contactID={item.ContactID}
                                        onCancel={() => setIsAddPhone(false)}
                                        onSave={handleSavePhone}
                                        onTouchForm={() => {}} />
                                    : 
                                    <NwAddButton label='add new' ghost onClick={handleAddNewPhone} />
                                }
                            </FlexContainer>
                        </>
                    }
                    <FlexContainer 
                        mg='8px 0 0'
                        fullWidth 
                        justifyContent='center'>
                        <NwAddButton disabled={isAddEmail || isAddPhone} primary label='Add Connection' onClick={handleAddConnection} />
                    </FlexContainer>
                </FlexContainer>
            </NwCard>
        )
    }

    return (
        <Badge.Ribbon
            color={item.TypeName === "Personal" ? StyleVariables.DangerColor : StyleVariables.PrimaryColor}
            text={
                <NwIcon icon={renderTypeIcon()} size='sm' />
            }
            placement="start"
        >   
            <div className='drawer-content'>
                {renderCard()}
            </div>
        </Badge.Ribbon>
    );
};

export default ConnectionPreviewCard;
