export const STATEMENT_FILTERS_SETS = {
    activeSet: "v0",
    customSets: [],
    filterType: ("SAVED_SEARCH"),
    staticSets: [
        {
            id: "v0",
            name: "",
            fixed: true,
            filters: []
        }
    ]
}