import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import { ListContext } from 'Contexts/ListContext';
import Axios from 'axios';
import { forceReloadProjectStatements, useProjectStatements } from 'Hooks/Project/UseProjectStatements';
import { useMemo } from 'react';
import { useEffect } from 'react';
import InvoiceItem from './InvoiceItem';
import InvoiceDrawer from 'Components/Invoices/InvoiceDrawer';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import { useUserPermission } from 'Contexts/UserContext';
import EmptyState from 'Components/Gui/EmptyState';

const Wrapper = styled.div`

`

const ProjectInvoicesCard = ({ projectId }) => {
    //const { data: currentProject } = useProject(projectId);
    const { data: statements, isFetching: loading } = useProjectStatements(projectId, "Client")
    const [invoices, setInvoices] = useState([]);
    const { cachedList } = useContext(ListContext);
    const paymentTerms = cachedList.paymentTerms.items;
    const bankAccounts = cachedList.bankAccounts.items;
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const accessAccounting = useUserPermission('AccessAccounting');

    const handleInvoiceClick = (invoiceID) => {
        if (accessAccounting) {
            setSelectedInvoice(invoiceID);
        }
    }

    const handleCloseInvoiceDrawer = () => {
        forceReloadProjectStatements(projectId, "Client");
        forceReloadProjectAccountingDetails(projectId);
        forceReloadProjectTransactions(projectId);
        setSelectedInvoice(null);
    }

    const invoiceIDs = useMemo(() => {
        let invoiceIDs = [];
        if (statements && statements.length) {
            for (const contacts of statements) {
                for (const billinfo of contacts.BillingInfos) {
                    for (const transaction of billinfo.Transactions) {
                        for (const statement of transaction.Statements) {
                            if (statement.InvoiceID && !invoiceIDs.includes(statement.InvoiceID)) {
                                invoiceIDs.push(statement.InvoiceID)
                            }
                        }
                    }
                }
            }
        }
        return invoiceIDs
    }, [statements]);

    useEffect(() => {
        const getInvoice = async (invoiceID) => {
            try {
                const response = await Axios.get(`invoices/${invoiceID}`);
                return response
            } catch (error) {
                console.log(error);
                return null
            }
        }

        if (invoiceIDs && invoiceIDs.length) {
            setInvoices([]);
            for (const invoiceID of invoiceIDs) {
                getInvoice(invoiceID).then((response) => {
                    if (response) {
                        setInvoices((invoices) => [...invoices, response])
                    }
                })
            }
        }
    }, [invoiceIDs]);

    return (
        <NwCard
            title='Invoices'
            icon={light('file-invoice')}
        >
            {/* <BuildingFeature /> */}
            {(invoices && invoices.length > 0)
                ?
                <Wrapper>
                    {invoices.map((invoice) => (
                        <InvoiceItem
                            key={invoice.ID}
                            invoice={invoice}
                            paymentTerms={paymentTerms}
                            bankAccounts={bankAccounts}
                            onInvoiceClick={handleInvoiceClick}
                            hasAccountingAccess={accessAccounting}
                        />
                    ))}
                    {selectedInvoice &&
                        <InvoiceDrawer invoiceId={selectedInvoice} onClose={() => handleCloseInvoiceDrawer(null)} />
                    }
                </Wrapper>
                :
                <Wrapper>
                    <EmptyState message="No invoices" />
                </Wrapper>

            }
        </NwCard>
    );
}

export default ProjectInvoicesCard;