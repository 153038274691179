import { Tabs } from 'antd';
import styled from 'styled-components';

// FEATURESDRAWER.JS

export const FeatureSetContainer = styled.div`
    padding: 0 1.6rem;
`

// DRAGGABLETABS.JS

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {
        background-color: #fff;

        .ant-tabs-nav-wrap {
            padding-left: 2rem;
        }
    }
`

// FEATURESGROUP.JS

export const FeatureGroupContainer = styled.div`
  margin-bottom: 4rem;
`

export const FeatureBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .feature-box {
    width: 25%;
    background-color: #fff;
    border-right: 1rem solid ${props => props.theme.LayoutBodyBackground};
    border-bottom: 1rem solid ${props => props.theme.LayoutBodyBackground};
    box-sizing: border-box;
    
    &.span-2 {
      width: 50%;
    }
    &.span-4 {
      width: 100%;
    }
    &.active {
      background-color: ${props => props.theme.WarningColor};
    }


    .feature-box-header {
        border-bottom: 1px solid ${props => props.theme.LayoutBodyBackground};
        padding: .5em;
        text-transform: uppercase;
        font-size: .8rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .feature-box-content {
      padding: 0;
      width: 100%;
      min-height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .feature-value {
        font-size: 1.4em;
        line-height: 1.1em;
        font-weight: 500;
        text-align: center;
        padding: .5em 1em 0 1em;
        flex: 1;
        width: 100%;

        &.text-medium {
          font-size: 1.2em;
        }
        &.text-small {
          font-size: 1em;
          font-weight: 300;
          text-align: left;
          padding: .5em 1em;
        }
        &.text-list {
          em {
            display: block;
            line-height: 1.2em;
            font-style: normal;
            text-align: left;
            
          }
        }
      }
    }

    &.feature-conversion {
      .feature-box-content {
        justify-content: space-between;

        .feature-value {
          width: 100%;
          //flex: 2 0 0;
        }

        .feature-metrics {
          width: 100%;
          //flex: 1 0 0;
          display: flex;
          justify-content: space-between;
          border-top: ${prop => prop.theme.DefaultBorder};
          
          span {
            flex: 1 0 0;
            border-right: ${prop => prop.theme.DefaultBorder};
            font-size: .6em;
            text-transform: uppercase;
            text-align: center;
            padding: 1px 0;
            cursor: pointer;

            &:last-child {
              border-right: none;
            }

            &.active-metric {
              background-color: ${prop => prop.theme.PrimaryColor};
              color: #fff;
            }
          }
        }
      }
    }
  }
`;

export const FeatureContentWrapper = styled.div`
  margin-bottom: 4px;

  span {
      font-weight: bold;
  }
`;