import React from "react";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const PaymentStatusSelector = ({value, disabled, onChange, onBlur}) => {
    return (
        <RadioButtonGroup 
            value={value} 
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue='NotPaid'
            options={[
                { label: 'Not Paid', value: 'NotPaid'},
                // { label: 'Partially Paid', value: 'PartiallyPaid'},
                { label: 'Paid', value: 'Paid'}
            ]} />
    );
};

export default PaymentStatusSelector;
