import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const onGetProjectCommentsByID = ID => (
    Axios.get(`/projects/comments/${ID}`)
)

const useProjectComments = (ID) => {
    return useQuery(["projectcomments", ID], () => onGetProjectCommentsByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false
    });
}


const forceReloadProjectComments = (ID) => {
    return queryClient.invalidateQueries(['projectcomments', ID])
}

export {
    useProjectComments,
    forceReloadProjectComments
};


