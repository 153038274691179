import React, { useState } from 'react';
import { Tooltip } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';

import FlexContainer from 'Components/Gui/FlexContainer';
import { getNow, getTime, showDateNoTime } from 'Libs/NwMoment';
import { NwLinkButton } from 'Components/Gui/NwButton';
import ModelAvailability from 'Components/EventDatesPicker/ModelAvailability';
import OptionSelector from 'Components/OptionSelector/OptionSelector';
import EventPeriodPicker from 'Components/EventDatesPicker/EventPeriodPicker';
import { enumerateDaysBetweenDates } from 'Libs/NwUtils';

const Container = styled.div`
    margin-bottom: 24px;
`;

const DateLabel = styled.div`
    width: 80px;
`;

// const getDate = date => {
//     return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// };

const DatePicker = ({ value, disableOptions, modelId, removable, disabledDates, eventType, onChange, onRemove, useDoubleTime, timeError }) => {
    const { startDate, endDate, dates } = value;
    const [globalOptionNumber, setGlobalOptionNumber] = useState(0);

    const handleUpdateOptionNumber = index => optionNumber => {
        const newDates = [...dates];
        newDates[index].optionNumber = optionNumber;
        onChange({
            ...value,
            dates: newDates
        });
    };

    const handleUpdateGlobalOptionNumber = optionNumber => {
        setGlobalOptionNumber(optionNumber);
        onChange({
            ...value,
            dates: [...dates].map(date => {
                return {
                    date: date.date,
                    optionNumber: optionNumber
                }
            })
        });
    };

    return (
        <Container>
            <EventPeriodPicker
                value={value}
                removable={removable}
                disabledDates={disabledDates}
                onChange={onChange}
                onRemove={onRemove}
                useDoubleTime={useDoubleTime}
                timeError={timeError}
            />
            {dates.length > 1 && eventType === 'option' && !disableOptions ?
                <>
                    <FlexContainer justifyContent='space-between' mg='0 0 0 80px'>
                        <div>Set all days to:</div>
                        <OptionSelector
                            modelId={modelId}
                            dates={dates.map(date => date.date)}
                            optionNumber={globalOptionNumber}
                            onUpdateOptionNumber={handleUpdateGlobalOptionNumber} />
                    </FlexContainer>
                    {dates.map((date, index) => (
                        <FlexContainer fullWidth mg='8px 0 0' key={`${showDateNoTime(date.date)}`}>
                            <DateLabel>{showDateNoTime(getMoment(date.date))}</DateLabel>
                            <FlexContainer fullWidth justifyContent='space-between'>
                                {modelId &&
                                    <ModelAvailability startDate={date.date} endDate={date.date} modelId={modelId} />
                                }
                                <OptionSelector
                                    modelId={modelId}
                                    dates={[date.date]}
                                    optionNumber={date.optionNumber}
                                    onUpdateOptionNumber={handleUpdateOptionNumber(index)} />
                            </FlexContainer>
                        </FlexContainer>
                    ))}
                </>
                :
                <FlexContainer justifyContent='space-between'>
                    {modelId &&
                        <ModelAvailability startDate={startDate} endDate={endDate} modelId={modelId} />
                    }
                    {eventType === 'option' && !disableOptions &&
                        <OptionSelector
                            modelId={modelId}
                            dates={[dates[0].date]}
                            optionNumber={dates[0].optionNumber}
                            onUpdateOptionNumber={handleUpdateOptionNumber(0)} />
                    }
                </FlexContainer>
            }
        </Container>
    )
};

const getMaxDate = dates => {
    let maxDate = dates[0];
    for (const date of dates) {
        if (maxDate.endDate < date.endDate) {
            maxDate = date;
        }
    }
    return maxDate;
};

const EventDatesPicker = ({ value: dates, disableOptions, modelId, eventType, onChange, useDoubleTime, timeErrors }) => {
    
    const handleAddDatePeriod = () => {
        const maxDate = getMaxDate(dates)
        const endDate = maxDate.endDate.clone();
        endDate.add(1, 'days');
        //const startBreakTime = (useDoubleTime && maxDate.startBreak) ? getTime(getNow().hour(maxDate.startBreak[0]).minute(maxDate.startBreak[1])) : null;
        //const endBreakTime = (useDoubleTime && maxDate.endBreak) ? getTime(getNow().hour(maxDate.endBreak[0]).minute(maxDate.endBreak[1])) : null;
        //to avoid problems I always start a new period without breaks
        const startBreakTime = [0,0]
        const endBreakTime = [0,0]

        onChange([...dates, {
            startDate: endDate,
            endDate: endDate,
            startTime: getTime(getNow().hour(maxDate.startTime[0]).minute(maxDate.startTime[1])),
            endTime: getTime(getNow().hour(maxDate.endTime[0]).minute(maxDate.endTime[1])),
            startBreak: startBreakTime,
            endBreak: endBreakTime,
            allDay: maxDate.allDay,
            dates: [{
                date: endDate,
                optionNumber: null
            }]
        }]);
    }

    const handleDateChange = (index, date) => {
        //let newDates = [...dates];
        //newDates[index] = date;
        const newDates = []
        for (let i = 0; i < dates.length; i++) {
            if (i === index) {
                newDates.push(date)
            } else {
                newDates.push(dates[i])
            }
        }
        onChange(newDates);
    };

    const handleDateRemove = (index) => {
        const removedDates = [...dates];
        removedDates.splice(index, 1);
        onChange(removedDates);
    }

    const getDisabledDates = (dateIndex) => {
        let disabledDates = [];
        dates.map((date, index) => {
            if (dateIndex !== index) {
                disabledDates = [...disabledDates, ...enumerateDaysBetweenDates(date.startDate, date.endDate)]
            }
            return null;
        });

        return disabledDates;
    };

    return (
        <>
            {dates.map((date, index) => {
                return (
                    <DatePicker
                        key={`evnet-date-${index}`}
                        value={date}
                        disabledDates={getDisabledDates(index)}
                        modelId={modelId}
                        eventType={eventType}
                        disableOptions={disableOptions}
                        removable={index !== 0}
                        onChange={(value) => handleDateChange(index, value)}
                        onRemove={() => handleDateRemove(index)} 
                        useDoubleTime={useDoubleTime}
                        timeError={timeErrors && timeErrors[index] ? timeErrors[index] : null}
                    />
                );
            })}
            <FlexContainer justifyContent='flex-end' mg='8px 0 0'>
                <Tooltip title="Click here to add several dates or periods">
                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <NwLinkButton label='add another date/period' onClick={handleAddDatePeriod} />
                    </div>
                </Tooltip>
            </FlexContainer>
        </>
    )
};

export default EventDatesPicker;