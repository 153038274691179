import React from 'react';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { Loading } from 'Components/Gui/Loading';
import ModelsTable from 'Components/ModelSelector/ModelsWall/ModelsTable/ModelsTable';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import { WallContainer } from './ModelsTableUI';
import { NWRadioButtonGroup } from 'Components/Gui/NWForm/NwFormItems';
import { useState } from 'react';
import RadioButtonGroup from 'Components/Gui/NWForm/RadioButtonGroup';

const ModelsTableWrapper = ({
    currentEvent,
    currentPackage,
    footer,
    header,
    isDrawer,
    isLoading,
    isMobile,
    modelEventsInfo,
    models,
    multipleSelection,
    onSelectModel,
    packageGroup,
    selectedList,
    scope,
    showModelDrawerOnClick,
    toggleModelAddToSelectedList,
    updateSelectedList,
}) => {

    const isEventJob = currentEvent && currentEvent.TypeName === 'Job'

    const [addModelsAs, setAddModelsAs] = useState((isEventJob && currentEvent.Confirmed === 2) ? 10 : 0);

    return (
        <>
            <WallContainer>
                {header}
                {isLoading ?
                    <Loading loadingType="centered" />
                    :
                    (models.length ?
                        <NwCard className="modeltable-card-wrapper" padding={isDrawer ? "no" : ""}>
                            {(scope === 'event' && isEventJob) &&
                            <div className="add-models-as">
                                add models as
                                &nbsp;&nbsp; 
                                <RadioButtonGroup 
                                    buttonStyle="solid"
                                    value={addModelsAs}
                                    options={[
                                        {key: 0, value: 0, label: 'option'},
                                        {key: 10, value: 10, label: 'confirmed'},
                                    ]}
                                    onChange={value => {
                                        setAddModelsAs(value)
                                    }}
                                />
                            </div>
                            }
                            <ModelsTable
                                currentEvent={currentEvent}
                                currentPackage={currentPackage}
                                isDrawer={isDrawer}
                                isMobile={isMobile}
                                list={models}
                                modelEventsInfo={modelEventsInfo}
                                multipleSelection={multipleSelection}
                                onModelClick={onSelectModel}
                                onSelect={updateSelectedList}
                                onSelectModel={toggleModelAddToSelectedList}
                                packageGroup={packageGroup}
                                selectedList={selectedList}
                                showModelDrawerOnClick={showModelDrawerOnClick}
                                scope={scope}
                                addModelsAs={addModelsAs}
                            />
                        </NwCard>
                        : <CenteredBlock>
                            <EmptyState message="no models fitting your search" />
                        </CenteredBlock>
                    )
                }
            </WallContainer>
            {footer}
        </>
    )
};

export default ModelsTableWrapper;
