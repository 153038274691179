import React from "react";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { showDateNoTime, showDate, showTime, isAllDay } from "Libs/NwMoment";

const VerticalPeriodContainer = styled.div`
    padding: ${props => props.$noPadding ? '0' : '.75rem 0'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;


    .period-date {
        font-size: 1rem;
        text-transform: uppercase;
    }

    .period-arrow {
        font-size: 1rem;
        padding: .1rem 0;
    }

    .no-period {
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
    }

    &.vertical-period-container-small {
        padding: ${props => props.$noPadding ? '0' : '.25rem 0'};
        
        .period-date {
            font-size: ${props => props.theme.FontSizeSm};
        }
        .period-arrow {
            font-size: ${props => props.theme.FontSizeSm};
            padding: 0;
        }
    }

    &.vertical-period-container-big {
        .period-date {
            font-size: 1.25rem;
        }
        .period-arrow {
            font-size: 1.25rem;
        }
    }
`

const VerticalPeriod = ({ startDate, endDate, size, emptyText, noPadding, time, hideDate }) => {

    let className = "vertical-period-container"
    if (size) {
        className += ` vertical-period-container-${size}`
    }

    if (startDate && endDate) {
        if (time) {
            const st_string = startDate;
            const et_string = endDate;
            const st = getMoment(st_string);
            const et = getMoment(et_string);
            if (et && st.isSame(et, 'day')) {
                //day on top (if not hidden), time with arrow (if not allday)
                if (isAllDay(st, et)) {
                    return (
                        <VerticalPeriodContainer className={className} $noPadding={noPadding}>
                            {!hideDate && <div className='period-date'><strong>{showDateNoTime(startDate)}</strong></div>}
                            <div className='period-date'>ALL<br />DAY</div>
                        </VerticalPeriodContainer>
                    )
                } else {
                    return (
                        <VerticalPeriodContainer className={className} $noPadding={noPadding}>
                            {!hideDate && <div className='period-date'><strong>{showDateNoTime(startDate)}</strong></div>}
                            <div className='period-date'>{showTime(startDate)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-down')} /></div>
                            <div className='period-date'>{showTime(endDate)}</div>
                        </VerticalPeriodContainer>
                    )
                }
            } else {
                //start day and time then arrow the end day and time
                if (isAllDay(st, et)) {
                    return (
                        <VerticalPeriodContainer $noPadding={noPadding} className={className}>
                            <div className='period-date'>{showDateNoTime(startDate)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-down')} /></div>
                            <div className='period-date'>{showDateNoTime(endDate)}</div>
                        </VerticalPeriodContainer>
                    )
                } else {
                    return (
                        <VerticalPeriodContainer $noPadding={noPadding} className={className}>
                            <div className='period-date'>{showDate(startDate)}</div>
                            <div className='period-arrow'><NwIcon icon={light('arrow-down')} /></div>
                            <div className='period-date'>{showDate(endDate)}</div>
                        </VerticalPeriodContainer>
                    )
                }

            }
        } else {
            return (
                <VerticalPeriodContainer $noPadding={noPadding} className={className}>
                    <div className='period-date'><strong>{showDateNoTime(startDate)}</strong></div>
                    <div className='period-arrow'><NwIcon icon={light('arrow-down')} /></div>
                    <div className='period-date'><strong>{showDateNoTime(endDate)}</strong></div>
                </VerticalPeriodContainer>
            )
        }
    } else {
        return (
            <VerticalPeriodContainer $noPadding={noPadding} className="vertical-period-container">
                <div className="no-period">
                    {emptyText
                        ?
                        <>{emptyText}</>
                        :
                        <>no<br />period</>
                    }
                </div>
            </VerticalPeriodContainer>
        )
    }
}

export default VerticalPeriod