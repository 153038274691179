import React from "react";
import { Select } from "antd";

import { useGlobalFeatures } from 'Hooks/Features/UseFeatures';
import RadioButtonGroup from 'Components/Gui/NWForm/RadioButtonGroup';

const Option = Select.Option;

const FeaturesMultichoice = ({
    onChange,
    value,
    disabled,
    itemid,
}) => {

    const { data: globalFeatures } = useGlobalFeatures();
    const feature = globalFeatures.find(feature => feature.ID === itemid);

    let choices = feature ? feature.Choices.map(choice => {
        return {
            ...choice,
            value: choice.ID,
            label: choice.Value
        }
    }) : [];

    const handleChoiceChange = choices => {
        onChange({
            ...value,
            choices: choices
        });
    };

    const handleOperatorChange = operator => {
        onChange({
            ...value,
            operator: operator
        });
    };

    return (
        <div>
            <Select
                autoComplete='off'
                mode="multiple"
                value={value.choices}
                placeholder="Please select"
                onChange={handleChoiceChange}
                disabled={disabled}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                style={{ width: "100%", marginBottom: '0.5rem' }}>
                {choices.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)}
            </Select>
            <RadioButtonGroup 
                value={value.operator} 
                onChange={handleOperatorChange} 
                size='small' 
                options={[
                    { label: 'All values', value: 'and' },
                    { label: 'At least one of these values', value: 'or'}
                ]} />
        </div>
    );
};

export default FeaturesMultichoice;
