export const TASKS_FILTERS = {
    reducerPrefix: 'TaskFilters',
    sections: [
        {name: 'period'},
        {name: 'details'}
    ],
    filters: [
        {
            name: 'DueDate',
            label: 'Due Date',
            area: 'period',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period',
        },
        {
            name: 'Added',
            label: 'Added',
            area: 'period',
            defaultval: '{last30days}',
            commontype: 'period',
            type: 'period',
        },
        {
            name: 'UserID',
            label: 'Created by',
            area: 'details',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'contactID',
            label: 'Contact',
            area: 'details',
            defaultval: undefined,
            commontype: 'customer',
            type: 'contactList'
        },
        {
            name: 'projectClientID',
            label: 'Project Client',
            area: 'details',
            defaultval: undefined,
            commontype: 'customer',
            type: 'clientList'
        },
        {
            name: 'Status',
            label: 'Status',
            area: 'details',
            defaultval: false,
            commontype: 'status',
            type: 'status-selector'
        },
    ]
};