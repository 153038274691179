import React from 'react';
import useEventStyle from 'Hooks/UseEventStyle';
import NwIcon from '../Gui/NwIcon';
import { EventTag } from './EventDetailsUI';


const ModelEventTypeTag = ({ event }) => {
	const eventstyle = useEventStyle(event, event.EventType)
	
	const renderOptionNumber = () => {
		if (event.EventType === "Job" && event.OptionNumber && event.OptionNumber > 0 && event.OptionNumber < 10) {
			return `(${event.OptionNumber})`
		}
		return '';
	};

	if (!eventstyle) {
		return null;
	}

	const tagStyle = { background: eventstyle.backgroundColor, color: eventstyle.color };
	return (
		<EventTag style={tagStyle}>
			{eventstyle.icon && <NwIcon icon={eventstyle.icon} />}{eventstyle.name} {renderOptionNumber()}
		</EventTag>
	);
};

export default ModelEventTypeTag;
