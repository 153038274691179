import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';

const savePackagesSmartList = async customs => {
    try {
        await Axios.post('/userclientconfigurations', {
            Name: 'package-smart-list',
            JsonData: JSON.stringify({
                smartList: customs
            })
        });
    } catch (error) {
        console.log('ant : Save Packages Smart List Error => ', error);
    }
};

const PackageFiltersReducer = (state, action) => {
    switch (action.type) {
        case "PackageFiltersChangeActiveSet":
            ls.set("nw_config_packagewallfilter", {
                ...state,
                activeSet: action.newActiveSet,
                filterType: action.filterType
            });
            return {
                ...state,
                activeSet: action.newActiveSet,
                filterType: action.filterType
            };
        case "PackageFiltersAddCustomSet":
            const customSetCopy = Array.isArray(state.customSets) ? [...state.customSets] : [];
            customSetCopy.push(action.newSet);
            const data = {
                ...state,
                customSets: customSetCopy,
                activeSet: action.newSet.id
            }
            ls.set("nw_config_packagewallfilter", data);
            let smartList = [...data.customSets];
            savePackagesSmartList(smartList);
            return {
                ...state,
                customSets: customSetCopy,
                activeSet: action.newSet.id
            };
        case "PackageFiltersEditCustomSet":
            let customSetCopy2 = state.customSets.filter(
                el => el.id !== action.newSet.id
            );
            action.newSet.id = Date.now();
            customSetCopy2.push(action.newSet);
            const editData = {
                ...state,
                customSets: customSetCopy2,
                activeSet: action.newSet.id
            };
            ls.set("nw_config_packagewallfilter", editData);
            let editSmartList = editData.customSets
            savePackagesSmartList(editSmartList);
            return {
                ...state,
                customSets: customSetCopy2,
                activeSet: action.newSet.id
            };
        case "PackageFiltersCloneSet":
            const copyOfActive = _.cloneDeep(action.activeSet);
            copyOfActive.id = Date.now();
            copyOfActive.fixed = false;
            copyOfActive.name = copyOfActive.name + "(copy)";
            const copyOfCustomSets = [...state.customSets];
            copyOfCustomSets.push(copyOfActive);
            const cloneData = {
                ...state,
                customSets: copyOfCustomSets,
                activeSet: copyOfActive.id
            };
            ls.set("nw_config_packagewallfilter", cloneData);
            let cloneSmartList = [...cloneData.customSets];
            savePackagesSmartList(cloneSmartList);
            return {
                ...state,
                customSets: copyOfCustomSets,
                activeSet: copyOfActive.id
            };
        case "PackageFiltersDeleteSet":
            const newCustomSets = [...state.customSets].filter(
                el => el.id !== action.activeSet
            );
            const deleteData = {
                ...state,
                customSets: newCustomSets,
                activeSet: 'v1'
            };
            ls.set("nw_config_packagewallfilter", deleteData);
            let deleteSmartList = [...deleteData.customSets];
            savePackagesSmartList(deleteSmartList);
            return {
                ...state,
                customSets: newCustomSets,
                activeSet: 'v1'
            };
        case "PackageSaveCustomSets":
            let newFilterData = { ...state, customSets: action.customSets };
            ls.set("nw_config_packagewallfilter", newFilterData);
            return newFilterData;
        default:
            return state;
    }
};

export default PackageFiltersReducer;
