import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NWTextAreaCharCounter } from "Components/Gui/NWForm/NwFormItems";
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import { Loading } from 'Components/Gui/Loading';
import Axios from 'axios';

const Container = styled.div`
    padding: 8px 0;
    //border-bottom: 1px solid ${props => props.theme.LighterBorderColor};

    .transaction-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;

        .transaction-details-left {
            flex: 1 1 0;

            .transaction-title {
                margin-bottom: .5rem;
            }
            p {
                margin-bottom: .5rem;
            }
        }
        .transaction-details-right {
            flex: 0 0 4rem;
        }
    }
`;


const TransactionStatementDescriptionForm = ({ transaction, statement, onUpdateTransaction }) => {
    const [touchForm, setTouchForm] = useState(false);

    const submitForm = async (values, setStatus, setSubmitting) => {
        setSubmitting(true);
        let data = {
            TransactionID: transaction.ID,
            StatementID: statement.ID,
            description: values.Description
        };

        try {
            await Axios.put(`/transactions/${transaction.Family}/modifyStatementDescription`, data)
            forceReloadTransaction(transaction.ID);
            onUpdateTransaction();
            setTouchForm(false);
            setSubmitting(false);
        } catch (error) {
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            setSubmitting(false);
            console.log("errore", error);
        }
    };
    
    let initialValues = {
        Description: statement ? statement.Description : '',
    };

    return (
        statement
            ?
            <Container>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        submitForm(values, setStatus, setSubmitting);
                    }}>
                    {({ isSubmitting, values, handleSubmit }) => {
                        return (
                            <NwForm
                                values={values}
                                onTouchForm={setTouchForm}
                                onFinish={handleSubmit}
                                layout="vertical">
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Description"
                                    name="Description"
                                    type="text"
                                    value={values.Description}
                                    maxLength={2000}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setTouchForm(false);
                                                onUpdateTransaction();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            htmlType="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                            </NwForm>
                        )
                    }}
                </Formik>
            </Container>
            :
            <Loading />
    );
};

export default TransactionStatementDescriptionForm; 