import React from "react";
import { Switch } from "antd";
import { timeArrayToString, timeStringToArray } from "Libs/NwMoment";
import TimePicker from 'Components/EventDateTimePicker/TimePicker';
import styled from 'styled-components';

const TimeContainer = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  flex: 1 1 0;

  .switch-container {
    padding-right: .75rem;
    span {
      margin-left: 5px;
    }
  }
  .time-values {
    display: flex;
    align-items: center;
    
    > div {
      margin-left: 5px;
    }
  }
`;

const TimeRangePicker = ({ fields, values, methods }) => {

    const { startTimeFieldName, endTimeFieldName, allDayFieldName } = fields
    const { startTime, endTime } = values
    
    const { setFieldValue, onSetAllDay } = methods

    //const baseDate = date ? getMoment(date).format(dateFormat) : getMoment().format(dateFormat);

    const onChangeStartTime = (value) => {
        setFieldValue(startTimeFieldName, timeStringToArray(value));
    };
    const onChangeEndTime = (value) => {
        setFieldValue(endTimeFieldName, timeStringToArray(value));
    };

    const handleSetAllDay = (value) => {
        if (value) {
            setFieldValue(startTimeFieldName, [0, 0]);
            setFieldValue(endTimeFieldName, [23, 59]);
        } else {
            setFieldValue(startTimeFieldName, [9, 0]);
            setFieldValue(endTimeFieldName, [11, 0]);
        }
        if (allDayFieldName) {
            setFieldValue(allDayFieldName, value);
        }
        if (onSetAllDay) {
            onSetAllDay(value);
        }
    }

    const isTimesArrayAllDay = (start, end) => {
        return start[0] === 0 && start[1] === 0 && end[0] === 23 && end[1] === 59;
    }

    const isAllDay = () => {
        if (startTime && endTime) {
            return isTimesArrayAllDay(startTime, endTime);
        }
        return true
    }

    return (
        <TimeContainer>
            <div className="switch-container">
                <Switch checked={isAllDay()} onChange={handleSetAllDay} />
                <span>all day</span>
            </div>
            {
                !isAllDay() && (
                    <div className="time-values">
                        <TimePicker
                            label='Start Time'
                            name='startTime'
                            //afterTime={timeArrayToString(endTime)}
                            value={startTime ? timeArrayToString(startTime) : null}
                            onChange={onChangeStartTime}
                        />
                        <TimePicker
                            label='End Time'
                            name='endTime'
                            //beforeTime={timeArrayToString(startTime)}
                            value={endTime ? timeArrayToString(endTime) : null}
                            onChange={onChangeEndTime}
                        />
                    </div>
                )
            }
        </TimeContainer>
    );
};

export default TimeRangePicker;
