import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';
import constants from 'Constants';

const saveModelsSmartList = async customs => {
    try {
        await Axios.post('/userclientconfigurations', {
        Name: 'model-smart-list',
        JsonData: JSON.stringify({
            smartList: customs
        })
      });
    } catch (error) {
        console.log('ant : Save Models Smart List Error => ', error);
    }
};

const ModelFiltersReducer = (state, action) => {
  //console.log("ModelFiltersReducer action", action.type)
  switch (action.type) {
    case "ModelFiltersSetAdvanced":
      let _staticSets = state[action.area].staticSets;
      _staticSets[0].filters = action.filters
      ls.set("nw_config_modelwallfilter", {
          ...state,
          [action.area]: {
            ...state[action.area],
            staticSets: _staticSets,
          }
      });
      return {
        ...state,
        [action.area]: {
            ...state[action.area],
            staticSets: _staticSets,
        }
      };
    case "ModelFiltersChangeActiveSet":
      ls.set("nw_config_modelwallfilter", {
          ...state,
          [action.area]: {
            ...state[action.area],
            activeSet: action.newActiveSet,
            filterType: action.filterType
          }
      });
      return {
        ...state,
        [action.area]: {
            ...state[action.area],
            activeSet: action.newActiveSet,
            filterType: action.filterType
        }
      };
    case "ModelFiltersAddCustomSet":
      const customSetCopy = [...state[action.area].customSets];
      customSetCopy.push(action.newSet);
      const data = {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: customSetCopy,
          activeSet: action.newSet.id
        }
      }
      ls.set("nw_config_modelwallfilter", data);
      
      let smartList = {};
      for (const area of constants.AREAS) {
          smartList = {
              ...smartList,
              [area]: data[area].customSets
          }
      }
      saveModelsSmartList(smartList);
      return {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: customSetCopy,
          activeSet: action.newSet.id
        }
      };
    case "ModelFiltersEditCustomSet":
      let customSetCopy2 = state[action.area].customSets.filter(
        el => el.id !== action.newSet.id
      );
      action.newSet.id = Date.now();
      customSetCopy2.push(action.newSet);
      const editData = {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: customSetCopy2,
          activeSet: action.newSet.id
        }
      };

      ls.set("nw_config_modelwallfilter", editData);
      let editSmartList = {};
      for (const area of constants.AREAS) {
        editSmartList = {
              ...editSmartList,
              [area]: editData[area].customSets
          }
      }

      saveModelsSmartList(editSmartList);
      return {
        ...state,
        [action.area]: {
            ...state[action.area],
            customSets: customSetCopy2,
            activeSet: action.newSet.id
        }
      };
    case "ModelFiltersCloneSet":
      const copyOfActive = _.cloneDeep(action.activeSet);
      copyOfActive.id = Date.now();
      copyOfActive.fixed = false;
      copyOfActive.name = copyOfActive.name + "(copy)";
      const copyOfCustomSets = [...state[action.area].customSets];
      copyOfCustomSets.push(copyOfActive);

      const cloneData = {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: copyOfCustomSets,
          activeSet: copyOfActive.id
        }
      };

      ls.set("nw_config_modelwallfilter", cloneData);
      let cloneSmartList = {};
      for (const area of constants.AREAS) {
        cloneSmartList = {
            ...cloneSmartList,
            [area]: cloneData[area].customSets
        }
      }

      saveModelsSmartList(cloneSmartList);
      return {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: copyOfCustomSets,
          activeSet: copyOfActive.id
        }
      };
    case "ModelFiltersDeleteSet":
      const newCustomSets = [...state[action.area].customSets].filter(
        el => el.id !== action.activeSet
      );
      const deleteData = {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: newCustomSets,
          activeSet: 'v0'
        }
      };

      ls.set("nw_config_modelwallfilter", deleteData);
      let deleteSmartList = {};
      for (const area of constants.AREAS) {
        deleteSmartList = {
            ...deleteSmartList,
            [area]: deleteData[area].customSets
        }
      }

      saveModelsSmartList(deleteSmartList);
      return {
        ...state,
        [action.area]: {
          ...state[action.area],
          customSets: newCustomSets,
          activeSet: 'v0'
        }
      };
    case "ModelSaveCustomSets":
        let newFilterData = { ...state };

        for (const area of constants.AREAS) {
            newFilterData = {
                ...newFilterData,
                [area]: {
                    ...newFilterData[area],
                    customSets: action.customSets[area]
                }
            }
        }
        ls.set("nw_config_modelwallfilter", newFilterData);
        return newFilterData;
    default:
      //console.log("default del reducer")
      return state;
  }
};

export default ModelFiltersReducer;
