import React from 'react';

import { useUpdateEventCustomFields } from 'Hooks/Event/UseEvent';
import CustomFieldsForm from 'Components/CustomFields/CustomFieldsForm';

const BookingEventCustomFields = ({ event, customFields, onClose, onTouchForm }) => {
    const { mutateAsync: onUpdateEventCustomFields } = useUpdateEventCustomFields();

    const handleSaveCustomFields = async customFields => {
        try {
            await onUpdateEventCustomFields({ eventId: event.ID, data: customFields });
        } catch (error) {
            console.log('ant : Save Event Custom Fields Error => ', error);
        }
    };

    return (
        <CustomFieldsForm 
            object={event}
            customFields={customFields}
            onTouchForm={onTouchForm}
            onClose={onClose}
            onSave={handleSaveCustomFields} />
    );
};

export default BookingEventCustomFields;