import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { duotone, thin } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import styled from 'styled-components';
import MiniLoading from "Components/Gui/MiniLoading"
import { NwButton } from 'Components/Gui/NwButton';

const StyledIcon = styled(NwIcon)`
    cursor: pointer;
    font-size: ${props => props.$pixelSize ? props.$pixelSize : 18}px;
    opacity: .4;
    .fa-primary {
        fill: ${props => props.theme.TextColor};
        opacity: 0;
    }
    .fa-secondary {
        fill: ${props => props.theme.TextColor};
        opacity: .5;
    }

    &.todo-icon-done {
        opacity: 1;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.SuccessColor};
            opacity: 1;
        }
    }

    &.todo-icon-half {
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.PrimaryDark};
            opacity: 1;
        }
        opacity: .5;
    }
`



const ToDoCheck = ({ eventId, completedValue, halfCompletedValue, previewMode, viewStyle, onStatusChanged, action, size }) => {
    const [completed, setCompleted] = useState(completedValue);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setCompleted(completedValue);
    }, [completedValue]);

    const onChangeFunction = (e) => {
        if (!previewMode) {
            setLoading(true);
            e.stopPropagation();
            const completedValueToSend = (completed ? "false" : "true")
            Axios.put(`/todos/setStatus/${eventId}/${completedValueToSend}`)
                .then((data) => {
                    setCompleted(!completed)
                    onStatusChanged();
                    setLoading(false);
                })
                .catch(error => {
                    console.log("error", error);
                    setLoading(false);
                });
        }
    };

    const getClassName = `todo-icon ${completed ? " todo-icon-done" : (halfCompletedValue ? " todo-icon-half" : "")}`
    //const iconType = (completed || halfCompletedValue) ? duotone('circle-check') : duotone('circle');
    const iconType = (completed || halfCompletedValue) ? duotone('circle-check') : thin('circle-check');
    return (
        viewStyle === 'button' ? 
            <NwButton 
                onClick={action || onChangeFunction} 
                label={isLoading ? <MiniLoading />
                : <StyledIcon 
                    className={getClassName} 
                    loading={isLoading ? 1 : 0}
                    icon={iconType}
                    size={size}
                />
            } />
            : isLoading ? <MiniLoading />
            : <StyledIcon 
                className={getClassName} 
                onClick={action || onChangeFunction} 
                loading={isLoading ? 1 : 0}
                icon={iconType}
                $pixelSize={size}
            />
     );

}
export default ToDoCheck;
