import React, { useEffect } from "react";
import { diffInTimeArrays, manipulateTimeArray, timeArrayToString, timeStringToArray } from "Libs/NwMoment";
import TimePicker from 'Components/EventDateTimePicker/TimePicker';
import styled from 'styled-components';

const TimeContainer = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  flex: 1 1 0;

  .switch-container {
    padding-right: .75rem;
    span {
      margin-left: 5px;
    }
  }
  .time-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > span.first-timespan {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
    }    
    > span.second-timespan {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
    }
  }
`;


const DoubleTimeRangePicker = ({ fields, values, methods }) => {

    const { startTimeFieldName, endTimeFieldName, startBreakFieldName, endBreakFieldName } = fields
    const { startTime, endTime, startBreak, endBreak } = values
    const { setFieldValue, onChangeDefaultBreaks } = methods

    const onChangeStartTime = (value) => {
        setFieldValue(startTimeFieldName, timeStringToArray(value));
    };
    const onChangeEndTime = (value) => {
        setFieldValue(endTimeFieldName, timeStringToArray(value));
    };
    const onChangeStartBreak = (value) => {
        setFieldValue(startBreakFieldName, timeStringToArray(value));
    };
    const onChangeEndBreak = (value) => {
        setFieldValue(endBreakFieldName, timeStringToArray(value));
    };

    const hasStartBreakValue = !startBreak || (!startBreak.length || startBreak.length !== 2) || (startBreak[1] === 0 && startBreak[0] === 0) ? false : true
    const hasEndBreakValue = !endBreak || (!endBreak.length || endBreak.length !== 2) || (endBreak[1] === 0 && endBreak[0] === 0) ? false : true


    const setDefaultBreak = () => {
        const timeDiff = diffInTimeArrays(startTime, endTime)
        let defaultBreakStartDiff = [2, 0] //quantity to add (negative to subtract), array position (hour, minute)
        let defaultBreakEndDiff = [-2, 0]
        if (timeDiff[0] < 5) {
            if (timeDiff[0] < 1) {
                defaultBreakStartDiff = null
                defaultBreakEndDiff = null
            } else {
                if (timeDiff[0] < 2) {
                    defaultBreakStartDiff[0] = 15
                    defaultBreakStartDiff[1] = 1
                    defaultBreakEndDiff[0] = -15
                    defaultBreakEndDiff[1] = 1
                } else {
                    defaultBreakStartDiff[0] = 30
                    defaultBreakStartDiff[1] = 1
                    defaultBreakEndDiff[0] = -30
                    defaultBreakEndDiff[1] = 1
                }
            }

        }
        if (defaultBreakStartDiff && defaultBreakEndDiff) {
            const defaultBreakStart = hasStartBreakValue ? startBreak : manipulateTimeArray(startTime, defaultBreakStartDiff[0], defaultBreakStartDiff[1])
            const defaultBreakEnd = hasEndBreakValue ? endBreak : manipulateTimeArray(endTime, defaultBreakEndDiff[0], defaultBreakEndDiff[1])
            if (onChangeDefaultBreaks) {
                console.log("exists onChangeDefaultBreaks")
                onChangeDefaultBreaks(defaultBreakStart, defaultBreakEnd)
            } else {
                onChangeStartBreak(defaultBreakStart)
                onChangeEndBreak(defaultBreakEnd)
            }
        }
    }

    useEffect(() => {
        if (!hasStartBreakValue && !hasEndBreakValue) {
            setDefaultBreak()
        }
    }, [])

    return (
        <TimeContainer>
            <div className="time-values">
                <span className="first-timespan">
                    <TimePicker
                        label='Start Time 1'
                        name='startTime'
                        //afterTime={timeArrayToString(endTime)}
                        value={startTime ? timeArrayToString(startTime) : null}
                        onChange={onChangeStartTime}
                    />
                    <TimePicker
                        label='End Time 1'
                        name='startBreakTime'
                        //beforeTime={timeArrayToString(startTime)}
                        //afterTime={timeArrayToString(endBreak)}
                        value={timeArrayToString(startBreak)}
                        onChange={onChangeStartBreak}
                    />
                </span>
                <span className="second-timespan">
                    <TimePicker
                        label='Start Time 2'
                        name='endBreakTime'
                        //beforeTime={timeArrayToString(startBreak)}
                        //afterTime={timeArrayToString(endTime)}
                        value={timeArrayToString(endBreak)}
                        onChange={onChangeEndBreak}
                    />
                    <TimePicker
                        label='End Time 2'
                        name='endTime'
                        //
                        beforeTime={timeArrayToString(endBreak)}
                        value={timeArrayToString(endTime)}
                        onChange={onChangeEndTime}
                    />
                </span>
            </div>
        </TimeContainer>
    );
};

export default DoubleTimeRangePicker;
