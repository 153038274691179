import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { getContactName, getModel } from 'Hooks/Contact/UseContactsList';
import NwIcon from 'Components/Gui/NwIcon';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwAddButton, NwButton, NwLinkButton, NwRemoveButton } from 'Components/Gui/NwButton';
import ProjectEmailPreview from './ProjectEmailPreview';
import { ModelCover } from 'Components/ModelCover';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import { EmailItem } from './SendProjectDrawerUI';
import SendDrawer from 'Components/SendDrawer/SendDrawer';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwDivider from 'Components/Gui/NwDivider';
import { Checkbox } from 'antd';
import { getFileTypeIcons } from 'Libs/NwUtils';


const EmailToSendItem = ({
    message,
    signature,
    onAddEmail,
    onRemoveEmail,
    onSelect,
    onSelectModelDocument,
    recipient,
}) => {
    const [showPreview, setShowPreview] = useState(false);
    const [showAddressAdd, setShowAddressAdd] = useState(false);

    const handleAddEmail = (email) => {
        onAddEmail(recipient.ModelData.ID, { email: email.Email, ccs: email.CC });
        setShowAddressAdd(false);
    }

    const existingEmails = useMemo(() => {
        if (recipient.Emails.length === 0) {
            return []
        }
        return recipient.Emails.map(email => (
            { Email: email.email.toLowerCase() }
        ));
    }, [recipient.Emails]);

    return (
        <>
            <EmailItem>
                <div className="checkbox">
                    <CustomSwitch
                        checked={recipient.Selected && recipient.Emails.length > 0}
                        disabled={recipient.Emails.length === 0}
                        onChange={(check) => onSelect(recipient.ModelData.ID, check)}
                        checkedChildren={<NwIcon icon={light('check')} />}
                        unCheckedChildren={<NwIcon icon={light('times')} />}
                    />
                </div>
                <div className="model-cover">
                    <ModelCover maxWidth={80} model={recipient.ModelData} classN="model-cover-small" />
                </div>
                <div className="model-details">
                    <h2>{recipient.ModelData.Name.toLowerCase()}</h2>
                    <small>{recipient.DocVersion.EventDatesModels.length} booking events, {recipient.DocVersion.Travels.length} travels, {recipient.DocVersion.FollowUps.length} other activities</small>
                    <div className="mail-addresses-container">
                        {recipient.Emails.length === 0 &&
                            <div className="mail-address warning">
                                <div className="icon-container">
                                    <NwIcon icon={light('triangle-exclamation')} />
                                </div>
                                <p>no e-mail address</p>
                            </div>
                        }
                        {recipient.Emails.map((email, index) => (
                            <div className="mail-address" key={index}>
                                <div className="icon-container">
                                    <NwIcon icon={light('envelope')} />
                                </div>
                                <p>
                                    {email.email}
                                    {email.ccs && email.ccs.length > 0 &&
                                        <span className="ccs">(cc: {email.ccs.join(', ')})</span>
                                    }
                                </p>
                                <NwButton iconOnly minimal icon={solid("circle-xmark")} onClick={() => onRemoveEmail(recipient.ModelData.ID, email.email)} />
                            </div>
                        ))}
                    </div>
                    <p>
                        <NwAddButton ghost onClick={() => setShowAddressAdd(true)} label="add address" />
                    </p>
                    {recipient.Attachments && recipient.Attachments.length > 0 &&
                        <div className="model-documents-container">
                            <NwDivider>Model Attachments</NwDivider>
                            {recipient.Attachments.map((modeldocument) => (
                                <div className="model-document" key={modeldocument.DocumentID}>
                                    <Checkbox checked={modeldocument.Selected} onChange={(e) => onSelectModelDocument(recipient.ModelData.ID, modeldocument.DocumentID, e)}>
                                        <NwIcon icon={getFileTypeIcons(modeldocument.DocumentData.Extension)} />
                                        {modeldocument.DocumentData.Title}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="model-actions">
                    <div className="icon-container">
                        <NwIcon primary pointer icon={light('envelope-open-text')} onClick={() => setShowPreview(true)} />
                    </div>
                    <NwLinkButton onClick={() => setShowPreview(true)} label="preview e-mail" />
                </div>
            </EmailItem>
            {showPreview &&
                <ProjectEmailPreview
                    doc={recipient.DocVersion}
                    message={message}
                    signature={signature}
                    onClose={() => setShowPreview(false)}
                />
            }
            {showAddressAdd &&
                <NwDrawer padding="0px" title='Add Recipients' onClose={() => setShowAddressAdd(false)}>
                    <SendDrawer
                        existingEmails={existingEmails}
                        searchBox
                        onSelectRecipient={handleAddEmail}
                        newContact={false}
                        onCancel={() => setShowAddressAdd(false)}
                    />
                </NwDrawer>
            }
        </>
    );
};

export default EmailToSendItem

