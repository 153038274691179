import React, { useContext } from "react";
import { Row, Col, AutoComplete } from "antd";
import styled from 'styled-components';

import { SelectCountry } from 'Components/Gui/NWForm/SelectCountry';
import { ListContext } from "Contexts/ListContext";
import FlexContainer from 'Components/Gui/FlexContainer';
import StyleVariables from 'Components/Gui/StyleVariables';
import { FormInput } from 'Components/Gui/NWForm/FormInputs';

const Container = styled.div`
    width: 100%;
    
    .ant-input-prefix {
        margin: auto 12px auto 0;
    }

    .ant-input-prefix, .select-wrapper > .prefix {
        color: ${StyleVariables.PrimaryDarkLight} !important;
        font-size: .75rem !important;
        height: 1rem;
        border-right: 1px solid ${StyleVariables.DarkerBorderColor};
        padding-right: .4rem;
        //min-width: 3rem;
        display: flex;
        justify-content: center;
    }

    .small-prefix {
        .ant-input-prefix {
            //min-width: 2rem;
        }
    }

    .long-prefix {
        .ant-input-prefix {
            //min-width: 5rem;
        }
    }

    .ant-row {
        margin-bottom: .75rem;
    }

    .select-wrapper {
        position: relative;
        width: 100%;
        .prefix {
            position: absolute;
            z-index: 1;
            padding-left: .7rem;
            top: .4rem;
        }
        .ant-select {
            width: 100%;
        }

        &.select-wrapper-city {
            .ant-select-selection-search {
                padding-left: 3rem;
            }
        }
        &.select-wrapper-country {
            .ant-select {
                width: calc(100% - 1.9rem);
            }

            .ant-select-selector {
                padding-left: 6rem !important;
            }
        }
    }
`;

const AddressForm = ({ value, phoneAddible, onChange, onBlur }) => {
    const { cachedList } = useContext(ListContext);
    const cities = cachedList.cities.items.map(city => city.Name);

    const handleInputChange = event => {
        onChange({
            ...value,
            [event.target.name]: event.target.value
        });
    };

    const handleCityChange = city => {
        onChange({
            ...value,
            City: city
        });
    };

    const handleCountryChange = country => {
        onChange({
            ...value,
            Country: country
        });
    };

    return (
        <Container>
            <Row gutter={16}>
                <Col span={24}>
                    <FormInput prefix='STREET' value={value.Street} name='Street' onChange={handleInputChange} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={16}>
                    <div className='select-wrapper select-wrapper-city'>
                        <div className='prefix'>CITY</div>
                        <AutoComplete
                            autoComplete='off'
                            value={value.City}
                            dataSource={cities}
                            onChange={handleCityChange}
                            onBlur={onBlur}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            filterOption={(inputValue, option) =>
                                option.props.children
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <FormInput className="small-prefix" prefix='ZIP' value={value.Zip} name='Zip' onChange={handleInputChange} onBlur={onBlur} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormInput  className="long-prefix" prefix='COUNTY / PROV' value={value.County} name='County' onChange={handleInputChange} onBlur={onBlur} />
                </Col>
                <Col span={12}>
                    <FormInput className="long-prefix" prefix='STATE / REGION' value={value.State} name='State' onChange={handleInputChange} onBlur={onBlur} />
                </Col>
            </Row>
            <Row gutter={16} align="middle">
                <Col span={24}>
                    <div className='select-wrapper select-wrapper-country'>
                        <div className='prefix'>COUNTRY</div>
                        <SelectCountry
                            name='Country'
                            value={value.Country}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={handleCountryChange}
                            onBlur={onBlur}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <FormInput prefix='ACCESS' value={value.Access} name='Access' onChange={handleInputChange} onBlur={onBlur} />
                </Col>
            </Row>
            {phoneAddible &&
                <Row gutter={16}>
                    <Col span={24}>
                        <FormInput prefix='PHONE' value={value.Phone} name='Phone' onChange={handleInputChange} onBlur={onBlur} />
                    </Col>
                </Row>
            }
        </Container>
    );
};

export default AddressForm;
