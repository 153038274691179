import React from "react";
import { Result } from "antd";

const NwResult = (props) => {
    return (
        <Result
            status={props.status}
            title={props.title}
        />
    );
}

export default NwResult;
