import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import _ from "lodash";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { light, duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Modal } from 'antd';

import { NwSaveButton, NwCancelButton } from 'Components/Gui/NwButton';
import MediaCover from 'Components/Gui/MediaCover';
import NwIcon from 'Components/Gui/NwIcon';
import { isLandscape } from 'Libs/NwUtils';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NWTextAreaCharCounter } from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import PlayListInfoForm from 'Components/MediaDrawer/PlayListInfoForm';
import { forceContactPlaylists } from 'Hooks/Contact/UseContactPlaylists';
import { Segmented } from 'antd';
import { Loading } from 'Components/Gui/Loading';
import CreatedBy from 'Components/CreatedBy/CreatedBy';

const { confirm } = Modal;

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: ${props => props.$isDrawer?"10vw":"0"};
    width: ${props => props.$isDrawer?"90vw":"100vw"};
    min-height: 15rem;
    background-color: #fff;
    z-index: 1030;
    box-shadow: 0 -0.5rem 0.5rem rgba(90, 97, 105, 0.1);
    
    

    div.nw-book-footer-header {
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem .5rem 0;
        min-height: 5rem;
        div.nw-book-footer-header-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            /* .book-name {
                font-size: 1rem;
                font-weight: bold;
                margin-right: 8px;
            } */
            .book-close {
                height: 3rem;
                padding: 0 2rem;
                font-size: 2rem;
                border-right: ${props => props.theme.DefaultBorder};
                cursor: pointer;
            }

            .book-title {
                text-transform: uppercase;
                padding: .25rem 0 0 2rem;
                //cursor: pointer;
                cursor: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgUGl4ZWxtYXRvciBQcm8gMi40LjMgLS0+Cjxzdmcgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHBhdGggaWQ9IlBlcmNvcnNvIiBmaWxsPSIjMDAwMDAwIiBzdHJva2U9Im5vbmUiIGQ9Ik0gMTEuMzM0Mzc1IDAuNjAzNzUgQyAxMi4xMTU2MjUgLTAuMTc3NDM3IDEzLjM4NDM3NSAtMC4xNzc0MzcgMTQuMTY1NjI1IDAuNjAzNzUgTCAxNS4zOTY4NzUgMS44MzU5MzggQyAxNi4xNzgxMjUgMi42MTY4NzUgMTYuMTc4MTI1IDMuODg0Mzc1IDE1LjM5Njg3NSA0LjY2NTYyNSBMIDEzLjg4NDM3NSA2LjE3ODEyNSBMIDkuODIxODc1IDIuMTE2MjUgTCAxMS4zMzQzNzUgMC42MDM3NSBaIE0gMTMuMTc4MTI1IDYuODg0Mzc1IEwgNS44OTA2MjUgMTQuMTY4NzUgQyA1LjU2NTYyNSAxNC40OTM3NSA1LjE2MjUgMTQuNzM0Mzc1IDQuNzIxODc1IDE0Ljg2MjUgTCAwLjk2MTU2MyAxNS45Njg3NSBDIDAuNjk4NDM4IDE2LjA0Njg3NSAwLjQxMzc1IDE1Ljk3NSAwLjIxOTY4OCAxNS43NTMxMjUgQyAwLjAyNTYxOSAxNS41ODc1IC0wLjA0NjkzNyAxNS4zMDMxMjUgMC4wMzA1IDE1LjAzNzUgTCAxLjEzNjU2MiAxMS4yNzgxMjUgQyAxLjI2NjU2MiAxMC44Mzc1IDEuNTA1IDEwLjQzNDM3NSAxLjgzMDMxMiAxMC4xMDkzNzUgTCA5LjExNTYyNSAyLjgyMzEyNSBMIDEzLjE3ODEyNSA2Ljg4NDM3NSBaIi8+Cjwvc3ZnPgo='), pointer;

                .book-name {
                    font-weight: 500;
                    font-size: .95rem;
                }
                .book-author-text {
                    font-size: 0.7rem;
                    opacity: .6;
                }
            }

            
        }

        div.nw-book-footer-header-middle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        div.nw-book-footer-header-right {
            display: flex;
            justify-content: flex-start;
            align-items: center;

        }
    }

    div.nw-book-footer-maincontent {
        display: flex;
        justify-content: space-between;
        border-top: ${props => props.theme.DefaultBorder};
        background: #f0f0f0;
        padding: 10px 24px 24px 16px;

        div.nw-book-footer-loading {
            width: 100%;
            height: calc(25vh - 4rem);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        div.nw-book-footer-scroller {
            width: 100%;
            height: calc(25vh - 4rem);
            overflow: auto;

            div.nw-book-footer-droppable {
                display: flex;
                height: calc(25vh - 4rem);
                
                div.nw-book-footer-media {
                    position: relative;
                    height: calc(25vh - 4.5rem);
                    min-height: 150px;
                    min-width: 9vw;
                    margin: .25rem;
                    color: #fff;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 1px 6px rgba(57,73,76,.35);

                    user-select: none;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                
                    &:hover {
                        .hover-container {
                            opacity: 1;
                        }
                    }
                }

                div.nw-book-footer-video {
                    display: flex;
                    height: calc(25vh - 4rem);
                    margin-right: ${props => props.$doublepage ? "2rem" : "1rem"};
                }

                .add-empty-page-button {
                    height: calc(25vh - 4rem);
                    width: 10rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                    cursor: pointer;
                }
            }
        }
    }

    .disabled-button {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
    }
`;

const ModelCoverWrapper = styled.div`
    min-width: 16vw; 
    margin: 0.25rem;
    position: relative;
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
`;

const RemoveButton = styled.div`
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: auto;
    /* background: white; */
    z-index: 100;

    svg {
        font-size: 2rem;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.TextColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
        cursor: pointer;
    }

    &:hover {
        svg {
            --fa-primary-color: white;
            --fa-secondary-color: ${props => props.theme.DangerColor};
            --fa-primary-opacity: 1;
            --fa-secondary-opacity: 1;
        }
    }
`;

// const CommentIcon = styled(NwIcon)`
//     .fa-primary {
//         fill: white;
//         opacity: 1;
//     }

//     .fa-secondary {
        
//         fill: ${props => props.theme.PrimaryColor};
//         opacity: 1;
//      }
// `;



const ModelBookEditorFooter = ({ book, playlist, contactID, isBookTouched, onSetBookTouched, onSetPlaylist, onCancelBook, onSaveBook, onRemoveMedia, packageBook, onAddPlaceholder, bookLayout, onChangeBookLayout, isDrawer, savingBook}) => { 
    
    const [editMediaNote, setEditMediaNote] = useState()
    const [touchForm, setTouchForm] = useState(false)
    const [isBookDrawer, setIsBookDrawer] = useState(false)
    const doublepage = (bookLayout === "Pages")

    const isEven = n => (n % 2 === 0)
    
    const getBookMediaStyle = (index, picture, isDragging, combineTargetFor, draggableStyle) => {
        const bgImage = picture.ID ? `url(${picture.thumbnail})` : 'none'
        const bgColor = picture.ID ?  'transparent' : '#34495e'
        const mRight = doublepage ? (isEven(picture.page) ? '2rem' : '.25rem') : '1rem'
        const mWidth = isLandscape(picture) ? "16vw" : "8vw"
        return ({
            marginRight: mRight,
            backgroundImage: bgImage,
            minWidth: mWidth,
            borderColor: combineTargetFor ? '#c4183c' : 'transparent',
            backgroundColor: bgColor,
            ...draggableStyle
        })        
    }

    const getBookWidth = useMemo(() => {
        if (playlist.length) {
            const lastpicture = _.last(playlist)
            let pages = playlist.length + 1;
            if (lastpicture && lastpicture.page) {
                pages = _.last(playlist).page;
            }
            return (pages > 9) ? `calc(${pages * 8}vw + 600px + ${pages * 1.5}rem)` : '100%'
        }
        return '100%'
    }, [playlist])

    const getBookDroppableStyle = isDraggingOver => ({
        background: isDraggingOver ? 'rgba(0, 0, 0, .25)' : 'transparent',
        width: getBookWidth
    })

    

    // const handleEditNote = media => () => {
    //     setEditMediaNote(media);
    // };

    const handleSaveNote = (note, setSubmitting) => {
        let updatedPlaylist = [...playlist]
        const playlistIndex = updatedPlaylist.findIndex(document => document.ID === editMediaNote.ID)
        updatedPlaylist[playlistIndex].notes = note
        onSetBookTouched(true)
        onSetPlaylist(updatedPlaylist)
        setTouchForm(false)
        setEditMediaNote()
    }

    const handleEditBookName = () => {
        setIsBookDrawer(true)
    }

    const handleAddEmptyPage = () => {
        onAddPlaceholder()
    }

    const handleCancelBook = () => {
        if (isBookTouched)
            confirm({
                title: <div>Are you sure to close the drawer?</div>,
                // title: 'You can lose your inputs, Are you sure to close the drawer?',
                // icon: <NwIcon icon='exclamation-circle' />, //<ExclamationCircleOutlined />,
                // // content: 'Some descriptions',
                onOk() {
                    onCancelBook()
                },
                onCancel() { },
            });
        else 
            onCancelBook()
    }

    return (
        <Container $isDrawer={isDrawer} $doublepage={doublepage}>
            <div className="nw-book-footer-header">
                <div className="nw-book-footer-header-left">
                    {/* <div className='book-name'>{book.Name}</div> */}
                    <div className="book-close">
                        <NwIcon icon={light("xmark")} onClick={handleCancelBook} />
                    </div>
                    <div className="book-title" onClick={(!packageBook && !savingBook) ? handleEditBookName : null}>
                        <div className="book-name">
                            {book.Name}
                        </div>
                        <div className="book-author-text">
                            <CreatedBy object={book} short textonly />
                        </div>
                    </div>
                </div>
                <div className="nw-book-footer-header-middle">
                    <Segmented
                        value={bookLayout}
                        options={[
                            {
                                value: 'Pages',
                                icon: <NwIcon icon={light("book-open")} />,
                            },
                            {
                                value: 'Sequence',
                                icon: <NwIcon icon={light("file-user")} />,
                            },
                        ]}
                        onChange={onChangeBookLayout}
                        disabled={(book.Type && book.Type.toLowerCase() === 'media') || savingBook}
                    />
                </div>
                <div className="nw-book-footer-header-right">
                    <NwSaveButton
                        disabled={!isBookTouched || savingBook}
                        label="Save"
                        className={playlist.length === 0 ? 'disabled-button' : ''}
                        onClick={playlist.length === 0 ? undefined : onSaveBook} />
                    
                </div>
            </div>
            <div className="nw-book-footer-maincontent">
                {savingBook
                ?
                <div className="nw-book-footer-loading">
                    <Loading textBlack text="saving book" />
                </div>
                :
                <div className="nw-book-footer-scroller">
                    <Droppable droppableId="BookPageContainer" direction="horizontal" isCombineEnabled={true}>
                        {(provided, snapshot) => (
                            <div className="nw-book-footer-droppable" ref={provided.innerRef} style={getBookDroppableStyle(snapshot.isDraggingOver)}>
                                {playlist.map((picture, index) => {
                                    if ((book.Type && book.Type.toLowerCase() === "media") && picture.caption === "Placeholder") return (null);
                                    return (
                                        <Draggable
                                            key={`BookPage-${picture.ID}-${index}`}
                                            draggableId={`BookPage-${picture.ID}-${index}`}
                                            index={index}
                                            isDragDisabled={(picture.ID === null)}
                                            >
                                            {(provided, snapshot) => (
                                                picture.typeName === 'Video' ? 
                                                    <div
                                                        className="nw-book-footer-video"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={provided.draggableProps.style}>
                                                        <ModelCoverWrapper
                                                            className="model-cover-wrapper"
                                                            width={picture.thumbnailWidth}
                                                            height={picture.thumbnailHeight}
                                                        >
                                                            <MediaCover
                                                                className="media-cover"
                                                                type="Video"
                                                                src={picture.src}
                                                                width={picture.thumbnailWidth}
                                                                height={picture.thumbnailHeight}
                                                                style={{
                                                                    minWidth: '16vw', 
                                                                    height: '100%'
                                                                }} />
                                                            { picture.removable && //picture.caption !== 'Placeholder' &&
                                                            
                                                                <RemoveButton>
                                                                    {/* <CommentIcon
                                                                        icon={picture.notes ? duotone('comment-alt-lines') : light('comment-alt')}
                                                                        pointer 
                                                                        onClick={handleEditNote(picture)} /> */}
                                                                    <NwIcon 
                                                                        className='trash'
                                                                        icon={duotone('circle-xmark')}
                                                                        onClick={() => onRemoveMedia(index)} />
                                                                </RemoveButton>
                                                            }
                                                        </ModelCoverWrapper>
                                                    </div>
                                                    :
                                                    <div className="nw-book-footer-media"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getBookMediaStyle(
                                                            index,
                                                            picture,
                                                            snapshot.isDragging,
                                                            snapshot.combineTargetFor,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                    { picture.removable && //picture.caption !== 'Placeholder' &&
                                                        <RemoveButton>
                                                            {/* <CommentIcon
                                                                icon={picture.notes ? duotone('comment-alt-lines') : light('comment-alt')}
                                                                pointer 
                                                                onClick={handleEditNote(picture)} /> */}
                                                            <NwIcon 
                                                                className='trash'
                                                                icon={duotone('circle-xmark')}
                                                                onClick={() => onRemoveMedia(index)} />
                                                        </RemoveButton>
                                                    }
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {doublepage && <div className="add-empty-page-button" onClick={handleAddEmptyPage}><NwIcon icon={light("file-circle-plus")} /></div>}
                            </div>
                        )}
                    </Droppable>
                </div>
                }
            </div>
            {editMediaNote && 
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Note'
                    onClose={() => {
                        setTouchForm(false);
                        setEditMediaNote()
                    }}>
                    <Formik
                        initialValues={{ Note: editMediaNote.notes || '' }}
                        validationSchema={Yup.object().shape({
                            Note: Yup.string().required("Content is required")
                        })}
                        onSubmit={({ Note }, { setStatus, setSubmitting }) => {
                            setStatus();
                            handleSaveNote(Note, setSubmitting);
                        }}
                        render={({
                            isSubmitting,
                            values,
                            handleSubmit
                        }) => (
                            <NwForm 
                                values={values}
                                onTouchForm={setTouchForm}
                                onFinish={handleSubmit} 
                                layout="vertical">
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Note"
                                    name="Note"
                                    type="text"
                                    value={values.Note}
                                    maxLength={2000}
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                />
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setTouchForm(false);
                                                setEditMediaNote();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            htmlType="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                            </NwForm>
                        )}/>
                </NwDrawer>
            }
            {isBookDrawer &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Book'
                    onClose={() => setIsBookDrawer(false)}>
                    <PlayListInfoForm 
                        playlist={book} 
                        onUpdatePlaylists={() => { 
                            forceContactPlaylists(contactID) 
                        }}
                        onTouchForm={setTouchForm}
                        onClose={() => setIsBookDrawer(false)} />
                </NwDrawer>
            }
        </Container>
    );
};

export default ModelBookEditorFooter;