import React, { useState } from 'react';
import { Alert } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import { toISODate } from 'Libs/NwMoment';
import NwIcon from "Components/Gui/NwIcon";
import { enumerateDaysBetweenDates, getEndTime } from 'Libs/NwUtils';
import TimeRangePicker from 'Components/EventDateTimePicker/TimeRangePicker';
import DoubleTimeRangePicker from 'Components/EventDateTimePicker/DoubleTimeRangePicker';
import { NwLinkButton } from 'Components/Gui/NwButton';

const Wrapper = styled.div`
    padding: .25rem;
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */

    .remove-wrapper {
        width: 2rem;
        font-size: 1.25rem;
        color: ${props => props.theme.DangerColor};
    }

    .content-wrapper {
        flex: 1 1 0;
        display: block;
        .dates-line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2rem;
            font-weight: bold;
            margin-bottom: .5rem;

            .start-date {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
            }
            .end-date {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;

                .disabled-end-date {
                    background: #d9d9d9;
                    opacity: 0.7;
                }
            }
        }
        .times-line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;
            font-weight: bold;

            .time-picker-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
            }
        }
    }
`;

const getDate = date => {
    return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

const EventPeriodPicker = ({ value, removable, disabledDates = [], dateOnly, endNullable, onChange, onRemove, useDoubleTime, timeError }) => {
    const { startDate, endDate, startTime, endTime, startBreak, endBreak, allDay } = value;
    const [disableEndDate, setDisableEndDate] = useState(endNullable ? false : true);
    const [doubleTime, setDoubleTime] = useState(false);
    const [isAllDay, setIsAllDay] = useState(startTime && endTime && startTime[0] === 0 && startTime[1] === 0 && endTime[0] === 23 && endTime[1] === 59)
    
    const handleUpdateStartDate = date => {
        const updatedValue = {
            ...value,
            startDate: date,
            dates: [{
                date: date,
                optionNumber: null
            }]
        };
        if (!endNullable) {
            updatedValue.endDate = date;
        }
        onChange(updatedValue);
        if (!disableEndDate && !endNullable) {
            setDisableEndDate(true);
        }
    };

    const handleChangeStartTime = time => {
        const endTimeVal = getEndTime(startTime, endTime, time);
        onChange({
            ...value,
            startTime: time,
            endTime: [parseInt(endTimeVal / 60), endTimeVal % 60]
        });
    }

    const handleChangeEndTime = time => {
        onChange({
            ...value,
            endTime: time
        });
    }

    const handleChangeStartBreak = time => {
        onChange({
            ...value,
            startBreak: time
        });
    }

    const handleChangeEndBreak = time => {
        onChange({
            ...value,
            endBreak: time
        });
    }

    const handleClickEndDate = () => {
        if (disableEndDate) {
            setDisableEndDate(false);
        }
    }

    const handleUpdateEndDate = date => {
        const betweenDates = enumerateDaysBetweenDates(startDate, date);
        const dates = betweenDates.map(item => {
            const date = getMoment(item);
            return {
                date: getMoment(date),
                optionNumber: null
            }
        })
        onChange({
            ...value,
            endDate: date,
            dates: dates
        });
    }

    const handleSetAllDay = checked => {
        if (checked) {
            onChange({
                ...value,
                allDay: checked,
                startTime: [0, 0], //getTime(getNow(true)),
                endTime: [23, 59] //getTime(getNow().endOf('day')),
            });
        } else {
            onChange({
                ...value,
                allDay: checked,
                startTime: [9, 0], //getTime(getNow().hour(9).minute(0)),
                endTime: [11, 0] //getTime(getNow().hour(11).minute(0)),
            });
        }
        setIsAllDay(checked);
    };

    const disabledEndDate = date => {
        if (toISODate(date) >= toISODate(startDate)) {
            if (!disabledDates.find(disabledDate => {
                let disabled = getDate(getMoment(disabledDate));
                const selectedDate = getDate(date);
                const start = getDate(startDate);
                if ((getMoment(disabledDate).year() === date.year()
                    && getMoment(disabledDate).month() === date.month()
                    && getMoment(disabledDate).date() === date.date())
                    || (start < disabled && selectedDate > disabled)) {
                    return true;
                }
                return false;
            })) {
                return false;
            }
        }
        return true;
    }

    const disabledStartDate = date => {
        if (!disabledDates.find(disabledDate => getMoment(disabledDate).year() === date.year()
            && getMoment(disabledDate).month() === date.month()
            && getMoment(disabledDate).date() === date.date())) {
            return false;
        }
        return true;
    }

    const onChangeTimes = (name, value) => {
        switch (name) {
            case 'startTime':
                handleChangeStartTime(value);
                break;
            case 'endTime':
                handleChangeEndTime(value);
                break;
            case 'startBreak':
                handleChangeStartBreak(value);
                break;
            case 'endBreak':
                handleChangeEndBreak(value);
                break;
            default:
                break;
        }
    }

    const clearBreak = () => {
        onChange({
            ...value,
            startBreak: [0,0],
            endBreak: [0,0]
        });
    }

    const switchToSingleTime = () => {
        clearBreak();
        setDoubleTime(false);
    }

    const switchToDoubleTime = () => {
        clearBreak();
        setDoubleTime(true);
    }

    const onChangeDefaultBreaks = (start, end) => {
        onChange({
            ...value,
            startBreak: start,
            endBreak: end
        });
    }

    return (
        <Wrapper>
            {/* <div className="remove-wrapper">
                {removable &&
                    <NwIcon icon={light("times-circle")} onClick={onRemove} />
                }
            </div> */}
            <div className="content-wrapper">
                <div className="dates-line">
                    {removable &&
                        <div className="remove-wrapper">
                            <NwIcon danger icon={light("times-circle")} onClick={onRemove} />
                        </div>
                    }
                    <div className="start-date">
                        <div>From</div>
                        <CustomDatePicker
                            label='Start Date'
                            name='startDate'
                            value={startDate}
                            disabledRange={disabledStartDate}
                            onChange={handleUpdateStartDate} />
                    </div>
                    <div className="end-date">
                        <div>To</div>
                        <div onClick={handleClickEndDate}>
                            <CustomDatePicker
                                label='End Date'
                                name='endDate'
                                className={disableEndDate && 'disabled-end-date'}
                                disabledRange={disabledEndDate}
                                value={endDate}
                                onChange={handleUpdateEndDate} />
                        </div>
                    </div>
                </div>
                {!dateOnly &&
                    <>
                        <div className="times-line">
                            <div>Work&nbsp;Time</div>
                            {doubleTime
                                ?
                                <div className="time-picker-wrapper">
                                    <DoubleTimeRangePicker
                                        fields={{
                                            startTimeFieldName: 'startTime',
                                            endTimeFieldName: 'endTime',
                                            startBreakFieldName: 'startBreak',
                                            endBreakFieldName: 'endBreak'
                                        }}
                                        values={{
                                            startTime: startTime,
                                            endTime: endTime,
                                            startBreak: startBreak,
                                            endBreak: endBreak
                                        }}
                                        methods={{
                                            setFieldValue: onChangeTimes,
                                            onChangeDefaultBreaks: onChangeDefaultBreaks 
                                        }}
                                    />
                                    <NwLinkButton label="single" onClick={switchToSingleTime} />
                                </div>
                                :
                                <div className="time-picker-wrapper">
                                    <TimeRangePicker
                                        fields={{
                                            startTimeFieldName: "startTime",
                                            endTimeFieldName: "endTime",
                                            allDayFieldName: 'allDay'
                                        }}
                                        values={{
                                            startTime: startTime,
                                            endTime: endTime
                                        }}
                                        methods={{
                                            setFieldValue: onChangeTimes,
                                            onSetAllDay: handleSetAllDay
                                        }}
                                    />
                                    {useDoubleTime && !isAllDay &&
                                        <NwLinkButton label="double time slot" onClick={switchToDoubleTime} />
                                    }
                                </div>
                            }
                        </div>
                        {timeError && <Alert type="error" message={timeError} />}
                    </>
                }
            </div>
        </Wrapper>
    )
};


export default EventPeriodPicker;