import React from "react";
import styled from "styled-components";
import StyleVariables from "./StyleVariables";

const ResponsiveContent = styled.div`
    display: flex;
    flex-direction: row;

    width: ${props => props.fullWidth ? "100%" : "auto"};

    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }

    p {
        margin: 0;
    }

    .is-strong {
        font-weight: 500 !important;
    }
    .is-stronger {
        font-weight: 700 !important;
    }
    .is-uppercase {
        text-transform: uppercase !important;
    }

    .is-label {
        text-transform: uppercase;
        font-size: .85rem;
        font-weight: 600;
    }
    .is-content {
        font-size: 1rem;
        font-weight: 300;
        white-space: pre-line;

        &.small {
            font-size: .85rem;
        }
        &.big {
            font-size: 1.15rem;
        }
    }
    .is-center {
        text-align: center;
    }
    .is-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        margin-bottom: 1rem;

        svg {
            
        }
        p {
            margin-top: .5rem;
        }
    }
`;

const ResponsiveColumn = styled.div`
    border-right: ${props => props.isBorder ? StyleVariables.DarkerBorder : "none"};
    padding: 5px 10px;
    
    &.left {
        min-width: ${props => props.leftMinWidth ? (props.leftMinWidth + "px") : "0"};
    }
    &.main {
        flex: 1 1 0;
    }

    @media only screen and (max-width: 480px) {
        flex-direction: column;
        border-right: none;
        border-bottom: ${props => props.isBorder ? StyleVariables.DarkerBorder : "none"};
    }

`

const ResponsiveFlexContainer = ({ columns, leftMinWidth, fullWidth }) => {
    return (
        <ResponsiveContent className="responsive-flex-container" fullWidth={fullWidth}>
            {columns.map(((column, index) => {
                const { content, isBorder, width } = column
                let cn = ""
                let style = {}
                if (width) {
                    if (width === "full") {
                        style.flex = "1 1 0"
                    } else {
                        style.width = width
                    }
                }
                if (index === 0) {
                    cn = "left"
                }
                if (index === 1 && columns.length === 2) {
                    cn = "main";
                }
                return (
                    <ResponsiveColumn className={cn} key={index} isBorder={isBorder ? true : false} leftMinWidth={leftMinWidth} style={style}>
                        {content}
                    </ResponsiveColumn>
                )
            }))}
        </ResponsiveContent>
    )
}

export default ResponsiveFlexContainer;