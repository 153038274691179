import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader, Layout } from 'antd';

const RedirectHeaderStyle = {
    border: '1px solid rgb(235, 237, 240)',
    zIndex: '10',
    position: 'fixed',
    width: '100%',
    padding: '4px 16px',
    top: '4rem'
};

const NavigationBack = ({ navigationTitle, navigationUrl }) => {
    const history = useHistory();

    return (navigationUrl ?
        <Layout>
            <PageHeader
                style={RedirectHeaderStyle}
                onBack={() => { history.push(navigationUrl) }}
                title={navigationTitle} />
        </Layout>
        : null
    )
};

export default NavigationBack;