import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Input, List } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';

import NwDrawer from 'Components/Gui/NwDrawer';
import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ModelCover } from 'Components/ModelCover';
import CreateModelForm from 'Components/ContactCreator/CreateModelForm';
import { Features } from 'Components/Features/FeaturesDrawer';
import MeetingModelMedia from 'Components/EventDrawer/ScoutingMeetingsEvent/MeetingModelMedia';
import { useModelsList } from "Hooks/Contact/UseContactsList";
import { useScoutingMeeting, forceReloadScoutingMeeting } from 'Hooks/Event/UseScoutingMeeting';
import { forceReloadTravelPlan } from 'Hooks/TravelPlan/UseTravelPlan';

const StyledInput = styled(Input)`
    width: 450px;
`;

const Container = styled.div`
    display: flex;
    
    .col {
        flex: 1;
        padding: 0 1em 1em;
    }

    .model-list {
        border-right: 1px solid ${props => props.theme.LighterBorderColor};
    }

    .ant-list-item {
        cursor: pointer;
    }
`;

const AddMeetingModelDrawer = ({ travelPlanId, meetingId, selectedModels, onClose }) => {
    const [filter, setFilter] = useState('');
    const [isFiltered, setFiltered] = useState(false);
    const { data: fullModelList } = useModelsList();
    const [isCreateModel, setCreateModel] = useState(false);
    const searchRef = useRef(null);
    const [createdModelId, setCreatedModelId] = useState();
    const [modelCreationStep, setModelCreationStep] = useState('creation');
    const { data: scoutingMeetingsData } = useScoutingMeeting(meetingId);
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchRef.current]);
    
    const handleSearch = event => {
        if (!isFiltered) {
            setFiltered(true);
        }
        setFilter(event.target.value);
    };

    const handleCreateModel = () => {
        setCreateModel(true);
    }

    const handleAddModel = async modelId => {
        const modelViewData = {
            MeetingID: meetingId,
            ModelID: modelId
        };

        try {
            await Axios.post('/ScoutingMeetings/AddModelView', modelViewData);
            await forceReloadScoutingMeeting(meetingId);
            await forceReloadTravelPlan(travelPlanId);
        } catch (error) {
            console.log('ant : Add Model View Error => ', error);
        }
    };

    const handleCreatedModel = modelId => {
        handleAddModel(modelId);
        setModelCreationStep('feature');
        setCreatedModelId(modelId);
    };

    const renderCreationModel = () => {
        switch (modelCreationStep) {
            case 'creation':
                let filters = filter.split(' ');
                const name = filters[0];
                filters.splice(0, 1);
                const surname = filters.length > 0 ? filters.join(' ') : '';
                return (
                    <CreateModelForm 
                        defaultFormData={{
                            InTown: false,
                            Name: name,
                            Surname: surname,
                            MotherAgencyID: scoutingMeetingsData.ContactID
                        }}
                        onModelCreated={handleCreatedModel}
                        onTouchForm={setTouchForm} />
                );
            case 'feature':
                return createdModelId ?
                    <>
                        <FlexContainer justifyContent='flex-end' mg='0 0 16px'>
                            <NwButton primary label='Next' onClick={() => setModelCreationStep('media')} />
                        </FlexContainer>
                        <Features contactID={createdModelId} />
                    </> : <div />
            case 'media':
                return (
                    <MeetingModelMedia 
                        modelId={createdModelId} 
                        meetingId={meetingId}
                        onClose={() => {
                            forceReloadScoutingMeeting(meetingId);
                            onClose();
                        }} />
                )
            default:
        }
    }

    const models = useMemo(() => {
        let models = !isFiltered ? fullModelList.filter(model => model.ST === 5 && model.MAID === scoutingMeetingsData.ContactID) :
            filter ? fullModelList.filter(model => `${model.N} ${model.S}`.toLowerCase().includes(filter.toLocaleLowerCase())) : [];
        models = models.filter(model => !selectedModels.find(item => item === model.ID));
        return models;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullModelList, filter, scoutingMeetingsData]);

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title={'Add Model'}
            onClose={onClose}>
            {isCreateModel ? renderCreationModel()
                : <>
                    <FlexContainer mg={'0 0 16px'}>
                        <StyledInput 
                            autoComplete='off'
                            value={filter}
                            placeholder="Search Model" 
                            onChange={handleSearch}
                            ref={searchRef} />
                    </FlexContainer>
                    <Container>
                        <div className='col model-list'>
                            <List
                                itemLayout="horizontal"
                                dataSource={models}
                                renderItem={modelObject => {
                                    return (
                                        <List.Item className="ant-list-item-top" onClick={() => { 
                                            handleAddModel(modelObject.ID)
                                            onClose();
                                        }}>
                                            <List.Item.Meta
                                                avatar={<ModelCover model={modelObject} classN="model-cover-small" />}
                                                title={`${modelObject.N} ${modelObject.S}`}>
                                            </List.Item.Meta>
                                        </List.Item>
                                    )}
                                }
                            />
                        </div>
                        <div className='col'>
                            <NwAddButton primary label='Create Model' onClick={handleCreateModel} />
                        </div>
                    </Container>
                </>
            }
        </NwDrawer>
    )
};

export default AddMeetingModelDrawer;