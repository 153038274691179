export const BILLING_PROJECTS_FILTERS = {
    reducerPrefix: 'JobFilters',
    sections: [
        {name: 'Period'},
        {name: 'People'},
        {name: 'Content and tags', showCustomFields: true, customFieldsObject: 'Project'},
        {name: 'Included events'},
        {name: 'Billing'}
    ],
    filters: [
        {
            name: 'WorkingOn',
            label: 'Period',
            area: 'period',
            defaultval: '{today_today}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'EmptyDates',
            label: 'Include projects without dates',
            area: 'period',
            defaultval: 'include',
            commontype: null,
            type: 'empty-dates'
        },
        {
            name: 'Booker',
            label: 'Booker',
            area: 'people',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'modelID',
            label: 'Model',
            area: 'people',
            defaultval: "",
            commontype: 'model',
            type: 'modelList'
        },
        {
            name: 'clientID',
            label: 'Customer',
            area: 'people',
            defaultval: "",
            commontype: 'customer',
            type: 'customerList'
        },
        {
            name: 'searchClientInCallsheet',
            label: 'Search customer also in project callsheet',
            area: 'people',
            defaultval: true,
            commontype: 'searchClientInCallsheet',
            type: 'filter-type'
        },
        {
            name: 'terms',
            label: 'Word',
            area: 'content and tags',
            defaultval: "",
            commontype: 'word_content',
            type: 'word_content'
        },
        {
            name: 'projectCategoryID',
            label: 'Category',
            area: 'content and tags',
            defaultval: 1,
            commontype: 'category',
            type: 'category'
        },
        {
            name: 'tags',
            label: 'Tags',
            area: 'content and tags',
            defaultval: [],
            commontype: 'tag',
            type: 'jobs-tag'
        },
        {
            name: 'projectDepartmentID',
            label: 'Department',
            area: 'content and tags',
            defaultval: 1,
            commontype: 'department',
            type: 'department'
        },
        {
            name: 'hasDocuments',
            label: 'Has documents',
            area: 'content and tags',
            defaultval: true,
            commontype: 'hasDocuments',
            type: 'filter-type'
        },
        {
            name: 'hasJobs',
            label: 'Jobs',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'hasOptions',
            label: 'Options',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'hasTravels',
            label: 'Travels',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'hasTransactions',
            label: 'Transactions',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'hasTodos',
            label: 'Tasks to complete',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'hasPackage',
            label: 'Package',
            area: 'included events',
            defaultval: true,
            commontype: 'included',
            type: 'filter-type'
        },
        {
            name: 'transactionStatuses',
            label: 'At least a transaction',
            area: 'billing',
            defaultval: [],
            commontype: null,
            type: 'JobsTransactionStatus'
        },
        {
            name: 'totalToInvoiceRange',
            label: 'Amount to invoice between',
            area: 'billing',
            defaultval: {
                Currency: "",
                FromAmount: null,
                ToAmount: null,
            },
            commontype: null,
            type: 'currency-range'
        },
        {
            name: 'hasUsages',
            label: 'Has usages',
            area: 'billing',
            defaultval: true,
            commontype: null,
            type: 'filter-type'
        },
        {
            name: 'TransactionFilter',
            label: 'Search on transactions',
            area: 'billing',
            defaultval: {
                Operation: "Any",
                Status: null,
                Invoiced: null,
                Paid: null,
                Families: null,
                Labels: null,
                Currency: null,
                AmountRange: {
                    Currency: "",
                    FromAmount: null,
                    ToAmount: null
                }
            },
            commontype: null,
            type: 'transaction-filter'
        },
    ]
};