import React from "react";
import { Formik, Field } from "formik";
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWEventPeriodPicker } from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import { NWInput } from 'Components/Gui/NWForm/NwFormItems';
import { NWCheckbox } from 'Components/Gui/NWForm/NwFormItems';

const ModelInTownScheduleAddEdit = ({ modelID, schedule, onChange, onCancel, checkOverlapping, onTouchForm }) => {
    const initialValues = {
        scheduleDate: null,
        StartDate: schedule && schedule.StartDate ? getMoment(schedule.StartDate) : null,
        EndDate: schedule && schedule.EndDate ? getMoment(schedule.EndDate) : null,
        ModelID: schedule ? schedule.ModelID : modelID,
        Period: {
            startDate: schedule && schedule.StartDate ? getMoment(schedule.StartDate) : null,
            endDate: schedule && schedule.EndDate ? getMoment(schedule.EndDate) : null,
        },
        Location: schedule?schedule.Location:"",
        IsInTown: schedule?schedule.IsInTown:true,
    };

    const submitForm = async (values, setSubmitting) => {        
        let newValues = {
            ModelID: values.ModelID,
            StartDate: getMoment(values.Period.startDate),
            EndDate: values.Period.endDate && getMoment(values.Period.endDate),
            Location: values.IsInTown?"":values.Location,
            IsInTown: values.IsInTown
        };
        
        setSubmitting(true);
        if (schedule) {
            newValues.ID = schedule.ID;
            try {
                await Axios.put(`/inTownSchedules`, newValues);
                onTouchForm(false);
                await forceReloadContact(values.ModelID)
                onChange();
                setSubmitting(false);
            } catch (error) {
                console.log("error", error);
            }
        } else {
            try {
                await Axios.post('/inTownSchedules', newValues);
                onTouchForm(false);
                await forceReloadContact(values.ModelID);
                onChange();
                onCancel();
                setSubmitting(false);
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    const validatePeriod = period => {
        if (!period.startDate) {
            return 'Start Date is required';
        }
        // if (period.endDate && period.endDate.diff(period.startDate,"months") > 12) {
        //     return "event longer than a year"
        // }
        return checkOverlapping({
            StartDate: period.startDate,
            EndDate: period.endDate
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validationSchema={Yup.object().shape({
                Location: Yup.string().when("IsInTown", {
                    is: false,
                    then: Yup.string().required("Content is required")
                })
            })}>
            {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Field
                            component={NWEventPeriodPicker}
                            name="Period"
                            endNullable
                            dateOnly
                            value={values.Period}
                            validate={validatePeriod}
                            required
                        />
                        <div style={{margin: "auto 0 10px 20px"}}>
                            <Field
                                component={NWCheckbox}
                                text="In town"
                                value={values.IsInTown}
                                name="IsInTown"
                                onAfterChange={() => {
                                    setFieldValue("Location", "");
                                }}
                            />
                        </div>
                    </div>
                    {!values.IsInTown && <Field
                        component={NWInput}
                        label="Location"
                        name="Location"
                        type="text"
                        value={values.Location}
                    />}
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default ModelInTownScheduleAddEdit;
