import React from "react";

import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const FeaturesBool = ({ onChange, value, disabled }) => {
    return (
        <RadioButtonGroup 
            value={value}
            disabled={disabled}
            defaultValue={true}
            onChange={onChange}
            options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
                { label: '?', value: "" }
            ]} />
    );
};

export default FeaturesBool;
