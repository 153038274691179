import React from "react";
import styled from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables.js';

const StyledTag = styled.small`
  margin-right: .2em;
  margin-bottom: .2em;
  font-size: .75rem;
  line-height: 1.4em;
  font-weight: normal;
  background-color: ${(props) => props.$bgcolor};
  cursor: ${(props) => props.$pointer ? "pointer" : "default"};
  color: ${(props) => props.$blackfont ? "#000" : "#fff"};
  padding: .25em .75em;
  border-radius: 4px;
  white-space: nowrap;
  text-transform: ${(props) => props.$uppercase ? "uppercase" : "inherit"};
`

const nwTagColors = {
  "danger": StyleVariables.DangerColor,
  "dark": StyleVariables.PrimaryDark,
  "warning": StyleVariables.WarningColor,
  "primary": StyleVariables.PrimaryColor
}

const NwTag = (props) => {
  const { type, children, pointer, uppercase, clickaction } = props;
  const blackfont = type && (type === "warning")

  return (
    <StyledTag
      onClick={clickaction}
      $blackfont={blackfont} 
      $bgcolor={type ? nwTagColors[type] : StyleVariables.PrimaryDarkLight} 
      $pointer={pointer} 
      $uppercase={uppercase}
      style={props.style || {}}
    >
      {children}
    </StyledTag>
  );
};

export { NwTag };
