import React, { useState, useEffect } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
    EventContainer,
    EventTitleContainer,
    ObjectHistoryContainer
} from 'Components/EventDrawer/EventDrawerUi/EventUI';
import NoticeForm from 'Components/EventDrawer/NoticeEvent/NoticeForm';
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import NoticeDetails from 'Components/EventDrawer/NoticeEvent/NoticeDetails';
import { useNotice, useDeleteNotice, forceReloadNotice } from 'Hooks/Event/UseNotice';

const NoticeEvent = ({
    ID,
    viewMode,
    plainMode,
    dateSelected,
    allDay,
    afterUpdate,
    afterRemove,
    onClose,
    onTouchForm
}) => {
    const [noticeID, setNoticeID] = useState(ID);
    const { data: noticeData } = useNotice(noticeID);
    const [isEdit, setIsEdit] = useState(false);
    const { mutateAsync: onDeleteNotice } = useDeleteNotice();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setNoticeID(ID);
    }, [ID]);

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteNotice({ ID: eventId, noticeId: noticeID });
            forceReloadNotice(ID);
        } catch (error) {
            console.log('ant : Remove Notice Error => ', error);
        }
    };

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const HeaderContent = () => (
        <div className="ant-drawer-header ant-drawer-header-sticky ">
            <div className="ant-drawer-title">{noticeData ? 'Notice' : 'Add New Notice'}</div>
        </div>
    )



    const NoticeFormContent = () => (
        <NoticeForm
            viewMode={viewMode}
            afterUpdate={afterUpdate}
            plainMode={plainMode}
            dateSelected={dateSelected}
            allDay={allDay}
            onTouchForm={touchForm => {
                if (noticeData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            }}
            onUpdateNoticeData={noticeId => {
                handleClearTouchForm();
                if (noticeId) {
                    setNoticeID(noticeId)
                } else {
                    forceReloadNotice(noticeId)
                }
            }}
            noticeData={noticeData}
            onCancel={() => {
                handleClearTouchForm();
                if (noticeData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            }} />
    )

    const actions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => setIsEdit(!isEdit)) },
        {
            name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (async () => {
                await handleRemoveEvent(noticeData.ID);
                afterRemove(true);
            })
        }
    ];

    return (
        <EventContainer>
            <HeaderContent />
            {noticeData ?
                <>
                    <EventTitleContainer>
                        <h2><strong>{noticeData.Title}</strong></h2>
                        <div>
                            <p>
                                <NwDropDownMenu
                                    right
                                    actions={actions}
                                />
                            </p>
                        </div>
                    </EventTitleContainer>
                    <NoticeDetails noticeData={noticeData} />
                    <ObjectHistoryContainer>
                        <ObjectHistory asLine object={noticeData} />
                    </ObjectHistoryContainer>
                    {isEdit &&
                        <NwDrawer
                            padding="0px"
                            width={740}
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            title='Edit Notice'
                            onClose={() => {
                                setIsEdit(false);
                                handleClearTouchForm();
                            }}>
                            <EventContainer>
                                <NoticeFormContent />
                            </EventContainer>
                        </NwDrawer>
                    }
                </>
                :
                (!ID && <NoticeFormContent />)
            }
        </EventContainer>
    );
};

export default NoticeEvent;