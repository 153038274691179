import React, { useState, useEffect } from 'react';
import { Popover, Tag } from 'antd';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables';
import SendDrawer from 'Components/SendDrawer/SendDrawer';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwButton } from 'Components/Gui/NwButton';
import { useContact } from "Hooks/Contact/UseContact";
import { useContactConnections } from "Hooks/Contact/UseContactConnections";

const TagHover = styled(Tag)`
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: ${StyleVariables.PrimaryColor} !important;
    }
`;
const PopoverContent = styled.div`
    color: white;
    .email-item {
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        .email-text {
            margin-right: 20px;
        }
        .remove-email {
            margin-left: auto;
            cursor: pointer;
            user-select: none;
        }
    }
`;

const RecipientsContent = styled.div`
    margin-top: 20px;
    .recipient-row {
        display: flex;
        flex-direction: row;
        .small-line {
            width: 100px;
            height: 1px;
            margin: auto 0;
            background-color: ${StyleVariables.PrimaryDarkLight};
        }
        .agenda-text {
            font-size 14px;
            padding: 0 10px;
        }
        .long-line {
            width: 100%;
            height: 1px;
            margin: auto 0;
            background-color: ${StyleVariables.PrimaryDarkLight};
        }
    }
    .add-recipients {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .recipients-list {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const Recipients = ({ recipientsData, contactID, onUpdateRecipientsData }) => {
    const { data: currentContact } = useContact(contactID);
    const { data: Connections } = useContactConnections(contactID);

    const [editRecipient, setEditRecipient] = useState(false);

    useEffect(() => {
        if (currentContact && Connections) {
            const { DefaultConnection } = currentContact;
            if (DefaultConnection) {
                onUpdateRecipientsData([{
                    ContactID: DefaultConnection.ContactID,
                    ConnectionID: DefaultConnection.ID,
                    To: DefaultConnection.Email,
                    Ccs: DefaultConnection.CC ? DefaultConnection.CC : []
                }]);                
            }
        }
    }, [Connections, currentContact]);

    const handleAddEmail = () => () => {
        setEditRecipient(true);
    };

    const handleCloseEmailModal = () => {
        setEditRecipient(false);
    }

    const handleAddeditRecipient = (recipient) => {
        onUpdateRecipientsData([
            ...recipientsData,
            {
                ContactID: recipient.ContactID,
                ConnectionID: recipient.ConnectionID,
                To: recipient.Email,
                Ccs: (recipient.CC && recipient.CC.length) ? [...recipient.CC]: []
            }
        ]);
        handleCloseEmailModal();
    };

    const handleRemoveRecipient = (index) => {
        const _recipientsData = [...recipientsData];
        _recipientsData.splice(index, 1);
        onUpdateRecipientsData([..._recipientsData]);
    }

    const handleRemoveCC = (index, cIndex) => {
        const _recipientsData = [...recipientsData];
        const _Ccs = _recipientsData[index].Ccs;
        _Ccs.splice(cIndex, 1);
        _recipientsData[index].Ccs = [..._Ccs];
        onUpdateRecipientsData([..._recipientsData]);        
    }

    return (
        <RecipientsContent>
            <div className="recipient-row">
                <div className="small-line"/>
                <div className="agenda-text">RECIPIENTS</div>
                <div className="long-line"/>
            </div>
            <div className="add-recipients">
                <div/>
                <NwButton
                    icon={solid('plus-circle')}
                    ghost
                    label='add recipients'
                    size='small'
                    onClick={handleAddEmail('recipient')}
                />
            </div>
            <div className="recipients-list">
                {recipientsData.map((recipient, index) => {
                    return (
                        <div key={`recipient-${index}`} style={{marginBottom: "5px"}}>
                            <Popover
                                overlayClassName="recipients-popover"
                                content={
                                    <PopoverContent>
                                        <div>CCs:</div>
                                        {recipient.Ccs && recipient.Ccs.map((email, cIndex) => {
                                            return (
                                                <div
                                                    key={cIndex}
                                                    className="email-item"
                                                    style={{borderTop: cIndex?"1px dashed white":"none"}}
                                                >
                                                    <div className="email-text">{email}</div>
                                                    <div className="remove-email" onClick={() => handleRemoveCC(index, cIndex)}>X</div>
                                                </div>
                                            )
                                            })}
                                    </PopoverContent>
                                }
                            >
                                <TagHover visible color={StyleVariables.PrimaryDark} closable onClose={() => handleRemoveRecipient(index)}>
                                    {recipient.To}{(recipient.Ccs && recipient.Ccs.length > 0) && ` (+${recipient.Ccs.length} CC)`}
                                </TagHover> 
                            </Popover>
                        </div>
                    )
                })}
            </div>
            {editRecipient &&
                <NwDrawer padding="0px" title='E-mail Addresses' onClose={handleCloseEmailModal}>
                    <SendDrawer
                        searchBox
                        mailingLists={false}
                        suggestions={[]}
                        existingEmails={[]}
                        onSelectRecipient={handleAddeditRecipient}
                    />
                </NwDrawer>
            }
        </RecipientsContent>
    );
}

export default Recipients;