import React, { useMemo } from 'react';
import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

const AgencyDepartmentSelector = ({ value, onChange, dataSource }) => {
    const networkAgencies = useMemo(() => {
        let agencies = [];
        dataSource.map(data => {
            agencies = [...agencies, ...data.NetworkAgencies]
            return null;
        });
        return agencies;
    }, [dataSource]);

    const handleChange = updatedValue => {
        const ids = updatedValue ? updatedValue.split('-') : [];
        if (ids.length === 0) {
            onChange(null);
        } else if(ids.length === 1) {
            onChange({
                NetworkAgencyID: ids[0],
                DepartmentID: null
            })
        } else {
            onChange({
                NetworkAgencyID: ids[0],
                DepartmentID: ids[1]
            })
        }
    };

    let selectedItem = null;
    if (value && value.NetworkAgencyID) {
        if (value.DepartmentID) {
            selectedItem = `${value.NetworkAgencyID}-${value.DepartmentID}`;
        } else {
            selectedItem = `${value.NetworkAgencyID}`;
        }
    };

    return (
        <TreeSelect
            showSearch
            value={selectedItem}
            dropdownStyle={{ maxHeight: 250, overflow: 'auto' }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            getPopupContainer={triggerNode => triggerNode.parentNode}
            onChange={handleChange}
        >
            {networkAgencies.map(networkAgency => (
                <TreeNode value={`${networkAgency.ID}`} key={`${networkAgency.ID}`} title={networkAgency.Name}>
                    {networkAgency.Departments.map(department => (
                        <TreeNode 
                            value={`${department.NetworkAgencyID}-${department.ID}`} 
                            key={`${department.NetworkAgencyID}-${department.ID}`}
                            title={`${networkAgency.Name} / ${department.Name}`} />
                    ))}
                </TreeNode>
            ))}
        </TreeSelect>
    );
};

export default AgencyDepartmentSelector;