import React, { useState } from "react";

import CreateEditForm from 'Components/FolderAddEditDrawer/CreateEditForm';
import NwDrawer from "Components/Gui/NwDrawer";

const FolderAddEditDrawer = ({ folder, type, onClose, items }) => {
    const [touchForm, setTouchForm] = useState(false);
    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={folder ? 'Edit folder' : "Create a new folder"}
        >
            <CreateEditForm items={items} type={type} folder={folder} onClose={onClose} onTouchForm={setTouchForm} />
        </NwDrawer>
    );
};
 
export default FolderAddEditDrawer;
