import Axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const onGetPlaylistByID = ID => (
    Axios.get(`/playlists/${ID}`)
)

const usePlaylist = (ID) => {
    return useQuery(["playlist", ID], () => onGetPlaylistByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const onGetPlaylistPackages = ID => (
    Axios.get(`/playlists/packages/${ID}`)
)

const usePlaylistPackages = (ID) => {
    return useQuery(["playlist-packages", ID], () => onGetPlaylistPackages(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const onGetPlaylistLastPackages = ID => (
    Axios.get(`/playlists/lastpackages/${ID}/6`)
)

const usePlaylistLastPackages = (ID) => {
    return useQuery(["playlist-last-packages", ID], () => onGetPlaylistLastPackages(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const onGetPlaylistInfo = ID => (
    Axios.get(`/playlists/info/${ID}`)
)

const usePlaylistInfo = (ID) => {
    return useQuery(["playlist", ID], () => onGetPlaylistInfo(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const onGetPlaylistPreview = ID => (
    Axios.get(`/playlists/preview/${ID}`)
)

const usePlaylistPreview = (ID) => {
    return useQuery(["playlist", ID], () => onGetPlaylistPreview(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const forceReloadPlaylist = (ID) => {
    queryClient.invalidateQueries(['playlist', ID])
}

export {
    usePlaylist,
    usePlaylistInfo,
    usePlaylistPreview,
    forceReloadPlaylist,
    usePlaylistPackages,
    usePlaylistLastPackages,
};


