import React from "react";
import styled from 'styled-components';
import { Loading } from 'Components/Gui/Loading';
import { useProject } from 'Hooks/Project/UseProject';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { generateModelsDates } from './ProjectEventModelsUtils';
import EventModelDate from './ProjectEventModelDate';

const Container = styled.div`
  position: relative;
  background: white;
  border-radius: 4px;
`;

const EventDatesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: ${props => props.theme.DefaultBorder};
  padding: 0px 0px 30px 40px;
  > span {
    margin-right: 15px;
    margin-top: 15px;
  }
`;

const ModelNameContainer = styled.div`
  font-size: 1.1rem;
  padding-left: 40px;
  font-weight: bold;
  padding-top: 10px;
`;

const ProjectEventModels = ({ projectId, currentProject, filterModel }) => {
    //const { data: project } = useProject(projectId);
    
    if (!currentProject) {
        return (
            <Container>
                <Loading textBlack />
            </Container>
        )
    }

    const groupedEventDates = generateModelsDates(currentProject);

    return (
        <Container>
            {filterModel && 
                <div>
                    <ModelNameContainer>{getContactName(filterModel)}</ModelNameContainer>
                    <EventDatesContainer>
                        {groupedEventDates.eventDates.map(eventDate => (
                            <EventModelDate
                                key={`${filterModel ? filterModel.ID : ''}-${eventDate ? eventDate.ID : ''}`}
                                eventDate={eventDate}
                                model={eventDate.EventDatesModels.find(m => m.ModelID === filterModel)}
                            />
                        ))}
                    </EventDatesContainer>
                </div>
            }
            {groupedEventDates.models.filter(m => m.ModelID !== filterModel).sort((a, b) => getContactName(a.ModelID) > getContactName(a.b) ? 1 : -1).map(model => (
                <div key={model.ModelID}>
                    <ModelNameContainer>{getContactName(model.ModelID)}</ModelNameContainer>
                    <EventDatesContainer>
                    {
                        groupedEventDates.eventDates.map(eventDate => (
                        <EventModelDate
                            key={`${model.ID}-${eventDate.ID}`}
                            eventDate={eventDate}
                            model={eventDate.EventDatesModels.find(m => m.ModelID === model.ModelID)}
                        />
                        ))
                    }
                    </EventDatesContainer>
                </div>
                ))
            }
        </Container>
    );
};

export default ProjectEventModels;
