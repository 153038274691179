import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getRatingByID = (ID, type) => (
    Axios.get(`/ItemRatings/GetRatingForItem?${type}ID=${ID}`)
)

const useRating = (ID, type) => (
    useQuery(["rating", ID, type], () => getRatingByID(ID, type), {
        staleTime: 60000,
        cacheTime: 60000
    })
)

const updateRating = async ({ data }) => (
    Axios.post('/ItemRating', data)
);

const useUpdateRating = () => {
    return useMutation(updateRating, {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(['rating', variables.ID, variables.type], data);
        }
    })
}

export {
    useRating,
    useUpdateRating
}
