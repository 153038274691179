import { createBreakpoint } from "react-use";

const useNwBreakPoints = () => {
    const useBreakpointHook = createBreakpoint({ mobile: 0, tablet: 720, laptop: 1024, wide: 1280, xwide: 1600});
    const breakpoint = useBreakpointHook();
    
    switch (breakpoint) {
        case "mobile":
            return 1;
        case "tablet":
            return 2;
        case "laptop":
            return 3;
        case "wide":
            return 4;
        case "xwide":
            return 5;
        default:
            return 3;
    }
}

export default useNwBreakPoints;
