import React from 'react';

import { duotone, light, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import NwIcon from 'Components/Gui/NwIcon';
import { showLog } from 'Libs/NwMoment';
import { Typography } from 'antd';
import NwClipboardCopyText from 'Components/Gui/NwClipboardCopyText';
import NwLink from 'Components/Gui/NwLink';
import { NwButton } from 'Components/Gui/NwButton';

const { Text } = Typography;

const PackageRecipientsSummary = ({ recipients, isSent, onGetAnonymousLink, onOpenAddRecipients }) => {
    
    return (
        <div className='package-drawer-recipients-container'>
            <NwCard
                icon={light("mailbox")}
                title="Recipients"
                extra={
                    <div className="actions-group-holder">
                        <NwButton primary lowercase icon={light("link")} label='get link (no tracking)' onClick={onGetAnonymousLink} disabled={isSent} />
                        <NwButton primary lowercase icon={light("mailbox")} label='add recipients' disabled={isSent} onClick={onOpenAddRecipients} />
                    </div>
                }
            >
                {recipients.length === 0 &&
                    <p style={{paddingLeft: '2.25rem'}}>no recipients yet</p>
                }
                {recipients.map((recipient, index) => {
                    const iconClassName = `recipient-icon ${recipient.HasRecipientOpenPackage && " recipient-icon-done"}`
                    const iconType = recipient.HasRecipientOpenPackage ? duotone('circle-check') : thin('circle-check');
                    return (
                        <div className="package-drawer-recipient" key={index}>
                            <div className="package-drawer-recipient-icon">
                                <NwIcon icon={iconType} className={iconClassName} />
                            </div>
                            <div className='package-drawer-recipient-email'>
                                <p>{recipient.Email}</p>
                            </div>
                            <div className='package-drawer-recipient-date'>
                                {recipient.HasRecipientOpenPackage && recipient.LastRecipientOpensPackageDateTime
                                    ?
                                    <p>last visit: {showLog(recipient.LastRecipientOpensPackageDateTime)}</p>
                                    :
                                    <p className="not-visited"><NwIcon icon={light("hourglass")} />not visited</p>
                                }
                            </div>
                            <div className='package-drawer-recipient-link'>
                                <p>
                                    <NwLink target="_blank" rel="noreferrer" onClick={() => { window.open(recipient.Link) }}>
                                        <NwIcon icon={light("external-link-alt")} />package link
                                    </NwLink>
                                </p>
                                <p>
                                    <NwClipboardCopyText asLink label="Copy link" text={recipient.Link} />
                                </p>
                            </div>
                            <div className='package-drawer-recipient-code'>
                                <p>code</p>
                                <Text code>
                                    {recipient.Guid}
                                </Text>
                            </div>
                        </div>
                    )
                })}
            </NwCard>
        </div>
    )
};

export default PackageRecipientsSummary;