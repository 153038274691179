import { getMoment } from "Libs/NwMoment";

import { MODELS_FILTERS } from "Configs/Filters/MODELS_FILTERS";
import { CUSTOMERS_FILTERS } from 'Configs/Filters/CUSTOMERS_FILTERS';
import { EVENTS_FILTERS } from 'Configs/Filters/EVENTS_FILTERS';
import { BILLING_PROJECTS_FILTERS } from 'Configs/Filters/BILLING_PROJECTS_FILTERS';
import { TRAVEL_PLANS_FILTERS } from 'Configs/Filters/TRAVEL_PLANS_FILTERS';
import { STATEMENTS_FILTERS } from 'Configs/Filters/STATEMENTS_FILTERS';
import { TASKS_FILTERS } from 'Configs/Filters/TASKS_FILTERS';
import { PACKAGES_FILTERS } from 'Configs/Filters/PACKAGES_FILTERS';
import { groupConversionValues } from "Libs/NWFeatures";


const getInitialValue = (typeName, value) => {
    switch (typeName) {
        // case 'MultipleChoice':
        //     return value ? value.split(',') : [];
        case 'Boolean':
            return value ? value : false;
        // case 'Numeric':
        //     return value ? value : 0;
        case 'Date':
            return value ? getMoment(value) : ''
        default:
            return value ? value : '';
    };
};

const createModelFilterList = (currentUser, globalFeatureSets, globalFeatures, userGuiConfig, customFields) => {
    const iv = {
        ID: Date.now(),
        Name: "My custom list"
    };
    for (const customField of customFields) {
        iv[`custom_fields_${customField.Name}`] = {
            active: false,
            value: getInitialValue(customField.TypeName),
            area: 'Demographic'
        };
    };

    MODELS_FILTERS.filters.forEach(filter => {
        if (filter.name !== "Features") {
            iv[filter.name] = {
                active: false,
                value: filter.defaultval,
                area: filter.area,
                isFeature: false
            };
            if (filter.name === "Booker" || filter.name === 'Scouter')
                iv[filter.name].value = currentUser.UserID;
        } else {
            const features = {};
            if (globalFeatures && globalFeatureSets && userGuiConfig) {
                for (const featureSet of globalFeatureSets) {
                    for (const featureGroup of featureSet.FeatureGroups) {
                        for (const groupFeature of featureGroup.GroupFeatures) {
                            const feature = globalFeatures.find(feature => feature.ID === groupFeature.FeatureID);
                            let groupFeatureValue = {
                                active: false,
                                isFeature: true,
                                area: feature.Name,
                                type: feature.TypeName,
                                featureSetName: featureSet.Name
                            };
                            switch (feature.TypeName) {
                                case "Conversion":
                                    const conversion = userGuiConfig.metrics.find(metric => metric.ID === feature.ConversionID);
                                    if (conversion) {
                                        const groupedConversionValues = groupConversionValues(conversion.ConversionValues);
                                        groupFeatureValue.value = [groupedConversionValues[0].Key, groupedConversionValues[groupedConversionValues.length - 1].Key];
                                    } else {
                                        groupFeatureValue.value = []
                                    }
                                    break;
                                case "Multichoice":
                                    groupFeatureValue.value = {
                                        choices: [],
                                        operator: 'and'
                                    };
                                    break;
                                case "Choice":
                                case "Trans":
                                    groupFeatureValue.value = [];
                                    break;
                                case 'Boolean':
                                    groupFeatureValue.value = false;
                                    break;
                                case 'Numeric':
                                    groupFeatureValue.value = [0, 0];
                                    break;
                                default:
                                    groupFeatureValue.value = "";
                                    break;
                            }
                            features[feature.ID] = groupFeatureValue;
                        }
                    }
                }
            }
            iv[filter.name] = features;
        }
    });
    return iv;
};

const getSectionCustomFields = (section, globalCustomFields) => {
    const cf = {...globalCustomFields.items}
    const sectionCustomFields = [];
    if (section.showCustomFields) {
        if (section.customFieldsObject instanceof Array) {
            for (const cfo of section.customFieldsObject) {
                const filteredCustomFields = cf[cfo].filter(item => !item.Disabled)
                for (const filteredCustomField of filteredCustomFields) {
                    if (!sectionCustomFields.find(item => item.Name === filteredCustomField.Name)) {
                        sectionCustomFields.push(filteredCustomField)
                    }
                }
                //sectionCustomFields.push(...filteredCustomFields)
            }
        } else {
            if (section.customFieldsObject && typeof section.customFieldsObject === "string") {
                const filteredCustomFields = cf[section.customFieldsObject].filter(item => !item.Disabled);
                //sectionCustomFields.push(...filteredCustomFields)
                for (const filteredCustomField of filteredCustomFields) {
                    if (!sectionCustomFields.find(item => item.Name === filteredCustomField.Name)) {
                        sectionCustomFields.push(filteredCustomField)
                    }
                }
            }
        }
    }
    return sectionCustomFields;
}

const createCommonFilterList = (filters, currentUser, globalCustomFields) => {
    const iv = {
        ID: Date.now(),
        Name: "My custom list"
    };
    filters.filters.forEach(filter => {
        iv[filter.name] = {
            active: false,
            value: filter.defaultval,
            area: filter.area,
            isFeature: false
        };
        if (filter.type === "bookerList" && filter.defaultval === "{me}") {
            iv[filter.name].value = currentUser.UserID;
        }
    });
    for (const section of filters.sections) {
        if (section.showCustomFields) {
            const sectionCustomFields = getSectionCustomFields(section, globalCustomFields);
            for (const customField of sectionCustomFields) {
                iv[`custom_fields_${customField.Name}`] = {
                    active: false,
                    value: getInitialValue(customField.TypeName),
                    area: section.name
                };
            };
        }
    }
    return iv;
}

const formatCommonFilterValues = (filtersList, filtersSets, currentUser) => {
    return filtersSets.map(filterSet => {
        return {
            ...filterSet,
            filters: filterSet.filters.map(filterInSet => {
                const filterItem = filtersList.filters.find(item => item.name === filterInSet.name);
                if (!filterItem) return filterInSet;
                if (filterItem.type === 'date') {
                    let value = filterInSet.value;
                    if (filterInSet.value && typeof filterInSet.value === "string") {
                        const match = filterInSet.value.match(/\{(\w+)\}/);
                        if (!match) {
                            value = getMoment(filterInSet.value);
                        }
                    }
                    return {
                        ...filterInSet,
                        value: value
                    }
                }
                if (filterItem.type === 'bookerList') {
                    if (filterInSet.value === '{me}') {
                        return {
                            ...filterInSet,
                            value: currentUser.UserID
                        }
                    }
                }
                return filterInSet;
            })
        }
    });
};

// const createBillingProjectsFilterList = (currentUser, customFields) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     BILLING_PROJECTS_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "Booker")
//             iv[filter.name].value = currentUser.UserID;
//     });
//     for (const customField of customFields) {
//         iv[`custom_fields_${customField.Name}`] = {
//             active: false,
//             value: getInitialValue(customField.TypeName),
//             area: 'content and tags'
//         };
//     };
//     return iv;
// };

// const createPackagessFilterList = (currentUser, customFields) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     PACKAGES_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "UserID")
//             iv[filter.name].value = currentUser.UserID;
//     });
//     for (const customField of customFields) {
//         iv[`custom_fields_${customField.Name}`] = {
//             active: false,
//             value: getInitialValue(customField.TypeName),
//             area: 'content and tags'
//         };
//     };
//     return iv;
// };
// const createCustomerFilterList = (currentUser, customFields) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     for (const customField of customFields) {
//         iv[`custom_fields_${customField.Name}`] = {
//             active: false,
//             value: getInitialValue(customField.TypeName),
//             area: 'Demographic'
//         };
//     };
//     CUSTOMERS_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "Booker")
//             iv[filter.name].value = currentUser.UserID;
//     });
//     return iv;
// };
// const createTravelPlanFilterList = (currentUser, customFields) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     TRAVEL_PLANS_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "BookerID") {
//             iv[filter.name].value = currentUser.UserID;
//         }
//     });
//     for (const customField of customFields) {
//         iv[`custom_fields_${customField.Name}`] = {
//             active: false,
//             value: getInitialValue(customField.TypeName),
//             area: 'content and tags'
//         };
//     };
//     return iv;
// };
// const createTodosFilterList = (currentUser, customFields) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     TASKS_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "UserID")
//             iv[filter.name].value = currentUser.UserID;
//     });
//     for (const customField of customFields) {
//         iv[`custom_fields_${customField.Name}`] = {
//             active: false,
//             value: getInitialValue(customField.TypeName),
//             area: 'content and tags'
//         };
//     };
//     return iv;
// };
// const createEventFilterList = (currentUser) => {
//     const iv = {
//         ID: Date.now(),
//         Name: "My custom list"
//     };
//     EVENTS_FILTERS.filters.forEach(filter => {
//         iv[filter.name] = {
//             active: false,
//             value: filter.defaultval,
//             area: filter.area,
//             isFeature: false
//         };
//         if (filter.name === "Booker")
//             iv[filter.name].value = currentUser.UserID;
//     });
//     return iv;
// };

const createStatementFilterList = (currentUser, customFields) => {
    const iv = {
        ID: Date.now(),
        Name: "My custom list"
    };
    STATEMENTS_FILTERS.filters.forEach(filter => {
        iv[filter.name] = {
            active: false,
            value: filter.defaultval,
            area: filter.area,
            isFeature: false
        };
        if (filter.name === "UserID")
            iv[filter.name].value = currentUser.UserID;
    });
    for (const customField of customFields) {
        iv[`custom_fields_${customField.Name}`] = {
            active: false,
            value: getInitialValue(customField.TypeName),
            area: 'content and tags'
        };
    };
    return iv;
};

const mapSetToInitialValues = (initialValues, itemSet) => {
    initialValues.ID = itemSet.id;
    initialValues.Name = itemSet.name;
    itemSet.filters.forEach(filter => {
        if (filter.name === "Features") {
            filter.value.forEach(el => {
                if (initialValues["Features"][`${el.FeatID}`]) {
                    initialValues["Features"][`${el.FeatID}`].active = true;
                    switch (el.FeatType) {
                        case "Conversion":
                            initialValues["Features"][`${el.FeatID}`].value = [
                                el.MinVal,
                                el.MaxVal
                            ];
                            break;
                        case "Numeric":
                            initialValues["Features"][`${el.FeatID}`].value = [
                                el.MinVal,
                                el.MaxVal
                            ];
                            break;
                        case "Multichoice":
                            initialValues["Features"][`${el.FeatID}`].value = {
                                choices: el.Choices,
                                operator: el.Operator
                            };
                            break;
                        case "Trans":
                            initialValues["Features"][`${el.FeatID}`].value = el.Choices;
                            break;
                        case "Choice":
                            initialValues["Features"][`${el.FeatID}`].value = el.Choices;
                            break;
                        case "Boolean":
                            initialValues["Features"][`${el.FeatID}`].value = el.Checked;
                            break;
                        case 'Rating':
                            initialValues["Features"][`${el.FeatID}`].value = el.MinVal;
                            break;
                        default:
                            initialValues["Features"][`${el.FeatID}`].value = el.TextVal;
                            break;
                    }
                }
            }
            );
        } else {
            if (initialValues[`${filter.name}`]) {
                initialValues[`${filter.name}`].active = true;
                initialValues[`${filter.name}`].value = filter.value;
            }
        }
    });
};

const createFilters = obj => {
    const filters = [];
    const features = [];
    for (let [key, value] of Object.entries(obj)) {
        if (key === "Features") {
            for (let [key_f, value_f] of Object.entries(value)) {
                if (value_f.active) {
                    switch (value_f.type) {
                        case "Conversion":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value[0],
                                MaxVal: value_f.value[1]
                            });
                            break;
                        case "Trans":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.map(el => Number(el))
                            });
                            break;
                        case "Multichoice":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.choices,
                                Operator: value_f.value.operator
                            });
                            break;
                        case "Choice":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.length === 0 ? [] : value_f.value
                            });
                            break;
                        case "Boolean":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Checked: value_f.value
                                    ? true
                                    : value_f.value === ""
                                        ? null
                                        : false
                            });
                            break;
                        case "Rating":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value,
                                MaxVal: 5
                            });
                            break;
                        case "Numeric":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value[0],
                                MaxVal: value_f.value[1]
                            });
                            break;
                        default:
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                TextVal: value_f.value
                            });
                            break;
                    }
                }
            }
        } else {
            if (value.active) {
                filters.push({ name: key, value: value.value, fieldTypeName: value.fieldTypeName });
            }
        }
    }

    if (features.length > 0)
        filters.push({ name: "Features", value: features });
    return filters;
};

const formatModelsFilterValues = (filters, currentUser) => {
    return filters.map(filter => {
        return {
            ...filter,
            filters: filter.filters.map(subFilter => {
                const filterItem = MODELS_FILTERS.filters.find(item => item.name === subFilter.name);
                if (filterItem) {
                    if (filterItem.type === 'date') {
                        let value = subFilter.value;
                        if (subFilter.value && typeof subFilter.value === "string") {
                            const match = subFilter.value.match(/\{(\w+)\}/);
                            if (!match) {
                                value = getMoment(subFilter.value);
                            }
                        }
                        return {
                            ...subFilter,
                            value: value
                        }
                    }
                    if (filterItem.type === 'bookerList') {
                        if (subFilter.value === '{me}') {
                            return {
                                ...subFilter,
                                value: currentUser.UserID
                            }
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};

// const formatBillingProjectsFilterValues = (filters, currentUser) => {
//     return filters.map(filter => {
//         return {
//             ...filter,
//             filters: filter.filters.map(subFilter => {
//                 const filterItem = BILLING_PROJECTS_FILTERS.filters.find(item => item.name === subFilter.name);
//                 if (!filterItem) return subFilter;
//                 if (filterItem.type === 'date') {
//                     let value = subFilter.value;
//                     if (subFilter.value && typeof subFilter.value === "string") {
//                         const match = subFilter.value.match(/\{(\w+)\}/);
//                         if (!match) {
//                             value = getMoment(subFilter.value);
//                         }
//                     }
//                     return {
//                         ...subFilter,
//                         value: value
//                     }
//                 }
//                 if (filterItem.type === 'bookerList') {
//                     if (subFilter.value === '{me}') {
//                         return {
//                             ...subFilter,
//                             value: currentUser.UserID
//                         }
//                     }
//                 }
//                 return subFilter;
//             })
//         }
//     });
// };
// const formatPackageFilterValues = (filters, currentUser) => {
//     return filters.map(filter => {
//         return {
//             ...filter,
//             filters: filter.filters.map(subFilter => {
//                 const filterItem = PACKAGES_FILTERS.filters.find(item => item.name === subFilter.name);
//                 if (!filterItem) return subFilter;
//                 if (filterItem.type === 'date') {
//                     let value = subFilter.value;
//                     if (subFilter.value && typeof subFilter.value === "string") {
//                         const match = subFilter.value.match(/\{(\w+)\}/);
//                         if (!match) {
//                             value = getMoment(subFilter.value);
//                         }
//                     }
//                     return {
//                         ...subFilter,
//                         value: value
//                     }
//                 }
//                 if (filterItem.type === 'bookerList') {
//                     if (subFilter.value === '{me}') {
//                         return {
//                             ...subFilter,
//                             value: currentUser.UserID
//                         }
//                     }
//                 }
//                 return subFilter;
//             })
//         }
//     });
// };
// const formatTravelPlanFilterValues = (filters, currentUser) => {
//     return filters.map(filter => {
//         return {
//             ...filter,
//             filters: filter.filters.map(subFilter => {
//                 const filterItem = TRAVEL_PLANS_FILTERS.filters.find(item => item.name === subFilter.name);
//                 if (filterItem.type === 'date') {
//                     let value = subFilter.value;
//                     if (subFilter.value && typeof subFilter.value === "string") {
//                         const match = subFilter.value.match(/\{(\w+)\}/);
//                         if (!match) {
//                             value = getMoment(subFilter.value);
//                         }
//                     }
//                     return {
//                         ...subFilter,
//                         value: value
//                     }
//                 }
//                 if (filterItem.type === 'bookerList') {
//                     if (subFilter.value === '{me}') {
//                         return {
//                             ...subFilter,
//                             value: currentUser.UserID
//                         }
//                     }
//                 }
//                 return subFilter;
//             })
//         }
//     });
// };
// const formatTodoFilterValues = (filters, currentUser) => {
//     return filters.map(filter => {
//         return {
//             ...filter,
//             filters: filter.filters.map(subFilter => {
//                 const filterItem = STATEMENTS_FILTERS.filters.find(item => item.name === subFilter.name);
//                 if (!filterItem) return subFilter;
//                 if (filterItem.type === 'date') {
//                     let value = subFilter.value;
//                     if (subFilter.value && typeof subFilter.value === "string") {
//                         const match = subFilter.value.match(/\{(\w+)\}/);
//                         if (!match) {
//                             value = getMoment(subFilter.value);
//                         }
//                     }
//                     return {
//                         ...subFilter,
//                         value: value
//                     }
//                 }
//                 if (filterItem.type === 'bookerList') {
//                     if (subFilter.value === '{me}') {
//                         return {
//                             ...subFilter,
//                             value: currentUser.UserID
//                         }
//                     }
//                 }
//                 return subFilter;
//             })
//         }
//     });
// };
// const formatEventFilterValues = (filters, currentUser) => {
//     return filters.map(filter => {
//         return {
//             ...filter,
//             filters: filter.filters.map(subFilter => {
//                 const filterItem = EVENTS_FILTERS.filters.find(item => item.name === subFilter.name);
//                 if (filterItem.type === 'date') {
//                     let value = subFilter.value;
//                     if (subFilter.value && typeof subFilter.value === "string") {
//                         const match = subFilter.value.match(/\{(\w+)\}/);
//                         if (!match) {
//                             value = getMoment(subFilter.value);
//                         }
//                     }
//                     return {
//                         ...subFilter,
//                         value: value
//                     }
//                 }
//                 if (filterItem.type === 'bookerList') {
//                     if (subFilter.value === '{me}') {
//                         return {
//                             ...subFilter,
//                             value: currentUser.UserID
//                         }
//                     }
//                 }
//                 return subFilter;
//             })
//         }
//     });
// };

const formatStatementFilterValues = (filters, currentUser) => {
    return filters.map(filter => {
        return {
            ...filter,
            filters: filter.filters.map(subFilter => {
                const filterItem = STATEMENTS_FILTERS.filters.find(item => item.name === subFilter.name);
                if (!filterItem) return subFilter;
                if (filterItem.type === 'date') {
                    let value = subFilter.value;
                    if (subFilter.value && typeof subFilter.value === "string") {
                        const match = subFilter.value.match(/\{(\w+)\}/);
                        if (!match) {
                            value = getMoment(subFilter.value);
                        }
                    }
                    return {
                        ...subFilter,
                        value: value
                    }
                }
                if (filterItem.type === 'bookerList') {
                    if (subFilter.value === '{me}') {
                        return {
                            ...subFilter,
                            value: currentUser.UserID
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};




export {
    createFilters,
    createModelFilterList,
    formatModelsFilterValues,
    getSectionCustomFields,
    createCommonFilterList,
    formatCommonFilterValues,
    mapSetToInitialValues,
    //formatBillingProjectsFilterValues,
    formatStatementFilterValues,
    //formatTodoFilterValues,
    //formatPackageFilterValues,
    //formatEventFilterValues,
    //formatTravelPlanFilterValues,
    //createBillingProjectsFilterList,
    //createEventFilterList,
    //createCustomerFilterList,
    //createTravelPlanFilterList,
    createStatementFilterList,
    //createTodosFilterList,
    //createPackagessFilterList,
}