import React, { useState, useContext } from "react";
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Row, Col } from 'antd';
import Axios from 'axios';

import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import { NWVisibilitySelector, NWTextAreaCharCounter, NWSelect } from 'Components/Gui/NWForm/NwFormItems';
import { useStateValue } from 'Contexts/StateProvider';
import { ListContext } from 'Contexts/ListContext';
import { validateVisibility } from "Libs/NwUtils";
import { onUpdateNotes } from "Hooks/Contact/UseContactNotes";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import NwDrawer from 'Components/Gui/NwDrawer';

const StyledRow = styled(Row)`
    .ant-select-selection-item {
        flex: unset;
    }
`;

const NoteAddEditDrawer = ({ contactID, note, afterNotesCreation }) => {
    const [{ areaContext }] = useStateValue();
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    
   
    let initialValues = {
        Text: note ? note.Text : "",
        Type: note ? note.NoteTypeCode: cachedList.noteTypes.items[0].Code,
        Visibility: {
            isGlobal: true,
            Areas: []
        }
    };

    const addNote = (values, setStatus, setSubmitting) => {
        setSubmitting(true);

        if (note) {
            Axios.put("/notes", {
                ID: note.ID,
                NoteTypeCode: values.Type,
                Text: values.Text,
                PrivacyLevel: "Public",
                ContactID: contactID,
                VisibilityAreas: values.Visibility.Areas
            })
                .then(() => {
                    setTouchForm(false);
                    onUpdateNotes(contactID);
                    afterNotesCreation();
                })
                .catch(error => {
                    console.log("errore", error);
                });
        } else {
            Axios.post("/notes", {
                NoteTypeCode: values.Type,
                Text: values.Text,
                PrivacyLevel: "Public",
                ContactID: contactID,
                VisibilityAreas: values.Visibility.Areas
            })
            .then(() => {
                setTouchForm(false);
                onUpdateNotes(contactID)
                afterNotesCreation()
            })
            .catch(error => {
                console.log("errore", error);
            });
        }
    };

    return (
        <NwDrawer         
            onTouchForm={setTouchForm}
            touchForm={touchForm} 
            title={note ? 'Edit Note' : 'Add Note'} 
            onClose={afterNotesCreation}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Text: Yup.string().required("Content is required")
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    addNote(values, setStatus, setSubmitting);
                }}
                render={({
                    isSubmitting,
                    handleSubmit,
                    values
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <StyledRow>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWVisibilitySelector}
                                    label="Visibility"
                                    inlineLabel
                                    name="Visibility"
                                    value={values.Visibility}
                                    validate={validateVisibility(values.Visibility, areaContext.area)}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24} style={{textAlign: "right"}}>
                                <Field
                                    component={NWSelect}
                                    label="Note category"
                                    name="Type"
                                    inlineLabel
                                    value={values.Type}
                                    options={cachedList.noteTypes.items.map(el => {
                                        return { key: el.Code, label: el.Name, value: el.Code };
                                    })}
                                />  
                            </Col>
                        </StyledRow>
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Note"
                            name="Text"
                            type="text"
                            required
                            value={values.Text}
                            maxLength={2000}
                            style={{width:"100%"}}
                            autoSize={{ minRows: 5, maxRows: 6 }}
                        />
                        <NwFormButtonsBar 
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        afterNotesCreation()
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </NwDrawer>
    );
};

export default NoteAddEditDrawer;
