import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwAddButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import TaskCard from 'Components/TaskList/TaskCard';
import useLocalStorageState from 'Hooks/UseLocalStorageState';
import { useContact, forceReloadContact } from 'Hooks/Contact/UseContact';
import Switch from 'Components/Gui/CustomSwitch';
import { TasksListContainer, TasksListHeader, TasksContainer } from 'Components/TaskList/TasksUI';
import EventDrawer from 'Components/EventDrawer/EventDrawer';

const TaskCardsGrid = ({ contactId, drawerMode, previewMode, contactType, browseTodosType, /*onCloseTodoList*/ }) => {
    const { data: currentContact } = useContact(contactId);
    const [showDetails, setShowDetails] = useLocalStorageState('todos-details', 'show');
    const [addDrawer, setAddDrawer] = useState(false);
    const browseTodos = browseTodosType === 'all-tasks' ? true : false;
    const history = useHistory();

    useEffect(() => {
        if (previewMode && showDetails !== 'show') {
            setShowDetails('show');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReloadTodos = () => {
        forceReloadContact(contactId);
    };

    const handleMoveToSearchTodos = () => {
        history.push(`/search-todos/${contactId}`);
    };

    const isContent = currentContact && ((currentContact.ToDos.length > 0 && !browseTodos && (contactType !== 'model' || showDetails==='show')) || browseTodos);

    return (!previewMode || (currentContact && currentContact.ToDos.length > 0 )) &&
        <TasksListContainer>
            <TasksListHeader isContent={isContent}>
                <NwCard
                    title="Tasks"
                    icon={light("tasks")}
                    horizontal
                >
                    {currentContact && 
                        <FlexContainer justifyContent={browseTodos && contactType !== 'model' ? 'flex-end' : 'space-between'}>
                            {!browseTodos &&
                                <FlexContainer>
                                    <FlexContainer mg='0 4px 0'>
                                        <div>{`${currentContact.ToDos.length} DUE `}</div>
                                        &nbsp;&nbsp;
                                        {currentContact.ToDos.length > 0 && !previewMode && contactType  &&
                                            <Switch
                                                checkedChildren="show details"
                                                onChange={checked => {
                                                    if (!checked) {
                                                        setShowDetails('hide')
                                                    } else {
                                                        setShowDetails('show')
                                                    }
                                                }}
                                                checked={showDetails === 'show'}
                                                unCheckedChildren="hide details"

                                            />
                                        }
                                    </FlexContainer>
                                </FlexContainer>
                            }
                            {!previewMode &&
                                <div>
                                    <NwAddButton
                                        ghost
                                        size='small'
                                        label='search tasks'
                                        icon={solid('search')}
                                        onClick={handleMoveToSearchTodos} />
                                    &nbsp;
                                    <NwAddButton ghost size="small" label='new task' onClick={() => setAddDrawer(true)}/>
                                </div>
                            }
                        </FlexContainer>
                    }
                </NwCard>
            </TasksListHeader>
            {currentContact && currentContact.ToDos.length > 0 && !browseTodos &&
                <TasksContainer showDetails={contactType && showDetails === 'show'}>
                    <NwCard>
                        <Row>
                            {currentContact.ToDos.map((todo, index) => {
                                return (
                                    <Col className='todo-item' lg={drawerMode ? 12 : 8} md={12} sm={12} key={index}>
                                        <TaskCard
                                            todoItem={todo}
                                            previewMode={previewMode}
                                            contactId={contactId}
                                            onUpdate={handleReloadTodos}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </NwCard>
                </TasksContainer>
            }
            
            {addDrawer &&
                <EventDrawer 
                    viewMode='create'
                    onClose={() => setAddDrawer(false)}
                    afterUpdate={handleReloadTodos}
                    eventType="todo"
                    modelId={contactId}
                />
            }
        </TasksListContainer>;
};

export default TaskCardsGrid;