import React from "react";
import { Select } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';

const { Option, OptGroup } = Select;

const Container = styled.div`
    display: flex;
    width: ${props => props.$width};

    .ant-btn:hover, .ant-btn:focus {
        border-color: rgba(0,0,0,0.25) !important;
    }

    .ant-btn:hover {
        color: ${props => props.theme.DangerColor} !important;
    }

    .ant-select-selection {
        border-radius: ${props => props.$allowclear && '4px 0 0 4px'}
    }

    .ant-select-selector {
        border-radius: ${props => !props.$allowClear ? '6px !important' : '6px 0 0 6px !important'};
    }
`;

const StyledSelect = styled(Select)`
    min-width: ${props => props.width ? 'unset' : '200px'};
    width: ${props => props.width && '100%'};
`;

const ClearButtonStyle = {
    padding: '0 8px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 4px 4px 0',
    color: 'rgba(0,0,0,0.25)',
    borderLeft: 'none',
    height: 'auto'
};

const CustomSelect = props => {
    const { disableClosable, width, onChange, allowClear, children, style, mode, options, optionType, ...rest } = props;

    return (
        <Container $width={width} $allowClear={allowClear} style={style}>
            {optionType === 'group' ? 
                <StyledSelect
                    width={width}
                    onChange={onChange}
                    mode={mode}
                    autoComplete='off'
                    {...rest}>
                    {options[0].options.length && 
                        options[0].options.map(option => {
                            return (
                                <Option key={option.value} value={option.value}>{option.label}</Option>
                            )
                        })
                    }
                    {options.filter(optGroup => optGroup.groupName && optGroup.options.length).map(optGroup => {
                        return (
                            <OptGroup key={optGroup.groupName} label={optGroup.groupName}>
                                {optGroup.options.map(option => {
                                    return (
                                        <Option key={option.value} value={option.value}>{option.label}</Option>
                                    )
                                })}
                            </OptGroup>
                        )
                    })}
                </StyledSelect>
                :
                <StyledSelect
                    width={width}
                    onChange={onChange}
                    mode={mode}
                    options={options}
                    autoComplete='off'
                    {...rest}
                />
            }
            {!disableClosable && allowClear &&
                <NwButton icon={light('times')} style={ClearButtonStyle} onClick={event => onChange(mode ? [] : undefined)} />
            }
        </Container>
    );
};

export default CustomSelect;
