export const CUSTOMERS_WALL_LOCALFILTERS_DEFAULT = {    
    sort: "Name",
    namefilter: "",
    category: 1,
    onlymine: false
};
export const CUSTOMERS_WALL_QUICKFILTER_DEFAULT = {    
    sort: "Name",
    namefilter: "",
    category: 1,
    onlymine: false,
    tagFilter: []
};
export const CUSTOMERS_WALL_DEFAULT_STYLE = "table";

export const MODELS_WALL_LOCALFILTERS_DEFAULT = {    
    sort: "Name",
    namefilter: "",
    onlymine: false,
    intown: 'all'
}
export const MODELS_WALL_QUICKFILTER_DEFAULT = { 
    nameFilter: "",
    intown: "all",
    onlymine: false,
    tagFilter: [],
    sort: "Name",
    sortFolder: "Name",
    showQuickFilters: false,
    gender: "all",
    quickFilters: {}
}
export const MODELS_WALL_DEFAULT_STYLE = "cards"
