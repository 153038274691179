import React, { useState } from "react";
import { getMoment } from "Libs/NwMoment";
import { Formik } from "formik";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import {
    useContact,
    forceReloadContact,
    onUpdateContactCustomFields,
} from 'Hooks/Contact/UseContact';
import { getCustomFieldInitialValue, getEmbeddedCustomField } from 'Libs/NwCustomFields';
import { toISODate } from "Libs/NwMoment";

const CustomPropertiesForm = ({ touchForm, type, contactID, customFields, onCancel, onTouchForm }) => {
    const { data: currentContact } = useContact(contactID);
    
    //const [isSubmitting, setIsSubmitting] = useState(false);
    
    const availableCustomFields = customFields ? customFields.filter(field => !field.Disabled) : [];

    // type 1: model
    // type 2: customer
    const isModel = type === 1;

    let initialValues = {
        CustomFields: {}
    };

    availableCustomFields.map(customField => {
        if (!customField.Disabled) {
            initialValues = {
                ...initialValues,
                CustomFields: {
                    ...initialValues.CustomFields,
                    [customField.Name]: getCustomFieldInitialValue(customField.TypeName, currentContact.CustomFields[customField.Name])
                }
            };
        }
        return null;
    })

    const submitForm = async (values, setSubmitting) => {
        const isCustomFieldsTouched = JSON.stringify(initialValues.CustomFields) !== JSON.stringify(values.CustomFields);
        if (isCustomFieldsTouched) {
            let customFieldsData = {};
            Object.keys(values.CustomFields).map(key => {
                if (values.CustomFields[key] !== '' && JSON.stringify(values.CustomFields[key]) !== JSON.stringify([]) && values.CustomFields[key]) {
                    const customField = availableCustomFields.find(item => item.Name === key);
                    if (customField.TypeName === 'MultipleChoice') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key].join(',')
                        }
                    } else if (customField.TypeName === 'Date') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: toISODate(values.CustomFields[key])
                        }
                    } else {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key]
                        }
                    }
                }
                return null;
            })

            try {
                await onUpdateContactCustomFields({ contactId: currentContact.ID, data: customFieldsData });
                forceReloadContact(contactID)
                if (onTouchForm) {
                    onTouchForm(false);
                }
                onCancel();
            } catch (error) {
                console.log('ant : Save Event Custom Fields Error => ', error);
            }
        } else {
            onCancel();
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                submitForm(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    {availableCustomFields.map((customField, index) => {
                        return <div key={`${customField.Name}-${index}`}>
                            {getEmbeddedCustomField(values, customField)}
                        </div>
                    })}
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting || !touchForm}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default CustomPropertiesForm;
