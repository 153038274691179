import { useEffect } from 'react';

const useFormFieldFocus = elementId => {
    useEffect(() => {
        const timer = setTimeout(() => {
            const element = document.getElementById(elementId);
            if (element) {
                element.focus();
            }
        }, 300);
        
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useFormFieldFocus;