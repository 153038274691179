import React from 'react';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";

import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import FlexContainer from 'Components/Gui/FlexContainer';
import { datePickerBeforeOnChange, toISODate } from "Libs/NwMoment";

const CheckDate = styled.div`
    margin: 2px 8px 0 0;
`;

const CheckDatePeriodPicker = ({ value, onChange }) => {
    const onFromDateChange = (val) => {
        onChange({
            FromDate: val,
            ToDate: val
        });
    }
    const onToDateChange = (val) => {
        onChange({
            ...value,
            ToDate: val
        });
    }
    const disableCheckOutDate = beforeDate => date => {
        if (beforeDate && toISODate(beforeDate) > toISODate(date)) {
            return true;
        }
        return false;
    }
    return (
        <FlexContainer>
            <FlexContainer alignItems='flex-start' mg='0 16px 0 0'>
                <CheckDate>Check In</CheckDate>
                <CustomDatePicker
                    name='FromDate'
                    value={value.FromDate ? getMoment(value.FromDate) : null}
                    format="MMM DD, YYYY"
                    style={{ width: "100%" }}
                    getCalendarContainer={triggerNode => triggerNode.parentNode}
                    manipulateValue={datePickerBeforeOnChange}
                    onChange={onFromDateChange}
                />
            </FlexContainer>
            <FlexContainer alignItems='flex-start'>
                <CheckDate>Check Out</CheckDate>
                <CustomDatePicker
                    name='ToDate'
                    value={value.ToDate ? getMoment(value.ToDate) : null}
                    format="MMM DD, YYYY"
                    style={{ width: "100%" }}
                    getCalendarContainer={triggerNode => triggerNode.parentNode}
                    manipulateValue={datePickerBeforeOnChange}
                    onChange={onToDateChange}
                    disabledDate={disableCheckOutDate(value.FromDate)}
                />
            </FlexContainer>
        </FlexContainer>
    )
};


export default CheckDatePeriodPicker;