import React from 'react';
import useEventStyle from 'Hooks/UseEventStyle';
import NwIcon from '../Gui/NwIcon';




const EventTypeIcon = ({ event, big = false, type }) => {
	const eventstyle = useEventStyle( event, type, false, false, true )
	if (!eventstyle || !eventstyle.icon) {
	    return null;
	}
	return (
		<NwIcon style={{marginRight: '.25rem'}} icon={eventstyle.icon} />
	);
};

export default EventTypeIcon;
