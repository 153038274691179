import React, { useState, useEffect } from "react";
import { getMoment } from "Libs/NwMoment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
    NWInput,
    NWTextAreaCharCounter,
    NWRadioButtonGroup,
    NWRangePicker,
    NWContactSelector
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton, NwButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import AddressLine from 'Components/Addresses/AddressLine';
import RoomPreview from 'Components/EventDrawer/RoomLodgingEvent/RoomPreview';
import ExistingRoomLodging from 'Components/EventDrawer/RoomLodgingEvent/ExistingRoomLodging';
import EmptyState from 'Components/Gui/EmptyState';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import {
    useUpdateAccommodation,
    useAddAccommodation
} from 'Hooks/Event/UseAccommodation';
import { getNow, toISODate } from "Libs/NwMoment";
// import { ListContext } from 'Contexts/ListContext';

const BEDS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FormLabel = styled.div`
    width: 40px;
`;

const initialAddress = {
    Country: undefined,
    City: '',
    County: '',
    State: '',
    Zip: '',
    Street: '',
    Access: ''
};

const RoomLodgingForm = props => {
    const {
        accommodationData,
        viewMode,
        modelId,
        afterUpdate,
        onCancel,
        onUpdateAccommodationData,
        room,
        connection: _connection,
        onTouchForm
    } = props;
    const { mutateAsync: onAddAccommodation } = useAddAccommodation();
    const { mutateAsync: onUpdateAccommodation } = useUpdateAccommodation();
    const [availableRooms, setAvailableRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(room);
    const [selectedModel, setSelectedModel] = useState(modelId);
    const [connection, setConnection] = useState(_connection);
    const [isFetchedRooms, setIsFetchedRooms] = useState(false);
    const [isFetchingRooms, setIsFetchingRooms] = useState(false);
    const [existingAccommodations, setExistingAccommodations] = useState([]);
    const [isCheckRoomLodging, setIsCheckRoomLodging] = useState(false);
    const [entireRoom, setEntireRoom] = useState(false);

    const getExistingAccommodations = async () => {
        try {
            const accommodations = await Axios.post('/accommodation/availabilitysearch', {
                ModelID: selectedModel,
                RoomsOnly: true
            });
            setExistingAccommodations(accommodations);
        } catch (error) {
            console.log('ant : Fetch Existing Accommodations Error => ', error);
        }
    }

    useEffect(() => {
        if (selectedModel) {
            getExistingAccommodations()
        }
    }, [selectedModel]);

    const submitForm = async (values, setSubmitting) => {
        const newAccommodationData = {
            ...values,
            ...values.Address,
            FromDate: values.Period[0],
            ToDate: values.Period[1]
        };

        delete newAccommodationData.Address;

        if (!accommodationData) {
            try {
                const newAccommodation = await onAddAccommodation({ accommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate({
                        ...newAccommodation,
                        TypeName: "Accommodation"
                    });
                }
                onUpdateAccommodationData(newAccommodation.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create Accommodation Error", error);
            }
            setSubmitting(false);
        }
        else {
            newAccommodationData.ID = accommodationData.ID;

            try {
                await onUpdateAccommodation({ accommodationData: newAccommodationData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateAccommodationData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update Accommodation Error", error);
            }
            setSubmitting(false);
        }
    };

    const getDefaultPeriod = () => {
        return [getNow(true), getNow(true).add(1, 'month')];
    };

    let initialValues = {
        ModelID: accommodationData ? accommodationData.ModelID : modelId ? modelId : '',
        Title: accommodationData ? accommodationData.Title : '',
        ServiceID: accommodationData ? accommodationData.ServiceID : '',
        RoomID: accommodationData ? accommodationData.RoomID : '',
        Note: accommodationData ? accommodationData.Note : '',
        OccupiedBeds: 1,
        Address: {
            Country: accommodationData ? accommodationData.Country : '',
            City: accommodationData ? accommodationData.City : '',
            County: accommodationData ? accommodationData.County : '',
            State: accommodationData ? accommodationData.State : '',
            Zip: accommodationData ? accommodationData.Zip : '',
            Street: accommodationData ? accommodationData.Street : '',
            Access: accommodationData ? accommodationData.Access : '',
        },
        Period: accommodationData ?
            [getMoment(accommodationData.FromDate), getMoment(accommodationData.ToDate)]
            : getDefaultPeriod(),
        PhoneNumber: '',
        ReservationCode: accommodationData ? accommodationData.ReservationCode || '' : ''
    };

    const handleSetRoom = async (room, setFieldValue) => {
        try {
            const connection = await Axios.get(`/connections/${room.ConnectionID}`);
            setConnection(connection);
            setFieldValue('RoomID', room.ID);
            setFieldValue('ServiceID', room.ServiceID);
            if (connection.Addresses.length) {
                setFieldValue('Address', connection.Addresses[0]);
            } else {
                setFieldValue('Address', initialAddress);
            }
            let phoneNumber = '';
            if (connection.Phones.length) {
                const firstPhone = connection.Phones.find(phone => phone.Number);
                if (firstPhone) {
                    phoneNumber = firstPhone.Number;
                }
            }
            setFieldValue('PhoneNumber', phoneNumber);
        } catch (error) {
            console.log('ant : Get Connection Addresses and Phones Error => ', error);
        }
        setSelectedRoom(room);
        setFieldValue('Title', `${room.ConnectionName} ${room.Name}`);
    }

    const handleFetchRooms = (values, setFieldTouched) => async () => {
        setFieldTouched('Period', true);
        if (!validateRange(values.Period)) {
            const data = {
                RequiredBeds: values.OccupiedBeds,
                FromDate: values.Period[0],
                ToDate: values.Period[1],
                EntireRoom: entireRoom
            };

            setIsFetchingRooms(true);
            try {
                const contactConnectionRooms = await Axios.post('/rooms/GetAvailable', data);
                let rooms = [];
                for (const contactConnections of contactConnectionRooms) {
                    for (const connection of contactConnections.Connections) {
                        for (const room of connection.Rooms) {
                            rooms.push({
                                ...room,
                                ServiceID: contactConnections.ServiceID,
                                ConnectionName: connection.ConnectionName
                            });
                        }
                    }
                }
                setAvailableRooms(rooms);
                if (!isFetchedRooms) {
                    setIsFetchedRooms(true);
                }
                setIsFetchingRooms(false);
            } catch (error) {
                console.log('ant : Fetch Rooms Error => ', error);
                setIsFetchingRooms(false);
            }
        }
    };

    const getTypeIcon = type => {
        switch (type) {
            case 'Land':
                return light('phone-office');
            case 'Mobile':
                return solid('mobile-alt');
            case 'Fax':
                return light('fax');
            case 'Other':
                return light('phone');
            default:
                break;
        }
    };

    const disableDate = rangeDate => {
        const period = existingAccommodations.find(accommodation => {
            if (toISODate(rangeDate) >= toISODate(accommodation.FromDate) && toISODate(rangeDate) <= toISODate(accommodation.ToDate) ) {
                return true;
            }
            return false;
        });

        if (period) {
            return true;
        }
    };

    const validateRange = range => {
        if (range) {
            const period = existingAccommodations.find(accommodation => {
                if (toISODate(range[0]) > toISODate(accommodation.ToDate) || toISODate(range[1]) < toISODate(accommodation.FromDate) ) {
                    return false;
                }
                return true;
            });

            if (period) {
                return 'This period is overlapped with other periods';
            }
            return null;
        }
        return 'Please select period';
    };

    const handleCheckRoomLodging = () => {
        setIsCheckRoomLodging(true);
    };

    return (
        <div className='event-form'>
            <div className='drawer-content'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        Title: Yup.string().required("Content is required"),
                        ServiceID: Yup.number().required('Content is required'),
                        ModelID: Yup.number().required('Content is required'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        submitForm(values, setSubmitting);
                    }}
                >
                    {({ isSubmitting, values, handleSubmit, setFieldValue, validateField, setFieldTouched }) => (
                        <NwForm
                            values={values}
                            onTouchForm={onTouchForm}
                            small
                            onFinish={handleSubmit}
                            layout="vertical">
                            {!modelId &&
                                <Field
                                    component={NWContactSelector}
                                    label="Model"
                                    name="ModelID"
                                    activeTypes={['mod']}
                                    value={values.ModelID}
                                    onAfterChange={value => {
                                        setSelectedModel(value);
                                    }}
                                />
                            }
                            <FlexContainer alignItems='flex-start'>
                                <Field
                                    component={NWRangePicker}
                                    label={
                                        <div>
                                            First Night
                                            <span style={{ width: "5.5rem", display: "inline-block" }}>&nbsp;</span>
                                            Last Night
                                        </div>
                                    }
                                    validate={validateRange}
                                    name='Period'
                                    format="MMM DD, YYYY"
                                    disabledDate={disableDate}
                                    disabled={selectedRoom ? 1 : 0}
                                    value={values.Period}
                                />
                                <p
                                    style={{ margin: '24px 16px 0' }}
                                    className={`nw-link ${selectedModel ? '' : 'disabled'}`}
                                    onClick={selectedModel ? handleCheckRoomLodging : undefined}>
                                    Check model's lodgings
                                </p>
                            </FlexContainer>
                            <FlexContainer alignItems='flex-start'>
                                <FlexContainer alignItems='flex-start'>
                                    <FormLabel>Beds</FormLabel>
                                    <Field
                                        size='small'
                                        component={NWRadioButtonGroup}
                                        value={values.OccupiedBeds}
                                        name="OccupiedBeds"
                                        buttonStyle="solid"
                                        options={BEDS.map(bed => {
                                            return { key: bed, value: bed, label: bed };
                                        })}
                                        disabled={selectedRoom ? 1 : 0}
                                    />
                                </FlexContainer>
                                <FlexContainer mg='0 16px'>
                                    <CustomSwitch
                                        text='Entire Room'
                                        value={entireRoom}
                                        disabled={selectedRoom ? 1 : 0}
                                        onChange={setEntireRoom} />
                                </FlexContainer>
                                <NwButton
                                    primary
                                    label='Find Room'
                                    loading={isFetchingRooms}
                                    disabled={isFetchingRooms || (!values.ModelID && !modelId)}
                                    loadingLabel={'Finding...'}
                                    onClick={handleFetchRooms(values, setFieldTouched)} />
                            </FlexContainer>
                            {availableRooms.length === 0 && isFetchedRooms ?
                                <EmptyState message='No Accommodations' />
                                : !selectedRoom && <FlexContainer mg='16px 0 0' fullWidth>
                                    <Row gutter={16} style={{ width: '100%' }}>
                                        {availableRooms.map(room => {
                                            return (
                                                <Col span={8} key={room.ID}>
                                                    <RoomPreview
                                                        room={room}
                                                        fromDate={values.Period[0]}
                                                        toDate={values.Period[1]}
                                                        onSelectRoom={() => handleSetRoom(room, setFieldValue)} />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </FlexContainer>
                            }
                            {selectedRoom &&
                                <>
                                    <FlexContainer mg='16px 0' fullWidth>
                                        <Row gutter={16} style={{ width: '100%' }}>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <RoomPreview
                                                    room={selectedRoom}
                                                    readOnly
                                                    fromDate={values.Period[0]}
                                                    toDate={values.Period[1]} />
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <FlexContainer mg='1rem 0'>
                                                    <NwButton
                                                        primary
                                                        label='Search for another room'
                                                        onClick={() => {
                                                            setConnection();
                                                            setFieldValue('RoomID', '');
                                                            setFieldValue('ServiceID', '');
                                                            setFieldValue('Address', initialAddress);
                                                            setFieldValue('PhoneNumber', '');
                                                            setFieldValue('Title', '');
                                                            setFieldValue('Note', '');
                                                            setSelectedRoom();
                                                            setAvailableRooms([]);
                                                        }} />
                                                </FlexContainer>
                                            </Col>
                                        </Row>
                                    </FlexContainer>
                                    <Field
                                        component={NWInput}
                                        label="Title"
                                        name="Title"
                                        type="text"
                                        value={values.Title}
                                    />
                                    <NwDivider>Addresses</NwDivider>
                                    <FlexContainer alignItems='flex-start' justifyContent='space-between'>
                                        {connection.Addresses.length > 0 &&
                                            <FlexContainer mg='0 0 16px'>
                                                <AddressLine address={connection.Addresses[0]} disableMap />
                                            </FlexContainer>
                                        }
                                        {connection.Phones.filter(phone => phone.Number).length > 0 &&
                                            <Field
                                                component={NWRadioButtonGroup}
                                                name="PhoneNumber"
                                                value={values.PhoneNumber}
                                                buttonType='radio'
                                                options={connection.Phones.map(phone => {
                                                    return {
                                                        key: phone.ID,
                                                        value: phone.Number,
                                                        label: phone.Number,
                                                        icon: getTypeIcon(phone.Type)
                                                    };
                                                })}
                                            />
                                        }
                                    </FlexContainer>
                                    <NwDivider />
                                    <Field
                                        component={NWTextAreaCharCounter}
                                        label="Note"
                                        name="Note"
                                        type="text"
                                        value={values.Note}
                                        maxLength={2000}
                                        autoSize={{ minRows: 4, maxRows: 10 }}
                                    />
                                    <Field
                                        component={NWInput}
                                        label="Reservation Code"
                                        name="ReservationCode"
                                        type="text"
                                        value={values.ReservationCode}
                                    />
                                    <NwFormButtonsBar
                                        left={
                                            <NwCancelButton
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    if (onTouchForm) {
                                                        onTouchForm(false);
                                                    }
                                                    onCancel();
                                                }}
                                            />
                                        }
                                        right={
                                            <NwSaveButton
                                                htmlType="submit"
                                                disabled={isSubmitting}
                                                loading={isSubmitting}
                                            />
                                        }
                                    />
                                </>
                            }
                        </NwForm>
                    )}
                </Formik>
                {isCheckRoomLodging &&
                    <ExistingRoomLodging
                        accommodations={existingAccommodations}
                        onClose={() => setIsCheckRoomLodging(false)} />
                }
            </div>
        </div>
    );
};

export default React.memo(RoomLodgingForm);
