import React from 'react';
import { Result } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import styled from 'styled-components';
import { thin } from '@fortawesome/fontawesome-svg-core/import.macro';

const ResultContainer = styled.div`
    .ant-result-icon {
        font-size: 5rem;
        opacity: .5;
    }

    &.full-page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 4rem);
        background-color: ${props => props.theme.BackgroundColorLight};

        .ant-result-icon {
            font-size: 10rem;
            opacity: .5;
        }
    }
`


const BuildingFeature = ({ fullPage }) => {
    return (
        <ResultContainer className={fullPage ? 'full-page' : ''}>
            <Result
                icon={<NwIcon icon={thin('computer-classic')} />} //bounce
                title="Coming soon"
                subTitle={<p>Sorry, this feature is currently under development.<br/>It will be ready soon!</p>}
            />
        </ResultContainer>
    );
}

export default BuildingFeature;