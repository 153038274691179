import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import ModelAgenciesDrawer from 'Components/ModelAgencies/ModelAgenciesDrawer';
import AgencyModels from 'Components/ContactDetails/CustomerDetails/CommonComponents/CustomerModelList/AgencyModels';
import FlexContainer from 'Components/Gui/FlexContainer';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { getModel } from 'Hooks/Contact/UseContactsList';
import useLocalStorageState from 'Hooks/UseLocalStorageState';
import { useStateValue } from 'Contexts/StateProvider';
import { MODEL_AGENCY_TYPES, MODEL_AGENCY_TYPE_ORDERS } from 'Constants/ModelAgencyTypes';
import { useAgencyModels } from 'Hooks/Contact/UseAgencyModels';

const { TabPane } = Tabs;

const ModelsContainer = styled.div`
    width: 100%;
    opacity: 1;
    max-height: 100%;
    overflow: hidden;
    transition: all 0.3s;
    .ant-card-actions {
        border-top: none;
        padding: 0 16px;
        li {
            width: unset !important;
            float: right;
        }
    }
    .nw-card-paginated-content {
        min-height: 454px;
    }
    .ant-tabs-nav {
        margin-bottom: 0;
    }
    .ant-card-head {
        display: ${props => !props.fluid && 'none'};
    }
    .ant-card {
        border-radius: 0 0 6px 6px;
    }
    .ant-card-bordered {
        border-top: none;
    }
`;

const ModelsHeader = styled.div`
    width: 100%;
    .ant-card-bordered {
        border: 0.5px solid #f0f0f0;
    }

    .ant-card {
        border-radius: 6px 6px 0 0;
    }
`;

const CardNameLine = styled(FlexContainer)`
    .title {
        margin-right: 1rem !important;
    }
`;

const ModelCounter = styled.div`
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: ${props => props.theme.DefaultBorder};

    &:last-child {
        border-right: none;
    }
`;

const CustomerModelList = ({ contactID, fluid }) => {
    const [{ areaContext }] = useStateValue();
    //const { data: currentContact } = useContact(contactID);
    const { data: currentAgencyModels } = useAgencyModels(contactID);
    const [isAddModelAgency, setIsAddModelAgency] = useState(false);
    const [filterModelType, setFilterModelType] = useState(areaContext.area === 'scouting' ? 'Scouting' : 'Booking');
    const [activeTab, setActiveTab] = useState('All');
    //const [browseModels, setBrowseModels] = useLocalStorageState('show-todos', false);

    const handleChangeTab = tab => {
        setActiveTab(tab);
    };

    const handleAddNewModelAgency = () => {
        setIsAddModelAgency(true);
    };

    // const handleUpdateModelType = event => {
    //     setFilterModelType(event.target.value);
    // };
    
    const availableModels = useMemo(() => {
        let availableModels = [];
        if (currentAgencyModels) {
            let models = []; 
            for (const model of currentAgencyModels) {
                const modelObject = getModel(model.ModelID);
                if (modelObject) {
                    models.push({
                        ...model,
                        Name: `${modelObject.N} ${modelObject.S}`,
                        ST: modelObject.ST 
                    });
                }
            }
            
            availableModels = models.filter(model => {
                if (model.ST === 4 && filterModelType === 'Booking' && (model.TypeName === activeTab || activeTab === 'All')) {
                    return true;
                } else if (model.ST === 5 && filterModelType === 'Scouting' && (model.TypeName === activeTab || activeTab === 'All')) {
                    return true;
                }
                return false;
            });

            availableModels.sort((model1, model2) => model1.Name > model2.Name ? 1 : -1);
            availableModels.sort((model1, model2) => MODEL_AGENCY_TYPE_ORDERS[model1.TypeName] >= MODEL_AGENCY_TYPE_ORDERS[model2.TypeName] ? 1 : -1);
        }
        return availableModels;
    }, [currentAgencyModels, filterModelType, activeTab]);

    const {
        motherModels,
        placementModels,
        otherModels,
        scoutingModels,
        bookingModels
    } = useMemo(() => {
        let models = {
            motherModels: [],
            placementModels: [],
            otherModels: [],
            scoutingModels: [],
            bookingModels: []
        };
        if (currentAgencyModels) {
            let agencyModels = [];
            for (const model of currentAgencyModels) {
                const modelObject = getModel(model.ModelID);
                if (modelObject) {
                    agencyModels.push({
                        ...model,
                        Name: `${modelObject.N} ${modelObject.S}`,
                        ST: modelObject.ST 
                    });
                }
            }
            models.scoutingModels = agencyModels.filter(model => model.ST === 5);
            models.bookingModels = agencyModels.filter(model => model.ST === 4);
            const filteredModels = areaContext.area === 'scouting' ? [...models.scoutingModels] : [...models.bookingModels];
            models.motherModels = filteredModels.filter(model => model.TypeName === 'Mother');
            models.placementModels = filteredModels.filter(model => model.TypeName === 'Placement');
            models.otherModels = filteredModels.filter(model => model.TypeName === 'Other');
        }
        return models;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAgencyModels]);
    
    return (
        <FlexContainer column alignItems='flex-start' mg='0 0 1rem' fullWidth>
            {!fluid && 
                <ModelsHeader>
                    <NwCard 
                        title={
                            <CardNameLine>
                                <h1 className='title'>Models</h1>
                                {/* {browseModels ?
                                    <NwButton 
                                        ghost
                                        icon={light('compress-wide')}
                                        solid 
                                        size="small"
                                        iconOnly
                                        onClick={() => setBrowseModels(false)} />
                                    : 
                                    <NwButton 
                                        ghost
                                        icon={light('expand-wide')}
                                        solid
                                        size="small"
                                        iconOnly
                                        onClick={() => setBrowseModels(true)}
                                    />
                                } */}
                            </CardNameLine>
                        }
                        icon={light("user-friends" )}
                        horizontal>
                        {currentAgencyModels && 
                            <FlexContainer justifyContent='space-between' alignItems='center' flex={1}>
                                <FlexContainer>
                                    <FlexContainer>
                                        {motherModels.length > 0 && <ModelCounter>{`${motherModels.length} as mother`}</ModelCounter>}
                                        {placementModels.length > 0 && <ModelCounter>{`${placementModels.length} as placement`}</ModelCounter>}
                                        {otherModels.length > 0 && <ModelCounter>{`${otherModels.length} as other`}</ModelCounter>}
                                        {areaContext.area !== 'scouting' && scoutingModels.length > 0 && <ModelCounter>{`${scoutingModels.length} for scouting`}</ModelCounter>}
                                        {areaContext.area === 'scouting' && bookingModels.length > 0 && <ModelCounter>{`${bookingModels.length} for booking`}</ModelCounter>}
                                    </FlexContainer>
                                </FlexContainer>
                                <div>
                                    {/* {browseModels &&  */}
                                        <NwAddButton ghost size="small" label='new model' onClick={handleAddNewModelAgency}/>
                                    {/* } */}
                                </div>
                            </FlexContainer>
                        }
                    </NwCard>
                </ModelsHeader>
            }
            {/* {browseModels &&  */}
                <ModelsContainer>
                    <NwCard
                        title="Models"
                        icon={light("user-friends")}
                        extra={
                            <NwAddButton ghost onClick={handleAddNewModelAgency} />
                        }
                    >
                        {currentAgencyModels ?
                            fluid ? <AgencyModels models={currentAgencyModels} />
                            : 
                                <Tabs
                                    tabBarExtraContent={
                                        <RadioButtonGroup 
                                            value={filterModelType} 
                                            onChange={setFilterModelType} 
                                            size='small'
                                            options={[
                                                { label: 'Booking', value: 'Booking' },
                                                { label: 'Scouting', value: 'Scouting'}
                                            ]} />
                                    }
                                    size='small' 
                                    activeKey={activeTab} 
                                    onChange={handleChangeTab}>
                                    {MODEL_AGENCY_TYPES.map(tab => (
                                        <TabPane 
                                            tab={tab.label} 
                                            key={tab.value}>
                                            <AgencyModels
                                                modelType={filterModelType} 
                                                models={availableModels} />
                                        </TabPane>
                                    ))}
                                </Tabs>
                            : <div />
                        }
                        {isAddModelAgency && 
                            <ModelAgenciesDrawer 
                                viewMode={'INSERTING'}
                                exclusions={[]}
                                excludedModels={currentAgencyModels ? currentAgencyModels.map(model => model.ModelID) : []}
                                agencyID={contactID}
                                onClose={() => setIsAddModelAgency(false)}
                            />
                        }
                    </NwCard>
                </ModelsContainer> 
            {/* } */}
        </FlexContainer>
    );
};

export default CustomerModelList;