import styled from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables';
import cancelledstamp from '_img/cancelled.png';
import NwIcon from 'Components/Gui/NwIcon';

export const EventContainer = styled.div`    
    .event-content-wrapper {    
        overflow: auto;
        
        .event-details {
            margin-top: 1rem;
            padding: 0 2em;
        }

        .spec {
            flex: 1 0 0;
            small {
                font-size: .7rem;
                text-transform: uppercase;
            }
        }
    }
    
    .event-form {
        padding: 1rem;
    }
`;

export const DetailItemContainer = styled.div`
    display: block;
    /* border-bottom: ${StyleVariables.DefaultBorder}; */
    width: 100%;
`;

export const EventFieldsTable = styled.div`
  list-style-type: none;
  padding-left: 0px;
  margin-left: 40px;
  margin-bottom: 20px;
  .title-cell {
    padding-right: 40px;
    font-size: .65rem;
    text-transform: uppercase;
  }
  .content-cell {
    padding-left: 20px;
    font-size: .95rem;
  }
`

export const ObjectHistoryContainer = styled.div`
  border-top: ${props => props.theme.DefaultBorder};
  border-bottom: ${props => props.theme.DefaultBorder};
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
  font-size: .8rem;
  background-color: ${props => props.theme.LayoutBodyBackground};
`;

export const EventDaysHeaderContainer = styled.div`
  position: ${props => !props.plainMode && 'sticky'};
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 40px;
  top: 120px;
  background: white;
  z-index: 2;
  align-items: center;
  height: 50px;
  border-bottom: ${props => props.theme.DefaultBorder};
`;

export const EventActionsToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 0 0;
  top: 120px;
  background: white;
  z-index: 2;
  align-items: center;
  height: 50px;
  border-bottom: ${props => props.theme.DefaultBorder};
`;

export const EventTitleText = styled.h1`
    font-size: 1.3rem;
    padding: .5rem 0;
    flex: 1 0 0;
    line-height: 1em;
    margin-bottom: 0;

    &.is-cancelled {
        ${props => props.theme.RedLineThrough(2)}
    }
`;

export const EventExternalJobAgencyName = styled.div`
    display: flex;
    align-items: center;

    h2 {
        font-weight: bold;
        padding-right: .5rem;
        margin: 0;
    }

    .ant-tag {
        margin-bottom: 0 !important;
    }

    &.is-cancelled {
      ${props => props.isInStorage && props.theme.RedLineThrough(2)}
    }
`;

export const EventTitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 20px 30px 0px 40px;

  .event-title-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 9rem;

    .project-send-button {
        font-size: 1.4rem;
    }
  }
`;

export const EventDetailsWrapper = styled.div`

    width: 100%;
    padding: 16px 30px 16px 40px;

    &.is-cancelled {
        /* background-image: url(${cancelledstamp});
        background-size: auto 90%;
        background-repeat: no-repeat;
        background-position: bottom .5rem right 1rem; */
    }

    .description {
        font-size: .95rem;
        text-transform: none;
    }
    .arrow-icon {
        margin: 0 1rem;
    }
    .label {
        font-size: .85rem;
        text-transform: uppercase;
        font-weight: 300;
    }
    .date-time {
        font-size: 1rem;
    }

    .address-container {
        font-size: 1rem;
    }

    &:first-child {
        padding-left: 0;
        flex: unset;
        min-width: 10rem;
    }
    &:last-child {
        border-right: none;
    }

    /* .row {
        display: flex;
        .col {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 1rem;
            text-transform: uppercase;
            font-size: .65rem;
        }
    } */

    .header {
        .col {
            .content {
                font-size: 1rem;
            }
            &:last-child {
                padding-bottom: 20px;
            }
        }
    }
`;

export const BookingEventTitleText = styled.h1`
    font-size: 1.4rem;
    padding: .5rem 0;
    flex: 1 0 0;
    line-height: 1em;
    margin-bottom: 0;

    strong {
        font-weight: 700;
    }

    &.is-cancelled {
      ${props => props.isInStorage && props.theme.RedLineThrough(2)}
    }
`;

export const BookingEventModelDetailsWrapper = styled.div`
    
    display: flex;
    width: 100%;
    
    &.is-cancelled {
        /* background-image: url(${cancelledstamp});
        background-size: auto 90%;
        background-repeat: no-repeat;
        background-position: bottom .5rem right 1rem; */
    }
`;

export const BookingEventDateContainer = styled.div`
  padding: 10px 10px 10px 20px;
  width: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-bottom: ${props => props.theme.DefaultBorder};
  
  .calendar-button {
    padding-top: 8px;
    margin-top: auto;
  }
`;

export const BookingEventModelInfo = styled.div`
  padding: 1rem 2rem;
  flex: 1;
  border-left: ${props => props.theme.DefaultBorder};
  border-bottom: ${props => props.theme.DefaultBorder};
  .selected-model-name {
    font-weight: 700;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    .model-option-number {
      margin-left: 5px;
      .ant-badge-count {
        background-color: ${props => props.theme.GoldColor};
      }
    }
  }
  div.event-info-collapse {
    .ant-collapse-header {
      display: flex;
      width: 100%;
      padding-left: 0px;
      padding-bottom: 0px;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 10px;
      .ant-collapse-arrow {
        left: 0px;
        top: 27px;

      }
      .models-names-list {
        margin-top: -4px;
        padding-left: 1px;
        text-transform: capitalize;

        span.is-cancelled {
          ${props => props.isInStorage && props.theme.RedLineThrough(2)}
        }
        em {
          font-style: normal;
        }
      }
    }
    .ant-collapse-content .ant-collapse-content-box {
      padding-left: 0px;
      padding-top: 0px;
    }
  }
`;

export const BookingEventSingleModelNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 700;
  width: 100%;
  padding: ${props => props.withPadding ? '20px 16px 0px 0px' : '0px'};
  margin-top: ${props => props.withMarginTop ? '12px' : '-4px'};
  .model-name-container {
    display: flex;
    align-items: center;
    .model-name {
      margin-right: 5px;
      text-transform: capitalize;

      &.is-cancelled {
        ${props => props.theme.RedLineThrough(1)}
      }
    }
  }
`;

export const BookingEventDateInfo = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;
  /* div {
    .notes {
      padding-bottom: 10px;
      align-items: baseline;
    }
    .notes, .time-and-address {
      display: flex;
    }
    .notes .note-title {
      min-width: 70px;
      font-size: 0.7rem;
      text-transform: uppercase;
    }
    .time-and-address {
      .time-range {
        width: 100px
      }
    }
    .address-info {
      display: flex;
      .address {
        display: flex;
        .address-data {
          padding-left: 10px;
        }
      }
    }
  
    > span {
      margin-left: auto;
    }
  } */
`;

export const BookingEventModelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  .selected-model-name {
    .model-name {
      ${props => props.isCanceled ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
      text-transform: capitalize;
    }
  }
`;

export const BookingEventStyledCalendarEditIcon = styled(NwIcon)`
  cursor: pointer;
  font-size: 1.5rem;
`;