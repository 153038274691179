import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';

const saveCustomerSmartList = async customs => {
    try {
        await Axios.post('/userclientconfigurations', {
            Name: 'customer-smart-list',
            JsonData: JSON.stringify({
                smartList: customs
            })
        });
    } catch (error) {
        console.log('ant : Save Jobs Smart List Error => ', error);
    }
};

const CustomerFiltersReducer = (state, action) => {
    switch (action.type) {
        case "CustomerFiltersChangeActiveSet":
            ls.set("nw_config_customerwallfilter", {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    activeSet: action.newActiveSet,
                    filterType: action.filterType,
                }
            });
            return {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    activeSet: action.newActiveSet,
                    filterType: action.filterType,
                }
            };
        case "CustomerFiltersAddCustomSet":
            const customSetCopy = [...state[action.area].customSets];
            customSetCopy.push(action.newSet);
            ls.set("nw_config_customerwallfilter", {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: customSetCopy,
                    activeSet: action.newSet.id,
                }
            });

            let smartList = [...customSetCopy];
            saveCustomerSmartList(smartList);

            return {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: customSetCopy,
                    activeSet: action.newSet.id,
                }
            };
        case "CustomerFiltersEditCustomSet":
            let customSetCopy2 = state[action.area].customSets.filter(
                el => el.id !== action.newSet.id
            );
            action.newSet.id = Date.now();
            customSetCopy2.push(action.newSet);
            ls.set("nw_config_customerwallfilter", {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: customSetCopy2,
                    activeSet: action.newSet.id,
                }
            });
            saveCustomerSmartList([...customSetCopy2]);
            return {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: customSetCopy2,
                    activeSet: action.newSet.id,
                }
            };
        case "CustomerFiltersCloneSet":
            const copyOfActive = _.cloneDeep(action.activeSet);
            copyOfActive.id = Date.now();
            copyOfActive.fixed = false;
            copyOfActive.name = copyOfActive.name + "(copy)";
            const copyOfCustomSets = [...state[action.area].customSets];
            copyOfCustomSets.push(copyOfActive);
            ls.set("nw_config_customerwallfilter", {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: copyOfCustomSets,
                    activeSet: copyOfActive.id
                }
            });
            saveCustomerSmartList([...copyOfCustomSets]);
            return {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: copyOfCustomSets,
                    activeSet: copyOfActive.id
                }
            };
        case "CustomerFiltersDeleteSet":
            const newCustomSets = [...state[action.area].customSets].filter(
                el => el.id !== action.activeSet
            );
            ls.set("nw_config_customerwallfilter", {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: newCustomSets,
                    activeSet: "v0"
                }
            });
            saveCustomerSmartList([...newCustomSets]);
            return {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: newCustomSets,
                    activeSet: "v0"
                }
            };
        case "CustomerSaveSets":
            const newFilterData = {
                ...state,
                [action.area]: {
                    ...state[action.area],
                    customSets: action.customSets,
                }
            };
            ls.set("nw_config_customerwallfilter", newFilterData);
            return newFilterData;
        default:
            //console.log("default del reducer")
            return state;
    }
};

export default CustomerFiltersReducer;
