import React, { useEffect, useMemo } from "react";
import { NWSelect } from "Components/Gui/NWForm/NwFormItems";
import { Field } from "formik";
import { getModelsByEvent } from './eventModelsSelectorUtils';

const EventModelSelector = ({
    disabled = false,
    event,
    fixedIfSingle = false,
    form,
    modelId,
    name = 'ModelID',
    onChange,
    project,
    required = false,
    withZeroOption = false,
}) => {

    const models = useMemo(() => {
        let filteredModels = getModelsByEvent(event, project);
        if (fixedIfSingle && filteredModels.length === 1) {
            return filteredModels
        }
        if (withZeroOption) {
            filteredModels = [{ ModelId: null, Name: 'Select a model' }, ...filteredModels]
        }
        return filteredModels
    }, [project, event]);

    useEffect(() => {
        if (models && models.length === 1) {
            form.setFieldValue(name, models[0].ModelId);
        }
    }, [models]);

    const handleOnChange = (form, modelID) => {
        if (onChange) {
            onChange(form, modelID);
        }
    }

    return (
        <Field
            component={NWSelect}
            value={form.values[name]}
            label="Model"
            name={name}
            onAfterChange={modelID => handleOnChange(form, modelID)}
            disabled={(modelId || models.length === 1) && disabled}
            placeholder='Select a model'
            options={models.map(el => {
                return { key: el.ModelId, value: el.ModelId, label: el.Name };
            })}
            required={required}
        />
    );
};

export default EventModelSelector;
