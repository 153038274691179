import React from "react";

import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const PrivacyLevelSelector = props => {
    return (
        <RadioButtonGroup 
            disabled={props.disabled} 
            value={props.value} 
            defaultValue={1} 
            onChange={props.onChange}
            options={[
                { label: 'Public', value: 'Public' },
                { label: 'Personal', value: 'User'}
            ]} />
    );
};

export default PrivacyLevelSelector;





