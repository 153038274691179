import React, { useState } from 'react';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
import { Loading } from 'Components/Gui/Loading';
import SendProjectDrawerFromEvent from 'Components/SendProjectDrawer/SendProjectDrawerFromEvent';
import useNwBreakPoints from 'Hooks/UseNwBreakPoints';
import { forceReloadPackage, usePackage } from 'Hooks/Packages/UsePackages';
import { useEffect } from 'react';
import { groupPackage } from 'Libs/NwUtils';
import { PackageDrawerContainer } from 'Components/PackageDrawer/PackageDrawerUI';
import PackageHeader from 'Components/PackageDrawer/PackageHeader/PackageHeader';
import PackageContent from 'Components/PackageDrawer/PackageContent';

const ProjectPackageDetails = ({
    afterRemove,
    afterUpdate,
    onSelectEvent,
    //packageData,
    packageID,
    plainMode,
}) => {
    const [addMode, setAddMode] = useState(false);
    const { data: currentPackage, isFetching } = usePackage(packageID); //packageData.ID
    const [groupedPackage, setGroupedPackage] = useState();
    const [isSort, setIsSort] = useState(false);

    // const handleShareProject = () => {
    //     setSendProjectDrawer(true);
    // }


    const onUpdatePackage = (skipactive, isDeleted = false) => {
        if (!isDeleted) {
            forceReloadPackage(packageID) //packageData.ID
        }
    }

    const onDeletePackage = () => {
        afterRemove()
    }

    // const onClonePackage = (ClonedPackageID) => {
    //     setCurrentPackageID(ClonedPackageID)
    // }

    const handleSetAddMode = () => {
        setAddMode(prevAddMode => !prevAddMode);
    };

    useEffect(() => {
        if (currentPackage) {
            setGroupedPackage(groupPackage(currentPackage));
        }
    }, [currentPackage]);

    return (
        (isFetching || !groupedPackage)
            ?
            <Loading textBlack />
            :
            <div>
                {!plainMode &&
                    <ProjectEventsTimeline
                        event={{
                            //...packageData,
                            ...currentPackage,
                            TypeName: "Package"
                        }}
                        projectID={groupedPackage.Project.ID}
                        onSelectEvent={onSelectEvent}
                        showPackage
                    />
                }
                <PackageDrawerContainer>
                    <PackageHeader
                        currentPackage={groupedPackage}
                        onUpdatePackage={onUpdatePackage}
                        onDeletePackage={onDeletePackage}
                        onClonePackage={() => { }} //onClonePackage
                        isSort={isSort}
                        onSetIsSort={setIsSort}
                        compact
                    />
                    <PackageContent
                        isSort={isSort}
                        currentPackage={groupedPackage}
                        addMode={addMode}
                        showOnlyGroupModels
                        onUpdatePackage={onUpdatePackage}
                        onSetAddMode={handleSetAddMode}
                        compact
                    />
                </PackageDrawerContainer>
            </div>
    )
}

export default ProjectPackageDetails;