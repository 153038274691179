import React from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import FlexContainer from 'Components/Gui/FlexContainer';
import { EventModelNameLineNonBooking } from './EventModelNameLineNonBooking';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';

const EventTitleContainer = styled.div`
    padding: 1rem 1.5rem;

    h1, h2, p {
        margin-bottom: 0;
    }

    &.cancel {
        h2 {
            ${props => props.theme.RedLineThrough(2)}
        }
    }
`

export const EventTitle = (props) => {

    const isMeeting = (props.eventProperties.eventType === "Meeting")
    const isScoutingAccommodation = (props.eventProperties.eventType === "ScoutingAccommodation")

    const buttonactions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: props.actions.onEditGeneral },
    ];
    if (props.actions.onDeleteGroup) {
        buttonactions.push({ name: "delete for all models", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: props.actions.onDeleteGroup })
    }
    if ((isMeeting || isScoutingAccommodation) && props.actions.onDelete) {
        buttonactions.push({ name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: props.actions.onDelete })
    }
    if (props.event.Project) {
        buttonactions.push({ name: 'unlink from project', key: 'unlink-project', icon: light('trash-alt'), action: props.actions.onProjectUnlink })
    }
    
    const isGloballyCancelled = () => {
        if (props.event.Cancel) {
            if (props.event.RelatedEvents && props.event.RelatedEvents.length > 0) {
                if (props.event.RelatedEvents.every(e => e.Cancel)) {
                    return true
                } else {
                    return false
                }
            }
            return true
        }
        return false
    }

    if (!props.eventProperties.isSingle || isMeeting || isScoutingAccommodation) {
        return (
            <EventTitleContainer className={isGloballyCancelled() ? 'cancel' : null}>
                <FlexContainer justifyContent="space-between">
                    <h2>{props.event.Title}</h2>
                    <div>
                        <NwDropDownMenu
                            right
                            actions={buttonactions}
                        />
                    </div>
                </FlexContainer>
            </EventTitleContainer>
        )
    } else {
        return (
            <EventTitleContainer className={isGloballyCancelled() ? 'cancel' : null}>
                <EventModelNameLineNonBooking {...props} aboveTitle />
                <h2>{props.event.Title}</h2>
            </EventTitleContainer>
        )
    }
}