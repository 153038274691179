import React from 'react';
import { Field } from 'formik';
import { Typography } from 'antd';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NWInput } from "Components/Gui/NWForm/NwFormItems";

const FilterSetAddEditDrawer = ({ isAdd, isSubmitting, values, fromTop, fromLeft, onClose, handleSubmit }) => {
    return (
        <NwDrawer
            width={640}
            placement="left"
            fromTop={fromTop}
            fromLeft={fromLeft}
            onClose={onClose}
            zIndex={5}
            title={isAdd ? 'Add New Set' : 'Edit Set'}
        >
            <Typography>List Name</Typography>
            <Field
                component={NWInput}
                value={values.Name}
                type="text"
                name="Name"
                required
            />
            <NwFormButtonsBar
                left={
                    <NwCancelButton
                        onClick={onClose}
                    />
                }
                right={
                    <NwSaveButton
                        htmlType="submit"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        label="Save"
                        loadingLabel="Saving..."
                    />
                }
            />
        </NwDrawer>
    )
};

export default FilterSetAddEditDrawer;