import React, { useContext } from "react";
import styled from 'styled-components';

import { ListContext } from "Contexts/ListContext";
import GooglePlaces from 'Components/GooglePlaces/GooglePlaces';
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';
import AddressForm from "Components/Gui/NWForm/AddressForm";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const Container = styled.div`
    margin: 20px 0;
    .ant-input-prefix {
        color: ${StyleVariables.PrimaryDarkLight};
        font-size: .8rem;
    }
`;

const initialValues = {
    Country: undefined,
    City: '',
    County: '',
    State: '',
    Zip: '',
    Street: '',
    Access: ''
};

const AddressSelector = ({ value, onChange }) => {
    const { cachedList } = useContext(ListContext);
    const countriesList = cachedList.countries.items;

    const handleSetAddress = (address) => {
        const country = countriesList.find(country => country.Code === address.CountryCode);
        onChange({
            Country: country ? country.Code : undefined,
            City: address.City ? address.City : '',
            County: address.County ? address.County : '',
            State: address.State ? address.State : '',
            Zip: address.Zip ? address.Zip : '',
            Street: address.Street ? address.Street : '',
            Access: ''
        })
    };

    return (
        <Container>
            <GooglePlaces
                clearable 
                onSetAddress={handleSetAddress}
                suffix={
                    <NwIcon icon={light('search')} />
                } />
            <AddressForm value={value || initialValues} onChange={onChange} />
        </Container>
    );
};

export default AddressSelector;
