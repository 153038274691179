import React from "react";
import styled from "styled-components";
import useCountryName from "Hooks/UseCountryName";
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReactHtmlParser from "react-html-parser";
import NwIcon from "Components/Gui/NwIcon";

const AddressLineContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.3em;

    .icon-container {
        margin: .5rem 1.5rem 0 1rem;
        font-size: 1.3rem;
    }
`

const AddressLine = ({ address, disableMap, noIcon, onClick, twoLines }) => {
    const countryName = useCountryName(address.Country);

    const ComposeAddressForMaps = () => {
        const addressString = [address.Street, address.City, address.Zip, address.State, countryName].filter(Boolean).join("+");
        return "https://www.google.com/maps/place/" + encodeURIComponent(addressString)
    }

    const showMap = event => {
        event.stopPropagation();
        window.open(ComposeAddressForMaps())
    }

    const getAddressText = () => {
        let addressText = "";
        if (address.Street) {
            addressText += address.Street + ","
        }
        if (address.Street && twoLines) {
            addressText += "<br />"
        }

        //this part can be formatted differently depending on the country
        let localLine = "";
        switch (address.Country) {
            case "US":
                if (address.City) {
                    localLine += `${address.City}, `
                }
                if (address.State) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.State}`
                    } else {
                        localLine += address.State
                    }
                }
                if (address.Zip) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.Zip}`
                    } else {
                        localLine += address.Zip
                    }
                }
                if (countryName) {
                    if (localLine.length > 0) {
                        localLine += `<br/>${countryName}`
                    } else {
                        localLine += countryName
                    }
                }
                break;
            case "IT":
                if (address.Zip) {
                    localLine += ` ${address.Zip},`
                }
                if (address.City) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.City}`
                    } else {
                        localLine += address.City
                    }
                }
                if (address.County) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.County}`
                    } else {
                        localLine += address.County
                    }
                }
                if (countryName) {
                    if (localLine.length > 0) {
                        localLine += `<br/>${countryName}`
                    } else {
                        localLine += countryName
                    }
                }
                break;
            case "UK":
                if (address.City) {
                    localLine += address.City
                }
                if (address.Zip) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.Zip}`
                    } else {
                        localLine += address.Zip
                    }
                }
                if (countryName) {
                    if (localLine.length > 0) {
                        localLine += `<br/>${countryName}`
                    } else {
                        localLine += countryName
                    }
                }
                break;
            //case "FR":
            //case "DE":
            default:
                if (address.Zip) {
                    localLine += `${address.Zip},`
                }
                if (address.City) {
                    if (localLine.length > 0) {
                        localLine += ` ${address.City}`
                    } else {
                        localLine += address.City
                    }
                }
                if (countryName) {
                    if (localLine.length > 0) {
                        localLine += `<br/>${countryName}`
                    } else {
                        localLine += countryName
                    }
                }
                break;
        }
        addressText += localLine;

        if (address.Access) {
            if (addressText.length > 0) {
                addressText += "<br />"
            }
            addressText += `Access: ${address.Access}`
        }
        return addressText;
    }


    const formattedAddressText = getAddressText()

    let stylefordiv = {}
    if (noIcon && !disableMap) {
        stylefordiv = { cursor: "pointer" }
    }

    return (
        <AddressLineContainer>
            {(formattedAddressText.length === 0)
                ?
                <div><em>no address</em></div>
                :
                <>
                    {!disableMap && !noIcon &&
                        <div className="icon-container">
                            <NwIcon
                                pointer
                                icon={duotone('map-location-dot')}
                                tooltip="Show me the location on Google Maps"
                                onClick={showMap}
                            />
                        </div>
                    }
                    <div style={stylefordiv} onClick={(noIcon && !disableMap) ? showMap : onClick}>
                        {ReactHtmlParser(formattedAddressText)}
                    </div>

                </>
            }
        </AddressLineContainer>
    );
};

export default AddressLine