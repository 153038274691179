import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from 'Components/Gui/Loading';
import { useProject } from 'Hooks/Project/UseProject';
import BookingEventComments from './BookingEventComments'

const Container = styled.div`
  position: relative;
  margin-top: 20px;
`;


const BookingEventCommentsContainer = ({ eventData, filterModel }) => {

  const { data: project } = useProject(eventData.Project.ID);

  if (!project) {
    return (
      <Container>
        <Loading textBlack />
      </Container>
    )
  }

  return (
    <Container>
      <BookingEventComments eventData={eventData} filterModel={filterModel} project={project} />
    </Container>
  );
};

// BookingEventCommentsContainer.propTypes = {
//   eventData: PropTypes.shape({
//     Project: PropTypes.shape({
//       ID: PropTypes.number.isRequired
//     }).isRequired
//   }),
//   filterModel: PropTypes.number.isRequired
// }

export default BookingEventCommentsContainer;
