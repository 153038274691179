import React from "react";
import styled from 'styled-components';

const DotWrapper = styled.span`
    box-sizing: border-box;
    margin: ${props => props.$margin ? props.$margin : 0};
    padding: 0;
    color: rgba(0, 0, 0, 0.75);
    font-size: 15px;
    font-variant: none;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'ss01', 'case' on, "kern" off;
    position: relative;
    display: inline-block;
    line-height: inherit;
    vertical-align: baseline;

    .dot-inner {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 6px;
        height: 6px;
        vertical-align: middle;
        border-radius: 50%;
        background: ${props => props.theme.SuccessColor};
    }
`

const AcknowledgmentDot = ({ ackn, mg }) => {
    if (ackn && ackn === "Checked") {
        return (
            <DotWrapper $margin={mg}>
                <span className="dot-inner"></span>
            </DotWrapper>
        )
    }
    return (null)
}

export { AcknowledgmentDot };
