import { light, solid, thin, icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import StyleVariables from "Components/Gui/StyleVariables"

export const eventsTypes = [
    {
        id: 10,
        code: "Casting",
        name: "casting",
        shortName: "CAST",
        family: "booking",
        timeType: "workDays",
        textColor: "#fff",
        baseColor: "#389e0d",  //green-7
        subTypes: []
    },
    {
        id: 20,
        code: "Job",
        name: "job",
        shortName: "JOB",
        family: "booking",
        timeType: "workDays",
        textColor: "#fff",
        secondaryTextColor: "#000",
        baseColor: "#f5222d",  //red-6
        secondaryColor: "#fadb14",  //yellow-6
        subTypes: []
    },
    {
        id: 100,
        code: "ToDo",
        name: "task",
        shortName: "TODO",
        family: "todo",
        timeType: "dateAndReminder",
        textColor: "#fff",
        secondaryTextColor: "#fff",
        baseColor: "#fa8c16",  //orange-6
        secondaryColor: StyleVariables.SuccessColor,  //gray-7
        icon: thin('circle'),
        secondaryIcon: thin('dot-circle')
    },
    {
        id: 110,
        code: "FollowUp",
        name: "follow-up",
        shortName: "FOL",
        family: "followup",
        timeType: "period",
        textColor: "#fff",
        baseColor: "#8c8c8c",  //gray-7
        icon: icon({name: "concierge-bell", family: "sharp", style: "solid"}),
        subTypes: []
    },
    {
        id: 120,
        code: "Travel",
        name: "travel",
        shortName: "TRA",
        family: "travel",
        timeType: "period",
        textColor: "#fff",
        baseColor: "#8c8c8c",  //gray-7
        icon: icon({name: "suitcase-rolling", family: "sharp", style: "solid"}),
        subTypes: []
    },
    {
        id: 130,
        code: "ExtJob",
        name: "external job",
        shortName: "EXT",
        family: "externaljob",
        timeType: "period",
        textColor: "#fff",
        secondaryTextColor: "#fff",
        baseColor: "#433a6e",  //violet for confirmations
        secondaryColor: "#796db2",  //lighter violet for options
        icon: icon({name: "location-dot", family: "sharp", style: "solid"}),
        secondaryIcon: icon({name: "location-dot", family: "sharp", style: "light"})
    },
    {
        id: 140,
        code: "Meeting",
        name: "meeting",
        shortName: "MEE",
        family: "meeting",
        timeType: "singleDay",
        textColor: "#fff",
        baseColor: "#8c8c8c",  //gray-7
        icon: icon({name: "location-dot", family: "sharp", style: "solid"})
    },
    {
        id: 150,
        code: "Notice",
        name: "notice",
        label: 'Notice',
        shortName: "NOT",
        family: "notice",
        timeType: "period",
        textColor: "#000",
        baseColor: "#fffb8f",  //gray-7
        icon: icon({name: "bullhorn", family: "sharp", style: "solid"})
    },
    {
        id: 160,
        code: "Accommodation",
        name: "accommodation",
        shortName: "ACC",
        family: "accommodation",
        timeType: "period",
        baseColor: "#f0f0f0",
        textColor: "#000",
        //secondaryTextColor: "#000",
        //secondaryColor: '#f0f0f0',
        icon: icon({name: "bed", family: "sharp", style: "solid"})
    },
    {
        id: 170,
        code: "RoomLodging",
        name: "room lodging",
        shortName: "ROO",
        family: "roomlodging",
        timeType: "period",
        baseColor: "#f0f0f0",
        textColor: "#000",
        //secondaryTextColor: "#000",
        //secondaryColor: '#f0f0f0',
        icon: icon({name: "house-user", family: "sharp", style: "solid"})
    },
    {
        id: 200,
        code: "Package",
        name: "package",
        shortName: "PACK",
        family: "package",
        timeType: null,
        textColor: "#fff",
        baseColor: "#8c8c8c",  //gray-7
        icon: icon({name: "book-arrow-right", family: "sharp", style: "light"})
    }
]