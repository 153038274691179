import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getMeeting = meetingId => (
    Axios.get(`/meetings/${meetingId}`)
);

const useMeeting = meetingId => {
    return useQuery(["meeting", meetingId], () => getMeeting(meetingId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: meetingId ? true : false
    });
};

const forceReloadMeeting = (ID) => {
    queryClient.invalidateQueries(['meeting', ID])
}

const onDeleteMeeting = ({ ID }) => (
    Axios.delete(`/meetings/${ID}`)
)

const useDeleteMeeting = () => {
    return useMutation(onDeleteMeeting, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.meetingId) {
                forceReloadMeeting(variables.meetingId);
            }
        }
    })
}

const onUpdateMeeting = ({ meetingData }) => (
    Axios.put('/meetings', meetingData)
);

const useUpdateMeeting = () => {
    return useMutation(onUpdateMeeting, {
        onSuccess: (data, variables) => {
            forceReloadMeeting(variables.meetingData.ID);
        }
    })
}

const onAddMeeting = ({ meetingData }) => (
    Axios.post('/meetings', meetingData)
);

const useAddMeeting = () => {
    return useMutation(onAddMeeting, {
        onSuccess: (data, variables) => {
            forceReloadMeeting(data.ID);
        }
    })
};

const onCancelMeeting = ({ ID }) => (
    Axios.put(`/meetings/cancellation/${ID}`)
);

const useCancelMeeting = () => {
    return useMutation(onCancelMeeting, {
        onSuccess: (data, variables) => {
            forceReloadMeeting(variables.ID);
        }
    })
};

const onSetMeetingProject = ({ meetingId, projectId }) => (
    Axios.put('/meetings/SetProject', {
        ObjectID: meetingId,
        ProjectID: projectId
    })
);

const useSetMeetingProject = () => {
    return useMutation(onSetMeetingProject, {
        onSuccess: (data, variables) => {
            forceReloadMeeting(variables.meetingId);
        }
    })
};

export {
    useMeeting,
    useDeleteMeeting,
    useUpdateMeeting,
    useAddMeeting,
    forceReloadMeeting,
    useCancelMeeting,
    useSetMeetingProject
};