import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useDeleteEventDatesModels, useDeleteEventDate } from 'Hooks/Event/UseEvent';
import NwIcon from 'Components/Gui/NwIcon';
import EventModelActions from 'Components/EventDrawer/EventDrawerUi/EventModelActions';
import OptionNumber from 'Components/EventDrawer/EventDrawerUi/OptionNumber';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwButton } from 'Components/Gui/NwButton';
import EventDateModel from 'Components/EventDrawer/BookingEvent/EventDatesAndModels/EventDateModel';
import CollapsibleModels from './CollapsibleModels';
import { groupModels, sortGroupedModelsByCurrentModel, findModelInEventData, getModelActionName } from './eventDatesAndModelsUtils';
import NwDivider from 'Components/Gui/NwDivider';
import StyleVariables from 'Components/Gui/StyleVariables';
import { BookingEventDateContainer, BookingEventDateInfo, BookingEventModelDetailsWrapper, BookingEventModelHeader, BookingEventModelInfo, BookingEventSingleModelNameContainer, BookingEventStyledCalendarEditIcon } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import VerticalDate from 'Components/Dates/VerticalDate';
import AddressLine from 'Components/Addresses/AddressLine';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';
import VerticalPeriod from 'Components/Dates/VerticalPeriod';
import HorizontalPeriod from 'Components/Dates/HorizontalPeriod';

const EventDatesAndModels = ({
    eventData,
    eventDateInfo,
    selectedModel,
    loadEventData,
    filterModel,
    afterUpdate,
    onExtendOptionNumberToAllDates,
    onOpenEditWorkingDateDrawer,
    onOpenEditWorkingDayDrawer,
}) => {
    const _groupedModels = groupModels(
        eventDateInfo.EventDatesModels,
        { 
            FromDate: eventDateInfo.FromDate, 
            ToDate: eventDateInfo.ToDate,
            StartBreak: eventDateInfo.StartBreak,
            EndBreak: eventDateInfo.EndBreak,
            Address: eventDateInfo.Address
        }
    )

    const filteredGroupedModels = sortGroupedModelsByCurrentModel(_groupedModels, selectedModel);
    const [groupedModels, setGroupedModels] = useState(filteredGroupedModels);
    const { mutateAsync: onDeleteEventDatesModels } = useDeleteEventDatesModels();
    const { mutateAsync: onDeleteEventDate } = useDeleteEventDate();
    const [viewDateModels, setViewDateModels] = useState(false);

    useEffect(() => {
        const _groupedModels = groupModels(
            eventDateInfo.EventDatesModels,
            { 
                FromDate: eventDateInfo.FromDate, 
                ToDate: eventDateInfo.ToDate,
                StartBreak: eventDateInfo.StartBreak,
                EndBreak: eventDateInfo.EndBreak,
                Address: eventDateInfo.Address
            }
        )
        const filteredGroupedModels = sortGroupedModelsByCurrentModel(_groupedModels, selectedModel);
        setGroupedModels(filteredGroupedModels);
    }, [eventDateInfo]);


    const reloadGroupedModels = (newModel) => {
        if (newModel) {
            const newGroupedModels = groupedModels.map(groupedModel => {
                return {
                    ...groupedModel,
                    models: groupedModel.models.map(model => model.ID === newModel.ID ? newModel : model)
                }
            })
            setGroupedModels(newGroupedModels);
        }
        afterUpdate();
    }

    const removeModelFromList = (modelToRemove) => {
        const newGroupedModels = groupedModels.map(groupedModel => {
            return {
                ...groupedModel,
                models: groupedModel.models.filter(model => model.ID !== modelToRemove.ID)
            }
        })
        setGroupedModels(newGroupedModels);
    }

    const handleRemoveEventDatesModel = async (model) => {
        try {
            await onDeleteEventDatesModels({ ID: eventData.ID, eventDateModelId: model.ID });
            await removeModelFromList(model);
            loadEventData();
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    }

    const handleDeleteEventDatesModels = async (models, deleteWholeDay = false) => {
        if (deleteWholeDay) {
            await onDeleteEventDate({ ID: eventData.ID, eventDateId: eventDateInfo.ID });
            loadEventData();
            return;
        }
        models.forEach(model => {
            handleRemoveEventDatesModel(model);
        })
    }

    const onReloadEventStatus = async (model) => {
        const event = await Axios.get(`/events/${eventData.ID}`);
        const newModel = findModelInEventData(event, model.ID);
        reloadGroupedModels(newModel);
    }

    const handleViewDateModels = () => {
        setViewDateModels(true);
    };

    const handleCloseDateModels = () => {
        setViewDateModels(false);
    };

    const onEditTimeAndAddress = () => {
        onOpenEditWorkingDateDrawer({
            FromDate: eventDateInfo.FromDate,
            ToDate: eventDateInfo.ToDate,
            Address: eventDateInfo.Address,
            Notes: eventDateInfo.Notes,
            models: [],
            eventDateId: eventDateInfo.ID
        })
    };

    if (eventDateInfo.EventDatesModels.length === 0) {
        return (
            <BookingEventModelDetailsWrapper className={eventDateInfo.Cancel ? "is-cancelled" : null}>
                <BookingEventDateContainer>
                    <VerticalDate date={eventDateInfo.FromDate} showDay />
                    <div className="calendar-button">
                        <BookingEventStyledCalendarEditIcon icon={light('calendar-edit')} size="2x" onClick={() => onOpenEditWorkingDayDrawer(eventDateInfo)} />
                    </div>
                </BookingEventDateContainer>
                <BookingEventModelInfo>
                    <div>No models</div>
                    <BookingEventDateInfo>
                        <ResponsiveFlexContainer
                            leftMinWidth={120}
                            columns={[
                                {
                                    // content: (
                                    //     <div className='is-label'>
                                    //         <VerticalPeriod noPadding startDate={eventDateInfo.FromDate} endDate={eventDateInfo.ToDate} time hideDate />
                                    //     </div>
                                    // ),
                                    content: (
                                        (eventDateInfo.StartBreak)
                                            ?
                                            <div className='is-label'>
                                                <HorizontalPeriod noPadding startDate={eventDateInfo.FromDate} endDate={eventDateInfo.StartBreak} time hideDate />
                                                <HorizontalPeriod noPadding startDate={eventDateInfo.EndBreak} endDate={eventDateInfo.ToDate} time hideDate />
                                            </div>
                                            :
                                            <div className='is-label'>
                                                <VerticalPeriod noPadding startDate={eventDateInfo.FromDate} endDate={eventDateInfo.ToDate} time hideDate />
                                            </div>
                                    ),
                                    isBorder: true
                                },
                                {
                                    content: (
                                        <div className='is-content'>
                                            <AddressLine address={eventDateInfo.Address} twoLines />
                                        </div>
                                    ),
                                    width: "full",
                                    isBorder: false
                                },
                                {
                                    content: (<NwButton icon={light('pen')} onClick={onEditTimeAndAddress} />),
                                    isBorder: false
                                }
                            ]}
                        />
                        {eventDateInfo.Notes && (
                            <ResponsiveFlexContainer
                                leftMinWidth={120}
                                columns={[
                                    {
                                        content: (
                                            <div className='is-label is-center'>NOTES</div>
                                        ),
                                        isBorder: true
                                    },
                                    {
                                        content: (
                                            <div className='is-content'>
                                                {eventDateInfo.Notes}
                                            </div>
                                        ),
                                        width: "full",
                                        isBorder: false
                                    }
                                ]}
                            />
                        )
                        }
                    </BookingEventDateInfo>
                </BookingEventModelInfo>
            </BookingEventModelDetailsWrapper>
        )
    }

    return (
        <BookingEventModelDetailsWrapper className={eventDateInfo.Cancel ? "is-cancelled" : null}>
            <BookingEventDateContainer>
                <VerticalDate date={eventDateInfo.FromDate} size="big" showDay />
                <div>
                    <NwIcon icon={light('stream')} pointer onClick={handleViewDateModels} />
                </div>
                <div className="calendar-button">
                    <BookingEventStyledCalendarEditIcon icon={light('calendar-edit')} onClick={() => onOpenEditWorkingDayDrawer(eventDateInfo)} />
                </div>
            </BookingEventDateContainer>
            <BookingEventModelInfo>
                {groupedModels.map((modelsGroup, index) => {
                    const currentModel = modelsGroup.models.find(m => m.ModelID === selectedModel);
                    const otherModels = modelsGroup.models.filter(m => m.ModelID !== selectedModel);
                    if (!currentModel && otherModels.length === 0) {
                        return null;
                    }

                    const onEditTimeAndAddress = (groupedmodels) => {
                        if (groupedmodels) {
                            onOpenEditWorkingDateDrawer({
                                FromDate: modelsGroup.groupingData.FromDate,
                                ToDate: modelsGroup.groupingData.ToDate,
                                StartBreak: modelsGroup.groupingData.StartBreak,
                                EndBreak: modelsGroup.groupingData.EndBreak,
                                Address: modelsGroup.groupingData.Address,
                                Notes: modelsGroup.groupingData.Notes,
                                models: modelsGroup.models
                            })
                        } else {
                            onOpenEditWorkingDateDrawer({
                                FromDate: eventDateInfo.FromDate,
                                ToDate: eventDateInfo.ToDate,
                                StartBreak: eventDateInfo.StartBreak,
                                EndBreak: eventDateInfo.EndBreak,
                                Address: eventDateInfo.Address,
                                Notes: eventDateInfo.Notes,
                                models: modelsGroup.models
                            })
                        }
                    }

                    const actions = [
                        { name: "", key: "edit", tooltip: "Edit time and address", pinned: true, icon: light("pen"), action: () => onEditTimeAndAddress() },
                    ]

                    if (currentModel) {
                        actions.push({
                            name: getModelActionName(currentModel, eventDateInfo),
                            key: currentModel.ID.toString(),
                            type: "delete",
                            tooltip: "Remove model",
                            icon: light("trash-alt"),
                            confirmationPlacement: "left",
                            action: () => handleRemoveEventDatesModel(currentModel)
                        })
                    }

                    otherModels.map(model => {
                        actions.push({
                            name: getModelActionName(model, eventDateInfo),
                            key: model.ID.toString(),
                            type: "delete",
                            tooltip: "Remove model",
                            icon: light("trash-alt"),
                            confirmationPlacement: "left",
                            action: () => handleRemoveEventDatesModel(model)
                        })
                        return null;
                    })

                    actions.push({
                        name: "Remove date for all models",
                        key: 'remove for all',
                        type: "delete",
                        tooltip: "Remove models",
                        icon: light("trash-alt"),
                        confirmationPlacement: "left",
                        action: () => handleDeleteEventDatesModels(modelsGroup.models, groupedModels.length === 1)
                    })

                    const renderPopOver = (eventDateInfo, eventData, eventDateModelID) => {
                        let eventDateModel;
                        if (eventDateInfo) {
                            eventDateModel = eventDateInfo.EventDatesModels.find(eventDateModel => eventDateModel.ID === eventDateModelID);
                        }
                        return (
                            <Popover
                                overlayClassName="nw-popover"
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                placement="left"
                                title={`Cancel by ${eventDateModel ? eventDateModel.CancelledByName : eventData.CancelledByName}`}
                                content={() => {
                                    if (eventDateInfo) {
                                        return (
                                            <>
                                                {eventDateModel.CancellationNote &&
                                                    <div>{eventDateModel.CancellationNote}</div>
                                                }
                                            </>
                                        )
                                    } else if (eventData) {
                                        return (
                                            <>
                                                {eventData.CancellationNote &&
                                                    <div>{eventData.CancellationNote}</div>
                                                }
                                            </>
                                        )
                                    }
                                }}>
                                <NwIcon icon={solid('message-xmark')} color={StyleVariables.DangerColor} />
                            </Popover>
                        )
                    }

                    return (
                        <div key={modelsGroup.models[0] ? modelsGroup.models[0].ID : index}>
                            {
                                currentModel && (
                                    <BookingEventModelHeader isCanceled={currentModel.Cancel}>
                                        <div className="selected-model-name">
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <span className="model-name">{getContactName(currentModel.ModelID).toLowerCase()}</span>
                                                <div style={{ margin: "auto 0.5rem" }}>
                                                    {currentModel.Cancel &&
                                                        renderPopOver(eventDateInfo, eventData, currentModel.ID)
                                                    }
                                                </div>
                                            </div>
                                            <OptionNumber
                                                key={currentModel.OptionNumber}
                                                number={currentModel.OptionNumber}
                                                event={eventData}
                                                eventDateInfo={eventDateInfo}
                                                selectedModel={currentModel}
                                                eventDatesModel={currentModel}
                                                reloadGroupedModels={reloadGroupedModels}
                                                onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
                                            />
                                        </div>
                                        <EventModelActions
                                            model={currentModel}
                                            eventDateInfo={eventDateInfo}
                                            eventData={eventData}
                                            reloadGroupedModels={reloadGroupedModels}
                                            afterUpdate={afterUpdate}
                                            onReloadEventStatus={onReloadEventStatus}
                                        />
                                    </BookingEventModelHeader>
                                )
                            }
                            {/* {currentModel && otherModels.length > 0 && 
                    <NwDivider>Other Models</NwDivider>
                } */}
                            {
                                otherModels.length > 1 && (
                                    <CollapsibleModels
                                        collapsed={!filterModel && otherModels.length < 10}
                                        models={otherModels}
                                        eventData={eventData}
                                        eventDateInfo={eventDateInfo}
                                        currentModel={currentModel}
                                        reloadGroupedModels={reloadGroupedModels}
                                        onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
                                        afterUpdate={afterUpdate}
                                    />
                                )
                            }
                            {
                                otherModels.length === 1 && (
                                    <BookingEventSingleModelNameContainer withPadding>
                                        <div className="model-name-container">
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <span className={`model-name${otherModels[0].Cancel ? ' is-cancelled' : ''}`}>
                                                    {getContactName(otherModels[0].ModelID).toLowerCase()}
                                                </span>
                                                <div style={{ margin: "auto 0.5rem" }}>
                                                    {otherModels[0].Cancel &&
                                                        renderPopOver(eventDateInfo, eventData, otherModels[0].ID)
                                                    }
                                                </div>
                                            </div>
                                            <OptionNumber
                                                key={otherModels[0].OptionNumber}
                                                number={otherModels[0].OptionNumber}
                                                event={eventData}
                                                eventDateInfo={eventDateInfo}
                                                selectedModel={currentModel}
                                                eventDatesModel={otherModels[0]}
                                                reloadGroupedModels={reloadGroupedModels}
                                                onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
                                            />
                                        </div>
                                        <EventModelActions
                                            model={otherModels[0]}
                                            eventData={eventData}
                                            eventDateInfo={eventDateInfo}
                                            reloadGroupedModels={reloadGroupedModels}
                                            afterUpdate={afterUpdate}
                                            onReloadEventStatus={onReloadEventStatus}
                                        />
                                    </BookingEventSingleModelNameContainer>
                                )
                            }
                            <BookingEventDateInfo>
                                <ResponsiveFlexContainer
                                    leftMinWidth={130}
                                    columns={[
                                        {
                                            content: (
                                                (modelsGroup.groupingData.StartBreak)
                                                    ?
                                                    <div className='is-label'>
                                                        <HorizontalPeriod noPadding startDate={modelsGroup.groupingData.FromDate} endDate={modelsGroup.groupingData.StartBreak} time hideDate />
                                                        <HorizontalPeriod noPadding startDate={modelsGroup.groupingData.EndBreak} endDate={modelsGroup.groupingData.ToDate} time hideDate />
                                                    </div>
                                                    :
                                                    <div className='is-label'>
                                                        <VerticalPeriod noPadding startDate={modelsGroup.groupingData.FromDate} endDate={modelsGroup.groupingData.ToDate} time hideDate />
                                                    </div>
                                                ),
                                                // (modelsGroup.groupingData.StartBreak || eventDateInfo.StartBreak)
                                                //     ?
                                                //     <div className='is-label'>
                                                //         <HorizontalPeriod noPadding startDate={modelsGroup.groupingData.FromDate || eventDateInfo.FromDate} endDate={modelsGroup.groupingData.StartBreak || eventDateInfo.StartBreak} time hideDate />
                                                //         <HorizontalPeriod noPadding startDate={modelsGroup.groupingData.EndBreak || eventDateInfo.EndBreak} endDate={modelsGroup.groupingData.ToDate || eventDateInfo.ToDate} time hideDate />
                                                //     </div>
                                                //     :
                                                //     <div className='is-label'>
                                                //         <VerticalPeriod noPadding startDate={modelsGroup.groupingData.FromDate || eventDateInfo.FromDate} endDate={modelsGroup.groupingData.ToDate || eventDateInfo.ToDate} time hideDate />
                                                //     </div>
                                                // ),
                                            isBorder: true
                                        },
                                        {
                                            content: (
                                                <div className='is-content'>
                                                    <AddressLine address={modelsGroup.groupingData.Address} twoLines />
                                                </div>
                                            ),
                                            width: "full",
                                            isBorder: false
                                        },
                                        {
                                            content: (<NwButton icon={light('pen')} onClick={() => onEditTimeAndAddress(true)} />),
                                            isBorder: false
                                        }
                                    ]}
                                />
                                {modelsGroup.groupingData.Notes && (
                                    <ResponsiveFlexContainer
                                        leftMinWidth={130}
                                        columns={[
                                            {
                                                content: (
                                                    <div className='is-label is-center'>NOTES</div>
                                                ),
                                                isBorder: true
                                            },
                                            {
                                                content: (
                                                    <div className='is-content'>
                                                        {modelsGroup.groupingData.Notes}
                                                    </div>
                                                ),
                                                width: "full",
                                                isBorder: false
                                            }
                                        ]}
                                    />
                                )
                                }
                                {currentModel && groupedModels.find((modelGroup, groupIndex) => groupIndex !== index && modelGroup.models.find(model => model.ModelID !== currentModel.ModelID)) &&
                                    <NwDivider>Other Models</NwDivider>
                                }
                            </BookingEventDateInfo>
                        </div>
                    )
                })
                }
            </BookingEventModelInfo>
            {viewDateModels &&
                <NwDrawer
                    title={`${getMoment(eventDateInfo.FromDate).format("ddd Do MMM YYYY")} - ${eventData.Name}`}
                    onClose={handleCloseDateModels}>
                    <EventDateModel afterUpdate={afterUpdate} eventDateInfo={eventDateInfo} eventData={eventData} />
                </NwDrawer>
            }
        </BookingEventModelDetailsWrapper>
    )
}

export default EventDatesAndModels;