import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';
import Axios from 'axios';

const forceReloadMailingLists = () => queryClient.invalidateQueries(["mailinglists"]);

const getMailingLists = () => {
    return Axios.get(`/MailingLists`);
}

const useMailingLists= () => {
    return (
        useQuery(["mailinglists"],
            getMailingLists,
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}


export {
    useMailingLists,
    forceReloadMailingLists
};