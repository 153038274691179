import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { NwAddButton } from "Components/Gui/NwButton";
import { Loading } from 'Components/Gui/Loading';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import TaskListItemContact from 'Components/TaskList/TaskListItemContact';
import Axios from 'axios';
import NwDrawer from 'Components/Gui/NwDrawer';

const Wrapper = styled.div`
    .drawer-toolbar-container {
        padding-right: 1rem;
    }
`;

const ContactTasksDrawer = ({ contactID, onClose }) => {

    const [addDrawer, setAddDrawer] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [fetchingTodos, setFetchingTodos] = useState(true);
    const [toDos, setToDos] = useState([]);
    
    const handleReloadTodos = () => {
        fetchTodos()
        forceReloadContact(contactID)
    };

    const handleSelectTask = (id) => {
        setSelectedId(id)
    }

    const fetchTodos = async () => {
        const todolist = await Axios.post('/todos/search', { Status: null, contactID: contactID })
        setToDos(todolist)
        setFetchingTodos(false)
    }

    useEffect(() => {
        fetchTodos()
    }, []);

    if (fetchingTodos) return (<Loading />)

    return (
        <NwDrawer
            title="Tasks"
            padding="0px"
            //width={740} 
            onClose={onClose}
        >
            <Wrapper>
                <div className='drawer-toolbar-container'>
                    <NwAddButton
                        primary
                        onClick={() => setAddDrawer(true)}
                        label="Add a new task"
                    />
                </div>
                {toDos && toDos.length > 0 &&
                    <List
                        itemLayout="horizontal"
                        dataSource={toDos}
                        renderItem={item => (
                            <TaskListItemContact
                                key={item.ID}
                                contactID={contactID}
                                onSelect={handleSelectTask}
                                todoItem={item}
                                extended
                                onUpdate={handleReloadTodos}
                            />
                        )}
                    />
                }
                {addDrawer &&
                    <EventDrawer
                        viewMode='create'
                        onClose={() => setAddDrawer(false)}
                        afterUpdate={handleReloadTodos}
                        eventType="todo"
                        modelId={contactID}
                    />
                }
                {selectedId &&
                    <EventDrawer
                        eventType="ToDo"
                        eventID={selectedId}
                        onClose={() => setSelectedId(null)}
                        //projectId={projectId}
                        afterUpdate={handleReloadTodos}
                        afterRemove={handleReloadTodos}
                    />
                }
            </Wrapper>
        </NwDrawer>
    );
};

export default ContactTasksDrawer;