import React from 'react'
import styled from 'styled-components';
import { Divider } from 'antd';
import { NwButton } from 'Components/Gui/NwButton';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import NwIcon from './NwIcon';

const StyledDivider = styled(Divider)`
    margin: ${props => props.$lessMargin ? ".5rem 0 .25rem 0" : "1rem 0 .5rem 0"} !important;
    position: relative;

    &::before, &::after {
        border-color: ${props => props.$dark ? props.theme.DarkerBorderColor : props.theme.BorderColor} !important;
    }

    .ant-divider-inner-text {
        font-size: ${props => props.$textSize};
        font-weight: ${props => props.$dark ? "500" : "normal"};
        padding: 0 0.5em !important;
        text-transform: uppercase;
        opacity: ${props => props.$dark ? ".75" : ".5"};
    }

    .icon-on-divider {
        margin-right: .5em;
    }

    .button-on-divider {
        margin-left: 1rem;
        border: none;
        box-shadow: none;
        background: transparent;
    }
`

const NwDivider = ({ orientation = "left", children, action, dark, textSize = "small", lessMargin, bold, icon, ...other }) => {
    const fontSizes = {
        "big": "1rem",
        "medium": ".8rem",
        "small": ".6rem"
    }

    return (
        <StyledDivider orientation={orientation} $dark={dark} $textSize={fontSizes[textSize]} $lessMargin={lessMargin} $bold={bold} {...other}>
            {icon && <span className="icon-on-divider"><NwIcon icon={icon} /></span>}
            {children}
            {action && <NwButton icon={light("pen")} className="button-on-divider" iconOnly onClick={action} />}
        </StyledDivider>
    )
}

export default NwDivider