import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const onContactCommentsUpdate = async (ID, callback) => {
    Axios.get(`/contacts/comments/${ID}`).then(comments => {
        queryClient.setQueryData(['contact', ID], (old) => ({
            ...old,
            Comments: comments,
        }))
        callback && callback()
    });
};

export {
    onContactCommentsUpdate
}