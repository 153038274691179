import React, { useEffect, useState } from "react";
import Select from 'Components/Gui/NWForm/CustomSelect';
import { getContactBillingInfo } from "Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils";
import NwDrawer from "../NwDrawer";
import BillingInfo from "Components/BillingInfo/BillingInfo";
import { Alert } from "antd";
import ContactSelector from "Components/ContactSelector/ContactSelector";




const ContactWithBillingInfo = ({ value, onChange, contacts, billingInfos, onBlur, fullSelector }) => {

    const [contactBillingInfos, setContactBillingInfos] = useState(billingInfos)
    const [selectedContact, setSelectedContact] = useState(value ? value.ContactID : null)
    const [billingInfoDrawer, setBillingInfoDrawer] = useState(false)
    const [loadingBillingInfo, setLoadingBillingInfo] = useState(false)

    const getSelectedBillingInfo = () => {
        return contactBillingInfos.find(item => item.ID === value.ContactBillingInfoID)
    }

    const getDefaultBillingInfo = () => {
        return contactBillingInfos.find(item => item.isDefault)
    }

    const [selectedBillingInfo, setSelectedBillingInfo] = useState(getSelectedBillingInfo());

    useEffect(() => {
        const fetchBillingInfo = async (contactID) => {
            setLoadingBillingInfo(true)
            const fetchedBillingInfos = await getContactBillingInfo(contactID)
            setContactBillingInfos(fetchedBillingInfos)
            setLoadingBillingInfo(false)
        }
        if (selectedContact) {
            onChange(
                {
                    ...value,
                    ContactID: selectedContact,
                }
            )
            fetchBillingInfo(selectedContact)
        }
    }, [selectedContact])

    useEffect(() => {
        if (contactBillingInfos) {
            setSelectedBillingInfo(getDefaultBillingInfo())
        }
    }, [contactBillingInfos])

    useEffect(() => {
        if (selectedBillingInfo) {
            onChange(
                {
                    ...value,
                    ContactBillingInfoID: selectedBillingInfo.ID
                }
            )
        }
    }, [selectedBillingInfo])

    const onChangeContact = (event) => {
        //LOAD BILLING INFO FOR SELECTED CLIENT AND PUT IN STATE
        setSelectedContact(event)
    }

    const handleSelectBillingInfo = (billingInfo) => {
        setSelectedBillingInfo(billingInfo)
        setBillingInfoDrawer(false)
        onBlur()
    }


    return (
        <>
            <div>
                {fullSelector
                    ?
                    <ContactSelector
                        value={value.ContactID}
                        activeTypes={['cli', 'age', 'srv']}
                        onChange={onChangeContact}
                        onBlur={onBlur}
                        topListContacts={contacts}
                    />
                    :
                    ((contacts.length > 1)
                    ?
                        <Select
                            value={value.ContactID}
                            disabled={contacts.length === 1}
                            options={contacts.map(el => {
                                return { key: el.ID, value: el.ID, label: el.Name };
                            })}
                            onBlur={onBlur}
                            onChange={onChangeContact}
                        />
                    :
                        <h3>{contacts[0].Name}</h3>
                    )
                }
                {selectedBillingInfo ?
                    <BillingInfo data={selectedBillingInfo} editable={contactBillingInfos.length > 1} buttonLabel="change billing profile" onClick={() => setBillingInfoDrawer(true)} />
                    :
                    loadingBillingInfo
                        ?
                        <small>loading billing info</small>
                        :
                        <Alert message="no billing info" type="error" />
                }
            </div>
            {billingInfoDrawer &&
                <NwDrawer
                    title="Select billing info"
                    onClose={() => setBillingInfoDrawer(false)}
                    width='40rem'
                >
                    {contactBillingInfos.map(billingInfo => (
                        <BillingInfo 
                            key={billingInfo.ID} 
                            data={billingInfo} 
                            editable={true} 
                            buttonLabel="select" 
                            onClick={() => handleSelectBillingInfo(billingInfo)} 
                            extended
                        />
                    ))}
                </NwDrawer>
            }
        </>
    )
};

export default ContactWithBillingInfo;
