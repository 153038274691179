import React, { useState } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { NwAddButton } from "Components/Gui/NwButton";
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import TaskListItemContact from 'Components/TaskList/TaskListItemContact';
import ContactTasksDrawer from 'Components/TaskList/ContactTasksDrawer';

const Wrapper = styled.div`
    height: 100%;
    .ant-list-item {
        align-items: flex-start;
    }
    .ant-card-body-content {
        padding: 0 !important;
    }
    .ant-card {
        height: 100%;
    }
    .content {
        padding: 1rem 2rem;
    }
`;

const ContactDetailsTasks = ({ currentContact }) => {
    
    const [addDrawer, setAddDrawer] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [showAllTasks, setShowAllTasks] = useState(false);

    const handleReloadTodos = () => {
        forceReloadContact(currentContact.ID);
    };

    const handleSelectTask = (id) => {
        setSelectedId(id)
    }

    const handleShowAllTasks = () => {
        setShowAllTasks(true);
    }

    return (
        <Wrapper>
            <NwCard
                title="Tasks"
                icon={light("tasks")}
                padding={'no-padding'}
                extra={
                    <div>
                        <NwAddButton
                            ghost
                            size='small'
                            label=''
                            icon={light('folder-open')}
                            onClick={handleShowAllTasks} />
                        &nbsp;
                        <NwAddButton ghost size="small" label='add' onClick={() => setAddDrawer(true)} />
                    </div>
                }
            >
                {currentContact && currentContact.ToDos.length > 0 &&  
                <List
                    itemLayout="horizontal"
                    dataSource={currentContact.ToDos}
                    renderItem={item => (
                        <TaskListItemContact
                            key={item.ID}
                            contactID={currentContact.ID}
                            onSelect={handleSelectTask}
                            todoItem={item}
                            onUpdate={handleReloadTodos}
                        />
                    )}
                />
            }
            </NwCard>
            {addDrawer &&
                <EventDrawer
                    viewMode='create'
                    onClose={() => setAddDrawer(false)}
                    afterUpdate={handleReloadTodos}
                    eventType="todo"
                    modelId={currentContact.ID}
                />
            }
            {selectedId &&
                <EventDrawer
                    eventType="ToDo"
                    eventID={selectedId}
                    onClose={() => setSelectedId(null)}
                    //projectId={projectId}
                    afterUpdate={handleReloadTodos}
                    afterRemove={handleReloadTodos}
                />
            }
            {showAllTasks &&
                <ContactTasksDrawer
                    contactID={currentContact.ID}
                    onClose={() => setShowAllTasks(false)}
                />
            }
        </Wrapper>
    );
};

export default ContactDetailsTasks;