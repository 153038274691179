import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { useUserPermission } from 'Contexts/UserContext';
import { HeaderContext } from '../Header/NwHeader';
import { StyledAreaSelect } from '../Header/NwHeaderUI';

const { Option } = Select;

const AreaSelect = () => {

    const headerContextValues = useContext(HeaderContext);
    const { area, onChangeArea } = headerContextValues;

    const [currentArea, setCurrentArea] = useState(area);

    useEffect(() => {
        setCurrentArea(area);
    }, [area])

    const accessAccounting = useUserPermission('AccessAccounting');
    const accessBooking = useUserPermission('AccessBooking');
    const accessManagement = useUserPermission('AccessManagement');
    const accessScouting = useUserPermission('AccessScouting');
    
    const onChangeCurrentArea = (value) => {
        setCurrentArea(value);
        onChangeArea(value);
    }

    return (
        <StyledAreaSelect
            autoComplete='off'
            popupClassName="ant-select-dropdown-areaselect"
            value={currentArea}
            onChange={onChangeCurrentArea}
        >
            <Option value="booking" disabled={!accessBooking}>
                <NwIcon primary icon={accessBooking ? light("heart-rate") : light('ban')} style={{ marginRight: ".6em" }} />BOOKING
            </Option>
            <Option value="scouting" disabled={!accessScouting}>
                <NwIcon primary icon={accessScouting ? light("globe") : light('ban')} style={{ marginRight: ".6em" }} />SCOUTING
            </Option>
            <Option value="accounting" disabled={!accessAccounting}>
                <NwIcon primary icon={accessAccounting ? light("money-bill-trend-up") : light('ban')} style={{ marginRight: ".6em" }} />ACCOUNTING
            </Option>
            <Option value="management" disabled={!accessManagement}>
                <NwIcon primary icon={accessManagement ? light("chart-pie") : light('ban')} style={{ marginRight: ".6em" }} />MANAGEMENT
            </Option>
        </StyledAreaSelect>
    );
};

export default AreaSelect;

