import React, { useState, useMemo } from 'react';
import { Layout } from 'antd';

import NwDrawer from 'Components/Gui/NwDrawer';
import AddMediaHeader from './AddMediaHeader';
import { useContactPicturesAndVideos } from "Hooks/Contact/UseContactMedia";
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import AddMediaList from './AddMediaList';

const AddMediaDrawer = ({ onClose, modelId, packageId, packageGroup }) => {
    const replacePlaceHolder = useDataPlaceholder();
    const { data: documents, isFetching } = useContactPicturesAndVideos(modelId);
    const [size, setSize] = useState('medium');
    const [sort, setSort] = useState('time');
    const [showMedia, setShowMedia] = useState({
        isShowPictures: true,
        isShowVideos: true,
    });
    const [tags, setTags] = useState([]);
    const [uploader, setUploader] = useState();

    const [uploadedSince, setUploadedSince] = useState();
    const [uploadedUntil, setUploadedUntil] = useState();

    const handleUpdateSort = value => {
        setSort(value);
    };

    const handleUpdateSize = value => () => {
        setSize(value);
    };

    const handleUpdateShowMedia = updatedShowMedia => {
        setShowMedia(updatedShowMedia);
    };

    const handleUpdateTags = updatedTags => {
        setTags(updatedTags);
    };

    const handleChangeUploader = value => {
        setUploader(value);
    };

    const handleUploadedSinceChange = value => {
        setUploadedSince(value);
    };

    const handleUploadedUntilChange = value => {
        setUploadedUntil(value);
    };

    const displayedDocuments = useMemo(() => {
        let documentList = [];
        if (documents && documents.length) {
            documentList = documents.filter(document => {
                if ((document.Type==='Photo' && showMedia.isShowPictures)
                    || (document.Type==='Video' && showMedia.isShowVideos)) {
                    return true;
                };
                return false;
            });
    
            if (uploader) {
                documentList = documentList.filter(document => document.UserCreated === uploader);
            }
    
            documentList = documentList.filter(document => {
                for(const tag of tags) {
                    if(!document.Tags.find(item => item.ID === tag)) {
                        return false;
                    }
                }
                return true;
            });
    
            const uploadedSinceDate = replacePlaceHolder(uploadedSince);
            const uploadedUntilDate = replacePlaceHolder(uploadedUntil);
            documentList = documentList.filter(document => {
                if (uploadedSince) {
                    if (new Date(uploadedSinceDate) > new Date(document.DateCreated))
                        return false;
                }
                if (uploadedUntil) {
                    if (new Date(uploadedUntilDate) < new Date(document.DateCreated))
                        return false;
                }
                return true;
            }) 
        }
        return documentList;
    }, [uploader, 
        uploadedSince, 
        uploadedUntil, 
        showMedia, 
        documents, 
        tags,
        replacePlaceHolder]);

    return (
        <NwDrawer
            width="90vw"
            title='Add Media'
            onClose={onClose}
            padding={0}
        >
            <Layout style={{minHeight: "100vh"}}>
                <AddMediaHeader
                    modelId={modelId}
                    documents={documents?documents:[]}
                    loading={isFetching}
                    size={size}
                    sort={sort} 
                    onChangeSort={handleUpdateSort}
                    onChangeSize={handleUpdateSize}
                    showMedia={showMedia}
                    onUpdateShowMedia={handleUpdateShowMedia}
                    tags={tags}
                    onUpdateTags={handleUpdateTags}
                    uploader={uploader}
                    onChangeUploader={handleChangeUploader}
                    uploadedSince={uploadedSince}
                    onChangeUploadedSince={handleUploadedSinceChange}
                    uploadedUntil={uploadedUntil}
                    onChangeUploadedUntil={handleUploadedUntilChange}
                />
                <AddMediaList
                    documents={displayedDocuments}
                    modelId={modelId}
                    onClose={onClose}
                    packageGroup={packageGroup}
                    packageId={packageId}
                    size={size}
                    sort={sort}
                />
            </Layout>
        </NwDrawer>
    )
}

export default AddMediaDrawer;
