import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dinero from "dinero.js";

import NwDrawer from "Components/Gui/NwDrawer";
import { Loading } from 'Components/Gui/Loading';
import StyleVariables from "Components/Gui/StyleVariables";
import { getContactName } from "Hooks/Contact/UseContactsList";
import { useUnGroupRows, useRemoveFromGroup } from "Hooks/Invoices/UseInvoices";

const GroupEditContent = styled.div`
    .g-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .g-title {
            font-weight: bold;
            font-size: 16px;
        }
    }
    .row-block {
        border-top: ${StyleVariables.DarkerBorder};
        margin-top: 2rem;
        .row {
            display: flex;
            flex-direction: row;
            padding: 1rem 0 2rem 0;
            .description {
                margin-right: auto;
                font-size: 14px;
            }
            .currency {
                padding: 0 1rem;
            }
            border-bottom: ${StyleVariables.DarkerBorder};
        }
    }
`;

const CustomButton = styled.div`
    color: ${StyleVariables.PrimaryColor};
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
`;

const GroupEditDrawer = (props) => {
    const { onClose, invoiceData, record, isLoading } = props;
    const [touchForm, setTouchForm] = useState(false);
    const [rows, setRows] = useState([]);
    const statementFormat = `$0,0.00`

    const { mutateAsync: onUnGroupRows } = useUnGroupRows();
    const { mutateAsync: onRemoveFromGroup } = useRemoveFromGroup();

    useEffect(() => {
        setRows(invoiceData.Rows.filter(row => row.GroupGuid === record.Guid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceData]);

    const onUnGroup = async () => {
        try {
            await onUnGroupRows({
                ID: invoiceData.ID,
                data: {
                    GroupGuid: record.Guid
                }
            })
            onClose();
        } catch (e) {
        } finally {
        }
    }

    const onRemove = async (row) => {
        try {
            await onRemoveFromGroup({
                data: {
                    InvoiceID: invoiceData.ID,
                    GroupGuid: record.Guid,
                    RowID: row.ID
                }
            })
        } catch (e) {
        } finally {
        }
    }

    console.log("rows", rows);

    const renderRows = () => {
        if (isLoading) return <Loading textBlack/>
        return (
            <div className="row-block">
                {rows.map(row => {
                    const { Statement } = row;
                    const text = Statement.Description;
                    const amount = Math.abs(Statement.AmountDef?Statement.AmountDef:Statement.Amount) * 100;
                    const ModelID = Statement.Transaction ? Statement.Transaction.ModelID : null
                    return (
                        <div className="row" key={row.ID}>
                            <div className="description">
                                <div style={{fontWeight: "bold"}}>
                                    {ModelID !== null ? getContactName(ModelID).toUpperCase():""}
                                </div>
                                {text?<div>{text}</div>:
                                    <div style={{fontStyle: "italic"}}>No text</div>
                                }
                            </div>
                            <div className="currency">{Dinero({amount: Math.round(amount), currency: invoiceData.Currency}).toFormat(statementFormat)}</div>
                            {rows.length > 2 &&
                                <CustomButton onClick={() => onRemove(row)}>remove from group</CustomButton>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Grouped lines"
        >
            <GroupEditContent>
                <div className="g-header">
                    <div className="g-title">Grouped Lines</div>
                    <CustomButton onClick={() => onUnGroup()}>Ungroup</CustomButton>
                </div>
                {renderRows()}
            </GroupEditContent>
        </NwDrawer>
    );
}

export default GroupEditDrawer;