import React, { useState } from 'react'
import { Popover, Segmented } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomSwitch from 'Components/Gui/CustomSwitch';

import styled from 'styled-components';
import { useAgendaContext } from './AgendaContext';
import { set } from 'lodash';



const AgendaFooterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

    .agenda-footer-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .agenda-footer-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`


const AgendaFooter = ({
    onOpenSendAgendaDrawer,
}) => {

    const {
        //availableEventsPopoverVisible,
        bookingSection,
        //closeAvailableEventsPopover,
        hideAvailableEvents,
        isLoadingAvailableEvents,
        isShowingAvailableEvents,
        loadAvailableEvents,
        previewMode,
        type,
    } = useAgendaContext();

    const getAgendaFooterContent = () => {
        return (
            <AgendaFooterContainer>
                {(previewMode || (type && type.toLowerCase() !== 'model'))
                    ?
                    null
                    :
                    (type === 'model'
                        ?
                        <>
                            <div className="agenda-footer-left">
                                &nbsp;
                            </div>
                            <div className="agenda-footer-right">
                                <NwButton ghost icon={light("share")} size="small" label="send agenda" onClick={onOpenSendAgendaDrawer} />
                                {/* <Popover open={availableEventsPopoverVisible} onOpenChange={closeAvailableEventsPopover} content={"No available castings"} title={false} trigger="hover"> */}
                                    {/* <div className="ant-dropdown-link" onClick={e => e.preventDefault()}> */}
                                        {isShowingAvailableEvents
                                            ?
                                            <NwButton
                                                ghost
                                                icon={light("eye-slash")}
                                                solid
                                                size="small"
                                                label="hide available castings"
                                                onClick={hideAvailableEvents}
                                            />
                                            :
                                            <NwButton
                                                loading={isLoadingAvailableEvents}
                                                ghost
                                                icon={light("user-plus")}
                                                solid
                                                size="small"
                                                label="see available castings"
                                                onClick={loadAvailableEvents}
                                            />
                                        }
                                    {/* </div> */}
                                {/* </Popover> */}
                            </div>
                        </>
                        :
                        //TODO: filters on general agenda view as in calendar
                        <></>
                    )
                }
            </AgendaFooterContainer>
        )
    }

    if (bookingSection) {
        return (
            <div className="calendar-fixed-footer">
                {getAgendaFooterContent()}
            </div>
        )
    } else {
        return getAgendaFooterContent()
    }
}

export default AgendaFooter