import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCancelButton, NwSaveButton, NwAddButton } from "Components/Gui/NwButton";
import { NWInput, NWBirthDatePicker, NWAddressSelector, NWRadioButtonGroup, NWSwitch, NWContactSelector } from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import NwDivider from 'Components/Gui/NwDivider';
import MiniLoading from 'Components/Gui/MiniLoading';
import { ListContext } from "Contexts/ListContext";
import { validateDate, validateAddress } from 'Libs/NwUtils';
import { 
    useEditContactConnection, 
    useAddContactConnectionFull
} from 'Hooks/Contact/UseContactConnections';
import NwDrawer from "Components/Gui/NwDrawer";
import ConnectionPreviewCard from "Components/Connection/ConnectionPreviewCard";
import CreateCustomerForm from 'Components/ContactCreator/CreateCustomerForm';
import { getNow } from "Libs/NwMoment";

const ConnectionItem = styled.div`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? props.theme.PrimaryDarkLight : props.theme.PrimaryColor};
  text-decoration: underline;
`;

const FieldWrapper = styled.div`
  label {
      width: 100%;
  }
`;

const validateField = value => {
    if (!value) {
        return 'Content is required'
    }
    return null;
};

const validateSubType = value => {
    if (!value) {
        return 'Please select subtype';
    }
    return null;
}

const validateContactID = value => {
    if (!value) {
        return 'Please select a contact';
    }
    return null;
}

const ConnectionDetailForm = ({
    contactID,
    connection,
    connectionSubTypeID,
    connectionType,
    existingConnections,
    showCustomerSelector,
    recipientMode,
    onCancel,
    onSave,
    onTouchForm,
    onCreate,
    onAddConnection
}) => {
    const { mutateAsync: editConnection } = useEditContactConnection()
    const { mutateAsync: addConnectionFull } = useAddContactConnectionFull();
    const [connections, setConnections] = useState([]);
    const [isConnectionsFetching, setIsConnectionsFetching] = useState(false);
    const [activeConnection, setConnection] = useState();
    const [addCustomer, setAddCustomer] = useState(false);
    const [customerTouchForm, setCustomerTouchForm] = useState(false);
    const connectionTypes = useContext(ListContext).cachedList.connectionTypes.items;

    const getSubTypeOptions = () => {
        const ST = connectionTypes.find(el => el.Name === connectionType)
        return ST.SubTypes.map(st => {
            return { key: st.ID, value: st.ID, label: st.Name }
        });
    };

    useEffect(() => {
        if (contactID) {
            getConnections(contactID);
        }
    }, [contactID]);

    const getConnections = async contactId => {
        setIsConnectionsFetching(true);
        if (!contactId) {
            setConnections([]);
            setIsConnectionsFetching(false);
            return;
        }
        try {
            const connections = await Axios.get(`/contacts/connections/${contactId}`);
            setConnections(connections);
            setIsConnectionsFetching(false);
        } catch (error) {
            console.log('ant : connections error => ', error);
            setIsConnectionsFetching(false);
        }
    };

    const defaultSubType = () => {
        if (connectionType) {
            const ct = connectionTypes.find(el => el.Name === connectionType)
            if (ct.SubTypes.length > 0) {
                return ct.SubTypes[0].ID
            }
        }
        return null
    }

    const initialValues = {
        ID: 0,
        TypeName: connectionType,
        ConnectionSubTypeID: connectionSubTypeID ? connectionSubTypeID : defaultSubType(),
        Name: null,
        Surname: null,
        Role: null,
        Note: null,
        Birthday: {
            year: null,
            month: undefined,
            day: null
        },
        ValidFrom: null,
        ValidTo: null,
        Address: null,
        LandNumber: '',
        LandNote: '',
        MobileNumber: '',
        MobileNote: '',
        CustomerID: contactID,
        Email: '',
        CC: ''
    };

    if (connection) {
        initialValues.ID = connection.ID;
        initialValues.TypeName = connection.TypeName; // String
        initialValues.ConnectionSubTypeID = connection.ConnectionSubTypeID; // Int
        initialValues.Name = connection.Name;
        initialValues.Surname = connection.Surname;
        initialValues.Role = connection.Role;
        initialValues.Note = connection.Note;
        initialValues.Birthday = connection.Birthday;
        initialValues.ValidFrom = connection.ValidFrom;
        initialValues.ValidTo = connection.ValidTo;
        initialValues.Birthday = {
            year: connection.Birthday ? getMoment(connection.Birthday).year() : null,
            month: connection.Birthday ? getMoment(connection.Birthday).month() : undefined,
            day: connection.Birthday ? getMoment(connection.Birthday).date() : null
        };
    }

    const GetDate = (year, month, day) => {
        if (year === null || month === undefined || day === null) {
            return null;
        }
        const birthDate = getNow().year(year).month(month).date(day);
        return birthDate.format("MM-DD-YYYY");
    }

    const saveConnection = async (values, setSubmitting, resetForm) => {
        const ID = initialValues.ID;
        let data = {
            ContactID: values.CustomerID,
            //from type to typename. To check
            Type: values.TypeName,
            ConnectionSubTypeID: values.ConnectionSubTypeID,
            Name: values.Name,
            Surname: values.Surname,
            Role: values.Role,
            Note: values.Note,
            Birthday: values.TypeName !== "People" ? null : GetDate(values.Birthday.year, values.Birthday.month, values.Birthday.day), //values.Birthday,
            ValidFrom: values.ValidFrom,
            ValidTo: values.ValidTo,
            Address: values.Address,
        };

        if (values.LandNumber) {
            data = {
                ...data,
                Land: {
                    Type: 'Land',
                    Number: values.LandNumber,
                    Note: values.LandNote
                }
            };
        }
        if (values.MobileNumber) {
            data = {
                ...data,
                Mobile: {
                    Type: 'Mobile',
                    Number: values.MobileNumber,
                    Note: values.MobileNote
                }
            }
        }
        if (values.Email) {
            const CCs = []
            if (values.CC) {
                CCs.push(values.CC)
            }
            data = {
                ...data,
                Email: {
                    Address: values.Email,
                    CC: [...CCs]
                }
            }
        }


        if (ID) {
            data.ID = ID;
            await editConnection(data)
            if (onTouchForm) {
                onTouchForm(false);
            }
            onSave()
        } else {
            try {
                const connection = await addConnectionFull({ data: data }) //,emails: emails
                if (onTouchForm) {
                    onTouchForm(false);
                }
                setSubmitting(false)
                onSave(connection.ID);
                if (onCreate) {
                    onCreate(connection);
                }
            } catch (error) {
                console.log("errore", error)
            }
        }
    };

    const handleViewConnection = connection => () => {
        setConnection(connection);
    };

    const handleAddCustomer = () => {
        setAddCustomer(true);
    };

    const validateEmail = async value => {
        if (recipientMode) {
            if (!value) {
                return 'Content is required';
            }
        }
        const schema = Yup.object().shape({ email: Yup.string().email("E-mail format is invalid") });
        let errorMessage = null; 
        await schema.validate({ email: value || '' }).catch(error => {
            errorMessage = error.message;
            return error.message;
        });
        return errorMessage;
    }

    const validateCC = async value => {
        const schema = Yup.object().shape({ email: Yup.string().email() });
        let errorMessage = null; 
        await schema.validate({ email: value || '' }).catch(error => {
            errorMessage = error.message;
            return error.message;
        });
        return errorMessage;
    }

    const handleCloseCustomerCreationForm = () => {
        if (customerTouchForm) {
            setCustomerTouchForm(false);
        }
        setAddCustomer(false);
    };

    const handleCustomerCreated = setFieldValue => customer => {
        setFieldValue('CustomerID', customer.ID);
        setConnections([]);
        setAddCustomer(false);
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                saveConnection(values, setSubmitting, resetForm);
            }}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
                //TypeName: Yup.string().required("Content is required"),
                //CustomerID: Yup.string().required("Content is required")
            })}
        >
            {({ isSubmitting, values, handleSubmit, setFieldValue, isValid }) => (
            <NwForm 
                values={values}
                onTouchForm={onTouchForm}
                onFinish={handleSubmit} 
                layout="vertical">
                {showCustomerSelector &&
                    <FieldWrapper>
                        <Field
                            component={NWContactSelector}
                            // label="Customer"
                            label={
                                <FlexContainer fullWidth justifyContent='space-between'>
                                    <div>Customer</div>
                                    <NwAddButton label='create new customer' ghost onClick={handleAddCustomer} />
                                </FlexContainer>
                            }
                            activeTypes={['cli', 'age' , 'srv']}
                            activableTypes={[]}
                            name="CustomerID"
                            value={values.CustomerID}
                            validate={validateContactID}
                            onAfterChange={value => getConnections(value)}
                        />
                    </FieldWrapper> 
                }
                {connectionSubTypeID === 1 && 
                    <>
                        <NwDivider>STAFF</NwDivider>
                        {isConnectionsFetching ?
                            <MiniLoading />
                            :
                            <>
                                {connections.map(connection => {
                                    const disabled = existingConnections.find(item => item.Connection.ID === connection.ID);
                                    return (
                                        <ConnectionItem disabled={disabled} key={`connection-${connection.ID}`} onClick={!disabled && handleViewConnection(connection)}>
                                            {`${connection.Name}${connection.Surname ? ` ${connection.Surname}` : ''} / ${connection.Type}`}
                                        </ConnectionItem>
                                    )
                                })}
                                {/* {connections} */}
                            </>
                        }
                    </>
                }
                {!connectionSubTypeID && (values.TypeName !== "Personal") && (
                    <Field
                        component={NWRadioButtonGroup}
                        name="ConnectionSubTypeID"
                        label=''
                        value={values.ConnectionSubTypeID}
                        buttonStyle="solid"
                        validate={validateSubType}
                        options={getSubTypeOptions()}
                    />
                )}
                {/* Name */}
                {(values.TypeName === "Place" || values.TypeName === "Room") && (
                    <Field
                        component={NWInput}
                        label="Name"
                        name="Name"
                        value={values.Name}
                        validate={validateField}
                        type="text"
                    />
                )}
                {values.TypeName === "People" && (
                    <>
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="Name"
                                    name="Name"
                                    maxLength={100}
                                    value={values.Name}
                                    validate={validateField}
                                    type="text"
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                {/* Surname */}
                                <Field
                                    component={NWInput}
                                    label="Surname"
                                    name="Surname"
                                    maxLength={100}
                                    value={values.Surname}
                                    type="text"
                                />
                            </Col> 
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                {/* Role */}
                                <Field
                                    component={NWInput}
                                    label="Role"
                                    name="Role"
                                    maxLength={25}
                                    value={values.Role}
                                    type="text"
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                {/* Birthday */}
                                <Field
                                    component={NWBirthDatePicker}
                                    label="Birthday"
                                    name="Birthday"
                                    style={{ width: "100%" }}
                                    value={values.Birthday}
                                    validate={value => validateDate(value, true)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Field
                    component={NWInput}
                    label="Note"
                    name="Note"
                    value={values.Note}
                    type="text"
                    maxLength={1000}
                />
                
                        {(values.TypeName === "People") && !connection &&
                            <>
                                <Field
                                    component={NWInput}
                                    label="E-mail"
                                    name="Email"
                                    value={values.Email}
                                    type="text"
                                    maxLength={250}
                                    validate={value => validateEmail(value)}
                                />
                                <Field
                                    component={NWInput}
                                    label="E-mail CC"
                                    name="CC"
                                    value={values.CC}
                                    type="text"
                                    validate={value => validateCC(value)}
                                />
                            </>
                        }
                {(values.TypeName === "Place" || values.TypeName === "Room") && !connection &&
                    <Field
                        name='Address'
                        component={NWAddressSelector}
                        value={values.Address}
                        //validate={validateAddress} 
                    />
                }
                {!connection &&
                    <>
                        <br />
                        <FlexContainer alignItems='flex-end'>
                            <Field
                                component={NWInput}                        
                                label={
                                    <FlexContainer>
                                        <NwIcon icon={light('phone-office')} />&nbsp;
                                        <div>Land Number</div>
                                    </FlexContainer>
                                }
                                name="LandNumber"
                                type="text"
                                value={values.LandNumber}
                            />&nbsp;&nbsp;
                            <Field
                                component={NWSwitch}
                                label=""
                                name="showLandNote"
                                maxLength={50}
                                text='Add Note'
                                defaultChecked={values.showLandNote ? true : false}
                                checked={Boolean(values.showLandNote)} />
                        </FlexContainer>
                        {values.showLandNote &&
                            <Field
                                component={NWInput}
                                label="Land Note"
                                name="LandNote"
                                maxLength={100}
                                type="text"
                                value={values.LandNote}
                            />
                        }
                        <FlexContainer alignItems='flex-end'>
                            <Field
                                component={NWInput}                        
                                label={
                                    <FlexContainer>
                                        <NwIcon icon={solid('mobile-alt')} />&nbsp;
                                        <div>Mobile Number</div>
                                    </FlexContainer>
                                }
                                name="MobileNumber"
                                type="text"
                                maxLength={50}
                                value={values.MobileNumber}
                            />&nbsp;&nbsp;
                            <Field
                                component={NWSwitch}
                                label=""
                                name="showMobileNote"
                                text='Add Note'
                                defaultChecked={values.showMobileNote ? true : false}
                                checked={Boolean(values.showMobileNote)} />
                        </FlexContainer>
                        {values.showMobileNote && 
                            <Field
                                component={NWInput}
                                label="Mobile Note"
                                name="MobileNote"
                                maxLength={100}
                                type="text"
                                value={values.MobileNote}
                            />
                        }
                    </>
                }
                <NwFormButtonsBar
                    left={
                        recipientMode ? <div />
                        : <NwCancelButton
                            disabled={isSubmitting}
                            onClick={() => {
                                if (onTouchForm) {
                                    onTouchForm(false);
                                }
                                onCancel();
                            }}
                        />
                    }
                    right={
                        <NwSaveButton
                            onClick={handleSubmit}
                            disabled={!isValid}
                            loading={isSubmitting}
                        />
                    }
                />
                {activeConnection && 
                    <NwDrawer
                        title='Connection'
                        onClose={() => setConnection()}>
                        <ConnectionPreviewCard 
                            recipientMode={recipientMode}
                            item={activeConnection} 
                            onAddConnection={onAddConnection} 
                            onCancel={() => {
                                setConnection();   
                                onCancel()
                            }} />
                    </NwDrawer>
                }
                {addCustomer && 
                    <NwDrawer
                        touchForm={customerTouchForm}
                        onTouchForm={setCustomerTouchForm}
                        onClose={() => setAddCustomer()}
                        title='Add New Customer'>
                        <CreateCustomerForm 
                            onCustomerCreated={handleCustomerCreated(setFieldValue)} 
                            onTouchForm={setCustomerTouchForm}
                            onCancel={handleCloseCustomerCreationForm}
                        /> 
                    </NwDrawer>
                }
            </NwForm>
            )}
        </Formik>
    );
};

export default ConnectionDetailForm;
