import React from "react";
import { InputNumber } from "antd";

const NumericRangeSelector = ({ onChange, onBlur, value, disabled }) => {
    const MinValue = value ? value[0] : null
    const MaxValue = value ? value[1] : null
    
    const onMinValueChange = (val) => {
        //let _maxValue = val;
        //if (MaxValue !== null) {_maxValue = val > MaxValue? val : MaxValue;
        // onChange({
        //     ...value,
        //     MinValue: val ? val : null,
        //     MaxValue: _maxValue ? _maxValue : null
        // });
        onChange([val, value[1]])
    }

    const onMaxValueChange = (val) => {
        //let _minValue = MinValue;
        //if (MinValue !== null) _minValue = val < MinValue? val : MinValue;
        // onChange({
        //     ...value,
        //     MinValue: _minValue ? _minValue : null,
        //     MaxValue: val ? val : null
        // });
        onChange([value[0], val])
    }
    
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{margin: "auto 5px"}}>between</div>
            <InputNumber
                autoComplete='off'
                style={{ width: "60px", textAlign: "center", marginLeft: "10px"}}
                disabled={disabled}
                min={0}
                value={MinValue === null ? 0 : MinValue}
                onChange={(val) => onMinValueChange(val)}
            />
            <div style={{margin: "auto 5px"}}>and</div>
            <InputNumber
                autoComplete='off'
                style={{ width: "60px", textAlign: "center"}}
                disabled={disabled}
                min={0}
                value={MaxValue === null ? 0 : MaxValue}
                onChange={(val) => onMaxValueChange(val)}
            />
        </div>
    );
};

export default NumericRangeSelector;
