import React, { useEffect, useState } from 'react';
import NwDrawer from 'Components/Gui/NwDrawer';
//import { Segmented } from 'antd';
import { TransactionDrawerContent } from '../../Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import CommAsMothAgForm from '../TransactionsCreateForms/CommAsMothAgForm';
import { getContactBillingInfo } from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils';
import { Loading } from 'Components/Gui/Loading';

const AddExternalJobTransactionDrawer = ({ event, onClose, onSave }) => {
    const [touchForm, setTouchForm] = useState(false);
    //const [selectedTab, setSelectedTab] = useState(1);
    
    return (
        <NwDrawer
            width='40vw'
            maxWidth='640px'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={`New Commission`}>
            {(!event)
                ?
                <Loading />
                :
                <TransactionDrawerContent>
                    <CommAsMothAgForm
                        externalJobData={event}
                        onCloseDrawer={onClose}
                    />
                    {/* <Segmented
                        block
                        value={selectedTab}
                        onChange={setSelectedTab}
                        options={[
                            {
                                label: (
                                    <div>
                                        <h2>Commission as mother agency</h2>
                                        <small></small>
                                    </div>
                                ),
                                value: 1,
                            },
                            {
                                label: (
                                    <div>
                                        <h2>Other</h2>
                                        <small>???</small>
                                    </div>
                                ),
                                value: 2,
                            }
                        ]}
                    />
                    {selectedTab === 1 &&
                        <CommAsMothAgForm
                            externalJobData={event}
                            onCloseDrawer={onClose}
                            clientBillingInfos={clientBillingInfos}
                        />
                    }
                    {selectedTab === 2 &&
                        <p></p>
                    } */}
                </TransactionDrawerContent>
            }
        </NwDrawer>
    )
};

export default AddExternalJobTransactionDrawer;