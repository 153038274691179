import { Card, Badge } from "antd";
import styled from "styled-components";
import "react-perfect-scrollbar/dist/css/styles.css";

const CardActionsHeight = 60
const CardHeaderHeight = 48
const CardSmallHeaderHeight = 36
const CardPaginationHeight = 38

const NwStyledCard = styled(Card)`
  .scrollbar-container > .ps__rail-x,
  .scrollbar-container > .ps__rail-y {   opacity: 0.6 !important; }

  .full-height {
    height: 100%;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  background-color: ${props => props.$transparent ? "transparent" : "#fff"}; 

  @media only screen and (max-width: 720px) {
    &.ant-card {
      border-radius: 0 !important;
      //width: 100vw;
      width: 100%;
    }
    &.ant-card-bordered {
      border: none !important;
    }
  }
  .ant-card-head {
    font-size: 1.1rem !important;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 16px 0 24px;

    @media only screen and (max-width: 720px) {
      .ant-card-head-wrapper {
        align-items: normal;
        flex-direction: column;
      }
      .ant-card-head-title {
        padding: 1rem 0 !important;
      }
      .ant-card-extra {
        padding: 0 0 10px 0 !important;
      }
    }

    .nw-card-header-icon {
      margin-right: .5em;
    }

    .ant-card-extra {
      padding: 10px 0;
    }

    .ant-card-head-title {
      padding: 14px 0;
      
      .nw-card-head-title {
        display:flex;
        justify-content: flex-start;
        align-items: center;
        
        .nw-card-head-icon-holder {
            min-width: 2rem;
        }

        .nw-card-head-selection {
            min-width: 2rem;
        }

        .nw-card-head-title-content {
          line-height: 120%;
          display: flex;
          align-items: center;

          h1 {
            font-size: 1rem;
            margin: 2px 0 0 0;
            text-transform: capitalize;
            @media only screen and (max-width: 720px) {
              font-size: 1.3rem !important;
            }
          }
          
          div, p {
            font-size: .65em;
            text-transform: uppercase;
            font-weight: normal;
            margin: 0 0 .1em 0;
          }

          small {
            margin: 0 0 .1em 0;
            text-transform: uppercase;
            font-weight: normal;
            font-size: .5em;
          }
        }

        .nw-card-head-title-action {
          margin-left: 1em;
        }
      }
    }

    .ant-tabs {    

      .ant-tabs-extra-content {
        line-height: 50px !important;
        padding-right: 10px;
      }

      .ant-tabs-nav {
        min-height: 48px;

        .ant-tabs-ink-bar {
          background-color: ${props => props.theme.PrimaryColor};
        }


        .ant-tabs-tab {
          padding: 12px 0;
          font-weight: 500;
          font-size: 1rem;

            .ant-tabs-tab-btn {
                opacity: .5;
            }

            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    opacity: 1;
                    color: ${props => props.theme.TextColor};
                }
            }

          .tab-title-with-icon-and-counter {
            display: flex;
            align-items: center;
            .tab-title-prefix {
                padding-right: .5em;
            }
            .tab-title-suffix {
                padding-left: .5em;
            }
          }
        }
      }

      
    }

  }

  &.ant-card-contain-tabs {
    .ant-card-head {
      padding: 0 10px 0 24px;
    }
  }

  .ant-card-toolbar {
    border-bottom: ${props => props.theme.DefaultBorder};
    padding: .75em 1.5em;

    button {
      margin-right: .25em;
    }

    &.ant-card-toolbar-flex {
      display: flex;
    }
  }

  .ant-card-body {
    flex: 1;
    padding: ${props => props.$filters ? '42px 0 0' : 0};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    .nw-card-paginated-content {
      flex: 1 0 0;
      padding: .5rem 1rem;
      position: relative;

      &.large-padding {
        padding: 1rem 2rem;
      }
      &.no-padding-y {
        padding: 0 2rem;
      }
      &.no-padding-x {
        padding: 1rem 0;
      }
      &.no-padding {
        padding: 0;
      }

      &.card-loading {
        opacity: .25;
      }
    }

    .nw-empty-state {
      &::before, &::after {
          content: '';
          margin: auto;
      }
    }

    .nw-card-paginated-pagination {
      flex: ${CardPaginationHeight}px 0 0;
    }

    .ant-card-body-content {
        padding: .5rem 1rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
    }

    .active-filters {
        width: 100%;
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 5;
        background: white;
    }

    .active-filters-label {
        border-bottom: ${props => !props.$filterscontent && props.theme.DefaultBorder};
        padding: 8px 24px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 42px;
    }

    .filters-content {
        padding: 16px 24px;
        border-bottom: ${props => props.theme.DefaultBorder};
        width: 100%;
        background: white;
        display: flex;
        box-shadow: 0 4px 2px -2px rgb(90 97 105 / 10%);
    }
  }

  .ant-card-actions {
    height: ${CardActionsHeight}px;
    flex: ${CardActionsHeight}px 0 0;
    box-sizing: border-box;
    overflow: hidden;
    padding: .5rem 1.5rem;
    color: ${props => props.theme.TextColor};
  }

  &.ant-card-small {
    .ant-card-head {
      height: ${CardSmallHeaderHeight}px;
      font-size: .85rem !important;
      flex: ${CardSmallHeaderHeight}px 0 0;
    }    
  }

  &.nw-card-primary {
    border-color: ${props => props.theme.PrimaryColor};
  }
  &.nw-card-danger {
    border-color: ${props => props.theme.DangerColor};
    color: ${props => props.theme.DangerColor};
  }

  &.large-padding {
    .ant-card-body-content {
      padding: 1rem 2rem;
    }
  }
  &.no-padding-y {
    .ant-card-body-content {
      padding: 0 2rem;
    }
  }
  &.no-padding-x {
    .ant-card-body-content {
      padding: 1rem 0;
    }
  }
  &.no-padding {
    .ant-card-body-content {
      padding: 0;
    }
  }

  &.nw-card-horizontal {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .ant-card-head {
      flex: auto 0 0;
      border-bottom: none;
      border-right: ${props => props.theme.DefaultBorder};
      height: auto;
      min-height: 0;
      
      .ant-card-head-title {
        padding: 0;
      }
    }

    .ant-card-body {
        flex: 1 0 0;
        padding: 0 1em;
        min-height: 3rem;
        justify-content: center;
    }

    .ant-card-body-content {
      padding: 0;
      justify-content: center;
    }
  }
`;

const GetNwCardContentStyle = (props) => {
    let ClassN = "nw-card-paginated-content"
    if (props.padding) {
        switch (props.padding) {
            case "no":
                ClassN += " no-padding"
                break;
            case "no-x":
                ClassN += " no-padding-x"
                break;
            case "no-y":
                ClassN += " no-padding-y"
                break;
            case "large":
                ClassN += " large-padding"
                break;
            default:
        }
    }
    if (props.loading) {
        ClassN += " card-loading"
    }
    return ClassN
}


const GetNwCardStyle = (props, hastabs) => {
    let Style = props.Style || {}
    let BodyStyle = {}
    let ScrollStyle = {}

    let CardBodyPadding = 16
    let ClassN = props.className || ""

    if (props.padding) {
        if (ClassN) {
            ClassN += " "
        }
        switch (props.padding) {
            case "no":
                ClassN += "no-padding"
                CardBodyPadding = 0
                break;
            case "no-x":
                ClassN += "no-padding-x"
                CardBodyPadding = 16
                break;
            case "no-y":
                ClassN += "no-padding-y"
                CardBodyPadding = 0
                break;
            case "large":
                ClassN += "large-padding"
                CardBodyPadding = 16
                break;
            default:
        }
    } else {
        if (hastabs) {
            ClassN += " no-padding"
            CardBodyPadding = 0
        } else {
            ClassN += " standard-padding"
            CardBodyPadding = 8
        }
    }
    if (props.primary) {
        ClassN += " nw-card-primary"
    }
    if (props.danger) {
        ClassN += " nw-card-danger"
    }

    if (props.horizontal) {
        ClassN += " nw-card-horizontal"
    }

    if (props.minheight) {
        if (isNaN(props.minheight)) {
            Style.minHeight = props.minheight
        } else {
            Style.minHeight = `${props.minheight}px`

            let ContentMinHeight = props.minheight
            ContentMinHeight -= CardHeaderHeight
            if (props.actions) {
                ContentMinHeight -= CardActionsHeight
            }
            if (props.footer || props.footerButtons) {
                ContentMinHeight -= CardActionsHeight
            }
            ContentMinHeight -= (CardBodyPadding * 2)
            BodyStyle.minHeight = `${ContentMinHeight - 2}px`
        }
    }

    if (props.fullwidth) {
        Style.width = "100%"
    }
    if (props.fullheight) {
        Style.height = "100%"
    }

    if (props.scrollable) {
        Style.height = `${props.scrollheight || 300}px`
        BodyStyle.height = `${props.scrollheight || 300}px`
        ScrollStyle.height = `${props.scrollheight || 300}px`
    }

    if (props.loading) {
        BodyStyle.opacity = ".25"
    } else {
        BodyStyle.opacity = "1"
    }
    return { ClassN, Style, BodyStyle, ScrollStyle }
}

const FilterBadge = styled(Badge)`
    margin-left: 8px;
    cursor: pointer;
    .ant-badge-count {
        background-color: ${props => props.theme.PrimaryColor};
        margin-right: -2px;
        margin-top: 1px;
    }
`;

const TabsContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    .active-filters {
        width: 100%;
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 5;
        background: white;
    }

    .active-filters-label {
        border-bottom: ${props => !props.$filterscontent && props.theme.DefaultBorder};
        padding: 8px 24px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 42px;
    }

    .filters-content {
        padding: 16px 24px;
        border-bottom: ${props => props.theme.DefaultBorder};
        width: 100%;
        background: white;
        box-shadow: 0 1px 0px 0px rgb(0 0 0 / 40%);
        display: flex;
    }
    
    .tabs-content {
        padding: ${props => props.$filters ? '42px 0 0 0' : 0};
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export { NwStyledCard, GetNwCardContentStyle, GetNwCardStyle, FilterBadge, TabsContentWrapper };
