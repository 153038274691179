import React from 'react';
import { Result } from 'antd';
import NwIcon from './NwIcon';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const ResultContainer = styled.div`
    .ant-result-icon {
        font-size: 3rem;
    }
`


const ErrorMessage = ({ text }) => {
    return (
        <ResultContainer>
            <Result
                icon={<NwIcon icon={light('bomb')} />}
                title="Error"
                subTitle={text}
            />
        </ResultContainer >
    );
}

export default ErrorMessage;