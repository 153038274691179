import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";
import { sortTags, mapTags } from "Libs/NwUtils";

const Option = Select.Option;

const InputTag = ({ contactType, onChange, value, disabled }) => {
  const { cachedList } = useContext(ListContext);
  const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items);
  const allTags = sortTags(mapTags(cachedtags, contactType));

  const handleChange = values => {
    const _newValues = values.map(name => allTags.find(item => item.Name === name).ID);
    onChange(_newValues);
  };

  const getNameValues = values => {
    const _newValues = values.map(id => allTags.find(item => item.ID === id).Name);
    return _newValues;
  }

  return (
    <Select
      autoComplete='off'
      mode="multiple"
      value={getNameValues(value)}
      placeholder="Please select"
      onChange={handleChange}
      disabled={disabled}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      style={{ width: "100%" }}
    >
      {allTags.map(el => (
        <Option key={el.ID} value={el.Name}>
          {el.Name}
        </Option>
      ))}
    </Select>
  );
};

export default InputTag;
