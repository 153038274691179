import Color from 'color';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';


export const getEventColorsByType = (eventTypes, followUpLabels, evtype, subtypecode, optionnumber) => {
    let eventtype = eventTypes.items.find((et) => (et.code.toLowerCase() === evtype.toLowerCase()))
    let bgcolor = eventtype.baseColor
    let color = eventtype.textColor
    let icon = eventtype.icon
    let name = eventtype.name

    switch (eventtype.code.toLowerCase()) {
        case "casting":
            if (subtypecode) {
                const st = eventtype.subTypes.find((st) => {
                    return st.Code.toLowerCase() === subtypecode.toLowerCase()
                })
                if (st) {
                    name = st.Name
                    bgcolor = `#${st.BackColor}`
                    color = `#${st.ForeColor}`
                }
            } else {
                if (eventtype.subTypes && eventtype.subTypes.length > 0) {
                    const st = eventtype.subTypes.find((st) => ( st.Default ))
                    if (st) {
                        name = st.Name
                        bgcolor = `#${st.BackColor}`
                        color = `#${st.ForeColor}`
                    }
                }
            }
            break
        case "job":
            let confcolors = null;
            let optcolors = null;
            if (subtypecode) {
                const st = eventtype.subTypes.find((st) => {
                    return st.Code.toLowerCase() === subtypecode.toLowerCase()
                })
                if (st) {
                    confcolors = getConfirmationStyle(st)
                    optcolors = getOptionStyle(st)
                }
            } else {
                if (eventtype.subTypes && eventtype.subTypes.length > 0) {
                    const st = eventtype.subTypes.find((st) => ( st.Default ))
                    if (st) {
                        confcolors = getConfirmationStyle(st)
                        optcolors = getOptionStyle(st)
                    }
                } else {
                    confcolors = { bgcolor: bgcolor, name: name, color: color }
                    optcolors = getOptionStyleNoLabel(eventtype)
                }
            }
            let confirmationStatus = ""
            if (optionnumber) {
                if (optionnumber === 10) {
                    confirmationStatus = "confirmed"
                } else {
                    confirmationStatus = "option"
                }
            }
            switch (confirmationStatus) {
                case "confirmed":
                    bgcolor = confcolors.bgcolor
                    color = confcolors.color
                    name = confcolors.name
                    break
                case "option":
                    bgcolor = optcolors.bgcolor
                    color = optcolors.color
                    name = optcolors.name
                    break
                default:
                    break
            }
            break;
        case "followup":
            if (subtypecode) {
                const followupLabel = followUpLabels.items.find(item => item.Code === subtypecode);
                if (followupLabel) {
                    bgcolor = followupLabel.BackColor;
                    color = followupLabel.ForeColor;
                    if (followupLabel.ModelNotAvailable) {
                        icon = solid('do-not-enter');
                    }
                    name = followupLabel.Label
                }
            }
            break
        case "extjob":
            if (optionnumber !== 10 && eventtype.secondaryColor) {
                bgcolor = eventtype.secondaryColor
            }
            if (optionnumber !== 10 && eventtype.secondaryTextColor) {
                color = eventtype.secondaryTextColor
            }
            if (optionnumber !== 10 && eventtype.secondaryIcon) {
                icon = eventtype.secondaryIcon
            }
            break
        default:
            break
    }
    return { color: color, backgroundColor: bgcolor, icon: icon, name: name }
}

export const getConfirmationStyle = (st) => {
    const colors = {}
    colors.bgcolor = `#${st.BackColor}`
    colors.color = `#${st.ForeColor}`
    colors.name = st.Name
    return colors
}

export const getMixedStyle = (st) => {
    const colors = {}
    const firstcolor = Color(`#${st.BackColor}`).alpha(0.8).hsl().string()
    const secondcolor = Color(`#${st.OptionBackColor}`).alpha(0.8).hsl().string()
    colors.bgcolor = `linear-gradient(90deg, ${firstcolor} 0%, ${secondcolor} 100%)`
    colors.color = `#000`
    colors.name = st.Name    // + " [mixed]"
    return colors
}

export const getOptionStyle = (st) => {
    //for all job labels (subtype)
    const colors = {}
    if (st.Name.toLowerCase().indexOf("job") >= 0) {
        colors.name = st.Name.replace(/job/gi, "option")
    } else {
        colors.name = st.Name + " [option]"
    }
    if (st.OptionBackColor) {
        colors.bgcolor = `#${st.OptionBackColor}`
        colors.color = `#${st.OptionForeColor}`
    } else {
        const firstcolor = Color(`#${st.BackColor}`).alpha(0.25).hsl().string()
        const secondcolor = Color(`#${st.BackColor}`).alpha(0.15).hsl().string()
        colors.bgcolor = `repeating-linear-gradient(
            -55deg,
            ${firstcolor},
            ${firstcolor} 5px,
            ${secondcolor} 5px,
            ${secondcolor} 10px
        )`
        colors.color = "#000"
    }
    return colors
}

export const getOptionStyleNoLabel = (st) => {
    //for job event type with no labels (if still possible)
    const colors = {}
    if (st.name.toLowerCase().indexOf("job") >= 0) {
        colors.name = st.name.replace(/job/gi, "option")
    } else {
        colors.name = st.name + " [option]"
    }
    if (st.secondaryColor) {
        colors.bgcolor = `#${st.secondaryColor}`
        if (st.secondaryTextColor) {
            colors.color = `#${st.secondaryTextColor}`
        }
    } else {
        const firstcolor = Color(`#${st.baseColor}`).alpha(0.25).hsl().string()
        const secondcolor = Color(`#${st.baseColor}`).alpha(0.15).hsl().string()
        colors.bgcolor = `repeating-linear-gradient(
            -55deg,
            ${firstcolor},
            ${firstcolor} 5px,
            ${secondcolor} 5px,
            ${secondcolor} 10px
        )`
        colors.color = "#000"
    }
    return colors
}