import React from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from 'Components/PermissionBlock';
import { useUserPermission } from 'Contexts/UserContext';

const MotherAgencyName = styled.div`
    text-transform: uppercase;
`
const Represented = styled.div`
    text-transform: uppercase;
    font-weight: bold;
`

const ModelDetailsHeaderAgencies = ({ model, readOnly  }) => {
    const viewModelAgencies = useUserPermission('ViewModelAgencies', model && (model.Users || model.U).map(user => user.UserID));
    
    const AgenciesContent = () => {
        if (model.MotherAgencyID || model.MAID) {
            return (
                <MotherAgencyName viewModelAgencies={viewModelAgencies}><NwIcon icon={light("globe")} />&nbsp;&nbsp;{model.MotherAgencyName || model.MAN}</MotherAgencyName>  
            )
        }
        if (model.Represented || model.R) {
            return (
                <Represented viewModelAgencies={viewModelAgencies}><NwIcon icon={light("medal")} />&nbsp;&nbsp;represented</Represented>
            )
        }
        return (
            <p><NwIcon icon={light("globe")} />&nbsp;&nbsp;no mother ag.</p>
        )
    };
    
    return (
        <>
            <div className="header-body-boxes">
                <PermissionBlock
                    content={AgenciesContent()}
                    permission={viewModelAgencies}
                />
            </div>
        </>
    )
    
};

export default ModelDetailsHeaderAgencies;
