import React, { useContext, useState, useMemo, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Typography } from "antd";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { useStateValue } from "Contexts/StateProvider";
import { NwButton, NwRemoveButton, NwEditButton } from "Components/Gui/NwButton";
import { UserContext } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import { ConfigContext } from 'Contexts/ConfigContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwIcon from 'Components/Gui/NwIcon';
import SelectedFilters from "Components/Filters/SelectedFilters";
import {
    createModelFilterList,
    createFilters,
    mapSetToInitialValues
} from 'Components/Filters/FilterForm/FilterUtils';
import NwDrawer from "Components/Gui/NwDrawer";
import NwDivider from "Components/Gui/NwDivider";
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import { useGlobalFeatureSets, useGlobalFeatures } from 'Hooks/Features/UseFeatures';
import FlexContainer from 'Components/Gui/FlexContainer';
import ModelFilterDrawer from "Components/Filters/FilterForm/ModelFilterForm/ModelFilterDrawer";
import {
    FiltersContainer,
    FilterItem,
    ObjectCounter,
    FilterItemWrapper,
    FilterPanel,
    FilterActions,
    FilterModal,
} from 'Components/Filters/FilterForm/FilterFormUI';
import FilterSetAddEditDrawer from 'Components/Filters/FilterForm/FilterSetAddEditDrawer';
import QuickFilters from "./QuickFilters";
import { MODELS_FILTERS } from "Configs/Filters/MODELS_FILTERS";

const StaticFilters = [
    { Name: 'Work', Value: 'Work' },
    { Name: 'Demographic', Value: 'Demographic' }
];

const ScoutingStaticFilters = [
    { Name: 'Bookers', Value: 'Bookers' },
    { Name: 'Demographic', Value: 'Demographic' }
]

const ModelFilterForm = ({
    filtersType,
    isDrawer,
    itemSet,
    leftMargin,
    navigation,
    noActions,
    onChangeFilters,
    onCustomizingList,
    onSave,
    onSelectSet,
    onSetQuickFilter,
    onSetSearchType,
    quickFilter,
    reloadAllModels,
    searchType,
    setFiltersType,
    tags,
    view,
}) => {

    const [{ areaContext }, dispatch] = useStateValue();
    const breakpoint = useNwBreakPoints();
    const { currentUser } = useContext(UserContext);
    const { cachedList } = useContext(ListContext);
    const [selectedFilter, setFilter] = useState();
    const [isOpenDrawer, setOpenDrawer] = useState(false);
    const [openAddDrawer, setOpenAddDrawer] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const { data: globalFeatureSets } = useGlobalFeatureSets();
    const { data: globalFeatures } = useGlobalFeatures();
    const { userGuiConfig } = useContext(ConfigContext);
    const [customFields, setCustomFields] = useState([]);
    const [touchForm, setTouchForm] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    
    const filters = useMemo(() => {
        let filters = areaContext.area === 'scouting' ? [...ScoutingStaticFilters] : [...StaticFilters];
        if (globalFeatureSets && areaContext.area !== 'accounting') {
            for (const featureSet of globalFeatureSets) {
                filters.push({ Name: `Features (${featureSet.Name})`, Value: featureSet.Name });
            }
        }
        for (const customField of customFields) {
            filters.push({ Name: `custom_fields_${customField.Name}`, Value: `custom_fields_${customField.Name}` });
        }
        return filters;
    }, [globalFeatureSets, customFields]);

    useEffect(() => {
        setCustomFields(cachedList.customFields.items['Model']);
    }, [cachedList]);

    const saveFilterSet = (values, setSubmitting, resetForm) => {
        const data = {
            id: (isAdd && itemSet) ? Date.now() : values.ID,
            name: values.Name,
            fixed: false,
            filters: createFilters(values)
        };
        if (!isAdd) {
            dispatch({
                type: "ModelFiltersEditCustomSet",
                area: areaContext.area,
                newSet: data
            });
        } else {
            dispatch({
                type: "ModelFiltersAddCustomSet",
                area: areaContext.area,
                newSet: data
            });
            setFiltersType("smart-lists");
        }
        setSubmitting(false);
        onSave(data.id);
    };

    const handleOpenFilter = value => () => {
        setFilter(value);
        setOpenDrawer(true);
        setOpenAddDrawer(false);
    }

    const handleCloseDrawer = () => {
        setFilter();
        setOpenDrawer(false);
    };

    const handleResetFilterForm = (values, setFieldValue) => () => {
        if (filtersType === "all-models") {
            dispatch({
                type: "ModelFiltersSetAdvanced",
                area: areaContext.area,
                filters: [],
            });
            reloadAllModels();
            setFilterApplied(false);
        }
        const fieldNames = Object.keys(initialValues);
        fieldNames.map(fieldName => {
            setFieldValue(fieldName, initialValues[fieldName])
            return null
        })
        onChangeFilters(createFilters(initialValues))
        onCustomizingList(false);
    }

    const initialValues = createModelFilterList(currentUser, globalFeatureSets, globalFeatures, userGuiConfig, customFields);
    if (itemSet) {
        mapSetToInitialValues(initialValues, itemSet)
    }

    const handleDeleteFilterList = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "ModelFiltersDeleteSet",
                area: areaContext.area,
                activeSet: itemSet.id
            });
            onSelectSet('v0');
            setFiltersType('all-models');
            resolve();
        }).catch(error => console.log("Errore", error));
    };

    const handleOpenAddListDrawer = () => {
        setOpenDrawer(false)
        setOpenAddDrawer(true)
    };

    const handleCloseAddDrawer = () => {
        setOpenAddDrawer(false)
    }

    const getFilterCounter = (filter, values) => {
        let count = 0;
        switch (filter) {
            case 'Work':
            case 'Bookers':
            case 'Demographic':
            case 'Location':
            case 'Media':
                let valueKeys = Object.keys(values);
                valueKeys.forEach(key => {
                    if (values[key].active && values[key].area.toLowerCase() === filter.toLowerCase())
                        count++;
                });
                break;
            default:
                let featureKeys = Object.keys(values.Features);
                featureKeys.forEach(key => {
                    if (values.Features[key].active && values.Features[key].featureSetName.toLowerCase() === filter.toLowerCase())
                        count++;
                });
                break;
        }
        return count;
    }

    const isCustomSelection = (initialValues, values) => {
        return JSON.stringify(initialValues) !== JSON.stringify(values)
    };

    const handleApplyFilters = (filterValues, setValues) => {
        setValues(filterValues);
        onChangeFilters(createFilters(filterValues));
        onCustomizingList(isCustomSelection(initialValues, filterValues));
        handleCloseDrawer();
        if (filtersType === "all-models") {
            dispatch({
                type: "ModelFiltersSetAdvanced",
                area: areaContext.area,
                filters: createFilters(filterValues),
            });
            setFilterApplied(true);
        }
    };

    if (filtersType === "all-models" && !searchType) {
        return (
            <QuickFilters
                onSetQuickFilter={onSetQuickFilter}
                quickFilter={quickFilter}
                tags={tags}
            />
        )
    }

    return (
        <>
            {view !== 'compact' && <NwDivider>active filters</NwDivider>}
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Content is required")
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleCloseAddDrawer();
                    saveFilterSet(values, setSubmitting, resetForm);
                }}
                enableReinitialize={true}>
                {({ isSubmitting, values, handleSubmit, setFieldValue, setValues }) => (
                    <NwForm noValidate onFinish={handleSubmit} layout="vertical">
                        {view !== 'compact' &&
                            <FilterPanel>
                                <SelectedFilters filters={createFilters(values)} customFields={customFields} filterList={MODELS_FILTERS} />
                            </FilterPanel>
                        }
                        {view === 'compact' ?
                            <FlexContainer mg={filtersType === 'smart-lists' && '0 0 0 16px'}>
                                {/* {createFilters(values).length > 0 && 
                                    <>
                                        <Popover
                                            placement='bottom'
                                            trigger='click'
                                            content={
                                                <CompactSelectedFiltersContainer>
                                                    <SelectedFilters filters={createFilters(values)} view='compact' customFields={customFields} />
                                                </CompactSelectedFiltersContainer>
                                            }
                                            >
                                            <FiltersLabel>{`${createFilters(values).length} filter${createFilters(values).length > 1 ? 's' : ''}`}</FiltersLabel>
                                        </Popover>
                                       <NwDividerVertical />
                                    </>
                                }
                                
                                <Dropdown overlay={
                                    <FiltersMenu>
                                        {filters.filter(filter => !filter.includes('custom_fields'))
                                            .map((filter, index) => {
                                            return (
                                                <Menu.Item key={`${index}`}>
                                                    <FilterItem key={filter} onClick={handleOpenFilter(filter)} className="no-borders">
                                                        <FilterItemWrapper count={getFilterCounter(filter, values)}>
                                                            <Typography>{filter}</Typography>
                                                            {getFilterCounter(filter, values) > 0 &&
                                                                <ObjectCounter>{getFilterCounter(filter, values)}</ObjectCounter>
                                                            }
                                                        </FilterItemWrapper>
                                                    </FilterItem>
                                                </Menu.Item>
                                            )
                                        })}
                                    </FiltersMenu>
                                }
                                >
                                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <NwButton icon={light("filter")} ghost label="edit filters" />
                                    </div>
                                </Dropdown> */}
                                {(view === "compact" && isCustomSelection(initialValues, values)) &&
                                    <>
                                       <NwDividerVertical />
                                        <NwButton
                                            ghost
                                            icon={solid('undo')}
                                            label='reset'
                                            onClick={handleResetFilterForm(values, setFieldValue)}
                                        />
                                    </>
                                }
                            </FlexContainer>
                            :
                            <>
                                {view !== 'compact' && (filtersType === "all-models" || isCustomSelection(initialValues, values) || (itemSet && !itemSet.fixed && !noActions)) &&
                                    <FilterActions pd='1em 1em .25em 1em'>
                                        {((filtersType === "all-models" && filterApplied) || isCustomSelection(initialValues, values)) &&
                                            <>
                                                <NwButton
                                                    size='small'
                                                    ghost
                                                    icon={solid('undo')}
                                                    label='reset'
                                                    onClick={handleResetFilterForm(values, setFieldValue)}
                                                />
                                               <NwDividerVertical />
                                            </>
                                        }
                                        {itemSet && !itemSet.fixed && !noActions &&
                                            <>
                                                {isCustomSelection(initialValues, values) &&
                                                    <>
                                                        <NwButton
                                                            size='small'
                                                            ghost
                                                            icon={solid('save')}
                                                            onClick={() => {
                                                                setIsAdd(false)
                                                                handleSubmit()
                                                            }}
                                                            label="save"
                                                            loadingLabel="saving..."
                                                        />
                                                       <NwDividerVertical />
                                                    </>
                                                }
                                                {!isCustomSelection(initialValues, values) &&
                                                    <>
                                                        <NwEditButton
                                                            size='small'
                                                            ghost
                                                            onClick={() => {
                                                                setIsAdd(false);
                                                                handleOpenAddListDrawer();
                                                            }}
                                                            label="rename"
                                                            loadingLabel="saving..."
                                                        />
                                                       <NwDividerVertical />
                                                        <NwRemoveButton
                                                            size='small'
                                                            ghost
                                                            onClick={handleDeleteFilterList}
                                                            label="delete"
                                                            loadingLabel="deleting..."
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                        {((filtersType === "all-models" && filterApplied) || (!noActions && isCustomSelection(initialValues, values))) &&
                                            <NwButton
                                                size='small'
                                                icon={light('floppy-disk-circle-arrow-right')}
                                                label="save as..."
                                                ghost
                                                onClick={() => {
                                                    setFieldValue('Name', '');
                                                    setIsAdd(true);
                                                    handleOpenAddListDrawer();
                                                }}
                                            />
                                        }
                                    </FilterActions>
                                }
                                <NwDivider>available filters</NwDivider>
                                <FiltersContainer>
                                    {filters.filter(filter => !filter.Name.includes('custom_fields'))
                                        .map((filter, index) => {
                                            return (
                                                <FilterItem key={`${filter.Value}_${index}`} onClick={handleOpenFilter(filter.Value)}>
                                                    <FilterItemWrapper count={getFilterCounter(filter.Value, values)}>
                                                        <Typography>{filter.Name}</Typography>
                                                        {getFilterCounter(filter.Value, values) > 0 &&
                                                            <ObjectCounter>{getFilterCounter(filter.Value, values)}</ObjectCounter>
                                                        }
                                                    </FilterItemWrapper>
                                                    <NwIcon icon={light('arrow-to-right')} />
                                                </FilterItem>
                                            )
                                        })}
                                </FiltersContainer>
                            </>
                        }

                        {isOpenDrawer &&
                            <>
                                {view === 'compact' ?
                                    <FilterModal
                                        width="96vw"
                                        title="Filters"
                                        open={isOpenDrawer}
                                        footer={null}
                                        onCancel={handleCloseDrawer}
                                    >
                                        <FlexContainer pd='0 0 32px'>
                                            <ModelFilterDrawer
                                                initialValues={values}
                                                selectedFilter={selectedFilter}
                                                customFields={customFields}
                                                touchForm={touchForm}
                                                onTouchForm={setTouchForm}
                                                onApplyFilters={filterValues => handleApplyFilters(filterValues, setValues)}
                                            />
                                        </FlexContainer>
                                    </FilterModal>
                                    :
                                    (isDrawer
                                        ?
                                        <NwDrawer
                                            className="max-width-800"
                                            onClose={handleCloseDrawer}
                                            visible={isOpenDrawer}
                                            zIndex={1100}
                                            title={selectedFilter}
                                            touchForm={touchForm}
                                            onTouchForm={setTouchForm}
                                        >
                                            <ModelFilterDrawer
                                                initialValues={values}
                                                selectedFilter={selectedFilter}
                                                customFields={customFields}
                                                touchForm={touchForm}
                                                onTouchForm={setTouchForm}
                                                onApplyFilters={filterValues => handleApplyFilters(filterValues, setValues)}
                                            />
                                        </NwDrawer>
                                        :
                                        <NwDrawer
                                            className="max-width-800"
                                            width="calc(100vw - 340px)"
                                            fromTop={navigation ? 110 : 60}
                                            fromLeft={(breakpoint < 4 ? 200 : 300) + (leftMargin ? leftMargin : 0)}
                                            placement="left"
                                            onClose={handleCloseDrawer}
                                            visible={isOpenDrawer}
                                            zIndex={5}
                                            title={selectedFilter}
                                            touchForm={touchForm}
                                            onTouchForm={setTouchForm}
                                        >
                                            <ModelFilterDrawer
                                                initialValues={values}
                                                selectedFilter={selectedFilter}
                                                customFields={customFields}
                                                touchForm={touchForm}
                                                onTouchForm={setTouchForm}
                                                onApplyFilters={filterValues => handleApplyFilters(filterValues, setValues)}
                                            />
                                        </NwDrawer>
                                    )
                                }
                            </>
                        }
                        {openAddDrawer &&
                            <FilterSetAddEditDrawer
                                fromTop={navigation ? 95 : 60}
                                fromLeft={(breakpoint < 4 ? 200 : 300) + (leftMargin ? leftMargin : 0)}
                                onClose={handleCloseAddDrawer}
                                isAdd={isAdd}
                                isSubmitting={isSubmitting}
                                values={values}
                                handleSubmit={handleSubmit}
                            />
                        }
                    </NwForm>
                )}
            </Formik>
            {filtersType === "all-models" && <div style={{ margin: "0 auto" }}>
                <NwButton
                    label="quick search"
                    primary
                    onClick={() => {
                        reloadAllModels();
                        onSetQuickFilter({
                            nameFilter: "",
                            intown: "all",
                            onlymine: false,
                            tagFilter: [],
                            showQuickFilters: false,
                            quickFilters: {}
                        })
                        onSetSearchType(false);
                    }}
                />
            </div>}
        </>
    );
};

export default ModelFilterForm;
