import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { ListContext } from "Contexts/ListContext";

const { Option } = Select;

const StyledSelect = styled(Select)`
    width: 25rem;
`;

const SelectTemplateSelector = ({ type, template, style, onTemplateChange }) => {
    const { templates } = useContext(ListContext).cachedList;
    const filteredTemplatesByType = templates.items.filter(template => template.area === type);

    useEffect(() => {
        if (!template) {
            const selectedTemplate = filteredTemplatesByType.find(template => template.isDefault);
            onTemplateChange(selectedTemplate);
        }
    }, [template]);

    const handleChange = id => {
        const selectedTemplate = filteredTemplatesByType.find(template => template.id === id);
        onTemplateChange(selectedTemplate);
    };

    return filteredTemplatesByType.length > 0 ?
            <StyledSelect autoComplete='off' style={style} value={template && template.id} onChange={handleChange}>
                {filteredTemplatesByType.map(template => (
                    <Option value={template.id} key={`template-${type}-${template.id}`}>{template.name}</Option>
                ))}
            </StyledSelect>
        :(    
            <div />
        );
};

export default SelectTemplateSelector;