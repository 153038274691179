import _ from 'lodash';
import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';

const onGetTravelPlanByID = ID => (
    Axios.get(`/travelPlans/${ID}`)
)

const useTravelPlan = (ID) => {
    return useQuery(["travelPlan", ID], () => onGetTravelPlanByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const getTravelPlanPreviewByID = ID => (
    Axios.get(`/travelPlans/Preview/${ID}`)
);

const usePreviewTravelPlan = (ID) => {
    return useQuery(["travelPlanPreview", ID], () => getTravelPlanPreviewByID(ID), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: ID ? true : false
    });
}

const forceReloadTravelPlan = (ID) => {
    queryClient.invalidateQueries(['travelPlan', ID])
}

const onGetOrderedEventList = (currentTravelPlan) => {
    let orderedEventList = [];
    if (currentTravelPlan) {
        let travels = currentTravelPlan.Travels.map((item) => ({...item, sortField: item.StartDateTime, elType:'Travel', TypeName: "Travel"}));
        let meetings = currentTravelPlan.Meetings.map((item) => ({...item, sortField: item.StartDate, elType:'Meeting', TypeName: "Meeting"}));
        let accommodations = currentTravelPlan.Accommodations.map((item) => ({...item, sortField: item.FromDate, elType:'ScoutingAccommodation', TypeName: "Accommodation"}));
        let result = [].concat(travels, meetings, accommodations);
        orderedEventList = _.sortBy(result, ['sortField']);
    } 
    return orderedEventList
};

const onUpdateTravelPlan = ({ travelPlanId, data }) => (
    Axios.put(`/travelPlans/${travelPlanId}`, data)
);

const useUpdateTravelPlan = () => {
    return useMutation(onUpdateTravelPlan, {
        onSuccess: (data, variables) => {
            forceReloadTravelPlan(variables.travelPlanId);
        }
    })
}

const onDeleteTravelPlan = ({ travelPlanId }) => (
    Axios.delete(`/travelPlans/delete/${travelPlanId}`)
);

const useDeleteTravelPlan = () => {
    return useMutation(onDeleteTravelPlan, {
        onSuccess: (data, variables) => {}
    })
};

const onAddTravelPlan = ({ data }) => (
    Axios.post("/travelPlans", data)
);

const useAddTravelPlan = () => {
    return useMutation(onAddTravelPlan, {
        onSuccess: (data, variables) => {
        }
    })
};

// const TRANSACTION_TYPES = [
//     {
//         Type: 'Hourly Rate',
//         TypeValue: 10
//     },
//     {
//         Type: 'DailyRate',
//         TypeValue: 20
//     },
//     {
//         Type: 'Royalty',
//         TypeValue: 30
//     },
//     {
//         Type: 'Expense',
//         TypeValue: 40
//     }
// ];

// const onGetTransActionType = typeValue => {
//     const transaction = TRANSACTION_TYPES.find(type => type.TypeValue === typeValue);
//     if (transaction) {
//         return transaction.Type;
//     }
//     return '';
// };

const onSearchTravelPlans = filters => (
    Axios.post('/travelPlans/search', filters)
);

const onFullSearchTravelPlans = filters => (
    Axios.post('/travelPlans/FullSearch', filters)
);

const useFullSearchTravelPlans = filters => {
    return useQuery(['travelPlan-FullSearch'], () => onFullSearchTravelPlans(filters), {
        enabled: filters ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

const refetchFullSearch = async (filters) => {
    try {
        await queryClient.fetchQuery(['travelPlan-FullSearch'], () => onFullSearchTravelPlans(filters));
    } catch (error) {
        console.log(error)
    }
};

const onUpdateTravelPlanCustomFields = ({ travelPlanId, data }) => (
    Axios.put(`/travelPlans/Customfields/${travelPlanId}`, data)
);

const useUpdateTravelPlanCustomFields = () => {
    return useMutation(onUpdateTravelPlanCustomFields, {
        onSuccess: (data, variables) => {
            forceReloadTravelPlan(variables.travelPlanId);
        }
    })
}

export {
    useTravelPlan,
    usePreviewTravelPlan,
    useUpdateTravelPlan,
    useAddTravelPlan,
    useDeleteTravelPlan,
    forceReloadTravelPlan,
    onGetOrderedEventList,
    //onGetTransActionType,
    //TRANSACTION_TYPES,
    onSearchTravelPlans,
    onFullSearchTravelPlans,
    useUpdateTravelPlanCustomFields,
    useFullSearchTravelPlans,
    refetchFullSearch,
};


