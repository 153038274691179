import React, { useState } from "react";
import styled from "styled-components";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useIsContactConnectionItemDefault, useEditContactConnectionItemDefault } from 'Hooks/Contact/UseContactConnections';
import { InMailingLists, MailLine } from "Components/Gui/NwContactInfo";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import FlexContainer from 'Components/Gui/FlexContainer';

const DefaultItem = styled.div`
    font-weight: ${props => props.isDefault && 'bold'};
`;

const EmailItem = ({ email, readOnly, noMainIcon, onEdit, onDelete, contactID, onAddToMailingList }) => {
    const [selectedEmail, setSelectedEmail] = useState();
   

    const { mutate: onSetItemDefault } = useEditContactConnectionItemDefault()

    const setDefault = () => {
        onSetItemDefault({itemType: "email", idItem: email.ID, contactID: contactID})
    }

    const handleAddToMailingList = () => {
        onAddToMailingList(email)
    };

    const isDefault = useIsContactConnectionItemDefault("email", email.ID, contactID);
    
    let actions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: onEdit},
        { name: 'set as default', key: "default", icon: light("bookmark"), action: setDefault },
        { name: "add to mailing list", key: "mail", icon: solid('plus-circle'), action: handleAddToMailingList },
        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: onDelete}
    ];

    actions = actions.filter(action => !(action.key === 'default' && isDefault));

    return (
        <FlexContainer justifyContent='space-between'>
            <DefaultItem isDefault={isDefault}>
                <MailLine mailAddress={email.Address} mailCC={email.CC} />
                <InMailingLists mailingLists={email.MailingLists} onAddToMailingList={handleAddToMailingList} />
            </DefaultItem>
            {!readOnly && 
                <NwDropDownMenu
                    size='small'
                    actions={actions}
                />
            }
        </FlexContainer>
    );
};

export default EmailItem;
