import _ from "lodash";
import ls from "local-storage";
import Axios from 'axios';

const saveInvoicesSmartList = async customs => {
  try {
      await Axios.post('/userclientconfigurations', {
      Name: 'invoice-smart-list',
      JsonData: JSON.stringify({
          smartList: customs
      })
    });
  } catch (error) {
    console.log('ant : Save Invoice Smart List Error => ', error);
  }
};

const InvoiceFiltersReducer = (state, action) => {
  switch (action.type) {
    case "InvoiceFiltersChangeActiveSet":
      ls.set("nw_config_invoicewallfilter", {
          ...state,
          activeSet: action.newActiveSet,
          filterType: action.filterType
      });
      return {
        ...state,
        activeSet: action.newActiveSet,
        filterType: action.filterType
      };
    case "InvoiceFiltersAddCustomSet":
      const customSetCopy = [...state.customSets];
      customSetCopy.push(action.newSet);
      const data = {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      }
      ls.set("nw_config_invoicewallfilter", data);

      let smartList = [...data.customSets];

      saveInvoicesSmartList(smartList);
      return {
        ...state,
        customSets: customSetCopy,
        activeSet: action.newSet.id
      };
    case "InvoiceFiltersEditCustomSet":
      let customSetCopy2 = state.customSets.filter(
        el => el.id !== action.newSet.id
      );
      action.newSet.id = Date.now();
      customSetCopy2.push(action.newSet);
      const editData = {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };

      ls.set("nw_config_invoicewallfilter", editData);

      let editSmartList = editData.customSets

      saveInvoicesSmartList(editSmartList);
      return {
        ...state,
        customSets: customSetCopy2,
        activeSet: action.newSet.id
      };
    case "InvoiceFiltersCloneSet":
      const copyOfActive = _.cloneDeep(action.activeSet);
      copyOfActive.id = Date.now();
      copyOfActive.fixed = false;
      copyOfActive.name = copyOfActive.name + "(copy)";
      const copyOfCustomSets = [...state.customSets];
      copyOfCustomSets.push(copyOfActive);

      const cloneData = {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };

      ls.set("nw_config_invoicewallfilter", cloneData);
      
      let cloneSmartList = [...cloneData.customSets];

      saveInvoicesSmartList(cloneSmartList);
      return {
        ...state,
        customSets: copyOfCustomSets,
        activeSet: copyOfActive.id
      };
    case "InvoiceFiltersDeleteSet":
      const newCustomSets = [...state.customSets].filter(
        el => el.id !== action.activeSet
      );
      const deleteData = {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v0'
      };

      ls.set("nw_config_invoicewallfilter", deleteData);
      
      let deleteSmartList = [...deleteData.customSets];

      saveInvoicesSmartList(deleteSmartList);
      return {
        ...state,
        customSets: newCustomSets,
        activeSet: 'v0'
      };
    case "InvoiceSaveCustomSets":
        let newFilterData = { ...state, customSets: action.customSets };

        ls.set("nw_config_invoicewallfilter", newFilterData);
        
        return newFilterData;
    default:
      //console.log("default del reducer")
      return state;
  }
};

export default InvoiceFiltersReducer;
