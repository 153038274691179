import /*React,*/ { useEffect, useState } from 'react'

const FormikObserver = props => {
    const [defaultValues] = useState(props.values);
    useEffect(() => {
        let compareDefaultValues = {...defaultValues};
        let compareCurrentValues = {...props.values};
        if (props.disabledFields) {
            for (const fieldName of props.disabledFields) {
                compareCurrentValues = {
                    ...compareCurrentValues,
                    [fieldName]: null
                };
                compareDefaultValues = {
                    ...compareDefaultValues,
                    [fieldName]: null
                }
            }
        }
        if (JSON.stringify(compareCurrentValues) !== JSON.stringify(compareDefaultValues)) {
            props.onChange(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, JSON.stringify(props.values), props.disabledFields])
    return null;
};

export default FormikObserver;