import React from 'react';
import {Select} from 'antd';

import constants from 'Constants';
const Option = Select.Option;

const SelectTransport = ({ allTransport, value, onChange }) => {
    return (
        <Select
            autoComplete='off'
            showSearch={false}
            value={value}
            style={{ display: 'block' }}
            placeholder="Select a Transport"
            allowClear={false}
            filterOption={false}
            onChange={onChange}
        >
            {allTransport && <Option value={null} key={'all-transport'}>All</Option>}
            {constants.TRANSPORTS.map(data => {
                return (
                    <Option value={data} key={data} >{data}</Option>
                );
            })}
        </Select>
    );

}
export default SelectTransport;
