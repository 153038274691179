import React, { useState, useEffect, useMemo } from 'react';
import { List, Image, Rate } from 'antd';
import Axios from 'axios';
import Slider from "react-slick";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import { NwAddButton, /*NwButton*/ } from 'Components/Gui/NwButton';
import { EventTitleText } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import NwUpload from "Components/Gui/NwUpload";
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';
import AddMeetingModelDrawer from 'Components/EventDrawer/ScoutingMeetingsEvent/AddMeetingModelDrawer';
import CountryLabel from 'Components/Gui/CountryLabel';
import { StyledRateContainer } from 'Components/Gui/NWForm/Rating';
import { getModel } from 'Hooks/Contact/UseContactsList';
import useAreaAccentColor from 'Hooks/UseAreaAccentColor';
import { useRating } from 'Hooks/Rating/UseRating';
import { getAge } from 'Libs/NwMoment';
import { getThumbnailHeight, getThumbnailWidth } from "Libs/NwUtils";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';

const SliderContainer = styled.div`
    margin: 1rem 2rem;
    width: calc(100% - 60px);

    .slick-prev {
      left: -30px;
    }

    .slick-next {
      right: -30px;
    }
    
    .slick-prev:before, .slick-next:before  {
        content: '' !important;
    }

    .slick-slide {
        margin-right:  2px;
    }
`;

const ModelName = styled.div`
    font-weight: bold;
`;

const ArrowIcon = styled.div`
    margin-top: -6px;
`;

const UploadContainer = styled(FlexContainer)`
    border-top: ${props => props.theme.DefaultBorder};
    border-bottom: ${props => props.theme.DefaultBorder};
    padding: 8px 0;
`;

const ListItem = styled(List.Item)`
    border-bottom: none !important;
`;

const OpenIconStyle = {
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: StyleVariables.TextColor
};

const NextArrow = ({ className, onClick }) => {
    return (
        <ArrowIcon
            className={className}
            onClick={onClick}>
            <NwIcon onClick={onClick} icon={light("chevron-right")} style={OpenIconStyle} />
        </ArrowIcon>
    );
};

const PrevArrow = ({ className, onClick }) => {
    return (
        <ArrowIcon
            className={className}
            onClick={onClick}>
            <NwIcon icon={light("chevron-left")} style={OpenIconStyle} />
        </ArrowIcon>
    );
}

const thumbheight = 50;

const ModelViewItem = ({ model, meetingId }) => {
    const ratingColor = useAreaAccentColor();
    const [pictures, setModelPictures] = useState([]);
    const [isStartUploadPictures, setStartUploadPictures] = useState(false);
    const [isModelViewDrawer, setIsModelViewDrawer] = useState(false);
    const { data: rating } = useRating(model.ID, 'Contact');

    const fetchModelPictures = async () => {
        try {
            const modelPictures = await Axios.get(`/documents/GetByModelView/${meetingId}/${model.ID}`);
            setModelPictures(modelPictures);
        } catch (error) {
            console.log('ant : Fetch Model Pictures Error => ', error);
        }
    };
    useEffect(() => {
        fetchModelPictures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertObjects = pictures => {
        return pictures.map(function (pic) {
            return {
                id: pic.ID,
                src: pic.Standard.Url,
                thumbnail: pic.Thumbnail.Url,
                thumbnailWidth: getThumbnailWidth(pic, thumbheight),
                thumbnailHeight: thumbheight,
                ...pic
            };
        });
    };

    const handleViewModel = () => {
        setIsModelViewDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setIsModelViewDrawer(false);
    };

    const picturesToRender = useMemo(() => {
        return convertObjects(pictures);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pictures]);

    const settings = {
        className: "slider variable-width",
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        centerPadding: 30,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <>
            <FlexContainer column alignItems='flex-start' fullWidth>
                <FlexContainer justifyContent='space-between' fullWidth>
                    <ModelName onClick={handleViewModel}>{`${model.N} ${model.S}`}</ModelName>
                    <FlexContainer>
                        <NwDropDownMenu
                            actions={[
                                { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => console.log('edit') },
                                { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => alert('coming soon')) }
                            ]}
                        />
                    </FlexContainer>
                </FlexContainer>
                <div onClick={handleViewModel}>
                    {model.archived ? <div>Archived</div>
                        :
                        <FlexContainer mg='0 16px 0 0'>
                            {model.B ? `${getAge(model.B)} years old, ` : 'n.d, '}
                            {model.NAT ? <CountryLabel useCode code={model.NAT} /> : 'n.d'}
                        </FlexContainer>
                    }
                    <StyledRateContainer ratingColor={ratingColor}>
                        <Rate
                            disabled
                            style={{ fontSize: "16px" }}
                            value={rating ? rating.averageRating : 0}
                        />
                    </StyledRateContainer>
                </div>
                <SliderContainer>
                    <Slider {...settings}>
                        {picturesToRender.map(picture => (
                            <Image key={picture.id} height={picture.thumbnailHeight} width={picture.thumbnailWidth} src={picture.src} />
                        ))}
                    </Slider>
                </SliderContainer>
                <UploadContainer fullWidth justifyContent='flex-end'>
                    <NwUpload
                        ghost
                        label='add picture'
                        maxSize={50}
                        showList={false}
                        disable={isStartUploadPictures}
                        types={["image"]}
                        endpoint={`documents/uploadsingle/2?contactID=${model.ID}&scoutingMeetingID=${meetingId}`}
                        onUploadStart={(uploadInfo) => {
                            setStartUploadPictures(true);
                        }}
                        onUploadEnd={() => {
                            setStartUploadPictures(false);
                            fetchModelPictures();
                        }}
                    />
                </UploadContainer>
            </FlexContainer>
            {isModelViewDrawer &&
                <ModelDrawer
                    modelId={model.ID}
                    hideCalendar
                    showRating
                    scoutingModel
                    meetingImages={pictures}
                    onClose={handleCloseModelDrawer} />
            }
        </>
    );
};

const ScoutingMeetingModels = ({ meetingId, travelPlanId, modelViews }) => {
    const [isAddModelDrawer, setIsAddModelDrawer] = useState(false);

    const handleAddModel = () => {
        setIsAddModelDrawer(true);
    };

    const handleCloseAddModelDrawer = () => {
        setIsAddModelDrawer(false);
    };

    const selectedModels = modelViews ? modelViews.map(modelView => modelView.ModelID) : [];

    return (
        <>
            <FlexContainer justifyContent='space-between' alignItems='flex-start'>
                <EventTitleText>Models</EventTitleText>
                <NwAddButton ghost onClick={handleAddModel} />
            </FlexContainer>
            <List
                itemLayout="horizontal"
                dataSource={modelViews ? modelViews : []}
                renderItem={modelView => {
                    const modelObject = getModel(modelView.ModelID);
                    return (
                        <ListItem className="ant-list-item-top">
                            <ModelViewItem
                                model={modelObject}
                                meetingId={meetingId} />
                        </ListItem>
                    )
                }
                }
            />
            {isAddModelDrawer &&
                <AddMeetingModelDrawer
                    selectedModels={selectedModels}
                    travelPlanId={travelPlanId}
                    meetingId={meetingId}
                    onClose={handleCloseAddModelDrawer} />
            }
        </>
    )
};

export default ScoutingMeetingModels;