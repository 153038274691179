import React, { useEffect } from "react";
import { Select, InputNumber, Checkbox } from "antd";
import { CURRENCIES } from "Configs/CURRENCIES";
import useConfigurations from "Hooks/UseConfigurations";

const Option = Select.Option;

const CurrencyRange = ({ onChange, value, disabled, taxed }) => {
    const { Currency, FromAmount, ToAmount } = value;
    const configuration = useConfigurations();

    useEffect(() => {
        if (!Currency) {
            onChange({
                ...value,
                Currency: configuration.defaultCurrency
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Currency]);

    const onCurrencyChange = (val) => {
        onChange({
            ...value,
            Currency: val
        });
    };

    const onFromAmountChange = (val) => {
        //let _toAmount = val;
        //if (ToAmount !== null) _toAmount = val > ToAmount? val : ToAmount;
        onChange({
            ...value,
            FromAmount: val ? val : null,
        //    ToAmount: _toAmount ? _toAmount : null
        });
    }

    const onToAmountChange = (val) => {
        //let _fromAmount = FromAmount;
        //if (FromAmount !== null) _fromAmount = val < FromAmount? val : FromAmount;
        onChange({
            ...value,
        //    FromAmount: _fromAmount ? _fromAmount : null,
            ToAmount: val ? val : null
        });
    }

    const onTaxedChange = () => {
        onChange({
            ...value,
            Taxed: !value.Taxed,
        });
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <Select
                autoComplete='off'
                value={Currency}
                placeholder="Please select"
                onChange={(val) => onCurrencyChange(val)}
                disabled={disabled}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                style={{ width: "90px"}}
            >
                {CURRENCIES.map(el=> <Option key={el.code} value={el.code}>{el.code}</Option>)}
            </Select>
            <InputNumber
                autoComplete='off'
                style={{ width: "90px", textAlign: "center", marginLeft: "10px"}}
                disabled={disabled}
                min={0}
                value={FromAmount === null ? 0 : FromAmount}
                onChange={(val) => onFromAmountChange(val)}
            />
            <div style={{margin: "auto 5px"}}>and</div>
            <InputNumber
                autoComplete='off'
                style={{ width: "90px", textAlign: "center"}}
                disabled={disabled}
                min={0}
                value={ToAmount === null ? 0 : ToAmount}
                onChange={(val) => onToAmountChange(val)}
            />
            {taxed &&
                <Checkbox
                    style={{marginLeft: "10px"}}
                    checked={value.Taxed}
                    onChange={() => onTaxedChange()}
                >Taxed</Checkbox>
            }
        </div>
    );
};

export default CurrencyRange;
