import React, { useState, useMemo } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ContactConnectionDrawer from "Components/Connection/ContactConnectionDrawer";
import { PhoneLine, MailLine } from "Components/Gui/NwContactInfo";
import { useUserPermission } from "Contexts/UserContext";
import PermissionBlock from "Components/PermissionBlock";
import FlexContainer from "Components/Gui/FlexContainer";
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import NwIcon from "Components/Gui/NwIcon";


const ContactDetailsHeaderConnections = ({ contact, readOnly, collapseView }) => {
    const [drawer, setDrawer] = useState(false);

    const viewClientConnections = useUserPermission('ViewClientConnections', contact && contact.Users.map(user => user.UserID));
    const viewAgencyConnections = useUserPermission('ViewAgencyConnections', contact && contact.Users.map(user => user.UserID));
    const viewServiceConnections = useUserPermission('ViewServiceConnections', contact && contact.Users.map(user => user.UserID));
    const viewModelConnections = useUserPermission('ViewModelConnections', contact && contact.Users.map(user => user.UserID));

    const viewConnections = useMemo(() => {
        if (contact) {
            if (contact.SubType === 1) return viewClientConnections;
            if (contact.SubType === 2) return viewAgencyConnections;
            if (contact.SubType === 3) return viewServiceConnections;
            return viewModelConnections;
        }
        return false;
    }, [contact, viewClientConnections, viewAgencyConnections, viewServiceConnections, viewModelConnections]);
    
    const ConnectionsContent = () => {
        return (
            collapseView ?
                <PermissionBlock
                    permission={viewConnections}
                    content={
                        <FlexContainer fullWidth={readOnly} justifyContent={readOnly && 'space-between'}>
                            <PhoneLine 
                                phoneNumber={(contact.DefaultConnection && contact.DefaultConnection.Mobile) ? contact.DefaultConnection.Mobile : null} 
                                phoneType="Mobile" onClick={() => viewConnections && setDrawer(true)} />
                            <MailLine 
                                mailAddress={(contact.DefaultConnection && contact.DefaultConnection.Email) ? contact.DefaultConnection.Email : null} 
                                onClick={() => viewConnections && setDrawer(true)} />
                        </FlexContainer>
                    } />
            : <div className="header-body-contacts-container">
                <PermissionBlock
                    permission={viewConnections}
                    content={
                        <div className="header-body-contacts-box">
                            <div className="header-body-contacts-lines-container">
                                {(contact.DefaultConnection && contact.DefaultConnection.Phone && !contact.DefaultConnection.Mobile) &&
                                    <PhoneLine noIcon phoneNumber={contact.DefaultConnection.Phone} phoneType="Land" />
                                }
                                <PhoneLine noIcon phoneNumber={(contact.DefaultConnection && contact.DefaultConnection.Mobile) ? contact.DefaultConnection.Mobile : null} phoneType="Mobile" />
                                <MailLine groupCC noIcon mailAddress={(contact.DefaultConnection && contact.DefaultConnection.Email) ? contact.DefaultConnection.Email : null} mailCC={(contact.DefaultConnection && contact.DefaultConnection.CC) ? contact.DefaultConnection.CC : null} />
                            </div>
                           <NwDividerVertical  style={{height: "2rem"}} />
                            <NwIcon
                                pointer
                                onClick={() => viewConnections && setDrawer(true)} 
                                icon={light("address-book")}
                                size="1x"
                            />
                        </div>
                    } />
            </div>
        )
    }

    return (
        <>
            {ConnectionsContent()}
            {drawer && <ContactConnectionDrawer contactSubType={contact.SubType} contactId={contact.ID} readOnly={readOnly} onClose={() => setDrawer(false)} />}
        </>
    )
}

export default ContactDetailsHeaderConnections;
