import React from "react";
import styled from 'styled-components';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { Switch } from "antd";
import { showDate } from "Libs/NwMoment";

const SelectIdsContainer = styled.div`
  .date-text {
    font-weight: 700;
  }
  ul.event-models {
    list-style: none;
    padding-left: 0px;
    column-count: 2;
    > li {
      margin-top: 10px;
      align-items: center;
      display: inline-block;
      .li-content {
        display: flex;
      }
      .event-model-name {
        margin-left: 20px;
      }
    }
    border-bottom: ${props => props.theme.DefaultBorder};
    padding-bottom: 20px;
  }
`;

const Container = styled.div`
  padding-top: 20px;
`;

const SelectModelsIds = ({ form, eventDates, onlyOneDay, modelProperty, onChange }) => {

  const onToggleSelectedModel = (modelId) => {
    if (form.values.modelIds.includes(modelId)) {
      const models = form.values.modelIds.filter(id => id !== modelId);
      form.setFieldValue('modelIds', [...models]);
      if (onChange) {
        onChange(models)
      }
      return;
    }
    const models = [...form.values.modelIds, modelId];
    form.setFieldValue('modelIds', models);
    if (onChange) {
        onChange(models);
    }
  }

  const checkModelSelected = (modelId) => {
    if (form.values.modelIds.find((id) => id === modelId)) {
      return true
    }
    return false;
  }

  eventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);


  if (onlyOneDay && eventDates[0] && eventDates[0].EventDatesModels && eventDates[0].EventDatesModels.length === 0) {
    return null;
  }

  return (
    <Container>
      {
        eventDates.map(eventDate => (
          <SelectIdsContainer key={eventDate.ID}>
            {
              !onlyOneDay && (
                <div className="date-text">{showDate(eventDate.FromDate, "ext-notime")}</div>
              )
            }
            <ul className="event-models">
              {
                eventDate.EventDatesModels.map(eventModel => (
                  <li key={eventModel.ID} className="event-model">
                    <div className="li-content">
                      <Switch checked={checkModelSelected(eventModel[modelProperty])} onChange={() => onToggleSelectedModel(eventModel[modelProperty])} />
                      <div className="event-model-name" onClick={() => onToggleSelectedModel(eventModel[modelProperty])}>
                        {getContactName(eventModel.ModelID)}
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </SelectIdsContainer>
        ))
      }
    </Container>
  );
};

export default SelectModelsIds;
