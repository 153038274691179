import React from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import { showDateNoTimeExtended } from 'Libs/NwMoment';
import Visibilities from 'Components/Gui/Visibilities';
import { EventDetailsWrapper } from '../EventDrawerUi/EventUI';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';
import { NwTag } from 'Components/Gui/NwTag';
import { taskHasDifferentDescriptions } from './TaskUtils';

const TaskDetails = ({ taskData }) => {

    const columns = []
    columns.push({
        content: (
            <div className='is-icon'>
                <NwIcon icon={light('tasks')} />
            </div>
        ),
        isBorder: true
    })
    if (taskData.Date) {
        columns.push({
            content: (
                <div>
                    {(taskData.PrivacyLevelName !== "Public") &&
                       <div style={{marginBottom:".5rem"}}><NwTag type="primary">Personal</NwTag></div>
                    }
                    {taskData.RemindDate &&
                        <>
                            <div className='is-label'>don't show before</div>
                            <div className='is-content'>{showDateNoTimeExtended(taskData.RemindDate)}</div>
                        </>
                    }
                    {taskData.Date &&
                    <>
                        <div className='is-label'>due date</div>
                        <div className='is-content'>{showDateNoTimeExtended(taskData.Date)}</div>
                    </>
                    }
                </div>
            ),
            isBorder: false
        })
    }
    

    return (
        <EventDetailsWrapper>
            <ResponsiveFlexContainer
                leftMinWidth={140}
                columns={columns}
            />
            {!taskHasDifferentDescriptions(taskData) &&
                <ResponsiveFlexContainer
                    leftMinWidth={140}
                    columns={[
                        {
                            content: (
                                <div className='is-label'>DESCRIPTION</div>
                            ),
                            isBorder: true
                        },
                        {
                            content: (
                                <div className='is-content'>{taskData.Description}</div>
                            )
                        }
                    ]}
                />
            }
           <ResponsiveFlexContainer
                leftMinWidth={140}
                columns={[
                    {
                        content: (
                            <div className='is-label'>Visibility Areas</div>
                        ),
                        isBorder: true
                    },
                    {
                        content: (
                            <Visibilities hideLabel visibilities={taskData.Visibility.Areas} />
                        )
                    }
                ]}
            />
        </EventDetailsWrapper>
    );
};

export default TaskDetails;