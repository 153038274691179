import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
// import Axios from 'axios';

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NWInput, NWColorSelector, NWRadioButtonGroup } from "Components/Gui/NWForm/NwFormItems";
import { useStateValue } from "Contexts/StateProvider";
import {GetTimestampNumeric} from "Libs/NwMoment";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';

const CreateEditForm = ({ items, folder, type, onClose, onTouchForm }) => {
    useFormFieldFocus('Name');
    const [{ areaContext }, dispatch] = useStateValue();

    const initialValues = {
        Name: '',
        Color: 'pink',
        Type: type || 'model'
    };

    if (folder) {
        initialValues.Name = folder.title;
        initialValues.Color = folder.color;
    }

    let validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Color: Yup.string().required("Color is required"),
    });

    const submitForm = async (values) => {
        try {    
            if (folder) {
                await dispatch({
                    type: 'editFolder',
                    folder: {
                        folderID: folder.ID,
                        folderName: values.Name,
                        color: values.Color,
                        type: values.Type
                    }
                });
                onTouchForm(false);
                onClose();
            } else {
                const id = GetTimestampNumeric();
                await dispatch({
                    type: 'createFolder',
                    newFolder: {
                        ID: id,
                        title: values.Name,
                        color: values.Color,
                        type: values.Type,
                        items: items?items:[]
                    }
                });
                dispatch({
                    type: 'ModelFiltersChangeActiveSet',
                    area: areaContext.area,
                    newActiveSet: id,
                    filterType: "FOLDER"
                });
                onTouchForm(false);
                onClose();
            }
        } catch (error) {
            console.log('ant : Update Folder Error => ', error);
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setStatus, setSubmitting,resetForm }) => {
                    setStatus();
                    submitForm(values, setStatus, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        {/* Name */}
                        <Field
                            component={NWInput}
                            label="Name"
                            name="Name"
                            type="text"
                            required
                            id='Name'
                            value={values.Name}
                        />
                        <Field
                            component={NWRadioButtonGroup}
                            name="Type"
                            disabled={folder ? true : false}
                            value={values.Type}
                            buttonStyle="solid"
                            options={[
                                { key: 'model', value: 'model', label: 'Model' },
                                { key: 'customer', value: 'customer', label: 'Customer' }
                            ]}
                        />
                        <Field
                            component={NWColorSelector}
                            label="Color"
                            name="Color"
                            required
                            value={values.Color}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </>
    );
};

export default CreateEditForm;
