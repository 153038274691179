import React, { useState, useEffect } from "react";
import { Input, Row, Col, Select } from "antd";
import styled from "styled-components";
import {
    completeURL,
    removeAfterFWDSlash,
    removeAfterQuestionMark,
    trimAt,
    isWebsite,
    determineSocialMedia,
    //getSplittedUrl
} from "Components/Social/SocialUtil";
import { useAddContactSocial, useEditContactSocial } from 'Hooks/Contact/UseContactSocials';
import SocialIcon from "./SocialIcon";
import { socialTypes } from "Constants/Socials";
import { Field, Formik } from "formik";
import { NwForm, NwFormButtonsBar } from "Components/Gui/NWForm/NwFormWrapper";
import { NWInput, NWInputNumber } from "Components/Gui/NWForm/NwFormItems";
import { NwButton, NwCancelButton, NwLinkButton, NwSaveButton } from "Components/Gui/NwButton";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import isUrlHttp from "is-url-http";


const SocialFormContainer = styled.div`
  
  .social-types {
    .social-type {

    }
  }
  
  .social-textbox {
    padding-top: 1rem;
    
    
    .ant-input-group-addon {
      background-color: ${props => props.theme.LighterBorderColor};
    }
    
    .social-link-test {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.15rem;
      padding-bottom: 4rem;
    }
  }

`



const SocialForm = ({ contactID, selectedSocial, onClose }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activeSocialType, setActiveSocialType] = useState("")
    //const [social, setSocial] = useState("")
    const [assembledUrl, setAssembledUrl] = useState("")
    const [addOnBefore, setAddOnBefore] = useState()
    const [addOnAfter, setAddOnAfter] = useState()
    const [inputValue, setInputValue] = useState("")
    const [touchForm, setTouchForm] = useState(false);

    const { mutateAsync: editContactSocial } = useEditContactSocial()
    const { mutateAsync: addContactSocial } = useAddContactSocial()

    useFormFieldFocus('SocialTextField');

    useEffect(() => {
        if (selectedSocial) {
            setInputValue(selectedSocial.Url)
            const socialType = socialTypes.find((s) => s.code === selectedSocial.Type)
            setActiveSocialType(socialType)
            setAssembledUrl(completeURL(socialType, selectedSocial.Url))
        } else {
            setActiveSocialType()
            setAssembledUrl("")
        }
    }, [selectedSocial]);

    useEffect(() => {
        if (activeSocialType) {
            setAssembledUrl(completeURL(activeSocialType, inputValue))
        } else {
            setAssembledUrl(inputValue)
        }
        setAddOns()
    }, [activeSocialType, inputValue]);

    const isValidUrl = urlString => {
        return isUrlHttp(urlString)
    }


    const clearActiveSocialType = () => {
        setActiveSocialType()
        //setAssembledUrl(inputValue)
    }

    const setAddOns = () => {
        if (activeSocialType) {
            setAddOnBefore(
                <>
                    {!selectedSocial &&
                        <>
                            <NwIcon icon={solid("circle-xmark")} onClick={clearActiveSocialType} />
                            &nbsp;
                        </>
                    }
                    {activeSocialType.getUrl("")}
                </>
            )
        } else {
            setAddOnBefore()
        }
        // var splittedUrl = getSplittedUrl(social)
        // if (splittedUrl.length > 0) {
        //   setAddOnBefore(getSplittedUrl(social)[0])
        // } else {
        //   setAddOnBefore("")
        // }
        // if (splittedUrl.length > 1) {
        //   setAddOnAfter(getSplittedUrl(social)[1])
        // } else {
        //   setAddOnAfter("")
        // }
    }

    const handleClickSocial = (clickedSocial, setFieldValue, values) => {
        //const value = values.SocialText
        setActiveSocialType(clickedSocial)
        document.querySelector("#SocialTextField").focus()

        // if (value.length > 0) {
        //     let temp = value
        //     temp = trimAt(temp)
        //     temp = removeAfterFWDSlash(temp)
        //     temp = removeAfterQuestionMark(temp)
        // }
    };

    const onChange = (value, setFieldValue) => {
        if (value === "") {
            setInputValue("")
        } else {
            if (isWebsite(value)) {
                const obj = determineSocialMedia(value)
                if (obj.social) {
                    const socialType = socialTypes.find((s) => s.code === obj.social)
                    //console.log("socialType", socialType)
                    if (socialType) {
                        setActiveSocialType(socialType)
                        setFieldValue("SocialText", obj.input)
                        setInputValue(obj.input)
                    } else {
                        setInputValue(value)
                    }
                } else {
                    setInputValue(value)
                }
            } else {
                let temp = trimAt(value)
                temp = removeAfterFWDSlash(temp)
                temp = removeAfterQuestionMark(temp)
                setFieldValue("SocialText", temp)
                setInputValue(temp)
            }
        }
    };

    const afterSave = () => {
        setActiveSocialType()
        setIsSubmitting(false)
        onClose()
    };

    const saveSocial = async (values) => {
        // Fare un controllo preliminare sui dati
        let username = encodeURI(values.SocialText.trim())
        if (username) {
            if (!selectedSocial) {
                let socialTypeCode = "Other"
                if (activeSocialType) {
                    socialTypeCode = activeSocialType.code
                } else {
                    if (username.indexOf("http") !== 0) {
                        username = "https://" + username
                    }
                }
                setIsSubmitting(true);
                const data = {
                    Type: socialTypeCode,
                    Url: username,
                    ContactID: contactID,
                    Followers: values.Followers
                };
                try {
                    await addContactSocial({ data: data, ContactID: contactID })
                    afterSave();
                    setIsSubmitting(false);
                } catch (error) {
                    console.log('ant : Add Social Error => ', error);
                    setIsSubmitting(false);
                }
            } else {
                if (selectedSocial.Url === username && selectedSocial.Followers === values.Followers) {
                    afterSave();
                } else {
                    const data = {
                        ID: selectedSocial.ID,
                        Type: activeSocialType.code,
                        Url: username,
                        ContactID: contactID,
                        Followers: values.Followers
                    };
                    setIsSubmitting(true);
                    try {
                        await editContactSocial({ data: data, ContactID: contactID })
                        afterSave();
                        setIsSubmitting(false);
                    } catch (error) {
                        console.log('ant : Update Social Error => ', error);
                        setIsSubmitting(false);
                    }
                }
            }
        }
    };

    const initialValues = {
        SocialText: selectedSocial ? selectedSocial.Url : "",
        Followers: selectedSocial ? selectedSocial.Followers : 0
    };

    const onFormSubmit = (values, setSubmitting) => {
        saveSocial(values)
        setSubmitting(false)
    }

    const navigateToSocial = () => {
        let urltoOpen = assembledUrl
        if (urltoOpen.indexOf("http") !== 0) {
            urltoOpen = "https://" + urltoOpen
        }
        window.open(urltoOpen, '_blank');
    }

    return (
        <SocialFormContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    onFormSubmit(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit, touched, isValid, errors }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Row>
                            {!selectedSocial &&
                                <Col span={8}>
                                    <div className="social-types">
                                        {socialTypes.map(item => (
                                            <SocialIcon
                                                key={item.code}
                                                social={item}
                                                active={activeSocialType && (activeSocialType.code === item.code)}
                                                onClick={handleClickSocial}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                size="small"
                                                showName
                                            />
                                        ))}
                                    </div>
                                </Col>
                            }
                            <Col span={selectedSocial ? 24 : 16}>
                                <div className="social-textbox">
                                    <Field
                                        component={NWInput}
                                        addonBefore={addOnBefore}
                                        addonAfter={addOnAfter}
                                        label=""
                                        name="SocialText"
                                        type="text"
                                        style={{ width: "100%" }}
                                        value={values.SocialText}
                                        onAfterChange={(value) => onChange(value, setFieldValue)}
                                    />
                                    {/* <NWInput
                                        
                                        component={NWInput}
                                        name="SocialText"
                                        id="SocialTextField"
                                        value={values.SocialText}
                                    /> */}
                                    <div className="social-link-test">
                                        <div>
                                            {selectedSocial && activeSocialType &&
                                                <SocialIcon
                                                    social={activeSocialType}
                                                    showName
                                                />
                                            }
                                        </div>
                                        {isValidUrl(assembledUrl)
                                            ? <NwButton label="test link" icon={light("link")} primary onClick={navigateToSocial} />
                                            :
                                            (assembledUrl && <NwButton label="not valid url" icon={light("link-slash")} disabled />)
                                        }
                                    </div>
                                    <Field
                                        component={NWInputNumber}
                                        label="Followers (as a number)"
                                        name="Followers"
                                        precision={0}
                                        style={{ width: "100%" }}
                                        value={values.Followers}
                                    />
                                    {/* <NWInputNumber
                                        component={NWInput}
                                        name="Followers"
                                        label="Followers (number)"
                                        id="Followers"
                                        value={values.Followers}
                                    /> */}
                                    <NwFormButtonsBar
                                        left={
                                            <NwCancelButton
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    //setTouchForm(false);
                                                    onClose();
                                                }}
                                            />
                                        }
                                        right={
                                            <NwSaveButton
                                                htmlType="submit"
                                                disabled={isSubmitting || !isValidUrl(assembledUrl)}
                                                loading={isSubmitting}
                                            />
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </NwForm>
                )}
            </Formik>
        </SocialFormContainer>
    );
};

export default SocialForm;
