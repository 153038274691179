import React, { useState } from 'react';
import styled from 'styled-components';
import { List, Popover } from 'antd';
import { NwLinkButton } from 'Components/Gui/NwButton';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import MiniLoading from 'Components/Gui/MiniLoading';
import NwIcon from 'Components/Gui/NwIcon';
import { useEffect } from 'react';
import { set } from 'lodash';

const Wrapper = styled.div`
    .ant-dropdown-menu-item-group-list {
        max-height: unset;
    }
`;

const Content = styled.div`
    padding: .25rem .75rem .5rem .75rem;
    min-width: 22rem;

    .ant-menu-item-group-title {
        padding: 6px 0;
    }

    .ant-list-item {
        padding: 6px 0;

        .ant-list-item-meta {
            align-items: center;
        }

        .ant-list-item-meta-title {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: normal;
        }
    }

    .loading-message {
        padding: 2rem;
        text-align: center;
    }
`;


const TemplateSelectorPopover = ({
    children,
    onSelect,
    placement,
    templates,
    trigger,
}) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const handleSelectTemplate = async (templateID) => {
        setLoading(true);
        await onSelect(templateID);
        setOpen(false);
        setLoading(false);
    }

    return (
        <Wrapper>
            <Popover
                zIndex={9999}
                overlayClassName='folder-popover'
                placement={placement || 'top'}
                trigger={trigger || 'click'}
                open={open}
                onOpenChange={handleOpenChange}
                content={
                    <Content>
                        {loading
                            ?
                            <div className="loading-message">
                                <MiniLoading size="lg" /> Generating document, please wait...
                            </div>
                            :
                            <>
                                <div role="presentation" className="ant-menu-item-group-title" title="print book">SELECT A PRINT LAYOUT</div>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={templates}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<NwIcon icon={light('file-pdf')} />}
                                                title={
                                                    <NwLinkButton label={item.name} onClick={() => handleSelectTemplate(item.id)} />
                                                }
                                                description=""
                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        }
                    </Content>
                }
            >
                {children}
            </Popover>
        </Wrapper>
    )
}

export default TemplateSelectorPopover;