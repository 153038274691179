import React from 'react';
import styled from 'styled-components';
import { NwButtonGroup } from 'Components/Gui/NwButton';
import AcknowledgementEvent from 'Components/EventButtons/AcknowledgementEvent';
import CancelEvent from 'Components/EventButtons/CancelEvent';

const ActionsContainer = styled.div`
    display: flex;
    .acknowledgement-cancel-group {
        margin-right: 20px;
    }
    span {
        .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
            border-right: 1px solid #d9d9d9 !important;
            &.event-cancelled-button {
                border-right: 1px solid ${props => props.theme.DangerColor} !important;
            }
        }
        .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
`;

const EventModelActions = ({ 
    eventData, 
    model, 
    eventDateInfo, 
    afterUpdate, 
    onReloadEventStatus 
}) => {
  return (
    <ActionsContainer>
      <NwButtonGroup className="acknowledgement-cancel-group" size="small">
        <AcknowledgementEvent
          button
          onStatusChanged={async () => {
            await onReloadEventStatus(model);
            await afterUpdate();
          }}
          acknowledgmentValue={model.Acknowledgment}
          type={eventData.TypeName}
          eventDateModelID={model.ID}
          eventId={eventData.ID}
        />
        <CancelEvent
          button
          eventDateInfo={eventDateInfo}
          eventId={eventData.ID}
          eventData={eventData}
          onStatusChanged={async () => {
            await onReloadEventStatus(model);
            await afterUpdate();
          }}
          modelID={model.ModelID}
          cancelValue={model.Cancel}
          type={eventData.TypeName}
          eventDateModelID={model.ID}
        />
      </NwButtonGroup>
    </ActionsContainer>
  )
}

export default EventModelActions;