import React from 'react';
import { Avatar } from 'antd';
import ContactAvatar from 'Components/ContactAvatarGroup/ContactAvatar';

const ContactAvatarGroup = ({contactIDs, maxCount = 4, size = "large"}) => {
    if (contactIDs && contactIDs.length > 0) {
        return (<Avatar.Group maxCount={maxCount} size={size}>
            {contactIDs.map((contactID) => (
                <ContactAvatar key={contactID} contactID={contactID} />
            ))}
        </Avatar.Group>)
    }
    return (<small>no contacts</small>)
    
}

export default ContactAvatarGroup;