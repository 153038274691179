import React from 'react'
import styled from 'styled-components';
import { ButtonBack, ButtonNext, ButtonFirst } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isLandscape } from 'Libs/NwUtils';
import _ from "lodash";
import VideoPlayer from 'Components/Gui/VideoPlayer';

const StyledImage = styled.img`
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
  margin: 0 2px;
`

const PlaceHolderDiv = styled.div`
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
  margin: 0 2px;
`


export const StyledBackButton = styled(ButtonBack)`
    color: ${props => props.$negative ? `white` : props.theme.TextColor};
    font-size: 4rem;
    margin-right: 16px;
    position: absolute;
    top: ${props => props.height / 2 - 130}px;
    border: none;
    background: transparent;
    &:focus {
        outline: none
    }
`;

export const StyledNextButton = styled(ButtonNext)`
    color: ${props => props.$negative ? `white` : props.theme.TextColor};
    font-size: 4rem;
    margin-left: 16px;
    position: absolute;
    top: ${props => props.height / 2 - 130}px;
    right: 0;
    border: none;
    background: transparent;
    &:focus {
        outline: none
    }
`;

export const StyledFirstButton = styled(ButtonFirst)`
    color: ${props => props.$negative ? `white` : props.theme.TextColor};
    font-size: 3.6rem;
    margin-left: 16px;
    position: absolute;
    top: ${props => props.height / 2 - 130}px;
    right: 0;
    border: none;
    background: transparent;
    &:focus {
        outline: none
    }
`;

export const PlayerContainer = styled.div`
    /* height: 324px; */
    position: relative;
    width: ${props => !props.isLandscape ? `${props.height / props.ratio}px` : '100%'};
    width: ${props => props.isWebVideo && '90%'};
    height: ${props => props.isWebVideo && '100%'};
    background: red;
    border-radius: 4px;

    @include respond-to($portable) {
        height: 212px;
        height: ${props => props.isWebVideo && '100%'};
    }

    @include respond-to($phone) {
        width: 100%;
        height: ${props => props.isWebVideo && '100%'};
    }

    video{
        border-radius: 4px;
    }
`;

const isVideo = (p) => ((p.Type && p.Type === "Video") || (p.TypeName && p.TypeName === "Video"))

const newCurrentPage = () => {
    return { index: 1, left: null, right: null, landscape: false, video: false, completed: false }
}

export const getPages = (items, breakpoints, playlistLayout) => {
    const sortedItems = _.sortBy(items, 'DocumentOrder')
    const bookpages = []

    //layout === "Pages" and not mobile: 2pics per page when needed
    if ((playlistLayout && playlistLayout === "Pages") && breakpoints > 2) {
        const lastOrder = _.last(sortedItems).DocumentOrder
        const documentsWithPlaceholders = []
        for (let i = 0; i <= lastOrder; i++) {
            const documentForOrder = _.find(sortedItems, { "DocumentOrder": i })
            if (documentForOrder) {
                documentsWithPlaceholders.push(documentForOrder)
            } else {
                documentsWithPlaceholders.push({ ID: 0 })
            }
        }
        let currentpage = newCurrentPage()
        let lastindex = 0
        let npic = 0
        for (const mypic of documentsWithPlaceholders) {
            npic++
            if (currentpage.completed) {
                lastindex = currentpage.index
                currentpage = newCurrentPage()
                currentpage.index = (lastindex + 1)
            }
            if (currentpage.left === null) {
                currentpage.left = mypic
                if (mypic.ID) {
                    currentpage.landscape = isLandscape(mypic)
                    currentpage.video = isVideo(mypic)
                    if (currentpage.landscape || currentpage.video) {
                        currentpage.completed = true
                        bookpages.push(currentpage)
                    } else {
                        if (npic === documentsWithPlaceholders.length) {
                            currentpage.right = { ID: 0 }
                            currentpage.completed = true
                            bookpages.push(currentpage)
                        }
                    }
                }
            } else {
                currentpage.right = mypic
                currentpage.completed = true
                bookpages.push(currentpage)
                // if (!mypic.ID) {
                // } else {
                // }
            }
        }
        return bookpages
    }
    //layout === "sequence" or mobile: 1 picture/video per page
    for (let i = 0; i < sortedItems.length; i++) {
        const mypic = sortedItems[i]
        let currentpage = newCurrentPage()
        currentpage.completed = true
        currentpage.left = mypic
        currentpage.landscape = isLandscape(mypic)
        currentpage.video = isVideo(mypic)
        currentpage.index = i;
        bookpages.push(currentpage);
    }
    return bookpages;
}

const PageImage = ({url, width, height, note}) => {
    //aggiungere note
    return <StyledImage src={url} $height={height} $width={width} />
}

export const renderSlide = (page, width, height, layout) => {
    let docwidth = 0
    let docheight = 0
    let docratio = 1

    const availableHeight = (height - 10)
    const availableWidth = (width - 20)

    if (page.video || page.left.Type === 'Video') {
        return (
            <VideoPlayer document={page.left} />
        )
    }

    let leftRatio = 1;
    let leftWidth = 0;
    let leftHeight = availableHeight;
    let rightRatio = 1;
    let rightWidth = 0;
    let rightHeight = availableHeight;

    if (page.landscape) {
        leftRatio = page.left.Standard.Height / page.left.Standard.Width
        leftWidth = Math.floor(availableHeight / leftRatio)

        if ((leftWidth) > (availableWidth - 4)) {
            let newHeight = Math.floor((availableHeight * (availableWidth - 4)) / (leftWidth));
            leftHeight = newHeight;
            leftWidth = Math.floor(leftHeight / leftRatio);
        }
        return (
            <PageImage url={page.left.Standard.Url} height={leftHeight} width={leftWidth} note={page.left.DocumentPlaylistNotes} />
        )
    }

    if (page.left.ID) {
        leftRatio = page.left.Standard.Height / page.left.Standard.Width
        leftWidth = Math.floor(availableHeight / leftRatio)
    }
    if (page.right && page.right.ID) {
        rightRatio = page.right.Standard.Height / page.right.Standard.Width
        rightWidth = Math.floor(availableHeight / rightRatio)
    }

    if (!page.left.ID) {
        leftWidth = rightWidth
        leftRatio = rightRatio
    }
    if (!page.right || !page.right.ID) {
        rightWidth = leftWidth
        rightRatio = leftRatio
    }

    if ((leftWidth + rightWidth) > (availableWidth - 4)) {
        let newHeight = Math.floor((availableHeight * (availableWidth - 4)) / (leftWidth + rightWidth));
        leftHeight = newHeight;
        leftWidth = Math.floor(leftHeight / leftRatio);
        rightHeight = newHeight;
        rightWidth = Math.floor(rightHeight / rightRatio);
    }

    if (layout === "Pages") {
        return (
            <>
                {page.left.ID 
                ?
                <PageImage url={page.left.Standard.Url} height={leftHeight} width={leftWidth} note={page.left.DocumentPlaylistNotes} />
                :
                <PlaceHolderDiv $height={leftHeight} $width={leftWidth} />
                }
                {(page.right && page.right.ID) 
                ?
                <PageImage url={page.right.Standard.Url} height={rightHeight} width={rightWidth} note={page.right.DocumentPlaylistNotes} />
                :
                <PlaceHolderDiv $height={rightHeight} $width={rightWidth} />
                }
            </>
        )
    } else {
        return (
            <>
                {page.left.ID &&
                    <PageImage url={page.left.Standard.Url} height={leftHeight} width={leftWidth} note={page.left.DocumentPlaylistNotes} />
                }
            </>
        )
    }
}