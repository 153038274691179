import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { groupBy } from 'lodash';
import { AutoComplete, Select } from 'antd';
import { useDebounce } from "react-use";
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ModelCover } from 'Components/ModelCover';
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';
import { useModelsList, useCustomersList } from "Hooks/Contact/UseContactsList";
import { deaccentizeandlowerize, getHighlightedText } from 'Libs/NwUtils';
import EmptyState from "Components/Gui/EmptyState";
import { HeaderContext } from "../NwHeader";

const Option = AutoComplete.Option;
const OptGroup = Select.OptGroup;

const StyledOption = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;

    p {
        margin-bottom: 0;
    }

    .highlight {
        font-weight: bold;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const Icon = styled(NwIcon)`
    margin-right: 8px;
`;

const StyledAutocompleteContainer = styled.div`
    height: ${props => props.$isMobile ? '56px' : '60px'};
    padding-left: ${props => (props.activeSearch && !props.$isMobile) && '24px'};
    padding-right: ${props => !props.$isMobile && '16px'};
	text-align: left;
	width: ${props => (props.activeSearch || props.openMode) ? props.$isMobile ? '100%' : (props.$isTablet ? '18rem' : '25rem') : '12.5rem'};

	display: flex;
	align-items: center;

	.ant-select {
		width: 100%;
		max-width: 24.5rem;
		border: none;
		border: none;
		font-size: 1em;
		background-color: transparent;

        .ant-select-selector {
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 80px;
            padding: ${props => props.$isMobile ? '4px 16px 4px 36px' : '4px 16px 4px 32px'};
            font-size: .95rem;
            
            .ant-select-selection-search {
                padding-left: ${props => props.$isMobile ? '24px' : '20px'};
            }
            input {
                font-size: .95rem;
                height: 100% !important;
                padding-bottom: 1px !important;
            }
        }
		
		.ant-select-selection {
			border: none;
			background-color: transparent;
        }

		input {
			border:none;
			line-height: 1.5em;
			font-size: 1.3em;
			height: auto;
			padding: 0;
			outline:none !important;
			box-shadow: none;

			&:focus, &:focus-within, &:active, &:hover {
				outline:none !important;
				box-shadow: none;
			}
		}

		svg {
			cursor: pointer;
            margin-left: 16px;
		}
	}

    .select-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        svg {
            position: absolute;
            left: 12px;
            z-index: 1;
            font-size: 1.2rem;
        }
    }
`;

const Wrapper = styled.div`
    padding-bottom: 2rem;
    .rc-virtual-list-holder {
        max-height: 80vh !important;

        .ant-select-item-group {
            padding-left: 24px;
        }

        .ant-select-item-option-content {
            span {
                margin: 0 20px 0 0 !important;
            }
        }

        .ant-select-item-option-grouped  {
            padding-left: 56px !important;
        }
    }
`;

const WrapperContainer = styled.div`
    width: ${props => props.$isMobile && '100%'};
`;

const IconStyle = { marginRight: 16 }

const GlobalSearch = ({
    closable,
    onInputChange,
    openMode,
    sources,
    value = '',
}) => {
    const { currentUser } = useContext(UserContext)
    
    const headerContextValues = useContext(HeaderContext);
    const { device, activeSearch, onViewSearch, onSearchContact } = headerContextValues;
    
    const [filteredDataSource, setFilteredDataSource] = useState([])
    const [currentValue, setCurrentValue] = useState("");
    const searchRef = useRef();
    const { cachedListLoaded } = useContext(ListContext);
    const { data: models, isFetching: loadingModels } = useModelsList();
    const { data: customers, isFetching: loadingCustomers } = useCustomersList();
    const [nameFilter, setNameFilter] = useState('');

    useDebounce(() => {
        setNameFilter(currentValue);
    }, 500, [currentValue]);

    const handleKeyDown = (event) => {
        if (event.keyCode === 27) {
            onViewSearch && onViewSearch(false);
            setCurrentValue('');
            if (searchRef && searchRef.current) {
                searchRef.current.blur();
            }
        }

        if (event.keyCode === 114 || (event.ctrlKey && event.keyCode === 70)) {
            event.preventDefault();
            onViewSearch && onViewSearch(true);
            searchRef.current.focus();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, false);

        return () => {
            document.removeEventListener("keydown", handleKeyDown, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const getModelSearchName = (model) => {
        return `${model.N.trim()} ${model.S ? model.S.trim() : ''}`;
    }

    const fullDataSource = useMemo(() => {
        //Add searchGroup and searchValue props to each object after filtering by type
        //This return an array of array one for each source each one containing the data to be searched on
        //if (cachedListLoaded) {
        if (!loadingModels && !loadingCustomers) {
            const dataSources = sources.map((source) => {
                switch (source.toLowerCase()) {
                    case "models":
                        return models ? (models.filter((model) => (model.ST === 4))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "models" })))
                            : [];
                    case "scouting":
                        return models ? (models.items.filter((model) => (model.ST === 5))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "scouting" })))
                            : [];
                    case "clients":
                        return customers ? (customers.filter((customer) => (customer.ST === 1))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "clients" })))
                            : [];
                    case "agencies":
                        return customers ? (customers.filter((customer) => (customer.ST === 2))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "agencies" })))
                            : [];
                    case "services":
                        return customers ? (customers.filter((customer) => (customer.ST === 3))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "services" })))
                            : [];
                    default:
                        return models ? (models.filter((model) => (model.ST === 4))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "models" })))
                            : [];
                }
            })
            return (dataSources.flat())
        }
        return [];
        //} else {
        //	return([])
        //}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModels, loadingCustomers])

    const renderOptions = () => {
        if (filteredDataSource) {
            let sortedDataSource = [...filteredDataSource];
            sortedDataSource = sortedDataSource.sort((data1, data2) => {
                if (data1.searchValue.toLowerCase().indexOf(currentValue.toLowerCase()) === 0) return -1;
                if (data2.searchValue.toLowerCase().indexOf(currentValue.toLowerCase()) === 0) return 1;
                return 1;
            })
            const grouped = groupBy(sortedDataSource, (item) => item.searchGroup);
            //TODO: Working on sort function to sort grouped results by our specific rules
            const options = Object.keys(grouped).sort((group1, group2) => {
                if (group1 === 'models') return -1;
                if (group2 === 'models') return 1;
                return 1;
            }).map((group, groupIndex) => (
                <OptGroup key={`opt-group-${groupIndex}`} label={group}>
                    {grouped[group].map((opt, index) => {
                        let item = null
                        let url = "";
                        switch (group.toLowerCase()) {
                            case "models":
                                url = `/models/${opt.ID}`
                                item = <StyledOption>
                                    <span style={{ margin: "5px 20px" }}>
                                        <ModelCover visibleByDefault classN="model-cover-small" model={opt} />
                                    </span>
                                    <p>{getHighlightedText(`${opt.N.toLowerCase()} ${opt.S ? opt.S.toLowerCase() : ''}`, currentValue)}<br /><small>{opt.MAN}</small></p>
                                </StyledOption>
                                break;
                            case "clients":
                            case "services":
                                url = `/contacts/${opt.ID}`
                                item = <StyledOption>
                                    <Icon icon={light("address-card")} />
                                    <p>{getHighlightedText(opt.N.toLowerCase(), currentValue)}</p>
                                </StyledOption>
                                break;
                            case "agencies":
                                url = `/contacts/${opt.ID}`
                                item = <StyledOption>
                                    <Icon icon={light("globe")} />
                                    <p>{getHighlightedText(opt.N.toLowerCase(), currentValue)}</p>
                                </StyledOption>
                                break;
                            default:
                                break;
                        }

                        return (
                            <Option key={`${groupIndex}-${index}-${opt.ID}`} value={url}>
                                {item}
                            </Option>
                        )
                    })}
                </OptGroup>
            ))
            return (options)
        } else {
            return ([])
        }
    }


    const onChange = (selected) => {
        //Usually the parameter is an array, as in this case it will always be single selection
        //the value has been deconstructed to a single value taking just the first element of the array
        if (onViewSearch) {
            onViewSearch(false);
            searchRef.current.blur();
        }
        if (selected) {
            setCurrentValue("")
            const contactInfo = selected.split('/');
            onSearchContact({
                type: contactInfo[1],
                ID: Number(contactInfo[2])
            });
        }
    }

    useEffect(() => {
        if (nameFilter.length >= 2) {
            const normalizedNameFilter = deaccentizeandlowerize(nameFilter);
            const filteredResult = fullDataSource.filter(
                (item) => {
                    const normalizedItem = deaccentizeandlowerize(item.searchValue);
                    return normalizedItem.includes(normalizedNameFilter)   
                }
            )
            setFilteredDataSource(filteredResult)
        } else {
            setFilteredDataSource([])
        }
    }, [nameFilter, fullDataSource]);

    const handleSearch = (inputValue) => {
        setCurrentValue(inputValue);
        if (onInputChange) {
            onInputChange(inputValue);
        }
    }

    const handleFocus = () => {
        if (!activeSearch && !openMode) {
            onViewSearch(true);
        }
    }

    const isMobile = device === 1;
    const isTablet = device === 2;

    const renderSearchContainer = () => {
        return currentUser ?
            <WrapperContainer $isMobile={isMobile} $isTablet={isTablet}>
                {cachedListLoaded &&
                    <StyledAutocompleteContainer
                        $isMobile={isMobile}
                        $isTablet={isTablet}
                        openMode={openMode}
                        activeSearch={activeSearch}>
                        {closable && activeSearch &&
                            <NwIcon
                                style={IconStyle}
                                icon={light("arrow-left")}
                                size="lg"
                                onClick={() => {
                                    setCurrentValue('');
                                    onViewSearch(false);
                                }}
                                pointer />
                        }
                        <div className='select-wrapper'>
                            <NwIcon icon={light('search')} />
                            <AutoComplete
                                autoComplete='off'
                                ref={searchRef}
                                className="global-search"
                                onSelect={onChange}
                                onSearch={handleSearch}
                                placeholder="Search in netwalk"
                                defaultOpen={false}
                                open={activeSearch}
                                value={currentValue}
                                notFoundContent={currentValue.length >= 2 && <EmptyState message='no results' />}
                                dropdownAlign={{ offset: [0, 0] }}
                                popupClassName={openMode ? 'package-search-dropdown' : (device === 1) ? 'mobile-search-dropdown' : 'global-search-dropdown'}
                                dropdownMatchSelectWidth={false}
                                dropdownStyle={{ width: (device === 1) ? '100%' : (device === 2) ? '18rem' : "25rem", maxHeight: '80vh' }}
                                autoFocus={openMode ? true : false}
                                onFocus={handleFocus}
                                dropdownRender={node => {
                                    return (
                                        <Wrapper>
                                            {node}
                                        </Wrapper>
                                    )
                                }}
                            >
                                {renderOptions()}
                            </AutoComplete>
                        </div>
                    </StyledAutocompleteContainer>
                }
            </WrapperContainer>
            : <div />
    }

    return (
        <>
            {(device < 5)
                ?
                <>
                    {activeSearch
                        ?
                        renderSearchContainer()
                        :
                        <div className="nw-global-search-icon-holder">
                            <NwIcon icon={light('search')} pointer onClick={() => onViewSearch && onViewSearch(true)} />
                        </div>
                    }
                </>
                :
                renderSearchContainer()
            }
        </>
    )
}

export default GlobalSearch;