import React, { useState } from "react";
import { Popover, Menu, Badge } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
// import Axios from 'axios';

import NwIcon from 'Components/Gui/NwIcon';
import { NwButton, NwAddButton } from 'Components/Gui/NwButton';
import FolderAddEditDrawer from 'Components/FolderAddEditDrawer/FolderAddEditDrawer';
import { useStateValue } from 'Contexts/StateProvider';
// import { onGetProjectByID } from 'Hooks/Project/UseProject';
// import { getModels, getCustomers } from "Hooks/Contact/UseContactsList";

const Content = styled.div`    
    .ant-menu-vertical {
        border-right: none;
    }

    .ant-menu {
        border-radius: 0 0 6px 6px;
    }
    .ant-menu-item {
        margin: 0 !important;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 2rem;
        line-height: 2rem;

        &:last-child {
            border-bottom: none;
        }

        text-transform: uppercase;
        font-size: .8rem;
        vertical-align: middle;

        &.strong {
            font-weight: bold;
        }

        &.small {
            font-size: .75rem;
        }

        &.list {
            padding: 2px 12px;
        }

        svg  {
            margin: 0 6px;
        }

        .active {
            font-weight: bold;

            svg {
                color: ${props => props.theme.DangerColor};
            }
        }

        .badge {
            float: right;
            color: #fff;
            background-color: ${props => props.theme.DangerColor};
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            margin-top: .2em;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
`;

const EmptyFolders = styled.div`
    padding: 8px 16px;
`;

const FolderBadge = styled.div`
    font-size: 1rem;
    .ant-badge-count {
        background: ${props => props.theme.PrimaryColor};
        margin-top: 2px;
    }
    .ant-badge-multiple-words {
        padding: 0 3px;
    }
`;

const AddToFolderButton = (props) => {
    const [{ folderContext }, dispatch] = useStateValue()
    const { ids, type, placement, size, showBadge } = props;
    const [isNewFolder, setIsNewFolder] = useState(false);

    const onClick = (e) => {
        const action = e.key.split("-")[0]
        let param = ""
        if (e.key.split("-").length > 1) {
            param = e.key.split("-")[1]
        }
        switch (action) {
            case "folderadd":
                addFolder(Number(param))
                break;
            case "folderremove":
                removeFolder(Number(param))
                break;
            default:
            //do nothing
        }
    }

    const isMultiple = (ids.length > 1)

    const isInFolder = folder => {
        return folder.items.findIndex(itemId => itemId === ids[0]) >= 0;
    }

    const addFolder = async folderId => {
        dispatch({
            type: 'addItemsToFolder',
            folderID: folderId,
            items: ids
        })
    }

    const removeFolder = async folderId => {
        dispatch({
            type: 'removeItemsFromFolder',
            folderID: folderId,
            items: ids
        })
    }

    const multiFolderMenu = () => {
        const actionsmenu = [];
        const folders = folderContext.openFolders.filter(folder => folder.type === type);
        for (const p of folders) {
            if (isMultiple || !isInFolder(p)) {
                actionsmenu.push({ name: p.title, key: `folderadd-${p.ID}`, icon: light("user-plus"), added: false })
            } else {
                actionsmenu.push({ name: p.title, key: `folderremove-${p.ID}`, icon: light("user-times"), added: true })
            }
        }
        return actionsmenu
    };

    const renderAction = (action, index) => {
        if (action.divider) {
            return ({
                key: `divider-${index}`,
                type: "divider"
            })
            // return (<Menu.Divider key={`divider-${index}`} />)
        }
        if (action.group) {
            return ({
                key: `group-${index}`,
                type: "group",
                label: action.name,
                children: action.subactions.map(renderAction)
            })
            // return (
            //     <Menu.ItemGroup key={`group-${index}`} title={action.name}>
            //         {action.subactions.map(renderAction)}
            //     </Menu.ItemGroup>
            // )
        }
        let classn = ""
        if (action.list) {
            classn += "list "
        }
        if (action.small) {
            classn += "small "
        }
        if (action.strong) {
            classn += "strong "
        }
        return ({
            key: action.key,
            className: classn,
            label: (
                <div>
                    <span className={action.added ? 'active' : ''}>
                        <NwIcon icon={action.icon} />
                        {action.name}
                    </span>
                    {action.badge && <span className="badge">{action.badge}</span>}
                </div>
            )
        });
        // return (
        //     <Menu.Item key={action.key} className={classn}>
        //         <span className={action.added ? 'active' : ''}>
        //             <NwIcon icon={action.icon} />
        //             {action.name}
        //         </span>
        //         {action.badge && <span className="badge">{action.badge}</span>}
        //     </Menu.Item>
        // )
    };

    const handleAddNewFolder = () => {
        setIsNewFolder(true);
    };

    const handleClose = () => {
        setIsNewFolder(false);
    };

    const addedFolders = folderContext.openFolders.filter(folder => folder.type === type && !isMultiple && isInFolder(folder));

    return (
        <>
            <Popover
                placement={placement}
                overlayClassName='folder-popover'
                trigger='click'
                title={
                    <NwAddButton
                        ghost
                        size='small'
                        label='create new folder'
                        onClick={handleAddNewFolder} />
                }
                content={
                    folderContext.openFolders.filter(folder => folder.type === type).length === 0 ?
                        <EmptyFolders>no active folders</EmptyFolders>
                        :
                        <Content>
                            <Menu onClick={onClick} className="nw-small-context-menu" items={multiFolderMenu().map(renderAction)}>
                                {/* {multiFolderMenu().map(renderAction)} */}
                            </Menu>
                        </Content>
                }
            >
                {showBadge ?
                    <FolderBadge>
                        <Badge count={addedFolders.length} size='small'>
                            {props.text
                                ?
                                <NwButton ghost icon={light('folder')} label={props.text} />
                                :
                                <NwIcon icon={light('folder')} size={size} />
                            }
                        </Badge>
                    </FolderBadge>
                    :
                    (props.text
                        ?
                        <NwButton ghost icon={light('folder')} label={props.text} />
                        :
                        <NwIcon icon={light('folder')} size={size} />
                    )
                }
            </Popover>
            {isNewFolder &&
                <FolderAddEditDrawer items={ids} type={type} onClose={handleClose} />
            }
        </>
    )
};

export default AddToFolderButton
