import React from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import MiniLoading from 'Components/Gui/MiniLoading';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const SwitchContainer = styled.span`
    display: flex;
    align-items: center;
    
    .switch {
        margin-right: ${props => props.$textOnLeft ? '0' : '4px'};
        margin-left: ${props => props.$textOnLeft ? '4px' : '0'};
    }

    svg {
        margin-right: 8px;
    }
`;


const CustomSwitch = ({ value,
    className,
    compact,
    loading,
    onChange,
    textOnLeft,
    text,
    type,
    ...other
}) => {
    const getChildren = (checked) => {
        switch (type) {
            case "visibility":
                if (checked) {
                    return (<><NwIcon icon={regular("eye")} /> {text}</>)
                }
                return (<><NwIcon icon={regular("eye-slash")} /> {text}</>)
            case "visibilityarea":
                if (checked) {
                    return ("global")
                }
                return ("area")
            case "priority":
                if (checked) {
                    return ("high")
                }
                return ("low")
            default:
                return text
        }
    }

    if (compact) {
        return (
            <Switch className='switch' checked={value} checkedChildren={getChildren(true)} unCheckedChildren={getChildren(false)} onChange={onChange} {...other} />
        )
    } else {
        if (textOnLeft) {
            return (
                <SwitchContainer $textOnLeft={textOnLeft} className={className}>
                    {text}
                    {loading ? <MiniLoading /> :
                        <Switch className='switch' checked={value} onChange={onChange} {...other} />
                    }
                </SwitchContainer>
            )
        } else {
            return (
                <SwitchContainer $textPosition="left" className={className}>
                    {loading ? <MiniLoading /> :
                        <Switch className='switch' checked={value} onChange={onChange} {...other} />
                    }
                    {text}
                </SwitchContainer>
            )
        }
    }
};

export default CustomSwitch;