import React from "react";
import { List } from "antd";
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

const StyledListItem = styled(List.Item)`
    .icon-container {
        margin: .5em 1em;
        svg {
            width: 20px !important;
            height: auto;
        }
    }
    .ant-list-item-meta {
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                margin-bottom: 0;
            }
            .ant-list-item-meta-description {
                color: ${props => props.theme.TextColor};
                .ant-typography {
                    font-size: 1em !important;
                    line-height: 1.2em;
                }
            }
        }
    }
    
`

const CommentListItem = ({actions, title, description}) => {
    
    return (
        <StyledListItem actions={actions} className="ant-list-item-top">
            <div className="icon-container">
                <NwIcon icon={light("comment")} />
            </div>
            <List.Item.Meta
                avatar={null}
                title={title}
                description={description}
            />
        </StyledListItem>
    );
};

export { CommentListItem };
