import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { NwButton } from "Components/Gui/NwButton";
import NwDrawer from 'Components/Gui/NwDrawer';
import { NWTextAreaCharCounter } from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { useEditContactAlert, useDeleteContactAlert } from "Hooks/Contact/UseContactAlert";
import { useContact } from "Hooks/Contact/UseContact";

const AlertDrawer = ({ onClose, contactID }) => {
    useFormFieldFocus('Alert');
    const [isDeleting, setIsDeleting] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const { data: currentContact, /*isFetching: isFetchingContact*/ } = useContact(contactID)
    const { mutateAsync: onEditAlert } = useEditContactAlert();
    const { mutateAsync: onDeleteAlert } = useDeleteContactAlert();

    const initialValues = {
        Alert: ''
    };
    
    if (currentContact && currentContact.Alert) {
        initialValues.Alert = currentContact.AlertMessage;
    }
    
    const saveAlert = async (values, setSubmitting) => {
        try {
            await onEditAlert({ContactID: currentContact.ID, Message: values.Alert})
            setSubmitting(false);   
            setTouchForm(false);         
			onClose('updated');
        } catch (error) {
            console.log('ant : Save Alert Error => ', error);
            setSubmitting(false);
        }
    };

    const handleDeleteAlert = async () => {
        setIsDeleting(true);
        try {
            await onDeleteAlert({contactID:currentContact.ID})
            setTouchForm(false);
            setIsDeleting(false);
            onClose();
        } catch (error) {
            console.log('ant : Save Alert Error => ', error);
            setIsDeleting(false);
        }
    };

    return (
        <NwDrawer 
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title={'Message'} 
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveAlert(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    Alert: Yup.string().required("Content is required")
                })}
                render={({
                    isSubmitting,
                    values,
                    handleSubmit
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Alert"
                            name="Alert"
                            type="text"
                            id='Alert'
                            value={values.Alert}
                            maxLength={2000}
                            autoSize={{ minRows: 12, maxRows: 20 }}
                        /> 
                        {currentContact.Alert && 
                            <NwButton
                                disabled={isDeleting}
                                loading={isDeleting}
                                label="Delete"
                                loadingLabel="Deleting..."
                                onClick={handleDeleteAlert}
                            />
                        }
                        &nbsp;
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </NwDrawer>
    );
};

export default AlertDrawer;