import React, { useState } from 'react';
import { /*Tag,*/ Dropdown, Menu } from 'antd';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import EventDrawer from "Components/EventDrawer/EventDrawer";
import NwIcon from 'Components/Gui/NwIcon';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import ToDoCheck from 'Components/Gui/ToDoCheck';
import { showDateNoTime, getExpirationInDays, getNow } from 'Libs/NwMoment';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { TaskContainer, TaskCheckDropdownItem } from 'Components/TaskList/TasksUI';
import { NwTag } from 'Components/Gui/NwTag';
import TaskContactAvatars from './TaskContactAvatars';

const TaskCard = ({ todoItem, contactId, previewMode, projectId, onUpdate, showAll }) => {
    const [editDrawer, setEditDrawer] = useState(false);
    const [visible, showVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const openEventDrawer = (id) => {
        setEditDrawer(true)
        setSelectedId(id);
    };

    const closeEditDrawer = () => {
        setEditDrawer(false)
    }

    const todo = {
        ...todoItem,
        TypeName: "ToDo",
        Completed: todoItem.GlobalStatus === "Completed",
        HalfCompleted: todoItem.GlobalStatus === "PartiallyCompleted"
    };

    const menuItems =  
        [...todo.TodoContacts].map((model, index) => (
            {
                key: index,
                label: (
                    <TaskCheckDropdownItem>
                        <div className='name'>
                            {model.ContactID ? getContactName(model.ContactID) : 'No contact'}
                        </div>
                        <ToDoCheck previewMode={previewMode} onStatusChanged={() => onUpdate(model.ID)} eventId={model.ID} completedValue={model.Status} />
                    </TaskCheckDropdownItem>
                )
            }
        ))

    const renderTag = (todo) => {
        const date = todo.Date;
        if (todo.GlobalStatus === "Completed" || !date) return (null)
        if (getExpirationInDays(date) === "today") {
            return (<NwTag type="warning">Due Today</NwTag>)
        }
        if (getMoment(date) < getNow()) {
            return (<NwTag type="danger">Overdue {getExpirationInDays(date)}</NwTag>)
        }
    }

    return (
        <>
            <TaskContainer className="event-selected" onClick={() => !previewMode && openEventDrawer(todo.TodoContacts[0].ID)} showAll={showAll}>
                <div className="task-body">
                    <div className="task-toolbar">
                        {todo.TodoContacts && todo.TodoContacts.length > 1 ?
                            <Dropdown
                                menu={{items: menuItems}}
                                trigger={['click']}
                                open={visible}
                                onOpenChange={visible => {
                                    if (!visible) {
                                        showVisible(false);
                                    }
                                }}>
                                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <ToDoCheck
                                        previewMode={previewMode}
                                        action={e => {
                                            showVisible(true);
                                            e.stopPropagation()
                                        }}
                                        completedValue={todo.Completed}
                                        halfCompletedValue={todo.HalfCompleted}
                                    />
                                </div>
                            </Dropdown>
                            :
                            <ToDoCheck
                                previewMode={previewMode}
                                onStatusChanged={() => onUpdate(todo.TodoContacts[0].ID)}
                                eventId={todo.TodoContacts ? todo.TodoContacts[0].ID : todo.ContactID}
                                completedValue={todo.Completed}
                            />
                        } 
                    </div>
                    <div className="task-content">
                        <div className="task-content-top">
                            {(todo.PrivacyLevelName !== "Public")
                                ?
                                <>
                                {(todo.Project && !projectId) && <div className="task-projectline-container with-tag"><ProjectLine project={todo.Project} ellipsis /></div>}
                                <div className="task-personal-tag"><NwTag type="primary">Personal</NwTag></div>
                                </>
                                :
                                (todo.Project && !projectId) && <div className="task-projectline-container"><ProjectLine project={todo.Project} ellipsis /></div>
                            }
                            <NwEllipsis className={`task-title${((!todo.Project || projectId) && (todo.PrivacyLevel !== "Public")) ? " with-tag" : ""}`} text={todo.Title} rows={2} />
                            <NwEllipsis className="task-description" text={todo.CommonDescription} rows={3} />
                        </div>
                        <div className="task-content-bottom">
                            <CreatedBy author={todo.UserCreated} date={todo.DateCreated?todo.DateCreated:todo.Date}/>
                            {/* {(todo.PrivacyLevelName === "Public")
                                ?
                                    (todo.Completed && todo.DateCompleted ? 
                                        <CreatedBy author={todo.UserCompleted} date={todo.DateCompleted} extra="completed"/> :
                                        <CreatedBy object={todo}/>)
                                :
                                    (todo.Completed && todo.DateCompleted ? 
                                        <CreatedBy author="" date={todo.DateCompleted} extra="completed"/> : 
                                        <CreatedBy author="" date={todo.DateCreated}/>)
                            } */}
                        </div>
                    </div>
                </div>
                <div className="task-bottom">
                    <TaskContactAvatars task={todo} />
                    <div className="task-time">
                        <div className="time-content">
                            <NwIcon icon={light("alarm-exclamation")} />
                            <span>Due on <strong>{showDateNoTime(todo.Date)}</strong></span>
                        </div>
                        {renderTag(todo)}
                    </div>
                </div>
            </TaskContainer>
            {editDrawer &&
                <EventDrawer
                    eventType="ToDo"
                    eventID={selectedId}
                    onClose={closeEditDrawer}
                    projectId={projectId}
                    afterUpdate={onUpdate}
                    afterRemove={onUpdate}
                />
            }
        </>
    )
};

export default TaskCard;
