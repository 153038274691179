import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Tag, Switch, Row, Col } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import ConversionFilter from 'Components/ModelSelector/ModelsWall/ModelsWallHeader/ConversionFilter';
import NwIcon from 'Components/Gui/NwIcon';
import { ModelCover } from 'Components/ModelCover';
import ModelDetailsHeaderFeatures from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderFeatures'
import RatingSelector from "Components/Gui/NWForm/RatingSelector";
import { useGlobalFeatures, useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { ConfigContext } from 'Contexts/ConfigContext';
import { useContact } from 'Hooks/Contact/UseContact';
import { getContactName, getModel, modelFullName } from 'Hooks/Contact/UseContactsList';
import { Loading } from 'Components/Gui/Loading';
import { useStateValue } from 'Contexts/StateProvider';
import MainFeatures from 'Components/Features/MainFeatures';
import NumericRangeSelector from 'Components/Gui/NWForm/NumericRangeSelector';
import NumericFilter from './NumericFilter';


const ModelToCompareContainer = styled.div`

    small {
        text-transform: uppercase;
        font-size: ${props => props.theme.FontSizeSm};
    }

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 4px;
    }

    div.features {
        margin-top: .5em;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        dl {
            display: inline-block;
            margin-right: .5em;
            margin-bottom: 0;
            white-space: nowrap;
            line-height: 1em;

            dt {
                display: inline-block;
                padding: 0;
            }
            dd {
                display: inline-block;
                padding: 0 .25em;
            }

            &:after {
                content: '•'
            }

            &:last-of-type {
                margin-right: 0;
                &:after {
                    content: none;
                }
            }
        }
    }
`

const Wrapper = styled.div`
    background: white;
    padding: .5rem 1rem 1.5rem 1rem;
    margin-top: .5rem;
`;

const StyledSwitch = styled(Switch)`
    margin-top: 4px;
`;

const FeatureLabel = styled.div`
    width: 100px;
`;

const ChoiceTag = styled(Tag)`
    background-color: ${props => props.checked ? props.theme.PrimaryColor : props.theme.GreyedColor};
    border-color: ${props => props.checked ? props.theme.PrimaryColor : props.theme.GreyedColor};
    color: white;
    cursor: pointer;
`;

const QuickModelsFilter = styled(FlexContainer)`
    background: white;
`;

const QuickFilterContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: ${props => props.$conversion ? '2rem' : '.5rem'};
`

const ModelFeaturesQuickFilters = ({ modelList, quickFilters, onUpdateQuickFilters, onCloseQuickFilters }) => {
    const { data: globalFeatures } = useGlobalFeatures();
    const { data: globalConversions } = useGlobalConversions();
    const { userGuiConfig } = useContext(ConfigContext);
    const location = useLocation();
    //const { data: currentContact } = useContact(location.state && location.state.contactId);
    const [modelToCompare, setModelToCompare] = useState((location.state && location.state.contactId) ? getModel(location.state.contactId) : null);
    const [modelFeatures, setModelFeatures] = useState(null)
    const [modelListCount, setModelListCount] = useState(0)
    const history = useHistory();

    const closeSimilarModelsPanel = () => {
        onCloseQuickFilters();
        history.replace({
            pathname: history.location.pathname,
            state: {
                contactId: null,
                type: null
            }
        })
    }

    useEffect(() => {
        if (location.state && location.state.contactId) {
            const datamodeltocompare = getModel(location.state.contactId)
            setModelToCompare(datamodeltocompare)
        }
    }, [location])

    useEffect(() => {
        if (modelList && modelList.length && modelList.length !== modelListCount) {
            let features = []
            if (modelList) {
                const modelWithFeatures = modelList.filter(model => model.F)
                const modelFeatures = modelWithFeatures.map(model => {
                    return JSON.parse(model.F)
                })
                
                for (const modelFeature of modelFeatures) {
                    if (modelFeature) {
                        for (const feature of modelFeature) {
                            if (feature.Features.length) {
                                for (const subFeature of feature.Features) {
                                    if (subFeature.TypeName === 'Choice' || subFeature.TypeName === 'Conversion' || subFeature.TypeName === 'Rating' || subFeature.TypeName === 'Numeric') {
                                        const featureIndex = features.findIndex(feat => feat.FeatureID === subFeature.FeatureID)
                                        if (featureIndex > -1) {
                                            if (!features[featureIndex].values.find(value => value === subFeature.Value)) {
                                                features[featureIndex].values.push(subFeature.Value)
                                            }
                                        } else {
                                            features.push({
                                                ...subFeature,
                                                values: [subFeature.Value]
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setModelFeatures(features)
            setModelListCount(modelList.length)
        }
    }, [modelList])

    useEffect(() => {
        if (modelToCompare && modelFeatures) {
            const features = JSON.parse(modelToCompare.F);
            const modelFeature = features[0];
            let quickFilters = {};
            for (const feature of modelFeature.Features) {
                if (feature.TypeName === 'Choice') {
                    quickFilters = {
                        ...quickFilters,
                        [feature.FeatureID]: [feature.Value]
                    }
                } else if (feature.TypeName === 'Conversion') {
                    const featureFromModel = modelFeatures.find(item => item.FeatureID === feature.FeatureID)
                    if (featureFromModel) {
                        const sortedValues = featureFromModel.values.map(item => parseInt(item)).sort((v1, v2) => v1 > v2 ? 1 : -1);
                        quickFilters = {
                            ...quickFilters,
                            [feature.FeatureID]: [parseInt(feature.Value) > sortedValues[0] ? parseInt(feature.Value) - 10 : parseInt(feature.Value)
                                , parseInt(feature.Value) < sortedValues[sortedValues.length - 1] ? parseInt(feature.Value) + 10 : parseInt(feature.Value)]
                        }
                    }
                } else if (feature.TypeName === 'Numeric') {
                    const featureFromModel = modelFeatures.find(item => item.FeatureID === feature.FeatureID)
                    if (featureFromModel) {
                        const sortedValues = featureFromModel.values.map(item => parseFloat(item)).sort((v1, v2) => v1 > v2 ? 1 : -1);
                        quickFilters = {
                            ...quickFilters,
                            [feature.FeatureID]: [parseFloat(feature.Value) > sortedValues[0] ? parseFloat(feature.Value) - 10 : parseFloat(feature.Value)
                                , parseFloat(feature.Value) < sortedValues[sortedValues.length - 1] ? parseFloat(feature.Value) + 10 : parseFloat(feature.Value)]
                        }
                    }
                } else if (feature.TypeName === 'Rating') {
                    quickFilters = {
                        ...quickFilters,
                        [feature.FeatureID]: [feature.Value]
                    }
                }
            }
            onUpdateQuickFilters(quickFilters);
        }
    }, [modelFeatures, modelToCompare]);

    const handleChangeQuickFilters = (featureId, value) => {
        onUpdateQuickFilters({
            ...quickFilters,
            [featureId]: value
        });
    };

    const renderFeature = feature => {
        switch (feature.TypeName) {
            case 'Choice':
                const globalFeature = globalFeatures.find(feat => feat.ID === feature.FeatureID && feat.TypeName === feature.TypeName);
                if (globalFeature) {
                    const choices = [];
                    for (const featureValue of feature.values) {
                        const choice = globalFeature.Choices.find(choice => choice.ID === featureValue);
                        choices.push(choice);
                    }
                    choices.sort((choice1, choice2) => choice1.Value > choice2.Value ? 1 : -1);
                    const value = quickFilters[feature.FeatureID];

                    return (
                        <QuickFilterContainer>
                            <StyledSwitch
                                checked={value ? true : false}
                                size='small'
                                onChange={checked => {
                                    if (checked) {
                                        handleChangeQuickFilters(feature.FeatureID, choices.map(choice => choice.ID));
                                    } else {
                                        handleChangeQuickFilters(feature.FeatureID, null);
                                    }
                                }} />&nbsp;&nbsp;
                            <FeatureLabel>{feature.DisplayName}</FeatureLabel>
                            <div>
                                {choices.map(choice => {
                                    const choiceValue = value ? value.find(item => item === choice.ID) : null;
                                    return (
                                        <ChoiceTag checked={!value ? true : choiceValue} key={choice.ID} onClick={() => {
                                            if (!value || choiceValue) {
                                                const featureChoices = (value ? value : choices.map(choice => choice.ID)).filter(item => item !== choice.ID);
                                                handleChangeQuickFilters(feature.FeatureID, featureChoices);
                                            } else {
                                                handleChangeQuickFilters(feature.FeatureID, value ? [...value, choice.ID] : [choice.ID]);
                                            }
                                        }}>
                                            {/* <NwIcon icon={(!value || choiceValue) ? solid('check-circle') : light('check-circle')} />&nbsp; */}
                                            {choice.Value}
                                        </ChoiceTag>
                                    )
                                })}
                            </div>
                        </QuickFilterContainer>
                    )
                }
                return `${feature.TypeName}-${feature.FeatureID}`;
            case 'Conversion':
                const globalConversion = globalConversions.find(conversion => conversion.Name === feature.ConversionName);
                if (globalConversion) {
                    const conversions = [];
                    const metric = userGuiConfig.metrics.find(metric => metric.ID === globalConversion.ID);
                    for (const featureValue of feature.values) {
                        const conversion = globalConversion.ConversionValues.find(conv => conv.Key === parseInt(featureValue) && (!metric || metric.metrics[0] === conv.Metric));
                        if (conversion && conversion.Key) {
                            conversions.push(conversion);
                        }
                    }
                    conversions.sort((conv1, conv2) => parseFloat(conv1.Value.replace(',','.')) > parseFloat(conv2.Value.replace(',','.')) ? 1 : -1);
                    return (
                        <QuickFilterContainer $conversion>
                            <StyledSwitch
                                checked={quickFilters[feature.FeatureID] ? true : false}
                                size='small'
                                onChange={checked => {
                                    if (checked) {
                                        handleChangeQuickFilters(feature.FeatureID, [conversions[0].Key, conversions[conversions.length - 1].Key]);
                                    } else {
                                        handleChangeQuickFilters(feature.FeatureID, null);
                                    }
                                }} />&nbsp;&nbsp;
                            <FeatureLabel>{feature.DisplayName}</FeatureLabel>
                            <ConversionFilter
                                disabled={!quickFilters[feature.FeatureID]}
                                conversions={conversions}
                                globalConversion={globalConversion}
                                metric={metric}
                                value={quickFilters[feature.FeatureID]}
                                onChange={value => {
                                    handleChangeQuickFilters(feature.FeatureID, value);
                                }} />
                        </QuickFilterContainer>
                    )
                }
                return `${feature.TypeName} - ${feature.FeatureID} - ${feature.ConversionName} - ${feature.DisplayName}`;
            case 'Rating':
                return (
                    <QuickFilterContainer>
                        <StyledSwitch
                            checked={quickFilters[feature.FeatureID] ? true : false}
                            size='small'
                            onChange={checked => {
                                if (checked) {
                                    handleChangeQuickFilters(feature.FeatureID, 1);
                                } else {
                                    handleChangeQuickFilters(feature.FeatureID, null);
                                }
                            }} />&nbsp;&nbsp;
                        <FeatureLabel>{feature.DisplayName}</FeatureLabel>
                        <RatingSelector simpleMode onChange={(value) => handleChangeQuickFilters(feature.FeatureID, value)} value={quickFilters[feature.FeatureID]} />
                    </QuickFilterContainer>
                );
            case 'Numeric':
                let minValue = 0
                let maxValue = 0
                for (const featureValue of feature.values) {
                    if (minValue === 0 || parseFloat(featureValue) < minValue) {
                        minValue = parseFloat(featureValue);
                    }
                    if (parseFloat(featureValue) > maxValue) {
                        maxValue = parseFloat(featureValue);
                    }
                }
                //const existingValues = feature.values.map(value => parseFloat(value)).sort((value1, value2) => value1 > value2 ? 1 : -1);
                return (
                    <QuickFilterContainer $conversion>
                        <StyledSwitch
                            checked={quickFilters[feature.FeatureID] ? true : false}
                            size='small'
                            onChange={checked => {
                                if (checked) {
                                    handleChangeQuickFilters(feature.FeatureID, [minValue, maxValue]);
                                } else {
                                    handleChangeQuickFilters(feature.FeatureID, null);
                                }
                            }} />&nbsp;&nbsp;
                        <FeatureLabel>{feature.DisplayName}</FeatureLabel>
                        <NumericFilter
                            disabled={!quickFilters[feature.FeatureID]}
                            minValue={minValue}
                            maxValue={maxValue}
                            value={quickFilters[feature.FeatureID]}
                            onChange={value => {
                                handleChangeQuickFilters(feature.FeatureID, value);
                            }} />
                    </QuickFilterContainer>
                )
            default:
                break;
        }
        return null;
    };

    return (
        <>
            {modelToCompare &&
                <QuickModelsFilter mg={'8px 0 0'}>
                    <ModelCover
                        // model={{
                        //     CG: currentContact.NetworkAgencies[0].CoverGuid,
                        //     ST: currentContact.SubType
                        // }}
                        model={modelToCompare}
                        maxWidth={70}
                    // classN="model-cover-small" 
                    />
                    <ModelToCompareContainer>
                        <h1>{modelFullName(modelToCompare)}</h1>
                        <div className="features">
                            <MainFeatures contact={modelToCompare} />
                        </div>
                        <NwIcon icon={light('times')} pointer onClick={closeSimilarModelsPanel} />
                    </ModelToCompareContainer>
                </QuickModelsFilter>
            }
            <Wrapper>
                {modelFeatures
                    ?
                    <Row gutter={16}>
                        {modelFeatures.map(feature => {
                            return (
                                <Col lg={12} md={24} sm={24} xs={24} key={feature.FeatureID}>
                                    {renderFeature(feature)}
                                </Col>
                            )
                        })}
                    </Row>
                    :
                    <Loading />
                }

            </Wrapper>
        </>
    );
};

export default ModelFeaturesQuickFilters;