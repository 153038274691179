import React from 'react';
import { Alert, Badge } from 'antd';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const Wrapper = styled.span`
    font-size: ${props => props.$small ? '1.1rem' : '1.25rem'};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    color: ${props => props.theme.TextColor};
        
    .legend-item {
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: .9rem;
        justify-content: flex-start;

        &.green {
            color: ${props => props.theme.SuccessColor};
        }

        .ant-badge {
            padding: 0 .15rem;
            .ant-badge-status-dot {
                width: ${props => props.$small ? '.6rem' : '.75rem'};
                height: ${props => props.$small ? '.6rem' : '.75rem'};
            }
        }
    }
`

const TransactionsStatusLegend = () => {

    const Statuses = ['Billable', 'Job', 'Option']

    const ColorMap = {
        Option: StyleVariables.WarningColor,
        Job: StyleVariables.DangerColor,
        Billable: StyleVariables.SuccessColor
    }
    const TextMap = {
        Option: "Option",
        Job: "Confirmed",
        Billable: "To Bill"
    }

    return (
        <Alert
            message="Transaction Status Description"
            description={<Wrapper>
                <div className="legend-item green">
                    <NwIcon icon={solid("check")} />
                    <span>Paid</span>
                </div>
                <div className="legend-item">
                    <NwIcon icon={thin("file-invoice")} />
                    <span>Invoiced</span>
                </div>
                <div className="legend-item">
                    <NwIcon icon={thin("lock")} />
                    <span>Invoice Draft</span>
                </div>
                {Statuses.map(status => (
                    <div key={status} className="legend-item">
                        <Badge color={ColorMap[status]} />
                        <span>{TextMap[status]}</span>
                    </div>
                ))}
            </Wrapper>}
            type="info"
            showIcon
        />
    )
}

export default TransactionsStatusLegend