import React from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

import NwIcon from 'Components/Gui/NwIcon';

const StyledRadioGroup = styled(Radio.Group)`
    .ant-radio-button-wrapper {
        font-size: ${props => props.size === 'small' && '.8rem'};
    }
`;

const radioStyle = {
    display: 'block',
};

const RadioButtonGroup = ({ value, onChange, options, defaultValue, buttonType = 'button', size, buttonStyle='solid', onBlur, ...rest }) => {
    return (
        <StyledRadioGroup 
            size={size} 
            value={value} 
            buttonStyle={buttonStyle}
            defaultValue={defaultValue}
            onBlur={onBlur}
            onChange={e => onChange(e.target.value)} 
            {...rest}>
            {options.map(option =>  {
                const IconStyle = {}
                if (option.label && option.label.length) {
                    IconStyle.marginRight =  8
                }
                if (option.color) {
                    IconStyle.color = option.color
                }
                if(buttonType === 'button')
                    return (
                        <Radio.Button key={option.key || option.value} value={option.value} disabled={option.disabled}>
                            {option.icon && <NwIcon icon={option.icon} style={IconStyle} />}
                            {option.label}
                        </Radio.Button>
                    )
                return (
                    <Radio style={radioStyle} key={option.key || option.value} value={option.value} disabled={option.disabled}>
                        {option.icon && <NwIcon icon={option.icon} style={IconStyle} />}
                        {option.label}
                    </Radio>
                )
            })}
        </StyledRadioGroup>
    )
};

export default RadioButtonGroup;