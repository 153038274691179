import styled from 'styled-components';
import FlexContainer from 'Components/Gui/FlexContainer';

export const EventWrapper = styled.div`
    cursor: pointer;
    width: 100%;
    border-bottom: ${props => props.theme.DefaultBorder};
    
    .ant-col {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: .8rem;
        ${props => props.$isGrouped && 'padding: 0 !important;'}

        &:last-child {
            border-right: none;
            /* font-size: .65rem;
            justify-content: center; */
        }
    }

    .address-line {
        span {
            display: inline-block;
            margin-right: .5rem;
        }
    }

    div.event-box-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.align-top {
            justify-content: flex-start;
        }
    }

    div.event-box-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        height: 100%;
        
        svg {
            font-size: .8rem;
        }

        .time {
            font-size: ${props => props.theme.FontSizeSm};
            line-height: 2.2em;
            ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
        }
    }

    div.event-box-details {
        padding: .5rem;
        min-height: 3rem;
        
        ${props => props.$cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'};

        .event-box-details-title {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: .3rem;

            .ant-tag {
                margin-right: .5rem;
            }

            p {
                ${props => props.cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'};
                display: inline;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
                line-height: 1.2em;
            }
        }
        .event-box-details-product {
            p {
                margin: 0 0 .15rem 0;
                line-height: 1.2em;
            }
        }
        .event-box-details-description {
            p {
                margin: 0 0 .15rem 0;
                line-height: 1.2em;
            }
        }
        small {
            display: block;
            font-weight: normal;
            font-size: ${props => props.theme.FontSizeSm};
        }
    }

    div.project-wrapper {
        div.project-header-wrapper {
            border-bottom: ${props => props.theme.DefaultBorder};
            background-color: ${props => props.theme.LayoutBodyBackground};
            padding: .5rem;
            min-height: 5rem;

            div.project-title-wrapper {
                margin-bottom: .15rem;
            }
            div.project-product-wrapper {
                font-size: ${props => props.theme.FontSizeSm};
                padding-right: 4rem;
                p {
                    margin: 0;
                }
            }
            div.project-category-wrapper {
                font-size: ${props => props.theme.FontSizeSm};
                padding-right: 4rem;
                p {
                    margin: 0;
                    font-style: italic;
                }
            }
            div.project-description-wrapper {
                font-size: ${props => props.theme.FontSizeSm};
                padding-right: 4rem;
            }
        }

        &.no-project {
            div.project-header-wrapper {
                min-height: 2rem;
            }
        }

        div.project-events-wrapper {
            //padding-left: 3rem;
            .event-in-project-box {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                min-height: 4rem;
                border-bottom: ${props => props.theme.DefaultBorder};
                &:last-child {
                    border-bottom: none;
                }
                
                .event-in-project-box-date {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 13rem;
                    min-height: 4rem;
                }
                .event-in-project-box-details {
                    padding: .5rem;
                    flex: 1 1 0;
                    border-left: ${props => props.theme.DefaultBorder};
                    height: 100%;

                    &.no-border {
                        border-left: none;
                    }

                    &.event-in-project-cancel {
                        ${props => props.theme.RedLineThrough(1)};
                    }

                    .event-in-project-box-details-title {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        
                        .ant-tag {
                            margin-right: .5rem;
                        }

                        p {
                            ${props => props.cancel ? props.theme.RedLineThrough(1) : 'text-decoration: none;'};
                            display: inline;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: .25rem;

                            &.address-line {
                                span {
                                    display: inline-block;
                                    margin-right: .5rem;
                                }
                            }
                        }
                    }
                    .event-in-project-box-details-description {
                        p {
                            margin: 0;
                            line-height: 1.2em;
                            padding-right: 1rem;
                        }
                    }
                }

                &.not-result {
                    min-height: 2rem;

                    .event-in-project-box-date {
                        min-height: 2rem;
                        opacity: .3;
                    }
                    .event-in-project-box-details {
                        padding: .25rem;
                        .event-in-project-box-details-title {
                            opacity: .3;
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Title = styled.div`
    
`;

export const EventContent = styled.div`
    
`;

export const PeriodCell = styled(FlexContainer)`
`;

export const TimeWrapper = styled.div`
    
`;

export const EventTypeTagContainer = styled.div`
`;

export const EventToolbar = styled.div`
`;

