import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { List } from 'antd';
import styled from "styled-components";
import * as Yup from "yup";
import { light, thin } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDrawer from "Components/Gui/NwDrawer";
import { forceReloadContact } from "Hooks/Contact/UseContact";
import { NwButton, NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from "Components/Gui/NWForm/NwFormWrapper";
import { NWSelect } from "Components/Gui/NWForm/NwFormItems";
import { useAddPlaylist, useRemovePlayList, getWebsitePlaylists } from "Hooks/Websites/UseWebsites";
import NwDivider from "Components/Gui/NwDivider";
import { findPath, getBookCover } from "Libs/NwUtils";
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import AvatarBg from 'Components/Gui/AvatarBg';
import { Loading } from 'Components/Gui/Loading';
import { forceReloadPlaylist } from "Hooks/Playlist/UsePlaylist";
import { forceContactPlaylists } from 'Hooks/Contact/UseContactPlaylists';
import NwIcon from "Components/Gui/NwIcon";
import { PublishedBookContainer, OtherBooksPublishedContainer  } from "Components/ContactDetails/ModelDetails/CommonComponents/BookPublishing/PublishedBooksUI";

const ModelBookPublishDrawer = (props) => {
    const { onClose, websiteData, books } = props;
    const [touchForm, setTouchForm] = useState(false);
    const [sectionOptions, setSectionOptions] = useState([]);
    const [linkedSections, setLinkedSections] = useState([]);
    const { mutateAsync: onAddPlaylist } = useAddPlaylist();
    const { mutateAsync: onRemovePlayList } = useRemovePlayList();
    const [book, setBook] = useState(props.book);
    //const [isTypeUpdate, setIsTypeUpdate] = useState(false);
    //const [dataforChangeBookType, setDataforChangeBookType] = useState(null);
    const [otherBooks, setOtherBooks] = useState([]);
    const [currentWebsite, setCurrentWebsite] = useState(websiteData[0]);
    const [currentSectionCode, setCurrentSectionCode] = useState("");
    const [refreshing, setRefreshing] = useState(false);

    const websiteOptions = websiteData.map((website, index) => {
        return { key: website.Code, label: website.Name, value: index };
    });

    const _publishAsOptions = [
        { key: "Portfolio", label: "Main Portfolio", value: "Portfolio", disabled: false },
        { key: "Digitals", label: "Digitals", value: "Digitals", disabled: false },
        { key: "CompCard", label: "Comp Card", value: "CompCard", disabled: false },
        { key: "Other", label: "Other", value: "Other", disabled: false }
    ];

    const _initialValues = {
        websiteCode: websiteOptions[0].value,
        publishAs: "",
        section: "",
    }

    const [initialValues, setInitialValues] = useState(_initialValues);
    const [publishAsOptions, setPublishAsOptions] = useState(_publishAsOptions);

    const makeSectionOptions = (data, path, linkedSections) => {
        const options = [];
        for (const item of data) {
            if (item.type === "list") {
                const _path = `${path.slice(2)} > ${item.name.toUpperCase()}`;
                if (!linkedSections.find(item => item.path === _path))
                    options.push({
                        key: item.code,
                        value: item.code,
                        label: _path
                    })
            } else if (item.subNav && item.subNav.length) {
                options.push(...makeSectionOptions(item.subNav, `${path} > ${item.name.toUpperCase()}`, linkedSections));
            }
        }
        return options;
    }

    const updateSectionOptions = (data, book, sectionCode) => {
        const _linkedSections = formatLinkedSections(book);
        setSectionOptions(makeSectionOptions(data, "", _linkedSections));
        setLinkedSections(_linkedSections);
        if (_linkedSections.length !== linkedSections.length) setRefreshing(false);
        updateOtherBooks(sectionCode);
    }

    const formatLinkedSections = (book) => {
        const _linkedSections = [];
        if (websiteData && websiteData.length) {
            book.Divisions.map((item) => {
                _linkedSections.push({
                    websiteName: item.WebsiteName ? item.WebsiteName.toUpperCase() : "",
                    path: findPath(websiteData, item.WebsiteCode, item.DivisionCode, "", true),
                    role: item.Role,
                    websiteCode: item.WebsiteCode,
                    divisionCode: item.DivisionCode,
                    playlistID: book.ID,
                });
                return null;
            });
        }
        return _linkedSections;
    }

    useEffect(() => {
        const newBooks = books.filter(item => item.ID === book.ID);
        if (newBooks && newBooks.length) {
            setBook(newBooks[0]);
            updateSectionOptions(currentWebsite.Structure.nav, newBooks[0], currentSectionCode);
        }
    }, [books]);

    const onPublish = async (values, setSubmitting) => {
        let data = { ...values };
        setSubmitting(true);
        try {
            await onAddPlaylist({
                data: {
                    WebsiteCode: websiteData[data.websiteCode].Code,
                    DivisionCode: data.section,
                    Role: data.publishAs,
                    PlaylistID: book.ID
                }
            });
            forceReloadContact(book.ContactID);
            forceReloadPlaylist(book.ID);
            forceContactPlaylists(book.ContactID);
            onClose();
        } catch (e) {

        } finally {
            setSubmitting(false);
        }
    }

    const unlinkPlaylist = async (item) => {
        setRefreshing(true);
        try {
            await onRemovePlayList({
                WebsiteCode: item.websiteCode,
                PlaylistID: item.playlistID,
                DivisionCode: item.divisionCode
            });
            forceReloadContact(book.ContactID);
            forceReloadPlaylist(book.ID);
            forceContactPlaylists(book.ContactID);
        } catch (e) {
        } finally {
            setRefreshing(false);
        }
    }

    // const changeBookType = (item) => {
    //     setIsTypeUpdate(true);
    //     setDataforChangeBookType(item);
    // }

    const isOptionDisabled = (option, otherBooksInSameDivision) => {
        if (option.value === "Portfolio") {
            if (otherBooksInSameDivision.find(book => book.Role === "Portfolio")) {
                return true;
            }
        }
        return false;
    }

    const updateOtherBooks = async (sectionCode) => {
        if (!sectionCode) {
            setOtherBooks([]);
            setPublishAsOptions(_publishAsOptions);
            return;
        };
        try {
            const allbooks = await getWebsitePlaylists(currentWebsite.Code);
            const otherBooksInSameDivision = allbooks.filter(item => item.DivisionCode.toLowerCase() === sectionCode.toLowerCase() && book.ContactID === item.Playlist.ContactID);
            setOtherBooks(otherBooksInSameDivision);
            setPublishAsOptions(_publishAsOptions.map(option => {
                return {
                    ...option,
                    //disabled: otherBooksInSameDivision.find(book => book.Role === option.value) ? true: false
                    disabled: isOptionDisabled(option, otherBooksInSameDivision)
                }
            }));
            if (otherBooksInSameDivision.length !== otherBooks.length) setRefreshing(false);
        } catch (e) {
            console.log("error in updateOtherBooks", e)
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title='Publish book on website'
        >
            {refreshing ?
                <Loading textBlack />
                :
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        onPublish(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        section: Yup.string().required('This field is required'),
                        publishAs: Yup.string().required('This field is required'),
                    })}
                >
                    {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                        <NwForm
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit}
                        >
                            <NwDivider textSize="medium">
                                THIS BOOK IS PUBLISHED ON
                            </NwDivider>
                            <List
                                itemLayout="horizontal"
                                dataSource={linkedSections}
                                renderItem={item => (
                                    <List.Item
                                        actions={[<NwButton iconOnly ghost icon={light("trash-alt")} onClick={() => unlinkPlaylist(item)} />]}
                                    >
                                        <List.Item.Meta
                                            description={
                                                <PublishedBookContainer>
                                                    <div className="published-book-icon">
                                                        <NwIcon icon={thin("globe")} />
                                                    </div>
                                                    <div className="published-book-info">
                                                        <p>WEBSITE: <strong>{item.websiteName}</strong></p>
                                                        <p>DIVISION: <strong>{item.path}</strong></p>
                                                        <p>as {item.role}</p>
                                                    </div>
                                                </PublishedBookContainer>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                            <NwDivider textSize="medium">
                                PUBLISH
                            </NwDivider>
                            <div>
                                <Field
                                    component={NWSelect}
                                    label="On website"
                                    name="websiteCode"
                                    placeholder=''
                                    value={values.websiteCode}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    options={websiteOptions}
                                    onAfterChange={(value) => {
                                        setCurrentWebsite(websiteData[value]);
                                        updateSectionOptions(websiteData[value].Structure.nav, book, "");
                                        setFieldValue("section", "");
                                        setCurrentSectionCode("");
                                        setInitialValues({
                                            ...initialValues,
                                            websiteCode: value,
                                            section: "",
                                        });
                                    }}
                                />
                                <Field
                                    component={NWSelect}
                                    label="In section"
                                    name="section"
                                    placeholder="Select webpath"
                                    value={values.section}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    options={sectionOptions}
                                    onAfterChange={(value) => {
                                        setCurrentSectionCode(value);
                                        updateOtherBooks(value);
                                        setFieldValue("publishAs", "");
                                        setInitialValues({
                                            ...initialValues,
                                            section: value,
                                            publishAs: "",
                                        });
                                    }}
                                />
                                <Field
                                    component={NWSelect}
                                    label="as"
                                    name="publishAs"
                                    placeholder=''
                                    value={values.publishAs}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    options={publishAsOptions}
                                    onAfterChange={(value) => {
                                        setInitialValues({
                                            ...initialValues, publishAs: value
                                        });
                                    }}
                                />
                            </div>
                            <br/>
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                            <br/>
                            <NwDivider textSize="medium">OTHER MODEL'S BOOKS IN THIS SECTION</NwDivider>
                            <List
                                itemLayout="horizontal"
                                dataSource={otherBooks}
                                renderItem={book => (
                                    <List.Item
                                        actions={[
                                            <NwButton iconOnly ghost icon={light("trash-alt")} onClick={() => unlinkPlaylist({ divisionCode: book.DivisionCode, websiteCode: book.WebsiteCode, playlistID: book.Playlist.ID })} />
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={<AvatarBg src={getBookCover(book.Playlist)} />}
                                            title={`${book.Playlist.Name.toUpperCase()}`}
                                            description={
                                                <OtherBooksPublishedContainer>
                                                    <CreatedBy object={book.Playlist} short textonly />
                                                    <p>PUBLISHED AS <strong>{book.Role}</strong></p>
                                                </OtherBooksPublishedContainer>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </NwForm>
                    )}
                </Formik>
            }
        </NwDrawer>
    )
}

export default ModelBookPublishDrawer;
