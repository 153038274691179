import React from "react";
import { getNow, showDate } from "Libs/NwMoment";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";
import NwIcon from "Components/Gui/NwIcon";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";

const VerticalDateContainer = styled.div`
    padding: ${props => props.verticalPadding ? `${props.verticalPadding} 0` : '.75rem 0'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: ${props => props.minWidth ? props.minWidth : 'auto'};
    position: relative;

    .today {
        font-size: .9rem;
        text-transform: uppercase;
        font-weight: bold;
    }
    .day-name {
        font-size: 1rem;
        text-transform: uppercase;
    }
    .day-number {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1em;
    }
    .month-name {
        font-size: 1rem;
        text-transform: uppercase;
    }
    .year {
        font-size: .8rem;
    }
    .no-date {
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
    }

    &.vertical-date-container-small {
        padding: ${props => props.verticalPadding ? `${props.verticalPadding} 0` : '.25rem 0'};
        .today {
            font-size: ${props => props.theme.FontSizeSm};
        }
        .day-name {
            font-size: ${props => props.theme.FontSizeSm};
        }
        .day-number {
            font-size: 1.1rem;
            line-height: 1.2em;
        }
        .month-name {
            font-size: ${props => props.theme.FontSizeSm};
        }
        .year {
            font-size: .65rem;
        }
    }

    &.vertical-date-container-big {
        .today {
            font-size: 1.15rem;
        }
        .day-name {
            font-size: 1.15rem;
        }
        .day-number {
            font-size: 1.5rem;
        }
        .month-name {
            font-size: 1.15rem;
        }
        .year {
            font-size: 1rem;
        }
    }

    .cancelled-icon-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 2.5rem;
        z-index: 10;
        color: ${props => props.theme.DangerColor};
        
        .cancelled-icon {
            
        }
    }
`

const VerticalDate = ({ date, showDay, emptyText, size, minWidth, verticalPadding, forceShowYear, useToday, useTomorrow, cancelled }) => {

    let className = "vertical-date-container"
    if (size) {
        className += ` vertical-date-container-${size}`
    }

    const notCurrentYear = date && (getMoment(date).year() !== getNow().year())
    const isToday = useToday && getMoment(date).isSame(getNow(), 'day')
    const isTomorrow = useTomorrow && getMoment(date).isSame(getNow().add(1, 'day'), 'day')

    if (date) {
        if (isToday) {
            return (
                <VerticalDateContainer verticalPadding={verticalPadding} className={className} minWidth={minWidth}>
                    <div className="today">
                        Today
                    </div>
                    {cancelled &&
                        <div className="cancelled-icon-wrapper">
                            <NwIcon className="cancelled-icon" icon={thin("xmark")} />
                        </div>
                    }
                </VerticalDateContainer>
            )
        }
        if (isTomorrow) {
            return (
                <VerticalDateContainer verticalPadding={verticalPadding} className={className} minWidth={minWidth}>
                    <div className="today">
                        Tomorrow
                    </div>
                    {cancelled &&
                        <div className="cancelled-icon-wrapper">
                            <NwIcon className="cancelled-icon" icon={thin("xmark")} />
                        </div>
                    }
                </VerticalDateContainer>
            )
        }
        return (
            <VerticalDateContainer verticalPadding={verticalPadding} className={className} minWidth={minWidth}>
                {showDay &&
                    <div className="day-name">
                        {showDate(date, 'weekDay')}
                    </div>
                }
                <div className="day-number">
                    {showDate(date, 'onlyDay')}
                </div>
                <div className="month-name">
                    {showDate(date, 'onlyMonth')}
                </div>
                {(notCurrentYear || forceShowYear) &&
                    <div className="year">
                        {getMoment(date).year()}
                    </div>
                }
                {cancelled &&
                    <div className="cancelled-icon-wrapper">
                        <NwIcon className="cancelled-icon" icon={thin("xmark")} />
                    </div>
                }
            </VerticalDateContainer>
        )
    } else {
        return (
            <VerticalDateContainer verticalPadding={verticalPadding} className={className} minWidth={minWidth}>
                <div className="no-date">
                    {emptyText
                        ?
                        <>{emptyText}</>
                        :
                        <>no<br />date</>
                    }
                </div>
                {cancelled &&
                    <div className="cancelled-icon-wrapper">
                        <NwIcon className="cancelled-icon" icon={thin("xmark")} />
                    </div>
                }
            </VerticalDateContainer>
        )
    }
}

export default VerticalDate