import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { List, Descriptions } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwAddButton } from 'Components/Gui/NwButton';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import EmptyState from 'Components/Gui/EmptyState';
import AddEditEstimatedFee from 'Components/Project/ProjectOverview/AddEditEstimatedFee';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { useProject } from 'Hooks/Project/UseProject';
import { useDeletePrevisionalTransaction } from 'Hooks/Project/UsePrevisionalTransactions';
import { ListContext } from 'Contexts/ListContext';
import NwIcon from 'Components/Gui/NwIcon';

const TransactionsContainer = styled.div`
    padding: 16px;
`;

const DescriptionsContainer = styled.div`
    font-weight: ${props => props.modelselected && 'bold'};
`;

const ProjectEstimatedFees = ({ projectId, modelId, readOnly }) => {
    const { data: currentProject } = useProject(projectId);
    const [editing, setEditing] = useState(false);
    const [transaction, setTransaction] = useState();
    const [touchForm, setTouchForm] = useState(false);
    const { mutate: onDeletePrevisionalTransaction } = useDeletePrevisionalTransaction();

    const { cachedList } = useContext(ListContext);
    const transactionFamilies = cachedList.transactionFamilies.items;
    const rateTransaction = transactionFamilies.find(family => family.Code === 'JobFee');

    const RenderAddButton = () => {
        return (
            <NwAddButton
                ghost
                size='small'
                label='Estimated Fees'
                onClick={handleAddPrevisionalTransaction}
            />
        );
    };

    const handleAddPrevisionalTransaction = () => {
        setTransaction();
        setEditing(true);
    };

    const handleCloseEdit = () => {
        setEditing(false);
    };

    const handleAfterUpdated = () => {
        setEditing(false);
        setTransaction();
    }

    const handleEditTransaction = transaction => {
        setEditing(true);
        setTransaction(transaction);
    };

    const handleRemoveTransaction = async transactionId => {
        onDeletePrevisionalTransaction({ ID: transactionId, projectId: projectId });
    };

    const actions = transaction => {
        const previsionalModels = currentProject.ProjectModels.filter(model => model.isPrevisional);
        const model = previsionalModels.find(previsionalModel => previsionalModel.PrevisionalTransactionID === transaction.ID);
        return [
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => handleEditTransaction(transaction) },
                    {
                        name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => {
                            if (!model) handleRemoveTransaction(transaction.ID);
                        })
                    }
                ]}
            />
        ];
    }


    return (
        <div className="internal-card">
            {currentProject &&
                <>
                    <div className="header">
                        <div className="title-container">
                            <NwIcon className="icon" icon={light("message-dollar")} />
                            <div className="title">Estimated Fees</div>
                        </div>
                        {!readOnly && RenderAddButton()}
                    </div>
                    <div className="content">
                        <List
                            itemLayout="horizontal"
                            dataSource={currentProject.PrevisionalTransactions}
                            renderItem={transaction => {
                                //const previsionalModels = currentProject.ProjectModels.filter(model => model.isPrevisional && model.PrevisionalTransactionID === transaction.ID);
                                //const modelSelected = previsionalModels.find(model => modelId && model.ModelID === modelId);
                                const transactionLabel = rateTransaction.TransactionLabels.find(label => label.ID === transaction.TransactionLabelID);
                                return (
                                    <List.Item className="ant-list-item-top" actions={!readOnly && actions(transaction)}>
                                        <DescriptionsContainer>
                                            <Descriptions column={2}>
                                                <Descriptions.Item key={`type-${transaction.ID}`} label="Label">{transactionLabel.Label}</Descriptions.Item>
                                                <Descriptions.Item key={`salary-${transaction.ID}`} label="Salary">{`${transaction.Amount} ${transaction.Currency}`}</Descriptions.Item>
                                                <Descriptions.Item key={`description-${transaction.ID}`} label="Description">{transaction.Description}</Descriptions.Item>
                                            </Descriptions>
                                        </DescriptionsContainer>
                                    </List.Item>
                                )
                            }}
                        />
                    </div>
                    {editing &&
                        <NwDrawer
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            title={transaction ? 'Edit Transaction' : 'Add Transaction'}
                            onClose={handleCloseEdit}>
                            <AddEditEstimatedFee
                                transaction={transaction}
                                projectId={currentProject.ID}
                                onCancel={handleCloseEdit}
                                onAfterUpdated={handleAfterUpdated}
                                onTouchForm={setTouchForm} />
                        </NwDrawer>
                    }
                </>
            }
        </div>
    )
};

export default ProjectEstimatedFees;