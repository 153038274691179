import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from 'QueryClientProvider';
import Axios from 'axios';

const forceReloadWebsites = () => queryClient.invalidateQueries("websites");
const forceReloadPlayLists = (websiteCode) => queryClient.invalidateQueries(["websites-playlists", websiteCode]);

const getWebsites = () => {
    return Axios.get(`/websites`);
}

const useWebsites = () => {
    return (
        useQuery(["websites"],
            getWebsites,
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}

const getWebsitePlaylists = (websiteCode) => {
    return Axios.get(`/websites/${websiteCode}/playlists`);
}

const usePlayList = (websiteCode) => {
    return useQuery(["websites-playlists", websiteCode], () => getWebsitePlaylists(websiteCode));
}

const onUpdateWebsite = ({ websiteCode, data }) => {
    return Axios.put(`/websites/${websiteCode}/structure`, data);
}

const onRemoveWebsite = ({websiteCode, data}) => {
    return Axios.put(`/websites/${websiteCode}/removenode`, data);
}

const onAddNewWebsite = ({data}) => {
    return Axios.post(`/websites`, data);
}

const onAddPlaylist = ({data}) => {
    return Axios.post(`/websites/addplaylist`, data);
}

const onUpdatePlaylist = ({data}) => {
    return Axios.put("/websites/playlists", data);
}

const onRemovePlayList = (data) => {
    return Axios.delete("/websites/RemovePlaylistFromDivision", {
        params: data
    });
}

const useAddPlaylist = () => {
    return useMutation(onAddPlaylist, {
        onSuccess: (_, variables) => {
            forceReloadWebsites();
            forceReloadPlayLists(variables.data.WebsiteCode);
        }
    });
}

const useUpdatePlaylist = () => {
    return useMutation(onUpdatePlaylist, {
        onSuccess: (_, variables) => {
            forceReloadWebsites();
            forceReloadPlayLists(variables.data.WebsiteCode);
        }
    });
}

const useRemovePlayList = () => {
    return useMutation(onRemovePlayList, {
        onSuccess: (_, variables) => {
            forceReloadPlayLists(variables.WebsiteCode);
        }
    });
}

const useUpdateWebsite = () => {
    return useMutation(onUpdateWebsite, {
        onSuccess: () => {
            forceReloadWebsites();
        }
    });
}

const useRemoveNode = () => {
    return useMutation(onRemoveWebsite, {
        onSuccess: () => {
            forceReloadWebsites();
        }
    });
}

const useAddNewWebsite = () => {
    return useMutation(onAddNewWebsite, {
        onSuccess: () => {
            forceReloadWebsites();
        }
    });
}

export {
    getWebsitePlaylists,
    useAddPlaylist,
    getWebsites,
    useWebsites,
    useUpdateWebsite,
    useRemoveNode,
    useAddNewWebsite,
    usePlayList,
    useRemovePlayList,
    useUpdatePlaylist,
}