import React, { useState, useMemo, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getMoment } from "Libs/NwMoment";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Alert, Modal, Spin } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import BookingCalendarHeader from 'Components/Schedule/Calendar/BookingCalendarHeader';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import MonthEventCard from 'Components/Schedule/Calendar/MonthEventCard';
import EventCard from 'Components/Schedule/Calendar/EventCard';
import CalendarWrapper from 'Components/Schedule/Calendar/CalendarUI';
import AddNewEventModal from 'Components/Schedule/Calendar/ModelCalendar/AddNewEventModal';
import { NwButton } from 'Components/Gui/NwButton';
import NwIcon from 'Components/Gui/NwIcon';
import { eventPropGetter, modelCalendarParseEventsTimeGrid } from 'Libs/Calendar';
import { ConfigContext } from 'Contexts/ConfigContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useMoveTravel, useMoveGroupedTravel, forceReloadTravel } from 'Hooks/Event/UseTravel';
import { forceReloadFollowup, useMoveFollowup, useMoveGroupedFollowup } from 'Hooks/Event/UseFollowup';
import { forceReloadExternalJob, useMoveExternalJob } from 'Hooks/Event/UseExternalJob';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { compareAsISODate, isAllDay, toISODate } from 'Libs/NwMoment';
import { forceReloadEvent, useMoveBookingEvent } from 'Hooks/Event/UseEvent';
import { getAgendaDefaultDate, getCalendarDefaultDate, getScheduleEvents } from 'Components/Schedule/ScheduleUtils';
import { Loading } from 'Components/Gui/Loading';
import DailyCalendarDrawer from 'Components/Schedule/Calendar/DailyCalendarDrawer';
import ContactAvatar from 'Components/ContactAvatarGroup/ContactAvatar';
import ModelCalendarHeader from 'Components/Schedule/Calendar/ModelCalendarHeader';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import { UserContext } from 'Contexts/UserContext';
import { useStateValue } from 'Contexts/StateProvider';
import moment from 'moment';

const ModelBigCalendar = Calendar
const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const CalendarView = styled.div`
    position: relative;

    .ant-spin {
        max-height: unset !important;
    }
`;

const StyledAlert = styled(Alert)`
    position: absolute;
    z-index: 20;
    top: 40%;
    left: 50%;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 12%);
    transform: translate(-50%, -50%);

    .ant-alert-message {
        margin-right: 8px;
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: none;
        padding-bottom: 0;
    }

    .multi-event-modal-container {
        ul > li {
            font-weight: bold;
        }
        
        .multi-event-modal-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 5rem;
        }
    }
`;

const CalendarBefore = styled.div`
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .calendar-before-back {
        font-size: 2rem;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem 0 .25rem;
        margin-right: 1rem;
    }

    .calendar-before-avatar {
        cursor: pointer;
        padding-right: 1rem;
    }

    .calendar-before-name {
        cursor: pointer;
        h3 {
            font-size: 1.5rem;
            line-height: 1em;
            font-weight: bold;
            margin: 0 0 1rem 0;
            text-transform: capitalize;
        }
    }
`

const ModelCalendar = ({
    afterReload,
    bookingSection,
    calendarStyle,
    contactId,
    filters,
    onClearContactID,
    onReload,
    onScheduleRangeChange,
    params,
    previewMode,
    reload,
    scheduleRange,
    setTimeGrid
}) => {

    const [{ areaContext }] = useStateValue();
    const history = useHistory();
    
    const [selectedEvent, setSelectedEvent] = useState();
    const [showEventDrawer, setShowEventDrawer] = useState(false);
    const [showAddNewEventDialog, setShowAddNewEventDialog] = useState(false);
    const [defaultEventDate, setDefaultEventDate] = useState();
    const [allDay, setAllDay] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isEventDrawer, setIsEventDrawer] = useState(false);
    const [eventType, setEventType] = useState();
    
    // const [modelPeriod, setModelPeriod] = useLocalStorageState('model-calendar-period', {
    //     firstDate: getNow(),
    //     lastDate: getNow()
    // });
    // const [bookingPeriod, setBookingPeriod] = useLocalStorageState('booking-calendar-period', {
    //     firstDate: getNow(),
    //     lastDate: getNow()
    // });

    const { userGuiConfig } = useContext(ConfigContext);
    const { currentUser } = useContext(UserContext);

    const { mutateAsync: onMoveBookingEvent } = useMoveBookingEvent();
    const { mutateAsync: onMoveTravel } = useMoveTravel();
    const { mutateAsync: onMoveFollowUp } = useMoveFollowup();
    const { mutateAsync: onMoveExternalJob } = useMoveExternalJob();
    const { mutateAsync: onMoveGroupedTravel } = useMoveGroupedTravel();
    const { mutateAsync: onMoveGroupedFollowup } = useMoveGroupedFollowup();

    const [draggedEvent, setDraggedEvent] = useState();
    const breakpoints = useNwBreakPoints();
    //const [expanded, setExpanded] = useState(bookingSection);
    const [expanded, setExpanded] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [showMultiAddModal, setShowMultiAddModal] = useState(false);
    const [errorModal, setErrorModal] = useState("");

    const [changeableEvent, setChangeableEvent] = useState();
    const [isMoving, setIsMoving] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [modelDetailsDrawerID, setModelDetailsDrawerID] = useState(null)

    const [dayViewDate, setDayViewDate] = useState(false)

    // useEffect(() => {
    //     if (userGuiConfig.calendar.weekFormat === 'monday') {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 1,
    //                 doy: 1
    //             }
    //         });
    //     } else {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 0,
    //                 doy: 1
    //             }
    //         });
    //     }
    // }, [userGuiConfig.calendar.weekFormat])

    moment.locale('us', {
        week: {
            dow: 1,
            doy: 1
        }
    });

    useEffect(() => {
        if (scheduleRange) {
            fetchEvents()
        }
    }, [params, scheduleRange])

    useEffect(() => {
        if (scheduleRange && reload) {
            fetchEvents()
        }
    }, [reload])

    // const handleExpandCalendar = () => {
    //     setExpanded(prevExpanded => !prevExpanded)
    // };

    const fetchEvents = async () => {
        setIsFetching(true)
        const scheduleEvents = await getScheduleEvents(params, scheduleRange)
        setCalendarEvents(modelCalendarParseEventsTimeGrid(scheduleEvents))
        setIsFetching(false)
        afterReload()
    }

    const reloadCalendar = () => {
        //console.log("calendar has to be reloaded")
        fetchEvents()
    }

    const onSelectEvent = event => {
        if (event.ID && event.elType !== 'Package' && !previewMode) {
            setShowEventDrawer(true)
            setSelectedEvent(event)
        }
    }

    const handleCloseEventDrawer = () => {
        setShowEventDrawer(false)
        setSelectedEvent(null)
    }

    const afterRemoveEvents = () => {
        setShowEventDrawer(false)
        setSelectedEvent(null)
        reloadCalendar()
    }

    const handleSelectSlot = timeSlot => {
        setDefaultEventDate(timeSlot.start)
        if (timeSlot.slots.length === 1) {
            setAllDay(true)
        }
        setShowAddNewEventDialog(true)
    }

    const handleCloseAddNewEventDialog = () => {
        setDefaultEventDate()
        setAllDay(false)
        setShowAddNewEventDialog(false)
    }

    const handleRangeChange = (event) => {
        if (event.start && event.end) {
            onScheduleRangeChange(event.start, event.end)
        } else {
            onScheduleRangeChange(event[0], event[event.length - 1])
        }
    }

    const filteredEvents = useMemo(() => {
        let filteredEvents = [...calendarEvents]
        if (filters) {
            if (filters.filterAcknowledgement === 'checked') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'Checked')
            } else if (filters.filterAcknowledgement === 'tocheck') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'NotChecked')
            }
            if (filters.filterMine === 'mine') {
                //filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID);
                filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID || (event.Project && event.Project.UserCreated === currentUser.UserID));
            }
            if (filters.hideCancelled) {
                filteredEvents = filteredEvents.filter(event => !event.Cancel)
            }
            if (filters.hideBilling) {
                filteredEvents = filteredEvents.filter(event => !event.Locked && event.TypeName === 'Job' && event.Confirmed > 0);
            }
        }
        return filteredEvents
    }, [calendarEvents, filters])

    useEffect(() => {
        const isInvisibleEvent = filteredEvents.find(event => (getMoment(event.start).hour() < 6 || getMoment(event.end).hour() >= 22) && !event.allDay)
        if (isInvisibleEvent && !expanded) {
            setExpanded(true)
        }
    }, [filteredEvents])

    // const startDate = useMemo(() => {
    //     if (scheduleRange) {
    //         const startmoment = getMoment(scheduleRange.start)
    //         let minDate = getMoment({
    //             y: startmoment.year(),
    //             M: startmoment.month(), 
    //             d: startmoment.date(),
    //             h: expanded ? 0 : 6, 
    //             m: 0,
    //             s: 0
    //         });
    //         return minDate
    //     }
    // }, [expanded, scheduleRange])

    const handleCreateEvent = (type) => {
        setShowAddNewEventDialog(false)
        setEventType(type)
        setIsEventDrawer(true)
    }

    // drag and drop
    const handleDragStart = event => {
        setDraggedEvent(event)
    }

    const dragFromOutsideItem = () => {
        return draggedEvent
    }

    const onDropFromOutside = ({ start, end, allDay }) => {
        const event = {
            ID: draggedEvent.ID,
            start,
            end,
            allDay: allDay,
        }
        setDraggedEvent(draggedEvent)
        moveEvent({ event, start, end })
    }

    const handleUpdateOnlyCurrentModel = async () => {
        setIsMoving(true);
        try {
            await updateEvent(changeableEvent.event, changeableEvent.startDate, changeableEvent.endDate)
            setIsMoving(false)
            setShowMultiAddModal(false)
            setChangeableEvent()
        } catch (error) {
            setIsMoving(false)
        }
    };

    const handleUpdateAllModels = async () => {
        setIsMoving(true)
        if (changeableEvent.event.TypeName === 'Travel') {
            const travelData = {
                GroupGuid: changeableEvent.event.GroupGuid,
                StartDateTime: changeableEvent.startDate,
                ArrivalDateTime: changeableEvent.endDate,
                ID: changeableEvent.event.ID
            }
            try {
                await onMoveGroupedTravel({ travelData })
            } catch (error) {
                console.log('ant : Update All Models Error => ', error)
            }
        } else if (changeableEvent.event.TypeName === 'FollowUp') {
            const followupData = {
                GroupGuid: changeableEvent.event.GroupGuid,
                StartDate: changeableEvent.startDate,
                EndDate: changeableEvent.endDate,
                ID: changeableEvent.event.ID
            }

            try {
                await onMoveGroupedFollowup({ followupData });
            } catch (error) {
                console.log('ant : Update All Models Error => ', error);
            }
        }
        reloadCalendar()
        setIsMoving(false)
        setShowMultiAddModal(false)
        setChangeableEvent()
    };

    const updateEvent = async (event, startDate, endDate) => {
        switch (event.TypeName) {
            case "Casting":
            case 'Job':
                const eventData = {
                    NewFromDateTime: startDate,
                    NewToDateTime: endDate
                }
                if (event.EventDateModelID) {
                    eventData.EventDateModelID = event.EventDateModelID
                    await onMoveBookingEvent({ eventData })
                    reloadCalendar()
                    forceReloadEvent(event.ID)
                } else {
                    alert("error");
                }
                break;
            case 'Travel':
                const travelData = {
                    ID: event.ID,
                    StartDateTime: startDate,
                    ArrivalDateTime: endDate,
                };

                await onMoveTravel({ travelData });
                forceReloadTravel(event.ID)
                reloadCalendar()
                break;
            case 'FollowUp':
                const followupData = {
                    ID: event.ID,
                    StartDate: startDate,
                    EndDate: endDate
                };

                await onMoveFollowUp({ followupData });
                reloadCalendar()
                forceReloadFollowup(event.ID)
                break;
            case 'ExtJob':
                const extJobData = {
                    ID: event.ID,
                    StartDate: startDate,
                    EndDate: endDate,
                };

                await onMoveExternalJob({ externalJobData: extJobData });
                forceReloadExternalJob(event.ID)
                reloadCalendar()
                break;
            default:
                break;
        }
    };

    const moveEvent = async ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        let allDay = event.allDay
        if (calendarEvents.find(existingEvent => existingEvent.ID === event.ID &&
            toISODate(existingEvent.start) !== toISODate(event.start)
            && toISODate(existingEvent.start) === toISODate(start))) {
            setShowAlert(true);

            setTimeout(() => {
                setShowAlert(false);
            }, [4000]);
        } else {
            if (!event.allDay && droppedOnAllDaySlot) {
                allDay = true
            } else if (event.allDay && !droppedOnAllDaySlot) {
                allDay = false
                setErrorModal("You can't move an all day event to a specific time slot. Please change the time details in the edit event drawer.")
                return null
            }

            //const startDate = moment.utc(Date.UTC(getMoment(start).year(), getMoment(start).month(), getMoment(start).date(), allDay ? 0 : getMoment(start).hour(), allDay ? 0 : getMoment(start).minute(), 0));
            //const endDate = moment.utc(Date.UTC(getMoment(end).year(), getMoment(end).month(), getMoment(end).date(), allDay ? 23 : getMoment(end).hour(), allDay ? 59 : getMoment(end).minute(), allDay ? 59 : 0));
            const startDate = allDay ? getMoment(start).startOf("day") : getMoment(start)
            const endDate = allDay ? getMoment(end).endOf("day") : getMoment(end)


            const nextEvents = calendarEvents.map(existingEvent => {
                return existingEvent.ID === event.ID && toISODate(existingEvent.start) === toISODate(event.start)
                    ?
                    {
                        ...existingEvent,
                        start,
                        end,
                        allDay
                    }
                    : existingEvent
            })

            setCalendarEvents(nextEvents);
            if (event.GroupGuid) {
                setIsFetching(true);
                const groupedEvent = await Axios.get(`/${event.TypeName}s/${event.ID}`);
                setIsFetching(false);

                setChangeableEvent({
                    event: {
                        ...event,
                        ModelID: groupedEvent.ModelID
                    },
                    startDate,
                    endDate,
                    relatedModelNames: groupedEvent.RelatedEvents.map(item => getContactName(item.ModelID))
                })
                setShowMultiAddModal(true);
            } else {
                updateEvent(event, startDate, endDate);
            }
        }
    }

    const resizeEvent = async ({ event, start, end }) => {
        const startDate = allDay ? getMoment(start).startOf("day") : getMoment(start)
        //const startDate = moment.utc(Date.UTC(getMoment(start).year(), getMoment(start).month(), getMoment(start).date(), allDay ? 0 : getMoment(start).hour(), allDay ? 0 : getMoment(start).minute(), 0));
        const endDate = allDay ? getMoment(end).endOf("day") : getMoment(end)
        //const endDate = moment.utc(Date.UTC(getMoment(end).year(), getMoment(end).month(), getMoment(end).date(), allDay ? 23 : getMoment(end).hour(), allDay ? 59 : getMoment(end).minute(), allDay ? 59 : 0));
        const isAllDay = getMoment(end).hour() === 0 && getMoment(end).minute() === 0;

        const nextEvents = calendarEvents.map(existingEvent => {
            return existingEvent.ID === event.ID && compareAsISODate(existingEvent.start, event.start)
                ? {
                    ...existingEvent,
                    start,
                    end
                }
                : existingEvent
        })

        setCalendarEvents(nextEvents);
        if (event.GroupGuid) {
            setIsFetching(true);
            const groupedEvent = await Axios.get(`/${event.TypeName}s/${event.ID}`);
            setIsFetching(false);

            setChangeableEvent({
                event: {
                    ...event,
                    ModelID: groupedEvent.ModelID
                },
                startDate,
                endDate: isAllDay ? getMoment(endDate).subtract(1, 'minute') : endDate,
                relatedModelNames: groupedEvent.RelatedEvents.map(item => getContactName(item.ModelID))
            })
            setShowMultiAddModal(true);
        } else {
            updateEvent(event, startDate, isAllDay ? getMoment(endDate).subtract(1, 'minute') : endDate);
        }
    }

    const switchToAgenda = () => {
        const agendaSelectedDate = getAgendaDefaultDate(scheduleRange)
        onScheduleRangeChange(agendaSelectedDate.toDate(), agendaSelectedDate.toDate())
    }

    const getDailyDrawerScheduleRange = () => {
        return { start: dayViewDate, end: dayViewDate }
    }

    const getCalendarHeader = (toolbar) => {
        if (bookingSection) {
            return (<BookingCalendarHeader
                calendarStyle={calendarStyle}
                model
                onSwitchToAgenda={switchToAgenda}
                params={params}
                previewMode={previewMode}
                setTimeGrid={setTimeGrid}
                timeGrid={true}
                toolbar={toolbar}
            />)
        } else {
            return (<ModelCalendarHeader
                calendarStyle={calendarStyle}
                onSwitchToAgenda={switchToAgenda}
                previewMode={previewMode}
                toolbar={toolbar}
            />)
        }
    }

    const openModelDrawer = () => {
        setModelDetailsDrawerID(contactId)
    }

    const isEventDraggable = (event) => {
        if (event.TypeName === 'Accommodation' || event.TypeName === "Notice") {
            return false
        }
        return true
    }

    const goToModelPage = () => {
        if (areaContext.area === 'booking') {
            history.push(`/models/${contactId}`);
        } else {
            history.push(`/${areaContext.area}-models/${contactId}`);
        }
    };

    return (
        <CalendarWrapper
            showTimeGrid={calendarStyle === 'week'}
            breakpoints={breakpoints}
            agendaWeeklyView={!expanded && calendarStyle === 'week'}
            minHeight={bookingSection ? null : 'calc(100vh - 25rem)'}
            bookingSection={bookingSection}
        >
            <CalendarView className='general-calendar model-calendar agenda-view'>
                {showAlert &&
                    <StyledAlert message="You can't move the event to this date" type="error" closable afterClose={() => setShowAlert(false)} />
                }
                {(scheduleRange && scheduleRange.start && scheduleRange.end)
                    ?
                    <Spin spinning={isFetching}>
                        {bookingSection &&
                            <CalendarBefore>
                                <div className="calendar-before-back">
                                    <NwIcon pointer icon={light('arrow-left-to-line')} onClick={onClearContactID} />
                                </div>
                                <div className="calendar-before-avatar">
                                    <ContactAvatar onClick={goToModelPage} contactID={contactId} />
                                </div>
                                <div className="calendar-before-name">
                                    <h3 onClick={goToModelPage}>{getContactName(contactId).toLowerCase()}</h3>
                                    <NwIcon pointer icon={light('sidebar-flip')} onClick={openModelDrawer} />
                                </div>
                            </CalendarBefore>
                        }
                        {calendarStyle === 'month'
                            ?
                            <ModelBigCalendar
                                popup
                                formats={{
                                    timeGutterFormat: userGuiConfig.calendar.timeFormat === '24' ? 'H:mm' : 'h:mm a'
                                }}
                                selectable="ignoreEvents"
                                events={filteredEvents}
                                step={30}
                                timeslots={2}
                                localizer={localizer}
                                //scrollToTime={getMoment(startDate).toDate()}
                                views={{ week: true, month: true }}
                                defaultView={calendarStyle}
                                tooltipAccessor={null}
                                components={{
                                    event: (event) => <MonthEventCard model event={event.event} />,
                                    toolbar: (toolbar) => getCalendarHeader(toolbar)
                                }}
                                defaultDate={getCalendarDefaultDate(scheduleRange).toDate()}
                                onSelectEvent={onSelectEvent}
                                onSelectSlot={handleSelectSlot}
                                onRangeChange={handleRangeChange}
                                eventPropGetter={eventPropGetter}
                                onDrillDown={value => {
                                    setDayViewDate(value)
                                }}
                            />
                            :
                            <DragAndDropCalendar
                                popup
                                formats={{
                                    timeGutterFormat: userGuiConfig.calendar.timeFormat === '24' ? 'H:mm' : 'h:mm a'
                                }}
                                events={filteredEvents}
                                step={30}
                                timeslots={2}
                                localizer={localizer}
                                //scrollToTime={getMoment(startDate).toDate()}
                                views={{ week: true, month: true }}
                                defaultView={calendarStyle}
                                tooltipAccessor={null}
                                components={{
                                    event: (event) => <EventCard
                                        event={{
                                            ...event.event,
                                            //FromDate: convertDateToUtc(event.event.start),
                                            //ToDate: convertDateToUtc(event.event.end),
                                            //allDay: isAllDay(convertDateToUtc(event.event.start), convertDateToUtc(event.event.end))
                                            FromDate: getMoment(event.event.start),
                                            ToDate: getMoment(event.event.end),
                                            allDay: isAllDay(getMoment(event.event.start), getMoment(event.event.end))
                                        }}
                                        model
                                        agendaView />,
                                    toolbar: (toolbar) => getCalendarHeader(toolbar)
                                }}
                                defaultDate={getCalendarDefaultDate(scheduleRange).toDate()}
                                onSelectEvent={onSelectEvent}
                                onSelectSlot={handleSelectSlot}
                                onRangeChange={handleRangeChange}
                                eventPropGetter={eventPropGetter}
                                resizableAccessor={event => (event.TypeName === 'Accommodation' || ((event.TypeName === 'Casting' || event.TypeName === 'Job') && event.allDay))
                                    ? false : true}
                                draggableAccessor={isEventDraggable}
                                selectable
                                onDropFromOutside={onDropFromOutside}
                                handleDragStart={handleDragStart}
                                dragFromOutsideItem={dragFromOutsideItem}
                                onEventDrop={moveEvent}
                                resizable
                                onEventResize={resizeEvent}
                                onDrillDown={value => {
                                    setDayViewDate(value)
                                }}
                            />
                        }
                    </Spin>
                    :
                    <Loading />
                }
            </CalendarView>
            {dayViewDate &&
                <DailyCalendarDrawer
                    params={params}
                    filters={filters}
                    scheduleRange={getDailyDrawerScheduleRange()}
                    // reload={reload}
                    // onReload={onReload}
                    // afterReload={afterReload}
                    // onSelectEvent={onSelectEvent}
                    onClose={() => setDayViewDate(null)}
                />
            }
            {showEventDrawer && selectedEvent &&
                <EventDrawer
                    eventType={selectedEvent.TypeName}
                    eventID={selectedEvent.ID}
                    modelId={contactId}
                    eventObject={selectedEvent}
                    onClose={handleCloseEventDrawer}
                    afterUpdate={() => {
                        fetchEvents()
                    }}
                    afterRemove={afterRemoveEvents} />
            }
            {showAddNewEventDialog &&
                <AddNewEventModal
                    contactId={contactId}
                    defaultEventDate={defaultEventDate}
                    calendarEvents={calendarEvents}
                    onCreateEvent={handleCreateEvent}
                    onFetchEvents={fetchEvents}
                    onCloseModal={handleCloseAddNewEventDialog} />
            }
            {isEventDrawer &&
                <EventDrawer
                    eventType={eventType.type}
                    show={true}
                    modelId={contactId}
                    eventInfo={eventType.eventInfo}
                    viewMode='create'
                    allDay={allDay}
                    dateSelected={getMoment(defaultEventDate)}
                    onClose={() => {
                        setIsEventDrawer(false);
                        setEventType();
                        setDefaultEventDate();
                        setAllDay(false);
                    }}
                    afterUpdate={() => {
                        fetchEvents();
                    }}
                    afterRemove={() => {
                        setIsEventDrawer(false);
                        fetchEvents();
                        setDefaultEventDate();
                        setAllDay(false);
                    }} />
            }
            {errorModal &&
                <StyledModal
                    open
                    width={480}
                    title="Error on drag"
                    footer={null}
                    closable
                    onCancel={() => setErrorModal("")}
                >
                    <div className="multi-event-modal-container">
                        <p>{errorModal}</p>
                    </div>
                </StyledModal>
            }
            {showMultiAddModal && changeableEvent &&
                <StyledModal
                    open
                    width={480}
                    title="Multiple talents event"
                    footer={null}
                    closable={false}
                >
                    <div className="multi-event-modal-container">
                        <p>This event is also on:</p>
                        <ul>
                            {changeableEvent.relatedModelNames.map((model, index) => (
                                <li key={index}>{model}</li>
                            ))}
                        </ul>
                        <p>Do you want to update this event only for {getContactName(changeableEvent.event.ModelID)} or for all models?</p>
                        <div className="multi-event-modal-buttons">
                            <NwButton
                                primary
                                loading={isMoving}
                                disabled={isMoving}
                                label={`update only for ${getContactName(changeableEvent.event.ModelID)}`}
                                onClick={handleUpdateOnlyCurrentModel} />
                            <NwButton
                                primary
                                loading={isMoving}
                                disabled={isMoving}
                                label='update for all models'
                                onClick={handleUpdateAllModels} />
                        </div>
                    </div>
                </StyledModal>
            }
            {modelDetailsDrawerID &&
                <ModelDrawer
                    modelId={modelDetailsDrawerID}
                    onClose={() => setModelDetailsDrawerID(null)}
                />
            }
        </CalendarWrapper>
    )
}

export default ModelCalendar
