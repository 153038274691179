import styled from 'styled-components';

export const PackageDrawerContainer = styled.div`
    .package-drawer-header-container {
        .title-card-wrapper {
            @media only screen and (max-width: 720px) {
                .ant-card-actions {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: none;
                    li {
                        width: 100% !important;
                        border-right: none;
                        display: flex;
                    }
                }
            }

            .ant-card-head-title {
                padding: 1.5rem 0;
                font-size: 1.25rem;

                .nw-card-head-title-content {
                    h1 {
                        font-size: 1.5rem;
                    }
                }
            }

            .package-header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0;

                @media only screen and (max-width: 720px) {
                    flex-direction: column;
                }

                .project-selector-wrapper {
                    border-right: ${props => props.theme.DefaultBorder};
                    padding-right: 1rem;
                    margin-right: 1rem;
                    @media only screen and (max-width: 720px) {
                        border-right: none;
                    }
                }
                
                h1 {
                    flex: 1 1 0;
                    font-size: 1.4rem;
                    font-weight: bold;
                }
            }
        }
        .steps-wrapper {
            flex: 1 1 0;
            margin: .25rem 1rem .5rem 1rem;

            .ant-steps-small {
                .ant-steps-item-custom {
                    &.step-disabled {
                        opacity: .6;
                    }
                    &.step-warning {
                        .ant-steps-item-icon {
                            & > .ant-steps-icon {
                                color: ${props => props.theme.DangerColor} !important;
                            }
                        }
                    }
                    .ant-steps-item-icon {
                        & > .ant-steps-icon {
                            font-size: 1.5rem;
                        }
                    }
                    .ant-steps-item-content {
                        .ant-steps-item-title {
                            font-size: .9rem;
                        }
                        .ant-steps-item-description {
                            font-size: .85rem;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }

    .package-drawer-recipients-container {
        margin: 1.5rem 0 1.5rem 0;
        @media only screen and (max-width: 720px) {
            margin: 0;
        }
        .ant-card-head {
            .ant-card-head-title {
                .nw-card-head-title {
                    font-size: 1.25rem;
                    .nw-card-head-title-content {
                        h1 {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }

        .package-drawer-recipient {
            max-width: 120rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: .5rem 1rem;

            p {
                margin: 0;
                line-height: 1.2em;
            }

            .package-drawer-recipient-icon {
                width: 3rem;
                
                .recipient-icon {
                    font-size: 1.5rem;
                    opacity: .4;
                    .fa-primary {
                        fill: ${props => props.theme.TextColor};
                        opacity: 0;
                    }
                    .fa-secondary {
                        fill: ${props => props.theme.TextColor};
                        opacity: .5;
                    }

                    &.recipient-icon-done {
                        opacity: 1;
                        .fa-primary {
                            fill: #fff;
                            opacity: 1;
                        }
                        .fa-secondary {
                            fill: ${props => props.theme.SuccessColor};
                            opacity: 1;
                        }
                    }
                }
            }
            .package-drawer-recipient-email {
                padding-right: 2rem;
                min-width: 20rem;
                p {
                    font-weight: bold;
                }
            }
            .package-drawer-recipient-date {
                min-width: 14rem;
                p {
                    text-transform: uppercase;
                    font-size: ${props => props.theme.FontSizeSm};

                    &.not-visited {
                        font-style: italic;
                        color: ${props => props.theme.TextColorMuted};
                        svg {
                            margin-right: .5rem;
                        }
                    }
                }
            }
            .package-drawer-recipient-link {
                display: flex;
                align-items: center;
                min-width: 10rem;
                flex: 1 1 0;
                svg {
                    margin-right: .25rem;
                }
                p {
                    text-transform: uppercase;
                    font-size: ${props => props.theme.FontSizeSm};
                    margin-right: 1rem;
                }
            }
            .package-drawer-recipient-code {
                display: flex;
                align-items: center;
                min-width: 10rem;
                p {
                    text-transform: uppercase;
                    font-size: ${props => props.theme.FontSizeSm};
                    padding-right: .5rem;
                }
                span {
                    margin-bottom: 0;
                }
            }
        }
    }

    .package-drawer-models-container {
        margin: 1rem 0;
        
        @media only screen and (max-width: 720px) {
            margin: 0;
        }

        >.ant-card {
            >.ant-card-head {
                background-color: #fff;
                border-radius: 4px;
                position: sticky;
                top: -1.75rem;
                z-index: 9999;
                min-height: 5rem;

                .ant-card-head-title {
                    .nw-card-head-title {
                        font-size: 1.25rem;
                        .nw-card-head-title-content {
                            h1 {
                                font-size: 1.25rem;
                            }
                        }
                    }
                }
                .ant-card-extra {
                    .actions-group-holder {
                        padding-top: .75rem;

                        .selected-models-actions {
                            padding: 0 4rem 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 2rem;
                            
                            small {
                                display: block;
                                margin-bottom: 0;
                                line-height: 1em;
                                font-size: .8rem;
                            }

                            .ant-btn-sm {
                                font-size: .8rem;
                            }
                        }
                    }
                }
            }
            >.ant-card-body {
                >.ant-card-body-content {
                    padding: .5rem 0 0 0;
                }
            }
        }

        .package-group {
            margin: 0 0 .5rem 2rem;
            .package-group-wrapper {

                >.ant-card {
                    >.ant-card-head {
                        background-color: #fff;
                        border-radius: 4px;
                        .ant-card-head-title {
                            .nw-card-head-title {
                                .nw-card-head-title-content {
                                    h1 {
                                        font-size: 1.15rem;
                                    }
                                }
                            }
                        }
                    }
                    >.ant-card-body {
                        >.ant-card-body-content {
                            padding: .5rem 0 0 0;
                        }
                    }
                }

                .package-group-models {
                    margin: .5rem 0 1.5rem 2rem;
            
                    .drag-container {
                        padding-left: 0px;
                        
                        .package-model-draggable {
                            margin-bottom: .5rem;
                            display: flex;                
                            
                            .package-model-card-wrapper {
                                flex: 1 0 0;
                                
                                .ant-card-head-title {
                                    padding: .75rem 0;
                                    @media only screen and (max-width: 720px) {
                                        margin-right: auto;
                                    }

                                    .nw-card-head-title-content {
                                        h1 {
                                            font-size: 1.1rem;
                                        }
                                    }
                                }
                                .ant-card-head-wrapper {
                                    @media only screen and (max-width: 720px) {
                                        flex-direction: column;
                                        align-items: left !important;
                                    }
                                }
                                .ant-card-extra {
                                    @media only screen and (max-width: 720px) {
                                        margin-left: 0;
                                        margin-right: auto;
                                    }
                                }

                                .package-drawer-model-card-content {
                                    
                                    p.package-drawer-model-note {
                                        padding: .5rem 1rem;
                                        font-size: .9rem;
                                        font-style: italic;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }

    .actions-group-holder {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
    }
`;