import styled from 'styled-components';

const CalendarCardWrapper = styled.div`
    .ant-card {   
        margin: ${props => props.bookingSection ? '1rem 1.25rem 2rem 1rem' : 'inherit'};

        .ant-card-body-content {
            min-height: unset !important;

            .general-calendar {
                padding-bottom: 0;
            }
        }
    }

    .calendar-fixed-footer {
        position: fixed;
        bottom: 1rem;
        right: .4rem;
        width:  ${props => props.hasSidebar ? 'calc(100vw - 22rem)' : 'calc(100vw - 7.5rem)'};
        background-color: #fff;
        border: 2px solid black;
        box-shadow: 0px 2px 1rem rgba(0, 0, 0, .65);
        border: ${props => props.theme.DefaultBorder};
        min-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }
`;

export default CalendarCardWrapper