import React, { useContext, useState, useRef } from "react";
import { Upload, notification } from "antd";
import styled from 'styled-components';

import { UserContext } from "Contexts/UserContext";
import { GetTimestamp } from "Libs/NwMoment";
import { NwUploadButton } from "Components/Gui/NwButton";
import { getBaseUrl } from "Libs/NwUtils";
import NwIcon from "./NwIcon";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const Container = styled.div`
    .ant-upload-list {
        display: ${props => props.disable && 'none'}
    }
    .ant-upload {
        display: ${props => props.hide && 'none'}
    }
`;

const DescriptionList = styled.ul`
    padding-left: 20px;
`;

const NwUpload = ({
  onUploadStart,
  onShowInvalidFiles,
  onUploadEnd,
  onUploadError,
  endpoint,
  maxSize,
  types = [],
  showList = false,
  disable,
  hide,
  primary,
  label,
  children
}) => {
  const { currentUser } = useContext(UserContext);
  const authorization = { Authorization: "Bearer " + currentUser.Token };
  const [generatedbatchid, setGeneratedbatchid] = useState(null);
  // const [batchcounter, setBatchcounter] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const uploadRef = useRef();

  const generateUploadToken = () => {
    if (!generatedbatchid) {
      setGeneratedbatchid("UPL_" + currentUser.UserID + "_" + GetTimestamp());
    }
    return (getBaseUrl() + "/" + endpoint + "&batchID=" + generatedbatchid);
  };

  const checkIsValidSize = file => {
    const sizeOk = file.size / 1024 / 1024 < maxSize;
    return sizeOk;
  };

  const checkIsValidFile = file => {
    const accept = []
    let acceptall = false
    types.forEach(el => {
      if (el === "image") {
        accept.push("image/jpeg");
        accept.push("image/png");
        accept.push("image/gif");
      }
      if (el === "document") {
        accept.push("application/pdf");
      }
      if (el === "data-document") {
        accept.push('text/csv');
        accept.push('application/vnd.ms-excel');
        accept.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      }
      if (el === "archive-document") {
        accept.push("application/zip");
        accept.push("application/x-rar-compressed");
      }
      if (el === "office-document") {
        accept.push('application/msword');
        accept.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        accept.push('application/vnd.ms-powerpoint');
        accept.push('application/vnd.openxmlformats-officedocument.presentationml.presentation');
      }
      if (el === "text-document") {
        accept.push('text/plain');
      }
      if (el === "video") {
        accept.push("video/quicktime");
        accept.push("video/mp4");
        accept.push("video/m4v");
        accept.push("video/mkv");
      }
      if (el === "all") {
        acceptall = true
      }
    })
    const typeOk = acceptall || accept.includes(file.type);
    return typeOk;
  };

  const beforeUpload = (file, fileList) => {
    if (file.uid === fileList[fileList.length - 1].uid) {
        const invalidFiles = fileList.filter(file => !checkIsValidSize(file) || !checkIsValidFile(file)).map(file => {
            return {
                ...file,
                name: file.name,
                reason: !checkIsValidSize(file) ? 'is too big' : 'is not valid'
            }
        });

        setInvalidFiles(invalidFiles);
        if (onShowInvalidFiles) {
            onShowInvalidFiles(invalidFiles);
        }
    }
    if (!checkIsValidSize(file)){
      return false
    }
    if (!checkIsValidFile(file)){
      return false
    }
    return true
  }

  const handleUploadChange = async info => {
    const fileList = info.fileList.filter(file => checkIsValidSize(file) && checkIsValidFile(file)); //[...info.fileList];

    setFileList(fileList);
    if (info.file.status === "uploading") {
      onUploadStart && onUploadStart(info);
      setUploading(true);
      // setBatchcounter(fileList.length);
      return;
    }

    if (info.file.uid === info.fileList[info.fileList.length - 1].uid) {
        if (fileList.length === 0) {
            const args = {
                message: `${info.fileList.length} file(s) not uploaded`,
                description: (
                        <DescriptionList>
                            {info.fileList.map(file => <li key={file.uid}>{`${file.name} ${!checkIsValidSize(file) ? 'is too big' : 'is not valid'}`}</li>)}
                        </DescriptionList>  
                    ),
                duration: 0,
                icon: <NwIcon icon={solid("circle-xmark")}/>
              };
              notification.error(args);
              setGeneratedbatchid(null);
              // setBatchcounter(0);
              setUploading(false);
              setFileList([]);
              setInvalidFiles([]);
              onUploadEnd();
        }
    }

    if (info.file.status === "done") {

      let checkIfAllFilesUploaded = true;
      for (var uploadingfile of fileList) {
        if (uploadingfile.status !== "done") {
          checkIfAllFilesUploaded = false;
        }
      }
      if (checkIfAllFilesUploaded) {
        //check if some errors occurs on the server (encoding or resizing)
        const filesWithError = fileList.filter(file => file.response && file.response.length && file.response[0].error)
        if (filesWithError.length > 0) {
          const args = {
            message: `${filesWithError.length} file(s) not uploaded`,
            description: (
                  <DescriptionList>
                      {filesWithError.map(file => <li key={file.uid}><strong>{file.name}</strong><br/><small><em>{file.response[0].error}</em></small></li>)}
                  </DescriptionList>  
              ),
            duration: 0,
            style: {width: '34rem'},
            icon: <NwIcon icon={solid("circle-xmark")}/>
          };
          notification.error(args)
        }

        try {
            setGeneratedbatchid(null);
            // setBatchcounter(0);
            setUploading(false);
            setFileList([]);
            setInvalidFiles([]);
            onUploadEnd();
        } catch (error) {
            console.log('ant : Get Documents Error => ', error);
            onUploadError();
        }
        if (invalidFiles.length > 0) {
            const args = {
              message: `${invalidFiles.length} file(s) not uploaded`,
              description: (
                    <DescriptionList>
                        {invalidFiles.map(file => <li key={file.uid}><strong>{file.name}</strong><br/><small><em>{file.reason}</em></small></li>)}
                    </DescriptionList>  
                ),
              duration: 0,
              style: {width: '34rem'},
              icon: <NwIcon icon={solid("circle-xmark")}/>
            };
            notification.error(args);
        }
        
      }
    }
  };

  return (
    <Container ref={uploadRef} disable={disable} hide={hide}>
        <Upload
            name="files"
            showUploadList={showList}
            listType="picture"
            action={generateUploadToken()}
            beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
            onChange={handleUploadChange}
            multiple={true}
            defaultFileList={fileList}
            fileList={fileList}
            headers={authorization} 
        >
            {children ? children
            : <NwUploadButton disabled={disable} label={label} ghost={!primary} primary={primary} size="small" loading={uploading} />
            }
        </Upload>
    </Container>
  );
};

export default NwUpload;
