import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import ProjectPage from 'Components/Project/ProjectPage';

const Actions = styled(FlexContainer)`
    svg {
        margin-left: 8px;
    }
`;

const ProjectDrawer = ({projectId, onClose}) => {
    const breakpoints = useNwBreakPoints();
    const history = useHistory();

    const handleMoveProjectDetails = () => {
        history.push(`/bookings/${projectId}`);
    }

    return (
        <NwDrawer
            width={"90vw"}
            title={
                <FlexContainer justifyContent='space-between'>
                    <div>Project Preview</div>
                    {breakpoints > 3 &&
                        <Actions>
                            <NwIcon icon={solid('expand-wide')} pointer onClick={handleMoveProjectDetails} size='lg' />
                        </Actions>
                    }
                </FlexContainer>
            }
            onClose={onClose}
            whiteBg={false}
            padding={breakpoints<4?"0":"24px"}
        >
            <ProjectPage
                isdrawer={true}
                projectId={projectId}
                onClose={onClose}
            />
        </NwDrawer>
    )
}

export default ProjectDrawer;