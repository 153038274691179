import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Popover, Input, Spin, Button } from 'antd';
import Axios from 'axios';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import NwIcon from 'Components/Gui/NwIcon';
import RadioButtonGroup from 'Components/Gui/NWForm/RadioButtonGroup';
import { NwButton, NwButtonGroup } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import OptionNumber from 'Components/EventDrawer/EventDrawerUi/OptionNumber';
import MiniLoading from 'Components/Gui/MiniLoading';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import StyleVariables from 'Components/Gui/StyleVariables';
import VerticalDate from 'Components/Dates/VerticalDate';

const Header = styled.div`
    border-bottom: 1px solid #e8e8e8;
    background-color: ${props => props.theme.LayoutBodyBackground};
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    p.n-selected {
        margin: 0;
    }
    div.select-actions {
        min-width: 11rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
`;

const Footer = styled.div`
    padding: 24px 16px;
    border-top: 1px solid #e8e8e8;
    position: fixed;
    bottom: 0;
    height: 8rem;
    background: white;
    z-index: 1;
    width: 100%;
`;

const DatesContainer = styled.div`
    .date {
        display: flex;
        width: 100%;
        border-bottom: ${props => props.theme.DefaultBorder};

        .models-list {
            flex: 1 1 0;
            padding: 1rem 2rem;
            border-left: ${props => props.theme.DefaultBorder};
            .model {
                margin: 0.5rem 0;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                .model-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    p {
                        margin: 0 0 0 .5rem;
                        text-transform: capitalize;
                        svg {
                            margin-right: .5rem;
                        }
                    }
                }
                .model-right {

                }
                
                &.model-cancelled {
                    .model-left {
                        p {
                            ${props => props.theme.RedLineThrough(1)}
                        }
                    }
                }
            }
        }
    }
`

const CancellationWrapper = styled.div`
    width: 240px;
    button {
        border-radius: 6px !important;
        border-right: 1px solid ${props => props.theme.LighterBorderColor} !important;
        font-size: 1rem !important;
    }
`;

const ButtonGroupWrapper = styled.div`
    margin-right: 16px;
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-right: 1px solid #d9d9d9 !important;
    }
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;

const SelectLink = styled.div`
    color: ${props => props.theme.PrimaryColor};
    text-decoration: underline;
    cursor: pointer;
    font-size: .95rem;
    font-weight: normal;
`;

const ManageModelsAndDates = ({ eventData, eventDates, eventDatesModels, afterUpdate }) => {

    const [selectedModelIds, setModelIds] = useState([]);
    // const [optionNumber, setOptionNumber] = useState(0);
    const optionNumber = 0;
    const [customerType, setCustomerType] = useState('Client');
    const [notes, setNotes] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSelectModel = modelId => event => {
        if (event.target.checked) {
            setModelIds(prevModelIds => {
                return [...prevModelIds, modelId];
            });
        } else {
            setModelIds(prevModelIds => {
                return prevModelIds.filter(eventModelId => eventModelId !== modelId);
            });
        }
    };

    const handleUpdateAcknowledgement = value => async () => {
        if (selectedModelIds.length > 0) {
            setLoading(true);
            try {
                await Axios.put(`/eventdatesmodels/AcknowledgeMulti/${value ? 'Checked' : 'NotChecked'}`, selectedModelIds);
                await afterUpdate();
                setModelIds([]);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('ant : Update Acknowledgement Error => ', error);
            }
        }
    };

    const handleUpdateOptionNumber = async optionNumber => {
        if (selectedModelIds.length > 0) {
            setLoading(true);
            try {
                await Axios.put(`/eventdatesmodels/UpdateOptionNumberMulti`, {
                    OptionNumber: optionNumber,
                    EventDatesModelsIDs: selectedModelIds
                });
                await afterUpdate();
                setModelIds([]);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('ant : Update Acknowledgement Error => ', error);
            }
        }
    };

    const handleCancel = cancel => async () => {
        if (selectedModelIds.length > 0) {
            setLoading(true);
            try {
                await Axios.put('/eventdatesmodels/Cancel', {
                    Cancel: {
                        Cancel: cancel,
                        CancelledBy: cancel ? customerType : '',
                        CancellationNote: cancel ? notes : ''
                    },
                    EventDateModelsIDs: selectedModelIds
                });
                await afterUpdate();
                setModelIds([]);
                setNotes('');
                setCustomerType('Client');
                setVisible(false);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('ant : Update Acknowledgement Error => ', error);
            }
        }
    };

    const handleSelectAll = () => {
        setModelIds(eventDatesModels.map(eventDateModel => eventDateModel.ID));
    };

    const handleDeselectAll = () => {
        setModelIds([]);
    };

    const handleSelectDateModels = eventDateModels => () => {
        const newModelIds = [...selectedModelIds, ...eventDateModels.map(model => model.ID)];
        setModelIds(newModelIds);
    };

    const handleDeselectDateModels = eventDateModels => () => {
        setModelIds(prevModelIds => prevModelIds.filter(modelId => !eventDateModels.find(dateModel => dateModel.ID === modelId)));
    };

    const handleSelectAllModels = modelId => () => {
        const sameModelIds = eventDatesModels.filter(model => model.ModelID === modelId).map(model => model.ID);
        const newModelIds = [...selectedModelIds, ...sameModelIds];
        setModelIds(newModelIds);
    };

    const handleDeselectAllModels = modelId => () => {
        const modelIds = eventDatesModels.filter(dateModel => dateModel.ModelID === modelId).map(model => model.ID);
        setModelIds(prevModelIds => prevModelIds.filter(modelId => !modelIds.includes(modelId)));
    };

    return (
        <>
            <Header>
                {loading ?
                    <MiniLoading />
                    :
                    <>
                        <p className="n-selected">{selectedModelIds.length} model dates selected</p>
                        <div className="select-actions">
                            <SelectLink onClick={handleSelectAll}>select all</SelectLink>
                           <NwDividerVertical />
                            <SelectLink onClick={handleDeselectAll}>deselect all</SelectLink>
                        </div>
                    </>
                }
            </Header>
            {eventData.EventDates.length > 0 &&
            <Spin spinning={loading}>
                <DatesContainer>
                    {eventData.EventDates.filter(eventDate => eventDate.EventDatesModels.length).map((eventDate) => {
                        return (
                            <div className="date" key={eventDate.ID}>
                                <VerticalDate minWidth="5rem" date={eventDate.FromDate} showDay />
                                <div className="models-list">
                                    {eventDate.EventDatesModels.map(model => {
                                        const modelChecked = selectedModelIds.find(modelId => modelId === model.ID);
                                        const modelIds = eventDatesModels.filter(dateModel => dateModel.ModelID === model.ModelID).map(model => model.ID);
                                        const isModelAddedToAllDates = modelIds.every(val => selectedModelIds.includes(val));
                                        return (
                                            <div className={`model${model.Cancel ? ' model-cancelled' : ''}`} key={model.ID}>
                                                <div className="model-left">
                                                    <Checkbox checked={modelChecked ? true : false} onChange={handleSelectModel(model.ID)} />
                                                    <p>
                                                        {model.Acknowledgment === 'Checked' &&
                                                            <NwIcon success icon={solid('check')} />
                                                        }
                                                        {getContactName(model.ModelID).toLowerCase()}
                                                    </p>
                                                    <OptionNumber
                                                        readOnly
                                                        key={model.OptionNumber}
                                                        number={model.OptionNumber}
                                                        event={eventData}
                                                        eventDateInfo={eventDate}
                                                        selectedModel={model}
                                                        eventDatesModel={model}
                                                    />
                                                </div>
                                                <div className="model-right">
                                                    {modelChecked &&
                                                        <FlexContainer mg='0 0 0 8px'>
                                                            <SelectLink onClick={isModelAddedToAllDates ? handleDeselectAllModels(model.ModelID) : handleSelectAllModels(model.ModelID)}>
                                                                {isModelAddedToAllDates ? 'deselect' : 'select'} on all dates
                                                            </SelectLink>
                                                        </FlexContainer>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <FlexContainer mg='16px 0 0'>
                                        <SelectLink onClick={handleSelectDateModels(eventDate.EventDatesModels)}>select all</SelectLink>
                                       <NwDividerVertical />
                                        <SelectLink onClick={handleDeselectDateModels(eventDate.EventDatesModels)}>deselect all</SelectLink>
                                    </FlexContainer>
                                </div>
                            </div>
                        )
                    })}
                </DatesContainer>
            </Spin>
            }
            <Footer>
                <FlexContainer>
                    <NwButtonGroup size="small">
                        <NwButton
                            disabled={loading}
                            style={{ color: 'green' }}
                            icon={solid('check')}
                            onClick={handleUpdateAcknowledgement(true)}
                        />
                        {/* <NwButton
                            disabled={loading}
                            style={{ color: StyleVariables.LighterBorderColor }}
                            icon={solid('check')}
                            onClick={handleUpdateAcknowledgement(false)}
                        /> */}
                        <Button
                            disabled={loading}
                            onClick={handleUpdateAcknowledgement(false)}
                        >
                            <span className="fa-layers fa-fw fa-lg">
                               <NwIcon icon={solid('check')} />
                               <NwIcon icon={solid('slash')} />
                            </span>
                        </Button>
                    </NwButtonGroup>
                    &nbsp;&nbsp;
                    <ButtonGroupWrapper>
                        <NwButtonGroup>
                            <Popover
                                overlayClassName="nw-popover"
                                trigger={'click'}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                onClick={e => e.stopPropagation()}
                                onOpenChange={setVisible}
                                open={visible}
                                placement="left"
                                content={
                                    <CancellationWrapper onClick={e => e.stopPropagation()}>
                                        <div>Cancelled by</div>
                                        <RadioButtonGroup
                                            value={customerType}
                                            onChange={setCustomerType}
                                            size='small'
                                            options={[
                                                { label: 'Client', value: 'Client' },
                                                { label: 'Agency', value: 'Agency' },
                                                { label: 'Model', value: 'Model' }
                                            ]} />
                                        <FlexContainer column mg='8px 0' alignItems='flex-start'>
                                            <div>Notes</div>
                                            <Input autoComplete='off' value={notes} onChange={e => setNotes(e.target.value)} />
                                        </FlexContainer>
                                        <FlexContainer justifyContent='flex-start' mg='8px 0 0'>
                                            <NwButton size='small' onClick={handleCancel(true)} label='Save' />
                                        </FlexContainer>
                                    </CancellationWrapper>
                                }>
                                <NwButton
                                    disabled={loading}
                                    style={{ color: 'red' }}
                                    icon={solid('times')}
                                />
                            </Popover>
                            <NwButton
                                disabled={loading}
                                onClick={handleCancel(false)}
                                style={{ color: StyleVariables.LighterBorderColor }}
                                icon={solid('times')}
                            />
                        </NwButtonGroup>
                    </ButtonGroupWrapper>
                </FlexContainer>
                {eventData.TypeName === 'Job' &&
                    <RadioButtonGroup
                        disabled={loading}
                        value={optionNumber}
                        onChange={handleUpdateOptionNumber}
                        size="small"
                        options={[
                            { value: 10, label: 'job' },
                            ...[...Array(5).keys()].map(key => (
                                { value: key + 1, label: key + 1 }
                            )),
                        ]} />
                }
            </Footer>
        </>
    );
};

export default ManageModelsAndDates;