import React, { useState } from "react";
import styled from "styled-components";
import { thin, light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getMoment, getNow, showDateNoTime } from "Libs/NwMoment";

import { NwLinkButton } from "Components/Gui/NwButton";
import StyleVariables from "Components/Gui/StyleVariables";
import NwIcon from "Components/Gui/NwIcon";
import EditPaymentStatusDrawer from "Components/Invoices/InvoicePage/Drawers/EditPaymentStatusDrawer";
import InvoiceConfirmDrawer from "Components/Invoices/InvoicePage/Drawers/InvoiceConfirmDrawer";
import PrintInvoiceButton from "../PrintInvoiceButton";
import { forceReloadInvoice, useClearInvoiceConfirm } from "Hooks/Invoices/UseInvoices";
import EditPaymentTermsDrawer from "./Drawers/EditPaymentTermsDrawer";
import EditBankDetailsDrawer from "./Drawers/EditBankDetailsDrawer";
import { Popover } from "antd";
import Axios from "axios";

const Wrapper = styled.div`
    
    padding: 1rem 0 2rem 0;
    
    .invoice-top-box {
        display: flex;
        padding-right: 2rem;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 0;

        .invoice-top-box-icon {
            min-width: 6rem;
            text-align: center;
            font-size: 2rem;
        }

        .invoice-top-box-content {

            .invoice-top-box-content-title {
                font-weight: bold;
                font-size: ${props => props.theme.FontSizeLg};
            }
            .invoice-top-box-content-label {
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeSm};
            }
            p {
                margin: 0;

                &.overdue {
                    background-color: ${props => props.theme.DangerColor};
                    color: #fff;
                    padding: .2rem .5rem;
                }
            }
            small {
                max-width: 13rem;
                font-size: ${props => props.theme.FontSizeSm};
                display: block;
                opacity: .5;
                line-height: 1.1em;
            }
        }
    }

    .invoice-top-export {
        display: flex;
        min-width:  12rem;
        
        .invoice-top-export-icon {
            margin-right: 2rem;
        }
    }

    .confirmation-payment-and-export {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 2rem;
    }
    
    .terms-and-bank-account {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
`;

const InvoicePageTop = ({ invoiceData, locked }) => {

    const paymentStatusOption = [
        { label: 'NOT PAID', value: 'NotPaid', icon: light("hourglass-empty") },
        { label: 'PARTIALLY PAID', value: 'PartiallyPaid', icon: thin("envelope-open-dollar") },
        { label: 'PAID', value: 'Paid', icon: thin("money-check-dollar") }
    ];

    const [showEditPaymentStatusDrawer, setShowEditPaymentStatusDrawer] = useState(false);
    const [showInvoiceConfirmDrawer, setShowInvoiceConfirmDrawer] = useState(false);
    const [showEditPaymentTermsDrawer, setShowEditPaymentTermsDrawer] = useState(false);
    const [showEditBankDrawer, setShowEditBankDrawer] = useState(false);

    const { mutateAsync: onClearInvoiceConfirm } = useClearInvoiceConfirm();

    const onClearInvoice = async () => {
        try {
            await onClearInvoiceConfirm(invoiceData.ID);
        } catch (e) {

        }
    }

    let selectedOption = paymentStatusOption.find(item => item.value === invoiceData.PaymentStatusName);
    if (!selectedOption) {
        selectedOption = paymentStatusOption[0];
    }

    const invoiceDataPaymentDate = invoiceData.PaymentDate ? invoiceData.PaymentDate : null;
    const invoiceDataPaymentDueDate = invoiceData.PaymentDueDate ? invoiceData.PaymentDueDate : null;

    const isOverDue = () => {
        //if invoice is not paid and payment due date is in the past
        return (!isPaid() && getMoment(invoiceDataPaymentDueDate).isBefore(getNow(), 'day'));
    }

    const isPaid = () => {
        return (invoiceData.PaymentStatusName === "Paid");
    }

    const setAsReadyToPost = async () => {
        await Axios.put(`/Invoices/SetReadyToPost`, { InvoiceID: invoiceData.ID, ReadyToPost: true });
        forceReloadInvoice(invoiceData.ID);
    }

    return (
        <Wrapper>
            <div className="confirmation-payment-and-export">
                <div className="invoice-top-box">
                    <div className="invoice-top-box-icon">
                        <NwIcon
                            icon={light("file-invoice")}
                            color={invoiceData.Number === null ? StyleVariables.DangerColor : "#000"}
                        />
                    </div>
                    {(!invoiceData.Number)
                        ?
                        <div className="invoice-top-box-content">
                            <div className="invoice-top-box-content-title">Not confirmed</div>
                            <NwLinkButton disabled={locked} onClick={() => setShowInvoiceConfirmDrawer(true)} label="confirm and assign invoice number" />
                        </div>
                        :
                        <div className="invoice-top-box-content">
                            <div className="invoice-top-box-content-title">{invoiceData.Number} / {getMoment(invoiceData.Date).year()} </div>
                            <div>Invoice date: {getMoment(invoiceData.Date).format("DD MMM YYYY")} </div>
                            <NwLinkButton disabled={locked} onClick={() => onClearInvoice()} label="clear invoice confirmation" />
                        </div>
                    }
                </div>
                <div className="invoice-top-box">
                    <div className="invoice-top-box-icon">
                        <NwIcon 
                            icon={selectedOption.icon} 
                            opacity={!invoiceData.Number ? .25 : 1} 
                            color={isOverDue() ? StyleVariables.DangerColor : "#000"}
                        />
                    </div>
                    <div className="invoice-top-box-content">
                        <div className="invoice-top-box-content-title">{selectedOption.label}</div>
                        {isPaid() && invoiceDataPaymentDate &&
                            <p>Payment date: <strong>{showDateNoTime(invoiceDataPaymentDate)}</strong></p>
                        }
                        {!invoiceData.Number ? 
                            <small>You can change payment status only for confirmed invoice</small>
                            :
                            <NwLinkButton disabled={!invoiceData.Number || locked} onClick={() => setShowEditPaymentStatusDrawer(true)} label="change payment status" />
                        }
                    </div>
                </div>
                <div className="invoice-top-export">
                    <div className="invoice-top-export-icon">
                        <PrintInvoiceButton 
                            invoiceID={invoiceData.ID}
                            invoiceType={invoiceData.TypeName}
                            placement="leftTop" 
                        />
                    </div>
                    <div className="invoice-top-export-icon">
                        <NwIcon 
                            disabled={locked} 
                            size="2x" 
                            icon={light("file-export")}
                            onClick={setAsReadyToPost}
                            tooltip="set this invoice as ready to post"
                            pointer
                        />
                    </div>
                    <div className="invoice-top-export-icon">
                        <NwIcon disabled size="2x" icon={light("cloud-arrow-down")} />
                    </div>
                </div>
            </div>
            <div className="terms-and-bank-account">
                {/* payment terms box */}
                <div className="invoice-top-box">
                    <div className="invoice-top-box-icon">
                        <NwIcon
                            icon={light("handshake")}
                            color={isOverDue() ? StyleVariables.DangerColor : "#000"}
                        />
                    </div>
                    <div className="invoice-top-box-content">
                        <div className="invoice-top-box-content-label">PAYMENT TERMS</div>
                        <p><strong>{invoiceData.PaymentTerm.Name}</strong></p>
                        {invoiceDataPaymentDueDate &&
                            <p className={isOverDue() ? 'overdue' : ''}>Due Date: {showDateNoTime(invoiceDataPaymentDueDate)}</p>
                        }
                        <NwLinkButton disabled={locked} onClick={() => setShowEditPaymentTermsDrawer(true)} label="change payment terms" />
                    </div>
                </div>
                {/* bank account box */}
                <div className="invoice-top-box">
                    <div className="invoice-top-box-icon">
                        <NwIcon icon={light('building-columns')} />
                    </div>
                    <div className="invoice-top-box-content">
                        <Popover
                            content={
                                <>
                                    <p><strong>{invoiceData.BankAccount.BankName}</strong></p>
                                    <p>Holder: {invoiceData.BankAccount.Holder}</p>
                                    <p>IBAN: {invoiceData.BankAccount.IBAN}</p>
                                    <p>BIC/SWIFT: {invoiceData.BankAccount.SWIFT}</p>
                                </>
                            }
                            title="Bank account details"
                        >
                            <div className="invoice-top-box-content-label">BANK ACCOUNT</div>
                            <p><strong>{invoiceData.BankAccount.BankName}</strong></p>
                            <p>Iban: {invoiceData.BankAccount.IBAN}</p>
                        </Popover>
                        <NwLinkButton disabled={locked} onClick={() => setShowEditBankDrawer(true)} label="change bank details" />
                    </div>
                </div>
                <div className="invoice-top-export">
                    &nbsp;
                </div>
            </div>
            {
                showEditPaymentStatusDrawer &&
                <EditPaymentStatusDrawer
                    invoiceData={invoiceData}
                    onClose={() => setShowEditPaymentStatusDrawer(false)}
                />
            }
            {
                showInvoiceConfirmDrawer &&
                <InvoiceConfirmDrawer
                    invoiceID={invoiceData.ID}
                    onClose={() => setShowInvoiceConfirmDrawer(false)}
                />
            }
            {
                showEditPaymentTermsDrawer &&
                <EditPaymentTermsDrawer
                    invoiceData={invoiceData}
                    onClose={() => setShowEditPaymentTermsDrawer(false)}
                />
            }
            {
                showEditBankDrawer &&
                <EditBankDetailsDrawer
                    invoiceData={invoiceData}
                    onClose={() => setShowEditBankDrawer(false)}
                />
            }
        </Wrapper >
    )
}

export default InvoicePageTop;