import React, { useEffect, useState } from "react";
import { Loading } from 'Components/Gui/Loading';
import { EventContainer, } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import ProjectEventsTimeline from 'Components/Project/ProjectEventsTimeline/ProjectEventsTimeline';
import { useFollowup, forceReloadFollowup } from 'Hooks/Event/UseFollowup';
import NwDrawer from 'Components/Gui/NwDrawer';
import FollowUpForm from 'Components/EventDrawer/FollowUpEvent/FollowUpForm';
import EventDrawerDetailsContainer from "Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer";
import FollowUpContent from "./FollowUpContent";
import { notification } from "antd";
import NwIcon from "Components/Gui/NwIcon";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Axios from "axios";

const FollowUpWrapper = ({
    afterRemove,
    afterUpdate,
    allDay,
    dateSelected,
    eventInfo,
    filterModel,
    ID,
    modelId,
    onClose,
    onSelectEvent,
    onTouchForm,
    plainMode,
    projectId,
    viewMode,
}) => {
    const [followUpID, setFollowUpID] = useState(ID);
    const [isEdit, setIsEdit] = useState(viewMode ? true : false);
    const { data: followUpData, isFetching, status } = useFollowup(followUpID);
    const [viewDetails, setViewDetails] = useState(false);
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setViewDetails(false);
    }, [filterModel]);

    useEffect(() => {
        if (ID) {
            setFollowUpID(ID)
        }
    }, [ID])

    const handleSelectProject = async project => {
        try {
            //await onSetTravelProject({ travelId: travelsData.ID, projectId: project });
            const err = await Axios.put('/followups/SetProject', {
                ObjectID: followUpData.ID,
                ProjectID: project
            })
            forceReloadFollowup(followUpData.ID);
            await afterUpdate();
        } catch (error) {
            if (error.errorCode && error.errorCode === "GenericError") {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: error.message,
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
            }
        }
    };

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const getFollowUpFormProps = () => ({
        plainMode: plainMode,
        eventInfo: eventInfo,
        followUpData: editModelEvent ? editModelEvent : followUpData,
        editModelEvent: editModelEvent ? true : false,
        viewMode: viewMode,
        modelId: modelId,
        projectId: projectId,
        dateSelected: dateSelected,
        allDay: allDay,
        afterUpdate: afterUpdate,
        onTouchForm: touchForm => {
            if (followUpData) {
                setTouchForm(touchForm);
            } else {
                onTouchForm(touchForm);
            }
        },
        onCancel: () => {
            handleClearEditEvent();
            handleClearTouchForm();
            if (followUpData) {
                setIsEdit(false);
            } else {
                onClose();
            }
        },
        onUpdateFollowupData: (id) => {
            if (id) {
                setFollowUpID(id)
            } else {
                forceReloadFollowup(followUpID);
            }
            handleClearTouchForm();
            handleClearEditEvent();
        }
    })

    if ((!followUpData && followUpID) || status === 'Loading') {
        return (
            <Loading textBlack />
        )
    }
    if (!followUpData && isEdit) {
        return (
            <EventContainer>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    <div className="ant-drawer-title">Add new activity</div>
                </div>
                <FollowUpForm {...getFollowUpFormProps()} />
            </EventContainer>
        )
    }
    return (
        <>
            {plainMode  //activity card in the project page
                ?
                <EventContainer hasProject={followUpData && followUpData.Project}>
                    <div className="event-content-wrapper">
                        {(!plainMode && followUpData.Project) &&
                            <ProjectEventsTimeline
                                event={{
                                    ...followUpData,
                                    TypeName: "Travel"
                                }}
                                projectID={followUpData.Project.ID}
                                onSelectEvent={onSelectEvent} />
                        }
                        <FollowUpContent
                            afterRemove={afterRemove}
                            afterUpdate={afterUpdate}
                            filterModel={filterModel}
                            handleSelectProject={handleSelectProject}
                            isEdit={isEdit}
                            onTouchForm={onTouchForm}
                            plainMode={plainMode}
                            projectId={projectId}
                            setEditModelEvent={setEditModelEvent}
                            setIsEdit={setIsEdit}
                            setViewDetails={setViewDetails}
                            followUpID={followUpID}
                            followUpData={followUpData}
                            viewDetails={viewDetails}
                        />
                    </div>
                </EventContainer>
                :
                <EventDrawerDetailsContainer
                    eventData={{ ...followUpData, elType: 'FollowUp', uniqID: `FollowUp-${followUpData.ID}` }}
                    filterModel={modelId}
                    isFetching={isFetching}
                    typeName="followup"
                    onSelectEvent={onSelectEvent}
                    onSelectProject={handleSelectProject}
                    firstTab={
                        <div className="event-content-wrapper">
                            {(!plainMode && followUpData.Project) &&
                                <ProjectEventsTimeline
                                    event={{
                                        ...followUpData,
                                        TypeName: "FollowUp"
                                    }}
                                    projectID={followUpData.Project.ID}
                                    onSelectEvent={onSelectEvent} />
                            }
                            <FollowUpContent
                                afterRemove={afterRemove}
                                afterUpdate={afterUpdate}
                                filterModel={filterModel}
                                handleSelectProject={handleSelectProject}
                                isEdit={isEdit}
                                onTouchForm={onTouchForm}
                                plainMode={plainMode}
                                projectId={projectId}
                                setEditModelEvent={setEditModelEvent}
                                setIsEdit={setIsEdit}
                                setViewDetails={setViewDetails}
                                followUpID={followUpID}
                                followUpData={followUpData}
                                viewDetails={viewDetails}
                            />
                        </div>
                    }
                />
            }
            {isEdit &&
                <NwDrawer
                    padding="0px"
                    width={740}
                    title='Edit Activity'
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        handleClearEditEvent();
                        setIsEdit(false);
                        handleClearTouchForm();
                    }}
                >
                    <EventContainer>
                        <FollowUpForm {...getFollowUpFormProps()} />
                    </EventContainer>
                </NwDrawer>
            }
        </>
    )
};

export default FollowUpWrapper;
