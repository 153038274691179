import React, { useState, useEffect, useMemo } from "react";
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import { List } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard, NwCardContent } from "Components/Gui/NwCard/NwCard";
import { NwCardTabsContent } from "Components/Gui/NwCard/NwCardTabs";
import { NWList, NWListItem } from "Components/Gui/NWList";
import { NwAddButton } from "Components/Gui/NwButton";
import EmptyState from "Components/Gui/EmptyState";
import CommentForm from 'Components/Comments/CommentForm';
import CommentItem from 'Components/Comments/CommentItem';
import { useStateValue } from 'Contexts/StateProvider';
import { sliceData, checkValidPage } from "Libs/NwUtils";
import NwIcon from "Components/Gui/NwIcon";
import { onContactCommentsUpdate } from "Hooks/Contact/UseContactComments";
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import { CommentListItem } from "Components/Comments/CommentListItem";
// import { useContact } from "Hooks/Contact/UseContact";
// import { useUserPermission } from 'Contexts/UserContext';

const ActionButtonsDropdown = styled.div`
  text-align: right;
  margin-bottom: 0.75rem;
`;

const Wrapper = styled.div`
    .ant-card-body-content {
        padding: 0 !important;
    }
`;

const LockContainer = styled(FlexContainer)`
    height: 100%;
    padding: ${props => props.$drawerMode && '32px 0'};
    svg {
        font-size: ${props => props.$drawerMode ?  '80px' : '120px'};
    }
`;

const DetailsCardContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    .tabs-content {
        justify-content: ${props => !props.viewComments && 'center'}
    }
`;

const Comments = ({
  events,
  drawerMode,
  createComments,
  readOnly,
  afterCommentsCreation,
  comments,
  ID,
  api,
  onUpdate,
  isPaginated = true,
  disableVisibility,
  hideComments,
  isInCardTab,
  isEvent,
  onEdit,
  fluid,
  contactId,
  onTouchForm 
}) => {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 3;
  const [editingMode, setEditingMode] = useState(false)
  const [createMode, setCreateMode] = useState(createComments)
  const [itemToModify, setItemToModify] = useState(null)
  const [loading, setLoading] = useState(false);
  const [touchForm, setTouchForm] = useState(false);
  const [{ areaContext }] = useStateValue()
  const totcomments = comments.length
  
  // const { data: contact } = useContact(contactId);
  // const viewClientComments = useUserPermission('ViewClientComments', contact && contact.Users.map(user => user.UserID));
  // const viewAgencyComments = useUserPermission('ViewAgencyComments', contact && contact.Users.map(user => user.UserID));
  // const viewServiceComments = useUserPermission('ViewServiceComments', contact && contact.Users.map(user => user.UserID));
  // const viewModelComments = useUserPermission('ViewModelComments', contact && contact.Users.map(user => user.UserID));

  const commentstorender = useMemo(() => {
    let sortedComments = [...comments]
    sortedComments.sort((comment1, comment2) => getMoment(comment1.DateCreated) < getMoment(comment2.DateCreated) ? 1 : -1)
    sortedComments = sortedComments.filter(comment => {
      if (comment.Visibility) {
        if (comment.Visibility.Areas.length === 0 || comment.Visibility.Areas.find(area => area.toLowerCase() === areaContext.area.toLowerCase())) {
          return true
        }
        return false
      }
      return true
    });
    return (isPaginated ? sliceData(activePage, itemsPerPage, sortedComments) : sortedComments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage, comments]);
  
  const viewComments = true
  // const viewComments = useMemo(() => {
  //     if (contact) {
  //         if (contact.SubType === 1) return viewClientComments;
  //         if (contact.SubType === 2) return viewAgencyComments;
  //         if (contact.SubType === 3) return viewServiceComments;
  //         return viewModelComments;
  //     }
  //     return false;
  // }, [contact, viewClientComments, viewAgencyComments, viewServiceComments, viewModelComments]);



  const handlePageChange = page => {
    setActivePage(page)
  };

  const pagination = isPaginated ? {
    total: totcomments,
    current: activePage,
    pageSize: itemsPerPage,
    onChange: handlePageChange
  } : null

  useEffect(() => {
    if (isPaginated) {
      checkValidPage(activePage, itemsPerPage, comments, handlePageChange)
    }
  });

  useEffect(() => {
    setCreateMode(createComments)
  }, [createComments]);

  const deleteComment = async (commentId) => {
    setLoading(true)
    await Axios.delete(`/comments/${commentId}`)
      .then(() => {
        switch (api) {
          case 'ContactID':
            onContactCommentsUpdate(ID, (() => setLoading(false)));
            break;
          case 'DocumentID':
            onUpdate('delete', commentId);
            break;
          case 'PlaylistID':
          case 'ProjectID':
          case 'TravelPlanID':
          case 'FollowUpID':
          case 'TravelID':
          case 'ExternalJobID':
          case 'ScoutingTravelID':
          case 'ScoutingMeetingID':
          case 'MeetingID':
          case 'ToDoID':
          case 'EventID':
            onUpdate();
            break;
          default:
            break;
        }
      })
      .catch(error => {
        console.log("errore", error);
      });
    setLoading(false);
  };

  const handleSaveComment = (isSuccess, comment) => {
    setItemToModify(null)
    setEditingMode(false)
    setCreateMode(false)
    afterCommentsCreation && afterCommentsCreation()

    if (isSuccess) {
      switch (api) {
        case 'ContactID':
          onContactCommentsUpdate(ID)
          break;
        case 'DocumentID':
          onUpdate('save', comment)
          break;
        case 'PlaylistID':
        case 'ProjectID':
        case 'TravelPlanID':
        case 'FollowUpID':
        case 'ScoutingTravelID':
        case 'ScoutingMeetingID':
        case 'TravelID':
        case 'ExternalJobID':
        case 'MeetingID':
        case 'ToDoID':
        case 'EventID':
          onUpdate()
          break;
        default:
          break;
      }
    }
  };

  const onSetItemToModify = (item) => {
    if (onEdit) {
      onEdit(item);
      return;
    }
    setItemToModify(item.ID);
    setEditingMode(true);
  }

  const renderCommentsList = commentList => {
    return commentList && commentList.length > 0 ?
      <NWList verticalAlignment="flex-start">
        {commentList.map(item => (
          <NWListItem
            key={item.ID}
            renderContent={() => <CommentItem item={item} disableVisibility={disableVisibility} events={events} />}
            renderActions={() => (!readOnly &&
              <ActionButtonsDropdown>
                <NwDropDownMenu
                  loading={loading}
                  actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => onSetItemToModify(item) },
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => { deleteComment(item.ID) }) }
                  ]}
                />
              </ActionButtonsDropdown>
            )} />
        ))}
      </NWList>
      :
      (!isEvent
        &&
        <EmptyState
          message="No comments"
        />
      );
  };

  const getCommentList = () => {
    return fluid ? renderCommentsList(comments) : renderCommentsList(commentstorender)
  };

  const getCommentListEvent = () => (
    (commentstorender && commentstorender.length) > 0 &&
    <List>
      {commentstorender.map(item => (
        <CommentListItem
          type="comment"
          key={item.ID}
          title={<CreatedBy object={item} short textonly />}
          description={<CommentItem item={item} disableVisibility={disableVisibility} isEvent />}
          actions={[
            <ActionButtonsDropdown>
              <NwDropDownMenu
                loading={loading}
                actions={[
                  { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => { setItemToModify(item.ID); setEditingMode(true); }) },
                  { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => { deleteComment(item.ID) }) }
                ]}
              />
            </ActionButtonsDropdown>
          ]} />
      ))}
    </List>
  )

  const renderCommentForm = (noPadding) => {
    return (
      <CommentForm
        comments={comments}
        api={api}
        ID={ID}
        contactId={contactId}
        noPadding={noPadding}
        disableVisibility={disableVisibility}
        onSave={handleSaveComment}
        selectedItem={createMode ? null : itemToModify}
        onTouchForm={touchForm => {
            if (drawerMode) {
                setTouchForm(touchForm);
            } else {
                onTouchForm && onTouchForm(touchForm)
            }
        }} />
    )
  }

  const getCommentCardContent = () => (
    (editingMode || createMode) && !drawerMode
      ? renderCommentForm()
      : <>
        {(editingMode || createMode) &&
          <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={() => handleSaveComment(false)}
            title={editingMode ? 'Edit Comment' : 'Add Comment'}>
            {renderCommentForm(true)}
          </NwDrawer>
        }
        {!hideComments &&
          <>
            {isPaginated
              ?
              <NwCardContent pagination={!fluid && pagination} loading={loading}>
                {getCommentList()}
              </NwCardContent>
              :
              isEvent ? getCommentListEvent() : getCommentList()
            }
          </>
        }
      </>
  )

  return (
    (isInCardTab
      ?
      <DetailsCardContent viewComments={viewComments}>
        <NwCardTabsContent loading={loading}>
            {viewComments ? getCommentCardContent()
                : 
                <LockContainer $drawerMode={drawerMode} justifyContent='center' alignItems='center'>
                    <NwIcon icon='lock-alt' />
                </LockContainer>
            }
        </NwCardTabsContent>
      </DetailsCardContent>
      :
      (isEvent
        ?
        // <NwLineCard
        //   title="Comments"
        //   icon="comments"
        //   counter={{ show: true, count: comments.length }}
        //   loading={loading}
        //   extra={
        //     <NwAddButton
        //       ghost
        //       size="small"
        //       onClick={() => {
        //         setCreateMode(true)
        //       }}
        //       label="add new"
        //     />
        //   }>
        getCommentCardContent()
        // </NwLineCard>
        :
        <Wrapper>
            <NwCard
                title="Comments"
                icon={light("comments")}
                loading={loading}
                extra={
                    !readOnly &&
                    <NwAddButton
                        ghost
                        disabled={!viewComments}
                        size="small"
                        onClick={() => {
                            setCreateMode(true)
                        }}
                        label="add new"
                    />
                }>
                {getCommentCardContent()}
            </NwCard>
        </Wrapper>
      )

    ));
};

export default Comments;
