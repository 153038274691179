import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import { light, solid, duotone } from '@fortawesome/fontawesome-svg-core/import.macro';


const StyledSpan = styled.span`
    position: relative;
    padding-right: .4em;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    margin-top: 8px;
    
    .nw-button-icon-badge-plus {
        position: absolute;
        top: -.4em;
        right: 0;
        font-size: .8rem;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: #000;
            opacity: 1;
        }
    }

    .nw-button-icon-badge-minus {
        position: absolute;
        top: -.4em;
        right: 0;
        font-size: .8rem;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.DangerColor};
            opacity: 1;
        }
    }
    
    .nw-button-icon-badge-edit {
        position: absolute;
        top: -.4em;
        right: 0;
        font-size: .8rem;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: #000;
            opacity: 1;
        }
    }
    
    .nw-button-icon-badge {
        background-color: ${props => props.theme.DangerColor};
        position: absolute;
        // top: -.4em;
        margin-top: 2px;
        right: 0;
        color: #fff;
        display: inline-block;
        padding: .4em .4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .nw-button-icon-check {
        position: absolute;
        top: -.4em;
        right: 0;
        font-size: .8rem;
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.PrimaryColor};
            opacity: 1;
        }
    }

    .bool-true {
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.SuccessColor};
            opacity: 1;
        }
    }
    .bool-false {
        .fa-primary {
            fill: #fff;
            opacity: 1;
        }
        .fa-secondary {
            fill: ${props => props.theme.DangerColor};
            opacity: 1;
        }
    }
`


export const NwBoolIcon = ({ value = true, fontSize = "1em" }) => {
    if (value) {
        return (
            <StyledSpan>
                <NwBaseIcon icon={solid("check")} style={{ fontSize }} className="bool-true" />
            </StyledSpan>
        )
    }
    return (<StyledSpan><NwBaseIcon icon={solid("ban")} style={{ fontSize }} className="bool-false" /></StyledSpan>)
}

export const NwGenderIcon = ({ gender }) => {
    const genderIcons = {
        'Female': light('venus'),
        'Male': light('mars'),
        'NonBinary': light('mars-and-venus')
    }

    //check if gender can be converted to a number and convert it to string
    let genderString = "Female"
    if (!isNaN(gender)) {
        switch (Number(gender)) {
            case 1:
                genderString = "Female"
                break;
            case 2:
                genderString = "Male"
                break;
            case 3:
                genderString = "NonBinary"
                break;
            default:
                break;
        }
    } else {
        genderString = gender
    }

    return (
        <StyledSpan>
            <NwBaseIcon icon={genderIcons[genderString]} />
        </StyledSpan>
    )
}

const getFaIcon = (icon) => {
    if (!icon) {
        return solid("martini-glass-empty");
    }
    return icon
}

const NwBaseIcon = (props) => {
    const { icon, loading, primary, danger, success, color, pointer, size, disabled, opacity, style, spin, onClick, ...other } = props

    const mystyle = style || {}
    if (pointer) {
        mystyle.cursor = "pointer"
    }
    if (opacity) {
        mystyle.opacity = opacity
    }
    let iconcolor = color
    if (danger) {
        iconcolor = StyleVariables.DangerColor
    }
    if (success) {
        iconcolor = StyleVariables.SuccessColor
    }
    if (primary) {
        iconcolor = StyleVariables.PrimaryColor
    }
    if (disabled) {
        mystyle.opacity = ".4"
        mystyle.cursor = "not-allowed"
        iconcolor = StyleVariables.TextColorMuted
    }
    
    
    let icontouse = getFaIcon(icon)
    let spinicon = spin
    if (loading) {
        icontouse = duotone("spinner-third")
        spinicon = true
    }

    return (
        <FontAwesomeIcon 
            onClick={disabled ? null : onClick} 
            icon={icontouse}
            size={size || "1x"}
            color={iconcolor}
            spin={spinicon}
            style={mystyle}
            {...other} 
        />)
}

const NwIcon = ({ icon, badge, plus, minus, edit, check, checkCircle, close, pointer, size, tooltip, loading, ...other }) => {

    if (plus) {
        return (
            <StyledSpan>
                <NwBaseIcon loading={loading} title={tooltip} icon={icon} {...other} pointer size={size || "lg"} />
                {!loading && <NwBaseIcon icon={duotone("plus-circle")} className="nw-button-icon-badge-plus" pointer />}
            </StyledSpan>
        )
    }
    if (minus) {
        return (
            <StyledSpan>
                <NwBaseIcon loading={loading} title={tooltip} icon={icon} {...other} pointer size={size || "lg"} />
                {!loading && <NwBaseIcon icon={duotone("minus-circle")} className="nw-button-icon-badge-minus" pointer />}
            </StyledSpan>
        )
    }
    if (edit) {
        return (
            <StyledSpan>
                <NwBaseIcon loading={loading} title={tooltip} icon={icon} {...other} pointer size={size || "lg"} />
                {!loading && <NwBaseIcon icon={duotone("pen-square")} className="nw-button-icon-badge-edit" pointer />}
            </StyledSpan>
        )
    }
    if (check) {
        return (
            <StyledSpan>
                <NwBaseIcon loading={loading} title={tooltip} icon={icon} {...other} pointer size={size || "lg"} />
                {!loading && <NwBaseIcon title={tooltip} icon={duotone("circle-check")} className="nw-button-icon-check" pointer {...other} />}
            </StyledSpan>
        )
    }
    if (badge) {
        return (<StyledSpan>
            <NwBaseIcon loading={loading} title={tooltip} icon={icon} {...other} pointer size={size || "lg"} />
            {!loading && <span className="nw-button-icon-badge">{badge}</span> }
        </StyledSpan>)
    }
    return (
        tooltip
            ?
            <Tooltip zIndex={9999} title={tooltip} mouseEnterDelay={.4} mouseLeaveDelay={.2}>
                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NwBaseIcon loading={loading} icon={icon} {...other} pointer={pointer} size={size || "1x"} />
                </div>
            </Tooltip>
            :
            <NwBaseIcon loading={loading} icon={icon} {...other} pointer={pointer} size={size || "1x"} />
    )
}

export default NwIcon