import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Input, Checkbox, List, notification } from 'antd';
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMailingLists } from 'Hooks/MailingLists/UseMailingLists';
import { Loading } from 'Components/Gui/Loading';
import { NwAddButton, NwRemoveButton } from 'Components/Gui/NwButton';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwIcon from 'Components/Gui/NwIcon';

const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .search-by-name {
        width: 300px !important;
        display: block !important;
    }
    .search-by-mine {
        margin: 0.5em 1em !important;
        white-space: nowrap;
    }
`

const AddToMailingListDrawer = ({ onClose, selectedEmail, onUpdateEmail }) => {
    const [filter, setFilter] = useState('');
    const [isSearchOnlyMine, setIsSearchOnlyMine] = useState(false);
    const [updating, setUpdating] = useState(false);
    const { data: mailingLists, isFetching } = useMailingLists();
    
    const handleChangeSearchByMe = event => {
        setIsSearchOnlyMine(event.target.checked);
    };

    const handleSearch = event => {
        setFilter(event.target.value);
    };

    const removeEmail = async (mailingListItemID) => {
        setUpdating(true);
        try {
            await Axios.delete(`/MailingLists/DeleteItem/${mailingListItemID}`);
            setUpdating(false)
            onUpdateEmail()
        } catch (error) {
            console.log('ant : Delete Email From Mailing List Error => ', error);
            setUpdating(false);
        }
    };

    const addEmailToMailingList = async (mailingListID, mailingListName) => {
        setUpdating(true);
        try {
            const connection = await Axios.post(`/MailingLists/Additem?mailingListID=${mailingListID}&emailID=${selectedEmail.ID}`);
            setUpdating(false)
            onUpdateEmail()
        } catch (error) {
            if (error && error.errorCode === 'EntityAlreadyExists') {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: `E-mail: ${selectedEmail.Address}\n already exists in mailing list: ${mailingListName}`,
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
            } else {
                console.log('ant : Add Email To Mailing List Error => ', error);
            }

            setUpdating(false);
        }
    };

    const actions = (mailingListID, mailingListName) => {
        const mailingListInEmail = selectedEmail.MailingLists.find(item => item.ID === mailingListID)
        if (mailingListInEmail && mailingListInEmail.MalingListItemID) {
            return [<NwRemoveButton ghost loading={updating} loadingLabel={'removing...'} onClick={() => removeEmail(mailingListInEmail.MalingListItemID)} label="remove" size="small" />];
        } else {
            return [<NwAddButton ghost loading={updating} loadingLabel={'adding...'} onClick={() => addEmailToMailingList(mailingListID, mailingListName)} label="add" size="small" />]
        }
    };

    const filteredMailingLists = useMemo(() => {
        if (!isFetching && mailingLists && mailingLists.length) {
            return mailingLists.filter(mailingList => mailingList.Name.includes(filter))
        }
        return []
    }, [mailingLists, filter, isFetching]);

    const isEmailInMailingList = (mailingListID) => {
        const mailingListInEmail = selectedEmail.MailingLists.find(item => item.ID === mailingListID)
        if (mailingListInEmail && mailingListInEmail.MalingListItemID) {
            return true
        }
        return false
    }

    return (
        <NwDrawer
            title="Add to Mailing Lists"
            onClose={onClose}>
            <FiltersContainer>
                <Input
                    className="search-by-name"
                    autoComplete='off'
                    addonBefore={<LegacyIcon type='search' />}
                    value={filter}
                    placeholder="Search Mailing List"
                    onChange={handleSearch} />
                <Checkbox
                    className="search-by-mine"
                    value={isSearchOnlyMine}
                    onChange={handleChangeSearchByMe}>
                    Only mine
                </Checkbox>
            </FiltersContainer>
            {isFetching ?
                <Loading />
                :
                <List
                    itemLayout="horizontal"
                    dataSource={filteredMailingLists}
                    renderItem={
                        mailingList => {
                            return (
                                <List.Item actions={actions(mailingList.ID, mailingList.Name)} className="ant-list-item-top">
                                    <List.Item.Meta
                                        avatar={<NwIcon check={isEmailInMailingList(mailingList.ID)} icon={light("mailbox")} />}
                                        title={mailingList.Name}
                                        description={<small>{mailingList.Description}</small>}
                                    />
                                </List.Item>
                            )
                        }
                    }
                />

            }
        </NwDrawer>
    );
};

export default AddToMailingListDrawer;