import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from "styled-components";

import CustomSelect from 'Components/Gui/NWForm/CustomSelect';
import { getNow } from "Libs/NwMoment";

const FilterDateInputStyle = styled.div`
//   width: 300px;
`

const FilterDateInput = props => {

  const [showDatePicker, setShowDatePicker] = useState(false);
  const filterValueDatePicker = () => {
    if (props.value) {
      if (typeof props.value == "string") {
        const match = props.value.match(/\{(\w+)\}/);
        if (!match) {
            return getMoment(props.value);
        }
      } else {
          return getMoment(props.value);
      }
    }
    return null;
  };

  useEffect(() => {
    if (props.value) {
      if (typeof props.value == "string") {
        const match = props.value.match(/\{(\w+)\}/);
        if (!match) {
          setShowDatePicker(true);
        }
      } else {
        setShowDatePicker(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterValueSelect = () => {
    if (props.value && typeof props.value === "string") {
      const match = props.value.match(/\{(\w+)\}/);
      if (match) {
        return props.value;
      } else {
        return "{others}";
      }
    } else if (props.value) {
      return "{others}";
    } else {
      return null;
    }
  };

  const handleSelectChange = val => {
    if (!val) {
      props.onChange();
      if (showDatePicker) {
        setShowDatePicker(false);
      }
    } else if (val === "{others}") {
      props.onChange(getNow());
      setShowDatePicker(true);
    } else {
      props.onChange(val);
    }
  };

  const handleDatePickerChange = date => {
    if (!date) {
      setShowDatePicker(false);
    }
    props.onChange(date ? getMoment(date) : "{today}");
  };

  return (
    <FilterDateInputStyle style={props.style}>
      <CustomSelect
        width="100%"
        value={filterValueSelect(props.value)}
        onChange={val => handleSelectChange(val)}
        disabled={props.disabled}
        allowClear={props.allowClear}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        options={[
            { value: '{today}', label: 'Today' },
            { value: '{tomorrow}', label: 'Tomorrow' },
            { value: '{yesterday}', label: 'Yesterday' },
            { value: '{amonthago}', label: 'A month ago' },
            { value: '{aweekago}', label: 'A week ago' },
            { value: '{ayearago}', label: 'A year ago' },
            { value: '{others}', label: 'Others' },
        ]}
      />
      {
        showDatePicker &&
        <DatePicker 
          autoComplete='off'
          style={{width: "100%"}}
          disabled={props.disabled}
          format="MMM DD, YYYY"
          value={filterValueDatePicker(props.value)}
          onChange={val => handleDatePickerChange(val)}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        />
      }
    </FilterDateInputStyle>
  );
};

export default FilterDateInput;
