import React, { useState } from "react";
import styled from 'styled-components';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import ProjectDrawer from 'Components/Project/ProjectDrawer';

const ProjectLineText = styled.small`
    font-size: ${props => props.$small ? ".7rem" : ".8rem"} !important;
    text-transform: uppercase;
    margin: ${props => props.$margin ? props.$margin : ".25rem 0 .5rem 0"};
    max-width: 100%;
    display:block;
    line-height: .8rem;
    max-height: ${props => props.$ellipsis ? ".85rem" : "none"};
    overflow: ${props => props.$ellipsis ? "hidden" : "visible"};
    color: ${props => props.theme.TextColor};
    cursor:  ${props => props.$pointer ? "pointer" : "default"};
`;

const ProjectLine = ({ project, ellipsis, mg, link, small }) => {

    const [isProjectDrawer, setIsProjectDrawer] = useState(false);

    const handlePreviewProject = projectId => {
        setIsProjectDrawer(true);
    };

    const handleCloseProjectDrawer = () => {
        setIsProjectDrawer(false);
    };

    return (
        <>
            <ProjectLineText $small={small} $ellipsis={ellipsis} $margin={mg} $pointer={link} onClick={link ? handlePreviewProject : null}>
                <strong>PRJ</strong> {getContactName(project.CustomerID)} — {project.Name}
            </ProjectLineText>
            {link && isProjectDrawer &&
                <ProjectDrawer projectId={project.ID} onClose={handleCloseProjectDrawer} />
            }
        </>
    );
};

export default ProjectLine;