import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { Select, Spin, Input } from 'antd';

import { Loading } from 'Components/Gui/Loading';
import { NWList, NWListItem } from "Components/Gui/NWList";
import FlexContainer from 'Components/Gui/FlexContainer';
import EmptyState from 'Components/Gui/EmptyState';
import { NwAddButton } from 'Components/Gui/NwButton';
import { UserContext } from 'Contexts/UserContext';
import { LoadingContainer } from 'Components/SendDrawer/SendDrawerContainer';
import EmailSearchItem from './EmailSearchItem';

const { Option, OptGroup } = Select;

const MailingLists = ({ fullHeight, onAddEmail, onAddAllEmails, selectedItems, addAllButton }) => {

    const [mailingLists, setMailingLists] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [mailingListId, setMailingListId] = useState(undefined);
    const { currentUser } = useContext(UserContext);
    const [emails, setEmails] = useState([]);
    const [isFetchingMailingList, setIsFetchingMailingList] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchMailingLists = async () => {
            setIsFetching(true);
            try {
                const mailingLists = await Axios.get(`/MailingLists`);
                setMailingLists(mailingLists);
            } catch (error) {
                console.log('ant : Fetch Mailing List Error => ', error);
            }
            setIsFetching(false);
        };

        fetchMailingLists();
    }, []);

    useEffect(() => {
        if (mailingListId) {
            fetchMailingsData(mailingListId)
        }
    }, [mailingListId]);

    const fetchMailingsData = async mailingListId => {
        setIsFetchingMailingList(true)
        const mlemails = await Axios.get(`/mailinglists/emails/${mailingListId}`)
        setEmails(mlemails)
        setIsFetchingMailingList(false)
    };

    const handleAddMailingListEmails = () => {
        const uniqueValuesSet = new Set();
        const emailsNoDuplicate = emails.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.Email);
            uniqueValuesSet.add(obj.Email);
            return !isPresentInSet;
        });
        if (!allSelected()) {
            onAddAllEmails(emailsNoDuplicate);
        }
    };

    const myMailingLists = mailingLists.filter(mailingList => mailingList.UserCreated === currentUser.UserID && mailingList.Name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    const otherMailingLists = mailingLists.filter(mailingList => mailingList.UserCreated !== currentUser.UserID && mailingList.Name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));

    const isSelected = (item) => {
        return selectedItems.find(selectedEmail => selectedEmail.Email === item.Email)
    }

    const allSelected = () => {
        var activeemails = selectedItems.map(item => item.Email)
        var emailsinlist = emails.map(item => item.Email)
        return emailsinlist.every(v => activeemails.includes(v));
    }

    

    return (
        <div>
            {isFetching ?
                <LoadingContainer>
                    <Loading textBlack />
                </LoadingContainer>
                :
                <>
                    <div className="search-header">
                        <Select
                            style={{ width: 320 }}
                            value={mailingListId}
                            placeholder={'Select Mailing List'}
                            onChange={setMailingListId}
                            onBlur={() => {
                                setSearchTerm('');
                            }}
                            dropdownRender={menu => (
                                <>
                                    <FlexContainer pd='8px 12px'>
                                        <Input
                                            style={{ height: 36 }}
                                            value={searchTerm}
                                            placeholder='Search...'
                                            onChange={e => setSearchTerm(e.target.value)} />
                                    </FlexContainer>
                                    {menu}
                                </>
                            )}
                        >
                            <OptGroup label="Mine">
                                {myMailingLists.map(mailingList => (
                                    <Option value={mailingList.ID} key={mailingList.ID}>{mailingList.Name}</Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Others">
                                {otherMailingLists.map(mailingList => (
                                    <Option value={mailingList.ID} key={mailingList.ID}>{mailingList.Name}</Option>
                                ))}
                            </OptGroup>
                        </Select>
                        {(emails.length > 0  && !isFetchingMailingList && addAllButton) &&
                            <NwAddButton ghost label='add all e-mails' disabled={allSelected()} onClick={handleAddMailingListEmails} />
                        }
                    </div>
                    <Spin spinning={isFetchingMailingList}>
                        <div className={`search-results ${fullHeight && " full-height"}`}>
                            {(emails.length === 0 && !isFetchingMailingList) ? <EmptyState message='No Results' />
                                :
                                <NWList>
                                    {emails.map((item, index) => (
                                        <NWListItem
                                            noPadding
                                            key={`email-${item.ID}`}
                                            renderContent={() => (
                                                <EmailSearchItem email={item} selected={isSelected(item)} onSelect={onAddEmail} />
                                            )}
                                        />
                                    ))}
                                </NWList>
                            }
                        </div>
                    </Spin>
                </>
            }
        </div>
    );
};

export default MailingLists;