import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const fetchGlobalFeatures = async () => {
  return await Axios.get('/ManageFeatures/Features/FullList');
};

const prefetchFeatures = () => {
    queryClient.prefetchQuery(
      ['global-features'], 
      () => fetchGlobalFeatures(),
      {
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
      }
  );
};

const useGlobalFeatures = () => {
  return (
      useQuery(['global-features'],
        fetchGlobalFeatures,
          {
              staleTime: 300000,
              cacheTime: Infinity,
              retry: false
          }
      )
  )
};

const forceReloadGlobalFeatures = () => {
    queryClient.invalidateQueries(['global-features']);
};

const fetchGlobalFeatureSets = async () => {
  return await Axios.get('/ManageFeatures/Featuresets/FullList');
};

const prefetchFeatureSets = () => {
    queryClient.prefetchQuery(
      ['global-feature-sets'], 
      () => fetchGlobalFeatureSets(),
      {
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
      }
  );
};

const useGlobalFeatureSets = () => {
  return (
      useQuery(['global-feature-sets'],
          fetchGlobalFeatureSets,
          {
              staleTime: 300000,
              cacheTime: Infinity,
              retry: false
          }
      )
  )
};

const forceReloadGlobalFeatureSets = () => {
    queryClient.invalidateQueries(['global-feature-sets']);
};

const fetchGlobalConversions = async () => {
  return await Axios.get('/ManageFeatures/GetGlobalConversions');
};

const prefetchConversions = async () => {
    try {
        return await queryClient.fetchQuery(['global-conversions'], () => fetchGlobalConversions(), {
            staleTime: 300000,
            cacheTime: Infinity
        });
    } catch (error) {
        console.log(error)
    }
};

const useGlobalConversions = () => {
  return (
      useQuery(['global-conversions'],
        fetchGlobalConversions,
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
      )
  )
};

const forceReloadGlobalConversions = () => {
    queryClient.invalidateQueries(['global-conversions']);
};

export {
  prefetchFeatures,
  prefetchFeatureSets,
  prefetchConversions,
  useGlobalFeatures,
  useGlobalFeatureSets,
  useGlobalConversions,
  forceReloadGlobalFeatures,
  forceReloadGlobalFeatureSets,
  forceReloadGlobalConversions
};
