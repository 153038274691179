import { useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const updateTags = async (ID) => {
    Axios.get(`/contacts/tags/${ID}`)
        .then(data => {
            queryClient.setQueryData(['contact', ID], (old) => ({
                ...old,
                Tags: data,
            }))
        });
}

const addContactTag = ({tag, contactID}) => (
    Axios.post(`/contacts/addtag/${contactID}/${tag.ID}`)
)

const useAddContactTag = () => {
    return useMutation(addContactTag, {
        onSuccess: (data, variables) => {
            updateTags(variables.contactID)
        }
    })
}

const removeContactTag = async ({tag, contactID}) => (
    Axios.post(`/contacts/removetag/${contactID}/${tag.ID}`)
)

const useRemoveContactTag = () => {
    return useMutation(removeContactTag, {
        onSuccess: (data, variables) => {
            const model = queryClient.getQueryData(['contact', variables.contactID])
            const newTags = model.Tags.filter(function (obj) {
                return obj.ID !== variables.tag.ID;
            })
            queryClient.setQueryData(['contact', variables.contactID], (old) => ({
                ...old,
                Tags: newTags,
            }))
        }
    })
}

export {
    updateTags,
    useAddContactTag,
    useRemoveContactTag
}