import React, { useState } from 'react';

import NwDrawer from 'Components/Gui/NwDrawer';
import { Segmented } from 'antd';
import { TransactionDrawerContent } from '../../Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import ExpenseFormNonBookingEvent from 'Components/Transactions/TransactionsCreateForms/ExpenseFormNonBookingEvent';
import RefundFormNonBookingEvent from 'Components/Transactions/TransactionsCreateForms/RefundFormNonBookingEvent';

const AddNonBookingEventTransactionDrawer = ({ event, eventType, onClose, onSave }) => {
    const [touchForm, setTouchForm] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);

    return (
        <NwDrawer
            width='60vw'
            minWidth='980px'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={`New Expense`}>
            <TransactionDrawerContent>
                <Segmented
                    block
                    value={selectedTab}
                    onChange={setSelectedTab}
                    options={[
                        {
                            label: (
                                <div>
                                    <h2>Expense</h2>
                                    <small>paid by the agency for the model</small>
                                </div>
                            ),
                            value: 1,
                        },
                        {
                            label: (
                                <div>
                                    <h2>Refund</h2>
                                    <small>expense paid by the model</small>
                                </div>
                            ),
                            value: 2,
                        }

                    ]}
                />
                {selectedTab === 1 &&
                    <ExpenseFormNonBookingEvent
                        event={event}
                        onCloseDrawer={onClose}
                        onSave={onSave}
                        eventType={eventType}
                    />
                }
                {selectedTab === 2 &&
                    <RefundFormNonBookingEvent
                        event={event}
                        onCloseDrawer={onClose}
                        onSave={onSave}
                        eventType={eventType}
                    />
                }
            </TransactionDrawerContent>
        </NwDrawer>
    )
};

export default AddNonBookingEventTransactionDrawer;