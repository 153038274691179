import React, { useState } from "react";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { showDateNoTime } from "Libs/NwMoment";
import NwIcon from "Components/Gui/NwIcon";
import ModelInTownSchedulesDrawer from "Components/ModelInTown/ModelInTownSchedulesDrawer";
import { getModel } from 'Hooks/Contact/UseContactsList';
import { useUserPermission } from "Contexts/UserContext";
import PermissionBlock from "Components/PermissionBlock";

const InTownContainer = styled.p`
    //text-transform: uppercase;
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};

    svg {
        margin-right: .5em;
    }
    small {
        font-weight: normal;
        font-size: .75rem;
    }
`

const ModelInTown = ({ modelID, modelInTownSchedule, plainMode, readOnly }) => {
    const [drawer, setDrawer] = useState(false);
    const modelObject = getModel(modelID);
    const viewModelInTownSchedule = useUserPermission('ViewModelInTownSchedule', modelObject && modelObject.U && modelObject.U.map(user => user.UserID));
    

    const getLocationPeriod = () => {
        if (!modelInTownSchedule.EndDate) {
            return `from ${showDateNoTime(modelInTownSchedule.StartDate)}`
        }
        return  `${showDateNoTime(modelInTownSchedule.StartDate)} > ${showDateNoTime(modelInTownSchedule.EndDate)}` 
    }

    const getModelInTown = () => {
        const todayDate = getMoment(new Date());
    
        if (!modelInTownSchedule) {
            return <InTownContainer><NwIcon icon={light("island-tropical")} />OUT</InTownContainer>
        }
        if (modelInTownSchedule &&  todayDate >= getMoment(modelInTownSchedule.StartDate)) {
            if (modelInTownSchedule.IsInTown) {
                if (!modelInTownSchedule.EndDate) {
                    return <InTownContainer isBold={true}><NwIcon icon={light("location-smile")} />IN TOWN</InTownContainer>
                } else {
                    return <InTownContainer isBold={true}><NwIcon icon={light("location-smile")} />IN TOWN <small>until {showDateNoTime(modelInTownSchedule.EndDate)}</small></InTownContainer>
                }
            } else {
                return <InTownContainer><NwIcon icon={light("island-tropical")} />&nbsp;&nbsp;OUT <small>(in {modelInTownSchedule.Location})</small></InTownContainer>
            }
        }
        if (modelInTownSchedule && todayDate < getMoment(modelInTownSchedule.StartDate)) {
            if (modelInTownSchedule.IsInTown) {
                return <InTownContainer><NwIcon icon={light("island-tropical")} />&nbsp;&nbsp;OUT <small>next arrival on {showDateNoTime(modelInTownSchedule.StartDate)}</small></InTownContainer>
            } else {
                return (
                <InTownContainer>
                    <NwIcon icon={light("island-tropical")} />
                    &nbsp;&nbsp;OUT <small>in {modelInTownSchedule.Location} {getLocationPeriod()}</small>
                </InTownContainer>
                )
            }
        }
    };

    const handleOpenSchedulesDrawer = event => {
        event.stopPropagation();
        setDrawer(true);
    };

    return (
        <>
            <div className="header-body-boxes header-body-boxes-hoverable" onClick={(!plainMode && viewModelInTownSchedule) ? handleOpenSchedulesDrawer : null}>
                <PermissionBlock
                    permission={viewModelInTownSchedule}
                    content={getModelInTown()}
                />
            </div>
            {drawer && <ModelInTownSchedulesDrawer readOnly={readOnly} modelID={modelID} onClose={() => setDrawer(false)} />}
        </>
    )
};

export default ModelInTown;
