import React, { useState, useMemo, useEffect } from 'react';
import { Checkbox } from 'antd';
import { useProject } from 'Hooks/Project/UseProject';
import { Loading } from 'Components/Gui/Loading';
import Switch from 'Components/Gui/CustomSwitch';
import { showDateNoTime } from 'Libs/NwMoment';
import FlexContainer from 'Components/Gui/FlexContainer';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import { NwButton } from 'Components/Gui/NwButton';
import useConfigurations from 'Hooks/UseConfigurations';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import NwDrawer from 'Components/Gui/NwDrawer';
import CustomSelect from 'Components/Gui/NWForm/CustomSelect';
import { ReportCreationDrawerWrapper } from './ReportCreationDrawerUI';
import { getModelConfirmationProperties, getTransactionConfirmationProperties } from '../ProjectUtils';
import ErrorMessage from 'Components/Gui/ErrorMessage';
import ReportCreationDrawerTransactionItem from './ReportCreationDrawerTransactionItem';
import Axios from 'axios';
import PdfPrintDrawer from 'Components/PdfPrintDrawer/PdfPrintDrawer';
import NwDivider from 'Components/Gui/NwDivider';
import ButtonTemplateSelector from 'Components/TemplateSelector/ButtonTemplateSelector';
import ContactSelector from 'Components/ContactSelector/ContactSelector';
import { saxonize } from 'Libs/NwUtils';

const ReportCreationDrawer = ({ projectId, onClose }) => {
    const { data: currentProjectAccountingDetails, isFetching: isFetchingProjectAccountingDetails } = useProjectAccountingDetails(projectId);
    const { data: currentProject, isFetching: isFetchingProject } = useProject(projectId);
    //const [template, setTemplate] = useState(null);
    const [callsheet, setCallsheet] = useState([]);

    const getProjectCallSheets = async () => {
        try {
            const callsheets = await Axios.get(`/projects/callsheets/${projectId}`);
            callsheets.sort((a, b) => a.CallSheetType.ID - b.CallSheetType.ID);
            const callsheetsWithActiveProperty = callsheets.map(callsheet => ({
                ...callsheet,
                active: true
            }));
            setCallsheet(callsheetsWithActiveProperty);
        } catch (error) {
            console.log('ant : Get Project Callsheets Error => ', error);
        }
    }

    const [usages, setUsages] = useState(currentProject.Usages.map(usage => ({
        ...usage,
        active: true
    })));

    const [otherFees, setOtherFees] = useState([]);
    const [modelsFees, setModelsFees] = useState([]);
    const [docVersion, setDocVersion] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [projectEvents, setProjectEvents] = useState(null);
    const [isFillingTemplate, setIsFillingTemplate] = useState(false);

    const handleSelectModel = (modelId) => {
        setSelectedModelId(modelId);
    }

    useEffect(() => {
        let otherFeesList = [];
        let modelsFeesList = [];

        if (currentProject && currentProjectAccountingDetails && currentProjectAccountingDetails.Transactions) {
            // Check if there are transactions and set the flag
            for (const transaction of currentProjectAccountingDetails.Transactions) {
                if (!transaction.ModelID) {
                    const hydratedTransaction = {
                        ...transaction,
                        key: transaction.ID,
                        active: true
                    }
                    otherFeesList.push(hydratedTransaction);
                } else {
                    if (!selectedModelId || transaction.ModelID === selectedModelId) {
                        const modelFeesList = modelsFeesList.find(mf => mf.ModelID === transaction.ModelID)
                        const hydratedTransaction = {
                            ...transaction,
                            key: transaction.ID,
                            ConfirmationProperties: getTransactionConfirmationProperties(currentProject, transaction)
                        }
                        if (modelFeesList) {
                            modelFeesList.transactions.push({
                                ...hydratedTransaction,
                                active: true
                            })
                        } else {
                            modelsFeesList.push({
                                ModelID: transaction.ModelID,
                                ModelConfirmationProperties: getModelConfirmationProperties(currentProject, transaction.ModelID),
                                transactions: [{
                                    ...hydratedTransaction,
                                    active: true
                                }],
                                active: true
                            })
                        }
                    }
                }
            }
        }
        setModelsFees([...modelsFeesList]);
        setOtherFees([...otherFeesList]);
    }, [currentProjectAccountingDetails, currentProject, selectedModelId]); //selectedBillingInfoId



    useEffect(() => {
        const tempProjectEvents = {}
        if (selectedModelId) {
            //console.log("events",currentProject.Events)
            //EventModels is not reliable
            //tempProjectEvents.EventsList = currentProject.Events.filter(event => event.EventModels.find(em => em.ModelID === selectedModelId)).map(event => ({
            tempProjectEvents.EventsList = currentProject.Events.filter(event => event.EventDates.some(ed => ed.EventDatesModels.some(em => em.ModelID === selectedModelId))).map(event => ({
                ...event,
                active: true,
                CheckIDs: [event.ID]
            }))
        } else {
            tempProjectEvents.EventsList = currentProject.Events.map(event => ({
                ...event,
                active: true,
                CheckIDs: [event.ID]
            }))
        }
        if (selectedModelId) {
            tempProjectEvents.TravelsList = currentProject.Travels.filter(event => event.Models.find(em => em.ModelID === selectedModelId)).map(event => ({
                ...event,
                active: true,
                CheckIDs: [event.Models.find(em => em.ModelID === selectedModelId).TravelID]
            }))
        } else {
            tempProjectEvents.TravelsList = currentProject.Travels.map(event => ({
                ...event,
                active: true,
                CheckIDs: [...event.GroupedEventIDs]
            }))
        }

        if (selectedModelId) {
            tempProjectEvents.AccommodationsList = currentProject.Accommodations.filter(event => event.Models.find(em => em.ModelID === selectedModelId)).map(event => ({
                ...event,
                active: true,
                CheckIDs: [event.Models.find(em => em.ModelID === selectedModelId).AccommodationID]
            }))
        } else {
            tempProjectEvents.AccommodationsList = currentProject.Accommodations.map(event => ({
                ...event,
                active: true,
                CheckIDs: [...event.GroupedEventIDs]
            }))
        }

        if (selectedModelId) {
            tempProjectEvents.ActivitiesList = currentProject.FollowUps.filter(event => event.Models.find(em => em.ModelID === selectedModelId)).map(event => ({
                ...event,
                active: true,
                CheckIDs: [event.Models.find(em => em.ModelID === selectedModelId).FollowUpID]
            }))
        } else {
            tempProjectEvents.ActivitiesList = currentProject.FollowUps.map(event => ({
                ...event,
                active: true,
                CheckIDs: [...event.GroupedEventIDs]
            }))
        }
        setProjectEvents(tempProjectEvents);

        getProjectCallSheets()

    }, [currentProject, selectedModelId]);

    const handleUpdateUsage = usageIndex => active => {
        const newUsages = [...usages];
        newUsages[usageIndex].active = active.target.checked;
        setUsages(newUsages);
    }

    const handleUpdateCallsheet = callsheetIndex => active => {
        const newCallsheet = [...callsheet];
        newCallsheet[callsheetIndex].active = active.target.checked;
        setCallsheet(newCallsheet);
    }

    const handleUpdateModelFees = (selectedFees, modelID) => {
        let newModelsFees = [...modelsFees];
        const modelIndex = newModelsFees.findIndex(modelFees => modelFees.ModelID === modelID);
        for (const t of newModelsFees[modelIndex].transactions) {
            if (selectedFees.includes(t.ID)) {
                t.active = true;
            } else {
                t.active = false;
            }
        }
        setModelsFees(newModelsFees);
    };

    const handleUpdateOtherFees = (selectedFees) => {
        let newOtherFees = [...otherFees];
        for (const t of newOtherFees) {
            if (selectedFees.includes(t.ID)) {
                t.active = true;
            } else {
                t.active = false;
            }
        }
        setOtherFees(newOtherFees);
    };

    // const handleChangeModel = modelId => active => {
    //     let newModelsFees = [...modelsFees];
    //     const modelIndex = newModelsFees.findIndex(modelFees => modelFees.ModelID === modelId);
    //     newModelsFees[modelIndex].active = active.target.checked;
    //     setModelsFees(newModelsFees);
    // };

    const handleChangeEvents = eventIndex => active => {
        const newEventsList = [...projectEvents.EventsList];
        newEventsList[eventIndex].active = active.target.checked;
        setProjectEvents({ ...projectEvents, EventsList: newEventsList });
    };

    const handleChangeTravels = eventIndex => active => {
        const newEventsList = [...projectEvents.TravelsList];
        newEventsList[eventIndex].active = active.target.checked;
        setProjectEvents({ ...projectEvents, TravelsList: newEventsList });
    };

    const handleChangeAccommodations = eventIndex => active => {
        const newEventsList = [...projectEvents.AccommodationsList];
        newEventsList[eventIndex].active = active.target.checked;
        setProjectEvents({ ...projectEvents, AccommodationsList: newEventsList });
    };

    // const handleChangeActivities = eventIndex => active => {
    //     const newEventsList = [...projectEvents.ActivitiesList];
    //     newEventsList[eventIndex].active = active.target.checked;
    //     setProjectEvents({ ...projectEvents, ActivitiesList: newEventsList });
    // };

    const handleGenerateContract = async (templateid) => {
        setIsFillingTemplate(true)
        const TransactionIDs = otherFees.filter(fee => fee.active).map(fee => fee.ID);
        for (const modelFees of modelsFees) {
            TransactionIDs.push(...modelFees.transactions.filter(fee => fee.active).map(fee => fee.ID))
        }
        const EventIDs = projectEvents.EventsList.filter(event => event.active).map(event => event.CheckIDs).flat();
        const TravelIDs = projectEvents.TravelsList.filter(event => event.active).map(event => event.CheckIDs).flat();
        const AccommodationIDs = projectEvents.AccommodationsList.filter(event => event.active).map(event => event.CheckIDs).flat();
        const FollowUpIDs = projectEvents.ActivitiesList.filter(event => event.active).map(event => event.CheckIDs).flat();
        const CallSheetIDs = callsheet.filter(cs => cs.active).map(cs => cs.ID);
        const UsagesIDs = usages.filter(usage => usage.active).map(usage => usage.ID);

        //create data object to send with all IDs array
        const data = {
            Template: templateid,
            Area: "ProjectReport",
            OutputFileName: "Report",
            //BillingClientInfoID: selectedBillingInfoId,
            ProjectID: projectId,
            ModelID: selectedModelId,
            TransactionIDs,
            EventIDs,
            TravelIDs,
            AccommodationIDs,
            FollowUpIDs,
            CallSheetIDs,
            UsagesIDs
        }

        //console.log("data", data)

        const contractResponse = await Axios.post('/projects/printreport', data)
        setDocVersion(contractResponse)
        setIsFillingTemplate(false)
    };

    const getUsageTypes = (usage) => {
        if (usage.UsageTypes && usage.UsageTypes.length > 0) {
            return usage.UsageTypes.join(', ');
        }
        return "General";
    }

    const getUsageAreas = (usage) => {
        if (usage.UsageTypes && usage.Areas.length > 0) {
            return usage.Areas.join(', ');
        }
        return "All Areas";
    }

    return (
        <>
            <NwDrawer
                width='70vw'
                title='Print Project Report'
                onClose={onClose}
            >
                {(isFetchingProject || isFetchingProjectAccountingDetails)
                    ?
                    <Loading textBlack />
                    :
                    <ReportCreationDrawerWrapper>
                        <div className="export-options">
                            <p>Print Report for:</p>
                            <ContactSelector 
                                activeTypes={["mod"]} 
                                activableTypes={[]}
                                customPlaceholder={"All models"}
                                projectId={projectId} 
                                onChange={handleSelectModel} 
                            />
                        </div>
                        <div className="export-content">
                            {projectEvents &&
                                <div>
                                    {(projectEvents.EventsList.length > 0) &&
                                        <>
                                            <NwDivider>Events</NwDivider>
                                            <div className="events-container">
                                                {projectEvents.EventsList.map((event, eventIndex) => (
                                                    <div className="event-container" key={`event-${eventIndex}`}>
                                                        <Checkbox
                                                            checked={event.active}
                                                            onChange={handleChangeEvents(eventIndex)}>
                                                            <FlexContainer>
                                                                <div className='event-tag'>
                                                                    <EventTypeTag event={event} type={event.TypeName} />
                                                                </div>
                                                                <div className='event-title'>{event.Name}</div>
                                                            </FlexContainer>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {(projectEvents.TravelsList.length > 0) &&
                                        <>
                                            <NwDivider>Travels</NwDivider>
                                            <div className="events-container">
                                                {projectEvents.TravelsList.map((event, eventIndex) => (
                                                    <div className="event-container" key={`event-${eventIndex}`}>
                                                        <Checkbox
                                                            checked={event.active}
                                                            onChange={handleChangeTravels(eventIndex)}>
                                                            <FlexContainer>
                                                                <div className='event-tag'>
                                                                    <EventTypeTag event={event} type="Travel" />
                                                                </div>
                                                                <div className='event-title'>{event.Title}</div>
                                                            </FlexContainer>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {(projectEvents.AccommodationsList.length > 0) &&
                                        <>
                                            <NwDivider>Accommodations</NwDivider>
                                            <div className="events-container">
                                                {projectEvents.AccommodationsList.map((event, eventIndex) => (
                                                    <div className="event-container" key={`event-${eventIndex}`}>
                                                        <Checkbox
                                                            checked={event.active}
                                                            onChange={handleChangeAccommodations(eventIndex)}>
                                                            <FlexContainer>
                                                                <div className='event-tag'>
                                                                    <EventTypeTag event={event} type="Accommodation" />
                                                                </div>
                                                                <div className='event-title'>{event.Title}</div>
                                                            </FlexContainer>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {/* {(projectEvents.ActivitiesList.length > 0) &&
                                        <>
                                            <NwDivider>Activities</NwDivider>
                                            <div className="events-container">
                                                {projectEvents.ActivitiesList.map((event, eventIndex) => (
                                                    <div className="event-container" key={`event-${eventIndex}`}>
                                                        <Checkbox
                                                            checked={event.active}
                                                            onChange={handleChangeActivities(eventIndex)}>
                                                            <FlexContainer>
                                                                <div className='event-tag'>
                                                                    <EventTypeTag event={event} type="FollowUp" />
                                                                </div>
                                                                <div className='event-title'>{event.Title}</div>
                                                            </FlexContainer>
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    } */}
                                </div>
                            }
                            {modelsFees.length > 0 &&
                            <div className="models-transactions-container">
                                <NwDivider>Fees & Expenses</NwDivider>
                                {modelsFees.map((modelFees, index) => {
                                    return (
                                        <div className="model-transactions-container" key={`model-fees-${modelFees.ModelID}-${index}`}>
                                            <h5>{saxonize(getContactName(modelFees.ModelID))} fees [{modelFees.ModelConfirmationProperties.ConfirmedWorkDays} confirmed work dates]</h5>
                                            <div className='transactions-container'>
                                                <ReportCreationDrawerTransactionItem modelID={modelFees.ModelID} transactions={modelFees.transactions} project={currentProject} onSelect={handleUpdateModelFees} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            }
                            {otherFees.length > 0 &&
                            <div className='other-transactions-container'>
                                <h5>other project fees</h5>
                                <div className='transactions-container'>
                                    <ReportCreationDrawerTransactionItem transactions={otherFees} project={currentProject} onSelect={handleUpdateOtherFees} />
                                </div>
                            </div>
                            }
                            {(usages.length) > 0 &&
                            <div className='usages-container'>
                                <NwDivider>Usages</NwDivider>
                                {usages.map((usage, index) => {
                                    return (
                                        <div key={`usage-${usage.ID}`} className='usage-container'>
                                            <Checkbox
                                                checked={usage.active}
                                                onChange={handleUpdateUsage(index)}
                                            >
                                                <h2>{`${getUsageTypes(usage)} - ${getUsageAreas(usage)}`}</h2>
                                                <p>{usage.Duration}</p>
                                                <p>{usage.Description}</p>
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                            }

                            {(callsheet.length) > 0 &&
                            <div className='callsheet-container'>
                                <NwDivider>Callsheet</NwDivider>
                                {callsheet.map((cs, index) => {
                                    return (
                                        <div key={`usage-${cs.ID}`} className='callsheet-container'>
                                            <Checkbox
                                                checked={cs.active}
                                                onChange={handleUpdateCallsheet(index)}
                                            >
                                                <h2>{cs.CallSheetType.Name}</h2>
                                                <p>{cs.Name}</p>
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                            }
                        </div>
                        <div style={{ textAlign: "center", margin: "1rem" }}>
                            <ButtonTemplateSelector loading={isFillingTemplate} templateArea="ProjectReport" onClick={handleGenerateContract} />
                            {/* <SelectTemplateSelector type="ProjectContract" onTemplateChange={handleSelectedTemplate} />
                            &nbsp;&nbsp;
                            <NwButton primary label='Generate Contract' onClick={handleGenerateContract} /> */}
                        </div>
                    </ReportCreationDrawerWrapper>
                }
            </NwDrawer>
            {docVersion &&
                <NwDrawer
                    onClose={() => setDocVersion(null)}
                    padding="0px"
                    title="Print Report"
                    visible={true}
                    whiteBg={false}
                    width="80vw"
                    maxWidth={"1024px"}
                >
                    <PdfPrintDrawer docVersion={docVersion} />
                </NwDrawer>
            }
        </>
    )

};

export default ReportCreationDrawer;