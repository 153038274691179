import React, { useState, useMemo } from 'react';
import { Alert, Checkbox, Input, Switch } from 'antd';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import _ from 'lodash';
import { Loading } from 'Components/Gui/Loading';
import { getNow, isAllDay, showDateNoTime } from 'Libs/NwMoment';
import { onGetOrderedEventList } from 'Hooks/Project/UseProject';
import CustomSelect from 'Components/Gui/NWForm/CustomSelect';
import { getContactName, getModel } from 'Hooks/Contact/UseContactsList';
import { EventContent, SendProjectDrawerContainer } from 'Components/SendProjectDrawer/SendProjectDrawerUI';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import Axios from 'axios';
import { NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwButton, NwCancelButton, NwLinkButton, NwSaveButton } from 'Components/Gui/NwButton';
import EmailToSendItem from './EmailToSendItem';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDivider from 'Components/Gui/NwDivider';
import { getFileTypeIcons } from 'Libs/NwUtils';
import SignatureSelector from 'Components/Gui/NWForm/SignatureSelector';

const LoadingContainer = styled.div`
    position: absolute;
    position: absolute;
    right: 0;
    left: 0;
    top: 40%;
    z-index: 2000;
`;

const { TextArea } = Input;


const SendProjectDrawerContent = ({ currentProject, ModelID, EventID, EventType, onClose }) => {
    const [emailItems, setEmailItems] = useState()
    const [isSending, setIsSending] = useState(false)
    const [selectedModel, setSelectedModel] = useState(ModelID)
    const [acknowledgeEvents, setAcknowledgeEvents] = useState(true)
    const [subject, setSubject] = useState(currentProject.Name)
    const [message, setMessage] = useState("")
    const [signature, setSignature] = useState()
    const [signatureID, setSignatureID] = useState()
    const [errorSending, setErrorSending] = useState()
    const [selectedEvents, setSelectedEvents] = useState()

    const getEmptyEmailItems = () => (
        {
            ProjectDocuments: [],
            Recipients: [],
        }
    )

    const getEmptyRecipient = () => (
        {
            ModelData: {
                Name: "",
                CG: null,
                ST: null,
            },
            Selected: true,
            Emails: [],
            DocVersion: {},
            Attachments: []
        }
    )

    
    const isDocumentOnModel = (doc) => {
        if (doc.ContactID) {
            return true
        }
        return false
    }

    const isDocumentOnEvent = (doc) => {
        if (doc.EventID || doc.FollowUpID || doc.TravelID || doc.AccommodationID) {
            return true
        }
        return false
    }

    const getGeneralProjectDocuments = () => {
        const docs = []
        if (currentProject.Documents && currentProject.Documents.length > 0) {
            const generaldocs = currentProject.Documents.filter(doc => (!isDocumentOnModel(doc) && !isDocumentOnEvent(doc)))
            docs.push(...generaldocs)
        }
        return docs
    }

    const isEventIDInEventDatesModels = (eventID, eventDatesModels) => {
        const selectedEvent = currentProject.Events.find(ev => ev.ID === eventID)
        if (selectedEvent && selectedEvent.EventDates) {
            for (const evdate of selectedEvent.EventDates) {
                if (evdate.EventDatesModels && evdate.EventDatesModels.length > 0) {
                    for (const evdatemodel of evdate.EventDatesModels) {
                        if (eventDatesModels.includes(evdatemodel.ID)) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    const isDocumentOnSelectedEvents = (doc, docversion) => {
        if (doc.EventID && docversion.EventDatesModels && docversion.EventDatesModels.length > 0 && isEventIDInEventDatesModels(doc.EventID, docversion.EventDatesModels)) {
            return true
        }
        if (doc.TravelID && docversion.Travels && docversion.Travels.length > 0 && docversion.Travels.some(tr => tr === doc.TravelID)) {
            return true
        }
        if (doc.FollowUpID && docversion.FollowUps && docversion.FollowUps.length > 0 && docversion.FollowUps.some(fu => fu === doc.FollowUpID)) {
            return true
        }
        if (doc.AccommodationID && docversion.Accommodations && docversion.Accommodations.length > 0 && docversion.Accommodations.some(ac => ac === doc.AccommodationID)) {
            return true
        }
        return false
    }

    const isDocumentForModel = (doc, docversion) => {
        if (isDocumentOnModel(doc)) {
            if (doc.ContactID === docversion.ModelID) {
                if (isDocumentOnEvent(doc)) {
                    return isDocumentOnSelectedEvents(doc, docversion)
                } else {
                    return true
                }
            }
        } else {
            if (isDocumentOnEvent(doc)) {
                return isDocumentOnSelectedEvents(doc, docversion)
            }
        }
        return false
    }

    const onSelectProjectDocument = (documentID, e) => {
        const newEmailItems = { ...emailItems }
        const generalDocument = newEmailItems.ProjectDocuments.find(doc => doc.DocumentID === documentID)
        if (generalDocument) {
            generalDocument.Selected = e.target.checked
        }
        setEmailItems(newEmailItems)
    }

    const onSelectModelDocument = (modelID, documentID, e) => {
        const newEmailItems = { ...emailItems }
        const recipient = newEmailItems.Recipients.find(rec => rec.ModelData.ID === modelID)
        const modelDocument = recipient.Attachments.find(doc => doc.DocumentID === documentID)
        if (modelDocument) {
            modelDocument.Selected = e.target.checked
        }
        setEmailItems(newEmailItems)
    }

    const buildEmailItems = (docversiondata) => {
        const newEmailItems = getEmptyEmailItems();
        const projectDocuments = getGeneralProjectDocuments()
        if (projectDocuments && projectDocuments.length > 0) {
            newEmailItems.ProjectDocuments.push(...projectDocuments.map((projectdocument) => (
                {
                    DocumentID: projectdocument.ID,
                    DocumentData: projectdocument,
                    Selected: false
                }
            )))
        }
        for (const docversion of docversiondata) {
            const model = getModel(docversion.ModelID)
            const newRecipient = getEmptyRecipient()
            newRecipient.ModelData = {
                ID: docversion.ModelID,
                Name: model.N + ' ' + model.S,
                CG: model.CG,
                ST: model.ST
            }
            newRecipient.DocVersion = docversion
            newRecipient.Emails = model.E ? [{ email: model.E, ccs: (model.ECC ? model.ECC : []) }] : []
            const modelDocuments = currentProject.Documents.filter(doc => isDocumentForModel(doc, docversion))
            if (modelDocuments && modelDocuments.length > 0) {
                newRecipient.Attachments.push(...modelDocuments.map((modeldocument) => (
                    {
                        DocumentID: modeldocument.ID,
                        DocumentData: modeldocument,
                        Selected: true
                    }
                )))
            }
            newEmailItems.Recipients.push(newRecipient)
        }
        document.querySelector('.ant-drawer-body').scrollTop = 0;
        setEmailItems({ ...newEmailItems })
    }

    const onSelectModelForEmail = (ModelID, check) => {
        const newEmailItemsRecipients = emailItems.Recipients.map((recipient) => {
            if (recipient.ModelData.ID === ModelID) {
                return ({
                    ...recipient,
                    Selected: check
                })
            } else {
                return recipient
            }
        })
        setEmailItems({ ...emailItems, Recipients: [...newEmailItemsRecipients] })
    }

    const onAddEmail = (ModelID, Email) => {
        const newEmailItemsRecipients = emailItems.Recipients.map((recipient) => {
            if (recipient.ModelData.ID === ModelID) {
                const newModelEmails = [...recipient.Emails, Email]
                return ({
                    ...recipient,
                    Emails: newModelEmails,
                    Selected: true
                })
            } else {
                return recipient
            }
        })
        setEmailItems({ ...emailItems, Recipients: [...newEmailItemsRecipients] })
    }

    const onRemoveEmail = (ModelID, Email) => {
        const newEmailItemsRecipients = emailItems.Recipients.map((recipient) => {
            if (recipient.ModelData.ID === ModelID) {
                const newModelEmails = [...recipient.Emails.filter((email) => email.email !== Email)]
                return ({
                    ...recipient,
                    Emails: newModelEmails,
                    Selected: newModelEmails.length === 0 ? false : recipient.Selected
                })
            } else {
                return recipient
            }
        })
        setEmailItems({ ...emailItems, Recipients: [...newEmailItemsRecipients] })
    }

    const onGenerateDocVersions = async () => {
        if (selectedEvents) {
            const dataToSend = {
                ...selectedEvents,
                ProjectID: currentProject.ID,
                Template: "pmc2",
                Area: "ProjectModelCalendar",
                OutputFileName: "MailProjectModelCalendar",
            }
            setIsSending(true)
            try {
                const response = await Axios.post('/projects/generateModelCalendar', dataToSend)
                setIsSending(false)
                buildEmailItems(response)
            } catch (error) {
                setIsSending(false)
            }
        }
    }

    const onSendMessages = async () => {
        if (emailItems && emailItems.Recipients.length > 0) {
            setIsSending(true)
            const emailsToSend = []
            for (const recipient of emailItems.Recipients) {
                if (recipient.Selected && recipient.Emails.length > 0) {
                    const modelDocuments = recipient.Attachments.filter((attachment) => attachment.Selected).map((attachment) => attachment.DocumentID)
                    const projectDocuments = emailItems.ProjectDocuments.filter((attachment) => attachment.Selected).map((attachment) => attachment.DocumentID)
                    const allDocuments = [...modelDocuments, ...projectDocuments]

                    // const docVersionData = await Axios.get(`/docVersions/${recipient.DocVersion.DocVersionID}`);
                    // const htmlContent = docVersionData.Html
                    // let replacedhtml = htmlContent
                    // replacedhtml = replacedhtml.replace('{{Message}}', message)
                    // await Axios.put('/docVersions/UpdateHtml', {
                    //     ID: recipient.DocVersion.DocVersionID,
                    //     Html: replacedhtml
                    // });

                    const data = {
                        DocVersionGUID: recipient.DocVersion.DocVersionGUID,
                        SendMethod: "SendGrid",
                        Subject: subject,
                        AsAttachment: false,
                        Message: message,
                        //ActionsAfter: AcknowledgeOnSend,
                        DocumentsToAttach: [...allDocuments], //recipient.ModelDocuments,
                        Recipients: recipient.Emails.map((email) => (
                            {
                                ContactID: recipient.ModelData.ID,
                                To: email.email,
                                Ccs: email.ccs
                            }
                        )),
                    }

                    if (signatureID) {
                        data.SignatureID = signatureID
                    }

                    if (acknowledgeEvents) {
                        let eventsMap = {};
                        if (recipient.DocVersion.EventDatesModels && recipient.DocVersion.EventDatesModels.length > 0) {
                            eventsMap.CheckEvents = [...recipient.DocVersion.EventDatesModels];
                        }
                        if (recipient.DocVersion.Travels && recipient.DocVersion.Travels.length > 0) {
                            eventsMap.CheckTravels = [...recipient.DocVersion.Travels];
                        }
                        if (recipient.DocVersion.FollowUps && recipient.DocVersion.FollowUps.length > 0) {
                            eventsMap.CheckFollowUps = [...recipient.DocVersion.FollowUps];
                        }
                        // if (recipient.DocVersion.Accommodations && recipient.DocVersion.Accommodations.length > 0) {
                        //     eventsMap.CheckAccommodations = [...recipient.DocVersion.Accommodations];
                        // }
                        const AcknowledgeOnSend = Object.keys(eventsMap).map(eventType => {
                            return {
                                Action: eventType,
                                Data: { Events: eventsMap[eventType] }
                            };
                        });
                        if (AcknowledgeOnSend.length > 0) {
                            data.ActionsBefore = AcknowledgeOnSend
                        }
                    }
                    emailsToSend.push(data)
                }
            }

            if (emailsToSend && emailsToSend.length > 0) {
                try {
                    await Axios.post('/docVersions/sendmessage', emailsToSend);
                    setIsSending(false)
                    onClose()
                } catch (error) {
                    setErrorSending("there was an error sending the message")
                    setIsSending(false);
                }
            } else {
                setErrorSending("At least a recipient e-mail address is needed to send the message")
                setIsSending(false);
            }
        }
    }

    const orderedEventsList = useMemo(() => {
        setSelectedEvents()
        let eventslist = []
        if (currentProject) {
            eventslist = onGetOrderedEventList(currentProject, "booking", true, true)
            if (selectedModel) {
                //create the filtered list of events
                eventslist = eventslist.filter((event) => {
                    if (event.elType === "Event") {
                        return event.EventModels.some(evdm => evdm.ModelID === selectedModel)
                    }
                    if (event.elType === "FollowUp" || event.elType === "Travel" || event.elType === "Accommodation") {
                        return event.Models.some(mod => mod.ModelID === selectedModel)
                    }
                    return null
                })
            } else {
                //remove dates without models
                for (const event of eventslist) {
                    if (event.elType === "Event") {
                        const validEventDates = event.EventDates.filter(evd => (evd.EventDatesModels && evd.EventDatesModels.length > 0))
                        if (validEventDates && validEventDates.length > 0) {
                            event.EventDates = [...validEventDates]
                        } else {
                            event.ToRemove = true
                        }
                    }
                }
                eventslist = eventslist.filter(event => !event.ToRemove)
            }

            for (const ev of eventslist) {
                switch (ev.elType) {
                    case "Travel":
                        ev.CommonStartDate = ev.StartDateTime
                        ev.CommonEndDate = ev.ArrivalDateTime
                        for (const model of ev.Models) {
                            model.CommonID = model.TravelID
                        }
                        break;
                    case "Accommodation":
                        ev.CommonStartDate = ev.FromDate
                        ev.CommonEndDate = ev.ToDate
                        for (const model of ev.Models) {
                            model.CommonID = model.AccommodationID
                            model.Cancel = false
                        }
                        break;
                    case "FollowUp":
                        ev.CommonStartDate = ev.StartDate
                        ev.CommonEndDate = ev.EndDate
                        for (const model of ev.Models) {
                            model.CommonID = model.FollowUpID
                        }
                        break;
                    default:
                        break;
                }
            }

            if (EventID && EventType) {
                const init_EventDatesModels = []
                const init_NonBookingEvents = {
                    Travel: [],
                    Accommodation: [],
                    FollowUp: []
                }
                if (EventType === "Job" || EventType === "Casting") {
                    if (eventslist && eventslist.length > 0) {
                        for (const ev of eventslist) {
                            if (ev.elType === "Event" && ev.ID === EventID) {
                                for (const eventdate of ev.EventDates) {
                                    const eventToSelect = eventdate.EventDatesModels.reduce((result, evdm) => {
                                        if (!evdm.Cancel) {
                                            if (ev.TypeName.toLowerCase() !== "job" || evdm.OptionNumber === 10) {
                                                if (!selectedModel || evdm.ModelID === selectedModel) {
                                                    result.push(evdm.ID)
                                                }
                                            }
                                        }
                                        return result
                                    }, [])
                                    init_EventDatesModels.push(...eventToSelect)
                                }
                            }
                        }
                        setSelectedEvents({
                            ...selectedEvents,
                            EventDatesModels: [...init_EventDatesModels],
                            Travels: [...init_NonBookingEvents.Travel],
                            FollowUps: [...init_NonBookingEvents.FollowUp],
                            Accommodations: [...init_NonBookingEvents.Accommodation]
                        })
                    }
                }
            } else {
                //adding to selected events only events not cancelled and not in the past
                if (eventslist && eventslist.length > 0) {
                    const init_EventDatesModels = []
                    const init_NonBookingEvents = {
                        Travel: [],
                        Accommodation: [],
                        FollowUp: []
                    }

                    for (const ev of eventslist) {
                        if (ev.elType === "Event") {
                            for (const eventdate of ev.EventDates) {
                                if (selectedModel) {
                                    if (eventdate.EventDatesModels.some(evdm => evdm.ModelID === selectedModel)) {
                                        const eventdatemodel = eventdate.EventDatesModels.find(evdm => evdm.ModelID === selectedModel)
                                        if (getMoment(eventdatemodel.FromDate).isSameOrAfter(getNow(), 'day') && !eventdatemodel.Cancel) {
                                            if (ev.TypeName.toLowerCase() !== "job" || eventdatemodel.OptionNumber === 10) {
                                                init_EventDatesModels.push(eventdatemodel.ID)
                                            }
                                        }
                                    }
                                } else {
                                    const eventToSelect = eventdate.EventDatesModels.reduce((result, evdm) => {
                                        if (!evdm.Cancel && getMoment(evdm.FromDate).isSameOrAfter(getNow(), 'day')) {
                                            if (ev.TypeName.toLowerCase() !== "job" || evdm.OptionNumber === 10) {
                                                result.push(evdm.ID)
                                            }
                                        }
                                        return result
                                    }, [])
                                    init_EventDatesModels.push(...eventToSelect)
                                }
                            }
                        } else {
                            if (selectedModel) {
                                const modelinstance = ev.Models.find(mod => mod.ModelID === selectedModel)
                                if (getMoment(ev.CommonEndDate).isSameOrAfter(getNow(), 'day') && !modelinstance.Cancel) {
                                    init_NonBookingEvents[ev.elType].push(modelinstance.CommonID)
                                }
                            } else {
                                if (getMoment(ev.CommonEndDate).isSameOrAfter(getNow(), 'day') && (ev.Models && ev.Models.length > 0 && ev.Models.some(m => !m.Cancel))) {
                                    const nonBookingEventToSelect = ev.Models.reduce((result, mod) => {
                                        if (!mod.Cancel) {
                                            result.push(mod.CommonID)
                                        }
                                        return result
                                    }, [])
                                    init_NonBookingEvents[ev.elType].push(...nonBookingEventToSelect)
                                }
                            }
                        }
                    }
                    setSelectedEvents({
                        ...selectedEvents,
                        EventDatesModels: [...init_EventDatesModels],
                        Travels: [...init_NonBookingEvents.Travel],
                        FollowUps: [...init_NonBookingEvents.FollowUp],
                        Accommodations: [...init_NonBookingEvents.Accommodation]
                    })
                }
            }
        }
        console.log("eventslist", eventslist)
        return eventslist
    }, [currentProject, selectedModel]);

    const projectModels = useMemo(() => {
        let optionitems = null
        if (currentProject) {
            optionitems = currentProject.ProjectModels.map(model => ({
                value: model.ModelID,
                label: getContactName(model.ModelID)
            }))
            optionitems.unshift({ value: '', label: 'all models' })
        }
        return optionitems
    }, [currentProject]);


    const selectAll = () => {
        const eventslist = [...orderedEventsList]

        if (eventslist && eventslist.length > 0) {
            const init_EventDatesModels = []
            const init_NonBookingEvents = {
                Travel: [],
                Accommodation: [],
                FollowUp: []
            }

            for (const ev of eventslist) {
                if (ev.elType === "Event") {
                    for (const eventdate of ev.EventDates) {
                        if (selectedModel) {
                            if (eventdate.EventDatesModels.some(evdm => evdm.ModelID === selectedModel)) {
                                const eventdatemodel = eventdate.EventDatesModels.find(evdm => evdm.ModelID === selectedModel)
                                if (!eventdatemodel.Cancel) {
                                    if (ev.TypeName.toLowerCase() !== "job" || eventdatemodel.OptionNumber === 10) {
                                        init_EventDatesModels.push(eventdatemodel.ID)
                                    }
                                }
                            }
                        } else {
                            const eventToSelect = eventdate.EventDatesModels.reduce((result, evdm) => {
                                if (!evdm.Cancel) {
                                    if (ev.TypeName.toLowerCase() !== "job" || evdm.OptionNumber === 10) {
                                        result.push(evdm.ID)
                                    }
                                }
                                return result
                            }, [])
                            init_EventDatesModels.push(...eventToSelect)
                        }
                    }
                } else {
                    if (selectedModel) {
                        const modelinstance = ev.Models.find(mod => mod.ModelID === selectedModel)
                        if (!modelinstance.Cancel) {
                            init_NonBookingEvents[ev.elType].push(modelinstance.CommonID)
                        }
                    } else {
                        if (ev.Models && ev.Models.length > 0 && ev.Models.some(m => !m.Cancel)) {
                            const nonBookingEventToSelect = ev.Models.reduce((result, mod) => {
                                if (!mod.Cancel) {
                                    result.push(mod.CommonID)
                                }
                                return result
                            }, [])
                            init_NonBookingEvents[ev.elType].push(...nonBookingEventToSelect)
                        }
                    }
                }
            }
            setSelectedEvents({
                ...selectedEvents,
                EventDatesModels: [...init_EventDatesModels],
                Travels: [...init_NonBookingEvents.Travel],
                FollowUps: [...init_NonBookingEvents.FollowUp],
                Accommodations: [...init_NonBookingEvents.Accommodation]
            })
        }
    }

    const select = (eventtype, id, checked) => {
        const tempSelEvents = { ...selectedEvents }
        if (checked) {
            //add id - no duplicates
            if (tempSelEvents[eventtype] && tempSelEvents[eventtype].length > 0) {
                tempSelEvents[eventtype] = [...new Set([...tempSelEvents[eventtype], id])]
            } else {
                tempSelEvents[eventtype] = [id]
            }
        } else {
            if (tempSelEvents[eventtype] && tempSelEvents[eventtype].length > 0) {
                //remove id
                tempSelEvents[eventtype] = tempSelEvents[eventtype].filter(edm => edm !== id)
            }
        }
        setSelectedEvents(tempSelEvents)
    }

    const isSelected = (eventtype, id) => {
        if (selectedEvents[eventtype] && selectedEvents[eventtype].length > 0) {
            return selectedEvents[eventtype].includes(id)
        }
        return false
    }

    const EventBlock = ({ event }) => {
        const { elType, EventModels, Models } = event;
        switch (event.elType) {
            case "Event":
                return (
                    <EventContent>
                        <div className="event-tag-title">
                            <div className="event-type-tag-container">
                                <EventTypeTag event={event} />
                                {/* type={event.TypeName} */}
                            </div>
                            <div className="event-title">{event.Name}</div>
                        </div>
                        <div className="event-dates">
                            {_.sortBy(event.EventDates, 'FromDate').map(date => {
                                if (!selectedModel || date.EventDatesModels.some(mod => mod.ModelID === selectedModel)) {
                                    const cancelClass = selectedModel && date.EventDatesModels.some(mod => (mod.ModelID === selectedModel && mod.Cancel)) ? "cancelled" : ""
                                    return (
                                        <div className={`event-date ${cancelClass}`} key={date.ID}>
                                            <div className="event-date-left">
                                                <div className="event-time">
                                                    <p>{showDateNoTime(date.FromDate)}</p>
                                                    {isAllDay(date.FromDate, date.ToDate)
                                                        ?
                                                        <p>All Day</p>
                                                        :
                                                        (date.StartBreak && date.EndBreak
                                                        ?
                                                        <p>{getMoment(date.FromDate).format('HH:mm')} &gt; {getMoment(date.StartBreak).format('HH:mm')}<br/>{getMoment(date.EndBreak).format('HH:mm')} &gt; {getMoment(date.ToDate).format('HH:mm')}</p>
                                                        :
                                                        <p>{getMoment(date.FromDate).format('HH:mm')} &gt; {getMoment(date.ToDate).format('HH:mm')}</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {/* {!selectedModel && */}
                                            <div className="event-date-right">
                                                {date.EventDatesModels.map(edm => {
                                                    const modelCancelClass = edm.Cancel ? " cancelled" : ""
                                                    const modelOptionClass = edm.OptionNumber < 10 && event.TypeName.toLowerCase() === 'job' ? " option" : ""
                                                    return (
                                                        <div className={`event-date-model${modelCancelClass}${modelOptionClass}`} key={edm.ID}>
                                                            <div className="switch-container">
                                                                <Switch
                                                                    checked={isSelected("EventDatesModels", edm.ID)}
                                                                    onChange={(checked) => select("EventDatesModels", edm.ID, checked)}
                                                                />
                                                            </div>
                                                            <div className="event-date-model-name">
                                                                {getContactName(edm.ModelID).toLowerCase()}{(edm.OptionNumber < 10 && event.TypeName.toLowerCase() === 'job') ? ` [option: ${edm.OptionNumber}]` : ''}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {/* } */}
                                        </div>
                                    )
                                }
                                return null
                            })}
                        </div>
                    </EventContent>
                )
            case "FollowUp":
            case "Travel":
            case "Accommodation":
                const cancelClass = selectedModel && event.Models.some(mod => (mod.ModelID === selectedModel && mod.Cancel)) ? 'cancelled' : ''
                return (
                    <EventContent>
                        <div className="event-tag-title">
                            <div className="event-type-tag-container">
                                <EventTypeTag event={event} type={event.Type} />
                            </div>
                            <div className="event-title">{event.Title}</div>
                        </div>
                        <div className="event-dates">
                            <div className={`event-date ${cancelClass}`}>
                                <div className="event-date-left">
                                    <p className="event-time">
                                        {showDateNoTime(event.CommonStartDate)}
                                        {isAllDay(event.CommonStartDate, event.CommonEndDate)
                                            ?
                                            <span> - All Day</span>
                                            :
                                            <span> - {getMoment(event.CommonStartDate).format('HH:mm')} - {getMoment(event.CommonEndDate).format('HH:mm')}</span>
                                        }
                                    </p>
                                </div>
                                {/* {!selectedModel && */}
                                <div className="event-date-right">
                                    {event.Models.map(edm => {
                                        const modelCancelClass = edm.Cancel ? "cancelled" : ""
                                        return (
                                            <div className={`event-date-model ${modelCancelClass}`} key={edm.CommonID}>
                                                <div className="switch-container">
                                                    <Switch checked={isSelected(`${event.elType}s`, edm.CommonID)} onChange={(checked) => select(`${event.elType}s`, edm.CommonID, checked)} />
                                                </div>
                                                <div className="event-date-model-name">
                                                    {getContactName(edm.ModelID).toLowerCase()}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </EventContent>
                )
            default:
                return null
        }
    }

    const atLeastOneSelected = useMemo(() => {
        if (selectedEvents) {
            if (selectedEvents.EventDatesModels && selectedEvents.EventDatesModels.length > 0) {
                return true
            }
            if (selectedEvents.Travels && selectedEvents.Travels.length > 0) {
                return true
            }
            if (selectedEvents.FollowUps && selectedEvents.FollowUps.length > 0) {
                return true
            }
            if (selectedEvents.Accommodations && selectedEvents.Accommodations.length > 0) {
                return true
            }
        }
        return false
    }, [selectedEvents]);

    const isFetching = false

    return (
        <>
            {isFetching ? <Loading /> :
                <>
                    {isSending &&
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    }
                    <SendProjectDrawerContainer isSending={isSending}>
                        {emailItems ?
                            <>
                                <div className="email-options">
                                    <NwDivider>E-mail options for all recipients</NwDivider>
                                    <div className="email-options-subject">
                                        <small>e-mail subject</small>
                                        <Input placeholder="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    <div className="email-options-message">
                                        <small>message</small>
                                        <TextArea rows={4} value={message} onChange={(e) => setMessage(e.target.value)} />
                                    </div>
                                    <div className="email-options-signature">
                                        <small>signature</small>
                                        <SignatureSelector value={signatureID} onSelectSignature={(signature) => setSignature(signature)} onChange={(signatureID) => setSignatureID(signatureID)} />
                                    </div>
                                    <div className="email-options-acknowledgement">
                                        <Checkbox checked={acknowledgeEvents} onChange={(e) => setAcknowledgeEvents(e.target.checked)}>
                                            set events as acknowledged
                                        </Checkbox>
                                    </div>
                                </div>
                                {emailItems.ProjectDocuments.length > 0 &&
                                <div className="project-general-documents">
                                    <NwDivider>Project documents (selected will be attached to all e-mails sent)</NwDivider>
                                    {emailItems.ProjectDocuments.map((doc) => (
                                        <div className="project-general-document" key={doc.DocumentID}>
                                            <Checkbox checked={doc.Selected} onChange={(e) => onSelectProjectDocument(doc.DocumentID, e)}>
                                                <NwIcon icon={getFileTypeIcons(doc.DocumentData.Extension)} />
                                                {doc.DocumentData.Title}
                                            </Checkbox>
                                        </div>
                                    ))}
                                </div>}
                                <NwDivider>Recipients</NwDivider>
                                <div className="emails-to-send">
                                    {emailItems.Recipients.map((recipient) => (
                                        <EmailToSendItem
                                            key={recipient.ModelData.ID}
                                            message={message}
                                            signature={signature}
                                            onAddEmail={onAddEmail}
                                            onRemoveEmail={onRemoveEmail}
                                            onSelect={onSelectModelForEmail}
                                            onSelectModelDocument={onSelectModelDocument}
                                            recipient={recipient}
                                        />
                                    ))}
                                </div>
                                {errorSending &&
                                    <Alert style={{marginTop: "1rem"}} message="Error" description={errorSending} type="error" showIcon />
                                }
                                <div className="buttons-bar">
                                    <NwFormButtonsBar
                                        left={
                                            <NwCancelButton
                                                onClick={onClose}
                                            />
                                        }
                                        right={
                                            <NwButton
                                                onClick={onSendMessages}
                                                loading={isSending}
                                                label="Send"
                                                primary
                                                icon={light("paper-plane")}
                                            />
                                        }
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className="export-options">
                                    <div className="export-options-models-selector">
                                        <p>Export for: </p>
                                        <CustomSelect
                                            options={projectModels}
                                            defaultValue={selectedModel ? selectedModel : ''}
                                            onChange={(value) => setSelectedModel(value)}
                                        />
                                    </div>
                                    <div className="export-options-tools">
                                        {!EventID &&
                                            <>
                                                <p>By default, future confirmed (and not cancelled) dates will be exported</p>
                                                <p><NwLinkButton label="click here to select also past events" onClick={selectAll} /></p>
                                            </>
                                        }
                                    </div>
                                </div>

                                <br /><br />
                                <div className="events-list">
                                    {orderedEventsList.map((event, index) => {
                                        return (<EventBlock event={event} key={index} />)
                                    })}
                                </div>
                                {errorSending &&
                                    <Alert style={{marginTop: "1rem"}} message="Error" description={errorSending} type="error" showIcon />
                                }
                                <div className="buttons-bar">
                                    <NwFormButtonsBar
                                        left={
                                            <NwCancelButton
                                                //disabled={isSubmitting}
                                                onClick={onClose}
                                            />
                                        }
                                        right={
                                            <NwSaveButton
                                                onClick={onGenerateDocVersions}
                                                disabled={!atLeastOneSelected}
                                                loading={isSending}
                                                label="Create e-mails"
                                            />
                                        }
                                    />
                                </div>
                            </>
                        }
                    </SendProjectDrawerContainer>
                </>
            }
        </>
    );
};

export default SendProjectDrawerContent