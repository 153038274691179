import styled from 'styled-components';

const FlexContainer = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items:  ${props => props.alignItems ? props.alignItems : 'center'};
    flex-direction: ${props => props.column ? 'column' : 'row'};
    flex-wrap: ${props => props.wrap && props.wrap};
    margin: ${props => props.mg && props.mg};
    padding: ${props => props.pd && props.pd};
    width: ${props => props.fullWidth && '100%'};
    flex: ${props => props.flex};
    height: ${props => props.fullHeight && '100%'};
`;

export default FlexContainer;