import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getContactNotes = async (ID) => {
    const notes = await Axios.get(`/contacts/notes/${ID}`)
    return notes
}

const onUpdateNotes = (ID) => {
    getContactNotes(ID).then((notes) => {
        queryClient.setQueryData(['contact', ID], (old) => ({
            ...old,
            Notes: notes
        }))
    })
}

export {
    onUpdateNotes
}