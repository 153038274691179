import React, { useEffect } from 'react';
//import { getMoment } from "Libs/NwMoment";
import 'moment/locale/en-nz';  
import 'moment/locale/en-gb';

import useLocalStorageState from "Hooks/UseLocalStorageState";
import { usergui as USERGUI } from "Configs/USERGUI";
import moment from 'moment';

const CONFIG_DEFAULT_STATE = {
	userGuiConfig: USERGUI
};

const ConfigContext = React.createContext(CONFIG_DEFAULT_STATE);
const ConfigContextConsumer = ConfigContext.Consumer;

const ConfigContextProvider = ({ children }) => {
    const [userGuiConfig, setUserGuiConfig] = useLocalStorageState(
        "userguiconfig",
        USERGUI
    );

    // useEffect(() => {
    //     if (userGuiConfig.calendar.weekFormat === 'monday') {
    //         moment.locale('en-gb');
    //     } else {
    //         moment.locale('en-nz');
    //     }
    // }, [userGuiConfig.calendar.weekFormat]);

    moment.locale('en-gb');

	const state = {
        userGuiConfig: userGuiConfig,
        updateUserConfig: setUserGuiConfig
    };
    
	return (
		<ConfigContext.Provider value={state}>	
            {children}
		</ConfigContext.Provider>
	);
}

export { ConfigContext, ConfigContextProvider, ConfigContextConsumer}

