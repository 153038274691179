import React, { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
import styled from 'styled-components';


const TransactionTitleContainer = styled.div`
    .transaction-title {
        font-size: .9rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    small {
        font-weight: 300;
        font-size: .7rem;
        text-transform: uppercase;
        line-height:1em;
        display: block;
    }
`;


const TransactionTitle = ({ transaction, target }) => {
    const { GetTransactionLabelName, GetFamilyName } = useContext(ListContext);


    const title = transaction.Title || ""
    let transactionFamily = GetFamilyName(transaction.Family)
    if (target && target==='agency') {
        if (transaction.Family.toLocaleLowerCase() === 'jobfee') {
            transactionFamily = 'Mother Agency Commission'
        }
    }
    let transactionTitle = ''

    if (transaction.TransactionLabelID) {
        const labelname = GetTransactionLabelName(transaction.Family, transaction.TransactionLabelID)
        if (title === "") {
            transactionTitle = labelname
        } else {
            if (title.toLocaleLowerCase() !== labelname.toLocaleLowerCase() && transactionFamily.toLocaleLowerCase() !== labelname.toLocaleLowerCase()) {
                transactionFamily += ` / ${labelname}`
            }
            transactionTitle = title
        }
    } else {
        transactionTitle = title
    }

    return (
        <TransactionTitleContainer>
            {(transactionFamily !== transactionTitle) && <small>{transactionFamily}</small>}
            <div className='transaction-title'>{transactionTitle}</div>
        </TransactionTitleContainer>
    )
}

export default TransactionTitle