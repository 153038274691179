import { Layout } from "antd";
import styled from 'styled-components';
import FlexContainer from "Components/Gui/FlexContainer";
import { NwCard } from "Components/Gui/NwCard/NwCard";

export const StyledLayout = styled(Layout)`
    margin-left: 300px;

    .ant-layout-content {
        padding: 2rem 3rem;
    }

    

    .ant-table-tbody {
        .ant-table-cell {
            &:last-child {
                border-left: ${props => props.theme.DefaultBorder};
            }
        }
    }
`;

export const StyledColumnCard = styled(NwCard)`
    margin: 0 1rem;

    .ant-card-body-content {
        margin: ${props => props.$noPadding ? '0' : '1rem 2rem 3rem 2rem'};
        padding: ${props => props.$noPadding ? '0 !important' : 'inherit'};
        form {
            max-width: 28rem;
        }
        
    }

    .fixed-height-container {
        min-height: 10rem;
    }

    .footer-button-container {
        padding: 1rem;
        text-align: center;
    }
`

export const ErrorMessage = styled.div`
    color: ${props => props.theme.DangerColor};
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8em;
`;

export const HiddenMetricContainer = styled.div`
    display: flex;

    div.hidden-metric {
        display: flex;
        white-space: nowrap;
        user-select: none;
        padding-left: .75rem;
        margin-right: .35rem;
        overflow: hidden;
        color: rgba(0,0,0,0.65);
        background-color: #fafafa;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        outline: none !important;

        > span {
            display: block;
            padding-right: 1rem;
            border-right: ${props => props.theme.DefaultBorder};
            cursor: move;
        }

        button {
            font-size: .75rem;
        }
    }
`

export const MetricsSortContainer = styled.div`
    display: flex;

    .metrics-sort-line {
        width: 100%;
        display: flex;
        margin-right: 10px;

        .metrics-sort-item {
            display: flex;
            white-space: nowrap;
            user-select: none;
            padding-left: .75rem;
            margin-right: .35rem;
            overflow: hidden;
            color: rgba(0,0,0,0.65);
            background-color: #fafafa;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            outline: none !important;

            > span {
                display: block;
                padding-right: 1rem;
                border-right: ${props => props.theme.DefaultBorder};
                cursor: move;
            }

            button {
                font-size: .75rem;
            }
        }
    }
`

export const IconContainer = styled.div`
    padding: 0 4px;
    margin-left: 10px;
    cursor: pointer;
    border-left: ${props => props.theme.DefaultBorder};

    svg {
        margin-top: 4px;
    }
`;

export const UploadCover = styled.div`
    color: black;
    font-size: 0.9em;
    &:hover {
        color: white;
    }
`;

export const SettingContainer = styled.div`
    margin: 1rem 2rem;

    &:first-of-type {
        margin-top: 3rem;
    }

    &:last-of-type {
        margin-bottom: 3rem;
    }

    &>span {
        min-width: 15rem;
        display: inline-block;
    }

    .ant-radio-group {
        margin-left: 1rem;
    }
`;