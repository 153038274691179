import React, { useContext } from 'react';
import { ListContext } from "Contexts/ListContext";
import { Alert } from 'antd';
import { NwButton } from 'Components/Gui/NwButton';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import TemplateSelectorPopover from './TemplateSelectorPopover';


const ButtonTemplateSelector = ({
    onClick,
    placement,
    template,
    trigger,
    templateArea,
    loading
}) => {
    const { templates } = useContext(ListContext).cachedList;
    const filteredTemplatesByType = templates.items.filter(template => template.area === templateArea);
    
    const handleSelectTemplate = async (templateID) => {
        return await onClick(templateID)
    }


    if (filteredTemplatesByType.length === 0) {
        return (
            <Alert message="No available print templates" type="warning" showIcon />
        )
    }
    if (filteredTemplatesByType.length === 1) {
        return (
            <NwButton loading={loading} primary label={`Generate Document [${filteredTemplatesByType[0].name}]`} onClick={() => handleSelectTemplate(filteredTemplatesByType[0].id)} />
        )
    }
    if (filteredTemplatesByType.length > 1) {
        return (
            <TemplateSelectorPopover
                onSelect={handleSelectTemplate}
                placement={placement}
                templates={filteredTemplatesByType}
                trigger={trigger}
            >
                <NwButton primary  icon={light("angle-up")} label="Generate Document" />
            </TemplateSelectorPopover>
        )
    }
};

export default ButtonTemplateSelector;