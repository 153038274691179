const getVisibilityNotes = (notes, area) => {
    let filteredNotes = [];
    filteredNotes = notes.filter(note => {
        if (note.Visibility) {
            if (note.Visibility.Areas.length === 0 || note.Visibility.Areas.find(item => item === area)) {
                return true;
            }
            return false;
        }
        return true;
    });
    return filteredNotes;
};

export {
    getVisibilityNotes
};