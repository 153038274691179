import React, { useContext } from 'react';

import { ListContext } from 'Contexts/ListContext';
import NwSelect from 'Components/Gui/NWForm/CustomSelect';

const CitySelectorMulti = props => {
	const {cachedList} = useContext(ListContext)

    const handleChange = value => {
        props.onChange(value);
    };

	return (
		<NwSelect 
            mode="multiple"
            style={{width: '100%'}}
			allowClear={true}
            showSearch={true}
            {...props}
            onChange={handleChange}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            options={cachedList.cities.items.map(c => ({
                value: c.Name,
                label: c.Name
            }))}
		/>		
	);
}

export default CitySelectorMulti;