import React, { useMemo, useState, useEffect } from 'react';
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Alert } from 'antd';

import { Loading } from 'Components/Gui/Loading';
import { isAllDay, showTime, toISODate } from 'Libs/NwMoment';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useMoveBookingEvent, forceReloadEvent, useEvent } from 'Hooks/Event/UseEvent';
import useEventStyle from 'Hooks/UseEventStyle';
import { BookingEventModelInfo } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import VerticalDate from 'Components/Dates/VerticalDate';

const EventPlannerContainer = styled.div`
    .event-planner-row {
        display: flex;
        width: 100%;
        .vertical-date-container {
            min-width: 5rem;
        }
    }
`;

const EventDateModel = styled.div`
    border: ${props => props.theme.DefaultBorder};
    border-radius: 8px;
    padding: 4px 8px;
    margin-bottom: 8px;
    cursor: pointer;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};

    .time {
        font-size: 0.8em;
    }
`;

const StyledAlert = styled(Alert)`
    min-width: 320px;
    position: absolute;
    z-index: 20;
    top: 40%;
    left: 50%;
    box-shadow: 0px 6px 16px rgb(0 0 0 / 12%);
    transform: translate(-50%, -50%);

    .ant-alert-message {
        margin-right: 8px;
    }
`;

const EventDates = ({ availableDates, eventDates, eventStyle }) => {
    useEffect(() => {
        const element = document.getElementById('start-event-date');
        element.scrollIntoView();
    }, []);

    return (
        <EventPlannerContainer>
            {availableDates.map((date, index) => {
                const eventDate = eventDates.find(eventDate => toISODate(eventDate.FromDate) === toISODate(date));
                return (
                    <div id={index === 8 ? 'start-event-date' : ''} key={date}>
                        <div className="event-planner-row">
                            <VerticalDate date={date} showDay />
                            <Droppable 
                                key={toISODate(date)} 
                                droppableId={toISODate(date)} 
                                direction="vertical" 
                            >
                                {(provided, snapshot) => (
                                        <BookingEventModelInfo ref={provided.innerRef} className='draggable' style={{width: '100%'}}>
                                            {eventDate && 
                                                <>
                                                    {eventDate.EventDatesModels.map((eventDateModel, index) => (
                                                        <Draggable
                                                            key={eventDateModel.ID}
                                                            draggableId={`${eventDateModel.ID}-${eventDateModel.ModelID}`}
                                                            index={index}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    >
                                                                    <EventDateModel 
                                                                        backgroundColor={eventStyle.backgroundColor}
                                                                        color={eventStyle.color}
                                                                        key={eventDateModel.ID} isDragging={snapshot.isDragging}>
                                                                        <div className='time'>{isAllDay(eventDateModel.FromDate, eventDateModel.ToDate) ? 'All Day' : 
                                                                            `${showTime(eventDateModel.FromDate)} - ${showTime(eventDateModel.ToDate)}`}</div>
                                                                        {getContactName(eventDateModel.ModelID)}
                                                                    </EventDateModel>
                                                                </div>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    ))}
                                                </>
                                            }
                                        </BookingEventModelInfo>
                                )}
                            </Droppable>
                        </div>
                    </div>
                )
            })}
        </EventPlannerContainer>
    )
};

const EventPlanner = ({ eventData, afterUpdate }) => {
    const { mutateAsync: onMoveBookingEvent } = useMoveBookingEvent();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { isFetching } = useEvent(eventData.ID);
    const eventStyle = useEventStyle(eventData, !eventData && "Casting")

    const eventDates = useMemo(() => {
        let sortedEventDates = eventData && eventData.EventDates ? [...eventData.EventDates] : [];
        sortedEventDates.sort((date1, date2) => date1.FromDate > date2.FromDate ? 1 : -1);
        return sortedEventDates;
    }, [eventData]);

    const availableDates = useMemo(() => {
        let dates = [];
        const startDate = getMoment(eventDates[0].FromDate).startOf('day');
        //const startDate = moment.utc(Date.UTC(moment.utc(eventDates[0].FromDate).year(), moment.utc(eventDates[0].FromDate).month(), moment.utc(eventDates[0].FromDate).date(), 0, 0, 0));
        const endDate = getMoment(eventDates[eventDates.length-1].FromDate).startOf('day');
        //const endDate = moment.utc(Date.UTC(moment.utc(eventDates[eventDates.length-1].FromDate).year(), moment.utc(eventDates[eventDates.length-1].FromDate).month(), moment.utc(eventDates[eventDates.length-1].FromDate).date(), 0, 0, 0));
        
        for (let date = getMoment(startDate).subtract(10, 'days'); date <= getMoment(endDate).add(10, 'days'); date=getMoment(date).add(1, 'days')) {
            dates.push(date);
        }
        return dates;
    }, [eventDates]);

    const onDragEnd = async result => {
        if (result && result.source && result.destination) {
            if (result.source.droppableId !== result.destination.droppableId) {
                const date = getMoment(result.destination.droppableId);
                const eventDateModelId = result.draggableId.split('-')[0];
                const existingEventDate = eventDates.find(eventDate => toISODate(eventDate.FromDate) === result.source.droppableId);
                const eventDateModel = existingEventDate.EventDatesModels.find(eventDateModel => eventDateModel.ID === parseInt(eventDateModelId));

                const newEventDate = eventDates.find(eventDate => toISODate(eventDate.FromDate) === result.destination.droppableId);
                if (newEventDate) {
                    if(newEventDate.EventDatesModels.find(eventDateModel => eventDateModel.ModelID === parseInt(result.draggableId.split('-')[1]))) {
                        setShowAlert(true);

                        setTimeout(() => {
                            setShowAlert(false);
                        }, [4000]);
                        return; 
                    }
                }

                const eventDate = {
                    ID: eventData.ID,
                    EventDateModelID: parseInt(eventDateModelId),
                    NewFromDateTime: getMoment(date).hour(getMoment(eventDateModel.FromDate).hour()).minute(getMoment(eventDateModel.FromDate).minute()),
                    // NewFromDateTime: moment.utc(Date.UTC(
                    //     getMoment(date).year(), 
                    //     getMoment(date).month(), 
                    //     getMoment(date).date(), 
                    //     getMoment(eventDateModel.FromDate).hour(), 
                    //     getMoment(eventDateModel.FromDate).minute(), 
                    //     0)),
                    NewToDateTime: getMoment(date).hour(getMoment(eventDateModel.ToDate).hour()).minute(getMoment(eventDateModel.ToDate).minute()),
                    // NewToDateTime: moment.utc(Date.UTC(
                    //     getMoment(date).year(), 
                    //     getMoment(date).month(), 
                    //     getMoment(date).date(), 
                    //     getMoment(eventDateModel.ToDate).hour(), 
                    //     getMoment(eventDateModel.ToDate).minute(), 
                    //     59))
                }
                
                setLoading(true);
                await onMoveBookingEvent({ eventData: eventDate });
                setLoading(false);
                forceReloadEvent(eventData.ID);
                afterUpdate();
            }
        }
    };  

    return (
        <>  
            {(loading || isFetching) ? 
                <Loading textBlack />
                : 
                <div>
                    {showAlert && 
                        <StyledAlert message="You can't move the event to this date" type="error" closable afterClose={() => setShowAlert(false)} />
                    }
                    <div >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <EventDates availableDates={availableDates} eventStyle={eventStyle} eventDates={eventDates} />
                        </DragDropContext>
                    </div>
                </div>
            }
        </>
    );
};

export default EventPlanner;