import React, { useContext, useMemo } from 'react'
import { getMoment } from "Libs/NwMoment";
import { Select, Calendar } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from "Components/Gui/NwButton";
import { StyledGroupButton } from "Components/Gui/StyledGroupButton";
import { groupBy, keys } from 'lodash';
import { getEventColorsByType } from 'Libs/EventStylesUtils';
import { ListContext } from 'Contexts/ListContext';
import { MobileDatePickerWrapper } from './MobileAgendaUI';
import { getEventsForDate } from './AgendaUtils';
import { useAgendaContext } from './AgendaContext';
import { Loading } from 'Components/Gui/Loading';


const MobileAgendaDatePicker = () => {

    const {
        contactId,
        isMobileDrawer,
        scheduleRange,
        month,
        monthEvents,
        onSelectDate
    } = useAgendaContext();

    const dateSelected = getMoment(scheduleRange.start)

    const { cachedList } = useContext(ListContext)

    const handleSelectDate = (value) => {
        window.scrollTo(0, 0)
        onSelectDate(value)
    }

    const evsByDates = useMemo(() => {
        const rangeDate = scheduleRange.start;
        const lastDayOfMonth = getMoment(rangeDate).endOf('month');
        const days = [];
        for (let i = 1; i <= lastDayOfMonth.date(); i++) {
            const dateForLoop = lastDayOfMonth.clone().date(i)
            const evs = getEventsForDate(dateForLoop, monthEvents, null)
            if (evs.length > 0) {
                days.push([...evs])
            } else {
                days.push([])
            }
        }
        return days
    }, [monthEvents])

    const renderDate = (calendardate) => {
        if (evsByDates && evsByDates.length > 0) {
            let dateColor = ""
            let dateMaxLevel = 0
            let evs = []
            if (calendardate.month() === month) {
                evs = evsByDates[calendardate.date() - 1]
            }
            if (evs && evs.length > 0) {
                const notCancelledEvent = evs.filter((ev) => (!ev.Cancel))
                const eventsGroupedByTypeAndSubTypeCode = groupBy(notCancelledEvent, (ev) => (ev.TypeName + "-" + ev.SubTypeCode));
                const groups = keys(eventsGroupedByTypeAndSubTypeCode);

                for (const gr of groups) {
                    if (gr.indexOf("-") > 0) {
                        const evtype = gr.split("-")[0];
                        if (evtype === "Job") {
                            //when changed to context, fix with contact ID as in AgendaDatePicker
                            let hasConfirmed = false
                            if (!contactId) {
                                hasConfirmed = eventsGroupedByTypeAndSubTypeCode[gr].some(ev => ev.ModelsDates.some(md => md.Models.some(m => m.OptionNumber === 10 && !m.Cancel)));
                            } else {
                                hasConfirmed = eventsGroupedByTypeAndSubTypeCode[gr].some(ev => ev.OptionNumber === 10 && !ev.Cancel);
                            }
                            if (hasConfirmed) {
                                dateColor = getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, 'Job', '', 10).backgroundColor
                                dateMaxLevel = 10
                            } else {
                                if (dateMaxLevel < 9) {
                                    dateColor = getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, 'Job', '', 1).backgroundColor
                                    dateMaxLevel = 9
                                }
                            }

                        } else {
                            if (gr.indexOf("ExtJob-") === 0) {
                                const hasConfirmed = eventsGroupedByTypeAndSubTypeCode[gr].filter((ev) => (ev.Confirmed)).length > 0;
                                if (hasConfirmed) {
                                    if (dateMaxLevel < 8) {
                                        dateColor = getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, "ExtJob", "", 10).backgroundColor
                                        dateMaxLevel = 8
                                    }
                                } else {
                                    if (dateMaxLevel < 7) {
                                        dateColor = getEventColorsByType(cachedList.eventTypes, cachedList.followUpLabels, "ExtJob", "", 1).backgroundColor
                                        dateMaxLevel = 7
                                    }
                                }
                            } else {
                                if (!dateMaxLevel) {
                                    dateMaxLevel = 1
                                }
                            }
                        }
                    }
                }
            }
            if (calendardate.isSame(dateSelected, 'day')) {
                dateColor = "#fff"
            }
            if (calendardate.month() !== month) {
                dateColor = "#ccc"
            }
            return (
                <div className="ant-picker-cell-inner ant-picker-calendar-date">
                    <div className="ant-picker-calendar-date-value">
                        <span style={{ fontWeight: dateMaxLevel > 0 ? 'bold' : 'normal', color: dateColor ? dateColor : '#000' }}>
                            {calendardate.format("DD")}
                        </span>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    const getCalendarHeader = (value, onChange) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
        }

        for (let index = start; index < end; index++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                </Select.Option>,
            );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }

        const clickLeft = () => {
            const now = value.clone().subtract(1, 'months');
            handleSelectDate(now);
        }

        const clickRight = () => {
            const now = value.clone().add(1, 'months');
            handleSelectDate(now);
        }

        const clickMonth = (selectedMonth) => {
            const newValue = value.clone();
            newValue.month(parseInt(selectedMonth, 10));
            handleSelectDate(newValue);
        }

        const clickYear = (selectedYear) => {
            const now = value.clone().year(selectedYear);
            handleSelectDate(now);
        }

        return (
            <div className="datepicker-date-navigation">
                <StyledGroupButton>
                    <NwButton
                        icon={light("chevron-left")}
                        onClick={clickLeft}
                    />
                    <Select
                        autoComplete='off'
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={clickYear}
                        value={String(year)}
                    >
                        {options}
                    </Select>
                    <Select
                        autoComplete='off'
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        onChange={clickMonth}
                    >
                        {monthOptions}
                    </Select>
                    <NwButton
                        icon={light("chevron-right")}
                        onClick={clickRight}
                    />
                </StyledGroupButton>
            </div>
        );
    }

    if (evsByDates && evsByDates.length > 0) {
        return (
            <MobileDatePickerWrapper className={isMobileDrawer ? 'mobile-drawer' : ''}>
                <Calendar
                    fullscreen={false}
                    mode="month"
                    dateFullCellRender={renderDate}
                    onPanelChange={handleSelectDate}
                    onSelect={handleSelectDate}
                    value={getMoment(dateSelected)}
                    bordered
                    headerRender={({ value, onChange }) => getCalendarHeader(value, onChange)}
                />
            </MobileDatePickerWrapper>
        )
    } else {
        return <Loading />;
    }
}

export default MobileAgendaDatePicker
