import React, { useState } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import Axios from 'axios';
import { light, duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

import AddCallSheet from 'Components/Project/ProjectContacts/AddCallSheet';
import EditCallSheet from 'Components/Project/ProjectContacts/EditCallSheet';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { MailLine, PhoneLine } from 'Components/Gui/NwContactInfo';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
// import Switch from 'Components/Gui/Switch';
// import MiniLoading from 'Components/Gui/MiniLoading';
//import { getValidText } from 'Libs/NwUtils';
// import { NwButton } from "Components/Gui/NwButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NwEllipsis from 'Components/Gui/NwEllipsis';
//import { getContactName } from 'Hooks/Contact/UseContactsList';

const Wrapper = styled(FlexContainer)`
    .type {
        text-transform: uppercase;
    }
    .name {
        font-weight: bold;
        font-size: 1.2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .icon {
        width: 28px;
        text-align: center;
    }
    .line-container-left {
        cursor: pointer;
    }
    .connections {
        width: calc(100% - 72px);
        font-size: .9rem;
    }
    .phone-note {
        font-size: .75rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 20px;
    }
    .address-book-plus {
        font-size: 2rem;
        cursor: pointer;
        svg {
            &.base-address-book {
                right: .5rem;
                top: .5rem;
                bottom: auto;
                left: auto;
            }
            &.stacked-plus {
                font-size: 1.25rem;
                top: 0;
                right: 0;
                bottom: auto;
                left: auto;
                --fa-secondary-opacity: 1;
                --fa-primary-opacity: 1;
                --fa-secondary-color: ${props => props.theme.PrimaryColor};;
                --fa-primary-color: #fff;
            }
        }
    }
`;

const CallSheetDetail = styled(FlexContainer)`
    line-height: 1.2;
    .connection-icon {
        margin-top: 4px;
        margin-left: 8px;
        margin-right: 16px;
        width: 32px;        
        display: flex;
        justify-content: center;
    }
    svg {
        margin-right: 4px;
        margin-bottom: 2px;
        font-size: .8rem;
    }
    .name {
        font-weight: bold;
        color: ${props => props.invisible && props.theme.DangerColor};
    }
    .description {
        //text-transform: uppercase;
        white-space: pre-wrap;
        font-size: .75rem;
        margin-top: 4px;
    }
    .disabled-text {
        color: ${props => props.theme.DisabledTextColor};
        font-style:  italic;
    }
`;

const CallSheetTypeTag = styled(Tag)`
    background-color: ${props => props.invisible === 'empty' ? props.theme.DisabledTextColor :(props.invisible === 'visible' ? props.theme.PrimaryColor : props.theme.DangerColor)};
    border-color: ${props => props.invisible === 'empty' ? "none" :(props.invisible === 'visible' ? props.theme.PrimaryColor : props.theme.DangerColor)};
    color: white;
    svg {
        margin-right: 4px;
    }
`;

const ProjectCallSheet = ({ projectId, callsheet, mode = 'projectCallSheet', onUpdateCallSheet, isempty = false }) => {
    const [isAddDrawer, setAddDrawer] = useState(false);
    const [isEditDrawer, setEditDrawer] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const handleCloseDrawer = () => {
        setEditDrawer(false);
    };

    const handleRemoveCallsheet = async callsheetID => {
        try {
            await Axios.delete(`/callsheets/${callsheetID}`);
            onUpdateCallSheet();
        } catch (error) {
            console.log('ant : Delete Callsheet Error => ', error);
        }
    }

    const handleEditCallsheet = () => {
        setEditDrawer(true);
    };

    if (isempty) {
        return (
            <Wrapper
                fullWidth 
                fullHeight
                column 
                justifyContent='space-between' 
                alignItems='flex-start'
            >
                <FlexContainer fullWidth column alignItems='flex-start'>
                    <FlexContainer fullWidth justifyContent='space-between'>
                        <CallSheetTypeTag invisible="empty">
                            {callsheet.Name}
                        </CallSheetTypeTag> 
                        <span onClick={() => setAddDrawer(true)}  className="address-book-plus fa-layers fa-fw fa-lg">
                            <FontAwesomeIcon className="base-address-book" icon={light('address-book')} />
                            <FontAwesomeIcon className="stacked-plus" icon={duotone('circle-plus')} />
                        </span>
                    </FlexContainer>
                    <CallSheetDetail mg='8px 0 0'>
                        <div className="disabled-text">not defined</div>
                    </CallSheetDetail>
                </FlexContainer>
                {isAddDrawer &&
                    <NwDrawer 
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title={'Add Contact'}
                        onClose={() => setAddDrawer(false)}>
                        <AddCallSheet 
                            callSheetType={callsheet.ID}
                            projectId={projectId}
                            onUpdateCallSheet={onUpdateCallSheet}
                            onClose={() => setAddDrawer(false)}
                            onTouchForm={setTouchForm} />                        
                    </NwDrawer>
                }
            </Wrapper>
        )
    }

    return (
        <Wrapper
            fullWidth 
            fullHeight
            column 
            justifyContent='space-between' 
            alignItems='flex-start'>
            <FlexContainer fullWidth column alignItems='flex-start'>
                <FlexContainer fullWidth justifyContent='space-between'>
                    <CallSheetTypeTag invisible={callsheet.Internal ? 'invisible' : 'visible'}>
                        {callsheet.Internal && <NwIcon icon={light('lock-alt')} />}
                        {callsheet.CallSheetType.Name}
                    </CallSheetTypeTag> 
                    <NwDropDownMenu
                        size='small'
                        actions={[
                            { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => handleEditCallsheet(callsheet)},
                            { name: "add another", key: "add", icon: light("user-plus"), action: (() => setAddDrawer(true)) },
                            { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => handleRemoveCallsheet(callsheet.ID)}
                        ]}
                    />
                </FlexContainer>
                <CallSheetDetail mg='8px 0 0'>
                    <div>
                        <div className='name'>
                            {callsheet.Name}
                        </div>
                        <NwEllipsis className="description" popover text={callsheet.Note} rows={3} /> 
                    </div>
                </CallSheetDetail>
            </FlexContainer>
            <FlexContainer fullWidth alignItems='flex-end' justifyContent='space-between'>
                <div className='connections'>
                    {callsheet.Email && 
                        <MailLine justify='flex-start' mailAddress={callsheet.Email} noIcon />
                    }
                    {callsheet.Phone && 
                        <PhoneLine justify='flex-start' phoneType='mobile' phoneNumber={callsheet.Phone} noIcon />
                    }
                </div>
            </FlexContainer>
            {isEditDrawer &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title={'Edit Contact'}
                    onClose={handleCloseDrawer}>
                    <EditCallSheet
                        mode={mode}
                        callsheet={callsheet}
                        onClose={handleCloseDrawer}
                        onUpdateCallSheet={onUpdateCallSheet}
                        onTouchForm={setTouchForm} />
                </NwDrawer>
            }
            {isAddDrawer &&
                <NwDrawer 
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title={'Add Contact'}
                    onClose={() => setAddDrawer(false)}>
                    <AddCallSheet 
                        callSheetType={callsheet.CallSheetType.ID}
                        projectId={projectId}
                        onUpdateCallSheet={onUpdateCallSheet}
                        onClose={() => setAddDrawer(false)}
                        onTouchForm={setTouchForm} />                        
                </NwDrawer>
            }
        </Wrapper>
    );
};

export default ProjectCallSheet;