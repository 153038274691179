import { getMoment } from "./NwMoment";

export const checkUserLicence = user => {
    if (user.isSuperAdmin) {
        return true;
    }
    if (!user.Licence) {
        return false;
    }
    if (user.Licence.LicenceKey) {
        if (user.Licence.Disabled) {
            return false;
        }
        if (user.Licence.IsDeleted) {
            return false;
        }
        if (user.Licence.ActivationDate && user.Licence.EndDate) {
            const today = getMoment()
            const startDate = getMoment(user.Licence.ActivationDate)
            const endDate = getMoment(user.Licence.EndDate)
            if (today.isSameOrAfter(endDate) || today.isSameOrBefore(startDate)) {
                return false;
            }
            return true
        }
        return false
    }
    return false;
}

export const checkUserInDepartments = (user, departments) => {
    return user.isSuperAdmin || user.UserDepartments.some(ud => departments.includes(ud.DepartmentID))
}

export const checkUserInNetworkAgency = (user, networkAgency) => {
    return user.isSuperAdmin || user.UserNetworkAgencies.some(ud => networkAgency = ud.NetworkAgencyID)
}

export const getUserName = (userID, usersList) => {
    if (userID) {
        if (usersList && usersList.length > 0) {
            const user = usersList.find(usr => usr.ID === userID)
            if (user) {
                let userName = user.Name
                if (user.Surname) {
                    userName += ` ${user.Surname[0]}`
                }
                return userName
            }
        }
    }
    return "unknown user"
}