import React, { useState } from 'react';
import CreateProjectDrawer from 'Components/CreateProject/CreateProjectDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import styled from 'styled-components';
import { NwLinkButton } from 'Components/Gui/NwButton';

const NoProjectContainer = styled(FlexContainer)`
    padding-left: 3rem;
    width: calc(100% - 1rem);
`

export const ProjectSelectorCard = ({onSelectProject}) => {
    const [isShowProjectModal, setIsShowProjectModal] = useState(false);

    const handleOpenProjectModal = () => {
        setIsShowProjectModal(true);
    }

    const handleCancel = () => {
        setIsShowProjectModal(false);
    }

    return (
        <>
            <NoProjectContainer justifyContent="space-between">
                <div>NO PROJECT</div>
                <NwLinkButton label="link to a project" onClick={handleOpenProjectModal} />
            </NoProjectContainer>
            {isShowProjectModal &&
                <CreateProjectDrawer
                    onCloseModal={handleCancel}
                    onChange={onSelectProject}
                    searchMode={true} />
            }
        </>
    );
};