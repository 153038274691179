import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getContactLegalsByContactID = ID => (
    Axios.get(`/contactlegals/getlegalsbycontact/${ID}`)
)

const deleteLegal = ({ LegalID, ContactID }) => (
    Axios.delete(`/contactlegals/${LegalID}`)
)

const addLegal = (data) => (
    Axios.post(`/contactlegals`, data)
)

const editLegal = (data) => (
    Axios.put(`/contactlegals`, data)
)

const useContactLegals = (ID) => (
    useQuery(["contactLegals", ID], () => getContactLegalsByContactID(ID), {
        staleTime: 60000,
        cacheTime: 60000
    })
)

const useRemoveLegal = () => {
    return useMutation(deleteLegal, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['contactLegals', variables.ContactID])
            const model = queryClient.getQueryData(['contact', variables.ContactID])
            const newLegalsCount = model.LegalsCount - 1;
            queryClient.setQueryData(['contact', variables.ContactID], (old) => ({
                ...old,
                LegalsCount: newLegalsCount,
            }))
        }
    })
}

const useAddLegal = () => {
    return useMutation(addLegal, { 
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['contactLegals', variables.ContactID])
            const model = queryClient.getQueryData(['contact', variables.ContactID])
            const newLegalsCount = model.LegalsCount + 1;
            queryClient.setQueryData(['contact', variables.ContactID], (old) => ({
                ...old,
                LegalsCount: newLegalsCount,
            }))
        }
    })
}

const useEditLegal = () => {
    return useMutation(editLegal, { 
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['contactLegals', variables.ContactID])
        }
    })
}

const onUploadLegal = (ContactID) => {
    queryClient.invalidateQueries(['contactLegals', ContactID])
}

export {
    useContactLegals,
    useRemoveLegal,
    useAddLegal,
    useEditLegal,
    onUploadLegal
}
