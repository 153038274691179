import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import NwIcon from "Components/Gui/NwIcon";
import { NwButton } from "Components/Gui/NwButton";
import { Divider, Popover, Tag } from "antd";
import StyleVariables from "./StyleVariables";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import AddressLine from "Components/Addresses/AddressLine";
import { HiddenMetricContainer } from "Containers/User/UserStyle";
import NwLink from "./NwLink";

const PopoverContent = styled.div`
    color: white;
    .email-item {
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        .email-text {
            margin-right: 20px;
        }
        .remove-email {
            margin-left: auto;
            cursor: pointer;
            user-select: none;
        }
    }
`;

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify ? props.justify : 'flex-end'};
    align-items: center;
    color: ${StyleVariables.TextColor};

    .line-container-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        margin-right: 0;
        padding: 0 0.25em 0 0;
        
        span {
            padding-right: .5em;
        }

        p {
            text-transform: uppercase;
            margin: 0;
            
            &.low {
                text-transform: lowercase;
            }
            
            small {
                display: block;
            }
        }

        &.line-container-left-phone, &.line-container-left-mail {
            p {
                text-overflow: ellipsis;
                //max-height: 1.5rem;
                line-height: 1.25rem !important;
                //overflow: hidden;
                max-width: 18rem;
                font-size: 1rem !important;
            }
        }
    }
`;

const KeepSpace = styled.div`
    min-width: 70px;
`

const MagnifiedPhone = styled.div`
    position: fixed;
    background: rgba(255,255,255,.75);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-style: normal;
        font-size: 10rem;
        color: #000;
    }
`

const InMailingListsContainer = styled.div`
    padding: .15rem 0 .15rem 1.6rem;
    font-size: .8rem;
    font-weight: 300;
    svg {
        margin-right: .5rem;
    }
`

const MailLinkString = (email, cc) => {
    let maillinkstring = `mailto:${email}`
    if (cc) {
        maillinkstring += `?cc=${cc.join(",")}`
    }
    return maillinkstring
}

const MailLink = ({ email, cc, disabled, linkText }) => {
    const openMail = () => {
        window.location.href = MailLinkString(email, cc);
    };
    return (
        linkText
            ?
            <NwLink
                tooltip="Send an e-mail to this address"
                disabled={disabled}
                onClick={openMail}
            >
                {linkText}
            </NwLink>
            :
            <NwButton
                ghost
                icon={light("paper-plane")}
                tooltip="Send an e-mail to this address"
                disabled={disabled}
                onClick={openMail}
            />
    );
};

const PhoneTypeIcon = (type) => {
    if (!type) return light("mobile");
    switch (type.toLowerCase()) {
        case "mobile":
            return light("mobile");
        case "land":
            return light("phone-square-alt");
        case "fax":
            return light("phone-square-alt");
        case "other":
            return light("phone-square-alt");
        default:
            return light("mobile");
    }
};

const PhoneLine = ({ phoneNumber, phoneType, disableMagnifier, justify, noIcon, onClick, mode }) => {
    const [magnifier, setMagnifier] = useState(false);
    const hasPhone = () => (phoneNumber && phoneNumber !== "")
    const cursorStyle = onClick ? { cursor: "pointer" } : {};

    const handleClick = () => {
        if (!disableMagnifier) {
            setMagnifier(true);
        }
    };

    const handleClose = () => {
        setMagnifier(false);
    };

    return (
        <>
            {mode === 'button' ?
                <NwButton lowercase='true' primary icon={PhoneTypeIcon(phoneType)} label={phoneNumber} onClick={handleClick} />
                :
                <LineContainer justify={justify}>
                    <div
                        className="line-container-left line-container-left-phone"
                        onClick={noIcon ? handleClick : onClick}
                        style={cursorStyle}
                    >
                        <span>
                            <NwIcon
                                icon={PhoneTypeIcon(phoneType)}
                                danger={!hasPhone()}
                            />
                        </span>
                        <p className={hasPhone() ? "" : "low"}>
                            {hasPhone() ? phoneNumber : "no mobile"}
                        </p>
                    </div>

                    {!noIcon &&
                        <>
                            {hasPhone() && !disableMagnifier ?
                                <>
                                    <Divider type="vertical" />
                                    <div className="line-container-right">
                                        <PhoneMagnifier
                                            disabled={!hasPhone()}
                                            phone={hasPhone() ? phoneNumber : ""}
                                        />
                                    </div>
                                </>
                                :
                                <KeepSpace />
                            }
                        </>
                    }
                </LineContainer>
            }
            {magnifier && BigPhoneNumber(phoneNumber, handleClose)}
        </>
    );
};

const InMailingLists = ({ mailingLists, onAddToMailingList }) => {
    if (mailingLists && mailingLists.length > 0) {
        return (<InMailingListsContainer className="nw-link" onClick={onAddToMailingList}><NwIcon icon={light('mailbox')} />in {mailingLists.length} mailing lists</InMailingListsContainer>)
    }
    return (<></>)
}


const MailLine = ({
    disableMailTo,
    groupCC,
    justify,
    mailAddress,
    mailCC,
    mode,
    noIcon,
    onClick,
}) => {

    const hasMail = () => (mailAddress && mailAddress !== "")
    const hasCC = () => (hasMail() && mailCC && (mailCC.length > 0))

    const cursorStyle = onClick ? { cursor: "pointer" } : {};

    const handleClick = () => {
        if (!disableMailTo) {
            window.location.href = MailLinkString(mailAddress, mailCC)
        }
    };

    return (mode === 'button' ?
        <NwButton lowercase='true' primary icon={light('envelope')} label={mailAddress} onClick={handleClick} />
        : <LineContainer justify={justify}>
            <div
                className="line-container-left line-container-left-mail"
                onClick={noIcon ? handleClick : onClick}
                style={cursorStyle}
            >
                <span>
                    <NwIcon
                        icon={light("envelope")}
                        danger={!hasMail()}
                    />
                </span>
                <p className="low">
                    {hasMail() ? mailAddress : "no e-mail"}
                    {hasCC() &&
                        ((groupCC || mailCC.length > 3)
                            ?
                            <Popover
                                overlayClassName="recipients-popover"
                                content={
                                    <PopoverContent>
                                        <div>CCs:</div>
                                        {mailCC && mailCC.map((email, cIndex) => {
                                            return (
                                                <div
                                                    key={cIndex}
                                                    className="email-item"
                                                    style={{ borderTop: cIndex ? "1px dashed white" : "none" }}
                                                >
                                                    <div className="email-text">{email}</div>
                                                </div>
                                            )
                                        })}
                                    </PopoverContent>
                                }
                            >
                                <Tag style={{ marginLeft: '.25rem', display: 'inline' }} color={StyleVariables.PrimaryDark}>
                                    {mailCC.length} CC
                                </Tag>
                            </Popover>
                            :
                            <small>CC: {mailCC.join("; ")}</small>
                        )
                    }
                </p>
            </div>
            {!noIcon &&
                <>
                    {hasMail() ?
                        <>
                            <Divider type="vertical" />
                            <div className="line-container-right">
                                <MailLink
                                    disabled={!hasMail()}
                                    email={hasMail() ? mailAddress : ""}
                                    cc={hasCC() && mailCC}
                                />
                            </div>
                        </>
                        :
                        <KeepSpace />
                    }
                </>
            }
        </LineContainer>
    );
};

const ContactAddressLine = ({ address, disableMap, noIcon, justify, onClick, twoLines }) => {
    return (
        <LineContainer justify={justify}>
            <AddressLine address={address} disableMap={disableMap} noIcon={noIcon} onClick={onClick} twoLines />
        </LineContainer>
    );
};

const BigPhoneNumber = (phone, onClose) =>
    ReactDOM.createPortal(
        <MagnifiedPhone onClick={onClose}>
            <h1>{phone}</h1>
        </MagnifiedPhone>,
        document.body
    );

const PhoneMagnifier = ({ phone, disabled, readOnly, linkText }) => {
    const [magnifier, setMagnifier] = useState(false);

    const handleClose = () => {
        setMagnifier(false);
    };

    return (
        <>
            {!readOnly && (
                (linkText
                    ?
                    <NwLink
                        tooltip="Show the phone number full screen"
                        disabled={disabled}
                        onClick={event => {
                            event.stopPropagation();
                            setMagnifier(true)
                        }}
                    >
                        {linkText}
                    </NwLink>
                    :
                    <NwButton
                        ghost
                        icon={light("search-plus")}
                        tooltip="Show the phone number full screen"
                        disabled={disabled}
                        onClick={event => {
                            event.stopPropagation();
                            setMagnifier(true)
                        }}
                    />
                )
            )}
            {magnifier && BigPhoneNumber(phone, handleClose)}
        </>
    );
};

export { MailLink, PhoneMagnifier, PhoneLine, MailLine, InMailingLists, ContactAddressLine };
