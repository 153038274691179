import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Layout } from "antd";

import ProjectComments from "Components/Project/ProjectComments/ProjectComments";
import ProjectDocuments from "Components/Project/ProjectDocuments/ProjectDocuments";
import ProjectTransactions from 'Components/Project/ProjectTransactions/ProjectTransactions';
import ProjectOverview from 'Components/Project/ProjectOverview/ProjectOverview';
import ProjectPageHeader from "Components/Project/ProjectPageHeader";
import ProjectDetailTabs from 'Components/Project/ProjectDetailTabs';
import ProjectContracts from 'Components/Project/ProjectContracts/ProjectContracts';
import ProjectContacts from 'Components/Project/ProjectContacts/ProjectContacts';
import ProjectEventModels from 'Components/Project/ProjectEventModels';
import ProjectEvents from "Components/Project/ProjectEvents/ProjectEvents";
import ProjectTodos from "Components/Project/ProjectTodos/ProjectTodos";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useHistory } from "react-router";
import { useProject } from "Hooks/Project/UseProject";
import { Loading } from "Components/Gui/Loading";
import { UserContext } from "Contexts/UserContext";
import { ListContext } from "Contexts/ListContext";
import StyleVariables from "Components/Gui/StyleVariables";

const StyledLayout = styled(Layout)`
    min-height: calc(100vh - 168px);
    padding: ${props => props.$mobileView ? "8px 0 16px 0" : "8px 16px 16px"};
`;

const ProjectPage = ({
    eventId,
    eventType,
    isdrawer,
    match,
    onClose,
    projectId,
}) => {
    const breakpoints = useNwBreakPoints();
    const selectedProject = isdrawer ? projectId : Number(match.params.projectId);
    const selectedEvent = isdrawer ? eventId : Number(match.params.eventId);
    const selectedEventType = isdrawer ? eventType : match.params.eventType;
    const history = useHistory();
    const [eventIDAndType, setEventIDAndType] = useState();
    const [layout, setLayout] = useState('overview');
    const { isLoading, error, data: projectData } = useProject(selectedProject);
    const { currentUser } = useContext(UserContext);
    const { cachedList } = useContext(ListContext);

    const handleMoveToEvent = (eventID, eventType) => {
        const evIDType = { ID: eventID, TypeName: eventType }
        setEventIDAndType(evIDType);
        window.scrollTo(0, 0);
        setLayout('calendar');
    };

    useEffect(() => {
        if (error) {
            console.log(error, projectData)
        }
    }, [isLoading, error])

    useEffect(() => {
        setEventIDAndType(null)
        setLayout('overview')
    }, [projectId])

    useEffect(() => {
        if (selectedEvent && selectedEventType) {
            handleMoveToEvent(Number(selectedEvent), selectedEventType);
        }
    }, [selectedEvent, selectedEventType])


    const handleSetLayout = layout => {
        setLayout(layout);
    };

    const afterProjectDelete = () => {
        if (isdrawer && onClose) {
            onClose()
        } else {
            history.push(`/schedule`)
        }
    }

    const getNetworkAgencyName = (networkAgencyId) => {
        let networkAgencies = [];
        for (const accountingItem of cachedList.accountingGroups.items) {
            networkAgencies = [...networkAgencies, ...accountingItem.NetworkAgencies];
        }
        const networkAgency = networkAgencies.find(agency => agency.ID === networkAgencyId);
        return networkAgency.Name;
    }


    const tabs = [
        <ProjectOverview
            projectId={selectedProject}
            currentProject={projectData}
            onMoveToEvent={handleMoveToEvent}
            afterProjectDelete={afterProjectDelete}
        />,
        <ProjectEvents
            openOnEvent={eventIDAndType}
            currentProject={projectData}
            projectId={selectedProject}
            onSetLayout={handleSetLayout}
        />,
        <ProjectEventModels
            projectId={selectedProject}
            currentProject={projectData}
        />,
        <ProjectTransactions projectId={selectedProject} />,
        <ProjectContacts projectId={selectedProject} />, //currentProject={projectData}
        <>
            <ProjectDocuments projectId={selectedProject} />
            <ProjectContracts projectId={selectedProject} />
        </>,
        <ProjectComments projectId={selectedProject} currentProject={projectData} />,
        <ProjectTodos projectId={selectedProject} currentProject={projectData} />
    ]

    if (isLoading) {
        return <Loading textBlack paddingTop="5rem" />
    }

    if (error || !projectData) {
        return <div>error</div>
    }

    return (
        projectData
            ?
            ((projectData.NetworkAgencyCreated !== currentUser.ActiveContext.nwAgId)
                ?
                <div style={{padding: '6rem 2rem', textAlign: 'center'}}>
                    <h2>Please switch to the correct network agency ({getNetworkAgencyName(projectData.NetworkAgencyCreated)}) to view this project</h2>
                    <p><a style={{textDecoration: 'underline', color: StyleVariables.PrimaryColor }} href="/schedule/">Go back to booking chart</a></p>
                </div>
                :
                <>
                    <ProjectPageHeader
                        projectId={selectedProject}
                        currentProject={projectData}
                        isdrawer={isdrawer}
                    />
                    <StyledLayout $mobileView={breakpoints < 4}>
                        <ProjectDetailTabs
                            isdrawer={isdrawer}
                            tabs={tabs}
                            layout={layout}
                            projectId={selectedProject}
                            onSetLayout={handleSetLayout}
                        />
                    </StyledLayout>
                </>
            )
            :
            <Loading textBlack paddingTop="5rem" />
    );
}

export default ProjectPage;

