import React from 'react';
import { find, isString } from 'lodash';
import { getMoment } from "Libs/NwMoment";
import ls from 'local-storage';
import constants from 'Constants';
import NwIcon from 'Components/Gui/NwIcon';
import emptystatesdrawings from '_img/drawings/index';
import { CURRENCIES } from 'Configs/CURRENCIES';
import { showDateNoTime } from 'Libs/NwMoment';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const getBaseUrl = () => {
    return constants.baseapiurl;
};

const getRoot = (path) => {
    return "/assets" + path;
}

const saxonize = (string) => {
    //add 's to the end of a string or only ' if the string ends with s
    if (string.substr(string.length - 1) === "s") {
        return string + "'";
    }
    return string + "'s";
}

const normalizePlaceholder = (placeholder) => {
    if (isString(placeholder)) {
        return unbracketize(placeholder);
    }
    return placeholder;
}

const unbracketize = (stringtounbracketize) => {
    var newstring = stringtounbracketize;
    if (newstring.substr(0, 1) === "{") {
        newstring = newstring.substr(1);
    }
    if (newstring.substr(newstring.length - 1) === "}") {
        newstring = newstring.substr(0, newstring.length - 1)
    }
    return newstring;
}

//utils for numbers
const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const deaccentize = (string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const deaccentizeandlowerize = (string) => {
    return deaccentize(string).toLowerCase();
}

const getOrdinal = (n) => {
    let ord = 'th';
    if (n % 10 === 1 && n % 100 !== 11) {
        ord = 'st';
    }
    else if (n % 10 === 2 && n % 100 !== 12) {
        ord = 'nd';
    }
    else if (n % 10 === 3 && n % 100 !== 13) {
        ord = 'rd';
    }
    return ord;
}

const friendlyNumber = (num) => {
    const intlFormat = (num) => {
        return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    }
    if (num >= 1000000) {
        return intlFormat(num / 1000000) + 'M';
    }
    if (num >= 1000) {
        return intlFormat(num / 1000) + 'k';
    }
    return intlFormat(num);
}

//utils for ES6
function importAll(r) {
    return r.keys().map(r);
}

//utils for Pagination
//Receive the current page, items per page and the whole data array
//returns the sliced data for the current page
function sliceData(currentPage, itemsPerPage, data) {
    if (!data) {
        return []
    }
    const offset = (currentPage - 1) * itemsPerPage
    let cdata = [];
    if (data.length && data.length > itemsPerPage) {
        cdata = data.slice(offset, offset + itemsPerPage)
    } else {
        cdata = data;
    }
    return cdata;
}

function checkValidPage(currentPage, itemsPerPage, data, callback) {
    if (currentPage !== 1 && data.length > 0) {
        const maxPage = Math.ceil(data.length / itemsPerPage);
        if (currentPage > maxPage && callback) {
            callback(currentPage - 1)
        }
    }
}

const getCloudFilesContainerUrl = () => {
    const tenantConfigurations = ls.get('nw_tenant_configurations');
    return tenantConfigurations ? tenantConfigurations.CloudFilesContainerUrl : '';
};

//used for contacts and playlist covers (where you can only find guids)
const getUrlCover = (guid) => getCloudFilesContainerUrl()
    ? `${getCloudFilesContainerUrl()}/${guid}_c.jpg` : ''

const getUrlDocument = (p) => {
    if (p.Standard) {
        return p.Standard.Url
    }
    if (p.Guid && p.Extension) {
        return `/api/documents/getprivatefile/${p.Guid}.${p.Extension}`
    }
    return null
}

const getUserCover = (Guid) => getUrlCover(Guid)

const getVideoCover = (document) => {
    if (document.Thumbnail && document.Thumbnail.Url) {
        return document.Thumbnail.Url;
    }
    //return emptystatesdrawings["emptybook"]
    return null
}

const getThumbnailWidth = (media, thumbheight) => {
    let mediaHeight = media.Standard.Height;
    let mediaWidth = media.Standard.Width;
    if (media.Thumbnail && media.Thumbnail.Height) {
        mediaHeight = media.Thumbnail.Height;
    }
    if (media.Thumbnail && media.Thumbnail.Width) {
        mediaWidth = media.Thumbnail.Width;
    }
    if (thumbheight && mediaWidth) {
        const ratio = mediaHeight / thumbheight;
        return mediaWidth / ratio;
    }
    if (!mediaWidth) {
        if (media.type === "video") {
            return 320;
        }
        return 180;
    }
    return mediaWidth;
}

const getThumbnailHeight = (p, thumbheight) => {
    if (p.Thumbnail && p.Thumbnail.Height) {
        return p.Thumbnail.Height;
    }
    return thumbheight || 180;
};


const getBookCover = (book) => {
    if (book.Covers) {
        if (book.Covers.length > 0) {
            const standardcover = find(book.Covers, { CoverFormat: "Standard" });
            if (standardcover) {
                return standardcover.Url
            }
            return book.Covers[0].Url
        }
    }
    if (book.CoverUrl) {
        return book.CoverUrl
    }
    return emptystatesdrawings["emptybook"]
}


//UTILS FOR TAGS

const mapTags = (fulltags, objecttype) => {
    return (fulltags.reduce(function (filtered, tag) {
        if (tag.TagTypeName === objecttype) {
            var newTag = { ID: tag.ID, Name: tag.Name.toLowerCase() }
            filtered.push(newTag);
        }
        return filtered;
    }, []))
}

const sortTags = (tags) => {
    return tags.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0))
}

const capitalize = text => {
    text = text
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    return text;
};

const getMediaType = typeName => {
    switch (typeName) {
        case 'Files':
            return 1;
        case 'Images':
            return 2;
        case 'Photo':
            return 2;
        case 'Videos':
            return 3;
        default:
            return 1;
    }
};

const isLandscape = (media) => {
    if ((media.Type && media.Type === "Video") || (media.TypeName && media.TypeName === "Video")) {
        return true;
    }
    if (media.Standard && media.Standard.Width && media.Standard.Height) {
        return (media.Standard.Width >= media.Standard.Height)
    } else {
        if (media.thumbnailWidth && media.thumbnailHeight) {
            return (media.thumbnailWidth >= media.thumbnailHeight)
        }
        if (media.Thumbnail && media.Thumbnail.Width && media.Thumbnail.Height) {
            return (media.Thumbnail.Width >= media.Thumbnail.Height)
        }
        return false
    }
};

const isDocumentPage = () => {
    const paths = window.location.pathname.split('/');
    const isDocument = paths.length > 0 && paths[1] === 'document';
    return isDocument;
};



const validateDates = (value) => {
    let error;
    if (value) {
        if (value.startdate.isAfter(value.enddate)) {
            error = 'It ends before it starts';
        }
        if (value.enddate.diff(value.startdate, "months") > 12) {
            error = "event longer than a year"
        }
    }
    return error;
};

const getDaysInMonth = (month, year) => {
    return (!year || !month) ? 31 : new Date(year, month + 1, 0).getDate();
};

const validateDate = (date, nullable) => {
    let error;
    if (date) {
        if ((date.year === null && date.month === undefined && date.day === null)) {
            if (nullable) {
                return ''
            }
            return 'Please input date';
        }
        else if ((date.year !== null && date.month !== undefined && date.day !== null)) {
            if (getDaysInMonth(date.month, date.year) < date.day) {
                return 'Please input valid date';
            }
            return error;
        }
        else {
            return 'Please input valid date';
        }
    }
    return error;
};

const validateAddress = (value) => {
    let error;
    if (value) {
        const emptyValues = [];
        if (!value.Street) {
            emptyValues.push('street');
        }
        if (!value.City) {
            emptyValues.push('city');
        }
        if (!value.Country) {
            emptyValues.push('country');
        }
        if (emptyValues.length > 0) {
            error = `Please input ${emptyValues.join(', ')}!`;
        }
        return error;
    } else {
        return 'Please input address';
    }
}

const validateVisibility = (visibility, area) => () => {
    if (!visibility.isGlobal) {
        if (!visibility.Areas.find(item => item === area)) {
            return 'Current area should be included';
        }
    }
    return null;
};

const getEventFieldName = eventType => {
    switch (eventType) {
        case 'travel':
            return 'TravelID';
        case 'followup':
            return 'FollowUpID';
        case 'event':
            return 'EventID';
        case 'meeting':
            return 'MeetingID';
        case 'todo':
            return 'TodoID';
        case 'extjob':
            return 'ExternalJobID';
        case 'accommodation':
            return 'AccommodationID';
        default:
    }
};

const getCurrency = currencyCode => {
    const currency = CURRENCIES.find(item => item.code === currencyCode);
    return `${currency ? currency.symbol : ''}`;
};

const validateAmount = value => {
    let error;
    if (!value[1]) {
        error = 'Amount is required';
    } else {
        if (isNaN(Number(value[1]))) {
            error = 'Amount is required';
        } else {
            if (Number(value[1]) === 0) {
                error = 'Amount is required';
            }
        }
    }
    return error;
};

const validateAmountZeroAllowed = value => {
    let error;
    if (value[1] === null) {
        error = 'Amount is required';
    } else {
        if (isNaN(Number(value[1]))) {
            error = 'Amount is required';
        }
    }
    return error;
};

const enumerateDaysBetweenDates = function (startDate, endDate) {
    let dates = [];
    const currDate = getMoment(startDate).startOf('day');
    const lastDate = getMoment(endDate).startOf('day');
    dates.push(currDate.clone().toDate());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
    }
    return dates;
};

const GetForeColor = (colorsList, backColor) => {
    const colors = colorsList.find(colors => colors.find(color => color === backColor));
    if (colors) {
        const colorIndex = colors.findIndex(color => color === backColor);
        if (colorIndex >= 1) {
            return '#ffffff';
        } else {
            return '#000000';
        }
    } else {
        if (backColor === '#000000') {
            return '#ffffff';
        }
        return '#000000';
    }
};

const getTransportIcon = transport => {
    switch (transport) {
        case 'Plane':
            return light('plane-departure');
        case 'Train':
            return light('train');
        case 'Bus':
            return light('bus');
        case 'Driver':
        case 'Boat':
            return light('ship');
        case 'Car':
            return light('car');
        case 'Taxi':
            return light('taxi');
        default: return light('plane');
    }
};

const getFileTypeIcons = (extension) => {
    switch (extension.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "tif":
        case "gif":
        case "png":
            return light("file-image");
        case "doc":
        case "docx":
            return light("file-word");
        case "ppt":
        case "pptx":
            return light("file-word");
        case "pdf":
            return light("file-pdf");
        case "xls":
        case "xlsx":
        case "csv":
            return light("file-spreadsheet");
        case "zip":
        case "rar":
            return light("file-archive");
        case "mp4":
        case "mov":
        case "mkv":
            return light("file-video");
        case "txt":
            return light("file-lines");
        default:
            return light("file");
    }
};

const validatePeriod = (value, disableDates) => {
    let error;
    if (disableDates && (!value.enableStart || !value.enableEnd)) {
        return null;
    }
    if (value.startDate > value.endDate) {
        error = 'It ends before it starts';
    }
    return error;
};

const mergeCustomFields = (definitions, values, showEmpty) => {
    const mergedFields = []
    for (const def of definitions) {
        const value = values[def.Name];
        if (value || showEmpty) {
            mergedFields.push({
                ...def,
                Value: value || null
            })
        }
    }
    return mergedFields
};

const parseCustomFieldValue = (field) => {
    if (field && field.TypeName === 'Date') {
        if (!field.Value) {
            return '';
        }
        return showDateNoTime(field.Value);
    }
    if (field && field.TypeName === 'MultipleChoice') {
        if (!field.Value) {
            return '';
        }
        return field.Value.split(',').join(' ');
    }
    if (field && field.TypeName === 'Boolean') {
        if (field.Value) {
            return <NwIcon icon={solid('check')} />
        } else {
            return <NwIcon icon={light('circle-xmark')} />
        }
    }
    if (!field.Value) {
        return '';
    }
    return field.Value;
}


const getCustomFieldValue = (key, value, customFields) => {
    const customField = customFields.find(customField => customField.Name === key);
    if (customField && customField.TypeName === 'Date') {
        return showDateNoTime(value);
    }
    if (customField && customField.TypeName === 'MultipleChoice') {
        return value.split(',').join(' ');
    }
    if (customField && customField.TypeName === 'Boolean') {
        return <NwIcon icon={solid('check')} />
    }
    return value;
};

const compareArrayTimes = (time1, time2) => {
    if (time1[0] > time2[0] || (time1[0] === time2[0] && time1[1] > time2[1])) {
        return true
    }
    return false;
};

const getEndTime = (previousStartTime, previousEndTime, startTime) => {
    let endTimeValue = previousEndTime[0] * 60 + previousEndTime[1];
    const difference = (endTimeValue === 1439 ? 1440 : endTimeValue) - previousStartTime[0] * 60 - previousStartTime[1];
    let endTime = startTime[0] * 60 + startTime[1] + difference;
    endTime = endTime >= 1440 ? 1439 : endTime;
    return endTime;
};

const checkIfArrayIsUnique = array => {
    let map = {}, i, size;

    for (i = 0, size = array.length; i < size; i++) {
        if (map[array[i]]) {
            return false;
        }

        map[array[i]] = true;
    }

    return true;
}

const groupPackage = (currentPackage) => {
    if (currentPackage) {
        let groupedModels = []
        let groupNames = []
        let modelIdsByGroup = []
        groupNames = [...new Set(currentPackage.Models.map(item => item.Group))];
        if (groupNames.length === 1 && !groupNames[0]) {
            //groupedModels = { models: [...currentPackage.Models] }
            //TODO: modificare con oggetto più complesso
            groupedModels = [
                { groupName: '', groupLabel: 'models', models: [...currentPackage.Models] }
            ]
            modelIdsByGroup.push({ group: '', modelIDs: currentPackage.Models.map(item => item.ID) })
        } else {
            //TODO: modificare con ciclo manuale per costruzione oggetto
            // const groupBy = key => array =>
            //     array.reduce((objectsByKeyValue, obj) => {
            //         const value = obj[key] ? obj[key] : 'not grouped';
            //         objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            //         return objectsByKeyValue;
            //     }, {});
            //const groupByGroupName = groupBy('Group');
            //groupedModels = groupByGroupName(currentPackage.Models)
            for (const mod of currentPackage.Models) {
                //find group in groupmodels
                const groupIndex = groupedModels.findIndex(group => group.groupName === mod.Group);
                if (groupIndex !== -1) {
                    groupedModels[groupIndex].models.push(mod);
                } else {
                    groupedModels.push({
                        groupName: mod.Group,
                        groupLabel: mod.Group ? mod.Group : 'not grouped',
                        models: [mod]
                    })
                }
                //find group in modelIdsByGroup
                const modelIdsByGroupIndex = modelIdsByGroup.findIndex(group => group.group === mod.Group);
                if (modelIdsByGroupIndex !== -1) {
                    modelIdsByGroup[modelIdsByGroupIndex].modelIDs.push(mod.ID);
                } else {
                    modelIdsByGroup.push({
                        group: mod.Group,
                        modelIDs: [mod.ID]
                    })
                }
            }
        }
        return { ...currentPackage, GroupedModels: groupedModels, GroupNames: groupNames, ModelIdsByGroup: modelIdsByGroup }
    }
};

const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map((part, index) => part.toLowerCase() === highlight.toLowerCase() ? <b key={index}>{part}</b> : part)}</span>;
};

const getValidText = text => {
    return text ? text : ''
};

const isEmpty = value => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
};

const findPath = (websites, websiteCode, sectionCode, prefix, hideWebsiteName = false) => {
    let path = "";
    const findSubPath = (data, sectionCode, subPath) => {
        for (const item of data) {
            if (item.code.toLowerCase() === sectionCode.toLowerCase()) {
                return `${subPath.toUpperCase()} > ${item.name.toUpperCase()}`;
            }
            if (item.subNav && item.subNav.length) {
                const _path = findSubPath(item.subNav, sectionCode, `${subPath.toUpperCase()} > ${item.name.toUpperCase()}`);
                if (_path) return _path;
            }
        };
        return "";
    }
    for (const website of websites) {
        if (website.Code.toLowerCase() === websiteCode.toLowerCase()) {
            const _subPath = findSubPath(website.Structure.nav, sectionCode, "");
            if (!hideWebsiteName) {
                path = `${prefix}${website.Name.toUpperCase()} - ${_subPath.slice(2)}`;
            } else {
                path = `${prefix}${_subPath.slice(2)}`;
            }
            break;
        }
    }
    return path;
}

//check if bgency or modamoda or localhost or dev. or dev2. are part of fullurl
const isPayrollBetaActive = () => {
    const fullurl = window.location.href;
    const domains = ['nw4demo.','bgency.', 'modamoda.', 'localhost:', 'dev.', 'dev2.'];
    return domains.some(domain => fullurl.includes(domain));
}



const getEmptyAddress = () => {
    return {
        Street: '',
        City: '',
        Zip: '',
        State: '',
        County: '',
        Country: '',
        Access: '',
    }
}


export {
    capitalize,
    checkIfArrayIsUnique,
    checkValidPage,
    compareArrayTimes,
    deaccentize,
    deaccentizeandlowerize,
    enumerateDaysBetweenDates,
    findPath,
    friendlyNumber,
    getBaseUrl,
    getBookCover,
    getCloudFilesContainerUrl,
    getCurrency,
    getCustomFieldValue,
    getEmptyAddress,
    getEndTime,
    getEventFieldName,
    getFileTypeIcons,
    GetForeColor,
    getHighlightedText,
    getMediaType,
    getOrdinal,
    getRoot,
    getThumbnailHeight,
    getThumbnailWidth,
    getTransportIcon,
    getUrlCover,
    getUrlDocument,
    getUserCover,
    getValidText,
    getVideoCover,
    groupPackage,
    importAll,
    isDocumentPage,
    isEmpty,
    isLandscape,
    isPayrollBetaActive,
    mapTags,
    mergeCustomFields,
    normalizePlaceholder,
    parseCustomFieldValue,
    randomInt,
    saxonize,
    sliceData,
    sortTags,
    validateAddress,
    validateAmount,
    validateAmountZeroAllowed,
    validateDate,
    validateDates,
    validatePeriod,
    validateVisibility,
}
