import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";
const Option = Select.Option;

const LabelSelector = ({ onChange, value, disabled, onBlur }) => {
    const { cachedList } = useContext(ListContext);
    const families = cachedList.transactionFamilies.items;
    const labels = [];
    
    families.map(item => {
        labels.push(...item.TransactionLabels);
        return null;
    });

    return (
        <Select
            autoComplete='off'
            mode="multiple"
            value={value}
            placeholder="Please select"
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        >
            {labels.map(el => (
                <Option key={el.ID} value={el.ID}>
                    {el.Label}
                </Option>
            ))}
        </Select>
    );
};

export default LabelSelector;
