import React, { useState } from "react";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from "Components/Gui/NwIcon";
import { forceReloadPackage } from 'Hooks/Packages/UsePackages';
import ModelDetailsBooks from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooks';
import AddMediaDrawer from "Components/PackageDrawer/PackageModelCard/AddMediaDrawer/AddMediaDrawer";
import { PackageActionsContainer } from "./ModelsTableUI";
import Axios from "axios";
import NwDrawer from "Components/Gui/NwDrawer";



const ModelsTableColumnPackage = ({
    currentPackage,
    isDrawer,
    model,
    newlyAddedToPackage,
    onAddingBooksToPackage,
    onRemovingBookFromPackage,
    packageGroup,
}) => {
    // Filter and order Main Features depending on User Metrics Config
    const [isOpenModelBooksDrawer, setIsOpenModelBooksDrawer] = useState(false);
    const [modelId, setModelId] = useState();
    const [refreshing, setRefreshing] = useState(false);
    const [isMediaDrawer, setIsMediaDrawer] = useState(false);
    const [isAddingFastPack, setIsAddingFastPack] = useState(0);

    const handleOpenModelBooksDrawer = modelId => {
        setModelId(modelId);
        setIsOpenModelBooksDrawer(true);
    };

    const handleCloseModelBooksDrawer = () => {
        setIsOpenModelBooksDrawer(false);
        setModelId();
    };

    const handleOpenAddMediaDrawer = modelId => {
        setModelId(modelId);
        setIsMediaDrawer(true);
    };

    const handleCloseAddMediaDrawer = () => {
        setIsMediaDrawer(false);
        setModelId();
    };

    // const getPlaylistCount = (model) => {
    //     if (!isDrawer) return 0;
    //     if (currentPackage && currentPackage.Models) {
    //         const found = currentPackage.Models.find(item => item.ModelID === model.ID);
    //         if (found && found.Playlists) {
    //             return found.Playlists.length;
    //         }
    //     }
    //     return 0;
    // }

    const getModelPlaylists = (modelID) => {
        if (!isDrawer) return [];
        const modelPlaylistsInPackage = []
        if (currentPackage && currentPackage.Models) {
            const found = currentPackage.Models.find(item => item.ModelID === modelID);
            if (found && found.Playlists) {
                const playlistsInSavedPackage = found.Playlists.map((pl) => pl.PlaylistID);
                modelPlaylistsInPackage.push(...playlistsInSavedPackage)
            }
        }
        if (newlyAddedToPackage && newlyAddedToPackage.length > 0) {
            const found = newlyAddedToPackage.find(item => item.modelID === modelID);
            if (found && found.playlistIDs && found.playlistIDs.length > 0) {
                const playlistsInNewlyAdded = found.playlistIDs;
                modelPlaylistsInPackage.push(...playlistsInNewlyAdded)
            }
        }
        return modelPlaylistsInPackage;
    }

    const openBooksDrawer = (modelID) => {
        handleOpenModelBooksDrawer(modelID);
    }

    const addFastPack = async (modelID) => {
        setIsAddingFastPack(modelID)
        var data = { PackageID: currentPackage.ID, Models: [modelID], PackageGroup: packageGroup }
        const addedBookIds = await Axios.post("/PackagePlaylists/AddFastPackages", data)
        //forceReloadPackage(currentPackage.ID)
        onAddingBooksToPackage(modelID, addedBookIds)
        setIsAddingFastPack(0)
    }

    const enableFastPack = (model) => {
        const isInSavedPackage = currentPackage.Models.some(item => item.ModelID === model.ID);
        const isInNewlyAddedPackage = newlyAddedToPackage.some(item => item.modelID === model.ID);
        return isInSavedPackage || isInNewlyAddedPackage;
    }

    const handleAddBook = async book => {
        setRefreshing(true);
        try {
            const data = {
                packageID: currentPackage.ID,
                playlistID: book.ID,
                PackageGroup: packageGroup
            }
            await Axios.post('/PackagePlaylists', data)
            onAddingBooksToPackage(book.ContactID, [book.ID])
            setRefreshing(false);
        } catch (error) {
            console.log('ant : Add Playlist To Package Error => ', error);
            setRefreshing(false);
        }
    };

    const handleDeleteBook = async book => {
        setRefreshing(true);
        const model = currentPackage.Models.find(model => model.ModelID === book.ContactID);
        let foundInSavedPackage = false
        if (model) {
            const playlist = model.Playlists.find(playlist => playlist.PlaylistID === book.ID);
            if (playlist) {
                const packagePlaylistID = playlist.ID;
                foundInSavedPackage = true
                try {
                    //await onDeletePlaylistFromPackage({ packageID: currentPackage.ID, packagePlaylistId: playlistID, isActivePackage: true });
                    await Axios.delete(`/PackagePlaylists/${packagePlaylistID}`)
                    forceReloadPackage(currentPackage.ID)
                    setRefreshing(false)
                } catch (error) {
                    console.log('ant : Delete Playlist To Package Error => ', error)
                    setRefreshing(false)
                }
            }
        }
        if (!foundInSavedPackage) {
            const foundInNewlyAddedPackage = newlyAddedToPackage.find(item => item.modelID === book.ContactID);
            if (foundInNewlyAddedPackage) {
                const index = foundInNewlyAddedPackage.playlistIDs.indexOf(book.ID);
                if (index > -1) {
                    await Axios.delete(`/PackagePlaylists/byPlaylistID/${currentPackage.ID}/${book.ID}`)
                    setRefreshing(false)
                    onRemovingBookFromPackage(book.ContactID, book.ID)
                } else {
                    setRefreshing(false)
                }
            } else {
                setRefreshing(false)
            }
        }
    }

    return (
        <>
            {model.PL
                ?
                <PackageActionsContainer>
                    {model.FP
                        ?
                        <NwIcon size="2x" disabled={enableFastPack(model)} loading={(isAddingFastPack === model.ID)} icon={solid("truck-fast")} tooltip={`fast pack (${model.FP})`} badge={model.FP} primary onClick={() => addFastPack(model.ID)} />
                        :
                        <NwIcon size="2x" icon={solid("truck-fast")} disabled primary tooltip={`fast pack (0)`} />
                    }
                    <NwIcon size="2x" icon={solid("images")} tooltip="select books" primary onClick={() => openBooksDrawer(model.ID)} />
                    <NwIcon size="2x" icon={solid("folder-user")} tooltip="create a new media selection for this package" primary onClick={() => handleOpenAddMediaDrawer(model.ID)} />
                </PackageActionsContainer>
                :
                <p>no books</p>
            }
            {isOpenModelBooksDrawer &&
                <NwDrawer
                    title='Model Books'
                    onClose={handleCloseModelBooksDrawer}>
                    <ModelDetailsBooks
                        isInCardTab
                        readOnly
                        packageEditView
                        contactID={modelId}
                        addedPlaylists={getModelPlaylists(modelId)}
                        onAddBook={handleAddBook}
                        onRemoveBook={handleDeleteBook}
                    />
                </NwDrawer>
            }
            {isMediaDrawer &&
                <AddMediaDrawer
                    onClose={handleCloseAddMediaDrawer}
                    modelId={modelId}
                    packageId={currentPackage.ID}
                    packageGroup={packageGroup}
                />
            }
        </>
    )
};

export default ModelsTableColumnPackage;
