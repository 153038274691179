import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import Axios from 'axios';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from '../NwButton';
import NwDrawer from '../NwDrawer';


const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const SignatureSelector = ({ onChange, value, onSelectSignature }) => {
    const [signatures, setSignatures] = useState([])
    const [selectedSignatureID, setSelectedSignatureID] = useState(null)
    const [showPreviewDrawer, setShowPreviewDrawer] = useState(false)
    const [loading, setLoading] = useState(false)

    const loadSignatures = async () => {
        setLoading(true)
        // load signatures
        const signatures = await Axios.get("/usersignatures/forcurrentuser")
        setSignatures(signatures)
        setLoading(false)
    }

    const getSelectedSignature = () => {
        if (signatures && signatures.length > 0) {
            return signatures.find(s => s.ID === selectedSignatureID)
        }
        return null
    }

    useEffect(() => {
        loadSignatures()
    }, [])

    useEffect(() => {
        if (signatures && signatures.length > 0 && !value) {
            const defaultSignatureID = getDefaultSignatureID()
            if (defaultSignatureID) {
                handleChange(defaultSignatureID)
            }
        }
    }, [signatures])

    useEffect(() => {
        if (onSelectSignature) {
            if (selectedSignatureID) {
                onSelectSignature(getSelectedSignature())
            } else {
                onSelectSignature(null)
            }
        }
    }, [selectedSignatureID])

    const handleChange = (value) => {
        setSelectedSignatureID(value)
        onChange(value)
        // if (onSelectSignature) {
        //     onSelectSignature(getSelectedSignature())
        // }
    }

    const getDefaultSignatureID = () => {
        if (signatures && signatures.length > 0) {
            const defaultSignature = signatures.find(s => s.IsDefault)
            return defaultSignature ? defaultSignature.ID : signatures[0].ID
        }
        return null
    }

    const signatureOptions = useMemo(() => {
        const signatureOptionsData = []
        signatureOptionsData.push({ label: 'No signature', value: null })
        for (const signature of signatures) {
            signatureOptionsData.push({ label: signature.Name.toUpperCase(), value: signature.ID })
        }
        return signatureOptionsData
    }, [signatures])
    
    if (loading) {
        return <Select loading />
    }
    return (
        <Wrapper>
            <Select
                autoComplete='off'
                value={value}
                label="Signature"
                style={{ width: '100%', minWidth: "200px" }}
                placeholder="Select Signature"
                onChange={handleChange}
                allowClear={false}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                options={signatureOptions}
            />
            {selectedSignatureID &&
            <NwButton icon={light('signature')} style={{ marginLeft: "10px" }} onClick={() => setShowPreviewDrawer(true)} label="preview" />
            }
            {showPreviewDrawer && selectedSignatureID && 
                <NwDrawer
                    title={getSelectedSignature().Name.toUpperCase()}
                    onClose={() => setShowPreviewDrawer(false)}
                    width="40rem"
                >
                    <div dangerouslySetInnerHTML={{ __html: getSelectedSignature().Signature }} />
                </NwDrawer>
            }
        </Wrapper>
    );
};

export default SignatureSelector;