import React, { useContext, useEffect, useMemo, useState } from 'react';
import TransactionDetailDrawer from 'Components/Transactions/TransactionsDrawer/TransactionDetailDrawer';
import { Loading } from 'Components/Gui/Loading';
import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { forceReloadProjectStatements, useProjectStatements } from 'Hooks/Project/UseProjectStatements';
import { NwTag } from 'Components/Gui/NwTag';
import { getMoment } from "Libs/NwMoment";
import { ProjectTransactionListContainer } from './ProjectTransactionsUI';
import ProjectGroupedStatementsBillingInfo from './ProjectGroupedStatementsBillingInfo.js';
import { ListContext } from 'Contexts/ListContext';
import TransactionCreateDrawer from 'Components/Transactions/TransactionsDrawer/TransactionCreateDrawer';
import TransactionsStatusLegend from 'Components/Transactions/TransactionsStatusLegend';

const ProjectGroupedStatements = ({ projectId, statementType, readOnly, isExpanded }) => {
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);
    const [transaction, setTransaction] = useState();
    const [transactionFamily, setTransactionFamily] = useState();
    const [showTransactionDrawer, setShowTransactionDrawer] = useState(false);
    const { data: project } = useProject(projectId);
    const { data: statements, /*isFetching: loading*/ } = useProjectStatements(projectId, statementType);
    const [selectedFamilies, setSelectedFamilies] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const { GetFamilyName } = useContext(ListContext);

    useEffect(() => {
        if (isExpanded) {
            setExpandedItems([])
        }
    }, [isExpanded])

    const usedFamilies = useMemo(() => {
        let families = []
        if (statements && statements.length) {
            //find families used in statements
            for (const contactstatement of statements) {
                for (const billinginfo of contactstatement.BillingInfos) {
                    for (const transaction of billinginfo.Transactions) {
                        if (transaction.Family && !families.includes(transaction.Family)) {
                            families.push(transaction.Family)
                        }
                    }
                }
            }
        }
        return families
    }, [statements]);

    const filteredStatements = useMemo(() => {
        let filteredStatements = [];
        if (statements && statements.length) {
            if (selectedFamilies.length) {
                for (const contactstatement of statements) {
                    const filteredbillinginfo = contactstatement.BillingInfos.map(bi => {
                        if (bi.Transactions && bi.Transactions.length) {
                            const filteredtransactions = bi.Transactions.filter(transaction => selectedFamilies.includes(transaction.Family));
                            return {
                                ...bi,
                                Transactions: filteredtransactions
                            }
                        } else {
                            return bi;
                        }
                    }).filter(bi => bi.Transactions && bi.Transactions.length);
                    filteredStatements.push({
                        ...contactstatement,
                        BillingInfos: filteredbillinginfo
                    })
                }
                //remove contactstatement without a billinginfo with statements
                filteredStatements = filteredStatements.filter(contactstatement => contactstatement.BillingInfos && contactstatement.BillingInfos.length);
            } else {
                filteredStatements = statements;
            }
        }
        return filteredStatements;
    }, [statements, selectedFamilies]);

    const handleEditTransaction = transaction => {
        setTransaction(transaction);
        setShowTransactionDetail(true);
    }

    const handleCloseTransactionDetail = () => {
        setTransaction();
        setShowTransactionDetail(false);
    };

    const handleCloseTransactionDrawer = () => {
        setTransactionFamily();
        setShowTransactionDrawer(false);
    };

    const handleSelectFamily = family => {
        if (selectedFamilies.includes(family)) {
            setSelectedFamilies(selectedFamilies.filter(selectedFamily => selectedFamily !== family))
        } else {
            setSelectedFamilies([...selectedFamilies, family])
        }
    }

    const handleSelectAllFamilies = () => {
        setSelectedFamilies([])
    }

    const isSelectedFamily = family => {
        return selectedFamilies.includes(family)
    }

    return (
        (filteredStatements)
            ?
            <ProjectTransactionListContainer>
                <div className="families-selector">
                    <NwTag pointer key="all-families" clickaction={handleSelectAllFamilies} type={selectedFamilies.length === 0 ? "primary" : null}>all transactions</NwTag>
                    {usedFamilies.map(family => (
                        <NwTag pointer key={family} clickaction={() => handleSelectFamily(family)} type={isSelectedFamily(family) ? "primary" : null}>{GetFamilyName(family)}</NwTag>
                    ))}
                </div>
                {filteredStatements.map((contact) => (
                    <div className="subject-container" key={`contact-${contact.ContactID}`}>
                        {(contact.BillingInfos.length > 1) && <h2>{contact.Name}</h2>}
                        {contact.BillingInfos.map((bi) => (
                            <ProjectGroupedStatementsBillingInfo
                                contactID={contact.ContactID}
                                contactName={contact.Name}
                                statementType={statementType}
                                isSingleBillingInfo={contact.BillingInfos.length === 1}
                                key={bi.ID}
                                billingInfo={bi}
                                project={project}
                                onEditTransaction={handleEditTransaction}
                                //readOnly={readOnly}
                                //isExpanded={isExpanded}
                                //expandedItems={expandedItems}
                                //onItemExpansion={setExpandedItems}
                            />
                        ))}
                    </div>
                ))}
                <div className="transactions-status-legend-container">
                    <TransactionsStatusLegend />
                </div>
                {showTransactionDrawer &&
                    <TransactionCreateDrawer
                        projectId={project.ID}
                        modelSelectionEnabled
                        transactionFamily={transactionFamily}
                        onClose={handleCloseTransactionDrawer}
                        onTransactionCreatedOrUpdated={() => {
                            forceReloadProjectStatements(project.ID)
                            forceReloadProject(project.ID)
                        }}
                    />
                }
                {showTransactionDetail &&
                    <TransactionDetailDrawer
                        hideProject
                        transaction={transaction}
                        projectId={project.ID}
                        // callsheets={project.Callsheets}
                        // clientID={project.CustomerID}
                        onClose={handleCloseTransactionDetail}
                        onUpdateTransaction={() => {
                            forceReloadProjectStatements(project.ID)
                            forceReloadProject(project.ID)
                        }}
                        onTransactionRemoved={() => {
                            setTransaction()
                            setShowTransactionDetail(false)
                            forceReloadProjectStatements(project.ID)
                            forceReloadProject(project.ID)
                        }}
                    />
                }
            </ProjectTransactionListContainer>
            :
            <Loading textBlack />
    );
};

export default ProjectGroupedStatements;