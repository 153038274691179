import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';

const getEventByID = ID => (
    Axios.get(`/events/${ID}`)
)

const useEvent = (ID) => {
    return useQuery(["event", ID], () => getEventByID(ID), {
        staleTime: 30000,
        cacheTime: 30000,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: ID ? true : false
    });
}

const forceReloadEvent = (ID) => {
    queryClient.invalidateQueries(['event', ID])
}

const onRemoveEvent = ({ ID }) => (
    Axios.delete(`/events/${ID}`)
)

const useRemoveEvent = () => {
    return useMutation(onRemoveEvent, {
        onSuccess: (data, variables) => {
        }
    })
}

const onUpdateEvent = ({ ID, data }) => (
    Axios.put(`/events/${ID}`, data)
)

const useUpdateEvent = () => {
    return useMutation(onUpdateEvent, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.ID);
        }
    })
}

const onSetRequest = ({ EventID, ModelID, OnRequest }) => (
    Axios.put('/eventdatesmodels/SetOnRequest', { EventID, ModelID, OnRequest })
);

const useSetRequest = () => {
    return useMutation(onSetRequest, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.EventID);
        }
    })
};

const onGetOptionNumber = data => (
    Axios.post(`/eventdatesmodels/SuggestedOptionNumber`, data)
);

const onUpdateOptionNumber = ({ data }) => (
    Axios.put(`/eventdatesmodels/UpdateOptionNumber`, data)
);

const onMoveEvent = (data) => (
    Axios.put(`/eventdates/Move`, data)
);

const useUpdateOptionNumber = () => {
    return useMutation(onUpdateOptionNumber, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.ID);
            //TODO: add reload project and project transactions
        }
    })
};

const onDeleteEventDatesModels = ({ eventDateModelId }) => (
    Axios.delete(`/eventdatesmodels/${eventDateModelId}`)
);

const useDeleteEventDatesModels = () => {
    return useMutation(onDeleteEventDatesModels, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.ID)
            if (variables.projectID) {
                forceReloadProject(variables.projectID)
                forceReloadProjectTransactions(variables.projectID)
            }
        }
    })
};

const onDeleteEventDate = ({ eventDateId }) => (
    Axios.delete(`/eventDates/${eventDateId}`)
)

const useDeleteEventDate = () => {
    return useMutation(onDeleteEventDate, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.ID);
        }
    })
};

const onChangeEventDateTime = async ({ data }) => {
    await Axios.put(`/eventdates/ChangeTime`, data);
};

const useChangeEventDateTime = () => {
    return useMutation(onChangeEventDateTime, {
        onSuccess: (data, variables) => {
        }
    })
}

const onChangeEventDateModelTime = ({ data }) => (
    Axios.put(`/eventdatesmodels/ChangeTime`, data)
);

const useChangeEventDateModeltime = () => {
    return useMutation(onChangeEventDateModelTime, {
        onSuccess: (data, variables) => {
        }
    })
};

const onChangeEventDateNote = ({ data }) => (
    Axios.put(`/eventdates/EditNotes`, data)
);

const useChangeEventDateNote = () => {
    return useMutation(onChangeEventDateNote, {
        onSuccess: (data, variables) => {
        }
    })
};

const onChangeEventDateModelNote = ({ data }) => (
    Axios.put(`/eventdatesmodels/EditNotes`, data)
);

const useChangeEventDateModelNote = () => {
    return useMutation(onChangeEventDateModelNote, {
        onSuccess: (data, variables) => {
        }
    })
};

const onChangeEventDateModelAddress = ({ addressData }) => (
    Axios.post('/eventdatesmodels/ChangeAddress', addressData)
)

const useChangeEventDateModelAddress = () => {
    return useMutation(onChangeEventDateModelAddress, {
        onSuccess: (data, variables) => {
        }
    })
};

const onChangeEventDateAddress = ({ addressData }) => (
    Axios.post('/eventDates/ChangeAddress', addressData)
)

const onChangeEventDateModels = (data) => (
    Axios.put('/eventdatesmodels', data)
)

const onChangeEventDate = (data) => (
    Axios.put('/eventdates/', data)
)

const useChangeEventDateAddress = () => {
    return useMutation(onChangeEventDateAddress, {
        onSuccess: (data, variables) => {
        }
    })
};

const onCancelEventDateModel = ({ EventDateModelsIDs, Cancel }) => (
    Axios.put(`/eventdatesmodels/Cancel`, {
        EventDateModelsIDs,
        Cancel
    })
);

const useCancelEventDateModel = () => {
    return useMutation(onCancelEventDateModel, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.eventId);
            //TODO: add reload project and project transactions
        }
    })
};

const onAcknowledgeEventDateModel = ({ eventDateModelId, acknowledge }) => (
    Axios.put(`/eventdatesmodels/Acknowledge/${eventDateModelId}/${acknowledge}`)
);

const useAcknowledgeEventDateModel = () => {
    return useMutation(onAcknowledgeEventDateModel, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.eventId);
        }
    })
};

const onAddModelToDate = ({ modelId, eventDateId, optionNumber }) => {
    const dataToSend = {
        ModelID: modelId,
        EventDateID: eventDateId
    }
    if (optionNumber && optionNumber > 0) {
        dataToSend.OptionNumber = optionNumber;
    }
    
    return (Axios.post("/eventdatesmodels/AddModelToDate", dataToSend))
};

const useAddModelToDate = () => {
    return useMutation(onAddModelToDate, {
        onSuccess: (data, variables) => {
            if (variables.eventId) {
                forceReloadEvent(variables.eventId);
            }
        }
    })
}

const onAddModelToDates = ({ modelId, eventDatesIds, optionNumber }) => {
    const eventDatesToSend = eventDatesIds.map(eventDateId => ({
        ID: eventDateId, 
        OptionNumber: (optionNumber && optionNumber > 0) ? optionNumber : null
    }))
    const dataToSend = {
        ModelID: modelId,
        EventDates: [...eventDatesToSend]
    }
    return (Axios.post("/eventdatesmodels/AddModelToDates", dataToSend))
    // if (optionNumber && optionNumber > 0) {
    //     dataToSend.OptionNumber = optionNumber;
    // }
    // return (Axios.post("/eventdatesmodels/AddModelToDates", {
    //     ModelID: modelId,
    //     EventDateIDs: [...eventDateIds]
    // }))
};

const useAddModelToDates = () => {
    return useMutation(onAddModelToDates, {
        onSuccess: (data, variables) => {
            if (variables.eventId) {
                forceReloadEvent(variables.eventId);
            }
        }
    })
}

/* event address */
const getEventAddressByID = ID => (
    Axios.get(`/eventaddresses/${ID}`)
)

const useEventAddress = (ID) => {
    return useQuery(["eventAddress", ID], () => getEventAddressByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const onAddEventAddress = ({ data }) => (
    Axios.post(`eventaddresses`, data)
);

const useAddEventAddress = () => {
    return useMutation(onAddEventAddress, {
        onSuccess: (data, variables) => {
        }
    })
};

const forceReloadEventAddress = (ID) => {
    queryClient.invalidateQueries(['eventAddress', ID])
}

const onAddEventDate = ({ data }) => (
    Axios.post('/eventDates/AddEventDate', data)
);

const useAddEventDate = () => {
    return useMutation(onAddEventDate, {
        onSuccess: (data, variables) => {
        }
    })
}

const onCancelEvent = ({ ID, cancel }) => (
    Axios.put(`/events/Cancel/${ID}/${cancel}`)
)

const useCancelEvent = () => {
    return useMutation(onCancelEvent, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.ID);
        }
    })
};

const onUpdateEventCustomFields = ({ eventId, data }) => (
    Axios.put(`/events/Customfields/${eventId}`, data)
);

const useUpdateEventCustomFields = () => {
    return useMutation(onUpdateEventCustomFields, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.eventId);
        }
    })
}

const onMoveBookingEvent = ({ eventData }) => (
    Axios.put(`/eventdatesmodels/moveDate`, eventData)
)

const useMoveBookingEvent = () => {
    return useMutation(onMoveBookingEvent, {
        onSuccess: (data, variables) => {
            forceReloadEvent(variables.eventData.ID);
        }
    })
};


//const Filters = [ 'casting', 'job', 'travel', 'followup', 'roomLodging', 'extjob', 'meeting', 'accommodation' ];

// const fetchEvents = async (configs) => {
//     // console.log("configs", configs)
//     const { modelID, clientID, serviceID, hasTransactions, WorkingOn } = configs;
//     let startDate = null;
//     let endDate = null;
//     if (WorkingOn) {
//         startDate = WorkingOn[0];
//         endDate = WorkingOn[1];
//     }
//     const eventsParams = [];
//     for (const filterKey of Filters) {
//         if (configs[filterKey]) {
//             let eventSubtypeCode = null;
//             if (filterKey === 'casting' || filterKey === 'job' || filterKey === 'travel' || filterKey === 'followup') {
//                 eventSubtypeCode = configs[filterKey].eventSubtypeCode;
//             }
//             if (!((modelID && filterKey === 'meeting') || 
//                 (clientID && filterKey === 'roomLodging') || 
//                 (serviceID && (filterKey === 'casting' || filterKey === 'job' || filterKey === 'extjob')) || 
//                 (hasTransactions && filterKey === 'meeting'))) {
//                 eventsParams.push(getUrlAndParams(filterKey, modelID, clientID, serviceID, hasTransactions, eventSubtypeCode));
//             }
//         }
//     }
//     // if (showHint) {
//     //     setShowHint(false);
//     // }
//     const events = await onFetchEvents({ eventsParams, startDate, endDate });
//     return events;
// };

// const getUrlAndParams = (type, modelId, clientId, serviceId, hasTransactions, eventSubtypeCode) => {
//     switch (type) {
//         case 'casting':
//             let castingParams = { modelID: modelId, globalClientID: clientId, eventType: "Casting" };
            
//             // if (subFilters.casting) {
//             //     castingParams = {
//             //         ...castingParams,
//             //         ...subFilters.casting
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 castingParams = {
//                     ...castingParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
//             if (eventSubtypeCode) {
//                 castingParams = {
//                     ...castingParams,
//                     eventSubtypeCode: eventSubtypeCode
//                 }
//             }

//             return { path: '/events/Search', params: castingParams, type: 'casting' };
//         case 'job':
//             let jobParams = { modelID: modelId, globalClientID: clientId, eventType: "Job" };

//             // if (subFilters.job) {
//             //     jobParams = {
//             //         ...jobParams,
//             //         ...subFilters.job,
//             //         hasTransactions: hasTransactions
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 jobParams = {
//                     ...jobParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
//             if (eventSubtypeCode) {
//                 jobParams = {
//                     ...jobParams,
//                     eventSubtypeCode: eventSubtypeCode
//                 }
//             }

//             return { path: '/events/Search', params: jobParams, type: 'job' };
//         case 'travel':
//             let travelParams = { modelID: modelId, projectClientID: clientId, serviceID: serviceId };

//             // if (subFilters.travel) {
//             //     travelParams = {
//             //         ...travelParams,
//             //         ...subFilters.travel,
//             //         hasTransactions: hasTransactions
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 travelParams = {
//                     ...travelParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
//             if (eventSubtypeCode) {
//                 travelParams = {
//                     ...travelParams,
//                     transport: eventSubtypeCode
//                 }
//             }

//             return { path: '/travels/Search', params: travelParams, type: 'travel' };
//         case 'followup':
//             let followupParams = { modelID: modelId, projectClientID: clientId, serviceID: serviceId };

//             // if (subFilters.followup) {
//             //     followupParams = {
//             //         ...followupParams,
//             //         ...subFilters.followup
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 followupParams = {
//                     ...followupParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
        
//             if (eventSubtypeCode) {
//                 followupParams = {
//                     ...followupParams,
//                     eventSubtypeCode: eventSubtypeCode
//                 }
//             }
            
//             return { path: '/FollowUps/Search', params: followupParams, type: 'followup' };
//         case 'accommodation':
//             let accommodationParams = { modelID: modelId, projectClientID: clientId, serviceID: serviceId, SubType: 0 };
            
//             // if (subFilters.accommodation) {
//             //     accommodationParams = {
//             //         ...accommodationParams,
//             //         ...subFilters.accommodation
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 accommodationParams = {
//                     ...accommodationParams,
//                     hasTransactions: hasTransactions
//                 }
//             }

//             return { path: '/accommodation/Search', params: accommodationParams, type: 'accommodation' };
//         case 'roomLodging':
//             let roomLodgingParams = { ModelID: modelId, projectClientID: clientId, serviceID: serviceId, SubType: 1 };

//             // if (subFilters.roomLodging) {
//             //     roomLodgingParams = {
//             //         ...roomLodgingParams,
//             //         ...subFilters.roomLodging,
//             //         hasTransactions: hasTransactions
//             //     }
//             // }

//             if (hasTransactions !== undefined) {
//                 roomLodgingParams = {
//                     ...roomLodgingParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
            
//             return { path: '/accommodation/Search', params: roomLodgingParams, type: 'accommodation' };
//         case 'extjob':
//             let externaljobParams = { modelID: modelId, agencyID: clientId };

//             if (hasTransactions !== undefined) {
//                 externaljobParams = {
//                     ...externaljobParams,
//                     hasTransactions: hasTransactions
//                 }
//             }
//             return { path: '/ExternalJobs/Search', params: externaljobParams, type: 'extjob' };
//         case 'meeting':
//             const meetingParams = { contactID: clientId };
//             return { path: '/meetings/Search', params: meetingParams, type: 'meeting' };
//         default: 
//             return null;
//     }
// };

// const onFetchEvents = async filters => {
//     const { eventsParams, startDate, endDate } = filters;
//     try {
//         let newEvents = [];
//         for(const eventParam of eventsParams) {
//             const { path, params, type } = eventParam;
            
//             let data = { ...params };
//             data = {
//                 ...data,
//                 from: startDate, //period && period[0],
//                 to: endDate //period && period[1],
//             };

//             const response = await Axios.post(path, data);
//             const eventsByType = response.filter(event => {
//                 return true;
//             }).map(event => {
//                 return {
//                     ...event,
//                     type
//                 };
//             });
//             newEvents = [...newEvents, ...eventsByType];
//         }
//         return newEvents;
//     } catch (error) {
//         console.log('ant : Search Events Error => ', error);
//     }
// }

// const useEventsWallIds = (configs) => {
//     return useQuery(['event-wall-ids'], () => fetchEvents(configs), {
//         // enabled: configs ? true : false,
//         enabled: true,
//         refetchOnWindowFocus: false,
//         staleTime: 300000,
//         cacheTime: Infinity,
//         retry: false
//     });
// };
// await fetchEvents(configToSend.modelID, configToSend.clientID, configToSend.serviceID, false, configToSend.startDate, configToSend.endDate)

// const refetchEventWallIds = async (configs) => {
//     try {
//         await queryClient.fetchQuery(['event-wall-ids'], () => fetchEvents(configs));
//     } catch (error) {
//         console.log(error)
//     }
// };

export {
    useEvent,
    forceReloadEvent,
    useSetRequest,
    useRemoveEvent,
    useUpdateEvent,
    onGetOptionNumber,
    useUpdateOptionNumber,
    useDeleteEventDatesModels,
    useDeleteEventDate,
    useChangeEventDateTime,
    useChangeEventDateModeltime,
    useChangeEventDateNote,
    useChangeEventDateModelNote,
    useAddEventAddress,
    useEventAddress,
    forceReloadEventAddress,
    useChangeEventDateModelAddress,
    useChangeEventDateAddress,
    useCancelEventDateModel,
    useAcknowledgeEventDateModel,
    useAddEventDate,
    useCancelEvent,
    useAddModelToDate,
    useAddModelToDates,
    useUpdateEventCustomFields,
    onMoveEvent,
    onChangeEventDateModels,
    useMoveBookingEvent,
    onChangeEventDate,
    //useEventsWallIds,
    //refetchEventWallIds
};


