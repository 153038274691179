import React from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButtonGroup } from "Components/Gui/NwButton";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import AcknowledgementEvent from 'Components/EventButtons/AcknowledgementEvent';
import CancelEvent from 'Components/EventButtons/CancelEvent';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { EventTitleText } from './EventUI';
import FlexContainer from 'Components/Gui/FlexContainer';
import ToDoCheck from 'Components/Gui/ToDoCheck';

const NameLine = styled.div`
    
    margin-bottom: 8px;
    
    h1, h2, p {
        margin-bottom: 0;
        font-weight: 700;
    }
`

const TodoCheckWrapper = styled.div`
    font-size: 1.5em;
`;

const ButtonGroupWrapper = styled.div`
    margin-right: 1rem;
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-right: 1px solid #d9d9d9 !important;
    }
    .ant-btn:nth-child(2), span:nth-child(2) > .ant-btn, a:nth-child(2) > .ant-btn {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;

export const EventModelNameLineNonBooking = ({
    aboveTitle,
    event,
    actions,
    eventProperties
}) => {
    const buttonactions = [
        { name: "", key: "edit", pinned: true, icon: light("pen"), action: actions.onEdit },
        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: actions.onDelete },
    ]
    if (actions.onUnlink) {
        buttonactions.push({ name: "unlink", key: "unlink", icon: light("unlink"), action: actions.onUnlink })
    }
    if (event.Project && eventProperties.isSingle && actions.onProjectUnlink) {
        buttonactions.push({ name: 'unlink from project', key: 'unlink-project', icon: light("trash-alt"), action: actions.onProjectUnlink })
    }
    let nameLineStyle = {};
    if (!aboveTitle) {
        nameLineStyle.padding = ".5rem 1.5rem";
    }

    if (eventProperties.eventType === "ToDo") {
        return (
            <NameLine style={{ ...nameLineStyle }}>
                <FlexContainer justifyContent="space-between" >
                    <TodoCheckWrapper>
                        <ToDoCheck
                            eventId={event.ID}
                            completedValue={event.Status}
                            onStatusChanged={actions.onStatusUpdate}
                        />&nbsp;&nbsp;
                    </TodoCheckWrapper>
                    <EventTitleText className={event.Cancel ? 'is-cancelled' : null}>{getContactName(event.ContactID)}</EventTitleText>
                    <NwDropDownMenu style={{ marginTop: ".25em" }} actions={buttonactions} />
                </FlexContainer>
            </NameLine>
        )
    } else {
        return (
            <NameLine style={{ ...nameLineStyle }}>
                <FlexContainer justifyContent="space-between" >
                    <EventTitleText className={event.Cancel ? 'is-cancelled' : null}>{getContactName(event.ModelID)}</EventTitleText>
                    {eventProperties.eventType !== 'Accommodation' && (actions.onAcknowledgmentUpdate || actions.onCancelUpdate) &&
                        <ButtonGroupWrapper>
                            <NwButtonGroup>
                                <AcknowledgementEvent
                                    button
                                    onStatusChanged={actions.onAcknowledgmentUpdate}
                                    acknowledgmentValue={event.Acknowledgment}
                                    type={eventProperties.eventType}
                                    eventId={event.ID} />
                                <CancelEvent
                                    button
                                    onStatusChanged={actions.onCancelUpdate}
                                    cancelValue={event.Cancel}
                                    type={eventProperties.eventType}
                                    eventId={event.ID} />
                            </NwButtonGroup>
                        </ButtonGroupWrapper>
                    }
                    <NwDropDownMenu style={{ marginTop: ".25em" }} actions={buttonactions} />
                </FlexContainer>
            </NameLine>
        )
    }
}