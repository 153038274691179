import React, { useContext } from "react";

import { ListContext } from "Contexts/ListContext";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";

const AgencyTypeSelector = props => {
  const { cachedList } = useContext(ListContext);

  const modelAgenciesTypes = cachedList.enumerators.items.find(el => {
    return el.Name === "ModelAgencyTypeEnum";
  }).Values;
  //const modelAgenciesTypes = cachedList.modelAgenciesTypes.items
  
  console.log()

  return (
    <RadioButtonGroup 
        value={props.value} 
        onChange={props.onChange} 
        options={modelAgenciesTypes.map(el => {
            const disabled = (el.Name === "Mother" && props.hideMother);
            return { disabled: disabled,  value: el.Name, label: el.Name }
        })} />
  );
};

export { AgencyTypeSelector };


