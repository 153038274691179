import React, { useState } from 'react';
import styled from 'styled-components';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { EventContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import PackageCard from 'Components/Project/EventCards/PackageCard';
import BookingEvent from "Components/EventDrawer/BookingEvent/BookingEvent";
import TravelWrapper from 'Components/EventDrawer/TravelsEvent/TravelWrapper';
import FollowUpWrapper from 'Components/EventDrawer/FollowUpEvent/FollowUpWrapper';
import MeetingsEvent from 'Components/EventDrawer/MeetingEvent/MeetingEvent';
import AccommodationWrapper from 'Components/EventDrawer/AccommodationEvent/AccommodationWrapper';
import { Loading } from 'Components/Gui/Loading';
import StyleVariables from 'Components/Gui/StyleVariables';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import useEventStyle from 'Hooks/UseEventStyle';

const Container = styled.div`
    margin-bottom: 16px;

    .ant-card-head-title {
        padding: 8px 0 !important;
        line-height: 1 !important;
    }

    .ant-card {
        border-radius: 4px;
    }
    
    .ant-card-head .ant-card-head-title .nw-card-head-title .nw-card-head-title-content {
        line-height: 1 !important;
    }

    .ant-card-head {
        padding-left: 0;
        padding-right: 0;
        &::before {
            background: ${props => props.event.backgroundColor ? props.event.backgroundColor : StyleVariables.DefaultBorder};
            width: 100%;
            height: 10px;
        }

        .ant-card-head-wrapper {
            padding: 0 16px 0 24px;
        }
    }
`;

const TitleWrapper = styled.div`
    svg {
        font-size: 1rem;
        line-height: 1;
    }
`;

const EventCard = ({ event, currentProject, modelId, projectId, onSetLayout }) => {
    const [showEventDrawer, setShowEventDrawer] = useState(false);
    const [eventUpdated, setEventUpdated] = useState(false);
    const eventstyle = useEventStyle(event);

    const getEvent = () => {
        switch (event.elType.toLowerCase()) {
            case "todo":
            case "travel":
            case "followup":
            case "accommodation":
                return event.GroupedEventIDs[0]
            default:
                return event.ID;
        }
    };

    const eventData = {
        ...event,
        Project: currentProject
    };


    const afterUpdateDrawer = () => {
        forceReloadProject(currentProject.ID);
    };

    const afterRemovingEvent = deletedEvent => {
        if (deletedEvent) {
            onSetLayout();
        }
        forceReloadProject(currentProject.ID);
    };

    return (
        <Container event={eventstyle}>
            {event ?
                <NwCard
                    title={
                        <TitleWrapper>
                            <EventTypeTag event={event} titleOnly big noBack />
                        </TitleWrapper>
                    }
                >
                    {(event.elType.toLowerCase() === "event") &&
                        <EventContainer>
                            <BookingEvent
                                filterModel={modelId}
                                plainMode
                                ID={eventData.ID}
                                projectId={projectId}
                                dateSelected={event.FirstDate}
                                afterUpdate={afterUpdateDrawer}
                                afterRemove={afterRemovingEvent} />
                        </EventContainer>
                    }
                    {(event.elType.toLowerCase() === "package") &&
                        <PackageCard packageId={event.ID} />
                    }
                    {(event.elType.toLowerCase() === "travel") &&
                        <EventContainer>
                            <TravelWrapper
                                ID={event.GroupedEventIDs[0]}
                                eventUpdated={eventUpdated}
                                plainMode
                                projectId={projectId}
                                filterModel={modelId}
                                afterUpdate={afterUpdateDrawer}
                                afterRemove={afterRemovingEvent} />
                        </EventContainer>
                    }
                    {(event.elType.toLowerCase() === "accommodation") &&
                        <EventContainer>
                            <AccommodationWrapper
                                ID={event.GroupedEventIDs[0]}
                                eventUpdated={eventUpdated}
                                plainMode
                                projectId={projectId}
                                filterModel={modelId}
                                afterUpdate={afterUpdateDrawer}
                                afterRemove={afterRemovingEvent}
                            />
                        </EventContainer>
                    }
                    {(event.elType.toLowerCase() === "followup") &&
                        <EventContainer>
                            <FollowUpWrapper
                                ID={event.GroupedEventIDs[0]}
                                eventUpdated={eventUpdated}
                                plainMode
                                disableProject
                                projectId={projectId}
                                filterModel={modelId}
                                afterUpdate={afterUpdateDrawer}
                                afterRemove={afterRemovingEvent} />
                        </EventContainer>
                    }
                    {(event.elType.toLowerCase() === "meeting") &&
                        <EventContainer>
                            <MeetingsEvent
                                ID={event.ID}
                                eventUpdated={eventUpdated}
                                projectId={projectId}
                                plainMode
                                disableProject
                                afterUpdate={afterUpdateDrawer}
                                afterRemove={afterRemovingEvent} />
                        </EventContainer>
                    }

                </NwCard>
                : <Loading />
            }
            {showEventDrawer &&
                <EventDrawer
                    drawerOrigin="EventCard"
                    eventType={event.elType}
                    eventID={getEvent()}
                    show={true}
                    projectId={currentProject.ID}
                    onClose={() => setShowEventDrawer(false)}
                    afterUpdate={() => {
                        setEventUpdated(prevUpdated => !prevUpdated);
                        afterUpdateDrawer();
                    }}
                    afterRemove={deletedEvent => {
                        setEventUpdated(prevUpdated => !prevUpdated);
                        afterRemovingEvent(deletedEvent);
                    }}
                />
            }
        </Container>
    )
};

export default EventCard;