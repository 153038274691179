import React from 'react';
import { Select } from 'antd';
import { getMoment } from "Libs/NwMoment";

import EventDateTimePicker from "Components/EventDateTimePicker/EventDateTimePicker";
import FlexContainer from 'Components/Gui/FlexContainer';
import { getNow } from 'Libs/NwMoment';

const PeriodTypes = [
    {
        label: 'Today',
        value: 'today'
    },{
        label: 'This week',
        value: 'week'
    },{
        label: 'Last 7 days',
        value: 'last7days'
    },{
        label: 'This month',
        value: 'month'
    },{
        label: 'Last 30 days',
        value: 'last30days'
    },{
        label: 'This year',
        value: 'year'
    },{
        label: 'Last 365 days',
        value: 'last365days'
    },{
        label: 'Period',
        value: 'period'
    },
];

const DateRangePicker = ({ value, onChange, ...rest }) => {
    const handleChangePeriodType = periodType => {
        
        let startDate = null;
        let endDate = null;
        if (periodType === 'today') {
            startDate = getNow();
            endDate = getNow();
        } else if (periodType === 'week') {
            startDate = getNow().startOf('isoWeek');
            endDate = getNow().endOf('isoWeek');
        } else if (periodType === 'last7days') {
            startDate = getNow().subtract(7, 'day');
            endDate = getNow();
        } else if (periodType === 'month') {
            startDate = getNow().startOf('month');
            endDate = getNow().endOf('month');
        } else if (periodType === 'last30days') {
            startDate = getNow().subtract(30, 'day');
            endDate = getNow();
        } else if (periodType === 'year') {
            startDate = getNow().startOf('year');
            endDate = getNow().endOf('year');
        } else if (periodType === 'last365days') {
            startDate = getNow().subtract(365, 'day');
            endDate = getNow();
        } else if (periodType === 'period') {
            startDate = getNow();
            endDate = getNow();
        }
        onChange({
            type: periodType,
            startDate,
            endDate
        });
    };

    const handleChangePeriod = ({ startdate, enddate }) => {
        onChange({
            type: value.type,
            startDate: startdate,
            endDate: enddate
        });
    };

    return (
        <>
            <Select
                autoComplete='off'
                value={value.type}
                options={PeriodTypes}
                onChange={handleChangePeriodType}
                {...rest} />
            {value.type === 'period' &&
                <FlexContainer mg='1rem 0 0'>
                    <EventDateTimePicker
                        defaultStartDate={(value.startDate || getNow())}
                        defaultEndDate={(value.endDate || getNow())}
                        eventMode='period-notime'
                        onChange={handleChangePeriod}
                    />
                </FlexContainer>
            }
        </>
    );
};

export default DateRangePicker;