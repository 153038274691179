import React from "react";
import styled from "styled-components";

import SocialIcon from "Components/Social/SocialIcon";
import { completeURL } from "Components/Social/SocialUtil";
import { socialTypes } from "Constants/Socials";
import { friendlyNumber } from "Libs/NwUtils";

const ContactSocialLinkContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 2rem;

    .url-and-followers {
        a {
            display: block;
            word-break: break-all;
        }
        small {
            display: block;
            text-transform: uppercase;
        }
    }
`;

const fixUrl = url => {
    //if url is not starting with http or https, add http
    if (!url.match(/^(http|https):\/\//)) {
        return `https://${url}`;
    }
    return url;
}

const ContactSocialLink = ({ contactSocial, showUrl }) => {
    let socialType = socialTypes.find(socialtype => socialtype.code === contactSocial.Type);
    if (!socialType) {
        socialType = socialTypes.find(socialtype => socialtype.code === "Other");
    }
    const socialUrl = completeURL(socialType, contactSocial.Url);
    const fixedSocialUrl = fixUrl(socialUrl);

    

    return (
        <ContactSocialLinkContainer>
            <SocialIcon
                size="big"
                onClick={() => { window.open(fixedSocialUrl, '_blank') }}
                social={socialType}
                socialUrl={!showUrl ? socialUrl : null}
                socialFollowers={contactSocial.Followers}
            />
            {showUrl &&
                <div className="url-and-followers">
                    <a target="_blank" className="nw-link" href={fixedSocialUrl} rel="noopener noreferrer">
                        {socialUrl}
                    </a>
                    {contactSocial.Followers && contactSocial.Followers > 0
                    ?
                        <small>Followers: {friendlyNumber(contactSocial.Followers)} [{contactSocial.Followers}]</small>
                    :
                        <></>
                    }
                </div>
            }
        </ContactSocialLinkContainer>
    )

};

export default ContactSocialLink;
