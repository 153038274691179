import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Image } from 'antd';
import { useWindowSize } from 'react-use';
import NwIcon from 'Components/Gui/NwIcon';
import { usePlaylist } from 'Hooks/Playlist/UsePlaylist';
import { getPages, renderSlide, StyledBackButton, StyledNextButton, StyledFirstButton } from 'Components/MediaDrawer/PlayListUtils';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import VideoPlayer from 'Components/Gui/VideoPlayer';


const PicturesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
    position: relative;
`;

const Root = styled.div`
    position: relative;
    .carousel-flex {
        display: flex;
    }
`;

const PageContainer = styled.div`
    background: white;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 8px 24px;
    font-weight: bold;
`;

const PlayListView = ({ playlistID }) => {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    const { data: playlist } = usePlaylist(playlistID)
    const { height, width } = useWindowSize();
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideWidth = (width * 0.9) - 160 
    const slideHeight = height - 100
    const breakpoints = useNwBreakPoints();
    const [pages, setPages] = useState([]); 

    useEffect(() => {
        if (playlist) {
            setPages(getPages(playlist.DocumentPlaylists, breakpoints, playlist.Layout)); //TODO: utilizzare il playlist.Layout invece della stringa fissa
        }
    }, [playlist]);

    const handleBack = () => {
        if (currentSlide > 0) {
            setCurrentSlide(prevCurrentSlide => prevCurrentSlide - 1);
        }
    };

    const handleNext = () => {
        if (currentSlide < pages.length - 1) {
            setCurrentSlide(prevCurrentSlide => prevCurrentSlide + 1);
        }
    }

    const handleFirst = () => {
        setCurrentSlide(0);
    }

    return pages.length > 0 &&
        (breakpoints > 2
            ?
            <Root>
                <CarouselProvider
                    naturalSlideWidth={slideWidth}
                    naturalSlideHeight={slideHeight}
                    totalSlides={pages.length}
                    visibleSlides={1}
                    step={1}
                >
                    <Container>
                        <Slider style={{ margin: "0 4rem" }}>
                            {pages.map((page, index) => (
                                <Slide key={index} index={index}>
                                    <PicturesWrapper>
                                        {renderSlide(page, slideWidth, slideHeight, playlist.Layout)}
                                    </PicturesWrapper>
                                </Slide>
                            ))}
                        </Slider>
                        {(pages.length > 1) &&
                            <>
                                <StyledBackButton $negative onClick={handleBack} height={height}><NwIcon icon={light("chevron-left")} /></StyledBackButton>
                                {(currentSlide === pages.length - 1)
                                ?
                                <StyledFirstButton $negative onClick={handleFirst} height={height}><NwIcon icon={light("redo")} /></StyledFirstButton>
                                :
                                <StyledNextButton $negative onClick={handleNext} height={height}><NwIcon icon={light ("chevron-right")} /></StyledNextButton>
                                }
                            </>
                        }
                    </Container>
                </CarouselProvider>
                <PageContainer>
                    {`${currentSlide + 1} / ${pages.length}`}
                </PageContainer>
            </Root>
            :
            <div ref={myRef}>
                {pages.map((doc, index) => {
                    if (doc.video || doc.left.TypeName === 'Video') {
                        return (
                            <div key={index} style={{paddingTop: index === 0 ? "1rem" :"0.5rem"}}>
                                <VideoPlayer document={doc.left} />
                            </div>
                        )
                    }
                    return (
                        <div key={index} style={{paddingTop: index === 0 ? "1rem" :"0.5rem"}}>
                            <Image src={doc.left.Standard.Url} preview={false}/>
                        </div>
                    )
                })}
                <PageContainer onClick={executeScroll}>
                    <NwIcon icon={light("arrow-up")}/>
                </PageContainer>
            </div>
        )
}

export default PlayListView