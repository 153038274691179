import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';
//import { getSimpleContactByID, useContact } from 'Hooks/Contact/UseContact';
import { forceReloadContact, useContact } from 'Hooks/Contact/UseContact';

const getContactConnectionsByID = ID => (
    Axios.get(`/contacts/connections/${ID}`)
)

// const getDefaultContactConnectionByID = ID => (
//     Axios.get(`/contacts/defaultconnection/${ID}`)
// )

const fetchRoomConnections = () => Axios.get('/connections/GetRooms');

const updateContactConnections = async (ID) => {
    forceReloadContactConnections(ID)
    forceReloadContact(ID)
    //await updateContactDefaultConnection(ID)
};

const forceReloadContactConnections = ID => {
    queryClient.invalidateQueries(['contactconnections', ID]);
};

// const updateContactDefaultConnection = async (ID) => {
//     try {
//         //const { DefaultConnection, DefaultConnections } = await getSimpleContactByID(ID);
//         const DefaultConnection = await getDefaultContactConnectionByID(ID);
//         forceReloadContact(ID)
//         // const connections = await getContactConnectionsByID(ID);
//         // queryClient.setQueryData(['contact', ID], (old) => ({
//         //     ...old,
//         //     DefaultConnection,
//         //     //DefaultConnections,
//         //     Connections: connections
//         // }));
//     } catch (error) {
//         console.log('ant : UpdateConnections Error => ', error);
//     }
// }

const useContactConnections = (ID) => (
    useQuery(["contactconnections", ID], () => getContactConnectionsByID(ID), {
        staleTime: 60000,
        cacheTime: 60000,
        enabled: ID ? true : false
    })
)

//DEFAULT CONNECTION

const editContactConnectionItemDefault = ({ itemType, idItem, contactID }) => (
    Axios.get(`/connections/setdefault${itemType}/${idItem}/${contactID}`)
)

const useEditContactConnectionItemDefault = () => {
    return useMutation(editContactConnectionItemDefault, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
}

const useIsContactConnectionItemDefault = (itemType, idItem, contactID) => {
    const { data: currentContact } = useContact(contactID);

    if (currentContact && currentContact.DefaultConnection) {
        const { EmailID, AddressID, FaxID, MobileID, PhoneID } = currentContact.DefaultConnection
        if (currentContact.DefaultConnection) {
            switch (itemType) {
                case "email":
                    return EmailID === idItem
                case "address":
                    return AddressID === idItem
                case "phone":
                    return (FaxID === idItem || MobileID === idItem || PhoneID === idItem)
                default:
                    return false
            }
        }
    }
    return false;
}

const isContactConnectionItemDefault = (itemType, idItem, contactID) => {
    const mycontact = queryClient.getQueryData(['contact', contactID])
    const { EmailID, AddressID, FaxID, MobileID, PhoneID } = mycontact.DefaultConnection
    if (mycontact.DefaultConnection) {
        switch (itemType) {
            case "email":
                return EmailID === idItem
            case "address":
                return AddressID === idItem
            case "phone":
                return (FaxID === idItem || MobileID === idItem || PhoneID === idItem)
            default:
                return false
        }
    }
    return false
}

// SORT CONNECTIONS
const sortContactConnections = ({ newitemsid, contactID }) => (
    Axios.put(`/connections/SetOrder/${contactID}`, newitemsid)
)

const useSortContactConnections = () => {
    return useMutation(sortContactConnections, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
}

// CONNECTIONS (ADD, EDIT, DELETE)
const editContactConnection = (data) => (
    Axios.put(`/connections`, data)
)

const useEditContactConnection = () => {
    return useMutation(editContactConnection, {
        onSuccess: (data, variables) => {
            updateContactConnections(data.ContactID);
        }
    })
}

//we're using useAddContactConnectionFull

// const addContactConnection = async ({ data, emails }) => {
//     const response = await Axios.post(`/connections`, data)
//     for (const email of emails) {
//         const emailData = {
//             ConnectionID: response.ID,
//             Address: email,
//         };
//         await addContactConnectionItem("emails", emailData, response.ContactID)
//     }
//     return response
// }

// const useAddContactConnection = () => {
//     return useMutation(addContactConnection, {
//         onSuccess: (data, variables) => {
//             updateContactConnections(data.ContactID);
//         }
//     })
// }

const deleteContactConnection = ({ connectionID, contactID }) => (
    Axios.delete(`/connections/${connectionID}`)
)

const useDeleteContactConnection = () => {
    return useMutation(deleteContactConnection, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
}



//GENERIC ITEM (PHONE, EMAIL, ADDRESS)
const editContactConnectionItem = ({ itemType, data, contactID }) => (
    Axios.put(`/connections/${itemType}`, data)
)

const useEditContactConnectionItem = () => {
    return useMutation(editContactConnectionItem, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
}

const addContactConnectionItem = ({ itemType, data, contactID }) => (
    Axios.post(`/connections/${itemType}`, data)
)

const useAddContactConnectionItem = () => {
    return useMutation(addContactConnectionItem, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
}

const deleteContactConnectionItem = ({ itemType, itemID, contactID }) => {
    let endpoint = ""
    switch (itemType) {
        // case "connection":
        //   endpoint = `connections/${itemID}`
        //   break;
        case "address":
            endpoint = `/connections/addresses/${itemID}`
            break;
        case "email":
            endpoint = `/connections/emails/${itemID}`
            break;
        case "phone":
            endpoint = `/connections/phones/${itemID}`
            break;
        default:
            break;
    }
    return Axios.delete(endpoint)
}

const useDeleteContactConnectionItem = () => {
    return useMutation(deleteContactConnectionItem, {
        onSuccess: (data, variables) => {
            updateContactConnections(variables.contactID);
        }
    })
};


const addContactConnectionFull = async ({data}) => {
    const response = await Axios.post(`/connections/AddFull`, data)
    return {
        ...response
    };
}

const useAddContactConnectionFull = () => {
    return useMutation(addContactConnectionFull, {
        onSuccess: (data, variables) => {
            updateContactConnections(data.ContactID);
        }
    })
}

const getRoomConnections = () => {
    return queryClient.getQueryData(['roomConnections']) ? queryClient.getQueryData(['roomConnections']) : []
}

const prefetchRoomConnections = () => {
    queryClient.prefetchQuery(
        ['roomConnections'],
        () => fetchRoomConnections(),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
};

const reloadRoomConnections = () => {
    queryClient.invalidateQueries(['roomConnections'])
};

const getRoomByID = ID => (
    Axios.get(`/rooms/${ID}`)
);

const useRoom = (ID) => {
    return useQuery(["room", ID], 
        () => getRoomByID(ID),
        {
            enabled: ID ? true : false,
            staleTime: 60000,
            cacheTime: 60000,
            retry: false
        })
}

const deleteRoom = ({ roomId }) => (
    Axios.delete(`/rooms/${roomId}`)
);

const useDeleteRoom = () => {
    return useMutation(deleteRoom, {
        onSuccess: (data, variables) => {
            if (variables.contactId) {
                forceReloadContactConnections(variables.contactId);
            }
        }
    })
};

const addRoom = ({ data }) => (
    Axios.post('/rooms', data)
);

const useAddRoom = () => {
    return useMutation(addRoom, {
        onSuccess: (data, variables) => {
            if (variables.contactId) {
                forceReloadContactConnections(variables.contactId);
            }
        }
    })
};

const updateRoom = ({ data }) => (
    Axios.put('/rooms', data)
);

const useUpdateRoom = () => {
    return useMutation(updateRoom, {
        onSuccess: (data, variables) => {
            if (variables.contactId) {
                forceReloadContactConnections(variables.contactId);
            }
        }
    })
};

export {
    useContactConnections,
    updateContactConnections,
    useEditContactConnectionItemDefault,
    isContactConnectionItemDefault,
    useDeleteContactConnection,
    useEditContactConnectionItem,
    useAddContactConnectionItem,
    useSortContactConnections,
    useEditContactConnection,
    //useAddContactConnection,
    useDeleteContactConnectionItem,
    useAddContactConnectionFull,
    forceReloadContactConnections,
    getRoomConnections,
    prefetchRoomConnections,
    reloadRoomConnections,
    useDeleteRoom,
    useAddRoom,
    useUpdateRoom,
    useRoom,
    useIsContactConnectionItemDefault
}
