import { StyledTabs } from "Components/Features/FeaturesUI";
import React from "react";
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


// Drag & Drop node
const TabNode = ({ connectDragSource, connectDropTarget, children }) => {
    return connectDragSource(connectDropTarget(children));
}

const cardTarget = {
    drop(props, monitor) {
        const dragKey = monitor.getItem().index;
        const hoverKey = props.index;

        if (dragKey === hoverKey) {
            return;
        }

        props.moveTabNode(dragKey, hoverKey);
        monitor.getItem().index = hoverKey;
    },
};

const cardSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index,
        };
    },
};

const WrapTabNode = DropTarget('DND_NODE', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
}))(
    DragSource('DND_NODE', cardSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }))(TabNode),
);

const DraggableTabs = ({ orders, onUpdateOrders, children, ...rest }) => {
    // const [order, setOrder] = useState([]);
  
    const moveTabNode = (dragKey, hoverKey) => {
        const newOrder = orders.slice();
    
        React.Children.forEach(children, c => {
            if (newOrder.indexOf(c.key) === -1) {
                newOrder.push(c.key);
            }
        });
    
        const dragIndex = newOrder.indexOf(dragKey);
        const hoverIndex = newOrder.indexOf(hoverKey);
    
        newOrder.splice(dragIndex, 1);
        newOrder.splice(hoverIndex, 0, dragKey);
    
        onUpdateOrders(newOrder);
    };
  
    const renderTabBar = (props, DefaultTabBar) => (
        <DefaultTabBar {...props}>
            {node => (
                <WrapTabNode key={node.key} index={node.key} moveTabNode={moveTabNode}>
                    {node}
                </WrapTabNode>
            )}
        </DefaultTabBar>
    );
  
    const tabs = [];
    React.Children.forEach(children, c => {
        tabs.push(c);
    });

    const orderTabs = tabs.slice().sort((a, b) => {
        const orderA = orders.indexOf(a.key);
        const orderB = orders.indexOf(b.key);

        if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB;
        }
        if (orderA !== -1) {
            return -1;
        }
        if (orderB !== -1) {
            return 1;
        }

        const ia = tabs.indexOf(a);
        const ib = tabs.indexOf(b);

        return ia - ib;
    });

    return (
        <DndProvider backend={HTML5Backend}>
            <StyledTabs renderTabBar={renderTabBar} {...rest}>
                {orderTabs}
            </StyledTabs>
        </DndProvider>
    );
};

export default DraggableTabs;