import React, { useState } from 'react';

import { NwAddButton, NwEditButton } from 'Components/Gui/NwButton';
import { NwCardTabs } from "Components/Gui/NwCard/NwCardTabs";
import ContactDetailsNotes from "Components/ContactDetailsNotes/ContactDetailsNotes";
import ContactDetailsCustomProperties from 'Components/ContactDetails/CommonComponents/CustomProperties/ContactDetailsCustomProperties';
import { useStateValue } from 'Contexts/StateProvider';
import { useUserPermission } from 'Contexts/UserContext';
import { getVisibilityNotes } from 'Libs/NwNotes';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getUncheckedItemsCount } from 'Libs/NwChecklists';
import CheckList from 'Components/CheckLists/CheckList';
import { CheckListProgress } from 'Components/CheckLists/CheckListElements';

const ModelDetailsCustomPropertiesAndNotesTabs = ({ currentContact, drawerMode, ...rest }) => {
    const [{ areaContext }] = useStateValue();

    const [createNotes, setCreateNotes] = useState(false);
    const [editCustomProperties, setEditCustomProperties] = useState(false);

    const viewModelNotes = useUserPermission('ViewModelNotes', currentContact && currentContact.Users.map(user => user.UserID));
    const afterNotesCreation = () => { setCreateNotes(false) }
    const afterEditingProperties = () => { setEditCustomProperties(false) }

    const checkListStatus = getUncheckedItemsCount(currentContact.CheckListItems)

    const [activeTab, setActiveTab] = useState(checkListStatus.complete ? 'customproperties' : 'checklist');
    
    const tabs = [
        {
            key: 'customproperties',
            icon: light('circle-info'),
            name: 'Additional Info'
        },
        {
            key: 'notes',
            icon: light('sticky-note'),
            name: 'Notes',
            counter: viewModelNotes ? getVisibilityNotes(currentContact.Notes, areaContext.area).length : null
        }
    ]
    if (checkListStatus.total > 0) {
        tabs.push({
            key: 'checklist',
            name: 'Checklist',
            prefix: [<CheckListProgress key="checklist-progress" checkListStatus={checkListStatus} />]
        })
    }

    const renderTabs = () => {
        switch (activeTab) {
            case "notes":
                return (
                    <ContactDetailsNotes
                        isInCardTab
                        currentContact={currentContact}
                        drawerMode={drawerMode}
                        createNotes={createNotes}
                        afterNotesCreation={afterNotesCreation}
                        noPagination
                    />)
            case "checklist":
                return (
                    <CheckList
                        objectID={currentContact.ID}
                        objectType="Model"
                        checkListItems={currentContact.CheckListItems}
                    />
                )
            default:
                return (
                    <ContactDetailsCustomProperties
                        currentContact={currentContact}
                        contactType="Model"
                        fullHeight
                        isInCardTab
                        editMode={editCustomProperties}
                        afterEditingProperties={afterEditingProperties}
                    />
                )
        }
    };

    const getTabsExtraContent = () => {
        switch (activeTab) {
            case "notes":
                return (
                    <NwAddButton
                        ghost
                        disabled={!viewModelNotes}
                        size="small"
                        onClick={() => {
                            setCreateNotes(true)
                        }}
                        label="add new"
                    />
                )
            case "customproperties":
                return (
                    <NwEditButton ghost onClick={() => setEditCustomProperties(true)} />
                )
            default:
                return null
        }
    }

    const onTabChange = key => {
        setActiveTab(key)
    };

    return (currentContact ?
        <NwCardTabs
            fullheight
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
            tabBarExtraContent={getTabsExtraContent()}
            {...rest}>
            {renderTabs()}
        </NwCardTabs>
        : <div />
    )
};

export default ModelDetailsCustomPropertiesAndNotesTabs;