import React from 'react';
import EventDrawerDetailsContainer from 'Components/EventDrawer/EventDrawerUi/EventDrawerDetailsContainer';
import ProjectPackageDetails from 'Components/EventDrawer/ProjectPackage/ProjectPackageDetails';
import { Loading } from 'Components/Gui/Loading';
import { usePackage } from 'Hooks/Packages/UsePackages';

const ProjectPackage = ({ ID, afterUpdate, projectId, afterRemove, plainMode, onSelectEvent }) => {
  const { data: packageData, isFetching } = usePackage(ID);

  if (!packageData) {
    return <Loading textBlack />;
  }

  return (
    plainMode ?
      <ProjectPackageDetails
        plainMode
        //packageData={packageData}
        packageID={packageData.ID}
        afterUpdate={afterUpdate}
        afterRemove={afterRemove}
        onSelectEvent={onSelectEvent}
      />
      :
      <EventDrawerDetailsContainer
        eventData={{ ...packageData, elType: 'Package', uniqID: `Package-${packageData.ID}` }}
        isFetching={isFetching}
        typeName="package"
        firstTab={
          <ProjectPackageDetails
            //packageData={packageData}
            packageID={packageData.ID}
            afterUpdate={afterUpdate}
            projectId={projectId}
            afterRemove={afterRemove}
            onSelectEvent={onSelectEvent}
          />
        }
      />
  )
}

export default ProjectPackage;