import React from "react";
import NwFlagIcon from 'Components/Gui/NwFlagIcon.js'
import useCountryName from "Hooks/UseCountryName";

const CountryLabel = props => {
    const countryName = useCountryName(props.code);
    if (props.code) {
        return (
            <>
                {props.flag && <NwFlagIcon code={props.code.toLowerCase()} />}
                {props.usecode ? props.code : countryName}
            </>
        )
    } else {
        return (<></>)
    }
};

export default CountryLabel;
