import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
import { UserContext } from 'Contexts/UserContext';

const useUserDepartments = (allAvailable, allAgencies) => {
    const { cachedList } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    
    const isSingleAgency = () => {
        if (cachedList.accountingGroups.items.length === 1) {
            if (cachedList.accountingGroups.items[0].NetworkAgencies.length === 1) {
                return true
            }
        }
        return false
    }
    
    
    if (!allAvailable) {
        const returnObject = { isSingleAgency: isSingleAgency(), departments: [] }
        const activeDepartments = currentUser.ActiveContext.activeDepartments
        const currentAccGroup = cachedList.accountingGroups.items.find(ag => ag.ID === currentUser.ActiveContext.accGroupId);
        const currentNwAg = currentAccGroup.NetworkAgencies.find(nwAg => nwAg.ID === currentUser.ActiveContext.nwAgId);
        for (const department of currentNwAg.Departments) {
            if (activeDepartments.includes(department.ID)) {
                returnObject.departments.push({...department, networkAgencyId: currentNwAg.ID, networkAgencyName: currentNwAg.Name, accountingGroupId: currentAccGroup.ID, accountingGroupName: currentAccGroup.Name});
            }
        }
        return returnObject;
    } else {
        const returnObject = { isSingleAgency: isSingleAgency(), departments: [] }
        const availableDepartments = currentUser.Departments
        if (allAgencies) {
            for (const accountingGroup of cachedList.accountingGroups.items) {
                for (const networkAgency of accountingGroup.NetworkAgencies) {
                    for (const department of networkAgency.Departments) {
                        if (availableDepartments.includes(department.ID)) {
                            returnObject.departments.push({...department, networkAgencyId: networkAgency.ID, networkAgencyName: networkAgency.Name, accountingGroupId: accountingGroup.ID, accountingGroupName: accountingGroup.Name});
                        }
                    }
                }
            }
            return returnObject;
        } else {
            const currentAccGroup = cachedList.accountingGroups.items.find(ag => ag.ID === currentUser.ActiveContext.accGroupId);
            const currentNwAg = currentAccGroup.NetworkAgencies.find(nwAg => nwAg.ID === currentUser.ActiveContext.nwAgId);
            for (const department of currentNwAg.Departments) {
                if (availableDepartments.includes(department.ID)) {
                    returnObject.departments.push({...department, networkAgencyId: currentNwAg.ID, networkAgencyName: currentNwAg.Name, accountingGroupId: currentAccGroup.ID, accountingGroupName: currentAccGroup.Name});
                }
            }
            return returnObject;
        }
    }
}

export default useUserDepartments