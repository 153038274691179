import React from "react";
import styled from 'styled-components';

import {
    Switch,
    Select,
    Popover,
} from "antd";
import MiniLoading from 'Components/Gui/MiniLoading';
import ModelFeaturesQuickFilters from 'Components/ModelSelector/ModelsWall/ModelsWallHeader/ModelFeaturesQuickFilters';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import ModelsEvents from "Components/ModelSelector/ModelsEvents";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useStateValue } from "Contexts/StateProvider";

const StyledHeader = styled.div`
    margin: 0;
    padding: 0;
    position: relative;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em 2em;
    font-size: .9rem;

    @media (max-width: 480px) {
        border-radius: 0;
    }

    @media only screen and (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .header-summary {
        border-right: 1px solid #e8e8e8;
        flex: auto 0 0;
        padding: 0 1em 0 0;

        @media only screen and (max-width: 960px) {
            border-right: none;
            padding: 0;
        }

        .header-summary-text {
            font-size: 1em;
            display: flex;
            align-items: center;

            em.counter {
                font-size: 1.6em;
                font-weight: bold;
                font-style: normal;
                padding-right: .5em;
            }
        }
        small.hidden-models {
            display: block;
        }
    }

    .header-filters {
        // border-right: 1px solid #e8e8e8;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        padding: 0 1em 0 1em;
        margin-bottom: auto;

        @media only screen and (max-width: 960px) {
            flex-direction: column;
            padding: 0;
            flex: none;
            align-items: flex-start;
        }

        .header-filters-first-row, .header-filters-second-row  {
             .control {
                 padding-right: 1em;
             }

             @media only screen and (max-width: 640px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
            }
        }
    }

    .header-actions {
        display: flex;
        flex-direction: row;
        margin-bottom: auto;
        flex: auto 0 0;
        padding: 0 0 0 1em;

        @media only screen and (max-width: 960px) {
            flex-direction: column;
            padding: 0;
            min-width: 0;
        }
    }

    
    div.control {
        padding: .5em 0;
        span.label {
            font-size: .85em;
            text-transform: uppercase;
            padding: 0 .5em 0 0;

            &.on-right {
                padding: 0 0 0 .5em;
            }
        }
    }
`

const QuickFiltersContainer = styled.div`
    margin: 0;
    padding: 0;
    position: relative;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
`

const ModelsWallHeader = ({
    defaultView,
    filteredList,
    filtersType,
    fixedView,
    isDrawer,
    isLoading,
    modelEventsInfo,
    modelList,
    multipleSelection,
    multiSelection,
    nItems,
    onChangeMultiSelection,
    onChangeShowOnlyModelsWithBooks,
    onChangeSort,
    onChangeSortFolder,
    onChangeStyle,
    onSetQuickFilter,
    onUpdateModelEvents,
    quickFilter,
    scope,
    searchType,
    showOnlyModelsWithBooks,
    //sort,
}) => {
    const [{ areaContext }, dispatch] = useStateValue()
    const breakpoints = useNwBreakPoints()
    const isMobile = breakpoints === 1
    
    let sortOptionsFolder = [
        { label: 'Name', value: 'Name' },
        { label: 'Manual', value: 'Manual' }
    ];
    let sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Agency', value: 'MotherAgency' },
    ];

    const hiddenModels = modelList ? modelList.filter(model => !model.ST) : [];

    return (
        <>
            <StyledHeader>
                <div className="header-summary">
                    {!isLoading
                        ?
                        <>
                            <div className="header-summary-text">
                                <em className="counter">{nItems}</em> MODELS FOUND
                            </div>
                            {hiddenModels.length ?
                                <Popover
                                    trigger='click'
                                    placement='bottom'
                                    content={
                                        <>
                                            {hiddenModels.map(model => <div key={`hidden-models-${model.ID}`}>{model.N}</div>)}
                                        </>
                                    }>
                                    <small className='hidden-models nw-link'>{`${hiddenModels.length} HIDDEN MODELS`}</small>
                                </Popover>
                                :
                                <small className='hidden-models'>&nbsp;</small>
                            }
                        </>
                        :
                        <>
                            <div className="loading-container">
                                <MiniLoading />
                            </div>
                            <p className="loading-title">Fetching Models...</p>
                        </>
                    }
                </div>
                <div className="header-filters">
                    {!fixedView &&
                        <div className="control" style={{ marginRight: "10px" }}>
                            <span className="label">show as</span>
                            <RadioButtonGroup
                                value={defaultView}
                                onChange={onChangeStyle}
                                size='small'
                                options={[
                                    { label: 'Cards', value: 'cards', disabled: isMobile },
                                    { label: 'List', value: 'table' }
                                ]} />
                        </div>
                    }
                    {!isDrawer &&
                        ((filtersType === 'folders')
                        ?
                            <div className="control">
                                <span className="label">sort by</span>
                                {breakpoints < 5 ?
                                    <Select
                                        autoComplete='off'
                                        options={sortOptionsFolder}
                                        size='small'
                                        value={quickFilter.sortFolder}
                                        onChange={onChangeSortFolder} />
                                    : <RadioButtonGroup
                                        value={quickFilter.sortFolder}
                                        onChange={onChangeSortFolder}
                                        size='small'
                                        options={sortOptionsFolder} />
                                }
                            </div>
                            :
                            <div className="control">
                                <span className="label">sort by</span>
                                {breakpoints < 5 ?
                                    <Select
                                        autoComplete='off'
                                        options={sortOptions}
                                        size='small'
                                        value={quickFilter.sort}
                                        onChange={onChangeSort} />
                                    : <RadioButtonGroup
                                        value={quickFilter.sort}
                                        onChange={onChangeSort}
                                        size='small'
                                        options={sortOptions} />
                                }
                            </div>
                        )
                    }
                    {onChangeShowOnlyModelsWithBooks &&
                        <div className="control" style={{ marginRight: "10px" }}>
                            <Switch
                                checked={showOnlyModelsWithBooks}
                                onChange={onChangeShowOnlyModelsWithBooks}
                            />
                            <span className="label on-right">only models with books</span>
                        </div>
                    }
                </div>
                <div className="header-actions" style={{ flexDirection: isDrawer && "column" }}>
                    {multipleSelection && breakpoints !== 1 &&
                        <div className="control" style={{ marginRight: "10px" }}>
                            <Switch
                                checked={multiSelection}
                                onChange={onChangeMultiSelection}
                            />
                            <span className="label on-right">multiple selection</span>
                        </div>
                    }
                    {areaContext.area !== 'scouting' && areaContext.area !== 'accounting' &&
                        <div className="control">
                            <ModelsEvents
                                models={filteredList}
                                onUpdateModelEvents={onUpdateModelEvents}
                                modelEventsInfo={modelEventsInfo}
                            />
                        </div>
                    }
                </div>
            </StyledHeader>
            {
                (filtersType === "all-models" && !searchType && quickFilter.showQuickFilters) &&
                <QuickFiltersContainer>
                    <ModelFeaturesQuickFilters
                        modelList={modelList}
                        quickFilters={quickFilter.quickFilters}
                        onUpdateQuickFilters={(_quickFilters) => {
                            onSetQuickFilter({
                                ...quickFilter,
                                quickFilters: _quickFilters
                            })
                        }}
                        onCloseQuickFilters={() => {
                            onSetQuickFilter({
                                ...quickFilter,
                                quickFilters: {},
                                showQuickFilters: false
                            })
                        }} />
                </QuickFiltersContainer>
            }
        </>
    )
};

export default ModelsWallHeader;

