const LoadingReducer = (state, action) => {
    switch (action.type) {
        case "UpdatePageStatus":
            return {
                ...state,
                loaded: action.status
            };
        default:
            return state;
    }
}
export default LoadingReducer;
