import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";
const Option = Select.Option;

const TransactionStatusSelector = ({ onChange, value, disabled, onBlur }) => {
    const { cachedList } = useContext(ListContext);
    const enumTransactions = cachedList.enumerators.items.find(item => item.Name === "JobTransactionStatusEnum");
    const allTx = [ {Value: "All", Name: "All Status"}, ...enumTransactions.Values ];

    return (
        <Select
            autoComplete='off'
            mode="single"
            value={value}
            placeholder="Please select"
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        >
            {allTx.map(el => (
                <Option key={el.Value} value={el.Value === "All" ? null : el.Value}>
                {el.Name}
                </Option>
            ))}
        </Select>
    );
};

export default TransactionStatusSelector;
