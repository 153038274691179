import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-top: .5rem;
    
    .label {
        margin-right: 4px;
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
    }
    .ant-tag {
        margin-right: 4px;
        font-size: .6em;
        border-radius: 10px;
        padding: 0 8px;
        line-height: 16px;
    }
`;

const Areas = {
    booking: 'BOOK',
    scouting: 'SCO',
    accounting: 'ACCT',
    art: 'ART',
    management: 'MGMT'
};

const Visibilities = ({ visibilities, hideLabel = false }) => {
    return (
        <Container>
            {!hideLabel && <div className='label'>visibility</div>} 
            {visibilities.length === 0 ? <Tag>GLOBAL</Tag>
            : visibilities.map(visibility => {
                return (
                    <Tag className='tag' key={visibility}>
                        {Areas[visibility]}
                    </Tag>
                )
            })}
        </Container>
    );
};

export default Visibilities;