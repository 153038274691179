import React, {useEffect, useState} from "react";
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import { List } from "antd";
import styled from 'styled-components';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import {NwAddButton} from "Components/Gui/NwButton";
import NwDrawer from "Components/Gui/NwDrawer";
import EmptyState from 'Components/Gui/EmptyState';
import ModelInTownScheduleAddEdit from "Components/ModelInTown/ModelInTownScheduleAddEdit";
import ModelInTownScheduleItems from "Components/ModelInTown/ModelInTownScheduleItems";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { getTodayISO, toISODate } from "Libs/NwMoment";

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};

            .ant-list-item {
                padding: ${props => props.isMobile && 0};
            }
        }
    }
`;

const ModelInTownSchedulesDrawer = ({ onClose, modelID, readOnly }) => {
    // const [inTownSchedules, setInTownSchedules] = useState([]);
    const [addInTownSchedules, setAddInTownSchedules] = useState(false);
    const [inTownSchedulesCurrentPeriods, setInTownSchedulesCurrentPeriods] = useState([]);
    const [inTownSchedulesNextPeriods, setInTownSchedulesNextPeriods] = useState([]);
    const [inTownSchedulesOldPeriods, setInTownSchedulesOldPeriods] = useState([]);
    const [touchForm, setTouchForm] = useState(false);
    const breakpoints = useNwBreakPoints();

    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reload = () => {
        Axios.get(`/models/getInTownSchedules/${modelID}`)
        .then(response => {
            // setInTownSchedules(response)
            getDate(response)
        })
        .catch(error => {
            console.log("error", error);
        });
    }

    const todayDate = getTodayISO();

    const getDate = (inTownSchedules) => {
        const oldPeriods = [];
        const NextPeriods = [];
        const CurrentPeriods = [];
        inTownSchedules.length && inTownSchedules.map(date => {
            if (todayDate >=  toISODate(date.StartDate) && todayDate <= toISODate( date.EndDate)){
                CurrentPeriods.push(date);
            }
            if (todayDate > toISODate( date.EndDate)){
                oldPeriods.push(date);
            }
            if (todayDate < toISODate( date.StartDate)){
                NextPeriods.push(date);
            }
            return null;
        });
        setInTownSchedulesOldPeriods(oldPeriods);
        setInTownSchedulesNextPeriods(
            NextPeriods.sort((a, b) => toISODate(a.StartDate) > toISODate(b.StartDate) ? 1 : -1)
        );
        setInTownSchedulesCurrentPeriods(CurrentPeriods);
    };

    const addInTownSchedulesForm = () => {
        setAddInTownSchedules(true)
    }

    const checkOverlapping = (value, editPeriod) => {
        let error;
        const periods = [...inTownSchedulesCurrentPeriods, ...inTownSchedulesNextPeriods, ...inTownSchedulesOldPeriods];

        const period = periods.find(period => {
            if (editPeriod && editPeriod.ID === period.ID) {
                return false;
            }
            if (!value.EndDate) {
                if (!period.EndDate) {
                    return true;
                }
                if (toISODate(value.StartDate) <= toISODate(period.EndDate)) {
                    return true;
                }
                return false;
            }
            if (!period.EndDate) {
                if (toISODate(period.StartDate) <= toISODate(value.EndDate)) {
                    return true;
                }  
                return false;
            }

            if (toISODate(value.StartDate) > toISODate(period.EndDate)
            || toISODate(value.EndDate) < toISODate(period.StartDate)) {
                return false;
            }

            return true;
        })
        
        if (period) {
            error = 'Please check the start and end dates. This period overlaps one or more of existing Locations'
        }
        return error;
    }

    return (
        <NwDrawer
            onClose={onClose}
            title="Model Location"
        >
            {!readOnly &&
                <div className='drawer-toolbar-container'>
                    <NwAddButton primary onClick={addInTownSchedulesForm} label='Add a New Location' />
                </div> 
            }
            <div className='drawer-content standard'>
                {addInTownSchedules && 
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title={'Add Location Period'}
                        onClose={() => setAddInTownSchedules(false)}>
                        <ModelInTownScheduleAddEdit 
                            modelID={modelID}
                            onChange={reload}
                            onCancel={() => setAddInTownSchedules(false)}
                            checkOverlapping={checkOverlapping}  
                            onTouchForm={setTouchForm}
                        />
                    </NwDrawer>
                }
                <Wrapper isMobile={breakpoints === 1}>
                    <NwCard title={'Current Location'} >
                        {inTownSchedulesCurrentPeriods.length > 0 ? 
                            <List
                                size='small'
                                itemLayout="horizontal"
                                dataSource={inTownSchedulesCurrentPeriods}
                                renderItem={period => {
                                    return (
                                        <ModelInTownScheduleItems key={period.ID}
                                            date={period}
                                            readOnly={readOnly}
                                            onChange={reload}
                                            checkOverlapping={value => checkOverlapping(value, period)}
                                        />
                                    )}
                                }
                            />
                            : <EmptyState message='Not Currently In-Town' />
                        }
                    </NwCard>
                </Wrapper>
                <Wrapper isMobile={breakpoints === 1}>
                    <NwCard title={'Next Locations'} >
                        {inTownSchedulesNextPeriods.length > 0 ?
                            <List
                                size='small'
                                itemLayout="horizontal"
                                dataSource={inTownSchedulesNextPeriods}
                                renderItem={period => {
                                    return (
                                        <ModelInTownScheduleItems key={period.ID}
                                            date={period}
                                            onChange={reload}
                                            readOnly={readOnly}
                                            checkOverlapping={value => checkOverlapping(value, period)}
                                        />
                                    )}
                                }
                            />
                            : <EmptyState message='No Next Locations' />
                        }
                    </NwCard>
                </Wrapper>
                <Wrapper isMobile={breakpoints === 1}>
                    <NwCard title={'Past Locations'} >
                        {inTownSchedulesOldPeriods.length > 0 ?
                            <List
                                size='small'
                                itemLayout="horizontal"
                                dataSource={inTownSchedulesOldPeriods}
                                renderItem={period => {
                                    return (
                                        <ModelInTownScheduleItems key={period.ID}
                                            date={period}
                                            onChange={reload}
                                            readOnly={readOnly}
                                            checkOverlapping={value => checkOverlapping(value, period)}
                                        />
                                    )}
                                }
                            />
                            : <EmptyState message='No Past Locations' />
                        }
                    </NwCard>
                </Wrapper>
            </div>
        </NwDrawer>
    );
};

export default ModelInTownSchedulesDrawer;
