const completeURL = (social, username) => {
  if (!social) {
    return username
  } else {
    return social.getUrl(username)
  }
}

const removeAfterFWDSlash = input => {
  if (input.split("/").length >= 2) {
    return (input = input.split("/")[0])
  } else {
    return input
  }
}

const removeAfterQuestionMark = input => {
  if (input.split("?").length >= 2) {
    return (input = input.split("?")[0])
  } else {
    return input
  }
}

const trimAt = input => {
  return (input = input.includes("@") ? input.substring(1) : input)
}

const isWebsite = input => {
  if (input && input.length > 0) {
    return input.includes("http") || input.includes("www")
  }
  return false;
  // const regex = new RegExp(
  //   "(facebook|fb|instagram|twitter|insta|instagr|youtube|imdb|models).(com|co|am)"
  // )
  // return input.match(regex) ? true : false
}

const determineSocialMedia = _input => {
  const instagramRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am)/?")
  const facebookRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?facebook.com/?")
  const twitterRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?twitter.com/?")
  //const modelsRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?models.com/?")
  //const modelsRegex = new RegExp("(?:(?:http|https)://)?(?:www\.)?models\.com/?")
  //const modelsRegex = new RegExp("^(?:http://|https://)?(?:www\.)?models\.com/.+$");
  const modelsRegex = new RegExp("^(?:http://|https://)?(?:www\.)?models\.com/?");
  const tiktokRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?tiktok.com/?")
  const youtubeRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?youtube.com/?")
  const imdbRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?imdb.com/?")
  const linkedinRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?linkedin.com/?")
  const pinterestRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?pinterest.(?:...)/?")
  const behanceRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?behance.net/?")
  //const tumblrRegex = new RegExp("(?:(?:http|https)://)?(?:www.)?tumblr.com/?")
  
  let _social = ""
  
  const checkRegExs = () => {
    if (_input.match(instagramRegex)) {
      _social = "Instagram"
      _input = _input.substring(_input.match(instagramRegex)[0].length);
      if (_input.split("/")[0] === "stories") {
        _input = _input.split("/")[1]
      }
      return true
    }
    if (_input.match(facebookRegex)) {
      _social = "Facebook"
      _input = _input.substring(_input.match(facebookRegex)[0].length);
      return true
    }
    if (_input.match(twitterRegex)) {
      _social = "Twitter"
      _input = _input.substring(_input.match(twitterRegex)[0].length)
      return true
    }
    if (_input.match(modelsRegex)) {
      _social = "ModelsCom"
      _input = _input.substring(_input.match(modelsRegex)[0].length)
      if (_input.split("/")[0] === "models") {
        _input = _input.split("/")[1]
      }
      return true
    }
    if (_input.match(tiktokRegex)) {
      _social = "TikTok"
      _input = _input.substring(_input.match(tiktokRegex)[0].length)
      return true
    }
    if (_input.match(youtubeRegex)) {
      _social = "Youtube"
      _input = _input.substring(_input.match(youtubeRegex)[0].length)
      if (
        _input.split("/")[0] === "User.js" ||
        _input.split("/")[0] === "u" ||
        _input.split("/")[0] === "channel"
      ) {
        _input = _input.split("/")[1]
      }
      return true
    }
    if (_input.match(imdbRegex)) {
      _social = "IMDB"
      _input = _input.substring(_input.match(imdbRegex)[0].length)
      if (_input.split("/")[0] === "name") {
        _input = _input.split("/")[1]
      }
      return true
    }
    if (_input.match(linkedinRegex)) {
      _social = "LinkedIn"
      _input = _input.substring(_input.match(linkedinRegex)[0].length)
      if (_input.split("/")[0] === "in") {
        _input = _input.split("/")[1]
      }
      return true
    }
    if (_input.match(pinterestRegex)) {
      _social = "Pinterest"
      _input = _input.substring(_input.match(pinterestRegex)[0].length);
      return true
    }
    if (_input.match(behanceRegex)) {
      _social = "Behance"
      _input = _input.substring(_input.match(behanceRegex)[0].length);
      return true
    }
  }

  checkRegExs()

  _input = trimAt(_input)
  _input = removeAfterFWDSlash(_input)
  _input = removeAfterQuestionMark(_input)
  
  return { social: _social, input: _input }
}

const getSplittedUrl = (social) => {
  const url = social.getUrl("SPLITTER")
  const splittedUrl = url.split("SPLITTER")
  return splittedUrl
}

export {
  completeURL,
  removeAfterFWDSlash,
  removeAfterQuestionMark,
  trimAt,
  isWebsite,
  determineSocialMedia,
  getSplittedUrl
}
