import React, { useState, useEffect } from 'react';
import { Popover, List } from "antd";
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import NwEllipsis from "Components/Gui/NwEllipsis";
import NwIcon from "Components/Gui/NwIcon";
import FlexContainer from 'Components/Gui/FlexContainer';
import { showDateNoTime, parseDateTime } from 'Libs/NwMoment';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { AgendaEventContent } from 'Components/Schedule/Agenda/AgendaEventStyle';
import { isModelBusy } from 'Libs/ModelAvailability';

const ModelAvailability = ({ startDate, endDate, modelId }) => {
    const [isFetched, setFetched] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [modelEvents, setModelEvents] = useState([]);
    
    useEffect(() => {
        handleFetchModelEvents();
    }, [startDate, endDate]);

    const handleFetchModelEvents = () => {
        const start = getMoment(startDate).startOf('day');
        const end = getMoment(endDate).endOf('day');

        let newValues = {
            FDate: start,
            TDate: end,
            idmodels: [modelId],
            inclJobs: true,
            inclCastings: true,
            checkConflict: true,
            inclTravels: true,
            inclAccommodations: true,
            inclExternalJobs: true,
            inclFollowUps: true,
            inclMeetings: true,
            inclNotices: true,
            General: false,
            Warning: false
        };

        Axios.post("/calendar/getevents", newValues).then(modelEventsInfo => {
            setModelEvents(modelEventsInfo.Events);
            setIsBusy(isModelBusy(modelEventsInfo.Events, modelId));
            setFetched(true);
        })
            .catch(error => {
                //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
                console.log("error", error);
            });
    }

    const start = parseDateTime(startDate, 'notime');
    const end = parseDateTime(endDate, 'notime');

    return (
        <div>
            {isFetched ?
                isBusy ?
                    <FlexContainer>
                        <Popover
                            placement="topRight"
                            content={
                                <List
                                    size="small"
                                    itemLayout="horizontal"
                                    dataSource={modelEvents}
                                    renderItem={event => (
                                        <AgendaEventContent $cancel={event.Cancel}>
                                            {event.Project &&
                                                <ProjectLine project={event.Project} ellipsis />
                                            }
                                            <EventTypeTag event={event} /> {event.Name}
                                            {event.Description && <small><NwEllipsis text={event.Description} rows={2} popover /></small>}
                                            <small>{showDateNoTime(event.FromDate)} - {showDateNoTime(event.ToDate)}</small>

                                        </AgendaEventContent>
                                    )}
                                />
                            }
                            title={start === end ? start : `${start} - ${end}`}
                            trigger="hover">
                            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <NwIcon
                                    icon={light("calendar-exclamation")}
                                    style={{ color: StyleVariables.DangerColor, marginRight: 8 }}
                                    onClick={event => {
                                        event.stopPropagation();
                                        handleFetchModelEvents(modelId);
                                    }} />

                            </div>
                        </Popover>
                        <div>{`busy in this period.`}</div>
                    </FlexContainer>
                    :
                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <NwIcon
                            icon={light("calendar-check")}
                            style={{ color: StyleVariables.SuccessColor, marginRight: 8 }}
                            onClick={event => {
                                event.stopPropagation();
                                handleFetchModelEvents(modelId);
                            }} />
                        {`available for this period.`}
                    </div>
                :
                <NwIcon icon={light("calendar")} onClick={event => {
                    event.stopPropagation();
                    handleFetchModelEvents(modelId);
                }} />
            }
        </div>
    )
};

export default ModelAvailability;