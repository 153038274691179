import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Loading } from 'Components/Gui/Loading';
import NwUpload from "Components/Gui/NwUpload";
import NwDrawer from 'Components/Gui/NwDrawer';

import {
    EventTitleText,
    EventContainer
} from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import EventDrawerHeader from 'Components/EventDrawer/EventDrawerUi/EventDrawerHeader';
import {
    useScoutingTravel,
    useDeleteScoutingTravel,
    forceReloadScoutingTravel
} from 'Hooks/Event/UseScoutingTravel';
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import EventDocuments from "Components/EventDocuments/EventDocuments";
import ScoutingTravelForm from 'Components/EventDrawer/ScoutingTravelsEvent/ScoutingTravelForm';
import FlexContainer from "Components/Gui/FlexContainer";
import TravelDetails from 'Components/EventDrawer/TravelsEvent/TravelDetails';

const ScoutingTravelsEvent = props => {
    const {
        ID,
        viewMode,
        travelPlanId,
        afterUpdate,
        afterRemove,
        plainMode,
        filterModel,
        dateSelected,
        allDay,
        onTouchForm,
        onClose
    } = props;
    const [travelID, setScoutingTravelID] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const { data: travelsData, /*isFetching,*/ status } = useScoutingTravel(travelID);
    const { mutateAsync: onDeleteScoutingTravel } = useDeleteScoutingTravel();
    const [editModelEvent, setEditModelEvent] = useState();
    const [touchForm, setTouchForm] = useState(false);

    useEffect(() => {
        setScoutingTravelID(ID);
    }, [ID]);

    useEffect(() => {
        // setViewDetails(false);
    }, [filterModel]);

    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteScoutingTravel({ ID: eventId, travelId: travelID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const RelatedEventsDetails = () => {
        const events = travelsData.RelatedEvents ? [travelsData, ...travelsData.RelatedEvents] : [travelsData];
        return (
            <div className="testest" style={{ width: "100%" }}>
                {events.map(event => {
                    return (
                        <FlexContainer justifyContent="space-between" alignItems="flex-end" pd="0 30px 16px 0" fullWidth>
                            {(!isEdit || plainMode) &&
                                <EventCommentsDocuments
                                    event={event}
                                    api='ScoutingTravelID'
                                    type='ScoutingTravel'
                                    fetchEvent={() => {
                                        forceReloadScoutingTravel(travelsData.ID)
                                    }}
                                    onTouchForm={onTouchForm}
                                />
                            }
                        </FlexContainer>
                    )
                })}
            </div>
        )
    }

    const EventDetails = () => (
        <>
            <div className="event-details">
                <FlexContainer justifyContent="space-between">
                    <EventTitleText>{travelsData.Title}</EventTitleText>
                    <NwDropDownMenu
                        actions={[
                            { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => setIsEdit(!isEdit)) },
                            {
                                name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (async () => {
                                    await handleRemoveEvent(travelsData.ID);
                                    afterRemove(true);
                                })
                            }
                        ]}
                    />
                </FlexContainer>
                <TravelDetails travelsData={travelsData} />
                <FlexContainer mg="1em 0 0 0">
                    {travelsData.GroupGuid &&
                        <NwUpload
                            primary
                            label='add document'
                            maxSize={20}
                            showList={false}
                            types={["all"]}
                            onUploadEnd={() => {
                                forceReloadScoutingTravel(travelsData.ID);
                            }}
                            endpoint={`documents/upload/1?eventGroupGuid=${travelsData.GroupGuid}`}
                        />
                    }
                </FlexContainer>
                {travelsData.GroupGuid &&
                    <EventDocuments
                        event={travelsData}
                        type={'eventGroup'}
                        updateEvent={() => forceReloadScoutingTravel(travelsData.ID)}
                    />
                }
            </div>
            <RelatedEventsDetails />
        </>
    )

    const HeaderContent = () => (
        travelsData
            ?
            (plainMode
                ?
                <div />
                :
                <EventDrawerHeader
                    type={120}
                    typeName="scoutingTravel"
                    data={travelsData}
                />
            )
            :
            <div className="ant-drawer-header ant-drawer-header-sticky ">
                <div className="ant-drawer-title">Add New Scouting Travel</div>
            </div>
    )

    const handleClearEditEvent = () => {
        if (editModelEvent) {
            setEditModelEvent();
        }
    }

    const handleClearTouchForm = () => {
        if (touchForm) {
            setTouchForm(false);
        }
    };

    const ScoutingTravelFormContent = () => (
        <ScoutingTravelForm
            plainMode={plainMode}
            travelsData={editModelEvent ? editModelEvent : travelsData}
            editModelEvent={editModelEvent ? true : false}
            viewMode={viewMode}
            dateSelected={dateSelected}
            allDay={allDay}
            travelPlanId={travelPlanId}
            afterUpdate={afterUpdate}
            onTouchForm={touchForm => {
                if (travelsData) {
                    setTouchForm(touchForm);
                } else {
                    onTouchForm(touchForm);
                }
            }}
            onCancel={() => {
                handleClearEditEvent();
                handleClearTouchForm();
                if (travelsData) {
                    setIsEdit(false);
                } else {
                    onClose();
                }
            }}
            onUpdateScoutingTravelData={travelId => {
                if (travelId) {
                    setScoutingTravelID(travelId)
                } else {
                    forceReloadScoutingTravel(travelID)
                }
                handleClearTouchForm();
                handleClearEditEvent();
            }} />
    )

    const Content = () => (
        <div>
            {plainMode ?
                <>
                    {travelsData && <EventDetails />}
                    {isEdit &&
                        <NwDrawer
                            padding="0px"
                            width={740}
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            title='Edit Scouting Travel'
                            onClose={() => {
                                handleClearEditEvent();
                                setIsEdit(false);
                                handleClearTouchForm();
                            }}
                        >
                            <EventContainer>
                                <ScoutingTravelFormContent />
                            </EventContainer>
                        </NwDrawer>
                    }
                </>
                :
                <>
                    {!isEdit && travelsData ?
                        <div className="event-content-wrapper">
                            <EventDetails />
                        </div>
                        : <div className="event-content-wrapper">
                            <ScoutingTravelFormContent />
                        </div>
                    }
                </>
            }
        </div>
    )

    if ((!travelsData && travelID) || status === 'Loading') {
        return <Loading textBlack />
    } else {
        return plainMode ?
            <EventContainer>
                {travelsData &&
                    <Row gutter={32}>
                        <Col span={14}>
                            <Content />
                        </Col>
                    </Row>
                }
            </EventContainer>
            : <EventContainer>
                <HeaderContent />
                <Content />
            </EventContainer>
    }
};

export default ScoutingTravelsEvent;
