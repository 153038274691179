export const TRAVEL_PLANS_FILTERS = {
    reducerPrefix: 'TravelPlanFilters',
    sections: [
        {name: "contacts"},
        {name: "travel details"}
    ],
    filters: [
        {
            name: 'BookerID',
            label: 'Scouter',
            area: 'contacts',
            defaultval: '{me}',
            commontype: 'booker',
            type: 'bookerList'
        },
        {
            name: 'ModelID',
            label: 'Model (seen):',
            area: 'contacts',
            defaultval: null,
            commontype: null,
            type: 'model-selector'
        },
        {
            name: 'AgencyID',
            label: 'Agency (met):',
            area: 'contacts',
            defaultval: null,
            commontype: null,
            type: 'agency-selector'
        },
        {
            name: 'DepartingDate',
            label: 'Departure Between:',
            area: 'travel details',
            defaultval: '{last30days}',
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Ongoing',
            label: 'Ongoing travel:',
            area: 'travel details',
            defaultval: true,
            commontype: null,
            type: 'boolean'
        },
        {
            name: 'Text',
            label: 'Search word in title or description',
            area: 'travel details',
            defaultval: '',
            commontype: null,
            type: ''
        },
    ]
};