import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import { Input, Dropdown, Menu, Select, AutoComplete } from 'antd';
import styled from 'styled-components';
import { getMoment, getTime, showTime } from "Libs/NwMoment";
//import Scrollbar from 'react-scrollbars-custom';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import { ConfigContext } from 'Contexts/ConfigContext';

const Container = styled.div`
    margin: 0 .5em 0 0;
    opacity: ${props => props.disabled && 0.7};

    .ant-input-group {
        display: flex !important;
    }
`;

const StyledInput = styled(Input)`
    padding: 0 6px !important;
`

// const StyledMenuWrapper = styled.div`
//     width: 110px;
//     height: 160px;
//     border: ${props => props.theme.DefaultBorder};
//     border-radius: 4px;
//     outline: none;
//     -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
// `

// const StyledMenu = styled(Menu)`
//     border-right: none;
// `

const StyledMenuItem = styled.div`
    font-size: .9rem !important;
    border-bottom: ${props => props.theme.DefaultBorder} !important;
    height: 20px !important;
    margin: 0 !important;
    padding: 5px 0 0 0 !important;
    text-align: center !important;
    box-sizing: border-box !important;
    overflow: visible !important;
    line-height: .9em !important;

    &.format12 {
        padding: 8px 20px 0 0 !important;
        text-align: right !important;
    }
`

const LTrim = (s) => (s.replace(/\s*((\S+\s*)*)/, "$1"))
const RTrim = (s) => (s.replace(/((\s*\S+)*)\s*/, "$1"))
const trim = (s) => (LTrim(RTrim(s)))

const PadDigits = (n, totalDigits) => {
    n = n.toString();
    var pd = '';
    if (totalDigits > n.length) {
        for (let i = 0; i < (totalDigits - n.length); i++) {
            pd += '0';
        }
    }
    return pd + n.toString();
}

const guessTime = (value) => {
    let str = trim(value);
    let str2 = ""
    let res = ""
    let am_pm = false
    let am = false
    let pm = false
    // let pos_ampm = -1
    let h = -1;
    let m = -1;

    if (str) {
        str2 = str;
        str2 = str2.replace("'", "");
        str2 = str2.replace(".", ":");
        str2 = str2.replace(",", ":");
        str2 = str2.replace(";", ":");
        str2 = str2.replace("_", ":");
        str2 = str2.replace("-", ":");
        str2 = str2.replace(/m/gi, "");
        var a_find = str2.search(/a/i)
        var p_find = str2.search(/p/i)
        if (a_find >= 0) {
            am_pm = true;
            am = true;
            pm = false;
            // pos_ampm = a_find;
        } else {
            if (p_find >= 0) {
                am_pm = true;
                am = false;
                pm = true;
                // pos_ampm = p_find;
            }
        }
        str2 = str2.replace(/[a-z]/gi, "");
        str2 = trim(str2);
        str2 = str2.replace(/[ ]/g, ":");
        const astr2 = str2.split(":");

        switch (astr2.length) {
            case 0:
                break;
            case 1:
                const a2str = astr2[0];
                const a2num = Number(a2str);
                if ((isNaN(a2num)) | (a2str.length === 0)) {
                    break;
                } else {
                    switch (a2str.substring(0, 1)) {
                        case "0":
                            if (a2str.length === 3) {
                                h = Number(a2str.substring(0, 2));
                                m = Number(a2str.substring(2, 3));
                            } else {
                                h = Number(a2str.substring(0, 2));
                                m = Number(a2str.substring(2, 4));
                                if (m > 59) {
                                    m = Number(a2str.substring(2, 3));
                                }
                            }
                            break;
                        case "1":
                            if (am_pm) {
                                if (Number(a2str.substring(1, 2)) <= 2) {
                                    h = Number(a2str.substring(0, 2));
                                    m = Number(a2str.substring(2, 4));
                                    if (m > 59) {
                                        m = Number(a2str.substring(2, 3));
                                    }
                                } else {
                                    h = Number(a2str.substring(0, 1));
                                    m = Number(a2str.substring(1, 3));
                                    if (m > 59) {
                                        m = Number(a2str.substring(1, 2));
                                    }
                                }
                            } else {
                                h = Number(a2str.substring(0, 2));
                                m = Number(a2str.substring(2, 4));
                                if (m > 59) {
                                    m = Number(a2str.substring(2, 3));
                                }
                            }
                            break;
                        case "2":
                            if (am_pm) {
                                h = Number(a2str.substring(0, 1));
                                m = Number(a2str.substring(1, 3));
                                if (m > 59) {
                                    m = Number(a2str.substring(1, 2));
                                }
                            } else {
                                if (Number(a2str.substring(1, 2)) <= 3) {
                                    h = Number(a2str.substring(0, 2));
                                    m = Number(a2str.substring(2, 4));
                                    if (m > 59) {
                                        m = Number(a2str.substring(2, 3));
                                    }
                                } else {
                                    h = Number(a2str.substring(0, 1));
                                    m = Number(a2str.substring(1, 3));
                                    if (m > 59) {
                                        m = Number(a2str.substring(1, 2));
                                    }
                                }
                            }
                            break;
                        default:
                            h = Number(a2str.substring(0, 1));
                            m = Number(a2str.substring(1, 3));
                            if (m > 59) {
                                m = Number(a2str.substring(1, 2));
                            }
                    }
                }
                break;
            default:
                for (var ia = 0; ia < astr2.length; ia++) {
                    if (astr2[ia].trim !== "") {
                        if (!isNaN(Number(astr2[0]))) {
                            if (h === -1) {
                                h = Number(astr2[ia]);
                            } else {
                                m = Number(astr2[ia]);
                            }
                        }
                    }
                }
        }
        if (h === 24) h = 0;
        if (h === 12 && am) h = 0;
        if (am_pm && h > 12) {
            h = -1;
        } else if (h > 23) {
            h = -1;
        }
        if (h !== -1) {
            if (m > 59) m = -1;
            if (m === -1) m = 0;
            if (pm && h > 0 && h < 12) h = h + 12;
            res = PadDigits(h, 2) + ":" + PadDigits(m, 2);
        }
    }
    return res;
}

const timesArray = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30",
    "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
]

const formatTo12 = (t) => (getMoment(t, 'HH:mm').format('h:mm A'))

const InputGroup = Input.Group;

const NWTimePicker = ({ label, name, value, disabled, beforeTime, afterTime, onChange }) => {
    
    const dropDownContainer = useRef(null)

    const { userGuiConfig } = useContext(ConfigContext);
    
    const format12 = userGuiConfig.calendar.timeFormat === "12"
    const formatTime = (t) => (format12 ? formatTo12(t) : t)

    //value which is written in the field
    const [currentValue, SetCurrentValue] = useState(formatTime(value))
    //value parsed on blur
    const [parsedValue, SetParsedValue] = useState(value)

    const [dropdown, SetDropdown] = useState(false)

    const scrollTopValue = 500;

    useEffect(() => {
        if (dropdown) {
            dropDownContainer.current.lastChild.firstChild.firstChild.firstChild.scrollTop = scrollTopValue
        }
    }, [dropdown])

    useEffect(() => {
        SetCurrentValue(formatTime(value));
    }, [value]);

    useEffect(() => {
        if (parsedValue !== value) {
            onChange(parsedValue)
        }
    }, [parsedValue])


    const openDropDown = (e) => {
        if (dropdown) {
            SetDropdown(false)
        } else {
            SetDropdown(true)
            if (e.target.value) {
                const parsedTime = guessTime(e.target.value)
                SetCurrentValue(formatTime(parsedTime))
                SetParsedValue(parsedTime)
            }
        }
    }

    const writeTime = (e) => {
        SetCurrentValue(e.target.value)
        SetDropdown(false)
    }

    const handleMenuClick = ({ key }) => {
        SetCurrentValue(formatTime(key))
        SetParsedValue(key)
        SetDropdown(false)
    };

    const onBlur = (e) => {
        const parsedTime = guessTime(e.target.value)
        SetCurrentValue(formatTime(parsedTime))
        SetParsedValue(parsedTime)
    }

    const displayedTimesArray = useMemo(() => {
        let displayedTimesArray = [...timesArray];
        if (beforeTime) {
            displayedTimesArray = displayedTimesArray.filter(time => time >= beforeTime);
        }
        if (afterTime) {
            displayedTimesArray = displayedTimesArray.filter(time => time <= afterTime);
        }
        return displayedTimesArray;
    }, [beforeTime, afterTime]);

    const timeClassName = format12 ? "format12" : ""


    return (
        <Container ref={dropDownContainer} className="dropdown-container" disabled={disabled}>
            <Dropdown
                disabled={disabled}
                open={dropdown}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                menu={{
                    onClick: handleMenuClick,
                    items: displayedTimesArray.map((t) => {
                        return ({
                            key: t,
                            label: <StyledMenuItem className={timeClassName}>{formatTime(t)}</StyledMenuItem>
                        })
                    }),
                    selectedKeys: [currentValue],
                    style: { maxHeight: "20rem", overflow: "scroll" }
                }}
            >
                <div className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                    <InputGroup compact>
                        <StyledInput autoComplete='off' style={{ maxWidth: format12 ? "85px" : "60px" }} onClick={openDropDown}  onChange={writeTime} onBlur={onBlur} value={currentValue} /> 
                        <NwButton style={{ width: "45px" }} icon={dropdown ? light("xmark") : light("clock")} onClick={openDropDown} />
                    </InputGroup>
                </div>
            </Dropdown>
        </Container>
    )
};

export default NWTimePicker;