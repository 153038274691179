import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import NwDrawer from 'Components/Gui/NwDrawer';
import { NWInput } from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useClonePackage } from 'Hooks/Packages/UsePackages';

const PackageCloneDrawer = ({ packageName, packageID, onClose }) => {
    const { mutateAsync: onClonePackage } = useClonePackage();
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = {
        Title: packageName ? `Copy of ${packageName}` : `Cloned Package`
    };

    const saveClonedPackage = async (values, setSubmitting) => {
        const data = {
            Name: values.Title,
            ID: packageID
        }
        setSubmitting(true);
        try {
            const id = await onClonePackage({ data });
            setTouchForm(false);
            setSubmitting(false);
            onClose(id);
        } catch (error) {
            console.log('ant : Add New Package Error => ', error);
            setSubmitting(false);
        }
    };

    const handleCloseDrawer = () => {
        setTouchForm(false);
        onClose();
    }

    return (
        <NwDrawer
            title='Clone Package'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={handleCloseDrawer}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    saveClonedPackage(values, setSubmitting);
                }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Package Title"
                            name="Title"
                            type="text"
                            value={values.Title} />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    label="Create"
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}


export default PackageCloneDrawer;