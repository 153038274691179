import { brands, light } from "@fortawesome/fontawesome-svg-core/import.macro";

export const socialTypes = [
    {
        name: 'Instagram',
        code: 'Instagram',
        getUrl: username => `https://www.instagram.com/${username}`,
        getIcon: () => brands("instagram")
    },
    {
        name: 'Facebook',
        code: 'Facebook',
        getUrl: username => `https://www.facebook.com/${username}`,
        getIcon: () => brands("facebook")
    },
    {
        name: 'X / Twitter',
        code: 'Twitter',
        getUrl: username => `https://www.twitter.com/${username}`,
        getIcon: () => brands("x-twitter")
    },
    {
        name: 'Models.com',
        code: 'ModelsCom',
        getUrl: username => `https://www.models.com/models/${username}`,
        getIcon: () => null
    },
    {
        name: 'TikTok',
        code: 'TikTok',
        getUrl: username => `https://www.tiktok.com/@${username}`,
        getIcon: () => brands("tiktok")
    },
    {
        name: 'YouTube',
        code: 'YouTube',
        getUrl: username => `https://www.youtube.com/channel/${username}`,
        getIcon: () => brands("youtube")
    },
    {
        name: 'IMDB',
        code: 'IMDB',
        getUrl: username => `https://www.imdb.com/name/${username}`,
        getIcon: () => brands("imdb")
    },
    {
        name: 'LinkedIn',
        code: 'LinkedIn',
        getUrl: username => `https://www.linkedin.com/in/${username}`,
        getIcon: () => brands("linkedin")
    },
    // {
    //     name: 'Tumblr',
    //     code: 'Tumblr',
    //     getUrl: username => `https://${username}.tumblr.com`,
    //     getIcon: () => brands("tumblr")
    // },
    {
        name: 'Pinterest',
        code: 'Pinterest',
        getUrl: username => `https://www.pinterest.com/${username}`,
        getIcon: () => brands("pinterest")
    },
    {
        name: 'Behance',
        code: 'Behance',
        getUrl: username => `https://www.behance.net/${username}`,
        getIcon: () => brands("behance")
    },
    {
        name: 'Vimeo',
        code: 'Vimeo',
        getUrl: username => `https://vimeo.com/${username}`,
        getIcon: () => brands("vimeo")
    },
    {
        name: 'Blog',
        code: 'Blog',
        getUrl: username => `${username}`,
        getIcon: () => light("rss")
    },
    {
        name: 'Website',
        code: 'Personal',
        getUrl: username => `${username}`,
        getIcon: () => light("globe-pointer")
    },
    {
        name: 'Other',
        code: 'Other',
        getUrl: username => `${username}`,
        getIcon: () => light("browser")
    },
  ];
