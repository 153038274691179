import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import s from 'underscore.string';
import ls from 'local-storage';
import { queryClient } from 'QueryClientProvider';
// import ConversionSelector from "Components/Gui/NWForm/ConversionSelector";


function capitalize(s) {
    if (!s) return "";
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
}



const modelFullName = (model, initialOnly, asArray) => {
    //initials of surname
    if (initialOnly) {
        if (model && model.Name) {
            return model.Surname ? `${capitalize(model.Name)} ${s.truncate(model.Surname, 1, ".").toUpperCase()}` : capitalize(model.Name)
        }
        if (model && model.N) {
            return model.S ? `${capitalize(model.N)} ${s.truncate(model.S, 1, ".").toUpperCase()}` : capitalize(model.N)
        }
    }
    //array [name, surname]
    if (asArray) {
        return [capitalize(model.N), (model.S ? capitalize(model.S) : "")]
    }
    //full name
    if (model && model.Name) {
        return model.Surname ? `${capitalize(model.Name)} ${capitalize(model.Surname)}` : capitalize(model.Name)
    }
    if (model && model.N) {
        return model.S ? `${capitalize(model.N)} ${capitalize(model.S)}` : capitalize(model.N)
    }
    return ""
}

const fetchModels = (area) => {
    if (area === 'accounting') {
        return Axios.get('/models/accounting');
    } else if (area === 'management') {
        return Axios.post('/models/management', {
            "StorageBookingFilter": "All"
        });
    } else {
        return Axios.get('/models');
    }
}

// const fetchModelsByArea_OLD = async area => {
//     queryClient.invalidateQueries(['models', area]);
//     try {
//         return await queryClient.fetchQuery(['models', area], () => fetchModels(area), {
//             staleTime: 300000,
//             cacheTime: Infinity
//         });
//     } catch (error) {
//         console.log(error)
//     }
// };

const fetchModelsByArea = async area => {
    queryClient.invalidateQueries(['models']);
    await queryClient.prefetchQuery(['models'], () => fetchModels(area), {
        staleTime: 300000,
        cacheTime: Infinity
    });
    // try {
    //     return await queryClient.fetchQuery(['models'], () => fetchModels(area), {
    //         staleTime: 300000,
    //         cacheTime: Infinity
    //     });
    // } catch (error) {
    //     console.log(error)
    // }
};

// const prefetchModels_OLD = () => {
//     queryClient.prefetchQuery(
//         ['models', getArea()],
//         () => fetchModels(getArea()),
//         {
//             staleTime: 300000,
//             cacheTime: Infinity,
//             retry: false
//         }
//     );
// };

const prefetchModels = () => {
    queryClient.prefetchQuery(
        ['models'],
        () => fetchModels(getArea()),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
};

// const useModelsList_OLD = () => {
//     return (
//         useQuery(['models', getArea()],
//             () => fetchModels(getArea()),
//             {
//                 staleTime: 300000,
//                 cacheTime: Infinity,
//                 retry: false
//             }
//         )
//     )
// }

const useModelsList = () => {
    return (
        useQuery(['models'],
            () => fetchModels(getArea()),
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}

// const getModel_OLD = (ID) => {
//     const models = queryClient.getQueryData(['models', getArea()]);
//     if (models) {
//         const c = models.find((m) => (m.ID === ID))
//         if (c) {
//             return c
//         }
//         const contacts = getSimpleContacts();
//         const contact = contacts.find(contact => contact.ID === ID);
//         if (contact) {
//             return {
//                 ...contact,
//                 archived: true
//             }
//         }
//     }
//     return null;
// }

const getModel = (ID) => {
    const models = queryClient.getQueryData(['models']);
    if (models) {
        const c = models.find((m) => (m.ID === ID))
        if (c) {
            return c
        }
        const contacts = getSimpleContacts();
        const contact = contacts.find(contact => contact.ID === ID);
        if (contact) {
            return {
                ...contact,
                archived: true
            }
        }
    }
    return null;
}

const validModelInContext = (modelId) => {
    const models = queryClient.getQueryData(['models']);
    if (models) {
        const c = models.find((m) => (m.ID === modelId))
        if (c) {
            return true
        }
    }
    return false;
}

const validCustomerInContext = (customerId) => {
    const customers = queryClient.getQueryData(['customers']);
    if (customers) {
        const c = customers.find((m) => (m.ID === customerId))
        if (c) {
            return true
        }
    }
    return false;
}

// const getModels_OLD = ids => {
//     const models = queryClient.getQueryData(['models', getArea()]) ? queryClient.getQueryData(['models', getArea()]) : []
//     if (ids && ids.length) {
//         return models.filter(cust => ids.includes(cust.ID))
//     }
//     models.sort((a, b) => (a.N + a.S).localeCompare(b.N + b.S))
//     return models.map(model => ({
//         ...model,
//         N: model.N ? capitalize(model.N) : "",
//         S: model.S ? capitalize(model.S) : "",
//     }))
// }

const getModels = ids => {
    const models = queryClient.getQueryData(['models']) ? queryClient.getQueryData(['models']) : []
    if (ids && ids.length) {
        return models.filter(cust => ids.includes(cust.ID))
    }
    models.sort((a, b) => (a.N + a.S).localeCompare(b.N + b.S))
    return models.map(model => ({
        ...model,
        N: model.N ? capitalize(model.N) : "",
        S: model.S ? capitalize(model.S) : "",
    }))
}

//const forceReloadModels_OLD = () => queryClient.invalidateQueries(['models', getArea()]);

const forceReloadModels = () => queryClient.invalidateQueries(['models']);







const fetchPlaylistsCount = () => {
    return Axios.get('/models/PlaylistsCount');
}

const prefetchPlaylistsCount = () => {
    queryClient.prefetchQuery(
        ['playlistsCount'],
        () => fetchPlaylistsCount(),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
};

const usePlaylistsCount = () => {
    return (
        useQuery(["playlistsCount"],
            () => fetchPlaylistsCount(),
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}

const fetchModelsWallIds = async (filters, activeDepartments) => {
    return Axios.post("/models/wall-id", {
        ...filters,
        Departments: activeDepartments
    })
};

// const useModelsWallIds_OLD = (area, filters, enabled, activeDepartments) => {
//     return useQuery(['model-wall-ids', area], () => fetchModelsWallIds(filters, activeDepartments), {
//         enabled: (enabled && filters) ? true : false,
//         refetchOnWindowFocus: false,
//         staleTime: 10000,
//         cacheTime: Infinity,
//         retry: false
//     });
// };

const useModelsWallIds = (filters, enabled, activeDepartments) => {
    return useQuery(['model-wall-ids'], () => fetchModelsWallIds(filters, activeDepartments), {
        enabled: (enabled && filters) ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 10000,
        cacheTime: Infinity,
        retry: false
    });
};

// const refetchModelWallIds_OLD = async (area, filters, activeDepartments) => { //drawer
//     const queryname = area;  //${drawer ? '-drawer' : ''}
//     try {
//         await queryClient.fetchQuery(['model-wall-ids', queryname], () => fetchModelsWallIds(filters, activeDepartments));
//     } catch (error) {
//         console.log(error)
//     }
// };

const refetchModelWallIds = async (filters, activeDepartments) => { //drawer
    try {
        await queryClient.fetchQuery(['model-wall-ids'], () => fetchModelsWallIds(filters, activeDepartments));
    } catch (error) {
        console.log(error)
    }
};

const forceReloadModelsWall = () => {
    queryClient.invalidateQueries(['model-wall-ids']);
}

const fetchModelsWithBooksIds = async () => {
    return Axios.get("/models/modelsWithPlaylist")
};

const useModelsWithBooksIds = (enabled) => {
    return useQuery(['models-with-books'], () => fetchModelsWithBooksIds(), {
        enabled: enabled ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        cacheTime: Infinity,
        retry: false
    });
};

//CUSTOMERS

const fetchCustomers = (area) => {
    if (area === 'accounting') {
        return Axios.get('/customers/accounting');
    } else if (area === 'management') {
        return Axios.post('/customers/management', {
            "StorageBookingFilter": "All"
        });
    } else {
        return Axios.get('/customers');
    }
}

// const fetchCustomersByArea_OLD = async area => {
//     queryClient.invalidateQueries(['customers', area]);
//     try {
//         return await queryClient.fetchQuery(['customers', area], () => fetchCustomers(area), {
//             staleTime: 300000,
//             cacheTime: Infinity
//         });
//     } catch (error) {
//         console.log(error)
//     }
// };

const fetchCustomersByArea = async area => {
    queryClient.invalidateQueries(['customers']);
    try {
        return await queryClient.fetchQuery(['customers'], () => fetchCustomers(area), {
            staleTime: 300000,
            cacheTime: Infinity
        });
    } catch (error) {
        console.log(error)
    }
};

// const prefetchCustomers_OLD = () => {
//     queryClient.prefetchQuery(
//         ['customers', getArea()],
//         () => fetchCustomers(getArea()),
//         {
//             staleTime: 300000,
//             cacheTime: Infinity,
//             retry: false
//         }
//     );
// }

const prefetchCustomers = () => {
    queryClient.prefetchQuery(
        ['customers'],
        () => fetchCustomers(getArea()),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
}

// const useCustomersList_OLD = () => {
//     return (
//         useQuery(["customers", getArea()],
//             () => fetchCustomers(getArea()),
//             {
//                 staleTime: 300000,
//                 cacheTime: Infinity,
//                 retry: false
//             }
//         )
//     )
// }

const useCustomersList = () => {
    return (
        useQuery(["customers"],
            () => fetchCustomers(getArea()),
            {
                staleTime: 300000,
                cacheTime: Infinity,
                retry: false
            }
        )
    )
}

// const getCustomer_OLD = (ID) => {
//     const customers = queryClient.getQueryData(['customers', getArea()])

//     if (customers) {
//         const c = customers ? customers.find((m) => (m.ID === ID)) : [];
//         if (c) {
//             return c
//         }
//     }
//     return null;
// }

const getCustomer = (ID) => {
    const customers = queryClient.getQueryData(['customers'])

    if (customers) {
        const c = customers ? customers.find((m) => (m.ID === ID)) : [];
        if (c) {
            return c
        }
    }
    return null;
}

// const getCustomers_OLD = ids => {
//     const customers = queryClient.getQueryData(['customers', getArea()]) ? queryClient.getQueryData(['customers', getArea()]) : [];
//     if (ids && ids.length) {
//         return customers ? customers.filter(cust => ids.includes(cust.ID)) : [];
//     }
//     return customers
// };

const getCustomers = ids => {
    const customers = queryClient.getQueryData(['customers']) ? queryClient.getQueryData(['customers']) : [];
    if (ids && ids.length) {
        return customers ? customers.filter(cust => ids.includes(cust.ID)) : [];
    }
    return customers
};

const forceReloadCustomers = () => {
    queryClient.invalidateQueries(['customers'])
}

const customerFullName = (customer, initialOnly, asArray) => {
    //initials of surname
    if (initialOnly) {
        if (customer && customer.Name) {
            return (customer.Surname && !customer.isCompany) ? `${capitalize(customer.Name)} ${s.truncate(customer.Surname, 1, ".").toUpperCase()}` : capitalize(customer.Name)
        }
        if (customer && customer.N) {
            return (customer.S && !customer.isCompany) ? `${capitalize(customer.N)} ${s.truncate(customer.S, 1, ".").toUpperCase()}` : capitalize(customer.N)
        }
    }
    //array [name, surname]
    if (asArray) {
        return [capitalize(customer.N), (customer.S ? capitalize(customer.S) : "")]
    }
    //full name
    if (customer && customer.Name) {
        return (customer.Surname && !customer.isCompany) ? `${capitalize(customer.Name)} ${capitalize(customer.Surname)}` : capitalize(customer.Name)
    }
    if (customer && customer.N) {
        return (customer.S && !customer.isCompany) ? `${capitalize(customer.N)} ${capitalize(customer.S)}` : capitalize(customer.N)
    }
    return ""
};

const fetchCustomersWallIds = async filters => {
    return Axios.post("/customers/wall-id", filters);
};

// const useCustomersWallIds_OLD = (drawer, area, filters, enabled) => {
//     const queryname = `${area}${drawer ? '-drawer' : ''}`
//     return useQuery(['customer-wall-ids', queryname], () => fetchCustomersWallIds(filters), {
//         enabled: (enabled && filters) ? true : false,
//         refetchOnWindowFocus: false,
//         staleTime: 10000,
//         cacheTime: Infinity,
//         retry: false
//     });
// };

const useCustomersWallIds = (filters, enabled) => {
    return useQuery(['customer-wall-ids'], () => fetchCustomersWallIds(filters), {
        enabled: (enabled && filters) ? true : false,
        refetchOnWindowFocus: false,
        staleTime: 10000,
        cacheTime: Infinity,
        retry: false
    });
};

// const refetchCustomerWallIds_OLD = async (drawer, area, filters) => {
//     const queryname = `${area}${drawer ? '-drawer' : ''}`
//     try {
//         await queryClient.fetchQuery(['customer-wall-ids', queryname], () => fetchCustomersWallIds(filters));
//     } catch (error) {
//         console.log(error)
//     }
// };

const refetchCustomerWallIds = async (filters) => {
    try {
        await queryClient.fetchQuery(['customer-wall-ids'], () => fetchCustomersWallIds(filters));
    } catch (error) {
        console.log(error)
    }
};

const forceReloadCustomersWall = () => {
    queryClient.invalidateQueries(['customer-wall-ids']);
}

//CONTACTS - GENERIC

const fetchContacts = async (_) => (
    await Axios.get('/contacts')
)

const forceReloadContacts = () => queryClient.invalidateQueries(['contacts']);

const prefetchContacts = () => {
    queryClient.prefetchQuery(
        ['contacts'],
        () => fetchContacts(),
        {
            staleTime: 300000,
            cacheTime: Infinity,
            retry: false
        }
    );
};

// const getAllContacts_OLD = () => {
//     const customers = queryClient.getQueryData(['customers', getArea()])
//     const models = queryClient.getQueryData(['models', getArea()])
//     return customers && models ? [...models, ...customers] : [];
// }

const getAllContacts = () => {
    const customers = queryClient.getQueryData(['customers'])
    const models = queryClient.getQueryData(['models'])
    return customers && models ? [...models, ...customers] : [];
}

const getContact = (ID) => {
    const contacts = getAllContacts()
    if (contacts) {
        const c = contacts.find((m) => (m.ID === ID))
        if (c) {
            return c
        }
    }
    return null;
}

const getContacts = ids => {
    const contacts = getAllContacts()
    if (ids && ids.length) {
        return contacts.filter(cont => ids.includes(cont.ID))
    }
    return contacts
}

const getSimpleContacts = () => {
    const contacts = queryClient.getQueryData(['contacts']);
    return contacts ? contacts : [];
}

const getContactName = (ID, asArray) => {
    if (!ID) return '';
    const contactID = typeof ID === "number" ? ID : parseInt(ID);
    const contacts = getSimpleContacts();
    const contact = (contacts || []).find(contact => contact.ID === contactID);
    if (contact) {
        return ((contact.st > 3) ? modelFullName(contact, false, asArray) : customerFullName(contact, false, asArray))
    } else {
        return '';
    }
}


//UTILS
export const getArea = () => {
    const currentArea = ls.get('nw_config_area') ? ls.get('nw_config_area').area : '';
    return currentArea
}



export {
    useModelsList,
    forceReloadModels,
    getModel,
    getModels,
    useCustomersList,
    getCustomer,
    getCustomers,
    forceReloadCustomers,
    getContact,
    getContacts,
    getContactName,
    prefetchModels,
    prefetchCustomers,
    prefetchContacts,
    modelFullName,
    customerFullName,
    forceReloadContacts,
    useModelsWallIds,
    refetchModelWallIds,
    forceReloadModelsWall,
    useModelsWithBooksIds,
    useCustomersWallIds,
    refetchCustomerWallIds,
    forceReloadCustomersWall,
    fetchModelsByArea,
    fetchCustomersByArea,
    getSimpleContacts,
    fetchCustomers,
    usePlaylistsCount,
    prefetchPlaylistsCount,
    validModelInContext,
    validCustomerInContext
}
