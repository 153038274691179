import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const getScoutingTravel = scoutingTravelId => (
    Axios.get(`/scoutingTravels/${scoutingTravelId}`)
);

const useScoutingTravel = scoutingTravelId => {
    return useQuery(["scoutingTravel", scoutingTravelId], () => getScoutingTravel(scoutingTravelId), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: scoutingTravelId ? true : false
    });
};

const forceReloadScoutingTravel = (ID) => {
    queryClient.invalidateQueries(['scoutingTravel', ID])
}

const onDeleteScoutingTravel = ({ ID }) => (
    Axios.delete(`/scoutingTravels/${ID}`)
)

const useDeleteScoutingTravel = () => {
    return useMutation(onDeleteScoutingTravel, {
        onSuccess: (data, variables) => {
            if (variables.ID !== variables.scoutingTravelId) {
                forceReloadScoutingTravel(variables.scoutingTravelId);
            }
        }
    })
}

const onUpdateScoutingTravel = ({ scoutingTravelData }) => (
    Axios.put('/scoutingTravels', scoutingTravelData)
);

const useUpdateScoutingTravel = () => {
    return useMutation(onUpdateScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.scoutingTravelData.ID);
        }
    })
}

const onAddScoutingTravel = ({ scoutingTravelData }) => (
    Axios.post('/scoutingTravels', scoutingTravelData)
);

const useAddScoutingTravel = () => {
    return useMutation(onAddScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(data.ID);
        }
    })
};

const onUnlinkScoutingTravel = ({ ID }) => (
    Axios.put(`/scoutingTravels/UnLink/${ID}`)
);

const useUnlinkScoutingTravel = () => {
    return useMutation(onUnlinkScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.scoutingTravelId);
        }
    })
};

const onCancelScoutingTravel = ({ ID }) => (
    Axios.put(`/scoutingTravels/cancellation/${ID}`)
);

const useCancelScoutingTravel = () => {
    return useMutation(onCancelScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.ID);
        }
    })
};

const onCloneBookersScoutingTravel = ({ data }) => (
    Axios.post(`/scoutingTravels/CloneForBookers`, data)
);

const useCloneBookersScoutingTravel = () => {
    return useMutation(onCloneBookersScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.data.EventID);
        }
    })
};

const onUpdateGroupedIndividualScoutingTravel = ({ scoutingTravelData }) => (
    Axios.put('/scoutingTravels/GroupedIndividual', scoutingTravelData)
);

const useUpdateGroupedIndividualScoutingTravel = () => {
    return useMutation(onUpdateGroupedIndividualScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.scoutingTravelData.ID);
        }
    })
};

const onUpdateGroupedCommonScoutingTravel = ({ scoutingTravelData }) => (
    Axios.put('/scoutingTravels/GroupedCommon', scoutingTravelData)
);

const useUpdateGroupedCommonScoutingTravel = () => {
    return useMutation(onUpdateGroupedCommonScoutingTravel, {
        onSuccess: (data, variables) => {
            forceReloadScoutingTravel(variables.scoutingTravelData.ID);
        }
    })
}

export {
    useScoutingTravel,
    useDeleteScoutingTravel,
    useUpdateScoutingTravel,
    useAddScoutingTravel,
    useUnlinkScoutingTravel,
    forceReloadScoutingTravel,  
    useCancelScoutingTravel,
    useCloneBookersScoutingTravel,
    useUpdateGroupedIndividualScoutingTravel,
    useUpdateGroupedCommonScoutingTravel
};