import React from 'react';
import styled from 'styled-components';
import { solid, duotone, light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';

const DocumentWrapper = styled.div`
    background: ${props => props.$type === 'Video' && 'black'};
    background-image: ${props => props.$src ? `url(${props.$src})` : 'none'};
    height: ${props => props.height}px; 
    background-size: ${props => props.$type === 'Video' ? 'contain' : 'cover'};
    width: unset !important;
    margin-left: 0 !important;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border: ${props => props.$error ? `2px solid ${props.theme.DangerColor}` : (props.$selected ? `2px solid ${props.theme.PrimaryColor}` : 'none')};
    box-shadow: ${props => props.theme.SelectedShadow};

    svg {
        position: absolute;
        color: ${props => props.selected ? props.theme.PrimaryColor : props.theme.PrimaryDarkLight};
        font-size: 1.45rem;
        margin-left: 4px;
        margin-top: 4px;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.PrimaryColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;  
    }
`;

const WatchPanel = styled.div`
    z-index: 78;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .encoding {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: #fff;
        cursor: default;

        h1 {
            font-size: 1.25rem;
            margin: 0;
            font-weight: 500;
            line-height: 1.5em;
            color: #fff;
        }
        small {
            font-size: ${props => props.theme.FontSizeSm};
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 1rem;
        }
        svg {
            position: relative;
            color: #fff;
            font-size: 2rem;
            margin-left: 0;
            margin-top: 0;
        }
    }
`;

const EncodingPanel = styled.div`
    z-index: 78;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    flex-direction: column;
    h1 {
        font-size: 1.25rem;
        margin: 0;
        font-weight: 500;
        line-height: 1.5em;
        color: #fff;
    }
    small {
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 1rem;
    }
    svg {
        position: relative;
        color: #fff;
        font-size: 2rem;
        margin-left: 0;
        margin-top: 0;
    }
`;

const DeleteErrorPanel = styled.div`
    z-index: 78;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5); 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: default;
    flex-direction: column;
    h1 {
        font-size: 1.25rem;
        margin: 0;
        font-weight: 500;
        color: #fff;
        text-align: center;
        padding: .25rem .5rem;
    }
    small {
        font-size: ${props => props.theme.FontSizeSm};
        text-transform: uppercase;
        color: #fff;
        padding: .25rem .5rem 1.5rem .5rem;
        line-height: 1.1em;
        text-align: center;
    }
    svg {
        position: relative;
        color: ${props => props.theme.DangerColor};
        font-size: 2rem;
        margin-left: 0;
        margin-top: 0;
    }
`;

const PlayIconStyle = {
    fontSize: 80,
    color: StyleVariables.TextColor
};

const MediaCover = ({ type, selected, encoding, selectable, deleteError, src, ...rest }) => {
    
    const DeleteErrorMessage = () => {
        const mediatype = type ? (type.toLowerCase() === "video" ? "video" : 'picture') : 'picture';
        if (deleteError === 'DocumentInPlaylist') {
            return (
                <small>This {mediatype} is in a book and can't be deleted<br/>Please remove it from the book first</small>
            )
        }
        return <small>{deleteError}</small>
    }

    return (
        <DocumentWrapper
            $type={type}
            $selected={selected}
            $error={deleteError}
            $src={src}
            {...rest}>
            {(selected || selectable) &&
                <NwIcon icon={selected ? duotone("check-circle") : solid("check-circle")} />
            }
            {type === 'Video' &&
                (encoding
                    ?
                    <EncodingPanel>
                        <h1>Encoding video</h1>
                        <small>Please wait...</small>
                        <NwIcon icon={light("camera-movie")} beat />
                    </EncodingPanel>
                    :
                    <WatchPanel>
                        <NwIcon
                            icon={solid("play-circle")}
                            style={PlayIconStyle} />
                    </WatchPanel>
                )
            }
            {deleteError &&
                <DeleteErrorPanel>
                    <h1>Delete error</h1>
                    <NwIcon icon={solid("exclamation-triangle")} />
                    <DeleteErrorMessage />
                </DeleteErrorPanel>
            }
        </DocumentWrapper >
    )
};

export default MediaCover;