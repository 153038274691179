import React, { useState, useMemo, useContext } from 'react'
import styled from 'styled-components';
import { withRouter } from "react-router";
import _ from "lodash";
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard, NwCardContent } from 'Components/Gui/NwCard/NwCard';
import { NwCardTabsContent } from 'Components/Gui/NwCard/NwCardTabs';
import { useStateValue } from 'Contexts/StateProvider';
import { sliceData } from 'Libs/NwUtils';
import NwUpload from "Components/Gui/NwUpload";
import EmptyState from 'Components/Gui/EmptyState';
import MediaDrawer from 'Components/MediaDrawer/MediaDrawer';
import { getUrlDocument } from "Libs/NwUtils";
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
//import { useContact } from "Hooks/Contact/UseContact";
import { onContactMediaUpdate } from 'Hooks/Contact/UseContactMedia';
import { Loading } from 'Components/Gui/Loading';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
// import NwObjectCounter from 'Components/Gui/NwObjectCounter';
import { NWList, NWListItem } from "Components/Gui/NWList";
import CustomSwitch from 'Components/Gui/CustomSwitch';
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import Document from './Document';
import { UserContext, useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const Wrapper = styled.div`
    height: 100%;
    position: relative;
    .ant-card-body-content {
        padding: 0 !important;
        justify-content: ${props => !props.viewModelNotes && 'center !important'}
    }
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    .tabs-content {
        justify-content: ${props => !props.viewModelNotes && 'center'}
    }
`;

const ActionButtonsDropdown = styled.div`
  text-align: right;
`;

const Description = styled.div`
    font-size: .95rem;
`;

const DocAuthorText = styled.div`
  font-size: .6rem;
  text-transform: uppercase;
  margin-top: .3em;
`;

const LockContainer = styled(FlexContainer)`
    height: 100%;
    padding: 32px 0;
    svg {
        font-size: ${props => props.$readOnly ? '80px' : '120px'};
    }
`;

const ContactDetailsDocuments = ({ currentContact, title, disableRating, isInCardTab, readOnly }) => {
    const itemsPerPage = 4;
    const [activePage, setActivePage] = useState(1)
    const [{ areaContext }] = useStateValue();
    const { currentUser } = useContext(UserContext);
    const [documentId, setDocumentId] = useState(null)
    const [isDocumentDrawer, setIsDocumentDrawer] = useState(false)
    const [isShowComment, setIsShowComment] = useState(false)
    const [isReloading, setIsReloading] = useState(false);
    const [filterTags, setTags] = useState([]);
    const [onlyMine, setOnlyMine] = useState(false);
    const breakpoints = useNwBreakPoints();
    //const { data: currentContact } = useContact(contactID);
    
    const viewClientFiles = useUserPermission('ViewClientFiles', currentContact && currentContact.Users.map(user => user.UserID));
    const viewAgencyFiles = useUserPermission('ViewAgencyFiles', currentContact && currentContact.Users.map(user => user.UserID));
    const viewServiceFiles = useUserPermission('ViewServiceFiles', currentContact && currentContact.Users.map(user => user.UserID));
    const viewModelFiles = useUserPermission('ViewModelFiles', currentContact && currentContact.Users.map(user => user.UserID));

    const viewFiles = useMemo(() => {
        if (currentContact) {
            if (currentContact.SubType === 1) return viewClientFiles;
            if (currentContact.SubType === 2) return viewAgencyFiles;
            if (currentContact.SubType === 3) return viewServiceFiles;
            return viewModelFiles;
        }
        return false;
    }, [currentContact, viewClientFiles, viewAgencyFiles, viewServiceFiles, viewModelFiles]);

    const handlePageChange = (page) => {
        setActivePage(page)
    }

    const sortedDocuments = useMemo(() => {
        let documentList = currentContact ? currentContact.Files.filter(document => {
            if (document.Visibility.Areas.length === 0 || document.Visibility.Areas.find(area => area === areaContext.area)) {
                return true;
            }
            return false;
        }) : [];

        documentList = documentList.filter(book => {
            let filtered = true;
            filterTags.map(filterTag => {
                if (!book.Tags.find(tag => tag.Name === filterTag)) {
                    filtered = false;
                }
                return null;
            });
            if (filtered) {
                return true;
            }
            return false;
        });

        documentList = documentList.filter(book => (!onlyMine || book.UserCreated === currentUser.UserID));

        _.sortBy(documentList, 'ID').reverse();
        return documentList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact, filterTags, onlyMine, currentUser]);

    const documentsToRender = breakpoints === 1 ? sortedDocuments : sliceData(activePage, itemsPerPage, sortedDocuments);

    const onEditDocument = document => {
        setDocumentId(document.ID);
        setIsDocumentDrawer(true);
    };

    const document = sortedDocuments && sortedDocuments.find(item => item.ID === documentId);

    const RenderUploadButton = () => (
        <NwUpload
            maxSize={20}
            showList={false}
            disable={!viewFiles}
            types={
                [
                    "document",
                    "image",
                    "data-document",
                    "office-document",
                    "archive-document",
                    "text-document"
                ]
            }
            onUploadStart={() => { }}
            onUploadEnd={() => {
                setIsReloading(true)
                onContactMediaUpdate(currentContact.ID, () => setIsReloading(false));
            }}
            endpoint={`documents/uploadsingle/1?contactID=${currentContact.ID}`}
        />
    );

    const handleShowDocumentDrawer = (document) => {
        setDocumentId(document.ID);
        setIsDocumentDrawer(true);
    };

    const onCloseDrawer = () => {
        setIsDocumentDrawer(false);
        setIsShowComment(false);
    };

    const selectMediaBefore = () => {
        const findIndex = sortedDocuments.findIndex(document => document.ID === documentId);
        if (findIndex > 0) {
            setDocumentId(sortedDocuments[findIndex - 1].ID);
        }
    };

    const selectMediaAfter = () => {
        const findIndex = sortedDocuments.findIndex(document => document.ID === documentId);
        if (findIndex > -1 && findIndex < sortedDocuments.length - 1) {
            setDocumentId(sortedDocuments[findIndex + 1].ID);
        }
    };

    const OnSelectCover = () => { };

    const onDeleteDocument = document => {
        Axios.delete(`/documents/?ids=${document.ID}`)
            .then(() => {
                if (onContactMediaUpdate) {
                    onContactMediaUpdate(currentContact.ID);
                }
            })
            .catch(error => {
                console.log("Errore", error);
            });
    }

    const onOpenDocument = document => {
        window.open(getUrlDocument(document), "_blank");
    };

    const getDescription = (document) => {
        // const onOpenDocumentComments = () => {
        //     setDocumentId(document.ID);
        //     setIsShowComment(true);
        //     setIsDocumentDrawer(true);
        // };

        return (<Description>
            <DocAuthorText><CreatedBy object={document} short textonly /></DocAuthorText>
            {/* <NwObjectCounter count={document.Comments.length} label="comments" onClick={onOpenDocumentComments} /> */}
        </Description>)
    }

    const actions = (document) => {
        return (
            <ActionButtonsDropdown>
                <NwDropDownMenu
                    actions={[
                        { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => onEditDocument(document)) },
                        { name: "", key: "download", pinned: true, icon: light("cloud-download"), action: (() => onOpenDocument(document)) },
                        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => onDeleteDocument(document)) }
                    ]}
                />
            </ActionButtonsDropdown>
        );
    }

    const cardContent = () => (
        viewFiles ?
        <>
            {(documentsToRender && (documentsToRender.length > 0)) ?
                <NwCardContent
                    pagination={breakpoints === 1 ? null : { total: sortedDocuments.length, current: activePage, pageSize: itemsPerPage, onChange: handlePageChange }}
                >
                    {isReloading ?
                        <Loading />
                        :
                        <NWList>
                            {documentsToRender.map(document => (
                                <NWListItem
                                    actions={!readOnly ? actions(document) : null}
                                    key={document.ID}
                                    renderContent={() => (
                                        <Document
                                            document={document}
                                            handleShowDocumentDrawer={handleShowDocumentDrawer}
                                            getDescription={getDescription}
                                            actions={!readOnly && actions}
                                            onEditDocument={onEditDocument}
                                        />
                                    )}
                                />
                            ))}
                        </NWList>
                    }
                </NwCardContent>
                : <EmptyState message="no documents" />
            }
        </>
        :
        <LockContainer $readOnly={readOnly} justifyContent='center' alignItems='center'>
            <NwIcon icon={light('lock-alt')} />
        </LockContainer>
    )

    const handleSetTags = (name, tags) => {
        setTags(tags);
    };

    const tags = useMemo(() => {
        let tags = [];
        if (currentContact)
        for (const file of currentContact.Files) {
            for (const tag of file.Tags) {
                if (!tags.find(item => item === tag.Name)) {
                    tags.push(tag.Name);
                }
            }
        }
        return tags;
    }, [currentContact]);

    const renderFilters = () => {
        return (breakpoints === 1 ?
            <FlexContainer fullWidth column justifyContent='space-between'>
                <FlexContainer mg='0 0 8px'>
                    <FilterTagSelector selectedOptions={filterTags} tags={tags} onChange={handleSetTags} />
                </FlexContainer>
                <CustomSwitch checked={onlyMine} onChange={setOnlyMine} text='only mine' />
            </FlexContainer>
            : <FlexContainer fullWidth justifyContent='space-between'>
                <FilterTagSelector selectedOptions={filterTags} tags={tags} onChange={handleSetTags} />
                <CustomSwitch checked={onlyMine} onChange={setOnlyMine} text='only mine' />
            </FlexContainer>
        )
    };
    
    const getActiveFilters = () => {
        let filters = [];
        if (filterTags.length) {
            for (const tag of filterTags) {
                filters.push(tag);
            }
        }
        if (onlyMine) {
            filters.push('Mine only');
        }
        return filters;
    }

    if (!currentContact) {
        return <div />
    }

    if (isInCardTab) {
        return (
            <Wrapper>
                <NwCardTabsContent
                    filtersContent={viewFiles && renderFilters()}
                    activeFilters={viewFiles && getActiveFilters()}
                >
                    {cardContent()}
                </NwCardTabsContent>
                {isDocumentDrawer && document &&
                    <MediaDrawer
                        mediaID={document.ID}
                        disableRating={disableRating}
                        contactID={currentContact.ID}
                        isShowComment={isShowComment}
                        onCloseDrawer={onCloseDrawer}
                        onSelectMediaBefore={selectMediaBefore}
                        onSelectMediaAfter={selectMediaAfter}
                        onMediaUpdate={onContactMediaUpdate}
                        onSelectCover={OnSelectCover} 
                        afterNewMediaCreation={onCloseDrawer}
                    />
                }
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <NwCard
                    title={title || "Files"}
                    icon={light("file")}
                    fullheight
                    padding='reduced'
                    filtersContent={viewFiles && renderFilters()}
                    activeFilters={viewFiles && getActiveFilters()}
                    extra={RenderUploadButton()}
                >
                    {cardContent()}
                </NwCard>
                {isDocumentDrawer && document &&
                    <MediaDrawer
                        mediaID={document.ID}
                        disableRating={disableRating}
                        contactID={currentContact.ID}
                        isShowComment={isShowComment}
                        onCloseDrawer={onCloseDrawer}
                        onSelectMediaBefore={selectMediaBefore}
                        onSelectMediaAfter={selectMediaAfter}
                        onMediaUpdate={onContactMediaUpdate}
                        onSelectCover={OnSelectCover} 
                        afterNewMediaCreation={onCloseDrawer}
                    />
                }
            </Wrapper>
        )
    }
}

export default withRouter(ContactDetailsDocuments)
