import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

// import { NwCardTabs } from 'Components/Gui/NwCard/NwCardTabs';
import PlayListInfoForm from 'Components/MediaDrawer/PlayListInfoForm';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDrawer from 'Components/Gui/NwDrawer';
import { useUserPermission } from 'Contexts/UserContext';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import ResponsiveFlexContainer from 'Components/Gui/ResponsiveFlexContainer';

const DetailsContainer = styled.div`
    flex: 1 1 0;
`
const PlaylistDetails = ({ playlist, isEdit, onUpdatePlaylists, onDeletePlayList }) => {
    //const [activeTab, setActiveTab] = useState('meta');
    const [editMode, setEditMode] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const userPermission = useUserPermission('DeleteBooks', [playlist.UserCreated]);

    useEffect(() => {
        if (isEdit) {
            setEditMode(true);
        }
    }, [isEdit]);


    const renderContent = () => {
        return (
            <FlexContainer alignItems='flex-start' justifyContent='space-between' fullWidth>
                <DetailsContainer>
                    <ResponsiveFlexContainer
                        leftMinWidth={140}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Name</p>
                                ),
                                isBorder: true,
                                width: "30%",
                            },
                            {
                                content: (
                                    <p className="is-content">{playlist.Name}</p>
                                ),
                                isBorder: false,
                            }
                        ]}
                    />
                    <ResponsiveFlexContainer
                        leftMinWidth={140}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Privacy Level</p>
                                ),
                                isBorder: true,
                                width: "30%",
                            },
                            {
                                content: (
                                    <p className="is-content">{playlist.PrivacyLevelName}</p>
                                ),
                                isBorder: false,
                            }
                        ]}
                    />
                    {/* <ResponsiveFlexContainer
                        leftMinWidth={140}
                        columns={[
                            {
                                content: (
                                    <p className="is-label">Published</p>
                                ),
                                isBorder: true,
                                width: "30%",
                            },
                            {
                                content: (
                                    <p className="is-content">{playlist.IsPublished ? 'true' : 'false'}</p>
                                ),
                                isBorder: false,
                            }
                        ]}
                    /> */}
                </DetailsContainer>
                <NwDropDownMenu
                    actions={[
                        { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => setEditMode(true) },
                        { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", disabled: !userPermission, action: (() => onDeletePlayList()) }
                    ]}
                />
            </FlexContainer>
        )
    }

    return (
        <NwCard
            title="Info"
            icon={light("info-square")}
        >
            {renderContent()}
            {editMode &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Book'
                    onClose={() => setEditMode(false)}>
                    <PlayListInfoForm
                        playlist={playlist}
                        onUpdatePlaylists={onUpdatePlaylists}
                        onTouchForm={setTouchForm}
                        onClose={() => setEditMode(false)} />
                </NwDrawer>
            }
        </NwCard>
    )
};

export default PlaylistDetails;