import React, { useState, useEffect } from 'react';
import { DatePicker, Row, Col } from 'antd';

import Switch from 'Components/Gui/CustomSwitch';

const DisableDatePicker = ({ displayLabel, value, defaultDate, onChange }) => {
    const [disableEvent, setDisableEvent] = useState(true);

    useEffect(() => {
        if (value) {
            setDisableEvent(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (date, dateString) => {
        onChange(date);
    }

    const handleChangeSwitch = value => {
        setDisableEvent(value);
        if (value) {
            onChange(null);
        }
    }

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Switch value={disableEvent} text={displayLabel} onChange={handleChangeSwitch} />
            </Col>
            <Col span={12}>
                {!disableEvent && 
                    <DatePicker
                        autoComplete='off'
                        placeholder={'Select a date'}
                        defaultValue={value || defaultDate} 
                        onChange={handleChange}
                        allowClear={false}
                        format="ddd D MMM YY"
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                    />
                }
            </Col>
        </Row>
    )
};

export default DisableDatePicker;