import React, { useState } from "react";

import ModelAgencyForm from "Components/ModelAgencies/ModelAgencyForm";
import NwDrawer from "Components/Gui/NwDrawer";

const ModelAgenciesDrawer = ({ 
    agencyID,
    contactID,
    currentModelAgency, 
    excludedModels,
    exclusions, 
    motherExist, 
    onClose, 
    represented,
    viewMode, 
}) => {
    const [touchForm, setTouchForm] = useState(false);
    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={viewMode === 'INSERTING' ? 'Add Model Agency' : "Edit Model Agency"}
        >
            {viewMode === "INSERTING" && (
                <ModelAgencyForm
                    exclusions={exclusions}
                    contactID={contactID}
                    agencyID={agencyID}
                    hideMother={motherExist} // || represented
                    excludedModels={excludedModels}
                    onCancel={onClose}
                    onSave={onClose}
                    onTouchForm={setTouchForm}
                />
            )}
            {viewMode === "EDITING" && (
                <ModelAgencyForm
                    hideMother={motherExist}  //|| represented
                    contactID={contactID}
                    agencyID={agencyID}
                    exclusions={[]}
                    excludedModels={excludedModels}
                    ModelAgency={currentModelAgency}
                    onCancel={onClose}
                    onSave={onClose}
                    onTouchForm={setTouchForm}
                />
            )}
        </NwDrawer>
    );
};

export default ModelAgenciesDrawer;
