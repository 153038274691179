import React, { useState } from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from "Components/Gui/NwButton";
import FeaturesDrawer from "Components/Features/FeaturesDrawer";
import MainFeatures from 'Components/Features/MainFeatures';
import { forceReloadContact } from "Hooks/Contact/UseContact";
import { useUserPermission } from 'Contexts/UserContext';
import PermissionBlock from "Components/PermissionBlock";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const ModelMainFeaturesContainer = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: left;
    align-items: ${props => props.$mobileView ? 'flex-start' : 'center'};
    flex-direction: ${props => props.$mobileView && 'column'};
    flex-wrap: wrap;
    min-height: 3em;
    dl {
      border-right: ${props => !props.$mobileView && props.theme.DefaultBorder};
      border-bottom: ${props => props.$mobileView && props.theme.DefaultBorder};
      padding: ${props => props.$mobileView ? 0 : '.25rem .5rem'};
      margin-bottom: 0;

      display: flex;
      flex-direction: ${props => props.$mobileView ? 'row' : 'column'};  
      align-items: ${props => props.$mobileView ? 'center' : 'flex-start'};

      &:last-of-type {
        border-right: ${props => !props.$hasFeatureFilter && 'none'};
      }

      &:first-of-type {
        padding-left: ${props => !props.$multiLine && 0};
      }

      dt {
        font-weight: normal;
        font-size: ${props => props.$mobileView ? '.9rem' : '.65rem'};
        display: block;
        text-transform: uppercase;
        color: #000;
      }
      dd {
        display:block;
        font-size: .9rem;
        margin-bottom: ${props => props.$mobileView && 0};
        margin-left: ${props => props.$mobileView && '4px'};
        font-weight: bold;
        text-transform: uppercase;
      }
    }
`

const ModelDetailsHeaderFeatures = ({ contact, readOnly, multiline, hasFeatureFilter }) => {
    const [editFeatures, setEditFeatures] = useState(false);
    const viewModelFeatures = useUserPermission('ViewModelFeatures', contact && contact.Users.map(user => user.UserID));
    const breakpoints = useNwBreakPoints();

    const onCloseFeatureDrawer = () => {
      //forceReloadContact(contact.ID)
      setEditFeatures(false)
    } 

    return (
        <div>
            <PermissionBlock
                permission={viewModelFeatures}
                content={
                    <ModelMainFeaturesContainer 
                        $mobileView={breakpoints === 1}
                        $hasFeatureFilter={hasFeatureFilter} 
                        $multiLine={multiline} 
                        onClick={() => viewModelFeatures && setEditFeatures(true)}>
                        <MainFeatures contact={contact} empty={readOnly ? 'no features' : <NwButton style={{opacity:.4}} ghost icon={light("pen")} size="lg" onClick={() => setEditFeatures(true)} label="edit measurements and sizes" />} />
                    </ModelMainFeaturesContainer>
                } />
            {editFeatures && (
                <FeaturesDrawer contactID={contact.ID} readOnly={readOnly} onClose={onCloseFeatureDrawer} />
            )}
        </div>
    );
};

export default ModelDetailsHeaderFeatures;
