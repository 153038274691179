import React, { useState, useEffect, useContext } from 'react';
import { Spin } from 'antd';
import styled from "styled-components";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import NoteModal from 'Components/PackageDrawer/PackageModelCard/NoteModal';
import GroupModal from 'Components/PackageDrawer/PackageGroupModals/GroupModal';
// import NwEllipsis from 'Components/Gui/NwEllipsis';
import AgendaDrawer from 'Components/Schedule/Agenda/AgendaDrawer';
// import FullFeatures from 'Components/Features/FullFeatures';
import PackageModelPlaylists from 'Components/PackageDrawer/PackageModelCard/PackageModelPlaylists';
import FlexContainer from 'Components/Gui/FlexContainer';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import NwDrawer from 'Components/Gui/NwDrawer';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { NwButton } from 'Components/Gui/NwButton';
import ModelDetailsBooks from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooks';
import { useAddPlaylistToPackage, useDeletePlaylistFromPackage } from 'Hooks/Packages/UsePackages';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { SortableHandle } from 'react-sortable-hoc';
import AddMediaDrawer from './AddMediaDrawer/AddMediaDrawer';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { PackageContext } from '../PackageContext';
import Axios from 'axios';

const DragContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 24px;
    background: white;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    .package-model-draggable-handler {
        margin: auto 1rem auto 0;
        font-size: 1rem;
    }
    h1 {
        margin: auto 0;
        font-size: 1.1rem;
    }
`

const PackageModelCard = ({
    compact,
    isSort,
    loading,
    model,
    readOnly,
    smallView,
    selectedModels,
    setSelectedModels
}) => {
    const [isOpenNote, setIsOpenNoteModal] = useState(false);
    const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
    const [isAgendaDrawer, setIsAgendaDrawer] = useState(false);
    const [playlists, setPlaylists] = useState([]);
    const [isOpenPreview, setIsOpenPreview] = useState(false);
    const [showBooks, setShowBooks] = useState(false);
    const [isMediaDrawer, setIsMediaDrawer] = useState(false);
    const breakpoints = useNwBreakPoints();
    const { mutateAsync: onAddPlaylistToPackage } = useAddPlaylistToPackage();
    const { mutateAsync: onDeletePlaylistFromPackage } = useDeletePlaylistFromPackage();

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage} = packageContextValue;
    const { ID: packageId, GroupNames } = groupedPackage;

    useEffect(() => {
        if (model) {
            setPlaylists(model.Playlists);
        }
    }, [model]);

    const handleUpdatePlaylistOrder = playlists => {
        setPlaylists(playlists);
    };

    const handleOpenNote = () => {
        setIsOpenNoteModal(true);
    };

    const handleCloseNote = (isUpdate, note) => {
        if (isUpdate) {
            afterUpdatePackage();
        }
        setIsOpenNoteModal(false);
    };

    const handleCloseAgendaDrawer = () => {
        setIsAgendaDrawer(false);
    };

    const handleOpenGroupModal = () => {
        setIsOpenGroupModal(true);
    };

    const handleCloseGroupModal = () => {
        setIsOpenGroupModal(false);
    };

    const handleViewModel = () => {
        setIsOpenPreview(true);
    };

    const handleClosePreview = () => {
        setIsOpenPreview(false);
    };

    const handleAddBook = async book => {
        const data = {
            packageID: packageId,
            playlistID: book.ID,
            packageGroup: model.Group
        }
        try {
            await onAddPlaylistToPackage({
                data,
                isActivePackage: true
            });
        } catch (error) {
            console.log('ant : Add Playlist To Package Error => ', error);
        }
    };

    const handleDeleteBook = async book => {
        const playlist = model.Playlists.find(playlist => playlist.PlaylistID === book.ID);
        try {
            await onDeletePlaylistFromPackage({ packageID: packageId, packagePlaylistId: playlist.ID, isActivePackage: true });
            //afterUpdatePackage()
        } catch (error) {
            console.log('ant : Delete Playlist To Package Error => ', error);
        }
    }

    const handleAddBooks = () => {
        setShowBooks(true);
    };

    const handleCloseBooksDrawer = () => {
        setShowBooks(false);
    };

    const handleAddMedia = () => {
        setIsMediaDrawer(true);
    }

    const handleCloseAddMediaDrawer = () => {
        setIsMediaDrawer(false);
    }

    const removeModel = async () => {
        await Axios.delete(`/packagemodels/delete?ModelIDs=${model.ID}`)
        setSelectedModels([])
        afterUpdatePackage()
    }

    const ExtraContent = () => {
        if (compact) {
            const modelactions = [
                { name: "add books", key: "addbooks", pinned: true, icon: light("circle-plus"), action: () => handleAddBooks() },
                { name: "add media", key: "addmedia", pinned: false, icon: light("circle-plus"), action: () => handleAddMedia() },
                { name: "edit note", key: "editnote", pinned: false, icon: light("message-pen"), action: () => handleOpenNote() },
                { name: "change group", key: "changegroup", pinned: false, icon: light("shuffle"), action: () => handleOpenGroupModal() },
                { name: "model details", key: "modeldetails", pinned: false, icon: light("circle-plus"), action: () => handleViewModel() },
                { name: "remove model", key: "removemodel", pinned: false, icon: light("user-xmark"), action: () => removeModel() },
            ]
            return (
                <NwDropDownMenu actions={modelactions} />
            )
        }
        return (
            <FlexContainer>
                {model && !readOnly &&
                    <NwButton ghost icon={light("message-pen")} label={breakpoints > 2 ? "edit note" : "note"} primary lowercase onClick={handleOpenNote} />
                }
                {GroupNames && !readOnly &&
                    <NwButton ghost icon={light("shuffle")} label={breakpoints > 2 ? "change group" : "group"} primary lowercase onClick={handleOpenGroupModal} />
                }
                <NwButton ghost icon={light("circle-plus")} label={breakpoints > 2 ? "add books" : "books"} primary lowercase onClick={handleAddBooks} />
                <NwButton
                    ghost
                    icon={light("circle-plus")}
                    label={(breakpoints > 2) ? "add media" : "media"}
                    primary
                    lowercase
                    onClick={handleAddMedia}
                    disabled={playlists.findIndex(item => item.Type.toLowerCase() === "media") >= 0 ? true : false}
                />
                <NwButton ghost icon={light('sidebar-flip')} label={breakpoints > 2 ? "model details" : "details"} primary lowercase onClick={handleViewModel} />
                <NwButton ghost icon={light('user-xmark')} label={breakpoints > 2 ? "remove model" : "remove"} primary lowercase onClick={removeModel} />
            </FlexContainer>
        )
    }

    const DragHandle = SortableHandle(() => (
        <NwIcon icon={light('sort-alt')} />
    ));

    if (isSort)
        return (
            <DragContainer>
                <div className="package-model-draggable-handler"><DragHandle /></div>
                <h1>{getContactName(model.ModelID)}</h1>
            </DragContainer>
        );

    const modelSelectionProps = {
        onSelect: () => { 
            setSelectedModels([...selectedModels, model.ID]) 
        },
        onDeselect: () => { 
            setSelectedModels(selectedModels.filter(id => id !== model.ID)) 
        },
        isSelected: selectedModels.includes(model.ID)
    }

    return (
        <Spin spinning={loading || isUpdatingOrder}>
            <NwCard
                selection={modelSelectionProps}
                title={getContactName(model.ModelID)}
                extra={<ExtraContent />}
            >
                {loading
                    ?
                    <></>
                    :
                    <div className="package-drawer-model-card-content">
                        {model.Note &&
                            <p className="package-drawer-model-note">{model.Note}</p>
                        }
                        <FlexContainer wrap='wrap' pd='0.5rem 1rem'>
                            <PackageModelPlaylists
                                contactID={model.ModelID}
                                onSetIsUpdatingOrder={setIsUpdatingOrder}
                                onUpdatePlaylistOrder={handleUpdatePlaylistOrder}
                                packageModelId={model.ID}
                                playlists={playlists}
                                readOnly={readOnly}
                                smallView={smallView}
                            />
                        </FlexContainer>
                    </div>
                }
            </NwCard>
            {isOpenNote &&
                <NoteModal
                    packageModel={model}
                    isShowModal={isOpenNote}
                    onClose={handleCloseNote} />
            }
            {isOpenGroupModal &&
                <GroupModal
                    packageModelIDs={[ model.ID ]}
                    show={isOpenGroupModal}
                    onClose={handleCloseGroupModal}
                    currentGroup={model.Group}
                    clearSelected={() => setSelectedModels([])}
                />
            }
            {showBooks &&
                <NwDrawer
                    title='Model Books'
                    onClose={handleCloseBooksDrawer}>
                    <ModelDetailsBooks
                        isInCardTab
                        readOnly
                        packageEditView
                        contactID={model.ModelID}
                        addedPlaylists={model.Playlists.map(playlist => playlist.PlaylistID)}
                        onAddBook={handleAddBook}
                        onRemoveBook={handleDeleteBook}
                    />
                </NwDrawer>
            }
            {isAgendaDrawer &&
                <AgendaDrawer modelId={model.ModelID} onClose={handleCloseAgendaDrawer} />
            }
            {isOpenPreview &&
                <ModelDrawer modelId={model.ModelID} onClose={handleClosePreview} />
            }
            {isMediaDrawer &&
                <AddMediaDrawer
                    onClose={handleCloseAddMediaDrawer}
                    modelId={model.ModelID}
                    packageId={packageId}
                    packageGroup={model.Group}
                />
            }
        </Spin>
    );
};

export default PackageModelCard;