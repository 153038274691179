import React, { useContext, useMemo } from 'react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';
import { ListContext } from 'Contexts/ListContext';
import useEventStyle from 'Hooks/UseEventStyle';
import NwIcon from 'Components/Gui/NwIcon';
import { useUserPermission } from 'Contexts/UserContext';

const ButtonStyle = {
    fontSize: 11,
    padding: '2px 12px',
    height: 26
};

const AddEventsButtons = ({ onCreateEvent, travelPlan, general }) => {
    const { cachedList } = useContext(ListContext);
    const meetingStyle = useEventStyle({}, "Meeting");
    const travelStyle = useEventStyle({}, "Travel");
    const externalJobStyle = useEventStyle({}, "ExtJob");
    const followupStyle = useEventStyle({}, "FollowUp");
    const noticeStyle = useEventStyle({}, "Notice");
    const accommodationStyle = useEventStyle({}, "Accommodation");
    const roomLodgingStyle = useEventStyle({}, "RoomLodging");
    const { eventSubtypes, followUpLabels } = cachedList;


    const canCreateEvent = useUserPermission('CreateBookingEvent');
    const canCreateTravel = useUserPermission('CreateTravels');
    const canCreateFollowUp = useUserPermission('CreateActivities');
    const canCreateMeeting = useUserPermission('CreateMeetings');
    //TODO: implement policy
    const canCreateAccommodation = true
    
    const permissionTooltipText = 'Not enough permissions to create';

    const { castingTypes, jobTypes, defaultCastingType, defaultJobType } = useMemo(() => {
        const castingTypes = eventSubtypes.items.filter(item => item.Type === 'Casting' && !item.Disabled);
        const jobTypes = eventSubtypes.items.filter(item => item.Type === 'Job' && !item.Disabled);
        const defaultCastingType = castingTypes.find(item => item.Default);
        const defaultJobType = jobTypes.find(item => item.Default);

        return {
            castingTypes,
            jobTypes,
            defaultCastingType: defaultCastingType ? defaultCastingType : castingTypes[0],
            defaultJobType: defaultJobType ? defaultJobType : jobTypes[0]
        };
    }, [eventSubtypes]);

    let defaultFollowUp = followUpLabels.items.find(followUpLabel => followUpLabel.Default);
    defaultFollowUp = defaultFollowUp ? defaultFollowUp : followUpLabels[0];
    defaultFollowUp = {
        type: 'FollowUp',
        label: defaultFollowUp ? defaultFollowUp.Label : '',
        icon: followupStyle && followupStyle.icon,
        disabled: !canCreateFollowUp,
        tooltip: !canCreateFollowUp ? `${permissionTooltipText} activities` : null,
        eventInfo: {
            followUpLabelCode: defaultFollowUp ? defaultFollowUp.Code : ''
        }
    }

    let eventTypes = followUpLabels.items.filter(item => !item.Default && !item.Disabled).map(followUpLabel => {
        return {
            type: 'FollowUp',
            label: followUpLabel.Label,
            icon: followupStyle && followupStyle.icon,
            disabled: !canCreateFollowUp,
            tooltip: !canCreateFollowUp ? `${permissionTooltipText} activities` : null,
            eventInfo: {
                followUpLabelCode: followUpLabel.Code
            }
        }
    });

    const renderEventSubType = type => {
        return (
            <FlexContainer>
                <NwIcon
                    icon={duotone('plus-circle')}
                    style={{ '--fa-primary-color': `#${type.ForeColor}`, '--fa-secondary-color': `#${type.BackColor}`, '--fa-primary-opacity': '1', '--fa-secondary-opacity': '1', marginRight: '4px' }}
                // swapOpacity
                />
                {type.Name}
            </FlexContainer>
        )
    }

    const renderEventType = type => {
        return (
            <FlexContainer>
                <NwIcon
                    icon={type.icon}
                    style={{ '--fa-primary-opacity': '1', '--fa-secondary-opacity': '1', marginRight: '4px' }}
                />
                {type.label}
            </FlexContainer>
        )
    }

    const handleCreateBookingEvent = type => {
        onCreateEvent({
            type: 'Event',
            eventInfo: {
                type: type.Type,
                subTypeCode: type.Code
            }
        });
    };

    const handleCreateEvent = type => {
        onCreateEvent(type);
    };

    const followUpEventTypes = eventTypes.map(eventType => {
        return { 
            name: renderEventType(eventType), 
            key: `event-${eventType.label}`, 
            action: (() => handleCreateEvent(eventType)),
            disabled: !canCreateFollowUp,
            tooltip: !canCreateFollowUp ? `${permissionTooltipText} activities` : null
        }
    });

    if (general) {
        followUpEventTypes.push({
            name: renderEventType({
                ...noticeStyle,
                label: 'Notice'
            }),
            key: 'Notice',
            action: () => handleCreateEvent({ type: 'Notice' })
        });
    }

    return (
        travelPlan
            ?
            <>
                <NwButton 
                    label='Travel' 
                    size='small' 
                    icon={travelStyle.icon} 
                    style={ButtonStyle}
                    disabled={!canCreateTravel}
                    tooltip={!canCreateTravel ? `${permissionTooltipText} travel events` : null}
                    onClick={() => handleCreateEvent({ type: 'Travel' })} 
                />
                &nbsp;
                <NwButton 
                    label='Meeting' 
                    size='small' 
                    icon={meetingStyle && meetingStyle.icon} 
                    style={ButtonStyle}
                    disabled={!canCreateMeeting}
                    tooltip={!canCreateMeeting ? `${permissionTooltipText} meeting events` : null}
                    onClick={() => handleCreateEvent({ type: 'Meeting' })} 
                />
                &nbsp;
                <NwButton 
                    label='Accommodation' 
                    size='small' 
                    icon={accommodationStyle && accommodationStyle.icon} 
                    style={ButtonStyle}
                    disabled={!canCreateAccommodation}
                    tooltip={!canCreateAccommodation ? `${permissionTooltipText} accommodation events` : null}
                    onClick={() => handleCreateEvent({ type: 'Accommodation' })} 
                />
            </>
            :
            <>
                <NwDropDownMenu
                    size="small"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    actions={[
                        { 
                            name: renderEventSubType(defaultCastingType), 
                            key: defaultCastingType.Code, 
                            pinned: true,
                            disabled: !canCreateEvent,
                            tooltip: !canCreateEvent ? `${permissionTooltipText} booking events` : null,
                            action: (() => handleCreateBookingEvent(defaultCastingType)) 
                        },
                        ...castingTypes.filter(type => type.Code !== defaultCastingType.Code).map(type => 
                            {
                                return { 
                                    name: renderEventSubType(type), 
                                    key: `casting-${type.Code}`,
                                    disabled: !canCreateEvent,
                                    tooltip: !canCreateEvent ? `${permissionTooltipText} booking events` : null,
                                    action: (() => handleCreateBookingEvent(type)) 
                                }
                            }
                        )
                    ]}
                />
                &nbsp;
                <NwDropDownMenu
                    size="small"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    actions={[
                        { 
                            name: renderEventSubType(defaultJobType),
                            disabled: !canCreateEvent,
                            tooltip: !canCreateEvent ? `${permissionTooltipText} booking events` : null,
                            key: defaultJobType.Code, 
                            pinned: true, 
                            action: (() => handleCreateBookingEvent(defaultJobType)) 
                        },
                        ...jobTypes.filter(type => type.Code !== defaultJobType.Code).map(type => 
                            {
                                return { 
                                    name: renderEventSubType(type),
                                    key: `job-${type.Code}`,
                                    disabled: !canCreateEvent,
                                    tooltip: !canCreateEvent ? `${permissionTooltipText} booking events` : null,
                                    action: (() => handleCreateBookingEvent(type)) 
                                }
                            }
                        )
                    ]}
                />
                &nbsp;
                <NwDropDownMenu
                    size="small"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    actions={[
                        {
                            name: 'Travel',
                            key: 'Travel',
                            icon: travelStyle.icon,
                            pinned: true,
                            disabled: !canCreateTravel,
                            tooltip: !canCreateTravel ? `${permissionTooltipText} travel events` : null,
                            action: () => handleCreateEvent({ type: 'Travel' })
                        },
                        {
                            name: 'Accommodation',
                            key: 'Accommodation',
                            icon: accommodationStyle.icon,
                            disabled: !canCreateAccommodation,
                            tooltip: !canCreateAccommodation ? `${permissionTooltipText} accommodation events` : null,
                            action: () => handleCreateEvent({ type: 'Accommodation' })
                        },
                        {
                            name: 'Room Lodging',
                            key: 'RoomLodging',
                            icon: roomLodgingStyle.icon,
                            disabled: !canCreateAccommodation,
                            tooltip: !canCreateAccommodation ? `${permissionTooltipText} room lodging events` : null,
                            action: () => handleCreateEvent({ type: 'RoomLodging' })
                        }
                    ]}
                />
                &nbsp;
                <NwButton 
                    label='External Jobs' 
                    size='small' 
                    icon={externalJobStyle.icon} 
                    style={ButtonStyle} 
                    onClick={() => handleCreateEvent({ type: 'ExtJob' })} 
                />
                &nbsp;
                <NwDropDownMenu
                    size="small"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    actions={[
                        {
                            name: renderEventType(defaultFollowUp),
                            key: `event-${defaultFollowUp.label}`,
                            pinned: true,
                            disabled: !canCreateFollowUp,
                            tooltip: !canCreateFollowUp ? `${permissionTooltipText} activities` : null,
                            action: (() => handleCreateEvent(defaultFollowUp))
                        },
                        ...followUpEventTypes
                    ]}
                />
            </>
    );
}

export default AddEventsButtons;
