import React, { useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';

import { removeStatementLog } from 'Libs/NwLogs';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDeleteInvoice } from "Hooks/Invoices/UseInvoices";
import useConfigurations from 'Hooks/UseConfigurations';
import ChangeAccountDrawer from 'Components/Invoices/InvoicePage/Drawers/ChangeAccountCodeDrawer';

const InvoiceHeader = styled.div`
    z-index: 1030;
    top: ${props => props.$inDrawer ? "0" : "5rem"};
    width: 100%;
    position: ${props => props.$inDrawer ? "relative" : "sticky"};

    .ant-page-header {
        margin: 1rem;
        padding: 0;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 4rem;
        
        .invoice-header-wrapper {
            padding: 10px 0 0 340px;

            span.invoice-label {
                position: absolute;
                top: 0;
                left: 0;
                width: 316px;
                padding: 1rem 1.5rem;
                font-weight: 300;
                height: 4rem;
                font-size: ${props => props.theme.FontSizeSm};
                text-transform: uppercase;
                border-right: ${props => props.theme.DefaultBorder};
            }

            h1 {
                font-size: ${props => props.theme.FontSizeLg};
            }
        }

        .ant-page-header-heading-extra {
            padding: 10px 20px 0 0;
        }
    }

    .confirm-button {
        color: ${StyleVariables.PrimaryColor};
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
        font-size: 14px;
        font-weight: 500;
    }
`;

const InvoicePageHeader = ({
    invoiceData,
    handleShowEditDrawer,
    inDrawer,
    locked,
    onDelete
}) => {
    const { mutateAsync: onDeleteInvoice } = useDeleteInvoice();
    
    const [showChangeAccountCodeDrawer, setShowChangeAccountCodeDrawer] = useState(false);

    const configurations = useConfigurations()
    const accounts = [];
    if (configurations && configurations.COA) {
        const COAjs = JSON.parse(configurations.COA);
        if (COAjs.accounts && COAjs.accounts.length > 0) {
            accounts.push(...COAjs.accounts);
        }
    }
    const enableCOA = accounts.length > 0;

    const usedIDs = [];
    const tempInvoiceData = invoiceData.Rows.map((item, index) => {
        const statement = item.Statement;
        if (!statement) return {
            key: index,
            index: item.ID,
            Guid: item.GroupGuid,
            RowIds: [{ ID: item.ID, Type: "Text" }],
            Type: "Text",
        }
        return {
            key: index,
            index: item.ID,
            Guid: item.GroupGuid,
            RowIds: [{ ID: item.ID, Type: "Statement" }],
            Type: "Statement",
        };
    })
    const groupedInvoiceData = tempInvoiceData.map((row) => {
        if (row.Guid === null) return row;
        if (usedIDs.filter(guid => guid === row.Guid).length === 0) {
            usedIDs.push(row.Guid);
            const group = tempInvoiceData.filter(el => el.Guid === row.Guid);
            return {
                ...row,
                RowIds: group.map(row => ({
                    ID: row.index,
                    Type: row.Type
                })),
            }
        }
        return null;
    }).filter(el => el !== null);

    const onDeleteWholeInvoice = async () => {
        try {
            await onDeleteInvoice(invoiceData.ID);
            removeStatementLog(invoiceData.ID);
            onDelete()
        } catch (e) {
            console.log("error deleting", e);
        }
    }

    const getInvoiceLabel = () => {
        if (invoiceData.TypeName === "Model") {
            return "Model Expenses Invoice"
        }
        if (invoiceData.TypeName === "Agency") {
            return "Moth. Ag. Comm. Invoice"
        }
        return "CLient Invoice"
    }

    const dropdownActions = [
        {
            name: "",
            key: "edit",
            disabled: locked,
            pinned: true,
            icon: light("pen"),
            action: () => handleShowEditDrawer()
        },
        {
            name: "Delete",
            key: "delete",
            disabled: locked,
            action: () => onDeleteWholeInvoice()
        },
        {
            name: "Create Credit Note",
            disabled: true,
            key: "creditnote",
            action: () => { }
        },
    ]

    if (enableCOA) {
        dropdownActions.push({
            name: "Set Account Code on all lines",
            key: "coa",
            action: () => setShowChangeAccountCodeDrawer(true)
        })
    }


    return (
        <>
            <InvoiceHeader $inDrawer={inDrawer}>
                <PageHeader
                    title={
                        <div className="invoice-header-wrapper">
                            <span className="invoice-label">
                                {getInvoiceLabel()}
                            </span>
                            <h1>
                                <strong>{getContactName(invoiceData.ContactID)}</strong> <em>/</em> {invoiceData.Name}
                            </h1>
                        </div>
                    }
                    subTitle={false}
                    extra={[
                        <NwDropDownMenu
                            size="small"
                            key="extra"
                            actions={dropdownActions}
                        />
                    ]}
                >
                </PageHeader>
            </InvoiceHeader>
            {showChangeAccountCodeDrawer &&
                <ChangeAccountDrawer
                    invoiceID={invoiceData.ID}
                    records={[ ...groupedInvoiceData ]}
                    onClose={() => setShowChangeAccountCodeDrawer(false)}
                />
            }
        </>
    )
}

export default InvoicePageHeader;
