import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import NwDrawer from 'Components/Gui/NwDrawer';
import Axios from 'axios';
import { Loading } from 'Components/Gui/Loading';
import { List } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';
import NwClipboardCopyText from 'Components/Gui/NwClipboardCopyText';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwMailTo from 'Components/Gui/NwMailTo';
import { UserContext } from 'Contexts/UserContext';
import _ from 'lodash';

const ListContainer = styled.div`
    
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }
    
    .cc-container {
        color: ${props => props.theme.TextColor};
        margin-bottom: .75rem;

        small {
            font-size: ${props => props.theme.FontSizeSm};
        }
    }
    
    .link-container {
        margin: .75rem 0;
    }
    .send-button-container {
        margin: .75rem 0;
    }
`

const PackageShareLink = ({ currentPackage, onClose, anonymous }) => {

    const [links, setLinks] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const { currentUser } = useContext(UserContext);
    
    const getLinks = async () => {
        setIsFetching(true);
        const packagelinks = await Axios.get(`/packages/getlinks/${currentPackage.ID}`);
        setLinks([...packagelinks])
        setIsFetching(false);
    }

    const getAnonymousLink = async () => {
        setIsFetching(true)
        const recipientData = {
            PackageID: currentPackage.ID,
            ContactID: null,
            ConnectionID: null,
            Email: currentUser.Email,
            CC: null
        }
        try {
            const packagelinks = await Axios.post('/Packages/AddRecipientForLink', recipientData);
            setLinks([packagelinks])
            setIsFetching(false);
            //onUpdatePackage()
        } catch (error) {
            console.log('ant : Add Recipients Error => ', error);
        }
    }

    useEffect(() => {
        if (anonymous) {
            getAnonymousLink();
        } else {
            getLinks();
        }
    }, [])

    const filteredLinks = () => {
        // if (anonymous) {
        //     const anonymousLinks = links.filter(link => link.Email === currentUser.Email)
        //     if (anonymousLinks.length > 0) {
        //         //const sortedlinks = _.sortBy(anonymousLinks, 'RecipientID')
        //         //return [ sortedlinks[0] ]
        //         return anonymousLinks[0]
        //     }
        //     return []
        // }
        return links
    }

    return (
        <NwDrawer
            width="40vw"
            onClose={onClose}
            title='Share links'
        >
            {isFetching
                ?
                <Loading />
                :
                <ListContainer>
                    <List
                        dataSource={filteredLinks()}
                        renderItem={link => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<NwIcon icon={light('envelope')} />}
                                    title={
                                        <NwMailTo email={link.Email} cc={link.Ccs} />
                                    }
                                    description={
                                        <>
                                            <div className="cc-container">
                                            {link.Ccs && link.Ccs.length > 0 &&
                                                <small>CC: {link.Ccs.join(', ')}</small>
                                            }
                                            </div>
                                            <div className="link-container">
                                                <NwClipboardCopyText text={link.Link} />
                                            </div>
                                            <div className="send-button-container">
                                                <NwMailTo asButton email={link.Email} cc={link.Ccs} subject={currentPackage.Name} body={link.Link} />
                                            </div>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </ListContainer>
            }
        </NwDrawer>
    )
}

export default PackageShareLink