import React from "react";
import styled from 'styled-components';
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwDivider from "Components/Gui/NwDivider";
import FlexContainer from 'Components/Gui/FlexContainer';
import { forceReloadContact } from "Hooks/Contact/UseContact";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { forceReloadContactBankAccounts } from "Hooks/Contact/UseContactBankAccounts";

const DetailValue = styled.div`
    font-size: .95rem;
    font-weight: bold;
    margin-right: ${props => !props.isMobile && '4px'};
`;

const DetailLabel = styled.div`
    font-size: .95rem;
`;

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};
        }
    }
`;

const BankAccountItem = ({ item, contactId, onEditDoc }) => {
    const breakpoints = useNwBreakPoints();

    const deleteBankACcount = async () => {
        try {
            await Axios.delete(`/ContactBankAccounts/${item.ID}`)
            forceReloadContactBankAccounts(contactId)
            forceReloadContact(contactId)
        } catch (error) {
            console.log('ant : delete bank account error => ', error)
        }
    };

    const renderActions = () => {
        return (
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: onEditDoc},
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => { deleteBankACcount(item.ID) }) }
                ]}
            />
        )
    };

    return (
        <Wrapper isMobile={breakpoints === 1}>
            <NwCard
                title={item.BankName}
                extra={renderActions()}>
                <FlexContainer 
                    column={breakpoints === 1} 
                    alignItems='flex-start'>
                    <DetailLabel isMobile={breakpoints === 1}>IBAN:</DetailLabel>
                    <DetailValue>{item.IBAN}</DetailValue>
                </FlexContainer>
                <FlexContainer 
                    column={breakpoints === 1} 
                    alignItems='flex-start'>
                    <DetailLabel isMobile={breakpoints === 1}>BIC / SWIFT:</DetailLabel>
                    <DetailValue>{item.BIC}</DetailValue>
                </FlexContainer>
                <NwDivider>Description</NwDivider>
                <DetailLabel>{item.Description}</DetailLabel>
                <NwDivider icon={light('lock-alt')}>Internal Notes</NwDivider>
                <DetailLabel>{item.InternalNote}</DetailLabel>
            </NwCard>
        </Wrapper>
    );
};

export default BankAccountItem;
