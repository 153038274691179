import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { queryClient } from 'QueryClientProvider';

const onGetPackagePlaylistByID = ID => {
    if (ID === null) return null;
    return Axios.get(`/packageplaylists/${ID}`);
}

const usePackagePlaylist = (ID) => {
    return useQuery(["package-playlist", ID], () => onGetPackagePlaylistByID(ID), {
        staleTime: 10000,
        cacheTime: 10000,
        retry: false,
        enabled: ID ? true : false
    });
}

const forceReloadPackagePlaylist = (ID) => {
    queryClient.invalidateQueries(['package-playlist', ID])
}


const editPackagePlaylistDocuments = ({ playlist }) => (
    Axios.put("/packageplaylists/Documents", playlist)
);

const useEditPackagePlaylistDocuments = () => {
    return useMutation(editPackagePlaylistDocuments, {

    });
}

export {
    usePackagePlaylist,
    useEditPackagePlaylistDocuments,
    forceReloadPackagePlaylist
};


