import React, { useState } from "react";
import CreateModelForm from "Components/ContactCreator/CreateModelForm";
import CreateCustomerForm from "Components/ContactCreator/CreateCustomerForm";
import CreateProjectDrawer from "Components/CreateProject/CreateProjectDrawer";
import NwDrawer from "Components/Gui/NwDrawer";
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import TravelPlanAddEditDrawer from "Components/TravelPlanAddEditDrawer/TravelPlanAddEditDrawer";
import { getNow } from "Libs/NwMoment";
import { useUserPermission } from "Contexts/UserContext";
import { notification } from "antd";

const ContactCreatorDrawer = ({
    onCancel,
    onCloseEventDrawer,
    onCustomerCreated,
    onEventCreated,
    onModelCreated,
    onProjectCreated,
    onTravelPlanCreated,
    type
}) => {
    const [touchForm, setTouchForm] = useState(false);

    // const afterUpdateBookingEvent = (data) => {
    //     onCancel()
    //     history.push(`/bookings/${data.ProjectID}/${data.ID}/Event`)
    // }

    const canCreateClients = useUserPermission('CreateClients');
    const canCreateAgencies = useUserPermission('CreateAgencies');
    const canCreateServices = useUserPermission('CreateServices');
    const canCreateModel = useUserPermission('CreateModels');
    const canCreateEvent = useUserPermission('CreateBookingEvent');
    const canCreateTravel = useUserPermission('CreateTravels');
    const canCreateFollowUp = useUserPermission('CreateActivities');
    const canCreateMeeting = useUserPermission('CreateMeetings');



    const typeIsBooking = (type) => {
        return (type.toLowerCase() === 'job' || type.toLowerCase() === 'casting')
    }

    const afterEventCreation = (data) => {
        let showDrawer = true
        if (typeIsBooking(type)) {
            showDrawer = false
        }
        const newEvent = { ID: data.ID, TypeName: type, ShowDrawer: showDrawer }
        onEventCreated(newEvent)
    }

    const handleCloseEventDrawer = () => {
        if (typeIsBooking(type)) {
            if (onCloseEventDrawer) {
                onCloseEventDrawer()
            }
            if (onCancel) {
                console.log("entrato in onCancel")
                onCancel()
            }
        } else {
            console.log("type is not booking")
            if (onCancel) {
                console.log("entrato in onCancel")
                onCancel()
            }
        }
    }

    const openNotification = (selected_action) => {
        const action_message = selected_action || `${type} creation`;
        
        const args = {
            message: 'Invalid Access',
            description:
                `We're sorry, but your current Netwalk user policies do not permit this operation (${action_message}). Please contact your administrator if you believe this is an error or need further assistance. Thank you for your understanding.`,
            duration: 10,
        };
        notification.open(args);
    };

    const renderCreatorDrawer = (type) => {
        switch (type) {
            case "Model":
                if (!canCreateModel) {
                    openNotification()
                    return null
                }
                return (
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        onClose={onCancel}
                        title={onChangeTitle()}
                    >
                        <CreateModelForm
                            onTouchForm={setTouchForm}
                            onModelCreated={onModelCreated}
                            type={type}
                            onCancel={onCancel}
                        />
                    </NwDrawer>
                );
            case "ScoutingModel":
                if (!canCreateModel) {
                    openNotification()
                    return null
                }
                return (
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        onClose={onCancel}
                        title={onChangeTitle()}>
                            <CreateModelForm 
                                onTouchForm={setTouchForm} 
                                onModelCreated={onModelCreated} 
                                type={type} 
                                onCancel={onCancel} 
                            />
                    </NwDrawer>
                )
            case 'Casting':
                if (!canCreateEvent) {
                    openNotification()
                    return null
                }
                return (
                    <EventDrawer
                        drawerOrigin="ContactCreatorDrawer"
                        eventType={'Event'}
                        show={true}
                        eventInfo={{
                            type: 'Casting'
                        }}
                        viewMode='create'
                        allDay={true}
                        dateSelected={getNow()}
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                        afterRemove={onCancel} />
                );
            case 'Job':
                if (!canCreateEvent) {
                    openNotification()
                    return null
                }
                return (
                    <EventDrawer
                        drawerOrigin="ContactCreatorDrawer"
                        eventType={'Event'}
                        show={true}
                        eventInfo={{
                            type: 'Job'
                        }}
                        viewMode='create'
                        allDay={true}
                        dateSelected={getNow()}
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                        afterRemove={onCancel} />
                )
            case "ToDo":
                return (
                    <EventDrawer
                        eventType='ToDo'
                        show={true}
                        viewMode='create'
                        onClose={onCancel}
                        afterUpdate={() => { }} />
                );
            case "Travel":
                if (!canCreateTravel) {
                    openNotification()
                    return null
                }
                return (
                    <EventDrawer
                        eventType='Travel'
                        show={true}
                        viewMode='create'
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                    />
                );
            case "Accommodation":
                return (
                    <EventDrawer
                        eventType='Accommodation'
                        show={true}
                        allDay={true}
                        viewMode='create'
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                    />
                );
            case "FollowUp":
                if (!canCreateFollowUp) {
                    openNotification("activity creation")
                    return null
                }
                return (
                    <EventDrawer
                        eventType='FollowUp'
                        show={true}
                        viewMode='create'
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                    />
                );
            case "Meeting":
                if (!canCreateMeeting) {
                    openNotification()
                    return null
                }
                return (
                    <EventDrawer
                        eventType='Meeting'
                        show={true}
                        viewMode='create'
                        allDay={true}
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                    />
                );
            case "Notice":
                return (
                    <EventDrawer
                        eventType='Notice'
                        show={true}
                        viewMode='create'
                        allDay={true}
                        onClose={handleCloseEventDrawer}
                        afterUpdate={afterEventCreation}
                    />
                );
            case 'Trip':
                return (
                    <TravelPlanAddEditDrawer
                        onTravelPlanCreated={onTravelPlanCreated}
                        onClose={onCancel}
                    />
                );
            default:
                if (type === 'Client' && !canCreateClients) {
                    openNotification()
                    return null
                }
                if (type === 'Agency' && !canCreateAgencies) {
                    openNotification()
                    return null
                }
                if (type === 'Service' && !canCreateServices) {
                    openNotification()
                    return null
                }
                return (
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        onClose={onCancel}
                        title={onChangeTitle()}>
                        <CreateCustomerForm
                            onCustomerCreated={onCustomerCreated}
                            type={type}
                            onCancel={onCancel}
                            onTouchForm={setTouchForm} />
                    </NwDrawer>
                )
        }
    };

    const onChangeTitle = () => {
        let typeForTitle = type
        return `Add a New ${typeForTitle}`
    };

    // if (type === 'Project' && !canCreateEvent) {
    //     openNotification()
    //     return null
    // }

    return (
        <>
            {type === 'Project'
                ?
                <CreateProjectDrawer
                    searchMode={false}
                    onCloseModal={onCancel}
                    onChange={onProjectCreated}
                />
                :
                renderCreatorDrawer(type)
            }
        </>
    );
};

export default ContactCreatorDrawer;
