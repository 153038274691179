import React, { useState } from "react";
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";
import {
    NWInput,
    NWContactSelector,
    NWTextAreaCharCounter,
    NWProjectSelector,
    NWLocationSelector,
    NWCheckDatePeriodPicker,
    NWSwitch,
} from "Components/Gui/NWForm/NwFormItems";
import {
    useUpdateAccommodation,
    useAddAccommodation,
    useUpdateGroupedCommonAccommodation,
    useUpdateGroupedIndividualAccommodation
} from 'Hooks/Event/UseAccommodation';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { usePreviewProject } from 'Hooks/Project/UseProject';
import EditEvent from "Components/EventDrawer/EditEvent";
import { getNow } from "Libs/NwMoment";

const AccommodationForm = ({
    accommodationData,
    editModelEvent,
    viewMode,
    modelId,
    projectId,
    afterUpdate,
    onCancel,
    onUpdateAccommodationData,
    onTouchForm
}) => {
    const { mutateAsync: onAddAccommodation } = useAddAccommodation();
    const { mutateAsync: onUpdateAccommodation } = useUpdateAccommodation();
    const { mutateAsync: onUpdateGroupedIndividualAccommodation } = useUpdateGroupedIndividualAccommodation();
    const { mutateAsync: onUpdateGroupedCommonAccommodation } = useUpdateGroupedCommonAccommodation();
    const [selectedProjectId, setProjectId] = useState((accommodationData && accommodationData.ProjectID) || projectId);
    const { data: project } = usePreviewProject(selectedProjectId);

    const submitForm = async (values, setSubmitting) => {
        const newAccommodationData = {
            ...values,
            ...values.Address,
            PhoneNumber: values.Address.Phone,
        };

        delete newAccommodationData.Address;

        if (!accommodationData) {
            try {
                const newAccommodation = await onAddAccommodation({ accommodationData: newAccommodationData });
                if (afterUpdate) {
                    afterUpdate({
                        ...newAccommodation,
                        TypeName: "Accommodation"
                    });
                }
                onTouchForm(false);
                onUpdateAccommodationData(newAccommodation.ID);
                onCancel();
            } catch (error) {
            }
            setSubmitting(false);
        }
        else {
            newAccommodationData.ID = accommodationData.ID;
            try {
                if (accommodationData.GroupGuid) {
                    if (editModelEvent) {
                        newAccommodationData.ChangeOnAllModels = values.ChangeOnAllModels;
                        await onUpdateGroupedIndividualAccommodation({ accommodationData: newAccommodationData });
                    } else {
                        newAccommodationData.GroupGuid = accommodationData.GroupGuid;
                        delete newAccommodationData.ID;
                        await onUpdateGroupedCommonAccommodation({ accommodationData: newAccommodationData });
                    }
                } else {
                    await onUpdateAccommodation({ accommodationData: newAccommodationData });
                }

                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateAccommodationData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                onTouchForm(false);
                console.log("ant : Update Accommodation Error", error);
            }
            setSubmitting(false);
        }
    };

    let initialValues = {
        ProjectID: accommodationData ? accommodationData.ProjectID : projectId ? projectId : '',
        ModelID: accommodationData ? accommodationData.ModelID : modelId ? modelId : '',
        Title: accommodationData ? accommodationData.Title : '',
        ServiceID: accommodationData ? (accommodationData.ServiceID ? accommodationData.ServiceID : null) : null,
        Note: accommodationData ? accommodationData.Note : '',
        FromDate: accommodationData ? accommodationData.FromDate : getNow(true),
        ToDate: accommodationData ? accommodationData.ToDate : getNow(true),
        Address: {
            Country: accommodationData ? accommodationData.Country : '',
            City: accommodationData ? accommodationData.City : '',
            County: accommodationData ? accommodationData.County : '',
            State: accommodationData ? accommodationData.State : '',
            Zip: accommodationData ? accommodationData.Zip : '',
            Street: accommodationData ? accommodationData.Street : '',
            Access: accommodationData ? accommodationData.Access : '',
            Phone: accommodationData ? accommodationData.PhoneNumber : ''
        },
        ReservationCode: accommodationData ? accommodationData.ReservationCode || '' : '',
        CheckDatePeriod: {
            FromDate: accommodationData ? accommodationData.FromDate : getNow(true),
            ToDate: accommodationData ? accommodationData.ToDate : getNow(true),
        }
    };

    const validationSchema = Yup.object().shape({
        Title: Yup.string().required("Content is required"),
        ModelID: Yup.number().required('Content is required'),
    });

    let dataSource = [];
    if (!editModelEvent || !accommodationData) {
        if (!accommodationData)
            dataSource.push({
                component: NWProjectSelector,
                name: "ProjectID",
                readOnly: projectId ? true : false,
                Col: 24,
                onAfterChange: (values, _, value) => {
                    if (values.ProjectID !== value)
                        setProjectId(value);
                }
            });
        dataSource.push({
            component: NWContactSelector,
            label: "Service",
            name: "ServiceID",
            activeTypes: ['srv'],
            activableTypes: [],
            Col: !modelId ? 12 : 24,
            onAfterChange: (values, setFieldValue, value) => {
                if (values.ServiceID !== value && !values.Title)
                    setFieldValue('Title', getContactName(value));
            }
        });
        if (!modelId)
            dataSource.push({
                component: NWContactSelector,
                label: "Model",
                name: "ModelID",
                activeTypes: ['mod'],
                activableTypes: [],
                Col: 12,
            });
        dataSource.push({
            component: NWInput,
            label: "Title",
            name: "Title",
            type: "text",
            Col: 24
        });
        dataSource.push({
            component: NWCheckDatePeriodPicker,
            name: "CheckDatePeriod",
            onAfterChange: (_, setFieldValue, value) => {
                if (value) {
                    setFieldValue('FromDate', value.FromDate);
                    setFieldValue('ToDate', value.ToDate);
                }
            },
            Col: 24,
        });
        dataSource.push({
            component: NWLocationSelector,
            label: "Address",
            name: "Address",
            eventType: "accommodation",
            projectCustomerId: project && project.CustomerID,
            customerId: "ServiceID",
            Col: 24,
        });
    }

    if (editModelEvent || !accommodationData || accommodationData.RelatedEvents.length === 0) {
        dataSource.push({
            component: NWTextAreaCharCounter,
            label: "Note",
            name: "Note",
            type: "text",
            maxLength: 2000,
            autoSize: { minRows: 4, maxRows: 10 },
            Col: 24,
        });
        dataSource.push({
            component: NWInput,
            label: "Reservation Code",
            name: "ReservationCode",
            type: "text",
            Col: 24,
        });
    }

    if (editModelEvent) {
        dataSource.push({
            component: NWSwitch,
            text: 'apply these changes on all models in this accommodation',
            name: 'ChangeOnAllModels',
            Col: 24
        });
    }

    return (
        <EditEvent
            submitForm={submitForm}
            mg={0}
            validationSchema={validationSchema}
            dataSource={dataSource}
            initialValues={initialValues}
            onCancel={onCancel}
            validate={() => { }}
            disableReinitialize
            onTouchForm={onTouchForm}
        />
    );
};

export default React.memo(AccommodationForm);
