import React, {useState} from "react";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

const ColorPicker = ({value, onChange, disabled}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColors] = useState(value ? value : '#000000');

   const handleClick = () => {
       if (!disabled) {
           setDisplayColorPicker(true)
       }
    };

   const handleClose = () => {
       setDisplayColorPicker(false)
    };

   const handleChange = (color) => {
       setColors(color.hex);
       onChange(color.hex);
    };

    const styles = reactCSS({
        'default': {
            color: {
                width: '40px',
                height: '15px',
                borderRadius: '2px',
                background: `rgba(${color})`,
            },
            swatch: {
                padding: '5px',
                background: `${color}`,
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'fixed',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        },
    });

    return (
        <div>
            <div style={ styles.swatch } onClick={ handleClick }>
                <div style={ styles.color } />
            </div>
            { displayColorPicker ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ handleClose }/>
                <SketchPicker color={ color } onChange={ handleChange } />
            </div> : null }

        </div>
    )

};

export default ColorPicker;
