import React from "react";
import EventTypeTag from "Components/EventDetails/EventTypeTag";


const TransactionEvent = ({ transaction, project }) => {

    let propsForEventTypeTag = {}
    let eventTitle = ''
    let eventExist = false;


    if (transaction.EventID) {
        let event = null;
        if (project) {
            event = project.Events.find(event => event.ID === transaction.EventID);
        } else {
            event = transaction.Event;
        }
        if (event) {
            eventExist = true
            const tempProps = {
                event : event,
                forModel : transaction.ModelID,
                forDate : transaction.WorkDate,
                type : event.TypeName
            }
            propsForEventTypeTag = tempProps
            eventTitle = event.Name
        }
    }
    if (transaction.FollowUpID) {
        let event = null;
        if (project) {
            event = project.FollowUps.find(event => event.Models.some((modelevent) => modelevent.FollowUpID === transaction.FollowUpID));
        } else {
            event = transaction.FollowUp;
        }
        if (event) {
            eventExist = true
            const tempProps = {
                event : event,
                type : 'FollowUp'
            }
            propsForEventTypeTag = tempProps
            eventTitle = event.Title
        }
    }
    if (transaction.AccommodationID) {
        let event = null;
        if (project) {
            event = project.Accommodations.find(event => event.Models.some((modelevent) => modelevent.AccommodationID === transaction.AccommodationID));
        } else {
            event = transaction.Accommodation;
        }
        if (event) {
            eventExist = true
            const tempProps = {
                event : event,
                type : 'Accommodation'
            }
            propsForEventTypeTag = tempProps
            eventTitle = event.Title
        }
    }
    if (transaction.TravelID) {
        let event = null;
        if (project) {
            event = project.Travels.find(event => event.Models.some((modelevent) => modelevent.TravelID === transaction.TravelID));
        } else {
            event = transaction.Travel;
        }
        if (event) {
            eventExist = true
            const tempProps = {
                event : event,
                type : 'Travel'
            }
            propsForEventTypeTag = tempProps
            eventTitle = event.Title
        }
    }
    if (eventExist) {
        return (
            <div>
                <EventTypeTag {...propsForEventTypeTag} /> {eventTitle}
            </div>
        )
    }
    return (<></>);
};

export default TransactionEvent