import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col, Modal } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import CustomerDrawer from 'Components/ContactDetails/CustomerDetails/Drawer/CustomerDrawer';
import ProjectEditDrawer from 'Components/Project/ProjectEditDrawer/ProjectEditDrawer';
import ProjectPageTaskList from 'Components/TaskList/ProjectPageTaskList';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import ProjectTags from 'Components/Project/ProjectOverview/ProjectTags';
import ProjectModels from 'Components/Project/ProjectOverview/ProjectModels';
import ProjectEventsOverview from 'Components/Project/ProjectOverview/ProjectEventsOverview';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { getCustomFieldValue } from 'Libs/NwUtils';
import { ListContext } from 'Contexts/ListContext';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import NwIcon from 'Components/Gui/NwIcon';
import { useAddTag, useRemoveTag, useDeleteProject, forceReloadProject } from 'Hooks/Project/UseProject';
//import { showPeriodByDay } from 'Libs/NwMoment';
import { Loading } from 'Components/Gui/Loading';
import { addProjectLog } from 'Libs/NwLogs';
import ProjectEstimatedFees from './ProjectEstimatedFees';
import ProjectPackagesOverview from './ProjectPackagesOverview';
import AuthorAndDepartment from 'Components/CreatedBy/AuthorAndDepartment';
import ProjectChangeBookerDrawer from '../ProjectChangeBookerDrawer';
import UserIcon from 'Components/UserIcon/UserIcon';
import { NwLinkButton } from 'Components/Gui/NwButton';
import { getUserName } from 'Libs/NwUsers';
import useDepartmentName from 'Hooks/UseDepartmentName';

const EventFieldsTable = styled.table`
  list-style-type: none;
  margin-left: 8px;
  .title-cell {
    border-right: ${props => props.theme.DefaultBorder};
    padding-right: 40px;
    font-size: .65rem;
    text-transform: uppercase;
    vertical-align: top; 
  }
  .content-cell {
    padding-left: 20px;
    font-size: .95rem;
  }
`

const ProjectOverviewContainer = styled.div`
    margin-bottom: 16px;
    /* .ant-page-header {
        padding-right: 1rem;
        border-bottom: ${props => props.theme.DefaultBorder};
        .ant-page-header-title-view-title {
            font-size: 2rem;
            line-height: 1;
        }

        .ant-page-header-content {
            padding-top: 0;
        }
    } */

    .project-overview-left {
        padding-left: 1rem;
    }
    .project-overview-right {
        padding-left: 1rem;
    }

    .internal-card {
        margin-top: 3rem;
        width: 100%;
        padding-right: 1rem;
        
        &.first-line {
            margin-top: .5rem;
        }

        .header {
            border-bottom: ${props => props.theme.DefaultBorder};
            display: flex;
            align-items: center;
            padding-bottom: .5rem;
            margin-bottom: 1rem;
            justify-content: space-between;

            .title-container {
                display: flex;
                align-items: center;
                
                .icon {
                    font-size: 18px;
                }
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    margin-left: 8px;
                }
            }
        }
        .content {
            .project-booker-card-content {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .project-booker-card-content-avatar {
                    padding-right: 2rem;
                }

                .project-booker-card-content-text {
                    flex: 1 1 0;
                    p {
                        margin: 0 0 .25rem 0;
                    }

                }
                .project-booker-card-content-actions {
                    padding-left: 2rem;
                }

            }
        }
        .todo-item {
            border-bottom: ${props => props.theme.LighterBorder};
        }
    }


    .project-info {
        border-right: ${props => props.theme.DefaultBorder};
    }
`;

// const NoWrapLine = styled.div`
//     white-space: nowrap;

//     @media (max-width: 600px) {
//         white-space: normal;
//     }
// `;

const ProjectDetail = styled(Row)`
    .ant-col:first-child {
        border-right: ${props => props.theme.DefaultBorder};
    }

    .ant-typography-expand {
        color: ${props => props.theme.PrimaryColor};
    }
`;

const ProjectOverview = ({ projectId, currentProject, onMoveToEvent, afterProjectDelete }) => {

    const allowBookerChange = true;

    const { mutateAsync: onAddTag } = useAddTag();
    const { mutateAsync: onRemoveTag } = useRemoveTag();
    const { mutateAsync: onDeleteProject } = useDeleteProject();

    const [isEditDrawer, setIsEditDrawer] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [validCustomFieldsKeys, setValidCustomFieldsKeys] = useState([]);
    const [isContactDrawer, setIsContactDrawer] = useState(false);
    const [openBookerChangeDrawer, setOpenBookerChangeDrawer] = useState(false);
    const { cachedList } = useContext(ListContext);

    const deptname =  useDepartmentName(currentProject.DepartmentCreated, currentProject.NetworkAgencyCreated)

    const usersList = cachedList.users.items;

    //const { data: currentProject } = useProject(projectId);

    useEffect(() => {
        const projectCustomFields = cachedList.customFields.items.Project
        setCustomFields(projectCustomFields)
        setValidCustomFieldsKeys(projectCustomFields.filter(field => !field.Disabled).map(field => field.Name))
    }, [cachedList]);

    useEffect(() => {
        if (currentProject) {
            addProjectLog(currentProject);
        }
    }, [currentProject]);

    const projectCategory = useMemo(() => {
        if (currentProject) {
            return cachedList.projectCategories.items.find(category => category.ID === currentProject.ProjectCategoryID)
        }
        return null
    }, [currentProject])

    const handleEditProject = () => {
        setIsEditDrawer(true);
    };

    const handleCloseEditDrawer = () => {
        setIsEditDrawer(false);
    };

    const handleAddTag = async tag => {
        await onAddTag({ tag, projectId });
    };

    const handleRemoveTag = async tag => {
        await onRemoveTag({ tag, projectId });
    };

    const handleCloseContactDrawer = () => {
        setIsContactDrawer(false);
    };

    const handleDeleteProject = async () => {
        try {
            await onDeleteProject({ projectId: projectId });
            afterProjectDelete()
        } catch (e) {
            Modal.error({
                title: e.message
            });
        }
    }

    const onOpenBookerChangeDrawer = () => {
        setOpenBookerChangeDrawer(true)
    }

    const onCloseBookerChangeDrawer = () => {
        setOpenBookerChangeDrawer(false)
    }

    return (
        <ProjectOverviewContainer>
            {!currentProject
                ?
                <Loading />
                :
                <>
                    <NwCard>
                        <Row gutter={32}>
                            <Col className="project-overview-left" lg={12} md={12} sm={24} xs={24}>
                                <div className="internal-card first-line">
                                    <div className="header">
                                        <div className="title-container">
                                            <NwIcon className='icon' icon={light('info-circle')} />
                                            <div className='title'>Info</div>
                                        </div>
                                        <NwDropDownMenu
                                            actions={[
                                                { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => handleEditProject() },
                                                { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => handleDeleteProject() }
                                            ]}
                                        />
                                    </div>
                                    <div className="content">
                                        <ProjectDetail gutter={16}>
                                            <EventFieldsTable>
                                                <tbody>
                                                    <tr>
                                                        <td className="title-cell">Product</td>
                                                        <td className="content-cell">{currentProject.Product || 'n.d.'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="title-cell">Category</td>
                                                        <td className="content-cell">{projectCategory ? projectCategory.Name : ''}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="title-cell">Period</td>
                                                        <td className="content-cell">{renderProjectDate()}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="title-cell">Description</td>
                                                        <td className="content-cell" style={{ paddingRight: '4rem' }}>
                                                            <NwEllipsis text={currentProject.Description || ''} rows={5} expandable symbol='more' />
                                                            {/* {currentProject.Description || ''}</td> */}
                                                        </td>
                                                    </tr>
                                                    {Object.keys(currentProject.CustomFields).map((key, index) => {
                                                        if (validCustomFieldsKeys && validCustomFieldsKeys.includes(key)) {
                                                            return (
                                                                <tr key={`key-${index}`}>
                                                                    <td className='title-cell'>{key.replace(/_/g, ' ')}</td>
                                                                    <td className='content-cell'>{getCustomFieldValue(key, currentProject.CustomFields[key], customFields)}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                    <ProjectTags objectType={'Event'} objectTags={currentProject.Tags} onAddTag={handleAddTag} onRemoveTag={handleRemoveTag} />
                                                </tbody>
                                            </EventFieldsTable>
                                        </ProjectDetail>
                                    </div>
                                </div>
                                <div className="internal-card">
                                    <div className="header">
                                        <div className="title-container">
                                            <NwIcon className='icon' icon={light('user-vneck')} />
                                            <div className='title'>Booker</div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="project-booker-card-content">
                                            <div className="project-booker-card-content-avatar">
                                                <UserIcon shape="square" size={72} userId={currentProject.UserCreated} />
                                            </div>
                                            <div className="project-booker-card-content-text">
                                                <p><strong>{getUserName(currentProject.UserCreated, usersList)}</strong></p>
                                                <p>Department: {deptname}</p>
                                            </div>
                                            {allowBookerChange &&
                                                <div className="project-booker-card-content-actions">
                                                    <NwIcon primary icon={light("user-pen")} onClick={onOpenBookerChangeDrawer} pointer />
                                                    &nbsp;
                                                    <NwLinkButton
                                                        onClick={onOpenBookerChangeDrawer}
                                                        label="assign to another booker"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <ProjectModels projectId={projectId} />
                                <ProjectEstimatedFees projectId={projectId} />
                            </Col>
                            <Col className="project-overview-right" lg={12} md={12} sm={24} xs={24}>
                                <ProjectPageTaskList
                                    projectId={projectId}
                                />
                                <ProjectEventsOverview
                                    projectId={projectId}
                                    onMoveToEvent={onMoveToEvent} />
                                <ProjectPackagesOverview
                                    projectId={projectId} />
                            </Col>
                        </Row>
                    </NwCard>
                    {isEditDrawer &&
                        <ProjectEditDrawer
                            project={currentProject}
                            projectId={projectId}
                            customFields={customFields}
                            onClose={handleCloseEditDrawer} />
                    }
                    {isContactDrawer &&
                        <CustomerDrawer customerID={currentProject.Contact.ID} onClose={handleCloseContactDrawer} />
                    }
                </>
            }
            {openBookerChangeDrawer &&
                <ProjectChangeBookerDrawer project={currentProject} onClose={onCloseBookerChangeDrawer} />
            }
        </ProjectOverviewContainer>
    )
}

export default ProjectOverview
