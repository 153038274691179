import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';

import { UserContext } from "Contexts/UserContext";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwHeader from 'Components/_LayoutComponents/Header/NwHeader';

const { Content } = Layout;

const NwLayout = ({children}) => {
    const { currentUser } = useContext(UserContext);
    const breakpoints = useNwBreakPoints();
    
    return (
        <Layout className="nw-page">
            {currentUser && <NwHeader />}                        
            <Content style={{ marginTop: currentUser ? (breakpoints < 2 ? 114 : 60) : 0 }}>
                {children}
            </Content>
        </Layout>
    )
}

export default withRouter(NwLayout);

