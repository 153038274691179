import React, { useState } from 'react';
import { Input, List } from 'antd';
import styled from 'styled-components';

import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from 'Components/Gui/NwButton';

const StyledInput = styled(Input)`
    width: 240px;
    margin-right: 8px;
`;

const Validation = styled.div`
    color: red;
`;

const StyledList = styled(List)`
    margin-top: 8px;
`;

const ChoicesSelector = ({ value, onChange }) => {
    const [option, setOption] = useState('');
    const [isDuplicate, setIsDuplicate] = useState(false);

    const handleAddOption = () => {
        if (value.find(item => item === option)) {
            setIsDuplicate(true);
        } else if(option !== '') {
            onChange([
                ...value,
                option
            ]);
            setOption('');
        }
    };

    const handleChangeOption = event => {
        setOption(event.target.value);
        if (isDuplicate) {
            setIsDuplicate(false);
        }
    };

    return (
        <div>
            <FlexContainer>
                <StyledInput autoComplete='off' value={option} onChange={handleChangeOption} />
                <NwButton label='Add' onClick={handleAddOption} />
            </FlexContainer>
            {isDuplicate && <Validation>You already have this choice</Validation>}
            <StyledList
                bordered
                size="small"
                dataSource={value}
                renderItem={item => (
                    <List.Item>
                        <div>{item}</div>
                    </List.Item>
                )} />
        </div>
    )
};

export default ChoicesSelector;