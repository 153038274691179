import React, { useContext } from "react";
import Dinero from "dinero.js";
import useConfigurations from "../../Hooks/UseConfigurations";
import { ConfigContext } from 'Contexts/ConfigContext';
import styled from "styled-components";


const MoneySpan = styled.span`
    color: ${props => props.negative ? props.theme.DangerColor : 'inherit'};
    background-color: ${props => props.foreignCurrency ? props.theme.WorkingColor : 'transparent'};
    font-size: ${props => props.$small ? '0.8em' : 'inherit'};
    font-weight: ${props => props.$bold ? 'bold' : 'inherit'};
    white-space: nowrap;
`

const Money = ({ amount, amounts, dineroObj, currency, cent, redIfNegative, boldIfDefaultCurrency, highlightCurrency, small, hideCurrency, invert }) => {
    
    const { userGuiConfig } = useContext(ConfigContext);
    const configuration = useConfigurations();
    let numberformat = 'USD0,0.00'
    if (userGuiConfig && userGuiConfig.numbers && userGuiConfig.numbers.format) {
        numberformat = userGuiConfig.numbers.format;
    }
    
    if (hideCurrency) {
        numberformat = numberformat.replace('USD', '').replace('$','');
    }
    
    let numberlocale = 'en-US'
    if (userGuiConfig && userGuiConfig.numbers && userGuiConfig.numbers.locale) {
        numberlocale = userGuiConfig.numbers.locale;
    }

    const isDefaultCurrency = dineroObj ? dineroObj.getCurrency() === configuration.defaultCurrency : (currency === configuration.defaultCurrency);

    const dineroObject = () => {
        if (dineroObj) {
            if (invert) {
                return dineroObj.multiply(-1)
            } else {
                return dineroObj
            }
        }
        if (amount) {
            if (cent) {
                const d = Dinero({ amount: Math.round(amount ? amount : 0), currency: (currency || configuration.defaultCurrency) })
                if (invert) {
                    return d.multiply(-1).setLocale(numberlocale)
                } else {
                    return d.setLocale(numberlocale)
                }
            }
            const d2 = Dinero({ amount: Math.round((amount ? amount : 0) * 100), currency: (currency || configuration.defaultCurrency) })
            if (invert) {
                return d2.multiply(-1).setLocale(numberlocale)
            } else {
                return d2.setLocale(numberlocale)
            }
        }
        if (amounts) {
            let totamount = Dinero({amount: 0, currency: (currency || configuration.defaultCurrency) });
            for (const singleamount of amounts) {
                totamount = totamount.add(Dinero({ amount: Math.round(singleamount * 100), currency: (currency || configuration.defaultCurrency) }))
            }
            if (invert) {
                return totamount.multiply(-1).setLocale(numberlocale);
            } else {
                return totamount.setLocale(numberlocale);
            }
        }
        return Dinero({amount: 0, currency: (currency || configuration.defaultCurrency) }).setLocale(numberlocale);
    }
    
    return (
        <MoneySpan
            className="money-span"
            $small={small} 
            $bold={isDefaultCurrency && boldIfDefaultCurrency} 
            negative={dineroObject().isNegative() && redIfNegative} 
            foreignCurrency={!isDefaultCurrency && highlightCurrency}
        >
                {dineroObject().toFormat(numberformat)}
        </MoneySpan>
    )
}

export default Money;
